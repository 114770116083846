/*eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';


import FloatingLabel from './FloatingLabel';
import UnderlineField from './UnderlineField';
import ErrorText from './ErrorText';
import HintText from './HintText';

import LoadLibPhoneNumber from '../../store/sources/LoadLibraryPhoneNumber';
import PhoneCountries from '../../constants/PhoneConstants';
import libphonenumber from '../../utils/libphonenumber';

/* const mapCountries = [
  {
    from: ['um'],
    to: 'us',
  },
  {
    from: ['aq', 'hm'],
    to: 'nf',
  },
  {
    from: ['tf'],
    to: 'mq',
  },
  {
    from: ['bv'],
    to: 'no',
  },
  {
    from: ['pn', 'gs'],
    to: 'nz',
  },
];

function mapCountry(country) {
  const countryFound = mapCountries.find(map =>
    map.from.indexOf(country) >= 0 // eslint-disable-line
  ); // eslint-disable-line
  return countryFound ? countryFound.to : country;
} */

function isValid(value) {
  return Boolean(value || value === 0);
}

const getStyles = (props, state) => {
  let border = '#CCC #BBB #AAA';

  if (state.isFocused) {
    border = '#888 #777 #666';
  }

  if (props.errorText) {
    border = 'rgb(224, 49, 83) rgb(198, 12, 48) rgb(161, 0, 32)';
  }

  const styles = {
    root: Object.assign({
      position: 'relative',
      backgroundColor: 'transparent',
      width: '100%',
    }, props.style),
    hintStyle: {

    },
  };

  return styles;
};

class IntlTelphoneInput extends Component {
  constructor(props) {
    super(props);

    this.intlTelInputRef = null;

    this.setIntlTelInputRef = element => {
      this.intlTelInputRef = element;
      this.intlTelInputRef && this.props.onLoaded && this.props.onLoaded(this.intlTelInputRef);
    };
  }

  static propTypes = {
    /**
     * Set the input's placeholder to an example number for the selected country.
     */
    autoPlaceholder: PropTypes.bool,

    /**
     * Value used to show the country selected.
     */
    defaultCountry: PropTypes.string,

    /**
     * Default value passed to element.
     */
    defaultValue: PropTypes.number,

    /**
     * Changes the css style if component is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Error text to be shown in case of some error occur.
     */
    errorText: PropTypes.string,

    /**
     * Inline error text style.
     */
    errorStyle: PropTypes.object,

    /**
     * Floating label component text.
     */
    floatingLabelText: PropTypes.string,

    /**
     * Floating label component style
     */
    floatingLabelStyle: PropTypes.object,

    /**
     * Sets the ID of the element.
     */
    id: PropTypes.string,

    /**
     * hintText element content.
     */
    hintText: PropTypes.string,

    /**
     * hintText element style.
     */
    hintStyle: PropTypes.object,

    /**
     * Callback function that is fired when component looses focus.
     */
    onBlur: PropTypes.func,

    /**
     * Callback function that is fired when component is changed.
     */
    onChange: PropTypes.func,

    /**
     * Callback function that is fired when flag is changed.
     */
    onFlagSelected: PropTypes.func,

    /**
     * Callback function that is fired when is focused.
     */
    onFocus: PropTypes.func,

    
    /**
     * Callback function that is fired when is loaded.
     */
    onLoaded: PropTypes.func,

    /**
     * UnderlineField component style.
     */
    underlineStyle: PropTypes.object,

    /**
     * Controls if underline should be show or not.
     */
    underlineShow: PropTypes.bool,

    /**
     * UnderlineField component style when it's focused.
     */
    underlineFocusStyle: PropTypes.object,

    /**
     * Value of the element.
     */
    value: PropTypes.string,

    style: PropTypes.object,

    /**
     * Value of tabIndex
     */
    tabIndex: PropTypes.number,

    /* Contact Us form check */
    isContactUsForm: PropTypes.bool,
  };

  static defaultProps = {
    nationalMode: false,
    preferredCountries: ['gb', 'us'],
    isContactUsForm: false,
    tabIndex: 0,
  };

  state = {
    isFocused: false,
    hasValue: isValid(this.props.value) || isValid(this.props.defaultValue), // eslint-disable-line
  };

  componentDidMount() {
    const {
      disabled,
    } = this.props;
    let { tel: telInput, flagDropDown } = this.intlTelInputRef;

    telInput = ReactDOM.findDOMNode(telInput);
    flagDropDown = ReactDOM.findDOMNode(flagDropDown);

    telInput.disabled = disabled;

    telInput.addEventListener('focus', this.handleFocus, false);
    telInput.addEventListener('blur', this.handleBlur, false);

    flagDropDown.addEventListener('click', this.handleFlagDropDownClick, false);

    LoadLibPhoneNumber.loadPhoneLib().then(() => {
      this.updateState(true);
    }, () => { });
  }

  loadJSONP = (url, callback) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = `${url + (url.indexOf('?') + 1 ? '&' : '?')}callback=${callback}`;
    ref.parentNode.insertBefore(script, ref);
    script.onload = () => {
      script.remove();
    };
  };
  geoIpLookup = (callback) => {
    this.loadJSONP('https://ipinfo.io?token=255a3f1017fbb6', 'sendBack');
    const that = this;
    window.sendBack = (resp) => {
      const countryCode = (resp && resp.country) ? resp.country : '';
      that.props.geoLookupCountryCode && that.props.geoLookupCountryCode(countryCode);
      callback(countryCode);
    };
  };

  /*   UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.defaultCountry &&
        nextProps.defaultCountry !== this.props.defaultCountry) {
        this.intlTelInputRef.setFlag(mapCountry(nextProps.defaultCountry.toLowerCase()));
      }
      this.setState({
        hasValue: isValid(nextProps.value) || isValid(nextProps.defaultValue),
      });
    } */

  componentDidUpdate() {
    const telInput = this.intlTelInputRef;
    ReactDOM.findDOMNode(telInput).disabled = this.props.disabled;
  }

  componentWillUnmount() {
    let { tel: telInput, flagDropDown } = this.intlTelInputRef;
    telInput = ReactDOM.findDOMNode(telInput);
    flagDropDown = ReactDOM.findDOMNode(flagDropDown);

    telInput.removeEventListener('focus', this.handleFocus, false);
    telInput.removeEventListener('blur', this.handleBlur, false);

    flagDropDown.removeEventListener('click', this.handleFlagDropDownClick, false);
  }

  updateState = (isReady) => {
    this.setState({ isReady });
  };

  getSelectedCountryData = () => {
    return this.intlTelInputRef?.selectedCountryData;
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) this.props.onFocus();
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
    if (this.props.onBlur) this.props.onBlur();
  };

  handleFlagDropDownClick = (e) => {
    if (this.props.disabled) {
      e.stopPropagation();
    }
  };

  handlePhoneNumberChange = (status, inputValue, countryData) => {
    let phone = inputValue || '';
    phone = phone.replace(/[^\d+]/g, '');
    if (phone.indexOf('+') < 0 && !this.props.isContactUsForm) {
      phone = `+${countryData.dialCode}${phone}`;
    }
    this.setState((_state, props) => ({
      hasValue: isValid(props.value) || isValid(props.defaultValue),
    }));
    if (this.props.onChange && this.props.value !== phone) {
      this.props.onChange(status, phone, countryData);
    }
  };

  handleSelectedFlag = (inputValue, countryData) => {
    let phone = inputValue || '';
    phone = phone.replace(/[^\d+]/g, '');
    if (phone.indexOf('+') < 0 && !this.props.isContactUsForm) {
      phone = `+${countryData.dialCode}${phone}`;
    }
    if (this.props.onFlagSelected) {
      this.props.onFlagSelected(phone, countryData);
    }
  }

  render() {
    const {
      id,
      autoPlaceholder,
      disabled,
      floatingLabelText,
      floatingLabelStyle,
      underlineShow,
      underlineStyle,
      underlineFocusStyle,
      errorStyle,
      errorText,
      hintText,
      hintStyle,
      value,
      style,
      ...other
    } = this.props;

    let formattedValue = value;
    if (this.state.isReady) {
      formattedValue = global.intlTelInputUtils.formatNumber(value, undefined,
        global.intlTelInputUtils.numberFormat.INTERNATIONAL);
    }

    // eslint-disable-next-line max-len
    const css = [`intl-tel-input intl-input-custom ${disabled ? 'intl-tel-input-disabled' : ''}`];
    const styles = getStyles(this.props, this.state);

    return (
      <div style={Object.assign(styles.root, style)}>
        {floatingLabelText &&
          <FloatingLabel
            htmlFor={id}
            style={floatingLabelStyle}
            disabled={disabled}
            hasValue={this.state.hasValue || autoPlaceholder}
            isFocused={this.state.isFocused}
          >
            {floatingLabelText}
          </FloatingLabel>}
        {hintText &&
          <HintText
            style={Object.assign(styles.hintStyle, hintStyle)}
            {...this.state}
          >
            {hintText}
          </HintText>
        }
        <IntlTelInput
          fieldId="telephone"
          countriesData={PhoneCountries}
          css={css}
          utilsScript={libphonenumber}
          value={value}
          onPhoneNumberChange={this.handlePhoneNumberChange}
          onSelectFlag={this.handleSelectedFlag}
          ref={this.setIntlTelInputRef}
          autoPlaceholder={false}
          geoIpLookup={this.geoIpLookup}
          defaultCountry={'auto'}
          nationalMode={false}
          autoHideDialCode={false}
          {...other}
        />

        {underlineShow &&
          <UnderlineField
            underlineStyle={underlineStyle}
            underlineFocusStyle={underlineFocusStyle}
            disabled={disabled}
            hasValue={this.state.hasValue || autoPlaceholder}
            isFocused={this.state.isFocused}
          />}
        <ErrorText style={errorStyle}>
          {errorText}
        </ErrorText>
      </div>
    );
  }
}

export default IntlTelphoneInput;
