import * as types from '../actionTypes';

const initialState = {
  isGoogleMapsApiLoaded: false,
  isGooglePlacesAutocompleteBinded: false,
};

export default function googleMapReducer(state = initialState, action) {
  switch (action.type) {
    case types.GOOGLE_MAPS_API_LOADED: {
      return Object.assign({}, state, {
        isGoogleMapsApiLoaded: true,
      });
    }
    case types.PLACES_AUTOCOMPLETE_BINDED: {
      return Object.assign({}, state, {
        isGooglePlacesAutocompleteBinded: true,
      });
    }
    default:
      return state;
  }
}
