import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from 'store/reducers/rootReducer';
import { existInLocal, getFromLocal } from '../utils/cache';
// import { saveState, loadState } from 'utils/storageUtils';
import * as userState from './reducers/userReducer';
import * as tokenState from './reducers/tokenReducer';
import * as registrationState from './reducers/registrationReducer';

function checkAndRestoreFromLocal() {
  // Name should be same as those of the reducers
  let user = userState.initialState;
  let token = tokenState.initialState;
  let registration = registrationState.initialState;
  if (existInLocal('user')) {
    user = Object.assign({}, user, getFromLocal('user', true, true));
  }
  if (existInLocal('adminToken')) {
    token = Object.assign({}, token, getFromLocal('adminToken'));
  }
  if (existInLocal('token')) {
    token = Object.assign({}, token, getFromLocal('token'));
  }
  if (existInLocal('register')) {
    registration = Object.assign({}, registration, getFromLocal('register', true, true));
  }
  return {
    user,
    token,
    registration,
  };
}

const persistedState = checkAndRestoreFromLocal();

let middlewareEnhancer;
if (process.env.NODE_ENV === "production") {
  middlewareEnhancer = applyMiddleware(thunkMiddleware)
} else {
  middlewareEnhancer = applyMiddleware(thunkMiddleware, createLogger());
}

const composedEnhancer = composeWithDevTools(middlewareEnhancer);

const store = createStore(
  rootReducer,
  persistedState,
  composedEnhancer,
);

export default store;
