import { garanteeArray } from '../utils/sourceUtils';
import { CATERING_SERVICE_GENERAL } from '../constants/CateringConstants';

const normalizeSelectionList = (selection) => ({
  id: +selection.id,
  quantity: +selection.quantity,
  customerNotes: selection.customerNotes,
  toShare: !!selection.toShare,
  // default values below for 'toShare'
  personId: +selection.personId || 0,
  paxName: selection.paxName || 'To Share',
});

const normalizeItemList = (item, category) => ({
  id: +item.id,
  cateringType: category,
  description: item.description,
  rank: +item.rank,
  title: item.title,
  unit: item.unit,
  service: item.service || CATERING_SERVICE_GENERAL,
  selectionList: garanteeArray((item.orSelectionList || {}).orderRequestSelection)
    .map(normalizeSelectionList),
});

const normalizeServices = (categories) => {
  const services = {};
  categories.forEach((category) => {
    category.itemList.forEach((item) => {
      let service = CATERING_SERVICE_GENERAL;
      if (item.service) service = item.service;
      if (!services[service]) {
        services[service] = {};
      }
      if (!services[service][category.cateringCategory]) {
        services[service][category.cateringCategory] = [];
      }
      services[service][category.cateringCategory].push(item);
    });
  });
  return services;
};

const normalizeCategories = (category) => ({
  cateringCategory: category.cateringCategory,
  id: +category.id,
  rank: +category.rank,
  itemList: garanteeArray((category.orList || {}).orderRequest)
    .map(i => normalizeItemList(i, category.cateringCategory)),
});

const normalizeSupplier = (supplier) => {
  const categories = garanteeArray((supplier.orCategoryList || {}).orderRequestCategory)
    .map(normalizeCategories);
  const services = normalizeServices(categories);
  return {
    serviceProviderId: +supplier.serviceProviderId,
    supplierDisplayName: supplier.supplierDisplayName,
    supplierName: supplier.supplierName,
    categories,
    services,
  };
};

const normalizeCateringOrderUtil = (cateringObj) => {
  const suppliers = garanteeArray(((((cateringObj || {}).cateringModuleDetail || {})
    .orSupplierList) || {}).orderRequestSupplier);
  return suppliers.map(normalizeSupplier);
};

export default normalizeCateringOrderUtil;
