import aircraftsList from "./Aircrafts";

export const getAircraftByField = (
  searchText,
  aircraftsArray = [],
  field = "id",
) => {
  let result = {};
  if (!searchText) {
    return result;
  }
  const aircrafts = aircraftsArray.length > 0 ? aircraftsArray : aircraftsList;
  aircrafts.some(aircraft => {
    if (aircraft[field] === searchText) {
      result = aircraft;
      return true;
    }
    return false;
  });
  return result;
};

export function getAircraftByAllFields(searchText) {
  let result = {};
  if (!searchText) {
    return result;
  }
  const fields = ["id", "name", "code", "displayName"];
  aircraftsList.some(aircraft =>
    fields.some(field => {
      if (aircraft[field] === searchText) {
        result = aircraft;
        return true;
      }
      return false;
    })
  );
  return result;
}

export const zeroPad = (num, places) => {
  const zero = places - num.toString().length + 1;
};

export const getAvailableAircrafts = availableAircrafts => {
  const availableAircraftIds = [];
  (availableAircrafts || []).forEach(availableAircraft => {
    aircraftsList.forEach(aircraft => {
      if (aircraft.id === availableAircraft.id) {
        availableAircraftIds.push(aircraft.id);
      }
    });
  });
  return availableAircraftIds;
};
