import * as types from "../actionTypes";
import { mergeInvoices } from '../../utils/PASUtils';

const initialState = {
  unbilledFlights: [],
  loadingUnbilledFlights: false,
  unbilledFlightsError: '',
  billedFlights: [],
  loadingBilledFlights: false,
  billedFlightsError: '',
  loadingMoreBilledFlights: [],
  moreBilledFlightsError: '',
  accountStatementInfo: [],
  accountStatements: [],
  accountStatementsError: '',
  loadingAccountStatements: 0,
  statementInvoices: [],
  statementInvoicesLastPage: false,
  loadingStatementInvoices: false,
  loadingMoreStatementInvoices: false,
  statemenetInvoicesError: '',
  loadingZipUrl: false,
  zipUrlError: '',
  loadingPdf: false,
  pdfError: '',
};

export default function PASReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_UNBILLED_INIT:
      return {
        ...state,
        unbilledFlights: [],
        loadingUnbilledFlights: true,
        unbilledFlightsError: '',
      };
    case types.FETCH_UNBILLED_SUCCESS:
      return {
        ...state,
        unbilledFlights: action.data.unbilledFlights || [],
        loadingUnbilledFlights: false,
        unbilledFlightsError: '',
      };
    case types.FETCH_UNBILLED_FAIL:
      return {
        ...state,
        unbilledFlights: null,
        loadingUnbilledFlights: false,
        unbilledFlightsError: action.data.error || '',
      };
    case types.FETCH_RECENT_INVOICES_INIT:
      return {
        ...state,
        billedFlights: [],
        loadingBilledFlights: true,
        billedFlightsError: '',
      };
    case types.FETCH_RECENT_INVOICES_SUCCESS:
      return {
        ...state,
        billedFlights: action.data.billedFlights || [],
        loadingBilledFlights: false,
        billedFlightsError: '',
      };
    case types.FETCH_RECENT_INVOICES_FAIL:
      return {
        ...state,
        billedFlights: null,
        loadingBilledFlights: false,
        billedFlightsError: action.data.error || '',
      };
    case types.FETCH_MORE_INVOICES_INIT:
      return {
        ...state,
        loadingMoreBilledFlights: [
          ...state.loadingMoreBilledFlights, {
            accountId: action.data.accountId,
            aircraftTypeId: action.data.aircraftTypeId,
          },
        ]
      };
    case types.FETCH_MORE_INVOICES_SUCCESS:
      return {
        ...state,
        loadingMoreBilledFlights:
          state.loadingMoreBilledFlights
            .filter(({ aircraftTypeId, accountId }) =>
              !(aircraftTypeId === action.data.aircraftTypeId &&
                accountId === action.data.accountId)
            ),
        moreBilledFlightsError: '',
        billedFlights: mergeInvoices(
          state.billedFlights,
          action.data
        ),
      };
    case types.FETCH_MORE_INVOICES_FAIL:
      return {
        ...state,
        loadingMoreBilledFlights:
          state.loadingMoreBilledFlights
            .filter(({ aircraftTypeId, accountId }) =>
              !(aircraftTypeId === action.data.aircraftTypeId &&
                accountId === action.data.accountId)
            ),
        moreBilledFlightsError: action.data.error || '',
      };
    case types.FETCH_ACCOUNT_STATEMENTS_INIT:
      return {
        ...state,
        loadingAccountStatements: state.loadingAccountStatements + 1,
        accountStatementsError: '',
        accountStatementInfo: [],
        accountStatements: [],
      };
    case types.FETCH_ACCOUNT_STATEMENTS_SUCCESS:
      return {
        ...state,
        loadingAccountStatements: state.loadingAccountStatements - 1,
        accountStatementsError: '',
        accountStatementInfo: action.data.accountStatementInfo || [],
        accountStatements: action.data.accountStatements || [],
      };
    case types.FETCH_ACCOUNT_STATEMENTS_FAIL:
      return {
        ...state,
        loadingAccountStatements: 0,
        accountStatementsError: action.data.error || '',
        accountStatementInfo: null,
        accountStatements: null,
      };
    case types.FETCH_STATEMENT_INVOICES_INIT:
      return {
        ...state,
        statementInvoices: action.data ? state.statementInvoices : [],
        statementInvoicesLastPage: false,
        loadingStatementInvoices: action.data ? false : true,
        loadingMoreStatementInvoices: action.data ? true : false,
        statementInvoicesError: '',
      };
    case types.FETCH_STATEMENT_INVOICES_SUCCESS:
      return {
        ...state,
        statementInvoices: action.data.loadMore ? [
          ...state.statementInvoices,
          ...action.data.statementInvoicesData.invoices
        ] : action.data.statementInvoicesData.invoices,
        statementInvoicesLastPage: action.data.statementInvoicesData.lastPage || false,
        loadingStatementInvoices: false,
        loadingMoreStatementInvoices: false,
        statementInvoicesError: '',
      };
    case types.FETCH_STATEMENT_INVOICES_FAIL:
      return {
        ...state,
        statementInvoices: null,
        statementInvoicesLastPage: false,
        loadingStatementInvoices: false,
        loadingMoreStatementInvoices: false,
        statementInvoicesError: action.data.error || '',
      };
    case types.CLEAR_INVOICE_FILTER:
      return {
        ...state,
        statementInvoicesLastPage: state.statementInvoices.length < 6,
        statementInvoices: state.statementInvoices.slice(0, 5) || [],
      }
    case types.FETCH_ZIP_URL_INIT:
      return {
        ...state,
        loadingZipUrl: true,
        zipUrlError: '',
      };
    case types.FETCH_ZIP_URL_SUCCESS:
      return {
        ...state,
        loadingZipUrl: false,
        zipUrlError: '',
      };
    case types.FETCH_ZIP_URL_FAIL:
      return {
        ...state,
        loadingZipUrl: false,
        zipUrlError: action.data.error || '',
      };
    case types.CLEAR_PAS_ERROR:
      return {
        ...state,
        [action.data.errorField]: '',
      }
    case types.FETCH_ACCOUNT_STATEMENT_PDF_INIT:
      return {
        ...state,
        loadingPdf: true,
        pdfError: '',
      }
    case types.FETCH_ACCOUNT_STATEMENT_PDF_SUCCESS:
      return {
        ...state,
        loadingPdf: false,
        pdfError: '',
      }
    case types.FETCH_ACCOUNT_STATEMENT_PDF_FAIL:
      return {
        ...state,
        loadingPdf: false,
        pdfError: action.data,
      }
    default:
      return state;
  }
}
