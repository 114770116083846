import React from 'react';
import PropTypes from 'prop-types';
import SpinnerLoader from '../shared/spinnerLoader';
import { isMobile } from 'react-device-detect';

const companyPhoneNumber = '+44 2036173107';

const OperationalNotes = ({ operationalNotes, isLoading }) => {
    const notes = operationalNotes || '';
    return (
        <div class="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove">
            <div class="vistajet_itinerary_card_header">
                <h5>Operational Notes</h5>
            </div>
            <div class="vistajet_itinerary_card_body">
            {
                isLoading ? (
                    <div className="vistajet_spinner">
                      <SpinnerLoader />
                    </div>
                  ) : (
                    <div>
                        { notes && <p>{notes} </p> }
                        <p>
                            Please plan to arrive at the airport 30 minutes before your scheduled departure. If there are any
                        changes to your schedule please contact us. Please call us for any special request. {isMobile ? <a href={`tel:${companyPhoneNumber}`}>Call
                            Now</a> : companyPhoneNumber}
                        </p>
        
                        <p className="uk-margin-small-top">Please note, PED’s (personal electronic devices) contain lithium batteries which can be a serious
                        fire risk during flight
                        <span className="uk-display-block">- Please report for any damaged device to your crew and ensure it is switched off for the duration
                        of the flight;
                        <span className="uk-display-block">- Should you lose or forget your PED on-board the aircraft, please notify VistaJet immediately </span>
                            <span className="uk-display-block">- Any PED’s stored in your Hold baggage should be confirmed off and packed in such a way to ensure
                        it cannot inadvertently switch on during the flight.</span>
                        </span>
                        </p>
                    </div>
                  )
            }
            </div>
        </div>);
};

OperationalNotes.propTypes = {
    operationalNotes: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default OperationalNotes;
