import React from "react";
import moment from "moment";
import {
  LEG_ARRAY,
  REGISTER_VIA_PAF,
  LOGIN_VIA_PAF,
  IS_OFFER_FLOW,
  QUOTE_OBJECT
} from "../../constants/localStorageKeys";
import { getFromLocal, removeFromLocal, existInLocal } from "../../utils/cache";
import { HOME_PAGE_URL, OFFERS_PAGE_URL, ITINERARY_DETAILS_PAGE_URL, ACTIVE_QUOTES_PAGE_URL, EXPIRED_QUOTES_PAGE_URL } from "../../configs/constants";
import LegListing from "./LegListing";
import { THANK_YOU_REQUEST_QUOTE_VJ, THANK_YOU_REQUEST_QUOTE_ADHOC, THANK_YOU_PLAN_A_FLIGHT, THANK_YOU_OFFER } from "../../constants/MessageConstants"
import ThankyouScreen from "./ThankyouScreen";
import QuoteSummary from "./QuoteSummary";
import { dateFormats, convertToDoubleDigits } from "../../utils/dateUtils";
import SpinnerLoader from "../shared/spinnerLoader";
import { getAircraftByField } from "../../utils/aircraftUtils";
import MessagePopUp from "../shared/MessagePopUpNew";
import ConfirmationPopUpNew from "../shared/ConfirmationPopUpNew";
import createGuid from "../../utils/guidUtils";
import { isAdhoc } from "../../utils/userUtils";
import ConfirmationPopup from "../shared/ConfirmationPopUpNew";
import { CONFIRMATION_TITLE, CONFIRMATION_MESSAGE, LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT } from "../../constants/DirtyPopupConstant";
import dirtystateSingleTon from "../../validators/validateDirty";
import SPAConstants from "../../constants/SPAConstants";

class PAFLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legArray: [],
      user: null,
      shouldModalOpen: false,
      isOfferFlow: false,
      offerObject: {},
      offerToolTipInfo: [],
      isEditFlow: false,
      isEditQuote: false,
      buyQuote: {},
    };
  }
  componentDidMount() {
    removeFromLocal(REGISTER_VIA_PAF);
    removeFromLocal(LOGIN_VIA_PAF);
    if (!this.props.isAirportsFetched) {
      this.props.fetchVisitedAirports().then(() => { }, this.handleErrors);
    }
    const dataFromLocal = getFromLocal(LEG_ARRAY);
    if (dataFromLocal) {
      this.setState({
        legArray: dataFromLocal
      });
      removeFromLocal(LEG_ARRAY);
    }
    let isOfferFlow = false;
    if (existInLocal(IS_OFFER_FLOW)) {
      isOfferFlow = getFromLocal(IS_OFFER_FLOW);
    }
    if (isOfferFlow && dataFromLocal && dataFromLocal.length > 0) {
      let offerObjectTemp = {};
      let offerToolTipInfo = [];
      offerObjectTemp.aircraftTypeId = dataFromLocal[0].aircraftTypeId;
      offerObjectTemp.operatingCompanyName =
        dataFromLocal[0].operatingCompanyName;
      if (dataFromLocal[0].isOneWay) {
        offerObjectTemp.type = "OneWay";
        offerObjectTemp.segmentEnd = dataFromLocal[0].segmentEnd;
        offerObjectTemp.segmentStart = dataFromLocal[0].segmentStart;
        offerObjectTemp.maxLegs = dataFromLocal[0].maxLegs;
        offerObjectTemp.maxGroundTimeInHours =
          dataFromLocal[0].maxGroundTimeInHours;
        offerObjectTemp.departureAirport = dataFromLocal[0].departureAirport;
        offerToolTipInfo.push({
          name: "Valid From",
          value: moment
            .utc(dataFromLocal[0].segmentStart)
            .format(dateFormats.DDMMMYYYY)
        });
        offerToolTipInfo.push({
          name: "Valid To",
          value: moment
            .utc(dataFromLocal[0].segmentEnd)
            .format(dateFormats.DDMMMYYYY)
        });
        offerToolTipInfo.push({
          name: "Maximum Flights",
          value: dataFromLocal[0].maxLegs
        });
        offerToolTipInfo.push({
          name: "Maximum time between flights",
          value: `${dataFromLocal[0].maxGroundTimeInHours}h`
        });
      } else {
        offerObjectTemp.type = "EmptyLeg";
        offerObjectTemp.validFrom = dataFromLocal[0].minDepartureTime;
        offerObjectTemp.validTo = dataFromLocal[0].maxDepartureTime;
        offerObjectTemp.departureAirport = dataFromLocal[0].departureAirport;
        offerObjectTemp.arrivalAirport = dataFromLocal[0].arrivalAirport;
        offerToolTipInfo.push({
          name: "Valid From",
          value: moment
            .utc(dataFromLocal[0].minDepartureTime)
            .format(dateFormats.DDMMMYYYY)
        });
        offerToolTipInfo.push({
          name: "Valid To",
          value: moment
            .utc(dataFromLocal[0].maxDepartureTime)
            .format(dateFormats.DDMMMYYYY)
        });
      }
      this.setState({
        isOfferFlow,
        offerObject: offerObjectTemp,
        offerToolTipInfo
      });
    }
    removeFromLocal(IS_OFFER_FLOW);
    const orderId = (new URLSearchParams(window.location.search)).get('orderId');
    const orderExt = (new URLSearchParams(window.location.search)).get('orderExtIdentifier');
    if (orderId) {
      const { fetchItineraryDetails, userToken } = this.props;
      fetchItineraryDetails(userToken, 'orderId', orderId);
      this.setState({ isEditFlow: true });
    }
    else if (orderExt) {
      const { fetchItineraryDetails, userToken } = this.props;
      fetchItineraryDetails(userToken, 'orderExtIdentifier', orderExt);
      this.setState({ isEditFlow: true });
    }
    const quoteId = (new URLSearchParams(window.location.search)).get('quoteId');
    if (quoteId) {
      const { fetchItineraryDetails, userToken } = this.props;
      fetchItineraryDetails(userToken, 'orderId', quoteId);
      this.setState({ isEditQuote: true });
    }
    const { user } = this.props;
    const quoteObj = user && getFromLocal(QUOTE_OBJECT.replace('{username}', user.username));
    const params = window.location.search;
    const quoteSummaryParam = params.split('/');
    const key = quoteSummaryParam[quoteSummaryParam.length - 2];
    if (quoteObj) {
      dirtystateSingleTon.setDirty(SPAConstants.MyQuotes, true);
      this.setState({ buyQuote: quoteObj });
      removeFromLocal(QUOTE_OBJECT.replace('{username}', user.username));
    } else if (key === "quote-summary-page") {
      window.open(ACTIVE_QUOTES_PAGE_URL, '_self');
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      itineraryLegs,
      isItineraryLoading,
      isAirportByIdLoading,
      visitedAirportsFinder,
      itineraryDetailsError,
      orderStatus,
      user,
      fetchPassengers
    } = nextProps;
    if ((nextProps.error !== "" && nextProps.error !== this.props.error)
      || (itineraryDetailsError && itineraryDetailsError !== this.props.itineraryDetailsError)) {
      this.messagePopup.show();
    }
    if (nextProps.confirmationMessage !== "" && nextProps.confirmationMessage != this.props.confirmationMessage) {
      this.confirmationMessagePopUp.show();
    }
    if (itineraryLegs && (itineraryLegs !== this.props.itineraryLegs)) {
      const ids = [];
      const orderId = (new URLSearchParams(window.location.search)).get('orderId');
      const orderExt = (new URLSearchParams(window.location.search)).get('orderExtIdentifier');
      itineraryLegs.forEach(leg => {
        ids.push(leg.departureAirportId);
        ids.push(leg.arrivalAirportId);
        fetchPassengers(user.userToken, {
          params: {
            legId: leg.legId || leg.flightLegExtIdentifier,
            status: orderStatus,
            uniqueIdentifier: orderId || orderExt,
            key: orderId ? 'orderId' : 'orderExtIdentifier'
          },
          leg,
        });
      })
      if (ids.length)
        this.props.getAirportById([...new Set(ids)]);
    }
    if (!isItineraryLoading && !isAirportByIdLoading
      && (isAirportByIdLoading !== this.props.isAirportByIdLoading)) {
      this.getLegs(visitedAirportsFinder);
    }
  }

  getLegs = (visitedAirportsFinder) => {
    let legArray = [];
    const { itineraryLegs } = this.props;
    const { isEditQuote, isEditFlow } = this.state;
    const params = window.location.href;
    const requoteParam = params.split('&');
    const key = requoteParam[requoteParam.length - 1];
    itineraryLegs.forEach((leg, index) => {
      if ((moment(leg.departureAirportTimeUTC).isAfter(moment.utc())
        && leg.legStatus !== 'Cancellation Pending'
        && leg.legStatus !== 'Cancelled')
        || isEditQuote) {
        leg.departTime = leg.departureAirportTimeLocal;
        leg.arrivalTime = leg.arrivalAirportTimeLocal;
        leg.departureAirport = visitedAirportsFinder[leg.departureAirportId];
        leg.arrivalAirport = visitedAirportsFinder[leg.arrivalAirportId];
        if (isEditFlow) {
          leg.selectedAircraft = leg.aircraftTypeName
            ? getAircraftByField(leg.aircraftTypeName, this.props.aircraftsList, 'code')
            : getAircraftByField(leg.aircraftTypeId, this.props.aircraftsList);
          if (!Object.keys(leg.selectedAircraft).length) {
            leg.selectedAircraft = getAircraftByField(leg.aircraftTypeId, this.props.aircraftsList)
          }
        }
        else {
          leg.selectedAircraft = leg.aircraftTypeId
            ? getAircraftByField(leg.aircraftTypeId, this.props.aircraftsList)
            : getAircraftByField(leg.requestedAircraftTypeId, this.props.aircraftsList);

        }

        leg.prevLeg = index > 0 ? legArray[index - 1] : null;
        leg.passengersCount = leg.numberOfPassenger;
        leg.date = key === 'requote' ? null : leg.departureAirportTimeLocal.split('T')[0];
        leg.duration = (leg.defaultDurationMinutes).toString();
        leg.hour = convertToDoubleDigits(moment.utc(leg.departureAirportTimeLocal).hours().toString());
        leg.minutes = convertToDoubleDigits(moment.utc(leg.departureAirportTimeLocal).minutes().toString());
        leg.time = key === 'requote' ? '' : `${leg.hour}:${leg.minutes}`;
        leg.guid = leg.flightLegExtIdentifier || createGuid();
        leg.parentQuoteId = (new URLSearchParams(window.location.search)).get('quoteId')
        leg.validation = {};
        leg.validation.errorOn = {
          departure: null,
          arrival: null,
          departureArrival: null,
          departureArrivalSame: null,
          time: null,
          passenger: null,
          aircraft: null,
          date: null,
          contract: null,
          minHoursException: null
        };
        leg.shouldRemoveButton = key === 'requote' ? true : false;
        leg.validation.isError = false;
        legArray.push(leg);
      }
    });
    legArray.length && this.setState({ legArray });
  }

  redirectURLLink = () => {
    const params = window.location.href;
    const requoteParam = params.split('&');
    const key = requoteParam[requoteParam.length - 1];
    const { isEditFlow, isOfferFlow, isEditQuote } = this.state;
    let URL = undefined;
    if (isOfferFlow) {
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
      URL = OFFERS_PAGE_URL;
    } else if (isEditFlow) {
      const flightLegExtIdentifier = (new URLSearchParams(window.location.search))
        .get('flightLegExtIdentifier');
      const orderId = (new URLSearchParams(window.location.search)).get('orderId');
      const orderExt = (new URLSearchParams(window.location.search)).get('orderExtIdentifier');
      let key = '';
      if (orderId) {
        key = 'orderId';
      }
      else if (orderExt) {
        key = 'orderExtIdentifier';
      }
      const Url = ITINERARY_DETAILS_PAGE_URL.url
        .replace('{key}', key)
        .replace('{orderExtendifier}', orderId || orderExt)
        .replace('{flightLegExtendifier}', flightLegExtIdentifier)
        .replace('{itineraryType}', 'upcomingItineraries');
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
      URL = Url;
    } else if (isEditQuote && key !== 'requote') {
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
      dirtystateSingleTon.setDirty(SPAConstants.MyQuotes, false);
      URL = ACTIVE_QUOTES_PAGE_URL;
    } else if (isEditQuote && key === 'requote') {
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
      dirtystateSingleTon.setDirty(SPAConstants.MyQuotes, false);
      URL = EXPIRED_QUOTES_PAGE_URL;
    } else {
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
    }
    return URL
  }

  redirectToHome = (isThankyou) => {
    if (isThankyou) {
      window.open(HOME_PAGE_URL, "_self");
    } else {
      const URL_TO_REDIRECT = this.redirectURLLink();
      if (URL_TO_REDIRECT) {
        window.open(URL_TO_REDIRECT, '_self');
      } else {
        window.history.back();
      }
    }
  };

  backToOffer = legArray => {
    this.setState({
      legArray
    });
    this.props.updateQuoteContent();
  };
  populateMessage = () => {
    let message;
    const { requestFlightSuccess, requestQuoteSuccess, buyOfferSuccess, user } = this.props;
    if (requestFlightSuccess) {
      message = THANK_YOU_PLAN_A_FLIGHT;
    }
    else if (this.props.requestQuoteSuccess) {
      message = isAdhoc(this.props.user) ? THANK_YOU_REQUEST_QUOTE_ADHOC : THANK_YOU_REQUEST_QUOTE_VJ
    }
    else if (buyOfferSuccess) {
      message = THANK_YOU_OFFER;
    }
    return message;
  }
  moveAwayFromOffer = () => {
    this.confirmationMessagePopUp.hide();
    this.setState({ isOfferFlow: false })

  }

  checkDirtyBeforeRedirect = isThankyou => {
    const isPAFDirty = dirtystateSingleTon.getDirty(SPAConstants.PAFContainer);
    if (isPAFDirty) {
      this.pafConfirmPopup.show();
    } else {
      this.redirectToHome(isThankyou);
    }
  };

  render() {
    const {
      isItineraryLoading,
      isAirportByIdLoading,
      error,
      itineraryDetailsError,
      orderExtIdentifier,
      orderIdentifiers,
      orderId,
      confirmationMessage,
      isLoadingPassenger,
      passengers,
      updatePassenger,
      isCreateCompanyLoading,
    } = this.props;
    let offerToolTipInfo = this.state.offerToolTipInfo || [];
    const offerObject = this.state.offerObject;
    const itineraryOrderId = (new URLSearchParams(window.location.search)).get('orderId');
    const itineraryOrderExt = (new URLSearchParams(window.location.search)).get('orderExtIdentifier');
    let itineraryDetails = {};
    if (itineraryOrderId || orderId) {
      itineraryDetails['orderId'] = itineraryOrderId || orderId;
    }
    else if (itineraryOrderExt || orderExtIdentifier) {
      itineraryDetails['orderExtIdentifier'] = itineraryOrderExt || orderExtIdentifier;
    }
    const errorToShow = error || itineraryDetailsError;
    return (
      <div>
        {(Object.keys(this.props.quoteContent).length === 0
          && (Object.keys(this.state.buyQuote).length === 0 || this.props.buyOfferSuccess)) ? (
            <div className="vistajet_bs_card">
              {(isItineraryLoading || isLoadingPassenger || updatePassenger || isCreateCompanyLoading) ?
                <div className="uk-text-center uk-padding paf_card_height vistajet_spinner"><SpinnerLoader /></div> :
                <div>
                  {!(
                    this.props.requestQuoteSuccess || this.props.requestFlightSuccess || this.props.buyOfferSuccess
                  ) ? (
                      <div>
                        <div className="vistajet_head_column">
                          <a
                            className="vistajet_back"
                            onClick={() => this.checkDirtyBeforeRedirect(false)}
                          >
                            <span>Back</span>
                          </a>
                          <h5 className="uk-text-center vistajet_color_red">
                            Request Summary
                        </h5>
                        </div>
                        <div>
                          {(isItineraryLoading || isAirportByIdLoading || isLoadingPassenger || updatePassenger) ?
                            <div className="uk-text-center uk-padding paf_card_height vistajet_spinner"><SpinnerLoader /></div>
                            :
                            <LegListing
                              aircraftsList={this.props.aircraftsList}
                              legArray={this.state.legArray}
                              isEditFlow={this.state.isEditFlow}
                              isEditQuote={this.state.isEditQuote}
                              isValidateFlightLoading={this.props.isValidateFlightLoading}
                              isRequestFlightLoading={this.props.isRequestFlightLoading}
                              validateFlightMessages={this.props.validateFlightMessages}
                              isProposalOrRestriction={this.props.isProposalOrRestriction}
                              user={this.props.user}
                              validateAllFlights={this.props.validateAllFlights}
                              isAirportsFetched={this.props.isAirportsFetched}
                              visitedAirports={this.props.visitedAirports}
                              fetchVisitedAirports={this.props.fetchVisitedAirports}
                              requestAllQuote={this.props.requestAllQuote}
                              createCompany={this.props.createCompanies}
                              isOfferFlow={this.state.isOfferFlow}
                              offerObject={this.state.offerObject}
                              offerToolTipInfo={this.state.offerToolTipInfo}
                              itineraryAccountId={this.props.itineraryAccountId}
                              itineraryDetails={Object.keys(itineraryDetails) ? itineraryDetails : null}
                              resetFlightErrorMessages={this.props.resetFlightErrorMessages}
                              aircraftsList={this.props.aircraftsList}
                              passengers={passengers}
                            />}
                        </div>
                      </div>
                    ) : (
                      <ThankyouScreen
                        legArray={this.props.legArray}
                        orderStatus={this.props.orderStatus}
                        redirectToHome={this.redirectToHome}
                        orderId={this.props.orderId || itineraryDetails.orderId}
                        user={this.props.user}
                        isOfferFlow={this.state.isOfferFlow}
                        message={this.populateMessage() || ""}
                        itineraryDetails={Object.keys(itineraryDetails) ? itineraryDetails : null}
                        orderIdentifiers={orderIdentifiers}
                        isBuyQuote={!!Object.keys(this.state.buyQuote).length}
                        requestFlightSuccess={this.props.requestFlightSuccess}
                        requestQuoteSuccess={this.props.requestQuoteSuccess}
                        buyOfferSuccess={this.props.buyOfferSuccess}
                      />
                    )}
                </div>
              }
            </div>

          ) : (
            <div>
              <QuoteSummary
                legArray={this.props.legArray}
                backToOffer={this.backToOffer}
                quoteContent={this.props.quoteContent}
                creditCards={this.props.creditCards}
                isCreditCardLoading={this.props.isCreditCardLoading}
                isCreditCardAdded={this.props.isCreditCardAdded}
                creditCardError={this.props.creditCardError}
                addCreditCardAction={this.props.addCreditCardAction}
                buyOfferQuote={this.props.buyOfferQuote}
                isRequestFlightLoading={this.props.isRequestFlightLoading}
                getCreditCardList={this.props.getCreditCardList}
                buyQuote={this.state.buyQuote}
                user={this.props.user}
                isGoogleMapsApiLoaded={this.props.isGoogleMapsApiLoaded}
                onGoogleMapsApiLoaded={this.props.onGoogleMapsApiLoaded}
                billingAddress={this.props.billingAddress}
                isBillingAddressLoading={this.props.isBillingAddressLoading}
                isBillingAddressError={this.props.isBillingAddressError}
                getBillingAddress={this.props.getBillingAddress}
                saveBillingAddress={this.props.saveBillingAddress}
              />
            </div>
          )}
        <MessagePopUp
          id="PAFError"
          content={errorToShow}
          ref={(messagePopup) => { this.messagePopup = messagePopup; }}
        />
        <ConfirmationPopUpNew
          ref={(confirmationMessagePopUp) => { this.confirmationMessagePopUp = confirmationMessagePopUp; }}
          leftButtonContent="No"
          rightButtonContent="Yes"
          onLeftButtonClick={() => this.confirmationMessagePopUp.hide()}
          onRightButtonClick={() => this.moveAwayFromOffer()}
          content={confirmationMessage}
        />
        <ConfirmationPopup
          ref={pafConfirmPopup => { this.pafConfirmPopup = pafConfirmPopup }}
          title={CONFIRMATION_TITLE}
          content={CONFIRMATION_MESSAGE}
          leftButtonContent={LEFT_BUTTON_TEXT}
          rightButtonContent={RIGHT_BUTTON_TEXT}
          onRightButtonClick={() => this.redirectToHome(false)}
          onLeftButtonClick={() => this.pafConfirmPopup.hide()}
        />
      </div>
    );
  }
}

export default PAFLayout;
