import * as type from '../../actionTypes';
import { getFlightId } from '../../../utils/itinerariesUtils';
import { getAnnouncedPax, setLegPassport, sortPassengers } from '../../../utils/passengerUtils';
import store from '../../store';

const initialState = {
  isChanged: 0,
  isLoadingPassenger: false,
  passengers: {},
  updatePassenger: false,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.ITINERARY_DETAILS_SUCCESS: {
      const {
        legs,
      } = action.data;
      const passengerData = {};
      legs.forEach(leg => {
        passengerData[getFlightId(leg)] = {};
        passengerData[getFlightId(leg)].isLoadingPassenger = false;
      });
      return Object.assign({}, state, {
        passengers: passengerData,
      });
    }
    case type.FETCH_PASSENGER_INIT: {
      state.passengers[action.flightLegId].isLoadingPassenger = true;
      return Object.assign({}, state, {
        isChanged: state.isChanged + 1,
        isLoadingPassenger: true,
        passengers: state.passengers,
      });
    }

    case type.FETCH_PASSENGER_SUCCESS: {
      const leg = store.getState().itineraryDetails.legs[action.flightLegId];
      state.passengers[action.flightLegId].passengers = sortPassengers(setLegPassport(action.data.normalizedData.passengers, leg));
      state.passengers[action.flightLegId].announcedPax = getAnnouncedPax(leg, state.passengers[action.flightLegId].passengers);
      state.passengers[action.flightLegId].isLoadingPassenger = false;
      return Object.assign({}, state, {
        isChanged: state.isChanged - 1,
        isLoadingPassenger: false,
        passengers: { ...state.passengers },
      });
    }

    case type.FETCH_PASSENGER_FAIL: {
      state.passengers[action.flightLegId].isLoadingPassenger = false;
      return Object.assign({}, state, {
        isChanged: state.isChanged - 1,
        isLoadingPassenger: false,
        passengers: state.passengers,
      });
    }

    case type.UPDATE_PASSENGER: {
      const { legId, passengers } = action.data;
      let statePassengers = { ...state.passengers };
      // update active leg's passengers list
      statePassengers[legId].passengers = passengers;
      // get announce passengers
      const leg = store.getState().itineraryDetails.legs[legId];
      // format passports
      statePassengers[legId].passengers = sortPassengers(setLegPassport(statePassengers[legId].passengers, leg));
      statePassengers[legId].announcedPax = getAnnouncedPax(leg, statePassengers[legId].passengers);
      return Object.assign({}, state, {
        isChanged: state.isChanged - 1,
        passengers: statePassengers,
      });
    }

    case type.STARTED_UPDATING_PASSENGER: {
      return Object.assign({}, state, {
        updatePassenger: true,
      });
    }

    case type.FINISHED_UPDATING_PASSENGER: {
      return Object.assign({}, state, {
        updatePassenger: false,
      });
    }
    default:
      return state;
  }
}
