import moment from "moment";

//  DATE-FNS UTILITIES
export const dateFnsFormats = {
  YYYY_MM_DD: "yyyy-MM-dd",
  DDMMMYYYY: "dd MMM yyyy",
  YYYY_MM_DD_WITH_TIME: "yyyy-MM-ddTHH:mm:ss.SSSXX"
}

//  LEGACY DATE HANDLING FOR MOMENT JS BELOW THIS POINT
export const dateFormats = {
  YYYY_MM_DD: "YYYY-MM-DD",
  DDMMMYYYY: "DD MMM YYYY",
  YYYY_MM_DD_WITH_TIME: "YYYY-MM-DDTHH:mm:ss.sssZZ",
  MMDDYYYY: "MM/DD/YYYY",
  MM_DD_YYYY_HHmm: "MM/DD/YYYY HH:mm",
  YYYY_MM_DD_HHmm: "YYYY-MM-DD HH:mm",
  YYYY_MM_DD_PAX: "YYYY-MM-DDTHH:mm:ssZ",
  YYYY_MM_DD_HHmmss: "YYYY-MM-DDTHH:mm:ss",
};

const INVALID_DATE = 'Invalid Date';
const DATE_WITHOUT_UTC = 'YYYY-MM-DDT[00:00:00Z]';

export const formatDate = (date, format) => date.format(format);

export function convertToZulu(momentObj) {
  if (!momentObj || isNaN(momentObj)) {
    return '';
  }
  return moment.isMoment(momentObj) ? moment(`${momentObj.format('YYYY-MM-DDTHH:mm:ss')}Z`) :
  momentObj;
}

// export const isDateBetween = (from, fromDateFormat, to, toDateFormat) => {
//   const currentDate = moment().format(dateFormats.YYYY_MM_DD);
//   const validFromDate = moment(from, fromDateFormat).format(
//     dateFormats.YYYY_MM_DD,
//   );
//   const validToDate = moment(to, toDateFormat).format(dateFormats.YYYY_MM_DD);
//   const isBetween = moment(currentDate).isBetween(
//     validFromDate,
//     validToDate,
//     null,
//     "[]"
//   );
//   return isBetween;
// };

export function getDateWithoutTimeZone(date) {
  let dateFormated = date;
  if (!(dateFormated instanceof moment)) {
    dateFormated = moment(date, dateFormats.DDMMMYYYY);
    if (!moment(dateFormated).isValid()) {
      return date;
    }
  }
  return dateFormated.format(DATE_WITHOUT_UTC);
}

export const convertToDoubleDigits = (time) => {
  if(+time < 10)
    return  `0${time}`;
  return time;
};

export function closest(el1, selector) {
  let matchesFn;
  let el = el1;
  // find vendor prefix
  ['matches',
    'webkitMatchesSelector',
	'mozMatchesSelector',
	'msMatchesSelector',
	'oMatchesSelector'].some((fn) => {
  if (typeof document.body[fn] === 'function') {
    matchesFn = fn;
    return true;
  }
  return false;
});
  let parent;
  // traverse parents
  while (el) {
    parent = el.parentElement;
    if (parent && parent[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }
  return null;
}
/**
 * Returns difference between current and provided momentobj
 * @param {string} date
 * @returns {moment}
 **/
export function dateDifference(date) {
  const dateMoment = moment(date, 'DD MMM YYYY', true);
  if (dateMoment._d.toString() === INVALID_DATE) {  // eslint-disable-line no-underscore-dangle
    return '';
  }
  return moment(moment.duration(moment().diff(moment(date))));
}
export function isValidDateInFuture(date) {
  if (date === '') {
    return false;
  }
  const momentDifference = dateDifference(date);
  if (momentDifference === '') {
    return true;
  }
  return momentDifference && momentDifference._i // eslint-disable-line no-underscore-dangle
      && momentDifference._i._milliseconds < 0; // eslint-disable-line no-underscore-dangle
}
