import moment from 'moment';
import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import MessagePopUpNew from '../../../src/components/shared/MessagePopUpNew';
import { dateFormats } from '../../utils/dateUtils';
import { formatArrivalTime, formatDepartTime } from '../PlanAFlight/ThankyouScreen';
import Helpers from '../../utils/Helpers';
import { numberWithCommas } from '../../utils/parserUtils';
import { quoteQueryEmail, quoteQueryEmailSubject } from 'constants/PAFConstants';
import { RAF_PAGE_URL, QUOTE_SUMMARY_PAGE_URL } from '../../configs/constants';
import OffersMap from '../OfferMap';
import { saveToLocal } from '../../utils/cache';
import { QUOTE_OBJECT } from '../../constants/localStorageKeys';
import { getAircraftByField } from '../../utils/aircraftUtils';
import { Link, Element } from 'react-scroll';
const glex = "/legacy/static/img/icons/GLEX-icon.svg";

class ExpiredQuotes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      quotes: true,
      loadMore: false,
      showMap: {},
    };
  }

  componentDidMount() {
    const { fetchQuotes } = this.props;
    const { pageNumber } = this.state;
    fetchQuotes('EXPIRED', pageNumber);
  }

  componentWillReceiveProps(nextProps) {
    const { quotes, isQuoteLoading, error, airportError, isQuoteLoadMoreLoading } = nextProps;
    if (!isQuoteLoading && (this.props.isQuoteLoading !== isQuoteLoading)) {
      if (!Object.keys(quotes).length) {
        this.setState({ quotes: false })
      } else if (Object.keys(quotes).length < 10) {
        this.setState({ loadMore: false })
        this.fetchAirports(quotes);
      } else {
        this.setState({ loadMore: true })
        this.fetchAirports(quotes);
      }
    }
    if (error || airportError) {
      this.messagePopup.show();
    }
    if (!isQuoteLoadMoreLoading && (this.props.isQuoteLoadMoreLoading !== isQuoteLoadMoreLoading)) {
      if (Object.keys(quotes).length === Object.keys(this.props.quotes).length) {
        this.setState({ loadMore: false })
      }
      else {
        this.fetchAirports(quotes);
      }
    }
  }

  fetchAirports = (quotes) => {
    const ids = [];
    Object.keys(quotes).forEach(quoteId => {
      const quote = quotes[quoteId];
      const { legs } = quote;
      legs.forEach(leg => {
        ids.push(leg.departureAirportId);
        ids.push(leg.arrivalAirportId);
      })
    })
    if (ids.length)
      this.props.getAirportById([...new Set(ids)]);
  }

  loadMore = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1 }, () => {
      this.props.fetchQuotes('EXPIRED', this.state.pageNumber);
    });
  }

  handleEmailUs = (id) => {
    const emailUsWindow =
      window.open(`mailto:${quoteQueryEmail}?Subject=${quoteQueryEmailSubject} ${id}`);
    setTimeout(() => {
      emailUsWindow.close();
    }, 3000);
  }

  showMap = (id) => {
    const map = { ...this.state.showMap }
    map[id] = true;
    this.setState({
      showMap: map
    });
  }

  closeMap = (id) => {
    const map = { ...this.state.showMap }
    map[id] = false;
    this.setState({
      showMap: map
    });
  }

  requote = (quoteId) => {
    const URL = RAF_PAGE_URL + `?quoteId=${quoteId}&requote`;
    window.open(URL, '_self');
  }

  populateLegs = (legs) => {
    const { visitedAirportsFinder, aircraftsList } = this.props
    legs.forEach(leg => {
      leg.departureAirport = visitedAirportsFinder[leg.departureAirportId];
      leg.arrivalAirport = visitedAirportsFinder[leg.arrivalAirportId];
      leg.selectedAircraft = getAircraftByField(leg.aircraftId, aircraftsList);
      leg.arrivalTime = leg.arrivalAirportTimeLocal;
      leg.departTime = leg.departureAirportTimeLocal;
      leg.passengersCount = leg.passengers;
    });
    return legs;
  }

  sortQuotes = (quotes) => {
    const quoteArray = Object.values(quotes).sort(function (a, b) {
      const _a = a.requestedDate.split(" ");
      const _b = b.requestedDate.split(" ");
      const formattedDateA = moment(`${moment(_a[0], "DD-MM-YYYY").format('YYYY-MM-DD')}T${_a[1]}`);
      const formattedDateB = moment(`${moment(_b[0], "DD-MM-YYYY").format('YYYY-MM-DD')}T${_b[1]}`)
      return moment(formattedDateA).diff(moment(formattedDateB));
    }).reverse();
    return quoteArray.map(_quote => (
      this.renderQuoteList(_quote)
    ));
  }

  renderQuoteList = (quote) => {
    const { visitedAirportsFinder } = this.props;
    const { showMap } = this.state;
    const { legs } = quote;
    const firstLeg = legs[0];
    const lastLeg = legs[legs.length - 1];
    const formattedDate = moment(firstLeg.departureAirportTimeLocal.split('T')[0]).format(dateFormats.DDMMMYYYY);
    const departureAirport = visitedAirportsFinder[firstLeg.departureAirportId];
    const arrivalAirport = visitedAirportsFinder[lastLeg.arrivalAirportId];
    const time = legs.reduce((sum, element) => sum + Number(element.duration), 0);
    const arrivalTime = formatArrivalTime(lastLeg.departureAirportTimeLocal, lastLeg.arrivalAirportTimeLocal);
    const departTime = formatDepartTime(firstLeg.departureAirportTimeLocal);
    const isReturn = legs.length === 2 && firstLeg.departureAirportId === lastLeg.arrivalAirportId;
    const isMultiLeg = legs.length > 1 && isReturn === false;
    const formattedTime = `${Math.floor(time / 60)}h ${Helpers.roundTo5Minutes(
      Math.floor(time % 60)
    )}m`;
    const { requestedDate, quotedDate, expiryDate, currency, amount, id } = quote;
    const formattedRequestedDate = moment(moment(requestedDate.split(' ')[0], "DD-MM-YYYY")).format(dateFormats.DDMMMYYYY)
    const formattedQuotedDate = moment(moment(quotedDate.split(' ')[0], "DD-MM-YYYY")).format(dateFormats.DDMMMYYYY)
    const expireDiff = Math.floor(moment.duration(moment(expiryDate)
      .diff(moment())).asDays()) + 1;
    let expire = expireDiff > 0 ? `in ${expireDiff} Days` : 'Today';
    const passengersCount = firstLeg.passengers;
    const aircraftName = firstLeg.aircraftTypeName;
    const parsedAmount = numberWithCommas(amount);
    let iconClass = "vistajet_departure_center";
    if (isReturn) {
      iconClass = "vistajet_departure_center vistajet_return_icon";
    }
    else if (isMultiLeg) {
      iconClass = "vistajet_departure_center vistajet_multicities_icon";
    }

    return (<div class="vistajet_quote_card vistajet_active_quotes_wrapper">

      <div id={`expired_quote_card_${id}`} className="vistajet_active_quotes uk-grid-collapse" data-uk-grid data-uk-height-match="target: > div > .uk-card">
        <div className="uk-width-2-3@s">
          <div className="vistajet_bs_card uk-card">
            <div className="vistajet_bs_body">
              <div class="vistajet_ref_column">
                <div class="uk-clearfix">
                  <div class="uk-float-right vistajet_subject_text">
                    <span>Subject to availability at the time of request</span>
                  </div>
                  <div class="uk-float-left">
                    <div class="vistajet_date_column">
                      <span class="vistajet_date_icon">{formattedDate}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vistajet_departure_column">
                <div class="uk-flex">
                  <div class="uk-width-2-5">
                    {departureAirport ?
                      <div class="vistajet_departure_left">
                        {departureAirport.city}
                        <small>{departureAirport.name}</small>
                      </div> :
                      <div className="vistajet_spinner">
                        <SpinnerLoader />
                      </div>}
                  </div>
                  <div class="uk-width-1-5 uk-text-center">
                    <div class={iconClass}>
                      <span>{formattedTime}</span>
                    </div>
                  </div>
                  <div class="uk-width-2-5 uk-text-right">
                    {arrivalAirport ?
                      <div class="vistajet_departure_right">
                        {arrivalAirport.city}
                        <small>{arrivalAirport.name}</small>
                      </div> :
                      <div className="vistajet_spinner">
                        <SpinnerLoader />
                      </div>}
                  </div>
                </div>
              </div>
              <div class="vistajet_eta_column">
                <div class="uk-clearfix">
                  <div class="uk-float-right">
                    {arrivalTime}
                  </div>
                  <div class="uk-float-left">
                    {departTime}
                  </div>
                </div>
              </div>
              <div class="vistajet_cp_column">
                <div class="uk-clearfix">
                  {legs.length === 1 && <div>
                    <div class="uk-float-right vistajet_passengers_column">
                      <span>{passengersCount}</span>
                    </div>
                    <div class="uk-float-left vistajet_aircraft_column">
                      <img src={glex} width="90" height="90" alt="" />
                      <small className="uk-text-middle">{aircraftName}</small>
                    </div>
                  </div>}
                </div>
              </div>

              <div className="uk-grid vistajet_edit_email_button_row" data-uk-grid>
                <div className="uk-width-expand@s vistajet_edit_email_button_wrapper">
                  <button className="vistajet_button_default" onClick={() => this.handleEmailUs(id)}> Email Us </button>
                </div>
                {!showMap[id] &&
                  <div className="vistajet_map_button_wrapper">
                    <Link to={`map-${id}`} spy={true} smooth={true} duration={1000}>
                      <button onClick={() => this.showMap(id)} className="vistajet_link_text_button"> View Map </button>
                    </Link>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-3@s">
          <div class="vistajet_details_column uk-card">
            <div class="vistajet_requested"><strong>Ref#{id}</strong></div>
            <div class="vistajet_requested">Requested: <span>{formattedRequestedDate}</span></div>
            <div class="vistajet_requested">Quoted: <span>{formattedQuotedDate}</span></div>
            <div class="vistajet_usd_column">{currency} {parsedAmount}</div>
            <button
              className="vistajet_button_default vistajet_button_primary vistajet_by_quote_button"
              onClick={() => this.requote(id)}
              disabled={!departureAirport || !arrivalAirport}
            >
              Re-Quote
          </button>
          </div>
        </div>
      </div>

      <Element id={`map-${id}`}>
        {showMap[id] &&
          <div id="vistajet_offer_map_default" className="vistajet_offer_map">
            <Link to={`expired_quote_card_${id}`} spy={true} smooth={true} offset={-10} duration={1000} >
              <button onClick={() => this.closeMap(id)} className="vistajet_close_map_button">
                <img src="/legacy/static/img/icons/vistajet_close_icon.svg" />
              </button>
            </Link>
            <OffersMap
              legs={this.populateLegs(legs)}
              isGoogleMapsApiLoaded={this.props.isGoogleMapsApiLoaded}
              onGoogleMapsApiLoaded={this.props.onGoogleMapsApiLoaded}
            />
          </div>
        }
      </Element>

    </div>);
  }

  onScroll(id) {
    const elementToScroll = document.getElementById(id);
    if (elementToScroll) elementToScroll.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { isQuoteLoading, quotes, isQuoteLoadMoreLoading, error, airportError } = this.props;
    const { loadMore } = this.state;
    return (
      <div class="uk-container">
        <div class="uk-flex uk-grid-small uk-margin-small-top" uk-grid>
          <div class="uk-width-1-1">
            {isQuoteLoading
              ? (<div className="vistajet_quote_card vistajet_spinner">
                <SpinnerLoader />
              </div>)
              : (<div>
                {Object.keys(quotes).length ? this.sortQuotes(quotes)
                  : (<div className="vistajet_quote_card">
                    <div className="uk-width-1-1 vistajet_empty_state">
                      <p className="vistajet_dark_text">You have no expired quotes.</p>
                    </div>
                  </div>)}
                <div className="uk-width-1-1 uk-text-center uk-margin-small">
                  {isQuoteLoadMoreLoading ? <SpinnerLoader />
                    : loadMore &&
                    <button onClick={this.loadMore} className="uk-button vistajet_button_default"> Load More </button>
                  }
                </div>
              </div>)}
          </div>
        </div>
        <MessagePopUpNew
          ref={(messagePopup) => { this.messagePopup = messagePopup; }}
          content={error || airportError}
        />
      </div>
    );
  }
}

export default ExpiredQuotes