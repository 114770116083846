import { 
  MembershipAccountStatementSource,
 } from '../sources/DirectAccountBalanceSource';
import * as types from '../actionTypes';

export const MembershipAccountStatementInit = (isLoadMore) => ({
  type: types.MEMBERSHIP_ACCOUNT_STATEMENT_INIT,
  isLoadMore,
});

export const MembershipAccountStatementSuccess = (data, isLoadMore) => ({
  type: types.MEMBERSHIP_ACCOUNT_STATEMENT_SUCCESS,
  data,
  isLoadMore,
});

export const MembershipAccountStatementFail = data => ({
  type: types.MEMBERSHIP_ACCOUNT_STATEMENT_FAIL,
  data,
});

export const MembershipAccountStatement = (token, currentLength, isLoadMore) =>
  MembershipAccountStatementSource(token, currentLength, isLoadMore);
