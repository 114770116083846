import { AIRCRAFT_URLS } from 'configs/constants';

const {
  LINK_TO_CHALLENGER_350,
  LINK_TO_CHALLENGER_605,
  LINK_TO_CHALLENGER_850,
  LINK_TO_GLOBAL_5000,
  LINK_TO_GLOBAL_XRS_6000,
  LINK_TO_GLOBAL_XRS_7500,
} = AIRCRAFT_URLS;

export const aircraftIds = [7, 16, 6, 5, 10];

export const AIRCRAFT_IDS = {
  CHALLENGER_350: 10,
  CHALLENGER_605: 5,
  CHALLENGER_850: 6,
  GLOBAL_5000: 16,
  GLOBAL_6000: 7,
};

export const AIRCRAFT_DETAILS = {
  1718: {
    id: 1718,
    aircraftName: 'Global 7500',
    flyingHours: '16:00',
    range: '7,700nm | 14,260km',
    maximumSpeed: '593mph | 955km/h',
    passengerCapacity: '14',
    passengerCapacitySleeping: '8',
    imgUrl: require('images/jet/7500/1.svg'),
    icon: '/legacy/Static/img/icons/GL7T-icon.svg',
    pageUrl: LINK_TO_GLOBAL_XRS_7500,
    code: 'GL7T',
    aircraftTypeName: 'GL7T',
  },  
  7: {
    id: 7,
    aircraftName: 'Global 6000',
    flyingHours: '13:00',
    range: '5,998nm | 11,110km',
    maximumSpeed: '590mph | 950km/h',
    passengerCapacity: '14',
    passengerCapacitySleeping: '7',
    imgUrl: require('images/jet/6000/1.svg'),
    //imgUrl: require('images/jet/350/1_small.jpg'),
    icon: '/legacy/Static/img/icons/GLEX-icon.svg',
    pageUrl: LINK_TO_GLOBAL_XRS_6000,
    code: 'GLEX',
    aircraftTypeName: 'GLEX',
  },
  16: {
    id: 16,
    aircraftName: 'Global 5000',
    flyingHours: '11:00',
    range: '5,200nm | 9,630km',
    maximumSpeed: '590mph | 950km/h',
    passengerCapacity: '13',
    passengerCapacitySleeping: '7',
    //imgUrl: require('images/jet/605/1.svg'),
    imgUrl: require('images/jet/5000/1.svg'),
    icon: '/legacy/Static/img/icons/GL5T-icon.svg',
    pageUrl: LINK_TO_GLOBAL_5000,
    code: 'GL5T',
    aircraftTypeName: 'GL5T',
  },
  6: {
    id: 6,
    aircraftName: 'Challenger 850',
    flyingHours: '6:30',
    range: '2,807nm | 5,200km',
    maximumSpeed: '528mph | 850km/h',
    passengerCapacity: '14',
    passengerCapacitySleeping: '7',
    imgUrl: require('images/jet/850/1.svg'),
    icon: '/legacy/Static/img/icons/CL850-icon.svg',
    pageUrl: LINK_TO_CHALLENGER_850,
    code: 'CL850',
    aircraftTypeName: 'CL850',
  },
  5: {
    id: 5,
    aircraftName: 'Challenger 605',
    flyingHours: '8:00',
    range: '3,995nm | 7,400km',
    maximumSpeed: ' 541mph | 870km/h',
    passengerCapacity: '12',
    passengerCapacitySleeping: '5',
    imgUrl: require('images/jet/605/1.svg'),
    //imgUrl: require('images/jet/5000/1.svg'),
    icon: '/legacy/Static/img/icons/CL605-icon.svg',
    pageUrl: LINK_TO_CHALLENGER_605,
    code: 'CL605',
    aircraftTypeName: 'CL605',
  },
  10: {
    id: 10,
    aircraftName: 'Challenger 350',
    flyingHours: '7:15',
    range: '3,200nm | 5,926km',
    maximumSpeed: '541mph | 870km/h',
    passengerCapacity: '9',
    passengerCapacitySleeping: '3',
    //imgUrl: require('images/jet/6000/1.svg'),
    imgUrl: require('images/jet/350/1.svg'),
    icon: '/legacy/Static/img/icons/CL350-icon.svg',
    pageUrl: LINK_TO_CHALLENGER_350,
    code: 'Challenger 350',
    aircraftTypeName: 'CL350',
  },
};

export const VJ_AIRCRAFTS = {
  GLOBAL_6000: {
    title: "Global 6000",
    rate: 17230,
    range: 5998,
    speed: 590,
    cabinlength: 48.4,
    cabinwidth: 8.17,
    cabinheight: 6.29,
    baggage: 195,
    pax: "15 passengers",
    image: require('images/jet/6000/1.svg'),
    aircrafturl: LINK_TO_GLOBAL_XRS_6000
  },
  GLOBAL_5000: {
    title: "Global 5000",
    rate: 17230,
    range: 5200,
    speed: 590,
    cabinlength: 45.7,
    cabinwidth: 8.17,
    cabinheight: 6.29,
    baggage: 195,
    pax: "15 passengers",
    image: require('images/jet/5000/1.svg'),
    aircrafturl: LINK_TO_GLOBAL_5000
  },
  CHALLENGER_850: {
    title: "Challenger 850",
    rate: 14730,
    range: 2807,
    speed: 528,
    cabinlength: 48.42,
    cabinwidth: 8.17,
    cabinheight: 6.08,
    baggage: 202,
    pax: "14 passengers",
    image: require('images/jet/850/1.svg'),
    aircrafturl: LINK_TO_CHALLENGER_850
  },
  CHALLENGER_605: {
    title: "Challenger 605",
    rate: 13730,
    range: 3995,
    speed: 541,
    cabinlength: 28.3,
    cabinwidth: 8.2,
    cabinheight: 6.2,
    baggage: 114,
    pax: "12 passengers",
    image: require('images/jet/605/1.svg'),
    aircrafturl: LINK_TO_CHALLENGER_605
  },
  CHALLENGER_350: {
    title: "Challenger 350",
    rate: 12265,
    range: 3200,
    speed: 541,
    cabinlength: 28.6,
    cabinwidth: 7.2,
    cabinheight: 6.1,
    baggage: 106,
    pax: "9 passengers",
    image: require('images/jet/350/1.svg'),
    aircrafturl: LINK_TO_CHALLENGER_350
  },
};
