import React, { Component } from 'react';
import ModalWithHeaderFooter from '../shared/ModalWithHeaderFooter';
import ConfirmationPopUp from '../shared/ConfirmationPopUp';
import validateForgotPasswordForm from '../../utils/Validators/ForgotPasswordValidator';
import MessagePopUp from '../shared/MessagePopUp';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      popupMessage: '',
      stack: '',
    };
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isForgotEmailSent && nextProps.isForgotEmailSent) {
      window.UIkit.modal('#forgotConfirmationModal').show();
    }
    if (
      nextProps.user.forgotPasswordErrors
    ) {
      this.setState({
        popupMessage: nextProps.user.forgotPasswordErrors.message,
      }, () => {
        window.UIkit.modal('#ErrorPopUp').show();
      });
      nextProps.clearForgotErrorMessage();
    }
    if (nextProps.user.resentForgotPassword && !nextProps.isSendingForgotEmail) {
      this.setState({
        popupMessage: 'The email has been sent, thank you!',
        stack: 'true',
      });
      window.UIkit.modal('#ErrorPopUp').show();
      nextProps.clearForgotPasswordResentMessageModal();
    }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;
    const res = validateForgotPasswordForm({
      email: email.toLowerCase(),
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      const payload = {
        email: email.toLowerCase(),
      };
      this.props.forgotAction(payload);
    }
  };

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  renderForm = () => {
    const {
      email,
      errors,
    } = this.state;
    const { isSendingForgotEmail } = this.props;
    const sendingEmailButtonText = isSendingForgotEmail
      ? 'Loading...'
      : 'Send Email';
    return (
      <div className="vistajet_primary_form uk-margin-medium-bottom">
        <form>
          <div className="vistajet_input_wrapper">
            <input
              id="fp-email"
              type="text"
              name="email"
              onChange={this.handleEmailChange}
              onFocus={this.removeErrors}
              value={email}
              autoComplete="email"
            />
            <label htmlFor="fp-email">
              Email address
            </label>
            {
              errors.errors && errors.errors.email && (
                <p>
                  {errors.errors.email}
                </p>
              )
            }
          </div>

          <p className="vistajet_link_text uk-text-center uk-margin-small-top">
            Go back to <a href={window.epiSettings.loginUrl}>login</a>
          </p>
          <div className="vistajet_modal_footer fix">
            <button
              className="uk-button uk-button-default uk-width-1-1 uk-text-center"
              disabled={isSendingForgotEmail}
              onClick={this.handleSubmit}>
              {sendingEmailButtonText}
            </button>
          </div>
        </form>
        {/* (isSendingForgotEmail)
        && (
          <div className="vistajet_offer_spinner">
            <SpinnerLoader />
          </div>
        )
        */
        }
      </div>
    );
  };

  resendConfirmationCode = () => {
    const { email } = this.state;
    const res = validateForgotPasswordForm({
      email,
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      const payload = {
        email,
        isResending: true,
      };
      this.props.forgotAction(payload);
    }
  };

  render() {
    const modalId = 'ForgotPasswordModal';
    const { isSendingForgotEmail } = this.props;
    return (
      <div id="container--forgotten-password">
        <ModalWithHeaderFooter
          id={modalId}
          container="#container--forgotten-password"
          headerContent="Forgot Password"
          subHeadingInHeader="Enter your email to receive a new password"
          content={this.renderForm()}
          isSendingForgotEmail={isSendingForgotEmail}
        />
        <ConfirmationPopUp
          title="Email Sent!"
          id="forgotConfirmationModal"
          leftButtonContent="Resend Email"
          onLeftButtonClick={this.resendConfirmationCode}
          rightButtonContent="Login"
          onRightButtonClick={() => window.location.replace(window.epiSettings.loginUrl)}
          content="Your Password has been reset, please check your email."
          isSendingForgotEmail={isSendingForgotEmail}
        />
        <MessagePopUp
          id="ErrorPopUp"
          stack={this.state.stack}
          content={this.state.popupMessage}
        />
      </div>
    );
  }
}
