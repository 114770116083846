import {
  login,
  forgotPassword,
  logout,
  changePassword,
  updateUserProfileData,
  saveBillingAddress,
  getBillingAddressSource,
} from '../sources/UserSource';
import * as types from '../actionTypes';

export const loginInit = () => ({
  type: types.LOGIN_INIT,
});

export const loginSuccess = data => ({
  type: types.LOGIN_SUCCESS,
  data,
});

export const loginNotVerifiedSuccess = data => ({
  type: types.LOGIN_NOT_VERIFIED_SUCCESS,
  data,
});

export const clearNotVerifiedUser = data => ({
  type: types.CLEAR_NOT_VERIFIED_USER,
  data,
});

export const loginFailure = data => ({
  type: types.LOGIN_FAILURE,
  data,
});


export const forgotInit = () => ({
  type: types.FORGOT_INIT,
});

export const forgotSuccess = data => ({
  type: types.FORGOT_SUCCESS,
  data,
});

export const forgotFailure = data => ({
  type: types.FORGOT_FAILURE,
  data,
});

export const forgotAction = payload => forgotPassword(payload);

export const changePasswordInit = () => ({
  type: types.CHANGE_PASSWORD_INIT,
});

export const changePasswordSuccess = data => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
  data,
});

export const updateUserCreateCompany = data => ({
  type: types.UPDATE_USER_CREATE_COMPANY,
  data,
});

export const changePasswordFailure = data => ({
  type: types.CHANGE_PASSWORD_FAILURE,
  data,
});

export const changePasswordRequired = data => ({
  type: types.CHANGE_PASSWORD_REQUIRED,
  data,
});

export const clearForgotErrorMessage = () => ({
  type: types.CLEAR_FORGOT_ERROR_MESSAGE,
});

export const clearForgotPasswordResentMessageModal = () => ({
  type: types.CLEAR_FORGOT_PASSWORD_RESENT_EMAIL_MESSAGE,
});

export const changePasswordAction = payload => changePassword(payload);

export const logoutInit = () => ({
  type: types.LOGOUT_INIT,
});

export const logoutSuccess = data => ({
  type: types.LOGOUT_SUCCESS,
  data,
});

export const logoutFail = data => ({
  type: types.LOGOUT_FAIL,
  data,
});

export const resendForgotEmail = data => ({
  type: types.RESEND_FORGOT_EMAIL,
  data,
});
export const updateUserReducer = data => ({
  type: types.UPDATE_USER_REDUCER,
  data,
});

// Update user profile actions
export const updateUserProfileDataInit = () => ({
  type: types.UPDATE_USER_PROFILE_INIT,
});

export const updateUserProfileDataSuccess = data => ({
  type: types.UPDATE_USER_PROFILE_SUCCESS,
  data,
});

export const updateUserProfileDataFail = data => ({
  type: types.UPDATE_USER_PROFILE_FAILURE,
  data,
});

export const saveBillingAddressInit = () => ({
  type: types.SAVE_BILLING_ADDRESS_INIT,
});

export const saveBillingAddressSuccess = (data) => ({
  type: types.SAVE_BILLING_ADDRESS_SUCCESS,
  data,
});

export const saveBillingAddressFailure = data => ({
  type: types.SAVE_BILLING_ADDRESS_FAILURE,
  data,
});

export const getBillingAddressInit = () => ({
  type: types.GET_BILLING_ADDRESS_INIT,
});

export const getBillingAddressSuccess = (data) => ({
  type: types.GET_BILLING_ADDRESS_SUCCESS,
  data,
});

export const getBillingAddressFailure = data => ({
  type: types.GET_BILLING_ADDRESS_FAILURE,
  data,
});

export const saveBillingAddressAction = (address) => saveBillingAddress(address);

export const loginAction = (email, password) => login(email, password);

export const logoutAction = token => logout(token);

export const updateUserProfileAction = (
  payload,
  user,
  passports,
  flightLegId,
  paxOperationId,
) => updateUserProfileData(payload, user, passports, flightLegId, paxOperationId);
// export const registerAction = (userToRegister) => register(userToRegister);
export const getBillingAddress = () => getBillingAddressSource();
