import axios from 'axios';
import normalizeAccountStatus from '../../normalizers/AccountStatusNormalizer';
import { // eslint-disable-line
  getAccountStatusInit,
  getAccountStatusSuccess,
  getAccountStatusFail,
} from '../actions/AccountStatusActions';
import {
  logoutAction,
} from '../actions';

import {
  REGISTER_URL,
  CONTEXT,
  APP_SOURCE,
  ACCOUNT_MEMBERSHIP_STATUS,
} from '../../configs/constants';

// import {
//   HTTP_HEADERS_JSON,
// } from '../constants/ApiConstants';

import {
  handleServerErrors,
  parseString,
} from '../../utils/sourceUtils';

import {
  guaranteeArray,
} from '../../utils/arrayUtils';
import { saveToLocal, existInLocal, getFromLocal } from '../../utils/cache';

const accountStatusInProgress = {};

const getAccountStatusSource = (token = 'undefined') => (dispatch) => {
  const uniqueKey = `${token}-${CONTEXT}`;
  if (accountStatusInProgress[uniqueKey]) {
    return accountStatusInProgress[uniqueKey];
  }

  const getAccountStatusPromise = new Promise((resolve, reject) => {
    const url = ACCOUNT_MEMBERSHIP_STATUS
      .replace('{context}', CONTEXT)
      .replace('{token}', token);
    dispatch(getAccountStatusInit());
    axios
      .get(url)
      .then((res) => {
        let user;
        if (existInLocal('user')) {
          user = getFromLocal('user', true, true);
        }
        if (
          res
          && res.data.responseHeaderDto
          && res.data.responseHeaderDto.isError) {
          const errors = handleServerErrors(res.data.responseHeaderDto.statusCode);
          dispatch(getAccountStatusFail(errors));
          user.isAccountDataFetched = false;
          saveToLocal(user, 'user', true, true);
          if (
            user &&
            user.isLoggedIn &&
            res.data.responseHeaderDto.statusCode &&
            res.data.responseHeaderDto.statusCode === 17
          ) {
            dispatch(logoutAction(token));
          }
          reject(errors);
        } else {
          const normalizedAccountStatus = normalizeAccountStatus(
            res.data.accountStatusDto
            && res.data.accountStatusDto.user || {},
          );
          dispatch(getAccountStatusSuccess(normalizedAccountStatus));
          const userObj = { ...user.user, ...normalizedAccountStatus };
          user.user = userObj;
          user.isAccountDataFetched = true;
          saveToLocal(user, 'user', true, true);
          resolve(normalizedAccountStatus);
        }
        delete accountStatusInProgress[uniqueKey];
      })
      .catch((err) => {
        let user;
        if (existInLocal('user')) {
          user = getFromLocal('user', true, true);
        }
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(getAccountStatusFail(errors));
        user.isAccountDataFetched = false;
        saveToLocal(user, 'user', true, true);
        reject(errors);
        delete accountStatusInProgress[uniqueKey];
      });
  });
  accountStatusInProgress[uniqueKey] = getAccountStatusPromise;
  return getAccountStatusPromise;
};

export default getAccountStatusSource;
