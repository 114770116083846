import {
  parseString,
  handleServerErrors,
} from '../../utils/sourceUtils';
import {
  HTTP_HEADERS,
} from '../../constants/ApiConstants';
import {
  CATERING_STATE_URL,
  CATERING_SELECTION_PDF,
  CATERING_PROPOSAL_URL,
  CUSTOMER_SELECTION_URL
} from '../../configs/constants';

import { LEGACY_ERROR_MESSAGES } from '../../constants/ErrorMessageConstants';
import { parseXmlStringToJson, jsonToXml } from '../../utils/parserUtils';
import * as actions from '../actions';
import * as HttpStatus from "http-status-codes";
import axios from 'axios';
import normalizeCateringOrderUtil from '../../normalizers/normalizeCateringOrder';
import normalizeCateringUtil from '../../normalizers/normalizeCatering'
import { changeCateringPropsalInit, changeCateringPropsalSuccess } from '../actions';
import { SOMETHING_UNEXPECTED } from "../../constants/MessageConstants";

const inProgressProposals = {};
const inProgressState = {};

export const getCateringState = (token, leg) => (dispatch) => {
  const flightId = leg.legId;
  const uniqueKey = `${flightId}-${token}`;

  if (inProgressState[uniqueKey]) {
    return inProgressState[uniqueKey];
  }

  const cateringStatePromise = new Promise((resolve, reject) => {
    if (!leg.legId) {
      reject({ text: LEGACY_ERROR_MESSAGES['400'] });
      return;
    }
    const url = CATERING_STATE_URL
      .replace('{token}', token)
      .replace('{flightId}', flightId);

    dispatch(actions.getCateringState(flightId));
    axios
      .get(url)
      .then((res) => {
        delete inProgressState[uniqueKey];
        if (res.status === HttpStatus.OK) {
          parseString(res.data, (parseErr, response) => {
            if (parseErr) {
              dispatch(actions.getCateringStateFail('Something went wrong'))
              reject();
            } else {
              const combineResponse = Object.assign({}, leg, response);
              const responseObj = normalizeCateringUtil(combineResponse, leg);
              delete inProgressState[uniqueKey];
              dispatch(actions.getCateringStateSuccess(responseObj));
              resolve(responseObj);
            }
          });
        }
        else {
          dispatch(actions.getCateringStateFail((handleServerErrors(res.status))));
        }
      })
      .catch(err => {
        dispatch(actions.getCateringStateFail((handleServerErrors(err))));
      });
  });
  inProgressState[uniqueKey] = cateringStatePromise;
  return cateringStatePromise;
}

export const getCateringProposal = (token, flightId) => (dispatch) => {

  if (inProgressProposals[flightId]) {
    return inProgressProposals[flightId];
  }

  const proposalPromise = new Promise((resolve, reject) => {
    if (!flightId) {
      dispatch(actions.getCateringProposalFail('Something went wrong'));
      return;
    }

    const url = CATERING_PROPOSAL_URL.replace('{token}', token).replace('{flightId}', flightId);
    dispatch(actions.getCateringProposalInit(flightId));
    axios
      .get(url)
      .then((res) => {
        delete inProgressProposals[flightId];
        parseString(res.data, (parseErr, response) => {
          if (parseErr) {
            dispatch(actions.getCateringProposalFail(handleServerErrors(parseErr)));
            reject();
          } else {
            const responseObj = normalizeCateringOrderUtil(response);
            delete inProgressProposals[flightId];
            responseObj.legId = flightId;
            dispatch(actions.getCateringProposalSuccess(responseObj));
          }
        });
      }).catch(err => {
        dispatch(actions.getCateringProposalFail(handleServerErrors(err)));
        reject();
      });
  });

  inProgressProposals[flightId] = proposalPromise;
  return proposalPromise;
}

export const changeCateringSelection = (token, flightId, cateringSelection, leg) => (dispatch) => {
  
  return new Promise((resolve, reject) => {
    if (!flightId || !cateringSelection) {
      reject({ text: LEGACY_ERROR_MESSAGES['400'] });
      return;
    }

    if (inProgressProposals[flightId]) {
      delete inProgressProposals[flightId];
    }

    let reqPayload = {
      customerSelections: {
        flightLegId: flightId,
        customerSelectionEntries: {
          customerSelectionEntry: cateringSelection,
        },
      },
    };

    reqPayload = jsonToXml(reqPayload);

    const url = CUSTOMER_SELECTION_URL.replace('{token}', token);
    dispatch(changeCateringPropsalInit(flightId));
    axios
      .put(url, reqPayload, {
        headers: HTTP_HEADERS,
      }).then((res) => {
        if (inProgressProposals[flightId]) {
          delete inProgressProposals[flightId];
        }
        dispatch(changeCateringPropsalSuccess(flightId))
        dispatch(getCateringState(token, leg));
        dispatch(getCateringProposal(token, flightId));
        dispatch(fetchCateringSelectionPDF(token, flightId));
        
        resolve();
      }).catch(err => {
        dispatch(actions.changeCateringPropsalFail(handleServerErrors(err)));
        reject();
      });
  });
}

export const fetchCateringSelectionPDF = (token, params) => dispatch => {
  return new Promise((resolve, reject) => {
    const url = CATERING_SELECTION_PDF.replace('{token}', token)
      .replace('{flightLegId}', params);
      dispatch(actions.getCateringPDFInit(params))
    axios
      .get(url)
      .then((res) => {
        parseString(res.data, (parseErr, res) => {
          if (parseErr) {
            dispatch(actions.getCateringPDFFail({ text: SOMETHING_UNEXPECTED, legId: params } ))
            reject({ text: SOMETHING_UNEXPECTED });
          }else{
            resolve({ fileUrl: res.fileUrlResponse.fileUrl, legId: params });
            dispatch(actions.getCateringPDFSuccess({ fileUrl: res.fileUrlResponse.fileUrl, legId: params }))
          }
        });
      })
      .catch(err => {
        reject(handleServerErrors(err));
        dispatch(actions.getCateringPDFFail({ text: handleServerErrors(err), legId: params }))
      });
  });
}
