import {
  hasVjDirectAccount,
  accountMembershipStatus,
  membershipType,
  accessType,
  accountsAircraftList,
} from '../utils/sourceUtils';
import { capitalize } from '../utils/stringUtils';
import { guaranteeArray } from '../utils/arrayUtils';

export function normalizeUser(user) {
  return {
    ...user,
  };
}

export const passportActiveFilter = passport => passport.isActive;
// export const passportInvalidFilter = passport => passport.isInvalid;

export function normalizePassport(passport) {
  const {
    // identifiers
    id, passportId,

    // expere data
    expiresOnDate, expiry,

    // flags
    isActive,
    isDefault,
    isInvalid,
    isOnHands,

    // country and issued by country iso
    isoPassportCountry,
    isoIssuedByCountry, isoPassportIssuedByCountry,

    // country and issued by country labels
    passportCountry,
    issuedByCountry, passportIssuedByCountry,

    // others
    displayName,
    passportNumber,
    ...other
  } = passport;

  return {
    // identifiers
    id: id || passportId,

    // expire data
    expiresOnDate: expiresOnDate || expiry,

    // flags
    isActive,
    isDefault,
    isInvalid,
    isOnHands,

    // country and issued by country iso
    isoPassportCountry: isoPassportCountry || '',
    isoIssuedByCountry: isoIssuedByCountry || isoPassportIssuedByCountry || '',

    // country and issued by country labels
    passportCountry,
    issuedByCountry: issuedByCountry || passportIssuedByCountry || '',

    // others
    displayName: displayName || '',
    passportNumber: `${(passportNumber === 0 || passportNumber) ? passportNumber : ''}`,

    // front-end control flags
    isAdded: false,
    isUpdated: true,
    isDeleted: false,

    ...other,
  };
}

export function normalizePassports(passports) {
  const init = {
    content: {},
    ids: [],
    defaultId: undefined,
  };

  if (passports) {
    return passports.filter(passportActiveFilter).reduce((previous, current) => {
      const prev = previous;
      const passport = normalizePassport(current);

      prev.content[passport.id] = passport;
      prev.ids.push(passport.id);
      if (!prev.defaultId && passport.isDefault) {
        prev.defaultId = passport.id;
      }
      return prev;
    }, init);
  }
  return init;
}

function getSalutation(salutation) {
  const str = salutation.split(' ');
  return str[0];
}

export const normalizePersonalData = (authenticatedUser, passportDataNormalized) => ({
  accountId: authenticatedUser.accountId || [],
  accessType: accessType(authenticatedUser.accounts),
  customerId: authenticatedUser.customerId || [],
  dateOfBirth: authenticatedUser.dateOfBirth || [],
  firstName: authenticatedUser.firstName || [],
  gender: authenticatedUser.gender || [],
  genderId: authenticatedUser.genderId || [],
  id: authenticatedUser.id || [],
  imageURL: authenticatedUser.imageURL ? authenticatedUser.imageURL : [],
  isChangedPassword: authenticatedUser.isChangedPassword,
  lastName: authenticatedUser.lastName || [],
  passports: passportDataNormalized,
  personId: authenticatedUser.personId || [],
  userPreferences: authenticatedUser.userPreferences || [],
  membershipTypeId: membershipType(authenticatedUser.accounts),
  membershipStatus: accountMembershipStatus(authenticatedUser.accounts),
  userVerificationStatusCode: authenticatedUser.verificationStatusCode || [],
  phoneNumber: authenticatedUser.personPhoneNumbers
    && authenticatedUser.personPhoneNumbers.length
    ? authenticatedUser.personPhoneNumbers[0].phoneNumber : '',
  userToken: authenticatedUser.token,
  hasVjDirectAccount: hasVjDirectAccount(authenticatedUser.accounts),
  companyBaseEmail: authenticatedUser.companyBaseEmail || [],
  companyBaseNumber: authenticatedUser.companyBaseNumber || [],
  accounts: authenticatedUser.accounts || [],
  username: authenticatedUser.username || '',
  preferredAircraft: accountsAircraftList(authenticatedUser.accounts),
  salutation: authenticatedUser.salutation
    ? getSalutation(authenticatedUser.salutation)
    : null,
});

export const normalizeBillingAddressResponse = (billingAddresses) => {
  if (billingAddresses && billingAddresses.length) {
    const preferredBillingAddress =
      billingAddresses.find(a => a.isPreferred && a.isPreferred.toUpperCase() === 'TRUE');

    const {
      billingAddress = '',
      city = '',
      state = '',
      countryName = '',
      addressableName = '',
      postCode = '',
      countryCode = '',
    } = preferredBillingAddress || billingAddresses[0];

    return {
      address: billingAddress,
      country: capitalize(countryName),
      postalCode: postCode,
      city, state, addressableName, countryCode,
    };
  }
  return {};
};

export function normalizePassenger(passenger, currentEmail) {
  const {
    firstName,
    lastName,
    gender,
    dateOfBirth,
  } = passenger;

  const passports = normalizePassports(guaranteeArray(passenger.passports));
  const phoneNumbers = guaranteeArray(passenger.phoneNumbers);

  const user = {
    firstName: `${firstName}`,
    lastName: `${lastName}`,
    gender,
    dateOfBirth,
  };

  const emails = guaranteeArray(passenger.emailAddresses);
  const emailObj = emails.find(email =>
    email.email === currentEmail || email.emailAddress === currentEmail) || {};
  user.email = emailObj.email || emailObj.emailAddress;

  // if (picture) {
  //   user.imageURL = picture;
  // }

  return { user, phoneNumbers, passports };
}

export function buildPassportRequest(passport) {
  return {
    id: passport.id,
    isDefault: passport.isDefault,
    isActive: passport.isActive,
    passportNumber: passport.passportNumber,
    isoPassportCountry: passport.isoPassportCountry,
    isoIssuedByCountry: passport.isoIssuedByCountry,
    expiresOnDate: passport.expiresOnDate,
    displayName: passport.displayName,
    isAdded: passport.isAdded,
    isUpdated: passport.isUpdated,
    isDeleted: passport.isDeleted,
  };
}
