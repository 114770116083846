import moment from 'moment';
import { garanteeArray } from './sourceUtils';
import {
  legStatus,
  SINGLE_LEG,
  MULTI_LEG,
  RETURN_LEG,
} from '../constants/ItineraryConstants';
import { jsonToXml } from './parserUtils';
import { getAircraftByAllFields } from './aircraftUtils';

const PAST_FLIGHT_MIN_HOURS_CRITERIA = 3;

export function getItineraryLegDates(firstLeg, lastLeg, item) {
  const dateFormat = 'DD MMM YYYY';
  let dates = `${moment
    .utc(firstLeg.departureAirportTimeLocal)
    .format(dateFormat)}`;
  if (item && item.legs && item.legs.length > 1) {
    dates = `${dates} | ${moment
      .utc(lastLeg.arrivalAirportTimeLocal)
      .format(dateFormat)}`;
  }
  return dates;
}

export function getItineryPathIcon(firstLeg, lastLeg, isMultileg) {
  return !isMultileg ? SINGLE_LEG :
    (firstLeg.departureAirportId === lastLeg.arrivalAirportId
      ? RETURN_LEG : MULTI_LEG)
}

export function populateLegsForMap(item, visitedAirportsFinder) {
  const temp = [];
  if (item && item.legs) {
    item.legs.forEach((itinerary) => {
      const { arrivalAirportId, departureAirportId } = itinerary;
      const arrivalAirport = visitedAirportsFinder[arrivalAirportId];
      const departureAirport = visitedAirportsFinder[departureAirportId];
      const singleLegs = {
        id: '',
        departureAirport: {},
        arrivalAirport: {},
      };

      singleLegs.departureAirport = departureAirport;
      singleLegs.arrivalAirport = arrivalAirport;
      singleLegs.id = itinerary.legId ? itinerary.legId.toString() : itinerary.flightLegExtIdentifier.toString();
      temp.push(singleLegs);
    });
  }
  return temp;
}

export function getLegLocalTime(time) {
  let localTime = '';
  if (time) {
    localTime = moment.utc(time).format('HH:mm');
  }
  return localTime;
}
export function getLegDuration(mins) {
  const h = Math.floor(mins / 60);
  const m = mins % 60;
  return `${h}h ${m}m`;
}

export function normalizeAircraft(aircraft) {
  return {
    images: aircraft.images,

    id: +garanteeArray(aircraft.id)[0],
    manufacturer: garanteeArray(aircraft.manufacturer)[0],
    name: garanteeArray(aircraft.name)[0],
    displayName: garanteeArray(aircraft.displayName)[0],
    code: garanteeArray(aircraft.code)[0],
    range: garanteeArray(aircraft.range)[0],
    speed: garanteeArray(aircraft.speed)[0],
    maxNumberOfPassengers: garanteeArray(aircraft.maxNumberOfPassengers)[0],
    maxNumberOfPassengersSleeping: garanteeArray(
      aircraft.maxNumberOfPassengersSleeping
    )[0],
    maxFlightTime: garanteeArray(aircraft.maxFlightTime)[0],
    turnaroundTimeMinutes: garanteeArray(aircraft.turnaroundTimeMinutes)[0],
    speedFirst30minBlock: garanteeArray(aircraft.speedFirst30minBlock)[0],
    speedSecond30minBlock: garanteeArray(aircraft.speedSecond30minBlock)[0],
    taxiTimeMinutes: garanteeArray(aircraft.taxiTimeMinutes)[0],
    cruisingAltitude: garanteeArray(aircraft.cruisingAltitude)[0],
    maxAltitudeFt: garanteeArray(aircraft.maxAltitudeFt)[0],
    maxAltitudeMetres: garanteeArray(aircraft.maxAltitudeMetres)[0],
    maxRangeKm: garanteeArray(aircraft.maxRangeKm)[0],
    maxRangeNm: garanteeArray(aircraft.maxRangeNm)[0],
    rank: garanteeArray(aircraft.rank)[0],
    lastUpdated: garanteeArray(aircraft.lastUpdated)[0],
    availableOnCustomerApp: garanteeArray(aircraft.availableOnCustomerApp)[0],
    tourURL: garanteeArray(aircraft.tourURL)[0]
  };
}

export function normalizeAirport(airport) {
  return {
    timezones: airport.timezones,

    id: +garanteeArray(airport.id)[0],
    icao: garanteeArray(airport.icao)[0],
    iata: garanteeArray(airport.iata)[0],
    iso3: garanteeArray(airport.iso3)[0],
    name: garanteeArray(airport.name)[0],
    iso2: garanteeArray(airport.iso2)[0],
    city: garanteeArray(airport.city)[0],
    country: garanteeArray(airport.country)[0],
    latitude: garanteeArray(airport.latitude)[0],
    longitude: garanteeArray(airport.longitude)[0],
    itineraryAirportName: garanteeArray(airport.itineraryAirportName)[0],
    airportCount: garanteeArray(airport.airportCount)[0],
    lastUpdatedTime: garanteeArray(airport.lastUpdatedTime)[0]
  };
}

export function normalizeLeg(legs) {
  return legs.map(leg => ({
    aircraft: normalizeAircraft(garanteeArray(leg.aircraft)[0]),
    arrivalAirport: normalizeAirport(garanteeArray(leg.arrivalAirport)[0]),
    departureAirport: normalizeAirport(garanteeArray(leg.departureAirport)[0]),

    duration: leg.duration,
    isEditable: leg.isEditable,
    isPast: leg.isPast,
    uniqueIdentifier: leg.uniqueIdentifier,

    leadPassengerName: garanteeArray(leg.leadPassengerName)[0],
    aircraftTailNumber: garanteeArray(leg.aircraftTailNumber)[0],
    aircraftTypeName: garanteeArray(leg.aircraftTypeName)[0],
    arrivalAirportId: garanteeArray(leg.arrivalAirportId)[0],
    arrivalAirportTimeLocal: garanteeArray(leg.arrivalAirportTimeLocal)[0],
    arrivalAirportTimeUTC: garanteeArray(leg.arrivalAirportTimeUTC)[0],
    defaultDurationMinutes: garanteeArray(leg.defaultDurationMinutes)[0],
    departureAirportId: garanteeArray(leg.departureAirportId)[0],
    departureAirportTimeLocal: garanteeArray(leg.departureAirportTimeLocal)[0],
    departureAirportTimeUTC: garanteeArray(leg.departureAirportTimeUTC)[0],
    legId: garanteeArray(leg.legId)[0],
    legStatus: garanteeArray(leg.legStatus)[0],
    numberOfPassenger: garanteeArray(leg.numberOfPassenger)[0],
    requestedAircraftTypeName: garanteeArray(leg.requestedAircraftTypeName)[0],
    version: garanteeArray(leg.version)[0]
  }));
}

export function normalizeOrder(quote) {
  return {
    arrivalAirport: normalizeAirport(quote.arrivalAirport),
    departureAirport: normalizeAirport(quote.departureAirport),
    arrivalTime: quote.arrivalTime,
    departureTime: quote.departureTime,
    leadPassenger: quote.leadPassenger,
    mobilePreviewCssClass: quote.mobilePreviewCssClass,
    orderIdentifier: quote.orderIdentifier,
    orderKey: quote.orderKey,
    orderStatus: quote.orderStatus,
    legs: normalizeLeg(garanteeArray(quote.legs))
  };
}

export function getLegKey(legId) {
  return isNaN(legId) ? "flightLegExtIdentifier" : "flightLegId";
}
export const getFlightId = (leg) => (
  leg.legId || leg.flightLegExtIdentifier
)
export function isLegId(legId) {
  return !isNaN(legId) && legId !== "";
}

export function isCancelledLeg(leg) {
  return leg.legStatus === legStatus.CANCELLED;
}

export function isPastLeg(leg) {
  const arrivalTimeUTC = moment(leg.arrivalAirportTimeUTC)
    .add(PAST_FLIGHT_MIN_HOURS_CRITERIA, "hours")
    .utc();
  const currentTimeUTC = moment().utc();

  return !isCancelledLeg(leg) && arrivalTimeUTC.isBefore(currentTimeUTC);
}

export function getOrderStatus(legs) {
  return legs[0].legStatus;
}

export function sepratePastUpcomingLegs(
  itineraries,
  currentUpcoming,
  currentPast,
  currentCancelled,
) {
  const pastOrders = [];
  const upcomingOrders = [];
  const cancelledOrders = [];
  itineraries.forEach((item = {}) => {
    const pastLegs = [];
    const upcomingLegs = [];
    const cancelledLegs = [];
    let pastLeadPassenger = "";
    let upcomingLeadPassenger = "";
    let cancelledLeadPassenger = "";
    let orderKey = "";
    let orderIdentifier = "";
    let accountId = "";
    orderIdentifier = item.orderLegId || item.orderExtIdentifier;
    orderKey = item.orderLegId ? "orderId" : "orderExtIdentifier";
    accountId = item.accountId;
    item.legs.leg.sort((a, b) => {
      const compare = moment(b.departureAirportTimeUTC).isAfter(
        a.departureAirportTimeUTC
      );
      return compare ? -1 : 1;
    });
    item.legs.leg.forEach(legObj => {
      const leg = legObj;
      if (isCancelledLeg(leg)) {
        if (!cancelledLeadPassenger) {
          cancelledLeadPassenger = leg.leadPassengerName || "";
        }
        leg.isCancelled = true;
        cancelledLegs.push(leg);
      } else if (isPastLeg(leg)) {
        if (!pastLeadPassenger) {
          pastLeadPassenger = leg.leadPassengerName || "";
        }
        leg.isPast = true;
        pastLegs.push(leg);
      } else {
        // upcoming leg
        if (!upcomingLeadPassenger) {
          upcomingLeadPassenger = leg.leadPassengerName || "";
        }
        leg.isPast = false;
        upcomingLegs.push(leg);
      }
    });
    let orderExist = false;
    if (pastLegs.length > 0) {
      orderExist = (currentPast || []).some(
        order => order.orderIdentifier === orderIdentifier
      );
      if (!orderExist) {
        pastOrders.push({
          orderKey,
          orderIdentifier,
          accountId,
          orderStatus: getOrderStatus(pastLegs),
          legs: pastLegs,
          // departure time of first leg
          departureTime: pastLegs[0].departureAirportTimeLocal,
          departureAirportTimeUTC: pastLegs[0].departureAirportTimeUTC,
          // arrival time of last leg
          arrivalTime: pastLegs[pastLegs.length - 1].arrivalAirportTimeLocal,
          // departure airport of first leg
          departureAirport: pastLegs[0].departureAirport,
          // arrival airport of last leg
          arrivalAirport: pastLegs[pastLegs.length - 1].arrivalAirportId,
          leadPassenger: pastLeadPassenger || "No Lead Passenger",
          mobilePreviewCssClass: "position-absolute hide"
        });
      }
    }
    if (cancelledLegs.length > 0) {
      orderExist = (currentCancelled || []).some(
        order => order.orderIdentifier === orderIdentifier
      );
      if (!orderExist) {
        cancelledOrders.push({
          orderKey,
          orderIdentifier,
          accountId,
          orderStatus: legStatus.CANCELLED,
          legs: cancelledLegs,
          // departure time of first leg
          departureTime: cancelledLegs[0].departureAirportTimeLocal,
          departureAirportTimeUTC: cancelledLegs[0].departureAirportTimeUTC,
          // arrival time of last leg
          arrivalTime:
            cancelledLegs[cancelledLegs.length - 1].arrivalAirportTimeLocal,
          // departure airport of first leg
          departureAirport: cancelledLegs[0].departureAirport,
          // arrival airport of last leg
          arrivalAirport:
            cancelledLegs[cancelledLegs.length - 1].arrivalAirportId,
          leadPassenger: cancelledLeadPassenger || "No Lead Passenger",
          mobilePreviewCssClass: "position-absolute hide"
        });
      }
    }
    if (upcomingLegs.length > 0) {
      orderExist = (currentUpcoming || []).find(
        order => order.orderIdentifier === orderIdentifier
      );
      if (!orderExist) {
        upcomingOrders.push({
          orderKey,
          orderIdentifier,
          accountId,
          orderStatus: getOrderStatus(upcomingLegs),
          legs: upcomingLegs,
          // departure time of first leg
          departureTime: upcomingLegs[0].departureAirportTimeLocal,
          departureAirportTimeUTC: upcomingLegs[0].departureAirportTimeUTC,
          // arrival time of last leg
          arrivalTime:
            upcomingLegs[upcomingLegs.length - 1].arrivalAirportTimeLocal,
          // departure airport of first leg
          departureAirport: upcomingLegs[0].departureAirport,
          // arrival airport of last leg
          arrivalAirport:
            upcomingLegs[upcomingLegs.length - 1].arrivalAirportId,
          leadPassenger: upcomingLeadPassenger || "No Lead Passenger",
          mobilePreviewCssClass: "position-absolute hide"
        });
      } else {
        if (item.isUpdate) {
          orderExist.legs = upcomingLegs;
          orderExist.accountId = accountId;
          orderExist.orderStatus = item.orderStatus;
          orderExist.departureTime = upcomingLegs[0].departureAirportTimeLocal;
          orderExist.departureAirportTimeUTC =
            upcomingLegs[0].departureAirportTimeUTC;
          // arrival time of last leg
          orderExist.arrivalTime =
            upcomingLegs[upcomingLegs.length - 1].arrivalAirportTimeLocal[0];
          // departure airport of first leg
          orderExist.departureAirport = upcomingLegs[0].departureAirport;
          // arrival airport of last leg
          orderExist.arrivalAirport =
            upcomingLegs[upcomingLegs.length - 1].arrivalAirportId;
          orderExist.leadPassenger =
            upcomingLeadPassenger || "No Lead Passenger";
        }
      }
    }
  });
  return { upcomingOrders, pastOrders, cancelledOrders };
}

export function isDialogScreen(path) {
  const paths = ["catering", "passengers"];
  return paths.some(p => path.includes(p));
}

export function sortUpcomingItinerariesLegs(a, b) {
  if (a.departureAirportTimeUTC > b.departureAirportTimeUTC) return 1;
  if (a.departureAirportTimeUTC < b.departureAirportTimeUTC) return -1;
  return 0;
}

export function sortPastItineraries(a, b) {
  const valueA = a.legs[0].arrivalAirportTimeUTC;
  const valueB = b.legs[0].arrivalAirportTimeUTC;
  if (valueA < valueB) return 1;
  if (valueA > valueB) return -1;
  return 0;
}

export function isLegCancelled(status) {
  return status === legStatus.CANCELLED ||
    status === legStatus.CANCELLATION_PENDING;
}

export function flightPayload(userType, leg, orderId, accountId) {
  const legAircraft = getAircraftByAllFields(leg.aircraftTypeName);
  let reqPayLoad = {};
  const account = accountId;
  const flightLegId = leg.legId;
  const activeLeg = leg;
  if (userType.isVJ2 || userType.isAdhoc) {
    reqPayLoad = {
      flightRequestForCustomer: {
        referenceOrderId: orderId,
        account,
        flightLegs: {
          flightLeg: {
            flightLegId,
            departureAirportId: activeLeg.departureAirportId,
            arrivalAirportId: activeLeg.arrivalAirportId,
            numberOfPassenger: activeLeg.numberOfPassenger,
            departureTime: activeLeg.departureAirportTimeUTC,
            arrivalTime: activeLeg.arrivalAirportTimeUTC,
            aircraftType: legAircraft.code,
            requestedAircraftTypeId: legAircraft.id,
            isCancelled: true,
          },
        },
        mode: 'cancel',
      },
    };
  } else if (userType.isFSP) {
    if (activeLeg.legStatus === 'Unconfirmed') {
      reqPayLoad = {
        flightRequestForCustomer: {
          quoteId: orderId || '',
          referenceOrderId: orderId || '',
          account,
          flightLegs: {
            flightLeg: {
              flightLegId: activeLeg.legId || '',
              flightLegExtIdentifier: activeLeg.flightLegExtIdentifier || '',
              departureAirportId: activeLeg.departureAirportId,
              arrivalAirportId: activeLeg.arrivalAirportId,
              numberOfPassenger: activeLeg.numberOfPassenger,
              departureTime: activeLeg.departureAirportTimeUTC,
              arrivalTime: activeLeg.arrivalAirportTimeUTC,
              aircraftType: legAircraft.code,
              requestedAircraftTypeId: legAircraft.id,
              isCancelled: true,
            },
          },
          mode: 'cancel',
        },
      };
    } else if (activeLeg.legStatus === 'Confirmed') {
      reqPayLoad = {
        flightRequestForCustomer: {
          referenceOrderId: orderId,
          account,
          flightLegs: {
            flightLeg: {
              flightLegId,
              departureAirportId: activeLeg.departureAirportId,
              arrivalAirportId: activeLeg.arrivalAirportId,
              numberOfPassenger: activeLeg.numberOfPassenger,
              departureTime: activeLeg.departureAirportTimeUTC,
              arrivalTime: activeLeg.arrivalAirportTimeUTC,
              aircraftType: legAircraft.code,
              requestedAircraftTypeId: legAircraft.id,
              isCancelled: true,
            },
          },
          mode: 'cancel',
        },
      };
    }
  }
  reqPayLoad = jsonToXml(reqPayLoad);
  return reqPayLoad;
}

export function getItineraryStatus(status, flag) {
  if (flag === "pastItineraries") {
    return status === legStatus.CANCELLATION_PENDING
      ? legStatus.CANCELLATION_PENDING
      : legStatus.CONFIRMED;
  } else if (flag === "upcomingItineraries") {
    if (status === legStatus.UNCONFIRMED) return "On Request";
    if (status === legStatus.CANCELLATION_PENDING)
      return legStatus.CANCELLATION_PENDING;
  }
  return flag === "cancelledItineraries" ? status : legStatus.CONFIRMED;
}

export function getItineraryLegStatus(currentLeg, itineraryType) {
  let status = '';
  if (itineraryType === 'cancelledItineraries') {
    status = 'Cancelled';
  }
  else if (itineraryType === 'pastItineraries') {
    if (currentLeg.legStatus !== 'Cancellation Pending') {
      status = 'Confirmed';
    }
    else {
      status = 'Cancellation Pending';
    }
  }
  else if (itineraryType === 'upcomingItineraries') {
    if (currentLeg.legStatus === 'Unconfirmed') {
      status = 'On Request';
    }
    else if (currentLeg.legStatus === 'Landed') {
      status = 'Confirmed';
    }
    else {
      status = currentLeg.legStatus;
    }
  }
  return status;
}

export function seprateDetailPastUpcomingLegs(legs, type) {
  let pastItinerary = [];
  let upcomingItinerary = [];
  let cancelledItinerary = [];
  if (type !== 'cancelledItineraries') {
    legs.forEach(leg => {
      if (isPastLeg(leg) === false && leg.legStatus !== "Cancelled") {
        upcomingItinerary.push(leg);
      }
      else if (isPastLeg(leg) === true && leg.legStatus !== "Cancelled") {
        pastItinerary.push(leg);
      }
    });
    if (type === 'upcomingItineraries') {
      return upcomingItinerary;
    }
    else if (type === 'pastItineraries') {
      return pastItinerary;
    }
    return legs;
  }
  else {
    legs.forEach(leg => {
      if (leg.legStatus === "Cancelled") {
        cancelledItinerary.push(leg);
      }
    })
    return cancelledItinerary;
  }
}