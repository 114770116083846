import axios from "axios";
import {
  FETCH_SINGLE_LEG_FEEDBACK,
  SAVE_LEG_FEEDBACK,
} from "../../configs/constants";
import { USER_REDUCER } from "../../constants/localStorageKeys";
import {
  normalizeFeedback,
  noramlizeSaveFeedbackParams
} from "../../normalizers/customerFeedbackNoramlizer";
import {
  fetchLegFeedbackInit,
  fetchLegFeedbackSuccess,
  fetchLegFeedbackFail,
  saveLegFeedbackInit,
  saveLegFeedbackSuccess,
  saveLegFeedbackFail
} from "../actions";
import { existInLocal, getFromLocal } from "../../utils/cache";
import { handleServerErrors } from "../../utils/sourceUtils";
import {
  INVALID_FEEDBACK_CODE,
  TIMEOUT_CODE,
  BAD_REQUEST
} from "../../constants/ServerCodeConstants";
import status from "../../configs/status";

export const fetchLegFeedbackSource = params => dispatch => {
  dispatch(fetchLegFeedbackInit(params.legId));
  let token = "";
  if (existInLocal(USER_REDUCER)) {
    const { user } = getFromLocal(USER_REDUCER, true, true);
    token = user.userToken;
  }
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    timeout: 120000
  };
  const paramsObj = {
    flightLegIds: [params.legId]
  };
  const url = FETCH_SINGLE_LEG_FEEDBACK.replace("{token}", token);
  axios
    .post(url, paramsObj, options)
    .then(response => {
      const noramalizedFeedback = normalizeFeedback(response.data);
      dispatch(fetchLegFeedbackSuccess(noramalizedFeedback, params.legId));
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(
          fetchLegFeedbackFail(
            handleServerErrors(TIMEOUT_CODE.code),
            params.legId
          )
        );
      } else if (err.code === INVALID_FEEDBACK_CODE.code) {
        dispatch(
          fetchLegFeedbackFail(
            handleServerErrors(INVALID_FEEDBACK_CODE.code),
            params.legId
          )
        );
      } else
        dispatch(
          fetchLegFeedbackFail(
            handleServerErrors(BAD_REQUEST, params.legId),
            params.legId
          )
        );
    });
};
export const saveLegFeedbackSource = params => dispatch => {
  dispatch(saveLegFeedbackInit(params.legId));
  const constructParamsForCall = noramlizeSaveFeedbackParams(params);

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    timeout: 120000
  };
  const url = SAVE_LEG_FEEDBACK.replace("{token}", params.token);
  axios
    .post(url, constructParamsForCall, options)
    .then(response => {
      if (response.status === status.OK) {
        if (response.data.responseHeader.isError) {
          dispatch(saveLegFeedbackFail(params.legId));
        } else {
          const noramalizedFeedback = normalizeFeedback({
            feedbacksResponse: constructParamsForCall.feedbacks
          });
          dispatch(saveLegFeedbackSuccess(noramalizedFeedback, params.legId));
        }
      }
    })
    .catch(err => {
      dispatch(saveLegFeedbackFail(params.legId));
    });
};
