import { requestQuote, createCompany } from "../sources/RequestQuoteSource";
import {
  REQUEST_QUOTE_INIT,
  REQUEST_QUOTE_SUCCESS,
  REQUEST_QUOTE_FAIL,
  CREATE_COMPANY_INIT,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAIL
} from "../actionTypes";

export const requestQuoteInit = () => ({
  type: REQUEST_QUOTE_INIT
});

export const requestQuoteSuccess = legs => ({
  type: REQUEST_QUOTE_SUCCESS,
  data: legs
});

export const requestQuoteFail = data => ({
  type: REQUEST_QUOTE_FAIL,
  data
});

export const createCompanyInit = () => ({
  type: CREATE_COMPANY_INIT
});

export const createCompanySuccess = data => ({
  type: CREATE_COMPANY_SUCCESS,
  data
});

export const createCompanyFail = data => ({
  type: CREATE_COMPANY_FAIL,
  data
});

export const requestAllQuote = (flightLegs, accountId) =>
  requestQuote(flightLegs, accountId);
export const createCompanies = legs => createCompany(legs);
