import React, { Component } from 'react';
import { convertToTitleCase } from '../utils/stringUtils';
import { scrollFunctionForTable } from '../utils/Helpers';
import moment from 'moment';
import { ITINERARY_DETAILS_PAGE_URL } from '../configs/constants';
import SkeletonLoaderAirport from '../components/shared/SkeletonLoaderAirport';

export default class UnbilledFlights extends Component {
  getAirportName = airport => {
    const { visitedAirportsFinder, isAirportByIdLoading, isAirportsLoading } = this.props;
    if (!visitedAirportsFinder) return '';
    const currentAirport = visitedAirportsFinder[airport];
    if (!currentAirport) {
      return (isAirportByIdLoading || isAirportsLoading) ?
        'Loading...' : 'Unidentified Airports'
    }
    return `${convertToTitleCase((currentAirport || {}).name || '')} (${(currentAirport || '').icao || ''})`;
  }

  getItineraryDetailsRoute = ({ orderId = '', legId = '' }) =>
    ITINERARY_DETAILS_PAGE_URL.url
      .replace('{key}', 'orderId')
      .replace('{orderExtendifier}', orderId)
      .replace('{flightLegExtendifier}', legId)
      .replace('{itineraryType}', '')

  renderAirports = (dep, arr) => {
    const { visitedAirportsFinder, isAirportByIdLoading, isAirportsLoading } = this.props;
    if (!visitedAirportsFinder[dep] || !visitedAirportsFinder[arr]) {
      return (isAirportsLoading || isAirportByIdLoading) ?
        <div className="unbilled_airport_loader">
          <SkeletonLoaderAirport />
        </div> : 'Unidentified Airports';
    } return `${this.getAirportName(dep)} to ${this.getAirportName(arr)}`
  }

  renderUnbilledRow = ({
    departureAirportTimeLocal,
    arrivalAirportId,
    departureAirportId,
    orderId,
    legId,
  }) => (
      <tr onClick={() => window.location = this.getItineraryDetailsRoute({ orderId, legId })}>
        <td class="vistajet_light_text uk-padding-remove-left uk-table-shrink uk-text-nowrap">
          {moment(departureAirportTimeLocal).utc().format('DD MMM YYYY')}
        </td>
        <td class="vistajet_dark_text uk-text-truncate uk-width-large vistajet_max_width_130" uk-tooltip={`title: ${this.renderAirports(departureAirportId, arrivalAirportId)}`}>
          {this.renderAirports(departureAirportId, arrivalAirportId)}
        </td>
        <td className="uk-padding-remove-right">
          <ul class="uk-iconnav uk-flex-right">
            <li><a class="vistajet_chevron_right_icon"></a></li>
          </ul>
        </td>
      </tr>
    );

  render() {
    const { unbilledFlights = [] } = this.props;
    return (
      <div>
        <p class="vistajet_dark_text uk-margin-remove-bottom">Unbilled Flights</p>
        {
          !(unbilledFlights || []).length ?
            <div>No Unbilled Flights Found.</div> :
            <div class="uk-overflow-auto vistajet_table vistajet_table_wrapper">
            { scrollFunctionForTable && scrollFunctionForTable() }
              <table class="uk-table uk-table-small uk-table-hover">
                <tbody>
                  {(unbilledFlights || []).map(this.renderUnbilledRow)}
                </tbody>
              </table>
            </div>
        }
      </div>
    );
  }
}