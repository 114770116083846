import * as type from '../../actionTypes';
import { getFlightId } from '../../../utils/itinerariesUtils';

const initialState = {
  itineraryPdfLoading: 0,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.ITINERARY_DETAILS_SUCCESS: {
      const {
        legs,
      } = action.data;
      const itineraryPdfUrl = {};
      legs.forEach(leg => {
        itineraryPdfUrl[getFlightId(leg)] = {};
        itineraryPdfUrl[getFlightId(leg)].itineraryPdfLoading = false;
      })
      return Object.assign({}, state, {
        itineraryPdf: itineraryPdfUrl,
      });
    }
    case type.ITINERARY_PDF_INIT: {
      state.itineraryPdf[action.flightLegId].itineraryPdfLoading = true;
      return Object.assign({}, state, {
        itineraryPdfLoading: state.itineraryPdfLoading + 1,
        itineraryPdf: state.itineraryPdf,
      });
    }

    case type.ITINERARY_PDF_SUCCESS: {
      state.itineraryPdf[action.flightLegId].itineraryPdf = action.data || {};
      state.itineraryPdf[action.flightLegId].itineraryPdfLoading = false;
      return Object.assign({}, state, {
        itineraryPdfLoading: state.itineraryPdfLoading - 1,
        itineraryPdf: state.itineraryPdf,
      });
    }

    case type.ITINERARY_PDF_FAIL: {
      state.itineraryPdf[action.flightLegId].itineraryPdfLoading = false;
      return Object.assign({}, state, {
        itineraryPdfLoading: state.itineraryPdfLoading - 1,
        itineraryPdf: state.itineraryPdf,
      });
    }
    default:
      return state;
  }
}
