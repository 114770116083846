import {
  fetchUnbilledFlights,
  fetchBilledFlights,
  fetchAccountStatements,
  fetchStatementInvoices,
  fetchZipUrl,
  fetchAccountStatementPdf,
} from '../sources/PASSource';
import * as types from '../actionTypes';

export const fetchUnbilledFlightsInit = () => ({
  type: types.FETCH_UNBILLED_INIT,
});

export const fetchUnbilledFlightsSuccess = data => ({
  type: types.FETCH_UNBILLED_SUCCESS,
  data,
});

export const fetchUnbilledFlightsFail = data => ({
  type: types.FETCH_UNBILLED_FAIL,
  data,
});

export const fetchUnbilledFlightsAction = token => fetchUnbilledFlights(token);

export const fetchRecentInvoicesInit = () => ({
  type: types.FETCH_RECENT_INVOICES_INIT,
});

export const fetchRecentInvoicesSuccess = data => ({
  type: types.FETCH_RECENT_INVOICES_SUCCESS,
  data,
});

export const fetchRecentInvoicesFail = data => ({
  type: types.FETCH_RECENT_INVOICES_FAIL,
  data,
});

export const fetchRecentInvoicesAction = (token, currentCount, aircraftTypeId) =>
  fetchBilledFlights(token, currentCount, aircraftTypeId);

export const fetchMoreInvoicesInit = data => ({
  type: types.FETCH_MORE_INVOICES_INIT,
  data,
});

export const fetchMoreInvoicesSuccess = data => ({
  type: types.FETCH_MORE_INVOICES_SUCCESS,
  data,
});

export const fetchMoreInvoicesFail = data => ({
  type: types.FETCH_MORE_INVOICES_FAIL,
  data,
});

export const fetchMoreInvoicesAction = (token, currentCount, aircraftTypeId, accountId) =>
  fetchBilledFlights(token, currentCount, aircraftTypeId, accountId);

export const fetchAccountStatementsInit = data => ({
  type: types.FETCH_ACCOUNT_STATEMENTS_INIT,
  data,
});

export const fetchAccountStatementsSuccess = data => ({
  type: types.FETCH_ACCOUNT_STATEMENTS_SUCCESS,
  data,
});

export const fetchAccountStatementsFail = data => ({
  type: types.FETCH_ACCOUNT_STATEMENTS_FAIL,
  data,
});

export const fetchAccountStatementsAction = (token, aircraftTypeId, accountId) =>
  fetchAccountStatements(token, aircraftTypeId, accountId);

export const fetchStatementInvoicesInit = (data) => ({
  type: types.FETCH_STATEMENT_INVOICES_INIT,
  data,
});

export const fetchStatementInvoicesSuccess = data => ({
  type: types.FETCH_STATEMENT_INVOICES_SUCCESS,
  data,
});

export const fetchStatementInvoicesFail = data => ({
  type: types.FETCH_STATEMENT_INVOICES_FAIL,
  data,
});

export const clearInvoiceFilter = () => ({
  type: types.CLEAR_INVOICE_FILTER,
})

export const fetchStatementInvoicesAction = (token, accountStatementId, pageNumber, pageSize) =>
  fetchStatementInvoices(token, accountStatementId, pageNumber, pageSize);

export const fetchZipUrlInit = () => ({
  type: types.FETCH_ZIP_URL_INIT,
});

export const fetchZipUrlSuccess = () => ({
  type: types.FETCH_ZIP_URL_SUCCESS,
});

export const fetchZipUrlFail = data => ({
  type: types.FETCH_ZIP_URL_FAIL,
  data,
});

export const fetchZipUrlAction = (key, token) =>
  fetchZipUrl(key, token);

export const clearPasError = data => ({
  type: types.CLEAR_PAS_ERROR,
  data,
});

export const fetchAccountStatementPdfInit = () => ({
  type: types.FETCH_ACCOUNT_STATEMENT_PDF_INIT,
});

export const fetchAccountStatementPdfSuccess = () => ({
  type: types.FETCH_ACCOUNT_STATEMENT_PDF_SUCCESS,
});

export const fetchAccountStatementPdfFail = (data) => ({
  type: types.FETCH_ACCOUNT_STATEMENT_PDF_FAIL,
  data,
});

export const fetchAccountStatementPdfAction = (id, token) => fetchAccountStatementPdf(id, token);
