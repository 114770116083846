import axios from 'axios';
import {
  addCreditCardInit,
  addCreditCardSuccess,
  addCreditCardFail,
  getCreditCardListInit,
  getCreditCardListSuccess,
  getCreditCardListFail,
  updateCreditCardSuccess,
  deleteCreditCardInit,
  deleteCreditCardSuccess,
  deleteCreditCardFailure,
} from '../actions';
import {
  ADD_CREDIT_CARD_URL, GET_CREDIT_CARD_LIST_URL, DELETE_CREDIT_CARD_URL,
} from '../../configs/constants';
import { CREDIT_CARD_MESSAGE } from '../../constants/MessageConstants';
import { HTTP_HEADERS_XML } from '../../constants/ApiConstants';
import { handleServerErrors } from '../../utils/sourceUtils';
import { normalizeMultipleCreditCards, normalizeCreditCard } from '../../normalizers/creditCardNormalizer';
import { builderXmlRequest, parserPascalCaseIgnoreNumber, parseString } from '../../utils/parserUtils';
import status from '../../configs/status';
import { guaranteeArray } from '../../utils/arrayUtils';
import { BAD_REQUEST, TIMEOUT_CODE } from '../../constants/ServerCodeConstants';
import { getFromLocal } from '../../utils/cache';
import { USER_REDUCER } from '../../constants/localStorageKeys';

export const AddCreditCardSource = (token, creditCardPayload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(addCreditCardInit());
    const {
      alias,
      creditCardNumber,
      expiryDate,
      cvv,
      defaultChecked,
      type,
    } = creditCardPayload;

    const creditCardRequestDto = {
      type,
      isDefault: defaultChecked ? 1 : 0,
    };

    if (alias) creditCardRequestDto.alias = alias;
    if (creditCardNumber) creditCardRequestDto.creditCardNumber = creditCardNumber;
    // if (nameOnTheCard) creditCardRequestDto.nameOnTheCard = nameOnTheCard;
    if (expiryDate) creditCardRequestDto.expiryDate = expiryDate;
    if (cvv) creditCardRequestDto.cvv = cvv;

    const payload = builderXmlRequest({
      creditCardRequestDto,
    });

    axios
      .post(
        ADD_CREDIT_CARD_URL.replace('{token}', token),
        payload,
        {
          headers: HTTP_HEADERS_XML,
        },
      )
      .then((res) => {
        parserPascalCaseIgnoreNumber(res.data, (error, result) => {
          if (error) {
            const errors = handleServerErrors(null);
            dispatch(addCreditCardFail(errors));
            reject(errors);
          } else {
            const {
              responseHeader,
              ...creditCard
            } = result.creditCardResponseDto;
            if (responseHeader && responseHeader.isError) {
              const {
                statusCode,
                message,
              } = responseHeader;
              if (statusCode == 1) { // API sends same code with different message
                dispatch(addCreditCardFail(message));
                reject(message);
              } else {
                const errors = handleServerErrors(statusCode);
                dispatch(addCreditCardFail(errors));
                reject(errors);
              }
            } else {
              const normalizedCreditCardResponse = normalizeCreditCard(creditCard);
              let message = CREDIT_CARD_MESSAGE[normalizedCreditCardResponse.cardBrandId];
              if (creditCardPayload.isEdit) {
                if (creditCardPayload.isSetDefault) {
                  message = `${message}${normalizedCreditCardResponse.cardNumberLastFour} is now your default credit card`;
                } else {
                  message = `${message}${normalizedCreditCardResponse.cardNumberLastFour} has been updated`;
                }
                dispatch(updateCreditCardSuccess({
                  response: normalizedCreditCardResponse,
                  message,
                }));
              } else {
                message = `${message}${normalizedCreditCardResponse.cardNumberLastFour} has been created`;
                dispatch(addCreditCardSuccess({
                  response: normalizedCreditCardResponse,
                  message,
                }));
              }
              resolve(normalizedCreditCardResponse);
            }
          }
        });
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(addCreditCardFail(errors));
        reject(errors);
      });
  });
};

export const getCreditCardList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(getCreditCardListInit());
    const { user } = getFromLocal(USER_REDUCER, true, true);
    const token = user.userToken;
    const options = {
      headers: {
        "Content-Type": "application/xml"
      },
      timeout: TIMEOUT_CODE.time
    };
    axios
      .get(GET_CREDIT_CARD_LIST_URL.replace('{token}', token), options)
      .then((response) => {
        if (response.status === status.OK) {
          parseString(response.data, (parseErr, result) => {
            if (parseErr) {
              dispatch(getCreditCardListFail(handleServerErrors(BAD_REQUEST)));
              reject(handleServerErrors(BAD_REQUEST));
            } else if (result.CreditCardsList.responseHeader.isError) {
              const error = result.CreditCardsList.responseHeader;
              dispatch(getCreditCardListFail(error.message));
              reject(error.message);
            } else {
              const response = result.CreditCardsList.ResponseBody
                ? result.CreditCardsList.ResponseBody.creditCards : {};
              const normalizedCreditCards = normalizeMultipleCreditCards(
                guaranteeArray(response.creditCard)
              );
              dispatch(getCreditCardListSuccess(normalizedCreditCards));
              resolve(normalizedCreditCards);
            }
          })
        }
      })
      .catch(err => {
        if (err.code === TIMEOUT_CODE.status) {
          dispatch(getCreditCardListFail(handleServerErrors(TIMEOUT_CODE.code)));
          reject(handleServerErrors(TIMEOUT_CODE.code))
        } else {
          dispatch(getCreditCardListFail(handleServerErrors(BAD_REQUEST)));
          reject(handleServerErrors(BAD_REQUEST))
        }
      });
  });
};

export const deleteCreditCardSource = (token, creditCardPayload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(deleteCreditCardInit());
    const url = DELETE_CREDIT_CARD_URL.replace('{token}', token);
    const {
      alias,
    } = creditCardPayload;
    const payload = builderXmlRequest({
      creditCardRequestDto: {
        alias,
      },
    });

    axios
      .post(
        url,
        payload,
        {
          headers: HTTP_HEADERS_XML,
        },
      )
      .then((res) => {
        parserPascalCaseIgnoreNumber(res.data, (error, result) => {
          if (error) {
            const errors = handleServerErrors(null);
            dispatch(deleteCreditCardFailure(errors));
            reject(errors);
          } else {
            const {
              responseHeaderDto,
            } = result.creditCardResponseDto;

            if (responseHeaderDto && responseHeaderDto.isError === true) {
              const {
                statusCode,
              } = responseHeaderDto;
              const errors = handleServerErrors(statusCode);
              dispatch(deleteCreditCardFailure(errors));
              reject(errors);
            } else {
              let message = CREDIT_CARD_MESSAGE[creditCardPayload.cardBrandId];
              message = `${message}${creditCardPayload.cardNumberLastFour} deleted`;
              dispatch(deleteCreditCardSuccess({
                response: creditCardPayload.alias,
                message,
              }));
              resolve(creditCardPayload.alias);
            }
          }
        });
      })
      .catch(err => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(addCreditCardFail(errors));
        reject(errors);
      });
  });
};
