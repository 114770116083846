import React, { Component } from 'react';
import numeral from 'numeral';
import { DIRECT_ACCOUNT_BALANCE_PAGE_URL } from '../../../configs/constants';
import SpinnerLoader from '../../shared/spinnerLoader';
import {
  isGuest,
  isFSP,
  isFspDirect,
} from '../../../utils/userUtils';

export default class MemberShipDetailCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupMessage: '',
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { getDirectAccountStatement } = this.props;
    if (user && user.user) {
      if (!isGuest(user.user) && (!isFSP(user.user) || isFspDirect(user.user))) {
        getDirectAccountStatement(user.user.userToken);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.directAccoutBalanceError !== nextProps.directAccoutBalanceError
      && nextProps.isDirectAccountBalanceError
    ) {
      this.setState({
        popupMessage: nextProps.isDirectAccountBalanceError,
      }, () => {
        // window.UIkit.modal('#DirectAccountManagementErrorPopup').show();
      });
    }
  }

  handleApiErrors = (err) => {
    this.setState({
      popupMessage: err,
    });
  };

  redirectToDirectAccountBalance = () => {
    window.location = DIRECT_ACCOUNT_BALANCE_PAGE_URL.url;
  }

  renderMemberShipDetailCard = () => {
    const { accountData, isDirectAccountBalanceError } = this.props;
    let amount = 0;
    if (accountData && Object.keys(accountData).length > 0) {
      amount = numeral(accountData.currentBalance).format('0,0');
      amount = `${amount}`;
    }
    return (
    <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card">
      <div class="vistajet_head_column">
        <div class="uk-clearfix vistajet_user_profile_buttons">
          <div class="uk-float-left">
            <h3 class="vistajet_right_tab_heading">Direct Membership</h3>
          </div>
          <div class="uk-float-right vistajet_account_button_full">
            <a
              className='uk-button uk-align-right uk-margin-remove vistajet_account_button vistajet_button_default'
              onClick={this.redirectToDirectAccountBalance}
            > <span>Account Statement</span></a>
          </div>
        </div>             
      </div>
      <div className="vistajet_profile_details_column">
        <div className="uk-flex uk-grid-small" data-uk-grid>
          <div className="uk-width-1-1">
          {
              accountData && Object.keys(accountData).length > 0
                ? (

            <div className="vistajet_label">
              <span>Balance</span>
              <h5 className="uk-margin-small-bottom"> 
                USD {amount}
              </h5>
            </div>
            )
            : isDirectAccountBalanceError ?
            <div className="vistajet_label">
              <span>Balance</span>
              <h5 className="uk-margin-small-bottom"> 
                ----
              </h5>
            </div>
            : <div className="uk-text-center">
                <SpinnerLoader />
              </div>
        }
          </div>  
        </div>
      </div>
    </div>
    );
  };

  render() {
    return this.renderMemberShipDetailCard();
  }
}
