import React from 'react';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
export default class ConfirmationPopup extends React.Component {
  state = {
    open: false,
  };

  show = () => {
    this.setState({ open: true });
  };

  hide = () => {
    const { onClose } = this.props;
    this.setState({ open: false });
    if (onClose) {
      onClose();
    }
  };

  render() {
    const {
      title,
      isCenter,
      closeOnEsc,
      closeOnOverlayClick,
      onExited,
      styles,
      showCloseIcon,
      closeIconSize,
      closeIconSvgPath,
      focusTrapped,
      id,
      onLeftButtonClick,
      leftButtonContent,
      rightButtonContent,
      onRightButtonClick,
      content,
    } = this.props;
    const { open } = this.state;

    return (
      <Modal
        open={open}
        classNames={{
          overlay: 'vistajet_modal vistajet_confirm_modal',
          modal: 'vistajet_modal_body vistajet_react_modal'
        }}
        closeOnOverlayClick={closeOnOverlayClick}
        onClose={this.hide}
        center={isCenter}
        closeOnEsc={closeOnEsc}
        onExited={onExited}
        styles={styles}
        showCloseIcon={showCloseIcon}
        closeIconSize={closeIconSize}
        closeIconSvgPath={closeIconSvgPath}
        focusTrapped={focusTrapped}
      >
        <div
          id={id}
        >
          <div className="vistajet_modal_content">
            <div className="uk-flex">
              <span class="vistajet_info_icon"></span>
              <div>
                {title && (
                  <p>{title}</p>
                )}
                <p className={title && "vistajet_sub_content"}>{content}</p>
              </div>
            </div>
          </div>
          <div className="vistajet_confirm_button_wrapper">
            {onLeftButtonClick ? (
              <button onClick={onLeftButtonClick} className="uk-modal-close uk-button uk-button-secondary ">{leftButtonContent}</button>
            ) : null
            }
            {onRightButtonClick ? (
              <button onClick={onRightButtonClick} className="uk-modal-close uk-button">{rightButtonContent}</button>
            ) : null
            }
          </div>
        </div>
      </Modal>
    );
  }
}

ConfirmationPopup.defaultProps = {
  isCenter: true,
  closeOnEsc: false,
  closeOnOverlayClick: true,
  showCloseIcon: false,
  focusTrapped: false,
  closeIconSize: 28,
  onExited: () => { },
  onClose: () => { }
};
