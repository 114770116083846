import React, { Component } from 'react';
// import Counter from 'components/shared/Counter';
import {
  CATERING_SHARED_ORDER_KEY,
  CATERING_SHARED_ORDER_LABEL,
} from '../../../constants/CateringConstants';
import { convertToTitleCase } from '../../../utils/stringUtils';
import dirtystateSingleTon from '../../../validators/validateDirty';
import SPAConstants from '../../../constants/SPAConstants';

class PassengerQuantityListItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showNotes: false,
      count: 0,
      value: props.customerNotes || '',
      isChanged: false,
    };
  }
  handleShowNotes = () => {
    const isShowingNotes = !this.state.showNotes;
    this.setState({ showNotes: isShowingNotes });
    if (isShowingNotes) this.props.onStartEditingNotes();
    else this.props.onStopEditingNotes();
  };

  handleCountChange = (count) => {
    if(count < 0) count = 0;
    if(count > 99) count = 99;
    let isChanged = false;
    if (count > 0) {
      isChanged = true;
    }
    this.setState({
      isChanged,
      count,
    });
    if (this.props.onQuantityChanged) {
      const passenger = { ...this.props.passenger };
      if (this.props.toShare) {
        passenger.passengerId = CATERING_SHARED_ORDER_KEY;
      }
      this.props.onQuantityChanged(passenger, count);
    }
  };

  handleNotesValue = (event) => {
    this.setState({
      value: event.target.value,
      isChanged: true,
    });
  }

  clearNotes = () => {
    if(this.state.value){
      this.setState({value: "", isChanged: true})
    }
  }

  handleSave = () => {
    dirtystateSingleTon.setDirty(SPAConstants.ItineraryCatering, true);
    this.setState({
      showNotes: false,
      isChanged: false,
    });
    this.props.onStopEditingNotes();
    const passenger = { ...this.props.passenger };
    if (this.props.toShare) {
      passenger.passengerId = CATERING_SHARED_ORDER_KEY;
    }
    this.props.onNotesSaved(passenger, this.state.value);
  };

  handelCancel = () => {
    this.setState({ showNotes: false, value: this.props.customerNotes, isChanged: false });
    this.props.onStopEditingNotes();
  };

  render() {
    const { passenger, toShare } = this.props;
    const key = toShare ? CATERING_SHARED_ORDER_LABEL : passenger.passengerId;
    const showNotesClass = (this.state.showNotes) ? 'open' : 'close';
    return (
      <div key={key}>
        <div className="uk-width vistajet_catering_menu_list">
          <div className="uk-width-expand vistajet_special_instruction_wrapper">
            <p className="uk-text-left uk-text-truncate vistajet_accordion_menu_item">{toShare ? CATERING_SHARED_ORDER_LABEL : convertToTitleCase(passenger.formattedPaxObj.fullName)}</p>
            <button className={`uk-button vistajet_special_instruction_button ${showNotesClass}`} type="button" onClick={this.handleShowNotes}>Special Instructions</button>
          </div>
          <div className="vistajet_counter_wrapper counter_padding_update">
            <button type="button" onClick={() => this.handleCountChange((this.props.count || 0) - 1)}>
              <img src="/legacy/static/img/icons/vistajet_minus_icon.svg" alt="icon" className="vistajet_minus_icon"/>
            </button>
            <span className="vistajet_counter_number">{this.props.count || 0}</span>
            <button type="button" onClick={() => this.handleCountChange((this.props.count || 0) + 1)}>
              <img src="/legacy/static/img/icons/vistajet_plus_two_icon.svg" alt="icon"  className="vistajet_plus_two_icon"/>
            </button>
          </div>
        </div>

        {(this.state.showNotes) && 
        <div className="vistajet_special_instruction_input">
          <div className="vistajet_special_instruction_input_wrapper">
            <input type="text" placeholder="Add special instruction here" className="uk-width" value={this.state.value} onChange={this.handleNotesValue} />
            <button className="uk-button vistajet_close_button" onClick={this.clearNotes}>
                <img src="/legacy/static/img/icons/ic_cancel_grey.svg" alt="icon"/>
            </button>
          </div>
          
          
          {this.state.value !== (this.props.customerNotes || '') && this.state.isChanged &&
            <div className="vistajet_confirm_button_wrapper">
              <button className="uk-button vistajet_button_default" onClick={this.handelCancel}>Cancel</button>
              <button className="uk-button vistajet_button_default vistajet_button_primary" onClick={this.handleSave}>Save</button>
            </div>
          }
        </div>}
      </div>
      // <li className="separator-bottom row passenger-item" key={key}>
      //   <div className="row" style={{ marginBottom: 3 }}>
      //     <div className="col s8 label-name">
      //       <div>{toShare ? CATERING_SHARED_ORDER_LABEL : passenger.formattedPaxObj.fullName}</div>
      //       <div onClick={this.handleShowNotes} className="special-instruction-field">
      //         <span>Special instructions</span>
      //         {this.state.showNotes ?
      //           ' up'
      //           : ' down'}
      //       </div>
      //     </div>
      //     <div className="col s4 text-right pr-4" >
      //       <Stepper
      //         value={this.props.count || 1}
      //         onChange={this.handleCountChange}
      //         maxValue={99}
      //       />
      //     </div>
      //   </div>
      //   <div className="row">
      //     {(this.state.showNotes) &&
      //       (<div className="item-catering-notes">
      //         <textarea
      //           placeholder="Write here.."
      //           onChange={this.handleNotesValue}
      //           value={this.state.value}
      //         />
      //         {this.state.value !== this.props.customerNotes && this.state.isChanged &&
      //           (<div className="catering-popup-footer">
      //             <span
      //               label="Save"
      //               className="right"
      //               style={{ backgroundColor: '#747678' }}
      //               onClick={this.handleSave}
      //             >Save</span>
      //             <span
      //               label="cancel"
      //               className="left"
      //               style={{ backgroundColor: '#747678' }}
      //               onClick={this.handelCancel}
      //               disabled={false}
      //             >Cancel</span>
      //           </div>)
      //         }
      //       </div>)
      //     }
      //   </div>
      // </li>
    );
  }

}

export default PassengerQuantityListItem;
