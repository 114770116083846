import axios from 'axios';
import moment from 'moment';
import {
  availOfferInit,
  availOfferFail,
  availOfferSuccess,
  availOfferQuotingNotAvailable,
  buyOfferInit,
  buyOfferFail,
  buyOfferSuccess,
} from '../actions';
import { TIMEOUT_CODE, BAD_REQUEST, MULTI_LEG_OFFER_QOUTING_NA } from '../../constants/ServerCodeConstants';
import { QUOTE_URL, REQUEST_FLIGHT_2_0_URL } from '../../configs/constants';
import status from '../../configs/status';
import { handleServerErrors } from '../../utils/sourceUtils';
import { normalizeQuoteResponse } from '../../normalizers/AvailOfferNormailizer';
import { parseString } from '../../utils/parserUtils';
import createGuid from '../../utils/guidUtils';
import { PAYMENT_METHODS_FOR_BOOKING, MEMBERSHIP_FLIGHT_REQUEST } from '../../constants/BookingFlightConstants';
import { normalizeFlightLegIdentifiers, normalizeLegStatus } from '../../normalizers/PAFNormalizer';
import { getFromLocal } from '../../utils/cache';
import { USER_REDUCER } from '../../constants/localStorageKeys';
import { REQUIRE_FUELSTOP, SOMETHING_UNEXPECTED } from "../../constants/MessageConstants";


const legsReqAvailOfferPayload = (legs) => {
  const emptyLegId = legs[0].isEmptyLeg ? `<emptyLegId>${legs[0].emptyLegId}</emptyLegId>` : '';
  const opportunityId = legs[0].isOneWay ?
    `<opportunityId>${legs[0].id}</opportunityId>` : '';
  return (legs.map((leg) => {
    const {
      departureAirport, arrivalAirport, departTime,
      selectedAircraft, passengersCount,
    } = leg;
    return `
      <flightLeg>
      ${emptyLegId}
      ${opportunityId}
        <departureAirportId>${departureAirport.id}</departureAirportId>
        <arrivalAirportId>${arrivalAirport.id}</arrivalAirportId>
        <departureTime>${moment(departTime).utc().toJSON()}</departureTime>
        <requestedAircraftTypeId>${selectedAircraft.id}</requestedAircraftTypeId>
        <numberOfPassenger>${passengersCount}</numberOfPassenger>
        <isCateringSelected>false</isCateringSelected>
      </flightLeg>
    `;
  }).join(''))
};

const createAvailOfferPayload = (legs, user) => {
  const { accounts } = user;
  const account = accounts.filter(acc => (acc.membershipStatus === 2));
  const { id, typeId, membershipTypeId } = account[0];

  return (
    `<quoteRequestDto>
      <accountId>${id}</accountId>
      <accountTypeId>${typeId}</accountTypeId>
      <membershipTypeId>${membershipTypeId}</membershipTypeId>
      <flightLegs>
        ${legsReqAvailOfferPayload(legs)}
      </flightLegs>
    </quoteRequestDto>`);
};

const legBuyOfferPayload = (legs) => {
  return legs.map((leg) => {
    const {
      departureAirport,
      arrivalAirport,
      departTime,
      passengersCount,
      arrivalTime,
      selectedAircraft,
      version,
    } = leg;
    const emptyLegId = leg.isEmptyLeg ? leg.id : '';
    const opportunityId = leg.isOneWay ? leg.id : '';

    return `<flightLeg>
      <flightLegId></flightLegId>
      <flightLegExtIdentifier>${createGuid()}</flightLegExtIdentifier>
      <arrivalAirportId>${arrivalAirport.id}</arrivalAirportId>
      <arrivalTime>${moment(arrivalTime).utc().format()}</arrivalTime>
      <departureAirportId>${departureAirport.id}</departureAirportId>
      <departureTime>${moment(departTime).utc().format()}</departureTime>
      <numberOfPassenger>${passengersCount}</numberOfPassenger>
      <requestedAircraftTypeId>${selectedAircraft.id}</requestedAircraftTypeId>
      <isCancelled>false</isCancelled>
      <opportunityId>${opportunityId}</opportunityId>
      <emptyLegId>${emptyLegId}</emptyLegId>
      <version>${version || '1'}</version>
    </flightLeg>`
  });
};

const createBuyOfferPayload = (legs, quoteDetails, paymentOptionId, creditCard, accountId, isQuote) => {
  const { quoteId, quoteOfferId, totalAmount, mode } = quoteDetails;
  const { ccAlias, ccCode } = creditCard;
  return (
    `<flightRequestForCustomer>
    <referenceOrderId>${quoteId}</referenceOrderId>
    <quoteId>${isQuote ? '' : quoteId}</quoteId>
    <quotePriceId>${isQuote ? '' : quoteOfferId}</quotePriceId>
    <account>${accountId}</account>
    <totalAmount>${totalAmount}</totalAmount>
    <orderExtIdentifier>${isQuote ? '' : quoteId}</orderExtIdentifier>
    <flightLegs>
      ${legBuyOfferPayload(legs)}
    </flightLegs>
    <paymentOptionId>${paymentOptionId}</paymentOptionId>
    ${PAYMENT_METHODS_FOR_BOOKING.CREDIT_CARD === paymentOptionId && ccAlias ?
      `<creditCardAlias>${ccAlias}</creditCardAlias>` : ''}
    ${PAYMENT_METHODS_FOR_BOOKING.CREDIT_CARD === paymentOptionId && ccCode ?
      `<cvvCode>${ccCode}</cvvCode>` : ''}
    <mode>${mode}</mode>
  </flightRequestForCustomer>`
  );

}

export const availOffer = (legs, user) => (dispatch) => {
  dispatch(availOfferInit());
  const options = {
    headers: {
      'Content-Type': 'application/xml'
    },
    timeout: TIMEOUT_CODE.time,
  };
  const token = user.userToken;
  const payload = createAvailOfferPayload(legs, user);

  axios
    .post(QUOTE_URL.replace('{token}', token), payload, options)
    .then((response) => {
      if (response.status === status.OK) {
        parseString(response.data, (parseErr, result) => {
          if (parseErr) {
            dispatch(
              availOfferFail('We were not able to book your flight, please try again later.')
            );
          } else if (result.responseDto.responseHeader.isError){
            const error = result.responseDto.responseHeader;
              if(error.statusCode === MULTI_LEG_OFFER_QOUTING_NA.code )
              {
                dispatch(availOfferQuotingNotAvailable(handleServerErrors(error.statusCode)));}
              else{
                  if (error.statusCode === 5) {
                    dispatch(availOfferFail(REQUIRE_FUELSTOP));
                  } else {
                    dispatch(availOfferFail(error.message));
                  }
                }
          } else {
            dispatch(availOfferSuccess({
              quoteContent: normalizeQuoteResponse(result.responseDto.responseBody, legs.length === 1),
              legs,
            }));
          }
        });
      }
      else {
        dispatch(availOfferFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(availOfferFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else dispatch(availOfferFail(handleServerErrors(BAD_REQUEST)));
    });
};

export const buyOffer = (legs, quoteDetails, paymentOption, creditCard, isQuote = false) => (dispatch) => {
  dispatch(buyOfferInit());
  const options = {
    headers: {
      'Content-Type': 'application/xml'
    },
  };
  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;
  const { accounts } = user;
  const account = isQuote ? accounts : accounts.filter(acc => (acc.membershipStatus === 2));
  const { id } = account[0];
  const payload = createBuyOfferPayload(legs, quoteDetails, paymentOption, creditCard, id, isQuote);

  axios
    .post(REQUEST_FLIGHT_2_0_URL.replace('{token}', token), payload, options)
    .then((response) => {
      if (response.status === status.OK) {
        parseString(response.data, (parseErr, result) => {
          if (parseErr) {
            dispatch(
              buyOfferFail('We were not able to book your flight, please try again later.')
            );
          } else if (result.ResponseBody.responseHeader.isError) {
            const header = result.ResponseBody.responseHeader;
            let textToReturn = header.message;
            if (header.statusCode !== MEMBERSHIP_FLIGHT_REQUEST.NO_FUNDS) {
              textToReturn = SOMETHING_UNEXPECTED;
            }
            dispatch(buyOfferFail(textToReturn));
          } else {
            dispatch(buyOfferSuccess(
              {
                orderId: result.ResponseBody.flightOrderId ? result.ResponseBody.flightOrderId : '',
                identifiers: normalizeFlightLegIdentifiers(
                  result.ResponseBody.flightLegs
                ),
                legArray: legs,
                status: normalizeLegStatus(result.ResponseBody.flightLegs),
              }
            ));
          }
        });
      }
      else {
        dispatch(buyOfferFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(buyOfferFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else dispatch(buyOfferFail(handleServerErrors(BAD_REQUEST)));
    });
};
