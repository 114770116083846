import PropTypes from 'prop-types';
import React, { Component } from 'react';

import OfferMap from '../OfferMap';
import FlightPathRow from '../shared/FlightPathRow';
import { legStatus } from '../../constants/ItineraryConstants';
import { populateLegsForMap } from '../../utils/itinerariesUtils';

class ItineraryListingCard extends Component {
  static propTypes = {
    dates: PropTypes.string,
    childLeg: PropTypes.bool,
    orderID: PropTypes.string,
    flightIcon: PropTypes.string,
    leg: PropTypes.instanceOf(Object),
    item: PropTypes.instanceOf(Object),
    handleItineraryTap: PropTypes.func,
    isGoogleMapsApiLoaded: PropTypes.bool,
    lastLeg: PropTypes.instanceOf(Object),
    onGoogleMapsApiLoaded: PropTypes.func.isRequired,
    visitedAirportsFinder: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    leg: {},
    item: {},
    dates: '',
    lastLeg: {},
    orderID: '',
    flightIcon: '',
    childLeg: false,
    handleItineraryTap: null,
    visitedAirportsFinder: {},
    isGoogleMapsApiLoaded: false,
  }

  getStatusStyle = (status) => {
    return status === legStatus.CANCELLATION_PENDING
      || status === legStatus.CANCELLED
      ? { color: 'red' }
      : {};
  }

  handleOnClick = (leg, item) => {
    const { handleItineraryTap } = this.props;
    if (handleItineraryTap) {
      handleItineraryTap(leg, item);
    }
  };

  render() {
    const {
      leg,
      item,
      index,
      dates,
      orderID,
      lastLeg,
      childLeg,
      flightIcon,
      itineraryType,
      aircraftsList,
      visitedAirportsFinder,
      onGoogleMapsApiLoaded,
      isGoogleMapsApiLoaded,
      upcomingFirstMultiLeg,
    } = this.props;

    return (
      <div className="vistajet_bs_card vistajet_itinerary_listing" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 25;">
        <div className={childLeg ? 'uk-flex uk-grid-collapse' : 'uk-flex uk-margin-medium-top uk-grid-collapse'} data-uk-grid>
          <div className={!childLeg ? 'uk-width-2-3@s' : 'uk-width-1-1@m'} onClick={() => this.handleOnClick(leg, item)}>
            <div className="vistajet_bs_body">
              <FlightPathRow
                leg={leg}
                item={item}
                dates={dates}
                index={index}
                lastLeg={lastLeg}
                orderID={orderID}
                childLeg={childLeg}
                flightIcon={flightIcon}
                aircraftsList={aircraftsList}
                isItineraryDetail={false}
                itineraryType={itineraryType}
                getLegLocalTime={this.getLegLocalTime}
                visitedAirportsFinder={visitedAirportsFinder}
                upcomingFirstMultiLeg={upcomingFirstMultiLeg}
                aircraftLists={this.props.aircraftLists}
              />
            </div>
          </div>
          {!childLeg && (
            <div className="uk-width-1-3@s vistajet_itinerary_listing_map">
              <OfferMap
                isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
                onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
                legs={populateLegsForMap(item, visitedAirportsFinder)}
                isListing
              />
            </div>
          )}
        </div>
        {/* {childLeg && (
          <div className="vistajet_divider" />
        )} */}
      </div>
    );
  }
}
export default ItineraryListingCard;
