import React, { Component } from "react";
import PropTypes from "prop-types";

export default class TimeElement extends Component {
  handleClick = () => {
    const { onClick, value } = this.props;
    return onClick(value);
  };

  render = () => {
    const { value } = this.props;
    return (
      <li onClick={this.handleClick} onKeyPress={this.handleClick}>
        {value}
      </li>
    );
  };
}

TimeElement.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string
};

TimeElement.defaultProps = {
  value: ""
};
