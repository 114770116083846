import {
  validateNotEmpty,
  validatePassword, validateRepeatPassword, validateSamePassword,
} from './Validators';

export default function ValidateChangePasswordForm(obj) {
  const oldpasswordResponse = validateNotEmpty(obj.oldPassword);
  const newpasswordResponse = validatePassword(obj.newPassword);
  const newpasswordRetypeResponse = validateRepeatPassword(obj.retypeNewPassword, obj.newPassword);
  const samePasswordResponse = validateSamePassword(obj.oldPassword, obj.newPassword);
  const res = {
    errors: {},
  };
  if (oldpasswordResponse.isError) {
    res.isError = oldpasswordResponse.isError;
    res.errors.oldPassword = oldpasswordResponse.message;
  }
  if (samePasswordResponse.isError) {
    res.isError = samePasswordResponse.isError;
    res.errors.newPassword = samePasswordResponse.message;
  }
  if (newpasswordResponse.isError) {
    res.isError = newpasswordResponse.isError;
    res.errors.newPassword = newpasswordResponse.message;
  }
  if (newpasswordRetypeResponse.isError) {
    res.isError = newpasswordRetypeResponse.isError;
    res.errors.retypeNewPassword = newpasswordRetypeResponse.message;
  }
  return res;
}
