import React, { Component } from "react";
import DrawerContainer from "../containers/DrawerContainer";
import UserContainer from "../containers/UserContainer";
import { HOME_PAGE_URL, RAF_PAGE_URL, PAF_PAGE_URL } from "../configs/constants";
import { blockRoute } from "../validators/validateDirty";
import MessagePopUpNew from './shared/MessagePopUpNew';
import { isFspDirect, isFSP } from "../utils/userUtils";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    }
  }

  componentDidMount() {
    blockRoute();
    const { user, getAircrafts, getAccountStatusAction, fetchAccountStatementPdfAction } = this.props;
    getAircrafts();

    if (user && user.user) {
      getAccountStatusAction(user.user.userToken).then(() => { }, this.handleApiErrors);
    }

    const { isLoggedIn, user: userObj } = user;
    const id = (new URLSearchParams(window.location.search)).get('accountStatementId');
    if (id) {
      if (isLoggedIn) {
        fetchAccountStatementPdfAction(id, userObj.userToken);
      } else {
        window.location.replace(window.epiSettings.loginUrl)
      }
    }

    const route = (new URLSearchParams(window.location.search)).get('from') || '';
    if (route === 'request-a-flight') {
      if (isLoggedIn) {
        if (isFspDirect(userObj) || isFSP(userObj))
          window.open(PAF_PAGE_URL, "_self");
        else {
          window.open(RAF_PAGE_URL, "_self");
        }
      } else {
        window.location.replace(window.epiSettings.loginUrl)
      }
    } else if ((route === 'login' || route === 'forgotPassword')) {
      if (isLoggedIn) {
        window.open('/', "_self");
      } else {
        window.location.replace(window.epiSettings.loginUrl)
      }
    } else if (route) {
      if (isLoggedIn) {
        window.open(route, "_self");
      } else {
        window.location.replace(window.epiSettings.loginUrl)
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      pdfError,
      fetchAccountStatementPdfAction,
      user: propUser,
      loadingPdf: oldLoadingPdf
    } = this.props;
    const { pdfError: error, user, loadingPdf } = nextProps;
    const { isLoggedIn, user: userObj } = user;
    if (propUser.isLoggedIn !== user.isLoggedIn && !user.changePasswordRequired) {
      const id = (new URLSearchParams(window.location.search)).get('accountStatementId');
      if (isLoggedIn && id) {
        fetchAccountStatementPdfAction(id, userObj.userToken);
      }
      const route = (new URLSearchParams(window.location.search)).get('from');
      if (route === 'request-a-flight') {
        if (isFSP(userObj) || isFSP(userObj))
          window.open(PAF_PAGE_URL, "_self");
        else {
          window.open(RAF_PAGE_URL, "_self");
        }
      } else if (route === 'login' || route === 'forgotPassword') {
        window.open('/', "_self");
      } else if (route) {
        window.open(route, "_self");
      }
    }
    if (!loadingPdf && loadingPdf !== oldLoadingPdf) {
      document.getElementById("full-screen-loader").remove();
    }
    if (error && error !== pdfError) {
      this.setState({ error });
      this.messagePopup.show();
    }
  }

  render() {
    const { error } = this.state;
    const { loadingPdf } = this.props;

    return (
      <React.Fragment>
        {loadingPdf ? this.removePointerEvent() : null}
        <UserContainer />
        <MessagePopUpNew
          ref={(messagePopup) => { this.messagePopup = messagePopup; }}
          content={error}
          onClose={() => window.open(HOME_PAGE_URL, '_self')}
        />
      </React.Fragment>
    );
  }
}
