import React from 'react';
import { CONTACT_US_PAGE_URL } from '../configs/constants';
import { redirect } from '../components/Drawer';

// eslint-disable max-len
export const NO_OFFERS_FOUND = 'No Offers Found';
export const NO_ONE_WAY_OFFERS_FOUND = 'No One Way Offers Found';
export const NO_EMPTY_LEG_OFFERS_FOUND = 'No Empty Legs Offers Found';
export const NO_SEARCH_OFFERS_FOUND = 'Sorry, we couldn\'t find any availability to match your search. Try different criteria or speak to one of our experts on +44 (0) 203 617 3106.';
export const NO_OFFER_SELECTED = 'Please select an offer from the list to view it on the map';

export const SOMETHING_UNEXPECTED = <React.Fragment>Something unexpected happened. Please try again or <a className="contact_us_anchor" onClick={() => redirect(CONTACT_US_PAGE_URL)}>contact us</a>.</React.Fragment>;
export const SOMETHING_UNEXPECTED_CONTACT = 'Something unexpected happened. Please try again or contact our team directly.';
export const SESSION_EXPIRED = 'Your session has expired, please login again.';
export const MEMBERSHIP_EXPIRED = 'No VJ direct account exist against given FSP customer.';
export const NETWORK_OFFLINE = 'Please reconnect to the internet and retry.';
export const ACCOUNT_NOT_FOUND = 'The account was not found.';
export const LEG_IN_PAST = 'One or more Flight Legs is in the past.';
export const PERMISSION_DENIED = 'The current requester don\'t have permission to request flights.';
export const UPDATE_FIRST = 'Please take update before submitting the change request. Some other user might have changed this itinerary as well.';
export const GENERIC_EXCEPTION = 'Generic Customer Web Application Exception.';
export const INVALID_FEEDBACK = 'The feedback identifier is expired.';
export const FEEDBACK_EXIT = 'Feedback already exist.';
export const CANNOT_ACCESS_STATEMENTS = 'You do not have access to the Statement PDF.';
export const NO_RELATED_INVOICES = 'The request has no related invoices.';

export const GROUND_TRANSPORT_NOT_FOUND = `No ground transportation arrangements have been requested at this time. Should you require a
  driver to greet you on arrival, or wish to bring your own vehicle into the terminal, we will
  happily organise this for you where available.`;

export const OTHER_SERVICES_NOT_FOUND = 'No additional services have been requested at this time.';
export const NO_SERVICES = 'No services are currently available in this location';
export const CATERING_NOT_FOUND = 'We would be delighted to accommodate any menu preferences you may have. In case no specific requirements are requested, menu suitable to the time of day will be provided.';
export const CATERING_NOT_AVAILABLE = 'Catering information is not available at this time.';

export const FBO_NOT_FOUND = 'The FBO address cannot be found at this time. Would you like directions to the airport instead?';
export const FBO_NOT_AVAILABLE = 'No FBO information available at this time.';

export const CREW_NOT_FOUND = 'Crew information will be made available 24 hours before the flight.';
export const CREW_NOT_AVAILABLE = 'Crew information is not currently available.';
export const CREW_WILL_BE_AVAILABLE = 'Crew information will be made available before the flight';

export const PASSENGER_NOT_FOUND = 'No passenger found.';
export const PASSENGER_EXCEED = '{Aircraft} can carry up to {newLimit} passengers. Please reduce the number passengers or change aircraft type.';
export const PASSENGER_EXCEED_IN_OFFERS = '{Aircraft} can carry up to {newLimit} passengers. Please reduce the number of passengers.';
export const PASSENGER_FUEL_STOP = 'Please be advised that this trip may require a fuel stop';
export const PASSENGER_EXCEED_AIRCRAFT_CHANGE = 'Passenger count can not be less than the number of added passengers. Please consider removing passengers from the list.'
export const PASSENGER_CANT_BE_REDUCED = 'Passenger count cannot be less than the number of added passengers. Please consider removing passengers from the list below.';
export const MOVEMENT_MESSAGE_NOT_FOUND = 'Latest flight information will be available on departure.';

export const TIMEOUT = 'Server not responding.';
export const UNAUTHORIZED_FLIGHT_REQUEST = 'You are not authorized to request a flight. Please contact us for further assistance';

export const ADD_LEG_OFFER = 'Adding an extra leg will require sending the request to VistaJet\'s sales team for quoting. Do you wish to continue?'; // eslint-disable-line max-len
export const MAP_ERROR = 'Map service is currently unavailable in your country';
export const noAirportAvailableMessage = 'The airport searched for cannot be found, please modify your search and try again'; // eslint-disable-line max-len
export const enableLocationMessage = 'No airports available, Please enable location services to view nearby airports'; // eslint-disable-line max-len
export const RESEND_EMAIL_CONFIRM = 'Thank you, the email has been sent.';

export const USER_ALREADY_EXISTS = 'User already exists';
export const PHONE_NUMBER_ALREADY_EXISTS = 'Phone Number already exists';
export const USER_NOT_AUTHENTICATED = 'User not authenticated';
export const LOGIN_FAILED = 'We could not log you in. Please check your username and password and try again';
export const PAYMENT_NOT_DEFINED = 'Payment Method not defined';

export const DEPARTURE_AIRPORT_EXCEPTION = "Please select departure airport";
export const ARRIVAL_AIRPORT_EXCEPTION = "Please select arrival airport";
export const DEPARTURE_ARRIVAL_AIRPORT_EXCEPTION = "Please select departure and arrival airport";
export const SAME_ARRIVAL_DEPARTURE_EXCEPTION = `The departure and arrival airport cannot be the same. Please try again`;
export const MIN_HOUR_EXPECPTION = `Please allow a minimum of four hours between booking time and flight departure. Alternatively, please call us for more information on this flight request`;
export const OUTSIDE_TIMEZONE_EXCEPTION = `Your flight date and time falls outside allowable timezone of airport. Please select a different date`;
export const FILL_FIELDS_EXCEPTION = "Please fill out all the fields in red to continue";
export const TIME_CLASH_EXCEPTION = "The selected date or time clashes with another of your flight requests";

export const AIRCRAFT_NOT_IN_CONTRACT = "Selecting a non-contract aircraft will have interchange rate";
export const FILL_AIRCRAFT_EXCEPTION = "Please select aircraft to continue";
export const FILL_TIME_EXCEPTION = "Please select time to continue";
export const FILL_DATE_EXCEPTION = "Please select date to continue";
export const FILL_CONTRACT_EXCEPTION = "Please select contract to continue";
export const USER_PROFILE_SUCCESSFULLY_UPDATED = 'Your account details have been updated';
export const EMAIL_ALREADY_EXISTS = 'Email already exists';
export const PASSWORD_UPDATE_SUCCESS = 'Thank You. Your password has been changed.';
export const PASSPORT_ADDED = 'Passport added successfully';
export const PASSPORT_UPDATED = 'Passport updated successfully';
export const PASSPORT_DELETED = 'Passport deleted successfully';
export const DEFAULT_PASSPORT_UPDATED = 'Default passport updated successfully';
export const PASSPORT_UPDATE_MESSAGES = [
  USER_PROFILE_SUCCESSFULLY_UPDATED,
  PASSPORT_ADDED,
  PASSPORT_UPDATED,
  PASSPORT_DELETED,
  DEFAULT_PASSPORT_UPDATED,
];
export const CREDIT_CARD_MESSAGE = {
  1: 'Your credit card AMERICAN EXPRESS-',
  4: 'Your credit card MASTER-',
  5: 'Your credit card VISA-',
};
export const CARD_TYPE_NOT_SUPPORTED = "Card type not supported";

export const BACK_TO_OFFER = "You are back to your offer."
export const OFFER_NOT_APPLIED = "Please be aware that the {offerType} offer will not be applied.";
export const FUEL_STOP_WARNING = "Please be advised that this trip may require a fuel stop.";
export const UPDATE_SAVED = 'Update Saved';
export const CITY_ALREADY_ADDED = 'City already added';

export const INVALID_DETAILS_EXCEPTION = "Invalid details provided";
export const THANK_YOU_PLAN_A_FLIGHT = "We have received your flight request, which is under review by our operations team. Our team is working on it and will contact you shortly."
export const THANK_YOU_REQUEST_QUOTE_ADHOC = <React.Fragment>You will shortly receive a notification with your quotation details. <br />Please note that all quotes are subject to availability at the time of request.</React.Fragment>
export const THANK_YOU_REQUEST_QUOTE_VJ = <React.Fragment>We have received your flight request which is under review by our operations team. Our team is working on it and will contact you shortly.</React.Fragment>
export const THANK_YOU_OFFER = <React.Fragment>You will shortly receive a notification with your booking arrangement. <br />Please note that flight confirmation requires receipt of funds. We are now verifying funds on your card, and we will only request the due amount when your flight is confirmed by the operator.</React.Fragment>
export const NO_FLIGHT_LEG_ORDER_FOUND = 'No Leg Details Found Against Given Order Id.';
export const NO_LEG_MESSAGE = 'No flight order and legs found against the given order id. ';

export const THANK_YOU_PASSWORD_CHANGED = 'Thankyou. Your password has been reset successfully.';

export const GROUNDTIME_VALIDATION_MESSAGE = 'There are restrictions on the departure time for this flight. The latest you can depart and stay within the offer terms is {time}. Please amend your departure time.';

export const ACCOUNT_EXPIRY_MESSAGE = 'Your program account has expired. Please contact your VistaJet sales VP to renew.';

export const OFFER_QUOTING_NOT_AVAILABLE = "Offer conditions not matched. Would you like to proceed with this request?";
export const MEMBERSHIP_EXPIRY_MESSAGE_VJDIRECT = 'Your direct membership is pending';
export const FSP_VJDIRECT_EXPIRY_MESSAGE = 'Your direct membership is pending and your program account has expired. Please contact your VistaJet sales VP to renew.';

export const ACCOUNT_EXPIRY_MESSAGE_RAF = 'Your Program Account has Expired. Please contact a VistaJet Program Executive';

export const OFFERS_VALIDATION_MESSAGE = 'One Way or Empty Leg flights can only be booked with a valid VistaJet' +
  'Direct Membership. Would you like to sign up for VistaJet Direct?'
export const MEMBERSHIP_EXPIRY_MESSAGE_VJDIRECT_OFFERS = 'Please wait until your membership is activated before purchasing an offer.';

export const INVOICES_NOT_FOUND_MESSAGE = `The request has no related invoices.`;

export const RECORD_NOT_FOUND_MESSAGE = `Record not found`;

export const PHONE_NUMBER_NOT_FOUND_MESSAGE = `Phone number not found.`;

export const USER_NOT_FOUND_MESSAGE = `User not found.`;

export const DEVICE_ID_NULL_MESSAGE = `Device id can't be null.`;

export const PHONE_NUMBER_NULL_MESSAGE = `Phone number can't be null.`;

export const USERNAME_NULL_MESSAGE = `Username can't be null.`;

export const RECORD_NOT_UPDATE_MESSAGE = `Record failed to update.`;

export const ACCOUNT_BLOCKED_MESSAGE = `An error occurred accessing the app on this device. Please contact VistaJet.`;

export const INVALID_SIGNATURE_MESSAGE = `Invalid signature.`

export const TIME_MISMATCH_MESSAGE = `Time mismatch.`;

export const MISSING_PARAMETER_MESSAGE = `Missing parameter.`;

export const USER_ALREADY_REGISTERED_MESSAGE = `User account details already exists. Please login or select forgotten password`;

export const INVALID_SOURCE_MESSAGE = `Invalid source`;

export const INVALID_TIME_MESSAGE = `Invalid time`;

export const REQUIRE_FUELSTOP = 'This flight may require a fuel stop.';

export const EMAIL_NUMBER = '+44 (0) 207 060 5700';
