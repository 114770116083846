import { guaranteeArray } from "../utils/arrayUtils";
import { mapLegStatus } from "../utils/PAFUtils";
export const normalizeFlightLegIdentifiers = (flightLegs) => {
  const flightLeg = guaranteeArray(flightLegs.flightLeg);
  const ids = [];
  flightLeg.forEach((leg) => {
    ids.push(leg.flightLegExtIdentifier);
  });
  return ids;
};

export const normalizeLegStatus = (flightLegs) => {
  const flightLeg = guaranteeArray(flightLegs.flightLeg);
  const status = mapLegStatus(flightLeg[0].legStatus);
  return status;
};
