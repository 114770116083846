import { connect } from "react-redux";

import withStore from "components/withStore";
import PAFLayout from "../components/PlanAFlight/PAFLayout";
import { validateAllFlights } from "../store/actions/ValidateFlightActions";

import { fetchAllVisitedAirports } from "store/actions/VisitedAirportsActions";
import {
  getAccountStatusAction,
  updateUserReducer,
  resetFlightErrorMessages,
  onGoogleMapsApiLoaded,
  saveBillingAddressAction,
  getBillingAddress,
  fetchPassengers,
} from "../store/actions/";

import {
  updateContractSelected,
  updateDepartureAirport,
  updateArrivalAirport,
  updateDate,
  updateSelectedAircraft,
  updatePassengersCounter,
  updateDuration,
  updateTime,
  updateEstimateTime,
  addLeg,
  checkContractSelected
} from "store/actions/PAFActions";
import {
  updateQuoteContent,
  buyOfferQuote,
  fetchItineraryDetails,
  getAirportById,
} from "../store/actions";

import {
  requestAllQuote,
  createCompanies
} from "../store/actions/RequestQuoteActions";
import {
  getAllCreditCards,
  addCreditCardAction
} from "../store/actions/CreditCardActions";
function mapStateToProps(state) {
  const {
    allLegs: itineraryLegs,
    isLoading: isItineraryLoading,
    accountId: itineraryAccountId,
    itineraryDetailsError,
    orderExtIdentifier,
    orderStatus: itineraryOrderStatus,
  } = state.itineraryDetails;
  const {
    visitedAirports,
    isAirportsFetched,
    visitedAirportsFinder,
    isAirportByIdLoading,
  } = state.visitedAirports;
  const {
    isValidateFlightLoading,
    validateFlightMessages,
    isProposalOrRestriction
  } = state.validateFlight;
  const {
    isContractSelected,
    allowAircraftSelect,
    allowAircraftErrMessage,
    accountId,
    legArray,
    error,
    orderIdentifiers,
    isRequestFlightLoading,
    requestQuoteSuccess,
    requestFlightSuccess,
    buyOfferSuccess,
    orderStatus,
    quoteContent,
    orderId,
    confirmationMessage,
    isCreateCompanyLoading,
  } = state.PAFData;
  const { aircraftsList } = state.aircrafts;
  const { user,
    isAccountDataFetched,
    billingAddress,
    isLoading: isBillingAddressLoading,
    error: isBillingAddressError,
  } = state.user;
  const {
    isAdded: isCreditCardAdded,
    isLoading: isCreditCardLoading,
    error: creditCardError,
    creditCards
  } = state.creditCardReducer;
  const userToken = user ? user.userToken : null;
  const { passengers, isLoadingPassenger, updatePassenger } = state.passengers;
  const { isGoogleMapsApiLoaded } = state.googleMap;
  return {
    validateFlightMessages,
    isProposalOrRestriction,
    visitedAirports,
    isAirportsFetched,
    isContractSelected,
    allowAircraftSelect,
    allowAircraftErrMessage,
    accountId,
    legArray,
    userToken,
    isAccountDataFetched,
    error,
    orderIdentifiers,
    user,
    isValidateFlightLoading,
    isRequestFlightLoading,
    requestQuoteSuccess,
    requestFlightSuccess,
    orderStatus,
    quoteContent,
    isCreditCardAdded,
    isCreditCardLoading,
    creditCardError,
    orderId,
    creditCards,
    itineraryLegs,
    isItineraryLoading,
    visitedAirportsFinder,
    isAirportByIdLoading,
    itineraryAccountId,
    aircraftsList,
    itineraryDetailsError,
    orderExtIdentifier,
    buyOfferSuccess,
    isGoogleMapsApiLoaded,
    billingAddress,
    isBillingAddressLoading,
    isBillingAddressError,
    confirmationMessage,
    passengers,
    isLoadingPassenger,
    updatePassenger,
    itineraryOrderStatus,
    isCreateCompanyLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateContractSelected: accountId =>
      dispatch(updateContractSelected(accountId)),
    updateDepartureAirport: departureAirport =>
      dispatch(updateDepartureAirport(departureAirport)),
    updateArrivalAirport: arrivalAirport =>
      dispatch(updateArrivalAirport(arrivalAirport)),
    fetchVisitedAirports: () => dispatch(fetchAllVisitedAirports()),
    updateSelectedAircraft: selectedAircraftDetails =>
      dispatch(updateSelectedAircraft(selectedAircraftDetails)),
    updateDate: dateDetails => dispatch(updateDate(dateDetails)),
    updatePassengersCount: updatePassengersCount =>
      dispatch(updatePassengersCounter(updatePassengersCount)),
    updateDuration: legIndex => dispatch(updateDuration(legIndex)),
    updateTime: timeDetails => dispatch(updateTime(timeDetails)),
    addLeg: () => dispatch(addLeg()),
    updateEstimateTime: timeObject => dispatch(updateEstimateTime(timeObject)),
    checkContractSelected: () => dispatch(checkContractSelected()),
    validateAllFlights: (legArray, accountId, isOffer, isEdit, order, passengers) =>
      dispatch(validateAllFlights(legArray, accountId, isOffer, isEdit, order, passengers)),
    getAccountStatusAction: token => dispatch(getAccountStatusAction(token)),
    updateUserReducer: userData => dispatch(updateUserReducer(userData)),
    requestAllQuote: (legArray, userInfo) =>
      dispatch(requestAllQuote(legArray, userInfo)),
    createCompanies: legs => dispatch(createCompanies(legs)),
    getCreditCardList: () => dispatch(getAllCreditCards()),
    addCreditCardAction: (token, payload) =>
      dispatch(addCreditCardAction(token, payload)),
    updateQuoteContent: () => dispatch(updateQuoteContent()),
    buyOfferQuote: (legs, quoteDetails, paymentOptionId, creditCard, isQuote) =>
      dispatch(buyOfferQuote(legs, quoteDetails, paymentOptionId, creditCard, isQuote)),
    fetchItineraryDetails: (token, key, params) => dispatch(fetchItineraryDetails(token, key, params)),
    getAirportById: id => dispatch(getAirportById(id)),
    resetFlightErrorMessages: () => dispatch(resetFlightErrorMessages()),
    onGoogleMapsApiLoaded: () => dispatch(onGoogleMapsApiLoaded()),
    getBillingAddress: () => dispatch(getBillingAddress()),
    saveBillingAddress: (address) => dispatch(saveBillingAddressAction(address)),
    fetchPassengers: (token, data) => dispatch(fetchPassengers(token, data)),
  };
}

const PlanAFlightContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PAFLayout);

export default withStore(PlanAFlightContainer);
