import React, { Component } from 'react';
import CreditCardInputNumber from '../shared/CreditCardInputNumber';
import MaskedTextField from '../shared/MaskedTextField';
import MessagePopUp from '../shared/MessagePopUpNew';
import Select from 'react-select';
import { NEW_CARD_TYPES } from '../../constants/CreditCardConstants';
import { replaceSpecialCharacterAndSpaces } from '../../utils/stringUtils';
import validateAddCreditCardDetailsForm from '../../utils/Validators/AddCreditCardDetailsValidator';
import { USER_REDUCER } from '../../constants/localStorageKeys';
import { getFromLocal } from '../../utils/cache';
import SpinnerLoader from '../shared/spinnerLoader';
import ConfirmationPopUp from '../shared/ConfirmationPopUpNew';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import { CONFIRMATION_TITLE, CONFIRMATION_MESSAGE, LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT } from '../../constants/DirtyPopupConstant';
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants';

class AddCreditCardModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      creditCardNumber: '',
      cvv: '',
      expiryDate: '',
      typeOfCardSelected: NEW_CARD_TYPES[1],
      defaultChecked: false,
      errors: {},
      popupMessage: '',
      open: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.creditCardError !== nextProps.creditCardError) {
      this.setState({ popupMessage: nextProps.creditCardError })
      if (nextProps.creditCardError) {
        this.messagePopup.show();
      }
    }
    if (nextProps.isCreditCardAdded) {
      this.clearState();
    }
  }

  show = () => {
    this.setState({ open: true });
  };

  hide = () => {
    const isAddCardDirty = dirtystateSingleTon.getDirty(SPAConstants.AddCreditCard);
    if (isAddCardDirty) {
      this.cardConfirmPopup.show();
    } else {
      this.hideAndClearModal();
    }
  };

  hideAndClearModal = () => {
    this.cardConfirmPopup.hide();
    this.clearState();
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleCreditCardNumberChange = (e) => {
    dirtystateSingleTon.setDirty(SPAConstants.AddCreditCard, true);
    const number = replaceSpecialCharacterAndSpaces(e.target.value);
    this.setState({ creditCardNumber: number });
  };

  handleCVVChange = (e) => {
    dirtystateSingleTon.setDirty(SPAConstants.AddCreditCard, true);
    this.setState({ cvv: e.target.value });
  };

  handleExpiryDateChange = (e) => {
    dirtystateSingleTon.setDirty(SPAConstants.AddCreditCard, true);
    this.setState({ expiryDate: e.target.value });
  };

  handleCardTypeChange = (obj) => {
    dirtystateSingleTon.setDirty(SPAConstants.AddCreditCard, true);
    this.setState({ typeOfCardSelected: obj });
  }

  handleDefaultCheck = () => {
    const { defaultChecked } = this.state;
    this.setState({
      defaultChecked: !defaultChecked,
    });
  }

  clearState = () => {
    dirtystateSingleTon.setDirty(SPAConstants.AddCreditCard, false);
    this.setState({
      creditCardNumber: '',
      cvv: '',
      expiryDate: '',
      typeOfCardSelected: NEW_CARD_TYPES[1],
      defaultChecked: false,
      errors: {},
      popupMessage: '',
    });
  }

  confirmation = () => {
    const { creditCards } = this.props;
    const { creditCardNumber } = this.state;
    const lengthCC = creditCardNumber.length;
    const lastFour = creditCardNumber.substring(lengthCC - 4, lengthCC)
    let found = false;
    if (creditCards && Object.keys(creditCards)) {
      creditCards.ids.some(id => {
        if ((creditCards.content[id].cardNumberLastFour).toString() === lastFour.toString()) {
          this.confirmPopup.show();
          found = true;
        }
      });
    }
    if (!found) {
      this.addCreditCard();
    }
  }

  validateForm = () => {
    const {
      creditCardNumber,
      cvv,
      expiryDate,
    } = this.state;
    const res = validateAddCreditCardDetailsForm({
      creditCardNumber,
      cvv,
      expiryDate,
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      this.confirmation();
    }
  }

  addCreditCard = () => {
    const {
      creditCardNumber,
      cvv,
      expiryDate,
      defaultChecked,
      typeOfCardSelected,
    } = this.state;
    const requestPayload = {
      creditCardNumber,
      cvv,
      expiryDate,
      defaultChecked,
      type: typeOfCardSelected.label,
    };
    const {
      addCreditCardAction,
    } = this.props;
    const { user } = getFromLocal(USER_REDUCER, true, true);
    const token = user.userToken;
    addCreditCardAction(token, requestPayload);
    this.clearState();
  }

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  renderConfirmationContent = () => {
    const { creditCardNumber } = this.state;
    const lengthCC = creditCardNumber.length;
    const lastFour = creditCardNumber.substring(lengthCC - 4, lengthCC)
    return (
      <div>
        {this.props.isCreditCardLoading ? (
          <div className="vistajet_spinner">
            <SpinnerLoader />
          </div>
        ) : (<div>
          You already have a card ending with {lastFour}. Are you sure you want to add this card?
            </div>)}
      </div>
    );
  };

  render() {
    const {
      className,
      isCenter,
      closeOnEsc,
      closeOnOverlayClick,
      onExited,
      styles,
      showCloseIcon,
      closeIconSize,
      closeIconSvgPath,
      focusTrapped,
      id,
      isCreditCardLoading,
    } = this.props;
    const {
      creditCardNumber,
      cvv,
      expiryDate,
      typeOfCardSelected,
      defaultChecked,
      errors,
      popupMessage,
      open,
    } = this.state;
    const typeOfCardOptions = NEW_CARD_TYPES;
    const addCreditCardButtonText = isCreditCardLoading ? 'Loading...' : 'Save Changes';
    return (
      <Modal
        open={open}
        classNames={{
          overlay: 'vistajet_modal vistajet_modal_large vistajet_add_credit_card_modal',
          modal: 'vistajet_modal_body vistajet_react_modal',
          closeButton: 'vistajet_modal_close',
          closeIcon: '<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg>'
        }}
        closeOnOverlayClick={closeOnOverlayClick}
        onClose={this.hide}
        center={isCenter}
        closeOnEsc={closeOnEsc}
        onExited={onExited}
        styles={styles}
        showCloseIcon={showCloseIcon}
        closeIconSize={closeIconSize}
        closeIconSvgPath={closeIconSvgPath}
        focusTrapped={focusTrapped}
      >
        <div id={id}>
          <div>
            <div className="vistajet_modal_header_fix">
              <h4 className="uk-text-center">Add Credit Card</h4>
            </div>

            <div className="vistajet_modal_content vistajet_primary_form uk-width-1-1 uk-margin-medium">
              <div className="uk-grid-medium uk-margin-small-top uk-margin-small-bottom" data-uk-grid>
                <div className="uk-width-1-1@s">
                  <div className="vistajet_input_wrapper">
                    <CreditCardInputNumber
                      id="creditCardNumber"
                      value={creditCardNumber}
                      onChange={this.handleCreditCardNumberChange}
                      disabled={false}
                      onFocus={this.removeErrors}
                    />
                    {
                      errors.errors && errors.errors.creditCardNumber && (
                        <p>
                          {errors.errors.creditCardNumber}
                        </p>
                      )
                    }
                  </div>
                </div>
                <div className="uk-width-1-2@s uk-margin-remove">
                  <div className="vistajet_input_wrapper">
                    <MaskedTextField
                      id="cvv"
                      value={cvv}
                      onChange={this.handleCVVChange}
                      onFocus={this.removeErrors}
                      mask={[/\d/, /\d/, /\d/, /\d/]}
                      guide={false}
                    />
                    <label htmlFor="cvv">
                      CVV
                    </label>
                    {
                      errors.errors && errors.errors.cvv && (
                        <p>
                          {errors.errors.cvv}
                        </p>
                      )
                    }
                  </div>
                </div>
                <div className="uk-width-1-2@s uk-margin-remove">
                  <div className="vistajet_input_wrapper">
                    <MaskedTextField
                      id="expiryDate"
                      value={expiryDate}
                      onChange={this.handleExpiryDateChange}
                      onFocus={this.removeErrors}
                      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                    />
                    <label htmlFor="expiryDate">
                      Expiry date (MM/YY)
                    </label>
                    {
                      errors.errors && errors.errors.expiryDate && (
                        <p>
                          {errors.errors.expiryDate}
                        </p>
                      )
                    }
                  </div>
                </div>
                <div className="uk-width-1-2@s uk-margin-remove">
                  <div className="vistajet_input_wrapper">
                    <Select
                      classNamePrefix="react-select"
                      className="vistajet_select_input"
                      options={typeOfCardOptions}
                      value={typeOfCardSelected}
                      onChange={this.handleCardTypeChange}
                    />
                    <label htmlFor="-">
                      Type of Card
                    </label>
                    <i uk-icon="icon: chevron-down; ratio: 1" />
                  </div>
                </div>
                <div className="uk-width-1-1@s uk-text-left uk-margin-remove">
                  <label for="">
                    <input
                      id="defaultChecked"
                      className="uk-checkbox uk-margin-small-right"
                      type="checkbox"
                      checked={defaultChecked}
                      onClick={this.handleDefaultCheck}
                      onFocus={this.removeErrors}
                    />
                    Set as default card
                    </label>
                </div>
              </div>
            </div>

            <div className="vistajet_modal_footer">
              <button
                className="uk-button uk-button-default uk-width-1-1 uk-text-center"
                value={addCreditCardButtonText}
                disabled={isCreditCardLoading}
                onClick={this.validateForm}
              >
                {'Save Changes  '}
                {isCreditCardLoading ? (
                  <div className="vistajet_spinner">
                    <SpinnerLoader />
                  </div>
                ) : null}
              </button>
            </div>
          </div>
          <MessagePopUp
            id="creditCardErrorPopUp"
            ref={(messagePopup) => { this.messagePopup = messagePopup; }}
            content={popupMessage}
          />
          <ConfirmationPopUp
            title="Confirmation"
            ref={(confirmPopup) => { this.confirmPopup = confirmPopup; }}
            id="addCreditCardConfirmationPopup"
            leftButtonContent="No"
            rightButtonContent="Yes"
            onLeftButtonClick={() => this.confirmPopup.hide()}
            onRightButtonClick={this.addCreditCard}
            content={this.renderConfirmationContent()}
          />
          <ConfirmationPopUp
            ref={cardConfirmPopup => { this.cardConfirmPopup = cardConfirmPopup }}
            title={CONFIRMATION_TITLE}
            content={CONFIRMATION_MESSAGE}
            leftButtonContent={LEFT_BUTTON_TEXT}
            rightButtonContent={RIGHT_BUTTON_TEXT}
            onRightButtonClick={() => this.hideAndClearModal()}
            onLeftButtonClick={() => this.cardConfirmPopup.hide()}
          />
        </div>
      </Modal>
    );
  }
}
AddCreditCardModal.defaultProps = {
  isCenter: true,
  closeOnEsc: false,
  closeOnOverlayClick: false,
  showCloseIcon: true,
  focusTrapped: true,
  // closeIconSize: 30,
  onExited: () => { },
  // closeIconSvgPath: <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg>,

};
export default AddCreditCardModal;
