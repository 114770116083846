import React, { Component } from 'react';

class MessagePopUp extends Component {
  render() {
    const {
      id, content
    } = this.props;

    return (
      <div id={id} className="vistajet_modal" stack="true" data-uk-modal bg-close="true" esc-close="false">
        <div className="uk-modal-dialog uk-modal-body vistajet_modal_body uk-margin-auto-vertical">
          <button className="uk-modal-close-default vistajet_modal_close" type="button" data-uk-close />
          <div className="vistajet_modal_content">
            <p className="uk-text-center">
              {content}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MessagePopUp;
