import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import ReactImageFallback from 'react-image-fallback';

const placeholderLarge = '/legacy/Static/img/thumbnails/placeholder-image-large.png';
const placeholderSmall = '/legacy/Static/img/thumbnails/placeholder-image-small.png';

class Entertainment extends Component {
  constructor(props) {
    super(props);
    this.state = {
        itemDetailView: false,
        item: {},
        type: '',
        legId: '',
    };
  }

  componentDidMount() {
    const { fetchEntertainmentIssue } = this.props;
    const params = window.location.search;
    const entertainmentDetailsParam = params.split('/');
    const tailNumber = entertainmentDetailsParam[entertainmentDetailsParam.length - 1];
    const legId = entertainmentDetailsParam[entertainmentDetailsParam.length - 4];
    this.setState({ legId });
    fetchEntertainmentIssue(tailNumber, legId);
        
  }

  componentWillReceiveProps(nextProps) {
    const { fetchEntertainment, entertainment } = nextProps;
    const params = window.location.search;
    const entertainmentDetailsParam = params.split('/');
    const tailNumber = entertainmentDetailsParam[entertainmentDetailsParam.length - 1];
    const legId = entertainmentDetailsParam[entertainmentDetailsParam.length - 4];
    const issueNumber = entertainment[legId] && entertainment[legId].entertainmentIssue && entertainment[legId].entertainmentIssue.issue;
    if(issueNumber) {
      fetchEntertainment(issueNumber, tailNumber, legId);
    }
  }

  handleDetailsToggle = (item, type) => {
    this.setState({ itemDetailView: true,
        item,
        type 
    });
  }

  handleBack = () => {
    this.setState({ itemDetailView: false });
  }

  renderItemDetails = (item, type) => {
      const trailerDetail = item && item.trailer && item.trailer.split('/');
      const trailerId = trailerDetail && trailerDetail[trailerDetail.length - 1];
      const trailer = trailerDetail && trailerId ? `https://youtube.com/embed/${trailerId}` : '';
      if (type === 'movie') {
        return (
        <div class="uk-width-1-1">
            <div class="vistajet_bs_card vistajet_itineraries_entertainment_card">
                <div class="vistajet_entertainment_header">
                    <a href="#" class="uk-modal-close" onClick={() => this.handleBack()}><span>Back</span></a>
                    <h4 class="uk-text-center vistajet_color_red">{item && item.title}</h4>
                </div>
                <div class="vistajet_bs_body uk-margin-medium-top">
                    <iframe width="100%" height="350" src={trailer || ''} frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="true"></iframe>
                    <div class="vistajet_entertainment_movie_details">
                        <div class="vistajet_entertainment_movie_tags"> 
                            <p className="uk-width-expand">Classification: {item.rated} | {item.runtime}| Genre: {item.genre} | {item.released}</p>
                            <div className="vistajet_entertainment_ratings"> 
                                <h5>{item.imdbRating}<span>/10</span></h5> 
                            </div>
                        </div>
                        <p>{item.plot || ''}</p>
    
                        <p>
                            { item.director ? <span class="uk-display-block"><strong>Director:</strong>{ item.director }</span> : ''}
                            { item.writer ? <span class="uk-display-block"><strong>Writers:</strong>{ item.writer }</span> : ''}
                            { item.country ? <span class="uk-display-block"><strong>Country:</strong>{ item.country }</span> : ''}
                            { item.awards ? <span class="uk-display-block"><strong>Awards:</strong>{ item.awards }</span> : ''}
                            { item.actors ? <span class="uk-display-block"><strong>Stars:</strong>{ item.actors }</span> : ''}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        );
      } else if (type === 'game') {
        return (
        <div class="uk-width-1-1">
            <div class="vistajet_bs_card vistajet_itineraries_entertainment_card">
                <div class="vistajet_entertainment_header">
                    <a href="#" class="uk-modal-close" onClick={() => this.handleBack()}><span>Back</span></a>
                    <h4 class="uk-text-center vistajet_color_red">{item && item.title}</h4>
                </div>
                <div class="vistajet_bs_body uk-margin-medium-top">
                    {
                        trailer ?  <iframe width="100%" height="350" src={trailer} frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="true"></iframe> : null
                    }
                   
                    <div class="vistajet_entertainment_movie_details">
                        <div class="vistajet_entertainment_movie_tags">
                            <p className="uk-width-expand">Genre: {item.genre} | Age Rating: {item.ageRating} | No. of Players: {item.nOfPlayers}</p>
                            <div className="vistajet_entertainment_ratings"> 
                                <h5>{item.rating}<span>/10</span></h5> 
                            </div>
                        </div>
                        <p>{item.description || ''}</p>
    
                        <p>
                            { item.developer ? <span class="uk-display-block"><strong>Developer:</strong>{item.developer}</span> : ''}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        );
      } else if (type === 'tvshow') {
        return (
        <div class="uk-width-1-1">
            <div class="vistajet_bs_card vistajet_itineraries_entertainment_card">
                <div class="vistajet_entertainment_header">
                    <a href="#" class="uk-modal-close" onClick={() => this.handleBack()}><span>Back</span></a>
                    <h4 class="uk-text-center vistajet_color_red">{item && item.title}</h4>
                </div>
                <div class="vistajet_bs_body uk-margin-medium-top">
                    <div class="vistajet_entertainment_movie_details">
                        <div class="vistajet_entertainment_movie_tags">
                            <p className="uk-width-expand">Classification: {item.rated} | {item.runtime}| Genre: {item.genre} | {item.released}</p>
                            <div className="vistajet_entertainment_ratings"> 
                                <h5>{item.imdbRating}<span>/10</span></h5> 
                            </div>
                        </div>
                        <p>{item.plot || ''}</p>
                        <p>
                            { item.director ? <span class="uk-display-block"><strong>Director:</strong>{ item.director }</span> : ''}
                            { item.writer ? <span class="uk-display-block"><strong>Writers:</strong>{ item.writer }</span> : ''}
                            { item.country ? <span class="uk-display-block"><strong>Country:</strong>{ item.country }</span> : ''}
                            { item.awards ? <span class="uk-display-block"><strong>Awards:</strong>{ item.awards }</span> : ''}
                            { item.actors ? <span class="uk-display-block"><strong>Stars:</strong>{ item.actors }</span> : ''}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        );
      }
  }

  renderEntertainmentDetails = (entertainmentDetails, isLoadingEntertainment, isLoading) => {
    return (
        <div class="uk-width-1-1">
        <div class="vistajet_bs_card vistajet_itineraries_entertainment_card">
            <div class="vistajet_entertainment_header">
                <h4 class="uk-text-center vistajet_color_red">Entertainment</h4>
            </div>
            {
                !isLoading && !isLoadingEntertainment ? 
                (
                    <div class="vistajet_bs_body">
                        <div class="vistajet_entertainment_column">
                            <h4>Movies</h4>
                            <div uk-slider="center: false; finite: true;">
                                <div class="uk-position-relative uk-visible-toggle uk-dark" tabindex="-1">
                                    <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-2@s">
                                    { entertainmentDetails.movies ? entertainmentDetails.movies.map(movie => (
                                            <li>
                                                <a href="#" onClick={() => this.handleDetailsToggle(movie, 'movie')} class="uk-card">
                                                    <div class="uk-card-media-top uk-text-center">
                                                        <ReactImageFallback
                                                            src={movie.poster}
                                                            fallbackImage={placeholderLarge}
                                                            alt="entertainment poster"
                                                        />
                                                    </div>
                                                    <h5 class="uk-card-title uk-text-center">{movie.title}</h5>
                                                </a>
                                            </li>    
                                            )) : null
                                        }    
                                    </ul>

                                    <a class="uk-position-center-left vistajet_slider_arrow_left" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                                    <a class="uk-position-center-right vistajet_slider_arrow_right" href="#" uk-slidenav-next uk-slider-item="next"></a>
                                </div>
                            </div>
                        </div>

                        <div class="vistajet_entertainment_column">
                            <h4>TV Shows</h4>
                            <div uk-slider="center: false; finite: true;">
                                <div class="uk-position-relative uk-visible-toggle uk-dark" tabindex="-1">
                                    <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-2@s">
                                    { entertainmentDetails.tvshows ? entertainmentDetails.tvshows.map(tvshow => (
                                            <li>
                                                <a href="#" onClick={() => this.handleDetailsToggle(tvshow, 'tvshow')} class="uk-card">
                                                    <div class="uk-card-media-top uk-text-center">
                                                        <ReactImageFallback
                                                            src={tvshow.poster}
                                                            fallbackImage={placeholderLarge}
                                                            alt="entertainment poster"
                                                        />
                                                    </div>
                                                    <h5 class="uk-card-title uk-text-center">{tvshow.title}</h5>
                                                </a>
                                            </li>    
                                            )) : null
                                        }    
                                    </ul>

                                    <a class="uk-position-center-left vistajet_slider_arrow_left" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                                    <a class="uk-position-center-right vistajet_slider_arrow_right" href="#" uk-slidenav-next uk-slider-item="next"></a>
                                </div>
                            </div>
                        </div>

                        <div class="vistajet_entertainment_column">
                            <h4>Games</h4>
                            <div uk-slider="center: false; finite: true;">
                                <div class="uk-position-relative uk-visible-toggle uk-dark" tabindex="-1">
                                    <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-2@s vistajet_entertainment_game_section">
                                        { entertainmentDetails.games ? entertainmentDetails.games.map(game => (
                                            <li>
                                                <a href="#" onClick={() => this.handleDetailsToggle(game, 'game')} class="uk-card">
                                                    <div class="uk-card-media-top uk-text-center">
                                                        <ReactImageFallback
                                                            src={game.poster}
                                                            fallbackImage={placeholderSmall}
                                                            alt="entertainment poster"
                                                        />
                                                    </div>
                                                    <h5 class="uk-card-title uk-text-center">{game.title}</h5>
                                                </a>
                                            </li>    
                                            )) : null
                                        }                   
                                    </ul>
                                    <a class="uk-position-center-left vistajet_slider_arrow_left" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                                    <a class="uk-position-center-right vistajet_slider_arrow_right" href="#" uk-slidenav-next uk-slider-item="next"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                ( 
                    <div className="vistajet_spinner uk-height-medium">
                        <SpinnerLoader />
                    </div>
                )
            }
        </div>
    </div>
    );
  }
  render() {
    const { entertainment, isLoadingEntertainment, isLoading } = this.props;
    const { itemDetailView,
            item,
            type,
            legId
        } = this.state;
    const entertainmentDetails = entertainment && entertainment[legId] && entertainment[legId].entertainment || {};
    return (
        <div>
            {  
                !itemDetailView ?
                this.renderEntertainmentDetails(entertainmentDetails, isLoadingEntertainment, isLoading) :
                this.renderItemDetails(item, type)    
            }
        </div>
    );   
  }
}

export default Entertainment;
