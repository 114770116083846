import CryptoJS from 'crypto-js';
import { DEFAULT_FLAG, SIGNUP_CONTEXT, SIGNUP_CONTEXT_PASSWORD } from '../configs/constants.js';

const decryptSingupKeys = () => {
    const signUpContextBytes = CryptoJS.AES.decrypt(SIGNUP_CONTEXT.toString(), DEFAULT_FLAG);
    const DECRYPTED_SIGNUP_CONTEXT = signUpContextBytes.toString(CryptoJS.enc.Utf8);

    const signUpContextPassworBytes = CryptoJS.AES.decrypt(SIGNUP_CONTEXT_PASSWORD.toString(), DEFAULT_FLAG);
    const DECRYPTED_SIGNUP_CONTEXT_PASSWORD = signUpContextPassworBytes.toString(CryptoJS.enc.Utf8);

    return {
        DECRYPTED_SIGNUP_CONTEXT,
        DECRYPTED_SIGNUP_CONTEXT_PASSWORD
    }
}

export default decryptSingupKeys;