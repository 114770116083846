
import React, { Component } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import OfferMap from "components/OfferMap";
import OfferDetails from "./OfferDetails";
import { AIRCRAFT_IDS, AIRCRAFT_DETAILS } from "mocks/aircraftDetails";
import OfferCard from "components/OfferCard";
import SkeletonLoaderCards from "./shared/SkeletonLoaderCards";
import SkeletonLoaderCardsWithoutMap from "./shared/SkeletonLoaderCardsWithoutMap";
import Pagination from "./shared/Pagination";
import MessagePopUpNew from './shared/MessagePopUpNew';
import AircraftDropdown from "./shared/AircraftDropDown/AircraftDropdown";
import { getAircraftByField } from "../utils/aircraftUtils";
import { SHOW_AIRPPORTS_TO } from '../utils/airportUtils';
import { formatOfferObject } from "../utils/offerUtils";
import { saveToLocal } from "../utils/cache";
import { LEG_ARRAY, IS_OFFER_FLOW } from "../constants/localStorageKeys";
import { RAF_PAGE_URL, PAF_PAGE_URL, HOME_PAGE_URL } from "../configs/constants";
import { Element } from 'react-scroll';
import { isFSP, isFspDirect, isAdhoc, isGuest, isVJ2 } from '../utils/userUtils';
import { redirect } from './Drawer';


import { MEMBERSHIP_STATUS } from '../constants/MembershipConstants';

import {
  NO_OFFERS_FOUND,
  NO_ONE_WAY_OFFERS_FOUND,
  NO_EMPTY_LEG_OFFERS_FOUND,
  NO_SEARCH_OFFERS_FOUND,
  NO_OFFER_SELECTED,
  OFFERS_VALIDATION_MESSAGE,
  MEMBERSHIP_EXPIRY_MESSAGE_VJDIRECT_OFFERS,
} from "../constants/MessageConstants";
import { convertToTitleCase } from "../utils/stringUtils";
import ConfirmationPopUp from "./shared/ConfirmationPopUp";
import ConfirmationPopUpNew from "./shared/ConfirmationPopUpNew";
import "react-datepicker/dist/react-datepicker.css";
import { isMobileOnly } from "react-device-detect";
import { dateFnsFormats } from '../utils/dateUtils';
import { format, parseISO } from "date-fns";

const PAGE_SIZE = isMobileOnly ? 3 : 9;
const LoaderComponenent = isMobileOnly ? <SkeletonLoaderCardsWithoutMap /> : <SkeletonLoaderCards />
const TABS = {
  All: 0,
  ONE_WAY: 1,
  EMPTY: 2
};

class OfferListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: "",
      selectedAircraft: null,
      tempFromAirport: '',
      fromAirport: null,
      tempToAirport: '',
      toAirport: null,
      activeTab: TABS.All,
      offers: props.offers,
      currentPage: 1,
      toggleState: true,
      isFound: false,
      isUndefinedIds: false,
      cardClicked: false,
      activeCard: null,
      isSendRequest: false,
      membershipExpiry: false,
      messageContent: '',
    };
    this.offerMessages = [
      NO_OFFERS_FOUND,
      NO_ONE_WAY_OFFERS_FOUND,
      NO_EMPTY_LEG_OFFERS_FOUND
    ];
  }

  componentDidMount() {
    this.isVJDirectMembershipExpire();
    const { offers, fetchVisitedAirports } = this.props;
    fetchVisitedAirports();
    if (!offers.length) {
      this.loadOffersWithLocation(this.props);
    }

  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.offers.length && !nextProps.isOfferFetched) {
      this.loadOffersWithLocation(nextProps);
    }
    if (nextProps.isAirportsFetched && nextProps.offers.length) {
      this.findAllAirports(nextProps.offers);
      this.setState({
        offers: nextProps.offers,
        activeCard: null
      });
    }
    if (nextProps.visitedAirports.length > this.props.visitedAirports.length) {
      this.setState({
        isUndefinedIds: nextProps.isAirportByIdLoading
      });
    }
    if (
      this.props.isLoggedIn !== nextProps.isLoggedIn &&
      nextProps.isLoggedIn === true &&
      this.state.activeCard !== null &&
      this.state.isSendRequest
    ) {
      const { user } = nextProps;
      const formattedOfferObject = [formatOfferObject(this.state.activeCard)];
      if (isFSP(user) && !isFspDirect(user)) {
        this.noConfirmPopup.show();
      } else if (isGuest(user) || isAdhoc(user)) {
        this.addPopup.show();
      } else {
        saveToLocal(formattedOfferObject, LEG_ARRAY);
        saveToLocal(true, IS_OFFER_FLOW);
        window.open(RAF_PAGE_URL, "_self");
      }
    }
    if (
      nextProps.user &&
      this.state.activeCard !== null &&
      this.state.isSendRequest &&
      (nextProps.user.userVerificationStatusCode === 4 ||
        nextProps.user.userVerificationStatusCode === 2)
    ) {
      const formattedOfferObject = [formatOfferObject(this.state.activeCard)];
      saveToLocal(formattedOfferObject, LEG_ARRAY);
      saveToLocal(true, IS_OFFER_FLOW);
    }
    if (!this.props.regionId && nextProps.regionId) {
      this.loadOffersWithLocation(nextProps);
    }
    if (this.props.error) {
      this.offerApiErrors.show();
    }
  }

  toggleIsSendRequest = (value) => {
    this.setState({
      isSendRequest: value,
    });
  }

  findAllAirports = offers => {
    const { visitedAirportsFinder, getAirportById } = this.props;
    const undefinedIds = [];
    offers.forEach(offer => {
      if (visitedAirportsFinder[offer.departureAirportId] === undefined) {
        undefinedIds.push(offer.departureAirportId);
      }
      if (
        offer.arrivalAirportId &&
        visitedAirportsFinder[offer.arrivalAirportId] === undefined
      ) {
        undefinedIds.push(offer.arrivalAirportId);
      }
    });
    if (undefinedIds && undefinedIds.length) {
      this.setState({
        isUndefinedIds: true
      });
      getAirportById([...new Set(undefinedIds)]);
    }
  };

  loadOffersWithLocation = options => {
    const {
      fetchOffers
    } = options;
    fetchOffers(1, 100000);
  };

  handleDateChange = date => {
    this.setState({
      selectedDate: date
    });
  };

  handleAircraftChange = value => {
    // let stateValue = null;
    // if (!Array.isArray(value)) {
    //   stateValue = value;
    // }
    // this.setState({
    //   selectedAircraft: stateValue
    // });
    this.setState({
      selectedAircraft: value
    });
  };

  handleFromAirportChange = value => {
    let stateValue = null;
    if (!Array.isArray(value)) {
      stateValue = value;
    }
    this.setState({
      fromAirport: stateValue
    });
  };

  handleTempFromAirportChange = value => {
    let stateValue = null;
    if (!Array.isArray(value)) {
      stateValue = value;
    }
    this.setState({
      tempFromAirport: stateValue
    });
  };

  handleToAirportChange = value => {
    let stateValue = null;
    if (!Array.isArray(value)) {
      stateValue = value;
    }
    this.setState({
      toAirport: stateValue
    });
  };

  handleTempToAirportChange = value => {
    let stateValue = null;
    if (!Array.isArray(value)) {
      stateValue = value;
    }
    this.setState({
      tempToAirport: stateValue
    });
  };

  onCardClick = offerId => {
    const offerObject = this.state.offers.find(offer => offer.id === offerId);
    const departureAirportDetails = this.props.visitedAirportsFinder[
      offerObject.departureAirportId
    ];
    let arrivalAirportDetails = null;
    const aircraftDetails = getAircraftByField(offerObject.aircraftTypeId, this.props.aircraftsList);
    offerObject.selectedAircraft = aircraftDetails;
    offerObject.departureAirport = departureAirportDetails;
    if (offerObject.isEmptyLeg) {
      arrivalAirportDetails = this.props.visitedAirportsFinder[
        offerObject.arrivalAirportId
      ];
      offerObject.arrivalAirport = arrivalAirportDetails;
    }
    if (Object.values(AIRCRAFT_IDS).includes(offerObject.aircraftTypeId)) {
      this.setState({
        activeCard: offerObject,
        aircraftDetails: AIRCRAFT_DETAILS[offerObject.aircraftTypeId],
        departureAirportDetails,
        arrivalAirportDetails,
        toggleState: !this.state.toggleState
      });
    } else {
      console.warn(`Offer contains an aircraft not in the app: ${offerObject.aircraftTypeId}`);
    }
    this.setState({
      cardClicked: true
    });
  };

  handleTabChange = tabIndex => {
    if (!this.props.isLoading) {
      this.setState({
        activeTab: tabIndex,
        currentPage: 1,
        activeCard: null
      });
    }
  };

  resetFilters = e => {
    e.preventDefault();
    this.setState({
      selectedDate: "",
      selectedAircraft: "",
      tempFromAirport: '',
      fromAirport: null,
      tempToAirport: '',
      toAirport: null,
      offers: this.props.offers,
      isFound: false
    });
  };

  handleSearch = event => {
    event.preventDefault();
    const {
      selectedAircraft,
      fromAirport,
      toAirport,
      activeTab
    } = this.state;
    let { selectedDate } = this.state;
    const { offers } = this.props;
    const { YYYY_MM_DD_WITH_TIME, DDMMMYYYY } = dateFnsFormats;
    let filteredOffers = offers.slice();
    let arrivalAirports = [];
    if (selectedDate) {
      selectedDate = format(selectedDate, DDMMMYYYY);
      filteredOffers = filteredOffers.filter(offer => {
        if (offer.isEmptyLeg) {
          const offerDate = parseISO(offer.minDepartureTime, YYYY_MM_DD_WITH_TIME);
          const offerDateFormat = format(offerDate, DDMMMYYYY);
          return selectedDate === offerDateFormat;
        }
        const offerDate = parseISO(offer.segmentStart, YYYY_MM_DD_WITH_TIME);
        const offerDateFormat = format(offerDate, DDMMMYYYY);
        return selectedDate === offerDateFormat;
      });
    }

    if (selectedAircraft) {
      filteredOffers = filteredOffers.filter(
        offer => offer.aircraftTypeId === selectedAircraft.id
      );

    }
    if (fromAirport) {
      filteredOffers = filteredOffers.filter(
        offer => offer.departureAirportPlaceId === fromAirport.value
      );
    }
    if (toAirport && activeTab !== TABS.ONE_WAY) {
      arrivalAirports = this.findAirportsWithCityAndCountry(toAirport.label);
      filteredOffers = filteredOffers.filter(offer =>
        offer.isEmptyLeg
          ? this.isArrivalAirportFound(offer.arrivalAirportId, arrivalAirports)
          : false
      );
    }
    this.setState({
      offers: filteredOffers,
      activeCard: null,
      currentPage: 1,
      isFound: true
    });
  };

  handlePageChange = data => {
    const { currentPage } = data;
    this.setState({ currentPage });
  };

  isArrivalAirportFound = (arrivalAirportId, arrivalAirports) =>
    arrivalAirports.some(airport => airport.id === arrivalAirportId);

  findAirportsWithCityAndCountry = toCityCountry =>
    this.props.visitedAirports.filter(airport => {
      const cityWithCountry = airport.cityAndCountry.toLowerCase();
      return cityWithCountry === toCityCountry.toLowerCase();
    });

  handleSendRequest = () => {
    const { user } = this.props;
    this.isVJDirectMembershipExpire();
    if (this.props.isLoggedIn) {
      if (isFSP(user) && !isFspDirect(user)) {
        this.noConfirmPopup.show();
      } else if (isGuest(user) || isAdhoc(user)) {
        this.state.membershipExpiry ?
          this.accountExpiryErrorPopUp.show() :
          this.addPopup.show();
      } else {
        const formattedOfferObject = [formatOfferObject(this.state.activeCard)];
        saveToLocal(formattedOfferObject, LEG_ARRAY);
        saveToLocal(true, IS_OFFER_FLOW);
        window.open(RAF_PAGE_URL, "_self");
      }
    } else {
      window.UIkit.modal("#registerLoginRedirectModal").show();
    }
  };

  filterAirports = (value, array) =>
    array
      .filter(element => {
        const city = element.cityName.toLowerCase();
        const country = element.countryName.toLowerCase();
        return (
          city.indexOf(value.toLowerCase()) !== -1 ||
          country.indexOf(value.toLowerCase()) !== -1
        );
      })
      .map(city => ({
        value: city.placeId,
        label: convertToTitleCase(`${city.cityName}, ${city.countryName}`)
      }));

  isVJDirectMembershipExpire = () => {
    const { user } = this.props;
    const isMembershipExpire = (user
      && user.accounts.length === 1
      && (user.accounts[0].membershipStatus === MEMBERSHIP_STATUS.SUSPENDED ||
        user.accounts[0].membershipStatus === MEMBERSHIP_STATUS.EXPIRED));

    if (isMembershipExpire) {
      this.setState({ membershipExpiry: true });
    }
  }
  onGoogleMapsApiLoaded = () => {
    this.props.onGoogleMapsApiLoaded();
    this.forceUpdate();
  }
  render() {
    const activeTabStyle = "uk-link-reset visatjet_flight_active";
    const unActiveTabStyle = "uk-link-reset";
    const leg = {
      selectedAircraft: this.state.selectedAircraft
    };

    const {
      visitedAirports,
      visitedAirportsFinder,
      isLoading,
      isAirportsLoading,
      cities,
      isAirportByIdLoading,
      loadingGeolocation,
      isLoggedIn,
      error,
      user,
    } = this.props;
    const {
      selectedDate,
      selectedAircraft,
      tempFromAirport,
      fromAirport,
      toAirport,
      tempToAirport,
      offers,
      activeTab,
      currentPage,
      isFound,
      activeCard,
      cardClicked,
      membershipExpiry
    } = this.state;

    let filteredOffers = offers;
    let fromAirportsList = [];
    let toAirportsList = [];
    const offerMessages = this.offerMessages;

    if (activeTab === TABS.EMPTY) {
      filteredOffers = offers.filter(offer => offer.isEmptyLeg === true);
    } else if (activeTab === TABS.ONE_WAY) {
      filteredOffers = offers.filter(offer => offer.isOneWay === true);
    }
    const offersCount = filteredOffers.length;
    filteredOffers = filteredOffers.slice(
      (currentPage - 1) * PAGE_SIZE,
      PAGE_SIZE * currentPage
    );

    // if (tempFromAirport && tempFromAirport.length > 2) {
    //   fromAirportsList = this.filterAirports(tempFromAirport, cities);
    // }
    if (tempFromAirport.length === 0 || tempFromAirport.length < 2) {
      fromAirportsList = this.filterAirports(tempFromAirport, cities.slice(1, SHOW_AIRPPORTS_TO));
    } else {
      fromAirportsList = this.filterAirports(tempFromAirport, cities);
    }

    if (tempToAirport.length === 0 || tempToAirport.length < 2) {
      toAirportsList = this.filterAirports(tempToAirport, cities.slice(1, SHOW_AIRPPORTS_TO));
    } else {
      toAirportsList = this.filterAirports(tempToAirport, cities);
    }
    // if (tempToAirport && tempToAirport.length > 2) {
    //   toAirportsList = this.filterAirports(tempToAirport, cities);
    // }
    let availableFrom = "";
    let availableTo = "";
    if (activeCard) {
      availableFrom = activeCard.isOneWay
        ? activeCard.segmentStart
        : activeCard.minDepartureTime;
      availableTo = activeCard.isOneWay
        ? activeCard.segmentEnd
        : activeCard.maxDepartureTime;
    }
    let mapStyle = "";
    if (isMobileOnly && !cardClicked) mapStyle = "vistajet_map_hidden";
    else if (isMobileOnly && cardClicked) mapStyle = "";

    let messageContent = membershipExpiry ? MEMBERSHIP_EXPIRY_MESSAGE_VJDIRECT_OFFERS : OFFERS_VALIDATION_MESSAGE;
    let paf_link = "";
    let paf_btn_text = ""
    if (isFSP(user) || isFspDirect(user)) {
      paf_link = PAF_PAGE_URL;
      paf_btn_text = "Plan A Flight"
    }
    if (isAdhoc(user) || isGuest(user) || isVJ2(user)) {
      paf_link = RAF_PAGE_URL;
      paf_btn_text = "Request Quote"
    }
    return (
      <div>
        <div className="uk-width-1-1">
          <div className="visatjet_flight_option">
            <a
              className={
                activeTab === TABS.All ? activeTabStyle : unActiveTabStyle
              }
              onClick={() => this.handleTabChange(TABS.All)}
            >
              All
            </a>
            <a
              className={
                activeTab === TABS.ONE_WAY ? activeTabStyle : unActiveTabStyle
              }
              onClick={() => this.handleTabChange(TABS.ONE_WAY)}
            >
              One Way
            </a>
            <a
              className={
                activeTab === TABS.EMPTY ? activeTabStyle : unActiveTabStyle
              }
              onClick={() => this.handleTabChange(TABS.EMPTY)}
            >
              Empty Leg
            </a>
          </div>
        </div>
        <div className="vistajet_offer_form vistajet_offer_component">
          <div className="row">
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 vistajet_padding_small vistajet_offer_max_width">
              <div className="vistajet_input vistajet_form_icon">
                <Select
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  onInputChange={this.handleTempFromAirportChange}
                  value={fromAirport}
                  options={fromAirportsList}
                  //menuIsOpen={tempFromAirport && tempFromAirport.length > 2}
                  onChange={this.handleFromAirportChange}
                  placeholder={"From"}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 vistajet_padding_small vistajet_offer_max_width">
              <div className="vistajet_input vistajet_to_icon">
                <Select
                  classNamePrefix="react-select"
                  isDisabled={activeTab === TABS.ONE_WAY}
                  isClearable={true}
                  isSearchable={true}
                  onInputChange={this.handleTempToAirportChange}
                  value={toAirport}
                  options={toAirportsList}
                  //menuIsOpen={tempToAirport && tempToAirport.length > 2}
                  onChange={this.handleToAirportChange}
                  placeholder={"To"}
                //className="vistajet_input vistajet_to_icon"
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 vistajet_padding_small vistajet_offer_max_width">
              <div className="vistajet_input">
                <DatePicker
                  selected={selectedDate}
                  dateFormat={dateFnsFormats.DDMMMYYYY}
                  placeholderText="Departure Date"
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                  isClearable
                  className="vistajet_date_icon"
                  customInput={
                    isMobileOnly && (<div
                      style={{ padding: '8px 0px 7px 21px' }}
                      className="vistajet_date_icon"
                    >{selectedDate ? selectedDate.format('DD MMM YYYY') : 'Departure Date'}</div>
                    )
                  }
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 vistajet_padding_small offer_aircraft_selector_padding vistajet_offer_aircraft_dropdown vistajet_aircraft_departure_picker">
              <AircraftDropdown
                // selectedAircraft={selectedAircraft}
                handleAircraftChange={this.handleAircraftChange}
                aircraftsList={this.props.aircraftsList}
                isContractSelected={false}
                leg={leg}
                isWidgetFlow={true}
              />
            </div>
            <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 vistajet_padding_small vistajet_offer_button_alignment">
              <button
                className="visatjet_send vistajet_send_icon uk-text-center offer_search_button"
                // style={{ color: "black" }}
                onClick={this.handleSearch}
                type="submit"
              >
                SEARCH
              </button>
            </div>
            {(selectedDate || selectedAircraft || toAirport || fromAirport) && (
              <button
                className="uk-button uk-button-text vistajet_rest_filter_button"
                onClick={this.resetFilters}
              >
                Reset Filter
                <img
                  src="/legacy/static/img/icons/ic_cancel.svg"
                  alt=""
                  width="14px"
                  className="uk-margin-small-left"
                />
              </button>
            )}
          </div>
        </div>
        {isLoading || isAirportsLoading || isAirportByIdLoading || isAirportByIdLoading || loadingGeolocation ? (
          LoaderComponenent
        ) : (
          <div>
            <div
              className="uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-1 uk-grid-small"
              data-uk-grid
            >
              {filteredOffers.length !== 0 ? (
                filteredOffers.map(offer => {
                  const arrivalAirport = offer.isEmptyLeg
                    ? visitedAirportsFinder[offer.arrivalAirportId]
                    : null;
                  return (
                    <OfferCard
                      key={offer.id}
                      offer={offer}
                      activeCardId={
                        this.state.activeCard ? this.state.activeCard.id : null
                      }
                      onCardClick={this.onCardClick}
                      arrivalAirport={arrivalAirport}
                      departureAirport={
                        visitedAirportsFinder[offer.departureAirportId]
                      }
                      shouldScroll
                      fromOffersPage
                    />
                  );
                })
              ) : isFound ? (
                <div
                  className="uk-width-1-1 vistajet_sorry_we_couldnt uk-margin-large-top"
                  uk-scrollspy="cls: uk-animation-slide-top-medium; delay: 25;"
                >
                  <div className="uk-grid-small" data-uk-grid>
                    <div className="uk-width-auto">
                      <img
                        className="vistajet_sorry_we_couldnt_info_icon"
                        src="/legacy/static/img/icons/vistajet_notfound_icon.svg"
                      />
                    </div>
                    <div className="uk-width-expand vistajet_sorry_we_couldnt_info_text">
                      <span className="uk-text-middle uk-text-break">
                        {NO_SEARCH_OFFERS_FOUND}
                      </span>
                    </div>
                    {paf_link &&
                      <div className="uk-width-1-1 uk-width-auto@s uk-text-right offer_plan_flight_btn_div">
                        <button
                          className="uk-button vistajet_button_default vistajet_button_primary offer_plan_flight_btn"
                          onClick={() => redirect(paf_link)}
                        >{paf_btn_text}</button>
                      </div>
                    }
                  </div>
                </div>
              ) : (
                <div
                  className="uk-width-1-1 vistajet_sorry_we_couldnt uk-margin-large-top"
                  uk-scrollspy="cls: uk-animation-slide-top-medium; delay: 25;"
                >
                  <div className="uk-grid-medium" data-uk-grid>
                    <div className="uk-width-expand">
                      <span className="uk-text-middle uk-text-break">
                        {offerMessages[activeTab]}.
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="uk-width-1-1">
              {visitedAirports.length > 0 ? (
                <Pagination
                  totalRecords={offersCount}
                  currentPage={this.state.currentPage}
                  pageLimit={PAGE_SIZE}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                />
              ) : null}
              <hr />
            </div>

            <Element id="map_container" className="vistajet_offer_card_map">
              <div className="uk-width-1-1@s">
                {this.state.activeCard ? (
                  <OfferDetails
                    departureAirportDetails={this.state.departureAirportDetails}
                    arrivalAirportDetails={this.state.arrivalAirportDetails}
                    offerId={this.state.activeCard.id}
                    from={availableFrom}
                    to={availableTo}
                    isLoggedIn={isLoggedIn}
                    handleSendRequest={this.handleSendRequest}
                    isSendRequest={this.toggleIsSendRequest}
                    aircraftDetails={this.state.aircraftDetails}
                    isGoogleMapsApiLoaded={this.props.isGoogleMapsApiLoaded}
                    onGoogleMapsApiLoaded={this.onGoogleMapsApiLoaded}
                    mapClass={mapStyle}
                  />
                ) : !isFound ? (
                  <div>
                    <div
                      className="vistajet_offer_not_selected uk-visible@s"
                      uk-scrollspy="cls: uk-animation-slide-top-medium; delay: 25;"
                    >
                      {" "}
                      <span>
                        <img src="/legacy/static/img/icons/vistajet_notfound_icon.svg" />
                        {NO_OFFER_SELECTED}
                      </span>
                    </div>
                    <div id="vistajet_offer_map_primary" className={mapStyle}>
                      <OfferMap
                        legs={[]}
                        isGoogleMapsApiLoaded={this.props.isGoogleMapsApiLoaded}
                        onGoogleMapsApiLoaded={this.props.onGoogleMapsApiLoaded}
                      />
                    </div>
                  </div>
                ) : null}
                {/* <hr /> */}
              </div>
            </Element>
            <ConfirmationPopUp
              id="registerLoginRedirectModal"
              leftButtonContent="Register"
              leftRef={window.epiSettings.registerUrl}
              rightButtonContent="Login"
              onRightButtonClick={() => window.location.replace(window.epiSettings.loginUrl)}
              content="Please Register or Login to request this flight."
              onClosePopup={this.toggleIsSendRequest}
            />
            <ConfirmationPopUpNew
              ref={noConfirmPopup => {
                this.noConfirmPopup = noConfirmPopup;
              }}
              leftButtonContent="Okay"
              onLeftButtonClick={() => window.open(HOME_PAGE_URL, "_self")}
              content={
                "You need to have a VJ direct Account in order to avail offers."
              }
            />
            <ConfirmationPopUpNew
              ref={addPopup => {
                this.addPopup = addPopup;
              }}
              leftButtonContent="No"
              rightButtonContent="Yes"
              onLeftButtonClick={() => window.open(HOME_PAGE_URL, "_self")}
              onRightButtonClick={() =>
                window.open(window.epiSettings.registerUrl, "_self")
              }
              content={messageContent}
            />
            <MessagePopUpNew
              ref={accountExpiryErrorPopUp => {
                this.accountExpiryErrorPopUp = accountExpiryErrorPopUp;
              }}
              id="accountExpiryErrorPopUp"
              content={messageContent}
            />
          </div>
        )}
        <MessagePopUpNew
          ref={offerApiErrors => {
            this.offerApiErrors = offerApiErrors;
          }}
          id="offerApiErrorstopUp"
          content={error}
        />
      </div>
    );
  }
}
export default OfferListing;
