import { availOffer, buyOffer } from "../sources/AvailOfferSource";
import * as types from "../actionTypes";

export const availOfferInit = () => ({
  type: types.AVAIL_OFFER_INIT
});

export const availOfferSuccess = data => ({
  type: types.AVAIL_OFFER_SUCCESS,
  data
});

export const availOfferFail = data => ({
  type: types.AVAIL_OFFER_FAIL,
  data
});

export const availOfferQuotingNotAvailable = data =>({
  type: types.AVAIL_OFFER_QUOTING_NOT_AVAILABLE,
  data
});

export const buyOfferInit = () => ({
  type: types.BUY_OFFER_INIT
});

export const buyOfferSuccess = data => ({
  type: types.BUY_OFFER_SUCCESS,
  data
});

export const buyOfferFail = data => ({
  type: types.BUY_OFFER_FAIL,
  data
});

export const availOfferQuote = (legs, user) => availOffer(legs, user);
export const updateQuoteContent = () => ({ type: types.UPDATE_QUOTE_CONSTANT });
export const buyOfferQuote = (
  legs,
  quoteDetails,
  paymentOptionId,
  creditCard,
  isQuote,
) => buyOffer(legs, quoteDetails, paymentOptionId, creditCard, isQuote);
