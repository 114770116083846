import React from "react";
import * as types from '../actionTypes';
import cities from '../../constants/CityConstants';

const initialState = {
  isLoading: false,
  offers: [],
  totalOffers: 0,
  error: '',
  cities: cities.slice(),
  isOfferFetched: false,
  regionId: false,
};

export default function offersReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_OFFERS_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        error: '',
        isOfferFetched: false,
      });
    }
    case types.FETCH_OFFERS_SUCCESS: {
      const shouldUpdate = state.offers.length <= state.totalOffers
        && action.data.offers.length > state.offers.length 
        || action.data.regionId != state.regionId
      if (!shouldUpdate) {
        return Object.assign({}, state, {
          isLoading: false,
          error: '',
        });
      }
      return Object.assign({}, state, {
        isLoading: false,
        offers: action.data.offers,
        totalOffers: action.data.totalOffers,
        error: '',
        isOfferFetched: action.data.isFetched || false,
        regionId: action.data.regionId,
        
      });
    }
    case types.FETCH_OFFERS_FAIL: {
      return Object.assign({}, state, {
        isLoading: false,
        offers: [],
        totalOffers: 0,
        error: action.data,
        isOfferFetched: true,
      });
    }
    default:
      return state;
  }
}
