/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import { RESEND_EMAIL_CONFIRM, EMAIL_NUMBER } from '../../constants/MessageConstants';
import MessagePopUp from '../shared/MessagePopUp';

export default class RegistrationConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupMessage: RESEND_EMAIL_CONFIRM,
    };
  }


  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const shouldVerifyUser = params.get('shouldVerifyUser');
    if (code) {
      this.props.verifyUserAction(code);
    }
    if (shouldVerifyUser == 'true') {
      this.props.clearNotVerifiedFlag();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.message !== nextProps.message
      && !nextProps.isError
      && !nextProps.isLoading
      && nextProps.isMessageSent
    ) {
      window.UIkit.modal('#emailSentMessagePopUp').show();
    }
    if (nextProps.isUserVerified && (this.props.authenticatedUser !== nextProps.authenticatedUser)) {
      window.location = '/?verified=true';
    }

    if (
      this.props.message !== nextProps.message
      && nextProps.isError
    ) {
      window.UIkit.modal('#emailSentMessagePopUp').show();
    }
    if (
      nextProps.isVerifyUserError
      && this.props.verifyUserError !== nextProps.verifyUserError
    ) {
      this.setState({
        popupMessage: nextProps.verifyUserError,
      });
      window.UIkit.modal('#emailSentMessagePopUp').show();
    }
    if (
      nextProps.loggedInUser &&
      (nextProps.loggedInUser.userVerificationStatusCode === 4
      || nextProps.loggedInUser.userVerificationStatusCode === 2) &&
      nextProps.loggedInUser.userNotVerified
    ) {
      this.props.clearNotVerifiedFlag();
    }
  }

  resendCode = () => {
    const {
      user,
      resendRegisterCodeAction,
      adminToken,
      loggedInUser,
      isLoading,
      verifyLoading,
      token,
    } = this.props;
    if ((!isLoading) && (!verifyLoading)) {
      const params = new URLSearchParams(window.location.search);
      const shouldVerifyUser = params.get('shouldVerifyUser');
      if (shouldVerifyUser == 'true') {
        const email = params.get('email');
        this.props.fetchToken().then(({ token }) => {
          this.props.authenticateToken(token).then(() => {
            resendRegisterCodeAction({
              token,
              username: email,
              externalAppUserId: loggedInUser.id,
            }).then(() => { }, this.handleError);
          }).catch(this.handleError);
        }, this.handleError);
        return;
      }

      resendRegisterCodeAction({
        token: adminToken || token,
        username: user.username,
        externalAppUserId: user.externalAppUserId,
      }).then(() => { }, this.handleError);
    }
  }

  redirectToLogin = () => {
    window.open(window.epiSettings.loginUrl, "_self");
  };
 

  handleError = (err) => {
    this.setState({
      popupMessage: err,
    });
  }

  renderPage = (isDirect) => {
    if (isDirect) {
      return this.renderConfirmRegistrationPage();
    }
    return this.renderSimpleConfirmPage();
  }

  //  Welcome sceen
  renderConfirmRegistrationPage = () => {
    const {
      isLoading,
      verifyLoading,
      isTokenLoading,
      isAuthLoading,
    } = this.props;
    const unActiveTabStyle = (
      isLoading
      || verifyLoading
      || isTokenLoading
      || isAuthLoading
    )
      ? 'vistajet_button_default uk-link-reset'
      : 'vistajet_button_default';
    const resendEmailText = (
      isLoading
      || verifyLoading
      || isTokenLoading
      || isAuthLoading
    )
      ? 'Loading...'
      : 'Resend Email';
    return (
      <div className="uk-container vistajet_screen_wrapper vistajet_welcome_screen">
        <div className="uk-flex uk-flex-center uk-margin-medium-top" data-uk-grid>
          <div data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 25;">
            {
              (isLoading || verifyLoading || isTokenLoading || isAuthLoading) && (
                <div className="vistajet_spinner">
                  <SpinnerLoader />
                </div>
              )
            }
            <div className="vistajet_heading_column">
              <div className="vistajet_heading_primary uk-text-center">
                Welcome to VistaJet Direct!
              </div>
            </div>
            <div>
              <p className="uk-text-center uk-width-2-3@s uk-width-1-2@m uk-margin-auto">
                Confirm your email and login now to discover our offers. Enjoy faster booking and priority access to a fleet of over 70 silver and red business jets, available for you to fly to anywhere in the world.
              </p>
            </div>

            {
              !verifyLoading ? (
                <div className="uk-flex uk-child-width-1-2@s uk-width-1-2@m uk-margin-auto vistajet_button_wrapper">
                  <a
                    className={unActiveTabStyle}
                    onClick={this.resendCode}
                  >
                    {resendEmailText}
                  </a>
                  <a
                    className="vistajet_button_default vistajet_button_primary"
                    onClick={this.redirectToLogin}
                  >
                    Login
                  </a>
                </div>
              )
                : (
                  <div className="uk-flex uk-child-width-1-2@s uk-width-1-2@m uk-margin-auto">
                    <p>
                      Please wait, verifying account.
                    </p>
                  </div>
                )
            }

          </div>
        </div>
      </div>
    );
  }

  // Confirm sceen
  renderSimpleConfirmPage = () => {
    const {
      isLoading, verifyLoading, isTokenLoading, isAuthLoading,
    } = this.props;
    const unActiveTabStyle = (isLoading || verifyLoading || isTokenLoading || isAuthLoading)
      ? 'vistajet_button_default uk-link-reset'
      : 'vistajet_button_default';
    const resendEmailText = (isLoading || verifyLoading || isTokenLoading || isAuthLoading)
      ? 'Loading...'
      : 'Resend Email';
    return (
      <div className="uk-container vistajet_screen_wrapper">
        <div className="uk-flex uk-flex-center uk-margin-medium-top" data-uk-grid>
          <div data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 25;">
            {
              (isLoading || verifyLoading || isTokenLoading || isAuthLoading) && (
              <div className="vistajet_spinner">
                <SpinnerLoader />
              </div>
              )
            }
            <div className="vistajet_heading_column">
              <div className="vistajet_heading_third">
                REGISTER
              </div>
              <div className="vistajet_heading_primary">
                {`Thanks for your request`}
              </div>
              <div className="vistajet_heading_secondary" />
            </div>
            <p>
            {`To start, please confirm your email. For support call ${EMAIL_NUMBER}`}
            </p>
            {
              !verifyLoading ? (
                <div className="uk-flex uk-child-width-1-2@s vistajet_button_wrapper">
                  <a
                    href="#"
                    className={unActiveTabStyle}
                    onClick={this.resendCode}
                  >
                    {resendEmailText}
                  </a>
                  <a href="#" className="vistajet_button_default vistajet_button_primary" onClick={this.redirectToLogin}>
                    Login
                  </a>
                </div>
              )
                : (
                  <div className="uk-flex uk-child-width-1-2@s">
                    <p>
                    Please wait, verifying account.
                    </p>
                  </div>

                )
            }
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { popupMessage } = this.state;
    const { isDirectConfirmContainer } = this.props;
    return (
      <div>
        {
          this.renderPage(isDirectConfirmContainer)
        }
        <MessagePopUp
          id="emailSentMessagePopUp"
          ref={(messagePopup) => { this.messagePopup = messagePopup; }}
          content={popupMessage}
        />
      </div>
    );
  }
}
