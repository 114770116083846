import React, { PropTypes } from "react";
import { zeroPad } from "../../utils/aircraftUtils";

import { AIRCRAFT_ICONS } from "./AircraftDropDown/AircraftDropdown";

// const contentStyle = {
//   display: "flex",
//   flexDirection: "column",
//   flexGrow: 2,
//   padding: "4px 0px"
// };
class AircraftDropList extends React.Component {
  //   static propTypes = {
  //     onAirCraftSelection: PropTypes.func,
  //     handleHideList: PropTypes.func,
  //     aircrafts: PropTypes.array,
  //     listDisplay: PropTypes.any,
  //     selectedAircraftObj: PropTypes.any,
  //     anchorEl: PropTypes.any,
  //     availableAircraftIds: PropTypes.array,
  //     enableAircraftSelection: PropTypes.bool,
  //     isOfferFlow: PropTypes.bool,
  //     isOfferValid: PropTypes.bool
  //   };

  constructor(props) {
    super(props);
    this.state = {
      selectedAircraftObj: this.props.selectedAircraftObj // selected aircraft object as state
    };
  }

  /* on change event when aircraft got changed
   * it will stop default behaviour and call AircraftSelector change function
   */
  onAirCraftSelection = e => {
    // e.preventDefault();
    const newAir = e.currentTarget.attributes["data-code"].nodeValue;
    const selectedAircraft = this.props.aircrafts.find(
      el => el.code === newAir
    );
    if (this.props.passengersCount > selectedAircraft.maxNumberOfPassengers) {
      return;
    }
    this.props.onAirCraftSelection(e);
    const list = document.getElementsByClassName('airCraftdropDownClass');
    Array.from(list).forEach(element => {
      element.classList.remove('uk-open');
      window.UIkit.dropdown(element).hide();
    });
    

  };
  render() {

    const compThis = this;
    let flyingTime = "";
    const clientWidth = (document.getElementById("aircraft-dropdown") || [])
      .clientWidth;
    const aircraftsData = compThis.props.aircrafts.map((a, i) => {
      let shouldShowAvailable = false;
      if (this.props.availableAircraftIds.length > 0) {
        if (this.props.availableAircraftIds.includes(a.id)) {
          shouldShowAvailable = true;
        }
      }
      flyingTime =
        `${parseInt(a.maxFlightTime / 60, 10)}:` +
        `${zeroPad(parseInt(a.maxFlightTime % 60, 10), 2)}`;
      const isDisable = this.props.passengersCount > a.maxNumberOfPassengers;
      let liClassName = "aircraft_selector--action putTabIndex";
      liClassName = `${liClassName} ${isDisable ? "disable_aircraft" : ""} ${!!compThis.props.selectedAircraftObj &&
        a.code === compThis.props.selectedAircraftObj.code ? "active" : ""}`
      return (
        <li
          className={liClassName}
          onClick={compThis.onAirCraftSelection}
          data-code={a.code}
          id={a.id}
          key={i}
        >
          <img
            alt={a.code}
            src={AIRCRAFT_ICONS[a.code]}
            className={`jet_selected jet_selected--${a.code}`}
          />
          <strong>
            
            <span>{a.displayName} <a className="vistajet_info_icon" href={a.tourURL} target="_blank"><i/></a></span>
            <small>             
              {/* <small className="aircraft_selector--icons"> */}
                {/* <i className="material-icons icon--timer">timer</i> */}
                {/* {flyingTime} */}
                Max capacity {" "} 
                <i className="pax-cap-icon pax-cap-icon--fix" />
                {a.maxNumberOfPassengers} 
                {shouldShowAvailable ? (
                <span>
                  <i className="pax-cap-icon pax-cap-icon--fix" />
                  {" passengers"}
                  <small style={{left: "84px"}}>
                      Available in Contract
                  </small>
                </span>
              ) : (
                  " passengers"
                )}                             
            </small>

          </strong>
          {/* <div className="aircraft_selector">
           
          </div> */}
          {/* <div className="font--caslon" style={contentStyle}>
           
            
          </div> */}
        </li>
      );
    });
    return (
      <div data-uk-dropdown = "mode: click"  data-animation = "uk-animation-slide-bottom-small"  id="airCraftdropDown" className="noMarginLeftAircraftDropdown airCraftdropDownClass airCraftdropDownClassWrapper">
          <ul>{aircraftsData}</ul>
      </div>
    );
  }
}

export default AircraftDropList;
