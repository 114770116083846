import React, { Component } from 'react';
import SpinnerLoader from './spinnerLoader';

class ModalWithHeaderFooter extends Component {
  render() {
    const {
      id,
      container,
      headerContent,
      content,
      isLoggingIn,
      isSendingForgotEmail,
      isPasswordChangeLoading,
      subHeadingInHeader,
      noCross
    } = this.props;

    return (
      <div id={id} container={container} className="vistajet_modal" data-uk-modal bg-close="false" esc-close="false">
        <div className="uk-modal-dialog uk-modal-body vistajet_modal_body uk-margin-auto-vertical">
        {(!noCross && !(isLoggingIn || isSendingForgotEmail || isPasswordChangeLoading)) && <button className="uk-modal-close-default vistajet_modal_close" type="button" data-uk-close />}
          <div className="vistajet_modal_header">
            {
              (isLoggingIn || isSendingForgotEmail || isPasswordChangeLoading) && (
                <div className="vistajet_spinner">
                  <SpinnerLoader />
                </div>
              )
            }
            <h4 className="uk-margin-small-top uk-text-center">
              {headerContent}
            </h4>
            <p className="uk-text-center uk-margin-remove">
              {subHeadingInHeader}
            </p>
          </div>

          <div className="vistajet_modal_content uk-margin-auto uk-width-5-6@s">
            {content}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalWithHeaderFooter;
