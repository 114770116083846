import { connect } from 'react-redux';

import withStore from '../components/withStore';
import Drawer from '../components/Drawer';

import * as actions from '../store/actions';

function mapStateToProps(state) {
  const { isLoggedIn, user, isLoggingOut } = state.user;
  const { token } = state.token;
  return {
    isLoggedIn,
    user,
    token,
    isLoggingOut,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(actions.loginAction(email, password)),
    logout: token => dispatch(actions.logoutAction(token)),
  };
}

const DrawerContainer = connect(mapStateToProps, mapDispatchToProps)(Drawer);

export default withStore(DrawerContainer);
