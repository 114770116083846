import React from "react";
import Helpers from "utils/Helpers";

const EstimateTime = props => {
  let timeToRender = ""; 
  let timeSelected = false;
  const leg = props.leg;
  if (props.eTime === "etd") {
    if (
      leg &&
      leg.departTime &&
      leg.departureAirport &&
      leg.departTime !== "Invalid date" &&
      !!leg.time
    ) {
      const departTime = leg.departTime.split("T")[1].split(":");
      timeToRender = `etd ${departTime[0]}:${departTime[1]} loc`;
    } else {
      timeToRender = "ETD";
    }
  } else {
    if (
      leg &&
      leg.arrivalTime &&
      leg.arrivalTime !== "Invalid date" &&
      !!leg.time
    ) {
      const arrivalTime = leg.arrivalTime.split("T")[1].split(":");

      const daysDiff = Helpers.getDaysDiff(leg.departTime, leg.arrivalTime);
      timeToRender =
        `${arrivalTime[0]}:` +
        `${Helpers.roundTo5Minutes(arrivalTime[1])} loc ${daysDiff}`;
        timeSelected = true;
    } else {
      timeToRender = "";    
    }
  }
  let toBeAnnounced = timeSelected? "vistajet_announced_select": "vistajet_not_announced";
  return <span className={toBeAnnounced}>{timeToRender}</span>;
};


export default EstimateTime;
