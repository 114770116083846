import React, { Component } from 'react';
import { concatenateStrings } from '../../../utils/stringUtils';

export default class UserDetailCard extends Component {

  renderUserDetailCard = () => {
    const { toggleIsUserEditDetails, user } = this.props;
    return (
      <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card">
        <div class="vistajet_head_column">
          <div class="uk-clearfix vistajet_user_profile_buttons">
            <div class="uk-float-left">
              <h3 class="vistajet_right_tab_heading">My Details</h3>
            </div>
            <div class="uk-float-right vistajet_user_profile_button_child vistajet_account_button_full">
              <a
                className='uk-button uk-align-right uk-margin-remove vistajet_account_button vistajet_button_default '
                onClick={toggleIsUserEditDetails}
              > <span>Edit Details</span></a>
            </div>
          </div>             
        </div>
        <div className="vistajet_profile_details_column">
          <div className="uk-flex uk-grid-small" data-uk-grid>
          <div className="uk-width-1-1">
            <div className="vistajet_label">
              <span>Full Name</span>
              <h5 className="uk-margin-small-bottom">
                {concatenateStrings(
                  user.user && user.user.firstName,
                  user.user && user.user.lastName,
                )}
              </h5>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1">
            <div className="vistajet_label">
              <span>Email</span>
              <h5>
                {user.user && user.user.username}
              </h5>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1">
            <div className="vistajet_label">
              <span>Number</span>
              <h5>
                {user.user && user.user.phoneNumber}
              </h5>
            </div>
          </div>                          
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderUserDetailCard();
  }
}
