import { connect } from "react-redux";

import withStore from "../components/withStore";
import IsAuthenticated from "../components/shared/IsAuthenticated";

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

const IsAuthenticatedContainer = connect(mapStateToProps)(IsAuthenticated);

export default withStore(IsAuthenticatedContainer);
