import { fetchItineraryDetailsSource, fetchOrderIdByLegDetails } from '../sources/ItineraryDetailsSource';
import fetchItineraryPdfSource from '../sources/ItineraryPDFSource';
import {
  ITINERARY_DETAILS_INIT,
  ITINERARY_DETAILS_SUCCESS,
  ITINERARY_DETAILS_FAIL,
  ITINERARY_PDF_INIT,
  ITINERARY_PDF_SUCCESS,
  ITINERARY_PDF_FAIL,
  FETCH_ORDER_ID_BY_LEG_INIT,
  FETCH_ORDER_ID_BY_LEG_SUCCESS,
  FETCH_ORDER_ID_BY_LEG_FAIL,
} from '../actionTypes';

export const itineraryDetailsInit = () => ({
  type: ITINERARY_DETAILS_INIT,
});

export const itineraryDetailsSuccess = data => ({
  type: ITINERARY_DETAILS_SUCCESS,
  data,
});

export const itineraryDetailsFail = data => ({
  type: ITINERARY_DETAILS_FAIL,
  data,
});

export const fetchItineraryDetails = (token, key, params, type) => fetchItineraryDetailsSource(token, key, params, type);


export const itineraryPdfInit = (flightLegId) => ({
  type: ITINERARY_PDF_INIT,
  flightLegId
});

export const itineraryPdfSuccess = (data, flightLegId) => ({
  type: ITINERARY_PDF_SUCCESS,
  data,
  flightLegId,
});

export const itineraryPdfFail = (data, flightLegId) => ({
  type: ITINERARY_PDF_FAIL,
  data,
  flightLegId,
});

export const fetchitineraryPdf = (token, flightLegId) => fetchItineraryPdfSource(token, flightLegId);

export const fetchOrderIdByLegInit = () => ({
  type: FETCH_ORDER_ID_BY_LEG_INIT,
});

export const fetchOrderIdByLegSuccess = data => ({
  type: FETCH_ORDER_ID_BY_LEG_SUCCESS,
  data,
});

export const fetchOrderIdByLegFail = data => ({
  type: FETCH_ORDER_ID_BY_LEG_FAIL,
  data,
});

export const fetchOrderIdByLeg = (key, identifier) => fetchOrderIdByLegDetails(key, identifier);
