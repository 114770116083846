/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  fetchItinerariesInit,
  fetchItinerariesSuccess,
  fetchItinerariesFail,
} from '../actions/ItineraryListingActions';
import { garanteeArray } from '../../utils/sourceUtils';
import {
  FETCH_ITINERARIES_URL,
  FETCH_CANCEL_ITINERARIES_URL,
} from '../../configs/constants';
import { ITINERARY_TYPES } from '../../constants/ItineraryConstants';
import normalizeItineraryListingData from '../../normalizers/itineraryListingNormalizer';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';
import { BAD_REQUEST, TIMEOUT_CODE, UNAUTHORIZED } from '../../constants/ServerCodeConstants';
import { SOMETHING_UNEXPECTED } from '../../constants/MessageConstants';
// import { itineraries } from '../../mocks/itineraries';

export const fetchItineraryListing = (token, itineraryType, pageNum, isLoadMore) => (dispatch) => {
  let url;
  if (itineraryType === ITINERARY_TYPES.UPCOMING_ITINERARIES) {
    url = FETCH_ITINERARIES_URL.replace('{isUpcoming}', true).replace(
      '{pageNum}',
      pageNum,
    ).replace('{token}', token);
  } else if (itineraryType === ITINERARY_TYPES.PAST_ITINERARIES) {
    url = FETCH_ITINERARIES_URL.replace('{isUpcoming}', false).replace(
      '{pageNum}',
      pageNum,
    ).replace('{token}', token);
  } else if (itineraryType === ITINERARY_TYPES.CANCELLED_ITINERARIES) {
    url = FETCH_CANCEL_ITINERARIES_URL.replace('{pageNum}', pageNum).replace('{token}', token);
  }
  const options = {
    timeout: TIMEOUT_CODE.time
  };

  dispatch(fetchItinerariesInit(itineraryType, isLoadMore));
  let myItineraries = {};
  axios
    .get(url, options)
    .then((response) => {
      if (response && response.data && response.data.responseHeaderDto
        && !response.data.responseHeaderDto.isError) {
        const itinerary = garanteeArray(response.data.itineraryDtos);
        // const itinerary = garanteeArray(itineraries.itineraryDtos);// mock data
        myItineraries = itinerary.map((itineraryLeg) => {
          const itinererylegs = normalizeItineraryListingData(itineraryLeg);
          return itinererylegs;
        });
        dispatch(fetchItinerariesSuccess(myItineraries, itineraryType, isLoadMore));
      } else if (response && response.data && response.data.responseHeaderDto
        && response.data.responseHeaderDto.isError && response.data.responseHeaderDto.statusCode){
        const errors = handleServerErrors(response.data.responseHeaderDto.statusCode);
          dispatch(fetchItinerariesFail(itineraryType, errors, isLoadMore));
      }
      else {
        dispatch(fetchItinerariesFail(itineraryType, SOMETHING_UNEXPECTED, isLoadMore));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(fetchItinerariesFail(itineraryType, handleServerErrors(TIMEOUT_CODE.code), isLoadMore));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(fetchItinerariesFail(itineraryType, handleServerErrors(UNAUTHORIZED), isLoadMore));
      }
      else dispatch(fetchItinerariesFail(itineraryType, handleServerErrors(BAD_REQUEST), isLoadMore));
    });
};
