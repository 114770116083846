import validateFlights from '../sources/ValidateFlightSource';
import {
  VALIDATE_FLIGHT_INIT,
  VALIDATE_FLIGHT_SUCCESS,
  VALIDATE_FLIGHT_FAIL,
  RESET_FLIGHT_ERROR_MESSAGES
} from '../actionTypes';

export const validateFlightInit = () => ({
  type: VALIDATE_FLIGHT_INIT,
});

export const validateFlightSuccess = () => ({
  type: VALIDATE_FLIGHT_SUCCESS,
});

export const validateFlightFail = data => ({
  type: VALIDATE_FLIGHT_FAIL,
  data,
});

export const resetFlightErrorMessages = () => ({
  type:RESET_FLIGHT_ERROR_MESSAGES
})

export const validateAllFlights = (flightLegs, accountId, offer, isEdit, order, passengers) =>
  validateFlights(flightLegs, accountId, offer, isEdit, order, passengers);
