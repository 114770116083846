import * as types from '../actionTypes';

const initialState = {
  memberPlans: {},
  isLoading: false,
  isError: false,
  error: '',
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MEMBERSHIP_INIT: {
      return Object.assign({}, state, {
        memberPlans: {},
        isLoading: true,
        isError: false,
        error: '',
      });
    }
    case types.FETCH_MEMBERSHIP_SUCCESS: {
      return Object.assign({}, state, {
        memberPlans: action.data,
        isLoading: false,
        isError: false,
        error: '',
      });
    }
    case types.FETCH_MEMBERSHIP_FAIL: {
      return Object.assign({}, state, {
        memberPlans: {},
        isLoading: false,
        isError: true,
        error: action.data,
      });
    }
    default:
      return state;
  }
}
