import xml2js from 'xml2js';
/*
converts xml string data from server to json object
*/
export function parseXmlStringToJson(xmlString) {
  let jsonData;
  parseString(xmlString, (err, result) => {
    jsonData = result;
  });
  return jsonData;
}

const parseNumbers = (str) => {
  if (!isNaN(str)) {
    const number = str % 1 === 0 ? parseInt(str, 10) : parseFloat(str);
    // garantee that left zeros or space remain as string
    if (`${number}`.length === str.length) {
      return number;
    }
  }
  return str;
};

const parser = new xml2js.Parser({
  explicitArray: false,
  trim: true,
  valueProcessors: [
    parseNumbers, // parse numbers must always be set before parse booleans
    xml2js.processors.parseBooleans,
  ],
});

export const parseString = (...args) => {
  return parser.parseString.apply(this, args);
};

const parserIgnoreNumber = new xml2js.Parser({
  explicitArray: false,
  trim: true,
  valueProcessors: [
    xml2js.processors.parseBooleans,
  ],
  tagNameProcessors: [
    xml2js.processors.firstCharLowerCase,
  ],
});

export const parserPascalCaseIgnoreNumber = (...args) => {
  parserIgnoreNumber.parseString.apply(this, args);
}

const builder = new xml2js.Builder({
  xmldec: {
    version: '1.0',
    encoding: 'UTF-8',
    standalone: undefined,
  },
});

export const builderXmlRequest = (obj) => {
  return builder.buildObject(obj);
}
/*
converts json object to xml
*/
export const jsonToXml = (jsonData) => {
  const builder = new xml2js.Builder();
  return builder.buildObject(jsonData);
}

export const numberWithCommas = (x) => {
  if (!x)
    return x;
  let num = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (num.includes('.')) {
    return num.split('.')[0];
  }
  return `${num}`;
}
