import { combineReducers } from 'redux';
import termsPolicyReducer from './termsAndPolicyReducer';
import userReducer from './userReducer';
import offersReducer from './offersReducer';
import geocodeReducer from './geocodeReducer';
import googleMapReducer from './googleMapReducer';
import visitedAirportsReducer from './visitedAirportsReducer';
import validateFlightReducer from './validateFlightReducer';
import PAFReducer from './PAFReducer';
import tokenReducer from './tokenReducer';
import registrationReducer from './registrationReducer';
import resendRegisterCodeReducer from './resendRegisterCodeReducer';
import membershipPlansReducer from './membershipPlanReducer';
import creditCardReducer from './creditCardReducer';
import directSignupReducer from './directSignupReducer';
import contactusReducer from './contactusReducer';
import itineraryListingReducer from './itineraryListingReducer';
import itineraryDetailsReducer from './itineraryDetails/itineraryDetailsReducer';

import passengerReducer from './itineraryDetails/passengerReducer';
import passengerPopupReducer from './passengerPopupReducer';
import topUpReducer from './topUpReducer';
import topUpHistoryReducer from './topUpHistoryReducer';
import directAccountBalanceReducer from './directAccountBalanceReducer';
import crewReducer from "./itineraryDetails/crewReducer";
import entertainmentReducer from "./itineraryDetails/entertainmentReducer";
import itineraryPdfReducer from "./itineraryDetails/itineraryPdfReducer";
import itineraryCancelReducer from "./itineraryDetails/flightCancelReducer";
import quotesReducer from './quotesReducer';
import PASReducer from './PASReducer';
import movementMessagesReducer from './itineraryDetails/movementMessagesReducer';
import availedServicesReducer from "./itineraryDetails/availedServicesReducer";
import groundServicesReducer from "./groundServicesReducer";

import aircraftsReducer from "./aircraftsReducer";
import customerFeedbackReducer from "./customerFeedbackReducer";
import cateringReducer from './cateringReducer';

import pushNotificationReduer from './pushNotificationReducer';
import weatherReducer from './itineraryDetails/weatherReducer';
import fboReducer from './itineraryDetails/fboReducer';

// new
import airportLookupReducer from 'features/slices/airportLookupSlice'

const rootReducer = combineReducers({
  user: userReducer,
  offers: offersReducer,
  geocode: geocodeReducer,
  visitedAirports: visitedAirportsReducer,
  googleMap: googleMapReducer,
  itineraries: itineraryListingReducer,
  termsPolicy: termsPolicyReducer,
  validateFlight: validateFlightReducer,
  PAFData: PAFReducer,
  token: tokenReducer,
  registration: registrationReducer,
  resendCode: resendRegisterCodeReducer,
  contactus: contactusReducer,
  membersPlanReducer: membershipPlansReducer,
  creditCardReducer,
  directSignupReducer,
  pushNotificationReduer,
  groundServicesReducer,
  itineraryDetails: itineraryDetailsReducer,
  PASData: PASReducer,
  topUp: topUpReducer,
  topUpHistory: topUpHistoryReducer,
  directAccountBalance: directAccountBalanceReducer,
  crewDetails: crewReducer,
  weather: weatherReducer,
  movementMessages: movementMessagesReducer,
  itineraryPdf: itineraryPdfReducer,
  availedServices: availedServicesReducer,
  cateringDetails: cateringReducer,
  passengers: passengerReducer,
  passengerPopup: passengerPopupReducer,
  fbo: fboReducer,
  aircrafts: aircraftsReducer,
  cancelItinerary: itineraryCancelReducer,
  customerFeedback: customerFeedbackReducer,
  quotes: quotesReducer,
  entertainment: entertainmentReducer,
  //  new
  airportLookup: airportLookupReducer,
});

export default rootReducer;
