import React from 'react';
import PropTypes from 'prop-types';

const getStyle = (props) => {
  return {
    position: 'absolute',
    opacity: props.hasValue ? 0 : 1,
    color: '#4a4a4a', // props.disabled
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    bottom: 0,
    fontSize: 13,
    fontFamily: '"museo-sans", sans-serif',
    paddingLeft: 46, 
    lineHeight: '36px',
    width: 'calc(100% - 46px)',
    height: '33.5px'
  };
};

const HintText = (props) => {
  const { style, children } = props;
  return (
    <div style={Object.assign(getStyle(props), style)}>
      {children}
    </div>
  );
};

HintText.propTypes = {
  /**
   * Content of HintText
   */
  children: PropTypes.node.isRequired,

  /**
   * Controls color style if hintText is enabled or not.
   */
  // disabled: PropTypes.bool.isRequired,

  /**
   * Controls opacity style if the label has value or not.
   */
  // hasValue: PropTypes.bool.isRequired,

  /**
   * Controls styles if hintText is focused or not.
   */
  // isFocused: PropTypes.bool.isRequired,

  /**
   * Override the inline-styles of the root element.
   */
  style: PropTypes.shape().isRequired,
};

export default HintText;
