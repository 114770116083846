import React, { Component } from 'react';
import moment from 'moment';
import { dateFormats, formatDate } from '../../utils/dateUtils';
import { makeData, formatArrivalTime, formatDepartTime } from './ThankyouScreen';
import Helpers from '../../utils/Helpers';
import { capitalize } from '../../utils/stringUtils';
import { numberWithCommas } from '../../utils/parserUtils';
import OffersMap from '../OfferMap';

const glex = "/legacy/static/img/icons/GLEX-icon.svg";

class OfferSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMap: {},
    };
  }

  showMap = (id) => {
    const map = { ...this.state.showMap }
    map[id] = true;
    this.setState({
      showMap: map
    },() => {
      this.onScroll(`quote_summary_map`);
    });
    const { hideDivider } = this.props;
    if (hideDivider) hideDivider();
  }

  closeMap = (id) => {
    const map = { ...this.state.showMap }
    map[id] = false;
    this.setState({
      showMap: map
    }, () => {
      this.onScroll(`quote_summary_card`);
    });
    const { showDivider } = this.props;
    if (showDivider) showDivider();
  }

  renderSingleLeg = () => {
    const { showMap } = this.state;
    const { legArray, quoteContent, buyQuote,
      isGoogleMapsApiLoaded, onGoogleMapsApiLoaded } = this.props;
    const requestedDate = buyQuote.requestedDate
      ? moment(moment(buyQuote.requestedDate.split(' ')[0], "DD-MM-YYYY")).format(dateFormats.DDMMMYYYY)
      : moment().format(dateFormats.DDMMMYYYY);
    const structuredData = makeData(legArray);
    const {
      date,
      departureAirport,
      arrivalAirport,
      time,
      aircraftName,
      arrivalTime,
      departTime,
      departDate,
    } = structuredData;
    const { passengersCount } = legArray[0];
    const { operatingCompanyName } = legArray[0];
    const formattedDate = date ? formatDate(moment(date), dateFormats.DDMMMYYYY)
      : formatDate(moment(departDate), dateFormats.DDMMMYYYY);
    const formattedTime = `${Math.floor(time / 60)}h ${Helpers.roundTo5Minutes(
      Math.floor(time % 60)
    )}m`;
    const offerType = legArray[0].isOneWay ? 'One Way' : 'Empty Leg';
    const {
      refrenceIds,
      content,
    } = quoteContent;
    let refrenceId = '', amount = '', currency = '',
      costsBreakdown, quotedDate = '', expireDiff = '', expire = '', displayMap = false;
    if (Object.keys(quoteContent).length) {
      refrenceId = refrenceIds[0];
      amount = content[refrenceId].amount;
      costsBreakdown = content[refrenceId].costsBreakdown;
      currency = content[refrenceId].currency;
    } else {
      refrenceId = buyQuote.id;
      amount = buyQuote.amount;
      currency = buyQuote.currency;
      quotedDate = moment(moment(buyQuote.quotedDate.split(' ')[0], "DD-MM-YYYY")).format(dateFormats.DDMMMYYYY)
      expireDiff = Math.floor(moment.duration(moment(buyQuote.expiryDate)
        .diff(moment())).asDays()) + 1;
      displayMap = true;
    }
    if (expireDiff !== '') {
      expire = expireDiff > 0 ? `in ${expireDiff} Days` : 'Today';
    }
    const parsedAmount = numberWithCommas(amount);
    return (
      <div id='quote_summary_card'>
        <div class="uk-flex uk-grid-medium vistajet_quote_summary_detail_card" data-uk-grid>
          <div class="uk-width-2-3@s">
            <div class="vistajet_ref_column">
              <div class="uk-clearfix">
                <div class="uk-float-right vistajet_subject_text">
                  {Object.keys(quoteContent).length
                    ? <span>Ref # {refrenceId}</span>
                    : <span>Subject to Availability at the time of request</span>}
                </div>
                <div class="uk-float-left">
                  <div class="vistajet_date_column">
                    <span class="vistajet_date_icon">{formattedDate}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="vistajet_departure_column">
              <div class="uk-flex">
                <div class="uk-width-2-5">
                  <div class="vistajet_departure_left">
                    {departureAirport.city}
                    <small>{departureAirport.name}</small>
                  </div>
                </div>
                <div class="uk-width-1-5 uk-text-center">
                  <div class="vistajet_departure_center">
                    <span>{formattedTime}</span>
                  </div>
                </div>
                <div class="uk-width-2-5 uk-text-right">
                  <div class="vistajet_departure_right">
                    {arrivalAirport.city}
                    <small>{arrivalAirport.name}</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="vistajet_eta_column">
              <div class="uk-clearfix">
                <div class="uk-float-right">
                  {arrivalTime}
                </div>
                <div class="uk-float-left">
                  {departTime}
                </div>
              </div>
            </div>
            <div class="vistajet_cp_column">
              <div class="uk-clearfix">
                <div class="uk-float-right vistajet_passengers_column">
                  <span>{passengersCount}</span>
                </div>
                <div class="uk-float-left vistajet_aircraft_column">
                  <img src={glex} width="90" height="90" alt="" />
                  <small className="uk-text-middle">{aircraftName}</small>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-1-3@s">
            <div class="vistajet_details_column">
              {Object.keys(quoteContent).length ?
                <div>
                  <div class="vistajet_requested">Requested: <span>{requestedDate}</span></div>
                  <div class="vistajet_status">{offerType}</div>
                  <div class="vistajet_operated">Operated by: <span>{operatingCompanyName}</span></div>
                </div>
                : <div>
                  <div class="vistajet_requested"><strong>Ref#{refrenceId}</strong></div>
                  <div class="vistajet_requested vistajet_p_b_10">Requested: <span>{requestedDate}</span></div>
                  <div class="vistajet_requested uk-margin-remove">Quoted: <span>{quotedDate}</span></div>
                  <div class="vistajet_expires">Expires <span>{expire}</span></div>
                </div>}
              <div class="vistajet_usd_column">{currency} {parsedAmount}
                {Object.keys(quoteContent).length ?
                  <span class="vistajet_tooltip">
                    <i class="vistajet_tooltip_icon">
                      <span class="vistajet_tooltip_box vistajet_quote_cost_tooltip">
                        {costsBreakdown.map(cost => (
                          <div class="uk-clearfix">
                            <div class="uk-float-right">
                              <span>{cost.currency} {numberWithCommas(cost.amount)}</span>
                            </div>
                            <div class="uk-float-left">
                              <span>{cost.description}:</span>
                            </div>
                          </div>
                        ))}
                        <div class="uk-clearfix">
                          <div class="uk-float-right">
                            <span><strong>{currency} {parsedAmount}</strong> </span>
                          </div>
                          <div class="uk-float-left">
                            <span><strong>Total:</strong> </span>
                          </div>
                        </div>
                      </span>
                    </i>
                  </span>
                  : null}
              </div>
            </div>
          </div>
        </div>
        {displayMap && !showMap[refrenceId] ?
          <div className="uk-width-2-3@s vistajet_map_button_wrapper">
            <button onClick={() => this.showMap(refrenceId)} className="vistajet_link_text_button"> View Map </button>
          </div>
          : null}
        <div id='quote_summary_map'>
          {showMap[refrenceId] &&
            <div id="vistajet_offer_map_default" className="vistajet_offer_map">
              <button onClick={() => this.closeMap(refrenceId)} className="vistajet_close_map_button">
                <img src="/legacy/static/img/icons/vistajet_close_icon.svg" alt="close" />
              </button>
              <OffersMap
                legs={legArray}
                isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
                onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
              />
            </div>
          }
        </div>
      </div>
    )
  }

  onScroll(id) {
    const elementToScroll = document.getElementById(id);
    if (elementToScroll) elementToScroll.scrollIntoView({ behavior: 'smooth' });
  }

  renderMultiLegBody = () => {
    const { legArray } = this.props;
    return (legArray.map((leg) => {
      const {
        departureAirport,
        arrivalAirport,
        date,
        duration,
        selectedAircraft,
        passengersCount,
      } = leg;
      const time = Number(duration);
      const arrivalTime = formatArrivalTime(leg.departTime, leg.arrivalTime);
      const departTime = formatDepartTime(leg.departTime)
      const formattedDate = date ? formatDate(moment(date), dateFormats.DDMMMYYYY)
        : formatDate(moment(leg.departTime), dateFormats.DDMMMYYYY);
      const formattedTime = `${Math.floor(time / 60)}h ${Helpers.roundTo5Minutes(
        Math.floor(time % 60)
      )}m`;
      const departureAirportCity = capitalize(departureAirport.city);
      const departureAirportName = capitalize(departureAirport.name);
      const arrivalAirportCity = capitalize(arrivalAirport.city);
      const arrivalAirportName = capitalize(arrivalAirport.name);

      return (
        <div>
          <div class="uk-width-1-1 vistajet_offer_summary_wrapper">
            <div class="vistajet_date_column">
              <span class="vistajet_date_icon">{formattedDate}</span>
            </div>
            <div class="vistajet_departure_column">
              <div class="uk-flex">
                <div class="uk-width-2-5">
                  <div class="vistajet_departure_left">
                    {departureAirportCity}
                    <small>{departureAirportName}</small>
                  </div>
                </div>
                <div class="uk-width-1-5 uk-text-center">
                  <div class="vistajet_departure_center">
                    <span>{formattedTime}</span>
                  </div>
                </div>
                <div class="uk-width-2-5 uk-text-right">
                  <div class="vistajet_departure_right">
                    {arrivalAirportCity}
                    <small>{arrivalAirportName}</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="vistajet_eta_column">
              <div class="uk-clearfix">
                <div class="uk-float-right">{arrivalTime}</div>
                <div class="uk-float-left">{departTime}</div>
              </div>
            </div>
            <div class="vistajet_cp_column">
              <div class="uk-clearfix">
                <div class="uk-float-right vistajet_passengers_column">
                  <span>{passengersCount}</span>
                </div>
                <div class="uk-float-left vistajet_aircraft_column">
                  <img src="/legacy/static/img/icons/glex_icon_sample.svg" alt="" />
                  <small>{selectedAircraft.displayName}</small>
                </div>
              </div>
            </div>
            <div class="vistajet_divider"></div>
          </div>
        </div>)
    })
    )
  }

  renderMultiLeg = () => {
    const { legArray, quoteContent, buyQuote,
      isGoogleMapsApiLoaded, onGoogleMapsApiLoaded } = this.props;
    const { showMap } = this.state;
    const requestedDate = buyQuote.requestedDate
      ? moment(moment(buyQuote.requestedDate.split(' ')[0], "DD-MM-YYYY")).format(dateFormats.DDMMMYYYY)
      : moment().format(dateFormats.DDMMMYYYY);
    const structuredData = makeData(legArray);
    const {
      departureAirport,
      arrivalAirport,
      isReturn,
    } = structuredData;
    const {
      refrenceIds,
      content,
    } = quoteContent;

    const iconClass = isReturn ? "vistajet_departure_center vistajet_return_icon"
      : "vistajet_departure_center vistajet_multicities_icon";
    let refrenceId = '', amount = '', currency = '',
      costsBreakdown, quotedDate = '', expireDiff = '', expire = '', displayMap = false;
    if (Object.keys(quoteContent).length) {
      refrenceId = refrenceIds[0];
      amount = content[refrenceId].amount;
      costsBreakdown = content[refrenceId].costsBreakdown;
      currency = content[refrenceId].currency;
    } else {
      refrenceId = buyQuote.id;
      amount = buyQuote.amount;
      currency = buyQuote.currency;
      quotedDate = moment(moment(buyQuote.quotedDate.split(' ')[0], "DD-MM-YYYY")).format(dateFormats.DDMMMYYYY)
      expireDiff = Math.floor(moment.duration(moment(buyQuote.expiryDate)
        .diff(moment())).asDays()) + 1;
      displayMap = true;
    }
    if (expireDiff !== '') {
      expire = expireDiff > 0 ? `in ${expireDiff} Days` : 'Today';
    }
    const parsedAmount = numberWithCommas(amount);
    const departureAirportCity = capitalize(departureAirport.city);
    const departureAirportName = capitalize(departureAirport.name);
    const arrivalAirportCity = capitalize(arrivalAirport.city);
    const arrivalAirportName = capitalize(arrivalAirport.name);
    return (
      <div id='quote_summary_card'>
        <div class="uk-width-1-1 vistajet_offer_summary_wrapper">
          <div class="vistajet_ref_column">
            <div class="uk-clearfix">
              <div class="uk-float-right">
                <span>Ref # {refrenceId}</span>
              </div>
              <div class="uk-float-left">
                <span>Requested Date: {requestedDate}</span>
              </div>
            </div>
          </div>
          <div class="vistajet_departure_column">
            <div class="uk-flex">
              <div class="uk-width-2-5">
                <div class="vistajet_departure_left">
                  {departureAirportCity}
                  <small>{departureAirportName}</small>
                </div>
              </div>
              <div class="uk-width-1-5 uk-text-center">
                <div class={iconClass}>
                </div>
              </div>
              <div class="uk-width-2-5 uk-text-right">
                <div class="vistajet_departure_right">
                  {arrivalAirportCity}
                  <small>{arrivalAirportName}</small>
                </div>
              </div>
            </div>
          </div>
          {Object.keys(buyQuote).length ?
            <div class="vistajet_ref_column">
              <div class="uk-clearfix">
                <div class="uk-float-right">
                  <span className="vistajet_red_text">Expires {expire}</span>
                </div>
                <div class="uk-float-left">
                  <span>Quoted Date: {quotedDate}</span>
                </div>
              </div>
            </div>
            : null}
          {displayMap && !showMap[refrenceId] ?
            <div className="vistajet_map_button_wrapper uk-margin-top">
              <button onClick={() => this.showMap(refrenceId)} className="vistajet_link_text_button">
                View Map
           </button>
            </div>
            : null}
        </div>

        <div id='quote_summary_map'>
          {showMap[refrenceId] &&
            <div id="vistajet_offer_map_default" className="vistajet_offer_map">
              <button onClick={() => this.closeMap(refrenceId)} className="vistajet_close_map_button">
                <img src="/legacy/static/img/icons/vistajet_close_icon.svg" alt="close" /></button>
              <OffersMap
                legs={legArray}
                isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
                onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
              />
            </div>
          }
        </div>

        <div class="uk-width-1-1">
          <div class="vistajet_usd_column uk-text-center">
            <span>{currency} {parsedAmount}
              {Object.keys(quoteContent).length ?
                <span class="vistajet_tooltip">
                  <i class="vistajet_tooltip_icon">
                    <span class="vistajet_tooltip_box">
                      {costsBreakdown.map(cost => (
                        <div class="uk-clearfix">
                          <div class="uk-float-right">
                            <span>{cost.currency} {numberWithCommas(cost.amount)}</span>
                          </div>
                          <div class="uk-float-left">
                            <span>{cost.description}:</span>
                          </div>
                        </div>))}
                      <div class="uk-clearfix">
                        <div class="uk-float-right">
                          <span><strong>{currency} {parsedAmount}</strong> </span>
                        </div>
                        <div class="uk-float-left">
                          <span><strong>Total:</strong> </span>
                        </div>
                      </div>
                    </span>
                  </i>
                </span> : null}
            </span>
          </div>
        </div>
        {this.renderMultiLegBody()}
      </div>
    )
  }

  render() {
    const { legArray } = this.props;
    return (
      <div>
        {legArray.length ?
          (legArray.length > 1 ? this.renderMultiLeg() : this.renderSingleLeg())
          : null}
      </div>
    )
  }
}

export default OfferSummary