import React, { Component } from 'react';
import moment from 'moment';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import ConfirmationPopUp from '../../shared/ConfirmationPopUpNew';
import MessagePopUp from '../../shared/MessagePopUp';
import SpinnerLoader from '../../shared/spinnerLoader';
import { getTruncatedCountryName, getNameLengthToDisplay } from 'utils/countryUtils';
import Helpers from 'utils/Helpers';

import
countryData,
{
  COUNTRY_CODE_TAIWAN_ISO2,
  COUNTRY_CODE_CHINA_ISO2,
} from '../../../constants/CountryConstants';
import { PaxOperationCode } from '../../../constants/ApiConstants';
import validateAddEditPassportForm from '../../../utils/Validators/PassportValidator';
import { convertToTitleCase } from '../../../utils/stringUtils';
import { dateFormats } from '../../../utils/dateUtils';
import Calendar from '../../shared/ReactCalendar';
import { LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT, CONFIRMATION_MESSAGE, CONFIRMATION_TITLE } from '../../../constants/DirtyPopupConstant';
import dirtystateSingleTon from '../../../validators/validateDirty';
import SPAConstants from '../../../constants/SPAConstants';

export default class PassportDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passportId: null,
      paxNumber: '',
      expiryDate: '',
      paxName: '',
      nationality: {},
      issuingCountry: {},
      isDefault: false,
      isAdded: false,
      isUpdated: false,
      isDeleted: false,
      isActive: true,
      isInvalid: 0,
      isOnHands: null,
      errors: {},
      popupMessage: '',
      isFieldsChanged: false,
    };
    const forState = {
      passportId: null,
      paxNumber: '',
      expiryDate: '',
      paxName: '',
      nationality: null,
      issuingCountry: null,
      isDefault: false,
    };
    this.oldState = Object.assign({}, forState);
    this.paxMapping = [];
  }

  componentDidMount() {
    this.populateValues();
    this.generatePaxNumberAndIdMapping(this.props.isEdit, this.props.editFieldId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user.user !== nextProps.user.user
      && nextProps.user.isProfileUpdated
    ) {
      const { toggleIsPassportAddEditDetails } = this.props;
      toggleIsPassportAddEditDetails();
    }
    if (nextProps.user.isProfileUpdateError) {
      window.UIkit.modal('#ManagePassportErrorPopUp').show();
    }
  }

  generatePaxNumberAndIdMapping = (isEdit, editFieldId) => {
    const { user } = this.props.user;
    if (user.passports.ids.length > 0) {
      return user.passports.ids.map((pax) => {
        if (editFieldId !== pax) {
          return this.paxMapping.push({
            paxNumber: user.passports.content[pax].passportNumber,
            id: pax,
          });
        }
      });
    }
    return null;
  }

  getTwoLetterIsoCode = (paxId, paxCountryType) => {
    const { passports } = this.props.user.user;
    const isoIssuedByCountry = passports.content[paxId][paxCountryType];
    const flagData = countryData.find(
      item => item.THREE_LETTER_CODE.toUpperCase()
        === isoIssuedByCountry.toUpperCase(),
    );
    let flagIso2 = flagData && flagData.TWO_LETTER_CODE;
    if (flagIso2.toUpperCase() === COUNTRY_CODE_TAIWAN_ISO2) flagIso2 = COUNTRY_CODE_CHINA_ISO2;
    return {
      iso2: flagIso2,
      COUNTRY_NAME: flagData.COUNTRY_NAME
    };
  }

  generateObjectOfCountry = (paxId, paxCountryType) => {
    const { passports } = this.props.user.user;
    const flagData = this.getTwoLetterIsoCode(paxId, paxCountryType);
    const flagIcon = `flag-icon-style flag-icon-${flagData.iso2.toLowerCase()}`;
    const countryLabel = Helpers.truncateStr(convertToTitleCase(flagData.COUNTRY_NAME), getNameLengthToDisplay());
    return {
      value: flagData.COUNTRY_NAME,
      label: <div className="country-list-input"><span className={flagIcon} ></span><span className="country-name">{countryLabel}</span></div>, // eslint-disable-line
      code: passports.content[paxId][paxCountryType],
    };
  }

  populateValues = () => {
    const { user } = this.props.user;
    const { isAdd, isEdit, editFieldId } = this.props;
    const forState = {};
    if (isEdit) {
      forState.passportId = editFieldId;
      forState.paxName = user.passports.content[editFieldId].displayName;
      forState.paxNumber = user.passports.content[editFieldId].passportNumber;
      forState.expiryDate = moment(user.passports.content[editFieldId].expiresOnDate);
      forState.nationality = this.generateObjectOfCountry(editFieldId, 'isoPassportCountry');
      forState.issuingCountry = this.generateObjectOfCountry(editFieldId, 'isoIssuedByCountry');
      forState.isDefault = user.passports.content[editFieldId].isDefault;
      forState.isActive = user.passports.content[editFieldId].isActive;
      forState.isInvalid = user.passports.content[editFieldId].isInvalid;
      forState.isDeleted = user.passports.content[editFieldId].isDeleted;
      this.oldState = Object.assign({}, forState);
    }
    forState.isAdded = isAdd;
    forState.isUpdated = isEdit;
    this.setState(forState);
  };

  generateCountries = () => {
    return countryData.map((country) => {
      const flagIcon = `flag-icon-style flag-icon-${country.TWO_LETTER_CODE.toLowerCase()}`;
      const countryLabel = convertToTitleCase(country.COUNTRY_NAME);
      return {
        value: country.COUNTRY_NAME,
        label: <div className="country-list-input"><span className={flagIcon} ></span><span className="country-name">{countryLabel}</span></div>, // eslint-disable-line
        code: country.THREE_LETTER_CODE,
      };
    });
  };

  checkIfPaxNumberExistsAndValidateForm = () => {
    const {
      paxNumber,
      expiryDate,
      paxName,
      nationality,
      issuingCountry,
    } = this.state;
    const {
      isAdd,
    } = this.props;
    const {
      user,
    } = this.props.user;
    const res = validateAddEditPassportForm({
      paxNumber,
      expiryDate,
      paxName,
      nationality,
      issuingCountry,
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      let paxExistsId = null;
      const exists = this.paxMapping.some((pax) => {
        if (paxNumber.toLowerCase() === pax.paxNumber) {
          paxExistsId = pax.id;
          return {
            id: pax.id,
          };
        }
        return null;
      });
      if (exists && isAdd) {
        this.setState({
          passportId: paxExistsId,
          isAdded: false,
          isUpdated: true,
          isDefault: user.passports.defaultId === paxExistsId,
        }, () => {
          this.overwritePopUp.show();
        });
      } else if (exists && !isAdd) {
        this.passportPopUp.show();
      } else {
        this.addOrUpdatePassport();
      }
    }
  }

  closeOverWritePopUp = () => {
    this.setState({
      passportId: null,
      isUpdated: false,
      isAdded: true,
    }, () => {
      this.closePopup(this.overwritePopUp);
    });
  }

  addOrUpdatePassport = () => {
    const {
      passportId,
      paxNumber,
      expiryDate,
      paxName,
      nationality,
      issuingCountry,
      isDefault,
      isAdded,
      isUpdated,
      isDeleted,
      isActive,
      isInvalid,
      isOnHands,
    } = this.state;

    const {
      user,
    } = this.props.user;

    const {
      isAdd,
      isEdit,
      updateUserProfileAction,
    } = this.props;

    const userPayload = {
      firstName: user.firstName,
      lastName: user.lastName,
      dateOfBirth: user.dateOfBirth,
      phoneNumber: user.phoneNumber,
      isPhoneNumberValid: true,
      email: user.username,
    };

    const defaultPaxId = user.passports.defaultId;
    const doPassportExist = user.passports.ids.length > 0;

    const passportPayload = {
      id: passportId,
      expiresOnDate: `${moment(expiryDate).format(dateFormats.YYYY_MM_DD_HHmmss)}Z`,
      isoPassportCountry: nationality && nationality.code,
      isoIssuedByCountry: issuingCountry && issuingCountry.code,
      displayName: paxName,
      passportNumber: paxNumber,
      isDefault,
      isAdded,
      isUpdated,
      isDeleted,
      isActive,
      isInvalid,
      isOnHands,
    };

    updateUserProfileAction(
      userPayload,
      user,
      this.normalizeAndCombinePassportPayload(
        passportPayload,
        doPassportExist
          ? user.passports.content[defaultPaxId]
          : null,
        defaultPaxId,
        isAdd,
        isEdit,
      ),
      -1,
      isAdd ? PaxOperationCode.Add : PaxOperationCode.Edit,
    )
      .then(() => { dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false) }, this.handleApiErrors);

    this.closePopup(this.overwritePopUp);
  }

  normalizeAndCombinePassportPayload = (
    newPayload,
    oldPayload,
    defaultId,
    isAdd = false,
    isEdit = false,
  ) => {
    const passportPayload = [];
    let payloadOfDefault = null;
    if (oldPayload) {
      payloadOfDefault = {
        id: oldPayload.id,
        expiresOnDate: oldPayload.expiresOnDate,
        isoPassportCountry: oldPayload.isoPassportCountry,
        isoIssuedByCountry: oldPayload.isoIssuedByCountry,
        displayName: oldPayload.displayName,
        passportNumber: oldPayload.passportNumber,
        isDefault: oldPayload.isDefault,
        isAdded: oldPayload.isAdded,
        isUpdated: oldPayload.isUpdated,
        isDeleted: oldPayload.isDeleted,
        isActive: oldPayload.isActive,
        isInvalid: oldPayload.isInvalid,
        isOnHands: oldPayload.isOnHands,
      };
    }
    if (isAdd) {
      if (newPayload.isDefault && oldPayload) {
        payloadOfDefault.isDefault = false;
      }
      if (oldPayload) {
        passportPayload.push(payloadOfDefault);
      }
      if (!oldPayload) {
        newPayload.isDefault = true;
      }
      passportPayload.push(newPayload);
      return passportPayload;
    }
    if (isEdit) {
      if (newPayload.isDefault && oldPayload) {
        payloadOfDefault.isDefault = false;
      }
    }
    if (newPayload.id === defaultId) {
      passportPayload.push(newPayload);
      return passportPayload;
    }
    passportPayload.push(payloadOfDefault);
    passportPayload.push(newPayload);
    return passportPayload;
  }

  handleApiErrors = (err) => {
    this.setState({
      popupMessage: err,
    });
  };

  handlePaxNameChange = (e) => {
    this.setState({ paxName: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handlePaxNumberChange = (e) => {
    this.setState({ paxNumber: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleExpiryChange = (date) => {
    this.setState({ expiryDate: date }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleNationalityCountryChange = (value) => {
    let newValue = value;
    newValue.label = getTruncatedCountryName(value.value, value.code);
    this.setState({
      nationality: newValue,
    }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleIssuingCountryChange = (value) => {
    let newValue = value;
    newValue.label = getTruncatedCountryName(value.value, value.code);
    this.setState({
      issuingCountry: newValue,
    }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleDefaultCheck = () => {
    const { isDefault } = this.state;
    this.setState({
      isDefault: !isDefault,
    }, () => {
      this.compareOldAndNewValues();
    });
  }

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  removeNationalityCountryError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors.nationality = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  }

  removeIssuingCountryError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors.issuingCountry = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  }

  compareOldAndNewValues = () => {
    const {
      isAdd,
    } = this.props;
    const {
      passportId,
      paxNumber,
      expiryDate,
      paxName,
      nationality,
      issuingCountry,
      isDefault,
      isFieldsChanged,
    } = this.state;
    const newState = {
      passportId,
      paxNumber,
      expiryDate,
      paxName,
      nationality,
      issuingCountry,
      isDefault,
    };
    let nationalityCheck = null;
    let issuingCountryCheck = null;
    let oldNationalityCheck = null;
    let oldIssuingCountryCheck = null;
    if (isAdd) {
      oldNationalityCheck = this.oldState.nationality;
      oldIssuingCountryCheck = this.oldState.issuingCountry;
      if (Object.keys(newState.nationality).length > 0) {
        nationalityCheck = newState.nationality.value;
      }
      if (Object.keys(newState.issuingCountry).length > 0) {
        issuingCountryCheck = newState.issuingCountry.value;
      }
    }
    if (!isAdd) {
      oldNationalityCheck = this.oldState.nationality.value;
      oldIssuingCountryCheck = this.oldState.issuingCountry.value;
      if (Object.keys(newState.nationality).length > 0) {
        nationalityCheck = newState.nationality.value;
      }
      if (Object.keys(newState.issuingCountry).length > 0) {
        issuingCountryCheck = newState.issuingCountry.value;
      }
    }

    if (
      (newState.paxNumber.toLowerCase() !== this.oldState.paxNumber.toLowerCase()
        || newState.expiryDate !== this.oldState.expiryDate
        || newState.paxName.toLowerCase() !== this.oldState.paxName.toLowerCase()
        || nationalityCheck !== oldNationalityCheck
        || issuingCountryCheck !== oldIssuingCountryCheck
        || newState.isDefault !== this.oldState.isDefault
      )
      && !isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, true);
      this.setState({
        isFieldsChanged: true,
      });
    } else if (
      (newState.paxNumber.toLowerCase() === this.oldState.paxNumber.toLowerCase()
        && newState.expiryDate === this.oldState.expiryDate
        && newState.paxName.toLowerCase() === this.oldState.paxName.toLowerCase()
        && nationalityCheck === oldNationalityCheck
        && issuingCountryCheck === oldIssuingCountryCheck
        && newState.isDefault === this.oldState.isDefault
      )
      && isFieldsChanged
    ) {

      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
      this.setState({
        isFieldsChanged: false,
      });
    }
  }

  handleGoBack = () => {
    const { isFieldsChanged } = this.state;
    const { toggleIsPassportAddEditDetails } = this.props;
    if (isFieldsChanged) {
      this.goBackPopUp.show();
    } else {
      toggleIsPassportAddEditDetails();
    }
  }

  closePopup = (ref) => {
    ref.hide();
  }

  goBackManagePassport = () => {
    dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
    this.goBackPopUp.hide();
    const { toggleIsPassportAddEditDetails } = this.props;
    toggleIsPassportAddEditDetails();
    this.goBackPopUp.hide();
  }

  renderPassportDetailsForm = () => {
    const countries = this.generateCountries();
    const {
      paxNumber,
      paxName,
      expiryDate,
      nationality,
      issuingCountry,
      isDefault,
      errors,
      popupMessage,
      isFieldsChanged,
    } = this.state;
    const { isAdd, isDefaultEdit } = this.props;
    const { isProfileUpdateLoading } = this.props.user;
    const title = isAdd ? 'Add Passport' : 'Edit Passport';
    const buttonText = isAdd ? 'Add Passport' : 'Update Passport';
    const buttonClass = isFieldsChanged
      ? 'uk-button change_password_button uk-width-1-1 uk-text-center vistajet_button_primary'
      : 'uk-button vistajet_button_primary vistajet_disabled change_password_button uk-width-1-1 uk-text-center';
    return (
      <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card vistajet_added_passport">
        {
          isProfileUpdateLoading && (
            <div className="vistajet_spinner">
              <SpinnerLoader />
            </div>
          )
        }
        <div className="vistajet_head_column">
          <div className="uk-clearfix">
            <div className="uk-float-left">
              <a href="#" onClick={this.handleGoBack}>
                <i data-uk-icon="icon: chevron-left; ratio: 1"></i>
                Back
            </a>
            </div>
            <div className="uk-text-center">
              <h3>{title}</h3>
            </div>
          </div>
        </div>
        {/* <div
          className="uk-grid-collapse vistajet_card_content uk-margin-remove vistajet_account_details_card uk-flex uk-align-center"
          data-uk-grid
        >
          <div className="uk-width-1-3@s">
          
          </div>
          <div className="uk-width-1-3@s">
            <h3 className="vistajet_right_tab_heading uk-text-center">
              {title}
            </h3>
          </div>
        </div> */}

        <div className="vistajet_profile_details_column vistajet_profile_edit">
          <div className="uk-flex uk-grid-small vistajet_primary_form uk-grid" data-uk-grid>
            <div className="vistajet_primary_form uk-width-1-2@s uk-grid-margin">
              <div
                className="vistajet_input_wrapper"
              >
                <input
                  id="paxNumber"
                  type="text"
                  maxLength="10"
                  name="passport-number"
                  onChange={this.handlePaxNumberChange}
                  onFocus={this.removeErrors}
                  value={paxNumber}
                />
                <label htmlFor="paxNumber">
                  Passport Number
                </label>
                {
                  errors.errors && errors.errors.paxNumber && (
                    <p>
                      {errors.errors.paxNumber}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="vistajet_primary_form uk-width-1-2@s uk-grid-margin">
              <div className="vistajet_input_wrapper">
                <div className="vistajet_date_picker_new">                 
                  <Calendar
                    dateInput={expiryDate}
                    placeholder={'Expiry Date (DD MMM YYYY)'}
                    readOnly={true}
                    minDate={moment().add(1, 'day')}
                    maxDate={moment().add(10, 'year')}
                    onDateChange={this.handleExpiryChange}
                    className="uk-width-1-1"
                  />
                  {
                    errors.errors && errors.errors.expiryDate && (
                      <p className="vistajet_outer_error">
                        {errors.errors.expiryDate}
                      </p>
                    )
                  }
                </div>
              </div>
            </div>

            <div className="vistajet_primary_form uk-width-1 uk-grid-margin uk-first-column">
              <div
                className="vistajet_input_wrapper"
              >
                <input
                  id="paxName"
                  type="text"
                  name="passport-name"
                  onChange={this.handlePaxNameChange}
                  onFocus={this.removeErrors}
                  value={paxName}
                  autocomplete="name"
                />
                <label htmlFor="paxName">
                  Name on Passport
                </label>
                {
                  errors.errors && errors.errors.paxName && (
                    <p>
                      {errors.errors.paxName}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="vistajet_primary_form vistajet_displayname_container vistajet_select_forms vistajet_remove_input_wrapper_transition uk-width-1-2@s uk-grid-margin uk-first-column">
              <div className="vistajet_input_wrapper">
                <Select
                  classNamePrefix="react-select"
                  onChange={this.handleNationalityCountryChange}
                  onFocus={this.removeNationalityCountryError}
                  options={countries}
                  value={nationality}
                  id="nationality"
                  valueKey="value"
                  labelKey="label"
                  className="vistajet_select_input"
                  placeholder=""
                />
                <label>
                  Nationality
                </label>
                {
                  errors.errors && errors.errors.nationality && (
                    <p>
                      {errors.errors.nationality}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="vistajet_primary_form vistajet_displayname_container vistajet_select_forms vistajet_remove_input_wrapper_transition uk-width-1-2@s uk-grid-margin">
              <div className="vistajet_input_wrapper">
                <Select
                  classNamePrefix="react-select"
                  onChange={this.handleIssuingCountryChange}
                  onFocus={this.removeIssuingCountryError}
                  options={countries}
                  value={issuingCountry}
                  id="issuingCountry"
                  valueKey="value"
                  labelKey="label"
                  className="vistajet_select_input"
                  placeholder=""
                />
                <label>
                  Issuing Country
                </label>
                {
                  errors.errors && errors.errors.issuingCountry && (
                    <p>
                      {errors.errors.issuingCountry}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="vistajet_primary_form uk-width-1-2@s vistajet_checkbox_user_profile">
              <label>
                <input
                  id="isDefault"
                  className="uk-checkbox"
                  type="checkbox"
                  name="default-passport"
                  checked={isDefault}
                  onClick={this.handleDefaultCheck}
                  onFocus={this.removeErrors}
                  disabled={isDefaultEdit}
                />
                Set as default passport
              </label>
            </div>
          </div>
        </div>

        <div className="vistajet_profile_footer">
          <div className="uk-width-1 uk-padding-remove">
            <div className="vistajet_profile_footer">
              <button
                className={buttonClass}
                onClick={this.checkIfPaxNumberExistsAndValidateForm}
                disabled={!isFieldsChanged}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>

        <ConfirmationPopUp
          ref={(goBackPopUp) => { this.goBackPopUp = goBackPopUp; }}
          title={CONFIRMATION_TITLE}
          leftButtonContent={LEFT_BUTTON_TEXT}
          onLeftButtonClick={() => this.closePopup(this.goBackPopUp)}
          rightButtonContent={RIGHT_BUTTON_TEXT}
          onRightButtonClick={this.goBackManagePassport}
          content={CONFIRMATION_MESSAGE}
        />
        <ConfirmationPopUp
          id="doYouWantToOverwrite"
          ref={(overwritePopUp) => { this.overwritePopUp = overwritePopUp; }}
          leftButtonContent="Cancel"
          onLeftButtonClick={this.closeOverWritePopUp}
          rightButtonContent="Overwrite"
          onRightButtonClick={this.addOrUpdatePassport}
          title="This passport is already saved"
          content="Do you want to overwrite it?"
          onOverlayClick={this.closeOverWritePopUp}
        />
        <ConfirmationPopUp
          id="passportInUse"
          ref={(passportPopUp) => { this.passportPopUp = passportPopUp; }}
          rightButtonContent="Ok"
          onRightButtonClick={() => this.closePopup(this.passportPopUp)}
          title="Passport number in use"
          content="You are trying to add an already registered passport"
        />
        <MessagePopUp
          id="ManagePassportErrorPopUp"
          content={popupMessage}
        />
      </div>
    );
  };

  render() {
    return this.renderPassportDetailsForm();
  }
}
