import React from "react";
import AircraftDropdown from "../../shared/AircraftDropDown/AircraftDropdown";

export default function AircraftDropdownWrapper(props) {
  return (
    <AircraftDropdown
      aircraftsList={props.aircraftsList}
      validateContract={props.validateContract}
      allowAircraftSelect={props.allowAircraftSelect}
      isContractSelected={props.isContractSelected}
      handleAircraftChange={props.handleAircraftChange}
      selectedContract={props.selectedContract}
      availableAircraftIds={props.availableAircraftIds || []}
      leg={props.leg}
      isOfferFlow={props.isOfferFlow}
      showAircraftInContract={props.showAircraftInContract}
      passengers={props.passengers}
      isEditFlow={props.isEditFlow}
      passengersCount={props.passengersCount}
    />
  );
}
