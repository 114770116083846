import axios from "axios";
import getLocation from "./geolocationAPI";

const url = "https://api.flyxo.com/airports/suggest_airport";
const sortByWeight = (a1, a2) => ((a2.weight || 0) - (a1.weight || 0))

//  airport types
export const METROPOLITAN_AREA_WITH_AIRPORTS = "metropolitan-area-with-airports";
export const METROPOLITAN_AREA = "metropolitan-area";
export const NORMAL_AIRPORT = "airport";
export const NESTED_AIRPORT = "airport-nested";

function normalizeAirports(airportData) {
	const { ap: airports, ma: metropolitanAreas } = airportData

	const airportsByAreas = airports.sort(sortByWeight).reduce((result, airport) => {
		if (!result.rest) result.rest = []

		const parentMA = metropolitanAreas.find(({ metropolitan_area_id }) =>
			metropolitan_area_id === airport.metropolitan_area_id
		)
		if (parentMA) {
			if (!result[airport.metropolitan_area_id]) {
				result[airport.metropolitan_area_id] = [];
			}

			result[airport.metropolitan_area_id].push({
				...airport,
				ma_name: parentMA.name,
				type: NESTED_AIRPORT
			});

			return result;
		}

		result.rest.push({ ...airport, type: NORMAL_AIRPORT });

		return result;
	}, {})

	const flattenDestionations = metropolitanAreas.reduce((acc, ma) => {
		if (airportsByAreas[ma.metropolitan_area_id]) {
			acc.push({ ...ma, type: METROPOLITAN_AREA_WITH_AIRPORTS });
			acc = [...acc, ...airportsByAreas[ma.metropolitan_area_id]];
		} else {
			acc.push({ ...ma, type: METROPOLITAN_AREA });
		}

		return acc;
	}, []);

	return airportsByAreas.rest
		? [...flattenDestionations, ...airportsByAreas.rest]
		: flattenDestionations;
}

export async function getNearestAirports() {
/* 	const location = await getLocation();
	if (!location || !location.coords) return;

	const response = await axios.post(url,
		{
			geo: { lat: location.coords.latitude, lon: location.coords.longitude }
			//geo: { lat: 48.8566, lon: 2.3522 }	// Paris coords for testing
		});
	//console.log(":getNearestAirport::response");
	//console.log(response);

	if (response.status === 200 && response.data && response.data.ma && response.data.ma[0]) {
		let result = normalizeAirports(response.data);
		//console.log(":getNearestAirport::result");
		//console.log(result);
		return result;
	} */
	return null;
}

export async function getPopularAirports() {
	const response = await axios.post(url, { geo: {} });
	//console.log(":getPopularAirports::response");
	//console.log(response);

	if (response.status === 200 && response.data && response.data.ma) {
		let result = normalizeAirports(response.data);
		//console.log(":getPopularAirports::result");
		//console.log(result);
		return result;
	}
	return null;
}

export async function searchAirports(searchValue) {
	var sessionKey = null;

	const data = {
		text: searchValue,
		...(sessionKey && { key: sessionKey })
	}

	const response = await axios.post(url, data);
	//console.log(":searchAirports::response");
	//console.log(response);

	if (!sessionKey) {
		sessionKey = response.data.key;
	}
	let result = normalizeAirports(response.data);
	//console.log(":searchAirports::result");
	//console.log(result);
	return result;
}
