import { connect } from 'react-redux';

import withStore from '../components/withStore';
import RegistrationConfirm from '../components/Registration/RegistrationConfirm';
import * as actions from '../store/actions';


function mapStateToProps(state, props) {
  const { user } = state.registration;
  const {
    message,
    isMessageSent,
    isLoading,
    isError,
  } = state.resendCode;
  const { isUserVerified, authenticatedUser } = state.user;
  const { isDirectConfirmContainer } = props;
  return {
    adminToken: state.token.adminToken,
    token: state.token.token,
    user,
    message,
    isMessageSent,
    isLoading,
    isError,
    isUserVerified,
    authenticatedUser,
    verifyLoading: state.user.isVerifyUserLoading,
    isVerifyUserError: state.user.isVerifyUserError,
    verifyUserError: state.user.verifyUserError,
    isDirectConfirmContainer,
    isTokenLoading: state.token.isRegistrationTokenLoading,
    isAuthLoading: state.token.isTokenAuthLoading,
    loggedInUser: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resendRegisterCodeAction: obj => dispatch(actions.resendRegisterCodeAction(obj)),
    verifyUserAction: verificationCode => dispatch(actions.verifyUserAction(verificationCode)),
    fetchToken: () => dispatch(actions.fetchToken(null, null, true)),
    authenticateToken: token => dispatch(actions.authenticateTokenAction(token)),
    clearNotVerifiedFlag: () => dispatch(actions.clearNotVerifiedUser()),
  };
}

const RegistrationConfirmContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationConfirm);

export default withStore(RegistrationConfirmContainer);
