import axios from 'axios';
import { ITINERARY_DETAIL_PDF } from '../../configs/constants';
import {
  itineraryPdfInit,
  itineraryPdfSuccess,
  itineraryPdfFail,
} from '../actions';
import {
  handleServerErrors,
  parseString,
} from '../../utils/sourceUtils';
import { SOMETHING_UNEXPECTED } from '../../constants/MessageConstants';

const status = {
  OK: 200,
};

const itineraryPdfPromiseInprogress = {};

const fetchItineraryPdfSource = (token, flightLegId) => (dispatch) => {
  const uniqueKey = flightLegId;
  if (itineraryPdfPromiseInprogress[uniqueKey]) {
    return itineraryPdfPromiseInprogress[uniqueKey];
  }
  const itineraryPdfPromise = new Promise((resolve, reject) => {
    const url = ITINERARY_DETAIL_PDF.replace('{token}', token).replace('{flightLegId}', flightLegId);
    dispatch(itineraryPdfInit(flightLegId));
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          parseString(response.data, (parseErr, response) => {
            if (parseErr) {
              reject({ text: SOMETHING_UNEXPECTED });
            }
            dispatch(itineraryPdfSuccess(response.itineraryPdf.fileUrl, flightLegId))
            resolve(response.itineraryPdf.fileUrl, flightLegId);
          });
        } else {
          const errors = handleServerErrors(response.status);
          dispatch(itineraryPdfFail(errors, flightLegId));
          reject(errors);
        }
        delete itineraryPdfPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(itineraryPdfFail(errors, flightLegId));
        reject(errors);
        delete itineraryPdfPromiseInprogress[uniqueKey];
      });
  });
  itineraryPdfPromiseInprogress[uniqueKey] = itineraryPdfPromise;
  return itineraryPdfPromise;
};

export default fetchItineraryPdfSource;
