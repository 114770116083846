import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import * as numeral from 'numeral';
import PostTransport from '../../utils/postTransport'

export default class CostComparisonCard extends Component {

  handleDiscoverMoreClick = url => window.open(url);

  feetToMetre = feet => (feet / 3.2808).toFixed(2);

  feetCubeToMetreCube = feet => (feet / 35.315).toFixed(2);

  nmToKm = nm => (nm / 0.53996).toFixed(0);

  mphToKmh = mph => (mph * 1.609344).toFixed(0);

  
  submitHiddenForm = () => {
    const { payload } = this.props;

    PostTransport(payload.url, payload.data)
  }

  getResult = ({
    price, fixed, variable, crew,
  }, vjAircraft) => {
    const { hours } = this.props;
    const finalCrew = hours > 200 ? crew * 2 : crew;
    const depreciation = price * 0.1;
    const saving = ((depreciation + fixed + ((hours * 1.2) * variable) + finalCrew) / hours) - vjAircraft.rate;
    const savingFormatted = numeral(Math.abs(saving)).format('0,0');
    const savingString = (saving < 0)
      ? `$${savingFormatted} additional cost per flight hour`
      : `$${savingFormatted} saving per flight hour`;

    let env = (window.globalEnv && window.globalEnv.toLowerCase());
    if (env === 'production')
    {
      this.props.payload.data['00N4G000006eYtw'].saving = savingString;
      this.props.payload.data['00N4G000006eYtw'].isSaving = (saving > 0);
      this.props.payload.data['00N4G000006eYtw'].amount = Math.round(Math.abs(saving));
      this.props.payload.data['00N4G000006eYtw'] = JSON.stringify(this.props.payload.data['00N4G000006eYtw'])
    } else {
      this.props.payload.data['00N4G000006eYtw'].saving = savingString;
      this.props.payload.data['00N4G000006eYtw'].isSaving = (saving > 0);
      this.props.payload.data['00N4G000006eYtw'].amount = Math.round(Math.abs(saving));
      this.props.payload.data['00N4G000006eYtw'] = JSON.stringify(this.props.payload.data['00N4G000006eYtw'])
    }
    this.submitHiddenForm();

    const tempString = (saving < 0)
      ? `Flying in a VistaJet ${vjAircraft.title} could cost an additional <span class="vistajet_red_text">$${savingFormatted}</span>  per flight hour*`
      : `Flying in a VistaJet ${vjAircraft.title} could save you <span class="vistajet_red_text">$${savingFormatted}</span> per flight hour*`;
    return { __html: tempString };
  };

  render() {
    const { aircraft, isLoading } = this.props;
    if (!aircraft) return null;
    const { vjAirCraft } = aircraft;
    return (
      <div className="uk-container">
        <div className="uk-flex uk-margin-large-top uk-grid-large" data-uk-grid>
          <div
            className="uk-width-1-1@m uk-width-1-1@s"
            uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 25;"
          >
            <div className="vistajet_aircraft_comparison uk-card uk-card-default uk-card-body vistajet_card">
              {
                isLoading &&
                (
                  <div className="vistajet_spinner"><SpinnerLoader /></div>
                )
              }
              <img className="uk-align-center vistajet_aircraft_display" src={vjAirCraft.image.default} alt={aircraft.title} />
              <h4 className="vistajet_additional_text uk-text-center" >
                <span dangerouslySetInnerHTML={this.getResult(aircraft, vjAirCraft)}></span>
              </h4>
              <div className="vistajet_aircraft_comparison_deatils">
                <div className="uk-flex-center uk-grid-small" data-uk-grid>
                  <div className="uk-width-auto uk-first-column uk-padding-large">
                    <h4>
                      {`Your current solution`}
                    </h4>
                    <h4>
                      {aircraft.title}
                    </h4>
                    <ul>
                      <li>
                        <strong>
                          Passenger capacity:
                          {' '}
                        </strong>
                        {aircraft.pax}
                      </li>
                      <li>
                        <strong>
                          {'Range: '}
                        </strong>
                        {aircraft.range}
                        {' '}
                        nm | {' '}
                        {this.nmToKm(aircraft.range)}
                        km
                      </li>
                      <li>
                        <strong>
                          Speed:
                          {' '}
                        </strong>
                        {aircraft.speed}
                        mph | {' '}
                        {this.mphToKmh(aircraft.speed)}
                        km/h
                      </li>
                      <li>
                        <strong>
                          Cabin Length:
                          {' '}
                        </strong>
                        {aircraft.cabinlength}
                        ft | {' '}
                        {this.feetToMetre(aircraft.cabinlength)}
                        m
                      </li>
                      <li>
                        <strong>
                          Cabin Height:
                          {' '}
                        </strong>
                        {aircraft.cabinheight}
                        ft | {' '}
                        {this.feetToMetre(aircraft.cabinheight)}
                        m
                      </li>
                      <li>
                        <strong>
                          Cabin Width:
                          {' '}
                        </strong>
                        {aircraft.cabinwidth}
                        ft | {' '}
                        {this.feetToMetre(aircraft.cabinwidth)}
                        m
                      </li>
                      <li>
                        <strong>
                          Baggage Capacity:
                          {' '}
                        </strong>
                        {aircraft.baggage}
                        ft
                        <sup>
                          3
                        </sup>
                        {' '}
                        {' '}
                        | {' '}
                        {this.feetCubeToMetreCube(aircraft.baggage)}
                        m
                        <sup>
                          3
                        </sup>
                      </li>
                    </ul>

                  </div>
                  <div className="uk-width-auto uk-padding-large">
                    <h4>
                      {`The VistaJet solution`}
                    </h4>
                    <h4>
                      {vjAirCraft.title}
                    </h4>
                    <ul>
                      <li>
                        <strong>
                          Passenger capacity:
                          {' '}
                        </strong>
                        {vjAirCraft.pax}
                      </li>
                      <li>
                        <strong>
                          Range:
                          {' '}
                        </strong>
                        {vjAirCraft.range}
                        nm | {' '}
                        {this.nmToKm(vjAirCraft.range)}
                        km
                      </li>
                      <li>
                        <strong>
                          Speed:
                          {' '}
                        </strong>
                        {vjAirCraft.speed}
                        mph | {' '}
                        {this.mphToKmh(vjAirCraft.speed)}
                        km/h
                      </li>
                      <li>
                        <strong>
                          Cabin Length:
                          {' '}
                        </strong>
                        {vjAirCraft.cabinlength}
                        ft | {' '}
                        {this.feetToMetre(vjAirCraft.cabinlength)}
                        m
                      </li>
                      <li>
                        <strong>
                          Cabin Height:
                          {' '}
                        </strong>
                        {vjAirCraft.cabinheight}
                        ft | {' '}
                        {this.feetToMetre(vjAirCraft.cabinheight)}
                        m
                      </li>
                      <li>
                        <strong>
                          Cabin Width:
                          {' '}
                        </strong>
                        {vjAirCraft.cabinwidth}
                        ft | {' '}
                        {this.feetToMetre(vjAirCraft.cabinwidth)}
                        m
                      </li>
                      <li>
                        <strong>
                          Baggage Capacity:
                          {' '}
                        </strong>
                        {vjAirCraft.baggage}
                        ft
                        <sup>
                          3
                        </sup>
                        {' '}
                        {' '}
                        | {' '}
                        {this.feetCubeToMetreCube(vjAirCraft.baggage)}
                        m
                        <sup>
                          3
                        </sup>
                      </li>
                    </ul>
                    <a
                      href="#"
                      onClick={() => this.handleDiscoverMoreClick(vjAirCraft.aircrafturl)}
                      className="vistajet_button_default"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              <p className="vistajet_aircraft_comparison_text">
                * Estimated additional cost is for comparison purposes
                only and is not to be used as the basis
                for financial planning. Additional cost is based on 10% annual depreciation of list price,
                estimated variable costs and estimated fixed costs.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
