import axios from "axios";
import { parseXmlStringToJson } from "../../utils/parserUtils";
import { getFromLocal, saveToLocal } from "../../utils/cache";
import { USER_REDUCER } from "../../constants/localStorageKeys";
import { USER_ACCESS_TYPES } from "../../constants/UserAccessTypes";
import { MEMBERSHIP_STATUS } from "../../constants/MembershipConstants";
import {
  requestQuoteInit,
  requestQuoteSuccess,
  requestQuoteFail,
  createCompanyInit,
  createCompanySuccess,
  createCompanyFail,
  updateUserCreateCompany,
  logoutAction
} from "../actions";
import { REQUEST_QUOTE_URL, CREATE_COMPANY_URL } from "../../configs/constants";
import status from "../../configs/status";
import createGuid from "../../utils/guidUtils";
import { handleServerErrors } from "../../utils/sourceUtils";
import {
  UNAUTHORIZED,
  BAD_REQUEST,
  TIMEOUT_CODE,
  INTERNAL_SERVER_ERROR
} from "../../constants/ServerCodeConstants";
import { parseString } from "../../utils/parserUtils";
import { guaranteeArray } from "../../utils/arrayUtils";
import { mapLegStatus } from "../../utils/PAFUtils";

export const requestQuote = (legs = [], userInfo) => dispatch => {
  dispatch(requestQuoteInit());

  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;

  let orderExtIdentifier = "";
  if (legs && legs[0] && legs[0].orderExtIdentifier) {
    let { orderExtIdentifier: orderId } = legs[0];
    orderExtIdentifier = orderId;
  } else {
    orderExtIdentifier = createGuid();
  }
  const accountId = userInfo.accounts && userInfo.accounts[0].id;
  const { parentQuoteId } = legs[0];

  let orderXml =
    "<flightRequestForCustomer>" +
    `<quoteId>${orderExtIdentifier}</quoteId>` +
    (parentQuoteId ? `<parentQuoteId>${parentQuoteId}</parentQuoteId>` : '') +
    `<account>${accountId}</account>` +
    "{contactInfo}" +
    "<flightLegs>" +
    "{flightLegs}" +
    "</flightLegs>" +
    "</flightRequestForCustomer>";

  const legsXml =
    "<flightLeg>" +
    "<isCancelled>{isCancelled}</isCancelled>" +
    "<flightLegExtIdentifier>{flightLegExtIdentifier}" +
    "</flightLegExtIdentifier>" +
    "<departureAirportId>{departureAirportId}</departureAirportId>" +
    "<arrivalAirportId>{arrivalAirportId}</arrivalAirportId>" +
    "<numberOfPassenger>{numberOfPassenger}</numberOfPassenger>" +
    "<requestedAircraftTypeId>{requestedAircraftTypeId}" +
    "</requestedAircraftTypeId>" +
    "<aircraftType>{aircraftType}</aircraftType>" +
    "<requestedAircraftTypeName>{requestedAircraftTypeName}" +
    "</requestedAircraftTypeName>" +
    "<departureTime>{departureTime}</departureTime>" +
    "<version>{version}</version>" +
    "<arrivalTime>{arrivalTime}</arrivalTime>" +
    "</flightLeg>";

  let contactXml =
    "<contact>" +
    "<userFirstName>{userFirstName}</userFirstName>" +
    "<userLastName>{userLastName}</userLastName>" +
    "<userEmail>{userEmail}</userEmail>" +
    "<userPhoneNumber>{userPhoneNumber}</userPhoneNumber>" +
    "</contact>";

  const {
    firstName,
    lastName,
    username,
    personPhoneNumbers,
    phoneNumber
  } = userInfo;
  const number = personPhoneNumbers
    ? personPhoneNumbers.phoneNumber
    : phoneNumber;
  contactXml = contactXml
    .replace("{userFirstName}", firstName)
    .replace("{userLastName}", lastName)
    .replace("{userEmail}", username)
    .replace("{userPhoneNumber}", number || "");

  orderXml = orderXml.replace("{contactInfo}", contactXml);

  const options = {
    headers: {
      "Content-Type": "application/xml"
    },
  };

  let totalLegs = "";
  let flightLeg = "";
  legs.forEach(leg => {
    const {
      guid,
      departureAirport,
      arrivalAirport,
      passengersCount,
      selectedAircraft,
      departTime,
      arrivalTime
    } = leg;
    const { id, displayName, code } = selectedAircraft;
    flightLeg = legsXml
      .replace("{isCancelled}", "false")
      .replace("{flightLegExtIdentifier}", guid)
      .replace("{departureAirportId}", departureAirport.id)
      .replace("{arrivalAirportId}", arrivalAirport.id)
      .replace("{numberOfPassenger}", passengersCount)
      .replace("{aircraftType}", code)
      .replace("{requestedAircraftTypeId}", id)
      .replace("{requestedAircraftTypeName}", displayName)
      .replace("{departureTime}", departTime)
      .replace("{arrivalTime}", arrivalTime)
      .replace("{version}", "0"); // 0 for insertion

    totalLegs += flightLeg;
  });

  const payload = orderXml.replace("{flightLegs}", totalLegs);

  axios
    .post(REQUEST_QUOTE_URL.replace("{token}", token), payload, options)
    .then(response => {
      if (response.status === status.OK) {
        parseString(response.data, (parseErr, result) => {
          if (parseErr) {
            dispatch(requestQuoteFail(handleServerErrors(BAD_REQUEST)));
          } else if (result.responseBody.responseHeader.isError) {
            const error = result.responseBody.responseHeader;
            dispatch(requestQuoteFail(handleServerErrors(error.statusCode)));
          } else {
            const flightLeg = guaranteeArray(
              result.responseBody.flightLegs.flightLeg
            );
            const status = mapLegStatus(flightLeg[0].legStatus);
            dispatch(requestQuoteSuccess({ status, legs }));
          }
        });
      } else {
        dispatch(requestQuoteFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (!err.status) {
        dispatch(requestQuoteFail(handleServerErrors()));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(requestQuoteFail(handleServerErrors(UNAUTHORIZED)));
        dispatch(logoutAction(token));
      } else {
        const jsonData = parseXmlStringToJson(err.response.text);
        if (!!jsonData && +jsonData.error.code[0]) {
          dispatch(
            requestQuoteFail(handleServerErrors(+jsonData.error.code[0]))
          );
        } else {
          dispatch(requestQuoteFail(handleServerErrors(BAD_REQUEST)));
        }
      }
    });
};

export const createCompany = legs => dispatch => {
  dispatch(createCompanyInit());

  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;

  const options = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  axios
    .post(CREATE_COMPANY_URL.replace("{token}", token), options)
    .then(response => {
      if (response.status === status.OK) {
        const { data } = response;
        if (data && data.responseHeaderDto && data.responseHeaderDto.isError) {
          const error = handleServerErrors(data.responseHeaderDto.statusCode);
          dispatch(createCompanyFail(error));
        } else {
          const { user } = data.accountStatusDto;
          dispatch(createCompanySuccess());
          let userInfo = getFromLocal(USER_REDUCER, true, true);
          let updatedUser = { ...userInfo.user, ...user };
          updatedUser.accessType = USER_ACCESS_TYPES.MEMBER;
          updatedUser.membershipStatus = MEMBERSHIP_STATUS.ADHOC
          updatedUser.membershipTypeId = MEMBERSHIP_STATUS.ADHOC
          userInfo.user = updatedUser;
          saveToLocal(userInfo, USER_REDUCER, true, true);
          dispatch(updateUserCreateCompany(userInfo.user));
          dispatch(requestQuote(legs, user));
        }
      } else {
        dispatch(createCompanyFail(handleServerErrors(INTERNAL_SERVER_ERROR)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(createCompanyFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else if (!err.status) {
        dispatch(createCompanyFail(handleServerErrors()));
      } else {
        dispatch(createCompanyFail(handleServerErrors(+err.status)));
      }
    });
};


