import axios from "axios";
import { jsonToXml, parseString } from "utils/parserUtils";
import { getFromLocal } from '../../utils/cache';
import { USER_REDUCER } from "../../constants/localStorageKeys";
import { VALIDATE_FLIGHT_URL } from "../../configs/constants";
import {
  validateFlightInit,
  validateFlightSuccess,
  validateFlightFail,
  availOfferQuote,
} from "../actions";
import moment from "moment";
import status from "configs/status";
import { normalizeValidateFlightResponse } from "normalizers/validateFlightNormalizer";
import { requestAllFSPFlights } from "store/actions/PAFActions";
import { handleServerErrors } from "utils/sourceUtils";
import { BAD_REQUEST, TIMEOUT_CODE } from "constants/ServerCodeConstants";
import { dateFormats } from "utils/dateUtils";

const payloadFSP = (legs, offer) =>
  legs.map((leg, key) => {
    const {
      departureAirport,
      arrivalAirport,
      departTime,
      arrivalTime,
      selectedAircraft,
      passengersCount
    } = leg;
    const flightLeg = {
      flightLegId: key + 1,
      departureAirportId: departureAirport.id,
      arrivalAirportId: arrivalAirport.id,
      departureTime: moment(departTime)
        .utc()
        .format(dateFormats.YYYY_MM_DD_WITH_TIME),
      arrivalTime: moment(arrivalTime)
        .utc()
        .format(dateFormats.YYYY_MM_DD_WITH_TIME),
      requestedAircraftTypeId: selectedAircraft.id,
      numberOfPassenger: passengersCount
    };
    if (offer) {
      flightLeg.requestedAircraftId = leg.aircraftId;
      flightLeg.requestedAircraftTailNumber = leg.tailNumber;
    }
    return flightLeg;
  });

const validateFlights = (flightLegs, accountId, offer = false, isEdit = false, order = {}, passengers = {}) => dispatch => {
  dispatch(validateFlightInit());

  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;

  const options = {
    headers: {
      "Content-Type": "application/xml"
    },
    timeout: 40000,
  };
  const requestPayload = jsonToXml({
    flightValidationRequestDto: {
      flightLegs: {
        flightLeg: flightLegs && payloadFSP(flightLegs, offer)
      }
    }
  });

  axios
    .post(
      VALIDATE_FLIGHT_URL.replace("{token}", token),
      requestPayload,
      options
    )
    .then(response => {
      if (response.status === status.OK) {
        parseString(response.data, (parseErr, result) => {
          if (parseErr) {
            dispatch(validateFlightFail(handleServerErrors(BAD_REQUEST)));
          } else if (result.validateFlightResponseDto.responseHeader.isError) {
            const error = result.validateFlightResponseDto.responseHeader;
            dispatch(validateFlightFail(handleServerErrors(error.statusCode)));
          } else if (result.validateFlightResponseDto.responseHeader.statusCode === 0) {
            dispatch(validateFlightSuccess());
            offer ? dispatch(availOfferQuote(flightLegs, user))
              : dispatch(requestAllFSPFlights(flightLegs, accountId, isEdit, order, passengers));
          } else {
            const responseBody = result.validateFlightResponseDto.responseBody;
            dispatch(validateFlightFail(normalizeValidateFlightResponse(responseBody)));
          }
        });
      } else {
        dispatch(validateFlightFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(validateFlightFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else dispatch(validateFlightFail(handleServerErrors(BAD_REQUEST)));
    });
};

export default validateFlights;
