import React, { Component } from 'react';
import Select from 'react-select';
import SpinnerLoader from '../shared/spinnerLoader';
import IntlTelphoneInput from "../shared/PhoneNumberSelector";
import cn from "classnames";
import { getFirstChars } from "../../utils/stringUtils";
import { Salutation } from '../../constants/SignupConstants';
import OTHER_AIRCRAFTS from '../../utils/OtherAircrafts';
import { TERMS_URL_PAGE, POLICY_URL_PAGE } from "../../configs/constants";
import validateCostCalculatorForm from "../../utils/Validators/CostCalculatorFormValidator";
import GeoLocation from "../../utils/geolocation";
import StateAbbreviation, { stateList } from '../../utils/stateUtils'

const aircrafts = [
  'Challenger 350',
  'Challenger 605',
  'Challenger 850',
  'Citation Excel',
  'Citation Sovereign',
  'Citation X',
  'Falcon 2000',
  'Falcon 2000EX',
  'Falcon 2000LX',
  'Falcon 7X',
  'Falcon 900EX EASy',
  'Falcon 900EX',
  'Global 5000',
  'Global 6000',
  'Gulfstream 450',
  'Gulfstream 550',
  'Gulfstream 550',
  'Gulfstream 650',
  'Hawker 4000',
  'Hawker 750',
  'Hawker 900XP',
  'Learjet 60XR',
  'Legacy 600',
  'Legacy 650',
];

export default class CostCalculatorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aircraft: '',
      selectedAircraft: null,
      hours: '',
      firstName: '',
      lastName: '',
      email: '',
      defaultCountry: null,
      country: "",
      state: "",
      phoneNumber: "",
      isPhoneNumberValid: false,
      possession: null,
      errors: {},
      salutation: Salutation[0],
      typeOfFlying: "",
      currentFlyingSolution: "",
      marketingCheckbox: false,
      privacyCheckbox: false,
    };
  }

  componentDidMount() {
    GeoLocation().then(pos => {
      if (!pos || !pos.region)
        return;
      this.setState({ stateCode: StateAbbreviation(pos.region) });
    }).catch(err => { });
  }

  componentWillReceiveProps(nextProps) {
    const { onClearReducer } = this.props;
    if (this.props.isSuccess !== nextProps.isSuccess) {
      onClearReducer();
      // this.refreshFormFields();
    }
  }

  geoLookupCountryCode = (code) => {
    this.geoLookupCountryCode = code;
  }

  handleAircraftChange = aircraft => this.setState({ aircraft });

  handlePossessionChange = possession => this.setState({ possession });

  handleHoursChange = e => {
    let hours = e.target.value;
    hours = hours.replace(/[.]/g, '');
    if (!isNaN(hours) && hours.length <= 4) {
      this.setState({ hours });
    }
  };

  handleFirstNameChange = firstName => this.setState({ firstName });

  handleLastNameChange = lastName => this.setState({ lastName });

  handleEmailChange = email => this.setState({ email });

  handlePhoneNumberChange = (status = true, phoneNumber, countryData) => {
    const phoneStrLength = phoneNumber.length;
    this.setState({
      country: phoneStrLength > 0 ? countryData.iso2.toUpperCase() : "",
      phoneNumber: getFirstChars(phoneNumber, 15, status),
      isPhoneNumberValid: status,
      requestACallBack: phoneStrLength > 0,
    });
  };

  handleStateChange = e => {
    this.setState({ stateCode: e.target.value })
  }

  handleTypeOfFlyingChange = e => {
    this.setState({ typeOfFlying: e.target.value })
  }

  handleCurrentFlyingSolutionChange = e => {
    this.setState({ currentFlyingSolution: e.target.value })
  }

  removePhoneError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      if (remErrors.errors && remErrors.errors.phoneNumber) {
        remErrors.errors.phoneNumber = null;
      }
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError
      };
      this.setState({
        errors: newState
      });
    }
  };

  handleSalutationChange = salutation => this.setState({ salutation });

  handleMarketingCheck = marketingCheckbox => this.setState({ marketingCheckbox });

  handlePrivacyCheck = privacyCheckbox => this.setState({ privacyCheckbox });

  handleSubmit = () => {
    const { aircraft = '', hours, email,
      country,
      stateCode,
      phoneNumber,
      isPhoneNumberValid,
      firstName,
      lastName,
      salutation,
      privacyCheckbox,
      typeOfFlying,
      currentFlyingSolution
    } = this.state;
    const { updateData } = this.props;
    const res = validateCostCalculatorForm({
      salutation,
      firstName,
      lastName,
      email,
      country,
      stateCode,
      phoneNumber,
      isPhoneNumberValid,
      privacyCheckbox,
      typeOfFlying,
      currentFlyingSolution
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      try {
        window.dataLayer.push({ 'event': 'enquirySubmitted', 'enquiryType': 'Cost Calculator' });
      } catch (e) {
        console.warn("dataLayer push failed for 'Cost Calculator': " + e)
      }
      const selectedAircraft = OTHER_AIRCRAFTS[aircraft.value] || null;
      if (selectedAircraft && hours) {
        const costCalculatorPayload = this.createPayload();

        updateData({
          aircraft: {
            ...selectedAircraft,
            title: aircraft.value,
          },
          hours,
          payload: costCalculatorPayload,
        });

        var p = document.createElement('img');
        p.src = "https://secure.adnxs.com/px?id=1090189&seg=17321571&redir=https%3A%2F%2Fpixel.mediaiqdigital.com%2Fpixel%3Fu1%3DCostCalculator%26u3%3D%26u4%3D%26pixel_id%3D1090189%26uid%3D%24%7BUID%7D&t=2";
        p.width = 1;
        p.height = 1;
        document.body.appendChild(p);
      }
    }
  };

  createPayload = () => {
    const VALUE_ENV_MAP = {
      preproduction: {
        oid: '00D2z0000000TxX',
        url: 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
      },
      production: {
        oid: '00D200000005jIW',
        url: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
      }
    }

    const FIELDS_MAP = {
      //salutation: 'salutation',
      webSalutationText: (env) => (env === 'preproduction') ? '00N4G000006eYu2' : '00N4G000006eYu2',
      webSalutationOther: (env) => (env === 'preproduction') ? '00N4G000006eYty' : '00N4G000006eYty',
      greetingSalutation: '00N4G000006eQ6E',
      //title:                                                    'title',
      firstName: 'first_name',
      lastName: 'last_name',
      //email:                                                    'email',
      //company:                                                  'company',
      phoneNumber: 'phone',
      message: 'description',
      marketingOptIn: (env) => (env === 'preproduction') ? '00N4G000006eYtx' : '00N4G000006eYtx',
      country: 'country_code',
      state: 'state_code',
      typeOfFlying: (env) => (env === 'preproduction') ? '00N2z00000CfdY3' : '00N4G000006rATd',
      currentFlyingSolution: (env) => (env === 'preproduction') ? '00N2z00000COAMx' : '00N4G000006rA1m',
      conversionURL: (env) => (env === 'preproduction') ? '00N4G000006eYtq' : '00N4G000006eYtq',
      googleSource: (env) => (env === 'preproduction') ? '00N4G000006eYu6' : '00N4G000006eYu6',
      googleMedium: (env) => (env === 'preproduction') ? '00N4G000006eYu5' : '00N4G000006eYu5',
      googleCampaign: (env) => (env === 'preproduction') ? '00N4G000006eYu3' : '00N4G000006eYu3',
      googleTerm: (env) => (env === 'preproduction') ? '00N4G000006eYu7' : '00N4G000006eYu7',
      customJSON: (env) => (env === 'preproduction') ? '00N4G000006eYtw' : '00N4G000006eYtw',
      flightsJSON: (env) => (env === 'preproduction') ? '00N4G000006eYts' : '00N4G000006eYts',
      vistajetAircraft: (env) => (env === 'preproduction') ? '00N4G000006eYu1' : '00N4G000006eYu1',
      internalNote: (env) => (env === 'preproduction') ? '00N4G000006eYtt' : '00N4G000006eYtt',
      callback: (env) => (env === 'preproduction') ? '00N4G000006eYtv' : '00N4G000006eYtv',
      origin: (env) => (env === 'preproduction') ? '00N4G000006eYtu' : '00N4G000006eYtu',
      type: (env) => (env === 'preproduction') ? '00N4G000006eYu0' : '00N4G000006eYu0',
    }

    const getValuePerEnv = (fieldName, env) => {
      const values = VALUE_ENV_MAP[env]
      if (!values || !values[fieldName]) {
        console.error(`Couldn't find ${fieldName} value for SalesForce`)
        return null
      }
      return values[fieldName]
    }

    const isFunction = (functionToCheck) => (typeof functionToCheck === 'function')

    const renameFields = (data, env) => {
      for (const field in { ...data }) {
        const value = data[field]
        const fieldName = FIELDS_MAP[field]
        if (isFunction(fieldName)) {
          // Use name of field depends on env
          data[fieldName(env)] = value
          delete data[field]
        } else if (fieldName) {
          data[fieldName] = value
          delete data[field]
        }
      }
      return data
    }

    const {
      salutation,
      firstName,
      lastName,
      email,
      phoneNumber,
      country,
      stateCode,
      marketingCheckbox,
      aircraft = '',
      privacyCheckbox,
      hours,
      typeOfFlying,
      currentFlyingSolution
    } = this.state;

    const url = window.location;
    const utmData = new URLSearchParams(window.location.search);
    const countryCode = country === "" ? this.geoLookupCountryCode : country;
    const selectedAircraft = OTHER_AIRCRAFTS[aircraft.value] || null;

    let env = (window.globalEnv && window.globalEnv.toLowerCase()) || 'preproduction'
    if (!VALUE_ENV_MAP[env]) env = 'preproduction'

    let data = {
      oid: getValuePerEnv('oid', env),
      salutation: salutation.label,
      firstName: firstName,
      lastName: lastName,
      email: email,
      company: "",
      phoneNumber: phoneNumber,
      message: "",
      typeOfFlying: typeOfFlying,
      currentFlyingSolution: currentFlyingSolution,
      marketingOptIn: marketingCheckbox,
      country: countryCode,
      state: countryCode === "US" ? stateCode : "",
      conversionURL: `${url.origin}${url.pathname}`,
      googleSource: utmData.get("utm_source") || "",
      googleMedium: utmData.get("utm_medium") || "",
      googleCampaign: utmData.get("utm_campaign") || "",
      googleTerm: utmData.get("utm_term") || "",
      customJSON: {
        action: "cost calculator",
        privacyCheck: privacyCheckbox,
        "flight hours": Number(hours),
        aircraft: aircraft.value,
        "vj aircraft": selectedAircraft.vjAirCraft.title,
        //"buying stage": possession.value,
      },
      callback: '',
      origin: 'Web',
      type: "General Enquiry",

      retURL: 'https://www.vistajet.com/thankyou.txt',
    }

    return {
      url: getValuePerEnv('url', env),
      data: renameFields(data, env)
    };
  };

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };


  isValidData = () => {
    const {
      aircraft,
      hours,
      salutation: {
        value,
      } = {},
      firstName,
      lastName,
      email,
      country,
      stateCode,
      phoneNumber,
      privacyCheckbox,
    } = this.state;
    return (aircraft.value && hours && `${value}` && firstName && lastName && email && phoneNumber && (country !== 'US' || stateCode) && privacyCheckbox);
  };

  generateSalutationOptions = () => {
    return Salutation.map((salutation) => {
      return {
        value: salutation.value,
        label: salutation.label,
      };
    });
  };

  generateAirCraftsOptions = () => {
    return aircrafts.map((aircraft) => {
      return {
        value: aircraft,
        label: aircraft,
      };
    });
  };

  render() {
    const salutationOptions = this.generateSalutationOptions();
    const airCraftsOption = this.generateAirCraftsOptions();
    const {
      hours,
      firstName,
      lastName,
      email,
      defaultCountry,
      phoneNumber,
      country,
      stateCode,
      aircraft,
      salutation,
      typeOfFlying,
      currentFlyingSolution,
      marketingCheckbox,
      privacyCheckbox,
      errors = {},
    } = this.state;
    const { isLoading, } = this.props;
    const hasStateField = country === 'US'

    return (
      <div data-uk-grid>
        <div className="uk-width-1-3@m uk-width-1-3@s uk-width-1-1" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 25;">
          <div className="vistajet_heading_column">
            <div className="vistajet_heading_third">Do you own an aircraft?</div>
            <h1 className="vistajet_heading_secondary">Discover the <br />VistaJet value</h1>
          </div>
          <p>Use the tool to analyze the value of membership compared to your existing private aviation solution.
            <br /><br />
            Our global infrastructure makes membership an efficient and cost-effective option, involving no depreciation and no asset risk.
            It offers guaranteed availability with as little as 24 hours’ notice, globally, on a consistent branded fleet, all at a fixed hourly rate.
          </p>
        </div>
        <div className="uk-width-2-3@m uk-width-2-3@s uk-width-1-1" uk-scrollspy="cls: uk-animation-slide-top-medium; delay: 25;">
          <div className="uk-card uk-card-default uk-card-body vistajet_card">
            <div className="vistajet_primary_form vistajet_registration_form vistajet_primary_program">
              {
                isLoading &&
                (
                  <div className="vistajet_spinner"><SpinnerLoader /></div>
                )
              }
              <form className="uk-grid-medium uk-grid" data-uk-grid>
                <div className="vistajet_aircraft_cost_list_dropdown uk-width-1-1">
                  <div className="uk-grid" data-uk-grid>
                    {/* <div className="uk-width-1-2@s">
                      <div className="vistajet_select_container">
                        <div className="vistajet_input_wrapper">
                          <Select
                            options={dropDownOptions}
                            placeholder={"Ownership Type"}
                            value={possession}
                            isSearchable={false}
                            onChange={this.handlePossessionChange}
                            className="vistajet_select_input"
                          />
                          <i uk-icon="icon: chevron-down; ratio: 1"></i>
                        </div>
                      </div>
                    </div> */}

                    <div className="uk-width-1-1@s uk-grid" uk-grid>
                      <div class="uk-width-auto">
                        <p class="vistajet_select_aircraft">Aircraft</p>
                      </div>
                      <div class="uk-width-expand">
                        <div className="vistajet_select_container">
                          <div className="vistajet_input_wrapper">
                            <Select
                              classNamePrefix="react-select"
                              placeholder={"Select"}
                              options={airCraftsOption}
                              value={aircraft}
                              isSearchable={false}
                              onChange={this.handleAircraftChange}
                              className="vistajet_select_input"
                            />
                            <i uk-icon="icon: chevron-down; ratio: 1"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-1">
                    <p className="vistajet_per_year_text">I fly approximately
                      <input
                        type="text"
                        onChange={this.handleHoursChange}
                        value={hours} className="vistajet_per_year_input" />
                      hours per year.
                    </p>
                  </div>
                </div>
                <div className="uk-width-1-1 uk-width-1-2@m">
                  <div className="uk-grid-collapse" data-uk-grid>
                    <div className="uk-width-1-4 uk-width-1-4@s uk-first-column">
                      <div
                        className="vistajet_input_wrapper"
                        style={{
                          borderRight: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0
                        }}
                      >
                        <Select
                          classNamePrefix="react-select"
                          isSearchable={false}
                          onChange={this.handleSalutationChange}
                          options={salutationOptions}
                          value={salutation}
                          id="salutation-select"
                          className="vistajet_select_input"
                          name="salutation"
                        />
                        <label htmlFor="-">Title</label>
                        <i data-uk-icon="icon: chevron-down; ratio: 1" class="uk-icon" />
                      </div>
                    </div>
                    <div className="uk-width-expand uk-width-3-4@s">
                      <div
                        className="vistajet_input_wrapper"
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 0,
                        }}
                      >
                        <input
                          id="firstName"
                          type="text"
                          name="firstName"
                          onChange={({ target }) => this.handleFirstNameChange(target.value)}
                          onFocus={this.removeErrors}
                          value={firstName}
                          autoComplete="given-name"
                          required
                        />
                        <label htmlFor="firstName">Name</label>
                        {
                          errors.errors && errors.errors.firstName && (
                            <p>{errors.errors.firstName}</p>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="uk-width-1-1 uk-width-1-2@m">
                  <div className="vistajet_input_wrapper">
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      onChange={({ target }) => this.handleLastNameChange(target.value)}
                      onFocus={this.removeErrors}
                      value={lastName}
                      autoComplete="family-name"
                      required
                    />
                    <label htmlFor="lastName">Surname</label>
                    {
                      errors.errors && errors.errors.lastName && (
                        <p>{errors.errors.lastName}</p>
                      )
                    }
                  </div>
                </div>

                <div className="uk-width-1-1 uk-width-1-2@m">
                  <div className="vistajet_input_wrapper">
                    <input
                      id="email"
                      type="text"
                      name="email"
                      onChange={({ target }) => this.handleEmailChange(target.value)}
                      onFocus={this.removeErrors}
                      value={email}
                      autoComplete="email"
                      required
                    />
                    <label htmlFor="email">Email</label>
                    {
                      errors.errors && errors.errors.email && (
                        <p>{errors.errors.email}</p>
                      )
                    }
                  </div>
                </div>

                <div className="uk-width-1-1 uk-width-1-2@m">
                  <div className={cn("vistajet_phone-row", hasStateField && '--has-state-field')}>
                    <div className="vistajet_input_wrapper vistajet_input_phone_number with_fixed_label">
                      <IntlTelphoneInput
                        id="phoneNumber"
                        value={phoneNumber}
                        errorText={errors.phoneNumber}
                        onChange={this.handlePhoneNumberChange}
                        onFocus={this.removePhoneError}
                        onBlur={this.handlePhoneNumberBlur}
                        autoPlaceholder={false}
                        isContactUsForm={true}
                        defaultCountry={defaultCountry}
                        geoLookupCountryCode={this.geoLookupCountryCode}
                      />
                      <label htmlFor="phoneNumber">Phone Number</label>
                      {errors.errors && errors.errors.phoneNumber && (
                        <p>{errors.errors.phoneNumber}</p>
                      )}
                    </div>
                    {hasStateField && (
                      <div className="vistajet_input_wrapper with_fixed_label">
                        <select className="uk-select vistajet_input_state-select" id="stateCode" defaultValue={stateCode || ""} onChange={this.handleStateChange}>
                          <option value="" disabled hidden>Select a state</option>
                          {Object.entries(stateList).map(([name, code]) => {
                            return (
                              <option value={code} key={code}>{name}</option>
                            )
                          })}
                        </select>
                        <label htmlFor="stateCode">State</label>
                        {errors.errors && errors.errors.stateCode && (
                          <p>{errors.errors.stateCode}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-1 uk-width-1-2@m">
                  <div className="vistajet_input_wrapper">
                    <select className="uk-select vistajet_select_input"
                      name="typeOfFlying" value={typeOfFlying}
                      onChange={this.handleTypeOfFlyingChange}>
                      <option value="">-- select --</option>
                      <option value="Business">Business</option>
                      <option value="Personal">Personal</option>
                      <option value="Both">Both</option>
                    </select>
                    <label htmlFor="typeOfFlying">What type of flying are you enquiring for?</label>
                    {
                      errors.errors && errors.errors.typeOfFlying && (
                        <p>{errors.errors.typeOfFlying}</p>
                      )
                    }
                  </div>
                </div>

                <div className="uk-width-1-1 uk-width-1-2@m">
                  <div className="vistajet_input_wrapper">
                    <select className="uk-select vistajet_select_input"
                      name="currentFlyingSolution" value={currentFlyingSolution}
                      onChange={this.handleCurrentFlyingSolutionChange}>
                      <option value="">-- select --</option>
                      <option value="Charter">Charter</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Fractional Ownership">Fractional Ownership</option>
                      <option value="Aircraft Ownership">Aircraft Ownership</option>
                      <option value="Jet Card">Jet Card</option>
                    </select>
                    <label htmlFor="currentFlyingSolution">What best describes how you currently fly?</label>
                    {
                      errors.errors && errors.errors.currentFlyingSolution && (
                        <p>{errors.errors.currentFlyingSolution}</p>
                      )
                    }
                  </div>
                </div>

                <div className="uk-width-1-1@m uk-width-1-1@s vistajet_checkbox vistajet_checkbox_top_auto">
                  <label htmlFor="marketingCheckbox">
                    <input
                      className="uk-checkbox"
                      type="checkbox"
                      name="checkbox"
                      id="marketingCheckbox"
                      checked={marketingCheckbox}
                      onClick={({ target }) => this.handleMarketingCheck(target.checked)}
                    />I would like to receive Marketing communication
                  </label>
                </div>
                <div className="uk-width-1-1@m uk-width-1-1@s vistajet_checkbox vistajet_checkbox_top_auto">
                  <label htmlFor="privacyCheckbox">
                    <input
                      className="uk-checkbox"
                      type="checkbox"
                      name="checkbox"
                      id="privacyCheckbox"
                      checked={privacyCheckbox}
                      onClick={({ target }) => this.handlePrivacyCheck(target.checked)}
                    />By clicking you are agreeing to the VistaJet <b><a href={TERMS_URL_PAGE} target="_blank" rel="noopener noreferrer">Terms and Conditions</a></b> {' '} and
                    <b> <a href={POLICY_URL_PAGE.replace('{headingId}', '')} target="_blank" rel="noopener noreferrer">Privacy Notice</a></b>
                  </label>
                </div>
                <div
                  className="uk-width-1-1@s uk-text-center uk-text-right@s uk-margin-remove uk-grid-margin uk-first-column"
                >
                  <input
                    disabled={!this.isValidData()}
                    type="button"
                    className="uk-button uk-button-full@s"
                    value="Compare now"
                    onClick={this.handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
