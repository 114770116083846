import React, { Component } from 'react';
import AddCreditCardModal from '../Credit-Card/AddCreditCardModal';
import CreditCardFlag from '../shared/CreditCardFlag';
import MaskedTextField from '../shared/MaskedTextField';
import { isAdhoc } from '../../utils/userUtils';

export default class PaymentMethods extends Component {

  getCreditCardImage = (cardBrandId) => {
    return (
      <CreditCardFlag
        brandId={cardBrandId}
      />
    );
  };

  renderDots = () => {
    let arr = [];
    for (let i = 0; i <= 11; i++) {
      arr.push(<span className="vistajet_credit_card_dots" />);
    }
    return arr;
  };

  renderAddedCreditCards = () => {
    const { selectedCvv, creditCards, errors, selectedCard } = this.props;

    return creditCards.ids.map((card, index) => {
      let selected = false;
      if (selectedCard === card) {
        selected = true;
      }
      const number = creditCards.content[card].cardNumberLastFour;
      const cardType = creditCards.content[card].cardBrandId;
      return (
        <div class="vistajet_select_credit_card uk-flex uk-flex-middle uk-width-1-1 uk-margin-small-bottom">
          <div class="uk-inline">
            <label class="vistajet_radio">
              <input
                name="radio"
                type="radio"
                checked={selected}
                onClick={() => this.props.handleSelectCard(card)}
              />
              <span />
            </label>
          </div>
          <div className="uk-inline uk-flex uk-flex-middle">
            <div class="credit-card-flag">
              {this.getCreditCardImage(cardType)}
            </div>
            <p className="vistajet_card_number">
              {this.renderDots()}
              {number}
            </p>
          </div>
          <div class="uk-inline uk-width-1-5">
            {
              selected ? (
                <div className="vistajet_input_wrapper">
                  <MaskedTextField
                    id="selectedCvv"
                    value={selectedCvv}
                    onChange={this.props.handleCVVChange}
                    onFocus={this.props.removeErrors}
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    guide={false}
                  />
                  <label htmlFor="selectedCvv">
                    CVV
                  </label>
                  {
                    errors.errors && errors.errors.cvv && (
                      <p>
                        {errors.errors.cvv}
                      </p>
                    )
                  }
                </div>
              ) : null
            }
          </div>
        </div>
      );
    });
  }

  render() {
    const { creditCards, selectedCard, user } = this.props;
    return (
      <div>
        <form class="uk-flex uk-grid-small" data-uk-grid>
          {!isAdhoc(user) ? <div class="vistajet_select_credit_card uk-flex uk-flex-middle uk-width-1-1 uk-margin-small-bottom">
            <div class="uk-inline">
              <label class="vistajet_radio">
                <input
                  type="radio"
                  checked={selectedCard === 'membership'}
                  onClick={() => this.props.handleSelectCard('membership')}
                />
                <span></span>
              </label>
            </div>
            <div class="uk-inline uk-flex uk-flex-middle">
              <div class="credit-card-flag" style={{ 'backgroundImage': 'url(/legacy/static/img/vistajet_logo.svg)', 'background-position': '5px 2px;' }}></div>
              <p class="vistajet_card_number">Using VistaJet Membership</p>
            </div>
          </div> : null}

          {creditCards && creditCards.ids && creditCards.ids.length ? this.renderAddedCreditCards() : null}
          <div>
            {creditCards && creditCards.ids && !creditCards.ids.length && isAdhoc(user)
              ? <span className="vistajet_billing_address_text">No payment methods found.</span>
              : null}
            <p className="vistajet_add_credit_card_wrapper">
              <a
                class="vistajet_add_credit_card"
                onClick={() => this.addCreditCard.show()}
                uk-toggle
              >
                Add Credit Card
              </a>
            </p>
          </div>
        </form>
        <div>
          <AddCreditCardModal
            id="AddCreditCard"
            ref={(addCreditCard) => { this.addCreditCard = addCreditCard; }}
            creditCards={this.props.creditCards}
            isCreditCardLoading={this.props.isCreditCardLoading}
            creditCardError={this.props.creditCardError}
            isCreditCardAdded={this.props.isCreditCardAdded}
            addCreditCardAction={this.props.addCreditCardAction}
          />
        </div>
      </div >
    )
  }

}