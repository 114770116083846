import React from 'react';
import PropTypes from 'prop-types';

const getStyle = () => {
  return {
    position: 'initial',
    bottom: 2,
    fontSize: 18,
    lineHeight: 'normal',
    color: 'red',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    paddingLeft: 20,
    paddingTop: 3,
    fontFamily: 'garamond, serif',
  };
};

const ErrorText = (props, context) => {
  const { children, style } = props;
  const styles = Object.assign(getStyle(props, context), style);
  if (children) {
    return (
      <div style={styles}>
        {children }
      </div>
    );
  }
  return null
};

ErrorText.propTypes = {
  /**
   * The contents of the Error Text.
   */
  children: PropTypes.node,

  /**
   * Inline styles of the element.
   */
  style: PropTypes.object, // eslint-disable-line
};

ErrorText.defaultProps = {
  children: null,
}

ErrorText.contextTypes = {
  muiTheme: PropTypes.object, // eslint-disable-line
};

export default ErrorText;
