import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getTopUpEmailBody from 'utils/emailUtils';
import MessagePopUp from '../shared/MessagePopUpNew';
import ConfirmationPopup from '../shared/ConfirmationPopUpNew';
import { TOPUP_HISTORY_PAGE_URL } from '../../configs/constants';
import MaskedTextField from '../shared/MaskedTextField';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { saveToLocal } from "../../utils/cache";
import { TOP_UP_STATUS } from "../../constants/localStorageKeys";
import SpinnerLoader from '../shared/spinnerLoader';
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants';
import { CONFIRMATION_MESSAGE, RIGHT_BUTTON_TEXT, LEFT_BUTTON_TEXT } from '../../constants/DirtyPopupConstant';

const mask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: true,
  integerLimit: 8,
});

class TopUpAccount extends Component {

  static propTypes = {
    TopUpReference: PropTypes.func,
    TopUpAction: PropTypes.func,
    CancelTopUp: PropTypes.func,
    token: PropTypes.string,
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    openPopUpMessage: PropTypes.number,
    bankName: PropTypes.string,
    currency: PropTypes.string,
    iban: PropTypes.string,
    referenceCode: PropTypes.string,
    swift: PropTypes.string,
    to: PropTypes.string,
    topUpAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topUpId:PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topUpReferenceLoader: PropTypes.bool,
    disableAmountField: PropTypes.bool,
    user: PropTypes.object,
    modalId: PropTypes.string,
    topUpSubmittedLoader: PropTypes.bool,
    topUpCancelLoader: PropTypes.bool,
    confirmId: PropTypes.string,
    redirect: PropTypes.bool,
    fromDirectScreen: PropTypes.bool,
    topUpError: PropTypes.string,
    ClearTopUpError: PropTypes.func,
  };
  static defaultProps = {
    disableAmountField: false,
    topUpAmount: '',
    confirmId: 'ConfirmBox',
  }
  constructor(props) {
    super(props);
    this.state = {
      topUpSentAmount: '',
      toTooltip: false,
      ibanTooltip: false,
    }
  }
  componentWillReceiveProps(nextProps) {
    const { topUpSentAmount } = this.state
    if (nextProps.openPopUpMessage && !nextProps.fromDirectScreen) {
      this.redirect(nextProps.openPopUpMessage);
    }
    if (nextProps.topUpId && topUpSentAmount) {
      this.setState({
        topUpSentAmount: '',
      })
    }
    if (nextProps.topUpError) {
      this.validationPopUpTopUp.show();
    }
  }
  callAction = () => {  
    const { token, accountId, TopUpReference } = this.props;
    const { topUpSentAmount } = this.state;
    TopUpReference(token, accountId, +topUpSentAmount, 'USD')
  }
  callSubmitted = () => {
    dirtystateSingleTon.setDirty(SPAConstants.TopUpAccount, false);
    const { token, topUpId, TopUpAction } = this.props;
    TopUpAction(token, topUpId);
  }
  amountChange = (event) => {
    const newValue = event.target.value.replace(/,/g, '');
    if(newValue !== '0') {
      dirtystateSingleTon.setDirty(SPAConstants.TopUpAccount, true);
      this.setState({
        topUpSentAmount: newValue,
      }) 
    }
  }
  emailDetails = () => {
    const {
      bankName,
      currency,
      iban,
      referenceCode,
      swift,
      to,
      topUpAmount,
    } = this.props;
    const info = {
      bankName,
      currency,
      iban,
      referenceCode,
      swift,
      to,
      topUpAmount,
    }
    const {
      firstName='',
      lastName='',
    } = this.props.user;
    const subject = encodeURI('VistaJet transfer');
    const body = encodeURI(getTopUpEmailBody(info, `${firstName} ${lastName}`));
    const newWindow = window.open(`mailto:?subject=${subject}&body=${body}`);
    setTimeout(() => {
      newWindow.close();
    }, 3000);
  }
  copyToClipboard = (data) => {
    const { to, iban } = this.props;
    let text = '';
    if (data) {
      text = to;
      this.setState({
        toTooltip: true,
      });
      setTimeout(() => {
        this.setState({
          toTooltip: false,
        });
      }, 1000);
    } else {
      text = iban;
      this.setState({
        ibanTooltip: true,
      });
      setTimeout(() => {
        this.setState({
          ibanTooltip: false,
        });
      }, 1000);
    }
    navigator.clipboard.writeText(text);
  }
  redirect = (openPopUpMessage) => {
    this.changeWindowLocation(openPopUpMessage);
  }
  callCancelTopUp = () => {
    const { CancelTopUp, token, topUpId} = this.props;
    dirtystateSingleTon.setDirty(SPAConstants.TopUpAccount, false);
    this.hideConfirmationBox();
    CancelTopUp (token, topUpId);
  }
  showConfirmationBox = () => {
    this.confirmationPopUp.show();
  }
  hideConfirmationBox = () => {
    this.confirmationPopUp.hide();
  }
  changeWindowLocation = (openPopUpMessage) => {
    if (openPopUpMessage === 1) {
      saveToLocal('Top Up successful!',TOP_UP_STATUS);
    } else if (openPopUpMessage === 2) {
      saveToLocal('Top Up cancelled!',TOP_UP_STATUS);
    }
    window.location = TOPUP_HISTORY_PAGE_URL.url;
  }
  disableTopUpButton = () => {
    const { topUpSentAmount } = this.state;
    if (topUpSentAmount) {
      return false;
    }
    return true;
  }
  renderButtonImage = () => {
    const { topUpReferenceLoader, topUpId } = this.props;
    let response = (
      <button className="uk-button vistajet_input_icon vistajet_check_icon"
        onClick={this.callAction} disabled={this.disableTopUpButton()}></button>
    );
    if (topUpReferenceLoader) {
      response = (
        <div uk-spinner="ratio: 0.8" className="vistajet_spinner"></div>
      );
    }
    else if (topUpId) {
      response = (
        <button className="uk-button vistajet_input_icon vistajet_close_white_icon"
          onClick={this.showConfirmationBox}></button>
      );
    }
    return response;
  }
  hideModal = () => {
    const isTopUpAccountDirty = dirtystateSingleTon.getDirty(SPAConstants.TopUpAccount);
    if(isTopUpAccountDirty) {
      this.topUpDirtyPopup.show();
    } else {
      this.hideTopUpModal();
    } 
  }
  hideTopUpModal = () => {
    dirtystateSingleTon.setDirty(SPAConstants.TopUpAccount, false);
    this.topUpDirtyPopup.hide();
    const { redirect, topUpAccountModal, TopUpModal } = this.props;
    if (TopUpModal) {
      TopUpModal.hide();
    } else {
      topUpAccountModal.hide();
    }
    if (redirect) {
      this.changeWindowLocation(0);
    }
  }
  clearErrorMessage = () => {
    this.props.ClearTopUpError();
  }
  makeModalTopClass = () => {
    const { modalId } = this.props;
    if (modalId) {
      return 'vistajet_modal vistajet_modal_medium'
    }
    return '';
  }
  makeModalSecondClass = () => {
    const { modalId } = this.props;
    if (modalId) {
      return '';
    }
    return 'uk-margin-medium-top uk-width-1-1 uk-width-4-5@s uk-width-1-2@m uk-margin-auto';
  }
  render() {
    const {
      bankName,
      iban,
      referenceCode,
      swift,
      to,
      topUpId,
      topUpAmount,
      disableAmountField,
      modalId,
      topUpSubmittedLoader,
      topUpCancelLoader,
    } = this.props;
    const { topUpSentAmount, toTooltip, ibanTooltip } = this.state;
        return (
          <div className="uk-container vistajet_screen_wrapper">
            <ConfirmationPopup
              content='Are you sure you want to cancel?'
              id={this.props.confirmId}
              rightButtonContent='Yes'
              onRightButtonClick = {this.callCancelTopUp}
              leftButtonContent='No'
              onLeftButtonClick = {this.hideConfirmationBox}
              ref={(confirmationPopUp)=>{this.confirmationPopUp=confirmationPopUp;}}
              />
          <div id={modalId} className={this.makeModalTopClass()} style={{background:'transparent'}} >
          <div className={this.makeModalSecondClass()}>
            <div className="vistajet_top_up">
            {
              (topUpSubmittedLoader || topUpCancelLoader) && 
              <div className="vistajet_spinner">
                <SpinnerLoader />
              </div>
            }
              <div className="uk-card uk-card-default uk-card-body vistajet_card vistajet_card_with_bottom_border">
                  <div className="vistajet_top_up_header">
                    {modalId ? <a onClick={this.hideModal} className="uk-modal-close">Back</a> : null}
                    <h4>Top Up</h4>
                  </div>
                  <div className="vistajet_upper_card_content">
                      <div className="vistajet_primary_form">
                          <div className="vistajet_input_with_icon uk-margin">
                              <div className="vistajet_input_icon vistajet_dollar_icon"></div>
                              <div className="vistajet_input_wrapper">
                                <MaskedTextField
                                  id='number'
                                  value={topUpSentAmount || topUpAmount}
                                  onChange={this.amountChange}
                                  mask={mask}
                                  disabled={disableAmountField}
                                  />
                                  <label htmlFor="text">Enter amount to transfer</label>
                              </div>
                              {this.renderButtonImage()}
                          </div>
                      </div>
                  </div>
              </div>
              <div className="uk-card uk-card-default uk-card-body vistajet_card">
                  <div className="vistajet_content_text_wrapper">
                      <p className="vistajet_content_text uk-text-center">Please connect to your online banking or call your bank.</p>
                      <p className="vistajet_content_text uk-text-center">Fill the amount field and use the reference number to make the transfer.</p>
                  </div>
                  
                  <h5 className="vistajet_form_heading">Online bank transfer</h5>

                  <div className="vistajet_primary_form">
                      <div className="vistajet_input_with_icon">
                          <div className="vistajet_input_icon vistajet_lock_icon"></div>
                          <div className="vistajet_input_wrapper">
                              <input type="text" value={referenceCode} disabled/>
                          </div>
                      </div>
                  </div>
                  {topUpId ? <div>
                    <div className="vistajet_copy_text_wrapper">
                        <h6>To</h6>
                        <p className="uk-inline">{to}</p>
                        <button className="uk-inline uk-button vistajet_copy_icon"
                          onClick={() => this.copyToClipboard(true)}
                        ></button>
                        { toTooltip &&
                          <div className="uk-inline copied-placeholder">Copied</div>
                        }
                    </div> 

                    <div className="vistajet_copy_text_wrapper">
                        <h6>IBAN</h6>
                        <p className="uk-inline iban-number">{iban}</p>
                        <button className="uk-inline uk-button vistajet_copy_icon"
                          onClick={() => this.copyToClipboard()}
                        ></button>
                        { ibanTooltip &&
                          <div className="uk-inline copied-placeholder">Copied</div>
                        }
                    </div>

                    <div className="vistajet_copy_text_wrapper">
                        <h6>Swift</h6>
                        <p className="uk-inline">{swift}</p>
                    </div>

                    <div className="vistajet_copy_text_wrapper">
                        <h6>Bank Name</h6>
                        <p className="uk-inline">{bankName}</p>
                    </div>
                  </div>: null}
                  <div className="vistajet_content_text_wrapper uk-margin-remove">
                      <p className="vistajet_content_text uk-text-center">
                        SEPA transfers within Europe cost the same as local transfers.
                      </p>
                  </div>
                  <div className="uk-margin-medium-bottom vistajet_bottom_button_wrapper">
                      <button className="uk-button vistajet_button_default"
                        onClick={this.emailDetails} disabled={!topUpId} >Send via email</button>
                      <button className="uk-button vistajet_button_default vistajet_button_primary"
                        onClick={this.callSubmitted} disabled={!topUpId}>
                      I made this transfer
                      </button>
                  </div>
              </div>
          </div>
        </div>
        </div>
        <MessagePopUp
          id = {'topUpValidationPopUp'}
          content={this.props.topUpError}
          ref={(validationPopUpTopUp)=>{this.validationPopUpTopUp=validationPopUpTopUp;}}
          onClose={this.clearErrorMessage}
        />
        <ConfirmationPopup
              ref={(topUpDirtyPopup)=>{this.topUpDirtyPopup=topUpDirtyPopup;}}
              content={CONFIRMATION_MESSAGE}
              rightButtonContent={RIGHT_BUTTON_TEXT}
              onRightButtonClick = {() => this.hideTopUpModal()}
              leftButtonContent={LEFT_BUTTON_TEXT}
              onLeftButtonClick = {() => this.topUpDirtyPopup.hide()}
              />
      </div>
      );
  }
}

export default TopUpAccount;
