import moment from 'moment';

const DATE_FORMAT = 'MM/DD/YYYY';
const DATE_WITHOUT_UTC = 'YYYY-MM-DDT[00:00:00Z]';

/**
 * Finds the active timezone
 */
export function getCurrentTimezone(timezones = [], time = moment()) {
  const timezone = timezones.find(({ effectiveFromTimeUtc, effectiveToTimeUtc }) =>
    time.isBetween(moment(effectiveFromTimeUtc), moment(effectiveToTimeUtc)) ||
    time.isSame(moment(effectiveFromTimeUtc))
  );
  if (!timezone) {
    return timezones[0];
  }

  return timezone;
}

export function getUtcOffsetMinutes(timezones = [], time = moment()) {
  const timezone = getCurrentTimezone(timezones, time);
  if (!timezone) {
    return 0;
  }

  if (timezone.dstFromTimeUtc
    && timezone.dstToTimeUtc
    && (time.isBetween(moment(timezone.dstFromTimeUtc), moment(timezone.dstToTimeUtc))
    || time.isSame(moment(timezone.dstFromTimeUtc)))) {
    return timezone.dstUtcOffset;
  }

  return timezone.standardUtcOffsetMinutes;
}

export function removeOffsetMinutes(offsetMinutes = 0, time = moment()) {
  return offsetMinutes > 0 ? time.utcOffset(0).subtract(offsetMinutes, 'minutes')
    : time.utcOffset(0).add(offsetMinutes, 'minutes');
}

export function getDateWithoutTimeZone(date) {
  let dateFormated = date;
  if (!(dateFormated instanceof moment)) {
    dateFormated = moment(date, DATE_FORMAT);
    if (!moment(dateFormated).isValid()) {
      return date;
    }
  }
  return dateFormated.format(DATE_WITHOUT_UTC);
}

export function getUTCDate(airport, localTime = moment()) {
  const timezone = getCurrentTimezone(airport.timezones);
  if (localTime instanceof moment) {
    return localTime.utc().add(timezone.dstUtcOffset, 'minutes');
  }
  return moment(localTime).utc().add(timezone.dstUtcOffset, 'minutes');
}
