import * as types from '../actionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.POST_CONTACTUS_INIT: {
      return Object.assign({}, state, {
        isSuccess: false,
        isLoading: true
      });
    }
    case types.POST_CONTACTUS_SUCCESS: {
      return Object.assign({}, state, {
        isSuccess: action.data,
        isLoading: false
      });
    }
    case types.POST_CONTACTUS_FAIL: {
      return Object.assign({}, state, {
        isSuccess: false,
        isLoading: false
      });
    }
    case types.POST_CONTACTUS_REFRESH: {
      return Object.assign({}, state, {
        isSuccess: false,
        isLoading: false
      });
    }

    default:
      return state;
  }
}
