import { 
  topUpHistorySource,
 } from '../sources/TopUpAccountSource';
import * as types from '../actionTypes';


export const TopUpHistoryInit = () => ({
  type: types.TOPUP_HISTORY_INIT,
})

export const TopUpHistorySuccess = (data) => ({
  type: types.TOPUP_HISTORY_SUCCESS,
  data,
})

export const TopUpHistoryFail = (data) => ({
  type: types.TOPUP_HISTORY_FAIL,
  data,
})

export const GetTopUpHistory = (token, accountId) =>
  topUpHistorySource(token, accountId);
