import React, { Component } from 'react';
import Select from 'react-select';
import MessagePopUp from '../../shared/MessagePopUp';
import ConfirmationPopUp from '../../shared/ConfirmationPopUpNew';
import CreditCardInputNumber from '../../shared/CreditCardInputNumber';
import MaskedTextField from '../../shared/MaskedTextField';
import { NEW_CARD_TYPES } from '../../../constants/CreditCardConstants';
import { replaceSpecialCharacterAndSpaces } from '../../../utils/stringUtils';
import SpinnerLoader from '../../shared/spinnerLoader';

import validateAddCreditCardDetailsForm from '../../../utils/Validators/AddCreditCardDetailsValidator';
import { LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT, CONFIRMATION_TITLE, CONFIRMATION_MESSAGE } from '../../../constants/DirtyPopupConstant';
import dirtystateSingleTon from '../../../validators/validateDirty';
import SPAConstants from '../../../constants/SPAConstants';

export default class CreditCardAddEditDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCardNumber: '',
      cvv: '',
      expiryDate: '',
      typeOfCardSelected: NEW_CARD_TYPES[1],
      defaultChecked: false,
      errors: {},
      popupMessage: '',
      isFieldsChanged: false,
      existsLastDigits: '',
    };
    const forState = {
      creditCardNumber: '',
      cvv: '',
      expiryDate: '',
      typeOfCardSelected: NEW_CARD_TYPES[1].value,
      defaultChecked: false,
    };
    this.oldState = Object.assign({}, forState);
  }

  componentDidMount() {
    const { isEdit } = this.props;
    if (isEdit) {
      this.populateValues();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      // this.props.creditCards !== nextProps.creditCards &&
      !nextProps.isAddCreditCardLoading
      && nextProps.isAddedCreditCard
    ) {
      const { toggleIsCreditCardAddEditDetails } = this.props;
      toggleIsCreditCardAddEditDetails();
    }
    if (
      this.props.addCreditCardLoadingError !== nextProps.addCreditCardLoadingError
      && nextProps.isAddCreditCardError
    ) {
      window.UIkit.modal('#creditCardErrorPopUp').show();
    }
  }

  handleError = (err) => {
    this.setState({
      popupMessage: err,
    });
  }

  populateValues = () => {
    const { editFieldId, creditCards } = this.props;
    const forState = {};
    forState.creditCardNumber = creditCards.content[editFieldId].alias;
    forState.expiryDate = creditCards.content[editFieldId].expiryDate;
    forState.typeOfCardSelected = {
      label: creditCards.content[editFieldId].type,
      value: creditCards.content[editFieldId].type,
    };
    forState.defaultChecked = creditCards.content[editFieldId].isDefault;
    this.oldState = Object.assign({}, forState, {
      cvv: '',
    });
    this.setState(forState);
  }

  handleCreditCardNumberChange = (e) => {
    const number = replaceSpecialCharacterAndSpaces(e.target.value);
    this.setState({ creditCardNumber: number }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleCVVChange = (e) => {
    this.setState({ cvv: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleExpiryDateChange = (e) => {
    this.setState({ expiryDate: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleCardTypeChange = (obj) => {
    this.setState({ typeOfCardSelected: obj }, () => {
      this.compareOldAndNewValues();
    });
  }

  handleDefaultCheck = () => {
    const { defaultChecked } = this.state;
    this.setState({
      defaultChecked: !defaultChecked,
    }, () => {
      this.compareOldAndNewValues();
    });
  }

  checkIfCardWithLastDigitExists = (event) => {
    event.preventDefault();
    const {
      creditCardNumber,
    } = this.state;
    const {
      creditCards,
    } = this.props;

    const lastFourDigits = Number(creditCardNumber.substr(creditCardNumber.length - 4));
    let exists = false;
    if (creditCards.hasOwnProperty('ids') && creditCards.ids.length > 0) {
      // check if card with last four digits exist
      exists = creditCards.ids.some((cardId) => creditCards.content[cardId].cardNumberLastFour == lastFourDigits);
      if (exists) {
        this.setState({
          existsLastDigits: lastFourDigits,
        }, () => {
          this.creditCardExistsPopUp.show();
        });
      } else {
        this.validateForm(event);
      }
    } else {
      this.validateForm(event);
    }
  }

  validateForm = (event) => {
    event.preventDefault();
    const {
      creditCardNumber,
      cvv,
      expiryDate,
    } = this.state;
    const {
      creditCards,
      editFieldId,
    } = this.props;
    const { isAdd } = this.props;
    let res = null;

    if (isAdd) {
      res = validateAddCreditCardDetailsForm({
        creditCardNumber,
        cvv,
        expiryDate,
      });
    } else {
      res = validateAddCreditCardDetailsForm({
        cvv,
        expiryDate,
        brandId: creditCards.content[editFieldId].cardBrandId,
      });
    }

    if (res.isError) {
      // set state of errors
      this.setState({
        errors: res,
      });
    } else {
      // API call to add credit card
      if (isAdd) {
        this.addCreditCard();
      } else {
        this.updateCreditCard();
      }
    }
    this.closePopup(this.creditCardExistsPopUp);
  }

  updateCreditCard = () => {
    const {
      creditCardNumber,
      cvv,
      expiryDate,
      defaultChecked,
      typeOfCardSelected,
    } = this.state;

    const {
      editFieldId,
      creditCards,
      addCreditCardAction,
      isEdit,
    } = this.props;

    const {
      user,
    } = this.props.user;

    const requestPayload = {
      creditCardNumber: creditCards.content[editFieldId].cardNumberLastFour,
      alias: creditCardNumber,
      cvv,
      expiryDate,
      defaultChecked,
      type: typeOfCardSelected.label,
      isEdit,
    };
    addCreditCardAction(user.userToken, requestPayload).then(() => { dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false) }, this.handleApiErrors);
  }

  addCreditCard = () => {
    const {
      creditCardNumber,
      cvv,
      expiryDate,
      defaultChecked,
      typeOfCardSelected,
    } = this.state;

    const requestPayload = {
      creditCardNumber,
      cvv,
      expiryDate,
      defaultChecked,
      type: typeOfCardSelected.label,
    };

    const {
      addCreditCardAction,
    } = this.props;

    const {
      user,
    } = this.props.user;

    addCreditCardAction(user.userToken, requestPayload).then(() => { dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false) }, this.handleApiErrors);
  }

  handleApiErrors = (err) => {
    this.setState({
      popupMessage: err,
    });
  }

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  handleNext = () => {
    const { progressIncrement, updateProgressInWizard } = this.props;
    const {
      creditCardNumber,
      cvv,
      expiryDate,
      typeOfCardSelected,
      defaultChecked,
    } = this.state;
    const stateObject = {
      creditCardNumber,
      cvv,
      expiryDate,
      typeOfCardSelected,
      defaultChecked,
    };
    if (progressIncrement <= 3) {
      const value = progressIncrement + 1;
      updateProgressInWizard(value, { addCreditCardState: stateObject });
    }
  };

  compareOldAndNewValues = () => {
    const {
      isAdd,
    } = this.props;
    const {
      creditCardNumber,
      cvv,
      expiryDate,
      typeOfCardSelected,
      defaultChecked,
      isFieldsChanged,
    } = this.state;
    const newState = {
      creditCardNumber,
      cvv,
      expiryDate,
      typeOfCardSelected,
      defaultChecked,
    };
    const typeOfCardCheck = newState.typeOfCardSelected.value;
    const oldTypeOfCardCheck = isAdd
      ? this.oldState.typeOfCardSelected
      : this.oldState.typeOfCardSelected.value;

    if (
      (newState.creditCardNumber !== this.oldState.creditCardNumber
        || newState.expiryDate !== this.oldState.expiryDate
        || newState.cvv !== this.oldState.cvv
        || newState.defaultChecked !== this.oldState.defaultChecked
        || typeOfCardCheck !== oldTypeOfCardCheck
      )
      && !isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, true);
      this.setState({
        isFieldsChanged: true,
      });
    } else if (
      (newState.creditCardNumber === this.oldState.creditCardNumber
        && newState.expiryDate === this.oldState.expiryDate
        && newState.cvv === this.oldState.cvv
        && newState.defaultChecked === this.oldState.defaultChecked
        && typeOfCardCheck === oldTypeOfCardCheck
      )
      && isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
      this.setState({
        isFieldsChanged: false,
      });
    }
  }

  handleGoBack = () => {
    const { isFieldsChanged } = this.state;
    const { toggleIsCreditCardAddEditDetails } = this.props;
    if (isFieldsChanged) {
      this.creditCardGoBackPopUp.show();
    } else {
      toggleIsCreditCardAddEditDetails();
    }
  };

  goBackManageCreditCard = () => {
    dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
    this.closePopup(this.creditCardGoBackPopUp);
    const { toggleIsCreditCardAddEditDetails } = this.props;
    toggleIsCreditCardAddEditDetails();
  }

  closePopup = (ref) => {
    ref.hide();
  }

  handleCancelAddCreditCard = () => {
    const { progressIncrement, updateProgressInWizard } = this.props;
    if (progressIncrement <= 3) {
      const value = progressIncrement + 1;
      updateProgressInWizard(value, [], false, false);
    }
  }

  renderForm = () => {
    const {
      creditCardNumber,
      cvv,
      expiryDate,
      typeOfCardSelected,
      defaultChecked,
      popupMessage,
      errors,
      isFieldsChanged,
      existsLastDigits,
    } = this.state;
    const {
      isAddCreditCardLoading,
      isAdd,
      editFieldId,
      creditCards,
      isDefaultEdit,
    } = this.props;
    const typeOfCardOptions = NEW_CARD_TYPES;
    const title = isAdd ? 'Add Card' : 'Edit Card';
    const promptText = `You already have a card ending with ${existsLastDigits}.Are you sure you want to add this card?`;
    const buttonText = isAdd ? 'Add Card' : 'Update Card';
    const buttonClass = isFieldsChanged
      ? 'uk-button change_password_button uk-width-1-1 uk-text-center vistajet_button_primary'
      : 'uk-button vistajet_button_primary vistajet_disabled change_password_button uk-width-1-1 uk-text-center';
    return (
      <div className="uk-width-1-1">
        <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card vistajet_added_passport">
          {
            isAddCreditCardLoading && (
              <div className="vistajet_spinner">
                <SpinnerLoader />
              </div>
            )
          }
          { /* <div
            className="uk-grid-collapse vistajet_card_content uk-margin-remove vistajet_account_details_card uk-flex uk-align-center"
            data-uk-grid
          >
            <div className="uk-width-1-3@s">
             <a href="#">
               <span onClick={this.handleGoBack}>
                <i data-uk-icon="icon: chevron-left; ratio: 1"></i>
                Back
            </span>
               </a> 
            </div>
            <div className="uk-width-1-3@s">
              <h3 className="vistajet_right_tab_heading uk-text-center">
                {title}
              </h3>
            </div>
          </div> */ }


          <div className="vistajet_head_column">
            <div className="uk-clearfix">
              <div className="uk-float-left">
                <a href="#" onClick={this.handleGoBack}>
                  <i data-uk-icon="icon: chevron-left; ratio: 1"></i>
                  Back
              </a>
              </div>
              <div className="uk-text-center">
                <h3>
                  {title}
                </h3>
              </div>
            </div>
          </div>

          <form>
            <div className="vistajet_primary_form uk-width-1-1 vistajet_change_password uk-grid" data-uk-grid>
              <div className="uk-width-1-1@s">
                <div className="vistajet_input_wrapper uk-margin-remove uk-flex uk-flex-middle">
                  <CreditCardInputNumber
                    id="creditCardNumber"
                    // inputclassNameName="full"
                    value={creditCardNumber}
                    onChange={this.handleCreditCardNumberChange}
                    // onBlur={this.handleCreditCardNumberBlur}
                    // errorText={errors.creditCardNumber}
                    disabled={!isAdd}
                    lastFour={!isAdd && creditCards.content[editFieldId].cardNumberLastFour}
                    brandId={!isAdd && creditCards.content[editFieldId].cardBrandId}
                    onFocus={this.removeErrors}
                  // tabIndexing={tabIndex++}
                  />
                  {
                    errors.errors && errors.errors.creditCardNumber && (
                      <p>
                        {errors.errors.creditCardNumber}
                      </p>
                    )
                  }
                </div>
              </div>
              <div className="uk-width-1-2@s vistajet_expiry_margin">
                <div className="vistajet_input_wrapper">
                  <MaskedTextField
                    id="cvv"
                    value={cvv}
                    onChange={this.handleCVVChange}
                    onFocus={this.removeErrors}
                    // onBlur={this.handleCVVBlur}
                    // errorText={errors.cvv}
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    guide={false}
                  // tabIndexing={tabIndex++}
                  />
                  <label htmlFor="cvv">
                    CVV
                  </label>
                  {
                    errors.errors && errors.errors.cvv && (
                      <p>
                        {errors.errors.cvv}
                      </p>
                    )
                  }
                </div>
              </div>

              <div className="uk-width-1-2@s vistajet_expiry_margin vistajet_expiry_date_margin">
                <div className="vistajet_input_wrapper">
                  <MaskedTextField
                    id="expiryDate"
                    value={expiryDate}
                    onChange={this.handleExpiryDateChange}
                    onFocus={this.removeErrors}
                    // onBlur={this.handleExpiryDateBlur}
                    // errorText={errors.expiryDate}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                  // tabIndexing={tabIndex++}
                  />
                  <label htmlFor="expiryDate">
                    Expiry date (MM/YY)
                  </label>
                  {
                    errors.errors && errors.errors.expiryDate && (
                      <p>
                        {errors.errors.expiryDate}
                      </p>
                    )
                  }
                </div>
              </div>

              <div className="uk-width-1-2@s uk-margin-remove vistajet_remove_input_wrapper_transition">
                <div className="vistajet_input_wrapper">
                  <Select
                    classNamePrefix="react-select"
                    // menuIsOpen={true}
                    className="vistajet_select_input"
                    // style={{
                    //   borderTopRightRadius: '3px',
                    //   borderBottomRightRadius: '3px',
                    // }}
                    options={typeOfCardOptions}
                    // placeholder="Type of Card"
                    value={typeOfCardSelected}
                    onChange={this.handleCardTypeChange}
                  />
                  <label htmlFor="-">
                    Type of Card
                  </label>
                  <i uk-icon="icon: chevron-down; ratio: 1" />
                </div>
              </div>
              <div className="uk-width-1-1@s uk-margin-remove vistajet_checkbox_user_profile">
                <label>
                  <input
                    id="defaultChecked"
                    className="uk-checkbox"
                    type="checkbox"
                    checked={defaultChecked}
                    onClick={this.handleDefaultCheck}
                    onFocus={this.removeErrors}
                    disabled={isDefaultEdit}
                  />
                  Set as default
                </label>
              </div>
              <MessagePopUp
                id="creditCardErrorPopUp"
                content={popupMessage}
              />

            </div>
            <div className="uk-width-1 uk-padding-remove uk-grid-margin">
              <div className="uk-width-1 uk-padding-remove">
                <div className="vistajet_profile_footer">
                  <button
                    type="submit"
                    className={buttonClass}
                    disabled={!isFieldsChanged}
                    onClick={isAdd
                      ? this.checkIfCardWithLastDigitExists
                      : this.validateForm
                    }
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <ConfirmationPopUp
          ref={(creditCardGoBackPopUp) => { this.creditCardGoBackPopUp = creditCardGoBackPopUp; }}
          leftButtonContent={LEFT_BUTTON_TEXT}
          onLeftButtonClick={() => this.closePopup(this.creditCardGoBackPopUp)}
          rightButtonContent={RIGHT_BUTTON_TEXT}
          onRightButtonClick={this.goBackManageCreditCard}
          title={CONFIRMATION_TITLE}
          content={CONFIRMATION_MESSAGE}
        />
        <ConfirmationPopUp
          id="cardWithSameNumberExists"
          ref={(creditCardExistsPopUp) => { this.creditCardExistsPopUp = creditCardExistsPopUp; }}
          leftButtonContent="Ok"
          onLeftButtonClick={(event) => this.validateForm(event)}
          rightButtonContent="Cancel"
          onRightButtonClick={() => this.closePopup(this.creditCardExistsPopUp)}
          title="Are you sure?"
          content={promptText}
        />
      </div>
    );
  };

  render() {
    return this.renderForm();
  }
}
