import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import PassengerCard from '../../components/ItineraryDetails/PassengerCard';
import withStore from '../../components/withStore';

function mapStateToProps(state, props) {
  return {
    ...props,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePassengers: (data) => dispatch(actions.updatePassengers(data)),
  };
}

const PassengerCardContainer = connect(mapStateToProps, mapDispatchToProps)(PassengerCard);

export default withStore(PassengerCardContainer);
