const CateringConstants = {
  CATERING_STATUS_PENDING_APPROVAL: 'Awaiting Menu',
  CATERING_STATUS_PENDING_SELECTION: 'Menu Available',
  CATERING_STATUS_ON_REQUEST: 'On Request',
  CATERING_STATUS_CONFIRMED: 'Confirmed',
  CATERING_STATUS_NOT_REQUIRED: 'Not Required',
  CATERING_STATUS_CODE_0: 0, // : No backend response 1
  CATERING_STATUS_CODE_1: 1, // : No Work Started 1
  CATERING_STATUS_CODE_2: 2, // : Proposal Started 1
  CATERING_STATUS_CODE_3: 3, // : Proposal Available 2
  CATERING_STATUS_CODE_4: 4, // : Customer Selection Made 3
  CATERING_STATUS_CODE_5: 5, // : Menu Proposal Rejected 1
  CATERING_STATUS_CODE_6: 6, // : Purchase Order Process Started 3
  CATERING_STATUS_CODE_7: 7, // : Catering Order(s) Placed 3
  CATERING_STATUS_CODE_8: 8, // : Catering Confirmed 4
  CATERING_STATUS_CODE_9: 9, // : Flight Preferences updated 3
  CATERING_STATUS_CODE_10: 10, // : Flight Cancelled - Cancel Catering 5
  CATERING_STATUS_CODE_11: 11, // : Flight Cancelled - Catering Cancelled 5
  CATERING_STATUS_CODE_12: 12, // : Check Work - Flight Changed 1
  CATERING_STATUS_CODE_13: 13, // : No Catering Required 5
  CATERING_STATUS_CODE_14: 14, // : C/H To Organise 4
  CATERING_STATUS_CODE_15: 15, // : Catering Delivered 4
  CATERING_STATUS_CODE_16: 16, // : Catering Feedback Received 4
  CATERING_STATUS_CODE_17: 17, // : Customer Selection Updating 3
  CATERING_STATUS_CODE_18: 18, // : Customer Selection Updated 3
  CATERING_STATUS_CODE_19: 19, // : Pending O/B Approval 3
  cateringStates: {
    NOT_STARTED:[0,1,2],
    PENDING_APPROVAL: [0, 1, 2, 5],
    PENDING_SELECTION: [3, 12],
    ON_REQUEST: [4, 6, 7, 9, 17, 18, 19],
    CONFIRMED: [8, 14, 15, 16],
    NOT_REQUIRED: [10, 11, 13],
  },
  CATERING_SERVICE_GENERAL: 'General',
  CATERING_SHARED_ORDER_KEY: '0',
  CATERING_SHARED_ORDER_LABEL: 'Shared Order',
  CATERING_SCREENS: {
    SUPPLIER_LIST_MENU: 'supplierlistmenu',
    PASSENGER_QUANTITY_LIST: 'passengerquantitylist',
    SUPPLIERS_LIST: 'suppliersscreen',
  }
};


module.exports = CateringConstants;
