export const LEG_ARRAY = "legArray";
export const USER_REDUCER = "user";
export const REGISTER_VIA_PAF = "REGISTER_VIA_PAF";
export const LOGIN_VIA_PAF = "LOGIN_VIA_PAF";
export const TOP_UP_STATUS = 'TOP_UP_STATUS'
export const IS_OFFER_FLOW = "isOfferFlow";
export const CREDIT_CARDS = "creditCards";
export const IS_FSP_VALIDATED = 'IS_FSP_VALIDATED';
export const IS_VJ_VALIDATED = 'IS_VJ_VALIDATED';
export const QUOTE_OBJECT = "QuoteObject-{username}";
export const IS_DATA_ENCRYPTED = "IS_DATA_ENCRYPTED";
