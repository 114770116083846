import React from 'react';
import PropTypes from 'prop-types';

const getStyle = (props) => {
  const style = {
    position: 'absolute',
    zIndex: '1',
    top: '13px',
    left: '20px',
    fontSize: '16px',
    fontFamily: 'garamond, serif',
    lineHeight: '22px',
    pointerEvents: 'none',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    transformOrigin: 'left top 0px',
  };

  if (props.isFocused || props.hasValue) {
    style.transform = 'perspective(1px) scale(1) translate3d(0, -28px, 0)';
    style.left = '20px';
    style.top = '5px';
  }

  return style;
};

const FloatingLabel = (props, context) => {
  const { children, style, htmlFor } = props;
  const styles = Object.assign(getStyle(props, context), style);
  return (
    <label
      htmlFor={htmlFor}
      style={styles}
    >
      {children}
    </label>
  );
};

FloatingLabel.propTypes = {
  /**
   * Content of the label.
   */
  children: PropTypes.object, // eslint-disable-line

  /**
   * Controls the label style color if it's enabled or not.
   */
  disabled: PropTypes.bool, // eslint-disable-line

  /**
   * Controls if the label has value or not.
   */
  hasValue: PropTypes.bool.isRequired, // eslint-disable-line

  /**
   * htmlFor property of the Label.
   */
  htmlFor: PropTypes.string, // eslint-disable-line

  /**
   * Controls the color style if the label is focused.
   */
  isFocused: PropTypes.bool.isRequired, // eslint-disable-line

  /**
   * Label inline style.
   */
  style: PropTypes.object, // eslint-disable-line
};

FloatingLabel.contextTypes = {
  muiTheme: PropTypes.object.isRequired, // eslint-disable-line
};

export default FloatingLabel;
