/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';

export default class ProgramBuilderDonutAndSuggestion extends Component {
  render() {
    const { data } = this.props;
    const { airCraft } = data;
    return (
      <div>
        <div className="uk-container">
          <div className="uk-flex uk-margin-xlarge-top uk-grid-small uk-grid" uk-grid="">
            <div className="uk-width-1-2@m uk-width-1-2@s uk-text-center uk-first-column uk-position-relative">
              <img
                className="uk-width-medium"
                alt="chart"
                uk-img=""
                src="/legacy/static/img/Chart.svg"
              />
              <div>
                <p className="vistajet_year_hours">
                  {data.yearlyHours}
                </p>
                <p className="vistajet_per_year">
                  Hours per year
                </p>
              </div>
              <div className="uk-column-1-1 uk-margin-medium-top uk-margin-medium-bottom">
                <div className="vistajet_chart_status">
                  <p>Flight Hours</p>
                </div>
                <div className="vistajet_chart_status vistajet_chart_status_gray">
                  <p>Recommended additional hours</p>
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div className="vistajet_perfect_craft uk-text-center uk-text-left@s">
                <h4 className="vistajet_color_red">
                  SUGGESTED AIRCRAFT
                </h4>
                <img
                  className="uk-width-small"
                  alt="jet"
                  src={airCraft.imgUrl.default}
                />
                <div className="vistajet_planshadows" />
                <div className="uk-margin-small-top">
                  <h5>
                    {airCraft.aircraftName}
                  </h5>
                  <small className="uk-display-block">
                    <strong>Flying hours</strong>:
                    {airCraft.flyingHours}
                  </small>
                  <small className="uk-display-block">
                    {' '}
                    <strong>Range</strong>:
                    {airCraft.range}
                  </small>
                  <small className="uk-display-block">
                    <strong>Maximum speed</strong>:
                    {airCraft.maximumSpeed}
                  </small>
                  <small className="uk-display-block">
                    <strong>Passenger capacity</strong>:
                    {airCraft.passengerCapacity}
                    {' | '}
                    {airCraft.passengerCapacitySleeping}
                    {' '}
                    sleeping
                  </small>
                </div>
                <div className="uk-margin-medium-bottom">
                  <a href={airCraft.pageUrl} target="_blank" rel="noopener noreferrer" className="uk-button vistajet_button_primary">
                    See Inside
                  </a>
                </div>
                <hr />
                <h4 className="vistajet_color_red uk-text-left">
                  PROGRAM MEMBERSHIP
                </h4>
                <ul className="uk-text-left">
                  <li>


                    No depreciation or asset risk
                  </li>
                  <li>


                    Transparent per hour cost
                  </li>
                  <li>


                    Consistent, versatile fleet
                  </li>
                  <li>


                    Ultra-personalised service
                  </li>
                  <li>


                    24/7 guaranteed availability
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
