import { fetchGroundServices, } from '../sources/GroundServicesSource';
import {
  FETCH_GROUND_SERVICES_FAIL,
  FETCH_GROUND_SERVICES_INIT,
  FETCH_GROUND_SERVICES_SUCCESS,
} from '../actionTypes';

export const fetchGroundServicesInit = () => ({
  type: FETCH_GROUND_SERVICES_INIT,
});

export const fetchGroundServicesSuccess = servicesOptions => ({
  type: FETCH_GROUND_SERVICES_SUCCESS,
  data: servicesOptions,
});

export const fetchGroundServicesFail = (data) => ({
  type: FETCH_GROUND_SERVICES_FAIL,
  data,
});

export const fetchAllGroundServices = (token, itineraryType, pageNum) => fetchGroundServices(token, itineraryType, pageNum);
