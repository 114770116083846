import React, { Component } from "react";
import ValidateFlightMessage from "../ValidateFlightMessage";

const MessagePopUp = props => {
  const { id } = props;
  let contentError = '';
  if (props.content && props.content.length > 1) {
    contentError = props.content.map(err => <li>{err}</li>);
  } else if (props.content) {
    contentError = props.content[0];
  }
  const errors = props.messages ? (
    <ValidateFlightMessage
      messages={props.messages}
      legs={props.legs}
      isProposalOrRestriction={props.isProposalOrRestriction}
    />
  ) : (
      contentError
    );

  return (
    <div
      id={id}
      className="vistajet_modal"
      stack="true"
      data-uk-modal
      bg-close="true"
      esc-close="false"
    >
      <div className="uk-modal-dialog uk-modal-body vistajet_modal_body uk-margin-auto-vertical">
        <div className="vistajet_modal_content uk-padding-remove">
          <ul className="vistajet_modal_list_message">{errors}</ul>
          <div className="uk-flex uk-flex-right vistajet_button_wrapper">
            <a
              className="vistajet_button_default vistajet_button_primary"
              onClick={() => props.handleClose(id)}
            >
              OK
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePopUp;
