import adminTokenSource from '../sources/AdminTokenSource';
import * as types from '../actionTypes';

export const fetchAdminTokenInit = () => ({
  type: types.ADMIN_FETCH_TOKEN_INIT,
});

export const fetchAdminTokenSuccess = data => ({
  type: types.ADMIN_FETCH_TOKEN_SUCCESS,
  data,
});

export const fetchAdminTokenFail = data => ({
  type: types.ADMIN_FETCH_TOKEN_FAIL,
  data,
});

export const fetchAdminToken = () => adminTokenSource();
