import React, { Component } from "react";
import ReactStars from "../shared/RatingsComponent/RatingComponent";
import {
  CUSTOMER_FEEDBACK_SUBMITTED,
  SUBMIT_CUSTOMER_FEEDBACK,
  UPCOMING_FEEDBACK_MSG,
} from "../../constants/feedbackConstants";
import { getParameterByName } from "../../utils/urlUtils";
import { dateInPast } from "../../utils/Validators/Validators";
import SpinnerLoader from ".././shared/spinnerLoader";
import CustomerFeedbackPopup from "./CustomerFeedbackPopup";
import MessagePopUp from "../shared/MessagePopUpNew";
import dirtystateSingleTon from "../../validators/validateDirty";
import SPAConstants from "../../constants/SPAConstants";
class CustomerFeedback extends Component {
  constructor() {
    super();
    this.state = {
      customerFeedbackPopup: false
    };
  }
  componentDidMount() {
    this.errorPopUp.hide();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.feedbackInfo) {
      if (
        nextProps.feedbackInfo.isSavingFeedback === false &&
        nextProps.feedbackInfo.feedbackSuccess === true
      ) {
        dirtystateSingleTon.setDirty(SPAConstants.CustomerFeedback, false);
        this.setState({
          customerFeedbackPopup: false
        });
      } else if (
        nextProps.feedbackInfo.feedbackError &&
        nextProps.feedbackInfo.feedbackError.length > 0
      ) {
        dirtystateSingleTon.setDirty(SPAConstants.CustomerFeedback, false);
        this.setState({
          customerFeedbackPopup: false
        });
        this.errorPopUp.show();
      }
    }
  }
  isFeedbackActive = () => {
    const { legData } = this.props;
    if (
      (!getParameterByName("feedbackIdentifier") &&
        !dateInPast(legData.arrivalAirportTimeUTC, "second") &&
        !legData.isPast) ||
      legData.orderKey === "orderExtIdentifier"
    ) {
      return false;
    }
    return true;
  };

  closeRatingDialogue = () => {
    this.setState({
      customerFeedbackPopup: false
    });
  };

  handleRatingDialogue = () => {
    this.setState({
      customerFeedbackPopup: true
    });
  };

  handleMessageText = () => {
    const { feedbackInfo } = this.props;
    const feedbackIdentifier = getParameterByName("feedbackIdentifier");

    if (feedbackIdentifier) {
      return (
        <p className="uk-margin-remove">{SUBMIT_CUSTOMER_FEEDBACK}</p>
      );
    }
    if (!this.isFeedbackActive()) {
      return (
        <p className="uk-margin-remove"> {UPCOMING_FEEDBACK_MSG} </p>
      );
    } else if (feedbackInfo && feedbackInfo.feedback) {
      if (feedbackInfo.feedback.feedbackRating) {
        return (
          <p className="uk-margin-remove">
            {CUSTOMER_FEEDBACK_SUBMITTED}
          </p>
        );
      }
    }
    return (
      <p className="uk-margin-remove"> {SUBMIT_CUSTOMER_FEEDBACK} </p>
    );
  };
  shouldShowModal = (show = false) => {
    if (show) {
      return this.handleRatingDialogue();
    }
    return;
  };
  clearError = () => {
    const { legId } = this.props.legData;
    this.props.clearFeedbackError(legId);
  };
  render() {
    const { feedbackInfo, isLoading } = this.props;
    const handleShowFeedback =
      feedbackInfo &&
      feedbackInfo.feedback &&
      feedbackInfo.feedback.feedbackRating;
    return (
      <div className="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card vistajet_feedback_section uk-padding-remove vistajet_feedback_wrapper">
        <div className="vistajet_itinerary_card_header">
          <h5>Feedback</h5>
        </div>
        <div
          className="vistajet_itinerary_card_body"
          onClick={() => {
            this.shouldShowModal(
              !handleShowFeedback &&
              this.isFeedbackActive() &&
              !this.state.customerFeedbackPopup &&
              !(this.props.feedbackInfo || {}).feedbackError
            );
          }}
        >
          {(feedbackInfo || {}).isFeedbackLoading || isLoading ? (
            <div className="vistajet_spinner">
              <SpinnerLoader />
            </div>
          ) : (
              <React.Fragment>
                {this.handleMessageText()}
                {this.isFeedbackActive() && (
                  <div className="vistajet_feedback_card_stars">
                    <ReactStars
                      className="rating-row-style"
                      count={5}
                      value={handleShowFeedback}
                      size={40}
                      color1="#9B9B9B"
                      color2="#ffd700"
                      half={false}
                      // showStatus={false}
                      edit={false}
                    />
                  </div>
                )}
                <CustomerFeedbackPopup
                  open={this.state.customerFeedbackPopup}
                  user={this.props.user}
                  saveLegFeedback={this.props.saveLegFeedback}
                  legId={this.props.legData.legId}
                  isFeedbackLoading={(feedbackInfo || {}).isFeedbackLoading}
                  closeRatingDialogue={this.closeRatingDialogue}
                  isSavingFeedback={(feedbackInfo || {}).isSavingFeedback}
                />
              </React.Fragment>
            )}
          <MessagePopUp
            ref={errorPopUp => {
              this.errorPopUp = errorPopUp;
            }}
            content={(this.props.feedbackInfo || {}).feedbackError}
            onExited={this.clearError}
          />
        </div>
      </div>
    );
  }
}

export default CustomerFeedback;
