import axios from "axios";
import { FORECAST_URL } from "../../configs/constants";
import {
    fetchWeatherInit,
    fetchWeatherSuccess,
    fetchWeatherFail
  } from "../actions";
  import {
    handleServerErrors,
  } from '../../utils/sourceUtils';
  
  const status = {
    OK: 200,
  };
  
const toCentigrade = (temperature) => ((temperature - 32) * 5 / 9);

const normalizeForecast = (data) => {
  const {
    currently,
  } = data;
  if (currently.icon && currently.summary && currently.temperature) {
    return {
      icon: currently.icon,
      summary: currently.summary,
      temperature: currently.temperature,
      centigrade: Math.round(toCentigrade(currently.temperature)),
    };
  }

  return false;
};

const weatherPromiseInprogress = {};

const fetchWeather = (lat, long, time, key, flightLegId) => (dispatch) => {
  const uniqueKey = `${flightLegId}-${lat}-${long}-${time}-${key}`;
  if (weatherPromiseInprogress[uniqueKey]) {
    return weatherPromiseInprogress[uniqueKey];
  }
  const weatherPromise = new Promise((resolve, reject) => {
    const url = FORECAST_URL
      .replace('{lat}', lat)
      .replace('{long}', long)
      .replace('{time}', time);
    dispatch(fetchWeatherInit(flightLegId, key));
    axios
      .get('https://cors-anywhere.herokuapp.com/' + url)
      .then((response) => {
        if (response.status === status.OK) {
          const weatherDetail = normalizeForecast(response.data);
          dispatch(fetchWeatherSuccess(weatherDetail, key, flightLegId));
          resolve(weatherDetail);
        } else {
          const errors = handleServerErrors(response.status);
          dispatch(fetchWeatherFail(errors, key, flightLegId));
          reject(errors);
        }
        delete weatherPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(fetchWeatherFail(errors, key, flightLegId));
        reject(errors);
        delete weatherPromiseInprogress[uniqueKey];
      });
  });
  weatherPromiseInprogress[uniqueKey] = weatherPromise;
  return weatherPromise;
};

export default fetchWeather;
