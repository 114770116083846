import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';

class MessagePopUp extends Component {
  state = {
    open: false,
  };

  show = () => {
    this.setState({ open: true });
  };

  hide = () => {
    const { onClose } = this.props;
    this.setState({ open: false });
    onClose();
  };

  render() {
    const {
      className,
      isCenter,
      closeOnEsc,
      closeOnOverlayClick,
      onExited,
      styles,
      showCloseIcon,
      closeIconSize,
      closeIconSvgPath,
      focusTrapped,
      id,
      content,
    } = this.props;
    const { open } = this.state;

    return (
      <Modal
        open={open}
        classNames={{
          overlay:'vistajet_modal vistajet_confirm_modal',
          modal:'vistajet_modal_body vistajet_react_modal',
          closeButton:'vistajet_modal_close',
          closeIcon:'<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg>'
        }}
        closeOnOverlayClick={closeOnOverlayClick}
        onClose={this.hide}
        center={isCenter}
        closeOnEsc={closeOnEsc}
        onExited={onExited}
        styles={styles}
        showCloseIcon={showCloseIcon}
        closeIconSize={closeIconSize}
        closeIconSvgPath={closeIconSvgPath}
        focusTrapped={focusTrapped}
      >
        <div id={id} className="vistajet_modal_content">
          <p className="uk-text-center">
            {content}
          </p>
        </div>
      </Modal>
    );
  }
}
MessagePopUp.defaultProps = {
  isCenter: true,
  closeOnEsc: false,
  closeOnOverlayClick: true,
  showCloseIcon: true,
  focusTrapped: true,
  closeIconSize: 28,
  onExited: () => {},
  onClose: () => {},
  closeIconSvgPath: <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />,
};
export default MessagePopUp;
