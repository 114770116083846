import { validateEmail } from './Validators';

export default function validateForgotPasswordForm(obj) {
  const emailResponse = validateEmail(obj.email);
  const res = {
    errors: {},
  };
  if (emailResponse.isError) {
    res.isError = emailResponse.isError;
    res.errors.email = emailResponse.message;
  }
  return res;
}
