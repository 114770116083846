import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { clearErrorFetchItinerary } from '../../store/actions/ItineraryListingActions'
import withStore from '../../components/withStore';
import ItinerariesListing from '../../components/Itinerary/ItinerariesListing';
import { ITINERARY_TYPES, EMPTY_MESSAGE } from '../../constants/ItineraryConstants';

function mapStateToProps(state) {
  const {
    cancelItineraries,
    isLoadMoreCancelled,
    errorsItineraries,
    isLoadingCancelledItineraries,
    isLoadingMoreCancelledItineraries,
  } = state.itineraries;
  const { token } = state.token;
  const { isGoogleMapsApiLoaded, } = state.googleMap;
  const itineraryHeading = 'Cancelled Itineraries';
  const itineraryType = ITINERARY_TYPES.CANCELLED_ITINERARIES;
  const { isAirportsLoading, visitedAirports, visitedAirportsFinder } = state.visitedAirports;

  return {
    token,
    itineraryType,
    visitedAirports,
    itineraryHeading,
    isAirportsLoading,
    visitedAirportsFinder,
    isGoogleMapsApiLoaded,
    itineraries: cancelItineraries,
    isLoadMoreDisable: isLoadMoreCancelled,
    errorsItineraries,
    isLoadingMore: isLoadingMoreCancelledItineraries,
    isLoadingItineraries: isLoadingCancelledItineraries,
    emptyMessage: EMPTY_MESSAGE.CANCELLED_ITINERARIES,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAirportById: id => dispatch(actions.getAirportById(id)),
    onGoogleMapsApiLoaded: () => dispatch(actions.onGoogleMapsApiLoaded()),
    fetchVisitedAirports: () => dispatch(actions.fetchAllVisitedAirports()),
    clearErrorFetchItinerary: () => dispatch(clearErrorFetchItinerary()),
    fetchItineraryListing: (token, itineraryType, pageNum, isLoadMore) => dispatch(actions.fetchAllItineraryListing(token, itineraryType, pageNum, isLoadMore)),
  };
}

const PasItinerariesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerariesListing);

export default withStore(PasItinerariesContainer);
