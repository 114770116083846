/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import MessagePopUpNew from '../shared/MessagePopUpNew';
import MessagePopUp from '../shared/MessagePopUp';
import validateLoginForm from '../../utils/Validators/LoginFormValidator';
import ResetPassword from '../ForgotPassword/ResetPassword';
import { saveToLocal } from "../../utils/cache";
import {
  CONFIRM_REGISTER_PAGE_URL,
  DIRECT_CONFIRM_REGISTER_PAGE_URL,
  PAF_PAGE_URL,
  RAF_PAGE_URL,
} from '../../configs/constants';
import {
  ACCOUNT_EXPIRY_MESSAGE,
  MEMBERSHIP_EXPIRY_MESSAGE_VJDIRECT,
  FSP_VJDIRECT_EXPIRY_MESSAGE
} from '../../constants/MessageConstants';
import { getFromLocal, existInLocal, removeFromLocal } from '../../utils/cache';
import { isFSPAccountExpire, isFSP, isFspDirect, isVJ2, isVJDirectAccountExpire } from "../../utils/userUtils";
import {
  LOGIN_VIA_PAF,
  REGISTER_VIA_PAF,
  IS_OFFER_FLOW,
  LEG_ARRAY,
  IS_FSP_VALIDATED,
  IS_VJ_VALIDATED,
} from '../../constants/localStorageKeys';
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants';

const resetPasswordModalId = 'ChangePassword';
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      password: '',
      popupMessage: '',
      isFSPValidated: false,
      isVJValidated: false,
      passwordType: 'password',
      AccountExpiryPopupMessage: '',
      passwordIconClass: 'vistajet_password_hide',
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const isUserVerified = params.get('verified');
    const isUserAuthenticated = params.get('notAuthenticated');
    if ((isUserVerified === 'true' || isUserAuthenticated === 'true') && (this.props.user && !this.props.user.isLoggedIn)) {
      this.setState({
        isUserVerified: true,
      }, () => {
        window.location.replace(window.epiSettings.loginUrl);
      });
    }
    this.checkUserAccountExpiry();
    this.checkUserVerification(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.isLoggedIn
      && (getFromLocal(LOGIN_VIA_PAF) || getFromLocal(REGISTER_VIA_PAF))) {
      // Clear dirty state before of  ModalPAF redirecting
      dirtystateSingleTon.setDirty(SPAConstants.RegisterPAFContainer, false);
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
      if (isFSP(nextProps.user.user) || isFspDirect(nextProps.user.user)) {
        window.open(PAF_PAGE_URL, "_self");
      }
      else {
        window.open(RAF_PAGE_URL, "_self");
      }
    }

    this.checkUserVerification(nextProps);
    this.checkUserAccountExpiry();

    if (
      this.props.user.isLoggedIn !== nextProps.user.isLoggedIn
      && nextProps.user.isLoggedIn
    ) {
      this.setState({
        isUserVerified: false,
      });
      if (
        window.location.href.indexOf(CONFIRM_REGISTER_PAGE_URL.url) === 0
        || window.location.href.indexOf(DIRECT_CONFIRM_REGISTER_PAGE_URL.url) === 0
      ) {
        if (existInLocal(IS_OFFER_FLOW)) {
          if (isVJ2(nextProps.user.user) || isFspDirect(nextProps.user.user)) {
            window.open(RAF_PAGE_URL, "_self");
          } else {
            removeFromLocal(IS_OFFER_FLOW);
            removeFromLocal(LEG_ARRAY);
            window.location = '/';
          }
        } else {
          window.location = '/';
        }
      } else {
        if (existInLocal(IS_OFFER_FLOW)) {
          if (isVJ2(nextProps.user.user) || isFspDirect(nextProps.user.user)) {
            window.open(RAF_PAGE_URL, "_self");
          } else {
            removeFromLocal(IS_OFFER_FLOW);
            removeFromLocal(LEG_ARRAY);
          }
        }
      }
    }
    if (
      this.props.user.error !== nextProps.user.error
      && nextProps.user.isError
    ) {
      this.setState({
        popupMessage: nextProps.user.error,
      });
      window.UIkit.modal('#loginErrorPopUp').show();
    }
    if (
      this.props.tokenError !== nextProps.tokenError
      && nextProps.isTokenError
    ) {
      this.setState({
        popupMessage: nextProps.tokenError,
      });
      window.UIkit.modal('#loginErrorPopUp').show();
    }
    if (
      this.props.authError !== nextProps.authError
      && nextProps.isAuthError
    ) {
      this.setState({
        popupMessage: nextProps.authError,
      });
      window.UIkit.modal('#loginErrorPopUp').show();
    }
    /* Open ChangePassword Modal when changePassRequired is True */
    if (nextProps.changePasswordRequired) {
      window.UIkit.modal(`#${resetPasswordModalId}`).show();
    }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const { loginAction } = this.props;
    const res = validateLoginForm({
      email: email.toLowerCase(),
      password,
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      const payload = {
        email: email.toLowerCase(),
        password,
      };
      loginAction(payload);
    }
  };

  togglePassView = () => {
    if (this.state.passwordType === 'password') {
      this.setState({
        passwordType: 'text',
        passwordIconClass: 'vistajet_password_show',
      });
    } else {
      this.setState({
        passwordType: 'password',
        passwordIconClass: 'vistajet_password_hide',
      });
    }
  };

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  renderForm = () => {
    return (
      <div className="vistajet_primary_form uk-margin-medium-bottom">
        Not used.
      </div>
    );
  };

  getHeaderContent = () => {
    const params = new URLSearchParams(window.location.search);
    const isUserVerified = params.get('verified');
    return (
      isUserVerified ? (
        <div>
          Thank you
          <br />
          your account is ready
        </div>
      ) : 'Enter your details'
    );
  };

  checkUserAccountExpiry = () => {
    const { user, isLoggingOut, userHasLoggedOut, } = this.props;
    if (!(this.state.isFSPValidated || this.state.isVJValidated) && !(isLoggingOut || userHasLoggedOut)) {
      if (isFSPAccountExpire(user.user, IS_FSP_VALIDATED) && isVJDirectAccountExpire(user.user, IS_VJ_VALIDATED)) {
        this.setState({
          AccountExpiryPopupMessage: FSP_VJDIRECT_EXPIRY_MESSAGE,
          isFSPValidated: true,
          isVJValidated: true,
        });
        this.accountExpiryErrorPopUp.show()
        saveToLocal(true, IS_FSP_VALIDATED);
        saveToLocal(true, IS_VJ_VALIDATED);
      }

      else if (isVJDirectAccountExpire(user.user, IS_VJ_VALIDATED)) {
        this.setState({
          AccountExpiryPopupMessage: MEMBERSHIP_EXPIRY_MESSAGE_VJDIRECT,
          isVJValidated: true,
        });
        this.accountExpiryErrorPopUp.show()
        saveToLocal(true, IS_VJ_VALIDATED);
      }

      else if (isFSPAccountExpire(user.user, IS_FSP_VALIDATED)) {
        this.setState({
          AccountExpiryPopupMessage: ACCOUNT_EXPIRY_MESSAGE,
          isFSPValidated: true,
        });
        this.accountExpiryErrorPopUp.show()
        saveToLocal(true, IS_FSP_VALIDATED);
      }
    }
  }

  checkUserVerification(props) {
    if (props.user && props.user.isLoggingIn) return; // If IsLoggingIN status we don't need to check all
    if (props.isForgotEmailSent) return; // Forgot flow also sends prop to Login :'(
    const params = new URLSearchParams(window.location.search);
    const shouldVerifyUser = params.get('shouldVerifyUser');
    const { user } = props;
    if (
      window.location.href.indexOf(CONFIRM_REGISTER_PAGE_URL.url) === -1
      && window.location.href.indexOf(DIRECT_CONFIRM_REGISTER_PAGE_URL.url) === -1
    ) {
      if (user.user && user.user.userVerificationStatusCode === 2) {
        if (shouldVerifyUser !== 'true' && props.user.user && props.user.user.userNotVerified) {
          props.clearNotVerifiedFlag();
          window.location = `${CONFIRM_REGISTER_PAGE_URL.url}?shouldVerifyUser=true&email=${this.state.email || user.user.user_email}`;
        }
      } else {
        if (shouldVerifyUser !== 'true' && props.user.user && props.user.user.userNotVerified) {
          props.clearNotVerifiedFlag();
          window.location = `${CONFIRM_REGISTER_PAGE_URL.url}?shouldVerifyUser=true&email=${this.state.email || user.user.user_email}`;
        }
      }
    }
    if (user && user.changePasswordRequired) {
      window.UIkit.modal(`#${resetPasswordModalId}`).show();
      return;
    }
  }

  render() {
    const { popupMessage, AccountExpiryPopupMessage } = this.state;

    return (
      <div>
        <ForgotPassword {...this.props} />
        <MessagePopUp
          id="loginErrorPopUp"
          content={popupMessage}
        />
        <MessagePopUpNew
          ref={accountExpiryErrorPopUp => {
            this.accountExpiryErrorPopUp = accountExpiryErrorPopUp;
          }}
          id="accountExpiryErrorPopUp"
          content={AccountExpiryPopupMessage}
        />
        <ResetPassword {...this.props} />
      </div>
    );
  }
}
