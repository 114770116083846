import axios from 'axios';
import { // eslint-disable-line
  directSignupInit,
  directSignupSuccess,
  directSignupFail,
} from '../actions';

import {
  MEMBERSHIP_WITHOUT_CARD_SIGN_UP
} from '../../configs/constants';

import {
  HTTP_HEADERS_XML,
} from '../../constants/ApiConstants';

import {
  handleServerErrors,
} from '../../utils/sourceUtils';

import {
  directSignupRequestWithoutPaymentNormalizer,
  normalizeSignupWithoutCardResponse,
} from '../../normalizers/MemberShipPlansNormalizer';
import { parseString } from '../../utils/parserUtils';

const DirectSignupSource = (
  token,
  payload,
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(directSignupInit());
    const url = MEMBERSHIP_WITHOUT_CARD_SIGN_UP.replace('{token}', token);
    const normalizedRequestPayload = directSignupRequestWithoutPaymentNormalizer(payload);
    axios
      .post(
        url,
        normalizedRequestPayload,
        {
          headers: HTTP_HEADERS_XML,
        },
      )
      .then((res) => {
        parseString(res.data, (parseErr, result) => {
          if (parseErr) {
            const errors = handleServerErrors(1);
            dispatch(directSignupFail(errors));
            reject(errors);
          } else if (result.signUpResponseBody) {
            const { responseHeader } = result.signUpResponseBody;
            if (!responseHeader) {
              const errors = handleServerErrors(1);
              dispatch(directSignupFail(errors));
              reject(errors);
            } else if (responseHeader.isError) {
              const errors = handleServerErrors(responseHeader.statusCode);
              dispatch(directSignupFail(errors));
              reject(errors);
            } else {
              const signUpResponse = result.signUpResponseBody;
              const normalizedResponse = normalizeSignupWithoutCardResponse(signUpResponse);
              dispatch(directSignupSuccess({
                paymentMethodDetails: normalizedResponse,
                accountId: signUpResponse.accountId,
              }));
              resolve(normalizedResponse);
            }
          }
        });
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(directSignupFail(errors));
        reject(errors);
      });
  });
};

export default DirectSignupSource;
