import * as type from '../../actionTypes';
import { getFlightId } from '../../../utils/itinerariesUtils';

const initialState = {
  isLoadingCrew: 0,
  isErrorCrew: '',
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.ITINERARY_DETAILS_SUCCESS: {
      const {
        legs,
      } = action.data;
      const crewData = {};
      legs.forEach(leg => {
        crewData[getFlightId(leg)] = {};
        crewData[getFlightId(leg)].isLoadingCrew = false;
      })
      return Object.assign({}, state, {
        crew: crewData,
      });
    }
    case type.FETCH_CREW_INIT: {
      state.crew[action.flightLegId].isLoadingCrew = true;
      state.crew[action.flightLegId].crewSuccess = false;
      return Object.assign({}, state, {
        isLoadingCrew: state.isLoadingCrew + 1,
        crew: state.crew,
        isErrorCrew: '',
      });
    }

    case type.FETCH_CREW_SUCCESS: {
      state.crew[action.flightLegId].crew = action.data || '';
      state.crew[action.flightLegId].crewSuccess = true;
      state.crew[action.flightLegId].isLoadingCrew = false;
      return Object.assign({}, state, {
        isLoadingCrew: state.isLoadingCrew - 1,
        crew: state.crew,
        isErrorCrew: '',
      });
    }

    case type.FETCH_CREW_FAIL: {
      state.crew[action.flightLegId].isLoadingCrew = false;
      state.crew[action.flightLegId].crewSuccess = false;
      return Object.assign({}, state, {
        isLoadingCrew: state.isLoadingCrew - 1,
        crew: state.crew,
        isErrorCrew: action.data,
      });
    }

    case type.CLEAR_ERROR_FETCH_ITINERARY: {
      return Object.assign({}, state, {
        isErrorCrew: '',
      });
    }
    default:
      return state;
  }
}
