import * as types from '../actionTypes';
import { 
  TRANSACTIONS_QUANTITY,
} from "configs/constants";

export const initialState = {
  membershipAccountLoading: false,
  accountData: null,
  moreTransactions: true,
  loadingMoreTransactions: false,
  directAccoutBalanceError: '',
  isDirectAccountBalanceError: false,
  isMemebershipAccountFetched: false,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.MEMBERSHIP_ACCOUNT_STATEMENT_INIT : {
      return Object.assign({}, state, {
        membershipAccountLoading: !action.isLoadMore,
        loadingMoreTransactions: action.isLoadMore,
        directAccoutBalanceError:'',
        isMemebershipAccountFetched: false,
        isDirectAccountBalanceError: false,
      });
    }
    case types.MEMBERSHIP_ACCOUNT_STATEMENT_SUCCESS : {
      const { data, isLoadMore } = action;
      let { accountData } = state;
      let moreTransactions = true
      if (isLoadMore && data && data.lineItems) {
        accountData.lineItems = accountData.lineItems.concat(data.lineItems);
      } else {
        accountData = data;
      }
      if (action.data.lineItems.length < TRANSACTIONS_QUANTITY) {
        moreTransactions = false;
      }
      return Object.assign({}, state, {
        membershipAccountLoading: false,
        accountData,
        moreTransactions,
        loadingMoreTransactions: false,
        isMemebershipAccountFetched: true,
        isDirectAccountBalanceError: false,
      });
    }
    case types.MEMBERSHIP_ACCOUNT_STATEMENT_FAIL : {
      return Object.assign({}, state, {
        membershipAccountLoading: false,
        loadingMoreTransactions: false,
        directAccoutBalanceError: action.data,
        isDirectAccountBalanceError: true,
      });
    }
    case types.CLEAR_TOPUP_ERROR : {
      return Object.assign({}, state, {
        directAccoutBalanceError: '',
      });
    }
    default:
      return state;
  }
}
