import React, { Component } from "react";

import { dateFnsFormats } from "../../utils/dateUtils";

import FlightTime from "./FlightTime";
import EstimateTime from "./EstimateTime";
import StepperWrapper from "./Wrappers/StepperWrapper";
import AircraftDropdownWrapper from "./Wrappers/AircraftDropdownWrapper";
import TimePickerWrapper from "./Wrappers/TimePickerWrapper";
import DatePickerWrapper from "./Wrappers/DatePickerWrapper";
import AirportSelectorWrapper from "./Wrappers/AirportSelectorWrapper";
import ConfirmationPopUp from "../shared/ConfirmationPopUpNew";
import PassengerCard from "../ItineraryDetails/PassengerCard";
export default class LegCard extends Component {

  constructor(props) {
    super(props);
    this.popupRefs = {};
    this.handlePassengerChange = this.handlePassengerChange.bind(this);
  }

  handleDate = date => {
    const { handleDateChange } = this.props;
    if (handleDateChange) handleDateChange(date);
  };

  handleAirportSelected = (value, source) => {
    const { handleAirportSelect } = this.props;
    if (handleAirportSelect) handleAirportSelect(value, source);
  };
  handleRemoveLeg = () => {
    this.removePopup.hide();
    this.props.handleRemoveFlight();
  }

  handlePassengerChange = (value) => {
    const { isEditFlow, handleStepperChange } = this.props;
    if (isEditFlow) {
      this.popupRefs.openPassengerPopup();
    }
    else {
      handleStepperChange(value);
    }
  }
  render() {
    const {
      guid,
      hour,
      minutes,
      time,
      date,
      selectedAircraft,
      duration,
      validation
    } = this.props.leg;

    const { passengers } = this.props;
    const { errorOn } = validation;
    const modalId = `#removeLegPopup_${guid}`;
    const addedPaxCount = passengers && passengers.passengers ? passengers.passengers.length : 1;
    return (
      <div className="vistajet_flight_booking_column">
        <div className="uk-width-1-1">
          <div className="vistajet_date_rleg_column">
            <div className="uk-clearfix">
              <div className="uk-float-right">
                {this.props.shouldRemoveButton ? (
                  <button
                    className="visjat_remove_leg"
                    onClick={() => { this.removePopup.show() }}
                  >
                    REMOVE
                  </button>
                ) : null}
              </div>
              <div className="uk-float-left">
                <div className="vistajet_date_column">
                  <span className="vistajet_date_icon">
                    <DatePickerWrapper
                      selected={date ? date : null}
                      placeholderText="Select Departure Date"
                      dateFormat={dateFnsFormats.DDMMMYYYY}
                      onChange={date => this.handleDate(date)}
                      highlightDates={this.props.allHighlightedDates}
                      isClearable={date}
                    />
                  </span>
                  <p className="vistajet_valid_error">{errorOn.date}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="vistajet_departure_column vistajet_mt_xsmall vistajet_mb_xsmall">
            <div className="uk-flex" data-uk-grid>
              <div className="uk-width-2-5">
                <AirportSelectorWrapper
                  leg={this.props.leg}
                  eTime={"etd"}
                  source={"departure"}
                  onAirportSelect={(value, source) =>
                    this.handleAirportSelected(value, source)
                  }
                  isAirportsFetched={this.props.isAirportsFetched}
                  visitedAirports={this.props.visitedAirports}
                  fetchVisitedAirports={this.props.fetchVisitedAirports}
                />
                <p className="vistajet_valid_error">{errorOn.departure}</p>
              </div>
              <div className="uk-width-1-5 uk-text-center">
                <FlightTime duration={duration} />
              </div>
              <div className="uk-width-2-5">
                <AirportSelectorWrapper
                  leg={this.props.leg}
                  eTime={"eta"}
                  source={"arrival"}
                  onAirportSelect={(value, source) =>
                    this.handleAirportSelected(value, source)
                  }
                  isAirportsFetched={this.props.isAirportsFetched}
                  visitedAirports={this.props.visitedAirports}
                  fetchVisitedAirports={this.props.fetchVisitedAirports}
                />
                <p className="vistajet_valid_error">{errorOn.arrival}</p>
              </div>
              <div className="uk-width-1-1 uk-margin-remove-top">
                <p className="vistajet_valid_error uk-text-center">
                  {errorOn.fuelStop}
                </p>
                <p className="vistajet_valid_error uk-text-center">
                  {errorOn.departureArrivalSame}
                </p>
              </div>
            </div>
          </div>
          <div className="vistajet_eta_column  vistajet_mb_xsmall">
            <div className="uk-clearfix">
              <div className="uk-float-right">
                <EstimateTime leg={this.props.leg} eTime={"eta"} />
              </div>
              <div className="uk-float-left">
                <TimePickerWrapper
                  hintText="Time"
                  hour={hour}
                  minutes={minutes}
                  time={time}
                  handleOnChange={value => this.props.handleOnChange(value)}
                  handleHour={value =>
                    this.props.handleDepartureHourChange(value)
                  }
                  handleMinutes={value =>
                    this.props.handleDepartureMinuteChange(value)
                  }
                />
                <p className="vistajet_valid_error">{errorOn.time}</p>
              </div>
            </div>
          </div>
          <div className="vistajet_cp_column disable-dbl-tap-zoom">
            <div className="uk-clearfix">
              <div className="uk-float-right vistajet_passengers_column uk-display-block">
                <StepperWrapper
                  value={addedPaxCount <= this.props.leg.passengersCount ?
                    this.props.leg.passengersCount : addedPaxCount}
                  maxValue={
                    selectedAircraft
                      ? selectedAircraft.maxNumberOfPassengers
                      : 14
                  }
                  onChange={this.handlePassengerChange}
                />
                <p className="vistajet_valid_error">
                  {errorOn.passengersCount}
                </p>
              </div>
              <div className="uk-float-left vistajet_aircraft_column">
                <AircraftDropdownWrapper
                  aircraftsList={this.props.aircraftsList}
                  validateContract={this.props.validateContract}
                  selectedAircraft={selectedAircraft}
                  allowAircraftSelect={this.props.allowAircraftSelect}
                  isContractSelected={this.props.isContractSelected}
                  handleAircraftChange={this.props.handleAircraftChange}
                  selectedContract={this.props.selectedContract}
                  availableAircraftIds={this.props.availableAircraftIds || []}
                  leg={this.props.leg}
                  isOfferFlow={this.props.isOfferFlow}
                  showAircraftInContract={this.props.showAircraftInContract}
                  passengers={(passengers || {}).passengers || []}
                  isEditFlow={this.props.isEditFlow}
                  passengersCount={this.props.leg.passengersCount}
                />
              </div>
            </div>
            {!(this.props.isOfferFlow && this.props.isOffer) ? (
              <p className="vistajet_valid_error">{errorOn.aircraft}</p>
            ) : null}
          </div>
          <p className="vistajet_valid_error">{errorOn.minHoursException}</p>
        </div>
        <PassengerCard
          ref={(abc) =>
            (
              this.popupRefs = abc
            )
          }
          passengers={(passengers || {}).passengers || []}
          activeLeg={this.props.leg}
          isLoadingPassenger={this.props.isLoadingPassenger}
          isLoading={this.props.isLoading}
          announcedPax={passengers && passengers.announcedPax}
          isEditable={this.props.isEditFlow}
          legId={guid}
          isLegCard
          gatherPassengerInfo={this.props.gatherPassengerInfo}
          handleStepperChange={this.props.handleStepperChange}
          paxNumber={addedPaxCount <= this.props.leg.passengersCount ?
            this.props.leg.passengersCount : addedPaxCount}
          maxValue={
            selectedAircraft
              ? selectedAircraft.maxNumberOfPassengers
              : 14
          }
        />
        <ConfirmationPopUp
          title="Confirmation"
          ref={(removePopup) => { this.removePopup = removePopup; }}
          id={modalId}
          leftButtonContent="No"
          rightButtonContent="Yes"
          onLeftButtonClick={() => this.removePopup.hide()}
          onRightButtonClick={() => this.handleRemoveLeg()}
          content={"Do you wish to remove this flight request?"}
        />
      </div>
    );
  }
}
