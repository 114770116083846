import { connect } from 'react-redux';
import withStore from '../components/withStore';

import TopUpHistory from '../components/AccountStatement/TopUpHistory';
import { GetTopUpHistory } from '../store/actions/TopUpHistoryActions';
import { 
  TopUpReferenceAction,
  TopUpAction,
  TopUpCancelReferenceCode,
  TopUpViewAction,
  ClearTopUpError,
} from '../store/actions/TopUpAccountActions';
import { getAccountId } from '../../src/utils/userUtils';

function mapStateToProps(state) {
  const { user } = state.user;
  const accountId = user ? getAccountId(user) : null;
  const token = user ? user.userToken : null;
  const {
    swift,
    bankName,
    currency,
    topUpAmount,
    to,
    iban,
    referenceCode,
    topUpId,
    openPopUpMessage,
    topUpReferenceLoader,
    topUpSubmittedLoader,
    topUpCancelLoader,
    disableAmountField,
    topUpError,
  } = state.topUp;

  const { topUpIds,
    topUpContent,
    membershipAccount,
    topUpHistoryLoader,
    topUpHistoryError,
  } = state.topUpHistory;

  return {
    user,
    topUpError,
    token,
    accountId,
    openPopUpMessage,
    topUpIds,
    topUpContent,
    membershipAccount,
    swift,
    bankName,
    currency,
    topUpAmount,
    to,
    iban,
    referenceCode,
    topUpId,
    topUpReferenceLoader,
    topUpHistoryLoader,
    topUpSubmittedLoader,
    topUpCancelLoader,
    disableAmountField,
    topUpHistoryError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetTopUpHistory: (token, accountId) =>
      dispatch(GetTopUpHistory(token, accountId)),

    TopUpReference: (token, accountId, transferAmount, Currency) =>
      dispatch(TopUpReferenceAction(token, accountId, transferAmount, Currency)),

    TopUpAction: (token, topUpId) =>
      dispatch(TopUpAction(token, topUpId)),

    CancelTopUp: (token, topUpId) => 
      dispatch(TopUpCancelReferenceCode(token, topUpId)),

    SetOpenPopupFalse : () =>
      dispatch(TopUpViewAction()),

    ClearTopUpError : () =>
      dispatch(ClearTopUpError()),
  };
}

const TopUpHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopUpHistory);

export default withStore(TopUpHistoryContainer);
