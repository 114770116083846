import { connect } from "react-redux";

import withStore from "../components/withStore";
import Header from "../components/Core";
import {
  loginAction,
  logoutAction,
  getAircraftsAction,
  getAccountStatusAction,
  fetchAccountStatementPdfAction,
} from "../store/actions";
function mapStateToProps(state) {
  const { user, aircrafts, PASData } = state;
  const { pdfError, loadingPdf } = PASData;
  return {
    user,
    aircrafts,
    pdfError,
    loadingPdf,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(loginAction(email, password)),
    logout: () => dispatch(logoutAction()),
    getAircrafts: () => dispatch(getAircraftsAction()),
    getAccountStatusAction: token => dispatch(getAccountStatusAction(token)),
    fetchAccountStatementPdfAction: (id, token) => dispatch(fetchAccountStatementPdfAction(id, token)),
  };
}

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export default withStore(HeaderContainer);
