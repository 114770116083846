import React from "react";
import AirportSelector from "../../shared/AirportSelector";

const determinigData = (source, leg) => {
  let data = null;
  // if (leg.prevLeg) {
    // if (source === "departure") data = leg.prevLeg.arrivalAirport;
    // else if (source === "arrival" && leg.isReturn) data = leg.prevLeg.departureAirport;
    // else if (source === "arrival" && leg.isWidgetFlow) data = leg.arrivalAirport;
  // } else {
    data = source === "departure" ? leg.departureAirport : leg.arrivalAirport;
  // }
  return data;
};

export default function AirportSelectorWrapper(props) {
  const prePopulatedData = determinigData(props.source, props.leg);
  return (
    <AirportSelector
      leg={props.leg}
      prePopulatedData={prePopulatedData}
      eTime={props.eTime}
      source={props.source}
      onAirportSelect={props.onAirportSelect}
      isAirportsFetched={props.isAirportsFetched}
      visitedAirports={props.visitedAirports}
      fetchVisitedAirports={props.fetchVisitedAirports}
    />
  );
}
