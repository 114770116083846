import { fetchSingleLegPax } from '../sources/PassengerSource';
import * as types from '../actionTypes';

export const updatePassengers = (data) => ({
  type: types.UPDATE_PASSENGER,
  data,
});
export const fetchPassengerInit = (flightLegId) => ({
  type: types.FETCH_PASSENGER_INIT,
  flightLegId,
});

export const fetchPassengerSuccess = (data, flightLegId) => ({
  type: types.FETCH_PASSENGER_SUCCESS,
  data,
  flightLegId,
});

export const fetchPassengerFail = (flightLegId) => ({
  type: types.FETCH_PASSENGER_FAIL,
  flightLegId,
});

export const startedUpdatingPassenger = () => ({
  type: types.STARTED_UPDATING_PASSENGER,
});

export const finishedUpdatingPassenger = () => ({
  type: types.FINISHED_UPDATING_PASSENGER,
});

export const fetchPassengers = (token, data) => fetchSingleLegPax(token, data);
