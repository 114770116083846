import {
  fetchLegFeedbackSource,
  saveLegFeedbackSource
} from "../sources/CustomerFeedbackSource";

import {
  FETCH_LEG_FEEDBACK_INIT,
  FETCH_LEG_FEEDBACK_SUCCESS,
  FETCH_LEG_FEEDBACK_FAIL,
  SAVE_LEG_FEEDBACK_INIT,
  SAVE_LEG_FEEDBACK_SUCCESS,
  SAVE_LEG_FEEDBACK_FAIL,
  CLEAR_FEEDBACK
} from "../actionTypes";

export const fetchLegFeedbackInit = flightLegId => ({
  type: FETCH_LEG_FEEDBACK_INIT,
  flightLegId
});

export const fetchLegFeedbackSuccess = (data, flightLegId) => ({
  type: FETCH_LEG_FEEDBACK_SUCCESS,
  data,
  flightLegId
});

export const fetchLegFeedbackFail = (data, flightLegId) => ({
  type: FETCH_LEG_FEEDBACK_FAIL,
  data,
  flightLegId
});

export const saveLegFeedbackInit = flightLegId => ({
  type: SAVE_LEG_FEEDBACK_INIT,
  flightLegId
});

export const saveLegFeedbackSuccess = (data, flightLegId) => ({
  type: SAVE_LEG_FEEDBACK_SUCCESS,
  data,
  flightLegId
});

export const saveLegFeedbackFail = flightLegId => ({
  type: SAVE_LEG_FEEDBACK_FAIL,
  flightLegId
});

export const clearFeedbackError = flightLegId => ({
  type: CLEAR_FEEDBACK,
  flightLegId
});

export const fetchLegFeedback = params => fetchLegFeedbackSource(params);
export const saveLegFeedback = params => saveLegFeedbackSource(params);
