import React, { Component } from 'react';
import Select from 'react-select';
import SpinnerLoader from '../shared/spinnerLoader';
import ConfirmationPopUp from '../shared/ConfirmationPopUpNew';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import countryData from '../../constants/CountryConstants';
import validateContractingFormDetails from '../../utils/Validators/ContractingDetailFormValidator';
import { convertToTitleCase } from '../../utils/stringUtils';
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants';
import { CONFIRMATION_TITLE, CONFIRMATION_MESSAGE, LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT } from '../../constants/DirtyPopupConstant';
import { getTruncatedCountryName } from 'utils/countryUtils';
import { isMobile } from 'react-device-detect';
class AddBillingAddressModal extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      firstName: user.firstName,
      lastName: user.lastName,
      address: '',
      country: {},
      city: '',
      state: '',
      postCode: '',
      vatNumber: '',
      errors: {},
      open: false,
    };
  }

  handleFirstNameChange = (e) => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, true);
    this.setState({ firstName: e.target.value });
  };

  handleLastNameChange = (e) => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, true);
    this.setState({ lastName: e.target.value });
  };

  handleAddress = (e) => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, true);
    this.setState({ address: e.target.value });
  }

  handleCountryChange = (value) => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, true);
    let newValue = value;
    if (isMobile) {
      newValue.label = getTruncatedCountryName(value.value, value.code);
    }
    this.setState({ country: newValue });
  };

  handleCityChange = (value) => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, true);
    this.setState({ city: value.target.value });
  };

  handleStateChange = (value) => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, true);
    this.setState({ state: value.target.value });
  };

  generateCountries = () => {
    return countryData.map((country) => {
      const flagIcon = `flag-icon-style flag-icon-${country.TWO_LETTER_CODE.toLowerCase()}`;
      const countryLabel = convertToTitleCase(country.COUNTRY_NAME);
      return {
        value: country.COUNTRY_NAME,
        label: <div className="country-list-input"><span className={flagIcon} ></span><span className="country-name">{countryLabel}</span></div>, // eslint-disable-line
        code: country.THREE_LETTER_CODE,
      };
    });
  };

  handlePostCode = (value) => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, true);
    this.setState({ postCode: value.target.value });
  };

  handleVatNumber = (value) => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, true);
    this.setState({ vatNumber: value.target.value });
  };

  validateForm = () => {
    const {
      firstName,
      lastName,
      address,
      country,
      city,
      state,
      postCode
    } = this.state;

    const countryValue = country.value || country;
    const cityValue = city.value || city;
    const res = validateContractingFormDetails({
      firstName,
      lastName,
      address,
      countryValue,
      cityValue,
      state,
      postCode,
    }, true);
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      this.handleNext();
    }
  }

  handleNext = () => {
    const {
      firstName,
      lastName,
      address,
      country,
      city,
      state,
      postCode,
      vatNumber,
    } = this.state;
    const { saveBillingAddress } = this.props;
    const addressableName = firstName + ' ' + lastName;
    const billingAddress = address;
    const countryCode = country.code;
    const addressObj = { addressableName, billingAddress, countryCode, city, state, postCode, vatNumber };
    saveBillingAddress(addressObj);
    this.clearState();
  };

  clearState = () => {
    dirtystateSingleTon.setDirty(SPAConstants.BillingAddress, false);
    const { firstName, lastName } = this.props.user
    this.setState({
      firstName,
      lastName,
      address: '',
      country: {},
      city: '',
      state: '',
      postCode: '',
      vatNumber: '',
      errors: {},
      open: false,
    })
  }

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  removeCountryError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors.country = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  }

  removeCityError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors.city = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  }

  show = () => {
    this.setState({ open: true });
  };

  hide = () => {
    const isBillingModalDirty = dirtystateSingleTon.getDirty(SPAConstants.BillingAddress);
    if (isBillingModalDirty) {
      this.billingConfirmPopup.show();
    } else {
      this.closeBillingModal();
    }
  }

  closeBillingModal = () => {
    this.billingConfirmPopup.hide();
    this.clearState();
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    };
  }
  disableButton = () => {
    const {
      firstName,
      lastName,
      address,
      country,
      city,
      state,
      postCode
    } = this.state;
    if (firstName && lastName && address && country && city && state &&
      postCode && country.value) {
      return false;
    } else {
      return true;
    }
  }
  renderForm = () => {
    const countries = this.generateCountries();
    const {
      firstName,
      lastName,
      address,
      country,
      city,
      state,
      errors,
      vatNumber,
      postCode,
    } = this.state;
    const { isBillingAddressLoading } = this.props;
    return (
      <div>
        <div className="vistajet_modal_header_fix">
          <h4 className="uk-text-center">Add Billing Address</h4>
        </div>

        <div className="vistajet_modal_content vistajet_primary_form uk-width-1-1 uk-margin-medium-top">
          <div className="uk-grid-medium uk-margin-small-top uk-margin-small-bottom" data-uk-grid>
            <div className="uk-width-1-2@s uk-margin-remove">
              <div className="vistajet_input_wrapper">
                <input
                  type="text"
                  name="firstName"
                  onChange={this.handleFirstNameChange}
                  onFocus={this.removeErrors}
                  value={firstName}
                />
                <label htmlFor="firstName">
                  Name
                    </label>
                {
                  errors.errors && errors.errors.firstName && (
                    <p>
                      {errors.errors.firstName}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-2@s uk-margin-remove">
              <div className="vistajet_input_wrapper">
                <input
                  type="text"
                  name="lastName"
                  onChange={this.handleLastNameChange}
                  onFocus={this.removeErrors}
                  value={lastName}
                />
                <label htmlFor="lastName">
                  Surname
                </label>
                {
                  errors.errors && errors.errors.lastName && (
                    <p>
                      {errors.errors.lastName}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-1@s uk-margin-remove">
              <div className="vistajet_input_wrapper">
                <input
                  id="address"
                  type="text"
                  name="address"
                  onChange={this.handleAddress}
                  onFocus={this.removeErrors}
                  value={address}
                />
                <label htmlFor="address">
                  Address
                </label>
                {
                  errors.errors && errors.errors.address && (
                    <p>
                      {errors.errors.address}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-2@s uk-margin-remove">
              <div className="vistajet_input_wrapper">
                <input
                  id="city"
                  type="text"
                  name="city"
                  onChange={this.handleCityChange}
                  onFocus={this.removeErrors}
                  value={city}
                />
                <label htmlFor="city">
                  City
                </label>
                {
                  errors.errors && errors.errors.city && (
                    <p>
                      {errors.errors.city}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-2@s uk-margin-remove">
              <div className="vistajet_input_wrapper">
                <input
                  id="state"
                  type="text"
                  name="state"
                  onChange={this.handleStateChange}
                  onFocus={this.removeErrors}
                  value={state}
                />
                <label htmlFor="city">
                  State
                </label>
                {
                  errors.errors && errors.errors.state && (
                    <p>
                      {errors.errors.state}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-1@s uk-margin-remove">
              <div className="vistajet_input_wrapper">
                <Select
                  classNamePrefix="react-select"
                  onChange={this.handleCountryChange}
                  onFocus={this.removeCountryError}
                  options={countries}
                  value={country}
                  id="country"
                  valueKey="value"
                  labelKey="label"
                  className="vistajet_select_input"
                />
                <label>
                  Country
                </label>
                {
                  errors.errors && errors.errors.country && (
                    <p>
                      {errors.errors.country}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-2@s uk-margin-remove">
              <div className="vistajet_input_wrapper">
                <input
                  type="text"
                  id={'postCode'}
                  name={'postCode'}
                  onChange={this.handlePostCode}
                  onFocus={this.removeErrors}
                  value={postCode}
                />
                <label htmlFor="postcode">
                  Postcode
                </label>
                {
                  errors.errors && errors.errors.postalCode && (
                    <p>
                      {errors.errors.postalCode}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-2@s uk-margin-remove">
              <div className="vistajet_input_wrapper">
                <input
                  type="text"
                  placeholder="(Optional)"
                  onChange={this.handleVatNumber}
                  value={vatNumber}
                />
                <label htmlFor="vatNumber">
                  Vat Number
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="vistajet_modal_footer">
          <button
            className="uk-button uk-button-default uk-width-1-1 uk-text-center"
            disabled={isBillingAddressLoading || this.disableButton()}
            onClick={this.validateForm}
          >
            {'Save Changes'}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      isCenter,
      closeOnEsc,
      closeOnOverlayClick,
      onExited,
      styles,
      showCloseIcon,
      closeIconSize,
      closeIconSvgPath,
      focusTrapped,
      id,
      isBillingAddressLoading,
    } = this.props;
    const { open } = this.state;
    return (
      <Modal
        open={open}
        classNames={{
          overlay: 'vistajet_modal vistajet_modal_large vistajet_billing_address_modal',
          modal: 'vistajet_modal_body vistajet_react_modal',
          closeButton: 'vistajet_modal_close',
          closeIcon: '<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg>'
        }}
        closeOnOverlayClick={closeOnOverlayClick}
        onClose={this.hide}
        center={isCenter}
        closeOnEsc={closeOnEsc}
        onExited={onExited}
        styles={styles}
        showCloseIcon={showCloseIcon}
        closeIconSize={closeIconSize}
        closeIconSvgPath={closeIconSvgPath}
        focusTrapped={focusTrapped}
      >
        <div id={id}>
          {isBillingAddressLoading ? <SpinnerLoader /> : this.renderForm()}
        </div>
        <ConfirmationPopUp
          ref={billingConfirmPopup => { this.billingConfirmPopup = billingConfirmPopup }}
          title={CONFIRMATION_TITLE}
          content={CONFIRMATION_MESSAGE}
          leftButtonContent={LEFT_BUTTON_TEXT}
          rightButtonContent={RIGHT_BUTTON_TEXT}
          onRightButtonClick={() => this.closeBillingModal()}
          onLeftButtonClick={() => this.billingConfirmPopup.hide()}
        />
      </Modal>
    );
  }
}

AddBillingAddressModal.defaultProps = {
  isCenter: true,
  closeOnEsc: false,
  closeOnOverlayClick: false,
  showCloseIcon: true,
  focusTrapped: false,
  // closeIconSize: 30,
  onExited: () => { },
  // closeIconSvgPath: <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg>,

};
export default AddBillingAddressModal;
