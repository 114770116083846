export const legStatus = {
  QUOTED: 'Quoted',
  OPTION: 'Option',
  CONFIRMED: 'Confirmed',
  READY: 'Ready',
  FLYING: 'Flying',
  LANDED: 'Landed',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  CANCELLATION_PENDING: 'Cancellation Pending',
  NOSHOW: 'No Show',
  UNCONFIRMED: 'Unconfirmed',
  REQUESTED: 'Requested',
};

export const quoteQueryEmail = 'sales@vistajet.com';

export const quoteQueryEmailSubject = 'Quote query';
