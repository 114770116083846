import React, { Component } from 'react';
import moment from 'moment';
import
countryData,
{
  COUNTRY_CODE_TAIWAN_ISO2,
  COUNTRY_CODE_CHINA_ISO2,
} from '../../../constants/CountryConstants';
import { PaxOperationCode } from '../../../constants/ApiConstants';
import { dateFormats } from '../../../utils/dateUtils';
import MessagePopUp from '../../shared/MessagePopUp';
import ConfirmationPopUp from '../../shared/ConfirmationPopUpNew';
import SpinnerLoader from '../../shared/spinnerLoader';
import { isMobile } from 'react-device-detect';

export default class PassportListingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupMessage: '',
    };
    this.selectedId = null;
    this.defaultId = null;
  }

  componentDidMount() {
    this.checkIfDefaultPassportExpired();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user.isProfileUpdateError
      && this.props.user.profileUpdateError !== nextProps.props.user.profileUpdateError
    ) {
      window.UIkit.modal('#PassportListingErrorPopUp').show();
    }
  }

  getPassportFlag = (passport, id = null) => {
    const paxId = id;
    const isoPassportCountry = ((passport || {}).content[paxId] || {}).isoPassportCountry;
    const flagData = (countryData || []).find(
      item => (item.THREE_LETTER_CODE || '').toUpperCase()
        === (isoPassportCountry || '').toUpperCase(),
    );
    let flagIso2 = flagData && flagData.TWO_LETTER_CODE;
    if ((flagIso2 || '').toUpperCase() === COUNTRY_CODE_TAIWAN_ISO2) flagIso2 = COUNTRY_CODE_CHINA_ISO2;
    return `flag-icon-style flag-icon-${(flagIso2 || '').toLowerCase()}`;
  }

  formatExpiryDate = (date) => {
    return moment(date).format(dateFormats.DDMMMYYYY);
  }

  closePopup = (ref) => {
    ref.hide();
  }

  handleApiErrors = (err) => {
    this.setState({
      popupMessage: err,
    });
  };

  updatePassportDetails = (id, isDefaultEdit) => {
    const { toggleIsPassportAddEditDetails } = this.props;
    toggleIsPassportAddEditDetails(
      false,
      true,
      id,
      isDefaultEdit,
    );
  }

  normalizeAndCombinePassportPayload = (
    newPayload,
    oldPayload,
    isDefaultDelete = false,
    isNewDefault = false,
  ) => {
    const passportPayload = [];
    let payloadOfDefault = null;
    if (oldPayload) {
      let defaultValue = oldPayload.isDefault;
      if (isDefaultDelete) {
        defaultValue = true;
      }
      if (isNewDefault) {
        defaultValue = false;
      }
      payloadOfDefault = {
        id: oldPayload.id,
        expiresOnDate: oldPayload.expiresOnDate,
        isoPassportCountry: oldPayload.isoPassportCountry,
        isoIssuedByCountry: oldPayload.isoIssuedByCountry,
        displayName: oldPayload.displayName,
        passportNumber: oldPayload.passportNumber,
        isDefault: defaultValue,
        isAdded: oldPayload.isAdded,
        isUpdated: oldPayload.isUpdated,
        isDeleted: oldPayload.isDeleted,
        isActive: oldPayload.isActive,
        isInvalid: oldPayload.isInvalid,
        isOnHands: oldPayload.isOnHands,
      };
      passportPayload.push(payloadOfDefault);
    }
    passportPayload.push(newPayload);
    return passportPayload;
  }

  deletePassport = (id) => {
    const {
      user,
    } = this.props.user;

    const {
      updateUserProfileAction,
    } = this.props;

    const userPayload = {
      firstName: user.firstName,
      lastName: user.lastName,
      dateOfBirth: user.dateOfBirth,
      phoneNumber: user.phoneNumber,
      isPhoneNumberValid: true,
      email: user.username,
    };

    const passportPayload = {
      id,
      isAdded: false,
      isUpdated: false,
      isDeleted: true,
    };

    let defaultPaxId;
    let isDefaultDelete = false;
    if (user.passports.defaultId === id) { // deleting the default passport
      isDefaultDelete = true;
      if (user.passports.ids[0] !== id) {
        defaultPaxId = user.passports.ids[0];
      }
      else if (user.passports.ids.length > 1) {
        defaultPaxId = user.passports.ids[1];
      } else {
        defaultPaxId = user.passports.defaultId;
      }
    } else {
      defaultPaxId = user.passports.defaultId; // deleting non-default passport
    }
    const doPassportExist = user.passports.ids.length > 1;

    updateUserProfileAction(
      userPayload,
      user,
      this.normalizeAndCombinePassportPayload(
        passportPayload,
        doPassportExist
          ? user.passports.content[defaultPaxId]
          : null,
        isDefaultDelete,
      ),
      -1,
      PaxOperationCode.Delete, // Delete Op code
    )
      .then(() => { }, this.handleApiErrors);
    this.closePopup(this.deletePopUp);
  }

  checkIfDefaultPassportExpired = () => {
    const {
      user,
    } = this.props.user;
    if (user.passports.ids.length > 0) {
      const defaultId = user.passports.defaultId
        ? user.passports.defaultId
        : user.passports.ids[0];
      if (
        moment((user.passports.content[defaultId] || {}).expiresOnDate, dateFormats.YYYY_MM_DD_WITH_TIME)
          .isBefore(moment())) {
        window.UIkit.modal('#passportExpired').show();
      }
    }
  }

  checkSetAsDefault = (id,dropdownId) => {
    const {
      user,
    } = this.props.user;
    const expiresDate = (user.passports.content[id] || {}).expiresOnDate;
    const isExpired = moment(expiresDate, dateFormats.YYYY_MM_DD_WITH_TIME).isBefore(moment());
    if (isMobile) {
      window.UIkit.dropdown(`#${dropdownId}`).hide();
    }
      if (!isExpired) {
        this.setAsNewDefault(id);
      } else {
        this.defaultId = id;
        this.expiredPopup.show();
      }
    }

    setAsNewDefault = (id) => {
      const {
        user,
      } = this.props.user;

      const {
        updateUserProfileAction,
      } = this.props;

      const userPayload = {
        firstName: user.firstName,
        lastName: user.lastName,
        dateOfBirth: user.dateOfBirth,
        phoneNumber: user.phoneNumber,
        isPhoneNumberValid: true,
        email: user.username,
      };

      const passportPayload = {
        id,
        isAdded: false,
        isUpdated: true,
        isDeleted: false,
        isDefault: true,
        expiresOnDate: (user.passports.content[id] || {}).expiresOnDate,
        isoPassportCountry: user.passports.content[id].isoPassportCountry,
        isoIssuedByCountry: user.passports.content[id].isoIssuedByCountry,
        displayName: user.passports.content[id].displayName,
        passportNumber: user.passports.content[id].passportNumber,
        isActive: user.passports.content[id].isActive,
        isInvalid: user.passports.content[id].isInvalid,
        isOnHands: user.passports.content[id].isOnHands,
      };

      const doPassportExist = user.passports.ids.length > 1;
      const defaultPaxId = user.passports.defaultId;

      const isNewDefault = true;
      updateUserProfileAction(
        userPayload,
        user,
        this.normalizeAndCombinePassportPayload(
          passportPayload,
          doPassportExist
            ? user.passports.content[defaultPaxId]
            : null,
          false,
          isNewDefault,
        ),
        -1,
        PaxOperationCode.ChangeDefault, // Change default Op code
      )
        .then(() => { }, this.handleApiErrors);
        this.closePopup(this.expiredPopup);
    }

    renderPassportListing = () => {
      const {
        toggleIsPassportAddEditDetails,
      } = this.props;
      const { user, isProfileUpdateLoading } = this.props.user;
      const { popupMessage } = this.state;
      return (
        <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card">
          {
            (isProfileUpdateLoading) && (
              <div className="vistajet_spinner">
                <SpinnerLoader />
              </div>
            )
          }
          <div class="vistajet_head_column">
            <div class="uk-clearfix">
              <div class="uk-float-left">
                <h3 class="vistajet_right_tab_heading">Manage Passports</h3>
              </div>
            </div>
          </div>
          <div className="vistajet_profile_details_column">
            <div className="vistajet_passport_details">
              {user && this.renderDefaultPassport()}
            </div>
          </div>
          {
            (user && user.passports.ids.length > 1) && (
              <div className="vistajet_profile_details_column">
                <div className="vistajet_passport_details">
                  <div className="uk-flex uk-grid-small" data-uk-grid>
                    <div className="uk-width-1-1">
                      <div className='vistajet_passpport_list_content'>
                        <div className="vistajet_label">
                          <span>Other Passports</span>
                        </div>
                        {this.renderOtherPassports()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          <div class="uk-grid uk-margin-remove vistajet_my_details" uk-grid>
            <div class="uk-width-expand@s vistajet_add_button_wrapper">
              <a
                className="vistajet_button_default"
                onClick={() => toggleIsPassportAddEditDetails(
                  true,
                  false,
                )}
              >
                <span>Add Passport</span></a>
            </div>
          </div>

          <ConfirmationPopUp
            id="areYouSureYouWantToDelete"
            ref={(deletePopUp) => { this.deletePopUp = deletePopUp; }}
            leftButtonContent="Delete"
            onLeftButtonClick={() => this.deletePassport(this.selectedId)}
            rightButtonContent="Cancel"
            onRightButtonClick={() => this.closePopup(this.deletePopUp)}
            content="Do you want to delete the passport?"
            title="Are you sure?"
          />
          <ConfirmationPopUp
            id="areYouSureYouWantToSetExpiredAsDefault"
            ref={(expiredPopup) => { this.expiredPopup = expiredPopup; }}
            leftButtonContent="Cancel"
            onLeftButtonClick={() => this.closePopup(this.expiredPopup)}
            rightButtonContent="Continue"
            onRightButtonClick={() => this.setAsNewDefault(this.defaultId)}
            content="This passport is expired. Do you want to proceed?"
            title="Attention"
          />
          <MessagePopUp
            id="PassportListingErrorPopUp"
            content={popupMessage}
          />
          <MessagePopUp
            id="passportExpired"
            content="Your default passport has expired"
          />
        </div>
      );
    };

    showDeletePrompt = (id, dropdownId) => {
      if (isMobile) {
        window.UIkit.dropdown(`#${dropdownId}`).hide();
      }
      this.selectedId = id;
      this.deletePopUp.show();
    }

    renderDefaultPassport = () => {
      const { user } = this.props.user;
      const dropdownId = "vistajet_card_dropdown_default";
      if (user && user.passports.ids.length === 0) {
        return (
          <h5 className="vistajet_userprofile_credit_card_error">No passports saved</h5>
        );
      }
      const defaultPassportId = user.passports.defaultId
        ? user.passports.defaultId
        : user.passports.ids[0];
      const defaultFlagIcon = this.getPassportFlag(user.passports, defaultPassportId);
      const defaultExpiryData = this.formatExpiryDate(
        (user.passports.content[defaultPassportId] || {}).expiresOnDate,
      );
      return (
        <div className="uk-flex uk-grid-small" data-uk-grid>
          <div className="uk-width-1-1">
            <div className='vistajet_passpport_list_content'>
              <div className="vistajet_label">
                <span>Default Passport</span>
              </div>
              <div className="vistajet_details_hover vistajet_passport_buttons_list">
                <span className={defaultFlagIcon} />
                <span className="vistajet_text uk-text-middle vistajet_passport_number">
                  {(user.passports.content[defaultPassportId] || {}).passportNumber}
                </span>
                <span className="vistajet_text uk-text-middle vistajet_expires_number">
                  <small>Expires {defaultExpiryData}</small>
                </span>
                <div className="vistajet_passport_hover_action">
                  <div className="uk-visible@m">
                    <span
                      onClick={() => this.updatePassportDetails(defaultPassportId, true)}
                      data-index={defaultPassportId}
                    >
                      <img src="/legacy/static/img/icons/vistajet_edit_red_icon.svg" alt="update" />
                    </span>
                    <span
                      onClick={() => this.showDeletePrompt(defaultPassportId)}
                      data-index={defaultPassportId}
                    >
                      <img src="/legacy/static/img/icons/vistajet_close_icon.svg" alt="delete" />
                    </span>
                  </div>
                  <ul class="uk-iconnav uk-flex-right uk-hidden@m">
                    <li><button class="vistajet_more_icon" type="button"></button></li>
                    <div id={dropdownId} uk-dropdown="mode: click; pos: bottom-right; delay-hide: 0" class="uk-margin-remove vistajet_iconnav_dropdown">
                      <ul class="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
                        <li className="uk-text-truncate">
                          <a
                            onClick={() => this.updatePassportDetails(defaultPassportId, true)}
                            data-index={defaultPassportId}>
                            <img src="/legacy/static/img/icons/vistajet_icon_passport_edit.svg" alt="update" />
                            <span>Edit</span>
                          </a>
                        </li>
                        <li className="uk-text-truncate">
                          <a
                            onClick={() => this.showDeletePrompt(defaultPassportId, dropdownId)}
                            data-index={defaultPassportId}>
                            <img src="/legacy/static/img/icons/vistajet_icon_passport_delete.svg" alt="delete" />
                            <span>Delete</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    renderOtherPassports = () => {
      const { user } = this.props.user;
      const defaultPassportId = user.passports.defaultId;
      return user.passports.ids.map((passportId, index) => {
        if (passportId != defaultPassportId) {
          const dropdownId = `vistajet_card_dropdown_${index}`;
          const flagIcon = this.getPassportFlag(user.passports, passportId);
          const expiryData = this.formatExpiryDate(
            (user.passports.content[passportId] || {}).expiresOnDate,
          );
          return (
            <div className="vistajet_details_hover">
              <span className={flagIcon} />
              <span className="vistajet_text uk-text-middle vistajet_passport_number">
                {user.passports.content[passportId].passportNumber}
              </span>
              <span className="vistajet_text uk-text-middle vistajet_expires_number">
                <small>Expires {expiryData}</small>
              </span>
              <div className="vistajet_passport_hover_action vistajet_passport_buttons_list">
                <div className="uk-visible@m">
                  <span>
                    <a
                      onClick={() => this.checkSetAsDefault(passportId, dropdownId)}
                      data-index={passportId}
                      style={{ textDecoration: 'underline', color: '#C35C69', fontSize: 14, paddingRight: 8 }}
                    >
                      Set as Default
                  </a>
                  </span>
                  <span
                    onClick={() => this.updatePassportDetails(passportId, false)}
                    data-index={passportId}
                  >
                    <img src="/legacy/static/img/icons/vistajet_edit_red_icon.svg" alt="update" />
                  </span>
                  <span
                    onClick={() => this.showDeletePrompt(passportId)}
                    data-index={passportId}
                  >
                    <img src="/legacy/static/img/icons/vistajet_close_icon.svg" alt="delete" />
                  </span>
                </div>
                <ul class="uk-iconnav uk-flex-right uk-hidden@m">
                  <li><button class="vistajet_more_icon" type="button"></button></li>
                  <div id={dropdownId} uk-dropdown="mode: click; pos: bottom-right; delay-hide: 0" class="uk-margin-remove vistajet_iconnav_dropdown">
                    <ul class="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
                      <li className="uk-text-truncate"
                        onClick={() => this.checkSetAsDefault(passportId,dropdownId)}
                        data-index={passportId}>
                        <a>
                          <img src="/legacy/static/img/icons/vistajet_icon_passport_default.svg" alt="" />
                          <span>Set As Default</span>
                        </a>
                      </li>
                      <li className="uk-text-truncate"
                        onClick={() => this.updatePassportDetails(passportId, false)}
                        data-index={passportId}>
                        <a>
                          <img src="/legacy/static/img/icons/vistajet_icon_passport_edit.svg" alt="" />
                          <span>Edit</span>
                        </a>
                      </li>
                      <li className="uk-text-truncate"
                        onClick={() => this.showDeletePrompt(passportId, dropdownId)}
                        data-index={passportId}>
                        <a>
                          <img src="/legacy/static/img/icons/vistajet_icon_passport_delete.svg" alt="" />
                          <span>Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
          );
        }
      });
    }

    render() {
      return this.renderPassportListing();
    }
  }
