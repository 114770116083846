import * as type from '../actionTypes';

const initialState = {
	groundServices: [],
	groundServicesAdd: {},
	isLoadingGroundServices: false,
	isLoadingAddGroundServices: false,
	isErrorGroundTransport: '',
};

export default function launchReducer(state = initialState, action) {
	switch (action.type) {
		case type.FETCH_GROUND_SERVICES_INIT: {
			return Object.assign({}, state, {
				isLoadingGroundServices: true,
				groundServices: {},
				isErrorGroundTransport: '',
			});
		}

		case type.FETCH_GROUND_SERVICES_SUCCESS: {
			return Object.assign({}, state, {
				isLoadingGroundServices: false,
				groundServices: action.data,
				isErrorGroundTransport: ''
			});
		}

		case type.FETCH_GROUND_SERVICES_FAIL: {
			return Object.assign({}, state, {
				isLoadingGroundServices: false,
				isErrorGroundTransport: action.data,
			});
		}
		
		case type.CLEAR_ERROR_FETCH_ITINERARY: {
			return Object.assign({}, state, {
				isErrorGroundTransport: '',
			});
		}
		default:
			return state;
	}
}
