import { guaranteeArray } from '../utils/arrayUtils';

export const normalizePushNotifications = (notifications) => ({
  cities: ((notifications.response.responseBody || {}).cities || {}).city
     && guaranteeArray(((notifications.response.responseBody || {}).cities || {}).city).reduce(
       ((current, city) => {
         const curr = current;
         curr.ids.push(city.cityId);
         curr.content[city.cityId] = {
           cityId: city.cityId,
           enabled: true,
         };
         return curr;
       }), { ids: [], content: {} },
     ),
  notifications: ((notifications.response.responseBody || {}).notifications || {}).notification
    && ((notifications.response.responseBody || {}).notifications || {}).notification.reduce(
      ((current, notification) => {
        const curr = current;
        curr.ids.push(notification.notificationId);
        curr.content[notification.notificationId] = notification;
        return curr;
      }), { ids: [], content: {} }),
});

export const normalizeRequestUpdateNotifications = (notifications, cities) => {
  const notificationPayload = notifications.ids.map(id => (
    `<notification>
      <notificationId>${notifications.content[id].notificationId}</notificationId>
      <enabled>${notifications.content[id].enabled}</enabled>
    </notification>`
  ));
  const cityPayload = cities.ids.map(id => (
    `<city>
      <cityId>${cities.content[id].cityId}</cityId>
      <enabled>${cities.content[id].enabled}</enabled>
    </city>`
  ));
  return (
    `<notificationSettings>
      <notifications>
        ${notificationPayload.join('')}
      </notifications>
      <cities>
        ${cityPayload.join('')}
      </cities>
     </notificationSettings>`
  );
};
