import React, { Component } from 'react';
import CreditCardListingCard from './CreditCardListingCard';
import CreditCardAddEditDetailsForm from './CreditCardAddEditDetailForm';
import MessagePopUp from '../../shared/MessagePopUp';

export default class CreditCardListingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreditCardDetailAddEdit: false,
      isAdd: false,
      isEdit: false,
      editFieldId: null,
      popupMessage: '',
      isDefaultEdit: false,
    };
  }

  componentDidMount() {
    const { getCreditCardList } = this.props;
    getCreditCardList().then(() => {}, this.handleApiErrors);
  }

  handleApiErrors = (err) => {
    this.setState({
      popupMessage: err,
    }, () => {
      window.UIkit.modal('#AddEditCreditCardListingErrorPopUp').show();
    });
  }

  toggleIsCreditCardAddEditDetails = (isAdd = false, isEdit = false, editId = null, isDefaultEdit = false) => {
    this.setState({
      isCreditCardDetailAddEdit: !this.state.isCreditCardDetailAddEdit,
      isAdd,
      isEdit,
      editFieldId: editId,
      isDefaultEdit,
    });
  }

  renderCards = () => {
    const {
      isCreditCardDetailAddEdit,
      isAdd,
      isEdit,
      editFieldId,
      popupMessage,
      isDefaultEdit,
    } = this.state;
    const {
      user,
      addCreditCardAction,
      creditCards,
      deleteCreditCardAction,
    } = this.props;
    if (isCreditCardDetailAddEdit) {
      return (
        <div>
          <CreditCardAddEditDetailsForm
            toggleIsCreditCardAddEditDetails={this.toggleIsCreditCardAddEditDetails}
            isAdd={isAdd}
            isEdit={isEdit}
            editFieldId={editFieldId}
            addCreditCardAction={addCreditCardAction}
            isDefaultEdit={isDefaultEdit}
            {...this.props}
          />
          <MessagePopUp
            id="AddEditCreditCardErrorPopUp"
            content={popupMessage}
          />
        </div>
      );
    }
    return (
      <div>
        <CreditCardListingCard
          toggleIsCreditCardAddEditDetails={this.toggleIsCreditCardAddEditDetails}
          user={user}
          addCreditCardAction={addCreditCardAction}
          deleteCreditCardAction={deleteCreditCardAction}
          creditCards={creditCards}
          {...this.props}
        />
        <MessagePopUp
          id="AddEditCreditCardListingErrorPopUp"
          content={popupMessage}
        />
      </div>
    );
  }

  render() {
    return this.renderCards();
  }
}
