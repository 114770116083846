import {
  validateFirstName,
  validateLastName,
  validateSalutation,
  addressValidator,
  cityValidator,
  countryValidator,
  stateValidator,
  zipCodeValidator,
} from './Validators';

export default function validateContractingFormDetails(obj, isBillingAddress = false) {
  const addressResponse = addressValidator(obj.address);
  const cityResponse = cityValidator(obj.cityValue);
  const countryResponse = countryValidator(obj.countryValue);
  const salutaionResponse = isBillingAddress ? {} : validateSalutation(obj.salutation);
  const firstNameResponse = validateFirstName(obj.firstName);
  const lastNameResponse = validateLastName(obj.lastName);
  const stateResponse = isBillingAddress ? stateValidator(obj.state) : {};
  const postalCodeResponse = isBillingAddress ? zipCodeValidator(obj.postCode) : {};

  const res = {
    errors: {},
  };
  if (addressResponse.isError) {
    res.isError = addressResponse.isError;
    res.errors.address = addressResponse.message;
  }
  if (cityResponse.isError) {
    res.isError = cityResponse.isError;
    res.errors.city = cityResponse.message;
  }
  if (countryResponse.isError) {
    res.isError = countryResponse.isError;
    res.errors.country = countryResponse.message;
  }
  if (salutaionResponse.isError) {
    res.isError = salutaionResponse.isError;
    res.errors.salutation = salutaionResponse.message;
  }
  if (firstNameResponse.isError) {
    res.isError = firstNameResponse.isError;
    res.errors.firstName = firstNameResponse.message;
  }
  if (lastNameResponse.isError) {
    res.isError = lastNameResponse.isError;
    res.errors.lastName = lastNameResponse.message;
  }
  if (stateResponse.isError) {
    res.isError = stateResponse.isError;
    res.errors.state = stateResponse.message;
  }
  if (postalCodeResponse.isError) {
    res.isError = postalCodeResponse.isError;
    res.errors.postalCode = postalCodeResponse.message;
  }
  return res;
}
