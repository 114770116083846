import {
  hasVjDirectAccount,
  accountMembershipStatus,
  membershipType,
  accessType,
  normalizePhoneNumber,
} from '../utils/sourceUtils';
import { normalizePassports } from '../utils/passportUtils';

export default function normalizeAccountStatus(user) {
  const {
    addressSaved,
    baseCompanyEmail,
    baseCompanyNumber,
    customerId,
    enabled,
    firstName,
    gender,
    genderId,
    id,
    isChangedPassword,
    lastName,
    personId,
    username,
    salutation,
    verificationStatusCode,
    verificationStatusDescription,
    accounts,
    personPhoneNumbers,
    userPreferences,
    imageURL,
    dateOfBirth,
    passport,
    canAccessStatement,
  } = user;
  return {
    addressSaved,
    baseCompanyEmail,
    baseCompanyNumber,
    customerId,
    enabled,
    firstName,
    gender,
    genderId,
    id,
    isChangedPassword,
    lastName,
    personId,
    username,
    salutation,
    verificationStatusCode,
    verificationStatusDescription,
    accounts,
    canAccessStatement,
    accessType: accounts && accessType(accounts) || 0,
    membershipTypeId: accounts && membershipType(accounts) || '',
    membershipStatus: accounts && accountMembershipStatus(accounts) || '',
    hasVjDirectAccount: accounts && hasVjDirectAccount(accounts) || '',
    personPhoneNumbers: personPhoneNumbers && personPhoneNumbers.length &&
      normalizePhoneNumber(personPhoneNumbers[0]),
    userPreferences,
    imageURL,
    dateOfBirth,
    passports: normalizePassports(passport),
  };
};
