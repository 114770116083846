/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  PAF_PAGE_URL, UPCOMING_ITINERARIES_PAGE_URL,
  PAST_ITINERARIES_PAGE_URL,
  CANCELLED_ITINERARIES_PAGE_URL,
  PAS_PAGE_URL,
  DIRECT_ACCOUNT_BALANCE_PAGE_URL,
  TOPUP_ACCOUNT_PAGE_URL,
  TOPUP_HISTORY_PAGE_URL,
  USER_PROFILE_PAGE,
} from '../configs/constants';
import { isFSP, isFspDirect, isAdhoc, isGuest, isVJ2, canAccessStatement } from '../utils/userUtils';

export const redirect = (URL) => {
  window.open(URL, "_self");
}

class Drawer extends React.Component {
  render() {
    let props = this.props;
    return (
      <React.Fragment>
        <li class="uk-parent">
          <a href="#">
            My Account
            <span uk-icon="icon: chevron-right"></span>
          </a>
          <ul className="uk-nav-sub">
            <li><a class="uk-nav-back">
              <span uk-icon="icon: chevron-left"></span>
              Back
            </a>
            </li>
            <li>
              <a href={USER_PROFILE_PAGE.url}>Manage Account</a>
            </li>
            {(isFSP(props.user) || isFspDirect(props.user)) &&
              <li>
                <a href={PAF_PAGE_URL}>Plan A Flight</a>
              </li>
            }
            {/* 
            {(isAdhoc(props.user) || isGuest(props.user) || isVJ2(props.user)) &&
              <li>
                <a href={RAF_PAGE_URL}>
                  Request Quote
              </a>
              </li>
            }
            {(isAdhoc(props.user) || isVJ2(props.user)) &&
              <li class="uk-parent">
                <a href="#">My Quotes</a>
                <ul class="uk-nav-sub">
                  <li>
                    <a href={ACTIVE_QUOTES_PAGE_URL}>
                      Active Quotes
                  </a>
                  </li>
                  <li>
                    <a href={EXPIRED_QUOTES_PAGE_URL}>
                      Expired Quotes
                  </a>
                  </li>
                </ul>
              </li>}
              */}
            {!isGuest(props.user) && (
              <li>
                <a href="#">My Itineraries</a>
                <ul>
                  <li>
                    <a href={UPCOMING_ITINERARIES_PAGE_URL}>
                      Upcoming
                    </a>
                  </li>
                  <li>
                    <a href={PAST_ITINERARIES_PAGE_URL}>
                      Past
                    </a>
                  </li>
                  <li>
                    <a href={CANCELLED_ITINERARIES_PAGE_URL}>
                      Cancelled
                    </a>
                  </li>
                </ul>
              </li>)
            }
            {(isFSP(props.user) || isFspDirect(props.user)) && canAccessStatement(props.user) &&
              <li>
                <a href={PAS_PAGE_URL}>
                  Program Account
                </a>
              </li>}
            {(isFspDirect(props.user) || isVJ2(props.user) || isAdhoc(props.user)) &&
              <li>
                <a href="#">Direct Account</a>
                <ul>
                  <li>
                    <a href={DIRECT_ACCOUNT_BALANCE_PAGE_URL.url}>
                      Account Balance
                    </a>
                  </li>
                  {!isAdhoc(props.user) && <li>
                    <a href={TOPUP_ACCOUNT_PAGE_URL.url}>
                      Top Up Account
                    </a>
                  </li>}
                  {!isAdhoc(props.user) && <li>
                    <a href={TOPUP_HISTORY_PAGE_URL.url}>
                      Top Up History
                    </a>
                  </li>}
                </ul>
              </li>
            }
          </ul>
        </li>
        <li>
          {
            <form action={window.epiSettings.logoutUrl} method="POST">
              <button type="submit">
                Log Out
              </button>
            </form>
          }
        </li>
      </React.Fragment>
    );
  }
}

export default Drawer;
