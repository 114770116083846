import { normalizePaxForCateringData } from 'utils/passengerUtils';
import { garanteeArray } from 'utils/sourceUtils';

function normalizeData(leg, routeParams) {
  return {
    cateringStatus: (leg.cateringModuleState || {}).stateName,
    stateId: (leg.cateringModuleState || {}).stateId,
    isPast: leg.isPast,
    arrivalAirportTimeLocal: leg.arrivalAirportTimeLocal,
    arrivalAirportId: leg.arrivalAirportId,
    departureAirportTimeLocal: leg.departureAirportTimeLocal,
    departureAirportTimeUTC: leg.departureAirportTimeUTC,
    departureAirportId: leg.departureAirportId,
    passengersInfo: (garanteeArray(leg.passengersInfo) || []).map(normalizePaxForCateringData),
    legId: (leg.legId || ''),
    flightLegExtIdentifier: (leg.flightLegExtIdentifier || ''),
    key: routeParams.key,
    within24Hours: leg.within24Hours,
  };
}

function normalizeCateringUtil(leg, params) {
  return normalizeData(leg, params);
}

export default normalizeCateringUtil;
