import * as types from '../actionTypes';

const initialState = {
  message: '',
  isMessageSent: false,
  isLoading: false,
  isError: false,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_REGISTER_CODE_INIT: {
      return Object.assign({}, state, {
        message: '',
        isMessageSent: false,
        isLoading: true,
        isError: false,
      });
    }
    case types.FETCH_REGISTER_CODE_SUCCESS: {
      return Object.assign({}, state, {
        message: action.data.message,
        isMessageSent: action.data.success,
        isLoading: false,
        isError: false,
      });
    }
    case types.FETCH_REGISTER_CODE_FAIL: {
      return Object.assign({}, state, {
        message: action.data,
        isMessageSent: false,
        isLoading: false,
        isError: true,
      });
    }
    default:
      return state;
  }
}
