import React from 'react';


import {
  CATERING_SHARED_ORDER_KEY,
  CATERING_STATUS_CODE_2,
  CATERING_SCREENS
} from '../../../constants/CateringConstants';
import {
  selectionCountdownString,
  shouldShowCountdownString, zeroFill,
  isSelectionMade
} from '../../../utils/cateringUtils';
import { isEmpty } from '../../../utils/arrayUtils';
import { convertToTitleCase } from '../../../utils/stringUtils';
import CateringFooter from './CateringFooter';
import SpinnerLoader from '../../shared/spinnerLoader';

const FALLBACK_MSG = 'Menu will be available shortly';

function getStyles() {
  const styles = {
    fallbackMsg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  };

  return styles;
}
export default class CateringSuppliers extends React.Component {

  getSupplierWiseCounts = (supplier) => {
    const passengerCounts = {};
    let totalCount = 0;
    Object.keys(supplier.services).forEach((serviceName) => {
      Object.keys(supplier.services[serviceName]).forEach((category) => {
        supplier.services[serviceName][category].forEach((item) => {
          if (item.quantities) {
            Object.keys(item.quantities).forEach((passengerId) => {
              const quantity = item.quantities[passengerId];
              if (passengerCounts[passengerId]) {
                passengerCounts[passengerId].push({ ...quantity, ...item });
              } else {
                passengerCounts[passengerId] = [{ ...quantity, ...item }];
              }
              totalCount += quantity.count || 0;
            });
          }
        });
      });
    });
    return { passengerCounts, totalCount };
  }
  handleConfirm = () => {
    const customerSelection = [];
    const { cateringProposal } = this.props;
    cateringProposal.forEach((proposal) => {
      Object.keys(proposal.services).forEach((service) => {
        const categories = proposal.services[service];
        Object.keys(categories).forEach((category) => {
          const items = categories[category];
          items.forEach(item => {
            if (item.quantities) {
              Object.keys(item.quantities).forEach((passengerId) => {
                const passengerSelection = item.quantities[passengerId];
                if (!isEmpty(passengerSelection)) {
                  customerSelection.push({
                    flightMenuPriceId: item.id,
                    personId: passengerSelection.passengerId,
                    quantity: passengerSelection.count,
                    customerNotes: passengerSelection.customerNotes,
                  });
                }
              });
            }
          });
        });
      });
    });
    // return customerSelection;
    this.props.onSelectionConfirmed(customerSelection);
  }

  handleClick = (supplier) => {
    const { catering, withIn24Hours, isPast, isFlightEditable, activeLeg } = this.props;
    const isEditable = activeLeg && activeLeg.legStatus !== "Cancellation Pending" &&
      (activeLeg && activeLeg.legStatus !== "Cancelled");
    if (!isEditable) return;
    if (catering && !withIn24Hours && !isPast) {
      this.props.handleScreenChange(CATERING_SCREENS.SUPPLIER_LIST_MENU);
    }
    if (this.props.onSupplierSelected) this.props.onSupplierSelected(supplier);
  }

  renderPassengerDetailItem(passengerCounts, passengerId) {
    const passengerDetails = passengerCounts[passengerId];
    const listOfCounts = [];
    passengerDetails.forEach((item) => {
      if (item.count) {
        listOfCounts.push(
          <dd>
            {item.title} <strong>x{item.count}</strong>
          </dd>
        );
      }
    });
    if (listOfCounts.length > 0) {
      return (
        <dl>
          <dt>
            {convertToTitleCase(passengerDetails[0].passengerName || "")}
          </dt>
          {listOfCounts}
        </dl>
      );
    }
    return false;
  }

  renderPassengerCounts = (passengerCounts) => {
    const list = [];
    Object.keys(passengerCounts).forEach((passengerId) => {
      if (passengerId !== CATERING_SHARED_ORDER_KEY) {
        list.push(this.renderPassengerDetailItem(passengerCounts, passengerId));
      }
    });
    if (passengerCounts[CATERING_SHARED_ORDER_KEY]) {
      list.push(
        this.renderPassengerDetailItem(
          passengerCounts,
          CATERING_SHARED_ORDER_KEY
        )
      );
    }
    return list;
  }

  renderCatering(catering) {
    const list = [];
    const { isPast, isEditable } = this.props;
    const removeCaret = (!isPast && isEditable) ? "" : "vistajet_catering_menu_caret_remove";
    let totalItemCount = 0;
    if (catering) {
      catering.forEach((supplier) => {
        const { totalCount, passengerCounts } = this.getSupplierWiseCounts(supplier);
        totalItemCount += totalCount;
        list.push(
          <div className="vistajet_menu_button_wrapper">
            <button
              className={`uk-button vistajet_catering_menu_button uk-text-truncate ${removeCaret}`}
              type="button"
              onClick={() => { this.handleClick(supplier); }}>
              {supplier.supplierDisplayName}
            </button>
            {(totalCount > 0) && this.renderPassengerCounts(passengerCounts)}
          </div>
        );
      });
      // if (isSelectionMade(this.props.catering) && totalItemCount > 0) {
      //   list.push(
      //     <details className="catering-details">
      //       <summary>
      //         <div style={{ fontWeight: 'Bold' }}>
      //           Total Items: {zeroFill(totalItemCount)}
      //         </div>
      //       </summary>
      //     </details>
      //   );
      // }
    }
    return list;
  }

  renderHeading() {
    const { catering, withIn24Hours, legCountDown } = this.props;
    if (withIn24Hours) {
      return <p className="vistajet_modal_subheading_2 uk-text-center">Menu</p>;
    }

    if (!shouldShowCountdownString(catering)) {
      return <p className="vistajet_modal_subheading_2 uk-text-center">Please select a menu</p>
    }

    return (<div>
      <p className="vistajet_modal_subheading_2 uk-text-center">Please select a menu</p>
      <p className="vistajet_modal_light_text uk-text-center uk-margin-small-top">Selection Countdown: {legCountDown}</p>
    </div>);
  }

  render() {
    const {
      loading,
      cateringProposal,
      catering,
    } = this.props;
    const styles = getStyles();
    if (loading) {
      return (
        < div className="vistajet_spinner">
          <SpinnerLoader />
        </div>
      );
    }

    if (catering && catering.stateId !== CATERING_STATUS_CODE_2 &&
      cateringProposal && cateringProposal.length) {
      return (
        <div>
          <div className="vistajet_subheading_container">
            {this.renderHeading()}
          </div>
          <div className="vistajet_catering_menu_content">
            {this.renderCatering(cateringProposal)}
          </div>
          <CateringFooter disabled={!this.props.isDirtyState} onClick={this.handleConfirm} />
        </div>
      )
    } else {
      return (<div style={styles.fallbackMsg}>
        {FALLBACK_MSG}
      </div>)
    }
  }
}