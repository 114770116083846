import CountriesList from '../constants/CountryConstants';
import Helpers from 'utils/Helpers';
import { convertToTitleCase } from 'utils/stringUtils';
import React from 'react'

export const getCountry = (key, value) => {
  const countryObj = CountriesList.find((c) => (
    (c[key].toUpperCase() === value.toUpperCase())
  ));
  return countryObj;
};

export const getTruncatedCountryName = (name, code, value) => {
  const countryLabel = Helpers.truncateStr(convertToTitleCase(name), getNameLengthToDisplay(value));
  const countryObj = getCountry('THREE_LETTER_CODE', code) || {};
  const flagIcon = `flag-icon-style flag-icon-${countryObj.TWO_LETTER_CODE.toLowerCase()}`
  return (<div className="country-list-input"><span className={flagIcon} ></span><span className="country-name">{countryLabel}</span></div>); // eslint-disable-line
};

export const getNameLengthToDisplay = (value) => {
  const toBeReduced = value ? value : 0;
  const width  = window.outerWidth;
  if (width < 350) {
    return 32-toBeReduced;
  } else if ( width < 400) {
    return 40-toBeReduced;
  } else if (width < 430) {
    return 44-toBeReduced;
  } else if (width < 800) {
    return 22+2*(toBeReduced+1);
  } else if ( width <= 1024) {
    return 32-2*(toBeReduced);
  } else {
    return 32-toBeReduced;
  }
};
