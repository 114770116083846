import * as type from '../../actionTypes';
import { getFlightId } from '../../../utils/itinerariesUtils';

const initialState = {
  isLoading: false,
  legs: {},
  accountId: null,
  allLegs: [],
  orderType: '',
  itineraryDetailsError: '',
  orderExtIdentifier: '',
  orderStatus: '',
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.ITINERARY_DETAILS_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        itineraryDetails: {},
        allLegs: [],
        orderType: '',
        itineraryDetailsError: '',
        orderStatus: '',
      });
    }

    case type.ITINERARY_DETAILS_SUCCESS: {
      const {
        legs,
        orderExtIdentifier,
        orderLegId,
        orderStatus,
        accountId,
        orderType
      } = action.data;
      const legsData = {};
      legs.forEach(leg => {
        legsData[getFlightId(leg)] = leg;
      })
      return Object.assign({}, state, {
        isLoading: false,
        legs: legsData,
        orderExtIdentifier,
        orderId: orderLegId,
        orderStatus,
        accountId,
        allLegs: legs,
        orderType: orderType ? orderType : '',
        itineraryDetailsError: '',
      });
    }

    case type.ITINERARY_DETAILS_FAIL: {
      return Object.assign({}, state, {
        isLoading: false,
        itineraryDetailsError: action.data,
      });
    }

    case type.FETCH_ORDER_ID_BY_LEG_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        orderExtIdentifier: '',
        itineraryDetailsError: '',
      });
    }
    case type.FETCH_ORDER_ID_BY_LEG_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        orderExtIdentifier: action.data,
        itineraryDetailsError: '',
      });
    }
    case type.FETCH_ORDER_ID_BY_LEG_FAIL: {
      return Object.assign({}, state, {
        isLoading: false,
        itineraryDetailsError: action.data,
      });
    }

    default:
      return state;
  }
}
