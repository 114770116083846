import React, { Component } from 'react';
import { getFiles } from '../utils/fileUtils';
import { scrollFunctionForTable } from '../utils/Helpers';
import SpinnerLoader from './shared/spinnerLoader';
import moment from 'moment';

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default class AccountStatementDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      filter: {
        start: {
          month: '',
          year: '',
        },
        end: {
          month: '',
          year: '',
        },
      }
    }
  }

  isActiveMonth = (month, isStart = false) => {
    const { selectedStatement: { periodStart, periodEnd } } = this.props;
    if (isStart) {
      const { filter: {
        start,
        end,
      } } = this.state;
      if (start.year) {
        if (end.month && end.year) { 
          return moment(`${month} ${start.year}`, 'MMM YYYY').isBetween(moment(periodStart), moment(`${end.month} ${end.year}`, 'MMM YYYY'), 'month', '[]');
        } 
        return moment(`${month} ${start.year}`, 'MMM YYYY').isBetween(moment(periodStart), moment(periodEnd), 'month', '[]');
      } return true;
    } else {
      const { filter: {
        start,
        end,
      } } = this.state;
      if (end.year) {
        if (start.month && start.year) {
          return moment(`${month} ${end.year}`, 'MMM YYYY').isBetween(moment(`${start.month} ${start.year}`, 'MMM YYYY'), moment(periodEnd), 'month', '[]');
        } return moment(`${month} ${end.year}`, 'MMM YYYY').isBetween(moment(periodStart), moment(periodEnd), 'month', '[]');
      } return true;
    }
  }

  isActiveYear = (year, isStart = false) => {
    const { filter: { start, end } } = this.state;
    const { selectedStatement: { periodStart, periodEnd } } = this.props;
    if (isStart) {
      if (start.month) {
        if (end.month && end.year) {
          return moment(`${start.month} ${year}`, 'MMM YYYY').isBetween(moment(periodStart), moment(`${end.month} ${end.year}`, 'MMM YYYY'), 'month', '[]'); 
        } return moment(`${start.month} ${year}`, 'MMM YYYY').isBetween(moment(periodStart), moment(periodEnd), 'month', '[]');
      } if (end.year === year && end.month === 'Jan') return false;
      return true;
    } else {
      if (end.month) {
        if (start.month && start.year) {
          return moment(`${end.month} ${year}`, 'MMM YYYY').isBetween(moment(`${start.month} ${start.year}`, 'MMM YYYY'), moment(periodEnd), 'month', '[]'); 
        } return moment(`${end.month} ${year}`, 'MMM YYYY').isBetween(moment(periodStart), moment(periodEnd), 'month', '[]');
      } if (start.year === year && start.month === 'Dec') return false;
      return true;
    }
  }

  isDisabledMonth = (month, isStart = false) => {
    if (!month) return false;
    const {
      selectedStatement: {
        periodStart, periodEnd,
      }
    } = this.props;

    if (isStart) {
      const {
        filter: {
          start: {
            year = '',
          },
        }
      } = this.state;
      if (year)
        return !moment(`${month} ${year}`, 'MMM YYYY').isBetween(periodStart, periodEnd, 'date', '[]');
      return false;
    } else {
      const {
        filter: {
          end: {
            year = '',
          },
        }
      } = this.state;
      if (year)
        return !moment(`${month} ${year}`, 'MMM YYYY').isBetween(periodStart, periodEnd, 'date', '[]');
      return false;
    }
  }

  shouldDisbaleLoadMore = () => {
    const {
      filter: {
        start = {},
        end = {},
      } = {}
    } = this.state;

    return !(start.month && start.year && end.month && end.year);
  }

  getYears = () => {
    const { selectedStatement = {} } = this.props;
    const { periodStart, periodEnd } = selectedStatement;
    const startYear = +(moment(periodStart).format('YYYY'));
    const endYear = +(moment(periodEnd).format('YYYY'));
    if (!startYear || !endYear) return [];
    if (startYear === endYear) return [startYear];
    const years = [
      ...Array(endYear + 1).keys()
    ];
    return years.splice(startYear, (endYear + 1) - startYear);
  }

  getFilterValue = ({ month = '', year = '' }) => {
    if (month || year) {
      return `${month} ${year}`
    } return '';
  }

  handleClearFilter = () => {
    const shouldClear = !this.shouldDisbaleLoadMore();
    if (shouldClear) this.props.clearInvoiceFilter();
    this.setState({
      filter: {
        start: {
          month: '',
          year: '',
        },
        end: {
          month: '',
          year: '',
        },
      }
    });
  }

  handleFilterStart = (newFilter) => {
    const { start = {}, end = {} } = this.state.filter || {};
    const newStart = {
      month: newFilter.month || start.month || '',
      year: newFilter.year || start.year || '',
    };
    this.setState({
      filter: {
        ...(this.state.filter || {}),
        start: newStart,
      }
    }, () => {
      const { start = {}, end = {} } = this.state.filter || {};
      const shouldFilter = start.month && start.year && end.month && end.year;
      if (shouldFilter) {
        this.fetchAllInvoices();
      }
    });
  }

  handleFilterEnd = (newFilter) => {
    const { start = {}, end = {} } = this.state.filter || {};
    const newEnd = {
      month: newFilter.month || end.month || '',
      year: newFilter.year || end.year || '',
    };
    this.setState({
      filter: {
        ...(this.state.filter || {}),
        end: newEnd,
      }
    }, () => {
      const { start = {}, end = {} } = this.state.filter || {};
      const shouldFilter = start.month && start.year && end.month && end.year;
      if (shouldFilter) {
        this.fetchAllInvoices();
      }
    });
  }

  handleOnFilterChange = () => {
    const {
      token = '',
      fetchStatementInvoices,
      selectedStatement: {
        id = '',
      }
    } = this.props;
    const { filter: {
      start = {}, end = {},
    } } = this.state;
    const shouldFilter = start.month && start.year && end.month && end.month;
    if (shouldFilter) {
      this.fetchAllInvoices();
    }
  }

  getPlaceHolder = (isStart = false) => {
    const {
      selectedStatement: {
        periodStart, periodEnd,
      }
    } = this.props;
    return isStart ?
      moment(periodStart).format('MMM YYYY') :
      moment(periodEnd).format('MMM YYYY');
  }

  getFilteredInvoices = (invoices = []) => {
    if (!invoices) return;
    const { filter: {
      start = {}, end = {},
    } } = this.state;
    const shouldFilter = start.month && start.year && end.month && end.year;
    if (!shouldFilter) return invoices.sort(this.compareInvoiceDates);
    const filterDate = {
      periodStart: moment(`${start.month} ${start.year}`, 'MMM YYYY'),
      periodEnd: moment(`${end.month} ${end.year}`, 'MMM YYYY'),
    };
    return invoices.filter(invoice =>
      moment(invoice.invoiceDate).isBetween(
        filterDate.periodStart, filterDate.periodEnd, 'month', '[]'
      )
    ).sort(this.compareInvoiceDates);
  };

  fetchAllInvoices = () => {
    const {
      token = '',
      fetchStatementInvoices,
      selectedStatement: {
        id = '',
      }
    } = this.props;
    fetchStatementInvoices(token, id, 0, 0);
  }

  compareInvoiceDates = (prev, next) =>
    moment(prev.invoiceDate).diff(moment(next.invoiceDate));

  onClickLoadMore = () => {
    const {
      token = '',
      fetchStatementInvoices,
      selectedStatement: {
        id = '',
      },
    } = this.props;
    const { pageNumber } = this.state;
    const nextPage = pageNumber + 1;
    fetchStatementInvoices(token, id, nextPage);
  }

  onClickDownloadAll = () => {
    const {
      token = '',
      fetchZipUrl,
      selectedStatement: {
        id = ''
      },
    } = this.props;
    fetchZipUrl({
      accountStatementId: id,
    }, token);
  }

  componentDidMount() {
    const {
      token,
      fetchStatementInvoices,
      selectedStatement: {
        id = '',
      }
    } = this.props;
    fetchStatementInvoices(token, id);
  }

  componentWillReceiveProps = nextProps => {
    const { statementInvoices = [] } = this.props;
    if (statementInvoices && statementInvoices.length && nextProps.statementInvoices !== statementInvoices &&
      nextProps.statementInvoices.length > statementInvoices.length) {
      const { pageNumber } = this.state;
      this.setState({ pageNumber: pageNumber + 1 });
    }
  }

  renderInvoiceRow = ({ invoiceDate, description, billedHours, fileKey }) => (
    <tr>
      <td className="vistajet_light_text uk-padding-remove-left uk-table-shrink uk-text-nowrap">{invoiceDate}</td>
      <td className="vistajet_light_text uk-text-truncate uk-table-expand vistajet_max_width_130" uk-tooltip={`title: ${description}`}>{description}</td>
      <td className="vistajet_dark_text uk-table-shrink uk-text-nowrap">{(+billedHours).toFixed(2)} Hrs</td>
      <td className="uk-padding-remove-right">
        <ul className="uk-iconnav uk-flex-right uk-visible@s">
          <li><a
            className="vistajet_eye_icon"
            href={getFiles(fileKey, this.props.token || '', false)}
            target={'_blank'}
          ></a></li>
          <li><a
            className="vistajet_pdf_icon"
            href={getFiles(fileKey, this.props.token || '', false, true)}
            target={'_blank'}
          ></a></li>
        </ul>
        <ul className="uk-iconnav uk-flex-right uk-hidden@s">
          <li><button className="vistajet_more_icon" type="button"></button></li>
          <div uk-dropdown="mode: click; pos: bottom-right" className="uk-margin-remove vistajet_iconnav_dropdown">
            <ul className="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
              <li className="uk-text-truncate"><a
                className="vistajet_eye_icon"
                href={getFiles(fileKey, this.props.token || '', false)}
                target={'_blank'}
              ><span>View</span></a></li>
              <li className="uk-text-truncate"><a
                className="vistajet_pdf_icon"
                href={getFiles(fileKey, this.props.token || '', false, true)}
                target={'_blank'}
              ><span>Download</span></a></li>
            </ul>
          </div>
        </ul>
      </td>
    </tr>
  );

  render() {
    const {
      onBackButtonClick,
      statementInvoices = [],
      statementInvoicesError = '',
      loadingStatementInvoices = false,
      loadingMoreStatementInvoices = false,
      statementInvoicesLastPage = false,
      loadingZipUrl = false,
      fetchZipUrl,
    } = this.props;
    const { filter: { start = {}, end = {} } } = this.state;
    const filteredInvoices = this.getFilteredInvoices(statementInvoices);
    if (loadingStatementInvoices) {
      return (
        <div className="uk-flex uk-flex-center uk-flex-middle uk-height-small">
          <SpinnerLoader />
        </div>
      )
    };
    return (
      <div>
        <div className="uk-grid-collapse" data-uk-grid>
          <div className="vistajet_invoices">
            <a href="#" className="vistajet_back_arrow"
              onClick={onBackButtonClick}
            ></a>
            <p className="vistajet_dark_text">Invoices</p>
          </div>
          {statementInvoices && <div className="uk-width-expand uk-text-right vistajet_date_range_wrapper">
            <div className="vistajet_date_range">
              <p>From</p>
              <input
                type="text"
                readOnly
                value={this.getFilterValue(start)}
                placeholder={this.getPlaceHolder(true)}
              // onChange = {this.handleOnFilterChange}
              />
              <div data-uk-dropdown="mode: click; pos: bottom-center;" className="vistajet_date_range_dropdown">
                <div className="vistajet_calender_placeholder uk-text-center">
                  <i className="vistajet_date_icon" />
                  {this.getFilterValue(start) || this.getPlaceHolder(true)}
                </div>
                <div className="uk-grid uk-grid-collapse vistajet_date_divider" data-uk-grid>
                  <div className="uk-width-1-2 uk-text-center">
                    <small>Month</small>
                    <ul
                      className="uk-nav uk-dropdown-nav"
                    >
                      {MONTHS.filter(m => this.isActiveMonth(m, true)).map((month, index) => (
                        <li
                          // key={index}
                          className={month === start.month ? 'active' : ''}
                          onClick={() => this.handleFilterStart({ month })}
                          // value={month}
                        >{month}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="uk-width-1-2 uk-text-center">
                    <small>Year</small>
                    <ul
                      className="uk-nav uk-dropdown-nav"
                    >
                      {this.getYears().filter(y => this.isActiveYear(y, true)).map((year, index) => (
                        <li
                          // key={index}
                          className={year === start.year ? 'active' : ''}
                          onClick={() => this.handleFilterStart({ year })}
                          // value={year}
                        >{year}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="vistajet_date_range">
              <p>To</p>
              <input
                type="text"
                readOnly
                value={this.getFilterValue(end)}
                placeholder={this.getPlaceHolder()}
              // onChange = {this.handleOnFilterChange}
              />
              <div data-uk-dropdown="mode: click; pos: bottom-center;" className="vistajet_date_range_dropdown">
                <div className="vistajet_calender_placeholder uk-text-center">
                  <i className="vistajet_date_icon" />
                  {this.getFilterValue(end) || this.getPlaceHolder()}
                </div>
                <div className="uk-grid uk-grid-collapse vistajet_date_divider" data-uk-grid>
                  <div className="uk-width-1-2 uk-text-center">
                    <small>Month</small>
                    <ul
                      className="uk-nav uk-dropdown-nav"
                    >
                      {MONTHS.filter(m => this.isActiveMonth(m)).map((month, index) => (
                        <li
                          // key={index}
                          className={month === end.month ? 'active' : ''}
                          onClick={() => this.handleFilterEnd({ month })}
                          // value={month}
                        >{month}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="uk-width-1-2 uk-text-center">
                    <small>Year</small>
                    <ul
                      className="uk-nav uk-dropdown-nav"
                    >
                      {this.getYears().filter(y => this.isActiveYear(y)).map((year, index) => (
                        <li
                          // key={index}
                          className={year === end.year ? 'active' : ''}
                          onClick={() => this.handleFilterEnd({ year })}
                          // value={year}
                        >{year}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <button className="uk-button vistajet_reset_date" onClick={this.handleClearFilter} />
          </div>}
        </div>

        <div className="uk-overflow-auto vistajet_table_wrapper vistajet_account_statement_detail">
          { scrollFunctionForTable && scrollFunctionForTable() }
          <table className="uk-table uk-table-small">
            <tbody>
              {(filteredInvoices || []).length ?
                filteredInvoices.map(this.renderInvoiceRow) :
                <tr>No Invoices Found.</tr>}
            </tbody>
          </table>
        </div>
        {<div className="vistajet_bottom_button_wrapper" uk-grid>
          <div className="uk-width-1-2@s">
            {!statementInvoicesLastPage && (
                <button className="uk-button vistajet_button_default" onClick={this.onClickLoadMore}>
                  {
                    loadingMoreStatementInvoices ?
                      <SpinnerLoader /> : 'Load More'
                  }
                </button>
              )
            }
          </div>
          <div className="uk-width-1-2@s uk-margin-remove uk-text-right@s">
            {(statementInvoices || []).length && <button
              className="uk-button vistajet_button_default"
              onClick={this.onClickDownloadAll}
            >{loadingZipUrl ? <SpinnerLoader /> : 'Download All'}
            </button>}
          </div>
        </div>}
      </div>
    );
  }
}