import React from 'react';

const getStyles = (props) => {
  const styles = {
    root: {
      width: 32,
      height: 21,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundImage: `url(${props.backgroundImage})`,
    },
  };
  return styles;
};

const CreditCardFlagImage = (props) => {
  const {
    style,
    className,
    backgroundImage, // eslint-disable-line
    ...other
  } = props;

  const styles = getStyles(props);

  return (
    <div
      className={`credit-card-flag ${className}`}
      style={Object.assign(styles.root, style)}
      {...other}
    />
  );
};

CreditCardFlagImage.defaultProps = {
  className: '',
};

export default CreditCardFlagImage;
