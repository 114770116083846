
import {
  fetchGeocodeInit,
} from '../actions';

export const RESULT_TYPES = {
  COUNTRY: 'country',
  LOCALITY: 'locality',
  SUBLOCALITY: 'sublocality',
  ROUTE: 'route',
  STREET_NUMBER: 'street_number',
};

const RESULT_TYPES_CONTENT = {
  street_number: {
    to: 'streetNumber',
  },
  street_address: {
    to: 'street',
  },
  sublocality: {
    to: 'neighborhood',
  },
  locality: {
    to: 'city',
  },
  country: {
    to: 'country',
  },
  postal_code: {
    to: 'postalCode',
  },
  route: {
    to: 'street',
  },
};

const normalizeResultsByTypes = (results, resultTypes) => {
  const types = resultTypes.slice();
  let normalizedAddress = {};
  let addressComponents = [];
  let formattedAddress = null;
  if (results && results.length && types.length) {
    let i = results.length - 1;
    while (i >= 0 && types.length) {
      const result = results[i];

      if (result.formatted_address) {
        formattedAddress = result.formatted_address;
      }

      if (result.address_components) {
        addressComponents = addressComponents.concat(result.address_components);
        const filteredTypes = [];
        for (let x = result.types.length - 1; x >= 0; x -= 1) {
          const indexOf = types.indexOf(result.types[x]);
          if (indexOf >= 0) {
            filteredTypes.push(types.splice(indexOf, 1)[0]);
          }
        }

        if (filteredTypes.length) {
          const normalizedResult = normalizeResultsByTypes(
            result.address_components,
            filteredTypes,
          );
          normalizedAddress = Object.assign(normalizedResult, normalizedAddress);
        }
      } else {
        const { length } = result.types;
        let foundType;
        let x = 0;
        while (x < length && !foundType) {
          const indexOf = types.indexOf(result.types[x]);
          if (indexOf >= 0) {
            [foundType] = types.splice(indexOf, 1);
          }
          x += 1;
        }
        if (foundType) {
          const {
            to, short,
          } = RESULT_TYPES_CONTENT[foundType];
          normalizedAddress = Object.assign({
            [to]: short ? result.short_name : result.long_name,
          }, normalizedAddress);
        }
      }

      i -= 1;
    }
  }
  normalizedAddress.formattedAddress = formattedAddress;
  if (addressComponents.length && types.length) {
    return Object.assign(normalizeResultsByTypes(addressComponents, types), normalizedAddress);
  }
  return normalizedAddress;
};

export const fetchGeocode = () => async (dispatch) => {
  dispatch(fetchGeocodeInit());
};
