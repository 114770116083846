import React from 'react';

const SkeletonLoaderCardsWithoutMap = () =>
    <div class="uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-1" data-uk-grid>
        <div class="uk-clearfix">
            <div class="uk-card uk-card-body vistajet_skeleton_card">
                <div class="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div class="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div class="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div class="uk-clearfix">
            <div class="uk-card uk-card-body vistajet_skeleton_card">
                <div class="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div class="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div class="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div class="uk-clearfix">
            <div class="uk-card uk-card-body vistajet_skeleton_card">
                <div class="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div class="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div class="vistajet_available_date uk-text-center"></div>
            </div>
        </div>                                         
    </div> 

export default SkeletonLoaderCardsWithoutMap;
