import axios from "axios";
import { getFromLocal } from '../../utils/cache';
import { USER_REDUCER } from "../../constants/localStorageKeys";
import {
  fetchQuotesInit,
  fetchQuotesSuccess,
  fetchQuotesFail,
} from "../actions";
import status from "configs/status";
import { FETCH_MY_QUOTES } from "../../configs/constants";
import { handleServerErrors } from "../../utils/sourceUtils";
import { normalizeMyQuotes } from "../../normalizers/quoteNormalizer";
import { BAD_REQUEST } from "../../constants/ServerCodeConstants";

const fetchQuotesPromiseInprogress = {};

const fetchQuotesSource = (quoteType, pageNum = 1) => (dispatch) => {
  const uniqueKey = `fetch${quoteType}Quotes`;
  if (fetchQuotesPromiseInprogress[uniqueKey]) {
    return fetchQuotesPromiseInprogress[uniqueKey];
  }
  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;

  const fetchQuotesPromise = new Promise((resolve, reject) => {
    const url = FETCH_MY_QUOTES.replace('{token}', token)
      .replace('{quoteStatus}', quoteType).replace('{pageNum}', pageNum);
    dispatch(fetchQuotesInit(pageNum));
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          const responseHeader = response.data.responseHeaderDto;
          if (responseHeader.isError === true) {
            dispatch(fetchQuotesFail(handleServerErrors(responseHeader.statusCode)));
            reject(handleServerErrors(responseHeader.statusCode));
          } else {
            const responseData = normalizeMyQuotes(response.data.quoteDtos);
            dispatch(fetchQuotesSuccess({ quoteType, quotes: responseData, pageNum }));
            resolve(responseData);
          }
        } else {
          dispatch(fetchQuotesFail(handleServerErrors(BAD_REQUEST)));
          reject(handleServerErrors(BAD_REQUEST));
        }
        delete fetchQuotesPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        if (!err.status) {
          dispatch(fetchQuotesFail(handleServerErrors()));
          reject(handleServerErrors());
        } else {
          dispatch(fetchQuotesFail(handleServerErrors(BAD_REQUEST)));
          reject(handleServerErrors(BAD_REQUEST));
        }
        delete fetchQuotesPromiseInprogress[uniqueKey];
      });
  });
  fetchQuotesPromiseInprogress[uniqueKey] = fetchQuotesPromise;
  return fetchQuotesPromise;
};

export default fetchQuotesSource;