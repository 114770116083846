import React, { Component } from 'react';
import Select from 'react-select';
import SpinnerLoader from '../shared/spinnerLoader';
import IntlTelphoneInput from "../shared/PhoneNumberSelector";
import cn from "classnames";
import { Salutation } from '../../constants/SignupConstants';
import {
  POLICY_URL_PAGE,
  TERMS_URL_PAGE,
  contactusEnv,
} from '../../configs/constants';

import {
  getFirstChars,
} from '../../utils/stringUtils';
import validateProgramBuilderDetailForm from '../../utils/Validators/ProgramBuilderDetailFormValidator';
import GeoLocation from "../../utils/geolocation";
import StateAbbreviation, { stateList } from '../../utils/stateUtils'
import PostTransport from '../../utils/postTransport'

export default class ProgramBuilderDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salutation: Salutation[0],
      firstName: '',
      lastName: '',
      email: '',
      defaultCountry: null,
      country: "",
      state: "",
      phoneNumber: "",
      isPhoneNumberValid: false,
      marketingCheckbox: false,
      agreementCheckbox: false,
      errors: {},
      showSeeMoreButton: props.isLegArray,
    };
  }

  componentDidMount() {
    GeoLocation().then(pos => {
      if (!pos || !pos.region)
        return;
      this.setState({ stateCode: StateAbbreviation(pos.region) });
    }).catch(err => { });
  }

  componentWillReceiveProps(nextProps) {
    const { onClearReducer } = this.props;
    if (this.props.isSuccess !== nextProps.isSuccess) {
      onClearReducer();
      // this.refreshFormFields();
    }
    if (nextProps.isLegArray) {
      this.enableSeeMoreButton(nextProps.legArray);
    } else {
      this.setState({ showSeeMoreButton: nextProps.isLegArray });
    }
  }

  fieldValidator = value => {
    if (value === '' || value === null || value === [] || value === 0) {
      return false;
    }
    return true;
  };

  enableSeeMoreButton = (legs = null) => {
    const {
      salutation,
      firstName,
      lastName,
      email,
      country,
      stateCode,
      phoneNumber,
      isPhoneNumberValid,
      agreementCheckbox,
    } = this.state;
    let legArray = legs ? legs : this.props.legArray;
    if (
      this.fieldValidator(salutation)
      && this.fieldValidator(firstName)
      && this.fieldValidator(lastName)
      && this.fieldValidator(email)
      && this.fieldValidator(phoneNumber)
      && this.fieldValidator(agreementCheckbox)
      && (country !== 'US' || stateCode)
      && agreementCheckbox
      && legArray
      && Object.keys(legArray).length > 0
    ) {
      this.setState({ showSeeMoreButton: true });
    } else {
      this.setState({ showSeeMoreButton: false });
    }
  };

  geoLookupCountryCode = (code) => {
    this.geoLookupCountryCode = code;
  }

  handleSalutationChange = (obj) => {
    this.setState({ salutation: obj });
  };

  handleFirstNameChange = (e) => {
    this.setState({ firstName: e.target.value }, this.enableSeeMoreButton);
  };

  handleLastNameChange = (e) => {
    this.setState({ lastName: e.target.value }, this.enableSeeMoreButton);
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value }, this.enableSeeMoreButton);
  };

  handlePhoneNumberChange = (status = true, phoneNumber, countryData) => {
    const phoneStrLength = phoneNumber.length;
    this.setState({
      country: phoneStrLength > 0 ? countryData.iso2.toUpperCase() : "",
      phoneNumber: getFirstChars(phoneNumber, 15, status),
      isPhoneNumberValid: status,
      requestACallBack: phoneStrLength > 0,
    }, this.enableSeeMoreButton);
  };

  handleStateChange = e => {
    this.setState({ stateCode: e.target.value }, this.enableSeeMoreButton)
  }

  removePhoneError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      if (remErrors.errors && remErrors.errors.phoneNumber) {
        remErrors.errors.phoneNumber = null;
      }
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError
      };
      this.setState({
        errors: newState
      });
    }
  };

  handleAgreementCheck = () => {
    const { agreementCheckbox } = this.state;
    this.setState({
      agreementCheckbox: !agreementCheckbox,
    }, this.enableSeeMoreButton);
  };

  handleMarketingCheck = () => {
    const { marketingCheckbox } = this.state;
    this.setState({
      marketingCheckbox: !marketingCheckbox,
    });
  };
  /*Validate and Submit Form*/
  validateForm = () => {
    const {
      salutation,
      firstName,
      lastName,
      email,
      country,
      stateCode,
      phoneNumber,
      isPhoneNumberValid,
      agreementCheckbox,
      marketingCheckbox,
    } = this.state;
    //const { submit, onContactUsForm } = this.props;
    const { submit } = this.props;
    const res = validateProgramBuilderDetailForm({
      salutation,
      firstName,
      lastName,
      email,
      country,
      stateCode,
      phoneNumber,
      isPhoneNumberValid,
      agreementCheckbox
    });
    if (res.isError) {
      // set state of errors
      this.setState({
        errors: res,
      });
    } else {
      // onContactUsForm(this.createPayload());
      try {
        window.dataLayer.push({ 'event': 'enquirySubmitted', 'enquiryType': 'Program Builder' });
      } catch (e) {
        console.warn("dataLayer push failed for 'Program Builder': " + e)
      }
      this.submitHiddenForm();
      submit({
        salutation,
        firstName,
        lastName,
        email,
        country,
        stateCode,
        phoneNumber,
        isPhoneNumberValid,
        agreementCheckbox,
        marketingCheckbox,
      });
    }
  };

  formatLegArray = (legs) => {
    return legs.legArray.map((leg) => {
      const passengersCount = Number(leg.passengersCount) || 0;
      return {
        from_airport_str: leg.departureAirport ? `${leg.departureAirport.cityAndCountry}, ${leg.departureAirport.name}` : '',
        from_airport: leg.departureAirport ? `${leg.departureAirport.icao}` : '',
        to_airport_str: leg.arrivalAirport ? `${leg.arrivalAirport.cityAndCountry}, ${leg.arrivalAirport.name}` : '',
        to_airport: leg.arrivalAirport ? `${leg.arrivalAirport.icao}` : '',
        out: null,
        back: null,
        date_time: null,
        no_passengers: passengersCount,
        ac_type: '',
        distance: null,
        count: leg.years
      };
    });
  }

  submitHiddenForm = () => {
    const payload = this.createPayload();
    PostTransport(payload.url, payload.data)

    var p = document.createElement('img');
    p.src = "https://secure.adnxs.com/px?id=1090189&seg=17321571&redir=https%3A%2F%2Fpixel.mediaiqdigital.com%2Fpixel%3Fu1%3DProgramBuilderDetail%26u3%3D%26u4%3D%26pixel_id%3D1090189%26uid%3D%24%7BUID%7D&t=2";
    p.width = 1;
    p.height = 1;
    document.body.appendChild(p);
  }

  createPayload = () => {
    const VALUE_ENV_MAP = {
      preproduction: {
        oid: '00D2z0000000TxX',
        url: 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
      },
      production: {
        oid: '00D200000005jIW',
        url: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
      }
    }

    const FIELDS_MAP = {
      //salutation: 'salutation',
      webSalutationText: (env) => (env === 'preproduction') ? '00N4G000006eYu2' : '00N4G000006eYu2',
      webSalutationOther: (env) => (env === 'preproduction') ? '00N4G000006eYty' : '00N4G000006eYty',
      greetingSalutation: '00N4G000006eQ6E',
      //title:                                                    'title',
      firstName: 'first_name',
      lastName: 'last_name',
      //email:                                                    'email',
      //company:                                                  'company',
      phoneNumber: 'phone',
      message: 'description',
      marketingOptIn: (env) => (env === 'preproduction') ? '00N4G000006eYtx' : '00N4G000006eYtx',
      country: 'country_code',
      state: 'state_code',
      conversionURL: (env) => (env === 'preproduction') ? '00N4G000006eYtq' : '00N4G000006eYtq',
      googleSource: (env) => (env === 'preproduction') ? '00N4G000006eYu6' : '00N4G000006eYu6',
      googleMedium: (env) => (env === 'preproduction') ? '00N4G000006eYu5' : '00N4G000006eYu5',
      googleCampaign: (env) => (env === 'preproduction') ? '00N4G000006eYu3' : '00N4G000006eYu3',
      googleTerm: (env) => (env === 'preproduction') ? '00N4G000006eYu7' : '00N4G000006eYu7',
      customJSON: (env) => (env === 'preproduction') ? '00N4G000006eYtw' : '00N4G000006eYtw',
      flightsJSON: (env) => (env === 'preproduction') ? '00N4G000006eYts' : '00N4G000006eYts',
      vistajetAircraft: (env) => (env === 'preproduction') ? '00N4G000006eYu1' : '00N4G000006eYu1',
      internalNote: (env) => (env === 'preproduction') ? '00N4G000006eYtt' : '00N4G000006eYtt',
      callback: (env) => (env === 'preproduction') ? '00N4G000006eYtv' : '00N4G000006eYtv',
      origin: (env) => (env === 'preproduction') ? '00N4G000006eYtu' : '00N4G000006eYtu',
      type: (env) => (env === 'preproduction') ? '00N4G000006eYu0' : '00N4G000006eYu0',
    }

    const getValuePerEnv = (fieldName, env) => {
      const values = VALUE_ENV_MAP[env]
      if (!values || !values[fieldName]) {
        console.error(`Couldn't find ${fieldName} value for SalesForce`)
        return null
      }
      return values[fieldName]
    }

    const isFunction = (functionToCheck) => (typeof functionToCheck === 'function')

    const renameFields = (data, env) => {
      for (const field in { ...data }) {
        const value = data[field]
        const fieldName = FIELDS_MAP[field]
        if (isFunction(fieldName)) {
          // Use name of field depends on env
          data[fieldName(env)] = value
          delete data[field]
        } else if (fieldName) {
          data[fieldName] = value
          delete data[field]
        }
      }
      return data
    }

    const {
      salutation,
      firstName,
      lastName,
      email,
      phoneNumber,
      country,
      stateCode,
      marketingCheckbox,
      agreementCheckbox
    } = this.state;

    const {
      legArray,
    } = this.props;
    const formattedLegArray = this.formatLegArray(legArray);

    const url = window.location;
    const utmData = new URLSearchParams(window.location.search);
    const countryCode = country === "" ? this.geoLookupCountryCode : country;

    let env = (window.globalEnv && window.globalEnv.toLowerCase()) || 'preproduction'
    if (!VALUE_ENV_MAP[env]) env = 'preproduction'


    const aircraft = this.props.suggestionData && this.props.suggestionData.airCraft ? this.props.suggestionData.airCraft.aircraftName : '';
    const hours = this.props.suggestionData && this.props.suggestionData.yearlyHours ? this.props.suggestionData.yearlyHours : 0;

    let data = {
      oid: getValuePerEnv('oid', env),
      salutation: salutation.label,
      firstName: firstName,
      lastName: lastName,
      email: email,
      company: "",
      phoneNumber: phoneNumber,
      message: "",
      marketingOptIn: marketingCheckbox,
      country: countryCode,
      state: countryCode === "US" ? stateCode : "",
      conversionURL: `${url.origin}${url.pathname}`,
      googleSource: utmData.get("utm_source") || "",
      googleMedium: utmData.get("utm_medium") || "",
      googleCampaign: utmData.get("utm_campaign") || "",
      googleTerm: utmData.get("utm_term") || "",
      customJSON: JSON.stringify({
        action: 'program builder',
        agreementCheckbox: agreementCheckbox,
        flights: formattedLegArray,
        aircraft: aircraft,
        hours: hours
      }),
      callback: '',
      origin: 'Web',
      type: 'Program Builder',

      retURL: 'https://www.vistajet.com/thankyou.txt',
    }

    return {
      url: getValuePerEnv('url', env),
      data: renameFields(data, env)
    };
  };

  generateSalutationOptions = () => {
    return Salutation.map((salutation) => {
      return {
        value: salutation.value,
        label: salutation.label,
      };
    });
  };

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };


  renderForm = () => {
    const salutationOptions = this.generateSalutationOptions();
    const {
      salutation,
      firstName,
      lastName,
      email,
      country,
      stateCode,
      phoneNumber,
      isPhoneNumberValid,
      errors,
      defaultCountry,
      marketingCheckbox,
      agreementCheckbox,
      showSeeMoreButton,
    } = this.state;
    const { isLoading, programBuilder } = this.props;
    const hasStateField = country === 'US'
    return (
      <div className="uk-container">
        <div className="uk-flex uk-margin-medium-top uk-grid-small uk-grid uk-grid-stack">
          <div className="uk-width-1-1@m uk-width-1-1@s uk-first-column uk-scrollspy-inview uk-animation-slide-top-medium">
            <div className="uk-card uk-card-default uk-card-body vistajet_card">
              <div className="vistajet_primary_form vistajet_primary_program form uk-margin-small-top">
                {
                  isLoading &&
                  (
                    <div className="vistajet_spinner"><SpinnerLoader /></div>
                  )
                }
                <div className="uk-grid-medium uk-grid-small" data-uk-grid>
                  {programBuilder}
                  <div className="uk-width-1-2@s">
                    <div className="uk-grid-collapse" data-uk-grid>
                      <div className="uk-width-1-4 uk-width-1-4@s uk-width-1-6@m">
                        <div
                          className="vistajet_input_wrapper"
                          style={{
                            borderRight: 'none',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        >
                          <Select
                            classNamePrefix="react-select"
                            isSearchable={false}
                            onChange={this.handleSalutationChange}
                            options={salutationOptions}
                            value={salutation}
                            id="salutation-select"
                            className="vistajet_select_input"
                            name="salutation"
                          />
                          <label htmlFor="-">
                            Title
                          </label>
                          <i data-uk-icon="icon: chevron-down; ratio: 1" class="uk-icon" />
                        </div>
                      </div>
                      <div className="uk-width-expand uk-width-3-4@s uk-width-5-6@m">
                        <div
                          className="vistajet_input_wrapper"
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderLeft: 0,
                          }}
                        >
                          <input
                            id="firstName"
                            type="text"
                            name="firstName"
                            onChange={this.handleFirstNameChange}
                            onFocus={this.removeErrors}
                            value={firstName}
                            required
                          />
                          <label htmlFor="firstName">
                            Name
                          </label>
                          {
                            errors.errors && errors.errors.firstName && (
                              <p>
                                {errors.errors.firstName}
                              </p>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-width-1-2@s">
                    <div className="vistajet_input_wrapper">
                      <input
                        id="lastName"
                        type="text"
                        name="lastName"
                        onChange={this.handleLastNameChange}
                        onFocus={this.removeErrors}
                        value={lastName}
                        autoComplete="off"
                        required
                      />
                      <label htmlFor="lastName">
                        Surname
                      </label>
                      {
                        errors.errors && errors.errors.lastName && (
                          <p>
                            {errors.errors.lastName}
                          </p>
                        )
                      }
                    </div>
                  </div>

                  <div className="uk-width-1-2@s  uk-margin-remove">
                    <div className="vistajet_input_wrapper">
                      <input
                        id="email"
                        type="text"
                        name="email"
                        onChange={this.handleEmailChange}
                        onFocus={this.removeErrors}
                        value={email}
                        autoComplete="off"
                        required
                      />
                      <label htmlFor="email">
                        Email
                      </label>
                      {
                        errors.errors && errors.errors.email && (
                          <p>
                            {errors.errors.email}
                          </p>
                        )
                      }
                    </div>
                  </div>

                  <div className="uk-width-1-1 uk-width-1-2@m uk-margin-remove">
                    <div className={cn("vistajet_phone-row", hasStateField && '--has-state-field')}>
                      <div className="vistajet_input_wrapper vistajet_input_phone_number with_fixed_label">
                        <IntlTelphoneInput
                          id="phoneNumber"
                          value={phoneNumber}
                          errorText={errors.phoneNumber}
                          onChange={this.handlePhoneNumberChange}
                          onFocus={this.removePhoneError}
                          onBlur={this.handlePhoneNumberBlur}
                          autoPlaceholder={false}
                          isContactUsForm={true}
                          defaultCountry={defaultCountry}
                          geoLookupCountryCode={this.geoLookupCountryCode}
                        />
                        <label htmlFor="phoneNumber">Phone Number</label>
                        {errors.errors && errors.errors.phoneNumber && (
                          <p>{errors.errors.phoneNumber}</p>
                        )}
                      </div>
                      {hasStateField && (
                        <div className="vistajet_input_wrapper with_fixed_label">
                          <select className="uk-select vistajet_input_state-select" id="stateCode" defaultValue={stateCode || ""} onChange={this.handleStateChange}>
                            <option value="" disabled hidden>Select a state</option>
                            {Object.entries(stateList).map(([name, code]) => {
                              return (
                                <option value={code} key={code}>{name}</option>
                              )
                            })}
                          </select>
                          <label htmlFor="stateCode">State</label>
                          {errors.errors && errors.errors.stateCode && (
                            <p>{errors.errors.stateCode}</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="uk-width-1-1@m uk-width-1-1@s vistajet_checkbox vistajet_checkbox_top_auto vistajet_checkbox_top_auto_remove">
                    <label htmlFor="marketingCheckbox">
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        name="checkbox"
                        id="marketingCheckbox"
                        checked={marketingCheckbox}
                        onClick={this.handleMarketingCheck}
                      />
                      I would like to receive Marketing communication
                    </label>
                  </div>

                  <div className="uk-width-1-1@m uk-width-1-1@s vistajet_checkbox vistajet_checkbox_top_auto">
                    <label htmlFor="agreementCheckbox">
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        name="checkbox"
                        id="agreementCheckbox"
                        checked={agreementCheckbox}
                        onClick={this.handleAgreementCheck}
                        onFocus={this.removeErrors}
                      />
                      I agree to the VistaJet
                      <strong>
                        <a href={TERMS_URL_PAGE} target="_blank">
                          {' '}
                          Terms and Conditions
                        </a>
                      </strong>
                      ,
                      and
                      <strong>
                        <a href={POLICY_URL_PAGE.replace('{headingId}', '')} target="_blank">
                          {' '}
                          Privacy Notice
                        </a>
                      </strong>
                    </label>
                  </div>
                  {
                    errors.errors && errors.errors.agreementCheckbox && (
                      <p className="vistajet_outer_error">
                        {errors.errors.agreementCheckbox}
                      </p>
                    )
                  }
                  {
                    (
                      <div className="uk-width-1-1@s uk-text-right uk-margin-remove">
                        <input
                          className="uk-input vistajet_button_default vistajet_button_primary uk-margin-medium-top"
                          type="button"
                          value="See Result"
                          disabled={!showSeeMoreButton}
                          onClick={this.validateForm}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderForm();
  }
}
