export const errorMessages = {
  // Salutation error messages
  SALUTATION_REQ: "Required",

  // Name error messages
  INVALID_NAME: "Name can't have numbers or special characters",
  FIRST_NAME_REQ: "First name is required",
  LAST_NAME_REQ: "Last name is required",

  // Email error messages
  EMAIL_REQ: "Email is required",
  INVALID_EMAIL: "Please provide correct email address",
  REPEAT_EMAIL_REQ: "Repeat email is required",
  EMAIL_DO_NOT_MATCH: "Emails must match",

  // ContactUs-Message error message
  MESSAGE_REQ: "Please enter your message",
  MESSAGE_INVALID: "Invalid message",

  // Phonenumber error messages
  PHONE_REQUIRED: "Phone number is required",
  INVALID_PHONE: "Please provide a valid telephone number",

  // Password error messages
  PASS_REQ: 'Password is required',
  PASS_MAX_LENGTH: 'For your password, please use a maximum of 30 characters',
  PASS_MAX_LENGT_MOBILE: 'Use atleast 8 characters. eg.(aA59cdL)',
  INVALID_PASS: `Please use at least 8 and a maximum of 30 characters
  including an uppercase letter, a lowercase letter and a number`,
  REPEAT_PASS_REQ: "Repeat password is required",
  PASS_DO_NOT_MATCH: "Passwords don't match. Please check and try again.",
  SAME_PASSWORD:
    "You have already used this password. Please enter a new password to continue",

  // Terms and condition error messages
  CHECK_REQUIRED: "Please accept the terms and conditions to continue",

  // Register API Actions
  USER_ALREADY_EXISTS: `There is already an account registered to this email id.
  
  Please use another email id or log in to continue`,
  TELEPHONE_ALREADY_EXISTS: `There is already an account registered to this phone number.
  Please use a different number`,

  // Credit card error messages
  CVV_REQUIRED: 'Security code is required',
  INVALID_CVV: 'Invalid CVV number. Please check and try again',
  INVALID_EXPIRY_DATE_FORMAT: 'Expiry Date format is not valid, please try MM/YY',
  INVALID_EXPIRY_DATE: 'Invalid expiry date. Please check and try again',
  EXPIRY_DATE_REQUIRED: 'Expiry date is required',
  CREDIT_CARD_NUMBER_REQUIRED: 'Credit card number is required',
  INVALID_CREDIT_CARD_NUMBER: 'Invalid credit card number. Please check and try again',

  // Contracting details error messages
  ADDRESS_REQUIRED: 'Address is required',
  CITY_REQUIRED: 'City is required',
  STATE_REQUIRED: 'State is required',
  COUNTRY_REQUIRED: 'Country is required',
  POSTAL_CODE_REQUIRED: 'Postal Code is required',
  PAYMENT_NOT_DEFINED: 'Payment Method not defined',

  //account statement error messgaes
  CANNOT_ACCESS_STATEMENTS: 'You do not have access to the Statement PDF',
  NO_RELATED_INVOICES: 'The request has no related invoices',
  // User profile error messages
  DATE_OF_BIRTH_REQUIRED: 'Date of birth is required',
  INVALID_DOB_FORMAT: 'Please use format: DD MMM YYYY',
  EMAIL_ALREADY_EXISTS: 'Email already exists',

  // User Passport validation error messages
  PASSPORT_NAME_REQUIRED: 'Passport Name is required',
  INVALID_PASSPORT_NAME: 'Name can not have any special characters or numbers',
  PASSPORT_NUMBER_REQUIRED: 'Passport Number is required',
  INVALID_PASSPORT_NUMBER: 'Passport can not have any special characters',
  PLESASE_USE_DATE_FORMAT: 'Please use format: DD/MM/YYYY',
  INVALID_PASSPORT_EXPIRY_DATE_FUTURE: 'Passport expiry date should be in future. Please insert a valid date.',
  INVALID_PASSPORT_EXPIRY_DATE: 'Passport expiry date should be valid. Please insert a valid date.',
  INVALID_NATIONALITY: 'Please enter valid nationality',
  INVALID_ISSUING_COUNTRY: 'Please enter valid issuing country',
};

export const LEGACY_ERROR_MESSAGES = {
  timeout: "Server not responding",
  loginFailed:
    "We could not log you in. Please check your username and password and try again",
  legAddSuccessMsg: `You have successfully created your flight  +
                    from {deptAirport} to {arrivalAirport}`,
  legUpdateSuccessMsg: `Your Flight from {deptAirport} to {arrivalAirport}
                       has been updated Successfully`,
  legUpdateWarning: "Please update the opened leg first",
  500: "Internal Server Error",
  501: "No services are available currently",
  1: "Phone Number already exists",
  2: "User already exists",
  3: "Phone already exists.",
  400: "Bad request",
  1007: "You are not authorized to request a flight. Please contact us for further assistance",
  1006: "Itinerary already updated. Please refresh it",
  paxCountAboveCapacity:
    "Aircraft capacity does not allow you to add more passengers",
  firstNameRequired: "First Name is required",
  lastNameRequired: "Last Name is required",
  emailRequired: "Please enter email address",
  emailIncorrect: "Email is incorrect",
  emailDoesntExists: "The provided email address does not exist",
  passwordRequired: "Password is required",
  passwordValid: `Passwords should contain at least 8 characters,
                 with 1 uppercase letter and 1 numeric`,
  passwordMatch: "Password does not match",
  phoneRequired: "Phone No. is required",
  phoneIncorrect: "Phone No. is incorrect",
  countryRequired: "Country is required",
  countryInvalid: "Invalid Country",
  termsRequired: "Please Agree to Terms & Conditions",
  titleRequired: "Title is required",
  singupSuccess: "Signup successful",
  telephoneAlreadyExists: `There is already an account registered to this phone number.
   Please use a different number`,
  userAlreadyExists: `There is already an account registered to this email id.
   Please use another email id or log in to continue`,
};

export const INVALID_DATE_MSG = 'Invalid date of birth';
