import axios from "axios";
import { GET_AIRCRAFTS_URL } from "../../configs/constants";
import { parseString } from "../../utils/parserUtils";
import status from "../../configs/status";
import {
  getAircraftsInit,
  getAircraftsSuccess,
  getAircraftsFailure,
} from "../actions";
import noramlizeAircrafts from "../../normalizers/AircraftNormalizer";

export const getAircraftsList = () => dispatch => {
  dispatch(getAircraftsInit());
  dispatch(getAircraftsFailure());

//  API call always fails due to issue in API. So, commenting out the API call and using the mock data.
/*   axios
    .get(GET_AIRCRAFTS_URL)
    .then(response => {
      if (response.status === status.OK) {
        parseString(response.data, (parseErr, result) => {
          if (parseErr) {
            dispatch(getAircraftsFailure());
          } else {
            const normalizedAircrafts = noramlizeAircrafts(result);
            //console.log(normalizedAircrafts);
            dispatch(getAircraftsSuccess(normalizedAircrafts));
          }
        });
      }
    })
    .catch(err => {
      dispatch(getAircraftsFailure(err));
    }); */
};
