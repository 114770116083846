
import { USER_ACCESS_TYPES } from '../constants/UserAccessTypes';
import { MEMBERSHIP_STATUS } from '../constants/MembershipConstants';
import { existInLocal, getFromLocal } from '../utils/cache';
import store from '../store/store';

export const getUserAccessType = user => {
  return user && user.accessType;
};

export const getToken = user => {
  if (user) {
    return user.userToken;
  }
  return ((store.getState().user || {}).user || {}).userToken || '';
};

export const getUser = () => (
  (store.getState().user || {}).user || {}
)

export const getMembershipStatus = user => {
  return user && user.membershipStatus;
};

// only used for topup vj direct user
export const getAccountId = user => {
  return user && getVJAccountId(user);
};

export const getVJAccountId = user => {
  if (user && user.accounts) {
    const vjAccount = user.accounts.filter(
      account => account.typeId === USER_ACCESS_TYPES.MEMBER
        && account.membershipStatus === MEMBERSHIP_STATUS.ACTIVE,
    );

    return user && vjAccount && vjAccount[0] && vjAccount[0].id;
  }

  return user.accountId;
};

export const getAdhocAccountId = user => {
  if (user && user.accounts) {
    const adhocAccount = user.accounts.filter(
      account => account.typeId === USER_ACCESS_TYPES.MEMBER
        && account.membershipStatus === MEMBERSHIP_STATUS.ADHOC,
    );

    if (adhocAccount && adhocAccount.length) {
      return adhocAccount[0].id;
    }
  }

  return user.accountId;
};

export const isLoggedIn = user => {
  return !!getToken(user);
};

export const isVJ2 = user => {
  return isLoggedIn(user) && isMembershipActive(user);
};

/**
    An FSP Direct user is:
    1. an FSP user i.e. has accessType = 1
    2. also has a VJ Direct account
* */
export const isFspDirect = user => {
  return isFSP(user) && user.hasVjDirectAccount;
};
/**
 * A simulate user could be:
 * 1 - visitor, not logged
 * 2 - guest, logged and access type equals to 0
 * 3 - a member user (access type equals to 9) but with no actived membership status
 */
export const isSimulateUser = user => {
  return (
    (!isLoggedIn(user)
      || getUserAccessType(user) === USER_ACCESS_TYPES.GUEST
      || (isMembershipUser(user)
        && getMembershipStatus(user) !== MEMBERSHIP_STATUS.ACTIVE))
    && !isAdhoc(user)
  );
};

export const isMembershipUser = user => {
  return getUserAccessType(user) === USER_ACCESS_TYPES.MEMBER;
};

export const isMembershipActive = user => {
  return (
    isMembershipUser(user)
    && getMembershipStatus(user) === MEMBERSHIP_STATUS.ACTIVE
  );
};

export const isMembershipWithoutConfirmation = user => {
  return (
    isLoggedIn(user)
    && !isGuest(user)
    && !isMembershipActive(user)
    && !isAdhoc(user)
    && !isFspDirect(user)
    && !isFSP(user)
  );
};

export const isMembershipPaymentInProcess = user => {
  return (
    isLoggedIn(user)
    && isGuest(user)
    && getMembershipStatus(user) === MEMBERSHIP_STATUS.NEW
  );
};

export const isFSP = user => {
  return isLoggedIn(user) && getUserAccessType(user) === USER_ACCESS_TYPES.FSP;
};

export const isAdhoc = user => {
  return (
    isLoggedIn(user)
    && isMembershipUser(user)
    && getMembershipStatus(user) === MEMBERSHIP_STATUS.ADHOC
  );
};

export const isBroker = user => {
  return (
    isLoggedIn(user)
    && getUserAccessType(user) === USER_ACCESS_TYPES.ON_DEMAND
  );
};

export const isGuest = user => {
  return (
    isLoggedIn(user)
    && getUserAccessType(user) === USER_ACCESS_TYPES.GUEST
  );
};

// TODO provide constants for user verification status code
export const isVerified = user => {
  if (isLoggedIn() && user) {
    return (
      user.verificationStatusCode !== 2 && user.verificationStatusCode !== 4
    );
  }
  return false;
};

export const mustChangePassword = user => {
  return isLoggedIn(user) && user && user.isChangedPassword;
};

export const getUserName = user => {
  return user && user.email;
};

export const getMembershipTypeId = user => {
  return user && user.membershipTypeId;
};

export const getUserId = user => {
  return user && user.id;
};

export const getPersonId = user => {
  return user && user.personId;
};

export const getUserHasAssociateAccount = user => {
  return user.accessType !== USER_ACCESS_TYPES.GUEST;
};

export const getUserInfo = (value) => {
  const userInfo = {
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.username,
    personPhoneNumbers: value.personPhoneNumbers,
    accounts: value.accounts,
  };
  return userInfo;
};

/*FIXME:: Please! This shouldn't be done in this way! have to give a demo*/
export const removeOffersForFSP = () => {
  if (getFromLocal('user', true, true)) {
    const user = getFromLocal('user', true, true);
    if (isFSP(user.user) && !isFspDirect(user.user)) {
      if (window.jQuery) {
        window.jQuery('a[href$="/offers/"]').remove();
        window.jQuery('a[href$="/direct-registration/"]').remove();
        window.jQuery("#spa-offers-with-map-container").parent().parent().prev().remove();
        window.jQuery("#spa-offers-with-map-container").parent().parent().remove();
      }
    }
    /* For VJ Direct and FSP Direct Join Direct button will be hidden*/
    if (getUserAccessType(user.user) === USER_ACCESS_TYPES.MEMBER && !isAdhoc(user.user) || isFspDirect(user.user)) {
      if (window.jQuery) {
        window.jQuery('a[href$="/direct-registration/"]').remove();
      }
    }
  }
};

export const isFSPAccountExpire = (user, localStorageKey) => {

  const isFSPAccountExpireTrue =
    user && user.accounts && !(user.customerId === undefined || user.customerId.length == 0)
    && !isAdhoc(user)
    && !existInLocal(localStorageKey)
    && (user.accounts.length === 0 || (user.accounts.length === 1 && !user.accounts[0].isPreferred));
  if (isFSPAccountExpireTrue) {
    return true;
  }
  else {
    return false;
  }
}

export const isVJDirectAccountExpire = (user, localStorageKey) => {
  if (user
    && (user.accounts && user.accounts.length === 1)
    && !isFspDirect(user)
    && !isFSP(user)
    && !existInLocal(localStorageKey)
    && (user.accounts[0].membershipStatus === MEMBERSHIP_STATUS.SUSPENDED ||
      user.accounts[0].membershipStatus === MEMBERSHIP_STATUS.EXPIRED)) {
    return true;
  }
  else {
    return false;
  }

}

export const canAccessStatement = (user = {}) =>
  user.canAccessStatement;
