import {
  validateSalutation,
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validatePassword,
  validateRepeatPassword,
  validateCheckBox,
} from './Validators';

export default function validateRegistrationForm(obj) {
  const salutationResponse = validateSalutation(obj.salutation.value);
  const firstNameResponse = validateFirstName(obj.firstName);
  const lastNameResponse = validateLastName(obj.lastName);
  const emailResponse = validateEmail(obj.email);
  const phoneNumberResponse = validatePhone(obj.phoneNumber, obj.isPhoneNumberValid);
  const passwordResponse = validatePassword(obj.password);
  const confirmPasswordResponse = validateRepeatPassword(obj.confirmPassword, obj.password);
  // const marketingCheckBoxResponse = validateCheckBox(obj.marketingCheckbox);
  const agreementCheckboxResponse = validateCheckBox(obj.agreementCheckbox);
  const res = {
    errors: {},
  };
  if (salutationResponse.isError) {
    res.isError = salutationResponse.isError;
    res.errors.salutation = salutationResponse.message;
  }
  if (firstNameResponse.isError) {
    res.isError = firstNameResponse.isError;
    res.errors.firstName = firstNameResponse.message;
  }
  if (lastNameResponse.isError) {
    res.isError = lastNameResponse.isError;
    res.errors.lastName = lastNameResponse.message;
  }
  if (emailResponse.isError) {
    res.isError = emailResponse.isError;
    res.errors.email = emailResponse.message;
  }
  if (phoneNumberResponse.isError) {
    res.isError = phoneNumberResponse.isError;
    res.errors.phoneNumber = phoneNumberResponse.message;
  }
  if (passwordResponse.isError) {
    res.isError = passwordResponse.isError;
    res.errors.password = passwordResponse.message;
  }
  if (confirmPasswordResponse.isError) {
    res.isError = confirmPasswordResponse.isError;
    res.errors.confirmPassword = confirmPasswordResponse.message;
  }
  // if (marketingCheckBoxResponse.isError) {
  //   res.isError = marketingCheckBoxResponse.isError;
  //   res.errors.marketingCheckbox = marketingCheckBoxResponse.message;
  // }
  if (agreementCheckboxResponse.isError) {
    res.isError = agreementCheckboxResponse.isError;
    res.errors.agreementCheckbox = agreementCheckboxResponse.message;
  }
  return res;
}
