import fetchTerms from '../sources/TermsSource';
import * as types from '../actionTypes';

export const fetchTermsInit = () => ({
  type: types.FETCH_TERMS_INIT,
});

export const fetchTermsSuccess = data => ({
  type: types.FETCH_TERMS_SUCCESS,
  data,
});

export const fetchTermsFail = () => ({
  type: types.FETCH_TERMS_FAILURE,
});

export const fetchTermsAction = () => fetchTerms();
