import { guaranteeArray } from "../utils/arrayUtils";

export function normalizeRestrictions(restrictions) {
  const data = [];
  const restriction = guaranteeArray(restrictions.restriction);
  restriction.forEach((rest) => {
    data.push(rest.description);
  });
  return data;
}

export function normalizeProposals(proposals) {
  const changes = [];
  const proposal = guaranteeArray(proposals.proposal);
  proposal.forEach((prop) => {
    changes.push(prop.description);
  });
  return changes;
}

export function normalizeValidateFlightResponse(response) {
  const data = [];
  const legs = guaranteeArray(response.flightLegs.flightLeg);
  legs.forEach((leg) => {
    const {
      flightLegId, introductionToSuggestion, restrictions, proposals,
    } = leg;
    const errors = {
      flightLegId: +flightLegId,
      introductionToSuggestion: introductionToSuggestion ? introductionToSuggestion : '',
      restrictions: restrictions ? normalizeRestrictions(restrictions) : [],
      proposals: proposals ? normalizeProposals(proposals) : [],
    };
    data.push(errors);
  });
  return data;
}
