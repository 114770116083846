import React, { Component } from 'react';
import { greatPlaceStyle } from './MapStyle';

const PinArrival = '/legacy/Static/img/icons/vistajet_marker.svg';
const PinDepLeft = '/legacy/Static/img/icons/pin-dep-left.svg';
const PinDepRight = '/legacy/Static/img/icons/vistajet_map_marker.svg';

const PinReturn = '/legacy/Static/img/icons/return-leg-pin.svg';
export default class MapMarker extends Component {
  getMarkerImg = () => {
    let img = '';
    if (this.props.type === 'to') {
      if (this.props.side === 'return') {
        img = PinReturn;
      } else {
        img = PinArrival;
      }
    } else if (this.props.side === 'return') {
      img = PinReturn;
    } else if (this.props.side === 'right') {
      img = PinDepRight;
    } else {
      img = PinDepLeft;
    }
    if (this.props.isListing) {
      img = PinArrival;
    }
    return img;
  };

  render() {
    const markerURL = this.getMarkerImg();
    let arrivalStyle = {};
    if (markerURL === PinArrival) {
      arrivalStyle = {
        left: -6,
        top: -6,
      }
    }
    return (
      <div>
        <img src={markerURL} style={{ ...greatPlaceStyle, ...arrivalStyle }} alt="" role="presentation" />
      </div>
    );
  }
}
