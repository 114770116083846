import * as types from '../actionTypes';
import {
  fetchPushNotification,
  updatePushNotifications,
} from '../sources/PushNotificationSource';

export const getPushNotificationInit = () => ({
  type: types.GET_PUSH_NOTIFICATION_INIT,
});

export const getPushNotificationSuccess = data => ({
  type: types.GET_PUSH_NOTIFICATION_SUCCESS,
  data,
});

export const getPushNotificationFailure = data => ({
  type: types.GET_PUSH_NOTIFICATION_FAILURE,
  data,
});

export const updatePushNotificationInit = () => ({
  type: types.UPDATE_PUSH_NOTIFICATION_INIT,
});

export const updatePushNotificationSuccess = data => ({
  type: types.UPDATE_PUSH_NOTIFICATION_SUCCESS,
  data,
});

export const updatePushNotificationFailure = data => ({
  type: types.UPDATE_PUSH_NOTIFICATION_FAILURE,
  data,
});

export const updateDisableCitiesCheck = data => ({
  type: types.UPDATE_PUSH_NOTIFICATION_DISABLE_CITY_CHECK,
  data,
});

export const fetchPushNotificationAction = (token) => fetchPushNotification(token);
export const updatePushNotificationAction = (
  token,
  notifications,
  cities,
  disableToggle,
) => updatePushNotifications(
  token,
  notifications,
  cities,
  disableToggle,
);
