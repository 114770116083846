import { postContactUsForm } from "../sources/ContactUsSource";
import * as types from '../actionTypes';

export const postContactUsInit = () => ({
  type: types.POST_CONTACTUS_INIT
});

export const postContactUsSuccess = data => ({
  type: types.POST_CONTACTUS_SUCCESS,
  data
});

export const postContactUsFail = data => ({
  type: types.POST_CONTACTUS_FAIL,
  data
});

export const clearReducer = () => ({
  type: types.POST_CONTACTUS_REFRESH
});

export const postContactUs = payload => postContactUsForm(payload);
