import moment from 'moment';
import { EMAIL_REGEX, NAME_REGEX } from 'constants/SignupConstants';

let instance = null;
class Validator {


  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  validEmail(email) {
    return (!EMAIL_REGEX.test(email));
  }
  validPhoneNo(phoneno) {
    const rePhone = /^[\+]?[(]?[0-9-]{8,14}$/;
    return !(rePhone.test(phoneno));
  }

  onlyAlphaNumeric(stringToTest) {
    const rePhone = /^[a-z0-9]+$/i;
    return !(rePhone.test(stringToTest));
  }

  validName(name) {
    return (!NAME_REGEX.test(name));
  }

  validRequired(value) {
    return !value || !value.toString().trim();
  }

  isInvalidDate(value) {
    return !value || value === 'Invalid date';
  }

  validPax(value) {
    return +value === 0;
  }

  validAircraft(value) {
    return !(value || {}).displayName;
  }

  validTimeZone(date, airport) {
    let isValid = true;
    if (!!airport && !!date && date !== 'Invalid date') {
      const time = moment.utc(date);
      airport.timezones.forEach((data) => {
        if (time.isBetween(data.effectiveFromTimeUtc, data.effectiveToTimeUtc) ||
                  time.isSame(data.effectiveFromTimeUtc)) {
          isValid = false;
        }
      });
      return isValid;
    }
    return false;
  }

  validAgreement(check) {
    return !check;
  }

  matchPassword(pass1, pass2) {
    return pass1 !== pass2;
  }

  checkCharacterLimit(value, maxLimit, minLimit = 0) {
    return value.length >= minLimit && value.length <= maxLimit;
  }

  validateDuration(duration, aircraftTime) {
    return (!isNaN(+duration) && (+duration > +aircraftTime));
  }

  // is given date string is after current date
  dateInFuture(dateString, res) {
    const resolution = res || 'day';
    const momentObj = moment(dateString).utc();
    return momentObj.isAfter(moment(), resolution);
  }

  // is given date string is before current date
  dateInPast(dateString, res) {
    const resolution = res || 'day';
    const momentObj = moment(dateString);
    return momentObj.isBefore(moment(), resolution);
  }

  isSameMinDate(date1, date2) {
    let dateOne = date1;
    let dateTwo = date2;
    if (!moment.isMoment(date1)) {
      dateOne = moment(date1);
    }
    if (!moment.isMoment(date2)) {
      dateTwo = moment(date2);
    }
    return dateOne.isSame(dateTwo, 'day');
  }

  /* @akram
  * Check if given dates are same
  *
  * @param date1: string OR Object
  * @param date2: string OR Object
  * @returns bool
  */
  isSameDate(date1, date2) {
    const d1 = (date1 !== null && typeof date1 === 'object') ? date1.format() : date1;
    const d2 = (date2 !== null && typeof date2 === 'object') ? date2.format() : date2;
    return (d1 === d2);
  }

  isDateValid(val, state) {
    return !this.isSameDate(val, state) &&
    (moment(val, ['DD/MM/YYYY', 'DD MMM YYYY'], true).isValid() || val === '');
  }

  isValidDate(date) {
    return (moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', 'YYYY MM DD',
      'MM DD YYYY'], true).isValid() || date === '');
  }
}

const ValidatorObj =  new Validator(); // singleton

export default ValidatorObj;
