const getTopUpEmailBody = (info, customerName) => {
  const {
    bankName,
    currency,
    iban,
    referenceCode,
    swift,
    to,
    topUpAmount,
  } = info;

  return (
    `Hello,\n\nI would like to top up my VistaJet Direct flight credit.` +
    `\nPlease transfer ${currency} ${topUpAmount} to the following account as soon as possible.` +
    `\n\nTo: ${to}` +
    `\nIBAN: ${iban}` +
    `\nSwift: ${swift}` +
    `\nBank Name: ${bankName}` +
    `\n\nPlease use reference ${referenceCode} when making the transfer to VistaJet.` +
    `\n\nThank you,` +
    `\n${customerName}`
  );
};
export default getTopUpEmailBody;
