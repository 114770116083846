// whether the @param `str` is string, properly defined and not null
// it will captilize its first letter, otherwise it returns an empty string
export function capitalize(str) {
  if (!!str && str.constructor === String) {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  return '';
}

export function convertToTitleCase(str) {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function getFirstChars(str, length, status = false) {
  const regex = new RegExp(`[\\s\\S]{1,${length}}`, 'g');
  const substring = str.match(regex);
  if (substring && substring.length > 0) {
    if (status) {
      if (substring[0].lastIndexOf('+') === 1) {
        const subStringToReturn = substring[0].substr(1, substring[0].length);
        return subStringToReturn;
      }
    }
    return substring[0];
  }
  return '';
}

export function replaceSpecialCharacterAndSpaces(str) {
  return str.replace(/[^A-Z0-9]+/ig, '');
}

export function concatenateStrings(str1, str2) {
  let str = null;
  if (str1 && str2) {
    str = `${str1} ${str2}`;
    return convertToTitleCase(str);
  }
  if (str1) {
    str = `${str1}`;
    return convertToTitleCase(str);
  }
  if (str2) {
    str = `${str2}`;
    return convertToTitleCase(str);
  }
  return str;
}
