const token = "7bcc4f7290d440468732d30c6d6cb235";

export const APP_VERSION = "0.1.0";

export const ENCRYPTION_SECRET = process.env.REACT_APP_ENCRYPTION_SECRET;

export const SALT = "!,9>@V{j~]";

export const API_URL = process.env.REACT_APP_API_URL;

export const APP_SOURCE = "CUSTOMERWEB";

export const CONTEXT = "customerapp";

export const DEFAULT_FLAG = '588842CD-8CBB-85D1-35ED-C73A4CE9E64B';

export const SIGNUP_CONTEXT = "U2FsdGVkX1+gjDqZR1ZHHWOGgMNIVc1V0yaaqjOeRZYswpJfDOM65gq99ltIN34b";

export const SIGNUP_CONTEXT_PASSWORD = "U2FsdGVkX1+utXyWMXwCbSv0eoKE8Iy3IVq7Hl5UEmk=";

//  webserviceqa.vistajet.com shouldn't be used for generally testing in any environment
//  it should only be used if testing against an API change and preparing the app for that change
const webservice = window.globalEnv && window.globalEnv.toLowerCase() === "production" ? process.env.REACT_APP_WEB_SERV : process.env.REACT_APP_WEB_PREP;

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const BASE_URL = `https://${webservice}.vistajet.com/extranetServices/`;

export const BASE_SERVICE_URL = `${BASE_URL}1.1/`;

const EPISERVER_VERSION = "api/episerver/v2.0";

const EPISERVER_FIELDS = "?fields=apiContent";

const FORECAST_API_KEY = process.env.REACT_APP_FORECAST_API_KEY;

export const CUSTOMER_APP_ID = 12;

export const LOGIN_URL = `${API_URL}/login`;

export const REGISTER_URL = `${BASE_URL}1.5/customerapp/customerappadmin/{token}/user/register?mode={mode}`;
export const GET_AIRCRAFTS_URL = `${BASE_URL}1.1/customerapp/customerapp/${token}/aircrafttype/updates`;
export const TOPUP_REFERENCE = `${BASE_URL}1.1/customerapp/customerapp/{token}/topUpReference`;
export const TOPUP_SUBMITTED = `${BASE_URL}1.1/customerapp/customerapp/{token}/topUp`;
export const TOPUP_CANCEL_REFERENCE = `${BASE_URL}1.1/customerapp/customerapp/{token}/cancelReferenceCode`;
export const TOPUP_HISTORY = `${BASE_URL}1.1/customerapp/customerapp/{token}/topUpHistory`;
export const GET_TOKEN_URL = `${BASE_SERVICE_URL}user/{context}/{username}/token/`;
export const AUTHENTICATE_URL = `${BASE_URL}1.6/user/{context}/{username}/authenticate?source=CUSTOMERWEB`;
export const FORGET_PASSWORD_URL = `${BASE_URL}1.1/user/{context}/{email}/requestNewPassword`;
export const FETCH_MEMBERSHIP_PLANS = `${BASE_URL}1.0/pricer/customerapp/{token}/membershipPlans`;
export const MEMBERSHIP_SIGN_UP = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/signup`;
export const MEMBERSHIP_WITHOUT_CARD_SIGN_UP = `${BASE_URL}1.2/customerapp/${CONTEXT}/{token}/signup`;
export const LOGOUT_URL = `${BASE_SERVICE_URL}user/${CONTEXT}/{token}/logout/V2/`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}1.1/user/{context}/{username}/password/?source=CUSTOMERWEB`;
export const FETCH_OFFERS_URL = `${BASE_URL}1.3/pricer/${CONTEXT}/${token}/offer-opportunities`;
export const FETCH_VISITED_AIRPORTS_URL = `${BASE_URL}1.0/msairportdata/${CONTEXT}/visitedAirport`;

// TODO: Add enviroment
export const FETCH_SUGGEST_AIRPORTS_URL = "https://api.flyxo.com/airports/suggest_airport";
export const FETCH_AIRPORT_BY_ID = `${BASE_URL}1.0/trendingairports/${CONTEXT}/airportById`;
export const REQUEST_QUOTE_URL = `${BASE_URL}1.5/customerapp/${CONTEXT}/{token}/requestflight`;
export const DOWNLOAD_PRIVATE_FILE_URL = `${BASE_URL}3.0/files/getfile/${CONTEXT}/{token}?mode=download&fileKey={key}`;
export const CREATE_COMPANY_URL = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/createCompany`;
export const VALIDATE_FLIGHT_URL = `${BASE_URL}1.2/opsrestrictions/customerapp/{token}/validateFlight`;
export const REQUEST_FLIGHT_2_0_URL = `${BASE_URL}1.6/customerapp/${CONTEXT}/{token}/requestflight/`;
export const QUOTE_URL = `${BASE_URL}1.0/pricer/${CONTEXT}/{token}/quote/`;
export const MANAGE_USER_PROFILE = `${BASE_SERVICE_URL}customerapp/{CONTEXT}/{token}/passenger/manage/1.2?{params}`;
export const ACCOUNT_MEMBERSHIP_STATUS = `${BASE_URL}1.7/customerapp/{context}/{token}/accountStatus`;
export const FORECAST_URL = `https://api.forecast.io/forecast/${FORECAST_API_KEY}/{lat},{long},{time}`;
export const DETAIL_FORECAST_URL = `https://api.forecast.io/forecast/${FORECAST_API_KEY}/{lat},{long}`;

export const LIMITED_OFFER_FETCH = {
  pageNumber: 1,
  pageSize: 3
};

export const HEADER = {
  "Accept-Language": "en"
};

export const HEADLESS_API_BASE_URL =
  "http://vjin01mstrx3y70inte.dxcloud.episerver.net/";



export const contactusEnv = "dev";

const currentEnv = "PROD";

export const INVOICES_PER_PAGE = 5;

export const ADD_CREDIT_CARD_URL = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/creditCardManagement/`;
export const GET_CREDIT_CARD_LIST_URL = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/getCreditCardList/`;
export const DELETE_CREDIT_CARD_URL = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/deleteCreditCard/`;
export const GET_NOTIFICATION_OPTIONS = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/getNotificationOptions`;
export const UPDATE_NOTIFICATION_OPTIONS = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/updatePushNotificationOptions`;
export const SAVE_BILLING_ADDRESS_URL = `${BASE_URL}1.1/customerapp/${CONTEXT}/{token}/saveAddress`;
export const TRANSACTIONS_QUANTITY = 5;
export const ON_DEMAND_ACCOUNT_BALANCE_URL = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/membershipAccountStatement?lineItemsPerPage={lineItemsPerpage}&currentCount={currentCount}`;

export const FETCH_ITINERARIES_URL = `${BASE_URL}1.8/customerapp/${CONTEXT}/{token}/fetchItineraries?isUpcoming={isUpcoming}&pageNum={pageNum}`;
export const FETCH_CANCEL_ITINERARIES_URL = `${BASE_URL}1.0/customerapp/${CONTEXT}/{token}/itineraries/cancelled?pageNum={pageNum}`;

export const FETCH_ITINERARY_DETAILS_URL = `${BASE_URL}1.7/customerapp/{context}/{token}/itineraries/fetchItinerariesByOrderId/?{key}={uniqueIdentifier}`;
export const FETCH_ENTERTAINMENT_ISSUE_NUMBER = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/issues/current?tail-number={tailNumber}`;
export const FETCH_ENTERTAINMENT = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/issues/{issueNumber}?tail-number={tailNumber}`;
export const FETCH_FBO_URL = `${BASE_URL}1.7/customerapp/${CONTEXT}/{token}/fboDetails/{flightLegId}`;
export const CREW_INFO_URL = `${BASE_URL}1.6/customerapp/${CONTEXT}/{token}/crewInfo/{flightLegId}`;
export const ITINERARY_DETAIL_PDF = `${BASE_URL}1.0/itinerary/customerapp/{token}/pdf/{flightLegId}`;
export const FETCH_GROUND_SERVICES_URL = `${BASE_URL}1.6/customerapp/${CONTEXT}/{token}/serviceOptions/`;
export const ADD_GROUND_SERVICES_URL = `${BASE_URL}1.1/customerapp/${CONTEXT}/{token}/services/add?{key}={uniqueIdentifier}`;
export const SERVICES_AVAILED_URL = `${BASE_URL}1.6/customerapp/${CONTEXT}/{token}/servicesAvailed?{key}={flightLegId}`;
export const CATERING_STATE_URL = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/catering/cateringState/{flightId}`;
export const CATERING_PROPOSAL_URL = `${BASE_URL}1.2/customerapp/${CONTEXT}/{token}/catering/cateringProposal/{flightId}/`;
export const CATERING_SELECTION_PDF = `${BASE_URL}1.1/customerapp/customerapp/{token}/flightMenuURL/{flightLegId}`;

export const CUSTOMER_SELECTION_URL = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/catering/customerSelection`;
export const FETCH_MOVEMENT_MESSAGES = `${BASE_URL}1.2/customerapp/${CONTEXT}/{token}/retrieveAllMovementMessageByFlightId/{flightId}`;
export const FETCH_SINGLE_LEG_PAX = `${BASE_URL}1.6/customerapp/${CONTEXT}/{token}/passenger?{flightLegKey}={flightLegId}`;
export const FLIGHT_CANCEL_URL = `${BASE_URL}1.4/customerapp/${CONTEXT}/{token}/requestflight`;
export const FETCH_UNBILLED_ACCOUNT_BALANCE = `${BASE_SERVICE_URL}${CONTEXT}/{token}/fetchUnBilledFlights`;

export const FETCH_PROGRAM_ACCOUNT_BALANCE = `${BASE_SERVICE_URL}customerapp/customerapp/{token}/nextInvoiceHoursV2?invoicesPerPage=${INVOICES_PER_PAGE}&currentCount={currentCount}&aircraftTypeId={aircraftTypeId}`;

export const FETCH_ACCOUNT_STATEMENTS = `${BASE_SERVICE_URL}${CONTEXT}/customerapp/{token}/accountStatements?accountId={accountId}&aircraftTypeId={aircraftTypeId}`;

export const DOWNLOAD_ZIP_URL = `${BASE_URL}1.1/customerapp/${CONTEXT}/{token}/accountStatements/allPdfZipped?{key}`;

export const FETCH_STATEMENT_INVOICES = `${BASE_SERVICE_URL}${CONTEXT}/customerapp/{token}/accountStatementInvoices?accountStatementId={accountStatementId}&pageNumber={pageNumber}&pageSize={pageSize}`;

export const GET_PDF_FILE_URL = `${BASE_URL}3.0/files/getfile/${CONTEXT}/{token}?accountStatementId={id}`;

export const FETCH_ORDER_ID_BY_LEG = `${BASE_URL}1.6/customerapp/${CONTEXT}/{token}/itineraries/flightLeg?{key}={legId}`;


export const FETCH_SINGLE_LEG_FEEDBACK = `${BASE_URL}1.0/customerapp/${CONTEXT}/{token}/getFlightFeedback`;


export const SAVE_LEG_FEEDBACK = `${BASE_URL}1.0/customerapp/${CONTEXT}/{token}/saveFlightFeedback`;

export const GET_PUBLIC_TOKEN = `${BASE_URL}1.1/user/customerapp/${CONTEXT}/token`;

export const HTTP_HEADERS = {
  deviceInfo: 'WEB',
  os: navigator.platform,
  appBuildNr: APP_VERSION,
  // deviceUUID: Helpers.guid(),
  Accept: 'application/xml',
  'Content-Type': 'application/xml',
  // requestedTime: Helpers.getCurrentTimestamp(),
};
export const HTTP_HEADERS_JSON = Object.assign({}, HTTP_HEADERS, {
  Accept: 'application/json',
  'Content-Type': 'application/json',
});
export const FETCH_MY_QUOTES = `${BASE_URL}1.5/customerapp/${CONTEXT}/{token}/fetchQuotes?quoteStatus={quoteStatus}&pageNum={pageNum}`;

export const GET_BILLING_ADDRESS_URL = `${BASE_URL}1.1/customerapp/${CONTEXT}/{token}/getAddresses`;

export const SEARCH_PAX_URL = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/passenger/search?key={term}`;

export const MANAGE_PAX_URL = `${BASE_URL}1.2/customerapp/${CONTEXT}/{token}/passenger/manage/?{key}={uniqueIdentifier}`;

export const POST_FILE_URL = `${BASE_URL}2.0/files/postfile/${CONTEXT}/{token}?fileKey={key}`;

// URLs for ES File Security
export const GET_PUBLIC_FILE_URL = `${BASE_URL}1.2/files/getpublicfile?fileKey={key}`;

export const GET_PRIVATE_FILE_URL = `${BASE_URL}2.0/files/getfile/${CONTEXT}/{token}?fileKey={key}`;

export const WRITE_USER_SETTINGS = `${BASE_SERVICE_URL}customerapp/${CONTEXT}/{token}/update_general_setting/`;

export const CONTACTUS_URL =
  "https://cs84.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";


const enviorments = {
  PRE_PROD: "http://vjin01mstrx3y70prep.dxcloud.episerver.net",
  INTEGRATION: "http://vjin01mstrx3y70inte.dxcloud.episerver.net",
  PROD: window.location.origin
};

export const TERMS_POLICY_URLS = {
  SUPPLIER_POLICY: `${enviorments[currentEnv]
    }/${EPISERVER_VERSION}/content/2360${EPISERVER_FIELDS}`,
  ANTI_CORRUPTION_POLICY: `${enviorments[currentEnv]
    }/${EPISERVER_VERSION}/content/2372${EPISERVER_FIELDS}`,
  SPEAK_UP_POLICY: `${enviorments[currentEnv]
    }/${EPISERVER_VERSION}/content/2371${EPISERVER_FIELDS}`,
  INSIDER_TRADING_POLICY: `${enviorments[currentEnv]
    }/${EPISERVER_VERSION}/content/2368${EPISERVER_FIELDS}`,
  SANCTIONS_COMPLIANCE_POLICY: `${enviorments[currentEnv]
    }/${EPISERVER_VERSION}/content/2370${EPISERVER_FIELDS}`,
  ANTI_MONEY_LAUNDERING_POLICY: `${enviorments[currentEnv]
    }/${EPISERVER_VERSION}/content/2367${EPISERVER_FIELDS}`
};

export const TERMS_URL = `${enviorments[currentEnv]
  }/${EPISERVER_VERSION}/content/2258${EPISERVER_FIELDS}`;
export const TERMS_URL_PAGE = `/${window.epiSettings.language}/legal/`;

export const POLICY_URL = `${enviorments[currentEnv]
  }/${EPISERVER_VERSION}/content/2273${EPISERVER_FIELDS}`;

// URLS redirection


export const POLICY_URL_PAGE = `/${window.epiSettings.language}/privacy-policy/{headingId}`;
export const MEMBRSHIP_PROGRAM_URL_PAGE = `/${window.epiSettings.language}/memberships/program/`;

export const DIRET_REGISTRATION_PAGE_URL = {
  url: `/${window.epiSettings.language}/direct-registration/`
};
export const CONFIRM_REGISTER_PAGE_URL = {
  url: `/${window.epiSettings.language}/registration/success/` // eslint-disable-line
};
export const DIRECT_CONFIRM_REGISTER_PAGE_URL = {
  url: `/${window.epiSettings.language}/direct-registration/success/` // eslint-disable-line
};

export const PAF_PAGE_URL = `/${window.epiSettings.language}/account/plan-a-flight/`;
export const MODIFY_ORDER_PAGE_URL = `/${window.epiSettings.language}/account/modify-order/`;
export const RAF_PAGE_URL = `/${window.epiSettings.language}/account/request-a-flight/`;
export const UPCOMING_ITINERARIES_PAGE_URL = `/${window.epiSettings.language}/account/itineraries/`;
export const PAST_ITINERARIES_PAGE_URL = `/${window.epiSettings.language}/account/itineraries/past/`;
export const CANCELLED_ITINERARIES_PAGE_URL = `/${window.epiSettings.language}/account/itineraries/cancelled/`;
export const HOME_PAGE_URL = `/${window.epiSettings.language}/`;
export const OFFERS_PAGE_URL = `/${window.epiSettings.language}/offers/`;
export const PAS_PAGE_URL = `/${window.epiSettings.language}/account/program-statement/`;
export const ACTIVE_QUOTES_PAGE_URL = `/${window.epiSettings.language}/account/quotes/`;
export const EXPIRED_QUOTES_PAGE_URL = `/${window.epiSettings.language}/account/quotes/expired/`;
export const QUOTE_SUMMARY_PAGE_URL = `/${window.epiSettings.language}/account/quotes/summary/`;
export const CONTACT_US_PAGE_URL = `/${window.epiSettings.language}/contact-us/`;

export const AIRCRAFT_URLS = {
  LINK_TO_CHALLENGER_350: `/${window.epiSettings.language}/private-jets/challenger-350/`,
  LINK_TO_CHALLENGER_605: `/${window.epiSettings.language}/private-jets/challenger-605/`,
  LINK_TO_CHALLENGER_850: `/${window.epiSettings.language}/private-jets/challenger-850/`,
  LINK_TO_GLOBAL_5000: `/${window.epiSettings.language}/private-jets/global-5000/`,
  LINK_TO_GLOBAL_XRS_6000: `/${window.epiSettings.language}/private-jets/global-6000/`,
  LINK_TO_GLOBAL_XRS_7500: `/${window.epiSettings.language}/private-jets/global-7500/`,
};

export const ENTERTAINMENT_DETAILS_PAGE_URL = {
  url: `/${window.epiSettings.language}/account/itineraries/summary/entertainment/?/{key}/{orderExtendifier}/{flightLegExtendifier}/{itineraryType}/entertainment/{tailNumber}`
};
export const ITINERARY_DETAILS_PAGE_URL = {
  url: `/${window.epiSettings.language}/account/itineraries/summary/?/{key}/{orderExtendifier}/{flightLegExtendifier}/{itineraryType}`
};
export const TOPUP_HISTORY_PAGE_URL = {
  url: `/${window.epiSettings.language}/account/balance/transactions/`,
};
export const TOPUP_ACCOUNT_PAGE_URL = {
  url: `/${window.epiSettings.language}/account/balance/top-up/`,
};
export const DIRECT_ACCOUNT_BALANCE_PAGE_URL = {
  url: `/${window.epiSettings.language}/account/balance/`,
};
export const USER_PROFILE_PAGE = {
  url: `/${window.epiSettings.language}/account/`
};
