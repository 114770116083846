export const stateList = {
  'Alaska': 'AK',
  'Alabama': 'AL',
  'Arkansas': 'AR',
  'Arizona': 'AZ',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'District of Columbia': 'DC',
  'Delaware': 'DE',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Guam': 'GU',
  'Hawaii': 'HI',
  'Iowa': 'IA',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Massachusetts': 'MA',
  'Maryland': 'MD',
  'Maine': 'ME',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Missouri': 'MO',
  'Mississippi': 'MS',
  'Montana': 'MT',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Nebraska': 'NE',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'Nevada': 'NV',
  'New York': 'NY',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Virginia': 'VA',
  'Vermont': 'VT',
  'Washington': 'WA',
  'Wisconsin': 'WI',
  'West Virginia': 'WV',
  'Wyoming': 'WY',
}

export const australiaStateList = {
  'Australian Capital Territory': 'ACT',
  'New South Wales': 'NSW',
  'Northern Territory': 'NT',
  'Queensland': 'QLD',
  'South Australia': 'SA',
  'Tasmania': 'TAS',
  'Victoria': 'VIC',
  'Western Australia': 'WA',
}

export const brazilStateList = {
  'Acre': 'AC',
  'Alagoas': 'AL',
  'Amazonas': 'AM',
  'Amapá': 'AP',
  'Bahia': 'BA',
  'Ceará': 'CE',
  'Distrito Federal': 'DF',
  'Espírito Santo': 'ES',
  'Goiás': 'GO',
  'Maranhão': 'MA',
  'Minas Gerais': 'MG',
  'Mato Grosso do Sul': 'MS',
  'Mato Grosso': 'MT',
  'Pará': 'PA',
  'Paraíba': 'PB',
  'Pernambuco': 'PE',
  'Piauí': 'PI',
  'Paraná': 'PR',
  'Rio de Janeiro': 'RJ',
  'Rio Grande do Norte': 'RN',
  'Rondônia': 'RO',
  'Roraima': 'RR',
  'Rio Grande do Sul': 'RS',
  'Santa Catarina': 'SC',
  'Sergipe': 'SE',
  'São Paulo': 'SP',
  'Tocantins': 'TO',
}

export const canadaStateList = {
  'Alberta': 'AB',
  'British Columbia': 'BC',
  'Manitoba': 'MB',
  'New Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Nova Scotia': 'NS',
  'Northwest Territories': 'NT',
  'Nunavut': 'NU',
  'Ontario': 'ON',
  'Prince Edward Island': 'PE',
  'Quebec': 'QC',
  'Saskatchewan': 'SK',
  'Yukon Territories': 'YT',
}

export const chinaStateList = {
  'Beijing': '11',
  'Tianjin': '12',
  'Hebei': '13',
  'Shanxi': '14',
  'Nei Mongol': '15',
  'Liaoning': '21',
  'Jilin': '22',
  'Heilongjiang': '23',
  'Shanghai': '31',
  'Jiangsu': '32',
  'Zhejiang': '33',
  'Anhui': '34',
  'Fujian': '35',
  'Jiangxi': '36',
  'Shandong': '37',
  'Henan': '41',
  'Hubei': '42',
  'Hunan': '43',
  'Guangdong': '44',
  'Guangxi': '45',
  'Hainan': '46',
  'Chongqing': '50',
  'Sichuan': '51',
  'Guizhou': '52',
  'Yunnan': '53',
  'Xizang': '54',
  'Shaanxi': '61',
  'Gansu': '62',
  'Qinghai': '63',
  'Ningxia': '64',
  'Xinjiang': '65',
  'Macao': '92',
}

export const indiaStateList = {
  'Andaman and Nicobar Islands': 'AN',
  'Andhra Pradesh': 'AP',
  'Arunachal Pradesh': 'AR',
  'Assam': 'AS',
  'Bihar': 'BR',
  'Chandigarh': 'CH',
  'Chhattisgarh': 'CT',
  'Daman and Diu': 'DD',
  'Delhi': 'DL',
  'Dadra and Nagar Haveli': 'DN',
  'Goa': 'GA',
  'Gujarat': 'GJ',
  'Himachal Pradesh': 'HP',
  'Haryana': 'HR',
  'Jharkhand': 'JH',
  'Jammu and Kashmir': 'JK',
  'Karnataka': 'KA',
  'Kerala': 'KL',
  'Lakshadweep': 'LD',
  'Maharashtra': 'MH',
  'Meghalaya': 'ML',
  'Manipur': 'MN',
  'Madhya Pradesh': 'MP',
  'Mizoram': 'MZ',
  'Nagaland': 'NL',
  'Odisha': 'OR',
  'Punjab': 'PB',
  'Puducherry': 'PY',
  'Rajasthan': 'RJ',
  'Sikkim': 'SK',
  'Tamil Nadu': 'TN',
  'Telangana': 'TG',
  'Tripura': 'TR',
  'Uttar Pradesh': 'UP',
  'Uttarakhand': 'UT',
  'West Bengal': 'WB',
}

export const irelandStateList = {
  'Clare': 'CE',
  'Cavan': 'CN',
  'Cork': 'CO',
  'Carlow': 'CW',
  'Dublin': 'D',
  'Donegal': 'DL',
  'Galway': 'G',
  'Kildare': 'KE',
  'Kilkenny': 'KK',
  'Kerry': 'KY',
  'Longford': 'LD',
  'Louth': 'LH',
  'Limerick': 'LK',
  'Leitrim': 'LM',
  'Laois': 'LS',
  'Meath': 'MH',
  'Monaghan': 'MN',
  'Mayo': 'MO',
  'Offaly': 'OY',
  'Roscommon': 'RN',
  'Sligo': 'SO',
  'Tipperary': 'TA',
  'Waterford': 'WD',
  'Westmeath': 'WH',
  'Wicklow': 'WW',
  'Wexford': 'WX',
}

export const italyStateList = {
  'Agrigento': 'AG',
  'Alessandria': 'AL',
  'Ancona': 'AN',
  'Aosta': 'AO',
  'Ascoli Piceno': 'AP',
  'L\'Aquila': 'AQ',
  'Arezzo': 'AR',
  'Asti': 'AT',
  'Avellino': 'AV',
  'Bari': 'BA',
  'Bergamo': 'BG',
  'Biella': 'BI',
  'Belluno': 'BL',
  'Benevento': 'BN',
  'Bologna': 'BO',
  'Brindisi': 'BR',
  'Brescia': 'BS',
  'Barletta-Andria-Trani': 'BT',
  'Bolzano': 'BZ',
  'Cagliari': 'CA',
  'Campobasso': 'CB',
  'Caserta': 'CE',
  'Chieti': 'CH',
  'Carbonia-Iglesias': 'CI',
  'Caltanissetta': 'CL',
  'Cuneo': 'CN',
  'Como': 'CO',
  'Cremona': 'CR',
  'Cosenza': 'CS',
  'Catania': 'CT',
  'Catanzaro': 'CZ',
  'Enna': 'EN',
  'Forlì-Cesena': 'FC',
  'Ferrara': 'FE',
  'Foggia': 'FG',
  'Florence': 'FI',
  'Fermo': 'FM',
  'Frosinone': 'FR',
  'Genoa': 'GE',
  'Gorizia': 'GO',
  'Grosseto': 'GR',
  'Imperia': 'IM',
  'Isernia': 'IS',
  'Crotone': 'KR',
  'Lecco': 'LC',
  'Lecce': 'LE',
  'Livorno': 'LI',
  'Lodi': 'LO',
  'Latina': 'LT',
  'Lucca': 'LU',
  'Monza and Brianza': 'MB',
  'Macerata': 'MC',
  'Messina': 'ME',
  'Milan': 'MI',
  'Mantua': 'MN',
  'Modena': 'MO',
  'Massa and Carrara': 'MS',
  'Matera': 'MT',
  'Naples': 'NA',
  'Novara': 'NO',
  'Nuoro': 'NU',
  'Ogliastra': 'OG',
  'Oristano': 'OR',
  'Olbia-Tempio': 'OT',
  'Palermo': 'PA',
  'Piacenza': 'PC',
  'Padua': 'PD',
  'Pescara': 'PE',
  'Perugia': 'PG',
  'Pisa': 'PI',
  'Pordenone': 'PN',
  'Prato': 'PO',
  'Parma': 'PR',
  'Pistoia': 'PT',
  'Pesaro and Urbino': 'PU',
  'Pavia': 'PV',
  'Potenza': 'PZ',
  'Ravenna': 'RA',
  'Reggio Calabria': 'RC',
  'Reggio Emilia': 'RE',
  'Ragusa': 'RG',
  'Rieti': 'RI',
  'Rome': 'RM',
  'Rimini': 'RN',
  'Rovigo': 'RO',
  'Salerno': 'SA',
  'Siena': 'SI',
  'Sondrio': 'SO',
  'La Spezia': 'SP',
  'Syracuse': 'SR',
  'Sassari': 'SS',
  'Savona': 'SV',
  'Taranto': 'TA',
  'Teramo': 'TE',
  'Trento': 'TN',
  'Turin': 'TO',
  'Trapani': 'TP',
  'Terni': 'TR',
  'Trieste': 'TS',
  'Treviso': 'TV',
  'Udine': 'UD',
  'Varese': 'VA',
  'Verbano-Cusio-Ossola': 'VB',
  'Vercelli': 'VC',
  'Venice': 'VE',
  'Vicenza': 'VI',
  'Verona': 'VR',
  'Medio Campidano': 'VS',
  'Viterbo': 'VT',
  'Vibo Valentia': 'VV',
}

export const mexicoStateList = {
  'Aguascalientes': 'AG',
  'Baja California': 'BC',
  'Baja California Sur': 'BS',
  'Chihuahua': 'CH',
  'Colima': 'CL',
  'Campeche': 'CM',
  'Coahuila': 'CO',
  'Chiapas': 'CS',
  'Federal District': 'DF',
  'Durango': 'DG',
  'Guerrero': 'GR',
  'Guanajuato': 'GT',
  'Hidalgo': 'HG',
  'Jalisco': 'JA',
  'Mexico State': 'ME',
  'Michoacán': 'MI',
  'Morelos': 'MO',
  'Nayarit': 'NA',
  'Nuevo León': 'NL',
  'Oaxaca': 'OA',
  'Puebla': 'PB',
  'Querétaro': 'QE',
  'Quintana Roo': 'QR',
  'Sinaloa': 'SI',
  'San Luis Potosí': 'SL',
  'Sonora': 'SO',
  'Tabasco': 'TB',
  'Tlaxcala': 'TL',
  'Tamaulipas': 'TM',
  'Veracruz': 'VE',
  'Yucatán': 'YU',
  'Zacatecas': 'ZA',
}

export default function GetStateList(stateFullName = '') {
  return stateList[stateFullName];
}
