import * as types from '../actionTypes';

const initialState = {
  isLoading: true,
  content: null,
};

export default function termsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TERMS_INIT: {
      return Object.assign({}, state, {
        content: null,
        isLoading: true,
      });
    }
    case types.FETCH_TERMS_SUCCESS: {
      return Object.assign({}, state, {
        content: action.data,
        isLoading: false,
      });
    }
    case types.FETCH_TERMS_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case types.FETCH_POLICY_INIT: {
      return Object.assign({}, state, {
        content: null,
        isLoading: true,
      });
    }
    case types.FETCH_POLICY_SUCCESS: {
      return Object.assign({}, state, {
        content: action.data,
        isLoading: false,
      });
    }
    case types.FETCH_POLICY_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    default:
      return state;
  }
}
