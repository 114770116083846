import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'utils/stringUtils';

import { Link } from 'react-scroll'
import { format, isAfter, isSameDay, isWithinInterval, parseISO } from 'date-fns';
import { dateFnsFormats } from 'utils/dateUtils';


export default class OfferCard extends Component {
  handlerOfferClick = () => {
    const { onCardClick, offer } = this.props;
    onCardClick(offer.id);
  };

  handleAvailability = (offer) => {
    let availableFrom = '';
    let availableTo = '';
    availableFrom = offer.isOneWay
      ? offer.segmentStart
      : offer.minDepartureTime;
    availableTo = offer.isOneWay ? offer.segmentEnd : offer.maxDepartureTime;

    return {
      availableFrom,
      availableTo,
    };
  };

  render() {
    const {
      arrivalAirport,
      departureAirport,
      activeCardId,
      offer,
      shouldScroll,
    } = this.props;
    const activeClass = activeCardId && activeCardId === offer.id ? 'vistajet_card_active' : '';

    const { availableFrom } = this.handleAvailability(offer);

    const today = new Date();
    const sameDay = isSameDay(today, parseISO(availableFrom)) || isAfter(today, parseISO(availableFrom)) ;

    const departureAirportCity = departureAirport && capitalize(departureAirport.city);
    const departureAirportName = departureAirport && capitalize(departureAirport.name);
    const arrivalAirportCity = arrivalAirport && capitalize(arrivalAirport.city);
    const arrivalAirportName = arrivalAirport && capitalize(arrivalAirport.name);

    const child = (
      <div
        className={`uk-card uk-card-body vistajet_card vistajet_card_dropoff ${activeClass}`}
      >
        <div className="vistajet_location_guide vistajet_location_truncate uk-float-left">
          {departureAirportCity}
          {' '}
          <span>
            {departureAirportName}
          </span>
        </div>
        <div className="vistajet_location_guide uk-float-right">
          {arrivalAirport ? (
            <div>
              {arrivalAirportCity}
              {' '}
              <span>
                {arrivalAirportName}
              </span>
            </div>
          ) : (
              <i>
                Anywhere
            </i>
            )}
        </div>
        {sameDay ? (
          <div className="vistajet_available_date uk-text-center">
            Available NOW
          </div>
        ) : (
            <div className="vistajet_available_date uk-text-center">
              Available from:
    {' '}
              <span>
                {format(parseISO(availableFrom), dateFnsFormats.DDMMMYYYY)}
              </span>
            </div>
          )}
      </div>
    );
    if (shouldScroll) {
      return (
        <Link to="map_container" spy={true} smooth={true} duration={1000}>
          <a onClick={this.handlerOfferClick}
          >
            {child}
          </a>
        </Link>
      )
    }
    return (
      <a onClick={this.handlerOfferClick}>
        {child}
      </a>
    );
  }
}
OfferCard.defaultProps = {
  arrivalAirport: null,
  activeCardId: null,
};

OfferCard.propTypes = {
  onCardClick: PropTypes.func.isRequired,
  offer: PropTypes.instanceOf(Object).isRequired,
  departureAirport: PropTypes.instanceOf(Object).isRequired,
  arrivalAirport: PropTypes.instanceOf(Object),
  activeCardId: PropTypes.number,
  shouldScroll: PropTypes.bool.isRequired,
};
