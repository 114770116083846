const countryData = [
  {
    COUNTRY_NAME: 'ARUBA',
    THREE_LETTER_CODE: 'ABW',
    TWO_LETTER_CODE: 'AW',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'AFGHANISTAN',
    THREE_LETTER_CODE: 'AFG',
    TWO_LETTER_CODE: 'AF',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'ANGOLA',
    THREE_LETTER_CODE: 'AGO',
    TWO_LETTER_CODE: 'AO',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'ANGUILLA',
    THREE_LETTER_CODE: 'AIA',
    TWO_LETTER_CODE: 'AI',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'ÅLAND ISLANDS',
    THREE_LETTER_CODE: 'ALA',
    TWO_LETTER_CODE: 'AX',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'ALBANIA',
    THREE_LETTER_CODE: 'ALB',
    TWO_LETTER_CODE: 'AL',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'ANDORRA',
    THREE_LETTER_CODE: 'AND',
    TWO_LETTER_CODE: 'AD',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'UNITED ARAB EMIRATES',
    THREE_LETTER_CODE: 'ARE',
    TWO_LETTER_CODE: 'AE',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'ARGENTINA',
    THREE_LETTER_CODE: 'ARG',
    TWO_LETTER_CODE: 'AR',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'ARMENIA',
    THREE_LETTER_CODE: 'ARM',
    TWO_LETTER_CODE: 'AM',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'AMERICAN SAMOA',
    THREE_LETTER_CODE: 'ASM',
    TWO_LETTER_CODE: 'AS',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'ANTARCTICA',
    THREE_LETTER_CODE: 'ATA',
    TWO_LETTER_CODE: 'AQ',
    REGION_NAME: 'Antarctica',
    REGION_ID: 17,
  },
  {
    COUNTRY_NAME: 'FRENCH SOUTHERN TERRITORIES',
    THREE_LETTER_CODE: 'ATF',
    TWO_LETTER_CODE: 'TF',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'ANTIGUA AND BARBUDA',
    THREE_LETTER_CODE: 'ATG',
    TWO_LETTER_CODE: 'AG',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'AUSTRALIA',
    THREE_LETTER_CODE: 'AUS',
    TWO_LETTER_CODE: 'AU',
    REGION_NAME: 'Australia + New Zealand',
    REGION_ID: 8,
  },
  {
    COUNTRY_NAME: 'AUSTRIA',
    THREE_LETTER_CODE: 'AUT',
    TWO_LETTER_CODE: 'AT',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'AZERBAIJAN',
    THREE_LETTER_CODE: 'AZE',
    TWO_LETTER_CODE: 'AZ',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'BURUNDI',
    THREE_LETTER_CODE: 'BDI',
    TWO_LETTER_CODE: 'BI',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'BELGIUM',
    THREE_LETTER_CODE: 'BEL',
    TWO_LETTER_CODE: 'BE',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'BENIN',
    THREE_LETTER_CODE: 'BEN',
    TWO_LETTER_CODE: 'BJ',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'BONAIRE, SINT EUSTATIUS AND SABA',
    THREE_LETTER_CODE: 'BES',
    TWO_LETTER_CODE: 'BQ',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'BURKINA FASO',
    THREE_LETTER_CODE: 'BFA',
    TWO_LETTER_CODE: 'BF',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'BANGLADESH',
    THREE_LETTER_CODE: 'BGD',
    TWO_LETTER_CODE: 'BD',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'BULGARIA',
    THREE_LETTER_CODE: 'BGR',
    TWO_LETTER_CODE: 'BG',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'BAHRAIN',
    THREE_LETTER_CODE: 'BHR',
    TWO_LETTER_CODE: 'BH',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'BAHAMAS',
    THREE_LETTER_CODE: 'BHS',
    TWO_LETTER_CODE: 'BS',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'BOSNIA AND HERZEGOVINA',
    THREE_LETTER_CODE: 'BIH',
    TWO_LETTER_CODE: 'BA',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SAINT BARTHÉLEMY',
    THREE_LETTER_CODE: 'BLM',
    TWO_LETTER_CODE: 'BL',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'BELARUS',
    THREE_LETTER_CODE: 'BLR',
    TWO_LETTER_CODE: 'BY',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'BELIZE',
    THREE_LETTER_CODE: 'BLZ',
    TWO_LETTER_CODE: 'BZ',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'BERMUDA',
    THREE_LETTER_CODE: 'BMU',
    TWO_LETTER_CODE: 'BM',
    REGION_NAME: 'North America',
    REGION_ID: 11,
  },
  {
    COUNTRY_NAME: 'PLURINATIONAL STATE OF BOLIVIA',
    THREE_LETTER_CODE: 'BOL',
    TWO_LETTER_CODE: 'BO',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'BRAZIL',
    THREE_LETTER_CODE: 'BRA',
    TWO_LETTER_CODE: 'BR',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'BARBADOS',
    THREE_LETTER_CODE: 'BRB',
    TWO_LETTER_CODE: 'BB',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'BRUNEI DARUSSALAM',
    THREE_LETTER_CODE: 'BRN',
    TWO_LETTER_CODE: 'BN',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'BHUTAN',
    THREE_LETTER_CODE: 'BTN',
    TWO_LETTER_CODE: 'BT',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'BOUVET ISLAND',
    THREE_LETTER_CODE: 'BVT',
    TWO_LETTER_CODE: 'BV',
    REGION_NAME: 'Antarctica',
    REGION_ID: 17,
  },
  {
    COUNTRY_NAME: 'BOTSWANA',
    THREE_LETTER_CODE: 'BWA',
    TWO_LETTER_CODE: 'BW',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'CENTRAL AFRICAN REPUBLIC',
    THREE_LETTER_CODE: 'CAF',
    TWO_LETTER_CODE: 'CF',
    REGION_NAME: 'Central Africa',
    REGION_ID: 13,
  },
  {
    COUNTRY_NAME: 'CANADA',
    THREE_LETTER_CODE: 'CAN',
    TWO_LETTER_CODE: 'CA',
    REGION_NAME: 'North America',
    REGION_ID: 11,
  },
  {
    COUNTRY_NAME: 'COCOS (KEELING) ISLANDS',
    THREE_LETTER_CODE: 'CCK',
    TWO_LETTER_CODE: 'CC',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'SWITZERLAND',
    THREE_LETTER_CODE: 'CHE',
    TWO_LETTER_CODE: 'CH',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'CHILE',
    THREE_LETTER_CODE: 'CHL',
    TWO_LETTER_CODE: 'CL',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'CHINA',
    THREE_LETTER_CODE: 'CHN',
    TWO_LETTER_CODE: 'CN',
    REGION_NAME: 'China',
    REGION_ID: 14,
  },
  {
    COUNTRY_NAME: "CÔTE D'IVOIRE",
    THREE_LETTER_CODE: 'CIV',
    TWO_LETTER_CODE: 'CI',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'CAMEROON',
    THREE_LETTER_CODE: 'CMR',
    TWO_LETTER_CODE: 'CM',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'THE DEMOCRATIC REPUBLIC OF THE CONGO',
    THREE_LETTER_CODE: 'COD',
    TWO_LETTER_CODE: 'CD',
    REGION_NAME: 'Central Africa',
    REGION_ID: 13,
  },
  {
    COUNTRY_NAME: 'CONGO',
    THREE_LETTER_CODE: 'COG',
    TWO_LETTER_CODE: 'CG',
    REGION_NAME: 'Central Africa',
    REGION_ID: 13,
  },
  {
    COUNTRY_NAME: 'COOK ISLANDS',
    THREE_LETTER_CODE: 'COK',
    TWO_LETTER_CODE: 'CK',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'COLOMBIA',
    THREE_LETTER_CODE: 'COL',
    TWO_LETTER_CODE: 'CO',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'COMOROS',
    THREE_LETTER_CODE: 'COM',
    TWO_LETTER_CODE: 'KM',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'CAPE VERDE',
    THREE_LETTER_CODE: 'CPV',
    TWO_LETTER_CODE: 'CV',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'COSTA RICA',
    THREE_LETTER_CODE: 'CRI',
    TWO_LETTER_CODE: 'CR',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'CUBA',
    THREE_LETTER_CODE: 'CUB',
    TWO_LETTER_CODE: 'CU',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'CURAÇAO',
    THREE_LETTER_CODE: 'CUW',
    TWO_LETTER_CODE: 'CW',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'CHRISTMAS ISLAND',
    THREE_LETTER_CODE: 'CXR',
    TWO_LETTER_CODE: 'CX',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'CAYMAN ISLANDS',
    THREE_LETTER_CODE: 'CYM',
    TWO_LETTER_CODE: 'KY',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'CYPRUS',
    THREE_LETTER_CODE: 'CYP',
    TWO_LETTER_CODE: 'CY',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'CZECH REPUBLIC',
    THREE_LETTER_CODE: 'CZE',
    TWO_LETTER_CODE: 'CZ',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'GERMANY',
    THREE_LETTER_CODE: 'DEU',
    TWO_LETTER_CODE: 'DE',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'DJIBOUTI',
    THREE_LETTER_CODE: 'DJI',
    TWO_LETTER_CODE: 'DJ',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'DOMINICA',
    THREE_LETTER_CODE: 'DMA',
    TWO_LETTER_CODE: 'DM',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'DENMARK',
    THREE_LETTER_CODE: 'DNK',
    TWO_LETTER_CODE: 'DK',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'DOMINICAN REPUBLIC',
    THREE_LETTER_CODE: 'DOM',
    TWO_LETTER_CODE: 'DO',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'ALGERIA',
    THREE_LETTER_CODE: 'DZA',
    TWO_LETTER_CODE: 'DZ',
    REGION_NAME: 'North Africa',
    REGION_ID: 2,
  },
  {
    COUNTRY_NAME: 'ECUADOR',
    THREE_LETTER_CODE: 'ECU',
    TWO_LETTER_CODE: 'EC',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'EGYPT',
    THREE_LETTER_CODE: 'EGY',
    TWO_LETTER_CODE: 'EG',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'ERITREA',
    THREE_LETTER_CODE: 'ERI',
    TWO_LETTER_CODE: 'ER',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'WESTERN SAHARA',
    THREE_LETTER_CODE: 'ESH',
    TWO_LETTER_CODE: 'EH',
    REGION_NAME: 'North Africa',
    REGION_ID: 2,
  },
  {
    COUNTRY_NAME: 'SPAIN',
    THREE_LETTER_CODE: 'ESP',
    TWO_LETTER_CODE: 'ES',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'ESTONIA',
    THREE_LETTER_CODE: 'EST',
    TWO_LETTER_CODE: 'EE',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'ETHIOPIA',
    THREE_LETTER_CODE: 'ETH',
    TWO_LETTER_CODE: 'ET',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'FINLAND',
    THREE_LETTER_CODE: 'FIN',
    TWO_LETTER_CODE: 'FI',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'FIJI',
    THREE_LETTER_CODE: 'FJI',
    TWO_LETTER_CODE: 'FJ',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'FALKLAND ISLANDS (MALVINAS)',
    THREE_LETTER_CODE: 'FLK',
    TWO_LETTER_CODE: 'FK',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'FRANCE',
    THREE_LETTER_CODE: 'FRA',
    TWO_LETTER_CODE: 'FR',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'FAROE ISLANDS',
    THREE_LETTER_CODE: 'FRO',
    TWO_LETTER_CODE: 'FO',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'FEDERATED STATES OF MICRONESIA',
    THREE_LETTER_CODE: 'FSM',
    TWO_LETTER_CODE: 'FM',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'GABON',
    THREE_LETTER_CODE: 'GAB',
    TWO_LETTER_CODE: 'GA',
    REGION_NAME: 'Central Africa',
    REGION_ID: 13,
  },
  {
    COUNTRY_NAME: 'UNITED KINGDOM',
    THREE_LETTER_CODE: 'GBR',
    TWO_LETTER_CODE: 'GB',
    REGION_NAME: 'United Kingdom',
    REGION_ID: 16,
  },
  {
    COUNTRY_NAME: 'GEORGIA',
    THREE_LETTER_CODE: 'GEO',
    TWO_LETTER_CODE: 'GE',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'GUERNSEY',
    THREE_LETTER_CODE: 'GGY',
    TWO_LETTER_CODE: 'GG',
    REGION_NAME: 'United Kingdom',
    REGION_ID: 16,
  },
  {
    COUNTRY_NAME: 'GHANA',
    THREE_LETTER_CODE: 'GHA',
    TWO_LETTER_CODE: 'GH',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'GIBRALTAR',
    THREE_LETTER_CODE: 'GIB',
    TWO_LETTER_CODE: 'GI',
    REGION_NAME: 'North Africa',
    REGION_ID: 2,
  },
  {
    COUNTRY_NAME: 'GUINEA',
    THREE_LETTER_CODE: 'GIN',
    TWO_LETTER_CODE: 'GN',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'GUADELOUPE',
    THREE_LETTER_CODE: 'GLP',
    TWO_LETTER_CODE: 'GP',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'GAMBIA',
    THREE_LETTER_CODE: 'GMB',
    TWO_LETTER_CODE: 'GM',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'GUINEA-BISSAU',
    THREE_LETTER_CODE: 'GNB',
    TWO_LETTER_CODE: 'GW',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'EQUATORIAL GUINEA',
    THREE_LETTER_CODE: 'GNQ',
    TWO_LETTER_CODE: 'GQ',
    REGION_NAME: 'Central Africa',
    REGION_ID: 13,
  },
  {
    COUNTRY_NAME: 'GREECE',
    THREE_LETTER_CODE: 'GRC',
    TWO_LETTER_CODE: 'GR',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'GRENADA',
    THREE_LETTER_CODE: 'GRD',
    TWO_LETTER_CODE: 'GD',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'GREENLAND',
    THREE_LETTER_CODE: 'GRL',
    TWO_LETTER_CODE: 'GL',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'GUATEMALA',
    THREE_LETTER_CODE: 'GTM',
    TWO_LETTER_CODE: 'GT',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'FRENCH GUIANA',
    THREE_LETTER_CODE: 'GUF',
    TWO_LETTER_CODE: 'GF',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'GUAM',
    THREE_LETTER_CODE: 'GUM',
    TWO_LETTER_CODE: 'GU',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'GUYANA',
    THREE_LETTER_CODE: 'GUY',
    TWO_LETTER_CODE: 'GY',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'HONG KONG',
    THREE_LETTER_CODE: 'HKG',
    TWO_LETTER_CODE: 'HK',
    REGION_NAME: 'China',
    REGION_ID: 14,
  },
  {
    COUNTRY_NAME: 'HEARD ISLAND AND MCDONALD ISLANDS',
    THREE_LETTER_CODE: 'HMD',
    TWO_LETTER_CODE: 'HM',
    REGION_NAME: 'Antarctica',
    REGION_ID: 17,
  },
  {
    COUNTRY_NAME: 'HONDURAS',
    THREE_LETTER_CODE: 'HND',
    TWO_LETTER_CODE: 'HN',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'CROATIA',
    THREE_LETTER_CODE: 'HRV',
    TWO_LETTER_CODE: 'HR',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'HAITI',
    THREE_LETTER_CODE: 'HTI',
    TWO_LETTER_CODE: 'HT',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'HUNGARY',
    THREE_LETTER_CODE: 'HUN',
    TWO_LETTER_CODE: 'HU',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'INDONESIA',
    THREE_LETTER_CODE: 'IDN',
    TWO_LETTER_CODE: 'ID',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'ISLE OF MAN',
    THREE_LETTER_CODE: 'IMN',
    TWO_LETTER_CODE: 'IM',
    REGION_NAME: 'United Kingdom',
    REGION_ID: 16,
  },
  {
    COUNTRY_NAME: 'INDIA',
    THREE_LETTER_CODE: 'IND',
    TWO_LETTER_CODE: 'IN',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'BRITISH INDIAN OCEAN TERRITORY',
    THREE_LETTER_CODE: 'IOT',
    TWO_LETTER_CODE: 'IO',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'IRELAND',
    THREE_LETTER_CODE: 'IRL',
    TWO_LETTER_CODE: 'IE',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'ISLAMIC REPUBLIC OF IRAN',
    THREE_LETTER_CODE: 'IRN',
    TWO_LETTER_CODE: 'IR',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'IRAQ',
    THREE_LETTER_CODE: 'IRQ',
    TWO_LETTER_CODE: 'IQ',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'ICELAND',
    THREE_LETTER_CODE: 'ISL',
    TWO_LETTER_CODE: 'IS',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'ISRAEL',
    THREE_LETTER_CODE: 'ISR',
    TWO_LETTER_CODE: 'IL',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'ITALY',
    THREE_LETTER_CODE: 'ITA',
    TWO_LETTER_CODE: 'IT',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'JAMAICA',
    THREE_LETTER_CODE: 'JAM',
    TWO_LETTER_CODE: 'JM',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'JERSEY',
    THREE_LETTER_CODE: 'JEY',
    TWO_LETTER_CODE: 'JE',
    REGION_NAME: 'United Kingdom',
    REGION_ID: 16,
  },
  {
    COUNTRY_NAME: 'JORDAN',
    THREE_LETTER_CODE: 'JOR',
    TWO_LETTER_CODE: 'JO',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'JAPAN',
    THREE_LETTER_CODE: 'JPN',
    TWO_LETTER_CODE: 'JP',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'KAZAKHSTAN',
    THREE_LETTER_CODE: 'KAZ',
    TWO_LETTER_CODE: 'KZ',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'KENYA',
    THREE_LETTER_CODE: 'KEN',
    TWO_LETTER_CODE: 'KE',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'KYRGYZSTAN',
    THREE_LETTER_CODE: 'KGZ',
    TWO_LETTER_CODE: 'KG',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'CAMBODIA',
    THREE_LETTER_CODE: 'KHM',
    TWO_LETTER_CODE: 'KH',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'KIRIBATI',
    THREE_LETTER_CODE: 'KIR',
    TWO_LETTER_CODE: 'KI',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'SAINT KITTS AND NEVIS',
    THREE_LETTER_CODE: 'KNA',
    TWO_LETTER_CODE: 'KN',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'REPUBLIC OF KOREA',
    THREE_LETTER_CODE: 'KOR',
    TWO_LETTER_CODE: 'KR',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'KUWAIT',
    THREE_LETTER_CODE: 'KWT',
    TWO_LETTER_CODE: 'KW',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    THREE_LETTER_CODE: 'LAO',
    TWO_LETTER_CODE: 'LA',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'LEBANON',
    THREE_LETTER_CODE: 'LBN',
    TWO_LETTER_CODE: 'LB',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'LIBERIA',
    THREE_LETTER_CODE: 'LBR',
    TWO_LETTER_CODE: 'LR',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'LIBYA',
    THREE_LETTER_CODE: 'LBY',
    TWO_LETTER_CODE: 'LY',
    REGION_NAME: 'North Africa',
    REGION_ID: 2,
  },
  {
    COUNTRY_NAME: 'SAINT LUCIA',
    THREE_LETTER_CODE: 'LCA',
    TWO_LETTER_CODE: 'LC',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'LIECHTENSTEIN',
    THREE_LETTER_CODE: 'LIE',
    TWO_LETTER_CODE: 'LI',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SRI LANKA',
    THREE_LETTER_CODE: 'LKA',
    TWO_LETTER_CODE: 'LK',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'LESOTHO',
    THREE_LETTER_CODE: 'LSO',
    TWO_LETTER_CODE: 'LS',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'LITHUANIA',
    THREE_LETTER_CODE: 'LTU',
    TWO_LETTER_CODE: 'LT',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'LUXEMBOURG',
    THREE_LETTER_CODE: 'LUX',
    TWO_LETTER_CODE: 'LU',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'LATVIA',
    THREE_LETTER_CODE: 'LVA',
    TWO_LETTER_CODE: 'LV',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'MACAO',
    THREE_LETTER_CODE: 'MAC',
    TWO_LETTER_CODE: 'MO',
    REGION_NAME: 'China',
    REGION_ID: 14,
  },
  {
    COUNTRY_NAME: 'SAINT MARTIN (FRENCH PART)',
    THREE_LETTER_CODE: 'MAF',
    TWO_LETTER_CODE: 'MF',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'MOROCCO',
    THREE_LETTER_CODE: 'MAR',
    TWO_LETTER_CODE: 'MA',
    REGION_NAME: 'North Africa',
    REGION_ID: 2,
  },
  {
    COUNTRY_NAME: 'MONACO',
    THREE_LETTER_CODE: 'MCO',
    TWO_LETTER_CODE: 'MC',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'REPUBLIC OF MOLDOVA',
    THREE_LETTER_CODE: 'MDA',
    TWO_LETTER_CODE: 'MD',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'MADAGASCAR',
    THREE_LETTER_CODE: 'MDG',
    TWO_LETTER_CODE: 'MG',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'MALDIVES',
    THREE_LETTER_CODE: 'MDV',
    TWO_LETTER_CODE: 'MV',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'MEXICO',
    THREE_LETTER_CODE: 'MEX',
    TWO_LETTER_CODE: 'MX',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'MARSHALL ISLANDS',
    THREE_LETTER_CODE: 'MHL',
    TWO_LETTER_CODE: 'MH',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'THE FORMER YUGOSLAV REPUBLIC OF MACEDONIA',
    THREE_LETTER_CODE: 'MKD',
    TWO_LETTER_CODE: 'MK',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'MALI',
    THREE_LETTER_CODE: 'MLI',
    TWO_LETTER_CODE: 'ML',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'MALTA',
    THREE_LETTER_CODE: 'MLT',
    TWO_LETTER_CODE: 'MT',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'MYANMAR',
    THREE_LETTER_CODE: 'MMR',
    TWO_LETTER_CODE: 'MM',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'MONTENEGRO',
    THREE_LETTER_CODE: 'MNE',
    TWO_LETTER_CODE: 'ME',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'MONGOLIA',
    THREE_LETTER_CODE: 'MNG',
    TWO_LETTER_CODE: 'MN',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'NORTHERN MARIANA ISLANDS',
    THREE_LETTER_CODE: 'MNP',
    TWO_LETTER_CODE: 'MP',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'MOZAMBIQUE',
    THREE_LETTER_CODE: 'MOZ',
    TWO_LETTER_CODE: 'MZ',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'MAURITANIA',
    THREE_LETTER_CODE: 'MRT',
    TWO_LETTER_CODE: 'MR',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'MONTSERRAT',
    THREE_LETTER_CODE: 'MSR',
    TWO_LETTER_CODE: 'MS',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'MARTINIQUE',
    THREE_LETTER_CODE: 'MTQ',
    TWO_LETTER_CODE: 'MQ',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'MAURITIUS',
    THREE_LETTER_CODE: 'MUS',
    TWO_LETTER_CODE: 'MU',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'MALAWI',
    THREE_LETTER_CODE: 'MWI',
    TWO_LETTER_CODE: 'MW',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'MALAYSIA',
    THREE_LETTER_CODE: 'MYS',
    TWO_LETTER_CODE: 'MY',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'MAYOTTE',
    THREE_LETTER_CODE: 'MYT',
    TWO_LETTER_CODE: 'YT',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'NAMIBIA',
    THREE_LETTER_CODE: 'NAM',
    TWO_LETTER_CODE: 'NA',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'NEW CALEDONIA',
    THREE_LETTER_CODE: 'NCL',
    TWO_LETTER_CODE: 'NC',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'NIGER',
    THREE_LETTER_CODE: 'NER',
    TWO_LETTER_CODE: 'NE',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'NORFOLK ISLAND',
    THREE_LETTER_CODE: 'NFK',
    TWO_LETTER_CODE: 'NF',
    REGION_NAME: 'Australia + New Zealand',
    REGION_ID: 8,
  },
  {
    COUNTRY_NAME: 'NIGERIA',
    THREE_LETTER_CODE: 'NGA',
    TWO_LETTER_CODE: 'NG',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'NICARAGUA',
    THREE_LETTER_CODE: 'NIC',
    TWO_LETTER_CODE: 'NI',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'NIUE',
    THREE_LETTER_CODE: 'NIU',
    TWO_LETTER_CODE: 'NU',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'NETHERLANDS',
    THREE_LETTER_CODE: 'NLD',
    TWO_LETTER_CODE: 'NL',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'NORWAY',
    THREE_LETTER_CODE: 'NOR',
    TWO_LETTER_CODE: 'NO',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'NEPAL',
    THREE_LETTER_CODE: 'NPL',
    TWO_LETTER_CODE: 'NP',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'NAURU',
    THREE_LETTER_CODE: 'NRU',
    TWO_LETTER_CODE: 'NR',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'NEW ZEALAND',
    THREE_LETTER_CODE: 'NZL',
    TWO_LETTER_CODE: 'NZ',
    REGION_NAME: 'Australia + New Zealand',
    REGION_ID: 8,
  },
  {
    COUNTRY_NAME: 'OMAN',
    THREE_LETTER_CODE: 'OMN',
    TWO_LETTER_CODE: 'OM',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'PAKISTAN',
    THREE_LETTER_CODE: 'PAK',
    TWO_LETTER_CODE: 'PK',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'PANAMA',
    THREE_LETTER_CODE: 'PAN',
    TWO_LETTER_CODE: 'PA',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'PITCAIRN',
    THREE_LETTER_CODE: 'PCN',
    TWO_LETTER_CODE: 'PN',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'PERU',
    THREE_LETTER_CODE: 'PER',
    TWO_LETTER_CODE: 'PE',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'PHILIPPINES',
    THREE_LETTER_CODE: 'PHL',
    TWO_LETTER_CODE: 'PH',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'PALAU',
    THREE_LETTER_CODE: 'PLW',
    TWO_LETTER_CODE: 'PW',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'PAPUA NEW GUINEA',
    THREE_LETTER_CODE: 'PNG',
    TWO_LETTER_CODE: 'PG',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'POLAND',
    THREE_LETTER_CODE: 'POL',
    TWO_LETTER_CODE: 'PL',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'PUERTO RICO',
    THREE_LETTER_CODE: 'PRI',
    TWO_LETTER_CODE: 'PR',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: "DEMOCRATIC PEOPLE'S REPUBLIC OF KOREA",
    THREE_LETTER_CODE: 'PRK',
    TWO_LETTER_CODE: 'KP',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'PORTUGAL',
    THREE_LETTER_CODE: 'PRT',
    TWO_LETTER_CODE: 'PT',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'PARAGUAY',
    THREE_LETTER_CODE: 'PRY',
    TWO_LETTER_CODE: 'PY',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'PALESTINIAN TERRITORY, OCCUPIED',
    THREE_LETTER_CODE: 'PSE',
    TWO_LETTER_CODE: 'PS',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'FRENCH POLYNESIA',
    THREE_LETTER_CODE: 'PYF',
    TWO_LETTER_CODE: 'PF',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'QATAR',
    THREE_LETTER_CODE: 'QAT',
    TWO_LETTER_CODE: 'QA',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'RÉUNION',
    THREE_LETTER_CODE: 'REU',
    TWO_LETTER_CODE: 'RE',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'ROMANIA',
    THREE_LETTER_CODE: 'ROU',
    TWO_LETTER_CODE: 'RO',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'RUSSIAN FEDERATION',
    THREE_LETTER_CODE: 'RUS',
    TWO_LETTER_CODE: 'RU',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'RWANDA',
    THREE_LETTER_CODE: 'RWA',
    TWO_LETTER_CODE: 'RW',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'SAUDI ARABIA',
    THREE_LETTER_CODE: 'SAU',
    TWO_LETTER_CODE: 'SA',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'SUDAN',
    THREE_LETTER_CODE: 'SDN',
    TWO_LETTER_CODE: 'SD',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'SENEGAL',
    THREE_LETTER_CODE: 'SEN',
    TWO_LETTER_CODE: 'SN',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'SINGAPORE',
    THREE_LETTER_CODE: 'SGP',
    TWO_LETTER_CODE: 'SG',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    THREE_LETTER_CODE: 'SGS',
    TWO_LETTER_CODE: 'GS',
    REGION_NAME: 'Antarctica',
    REGION_ID: 17,
  },
  {
    COUNTRY_NAME: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    THREE_LETTER_CODE: 'SHN',
    TWO_LETTER_CODE: 'SH',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'SVALBARD AND JAN MAYEN',
    THREE_LETTER_CODE: 'SJM',
    TWO_LETTER_CODE: 'SJ',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SOLOMON ISLANDS',
    THREE_LETTER_CODE: 'SLB',
    TWO_LETTER_CODE: 'SB',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'SIERRA LEONE',
    THREE_LETTER_CODE: 'SLE',
    TWO_LETTER_CODE: 'SL',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'EL SALVADOR',
    THREE_LETTER_CODE: 'SLV',
    TWO_LETTER_CODE: 'SV',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'SAN MARINO',
    THREE_LETTER_CODE: 'SMR',
    TWO_LETTER_CODE: 'SM',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SOMALIA',
    THREE_LETTER_CODE: 'SOM',
    TWO_LETTER_CODE: 'SO',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'SAINT PIERRE AND MIQUELON',
    THREE_LETTER_CODE: 'SPM',
    TWO_LETTER_CODE: 'PM',
    REGION_NAME: 'North America',
    REGION_ID: 11,
  },
  {
    COUNTRY_NAME: 'SERBIA',
    THREE_LETTER_CODE: 'SRB',
    TWO_LETTER_CODE: 'RS',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SOUTH SUDAN',
    THREE_LETTER_CODE: 'SSD',
    TWO_LETTER_CODE: 'SS',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'SAO TOME AND PRINCIPE',
    THREE_LETTER_CODE: 'STP',
    TWO_LETTER_CODE: 'ST',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'SURINAME',
    THREE_LETTER_CODE: 'SUR',
    TWO_LETTER_CODE: 'SR',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'SLOVAKIA',
    THREE_LETTER_CODE: 'SVK',
    TWO_LETTER_CODE: 'SK',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SLOVENIA',
    THREE_LETTER_CODE: 'SVN',
    TWO_LETTER_CODE: 'SI',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SWEDEN',
    THREE_LETTER_CODE: 'SWE',
    TWO_LETTER_CODE: 'SE',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SWAZILAND',
    THREE_LETTER_CODE: 'SWZ',
    TWO_LETTER_CODE: 'SZ',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'SINT MAARTEN (DUTCH PART)',
    THREE_LETTER_CODE: 'SXM',
    TWO_LETTER_CODE: 'SX',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'SEYCHELLES',
    THREE_LETTER_CODE: 'SYC',
    TWO_LETTER_CODE: 'SC',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'SYRIAN ARAB REPUBLIC',
    THREE_LETTER_CODE: 'SYR',
    TWO_LETTER_CODE: 'SY',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'TURKS AND CAICOS ISLANDS',
    THREE_LETTER_CODE: 'TCA',
    TWO_LETTER_CODE: 'TC',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'CHAD',
    THREE_LETTER_CODE: 'TCD',
    TWO_LETTER_CODE: 'TD',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'TOGO',
    THREE_LETTER_CODE: 'TGO',
    TWO_LETTER_CODE: 'TG',
    REGION_NAME: 'West Africa',
    REGION_ID: 10,
  },
  {
    COUNTRY_NAME: 'THAILAND',
    THREE_LETTER_CODE: 'THA',
    TWO_LETTER_CODE: 'TH',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'TAJIKISTAN',
    THREE_LETTER_CODE: 'TJK',
    TWO_LETTER_CODE: 'TJ',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'TOKELAU',
    THREE_LETTER_CODE: 'TKL',
    TWO_LETTER_CODE: 'TK',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'TURKMENISTAN',
    THREE_LETTER_CODE: 'TKM',
    TWO_LETTER_CODE: 'TM',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'TIMOR-LESTE',
    THREE_LETTER_CODE: 'TLS',
    TWO_LETTER_CODE: 'TL',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'TONGA',
    THREE_LETTER_CODE: 'TON',
    TWO_LETTER_CODE: 'TO',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'TRINIDAD AND TOBAGO',
    THREE_LETTER_CODE: 'TTO',
    TWO_LETTER_CODE: 'TT',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'TUNISIA',
    THREE_LETTER_CODE: 'TUN',
    TWO_LETTER_CODE: 'TN',
    REGION_NAME: 'North Africa',
    REGION_ID: 2,
  },
  {
    COUNTRY_NAME: 'TURKEY',
    THREE_LETTER_CODE: 'TUR',
    TWO_LETTER_CODE: 'TR',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'TUVALU',
    THREE_LETTER_CODE: 'TUV',
    TWO_LETTER_CODE: 'TV',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'TAIWAN',
    THREE_LETTER_CODE: 'TWN',
    TWO_LETTER_CODE: 'TW',
    REGION_NAME: 'China',
    REGION_ID: 14,
  },
  {
    COUNTRY_NAME: 'UNITED REPUBLIC OF TANZANIA',
    THREE_LETTER_CODE: 'TZA',
    TWO_LETTER_CODE: 'TZ',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'UGANDA',
    THREE_LETTER_CODE: 'UGA',
    TWO_LETTER_CODE: 'UG',
    REGION_NAME: 'East Africa',
    REGION_ID: 12,
  },
  {
    COUNTRY_NAME: 'UKRAINE',
    THREE_LETTER_CODE: 'UKR',
    TWO_LETTER_CODE: 'UA',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'UNITED STATES MINOR OUTLYING ISLANDS',
    THREE_LETTER_CODE: 'UMI',
    TWO_LETTER_CODE: 'UM',
    REGION_NAME: 'North Asia/North Pacific',
    REGION_ID: 15,
  },
  {
    COUNTRY_NAME: 'URUGUAY',
    THREE_LETTER_CODE: 'URY',
    TWO_LETTER_CODE: 'UY',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'UNITED STATES',
    THREE_LETTER_CODE: 'USA',
    TWO_LETTER_CODE: 'US',
    REGION_NAME: 'North America',
    REGION_ID: 11,
  },
  {
    COUNTRY_NAME: 'UZBEKISTAN',
    THREE_LETTER_CODE: 'UZB',
    TWO_LETTER_CODE: 'UZ',
    REGION_NAME: 'Russia + CIS',
    REGION_ID: 7,
  },
  {
    COUNTRY_NAME: 'HOLY SEE (VATICAN CITY STATE)',
    THREE_LETTER_CODE: 'VAT',
    TWO_LETTER_CODE: 'VA',
    REGION_NAME: 'Europe',
    REGION_ID: 1,
  },
  {
    COUNTRY_NAME: 'SAINT VINCENT AND THE GRENADINES',
    THREE_LETTER_CODE: 'VCT',
    TWO_LETTER_CODE: 'VC',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'BOLIVARIAN REPUBLIC OF VENEZUELA',
    THREE_LETTER_CODE: 'VEN',
    TWO_LETTER_CODE: 'VE',
    REGION_NAME: 'South America',
    REGION_ID: 6,
  },
  {
    COUNTRY_NAME: 'VIRGIN ISLANDS, BRITISH',
    THREE_LETTER_CODE: 'VGB',
    TWO_LETTER_CODE: 'VG',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'VIRGIN ISLANDS, U.S.',
    THREE_LETTER_CODE: 'VIR',
    TWO_LETTER_CODE: 'VI',
    REGION_NAME: 'Central America + Caribbean',
    REGION_ID: 5,
  },
  {
    COUNTRY_NAME: 'VIETNAM',
    THREE_LETTER_CODE: 'VNM',
    TWO_LETTER_CODE: 'VN',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'VANUATU',
    THREE_LETTER_CODE: 'VUT',
    TWO_LETTER_CODE: 'VU',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'WALLIS AND FUTUNA',
    THREE_LETTER_CODE: 'WLF',
    TWO_LETTER_CODE: 'WF',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'SAMOA',
    THREE_LETTER_CODE: 'WSM',
    TWO_LETTER_CODE: 'WS',
    REGION_NAME: 'South Asia/South Pacific',
    REGION_ID: 3,
  },
  {
    COUNTRY_NAME: 'YEMEN',
    THREE_LETTER_CODE: 'YEM',
    TWO_LETTER_CODE: 'YE',
    REGION_NAME: 'Middle East',
    REGION_ID: 9,
  },
  {
    COUNTRY_NAME: 'SOUTH AFRICA',
    THREE_LETTER_CODE: 'ZAF',
    TWO_LETTER_CODE: 'ZA',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'ZAMBIA',
    THREE_LETTER_CODE: 'ZMB',
    TWO_LETTER_CODE: 'ZM',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
  {
    COUNTRY_NAME: 'ZIMBABWE',
    THREE_LETTER_CODE: 'ZWE',
    TWO_LETTER_CODE: 'ZW',
    REGION_NAME: 'South Africa',
    REGION_ID: 4,
  },
];

export const COUNTRY_CODE_TAIWAN_ISO2 = 'TW';
export const COUNTRY_CODE_CHINA_ISO2 = 'CN';

export default countryData;
