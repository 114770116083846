import FetchMembershipSource from '../sources/MembershipPlanSource'; // eslint-disable-line
import * as types from '../actionTypes';

export const fetchMemberShipInit = () => ({
  type: types.FETCH_MEMBERSHIP_INIT,
});

export const fetchMemberShipSuccess = data => ({
  type: types.FETCH_MEMBERSHIP_SUCCESS,
  data,
});

export const fetchMemberShipFail = data => ({
  type: types.FETCH_MEMBERSHIP_FAIL,
  data,
});

export const fetchMembershipPlansAction = token => FetchMembershipSource(token);
