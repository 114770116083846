import * as types from '../actionTypes';

export const initialState = {
  notifications: {},
  cities: {},
  isLoading: false,
  isError: false,
  error: '',
  isUpdated: false,
  isFetched: false,
};

const updateNotification = (updatedNotification) => {
  return Object.assign({}, updatedNotification);
};

const updateCities = (updatedCities) => {
  return Object.assign({}, updatedCities);
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PUSH_NOTIFICATION_INIT: {
      return Object.assign({}, state, {
        notifications: {},
        cities: {},
        isLoading: true,
        isError: false,
        error: '',
        isUpdated: false,
        isFetched: false,
      });
    }
    case types.GET_PUSH_NOTIFICATION_SUCCESS: {
      return Object.assign({}, state, {
        notifications: action.data.notifications,
        cities: action.data.cities,
        isLoading: false,
        isError: false,
        error: '',
        isUpdated: false,
        isFetched: true,
      });
    }
    case types.GET_PUSH_NOTIFICATION_FAILURE: {
      return Object.assign({}, state, {
        notifications: {},
        cities: {},
        isLoading: false,
        isError: true,
        error: action.data,
        isUpdated: false,
        isFetched: false,
      });
    }
    case types.UPDATE_PUSH_NOTIFICATION_INIT: {
      return Object.assign({}, state, {
        notifications: state.notifications,
        cities: state.cities,
        isLoading: true,
        isError: false,
        error: '',
        isUpdated: false,
        isFetched: false,
      });
    }
    case types.UPDATE_PUSH_NOTIFICATION_SUCCESS: {
      return Object.assign({}, state, {
        notifications: updateNotification(action.data.notifications),
        cities: updateCities(action.data.cities),
        isLoading: false,
        isError: false,
        error: '',
        isUpdated: true,
        isFetched: false,
      });
    }
    case types.UPDATE_PUSH_NOTIFICATION_FAILURE: {
      return Object.assign({}, state, {
        notifications: state.notifications,
        cities: state.cities,
        isLoading: false,
        isError: true,
        error: action.data,
        isUpdated: false,
        isFetched: false,
      });
    }
    default:
      return state;
  }
}
