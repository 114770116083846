import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  PassengerDetails,
} from 'constants/passengerConstants';
import { getPassengerId } from 'utils/passengerUtils';
import { getCountry } from 'utils/countryUtils';
import moment from 'moment';
import { convertToTitleCase } from '../../utils/stringUtils';

class PassengerDetail extends Component {
  static propTypes = {
    addedPax: PropTypes.array,
    handleAddNewPassport: PropTypes.func,
    handleRemovePassport: PropTypes.func,
    selectedPassengerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setLegPassport: PropTypes.func,
    handleDirtyChange: PropTypes.func,
  };
  constructor(props) {
    super(props);
    const {
      selectedPassengerId,
      addedPax,
    } = props;

    const paxDetail = addedPax.find((pax) => {
      const passengerId = pax.passengerId || pax.passengerExtIdentifier;
      return (passengerId === selectedPassengerId);
    });
    this.state = {
      paxDetail,
      changePassportNumber: 0,
    };
  }
  setLegPassport = (passportNumber) => {
    this.props.handleDirtyChange(true);
    this.setState({
      changePassportNumber: passportNumber,
    });

    const paxId = getPassengerId(this.state.paxDetail);
    this.props.setLegPassport(paxId, passportNumber);
  }
  getDefaultPassportChecked = (isDefault, passportNumber) => {
    const { changePassportNumber } = this.state;
    if (changePassportNumber === passportNumber) {
      return true;
    } else if (!changePassportNumber && isDefault) {
      return true;
    }
    return false;
  }
  renderPassport = (passports) => (
    !passports.length ? (
      <div className="uk-width-1-1 uk-margin-small">
        No passport added
      </div>
    )
      :
      passports.map((passport, index) => {
        if (passport.isDeleted || (!passport.isActive && !passport.isLegPassport)) {
          return null;
        }
        const passportCountry = getCountry('THREE_LETTER_CODE', passport.isoPassportCountry) || {};
        const countryFlagCode = passportCountry && passportCountry.TWO_LETTER_CODE;
        const dateToExpire = passport.expiry ?
          `Expires ${moment(passport.expiry).format('DD MMM YYYY')}` : '';
        return (
          <div className="vistajet_user_profile_container vistajet_passenger_passport_container" key={index}>
            <div className="vistajet_profile_details_column">
              <div className="vistajet_passport_details">
                <div className="uk-flex uk-grid-small uk-grid uk-grid-stack">
                  <div className="uk-width-1-1">
                    <div className="vistajet_passpport_list_content">
                      <div className="vistajet_details_hover vistajet_passport_buttons_list">
                        <label className="vistajet_radio vistajet_passenger_passport_radio_buttons">
                          <input name="radio" type="radio"
                            checked={this.getDefaultPassportChecked(passport.isLegPassport, passport.passportNumber)} 
                            onChange={() => { this.setLegPassport(passport.passportNumber); }}
                          />
                          <span>&nbsp;</span>
                        </label>
                        <span className={`vistatejet_passenger_passport_flag flag-icon-style flag-icon-${countryFlagCode && countryFlagCode.toLowerCase()}`} />
                        <span className="vistajet_text uk-text-middle vistajet_passport_number">{passport && passport.passportNumber.toUpperCase()}</span>
                        <span className="vistajet_text uk-text-middle vistajet_expires_number">
                          <small className="uk-margin-small-left">{dateToExpire}</small>
                        </span>
                        <span className="vistajet_passport_hover_action vistajet_passport_buttons">
                          <span>
                            <img src="/legacy/static/img/icons/vistajet_close_grey_icon.svg" alt=""
                              onClick={() => {
                                this.props.handleRemovePassport(passport.passportNumber,
                                  this.props.selectedPassengerId);
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
  );
  render() {
    const {
      paxDetail,
    } = this.state;

    const dateOfBirth = paxDetail.dateOfBirth ?
      moment(paxDetail.dateOfBirth).format('DD-MMM-YYYY') : '-';

    const email = paxDetail.emailAddresses &&
      paxDetail.emailAddresses.length && paxDetail.emailAddresses[0].email || '-';

    const phoneNumber = paxDetail.phoneNumbers &&
      paxDetail.phoneNumbers.length && paxDetail.phoneNumbers[0].phoneNumber || '-';

    const gender = paxDetail.gender || '-';

    const passengerName = convertToTitleCase(`${paxDetail.firstName} ${paxDetail.lastName}`);

    return (
      <React.Fragment>
        <div class="vistajet_user_profile_container uk-padding-remove vistajet_passenger_details">
          <h5 class="uk-text-left">{passengerName}</h5>
          <div class="uk-grid uk-margin-remove vistajet_my_details">
            <div class="uk-width-1-2@s uk-padding-remove uk-margin-small-bottom">
              <span class="vistajet_label">Email</span>
              <p class="vistajet_passenger_detail_text">{email}</p>
            </div>
            <div class="uk-width-1-2@s uk-padding-remove uk-margin-small-bottom">
              <span class="vistajet_label">Phone Number</span>
              <p class="vistajet_passenger_detail_text">{phoneNumber}</p>
            </div>
            <div class="uk-width-1-2@s uk-padding-remove uk-margin-small-bottom">
              <span class="vistajet_label">Gender</span>
              <p class="vistajet_passenger_detail_text">{gender}</p>
            </div>
            <div class="uk-width-1-2@s uk-padding-remove uk-margin-small-bottom">
              <span class="vistajet_label">Date of Birth</span>
              <p class="vistajet_passenger_detail_text">{dateOfBirth}</p>
            </div>
          </div>
        </div>
        <h6 class="vistajet_subheading uk-margin-small-top uk-margin-small-bottom">Passenger Passports</h6>
        <React.Fragment>
          {this.renderPassport(paxDetail.passports)}
          <div className="vistajet_passenger_add_new_passport_button uk-margin-small">
            <a className="vistajet_button_default uk-margin-remove" uk-toggle=""
              onClick={() => {
                this.props.handleAddNewPassport(PassengerDetails, this.props.selectedPassengerId);
              }}>
              Add new passport
            </a>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default PassengerDetail;
