export function normalizeRegisterResponse(fleet) {
  const {
    personId,
    externalAppUserId,
    phoneNumber,
    token,
    username,
    salutation,
    firstName,
    lastName,
    gender,
    address,
    city,
    state,
    countryName,
  } = fleet;
  return {
    personId,
    externalAppUserId,
    phoneNumber,
    token,
    username,
    salutation,
    firstName,
    lastName,
    gender,
    address,
    city,
    state,
    countryName,
  };
}

export function normalizeRegisterRequestPayload(req, token) {
  return {
    deviceType: 'web',
    firstName: req.firstName,
    gender: req.gender || 'default',
    lastName: req.lastName,
    osVersion: 'windows',
    password: req.password,
    phoneNumber: req.phoneNumber,
    privacyConsent: req.agreementCheckbox,
    salutation: req.salutation.label,
    source: 'CUSTOMERWEB',
    subscription: req.marketingCheckbox || true,
    username: req.email,
    token,
    countryName: req.countryName || '',
  };
}
