import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { clearErrorFetchItinerary } from '../../store/actions/ItineraryListingActions'
import withStore from '../../components/withStore';
import ItinerariesListing from '../../components/Itinerary/ItinerariesListing';
import { ITINERARY_TYPES, EMPTY_MESSAGE } from '../../constants/ItineraryConstants';


function mapStateToProps(state) {
  const {
    errorsItineraries,
    isLoadMoreUpcoming,
    upcomingItineraries,
    isLoadingUpcomingItineraries,
    isLoadingMoreUpcomingItineraries,
  } = state.itineraries;
  const { token } = state.token;
  const { aircraftsList } = state.aircrafts;
  const itineraryHeading = 'Upcoming Itineraries';
  const { isGoogleMapsApiLoaded, } = state.googleMap;
  const itineraryType = ITINERARY_TYPES.UPCOMING_ITINERARIES;
  const { isAirportsLoading, visitedAirports, visitedAirportsFinder } = state.visitedAirports;

  return {
    token,
    aircraftsList,
    itineraryType,
    visitedAirports,
    itineraryHeading,
    isAirportsLoading,
    errorsItineraries,
    visitedAirportsFinder,
    isGoogleMapsApiLoaded,
    itineraries: upcomingItineraries,
    isLoadMoreDisable: isLoadMoreUpcoming,
    isLoadingMore: isLoadingMoreUpcomingItineraries,
    isLoadingItineraries: isLoadingUpcomingItineraries,
    emptyMessage: EMPTY_MESSAGE.UPCOMING_PAST_ITINERARIES,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAirportById: id => dispatch(actions.getAirportById(id)),
    onGoogleMapsApiLoaded: () => dispatch(actions.onGoogleMapsApiLoaded()),
    fetchVisitedAirports: () => dispatch(actions.fetchAllVisitedAirports()),
    clearErrorFetchItinerary: () => dispatch(clearErrorFetchItinerary()),
    fetchItineraryListing: (token, itineraryType, pageNum, isLoadMore) => dispatch(actions.fetchAllItineraryListing(token, itineraryType, pageNum, isLoadMore)),
  };
}

const UpcomingItinerariesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerariesListing);

export default withStore(UpcomingItinerariesContainer);
