import { isBefore, parseISO } from 'date-fns';
import React, { Component } from 'react';
import { currentDate } from '../utils/PASUtils';

export default class StepperPagination extends Component {
  componentDidUpdate() {
    this.updateDisabled();
  }

  updateDisabled = () => {
    const left = document.getElementById('stepper-button-left');
    const right = document.getElementById('stepper-button-right');
    if (!left || !right) return;
    if (left.classList.contains('uk-invisible'))
      left.classList.add('disabled');
    else left.classList.remove('disabled');
    if (right.classList.contains('uk-invisible'))
      right.classList.add('disabled');
    else right.classList.remove('disabled');
    // const buttonToDisable = ((document.getElementsByClassName('uk-invisible') || [])[0] || null);
    // if (buttonToDisable) {
    //   buttonToDisable.classList.add('disabled');
    // }
  }

  onClickYear = contract => {
    if (this.isFutureContract(contract || {}))
      return;
    this.props.updateCurrentYear((contract || {}));
  }

  isFutureContract = year => {
    const { contractPeriodStart } = year;
    const periodStart = parseISO(contractPeriodStart);
    return isBefore(currentDate, periodStart);
  }

  shouldArrowDisable = (contractualYear = [], selectedContractualYear = 0, direction) => {
    alert(`length:${contractualYear.length} selected:${selectedContractualYear}`);
    if (direction === 'left') {
      if (!selectedContractualYear) return true;
      if (selectedContractualYear === 1) return true;
      return false;
    }
    else {
      if (!selectedContractualYear) return false
      if ((Math.ceil(selectedContractualYear/5) - 1) === (Math.ceil(((contractualYear || []).length)/5) - 1))
        return true;
      return false;
    }
  }

  getTabClass = (contract) => {
    const { currentYear } = this.props;
    const stepperClass = 'vistajet_stepper_step'
    if (this.isFutureContract(contract || {}))
      return `${stepperClass} disabled`;
    if ((contract || {}) === currentYear) {
      return `${stepperClass} active`;
    } return stepperClass
  };

  renderTabContainer = () => {
    const {
      contractualYears = [],
    } = this.props;
    if (!(contractualYears || []).length) return null;
    const yearTabs = contractualYears.map(this.renderTab);
    return contractualYears.length > 5 ? (
      // <div>
      //   <a href="#" class="vistajet_stepper_left_arrow "></a>
      //     {yearTabs}
      //   <a href="#" class="vistajet_stepper_right_arrow"></a>
      // </div>
      <div id="contract-stepper" class="uk-position-relative uk-visible-toggle vistajet_stepper_wrapper"
          tabindex="-1" uk-slider="finite: true; sets: true;">
          <div class="uk-width-5-6 uk-margin-auto uk-overflow-hidden">
              <ul class="uk-slider-items uk-child-width-1-5">
                  {yearTabs}
              </ul>
          </div>

          <button id="stepper-button-left" className="uk-position-center-left vistajet_stepper_left_arrow" href="#"
              uk-slider-item="previous" onClick={this.updateDisabled}></button>
          <button id="stepper-button-right" className="uk-position-center-right vistajet_stepper_right_arrow" href="#"
              uk-slider-item="next" onClick={this.updateDisabled}></button>

      </div>
    ) : (
      <div id="contract-stepper" class="uk-position-relative uk-visible-toggle vistajet_stepper_wrapper"
          tabindex="-1" uk-slider="finite: true; sets: true;">
          <div class="uk-width-5-6 uk-margin-auto uk-overflow-hidden">
              <ul class="uk-slider-items uk-child-width-1-5 uk-flex-center">
                  {yearTabs}
              </ul>
          </div>
      </div>
    );
  }

  renderTab = (contract) => (
    <li>
      <a
        href="#"
        className={this.getTabClass(contract || {})}
        onClick={() => this.onClickYear(contract || {})}
      >
        {(contract || {}).periodNumber}
      </a>
    </li>
  )

  render() {
    return (
      <div class="uk-text-center@s">
        <div class="vistajet_stepper_wrapper uk-clearfix">
          {this.renderTabContainer()}
            {/* <a href="#" class="vistajet_stepper_left_arrow "></a>
            <a href="#" class="vistajet_stepper_step">1</a>
            <a href="#" class="vistajet_stepper_step active">2</a>
            <a href="#" class="vistajet_stepper_step">3</a>
            <a href="#" class="vistajet_stepper_step disabled">4</a>
            <a href="#" class="vistajet_stepper_step disabled">5</a>
            <a href="#" class="vistajet_stepper_right_arrow disabled"></a> */}
        </div>
    </div>
    );
  }
}