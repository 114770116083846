import React from "react";

const Button = props => {
  const { classNames, onClickHandler, label, disabled } = props;
  const styles = disabled ? `${classNames} vistajet_button_disable` : classNames;
  return (
    <button
      className={styles}
      onClick={onClickHandler}
      type="submit"
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
