import Helpers, { clone } from 'utils/Helpers';
import { comparePassengers } from '../utils/passengerUtils';
import { dateInFuture } from '../utils/Validators/Validators';
import { isArray, isObject } from '../utils/arrayUtils';
import moment from 'moment';

export const passportActiveFilter = passport => passport.isActive;

export function normalizePassport(passport) {
  const {
    // identifiers
    id, passportId,

    // expere data
    expiresOnDate, expiry,

    // flags
    isActive,
    isDefault,
    isInvalid,
    isOnHands,

    // country and issued by country iso
    isoPassportCountry,
    isoIssuedByCountry, isoPassportIssuedByCountry,

    // country and issued by country labels
    passportCountry,
    issuedByCountry, passportIssuedByCountry,

    // others
    displayName,
    passportNumber,
    ...other
  } = passport;

  return {
    // identifiers
    id: id || passportId,

    // expire data
    expiresOnDate: expiresOnDate || expiry,

    // flags
    isActive,
    isDefault,
    isInvalid,
    isOnHands,

    // country and issued by country iso
    isoPassportCountry: isoPassportCountry || '',
    isoIssuedByCountry: isoIssuedByCountry || isoPassportIssuedByCountry || '',

    // country and issued by country labels
    passportCountry,
    issuedByCountry: issuedByCountry || passportIssuedByCountry || '',

    // others
    displayName: displayName || '',
    passportNumber: `${(passportNumber === 0 || passportNumber) ? passportNumber : ''}`,

    // front-end control flags
    isAdded: false,
    isUpdated: true,
    isDeleted: false,

    ...other,
  };
}

export function normalizePassports(passports) {
  const init = {
    content: {},
    ids: [],
    defaultId: undefined,
  };

  if (passports) {
    return passports.filter(passportActiveFilter).reduce((previous, current) => {
      const prev = previous;
      const passport = normalizePassport(current);

      prev.content[passport.id] = passport;
      prev.ids.push(passport.id);
      if (!prev.defaultId && passport.isDefault) {
        prev.defaultId = passport.id;
      }
      return prev;
    }, init);
  }
  return init;
}

export function buildPassportRequest(passport) {
  return {
    id: passport.id,
    isDefault: passport.isDefault,
    isActive: passport.isActive,
    passportNumber: passport.passportNumber,
    isoPassportCountry: passport.isoPassportCountry,
    isoIssuedByCountry: passport.isoIssuedByCountry,
    expiresOnDate: passport.expiresOnDate,
    displayName: passport.displayName,
    isAdded: passport.isAdded,
    isUpdated: passport.isUpdated,
    isDeleted: passport.isDeleted,
  };
}

export function uniquePassports(paxPass) {
  const uniquePass = [];
  const uniqueIds = [];

  if (paxPass && !!paxPass.length && isArray(paxPass)) {
    paxPass.forEach((passport) => {
      const current = passport;
      const id = (Helpers.getValue(current.passportNumber || '')).toString();
      const existingPassInd = uniqueIds.indexOf(id);
      if (existingPassInd < 0) {
        uniquePass.push(current);
        uniqueIds.push(id);
      } else {
        uniquePass[existingPassInd] = // eslint-disable-line no-param-reassign
        Object.assign({}, current, uniquePass[existingPassInd]);
      }
    });
    return uniquePass;
  }

  return uniquePass;
}

export function mergePassports(oldPassArray, newPassArray) {
  let arr3 = [];
  if (!!oldPassArray) {
    arr3 = (newPassArray || []).concat(oldPassArray);
  } else {
    if (!!newPassArray) {
      arr3 = newPassArray;
    } else {
      arr3 = [];
    }
  }
  return uniquePassports(arr3);
}

/**
 * Returns bool in string
 * @param {boolean}
 * @returns {bool string}
 **/
export function stringifyBool(bool) {
  return (!!bool).toString();
}

export function transformPassportData(passport) {
  if (!passport || !passport.length || !isArray(passport)) {
    return false;
  }

  const passports = [];
  passport.forEach((current) => {
    const pid = [(current.id || '').toString()];
    const expiresOnDate = [(current.expiresOnDate || '').toString()];
    passports.push({
      id: pid,
      passportId: pid,
      isDefault: [stringifyBool(current.isDefault)],
      isActive: [stringifyBool(current.isActive)],
      passportNumber: [current.passportNumber.toString()],
      isoPassportCountry: [current.isoPassportCountry.toString()],
      isoIssuedByCountry: [current.isoIssuedByCountry.toString()],
      expiresOnDate,
      expiry: expiresOnDate,
      displayName: [current.displayName.toString()],
      isAdded: [stringifyBool(current.isAdded)],
      isUpdated: [stringifyBool(current.isUpdated)],
      isDeleted: [stringifyBool(current.isDeleted)],
      passportIssuedByCountry: [current.issuedByCountry.toString()],
      passportCountry: [(current.passportCountry || '').toString()],
      isInvalid: [stringifyBool(current.isInvalid)],
    });
  });

  return passports;
}

export function mergePassportbyPax(paxinfoDev, freqPaxCache) {
  const activeLegPax = clone(paxinfoDev);
  const cachePax = clone(freqPaxCache);
  activeLegPax.forEach((activePaxCurrent) => {
    let exists = false;
    cachePax.forEach((cachePaxCurrent) => {
      if (comparePassengers(activePaxCurrent, cachePaxCurrent)) {
        cachePaxCurrent.passports = // eslint-disable-line no-param-reassign
          mergePassports(cachePaxCurrent.passports, activePaxCurrent.passports);
        exists = true;
      }
    });
    if (!exists) {
      cachePax.push(activePaxCurrent);
    }
  });

  return cachePax;
}

export function isPassportExpired(passport) {
  const dateToExpire = passport.expiresOnDate;
  return !(
    passport.isActive &&
    +(passport.isInvalid) !== 1 &&
    (!dateToExpire || dateInFuture(dateToExpire))
  );
}

/**
 * Determines if passport expired before departure date.
 *
 * @param      {object}   passport  The passport
 * @param      {moment}   deptDate  The dept date
 * @return     {boolean}  True if passport expired before dept, False otherwise.
 */
export function isPassportExpiredBeforeDept(passport, deptDate) {
  const isPassExp = isPassportExpired(passport);
  if (!isPassExp) {
    const passExpire = moment(passport.expiresOnDate).utc();
    return passExpire.isBefore(deptDate);
  }
  return false;
}

// Please handle from calling function that passport1 and passport2 exist
// It will return false if provided both passports undefined
export function comparePassports(passport1, passport2) {
  if (!isObject(passport1) || !isObject(passport2)) {
    return false;
  }
  return (
    passport1.displayName === passport2.displayName &&
    passport1.isoIssuedByCountry === passport2.isoIssuedByCountry &&
    passport1.expiresOnDate === passport2.expiresOnDate &&
    passport1.isoPassportCountry === passport2.isoPassportCountry &&
    passport1.passportNumber === passport2.passportNumber &&
    passport1.isActive === passport2.isActive &&
    passport1.isDefault === passport2.isDefault
  );
}
