import * as types from "../actionTypes";

const initialState = {
  isAircraftsLoading: false,
  aircraftsList: [],
  error: ""
};

export default function aircraftsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_AIRCRAFTS_INIT: {
      return Object.assign({}, state, {
        isAircraftsLoading: true,
        aircraftsList: [],
        error: ""
      });
    }
    case types.GET_AIRCRAFTS_SUCCESS: {
      return Object.assign({}, state, {
        isAircraftsLoading: false,
        aircraftsList: action.data,
        error: ""
      });
    }
    case types.GET_AIRCRAFTS_FAILURE: {
      return Object.assign({}, state, {
        isAircraftsLoading: false,
        aircraftsList: [],
        error: action.data
      });
    }
    default:
      return state;
  }
}
