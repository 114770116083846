import axios from 'axios';
import { GET_TOKEN_URL } from '../../configs/constants';
import decryptSingupKeys from '../../utils/decrypt';
import {
  fetchTokenInit,
  fetchTokenSuccess,
  fetchTokenFail,
  fetchRegistrationTokenInit,
  fetchRegistrationTokenSuccess,
  fetchRegistrationTokenFail,
} from '../actions';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';
import normalizeParsedToken from '../../normalizers/tokenNormalizer';
import { parseXmlStringToJson } from '../../utils/parserUtils';
import { saveToLocal } from '../../utils/cache';

const status = {
  OK: 200,
};

const { DECRYPTED_SIGNUP_CONTEXT} = decryptSingupKeys();
const tokenPromiseInprogress = {};

const tokenSource = (username = DECRYPTED_SIGNUP_CONTEXT, context = DECRYPTED_SIGNUP_CONTEXT, isRegistrationFlow = false) => (dispatch) => {
  const uniqueKey = username;
  if (tokenPromiseInprogress[uniqueKey]) {
    return tokenPromiseInprogress[uniqueKey];
  }
  const tokenPromise = new Promise((resolve, reject) => {
    const url = GET_TOKEN_URL.replace('{context}', context)
      .replace('{username}', username);
    isRegistrationFlow ? dispatch(fetchRegistrationTokenInit()) : dispatch(fetchTokenInit());
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          const parsedTokenResponse = parseXmlStringToJson(response.data);
          const normalizedToken = normalizeParsedToken(parsedTokenResponse);
          isRegistrationFlow ? dispatch(fetchRegistrationTokenSuccess(normalizedToken.token)) : dispatch(fetchTokenSuccess(normalizedToken.token));
          saveToLocal({ token: normalizedToken.token }, 'token');
          resolve(normalizedToken);
        } else {
          const errors = handleServerErrors(response.status);
          isRegistrationFlow ? dispatch(fetchRegistrationTokenFail(errors)) : dispatch(fetchTokenFail(errors));
          saveToLocal({ token: null }, 'token');
          reject(errors);
        }
        delete tokenPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        isRegistrationFlow ? dispatch(fetchRegistrationTokenFail(errors)) : dispatch(fetchTokenFail(errors));
        saveToLocal({ token: null }, 'token');
        reject(errors);
        delete tokenPromiseInprogress[uniqueKey];
      });
  });
  tokenPromiseInprogress[uniqueKey] = tokenPromise;
  return tokenPromise;
};

export default tokenSource;
