export const legStatus = {
  QUOTED: 'Quoted',
  OPTION: 'Option',
  CONFIRMED: 'Confirmed',
  READY: 'Ready',
  FLYING: 'Flying',
  LANDED: 'Landed',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  CANCELLATION_PENDING: 'Cancellation Pending',
  NOSHOW: 'No Show',
  UNCONFIRMED: 'Unconfirmed',
  REQUESTED: 'Requested',
};

export const ITINERARY_TYPES = {
  PAST_ITINERARIES: 'pastItineraries',
  UPCOMING_ITINERARIES: 'upcomingItineraries',
  CANCELLED_ITINERARIES: 'cancelledItineraries',
};

export const EMPTY_MESSAGE = {
  UPCOMING_PAST_ITINERARIES: 'No Itineraries available',
  CANCELLED_ITINERARIES: 'There are no Cancelled flights',
};
export const SINGLE_LEG = 'SINGLE_LEG';
export const MULTI_LEG = 'MULTI_LEG';
export const RETURN_LEG = 'RETURN_LEG';

export const CABIN_HOSTESS = '103';

export const TIME_TO_SHOW_CREW = 24; // 24 Hours

export const SERVICE_AVAILED_MESSAGE = 'This service already exists';

export const SERVICE_DETAIL_PLACEHOLDER = 'We would be delighted to organise ground transportation for your flight. If you require this service, please advise the following details:\nPick-up/Drop off location:\nTime:\nNumber of passengers:\nPreferred vehicle type:';
export const AS_DETAIL_PLACEHOLDER = 'In order for us to apply for {service} at {airport}, please specify instructions';
export const AS_DETAIL_PLACEHOLDER_EMPTY = 'Services are currently unavailable';

export const GT_EMPTY_MESSAGE = 'No ground transportation arrangements have been requested at this time. Should you require a driver to greet you on arrival, or wish to bring your own vehicle into the terminal, we will happily organise this for you where available.';
export const AS_EMPTY_MESSAGE = 'No additional services have been requested at this time.';

export const GT_QUESTION_HEADING = 'Where do you need Ground Transportation service?';
export const AS_QUESTION_HEADING = 'Where do you need Additional service?';

export const GROUND_TRANSPORT_SERVICE = 'GROUND_TRANSPORT_SERVICE';
export const ADDITIONAL_SERVICE = 'ADDITIONAL_SERVICE';
