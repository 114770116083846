import React from "react";
import Stepper from "../../Stepper";

export default function StepperWrapper(props) {
  return (
    <Stepper
      value = { props.value }
      maxValue = { props.maxValue }
      onChange = { props.onChange }
    />
  );
}
