import * as types from "../actionTypes";

const initialState = {
  legArray: [],
  isContractSelected: false,
  allowAircraftSelect: true,
  allowAircraftErrMessage: null,
  accountId: null,
  isRequestFlightLoading: false,
  isCreateCompanyLoading: false,
  error: "",
  orderIdentifiers: [],
  quoteContent: {},
  requestFlightSuccess: false,
  requestQuoteSuccess: false,
  buyOfferSuccess: false,
  orderId: "",
  orderStatus: "",
  confirmationMessage: ""
};

export default function PAFReducer(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_FLIGHT_INIT: {
      return Object.assign({}, state, {
        isRequestFlightLoading: true,
        error: "",
        orderIdentifiers: [],
        orderId: "",
        orderStatus: "",
        confirmationMessage: ""
      });
    }
    case types.REQUEST_FLIGHT_SUCCESS: {
      return Object.assign({}, state, {
        requestFlightSuccess: true,
        isRequestFlightLoading: false,
        orderIdentifiers: action.data.flightDetails,
        legArray: action.data.legArray,
        error: "",
        confirmationMessage: "",
        orderStatus: action.data.status
      });
    }
    case types.REQUEST_FLIGHT_FAIL: {
      return Object.assign({}, state, {
        isRequestFlightLoading: false,
        error: action.data,
        legArray: [],
        orderIdentifiers: []
      });
    }
    case types.REQUEST_QUOTE_INIT: {
      return Object.assign({}, state, {
        isRequestFlightLoading: true,
        legArray: [],
        error: "",
        orderId: "",
        orderStatus: "",
        confirmationMessage: "",
      });
    }
    case types.REQUEST_QUOTE_SUCCESS: {
      return Object.assign({}, state, {
        isRequestFlightLoading: false,
        legArray: action.data.legs,
        requestQuoteSuccess: true,
        error: "",
        orderStatus: action.data.status,
        confirmationMessage: "",
      });
    }
    case types.REQUEST_QUOTE_FAIL: {
      return Object.assign({}, state, {
        isRequestFlightLoading: false,
        error: action.data,
        legArray: [],
        orderStatus: ""
      });
    }
    case types.CREATE_COMPANY_INIT: {
      return Object.assign({}, state, {
        isCreateCompanyLoading: true,
        error: ""
      });
    }
    case types.CREATE_COMPANY_SUCCESS: {
      return Object.assign({}, state, {
        isCreateCompanyLoading: false,
        error: ""
      });
    }
    case types.CREATE_COMPANY_FAIL: {
      return Object.assign({}, state, {
        isCreateCompanyLoading: false,
        error: action.data
      });
    }
    case types.AVAIL_OFFER_INIT: {
      return Object.assign({}, state, {
        isRequestFlightLoading: true,
        quoteContent: {},
        legArray: [],
        error: '',
        confirmationMessage:''
      });
    }
    case types.AVAIL_OFFER_SUCCESS: {
      return Object.assign({}, state, {
        quoteContent: action.data.quoteContent,
        legArray: action.data.legs,
        isRequestFlightLoading: false,
        error: '',
        confirmationMessage:''
      });
    }
    case types.AVAIL_OFFER_FAIL: {
      return Object.assign({}, state, {
        error: action.data,
        isRequestFlightLoading: false
      });
    }
    case types.AVAIL_OFFER_QUOTING_NOT_AVAILABLE:{
      return Object.assign({},state,{
        confirmationMessage: action.data,
        isRequestFlightLoading: false
      })
    }
    case types.BUY_OFFER_INIT: {
      return Object.assign({}, state, {
        isRequestFlightLoading: true,
        buyOfferSuccess: false,
        orderId: "",
        orderIdentifiers: "",
        orderStatus: "",
        error: '',
      });
    }
    case types.BUY_OFFER_SUCCESS: {
      return Object.assign({}, state, {
        orderIdentifiers: action.data.identifiers,
        orderId: action.data.orderId,
        isRequestFlightLoading: false,
        buyOfferSuccess: true,
        orderStatus: action.data.status,
        legArray: action.data.legArray,
        quoteContent: {},
      });
    }
    case types.BUY_OFFER_FAIL: {
      return Object.assign({}, state, {
        error: action.data,
        isRequestFlightLoading: false,
        requestFlightSuccess: false
      });
    }
    case types.UPDATE_QUOTE_CONSTANT: {
      return Object.assign({}, state, {
        quoteContent: {}
      });
    }
    default:
      return state;
  }
}
