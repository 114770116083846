// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { capitalize } from 'utils/stringUtils';
import { encodeData } from 'utils/sourceUtils';

// Constants
const DATE_FORMAT = 'YYYYMMDDTHHmmss';
const BASE_URL = 'https://calendar.google.com/calendar/render?';
const ACTION = 'TEMPLATE';
const OUTPUT = 'xml';
const TIMEZONE = 'Universal';


export class AddEventCalendarLink extends Component {
  getTitle() {
    const {
      departureAirport,
      arrivalAirport,
    } = this.props;

    const departureCity = capitalize(departureAirport.city);
    const departureCountry = capitalize(departureAirport.country);
    const arrivalCity = capitalize(arrivalAirport.city);
    const arrivalCountry = capitalize(arrivalAirport.country);

    return `Flight: ${departureCity}, ${departureCountry} - ${arrivalCity}, ${arrivalCountry}`;
  }

  getUtcDate(dateTime) {
    return moment(dateTime).utc().format(DATE_FORMAT);
  }

  getDates() {
    const {
      arrivalTime,
      departureTime,
    } = this.props;
    return `${this.getUtcDate(departureTime)}/${this.getUtcDate(arrivalTime)}`;
  }

  getLocation() {
    const { departureAirport } = this.props;
    const name = capitalize(departureAirport.name);
    const city = capitalize(departureAirport.city);
    const country = capitalize(departureAirport.country);

    return `${name} Airport - ${city}, ${country}`;
  }

  getLink() {
    const baseUrl = BASE_URL;
    const action = ACTION;
    const text = this.getTitle();
    const dates = this.getDates();
    const location = this.getLocation();
    const ctz = TIMEZONE;
    const output = OUTPUT;

    const params = encodeData({
      action,
      text,
      dates,
      location,
      ctz,
      output,
    });

    return `${baseUrl}${params}`;
  }

  render() {
    return (
      <a
        href={this.getLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        Add to Calender
      </a>
    );
  }
}

AddEventCalendarLink.propTypes = {
  departureAirportId: PropTypes.number,
  departureAirport: PropTypes.object,
  departureTime: PropTypes.string,
  arrivalAirportId: PropTypes.number,
  arrivalAirport: PropTypes.object,
  arrivalTime: PropTypes.string,
};

AddEventCalendarLink.defaultProps = {
  departureAirport: {},
  departureTime: '',
  arrivalAirport: {},
  arrivalTime: '',
};

export default AddEventCalendarLink;
