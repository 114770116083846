import * as types from '../actionTypes';
import {
  existInLocal,
  getFromLocal,
} from '../../utils/cache';
import { garanteeArray } from '../../utils/sourceUtils';
import {
  getPassengerId,
  comparePassengers,
  mergePassenger,
  setPaxDefaultPassport,
  setPaxLegPassport,
  setLeadPax,
} from '../../utils/passengerUtils';
import { currentPaxNormalizer } from '../../normalizers/PassengerNormalizer';
import { getUser } from '../../utils/userUtils';
import { clone } from '../../utils/Helpers';

const initialState = {
  frequentPax: existInLocal('frequentPax')
    ? garanteeArray(getFromLocal('frequentPax', true)) : [],
  addedPax: [],
  paxDetail: {},
  deletedPax: [],
  selectedPassengerId: null,
  count: 0,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.INITIALIZE_POPUP: {
      const { passengers, deletedPassengers } = action.data;
      const frequentPax = existInLocal('frequentPax')
        ? garanteeArray(getFromLocal('frequentPax', true)) : [];
      const currentUser = clone(getUser());
      currentUser.passports = Object.values(currentUser.passports.content);
      const currentUserPax = currentPaxNormalizer(currentUser);
      const currentInCache = frequentPax.find(pax => (comparePassengers(pax, currentUserPax)));
      if (!currentInCache) {
        frequentPax.push(currentUserPax);
      } else {
        mergePassenger(currentInCache, currentUserPax);
        currentInCache.passports = currentUserPax.passports;
      }
      const passengerIds = passengers.map(pax => getPassengerId(pax));
      state.frequentPax = frequentPax.filter(pax => (
        !passengerIds.includes(getPassengerId(pax))
      ));
      state.addedPax = passengers;
      state.deletedPax = deletedPassengers;

      return Object.assign({}, state);
    }
    case types.UPDATE_FREQUENT_PAX: {
      state.frequentPax = action.data;
      return Object.assign({}, state);
    }
    case types.UPDATE_PASSPORT: {
      const { data, passengerId } = action.data;
      state.addedPax.forEach(pax => {
        const id = getPassengerId(pax);
        if (id === passengerId) {
          if (data.isNew) {
            pax.passports.push(data);
          }
          if (data.isUpdated) {
            pax.passports.forEach(passport => {
              const pass = passport;
              if (passport.passportNumber === data.passportNumber) {
                pass.displayName = data.displayName;
                pass.expiry = data.expiry;
                pass.isUpdated = data.isUpdated;
                pass.isoPassportCountry = data.isoPassportCountry;
                pass.isoPassportIssuedByCountry = data.isoPassportIssuedByCountry;
                pass.passportCountry = data.passportCountry;
                pass.passportIssuedByCountry = data.passportIssuedByCountry;
              }
            });
          }
          setPaxLegPassport(pax.passports);
          setPaxDefaultPassport(pax.passports);
        }
      });
      return Object.assign({}, state, {
        count: state.count + 1,
      });
    }
    case types.UPDATE_ADDED_PASSENGERS: {
      const { passenger } = action.data;
      const id = getPassengerId(passenger);
      let isFrequent = false;
      const selectedpax = passenger;
      selectedpax.passports = setPaxLegPassport(selectedpax.passports);
      selectedpax.passports = setPaxDefaultPassport(selectedpax.passports);
      state.frequentPax.forEach((pax, index) => {
        const paxid = getPassengerId(pax);
        if (paxid === id) {
          isFrequent = true;
          pax.isSoftlyAdded = true; // eslint-disable-line
          pax.passports = setPaxLegPassport(pax.passports); // eslint-disable-line
          pax.passports = setPaxDefaultPassport(pax.passports); // eslint-disable-line
          state.addedPax.push(pax);
          state.frequentPax.splice(index, 1);
        }
      });
      if (!isFrequent) {
        selectedpax.isSoftlyAdded = true; // eslint-disable-line
        state.addedPax.push(selectedpax);
      }
      setLeadPax(state.addedPax);
      return Object.assign({}, state, {
        count: state.count + 1,
      });
    }
    case types.CLOSE_POPUP: {
      state = initialState;
      return Object.assign({}, state, {
        count: state.count - 1,
      });
    }
    case types.SET_LEG_PASSPORT: {
      const { passengerId, passportNumber } = action.data;
      state.addedPax.forEach(pax => {
        const id = getPassengerId(pax);
        if (id === passengerId) {
          pax.passports.forEach(passport => {
            const paxPass = passport;
            if (paxPass.passportNumber === passportNumber) {
              paxPass.isLegPassport = true;
            } else {
              paxPass.isLegPassport = false;
            }
          });
        }
      });
      return Object.assign({}, state, {
        count: state.count + 1,
      });
    }
    case types.SET_LEAD_PASSENGER: {
      const { paxId } = action.data;
      state.addedPax.forEach(pax => {
        const passenger = pax;
        const id = getPassengerId(passenger);
        if (paxId === id) {
          passenger.leadPassenger = true;
        } else {
          passenger.leadPassenger = false;
        }
      });
      return Object.assign({}, state, {
        count: state.count + 1,
      });
    }
    case types.UPDATE_SELECTED_PASSENGER: {
      state.selectedPassengerId = action.data.paxId;
      return Object.assign({}, state, {
      });
    }
    case types.CLEAR_SELECTED_PASSENGER: {
      state.selectedPassengerId = null;
      return Object.assign({}, state, {
        count: state.count + 1,
      });
    }
    case types.REMOVE_PASSPORT: {
      const { passportNumber, passengerId } = action.data;
      state.addedPax.forEach(pax => {
        const id = getPassengerId(pax);
        if (id === passengerId) {
          pax.passports.forEach((passport, index) => {
            const paxPass = passport;
            if (paxPass.passportNumber === passportNumber) {
              if (paxPass.isNew) {
                pax.passports.splice(index, 1);
              } else {
                paxPass.isDeleted = true;
              }
            }
          });
          setPaxLegPassport(pax.passports);
          setPaxDefaultPassport(pax.passports);
        }
      });
      return Object.assign({}, state, {
        count: state.count + 1,        
      });
    }
    case types.REMOVE_USER: {
      const { paxId } = action.data;
      state.addedPax.forEach((pax, index) => {
        const passengerId = getPassengerId(pax);
        if (passengerId === paxId) {
          if (!pax.isNewlyAdded && !pax.isSearched) {
            const leadPax = pax;
            leadPax.leadPassenger = false;
            pax.passports.forEach((passport) => {
              const paxPassport = passport;
              paxPassport.isLegPassport = false;
            });
            state.frequentPax.push(pax);
          }
          if (!pax.isSoftlyAdded) {
            state.deletedPax.push(pax);
          }
          state.addedPax.splice(index, 1);
        }
      });
      setLeadPax(state.addedPax);
      return Object.assign({}, state, { count: state.count + 1
      });
    }
    default:
      return state;
  }
}
