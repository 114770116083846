import React, { Component } from "react";
import CreditCardFlag from "../../shared/CreditCardFlag";
import MessagePopUp from "../../shared/MessagePopUp";
import ConfirmationPopUp from "../../shared/ConfirmationPopUpNew";
import SpinnerLoader from "../../shared/spinnerLoader";
import { isMobile } from "react-device-detect";

export default class CreditCardListingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupMessage: ""
    };
    this.selectedId = null;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isAddCreditCardError &&
      this.props.addCreditCardLoadingError !==
      nextProps.addCreditCardLoadingError
    ) {
      window.UIkit.modal("#creditCardListingErrorPopUp");
    }
  }

  closePopup = ref => {
    ref.hide();
  };

  setAsNewDefault = (id, dropdownId) => {
    const { user } = this.props.user;

    const { addCreditCardAction, creditCards } = this.props;

    const requestPayload = {
      type: null,
      defaultChecked: true,
      alias: creditCards.content[id].alias,
      isEdit: true,
      isSetDefault: true
    };
    if (isMobile) {
      window.UIkit.dropdown(`#${dropdownId}`).hide();
    }
    addCreditCardAction(user.userToken, requestPayload).then(
      () => { },
      this.handleApiErrors
    );
  };

  deleteCreditCard = id => {
    const { user } = this.props.user;

    const { deleteCreditCardAction, creditCards } = this.props;

    const requestPayload = {
      cardNumberLastFour: creditCards.content[id].cardNumberLastFour,
      cardBrandId: creditCards.content[id].cardBrandId,
      alias: id
    };

    deleteCreditCardAction(user.userToken, requestPayload).then(
      () => { },
      this.handleApiErrors
    );
    this.closePopup(this.creditCardDeletePopUp);
  };

  handleApiErrors = err => {
    this.setState({
      popupMessage: err
    });
  };

  getCreditCardImage = brandId => {
    return <CreditCardFlag brandId={brandId} />;
  };

  renderDots = () => {
    let arr = [];
    for (let i = 0; i <= 11; i++) {
      arr.push(<span key={i} className="vistajet_credit_card_dots" />);
    }
    return arr;
  };

  updateCreditCardDetails = (id, isDefaultEdit) => {
    const { toggleIsCreditCardAddEditDetails } = this.props;
    toggleIsCreditCardAddEditDetails(false, true, id, isDefaultEdit);
  };

  showDefaultDeletePrompt = (dropdownId) => {
    if (isMobile) {
      window.UIkit.dropdown(`#vistajet_card_dropdown_default`).hide();
    }
    this.defaultDeletePopup.show();
  };

  renderCreditCardListing = () => {
    const { toggleIsCreditCardAddEditDetails } = this.props;
    const { creditCards, isAddCreditCardLoading } = this.props;
    const { popupMessage } = this.state;
    return (
      <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card vistajet_direct_form">
        {isAddCreditCardLoading &&
          <div className="vistajet_spinner">
            <SpinnerLoader />
          </div>}

        <div class="vistajet_head_column">
          <div class="uk-clearfix">
            <div class="uk-float-left">
              <h3>Payment Methods</h3>
            </div>
          </div>
        </div>

        {/* <div
          className="uk-grid-collapse vistajet_card_content uk-margin-remove vistajet_account_details_card uk-flex uk-align-center"
          data-uk-grid
        >
          <div className="uk-width-expand@s">
            <h3 className="vistajet_right_tab_heading">
              Payment Methods
              </h3>
          </div>
        </div> */}

        <div className="vistajet_profile_details_column">
          <div className="vistajet_passport_details">
            {this.renderDefaultCreditCard()}
          </div>
        </div>

        {/* <div className="uk-grid uk-margin-remove vistajet_my_details">
          <div className="uk-width-expand@s vistajet_passport_list">
            <span className="vistajet_label">Default Payments</span>
            {this.renderDefaultCreditCard()}
          </div>
        </div> */}
        {creditCards.hasOwnProperty("ids") &&
          creditCards.ids.length > 1 &&
          <div className="vistajet_profile_details_column">
            <div className="vistajet_passport_details">
              <div className="uk-flex uk-grid-small" data-uk-grid>
                <div className="uk-width-1-1">
                  <div className="vistajet_passpport_list_content">
                    <div className="vistajet_label">
                      <span>Other Payments</span>
                    </div>
                    {this.renderOtherCards()}
                  </div>
                </div>
              </div>
            </div>
          </div>}

        {/* {
          creditCards.hasOwnProperty('ids') && creditCards.ids.length > 1 && (
            <div className="uk-grid uk-margin-remove vistajet_my_details">
              <div className="uk-width-expand@s vistajet_passport_list">
                <span className="vistajet_label">Other Payments</span>
                {this.renderOtherCards()}
              </div>
            </div>
          )
        } */}

        <div className="uk-grid uk-margin-remove vistajet_my_details">
          <div className="uk-width-expand@s vistajet_add_button_wrapper">
            <a
              className="vistajet_button_default"
              onClick={() => toggleIsCreditCardAddEditDetails(true, false)}
            >
              Add Card
            </a>
          </div>
        </div>
        <ConfirmationPopUp
          id="areYouSureYouWantToDelete"
          ref={creditCardDeletePopUp => {
            this.creditCardDeletePopUp = creditCardDeletePopUp;
          }}
          leftButtonContent="Delete"
          onLeftButtonClick={() => this.deleteCreditCard(this.selectedId)}
          rightButtonContent="Cancel"
          onRightButtonClick={() => this.closePopup(this.creditCardDeletePopUp)}
          content="Are you sure you wish to remove this credit card?"
        />
        <ConfirmationPopUp
          id="cannotDeleteDefault"
          ref={defaultDeletePopup => {
            this.defaultDeletePopup = defaultDeletePopup;
          }}
          rightButtonContent="Cancel"
          onRightButtonClick={() => this.closePopup(this.defaultDeletePopup)}
          content="Please select another credit card as default"
          title="You can't delete your default credit card"
        />
        <MessagePopUp id="creditCardListingErrorPopUp" content={popupMessage} />
      </div>
    );
  };

  showDeletePrompt = (id, dropdownId) => {
    if (isMobile) {
      window.UIkit.dropdown(`#${dropdownId}`).hide();
    }
    this.selectedId = id;
    this.creditCardDeletePopUp.show();
  };

  renderDefaultCreditCard = () => {
    const { creditCards } = this.props;
    if (!creditCards.hasOwnProperty("ids") || creditCards.ids.length === 0) {
      return <h5 className="vistajet_userprofile_credit_card_error">No credit card added</h5>;
    }
    if (creditCards.hasOwnProperty("ids") && creditCards.ids.length > 0) {
      const defaultCreditCardId = creditCards.defaultId;
      const card = creditCards.content[defaultCreditCardId];
      return (
        <div className="uk-flex uk-grid-small" data-uk-grid>
          <div className="uk-width-1-1">
            <div className="vistajet_passpport_list_content">
              <div className="vistajet_label">
                <span>Default Payment</span>
              </div>
              <div className="vistajet_passport vistajet_details_hover vistajet_select_credit_card uk-flex uk-flex-middle">
                <div className="uk-inline uk-flex uk-flex-middle vistajet_card_container">
                  {this.getCreditCardImage(card.cardBrandId)}
                  <p className="vistajet_card_number">
                    {this.renderDots()}
                    {card.cardNumberLastFour}
                  </p>
                </div>
                <div className="vistajet_passport_buttons_list vistajet_passport_hover_action vistajet_card_container">
                  <ul class="uk-iconnav uk-flex-right uk-hidden@m">
                    <li><button class="vistajet_more_icon" type="button"></button></li>
                    <div id="vistajet_card_dropdown_default" uk-dropdown="mode: click; pos: bottom-right" class="uk-margin-remove vistajet_iconnav_dropdown">
                      <ul class="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
                        <li className="uk-text-truncate"
                          onClick={() =>
                            this.updateCreditCardDetails(defaultCreditCardId, true)}
                          data-index={defaultCreditCardId}>
                          <a>
                            <img src="/legacy/static/img/icons/vistajet_icon_passport_edit.svg" />
                            <span>Edit</span>
                          </a>
                        </li>
                        <li className="uk-text-truncate">
                          <a
                            onClick={this.showDefaultDeletePrompt}
                            data-index={defaultCreditCardId}
                          >
                            <img src="/legacy/static/img/icons/vistajet_icon_passport_delete.svg" />
                            <span>Delete</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                  <div className="uk-visible@m">
                    <span
                      onClick={() =>
                        this.updateCreditCardDetails(defaultCreditCardId, true)}
                      data-index={defaultCreditCardId}
                    >
                      <img src="/legacy/static/img/icons/vistajet_edit_red_icon.svg" alt="" />
                    </span>
                    <span
                      onClick={this.showDefaultDeletePrompt}
                      data-index={defaultCreditCardId}
                    >
                      <img src="/legacy/static/img/icons/vistajet_close_icon.svg" alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  renderOtherCards = () => {
    const { creditCards } = this.props;
    const defaultCreditCardId = creditCards.defaultId;
    return creditCards.ids.map((cardId, index) => {
      if (cardId != defaultCreditCardId) {
        const card = creditCards.content[cardId];
        const dropdownId = `vistajet_card_dropdown${index}`;
        return (
          <div className="vistajet_passport vistajet_details_hover vistajet_select_credit_card uk-flex uk-flex-middle">
            <div className="uk-inline uk-flex uk-flex-middle vistajet_card_container">
              {this.getCreditCardImage(card.cardBrandId)}
              <p className="vistajet_card_number">
                {this.renderDots()}
                {card.cardNumberLastFour}
              </p>
            </div>
            <div className="vistajet_passport_buttons_list vistajet_passport_hover_action vistajet_card_container">
              <div className="uk-visible@m">
                <span>
                  <a
                    onClick={() => this.setAsNewDefault(cardId,dropdownId)}
                    data-index={cardId}
                  >
                    Set as Default
                  </a>
                </span>
                <span
                  onClick={() => this.updateCreditCardDetails(cardId, false)}
                  data-index={cardId}
                >
                  <img
                    src="/legacy/static/img/icons/vistajet_edit_red_icon.svg"
                    alt=""
                  />
                </span>
                <span
                  onClick={() => this.showDeletePrompt(cardId)}
                  data-index={cardId}
                >
                  <img src="/legacy/static/img/icons/vistajet_close_icon.svg" alt="" />
                </span>
              </div>
              <ul class="uk-iconnav uk-flex-right uk-hidden@m">
                <li><button class="vistajet_more_icon" type="button"></button></li>
                <div id={dropdownId} uk-dropdown="mode: click; pos: bottom-right; delay-hide: 0;" class="uk-margin-remove vistajet_iconnav_dropdown">
                  <ul class="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
                    <li className="uk-text-truncate">
                      <a
                        onClick={() => this.setAsNewDefault(cardId, dropdownId)}
                        data-index={cardId}>
                        <img src="/legacy/static/img/icons/vistajet_icon_passport_creditcarddefault.svg" />
                        <span>Set As Default</span>
                      </a>
                    </li>
                    <li className="uk-text-truncate">
                      <a
                        onClick={() => this.updateCreditCardDetails(cardId, false)}
                        data-index={cardId}
                      >
                        <img src="/legacy/static/img/icons/vistajet_icon_passport_edit.svg" />
                        <span>Edit</span>
                      </a>
                    </li>
                    <li className="uk-text-truncate">
                      <a onClick={() => this.showDeletePrompt(cardId, dropdownId)}
                        data-index={cardId}>
                        <img src="/legacy/static/img/icons/vistajet_icon_passport_delete.svg" />
                        <span>Delete</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>

          </div>
        );
      }
    });
  };

  render() {
    return this.renderCreditCardListing();
  }
}
