import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  PassengerList,
  PassengerSearch,
} from 'constants/passengerConstants';
import PassengerRow from './PassengerRow';
import Helpers from 'utils/Helpers';

class ListPassengerRows extends Component {

  handlePassengerClick = () => {
    this.props.handleRenderingChange(PassengerSearch, PassengerList);
  };

  renderPassengers = (count, addedPax) => {
    const list = addedPax.map((pax, i) => (
      <PassengerRow
        key={i}
        uniqueId={`uniqueId-${i}`}
        passenger={pax}
        passengerCount={addedPax.length}
        handleViewDetail={this.props.handleViewDetail}
        handleRemovePassport={this.props.handleRemovePassport}
        handleAddNewPassport={this.props.handleAddNewPassport}
        handleDirtyChange={this.props.handleDirtyChange}
        setLeadPassenger={this.props.setLeadPassenger}
        removeUser={this.props.removeUser}
        setLegPassport={this.props.setLegPassport}
      />)
    );
    const listLength = list.length > 0 ? list.length : 0;
    for (let i = 1; i <= count - listLength; i++) {
      list.push(
      <div className="vistajet_passenger_list uk-margin-remove uk-padding-bottom vistajet_remove_border_bottom" key={Helpers.guid()}>
        <div className="uk-padding-small-right uk-grid" onClick={this.handlePassengerClick}>
          <div className="uk-width-1-1 uk-margin-small-bottom uk-padding-remove vistajet_passenger_list_child_list vistajet_select_passenger">
            <span className="uk-align-left vistajet_passenger_list_name uk-margin-small">Select Passenger</span>
            <span className="uk-align-right vistajet_passenger_status">
                <span className=""> <img src="/legacy/static/img/icons/vistajet_chevron_right_gray.svg" alt=""/></span>
            </span>
          </div>
        </div>
      </div>
      );
    }
    return list;
  }
  render() {
    const {
      count,
      addedPax,
      showLoaderPassenger,
    } = this.props;
    return (
    <div>
      {!showLoaderPassenger ?
        this.renderPassengers(count, addedPax) :
        (
          <div className="vistajet_spinner"/>
        )
      }
    </div>
    );
  }
}

ListPassengerRows.propTypes = {
  count: PropTypes.number,
  handleRenderingChange: PropTypes.func,
  addedPax: PropTypes.array,
  handleViewDetail: PropTypes.func,
  handleRemovePassport: PropTypes.func,
  handleAddNewPassport: PropTypes.func,
  handleDirtyChange: PropTypes.func,
};

export default ListPassengerRows;
