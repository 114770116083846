import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import SpinnerLoader from '../shared/spinnerLoader';
import MessagePopUp from '../shared/MessagePopUpNew';
import { Salutation } from '../../constants/SignupConstants';
import {
  POLICY_URL_PAGE,
  CONFIRM_REGISTER_PAGE_URL,
} from '../../configs/constants';
import IntlTelphoneInput from '../shared/PhoneNumberSelector';
import { getCountry } from 'utils/countryUtils';
import validateRegistrationForm from '../../utils/Validators/RegistrationFormValidator';

import {
  getFirstChars,
} from '../../utils/stringUtils';
import { errorMessages } from '../../constants/ErrorMessageConstants';

export default class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salutation: Salutation[0],
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      marketingCheckbox: false,
      agreementCheckbox: false,
      errors: {},
      defaultCountry: null,
      isPhoneNumberValid: false,
      disableCreateButton: false,
      popupMessage: '',
      passwordType: 'password',
      confirmPasswordType: 'password',
      passwordIconClass: 'vistajet_password_hide',
      confirmPasswordIconClass: 'vistajet_password_hide',
      countryCode: '',
      countryName: '',
    };
  }

  componentDidMount() {
    const {
      fetchAdminToken,
      authenticateTokenAction,
      isLoggedIn,
      changePasswordRequired,
      registrationState,
    } = this.props;
    fetchAdminToken().then(({ token }) => {
      authenticateTokenAction(token).then(() => {
      }, this.handleApiErrors);
    }, this.handleApiErrors);


    if (isLoggedIn && !changePasswordRequired) {
      this.redirectToHome();
    }

    if (registrationState && registrationState.hasOwnProperty('firstName')) { // eslint-disable-line
      this.populateValues();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.paymentDetails !== nextProps.paymentDetails
      && nextProps.isSuccess
    ) {
      window.open(CONFIRM_REGISTER_PAGE_URL.url, "_self");
    }
    if (nextProps.isLoggedIn && !nextProps.changePasswordRequired) {
      this.redirectToHome();
    }
    // Error Popup handling
    if (
      this.props.adminTokenError !== nextProps.adminTokenError
      && nextProps.isAdminTokenError
    ) {
      this.registrationErrorPopUp.show();
    }
    if (
      this.props.tokenError !== nextProps.tokenError
      && nextProps.isTokenError
    ) {
      this.registrationErrorPopUp.show();
    }
    if (
      this.props.authError !== nextProps.authError
      && nextProps.isAuthError
    ) {
      this.registrationErrorPopUp.show();
    }
    if (
      this.props.userRegistrationError !== nextProps.userRegistrationError
      && nextProps.isUserRegisterError
    ) {
      this.registrationErrorPopUp.show();
    }
    if (
      this.props.directSignupError !== nextProps.directSignupError
      && nextProps.isDirectSignupError
    ) {
      this.registrationErrorPopUp.show();
    }
  }

  populateValues = () => {
    const { registrationState } = this.props;
    this.setState(
      registrationState,
    );
    // call this handler to update state of phoneNumber
    this.handlePhoneNumberChange(true, registrationState.phoneNumber);
  };

  handleApiErrors = (err) => {
    this.setState({
      popupMessage: err,
    });
  };

  redirectToHome = () => {
    window.location = '/';
  };

  handleSalutationChange = (obj) => {
    this.setState({ salutation: obj });
  };

  handleFirstNameChange = (e) => {
    this.setState({ firstName: e.target.value });
  };

  handleLastNameChange = (e) => {
    this.setState({ lastName: e.target.value });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  // handlePhoneNumberBlur = () => {
  //   const { errors, phoneNumber, isPhoneNumberValid } = this.state;
  //   this.setState({
  //     errors: {
  //       ...errors,
  //       // phoneNumber: validatePhoneNumber(phoneNumber, isPhoneNumberValid),
  //     },
  //   });
  // }

  handlePhoneNumberChange = (status = true, phoneNumber, countryData) => {
    const country = getCountry('TWO_LETTER_CODE', countryData.iso2) || {};
    if (country && country.THREE_LETTER_CODE) {
      this.setState({
        phoneNumber: getFirstChars(phoneNumber, 15, status),
        isPhoneNumberValid: status,
        countryCode: country && country.THREE_LETTER_CODE,
        countryName: country && country.COUNTRY_NAME,
      });
    } else {
      this.setState({
        phoneNumber: getFirstChars(phoneNumber, 15, status),
        isPhoneNumberValid: status,
        countryName: country && country.COUNTRY_NAME,
      });
    }
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  }

  handleConfirmPasswordChange = (e) => {
    this.setState({ confirmPassword: e.target.value });
  }

  toggleConfirmPassView = () => {
    const { confirmPasswordType } = this.state;
    if (confirmPasswordType === 'password') {
      this.setState({
        confirmPasswordType: 'text',
        confirmPasswordIconClass: 'vistajet_password_show',
      });
    } else {
      this.setState({
        confirmPasswordType: 'password',
        confirmPasswordIconClass: 'vistajet_password_hide',
      });
    }
  }

  togglePassView = () => {
    const { passwordType } = this.state;
    if (passwordType === 'password') {
      this.setState({
        passwordType: 'text',
        passwordIconClass: 'vistajet_password_show',
      });
    } else {
      this.setState({
        passwordType: 'password',
        passwordIconClass: 'vistajet_password_hide',
      });
    }
  }
  makePayloadForDirectRegistration = () => {
    const {
      firstName,
      lastName,
      countryCode,
      email
    } = this.state;
    const SignUpDto = {
      addressableName: `${firstName} ${lastName}`,
      countryCode: countryCode,
      city: '-',
      billingAddress: '',
      membershipTypeId: 1,
    }
    return SignUpDto;
  }
  handleAgreementCheck = () => {
    const { agreementCheckbox } = this.state;
    this.setState({
      agreementCheckbox: !agreementCheckbox,
    });
  }

  handleMarketingCheck = () => {
    const { marketingCheckbox } = this.state;
    this.setState({
      marketingCheckbox: !marketingCheckbox,
    });
  }

  validateForm = (e) => {
    e.preventDefault();
    const {
      salutation,
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword,
      // marketingCheckbox,
      agreementCheckbox,
      isPhoneNumberValid,
    } = this.state;
    const res = validateRegistrationForm({
      salutation,
      firstName,
      lastName,
      email: email.toLowerCase(),
      phoneNumber,
      password,
      confirmPassword,
      // marketingCheckbox,
      agreementCheckbox,
      isPhoneNumberValid,
    });
    if (res.isError) {
      // set state of errors
      this.setState({
        errors: res,
      });
    } else {
      // API call to create account
      this.setState({
        error: {},
      });
      try {
        window.dataLayer.push({ 'event': 'enquirySubmitted', 'enquiryType': 'Registration' });
      } catch (e) {
        console.warn("dataLayer push failed for 'Registration': " + e)
      }
      this.createAccount();
    }
  }

  createAccount = () => {
    const {
      salutation,
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      countryName,
      confirmPassword,
      marketingCheckbox,
      agreementCheckbox,
    } = this.state;
    const {
      getToken,
      authenticateTokenAction,
      directSignupSourceAction,
      registerMemeber,
    } = this.props;
    const requestPayload = {
      salutation,
      firstName,
      lastName,
      email: email.toLowerCase(),
      phoneNumber,
      password,
      countryName,
      confirmPassword,
      marketingCheckbox,
      agreementCheckbox,
    };
    const SignUpDto = this.makePayloadForDirectRegistration();
    getToken().then(({ token }) => {
      authenticateTokenAction(token).then((auth) => {
        registerMemeber(
          auth.token,
          requestPayload,
        ).then((user) => {
          directSignupSourceAction(user.user.token, SignUpDto).then(() => {
          }, this.handleApiErrors)
        }, this.handleApiErrors);
      }, this.handleApiErrors);
    }, this.handleApiErrors);
  }

  generateSalutationOptions = () => {
    return Salutation.map((salutation) => {
      return {
        value: salutation.value,
        label: salutation.label,
      };
    });
  };

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  removePhoneError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors.phoneNumber = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  }

  handleNext = () => {
    const { progressIncrement, updateProgressInWizard } = this.props;
    const {
      salutation,
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword,
      marketingCheckbox,
      agreementCheckbox,
    } = this.state;
    const stateObject = {
      salutation,
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword,
      marketingCheckbox,
      agreementCheckbox,
    };
    if (progressIncrement <= 3) {
      const value = progressIncrement + 1;
      updateProgressInWizard(value, { registrationState: stateObject });
    }
  };

  handleGoBack = () => {
    const { progressIncrement, updateProgressInWizard } = this.props;
    if (progressIncrement > 1) {
      const value = progressIncrement - 1;
      updateProgressInWizard(value);
    }
  };

  renderForm = () => {
    const salutationOptions = this.generateSalutationOptions();
    const {
      salutation,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      phoneNumber,
      errors,
      defaultCountry,
      marketingCheckbox,
      agreementCheckbox,
      popupMessage,
      passwordType,
      confirmPasswordType,
      passwordIconClass,
      confirmPasswordIconClass,
    } = this.state;
    const {
      isWizard,
      tokenLoading,
      registerLoading,
      adminTokenLoading,
      isMemberPlanLoading,
      isAuthLoading,
      wizardStepper,
      progressIncrement,
      isDirectSignupLoading,
    } = this.props;
    const createAccountButtonText = registerLoading
      || tokenLoading
      || isAuthLoading
      || adminTokenLoading
      ? 'Loading...' : 'Create an Account';
    const countinueRegisterButtonText = registerLoading
      || tokenLoading
      || isAuthLoading
      || adminTokenLoading
      || isMemberPlanLoading
      ? 'Loading...' : 'Continue';
    let mobileErrorTextMarginStyle = {};
    if (
      isMobile
      && errors.errors
      && errors.errors.password
      && (errorMessages.PASS_MAX_LENGTH == errors.errors.password
        || errors.errors.password == errorMessages.INVALID_PASS)) {
      mobileErrorTextMarginStyle = { marginBottom: 40 };
    }
    return (
      <div className="uk-card uk-card-default uk-card-body vistajet_card">
        <h2 className="uk-margin-medium-bottom h5">
          Please enter your details
        </h2>
        <div className="vistajet_primary_form vistajet_registration_form">
          <form className="uk-grid-medium uk-grid" data-uk-grid>
            <div className="uk-width-1-2@s">
              <div className="uk-grid-collapse" data-uk-grid>
                <div className="uk-width-1-3 uk-width-1-3@s">
                  <div
                    className="vistajet_input_wrapper"
                    style={{
                      borderRight: 'none',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    <Select
                      inputId="title"
                      classNamePrefix="react-select"
                      isSearchable={false}
                      onChange={this.handleSalutationChange}
                      options={salutationOptions}
                      value={salutation}
                      id="salutation-select"
                      className="vistajet_select_input"
                      name="salutation"
                    />
                    <label htmlFor="title">
                      Title
                    </label>
                    <i uk-icon="icon: chevron-down; ratio: 1" />
                  </div>
                </div>
                <div className="uk-width-expand uk-width-2-3@s">
                  <div
                    className="vistajet_input_wrapper"
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderLeft: 0,
                    }}
                  >
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      onChange={this.handleFirstNameChange}
                      onFocus={this.removeErrors}
                      value={firstName}
                      required
                      autoComplete="given-name"
                    />
                    <label htmlFor="firstName">
                      Name
                    </label>
                    {
                      errors.errors && errors.errors.firstName && (
                        <p>
                          {errors.errors.firstName}
                        </p>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>


            <div className="uk-width-1-2@s">
              <div className="vistajet_input_wrapper">
                <input
                  id="lastName"
                  type="text"
                  name="lastName"
                  onChange={this.handleLastNameChange}
                  onFocus={this.removeErrors}
                  value={lastName}
                  required
                  autoComplete="family-name"
                />
                <label htmlFor="lastName">
                  Surname
                </label>
                {
                  errors.errors && errors.errors.lastName && (
                    <p>
                      {errors.errors.lastName}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-2@s">
              <div className="vistajet_input_wrapper">
                <input
                  id="email"
                  type="text"
                  name="email"
                  onChange={this.handleEmailChange}
                  onFocus={this.removeErrors}
                  value={email}
                  required
                  autoComplete="email"
                />
                <label htmlFor='email'>
                  Email
                </label>
                {
                  errors.errors && errors.errors.email && (
                    <p>
                      {errors.errors.email}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-2@s">
              <div className="vistajet_input_wrapper vistajet_input_phone_number">
                <IntlTelphoneInput
                  id="phoneNumber"
                  value={phoneNumber}
                  errorText={errors.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                  onFocus={this.removePhoneError}
                  onBlur={this.handlePhoneNumberBlur}
                  hintText=""
                  autoPlaceholder={false}
                  defaultCountry={defaultCountry}
                />
                <label htmlFor="telephone">
                  Phone Number
                </label>
                {
                  errors.errors && errors.errors.phoneNumber && (
                    <p>
                      {errors.errors.phoneNumber}
                    </p>
                  )
                }
              </div>
            </div>

            <div className="uk-width-1-2@s uk-flex uk-flex-middle">
              <div
                className="vistajet_input_wrapper uk-flex uk-flex-middle"
                style={mobileErrorTextMarginStyle}
              >
                <input
                  id="password"
                  type={passwordType}
                  name="password"
                  onChange={this.handlePasswordChange}
                  onFocus={this.removeErrors}
                  value={password}
                  required
                  autoComplete="new-password"
                />
                <label htmlFor="password">
                  Password
                </label>
                <span // eslint-disable-line
                  className={passwordIconClass}
                  onMouseDown={this.togglePassView}
                  onMouseUp={this.togglePassView}
                  onTouchStart={this.togglePassView}
                  onTouchEnd={this.togglePassView}
                  id="passShowIcon"
                />
                {
                  errors.errors && errors.errors.password && (
                    <p>
                      {errors.errors.password}
                    </p>
                  )
                }
              </div>
            </div>


            <div className="uk-width-1-2@s">
              <div className="vistajet_input_wrapper uk-flex uk-flex-middle">
                <input
                  id="confirmPassword"
                  type={confirmPasswordType}
                  name="confirmPassword"
                  onChange={this.handleConfirmPasswordChange}
                  onFocus={this.removeErrors}
                  value={confirmPassword}
                  required
                  autoComplete="new-password"
                />
                <label htmlFor="confirmPassword">
                  Confirm Password
                </label>
                <span // eslint-disable-line
                  className={confirmPasswordIconClass}
                  onMouseDown={this.toggleConfirmPassView}
                  onMouseUp={this.toggleConfirmPassView}
                  onTouchStart={this.toggleConfirmPassView}
                  onTouchEnd={this.toggleConfirmPassView}
                  id="confirmPassShowIcon"
                />
                {
                  errors.errors && errors.errors.confirmPassword && (
                    <p>
                      {errors.errors.confirmPassword}
                    </p>
                  )
                }
              </div>
            </div>


            <div className="uk-width-1-1@m uk-width-1-1@s vistajet_checkbox vistajet_checkbox_top_auto">
              <label htmlFor="marketingCheckbox">
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  name="checkbox"
                  id="marketingCheckbox"
                  checked={marketingCheckbox}
                  onClick={this.handleMarketingCheck}
                />
                I would like to receive Marketing communication
              </label>
            </div>

            <div className="uk-width-1-1@m uk-width-1-1@s vistajet_checkbox vistajet_checkbox_top_auto">
              <label htmlFor="agreementCheckbox">
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  name="checkbox"
                  id="agreementCheckbox"
                  checked={agreementCheckbox}
                  onClick={this.handleAgreementCheck}
                  onFocus={this.removeErrors}
                />
                I consent, to the processing of my personal data in accordance with the
                <strong>
                  <a href={POLICY_URL_PAGE.replace('{headingId}', '?key=General_Information')} rel="noreferrer" target="_blank"> VistaJet Privacy Notice</a>
                </strong>
                {/* ,
                and to the
                <strong>
                  <a href={TERMS_URL_PAGE} target="_blank"> VistaJet Direct Mobile Program Terms and Conditions of Use</a>
                </strong> */}
              </label>
            </div>

            <ul className="uk-text-left uk-width-1-1 uk-margin-small-top">
              <li>
                <a href={POLICY_URL_PAGE.replace('{headingId}', '?key=What_types_of_personal_data_do_we_collect?')} rel="noreferrer" target="_blank">
                  What types of personal data do we collect?
                </a>
              </li>
              <li>
                <a href={POLICY_URL_PAGE.replace('{headingId}', '?key=What_do_we_use_your_personal_data_for?')} rel="noreferrer" target="_blank">
                  What do we use your personal data for?
                </a>
              </li>
              <li>
                <a href={POLICY_URL_PAGE.replace('{headingId}', '?key=Who_do_we_share_your_personal_data_with?')} rel="noreferrer" target="_blank">
                  Who do we share your personal data with?
                </a>
              </li>

            </ul>
            {
              errors.errors && errors.errors.agreementCheckbox && (
                <p className="vistajet_outer_error">
                  {errors.errors.agreementCheckbox}
                </p>
              )
            }
            {
              !isWizard
              && (
                <div className="uk-width-1-1@s uk-text-center uk-text-right@s uk-margin-remove">
                  <input
                    className="uk-button uk-button-full@s"
                    type="submit"
                    value={createAccountButtonText}
                    onClick={this.validateForm}
                    disabled={registerLoading
                      || tokenLoading
                      || isAuthLoading
                      || adminTokenLoading
                      || isDirectSignupLoading
                    }
                  />
                </div>
              )
            }
            {
              isWizard
              && (
                <div className="uk-width-1-1@s uk-text-right uk-margin-remove">
                  <input
                    className="uk-button"
                    type="submit"
                    value={countinueRegisterButtonText}
                    disabled={registerLoading
                      || tokenLoading
                      || isAuthLoading
                      || adminTokenLoading
                      || isMemberPlanLoading}
                    onClick={this.validateForm}
                  />
                </div>
              )
            }
          </form>
          <MessagePopUp
            id="registrationErrorPopUp"
            ref={(registrationErrorPopUp) => { this.registrationErrorPopUp = registrationErrorPopUp; }}
            content={popupMessage}
          />
        </div>
        {
          isWizard
          && (
            wizardStepper(
              progressIncrement,
            )
          )
        }
      </div>
    );
  };

  render() {
    return this.renderForm();
  }
}
