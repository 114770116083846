import * as types from "../actionTypes";
import { getAircraftsList } from "../sources/AircraftSource";

export const getAircraftsInit = () => ({
  type: types.GET_AIRCRAFTS_INIT
});

export const getAircraftsSuccess = (data) => ({
  type: types.GET_AIRCRAFTS_SUCCESS,
  data
});

export const getAircraftsFailure = (data) => ({
  type: types.GET_AIRCRAFTS_FAILURE,
  data
});

export const getAircraftsAction = () => getAircraftsList();
