import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStore from '../../components/withStore';
import Entertainment from '../../components/ItineraryDetails/Entertainment';

function mapStateToProps(state) {
  const {
    isLoadingEntertainment,
    entertainment
  } = state.entertainment;
  const { token } = state.token;
  const { isLoading } = state.itineraryDetails;
  return {
    token,
    isLoadingEntertainment,
    entertainment,
    isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEntertainmentIssue: (tailNumber, legId) => dispatch(actions.fetchEntertainmentIssueNumber(tailNumber, legId)),
    fetchEntertainment: (issueNumber, tailNumber, legId) => dispatch(actions.fetchEntertainmentDetails(issueNumber, tailNumber, legId)),
        
};
}

const EntertainmentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Entertainment);

export default withStore(EntertainmentContainer);
