/* global google:false */
import moment from "moment";
import { add } from "date-fns";
import GreatCircle from "great-circle";
import { garanteeArray } from "../utils/sourceUtils";
import CateringConstants from 'constants/CateringConstants';
import { TIME_TO_SHOW_CREW } from "../constants/ItineraryConstants";
import CountriesList from "../constants/CountryConstants";
import store from '../store/store';
import dateFormat from '../constants/DateConstants';

const LEG_STATUS_CONFIRMED = 'confirmed';
let instance = null;
const EARTH_RADIUS = 6372.8;
class Helpers {
  refs = {};
  static lastFocused = "";

  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`.toUpperCase();
  }

  getValue(data) {
    return typeof data === "object" ? (data || [])[0] : data;
  }

  getFlightDuration(deptAirport, arriveAirport, aircraftObj) {
    if (!aircraftObj) {
      return null;
    }
    const cruiseSpeed = parseFloat(this.getValue(aircraftObj.speed));
    const speedBlock30KPH = parseFloat(
      this.getValue(aircraftObj.speedFirst30minBlock)
    );
    const speedBlock60KPH = parseFloat(
      this.getValue(aircraftObj.speedSecond30minBlock)
    );
    const taxiTimeInMinutes = parseFloat(
      this.getValue(aircraftObj.taxiTimeMinutes)
    );
    const totalDistance = this.getAirportDistance(deptAirport, arriveAirport);

    const minutesInHalfAnHour = 30;
    const minutesInHour = 60;
    const sb30Distance = 0.5 * speedBlock30KPH;
    const sb60Distance = 0.5 * speedBlock60KPH;
    const sb30Distance2 = 2 * sb30Distance;
    let time = 2 * taxiTimeInMinutes;
    let distanceToGo = totalDistance;
    if (distanceToGo - sb30Distance2 > 0) {
      time += minutesInHour;
      distanceToGo -= sb30Distance2;

      if (distanceToGo - sb60Distance > 0) {
        time += minutesInHalfAnHour;
        distanceToGo -= sb60Distance;

        if (distanceToGo > 0) {
          time += (distanceToGo / cruiseSpeed) * minutesInHour;
        }
      } else {
        time += (distanceToGo / speedBlock60KPH) * minutesInHour;
      }
    } else {
      time += (distanceToGo / speedBlock30KPH) * minutesInHour;
    }

    return this.roundTo5Minutes(time); // in minutes
  }

  getAirportDistance(deptAirport, arriveAirport) {
    if (!deptAirport || !arriveAirport) return 0;
    return GreatCircle.distance(
      parseFloat(deptAirport.latitude),
      parseFloat(deptAirport.longitude),
      parseFloat(arriveAirport.latitude),
      parseFloat(arriveAirport.longitude),
      EARTH_RADIUS
    );
  }

  getCurrentTimestamp() {
    const now = new Date;
    return Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(),
      now.getMinutes(), now.getSeconds(), now.getMilliseconds());
  }

  roundTo5Minutes(minutes) {
    return this.zeroPad(5 * Math.round(minutes / 5), 2);
  }

  roundUp5(x) {
    return this.zeroPad(Math.ceil(x / 5) * 5, 2);
  }

  roundUpTo(x,roundUpto) {
    return Math.ceil(x / roundUpto) * roundUpto;
  }

  zeroPad(num, places) {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  findAndRemove(array, property, value) {
    array.forEach((result, index) => {
      if (result[property] === value) {
        // Remove from array
        array.splice(index, 1);
      }
    });
    return array;
  }

  getFlagClass(airport) {
    let countryCode = airport ? this.getValue(airport.iso2) : '';
    let flagClass = '';

    if (countryCode) {
      countryCode = countryCode.split('-');
      flagClass = `flag-icon-${countryCode[0].toLowerCase()}`;
    }
    return flagClass;
  }

  clone(obj) {
    if (!obj || typeof obj !== 'object' || !!obj.size) {
      return obj;
    }

    return JSON.parse(JSON.stringify(obj));
  }

  getFutureDateOffset() {
    const date = new Date();
    if ((date.getMonth() + 1) >= 6) {
      return 12;
    }
    return 12 - date.getMonth();
  }

  getMonthName(MonthIndex) {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov',
      'Dec'];
    let mIndex = MonthIndex;
    if (MonthIndex >= month.length) {
      mIndex = 0;
    }
    return month[mIndex + 1];
  }

  roundUp5MinutesMoment(date) {
    if (!date || !moment(date).isValid()) {
      return null;
    }
    const aux = moment(date);
    // Checks if there are any seconds or milliseconds on the date
    if (+aux.format('ssSSS') !== 0) {
      // If yes, adds a minute so when rounds down doesn't ignore the seconds
      aux.add(1, 'minute');
    }
    aux.startOf('minute'); // Rounds seconds dows
    const timeDiff = Math.ceil(aux.minutes() / 5) * 5 - aux.minutes(); // Minute difference
    return aux.add(timeDiff, 'minute'); // Rouds to 5 minutes
  }

  getLatestTimeOfAirport(airportObj) {
    let time = moment();
    const utcoffset = time.utcOffset();
    const offset = this.findOffset(time, airportObj);
    time = time.add(-1 * (utcoffset - offset), 'minutes');
    // As per VSHADOW-2279 - Removed in compliance with CMA/CMX
    // time = time.add(10, 'minutes'); // adding 10 mins more for takeoff
    return time;
  }

  generateRandomKey() {
    return Math.floor((Math.random() * 100) + 1);
  }

  redirectTo(hash) {
    window.location.hash = hash;
  }

  capitalizeFirstLetter(str) {
    if (!str) {
      return '';
    }
    return str.replace(/\w\S*/g,
      (txt) => (txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()));
  }

  getAirportDisplayText(airport) {
    // text: capitalizeFirstLetter(airport.name[0])+' '+airport.icao[0],
    return (!!airport && Object.keys(airport).length > 0) ?
    `${this.capitalizeFirstLetter(this.getValue(airport.city))}, ` +
    `${this.capitalizeFirstLetter(this.getValue(airport.name))} ` +
    `(${this.getValue(airport.icao)}), ${this.getValue(airport.iso2) ?
      this.getValue(airport.iso2).substring(0, 2) : ''}` : '';
  }
  
  getLegStatusString(statusStr) {
    let status = statusStr;
    if (status.toLowerCase() !== LEG_STATUS_CONFIRMED) {
      status = 'On Request';
    }
    return status;
  }

  getAirportSelectedText(airport) {
    return (!!airport && Object.keys(airport).length > 0) ?
      `${this.capitalizeFirstLetter(
        this.getValue(airport.name))} ${this.getValue(airport.icao)}` : '';
  }

  isGuid(id) {
    let isGuid = true;
    const regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    const match = regex.exec(id);

    if (!match) {
      // its a id generated from GV
      isGuid = false;
    }

    return isGuid;
  }
  getArrivalTime(departTime, duration, deptAirport, arriveAirport) {
    const time = moment.utc(departTime);
    // finding offset difference
    const deptOffset = this.findOffset(time, deptAirport);
    const arriveOffset = this.findOffset(time, arriveAirport);
    const offsetDiff = arriveOffset - deptOffset;
    const arrivalTime = time
      .add(duration, "minutes")
      .add(offsetDiff, "minutes")
      .format();
    return arrivalTime;
  }

  // finds the correct offset for the given timeObj and airportObj
  findOffset(time, airport) {
    let offset;
    if (airport && airport.timezones && airport.timezones.length) {
      const arrayOffset = this.getzoneOffset(time, airport.timezones);
      const zone = this.guaranteeArray(airport.timezones)[arrayOffset];
      if (
        zone.hasOwnProperty("dstFromTimeUtc") &&
        (time.isBetween(
          this.getValue(zone.dstFromTimeUtc),
          this.getValue(zone.dstToTimeUtc)
        ) ||
          time.isSame(this.getValue(zone.dstFromTimeUtc)))
      ) {
        offset = parseFloat(this.getValue(zone.dstUtcOffset) || 0);
      } else {
        offset = parseFloat(this.getValue(zone.standardUtcOffsetMinutes) || 0);
      }
    }

    return offset;
  }

  guaranteeArray(obj) {
    if (obj) {
      return this.isArray(obj) ? obj : [obj];
    }
    return [];
  }

  isArray(obj) {
    return obj.constructor === Array;
  }

  getzoneOffset(time, timezones) {
    let offset = 0;
    timezones.forEach((data, index) => {
      if (
        data.hasOwnProperty("dstFromTimeUtc") &&
        (time.isBetween(
          this.getValue(data.effectiveFromTimeUtc),
          this.getValue(data.effectiveToTimeUtc)
        ) ||
          time.isSame(this.getValue(data.effectiveFromTimeUtc)))
      ) {
        offset = index;
      }
    });
    return offset;
  }

  capitalizeWord(str) {
    return str.toLowerCase().split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }).join(' ')
  }

  // get utc string dates and returns (+1) or (+2)
  getDaysDiff(depart, arrival) {
    let result = "";
    const date1 = this.getUtcDateObj(depart);
    const date2 = this.getUtcDateObj(arrival);
    let dayDiff = date2.getDate() - date1.getDate();
    // const monthDiff = date2.getMonth() - date1.getMonth();
    // const yearDiff = date2.getYear() - date1.getYear();
    // if(monthDiff>1){
    //    monthDiff = Math.abs(date2.getMonth() - date1.getMonth())-12;
    // }else{
    //    if(monthDiff < -1){
    //        monthDiff = Math.abs(Math.abs(date1.getMonth() - date2.getMonth())-12);
    //    }
    // }
    if (dayDiff > 10) {
      dayDiff =
        Math.abs(date2.getDate() - date1.getDate()) -
        this.daysInMonth(date2.getMonth() + 1, date2.getYear());
    } else {
      if (dayDiff < -10) {
        dayDiff = Math.abs(
          Math.abs(date2.getDate() - date1.getDate()) -
          this.daysInMonth(date1.getMonth() + 1, date1.getYear())
        );
      }
    }
    let diff = 0;
    diff = dayDiff;
    // if(yearDiff>0 || yearDiff < 0){
    //    diff=diff*yearDiff;
    // }
    // if(monthDiff>0 || monthDiff < 0){
    //    diff=diff*monthDiff;
    // }
    if (diff > 0) {
      result = `(+${diff})`;
    } else if (diff < 0) {
      result = `(${diff})`;
    }

    return result;
  }
  // gets 2016-03-30T17:30:00+00:00 and returns js Obj with 0 offset

  truncateStr(text, len, useWordBoundary) {
    if (!text || !len) {
      return '';
    }
    let result = text.toString().trim();
    const isTooLong = result.length > len;
    result = isTooLong ? result.substr(0, len).trim() : result;
    result = (useWordBoundary && isTooLong) ? result.substr(0, result.lastIndexOf(' ')) : result;
    return isTooLong ? `${result}...` : result;
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  getValue(data) {
    return typeof data === "object" ? (data || [])[0] : data;
  }

  /* To deep copy an Object in tempArray */
  copy = (o) => {
    let output; let v; let
      key;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
      v = o[key];
      output[key] = (typeof v === 'object') ? this.copy(v) : v;
    }
    return output;
  };

  // finds the correct offset for the given timeObj and airportObj
  findOffset(time, airport) {
    let offset;
    if (airport && airport.timezones && airport.timezones.length) {
      const arrayOffset = this.getzoneOffset(time, airport.timezones);
      const zone = garanteeArray(airport.timezones)[arrayOffset];
      if (
        zone.hasOwnProperty("dstFromTimeUtc") &&
        (time.isBetween(
          this.getValue(zone.dstFromTimeUtc),
          this.getValue(zone.dstToTimeUtc)
        ) ||
          time.isSame(this.getValue(zone.dstFromTimeUtc)))
      ) {
        offset = parseFloat(this.getValue(zone.dstUtcOffset) || 0);
      } else {
        offset = parseFloat(this.getValue(zone.standardUtcOffsetMinutes) || 0);
      }
    }

    return offset;
  }
  // gets 2016-03-30T17:30:00+00:00 and returns js Obj with 0 offset
  getUtcDateObj(isoString) {
    if (!isoString) {
      return "";
    }
    const d = new Date(isoString);
    const mom = moment(d);
    return new Date(d.getTime() + -1 * mom.utcOffset() * 60000); // subtracting utc offset
  }
  isEditableLeg(leg, departureAirportDetail) {
    if (!leg || !leg.departureAirportTimeLocal || !departureAirportDetail) {
      return true;
    }
    let isEditable = true;
    // finding offset difference
    const time = moment.utc(this.getValue(leg.departureAirportTimeLocal));
    const timeZoneOffset = this.findOffset(time, departureAirportDetail);

    // for example time was 21:54+01:00
    // after below call, it will become 20:54
    const deptUtcObj = this.getUtcDateObj(
      this.getValue(leg.departureAirportTimeLocal)
    );

    // now adjust airport timezone in above time
    deptUtcObj.setTime(deptUtcObj.getTime() - timeZoneOffset * 60 * 1000);

    // get current utc time of the user
    const current = this.getUtcDateObj(new Date().toISOString());

    if (current.getTime() > deptUtcObj.getTime()) {
      isEditable = false;
    }

    return isEditable;
  }

  satisfy24HoursCheck(leg, departureAirport) {
    let satisfy24HoursCheck = false;

    // finding offset difference
    const time = moment.utc(this.getValue(leg.departureAirportTimeLocal));
    const timeZoneOffset = this.findOffset(time, departureAirport);

    // for example time was 21:54+01:00
    // after below call, it will become 20:54
    const deptUtcObj = this.getUtcDateObj(
      this.getValue(leg.departureAirportTimeLocal)
    );

    // now adjust airport timezone in above time
    deptUtcObj.setTime(deptUtcObj.getTime() - timeZoneOffset * 60 * 1000);
    const current = this.getUtcDateObj(new Date().toISOString());

    if (current.getTime() > deptUtcObj.getTime()) {
      // departure time is in past i.e. flight has already taken off
      satisfy24HoursCheck = true;
    } else {
      // departure time is in future
      deptUtcObj.setTime(
        deptUtcObj.getTime() - TIME_TO_SHOW_CREW * 60 * 60 * 1000
      ); // subtracting 24 hours

      if (current.getTime() > deptUtcObj.getTime()) {
        satisfy24HoursCheck = true;
      }
    }

    return satisfy24HoursCheck;
  }

  getCountryFromCode(code) {
    let countryObj = {};
    CountriesList.some((c) => {
      if (c.THREE_LETTER_CODE === code.toUpperCase()) {
        countryObj = c;
        return true;
      }
      return false;
    });
    return countryObj;
  }
  // get js date object and returns the formated string "02 Sep, 2016"
  getStandardDateFormat(dateObj) {
    // expect a moment date object
    return dateObj.format(dateFormat.DATE);
  }
  
  getLatestTimeOfAirport(airportObj) {
    let time = moment();
    const utcoffset = time.utcOffset();
    const offset = this.findOffset(time, airportObj);
    time = time.add(-1 * (utcoffset - offset), 'minutes');
    // As per VSHADOW-2279 - Removed in compliance with CMA/CMX
    // time = time.add(10, 'minutes'); // adding 10 mins more for takeoff
    return time;
  }

  // finds the correct offset for the given timeObj and airportObj
  findOffset(time, airport) {
    let offset;
    if (airport && airport.timezones && airport.timezones.length) {
      const arrayOffset = this.getzoneOffset(time, airport.timezones);
      const zone = garanteeArray(airport.timezones)[arrayOffset];
      if (zone.hasOwnProperty('dstFromTimeUtc') &&
        (time.isBetween(this.getValue(zone.dstFromTimeUtc),
          this.getValue(zone.dstToTimeUtc)) ||
          time.isSame(this.getValue(zone.dstFromTimeUtc)))) {
        offset = parseFloat(this.getValue(zone.dstUtcOffset) || 0);
      } else {
        offset = parseFloat(this.getValue(zone.standardUtcOffsetMinutes) || 0);
      }
    }
    return offset;
  }

  /*
   * find which timezone selected time lies
   * */
  getzoneOffset(time, timezones) {
    let offset = 0;
    timezones.forEach((data, index) => {
      if (data.hasOwnProperty('dstFromTimeUtc') &&
        (time.isBetween(this.getValue(data.effectiveFromTimeUtc),
          this.getValue(data.effectiveToTimeUtc)) ||
          time.isSame(this.getValue(data.effectiveFromTimeUtc)))) {
        offset = index;
      }
    });
    return offset;
  }

  addRealMonth = (d) => {
    d.setDate(1);
    return add(d, { years: 1, months: 1, days: -1 });
  }
}
export const clone = (obj) => {
  if (!obj || typeof obj !== 'object' || !!obj.size) {
    return obj;
  }
  return JSON.parse(JSON.stringify(obj));
};

const $ = window.$;
export const scrollFunctionForTable = () => {
  $('.vistajet_table_wrapper').scroll(function(element) {
      var target = element.target;
      var maxHorizontalScroll = target.scrollWidth - target.clientWidth;
      var scrollPosition = target.scrollLeft;
      if (scrollPosition === 0) {
          $(target).addClass('vistajet_scroll_right')
          .removeClass('vistajet_scroll_center')
          .removeClass('vistajet_scroll_left');
      } else if (scrollPosition === maxHorizontalScroll) {
          $(target).addClass('vistajet_scroll_left')
          .removeClass('vistajet_scroll_center')
          .removeClass('vistajet_scroll_right');
      } else {
          $(target).addClass('vistajet_scroll_center')
          .removeClass('vistajet_scroll_left')
          .removeClass('vistajet_scroll_right');
      }
  });
}

export default (Helpers = new Helpers()); // singleton
