import { guaranteeArray } from "../utils/arrayUtils";

export const normalizeOffer = offer => {
  if (offer.isOneWay && offer.isOneWay === true) {
    // one way
    return {
      aircraftId: +offer.aircraftId,
      aircraftTypeId: +offer.aircraftTypeId,
      availableIn: +offer.availableIn,
      departureAirportId: +offer.departureAirportId,
      departureAirportPlaceId: +offer.departureAirportPlaceId,
      id: +offer.id,
      isOneWay: offer.isOneWay === true,
      maxGroundTimeInHours: +offer.maxGroundTimeInHours,
      maxLegs: +offer.maxLegs,
      operatingCompanyId: +offer.operatingCompanyId,
      operatingCompanyName: offer.operatingCompanyName,
      segmentEnd: offer.segmentEnd, //moment.utc(offer.segmentEnd).format('DD MMM YYYY'),
      segmentStart: offer.segmentStart, //moment.utc(offer.segmentStart).format('DD MMM YYYY'),
      sortDate: offer.sortDate, //moment.utc(offer.sortDate).format('DD MMM YYYY'),
      tailNumber: offer.tailNumber,
      validFrom: offer.validFrom, //moment.utc(offer.validFrom).format('DD MMM YYYY'),
      validTo: offer.validTo //moment.utc(offer.validTo).format('DD MMM YYYY'),
    };
  }
  // empty leg
  return {
    aircraftId: +offer.aircraftId,
    aircraftTypeId: +offer.aircraftTypeId,
    amount: +offer.amount,
    arrivalAirportId: +offer.arrivalAirportId,
    brokerValidFrom: (offer.brokerValidFrom && offer.brokerValidFrom) || null,
    brokerValidTo: (offer.brokerValidTo && offer.brokerValidTo) || null,
    currency: offer.currency,
    departureAirportId: +offer.departureAirportId,
    departureAirportPlaceId: +offer.departureAirportPlaceId,
    emptyLegId: +offer.emptyLegId,
    flightTimeMinutes: +offer.flightTimeMinutes,
    fspValidFrom: offer.fspValidFrom,
    fspValidTo: offer.fspValidTo,
    id: +offer.id,
    isEmptyLeg: offer.isEmptyLeg === true,
    maxArrivalTime: offer.maxArrivalTime,
    maxDepartureTime: offer.maxDepartureTime,
    minArrivalTime: offer.minArrivalTime,
    minDepartureTime: offer.minDepartureTime,
    operatingCompanyId: +offer.operatingCompanyId,
    operatingCompanyName: offer.operatingCompanyName,
    sortDate: offer.sortDate,
    tailNumber: offer.tailNumber,
    validFrom: offer.validFrom,
    validTo: offer.validTo
  };
};

export function normalizeOffers(data = {},regionId = false) {
  const offersList = guaranteeArray(data.offers.offer) || [];
  const offers = offersList.map(offer => normalizeOffer(offer));
  return {
    offers,
    totalOffers: +data.totalCount || 0,
    regionId
  };
}
