import React, { Component } from 'react';
import CateringListActionItem from './CateringListActionItem';
import PassengerQuantityListItem from './PassengerQuantityListItem';
import {
  CATERING_SHARED_ORDER_KEY,
  CATERING_SHARED_ORDER_LABEL
} from '../../../constants/CateringConstants';
import { isEmpty } from '../../../utils/arrayUtils';
import { convertToTitleCase } from '../../../utils/stringUtils';
import ConfirmationPopup from '../../shared/ConfirmationPopUpNew';
import CateringFooter from './CateringFooter';
import dirtystateSingleTon from '../../../validators/validateDirty';
import SPAConstants from '../../../constants/SPAConstants'
import Helpers from '../../../utils/Helpers';
import { LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT, CONFIRMATION_TITLE } from '../../../constants/DirtyPopupConstant';


class PassengerQuantityList extends Component {

  constructor(props) {
    super(props);
    
    let passengerQuantities = Helpers.copy(props.menuItem.quantities);
    if (!passengerQuantities) {
      const sharedOrderObj = {};
      sharedOrderObj[CATERING_SHARED_ORDER_KEY] = {};
      passengerQuantities = sharedOrderObj;
    } else if (passengerQuantities && !passengerQuantities[CATERING_SHARED_ORDER_KEY]) {
      passengerQuantities[CATERING_SHARED_ORDER_KEY] = {};
    }
    this.state = {
      passengerQuantities,
      showingNotesCount: 0,
    };
  }

  onStartEditingNotes = () => this.setState({ showingNotesCount: this.state.showingNotesCount + 1 })
  onStopEditingNotes = () => this.setState({ showingNotesCount: this.state.showingNotesCount - 1 })
  onNotesSaved = (pax, notes) => {
    const { passengerQuantities } = this.state;
    const passenger = passengerQuantities[pax.passengerId];
    passenger.customerNotes = notes;
    this.setState({
      passengerQuantities,
    });
  }

  handleAdd = () => {
    this.props.handleScreenChange(null);
    this.props.onQunatitiesAdded(this.state.passengerQuantities);
  }
  handleQuantityChanged = (passenger, count) => {
    dirtystateSingleTon.setDirty(SPAConstants.ItineraryCatering, true);
    const { passengerQuantities } = this.state;
    const key = passenger.passengerId || CATERING_SHARED_ORDER_KEY;
    const name = key !== CATERING_SHARED_ORDER_KEY ?
      convertToTitleCase(passenger.formattedPaxObj.fullName) : CATERING_SHARED_ORDER_LABEL;
    passengerQuantities[key] = {
      count,
      passengerName: name,
      passengerId: passenger.passengerId,
    };
    this.setState({
      passengerQuantities,
    });
  }
  shouldEnableAddButton = () => {
    if (this.state.showingNotesCount) return false;
    const { passengerQuantities } = this.state;
    if (passengerQuantities[CATERING_SHARED_ORDER_KEY].count >= 0) return true;
    const selectedPassengerCount = Object.keys(passengerQuantities).length - 1;
    return !!selectedPassengerCount;
  }

  renderSharedOrder = () => {
    const { passengerQuantities } = this.state;
    const sharedQuantity = passengerQuantities[CATERING_SHARED_ORDER_KEY];
    if (!isEmpty(sharedQuantity)) {
      return (
        <PassengerQuantityListItem
          toShare={true}
          count={sharedQuantity.count}
          customerNotes={sharedQuantity.customerNotes}
          onQuantityChanged={this.handleQuantityChanged}
          onNotesSaved={this.onNotesSaved}
          onStartEditingNotes={this.onStartEditingNotes}
          onStopEditingNotes={this.onStopEditingNotes}
        />);
    }
    const passenger = { passengerId: 0 };
    return (<CateringListActionItem
      label={CATERING_SHARED_ORDER_LABEL}
      key={CATERING_SHARED_ORDER_KEY}
      data={passenger}
      actionLabel="Add"
      onActionClicked={() => this.handleQuantityChanged(passenger, 0)}
    />);
  }

  renderPassengers = () => {
    const passengersList = [];
    const { passengerQuantities } = this.state;
    this.props.passengers.forEach((passenger) => {
      if (passenger.isApproved) {
        const key = passenger.passengerId || CATERING_SHARED_ORDER_KEY;
        if (!passengerQuantities[key]) {
          passengersList.push((
            <CateringListActionItem
              label={convertToTitleCase(passenger.formattedPaxObj.fullName)}
              key={passenger.formattedPaxObj.passengerId}
              data={passenger}
              actionLabel="Add"
              onActionClicked={() => this.handleQuantityChanged(passenger, 0)}
            />)
          );
        } else {
          passengersList.push((
            <PassengerQuantityListItem
              passenger={passenger}
              count={passengerQuantities[key].count}
              customerNotes={passengerQuantities[key].customerNotes}
              onQuantityChanged={this.handleQuantityChanged}
              onNotesSaved={this.onNotesSaved}
              onStartEditingNotes={this.onStartEditingNotes}
              onStopEditingNotes={this.onStopEditingNotes}
            />)
          );
        }
      }
    });
    return passengersList;
  }
  handleConfirmCancel = () => {
    dirtystateSingleTon.setDirty(SPAConstants.ItineraryCatering, false);
    this.cateringItemCancel.hide();
    this.props.handleScreenChange(null);
  }

  onCrossClick = () => {
    const isCateringDirty = dirtystateSingleTon.getDirty(SPAConstants.ItineraryCatering);
    if(isCateringDirty) {
      this.cateringItemCancel.show();
    } else {
      this.props.handleScreenChange(null);
    }
  }


  render() {
    const { menuItem } = this.props;
    const isAddEnabled = this.shouldEnableAddButton();
    return (
      <div>
        <ConfirmationPopup
          ref={cateringItemCancel => {this.cateringItemCancel = cateringItemCancel}}
          content={"If you leave now, you will lose your current menu selection. Do you wish to leave anyway?"}
          title = {CONFIRMATION_TITLE}
          leftButtonContent={LEFT_BUTTON_TEXT}
          rightButtonContent={RIGHT_BUTTON_TEXT}
          onLeftButtonClick={() => this.cateringItemCancel.hide()}
          onRightButtonClick={() => this.handleConfirmCancel()}
        />
        <div className="vistajet_subheading_container">
          <p className="vistajet_modal_subheading_2 uk-text-center">Choose quantity for every passenger</p>
        </div>

        <div className="vistajet_catering_menu_content">

          <div className="uk-width vistajet_catering_menu_list vistajet_catering_menu_heading">
            <div className="uk-width-expand">
              <p className="uk-text-left uk-text-truncate vistajet_accordion_menu_item">{menuItem.title}</p>
              <p className="uk-text-left vistajet_accordion_menu_description">{menuItem.title}</p>
            </div>
            <button class="uk-button vistajet_accordion_menu_close" onClick={this.onCrossClick}>
              <img src="/legacy/static/img/icons/vistajet_close_white_icon.svg" alt="close icon" />
            </button>
          </div>
          {this.renderPassengers()}
          {this.renderSharedOrder()}
        </div>
        <CateringFooter
          onClick={this.handleAdd}
          disabled={!isAddEnabled}
        />
      </div>
      // <div>
      //   <div className="catering-content-container">
      //     <div className="catering-content-heading">
      //       {this.props.selectedSupplier}
      //     </div>
      //     <div className="catering-menu-prompt">Choose quantity for every passenger</div>
      //     <div className="passenger-quantiy-item-heading">
      //       {menuItem.title}
      //       <span
      //         className="right"
      //         style={{ cursor: 'pointer' }}
      //       >
      //         <span onClick={() => this.refs.cateringItemCancel.show()}>Cross</span>
      //       </span>
      //     </div>
      //     <div className="separator-bottom">
      //       {menuItem.description}
      //     </div>
      //     <ul className="catering-menu">
      //       {this.renderPassengers()}
      //       {this.renderSharedOrder()}
      //     </ul>
      //     <div className="catering-popup-footer">
      //       <span onClick={this.handleAdd}> Add </span>
      //     </div>
      //   </div>
      //    <ConfirmDialog
      //     ref="cateringItemCancel"
      //     content="If you leave now, you will lose your current menu selection.
      //       Do you wish to leave anyway?"
      //     title="Confirmation"
      //     cancelLabel="Stay on this page"
      //     confirmLabel="Move away"
      //     handleConfirm={this.handleConfirmCancel}
      //   />
      // </div>
    );
  }
}

export default PassengerQuantityList;
