import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStore from '../../components/withStore';
import ItineraryDetails from '../../components/ItineraryDetails/ItineraryDetails';
import { clearErrorFetchItinerary } from '../../store/actions/ItineraryListingActions'

function mapStateToProps(state) {
  const {
    legs,
    orderId,
    accountId,
    orderType,
    isLoading,
    orderStatus,
    orderExtIdentifier,
    itineraryDetailsError,
  } = state.itineraryDetails;
  const {
    movementMessages,
    isLoadingMovementMessages,
  } = state.movementMessages;
  const { visitedAirportsFinder, visitedAirports } = state.visitedAirports;
  const { user } = state.user;
  const { token } = state.token;
  const { isGoogleMapsApiLoaded } = state.googleMap;
  const { crew, isLoadingCrew, isErrorCrew } = state.crewDetails;
  const { passengers, isLoadingPassenger } = state.passengers;
  const { itineraryPdf, itineraryPdfLoading } = state.itineraryPdf;
  const {
    availedService,
    availedASData,
    isLoadingAvailedServices,
    isLoadingAdditionalServices,
    isErrorAvailedServices,
    isErrorAdditionalServices
  } = state.availedServices;
  const { aircraftsList } = state.aircrafts;
  const { groundServices, isLoadingGroundServices, isErrorGroundTransport } = state.groundServicesReducer;
  const { itineraryCancellationMessage, itineraryCancellationIsLoading } = state.cancelItinerary;
  const { cateringDetails } = state;
  const { fbo, isLoadingFbo } = state.fbo;
  const { isLoadingWeather, weather } = state.weather;
  const {feedback, isFeedbackLoading} = state.customerFeedback;


  return {
    legs,
    crew,
    user,
    token : token,
    orderId,
    orderType,
    accountId,
    isLoading,
    isErrorCrew,
    orderStatus,
    itineraryPdf,
    availedASData,
    isLoadingCrew,
    availedService,
    groundServices,
    orderExtIdentifier,
    visitedAirportsFinder,
    visitedAirports,
    isLoadingWeather,
    weather,
    itineraryPdfLoading,
    itineraryDetailsError,
    isGoogleMapsApiLoaded,
    isErrorGroundTransport,
    isErrorAvailedServices,
    isErrorAdditionalServices,
    isLoadingGroundServices,
    isLoadingAvailedServices,
    cateringDetails,
    passengers,
    isLoadingPassenger,
    isLoadingAdditionalServices,
    fbo,
    isLoadingFbo,
    movementMessages,
    isLoadingMovementMessages,
    itineraryCancellationMessage,
    itineraryCancellationIsLoading,
    feedback,
    isFeedbackLoading,
    aircraftsList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAirportById: id => dispatch(actions.getAirportById(id)),
    onGoogleMapsApiLoaded: () => dispatch(actions.onGoogleMapsApiLoaded()),
    fetchFbo: (token, legId) => dispatch(actions.fetchFboDetails(token, legId)),
    fetchWeather: (lat, long, time, key, flightLegId) => dispatch(actions.fetchWeatherDetails(lat, long, time, key, flightLegId)),
    fetchVisitedAirports: () => dispatch(actions.fetchAllVisitedAirports()),
    fetchCrew: (token, flightLegId) => dispatch(actions.fetchCrew(token, flightLegId)),
    fetchPassengers: (token, data) => dispatch(actions.fetchPassengers(token, data)),
    fetchGroundServices: (token, payload) => dispatch(actions.fetchAllGroundServices(token, payload)),
    fetchitineraryPdf: (token, flightLegId) => dispatch(actions.fetchitineraryPdf(token, flightLegId)),
    fetchCateringPdf: (token, flightLegId) => dispatch(actions.getCateringPDFAction(token, flightLegId)),
    fetchItineraryDetails: (token, key, params, type) => dispatch(actions.fetchItineraryDetails(token, key, params, type)),
    fetchAvailedServices: (token, key, flightLegId) => dispatch(actions.fetchAvailedServices(token, key, flightLegId)),
    flightCancel: (token, accountId, leg, user, orderId) => dispatch(actions.flightCancel(token, accountId, leg, user, orderId)),
    addGroundTransportService: (token, key, uniqueIdentifier, payload) => dispatch(actions.addGroundTransportService(token, key, uniqueIdentifier, payload)),
    getCateringState: (token, leg) => dispatch(actions.getCateringStateAction(token, leg)),
    getCateringProposal: (token, flightId) => dispatch(actions.getCateringProposalAction(token, flightId)),
    changeCateringProposal: (token, flightId, cateringSelection, leg) => dispatch(actions.changeCateringProposalAction(token, flightId, cateringSelection, leg)),
    fetchMovementMessages: (token, flightLegId) => dispatch(actions.fetchMovementMessages(token, flightLegId)),
    clearErrorFetchItinerary: () => dispatch(clearErrorFetchItinerary()),
    updateFlightCancel: () => dispatch(actions.flightCancelAfterSuccess()),
    fetchLegFeedback : (params) => dispatch(actions.fetchLegFeedback(params)),
  };
}

const ItineraryDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ItineraryDetails);

export default withStore(ItineraryDetailsContainer);
