import axios from 'axios';
import { REGISTER_URL, CONTEXT, APP_SOURCE } from 'configs/constants';
import {
  HTTP_HEADERS_JSON,
} from '../../constants/ApiConstants';

import {
  resendRegisterCodeInit,
  resendRegisterCodeSuccess,
  resendRegisterCodeFail,
} from '../actions';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';

const resendRegisterCode = object => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(resendRegisterCodeInit());

    const reqPayload = {
      username: object.username,
      externalAppUserId: object.externalAppUserId,
      source: APP_SOURCE,
    };


    const url = REGISTER_URL
      .replace('{context}', CONTEXT)
      .replace('{token}', object.token)
      .replace('{mode}', 'RESEND');

    axios
      .post(
        url,
        reqPayload,
        HTTP_HEADERS_JSON,
      )
      .then((res) => {
        const response = res.data;
        if (response.responseHeaderDto.isError) {
          const error = handleServerErrors(response.responseHeaderDto.statusCode);
          dispatch(resendRegisterCodeFail(error));
          reject(error);
        } else {
          dispatch(resendRegisterCodeSuccess({
            message: response.responseHeaderDto.message,
            success: !response.responseHeaderDto.isError,
          }));
          resolve({
            message: response.responseHeaderDto.message,
            success: !response.responseHeaderDto.isError,
          });
        }
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(resendRegisterCodeFail(errors));
        reject(errors);
      });
  });
};

export default resendRegisterCode;
