import { types as CardType } from "credit-card-type";
import moment from "moment";
import luhn from "../luhn";

import validatePhoneNumber from "./PhoneNumberValidator";
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  NAME_REGEX,
  PASSPORT_NAME_REGEX,
  PASSPORT_REGEX,
} from "../../constants/SignupConstants";
import { getCreditCardTypes, getPossibleBrandIds } from "../creditCardUtils";

import setResponse from "../errorUtils";
import { dateFormats } from '../dateUtils';
import { errorMessages } from "../../constants/ErrorMessageConstants";

export function validateSalutation(value) {
  // eslint-disable-line
  let res = setResponse();
  if (value === undefined || value === null) {
    res = setResponse(errorMessages.SALUTATION_REQ);
    return res;
  }
  return res;
}

export function validateTitleOther(title, titleOther) {
  if (title === "Other" && !titleOther) {
    return setResponse("Title is required");
  }
  return setResponse();
}

export function validateFirstName(value) {
  let res = setResponse();
  if (!value || (value && value.length < 2)) {
    res = setResponse(errorMessages.FIRST_NAME_REQ);
    return res;
  }
  if (!NAME_REGEX.test(value)) {
    res = setResponse(errorMessages.INVALID_NAME);
    return res;
  }
  return res;
}

export function validateLastName(value) {
  let res = setResponse();
  if (!value || (value && value.length < 2)) {
    res = setResponse(errorMessages.LAST_NAME_REQ);
    return res;
  }
  if (!NAME_REGEX.test(value)) {
    res = setResponse(errorMessages.INVALID_NAME);
    return res;
  }
  return res;
}

export function validateEmail(email) {
  let res = setResponse();
  if (!email) {
    res = setResponse(errorMessages.EMAIL_REQ);
    return res;
  }
  if (!EMAIL_REGEX.test(email)) {
    res = setResponse(errorMessages.INVALID_EMAIL);
    return res;
  }
  return res;
}

export function validateEmailStartingFromUpperCase(email) {
  let emailRegex = /^[aA-zZ0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  let res = setResponse();
  if (!email) {
    res = setResponse(errorMessages.EMAIL_REQ);
    return res;
  }
  if (!emailRegex.test(email)) {
    res = setResponse(errorMessages.INVALID_EMAIL);
    return res;
  }
  return res;
}

export function validateRepeatEmail(repeatEmail, email) {
  // eslint-disable-line
  let res = setResponse();
  if (!repeatEmail) {
    res = setResponse(errorMessages.REPEAT_EMAIL_REQ);
    return res;
  }
  if (repeatEmail !== email) {
    res = setResponse(errorMessages.EMAIL_DO_NOT_MATCH);
    return res;
  }
  return res;
}

export function validatePhone(phoneNumber, isPhoneNumberValid) {
  return validatePhoneNumber(phoneNumber, isPhoneNumberValid);
}

export function validatePhoneAndCountry(phoneNumber, isPhoneNumberValid, countryCode) {
  if (!countryCode)
    return setResponse(errorMessages.COUNTRY_REQUIRED);
  return validatePhoneNumber(phoneNumber, isPhoneNumberValid);
}

export function validateTypeOfFlying(typeOfFlying) {
  if (!typeOfFlying) {
    return setResponse("Type of flying is required");
  }
  return setResponse();
}

export function validateCurrentFlyingSolution(currentFlyingSolution) {
  if (!currentFlyingSolution) {
    return setResponse("Current flying solution is required");
  }
  return setResponse();
}


export function validateHowDidYouHear(howDidYouHear) {
  if (!howDidYouHear) {
    return setResponse("How you heard is required");
  }
  return setResponse();
}

export function validateHowOftenDoYouFly(howOftenDoYouFly) {
  if (!howOftenDoYouFly) {
    return setResponse("How often you fly is required");
  }
  return setResponse();
}

export function validatePassword(password) {
  let res = setResponse();
  if (!password) {
    res = setResponse(errorMessages.PASS_REQ);
    return res;
  }
  if (password.length > 30) {
    res = setResponse(
      // isMobile
      //   ? errorMessages.PASS_MAX_LENGT_MOBILE
      //   : errorMessages.PASS_MAX_LENGTH
      errorMessages.PASS_MAX_LENGTH,
    );
    return res;
  }
  if (password.length < 8 || !PASSWORD_REGEX.test(password)) {
    res = setResponse(
      // isMobile
      //   ? errorMessages.PASS_MAX_LENGT_MOBILE
      //   : errorMessages.INVALID_PASS
      errorMessages.INVALID_PASS,
    );
    return res;
  }
  return res;
}

export function validateRepeatPassword(repeatPassword, password) {
  let res = setResponse();
  if (!repeatPassword) {
    res = setResponse(errorMessages.REPEAT_PASS_REQ);
    return res;
  }
  if (repeatPassword !== password) {
    res = setResponse(errorMessages.PASS_DO_NOT_MATCH);
    return res;
  }
  return res;
}

export function validateMessage(value) {
  let res = setResponse();
  if (!value || (value && value.length < 2)) {
    res = setResponse(errorMessages.MESSAGE_REQ);
    return res;
  }

  return res;
}

export function validateNotEmpty(value) {
  let res = setResponse();
  if (!value) {
    res = setResponse(errorMessages.PASS_REQ);
    return res;
  }
  return res;
}

export function validateSamePassword(oldPassword, newPassword) {
  let res = setResponse();
  if (oldPassword === newPassword) {
    res = setResponse(errorMessages.SAME_PASSWORD);
    return res;
  }
  return res;
}

export function validateCheckBox(isChecked) {
  let res = setResponse();
  if (!isChecked) {
    res = setResponse(errorMessages.CHECK_REQUIRED);
    return res;
  }
  return res;
}

// Additional parameter cc added to get brandName. And validate 4 digit cvv for AMEX
//  (American Express and 3 or 4 digit cvv for others
export const validateCVV = (cvv, cc, brandId) => {
  let res = setResponse();
  if (!cvv) {
    res = setResponse(errorMessages.CVV_REQUIRED);
    return res;
  }

  if (cc) {
    const possibleBrandIds = getPossibleBrandIds(getCreditCardTypes(cc));

    if (
      (possibleBrandIds[0] !== CardType.AMERICAN_EXPRESS && cvv.length !== 3) ||
      (possibleBrandIds[0] === CardType.AMERICAN_EXPRESS && cvv.length !== 4)
    ) {
      res = setResponse(errorMessages.INVALID_CVV);
      return res;
    }
    return res;
  }
  if (
    (brandId != 1 && cvv.length !== 3) ||
    (brandId == 1 && cvv.length !== 4)
  ) {
    res = setResponse(errorMessages.INVALID_CVV);
    return res;
  }

  return res;
};

export const validateCVVWithBrandId = (cvv, brandId) => {
  let res = setResponse();
  if (!cvv) {
    res = setResponse(errorMessages.CVV_REQUIRED);
    return res;
  }

  if (
    (brandId !== CardType.AMERICAN_EXPRESS && cvv.length !== 3) ||
    (brandId === CardType.AMERICAN_EXPRESS && cvv.length !== 4)
  ) {
    res = setResponse(errorMessages.INVALID_CVV);
    return res;
  }

  return res;
};

const REGEX_MM_YY = /^([0][1-9]|[1][0-2])\/[0-9][0-9]$/;

const validateExpiryDateFormat = date => {
  if (!REGEX_MM_YY.test(date)) {
    return errorMessages.INVALID_EXPIRY_DATE_FORMAT;
  }

  const ccDate = moment(date, "MM-YY").endOf("month");

  if (ccDate.isBefore(moment())) {
    return errorMessages.INVALID_EXPIRY_DATE;
  }
  return undefined;
};

export const validateExpiryDate = expiryDate => {
  let res = setResponse();
  if (!expiryDate) {
    res = setResponse(errorMessages.EXPIRY_DATE_REQUIRED);
  } else {
    const error = validateExpiryDateFormat(expiryDate);
    if (error) {
      res = setResponse(error);
    }
  }
  return res;
};

export const validateCreditCardNumber = creditCardNumber => {
  let res = setResponse();
  if (!creditCardNumber) {
    res = setResponse(errorMessages.CREDIT_CARD_NUMBER_REQUIRED);
  } else if (!luhn(creditCardNumber)) {
    res = setResponse(errorMessages.INVALID_CREDIT_CARD_NUMBER);
  }
  return res;
};

export const addressValidator = address => {
  let res = setResponse();
  if (!address || address.constructor !== String) {
    res = setResponse(errorMessages.ADDRESS_REQUIRED);
    return res;
  }
  return res;
};

export const cityValidator = city => {
  let res = setResponse();
  if (!city || city.constructor !== String) {
    res = setResponse(errorMessages.CITY_REQUIRED);
    return res;
  }
  return res;
};

export const stateValidator = state => {
  let res = setResponse();
  if (!state || state.constructor !== String) {
    res = setResponse(errorMessages.STATE_REQUIRED);
    return res;
  }
  return res;
};

export const countryValidator = country => {
  let res = setResponse();
  if (!country || country.constructor !== String) {
    res = setResponse(errorMessages.COUNTRY_REQUIRED);
    return res;
  }
  return res;
};

// is given date string is after current date
export function dateInFuture(dateString, res) {
  const resolution = res || "day";
  const momentObj = moment(dateString).utc();
  return momentObj.isAfter(moment(), resolution);
}

export const dateInPast = (dateString, res) => {
  const resolution = res || "day";
  const momentObj = moment(dateString);
  return momentObj.isBefore(moment(), resolution);
}
export const zipCodeValidator = (zipCode) => {
  let res = setResponse();
  if (!zipCode || zipCode.constructor !== String) {
    res = setResponse(errorMessages.POSTAL_CODE_REQUIRED);
  }
  return res;
}

export const validateDateOfBirth = (dateOfBirth) => {
  let res = setResponse();
  if (!dateOfBirth) {
    res = setResponse(errorMessages.DATE_OF_BIRTH_REQUIRED);
  } else {
    const dateBirth = moment(dateOfBirth, dateFormats.DDMMMYYYY, true);
    if (!dateBirth.isValid()) {
      res = setResponse(errorMessages.INVALID_DOB_FORMAT);
    }
    if (dateBirth.isAfter(moment())) {
      res = setResponse('Invalid date. It must be before today');
    }
    // else if (!handleIsValidDate(dateBirth)) {
    //   dateOfBirthError = 'Invalid date. It must be before today';
    // }
  }
  return res;
};

export const passportNumberValidator = (passportNumber) => {
  let res = setResponse();
  const passportNumberTrimmed = passportNumber && `${passportNumber}`.trim();
  if (!passportNumberTrimmed) {
    res = setResponse(errorMessages.PASSPORT_NUMBER_REQUIRED);
  } else if (!PASSPORT_REGEX.test(passportNumberTrimmed)) {
    res = setResponse(errorMessages.INVALID_PASSPORT_NUMBER);
  }
  return res;
};

export const expiresOnDatevalidator = (expiresOnDate) => {
  let res = setResponse();
  if (!expiresOnDate) {
    res = setResponse(errorMessages.EXPIRY_DATE_REQUIRED);
  } else {
    if (!moment(expiresOnDate, dateFormats.DDMMMYYYY, true).isValid()) {
      res = setResponse(errorMessages.PLESASE_USE_DATE_FORMAT);
    }
    if (moment(expiresOnDate, dateFormats.DDMMMYYYY, true).startOf('day')
      .isBefore(moment()
        .startOf('day'))) {
      res = setResponse(errorMessages.INVALID_PASSPORT_EXPIRY_DATE_FUTURE);
    }
    if (moment(expiresOnDate, dateFormats.DDMMMYYYY, true).startOf('day')
      .isAfter(moment().add(10, 'years')
        .endOf('day'))) {
      res = setResponse(errorMessages.INVALID_PASSPORT_EXPIRY_DATE);
    }
  }
  return res;
};

export const displayNameValidator = (displayName) => {
  let res = setResponse();
  const displayNameTrimmed = displayName && `${displayName}`.trim();
  if (!displayNameTrimmed) {
    res = setResponse(errorMessages.PASSPORT_NAME_REQUIRED);
  } else if (!PASSPORT_NAME_REGEX.test(displayName)) {
    res = setResponse(errorMessages.INVALID_PASSPORT_NAME);
  }
  return res;
};

export const isoPassportIssueAndIssuingCountryValidator = (
  isoPassportCountry,
  issuingCountry = false,
) => {
  let res = setResponse();
  if (issuingCountry) {
    if (!isoPassportCountry) {
      res = setResponse(errorMessages.INVALID_ISSUING_COUNTRY);
      return res;
    }
    return res;
  }
  if (!isoPassportCountry) {
    res = setResponse(errorMessages.INVALID_NATIONALITY);
    return res;
  }
  return res;
};

export const validateStateCode = (stateCode, countryCode) => {
  if (countryCode !== 'US' || stateCode)
    return setResponse();
  return setResponse(errorMessages.STATE_REQUIRED)
}

// Format of stateCountries as below, although list could be omitted for this usage.
//
// const stateCountries = [
//   {
//       country: 'US',
//       list: stateList
//   }, {
//       country: 'IN',
//       list: indiaStateList
//   }];
export const validateStateCountries = (stateCountries, stateCode, countryCode) => {
  if (!stateCountries.some(o => o.country === countryCode) || stateCode)
    return setResponse();
  return setResponse(errorMessages.STATE_REQUIRED)
}