import { connect } from 'react-redux';

import withStore from '../components/withStore';
import ProgramBuilderWidget from '../components/ProgramBuilder/ProgramBuilderWidget';
import * as actions from '../store/actions';

function mapStateToProps(state) {
  const {
    isLoading, offers, error, totalOffers,
  } = state.offers;
  const { regionId } = state.geocode;
  const locationNotDisabled = !state.geocode.locationDisabled;
  const loadingGeolocation = state.geocode.isLoading;
  const { visitedAirports, isAirportsFetched } = state.visitedAirports;
  const { isLoggedIn } = state.user;
  const {
    isGoogleMapsApiLoaded,
    isGooglePlacesAutocompleteBinded,
  } = state.googleMap;
  const { aircraftsList } = state.aircrafts;
  return {
    isAirportsFetched,
    isLoading,
    offers,
    error,
    totalOffers,
    regionId,
    locationNotDisabled,
    loadingGeolocation,
    visitedAirports,
    isGoogleMapsApiLoaded,
    isGooglePlacesAutocompleteBinded,
    isLoggedIn,
    tokenAuthLoading: state.token.isTokenAuthLoading,
    adminTokenLoading: state.token.isadminTokenLoading,
    registerLoading: state.registration.isLoading,
    aircraftsList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGoogleMapsApiLoaded: () => dispatch(actions.onGoogleMapsApiLoaded()),
    onPlacesAutocompleteBinded: () => dispatch(actions.onPlacesAutocompleteBinded()),
    fetchVisitedAirports: () => dispatch(actions.fetchAllVisitedAirports()),
    fetchGeocode: () => dispatch(actions.fetchCountryGeocode()),
  };
}

const ProgramBuilderWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramBuilderWidget);

export default withStore(ProgramBuilderWidgetContainer);
