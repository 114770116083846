import React from 'react';

const SkeletonLoaderCards = () =>
    <div className="uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-1" data-uk-grid>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-clearfix">
            <div className="uk-card uk-card-body vistajet_skeleton_card">
                <div className="vistajet_location_guide uk-float-left">
                    <span></span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                    <span></span>
                </div>
                <div className="vistajet_available_date uk-text-center"></div>
            </div>
        </div>
        <div className="uk-width-1-1" >
            <div className="vistajet_skeleton_map"></div>
        </div>;                                                   
    </div> 

export default SkeletonLoaderCards;
