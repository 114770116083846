import React, { Component } from 'react';
import UserDetailCard from './UserDetailsCard';
import AddEditUserDetailForm from './AddEditUserDetailsForm';
import MemberShipDetailCard from './UserMembershipDetailsCard';

export default class UserDetailSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserDetailEdit: false,
    };
  }

  toggleIsUserEditDetails = () => {
    this.setState({
      isUserDetailEdit: !this.state.isUserDetailEdit,
    });
  }

  renderCards = () => {
    const { isUserDetailEdit } = this.state;
    const { user, getDirectAccountStatement, userType } = this.props;
    if (isUserDetailEdit) {
      return (
        <AddEditUserDetailForm
          toggleIsUserEditDetails={this.toggleIsUserEditDetails}
          {...this.props}
        />
      );
    }
    return (
      <div>
        <UserDetailCard
          toggleIsUserEditDetails={this.toggleIsUserEditDetails}
          user={user}
        />
        <br />
        {
          !userType.isGuestUser && (!userType.isFsp || userType.isFspDirect) && (
            <MemberShipDetailCard
              user={user}
              getDirectAccountStatement={getDirectAccountStatement}
              {...this.props}
            />
          )
        }
      </div>
    );
  }

  render() {
    return this.renderCards();
  }
}
