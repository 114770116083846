import React, { Component } from 'react';
import { TAB_BALANCE, TAB_STATEMENT, TAB_UNBILLED } from '../constants/PASConstants';
import UnbilledFlights from './UnbilledFlights';
import CurrentBalance from './CurrentBalance';
import SpinnerLoader from './shared/spinnerLoader'
import AccountStatement from './AccountStatement';
import MessagePopup from './shared/MessagePopUpNew';

export default class ProgramAccountStatement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentContract: 0,
      content: '',
      selectedContractualYear: 0,
      showDisclaimer: true,
    };
  }

  showError = (errorField = '') => {
    const errorPopup = this[errorField];
    if (errorPopup) errorPopup.show();
  }

  showUnbilledError = () => {
    this.unbilledErrorPopup.show();
  }

  showBilledError = () => {
    this.billedErrorPopup.show();
  }

  onSelectStatementTab = index => {
    const stepper = document.getElementById('contract-stepper');
    if (stepper) {
      const activeSlideTab = Math.ceil(index / 5) - 1;
      window.UIkit.slider(stepper).show(activeSlideTab);
      this.updateDisabled();
    }
  }

  updateDisabled = () => {
    const left = document.getElementById('stepper-button-left');
    const right = document.getElementById('stepper-button-right');
    if (!left || !right) return;
    if (left.classList.contains('uk-invisible'))
      left.classList.add('disabled');
    else left.classList.remove('disabled');
    if (right.classList.contains('uk-invisible'))
      right.classList.add('disabled');
    else right.classList.remove('disabled');
  }

  hasInvoices = flights => !!flights.reduce((acc, curr) => [
    ...acc, ...curr.invoice,
  ], []).length;

  selectContractualYear = selectedContractualYear =>
    this.setState({ selectedContractualYear }, this.updateDisabled);

  componentDidMount() {
    const { userToken = '' } = this.props;
    this.switchContent(TAB_BALANCE);
    this.props.fetchVisitedAirports();
    if (userToken) {
      this.props.fetchUnbilledFlights(userToken);
      this.props.fetchRecentInvoices(userToken);
    }
  }

  componentWillReceiveProps({
    billedFlights = [],
    unbilledFlights = [],
    visitedAirportsFinder = {},
    getAirportById,
    billedFlightsError = '',
    unbilledFlightsError = '',
    accountStatementsError = '',
    statementInvoicesError = '',
    zipUrlError = '',
  }) {
    if (!unbilledFlightsError && !billedFlightsError && (billedFlights || []).length && unbilledFlights)
      this.setState({ isError: false });
    if (unbilledFlightsError || billedFlightsError || (!(billedFlights || []).length) || !unbilledFlights)
      this.setState({ isError: true });
    if (unbilledFlightsError) return this.showError('unbilledErrorPopup');
    if (billedFlightsError) return this.showError('billedErrorPopup');

    if (accountStatementsError) this.showError('accountStatementsErrorPopup');
    if (statementInvoicesError) this.showError('statementInvoicesErrorPopup');
    if (zipUrlError) this.showError('zipUrlErrorPopup');

    if (Object.keys(visitedAirportsFinder).length && Object.keys(unbilledFlights || {}).length) {
      const missingAirports = [];
      Object.values(unbilledFlights).forEach(contract => {
        contract.forEach(flight => {
          const depId = (flight || {}).departureAirportId;
          const arrId = (flight || {}).arrivalAirportId;
          if (depId && !visitedAirportsFinder[depId])
            missingAirports.push(depId);
          if (arrId && !visitedAirportsFinder[arrId])
            missingAirports.push(arrId);
        });
      })
      if (missingAirports.length) {
        getAirportById(missingAirports);
      }
    }
  }

  getOtherAircrafts = () => {
    const { billedFlights = [] } = this.props;
    return billedFlights
      .filter(({ aircraftName = '' }) => !aircraftName)
      .map(({ accountId, aircraftTypeId }) => ({ accountId, aircraftTypeId }));
  }

  getOtherContracts = () => {
    const { billedFlights = [] } = this.props;
    return billedFlights.filter(({ aircraftName }) => !aircraftName);
  }

  getOtherFlights = contracts => contracts && contracts.filter(contract => !contract.aircraftName);

  getBilledFlights = contracts => contracts && contracts.filter(contract => contract.aircraftName);

  getUnbilledFlights = index => {
    const { billedFlights, unbilledFlights } = this.props;
    if (index === this.getBilledFlights(billedFlights).length) return [];
    return Object.keys(unbilledFlights).length ?
      unbilledFlights[
      this.getBilledFlights(billedFlights)[index].aircraftTypeId
      ] : [];
  }

  getActiveContract = index => {
    const { billedFlights } = this.props;
    const contractFlights = this.getBilledFlights(billedFlights);
    return contractFlights.length ? contractFlights[index] : {};
  }

  switchContent = content => {
    const { selectedContractualYear = 0 } = this.state;
    this.setState({ content }, () => {
      setTimeout(() => {
        if (content === TAB_STATEMENT && selectedContractualYear)
          this.onSelectStatementTab(selectedContractualYear);
      }, 400);
    });
  }

  switchContract = index => {
    const { billedFlights = [] } = this.props;
    const { currentContract } = this.state;
    const contractedFlights = this.getBilledFlights(billedFlights);
    if (index === contractedFlights.length) {
      if (currentContract === contractedFlights.length) return;
      this.setState({ content: TAB_BALANCE });
      window.UIkit.switcher('#component-nav').show(0);
    }
    this.setState({ currentContract: index }, () => {
      if (currentContract === contractedFlights.length) {
        document.getElementsByClassName('vistajet_side_tab_list')[0]
          .children[0]
          .classList.add('uk-active');
      }
    })
  };

  renderTabs = () => {
    const { billedFlights } = this.props;
    const { currentContract = 0 } = this.state;
    const otherFlights = this.getOtherFlights(billedFlights);
    const contractedFlights = this.getBilledFlights(billedFlights);
    return (
      <div>
        {(contractedFlights || [])
          .map((contract, index) => (
            <a href="#" className={currentContract === index ? 'active' : ''} onClick={() => this.switchContract(index)}>
              {(contract && contract.aircraftName) || ''}
            </a>
          ))}
        {(otherFlights || []).length && this.hasInvoices(otherFlights) ? (
          <a
            href="#"
            className={currentContract === contractedFlights.length ? 'active' : ''}
            onClick={() => this.switchContract(contractedFlights.length)}
          >
            Other Flights
        </a>
        ) : null}
      </div>
    );
  }

  renderSideMenu = () => (
    <ul className="uk-nav uk-nav-default vistajet_side_tab_list" uk-switcher="connect: #component-nav; animation: uk-animation-fade; toggle: > :not(.uk-nav-header); swiping: false;">
      <li onClick={() => this.switchContent(TAB_BALANCE)}>
        <a href="#">{TAB_BALANCE}</a>
      </li>
      <li onClick={() => this.switchContent(TAB_STATEMENT)}>
        <a href="#">{TAB_STATEMENT}</a>
      </li>
      <li className="vistajet_unbilled_flight" onClick={() => this.switchContent(TAB_UNBILLED)}>
        <a href="#">{TAB_UNBILLED}</a>
      </li>
    </ul>
  );

  render() {
    const {
      currentContract = 0,
      isError = false,
      selectedContractualYear = 0,
      showDisclaimer = false,
    } = this.state;
    const {
      userToken = '',
      unbilledFlights = [],
      unbilledFlightsError = '',
      billedFlights = [],
      billedFlightsError = '',
      loadingUnbilledFlights = false,
      loadingBilledFlights = false,
      loadingMoreInvoices = [],
      loadingAccountStatements = false,
      loadingStatementInvoices = false,
      loadingMoreStatementInvoices = false,
      loadingZipUrl = false,
      statementInvoicesLastPage = false,
      visitedAirportsFinder,
      fetchMoreInvoices,
      fetchAccountStatements,
      fetchStatementInvoices,
      accountStatementInfo,
      accountStatements,
      accountStatementsError = '',
      statementInvoices,
      statementInvoicesError = '',
      fetchZipUrl,
      getAirportById,
      clearInvoiceFilter,
      clearError,
      isAirportByIdLoading = false,
      isAirportsLoading = false,
      zipUrlError = '',
    } = this.props;
    const otherFlightSelected = currentContract === (this.getBilledFlights(billedFlights) || []).length;
    return (
      <div className="uk-container vistajet_screen_wrapper">
        <div className="uk-margin-medium-top uk-width-1-1@s uk-width-3-4@m uk-margin-auto vistajet_account_statement">
          <h3 className="vistajet_main_outer_heading">Program Account Balance</h3>
          {(loadingUnbilledFlights || loadingBilledFlights) ?
            <div className="uk-card uk-card-default uk-card-body vistajet_card uk-height-medium uk-flex uk-flex-middle uk-flex-center">
              <SpinnerLoader />
            </div>
            : (
              isError ? (
                <div className="uk-flex uk-grid-collapse uk-card uk-card-default uk-card-body vistajet_card uk-padding-remove"
                  data-uk-grid uk-height-match="target: > div > ul; row: false">
                  <div class="vistajet_statement_content">
                    <p class="uk-margin-left vistajet_dark_text">No Invoices Available. </p>
                  </div>
                </div>
              ) : (
                  <div class="uk-flex uk-grid-collapse uk-card uk-card-default uk-card-body vistajet_card uk-padding-remove"
                    data-uk-grid uk-height-match="target: > div > ul; row: false">
                    <div class="uk-width vistajet_aircraft_tab">
                      {this.renderTabs()}
                    </div>
                    {!otherFlightSelected && <div class="uk-width-1-4@s">
                      {this.renderSideMenu()}
                    </div>}

                    <div class="uk-width-expand@s">
                      <ul
                        id="component-nav"
                        className={`uk-switcher vistajet_statement_content ${otherFlightSelected ? 'vistajet_other_flight' : ''}`}
                      >
                        <li>
                          <CurrentBalance
                            contract={this.getActiveContract(currentContract)}
                            token={userToken}
                            visitedAirportsFinder={visitedAirportsFinder}
                            fetchMoreInvoices={fetchMoreInvoices}
                            loadingMoreInvoices={loadingMoreInvoices}
                            lastStatement={(accountStatements || [])[0]}
                            loadingAccountStatements={loadingAccountStatements}
                            isOtherFlight={otherFlightSelected}
                            otherAircrafts={this.getOtherAircrafts()}
                            otherContracts={this.getOtherContracts()}
                            otherFlightSelected={otherFlightSelected}
                          />
                        </li>
                        {!otherFlightSelected &&
                          <li>
                            <AccountStatement
                              contract={this.getActiveContract(currentContract)}
                              token={userToken}
                              visitedAirportsFinder={visitedAirportsFinder}
                              fetchAccountStatements={fetchAccountStatements}
                              accountStatementInfo={accountStatementInfo}
                              accountStatements={accountStatements}
                              accountStatementsError={accountStatementsError}
                              loadingAccountStatements={loadingAccountStatements}
                              fetchStatementInvoices={fetchStatementInvoices}
                              loadingStatementInvoices={loadingStatementInvoices}
                              loadingMoreStatementInvoices={loadingMoreStatementInvoices}
                              statementInvoices={statementInvoices}
                              statementInvoicesError={statementInvoicesError}
                              statementInvoicesLastPage={statementInvoicesLastPage}
                              fetchZipUrl={fetchZipUrl}
                              loadingZipUrl={loadingZipUrl}
                              clearInvoiceFilter={clearInvoiceFilter}
                              selectContractualYear={this.selectContractualYear}
                              selectedContractualYear={selectedContractualYear}
                            />
                          </li>}
                        <li>
                          <UnbilledFlights
                            unbilledFlights={this.getUnbilledFlights(currentContract)}
                            visitedAirportsFinder={visitedAirportsFinder}
                            getAirportById={getAirportById}
                            isAirportByIdLoading={isAirportByIdLoading}
                            isAirportsLoading={isAirportsLoading}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                )
            )}
        </div>
        <MessagePopup
          id="unbilledErrorPopup"
          ref={e => this.unbilledErrorPopup = e}
          content={unbilledFlightsError}
          onExited={() => clearError({ errorField: 'unbilledFlightsError' })}
        />
        <MessagePopup
          id="billedErrorPopup"
          ref={e => this.billedErrorPopup = e}
          content={billedFlightsError}
          onExited={() => clearError({ errorField: 'billedFlightsError' })}
        />
        <MessagePopup
          id="accountStatementsErrorPopup"
          ref={e => this.accountStatementsErrorPopup = e}
          content={accountStatementsError}
          onExited={() => clearError({ errorField: 'accountStatementsError' })}
        />
        <MessagePopup
          id="statementInvoicesErrorPopup"
          ref={e => this.statementInvoicesErrorPopup = e}
          content={statementInvoicesError}
          onExited={() => clearError({ errorField: 'statementInvoicesError' })}
        />
        <MessagePopup
          id="zipUrlErrorPopup"
          ref={e => this.zipUrlErrorPopup = e}
          content={zipUrlError}
          onExited={() => clearError({ errorField: 'zipUrlError' })}
        />
        {showDisclaimer && <div uk-alert class="disclaimer_bar">
          <a class="uk-alert-close uk-close uk-icon" onClick={() => this.setState({ showDisclaimer: false })}>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line>
              <line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line>
            </svg>
          </a>
          <p class="uk-text-center">This statement is provisional and subject to change. A formal statement will be made available to you periodically by VistaJet Finance department.</p>
        </div>}
      </div>
    );
  }
};
