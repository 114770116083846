export const TIMEOUT = 120000; // two minutes

export const CACHE_TIMEOUT = 300000; // five minutes

export const APP_VERSION = '0.1.0';

export const HTTP_HEADERS = {
  deviceInfo: 'WEB',
  os: navigator.platform,
  appBuildNr: APP_VERSION,
  // deviceUUID: Helpers.guid(),
  Accept: 'application/xml',
  'Content-Type': 'application/xml',
  // requestedTime: Helpers.getCurrentTimestamp(),
};

export const HTTP_HEADERS_JSON = Object.assign({}, HTTP_HEADERS, {
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export const HTTP_HEADERS_JSON_PAS = Object.assign({}, HTTP_HEADERS, {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
});
export const HTTP_HEADERS_XML = {
  Accept: 'application/xml',
  'Content-Type': 'application/xml',
};
export const PaxOperationCode = {
  Default: 0,
  Add: 1,
  Edit: 2,
  Delete: 3,
  ChangeDefault: 4,
};
