import React from 'react';
import { Provider } from 'react-redux';

import store from 'store/store';

const withStore = (WrappedComponent) => {
  const HOC = props => (
    <Provider store={store}>
      <WrappedComponent {...props} />
    </Provider>
  );

  return HOC;
};

export default withStore;
