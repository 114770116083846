import axios from 'axios';
import {
  fetchUnbilledFlightsInit,
  fetchUnbilledFlightsSuccess,
  fetchUnbilledFlightsFail,
  fetchRecentInvoicesInit,
  fetchRecentInvoicesSuccess,
  fetchRecentInvoicesFail,
  fetchMoreInvoicesInit,
  fetchMoreInvoicesSuccess,
  fetchMoreInvoicesFail,
  fetchAccountStatementsInit,
  fetchAccountStatementsSuccess,
  fetchAccountStatementsFail,
  fetchStatementInvoicesInit,
  fetchStatementInvoicesSuccess,
  fetchStatementInvoicesFail,
  fetchZipUrlInit,
  fetchZipUrlSuccess,
  fetchZipUrlFail,
  fetchAccountStatementPdfInit,
  fetchAccountStatementPdfFail,
  fetchAccountStatementPdfSuccess,
} from '../actions';
import {
  FETCH_UNBILLED_ACCOUNT_BALANCE,
  FETCH_PROGRAM_ACCOUNT_BALANCE,
  FETCH_ACCOUNT_STATEMENTS,
  FETCH_STATEMENT_INVOICES,
  DOWNLOAD_ZIP_URL,
  GET_PDF_FILE_URL,
} from '../../configs/constants';
import { HTTP_HEADERS_JSON, TIMEOUT, HTTP_HEADERS, HTTP_HEADERS_JSON_PAS } from '../../constants/ApiConstants';
import { handleServerErrors } from '../../utils/sourceUtils';
import { parseXmlStringToJson } from '../../utils/parserUtils';
import {
  normalizeUnbilled,
  pasNormalizer,
  normalizeAccountStatements,
  normalizeStatementInvoices,
} from '../../normalizers/PASNormalizer';
import { errorMessages } from '../../constants/ErrorMessageConstants';

const fetchUnbilledPromiseInProgress = {};
const fetchBilledPromiseInProgress = {};
const fetchAccountStatementsInProgress = {};
const fetchStatementInvoicesInProgress = {};
const zipDownloadInProgress = {};
const fetchPdfInProgress = {};

export const fetchUnbilledFlights = token => dispatch => {
  const uniqueKey = 'fetchUnbilled';
  if (fetchUnbilledPromiseInProgress[uniqueKey]) {
    return fetchUnbilledPromiseInProgress[uniqueKey];
  }

  dispatch(fetchUnbilledFlightsInit());
  const url = FETCH_UNBILLED_ACCOUNT_BALANCE.replace('{token}', token);
  const fetchUnbilledPromise = axios
    .get(url, {
      headers: HTTP_HEADERS_JSON,
      timeout: TIMEOUT,
    })
    .then(res => {
      delete fetchUnbilledPromiseInProgress[uniqueKey];
      if (res && res.data.responseHeader && res.data.responseHeader.isError) {
        const error = handleServerErrors(res.data.responseHeader.statusCode);
        dispatch(fetchUnbilledFlightsFail({ error }));
      } else {
        const response = res.data.fleetListResponseDto;
        const unbilledFlights = normalizeUnbilled((response && response.fleetDto) || []);
        dispatch(fetchUnbilledFlightsSuccess({ unbilledFlights }))
      }
    })
    .catch(err => {
      delete fetchUnbilledPromiseInProgress[uniqueKey];
      const error = handleServerErrors(
        err.response
          ? err.response.status
          : err.response,
      );
      dispatch(fetchUnbilledFlightsFail({ error }));
    });

  fetchUnbilledFlights[uniqueKey] = fetchUnbilledPromise;
  return fetchUnbilledPromise;
};

export const fetchBilledFlights = (token, currentCount = 0, aircraftTypeId = '', accountId = '') => dispatch => {
  const isLoadMore = accountId && aircraftTypeId;
  const uniqueKey = accountId ?
    `moreInvoices-${aircraftTypeId}` :
    `recentInvoices-${aircraftTypeId}`;

  if (fetchBilledPromiseInProgress[uniqueKey]) {
    return fetchBilledPromiseInProgress[uniqueKey];
  }

  if (isLoadMore)
    dispatch(fetchMoreInvoicesInit({ accountId, aircraftTypeId }));
  else
    dispatch(fetchRecentInvoicesInit());

  const url = FETCH_PROGRAM_ACCOUNT_BALANCE
    .replace('{token}', token)
    .replace('{currentCount}', currentCount)
    .replace('{aircraftTypeId}', aircraftTypeId);

  const fetchRecentInvoicesPromise = axios
    .get(url, {
      headers: HTTP_HEADERS,
      timeout: TIMEOUT,
    })
    .then(res => {
      delete fetchBilledPromiseInProgress[uniqueKey];
      const jsonData = parseXmlStringToJson(res.data);
      const billedFlights = pasNormalizer(jsonData);

      if (isLoadMore)
        dispatch(fetchMoreInvoicesSuccess({ billedFlights, accountId, aircraftTypeId }))
      else
        dispatch(fetchRecentInvoicesSuccess({ billedFlights }));
    })
    .catch(err => {
      delete fetchBilledPromiseInProgress[uniqueKey];
      const error = handleServerErrors(
        err.response
          ? err.response.status
          : err.response,
      );
      if (isLoadMore)
        dispatch(fetchMoreInvoicesFail({ error, accountId, aircraftTypeId }));
      else
        dispatch(fetchRecentInvoicesFail({ error }));
    });
  fetchBilledPromiseInProgress[uniqueKey] = fetchRecentInvoicesPromise;
  return fetchRecentInvoicesPromise;
}

export const fetchAccountStatements = (token, accountId, aircraftTypeId) => dispatch => {
  const uniqueKey = `accountStatements${accountId + aircraftTypeId}`;

  if (fetchAccountStatementsInProgress[uniqueKey]) {
    return fetchAccountStatementsInProgress[uniqueKey];
  }

  dispatch(fetchAccountStatementsInit());

  const url = FETCH_ACCOUNT_STATEMENTS
    .replace('{token}', token)
    .replace('{accountId}', accountId)
    .replace('{aircraftTypeId}', aircraftTypeId);

  const fetchAccountStatementsPromise = axios
    .get(url, {
      headers: HTTP_HEADERS_JSON,
      timeout: TIMEOUT,
    })
    .then(res => {
      delete fetchAccountStatementsInProgress[uniqueKey];
      const {
        accountStatementInfoDtoList,
        accountStatementDtoList,
        responseHeaderDto: {
          isError = false,
          statusCode,
        }
      } = res.data;
      if (isError) {
        const error = handleServerErrors(statusCode);
        dispatch(fetchAccountStatementsFail({ error }));
      } else {
        const accountStatementsData = normalizeAccountStatements({
          accountStatementInfoDtoList,
          accountStatementDtoList,
        });
        dispatch(fetchAccountStatementsSuccess(accountStatementsData));
      }
    })
    .catch(err => {
      delete fetchAccountStatementsInProgress[uniqueKey];
      const error = handleServerErrors(
        err.response
          ? err.response.status
          : err.response,
      );
      dispatch(fetchAccountStatementsFail({ error }));
    })

  fetchAccountStatementsInProgress[uniqueKey] = fetchAccountStatementsPromise;
  return fetchAccountStatementsPromise;
}

export const fetchStatementInvoices = (token, accountStatementId, pageNumber = 1, pageSize = 5) => dispatch => {
  const uniqueKey = 'accountStatementInvoices';

  if (fetchStatementInvoicesInProgress[uniqueKey]) {
    return fetchStatementInvoicesInProgress[uniqueKey];
  }

  dispatch(fetchStatementInvoicesInit(pageNumber > 1));

  const url = FETCH_STATEMENT_INVOICES
    .replace('{token}', token)
    .replace('{accountStatementId}', accountStatementId)
    .replace('{pageNumber}', pageNumber)
    .replace('{pageSize}', pageSize);

  const fetchStatementInvoicesPromise = axios
    .get(url, {
      headers: HTTP_HEADERS_JSON,
      timeout: TIMEOUT,
    })
    .then(res => {
      delete fetchStatementInvoicesInProgress[uniqueKey];
      const {
        accountStatementDetails = [],
        responseHeaderDto: {
          lastPage = false,
          isError = false,
          statusCode = '',
        },
      } = res.data;
      if (isError) {
        const error = handleServerErrors(statusCode)
        dispatch(fetchStatementInvoicesFail({ error }));
      } else {
        const invoices = normalizeStatementInvoices(accountStatementDetails);
        const statementInvoicesData = {
          invoices, lastPage,
        }
        dispatch(fetchStatementInvoicesSuccess({ statementInvoicesData, loadMore: pageNumber > 1 }));
      }
    })
    .catch(err => {
      delete fetchStatementInvoicesInProgress[uniqueKey];
      const error = handleServerErrors(
        err.response
          ? err.response.status
          : err.response,
      );
      dispatch(fetchStatementInvoicesFail({ error }));
    })

  fetchStatementInvoicesInProgress[uniqueKey] = fetchStatementInvoicesPromise;
  return fetchStatementInvoicesPromise;
}

export const fetchZipUrl = (key, token) => dispatch => {
  const uniqueKey = `downloadZip-${key}`;
  if (zipDownloadInProgress[uniqueKey]) {
    return zipDownloadInProgress[uniqueKey];
  }

  dispatch(fetchZipUrlInit());
  const url = DOWNLOAD_ZIP_URL
    .replace('{token}', token)
    .replace('{key}', Object.entries(key).reduce((acc, curr, i, data) =>
      `${acc}${curr[0]}=${curr[1]}${(i === data.length - 1 ? '' : '&')}`, ''));

  const fetchZipUrlPromise = axios
    .get(url, {
      headers: HTTP_HEADERS_JSON,
      timeout: TIMEOUT,
    })
    .then(res => {
      delete zipDownloadInProgress[uniqueKey];
      if (res.data && res.data.responseHeader && res.data.responseHeader.isError) {
        // const error = '';
        const error = res.data.responseHeader.statusCode === 1 ?
          errorMessages.NO_RELATED_INVOICES : handleServerErrors(res.data.responseHeader.statusCode);
        dispatch(fetchUnbilledFlightsFail({ error }));
      } else {
        const { fileKey = '' } = res.data;
        window.open(fileKey);
        dispatch(fetchZipUrlSuccess());
      }
    })
    .catch(err => {
      delete zipDownloadInProgress[uniqueKey];
      const error = handleServerErrors(
        err.response
          ? err.response.status
          : err.response,
      );
      dispatch(fetchZipUrlFail({ error }));
    });

  zipDownloadInProgress[uniqueKey] = fetchZipUrlPromise;
  return fetchZipUrlPromise;
};

export const fetchAccountStatementPdf = (id, token) => dispatch => {
  const uniqueKey = `fetchPdf-${id}`;
  if (fetchPdfInProgress[uniqueKey]) {
    return fetchPdfInProgress[uniqueKey];
  }
  const fetchPdfPromise = new Promise((resolve, reject) => {
    dispatch(fetchAccountStatementPdfInit());
    const url = GET_PDF_FILE_URL
      .replace('{token}', token)
      .replace('{id}', id)

    axios
      .get(url, {
        headers: HTTP_HEADERS_JSON_PAS,
        timeout: TIMEOUT,
      })
      .then((res) => {
        delete fetchPdfInProgress[uniqueKey];
        if (res && res.header
          && res.header['content-type'] === 'application/xml'
          && res.body.error) {
          reject(handleServerErrors(res.body.error.code));
          dispatch(fetchAccountStatementPdfFail(handleServerErrors(res.body.error.code)));
        } else {
          window.open(url, "_self");
          resolve(url);
          dispatch(fetchAccountStatementPdfSuccess());
        }
      })
      .catch(error => {
        delete fetchPdfInProgress[uniqueKey];
        const jsonData = parseXmlStringToJson(error && error.response.data || '') || {};
        const statusCode = +(jsonData.error && jsonData.error.code) || ''
        reject(handleServerErrors(statusCode));
        dispatch(fetchAccountStatementPdfFail(handleServerErrors(statusCode)));
      });
  });
  fetchPdfInProgress[uniqueKey] = fetchPdfPromise;
  return fetchPdfPromise;
};

