import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import GroundServicesModal from './GroundServicesModal';
import GroundTransportationModal from './GroundTransportationModal';
import { GROUND_TRANSPORT_SERVICE, ADDITIONAL_SERVICE } from '../../constants/ItineraryConstants';


class GroundServicesCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
      airportIndex: null,
      serviceData: {
        arrivalAirport: { detail: '' },
        departureAirport: { detail: '' },
      }
    };
  }

  getMissingAirports() {
    const { airportIds, visitedAirportsFinder, getAirportById } = this.props;
    const undefinedIds = [];
    const missingAirportIds = [];

    if (airportIds && airportIds.length) {
      for (let i = 0; i < airportIds.length; i++) {
        missingAirportIds.push(airportIds[i]);
      }
    }
    missingAirportIds.forEach((id) => {
      if (visitedAirportsFinder[id] === undefined) {
        undefinedIds.push(id);
      }
      if (undefinedIds && undefinedIds.length) {
        getAirportById([...new Set(undefinedIds)]);
      }
    });
  }

  getAirportValue = (index, AirportDetail) => (
    AirportDetail && AirportDetail[index] && AirportDetail[index].name + ' ' + AirportDetail[index].iso2
  )

  openGTModal = () => {
    this.GTModal.show();
  }

  openASModal = () => {
    this.ASModal.show();
  }

  renderServiceStatus = (item, componentType) => {
    let status = '';
    if (componentType === GROUND_TRANSPORT_SERVICE) {
      status = item.transportServiceStatus
        ? (item.transportServiceStatus === 'Confirmed'
          ? 'Confirmed' : 'On Request')
        : 'On Request';
    }
    else {
      status = item.status
        ? (item.status === 'Confirmed'
          ? 'Confirmed' : 'On Request')
        : 'On Request';
    }
    return status;
  }

  renderService = (item, index, airportLength, componentType) => {
    return (
      <div className="vistajet_itinerary_card_content">
        <div className="uk-flex vistajet_subheading_wapper">
          <h6 className="uk-width-expand vistajet_subheading">
            {item.transportServiceTitle || item.title}
          </h6>
          <h6 className="uk-width-auto vistajet_subheading_link">
            {this.renderServiceStatus(item, componentType)}
          </h6>
        </div>
        {item.notes && 
          <p className='uk-text-break vistajet_description_text'>
            {item.notes}
          </p>
        }
        {
          (index !== airportLength - 1) && <hr className="gray_hr" />
        }
      </div>
    )
  }
  renderNoService = () => (
    <div className="vistajet_itinerary_card_content"><p className="vistajet_description_text">No services requested</p></div>
  )
  renderServicecomponent = (AirportDetail, departureAirportAvailed, departureAirportAvailedLength, arrivalAirportAvailed, arrivalAirportAvailedLength, componentType) => (
    <div className="uk-grid uk-grid-large" data-uk-grid>
      <div className="uk-width-1-1 uk-width-1-2@s">
        <div>
          <h5 className="vistajet_departure_heading">{this.getAirportValue(0, AirportDetail)}</h5>
        </div>
        {departureAirportAvailed.length > 0
          ? departureAirportAvailed.map((e, index) => this.renderService(e, index, departureAirportAvailedLength, componentType))
          : this.renderNoService()}
      </div>
      <div className="uk-width-1-1 uk-width-1-2@s">
        <div>
          <h5 className="vistajet-arrival-heading">{this.getAirportValue(1, AirportDetail)}</h5>
        </div>
        {arrivalAirportAvailed.length > 0
          ? arrivalAirportAvailed.map((e, index) => this.renderService(e, index, arrivalAirportAvailedLength, componentType))
          : this.renderNoService()}
      </div>
    </div>
  )

  renderServicesListing = () => {
    const {
      airportIds,
      emptyMessage,
      componentType,
      availedService,
      visitedAirportsFinder,
    } = this.props;
    const AirportDetail = airportIds && airportIds.length && airportIds.map(e => visitedAirportsFinder[e]);
    let arrivalAirportAvailed = '', arrivalAirportAvailedLength = '', departureAirportAvailed = '', departureAirportAvailedLength = '';
    if (componentType === GROUND_TRANSPORT_SERVICE) {
      arrivalAirportAvailed = availedService
        && availedService.availedService
        && availedService.availedService.filter(e => e.onDeparture === false);
      arrivalAirportAvailedLength = arrivalAirportAvailed && arrivalAirportAvailed.length;
      departureAirportAvailed = availedService
        && availedService.availedService
        && availedService.availedService.filter(e => e.onDeparture === true);
      departureAirportAvailedLength = departureAirportAvailed && departureAirportAvailed.length;
    }
    else {
      arrivalAirportAvailed = availedService
        && availedService.availedASData
        && availedService.availedASData.filter(e => e.onDeparture === false);
      arrivalAirportAvailedLength = arrivalAirportAvailed && arrivalAirportAvailed.length;
      departureAirportAvailed = availedService
        && availedService.availedASData
        && availedService.availedASData.filter(e => e.onDeparture === true);
      departureAirportAvailedLength = departureAirportAvailed && departureAirportAvailed.length;
    }

    if (componentType === GROUND_TRANSPORT_SERVICE && availedService && availedService.availedService && availedService.availedService.length) {
      return this.renderServicecomponent(AirportDetail, departureAirportAvailed, departureAirportAvailedLength, arrivalAirportAvailed, arrivalAirportAvailedLength, componentType);
    }
    else if (componentType === ADDITIONAL_SERVICE && availedService && availedService.availedASData && availedService.availedASData.length) {
      return this.renderServicecomponent(AirportDetail, departureAirportAvailed, departureAirportAvailedLength, arrivalAirportAvailed, arrivalAirportAvailedLength, componentType);
    }
    else {
      return (<p>{emptyMessage}</p>);
    }
  }


  render() {
    const {
      token,
      legKey,
      isLoading,
      legStatus,
      airportIds,
      isEditable,
      cardHeading,
      componentType,
      itineraryType,
      getAirportById,
      availedService,
      groundServices,
      uniqueIdentifier,
      visitedAirportsFinder,
      isLoadingAvailedServices,
      addGroundTransportService,
      isLoadingAdditionalServices,
    } = this.props;
    this.getMissingAirports();

    const isAddGroundServiceButtonShow = itineraryType === 'upcomingItineraries' && legStatus !== 'Cancellation Pending';
    const isLoadingComponent = componentType === ADDITIONAL_SERVICE ? isLoadingAdditionalServices : isLoadingAvailedServices;

    return (
      <div>
        <div className="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove">
          <div className="vistajet_itinerary_card_header">
            <h5 className="uk-width-expand">{cardHeading}</h5>
            {
              isAddGroundServiceButtonShow
              && isEditable
              && !isLoadingComponent
              && !isLoading
              && (
                <a className="vistajet_button_default uk-margin-remove" onClick={() => componentType === ADDITIONAL_SERVICE ? this.openASModal() : this.openGTModal()} >
                  Add New Service
                </a>
              )}
          </div>
          <div className="vistajet_itinerary_card_body">
            {!isLoading && !isLoadingComponent ?
              this.renderServicesListing()
              :
              (
                <div className="vistajet_spinner">
                  <SpinnerLoader />
                </div>
              )
            }
          </div>
        </div>
        <GroundTransportationModal
          token={token}
          legKey={legKey}
          legStatus={legStatus}
          airportIds={airportIds}
          itineraryType={itineraryType}
          id="groundTransportationModal"
          getAirportById={getAirportById}
          availedService={availedService}
          groundServices={groundServices}
          ref={(e) => { this.GTModal = e; }}
          uniqueIdentifier={uniqueIdentifier}
          visitedAirportsFinder={visitedAirportsFinder}
          addGroundTransportService={addGroundTransportService}
        />
        <GroundServicesModal
          token={token}
          legKey={legKey}
          legStatus={legStatus}
          airportIds={airportIds}
          id="additionalServicesModal"
          itineraryType={itineraryType}
          getAirportById={getAirportById}
          availedService={availedService}
          groundServices={groundServices}
          ref={(e) => { this.ASModal = e; }}
          uniqueIdentifier={uniqueIdentifier}
          visitedAirportsFinder={visitedAirportsFinder}
          addGroundTransportService={addGroundTransportService}
        />
      </div>
    );
  }
}

export default GroundServicesCard;
