import axios from 'axios';
import { FETCH_ENTERTAINMENT_ISSUE_NUMBER, FETCH_ENTERTAINMENT } from '../../configs/constants';
import {
  fetchEntertainmentIssueInit,
  fetchEntertainmentIssueSuccess,
  fetchEntertainmentIssueFail,
  fetchEntertainmentInit,
  fetchEntertainmentSuccess,
  fetchEntertainmentFail,
} from '../actions';
import store from '../store';
import { getFromLocal } from '../../utils/cache';
import { USER_REDUCER } from '../../constants/localStorageKeys';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';

const status = {
  OK: 200,
};
const { user } = getFromLocal(USER_REDUCER, true, true) || {};
const token = (user || {}).userToken;

const fetchEntertainmentPromiseInprogress = {};
export const fetchEntertainment = (issueNumber, tailNumber = '_', legId) => (dispatch) => {
  const uniqueKey = `entertainment-${legId}`;
  if (fetchEntertainmentPromiseInprogress[uniqueKey]) {
    return fetchEntertainmentPromiseInprogress[uniqueKey];
  }
  const fetchEntertainmentPromise = new Promise((resolve, reject) => {
    const url = FETCH_ENTERTAINMENT.replace('{token}', token).replace('{issueNumber}', issueNumber)
      .replace('{tailNumber}', tailNumber);
    dispatch(fetchEntertainmentInit(legId));
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          dispatch(fetchEntertainmentSuccess(response.data, legId));
          resolve(response.data);
        } else {
          const errors = handleServerErrors(response.status);
          dispatch(fetchEntertainmentFail(errors, legId));
          reject(errors);
        }
        delete fetchEntertainmentPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(fetchEntertainmentFail(errors, legId));
        reject(errors);
        delete fetchEntertainmentPromiseInprogress[uniqueKey];
      });
  });
  fetchEntertainmentPromiseInprogress[uniqueKey] = fetchEntertainmentPromise;
  return fetchEntertainmentPromise;
};

export const fetchEntertainmentIssue = (tailNumber = '_', legId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const url = FETCH_ENTERTAINMENT_ISSUE_NUMBER.replace('{token}', token).replace('{tailNumber}', tailNumber);
    dispatch(fetchEntertainmentIssueInit(legId));
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          dispatch(fetchEntertainmentIssueSuccess(response.data, legId));
          resolve(response.data);
        } else {
          const errors = handleServerErrors(response.status);
          dispatch(fetchEntertainmentIssueFail(errors, legId));
          reject(errors);
        }
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(fetchEntertainmentIssueFail(errors, legId));
        reject(errors);
      });
  });
};
