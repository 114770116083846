import { connect } from "react-redux";

import withStore from "../components/withStore";
import Header from "../components/Header";
import {
  loginAction,
  logoutAction,
} from "../store/actions";
function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(loginAction(email, password)),
    logout: () => dispatch(logoutAction()),
  };
}

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export default withStore(HeaderContainer);
