import axios from 'axios';
import adminTokenSource from './AdminTokenSource';
import {
  registrationInit,
  registrationSuccess,
  registrationFail,
  verifyUserFail,
  verifyUserInit,
  verifyUserSuccess,
} from '../actions';

import {
  REGISTER_URL,
  CONTEXT,
  APP_SOURCE,
} from '../../configs/constants';

import {
  HTTP_HEADERS_JSON,
} from '../../constants/ApiConstants';

import {
  handleServerErrors,
} from '../../utils/sourceUtils';

import {
  normalizeRegisterResponse,
  normalizeRegisterRequestPayload,
} from '../../normalizers/registerNormalizer';
import authenticateTokenSource from './AuthenticateTokenSource';
import { saveToLocal, existInLocal, getFromLocal } from '../../utils/cache';

export const registrationSource = (token, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const url = REGISTER_URL.replace('{context}', CONTEXT)
      .replace('{token}', token)
      .replace('{mode}', 'REGISTER');

    dispatch(registrationInit());

    const reqPayload = normalizeRegisterRequestPayload(payload, token);
    axios
      .post(
        url,
        reqPayload,
        HTTP_HEADERS_JSON,
      )
      .then((res) => {
        const responseHeader = res.data.responseHeaderDto;
        if (!responseHeader.isError) {
          const responseBody = res.data.responseBodyDto;
          const normalizedResponse = normalizeRegisterResponse(responseBody);
          dispatch(registrationSuccess({
            user: normalizedResponse,
            isUserRegistered: true,
          }));
          saveToLocal({ user: normalizedResponse }, 'register', true, true);
          resolve({
            user: normalizedResponse,
            isUserRegistered: true,
            requestPayload: payload,
          });
        } else {
          if (responseHeader.statusCode) {
            const errors = handleServerErrors(responseHeader.statusCode);
            dispatch(registrationFail(errors));
            saveToLocal({ user: null }, 'register', true, true);
            reject(errors);
          } else {
            dispatch(registrationFail(responseHeader.message));
            saveToLocal({ user: null }, 'register', true, true);
            reject(responseHeader.message);
          }
        }
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(registrationFail(errors));
        saveToLocal({ user: null }, 'register', true, true);
        reject(errors);
      });
  });
};

const verifyUser = verifyData => (dispatch) => {
  const reqPayload = {
    verificationCode: verifyData.verificationCode,
    source: APP_SOURCE,
  };

  const url = REGISTER_URL.replace('{context}', CONTEXT)
    .replace('{token}', verifyData.token.token)
    .replace('{mode}', 'VERIFY');
  axios
    .post(
      url,
      reqPayload,
      HTTP_HEADERS_JSON,
    )
    .then((res) => {
      const response = res.data;
      let user = null;
      if (existInLocal('user')) {
        user = getFromLocal('user', true, true);
      }
      if (response.responseHeaderDto.isError) {
        // reject(handleServerErrors(response.responseHeaderDto, response.responseHeaderDto));
        const error = handleServerErrors(response.responseHeaderDto.statusCode);
        saveToLocal({
          isUserVerified: false,
          user: user.user,
        }, 'user', true, true);
        dispatch(verifyUserFail({ error }));
      } else {
        // resolve({
        //   message: response.responseHeaderDto.message,
        //   success: !response.responseHeaderDto.isError,
        //   token: response.responseBodyDto.token,
        // });
        console.log("TEST 1");
        console.log(user);
        if (user !== null) {
          if (user.user !== null)
          {
            user.user.userNotVerified = false;
          }
          saveToLocal({
            isUserVerified: true,
            user: user.user,
          }, 'user', true, true);
        }
        dispatch(verifyUserSuccess(response.responseBodyDto));
      }
    })
    .catch((err) => {          
      console.log("TEST 2");
      console.log(err);
      let user = null;
      if (existInLocal('user')) {
        user = getFromLocal('user', true, true);
      }
      const errors = handleServerErrors(
        err.response
          ? err.response.status
          : err.response,
      );
      if (user !== null) {
        saveToLocal({
          isUserVerified: false,
          user: user.user,
        }, 'user', true, true);
      }
      dispatch(verifyUserFail({ error: errors }));
    });
};

export const verifyUserSource = verificationCode => (dispatch) => {
  dispatch(verifyUserInit());
  dispatch(adminTokenSource()).then(
    (token) => {
      dispatch(authenticateTokenSource(token.token)).then(
        () => {
          dispatch(verifyUser({ token, verificationCode }));
        },
      );
    },
  );
};
