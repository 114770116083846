import React from 'react';
import { isMobile } from 'react-device-detect';
import DatePicker from 'react-datepicker';
import Helpers from '../../../utils/Helpers';
import { format, parse } from 'date-fns';



export default function DatePickerWrapper(props) {
  var highlightedDates = props.highlightDates.map(date => parse(date, "yyyy-MM-dd", new Date()))

  return (
    <DatePicker
      selected={props.selected ? parse(props.selected, "yyyy-MM-dd", new Date()) : new Date()}
      placeholderText="Select Date"
      dateFormat={props.dateFormat}
      onChange={props.onChange}
      minDate={new Date()}
      maxDate={Helpers.addRealMonth(new Date())}
      isClearable={props.date}
      highlightDates={props.highlightDates && props.highlightDates.length ? [{ "highlighted_date": highlightedDates }] : []}
      customInput={
        isMobile && (<div
          style={{
            marginRight: 8,
            fontStyle: 'Italic'
          }}
        >{props.selected ? format(parse(props.selected, "yyyy-MM-dd", new Date()), props.dateFormat) : 'Select Date'}</div>
        )
      }
    />)
}