import 'react-responsive-modal/styles.css';
import ReactModal from 'react-responsive-modal';
import React, { Component } from 'react';

class Modal extends Component {
  static defaultProps = {
    isCenter: true,
    closeOnEsc: false,
    closeOnOverlayClick: false,
    showCloseIcon: true,
    focusTrapped: true,
    closeIconSize: 28,
    onExited: () => {},
    onClose: () => {},
    closeIconSvgPath: <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />,
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  show = () => {
    this.setState({ open: true });
  };

  hide = () => {
    const { onClose } = this.props;
    this.setState({ open: false });
    onClose();
  };
  

  render() {
    const {
      classNames,
      isCenter,
      closeOnEsc,
      closeOnOverlayClick,
      onExited,
      styles,
      showCloseIcon,
      closeIconSize,
      closeIconSvgPath,
      focusTrapped,
      children,
    } = this.props;
    const { open } = this.state;
    const classes = {
      overlay: `overlay-background ${(classNames || {}).overlay || ''}`,
      ...classNames,
    };
    return (
      <ReactModal
        open={open}
        classNames={classes}
        closeOnOverlayClick={closeOnOverlayClick}
        onClose={this.hide}
        center={isCenter}
        closeOnEsc={closeOnEsc}
        onExited={onExited}
        styles={styles}
        showCloseIcon={showCloseIcon}
        closeIconSize={closeIconSize}
        closeIconSvgPath={closeIconSvgPath}
        focusTrapped={focusTrapped}
      >
        { children }
      </ReactModal>
    );
  }
}
export default Modal;
