import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Validate from '../../utils/Validator';
import { LEGACY_ERROR_MESSAGES, INVALID_DATE_MSG } from '../../constants/ErrorMessageConstants';
import "react-datepicker/dist/react-datepicker.css";
import Calendar from '../shared/ReactCalendar'
import { isValidDateInFuture } from '../../utils/dateUtils';
import { getCountry } from 'utils/countryUtils';
import Helpers from '../../utils/Helpers';
import {
  PassengerForm,
  PassportForm,
} from '../../constants/passengerConstants';
import {
  NAME_REGEX,
  INVALID_NAME,
} from '../../constants/SignupConstants';
import Source from '../../store/sources/ItineraryPassengerSource';
import { getGender } from '../../utils/passengerUtils';
import SpinnerLoader from '../shared/spinnerLoader';
import Select from 'react-select';

class AddNewPassenger extends React.Component {

  static propTypes = {
    showToastMesg: PropTypes.func,
    handleRenderingChange: PropTypes.func,
    handleAddPassportFromPax: PropTypes.func,
    newPassenger: PropTypes.object,
    submitNewPassenger: PropTypes.func,
    handleDirtyChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const dob = props.newPassenger.dateOfBirth ? moment(props.newPassenger.dateOfBirth) : '';
    const email = ((props.newPassenger.emailAddresses || [])[0] || {}).email || '';
    const phoneNumber = ((props.newPassenger.phoneNumbers || [])[0] || {}).phoneNumber || '';
    this.state = {
      changePassportNumber: 0,
      dateOfBirth: dob,
      email,
      firstName: props.newPassenger.firstName || '',
      genderId: this.getGenderObj(props.newPassenger.genderId, props.newPassenger.gender),
      lastName: props.newPassenger.lastName || '',
      phoneNumber,
      imageFile: props.newPassenger.profilePicture || '',
      passports: props.newPassenger.passports || [],
      passengerExtIdentifier: Helpers.guid(),
      validationErrors: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dob: '',
      },
      showLoader: false,
    };
    this.styles = {
      selectBox: { borderRadius: 0 },
    };
  }
  componentWillMount() {
    const { firstName, lastName, genderId, phoneNumber, email, dateOfBirth, passports } = this.state;
    if (firstName || lastName || genderId || phoneNumber || email || dateOfBirth) {
      this.props.handleDirtyChange(true);
    }
    if (passports.length === 1) this.setDefaultFirst();
  }
  getGenderObj = (genderId, genderName) => {
    if (genderId && genderName) {
      return {
        value: genderId,
        label: genderName,
      }
    }
    return '';
  }
  onFirstNameChange = (e) => {
    this.props.handleDirtyChange(true);
    const input = e.target.value;
    if (input && NAME_REGEX.test(input)) {
      this.setState({
        firstName: input,
      });
    } else if (!input) {
      this.setState({
        firstName: input,
      });
    }
  }

  onLastNameChange = (e) => {
    this.props.handleDirtyChange(true);
    const input = e.target.value;
    if (input && NAME_REGEX.test(input)) {
      this.setState({
        lastName: input,
      });
    } else if (!input) {
      this.setState({
        lastName: input,
      });
    }
  }

  onGenderChange = (obj) => {
    this.props.handleDirtyChange(true);
    this.setState({
      genderId: obj,
    });
  }
  onEmailChange = (e) => {
    this.props.handleDirtyChange(true);
    this.setState({
      email: e.target.value,
    });
  }
  validatePhoneNumberField(phoneno) {
    const rePhone = /^[\+]?[0-9]{0,14}$/;
    return !rePhone.test(phoneno);
  }
  onPhoneChange = (e) => {
    const value = e.target.value.toString();
    const isNotValid = this.validatePhoneNumberField(value);
    if (!isNotValid || !value) {
      this.props.handleDirtyChange(true);
      this.setState({
        phoneNumber: e.target.value,
      });
    }
  }

  handleDateChange = (jsDate) => {
    this.props.handleDirtyChange(true);
    if (Validate.isValidDate(jsDate) &&
      !Validate.isSameDate(jsDate, this.state.dateOfBirth)) {
      const isDobInvalid = jsDate !== '' && !moment(jsDate).isValid();
      const dateOfBirth = !isDobInvalid && jsDate &&
        moment(jsDate).format('DD MMM YYYY');
      const validationErrors = this.state.validationErrors;
      validationErrors.dob = isDobInvalid ? INVALID_DATE_MSG : '';
      this.setState({
        dateOfBirth,
        validationErrors,
      });
    } else {
      this.setState({
        dateOfBirth: jsDate,
      });
    }
  }
  handleDateInputBlur = () => {
    if (!Validate.isValidDate(this.state.dateOfBirth)) {
      const validationErrors = this.state.validationErrors;
      validationErrors.dob = INVALID_DATE_MSG;
      this.setState({
        ...this.state,
        validationErrors,
      });
      return true;
    }
    return false;
  }

  validateForm = () => {
    let isFormValid = true;
    const validationErrors = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      dob: '',
    };
    let focusField;
    // form validations
    if (Validate.validRequired(this.state.firstName)) {
      isFormValid = false;
      focusField = focusField || 'firstName';
      validationErrors.firstName = 'First name is required';
    } else if (Validate.validName(this.state.firstName)) {
      isFormValid = false;
      focusField = focusField || 'firstName';
      validationErrors.firstName = INVALID_NAME;
    }

    if (Validate.validRequired(this.state.lastName)) {
      isFormValid = false;
      focusField = focusField || 'lastName';
      validationErrors.lastName = 'Last name is required';
    } else if (Validate.validName(this.state.lastName)) {
      isFormValid = false;
      focusField = focusField || 'lastName';
      validationErrors.lastName = INVALID_NAME;
    }

    if (this.state.email &&
      Validate.validEmail(this.state.email.toLowerCase())) {
      isFormValid = false;
      focusField = focusField || 'email';
      validationErrors.email = LEGACY_ERROR_MESSAGES.emailIncorrect;
    }

    if (this.state.phoneNumber &&
      Validate.validPhoneNo(this.state.phoneNumber)) {
      isFormValid = false;
      focusField = focusField || 'phoneNumber';
      validationErrors.phoneNumber = LEGACY_ERROR_MESSAGES.phoneIncorrect;
    }
    const dob = this.state.dateOfBirth ?
      this.state.dateOfBirth : '';
    const isDobInvalid = isValidDateInFuture(dob) || this.handleDateInputBlur();
    validationErrors.dob = isDobInvalid ? INVALID_DATE_MSG : '';
    if (isDobInvalid) {
      isFormValid = false;
      focusField = focusField || 'dateOfBirth';
    }

    if (!isFormValid) {
      this.setState({ validationErrors });
      if (focusField) {
        if (focusField === 'dateOfBirth') {
          const datePicker = this.refs[focusField].refs.datePicker;
          if (datePicker && datePicker.getDOMNode) {
            datePicker.getDOMNode().focus();
          }
        } else {
          this.refs[focusField].focus();
        }
      }
    }
    return isFormValid;
  }

  handleSubmit = () => {
    const isFormValid = this.validateForm();
    // if form is valid, then do further action
    if (isFormValid) {
      const data = this.createData();
      if (data.profilePicture) {
        this.setState({ showLoader: true });
        Source.uploadImage(data.profilePicture).then((resp) => {
          data.profilePicture = resp.relativeUrl;
          this.props.submitNewPassenger(data);
          this.setState({ showLoader: false });
        });
      } else {
        this.props.submitNewPassenger(data);
      }
    }
  }
  handleAddPassportClick = () => {
    if (this.validateForm()) {
      this.props.handleDirtyChange(false);
      const data = this.createData();
      this.props.handleAddPassportFromPax(data);
      this.props.handleRenderingChange(PassportForm, PassengerForm);
    }
  }

  createData = () => {
    const { email } = this.state;
    const emailAddresses = email ? [{
      email: email.toLowerCase(),
      id: '',
    }] : [];
    const phoneNumbers = this.state.phoneNumber ? [{
      phoneNumber: this.state.phoneNumber,
      id: '',
    }] : [];
    const dateOfBirth = this.state.dateOfBirth || '';
    return {
      dateOfBirth,
      emailAddresses,
      firstName: this.state.firstName,
      genderId: +this.state.genderId.value || -1,
      gender: getGender(+this.state.genderId.value || ''),
      lastName: this.state.lastName,
      phoneNumbers,
      profilePicture: this.state.imageFile || '',
      passengerNationality: '',
      passengerId: '',
      passengerExtIdentifier: this.state.passengerExtIdentifier || Helpers.guid(),
      isNewlyAdded: true,
      isSoftlyAdded: true,
      passports: this.state.passports,
    };
  }
  handlePicUpload = (file) => {
    this.props.handleDirtyChange(true);
    const imageFile = file;
    this.setState({ imageFile });
  }
  setLegPassport = (passportNumber) => {
    this.props.handleDirtyChange(true);
    let { passports } = this.state;
    passports = passports.map(value => {
      if (value.passportNumber === passportNumber) {
        value.isLegPassport = true;
      } else {
        value.isLegPassport = false;
      }
      return value;
    });
    this.setState({
      passports,
    })
    this.setState({
      changePassportNumber: passportNumber,
    });
    this.props.setLegPassport(this.state.passengerExtIdentifier, passportNumber);
  }
  getDefaultPassportChecked = (isLegPassport, passportNumber) => {
    const { changePassportNumber } = this.state;
    if (changePassportNumber === passportNumber) {
      return true;
    } else if (isLegPassport) {
      return true;
    }
    return false;
  }
  setDefaultFirst = () => {
    const { passports } = this.state;
    if (passports && passports.length) {
      const filteredPassport = passports.filter(value => value.isLegPassport);
      if (!filteredPassport.length) {
        passports[0].isLegPassport = true;
        this.setState({
          passports,
        })
      }
    }
  }
  handleRemovePassport = (passportNumber, paxId) => {
    const { passports } = this.state;
    let filteredPassport = passports.filter(value => value.passportNumber !== passportNumber);
    if (filteredPassport.length) {
      const legPassport = filteredPassport.find(value => value.isLegPassport);
      if (!legPassport) {
        filteredPassport[0].isLegPassport = true;
      }
    }
    this.setState({
      passports: filteredPassport,
    })
    this.props.handleRemovePassport(passportNumber, paxId);
  }
  renderPassport = (passports) => (
    passports.map((passport, index) => {
      if (passport.isDeleted || (!passport.isActive && !passport.isLegPassport)) {
        return (null);
      }
      const passportCountry = getCountry('THREE_LETTER_CODE', passport.isoPassportCountry) || {};
      const countryFlagCode = passportCountry && passportCountry.TWO_LETTER_CODE;
      const dateToExpire = passport.expiry ?
        `Expires ${moment(passport.expiry).format('DD MMM YYYY')}` : '';
      return (
        <div className="vistajet_user_profile_container vistajet_passenger_passport_container" key={passport.id}>
          <div className="vistajet_profile_details_column">
            <div className="vistajet_passport_details">
              <div className="uk-flex uk-grid-small uk-grid uk-grid-stack"> 
                <div className="uk-width-1-1">
                  <div className="vistajet_passpport_list_content">
                    <div className="vistajet_details_hover vistajet_passport_buttons_list">
                      <label className="vistajet_radio vistajet_passenger_passport_radio_buttons" onClick={() => { this.setLegPassport(passport.passportNumber); }}>
                        <input name="radio" type="radio"
                          checked={this.getDefaultPassportChecked(passport.isLegPassport, passport.passportNumber)}
                          onChange={() => { this.setLegPassport(passport.passportNumber); }}
                        />
                        <span>&nbsp;</span>
                      </label>
                      <span className={`vistatejet_passenger_passport_flag flag-icon-style flag-icon-${countryFlagCode && countryFlagCode.toLowerCase()}`} />
                      <span className="vistajet_text uk-text-middle vistajet_passport_number">{passport && passport.passportNumber.toUpperCase()}</span>
                      <span className="vistajet_text uk-text-middle vistajet_expires_number">
                        <small className="uk-margin-small-left">{dateToExpire}</small>
                      </span>
                      <span className="vistajet_passport_hover_action vistajet_passport_buttons">
                        <span>
                          <img src="/legacy/static/img/icons/vistajet_close_grey_icon.svg" alt=""
                            onClick={() => {
                              this.handleRemovePassport(passport.passportNumber,
                                this.state.passengerExtIdentifier)
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })
  );
  disableButton = () => {
    const {
      firstName,
      lastName,
    } = this.state;
    if (firstName && lastName) {
      return false;
    }
    return true;
  }
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  render() {
    const genderOptions = [];
    genderOptions.push({
      value: 1,
      label: 'Male',
    },
      {
        value: 2,
        label: 'Female',
      });
    const { validationErrors = {} } = this.state;
    const {
      firstName,
      lastName,
      dateOfBirth,
      genderId,
      email,
      phoneNumber,
      showLoader,
      passports,
    } = this.state;
    const isButtonDisable = this.disableButton();
    const disableButtonClass = isButtonDisable ?
      `uk-button vistajet_disabled uk-width-1-1 uk-text-center` :
      `uk-button uk-button-default uk-width-1-1 uk-text-center`;
    return (
      <div>
        <div className="vistajet_add_new_passanger_form">
          <h6 className="passenger_details_subheading_text uk-margin-remove">Please provide passenger details</h6>
          <div className="vistajet_profile_details_column vistajet_profile_edit">
            <div className="vistajet_add_new_passenger_form_details uk-grid">
              <div className="uk-margin-small-bottom uk-width-1-2@s vistajet_primary_form">
                <div className="uk-margin-remove">
                  <div className="vistajet_input_wrapper">
                    <input
                      id="firstname"
                      value={firstName}
                      data-key="firstName"
                      type="text"
                      maxLength="100"
                      ref="firstName"
                      autocomplete="given-name"
                      onChange={this.onFirstNameChange}
                    />
                    <label
                      htmlFor="firstname"
                      ref="firstNameError"
                    >First Name</label>
                    {
                      validationErrors &&
                        validationErrors.firstName ?
                        <p>{validationErrors.firstName}</p> :
                        null
                    }
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-bottom uk-width-1-2@s vistajet_primary_form">
                <div className="uk-margin-remove">
                  <div className="vistajet_input_wrapper">
                    <input
                      id="lastname"
                      value={lastName}
                      data-key="lastName"
                      type="text"
                      maxLength="100"
                      ref="lastName"
                      autocomplete="family-name"
                      onChange={this.onLastNameChange}
                    />
                    <label
                      htmlFor="lastname"
                      ref="lastNameError"
                    >Last Name</label>
                    {
                      validationErrors &&
                        validationErrors.lastName ?
                        <p>{validationErrors.lastName}</p> :
                        null
                    }
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-bottom uk-width-1-2@s vistajet_primary_form">
                <div className="vistajet_input_wrapper uk-margin-remove">
                  <div className="vistajet_date_picker_new">
                  <Calendar
                    dateInput={dateOfBirth}
                    placeholder={'Date of birth'}
                    readOnly={true}
                    minDate={moment().subtract(200, 'year')}
                    maxDate={moment()}
                    onDateChange={this.handleDateChange}
                    className="uk-width-1-1"
                  />
                    {
                      validationErrors &&
                        validationErrors.dob ?
                        <p className="validation_error_passenger_details">{validationErrors.dob}</p> :
                        null
                    }
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-bottom uk-width-1-2@s vistajet_primary_form">
                <div className="vistajet_passenger_gender_select">
                  <div className="vistajet_input_wrapper">
                    <Select
                      classNamePrefix="react-select"
                      type="text"
                      ref="gender"
                      onChange={this.onGenderChange}
                      data-key="genderId"
                      value={genderId}
                      options={genderOptions}
                      className="vistajet_select_input"
                      placeholder=""
                      isSearchable={false}
                    />
                    <label>
                      Gender
                      </label>
                    <i uk-icon="icon: chevron-down; ratio: 1"></i>
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-bottom uk-width-1-2@s vistajet_primary_form">
                <div className="uk-margin-remove">
                  <div className="vistajet_input_wrapper">
                    <input
                      id="email"
                      type="text"
                      maxLength="255"
                      ref="email"
                      value={email}
                      data-key="email"
                      autocomplete="email"
                      onChange={this.onEmailChange}
                    />
                    <label
                      htmlFor="email"
                      ref="emailError"
                    >
                      Email
                      </label>
                    {
                      validationErrors &&
                        validationErrors.email ?
                        <p>{validationErrors.email}</p> :
                        null
                    }
                  </div>
                </div>
              </div>
              <div className="uk-margin-small-bottom uk-width-1-2@s vistajet_primary_form">
                <div>
                  <div className="vistajet_input_wrapper removeInputIncrementIcon">
                    <input
                      id="phoneNumber"
                      type="text"
                      ref="phoneNumber"
                      value={phoneNumber}
                      data-key="phoneNumber"
                      autocomplete="tel"
                      onChange={this.onPhoneChange}
                    />

                    <label
                      htmlFor="phoneNumber"
                      ref="phoneError"
                    >Phone Number</label>
                    {
                      validationErrors &&
                        validationErrors.phoneNumber ?
                        <p>{validationErrors.phoneNumber}</p> :
                        null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 className="passenger_details_subheading_text uk-margin-small-top uk-margin-small-bottom">
          {(passports && passports.length) ?
            `Manage Passports` :
            `Please add passport details`
          }
        </h6>
        {this.renderPassport(passports)}
        <div className="vistajet_add_passenger_passport_button">
          <div className="vistajet_passenger_add_new_passport_button uk-margin-small">
            <a className="vistajet_button_default"
              onClick={this.handleAddPassportClick}
            >
              Add new passport
            </a>
          </div>
        </div>
        <div className="vistajet_modal_footer uk-text-center">
          <button
            className={disableButtonClass}
            type="button"
            disabled={showLoader || isButtonDisable}
            onClick={this.handleSubmit}
            onBlur={() => { this.refs.firstName.focus(); }}
          >
            Confirm
      </button>
        </div>
        {(() => (showLoader ?
          (<SpinnerLoader />) : null
        ))}
      </div>
    );
  }
}

export default AddNewPassenger;
