import * as type from '../../actionTypes';

const initialState = {
  isLoadingEntertainment: 0,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.FETCH_ENTERTAINMENT_ISSUE_INIT: {
        const entertainmentData = {};
        entertainmentData[action.flightLegId] = {};
        entertainmentData[action.flightLegId].isLoadingEntertainment = true;
        
        return Object.assign({}, state, {
          isLoadingEntertainment: state.isLoadingEntertainment + 1,
          entertainment: entertainmentData,
        });
      }
  
      case type.FETCH_ENTERTAINMENT_ISSUE_SUCCESS: {
        state.entertainment[action.flightLegId].entertainmentIssue = action.data;
        state.entertainment[action.flightLegId].isLoadingEntertainment = false;
        return Object.assign({}, state, {
          isLoadingEntertainment: state.isLoadingEntertainment - 1,
          entertainment: state.entertainment,
        });
      }
  
      case type.FETCH_ENTERTAINMENT_ISSUE_FAIL: {
        state.entertainment[action.flightLegId].isLoadingEntertainment = false;
        return Object.assign({}, state, {
          isLoadingEntertainment: state.isLoadingEntertainment - 1,
          entertainment: state.entertainment,
        });
      }
    case type.FETCH_ENTERTAINMENT_INIT: {
      state.entertainment[action.flightLegId].isLoadingEntertainment = true;
      return Object.assign({}, state, {
        isLoadingEntertainment: state.isLoadingEntertainment + 1,
        entertainment: state.entertainment,
      });
    }

    case type.FETCH_ENTERTAINMENT_SUCCESS: {
      state.entertainment[action.flightLegId].entertainment = action.data;
      state.entertainment[action.flightLegId].isLoadingEntertainment = false;
      return Object.assign({}, state, {
        isLoadingEntertainment: state.isLoadingEntertainment - 1,
        entertainment: state.entertainment,
      });
    }

    case type.FETCH_ENTERTAINMENT_FAIL: {
      state.entertainment[action.flightLegId].isLoadingEntertainment = false;
      return Object.assign({}, state, {
        isLoadingEntertainment: state.isLoadingEntertainment - 1,
        entertainment: state.entertainment,
      });
    }
    default:
      return state;
  }
}
