import React, { Component } from 'react';
import ConfirmationPopUpNew from '../shared/ConfirmationPopUpNew';
import SpinnerLoader from '../shared/spinnerLoader';
import { TERMS_URL_PAGE } from '../../configs/constants';
import { scrollToTop } from '../../utils/browserUtils';

class FlightCancel extends Component {
  constructor(props) {
    super(props);
    this.openCancelPopUp = this.openCancelPopUp.bind(this);
    this.closeCancelPopUp = this.closeCancelPopUp.bind(this);
  }

  openCancelPopUp = () => {
    this.flightCancelPopUp.show();
  };

  closeCancelPopUp = () => {
    this.flightCancelPopUp.hide();
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.itineraryCancellationMessage !== nextProps.itineraryCancellationMessage
      && nextProps.itineraryCancellationMessage === 'OK'
    ) {
      this.flightCancelPopUp.hide();
      setTimeout(() => {
        scrollToTop();
      }, 1000);
      this.props.isRenderCancelButton();
      this.props.updateFlightCancel()
    }
  }

  handleCancelFlight = () => {
    const {
      flightCancel,
      accountId,
      leg,
      user,
      orderId,
      token,
    } = this.props;
    flightCancel(token, accountId, leg, user, orderId);
  };

  render() {
    const { itineraryCancellationIsLoading } = this.props;
    const popUpContent = <div>
      {itineraryCancellationIsLoading && (<div className="vistajet_spinner">
        <SpinnerLoader />
      </div>)}
      <React.Fragment>
        Please note that under the terms of the App<strong>
          <a href={TERMS_URL_PAGE} target="_blank" rel="noopener noreferrer"> Ts&amp;Cs </a>
        </strong>agreement,
        your decision to cancel the flight may result in you being subject to
        a cancellation charge and additional costs. Are you sure you want to
        cancel the flight?
      </React.Fragment>
    </div>;
    return (
      <div>
        <a
          class="vistajet_button_default uk-margin-remove"
          onClick={this.openCancelPopUp}
        >
          Cancel Flight
      </a>
        <ConfirmationPopUpNew
          ref={(flightCancelPopUp) => { this.flightCancelPopUp = flightCancelPopUp; }}
          id="FlightCancelPopUp"
          leftButtonContent="No"
          onLeftButtonClick={this.closeCancelPopUp}
          onRightButtonClick={this.handleCancelFlight}
          rightButtonContent="Yes"
          closeOnOverlayClick={false}
          content={popUpContent}
          title="Are you sure you want to cancel your flight?"
        />
      </div>
    );
  }
}

export default FlightCancel;