import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';

class Weather extends Component {

renderWeather = (weatherDetails, arrivalAirportId, departureAirportId, visitedAirportsFinder, departureDate, arrivalDate) => {
    if(weatherDetails) {
        const arrivalAirport = (visitedAirportsFinder[arrivalAirportId] || []).name || '';
        const departureAirport = (visitedAirportsFinder[departureAirportId] || []).name || '';
        const arrivalWeather = weatherDetails && weatherDetails.arrivalWeather || {};
        const departureWeather = weatherDetails && weatherDetails.departureWeather || {};
        const arrivalClass = `uk-width-expand ${arrivalWeather.icon}`;
        const departureClass = `uk-width-expand ${departureWeather.icon}`;

        return (
            <div className="uk-grid uk-grid-large" data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-2@s">
                    <h5 className="vistajet_departure_heading">{departureAirport}</h5>
                    {
                        departureWeather ? (<div className="uk-flex uk-flex-middle">
                            <h6 className={departureClass}>{departureWeather.centigrade}<sup>o</sup>C</h6>
                            <span className="uk-width-auto vistajet_weather_date uk-text-middle">
                                {departureDate || ''}
                            </span>
                        </div>) : 
                        (<div>
                            {'No weather details available.'}
                        </div>)
                    }                 
                                
                </div>
                    
                <div className="uk-width-1-1 uk-width-1-2@s">
                    <h5 className="vistajet-arrival-heading">{arrivalAirport}</h5>
                    {
                        arrivalWeather ? ( <div className="uk-flex uk-flex-middle">
                            <h6 className={arrivalClass}>{arrivalWeather.centigrade}<sup>o</sup>c</h6>
                            <span className="uk-width-auto vistajet_weather_date"> 
                            {arrivalDate || ''}                         
                            </span>
                        </div>) : 
                        (<div>
                            {'No weather details available.'}
                        </div>)
                    }          
                   
                </div>
            </div>
        )   
    } else {
        return (<p className="uk-margin-remove">
        {'No weather details available.'}
    </p>);
    }

}
  
    render() {
      const { weatherDetails,
            arrivalAirportId,
            departureAirportId,
            visitedAirportsFinder,
            isLoading,
            isLoadingWeather,
            departureDate,
            arrivalDate
         } = this.props;
        return (
            <div className="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove vistajet_ground_transportation vistajet_itinerary_weather">
                <div className="vistajet_itinerary_card_header">
                    <h5 className="uk-width-expand">Weather</h5>
                </div>
                <div className="vistajet_itinerary_card_body">
                {
                    !isLoading && !isLoadingWeather ? 
                    this.renderWeather(weatherDetails, arrivalAirportId, departureAirportId, visitedAirportsFinder, departureDate, arrivalDate) : 
                    (<div className="vistajet_spinner">
                        <SpinnerLoader />
                    </div>)
                }
                </div>
            </div>);
    }
}

export default Weather;