import * as types from '../actionTypes';

export const initialState = {
  isQuoteLoading: false,
  isQuoteLoadMoreLoading: false,
  activeQuotes: {},
  expiredQuotes: {},
  quoteError: '',
};

const addQuotes = (oldQuotes, newQuotes) => {
  const _oldQuotes = { ...oldQuotes };
  newQuotes.forEach(quote => {
    _oldQuotes[quote.id] = quote;
  });
  return _oldQuotes;
};

export default function quotesReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_QUOTES_INIT: {
      return Object.assign({}, state, {
        isQuoteLoading: action.data > 1 ? false : true,
        isQuoteLoadMoreLoading: action.data === 1 ? false : true,
        quoteError: '',
      });
    }
    case types.FETCH_QUOTES_SUCCESS: {
      return Object.assign({}, state, {
        isQuoteLoading: false,
        isQuoteLoadMoreLoading: false,
        activeQuotes: action.data.quoteType === 'QUOTED'
          ? addQuotes(state.activeQuotes, action.data.quotes)
          : state.activeQuotes,
        expiredQuotes: action.data.quoteType === 'EXPIRED'
          ? addQuotes(state.expiredQuotes, action.data.quotes)
          : state.expiredQuotes,
      });
    }
    case types.FETCH_QUOTES_FAIL: {
      return Object.assign({}, state, {
        isQuoteLoading: false,
        isQuoteLoadMoreLoading: false,
        quoteError: action.data
      });
    }
    default:
      return state;
  }
}
