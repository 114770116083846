import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ItineraryListingCard from './ItineraryListingCard';
import { ITINERARY_DETAILS_PAGE_URL } from '../../configs/constants';
import { legStatus, ITINERARY_TYPES } from '../../constants/ItineraryConstants';
import { getItineraryLegDates, getItineryPathIcon } from '../../utils/itinerariesUtils';

const FIRST_ITINERARY_INDEX = 0;

class ItineraryListItem extends Component {
  static propTypes = {
    index: PropTypes.number,
    itineraryType: PropTypes.string,
    item: PropTypes.instanceOf(Object),
    isGoogleMapsApiLoaded: PropTypes.bool,
    onGoogleMapsApiLoaded: PropTypes.func.isRequired,
    visitedAirportsFinder: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    item: {},
    index: 0,
    itineraryType: '',
    visitedAirportsFinder: {},
    isGoogleMapsApiLoaded: true,
  }


  getItinerary = (
    index,
    leg,
    lastLeg,
    item,
    dates,
    orderID,
    flightIcon,
    visitedAirportsFinder,
    onGoogleMapsApiLoaded,
    isGoogleMapsApiLoaded,
    itineraryType,
    upcomingFirstMultiLeg,
  ) => {

    return (
      <ItineraryListingCard
        leg={leg}
        item={item}
        index={index}
        dates={dates}
        orderID={orderID}
        lastLeg={lastLeg}
        flightIcon={flightIcon}
        isItineraryDetail={false}
        itineraryType={itineraryType}
        aircraftsList={this.props.aircraftsList}
        handleItineraryTap={this.handleItineraryTap}
        visitedAirportsFinder={visitedAirportsFinder}
        onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
        isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
        upcomingFirstMultiLeg={upcomingFirstMultiLeg}
      />
    );
  };

  handleItineraryTap = (leg, item) => {
    const { itineraryType } = this.props;
    // const flightLegId = leg.flightLegExtIdentifier !== '' ? leg.flightLegExtIdentifier : leg.legId;
    const flightLegId = leg.legId !== '' ? leg.legId : leg.flightLegExtIdentifier;

    const url = ITINERARY_DETAILS_PAGE_URL.url.replace(
      '{key}',
      item.orderKey,
    ).replace(
      '{orderExtendifier}',
      item.orderIdentifier,
    ).replace(
      '{flightLegExtendifier}',
      flightLegId,
    ).replace(
      '{itineraryType}',
      itineraryType,
    );

    window.location = url;
  };

  hasOrderId = ({ orderKey, orderIdentifier }) => orderKey === 'orderId' && orderIdentifier;


  renderOrderId = item => {
    if (!this.hasOrderId(item)) return null;
    if (isNaN(item.orderIdentifier)) {
      return null;
    }
    return `Order ID ${item.orderIdentifier}`;
  };

  render() {
    const {
      item,
      index,
      visitedAirportsFinder,
      onGoogleMapsApiLoaded,
      isGoogleMapsApiLoaded,
      itineraryType,
    } = this.props;

    if (!item) return null;
    const firstLeg = item.legs[0];
    const lastLeg = item.legs[item.legs.length - 1];
    const isMultileg = item.legs.length > 1;

    let itinerary;
    let legs;

    const flightIcon = getItineryPathIcon(firstLeg, lastLeg, isMultileg);
    const dates = getItineraryLegDates(firstLeg, lastLeg, item);
    const orderID = this.renderOrderId(item);
    const listClass = !isMultileg ? '' : 'vistajet_multileg';

    if (isMultileg
      && index === FIRST_ITINERARY_INDEX && ITINERARY_TYPES.UPCOMING_ITINERARIES === itineraryType
    ) {
      const firstLegDate = getItineraryLegDates(firstLeg, {}, {});
      itinerary = this.getItinerary(
        index,
        firstLeg,
        firstLeg,
        item,
        firstLegDate,
        orderID,
        '',
        visitedAirportsFinder,
        onGoogleMapsApiLoaded,
        isGoogleMapsApiLoaded,
        itineraryType,
        true,
      );

      const filteredLegs = item.legs.filter(
        l => l.legStatus !== legStatus.CANCELLED,
      );
      legs = filteredLegs.slice(1).map((leg) => {
        const legDate = getItineraryLegDates(leg, {}, {});
        return <ItineraryListingCard
          leg={leg}
          dates={legDate}
          childLeg
          visitedAirportsFinder={visitedAirportsFinder}
          lastLeg={leg}
          aircraftsList={this.props.aircraftsList}
        />;
      });
    } else {
      itinerary = this.getItinerary(
        index,
        firstLeg,
        lastLeg,
        item,
        dates,
        orderID,
        flightIcon,
        visitedAirportsFinder,
        onGoogleMapsApiLoaded,
        isGoogleMapsApiLoaded,
        itineraryType,
        false
      );
    }
    return (
      <div className="uk-container">
        <div className={listClass}>
          {itinerary}
          {legs && legs}
        </div>
      </div>
    );
  }
}

export default ItineraryListItem;
