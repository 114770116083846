import axios from "axios";
import moment from 'moment';
import * as HttpStatus from "http-status-codes";
import {
  loginInit,
  loginSuccess,
  loginFailure,
  forgotInit,
  forgotSuccess,
  forgotFailure,
  logoutInit,
  logoutSuccess,
  logoutFail,
  changePasswordInit,
  changePasswordFailure,
  changePasswordSuccess,
  changePasswordRequired,
  resendForgotEmail,
  loginNotVerifiedSuccess,
  updateUserProfileDataInit,
  updateUserProfileDataSuccess,
  updateUserProfileDataFail,
  getAccountStatusAction,
  saveBillingAddressInit,
  saveBillingAddressFailure,
  saveBillingAddressSuccess,
  getAircraftsAction,
  getBillingAddressFailure,
  getBillingAddressSuccess,
  getBillingAddressInit,
} from "../actions";

import {
  CONTEXT,
  AUTHENTICATE_URL,
  APP_VERSION,
  FORGET_PASSWORD_URL,
  CHANGE_PASSWORD_URL,
  LOGOUT_URL,
  SAVE_BILLING_ADDRESS_URL,
  GET_BILLING_ADDRESS_URL,
  MANAGE_USER_PROFILE,
} from '../../configs/constants';

import tokenSource from './TokenSource';
import {
  handleServerErrors,
  encryptData,
  encodeData,
  parsePascalCaseString,
} from '../../utils/sourceUtils';
import { getDateWithoutTimeZone } from '../../utils/dateUtils';

import {
  HTTP_HEADERS,
  HTTP_HEADERS_JSON,
  HTTP_HEADERS_XML,
} from '../../constants/ApiConstants';
import { PASSPORT_UPDATE_MESSAGES, LOGIN_FAILED } from '../../constants/MessageConstants';
import { getFromLocal, saveToLocal, removeFromLocal, existInLocal } from '../../utils/cache';
import { USER_REDUCER } from "../../constants/localStorageKeys";
import {
  normalizePassports,
  normalizePersonalData,
  normalizeBillingAddressResponse,
  normalizePassenger,
  buildPassportRequest,
} from '../../normalizers/userNormalizer';
import { parseXmlStringToJson, builderXmlRequest } from '../../utils/parserUtils';
import status from '../../configs/status';
import { TIMEOUT_CODE, INTERNAL_SERVER_ERROR, BAD_REQUEST } from '../../constants/ServerCodeConstants';
import { removeOffersForFSP } from "../../utils/userUtils";

const DATE_FORMAT = 'DD MMM YYYY';

function handleError() { }

const authenticateLogin = (payload, token) => dispatch => {
  return new Promise((resolve, reject) => {
    const url = AUTHENTICATE_URL.replace("{context}", CONTEXT).replace(
      "{username}",
      payload.email
    );

    const dataEncripted = `${payload.email}\\+${payload.password}`;

    const key = encryptData(token, dataEncripted);

    const reqPayload = {
      credentials: token + key,
      attributes: {
        deviceInfo: "WEB",
        appBuildNr: APP_VERSION,
        phoneNumber: "",
        devicePushId: ""
      },
    };

    axios
      .put(url, reqPayload, HTTP_HEADERS_JSON)
      .then((res) => {
        const response = res.data;
        if (
          response &&
          response.responseHeader &&
          response.responseHeader.isError
        ) {
          // reject({ text: ERROR_MESSAGES.loginFailed });
          const errors = response.responseHeader.statusCode == -1 ? LOGIN_FAILED : handleServerErrors(response.responseHeader.statusCode);
          dispatch(loginFailure({ error: errors }));
          saveToLocal({ user: null, isLoggedIn: false }, 'user', true, true);
          reject(errors);
        } else {
          const authenticatedUser =
            response.responseBody && response.responseBody.authenticatedUser;
          authenticatedUser.email = payload.username;
          // authenticatedUser.token = payload.token;

          const { passport, isChangedPassword } = authenticatedUser;

          let passportDataNormalized = [];
          const isChangePasswordRequired = (isChangedPassword || response.mustChangePassword);
          passportDataNormalized = normalizePassports(passport);
          if (isChangedPassword || response.mustChangePassword) {
            dispatch(changePasswordRequired());
          }
          const personalData = normalizePersonalData(
            authenticatedUser,
            passportDataNormalized,
          );
          if (personalData.userVerificationStatusCode === 2
            || personalData.userVerificationStatusCode === 4) {
            personalData.userNotVerified = true;
            personalData.user_email = payload.email;
            setTimeout(() => {
              dispatch(loginNotVerifiedSuccess(personalData));
            }, 100);
            personalData.externalAppUserId = authenticatedUser.id;
            saveToLocal({
              user: personalData,
              userNotVerified: true,
              isLoggingIn: false,
              isLoggedIn: false,
              changePasswordRequired: isChangePasswordRequired,
            }, 'user', true, true);
            resolve(personalData);
          } else {
            personalData.isUserVerifiedAfterLogin = true;
            saveToLocal({
              user: personalData,
              isLoggedIn: true,
              isLoggingIn: false,
              userNotVerified: false,
              changePasswordRequired: isChangePasswordRequired,
            }, 'user', true, true);
            removeOffersForFSP(personalData);
            dispatch(loginSuccess(personalData));
            dispatch(getAccountStatusAction(token));
            dispatch(getAircraftsAction())
            resolve(personalData);
          }
        }
      })
      .catch((err) => {
        const errors = err.response && err.response.status == -1 ? LOGIN_FAILED : handleServerErrors(
          err.response ? err.response.status : err.response,
        );
        dispatch(loginFailure({ error: errors }));
        saveToLocal({
          user: null,
          isLoggedIn: false,
          isLoggingIn: false,
        }, 'user', true, true);
        reject(errors);
      });
  });
};

export const login = payload => dispatch => {
  dispatch(loginInit());
  dispatch(tokenSource(payload.email, CONTEXT)).then(token => {
    dispatch(authenticateLogin(payload, token.token)).then(() => { },
      handleError);
  });
};

export const forgotPassword = payload => dispatch => {
  dispatch(forgotInit());
  const url = FORGET_PASSWORD_URL.replace("{context}", CONTEXT).replace(
    '{email}',
    payload.email,
  );

  const reqPayload = `<resetPassword><username>${
    payload.email
    }</username></resetPassword>`;
  if (payload.isResending) {
    dispatch(resendForgotEmail());
  }

  return axios
    .post(url, reqPayload, {
      headers: HTTP_HEADERS,
    })
    .then(() => {
      dispatch(forgotSuccess(true));
    })
    .catch(err => {
      const errJson = parseXmlStringToJson(err.response.data);
      dispatch(forgotFailure(errJson));
    });
};

export const logout = token => dispatch => {
  return new Promise((resolve, reject) => {
    const url = LOGOUT_URL.replace("{token}", token);
    dispatch(logoutInit());
    axios
      .get(url, HTTP_HEADERS)
      .then(response => {
        if (response.status === status.OK) {
          const jsonData = parseXmlStringToJson(response.data);
          if (jsonData.vjResponse.success === true) {
            setTimeout(function () {
              dispatch(logoutSuccess());
            }, 200)
          }
          // saveToLocal({ user: null, isLoggedIn: false }, 'user');
          removeFromLocal("user");
          removeFromLocal("token");
          resolve(jsonData.vjResponse.success);
        } else {
          const errors = handleServerErrors(response.status);
          dispatch(logoutFail(errors));
          saveToLocal({ isLoggedIn: true }, 'user', true, true);
          reject(errors);
        }
      })
      .catch(err => {
        const errors = handleServerErrors(
          err.response ? err.response.status : err.response,
        );
        dispatch(logoutFail(errors));
        saveToLocal({ isLoggedIn: true }, 'user', true, true);
        reject(errors);
      });
  });
};

const resetPassword = (payload, token) => dispatch => {
  return new Promise((resolve, reject) => {
    const url = CHANGE_PASSWORD_URL.replace("{context}", CONTEXT).replace(
      "{username}",
      payload.username,
    );

    const dataEncripted = `${payload.username}\\+${payload.oldPassword}`;

    const key = encryptData(token, dataEncripted);
    const newPassword = encryptData(token, payload.newPassword);
    const reqPayload = `<vjRequest><credentials>${token +
      key}</credentials><newPassword>${newPassword}</newPassword></vjRequest>`;
    axios
      .put(url, reqPayload, {
        headers: HTTP_HEADERS,
      })
      .then(res => {
        let user = null;
        if (existInLocal('user')) {
          user = getFromLocal('user', true, true);
        }
        if (res.status === HttpStatus.OK) {
          const jsonResponse = parseXmlStringToJson(res.data);
          if (!jsonResponse.vjResponse.success) {
            dispatch(changePasswordFailure({
              error: jsonResponse,
              isProfile: payload.isProfile || false,
            }));
          } else {
            const newUserObject = Object.assign({}, user, {
              changePasswordRequired: false,
            });
            saveToLocal(newUserObject, 'user', true, true);
            dispatch(changePasswordSuccess({
              isProfile: payload.isProfile || false,
            }));
          }
        }
        resolve();
      })
      .catch(err => {
        if (err.response) {
          const jsonResponse = parseXmlStringToJson(err.response.data);
          if (jsonResponse && jsonResponse.error && jsonResponse.error.message) {
            dispatch(changePasswordFailure({
              error: 'Old Password Is Invalid', // 1009 Old password does not match
              isProfile: payload.isProfile || false,
            }));
            reject(jsonResponse.error.message);
          }
        }
        const errors = handleServerErrors(
          err.response ? err.response.status : err.response,
        );
        dispatch(changePasswordFailure({
          error: errors,
          isProfile: payload.isProfile || false,
        }));
        reject(errors);
      });
  });
};

export const changePassword = payload => dispatch => {
  dispatch(changePasswordInit());
  dispatch(resetPassword(payload, payload.userToken)).then(() => { },
    handleError);
};

export const updateUserProfileData = (
  payload,
  user,
  passports,
  flightLegId = -1,
  paxOperationId = 0,
) => dispatch => {
  return new Promise((resolve, reject) => {
    if (user.personId && user.userToken) {
      dispatch(updateUserProfileDataInit());
      // Success Message
      const successMessage = PASSPORT_UPDATE_MESSAGES[paxOperationId];
      // params
      const params = encodeData({ flightLegId });
      const url = MANAGE_USER_PROFILE.replace('{CONTEXT}', CONTEXT)
        .replace('{token}', user.userToken)
        .replace('{params}', params);

      const updatedPassengersList = {
        passengerId: user.personId,
      };

      const {
        firstName,
        lastName,
        dateOfBirth,
        phoneNumber,
        email,
      } = payload;

      // details block
      if (firstName) updatedPassengersList.passengerFirstName = firstName;
      if (lastName) updatedPassengersList.passengerLastName = lastName;
      if (email) updatedPassengersList.passengerEmail = email;
      if (dateOfBirth && dateOfBirth.length !== 0) {
        const mDateOfBirth = dateOfBirth instanceof moment ?
          dateOfBirth : moment(dateOfBirth, DATE_FORMAT);
        updatedPassengersList.passengerDob = getDateWithoutTimeZone(mDateOfBirth);
      }

      updatedPassengersList.changedPhoneNumbers = {
        changedPhoneNumber: {
          oldPhoneNumber: user.phoneNumber,
          newPhoneNumber: phoneNumber,
        },
      };

      // passport block
      if (passports && passports.length) {
        updatedPassengersList.passports = {
          passport: passports.map(buildPassportRequest),
        };
      }


      // build xml payload
      const reqPayload = builderXmlRequest({
        passengerManageRequest: {
          passengers: {
            updated: {
              updatedPassengersList,
            },
          },
        },
      });

      axios
        .post(url, reqPayload, {
          headers: HTTP_HEADERS_XML,
        })
        .then((res) => {
          let user = null;
          if (existInLocal('user')) {
            user = getFromLocal('user', true, true);
          }
          if (res && res.status === 400) {
            parsePascalCaseString(res.data, (parseErr, result) => {
              if (result && result.error && result.error.code === 1009) {
                const errors = handleServerErrors(result.error.code);
                dispatch(updateUserProfileDataFail({
                  errorCode: result.error.code,
                  error: errors,
                }));
                reject(errors);
              } else {
                const errors = handleServerErrors(400);
                dispatch(updateUserProfileDataFail({ error: errors }));
                reject(errors);
              }
            });
          } else {
            parsePascalCaseString(res.data, (parseErr, { passenger }) => {
              if (parseErr) {
                const errors = handleServerErrors(400);
                dispatch(updateUserProfileDataFail({ error: errors }));
                reject(errors);
              } else {
                const response = normalizePassenger(passenger, email);
                const passport = {
                  passports: response.passports,
                };
                const userObject = Object.assign({}, user.user, response.user, passport, {
                  personPhoneNumber: response.phoneNumbers,
                  phoneNumber: response.phoneNumbers[0].phoneNumber,
                });
                user.user = userObject;
                saveToLocal(user, 'user', true, true);
                dispatch(updateUserProfileDataSuccess({
                  response,
                  message: successMessage,
                }));
                resolve(response);
              }
            });
          }
        })
        .catch(err => {
          if (err.response.status === 400) {
            // case of email or phone number already exists
            const res = parseXmlStringToJson(err.response.data);
            const errors = handleServerErrors(res.error.code);
            dispatch(updateUserProfileDataFail({
              error: errors,
            }));
            reject(errors);
          } else {
            const errors = handleServerErrors(
              err.response ? err.response.status : err.response,
            );
            dispatch(updateUserProfileDataFail({ error: errors }));
            reject(errors);
          }
        });
    } else {
      const errors = handleServerErrors(400);
      dispatch(updateUserProfileDataFail({ error: errors }));
      reject(errors);
    }
  });
};
export const saveBillingAddress = (address) => (dispatch) => {
  dispatch(saveBillingAddressInit());
  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;
  const url = SAVE_BILLING_ADDRESS_URL.replace('{token}', token);

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: TIMEOUT_CODE.time,
  };

  axios
    .post(url, address, options)
    .then((response) => {
      if (response.status === status.OK) {
        if (response && response.responseHeaderDto && response.responseHeaderDto.isError) {
          dispatch(saveBillingAddressFailure(response.responseHeaderDto.message));
        }
        else {
          dispatch(saveBillingAddressSuccess(normalizeBillingAddressResponse([address])));
          dispatch(getAccountStatusAction(token));
        }
      }
      else {
        dispatch(saveBillingAddressFailure(handleServerErrors(INTERNAL_SERVER_ERROR)));
      }

    })
    .catch((err) => {
      if (TIMEOUT_CODE.status === err.code) {
        dispatch(saveBillingAddressFailure(handleServerErrors(TIMEOUT_CODE.code)))
      } else if (!err.status) {
        dispatch(saveBillingAddressFailure(handleServerErrors()));
      } else {
        dispatch(saveBillingAddressFailure(handleServerErrors(+err.status)));
      }
    })
}

const getBillingAddressPromiseInprogress = {};
export const getBillingAddressSource = () => (dispatch) => {
  const uniqueKey = 'getBillingAddress';
  if (getBillingAddressPromiseInprogress[uniqueKey]) {
    return getBillingAddressPromiseInprogress[uniqueKey];
  }
  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;

  const getBillingAddressPromise = new Promise((resolve, reject) => {
    const url = GET_BILLING_ADDRESS_URL.replace('{token}', token);
    dispatch(getBillingAddressInit());
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          const responseHeader = response.data.responseHeaderDto;
          if (responseHeader.isError === true) {
            dispatch(getBillingAddressFailure(handleServerErrors(responseHeader.statusCode)));
            reject(handleServerErrors(BAD_REQUEST));
          } else {
            const responseData = response.data.companyAddressDtos;
            const normalizedResponse = normalizeBillingAddressResponse(responseData);
            dispatch(getBillingAddressSuccess(normalizedResponse));
            resolve(normalizedResponse);
          }
        } else {
          dispatch(getBillingAddressFailure(handleServerErrors(BAD_REQUEST)));
          reject(handleServerErrors(BAD_REQUEST));
        }
        delete getBillingAddressPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        if (!err.status) {
          dispatch(getBillingAddressFailure(handleServerErrors()));
          reject(handleServerErrors());
        } else {
          dispatch(getBillingAddressFailure(handleServerErrors(BAD_REQUEST)));
          reject(handleServerErrors(BAD_REQUEST));
        }
        delete getBillingAddressPromiseInprogress[uniqueKey];
      });
  });
  getBillingAddressPromiseInprogress[uniqueKey] = getBillingAddressPromise;
  return getBillingAddressPromise;
};
