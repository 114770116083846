import { getAircraftByAllFields } from '../utils/aircraftUtils';
import Helpers from '../utils/Helpers';
import {
  garanteeArray,
} from '../utils/sourceUtils';
import {
  existInLocal,
  saveToLocal,
  getFromLocal,
} from '../utils/cache';
import { comparePassengers, getGender, mergePassenger } from '../utils/passengerUtils';
import { mergePassportbyPax } from '../utils/passportUtils';
import { getUser } from '../utils/userUtils';
import { clone } from '../utils/Helpers';

const emailAddressesNormalizer = (email) => (
  {
    email: email.emailAddress || email.email || '',
    id: email.id || '',
  }
);

const phoneNumbersNormalizer = (phone) => (
  {
    id: phone.id || '',
    phoneNumber: phone.phoneNumber || '',
  }
);
const passportFilesNormalizer = (file) => (
  {
    fileLocation: file.fileLocation || '',
    fileName: file.fileName || '',
    fileUrl: file.fileUrl || '',
    id: file.id || '',
    isActive: file.isActive || false,
  }
);
const passportsNormalizer = (passport) => (
  {
    displayName: passport.displayName || '',
    expiresOnDate: passport.expiry || passport.expiresOnDate || '',
    expiry: passport.expiry || passport.expiresOnDate || '',
    isActive: passport.isActive || false,
    isAdded: passport.isAdded || false,
    isDefault: passport.isDefault || false,
    isInvalid: passport.isInvalid || 0,
    isOnHands: passport.isOnHands || false,
    isUpdated: passport.isUpdated || false,
    isDeleted: passport.isDeleted || false,
    isoPassportCountry: passport.isoPassportCountry || '',
    isoPassportIssuedByCountry:
      passport.isoPassportIssuedByCountry || passport.isoIssuedByCountry || '',
    issuedOnDate: passport.issuedOnDate || '',
    passportCountry: passport.passportCountry || '',
    passportFiles: garanteeArray(passport.passportFiles || []).map(passportFilesNormalizer),
    passportId: passport.passportId || passport.id || '',
    imageUrl: passport.imageUrl || '',
    passportIssuedByCountry: passport.passportIssuedByCountry || passport.issuedByCountry || '',
    passportNumber: (passport.passportNumber || '').toString(),
    visa: passport.visa || '',
  }
);

export const currentPaxNormalizer = (passenger) => {
  const email = passenger.email || passenger.username || '';
  let emailAddresses = garanteeArray(passenger.emailAddresses || []).map(emailAddressesNormalizer);
  if (!emailAddresses.length) {
    emailAddresses = garanteeArray({ email, id: '' });
  }
  return ({
    isApproved: passenger.isApproved,
    dateOfBirth: passenger.dateOfBirth || '',
    email,
    emailAddresses,
    firstName: passenger.firstName || passenger.passengerFirstName || '',
    genderId: passenger.genderId || '',
    gender: getGender(passenger.genderId || ''),
    lastName: passenger.lastName || passenger.passengerLastName || '',
    passengerExtIdentifier: passenger.passengerExtIdentifier || '',
    passengerId: passenger.passengerId || passenger.personId || '',
    passengerNationality: passenger.passengerNationality || '',
    passports: garanteeArray(passenger.passports || []).map(passportsNormalizer),
    phoneNumbers: garanteeArray(passenger.personPhoneNumbers || []).map(phoneNumbersNormalizer),
    profilePicture: passenger.profilePicture || (passenger.imageURL && passenger.imageURL.length &&
      passenger.imageURL[0]) ||
      passenger.passengerPictureUrl || '',
    truncatedName:
      Helpers.truncateStr(`${passenger.firstName
        || passenger.passengerFirstName} ${passenger.lastName
        || passenger.passengerLastName}`, 35).toLowerCase(),
  });
};

const legPassportsNormalizer = (passport) => (
  {
    ...passportsNormalizer(passport),
    passportFiles: garanteeArray(passport.passportFileDtos || []).map(passportFilesNormalizer),
    isLegPassport: passport.isLegPassport || false,
  }
);

const sourcePaxNormalizer = (passenger) => {
  const email = passenger.email || passenger.username || '';
  let emailAddresses = garanteeArray(passenger.emailAddresses || []).map(emailAddressesNormalizer);
  if (!emailAddresses.length) {
    emailAddresses = garanteeArray({ email, id: '' });
  }
  return ({
    ...currentPaxNormalizer(passenger),
    email,
    emailAddresses,
    genderId: passenger.genderId || passenger.gender || '',
    gender: getGender(passenger.genderId || passenger.gender || ''),
    phoneNumbers: garanteeArray(passenger.phoneNumbers || []).map(phoneNumbersNormalizer),
  });
};

export const frequentPaxNormalizer = (passengers) => {
  const cached = existInLocal('frequentPax') ?
    garanteeArray(getFromLocal('frequentPax', true)) : [];
  const cachedFrequentPax =
  garanteeArray(cached || []).map((passenger) => (
    sourcePaxNormalizer(passenger)
  ));
  const frequentPax = garanteeArray(passengers || []).map((passenger) => (
    sourcePaxNormalizer(passenger)
  ));
  const currentUser = clone(getUser());
  currentUser.passports = Object.values(currentUser.passports.content);
  const currentUserPax = currentPaxNormalizer(currentUser);
  if (!cachedFrequentPax.find(pax => (comparePassengers(pax, currentUserPax)))) {
    cachedFrequentPax.push(currentUserPax);
  }
  const mergedPax = mergePassportbyPax(frequentPax, cachedFrequentPax);
  const currentInMerged = mergedPax.find(pax => comparePassengers(pax, currentUserPax));
  mergePassenger(currentInMerged, currentUserPax);
  saveToLocal(mergedPax, 'frequentPax', true);
  return mergedPax;
};

export const legPaxNormalizer = (passengers) => (
  garanteeArray(passengers || []).map((passenger) => (
    {
      ...currentPaxNormalizer(passenger),
      passports: garanteeArray(passenger.passports || []).map(legPassportsNormalizer),
      leadPassenger: passenger.leadPassenger || false,
      isInformationUpdated: passenger.isInformationUpdateds || false,
      phoneNumbers: garanteeArray(passenger.phoneNumber || []).map((phoneNumber) => ({
        phoneNumber,
        id: '',
      })) || '',
    }
  ))
);

export const searchPaxNormalizer = (passengers) => (
  garanteeArray(passengers || []).map((passenger) => (
    {
      ...sourcePaxNormalizer(passenger),
      isSearched: true,
    }
  ))
);

export const normalizePassengerUtil = (passengers, routeParams, leg) => {
  const maxPax = parseInt(getAircraftByAllFields((leg.aircraft || {}).name || leg.aircraftTypeName)
    .maxNumberOfPassengers, 10);
  return ({
    passengers: passengers ? legPaxNormalizer(passengers) : [],
    legId: (leg.legId || ''),
    flightLegExtIdentifier: (leg.flightLegExtIdentifier || ''),
    numberOfPassenger: leg.passengerCount || leg.numberOfPassenger,
    maxNumberOfPassengers: maxPax,
    isEditable: Helpers.isEditableLeg(leg),
    key: routeParams.key,
    uniqueIdentifier: routeParams.uniqueIdentifier,
    deptAirportTimeUTC: leg.departureAirportTimeLocal,
  });
};
