import fetchCrewSource from '../sources/CrewSource';
import {
  FETCH_CREW_INIT,
  FETCH_CREW_SUCCESS,
  FETCH_CREW_FAIL,
} from '../actionTypes';

export const fetchCrewInit = (flightLegId) => ({
  type: FETCH_CREW_INIT,
  flightLegId,
});

export const fetchCrewSuccess = (data, flightLegId) => ({
  type: FETCH_CREW_SUCCESS,
  data,
  flightLegId,
});

export const fetchCrewFail = (data, flightLegId) => ({
  type: FETCH_CREW_FAIL,
  data,
  flightLegId,
});

export const fetchCrew = (token, flightLegId) => fetchCrewSource(token, flightLegId);
