
import {
  TOP_UP_PAYMENT_METHOD,
  TOP_UP_STATUS_TYPE,
  MEMBERSHIP_TYPE,
} from 'constants/topUpConstants';
import { garanteeArray } from '../utils/sourceUtils';

export const createTopUpNormalizer = response => {
  if (response && Object.keys(response).length > 0) {
    return ({
      swift: response.swift,
      bankName: response.bankName,
      currency: response.currency,
      topUpAmount: parseInt(response.transferAmount, 10),
      to: response.to,
      iban: response.iban,
      referenceCode: response.referenceCode,
      topUpId: response.topUpId,
      topUpStatusDesc: TOP_UP_STATUS_TYPE[1],
      paymentMethod: TOP_UP_PAYMENT_METHOD,
    });
  }

  return undefined;
};

export const topUpHistoryNormalizer = (response = { topUps: { topUp: {} } }) => {
  const topUps = garanteeArray(response.topUps.topUp).reduce((curr, item) => {
    const current = curr;
    const topUp = topUpNormalizer(item);
    if (topUp) {
      current.content[topUp.topUpId] = topUp;
      current.ids.push(topUp.topUpId);
    }
    return current;
  }, { content: {}, ids: [] });
  const currentBalance = response.currentBalance ?
    parseInt(response.currentBalance, 10) : 0;
  return {
    membershipAccount: {
      membershipSubType: MEMBERSHIP_TYPE[response.memberShipSubType],
      currentBalance,
      currency: response.currency,
    },
    topUps,
  };
};


export const topUpNormalizer = topUp => {
  if (topUp && Object.keys(topUp).length > 0) {
    return ({
      bankName: topUp.bankName,
      currency: topUp.currency,
      swift: topUp.swift,
      iban: topUp.iban,
      membershipTypeId: topUp.membershipTypeId,
      paymentMethod: TOP_UP_PAYMENT_METHOD,
      referenceCode: topUp.referenceCode,
      to: topUp.to,
      topUpAmount: parseInt(topUp.topUpAmount, 10),
      topUpDate: topUp.topUpDate,
      topUpId: topUp.topUpId,
      topUpStatusDesc: TOP_UP_STATUS_TYPE[topUp.topUpStatusId],
      topUpStatusId: topUp.topUpStatusId,
    });
  }
  return undefined;
};
