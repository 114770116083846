export function isArray(obj) {
  return obj.constructor === Array;
}

const onlyUnique = (value, index, self) => (
  self.indexOf(value) === index
);

export const filterUnique = (array) => (
  array.filter(onlyUnique)
);

export function guaranteeArray(obj) {
  if (obj) {
    return isArray(obj) ? obj : [obj];
  }
  return [];
}

export const isObject = obj => obj && obj.constructor === Object;

/**
 * Returns bool that tells if the array/object is empty
 * @param {Array, Object} obj
 * @returns {bool}
 * handle special cases:
 * no argument, null, string
 **/
export const isEmpty = obj => {
  // null and undefined are "empty"

  if (obj) {
    if (isObject(obj)) {
      return !Object.keys(obj).length;
    } else if (obj.constructor === Array) {
      return !obj.length;
    }
  }
  return true;
};

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = property => {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function(a, b) {
    if(a[property] !== undefined || a[property] !== undefined)
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
};
