import React from 'react';

export default function CateringFooter(props) {
  return (
    <div className="vistajet_modal_footer fix">
      <button
        className="uk-button uk-button-default uk-width-1-1 uk-text-center"
        type="button"
        disabled={!!props.disabled}
        onClick={props.onClick}>Confirm</button>
    </div>
  );
}
