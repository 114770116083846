import * as types from '../../actionTypes';

const initialState = {
	itineraryCancellationIsLoading: false,
	itineraryCancellationMessage: '',
};

export default function launchReducer(state = initialState, action) {
	switch (action.type) {
		case types.FLIGHT_CANCEL_INIT: {
			return Object.assign({}, state, {
				itineraryCancellationIsLoading: true
			});
		}
		case types.FLIGHT_CANCEL_SUCCESS: {
			return Object.assign({}, state, {
				itineraryCancellationMessage: action.data,
				itineraryCancellationIsLoading: false
			});
		}

		case types.FLIGHT_CANCEL_AFTER_SUCCESS: {
			return Object.assign({}, state, {
				itineraryCancellationMessage: '',
			});
		}

		case types.FLIGHT_CANCEL_FAILURE: {
			return Object.assign({}, state, {
				itineraryCancellationMessage: action.data,
				itineraryCancellationIsLoading: false
			});
		}

		default:
			return state;
	}
}
