import React, { Component } from "react";
import ReactStars from "../shared/RatingsComponent/RatingComponent";
import { isMobile } from "react-device-detect";

const CancelComment = "/legacy/static/img/icons/ic_cancel_grey.svg";
const ShowComment = "/legacy/static/img/icons/vistajet_comment_icon.svg";

class CustomerFeedbackOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      showCommentBox: false,
      description: ""
    };
  }
  renderCommentBox = () => {
    this.setState({
      showCommentBox: true
    });
  };
  onCrossClick = () => {
    this.setState({ description: "" });
    this.nameInput.focus();
    this.props.onrating({
      description: "",
      rating: this.state.rating,
      category: this.props.category
    });
  };
  onChangeMsg = event => {
    this.setState({ description: event.target.value });
    this.props.onrating({
      description: event.target.value,
      rating: this.state.rating,
      category: this.props.category
    });
  };
  handleRating = rating => {
    this.setState({ rating });
    this.props.onrating({
      rating,
      description: this.state.description,
      category: this.props.category
    });
  };
  render() {
    const { ratingFor, isOptional } = this.props;
    const { showCommentBox } = this.state;
    return (
      <div data-uk-grid className="uk-grid uk-grid-collapse uk-margin">
        <div className="uk-width-1-1">
          <div data-uk-grid className="uk-grid">
            <div className="uk-width-1-3@s uk-text-right@s uk-padding-remove vistajet_rating_label_wrapper">
              <p className="vistajet_rating_label">{ratingFor}</p>
              {isOptional && (
                <p className="vistajet_rating_optional">Optional</p>
              )}
            </div>
            <div className="uk-width-expand@s uk-margin-remove uk-padding-remove vistajet_rating_stars_with_comment_box">
              <div className="vistajet_stars_rating_container">
                <ReactStars
                  className="rating-row-style"
                  count={5}
                  value={this.state.rating}
                  onChange={this.handleRating}
                  size={isMobile ? 30 : 32}
                  color1={"#eeeeee"}
                  color2={"#ffd700"}
                  half={false}
                  showStatus={true}
                />
              </div>

              <div className="vistajet_primary_form  vistajet_feedback_form vistajet_comment_box_flex uk-margin-small-top">
                {showCommentBox ? (
                  <div className="vistajet_input_with_icon uk-margin-remove">
                    <div className="vistajet_input_wrapper">
                      <input
                        type="text"
                        value={this.state.description}
                        onChange={this.onChangeMsg}
                        placeholder="Please tell us more..."
                        ref={input => {
                          this.nameInput = input;
                        }}
                        autoFocus
                        className="vistajet_passenger_search_input"
                      />
                      {this.state.description !== "" && (
                        <span
                          className="vistajet_search_close_icon"
                          onClick={this.onCrossClick}
                        >
                          <img src={CancelComment} alt="" />
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <p
                    className="vistajet_rating_comment_text"
                    onClick={this.renderCommentBox}
                  >
                    <img src={ShowComment} />
                    <span>Want to tell us about anything?</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CustomerFeedbackOptions;
