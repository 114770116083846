import axios from "axios";
import { parseXmlStringToJson, jsonToXml } from "utils/parserUtils";
import {
  TopUpReferenceInit,
  TopUpReferenceSuccess,
  TopUpReferenceFail,
  TopUpInit,
  TopUpSuccess,
  TopUpFail,
  TopUpHistoryInit,
  TopUpHistorySuccess,
  TopUpHistoryFail,
  cancelReferenceCodeInit,
  cancelReferenceCodeSuccess,
  cancelReferenceCodeFail,
} from "store/actions/TopUpAccountActions";

import { 
  TOPUP_REFERENCE,
  TOPUP_SUBMITTED,
  TOPUP_HISTORY,
  TOPUP_CANCEL_REFERENCE
} from "configs/constants";

import status from "configs/status";
import { handleServerErrors } from "utils/sourceUtils";
import {
  INVALID_DETAILS_EXCEPTION,
  SOMETHING_UNEXPECTED,
} from "../../constants/MessageConstants";
import {
  INVALID_DETAILS_CODE
} from "../../constants/ServerCodeConstants";
import { BAD_REQUEST, TIMEOUT_CODE, UNAUTHORIZED } from "constants/ServerCodeConstants";
import { createTopUpNormalizer, topUpHistoryNormalizer } from "normalizers/TopUpNormalizer";

export const topUpReferenceSource = (token, accountId, transferAmount, currency) => dispatch => {
  dispatch(TopUpReferenceInit());
  const options = {
    headers: {
      "Content-Type": "application/xml"
    },
    timeout: TIMEOUT_CODE.time
  };
  const requestPayload = jsonToXml({
    topUpReferenceRequestDto: {
      transferAmount,
      currency,
      accountId,
    }
  });

  axios
    .post(
      TOPUP_REFERENCE.replace("{token}", token),
      requestPayload,
      options
    )
    .then(response => {
      if (response.status === status.OK) {
        const parsedData = parseXmlStringToJson(response.data);
        const responseHeader =
          parsedData.topUpReferenceResponseDto.responseHeader;
        if (responseHeader.isError) {
          const error = responseHeader.statusCode;
          if (error === INVALID_DETAILS_CODE.code &&
            responseHeader.message === INVALID_DETAILS_EXCEPTION) {
            dispatch(TopUpReferenceFail(SOMETHING_UNEXPECTED));
          } else {
            dispatch(TopUpReferenceFail(handleServerErrors(error)));
          }
        } else {
          const responseBody =
            parsedData.topUpReferenceResponseDto;
          dispatch(TopUpReferenceSuccess(createTopUpNormalizer(responseBody)));
        } 
      } else {
        dispatch(TopUpReferenceFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(TopUpReferenceFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(TopUpReferenceFail(handleServerErrors(UNAUTHORIZED)));
      }
      else dispatch(TopUpReferenceFail(handleServerErrors(BAD_REQUEST)));
    });
};

// Top_up Submitted
export const topUpSource = (token, topUpId) => dispatch => {
  dispatch(TopUpInit());
  const options = {
    headers: {
      "Content-Type": "application/xml"
    },
    timeout: TIMEOUT_CODE.time
  };
  const requestPayload = jsonToXml({
    topUpSubmitRequestDto: {
      topUpId,
    }
  });

  axios
    .post(
      TOPUP_SUBMITTED.replace("{token}", token),
      requestPayload,
      options
    )
    .then(response => {
      if (response.status === status.OK) {
        const parsedData = parseXmlStringToJson(response.data);
        const responseHeader =
          parsedData.topUpSubmitResponseDto.responseHeader;
        if (responseHeader.isError) {
          const error = responseHeader.statusCode;
          dispatch(TopUpFail(handleServerErrors(error)));
        } else {
          const data = 1;
          dispatch(TopUpSuccess(data));
        }
      } else {
        dispatch(TopUpFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(TopUpFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(TopUpFail(handleServerErrors(UNAUTHORIZED)));
      }
      else dispatch(TopUpFail(handleServerErrors(BAD_REQUEST)));
    });
};
// topUpHistorySource
export const topUpHistorySource = (token, accountId) => dispatch => {
  dispatch(TopUpHistoryInit());

  const options = {
    headers: {
      "Content-Type": "application/xml"
    },
    timeout: TIMEOUT_CODE.time
  };
  const requestPayload = jsonToXml({
    topUpHistoryRequestDto: {
      accountId,
    }
  });

  axios
    .post(
      TOPUP_HISTORY.replace("{token}", token),
      requestPayload,
      options
    )
    .then(response => {
      if (response.status === status.OK) {
        const parsedData = parseXmlStringToJson(response.data);
        const responseHeader =
          parsedData.topUpHistoryResponseDto.responseHeader;
        if (responseHeader.isError) {
          const error = responseHeader.statusCode;
          dispatch(TopUpHistoryFail(handleServerErrors(error)));
        } else {
          const data = parsedData.topUpHistoryResponseDto;
          dispatch(TopUpHistorySuccess(topUpHistoryNormalizer(data)));
        } 
      } else {
        dispatch(TopUpHistoryFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(TopUpHistoryFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(TopUpHistoryFail(handleServerErrors(UNAUTHORIZED)));
      }
      else dispatch(TopUpHistoryFail(handleServerErrors(BAD_REQUEST)));
    });
};

//TopUpCancelReferenceCodeSource
export const topUpCancelReferenceCodeSource = (token, topUpId) => dispatch => {
  dispatch(cancelReferenceCodeInit());

  const options = {
    headers: {
      "Content-Type": "application/xml"
    },
    timeout: TIMEOUT_CODE.time
  };
  const requestPayload = jsonToXml({
    topUpCancelRequestDto: {
      topUpId,
    }
  });

  axios
    .post(
      TOPUP_CANCEL_REFERENCE.replace("{token}", token),
      requestPayload,
      options
    )
    .then(response => {
      if (response.status === status.OK) {
        const parsedData = parseXmlStringToJson(response.data);
        const responseHeader =
          parsedData.topUpCancelResponseDto.responseHeader;
        if (responseHeader.isError) {
          const error = responseHeader.statusCode;
          dispatch(cancelReferenceCodeFail(handleServerErrors(error)));
        } else {
          const data = 2;
          dispatch(cancelReferenceCodeSuccess(data));
        }
      } else {
        dispatch(cancelReferenceCodeFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(cancelReferenceCodeFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(cancelReferenceCodeFail(handleServerErrors(UNAUTHORIZED)));
      }
      else dispatch(cancelReferenceCodeFail(handleServerErrors(BAD_REQUEST)));
    });
};
