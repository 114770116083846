/* eslint-disable */
import setResponse from '../errorUtils';
import { errorMessages } from '../../constants/ErrorMessageConstants';

export default function validatePhoneNumber(phoneNumber, isPhoneNumberValid) {
  let res = setResponse();
  if (!phoneNumber) {
    return res = setResponse(errorMessages.PHONE_REQUIRED);
  } else if (!isPhoneNumberValid) {
    return res = setResponse(errorMessages.INVALID_PHONE);
  }
  return res;
};
