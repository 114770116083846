import {animateScroll as scroll} from 'react-scroll';

export function isMSBrowser(userAgent = global.navigator.userAgent) {
  return !!userAgent.match(/Trident/g) || !!userAgent.match(/MSIE/g) || !!userAgent.match(/Edge/g);
}

export function isURLActive(pathname) {
  return window.location.hash.includes(pathname);
}

// Refernce Link:
// http://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser

// Opera 8.0+
export function isOpera() {
  return !!window.opr || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
}

// Firefox 1.0+
export function isFirefox() {
  return typeof InstallTrigger !== 'undefined';
}

// Internet Explorer 6-11
export function isIE() {
  return !!document.documentMode;
}

export function isEdge() {
  return window.navigator.userAgent.indexOf('Edge') > -1;
}

// Chrome 1+
export function isChrome() {
  return !!window.chrome && !!window.chrome.webstore;
}

// Blink engine detection
export function isBlink() {
  return (isChrome() || isOpera()) && !!window.CSS;
}
// Safari
export function isSafari() {
  return (navigator.userAgent.indexOf('Safari') > 0
    && navigator.userAgent.indexOf('Chrome') === -1);
}

// export const scrollToSection = () => {
//   window.scroll({
//     top: 0,
//     left: 0,
//     behavior: 'smooth'
//   });
// }


const SVS_B = (eAmt, where) => {
  if (where === "center" || where == "")
    window.scrollBy(0, eAmt / 2);
  if (where === "top")
    window.scrollBy(0, eAmt);
}

export const scrollToSection = (time, where) => {
  const e = document.getElementById('spa-header-container');
  var eTop = e.getBoundingClientRect().top;
  var eAmt = eTop / 100;
  var curTime = 0;
  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt, where);
    curTime += time / 100;
  }
}

export const scrollToTop = () => {
  scroll.scrollToTop();
}

