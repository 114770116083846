const dirtyStatefn = () => {
  let dirtystate = {};
  return {
    getDirty: key => dirtystate[key],
    setDirty: (key, value) => (dirtystate[key] = value),
    getAllDirty: () => dirtystate,
    setAllDirty: () => {
      for (let key in dirtystate) {
        dirtystate[key] = false;
      }
    },
    isDirtyTrue: () => {
      for (let key in dirtystate) {
        if (dirtystate[key]) {
          return true;
        }
      }
      return false;
    }
  };
};
const dirtystateSingleTon = dirtyStatefn();
export const onBeforeUnload = () => {
  if (dirtystateSingleTon.isDirtyTrue()) {
    return true;
  }
  return null;
};
export const blockRoute = () => {
  window.onbeforeunload = onBeforeUnload;
};
export default dirtystateSingleTon;
