import * as types from '../actionTypes';

const initialState = {
  paymentDetails: {},
  accountId: null,
  isSuccess: false,
  isLoading: false,
  isError: false,
  isDirectSignup: true,
  error: '',
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.DIRECT_SIGNUP_INIT: {
      return Object.assign({}, state, {
        paymentDetails: {},
        accountId: null,
        isLoading: true,
        isSuccess: false,
        isError: false,
        isDirectSignup: true,
        error: '',
      });
    }
    case types.DIRECT_SIGNUP_SUCCESS: {
      return Object.assign({}, state, {
        paymentDetails: action.data.paymentMethodDetails,
        accountId: action.data.accountId,
        isLoading: false,
        isSuccess: true,
        isError: false,
        isDirectSignup: true,
        error: '',
      });
    }
    case types.DIRECT_SIGNUP_FAIL: {
      return Object.assign({}, state, {
        paymentDetails: {},
        accountId: null,
        isLoading: false,
        isSuccess: false,
        isError: true,
        isDirectSignup: true,
        error: action.data,
      });
    }
    default:
      return state;
  }
}
