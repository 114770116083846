/* eslint-disable react/button-has-type,react/prop-types */
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import ModalWithHeaderFooter from '../shared/ModalWithHeaderFooter';
import ValidateChangePasswordForm from '../../utils/Validators/ChangePasswordValidator';
import MessagePopUp from '../shared/MessagePopUp';
import { THANK_YOU_PASSWORD_CHANGED } from '../../constants/MessageConstants';
import { errorMessages } from '../../constants/ErrorMessageConstants';
import { RAF_PAGE_URL, PAF_PAGE_URL } from '../../configs/constants';
import { isFspDirect, isFSP } from '../../utils/userUtils';

const modalId = 'ChangePassword';
export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      oldPasswordType: 'password',
      newPassword: '',
      newPasswordType: 'password',
      retypeNewPassword: '',
      retypeNewPasswordType: 'password',
      errors: {},
      oldPasswordIconClass: 'vistajet_password_hide',
      newPasswordIconClass: 'vistajet_password_hide',
      retypePasswordIconClass: 'vistajet_password_hide',
      popupMessage: 'Your Request cannot be processed this time',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.changePasswordRequired && nextProps.user.changePasswordSuccess
      && this.props.user.changePasswordSuccess !== nextProps.user.changePasswordSuccess
      && !nextProps.user.isDirectFromProfile) {
      const id = (new URLSearchParams(window.location.search)).get('accountStatementId');
      const route = (new URLSearchParams(window.location.search)).get('from');
      let location = '';
      const { user: userObj } = nextProps.user;
      if (route === 'request-a-flight') {
        if (isFspDirect(userObj) || isFSP(userObj))
          location = PAF_PAGE_URL;
        else {
          location = RAF_PAGE_URL
        }
      } else if (route && route !== 'login' && route !== 'forgotPassword') {
        location = route;
      } else if (id) {
        location = `/?accountStatementId=${id}`;
      } else {
        location = '/'
      };
      window.setTimeout(() => {
        window.location = location;
      }, 2000);
      window.UIkit.modal('#ThankyouPasswordChanged').show();
    }
    if (this.props.user.changePasswordErrors !== nextProps.user.changePasswordErrors
      && nextProps.user.changePasswordErrors
      && !nextProps.user.isDirectFromProfile) {
      this.setState({
        popupMessage: nextProps.user.changePasswordErrorText
          ? nextProps.user.changePasswordErrorText
          : 'Your Request cannot be processed this time',
      }, () => {
        window.UIkit.modal('#ChangeErrorPopUp').show();
      });
    }
  }

  handleOldPasswordChange = (e) => {
    this.setState({ oldPassword: e.target.value });
  };

  handleNewPasswordChange = (e) => {
    this.setState({ newPassword: e.target.value });
  };

  handleRetypePasswordChange = (e) => {
    this.setState({ retypeNewPassword: e.target.value });
  };

  handleChangePassword = (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, retypeNewPassword } = this.state;
    const { changePasswordAction } = this.props;
    const { username, userToken } = this.props.user.user;
    const res = ValidateChangePasswordForm({
      oldPassword,
      newPassword,
      retypeNewPassword,
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      const payload = {
        oldPassword,
        newPassword,
        retypeNewPassword,
        username,
        userToken,
        isProfile: false,
      };
      changePasswordAction(payload);
    }
  };

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  toggleOldPasswordType = () => {
    this.setState(
      {
        oldPasswordType: (this.state.oldPasswordType === 'password') ? 'text' : 'password',
        oldPasswordIconClass: (this.state.oldPasswordType === 'password') ? 'vistajet_password_show' : 'vistajet_password_hide',
      },
    );
  };

  toggleNewPasswordType = () => {
    this.setState(
      {
        newPasswordType: (this.state.newPasswordType === 'password') ? 'text' : 'password',
        newPasswordIconClass: (this.state.newPasswordType === 'password') ? 'vistajet_password_show' : 'vistajet_password_hide',
      },
    );
  };

  toggleRetypePasswordType = () => {
    this.setState(
      {
        retypeNewPasswordType: (this.state.retypeNewPasswordType === 'password') ? 'text' : 'password',
        retypePasswordIconClass: (this.state.retypeNewPasswordType === 'password') ? 'vistajet_password_show' : 'vistajet_password_hide',
      },
    );
  };

  renderForm = () => {
    const {
      oldPassword,
      newPassword,
      retypeNewPassword,
      errors,
    } = this.state;

    const { isPasswordChangeLoading } = this.props;
    const changePasswordButtonText = isPasswordChangeLoading
      ? 'Loading...'
      : 'Change Password';

    let mobileErrorTextMarginStyle = {};
    if (
      isMobile
      && errors.errors
      && errors.errors.newPassword
      && (errorMessages.PASS_MAX_LENGTH == errors.errors.newPassword
        || errors.errors.newPassword == errorMessages.INVALID_PASS)) {
      mobileErrorTextMarginStyle = { marginBottom: 35 };
    }
    return (
      <div className="vistajet_primary_form uk-margin-medium-bottom">
        <form>
          <div className="vistajet_input_wrapper uk-flex uk-flex-middle">
            <input
              id="oldPassword"
              type={this.state.oldPasswordType}
              name="oldPassword"
              onChange={this.handleOldPasswordChange}
              onFocus={this.removeErrors}
              value={oldPassword}
              autoComplete="current-password"
            />
            <label htmlFor="oldPassword">
              Old Password
            </label>
            {
              errors.errors && errors.errors.oldPassword && (
                <p>
                  {errors.errors.oldPassword}
                </p>
              )
            }
            <span
              className={this.state.oldPasswordIconClass}
              onMouseDown={this.toggleOldPasswordType}
              onMouseUp={this.toggleOldPasswordType}
              onTouchStart={this.toggleOldPasswordType}
              onTouchEnd={this.toggleOldPasswordType}
            />
          </div>

          <div
            className="vistajet_input_wrapper uk-flex uk-flex-middle"
            style={mobileErrorTextMarginStyle}
          >
            <input
              id="newPassword"
              type={this.state.newPasswordType}
              name="newPassword"
              onChange={this.handleNewPasswordChange}
              onFocus={this.removeErrors}
              value={newPassword}
              autoComplete="new-password"
            />
            <label htmlFor="password">
              New Password
            </label>
            {
              errors.errors && errors.errors.newPassword && (
                <p>
                  {errors.errors.newPassword}
                </p>
              )
            }
            <span
              className={this.state.newPasswordIconClass}
              onMouseDown={this.toggleNewPasswordType}
              onMouseUp={this.toggleNewPasswordType}
              onTouchStart={this.toggleNewPasswordType}
              onTouchEnd={this.toggleNewPasswordType}
            />
          </div>

          <div className="vistajet_input_wrapper uk-flex uk-flex-middle">
            <input
              id="retypeNewPassword"
              type={this.state.retypeNewPasswordType}
              name="retypeNewPassword"
              onChange={this.handleRetypePasswordChange}
              onFocus={this.removeErrors}
              value={retypeNewPassword}
              autoComplete="new-password"
            />
            <label htmlFor="retypeNewPassword">
              Retype Password
            </label>
            {
              errors.errors && errors.errors.retypeNewPassword && (
                <p>
                  {errors.errors.retypeNewPassword}
                </p>
              )
            }
            <span
              className={this.state.retypePasswordIconClass}
              onMouseDown={this.toggleRetypePasswordType}
              onMouseUp={this.toggleRetypePasswordType}
              onTouchStart={this.toggleRetypePasswordType}
              onTouchEnd={this.toggleRetypePasswordType}
            />
          </div>
          <div className="vistajet_modal_footer fix">
            <button
              className="uk-button uk-button-default uk-width-1-1 uk-text-center"
              disabled={isPasswordChangeLoading}
              onClick={this.handleChangePassword}
            >
              {changePasswordButtonText}
            </button>
          </div>
        </form>
      </div>
    );
  };

  render() {
    const { isPasswordChangeLoading } = this.props;
    const headerContent = 'Change Password';

    return (
      <div id="container--reset-password">
        <ModalWithHeaderFooter
          id={modalId}
          container="#container--reset-password"
          headerContent={headerContent}
          subHeadingInHeader="Please setup a new password below"
          content={this.renderForm()}
          noCross
          isPasswordChangeLoading={isPasswordChangeLoading}
        />
        <MessagePopUp
          id="ChangeErrorPopUp"
          stack='true'
          content={this.state.popupMessage}
        />
        <MessagePopUp
          id="ThankyouPasswordChanged"
          stack='true'
          content={THANK_YOU_PASSWORD_CHANGED}
        />
      </div>
    );
  }
}
