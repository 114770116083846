const SPAConstants = {
  PAFContainer: "PAFContainer",
  RegisterPAFContainer: "RegisterPAFContainer",
  GroundTransportation: "GroundTransportation",
  AdditionalServices: "AdditionalServices",
  ItineraryCatering: "ItineraryCatering",
  ItineraryPassengers : 'ItineraryPassengers',
  ItineraryPassengersPassport : 'ItineraryPassengersPassport',
  ItineraryNewPassengers : 'ItineraryNewPassengers',
  TopUpAccount: 'TopUpAccount',
  AddCreditCard: 'AddCreditCard',
  MyQuotes: 'MyQuotes',
  BillingAddress: 'BillingAddress',
  UserProfile : 'UserProfile',
  CustomerFeedback : 'CustomerFeedback'
};
export default SPAConstants;
