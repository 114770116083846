import axios from "axios";
import { parseXmlStringToJson } from "utils/parserUtils";
import {
  MembershipAccountStatementInit,
  MembershipAccountStatementSuccess,
  MembershipAccountStatementFail,
} from "store/actions/DirectAccountBalanceActions";

import {
  SOMETHING_UNEXPECTED_CONTACT,
} from "../../constants/MessageConstants";

import { 
  ON_DEMAND_ACCOUNT_BALANCE_URL,
  TRANSACTIONS_QUANTITY,
} from "configs/constants";
import status from "configs/status";
import { handleServerErrors } from "utils/sourceUtils";
import { BAD_REQUEST, TIMEOUT_CODE, UNAUTHORIZED } from "constants/ServerCodeConstants";
import { normalizeAccount } from "normalizers/DirectAccountBalanceNormalizer";

export const MembershipAccountStatementSource = (token, currentLength = 0, isLoadMore) => dispatch => {
  dispatch(MembershipAccountStatementInit(isLoadMore));
  const options = {
    headers: {
      "Content-Type": "application/xml"
    },
    timeout: TIMEOUT_CODE.time
  }; 
  const url = ON_DEMAND_ACCOUNT_BALANCE_URL
    .replace('{token}', token)
    .replace('{lineItemsPerpage}', TRANSACTIONS_QUANTITY)
    .replace('{currentCount}', currentLength);

  axios
    .get(
      url,
      options
    )
    .then(response => {
      if (response.status === status.OK) {
        const parsedData = parseXmlStringToJson(response.data);
        const responseHeader =
          parsedData.ResponseDto.responseHeader;
        if (responseHeader.isError) {
          const error = responseHeader.statusCode;
          if (error === 1 &&
              responseHeader.message !== SOMETHING_UNEXPECTED_CONTACT) {
              dispatch(MembershipAccountStatementFail());
          } else {
            dispatch(MembershipAccountStatementFail(handleServerErrors(error)));
          }
        } else {
          dispatch(MembershipAccountStatementSuccess(normalizeAccount(parsedData), isLoadMore));
        } 
      } else {
        dispatch(MembershipAccountStatementFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(MembershipAccountStatementFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(MembershipAccountStatementFail(handleServerErrors(UNAUTHORIZED)));
      }
      else dispatch(MembershipAccountStatementFail(handleServerErrors(BAD_REQUEST)));
    });
};
