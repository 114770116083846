import { getAircraftByAllFields } from '../utils/aircraftUtils';
// import { getAirportById } from '../utils/airportUtils';
import { garanteeArray } from '../utils/sourceUtils';
import Helpers from '../utils/Helpers';
import { isPastLeg } from '../utils/itinerariesUtils';

const normalizeLegData = (legs, accountId) => {
  let legArray = [];
  legArray = garanteeArray(legs);
  const newLegArray = { leg: [] };
  newLegArray.leg = legArray.map(legItem => {
    let singleLeg = {};
    singleLeg.legId = legItem.legId || '';
    singleLeg.accountId = accountId;
    singleLeg.arrivalAirportId = legItem.arrivalAirportId || '';
    singleLeg.departureAirportId = legItem.departureAirportId || '';
    singleLeg.departureAirport = legItem.departureAirportId;
    singleLeg.departureAirportTimeLocal =
      legItem.departureAirportTimeLocal || '';
    singleLeg.departureAirportTimeUTC = legItem.departureAirportTimeUTC || '';
    singleLeg.arrivalAirportTimeLocal = legItem.arrivalAirportTimeLocal || '';
    singleLeg.arrivalAirportTimeUTC = legItem.arrivalAirportTimeUTC || '';
    singleLeg.defaultDurationMinutes = legItem.defaultDurationMinutes || '';
    singleLeg.aircraftTypeName = legItem.aircraftTypeName || '';
    singleLeg.aircraftTypeId = legItem.aircraftTypeId || '';
    singleLeg.requestedAircraftTypeName =
      legItem.requestedAircraftTypeName || '';
    singleLeg.aircraftTailNumber = legItem.aircraftTailNumber || '';
    singleLeg.aircraftId = legItem.aircraftId || '';
    singleLeg.numberOfPassenger = legItem.numberOfPassenger || '';
    singleLeg.legStatus = legItem.legStatus || '';
    singleLeg.version = legItem.version || '';
    singleLeg.operatorTailId = legItem.operatorTailId || '';
    singleLeg.operatorTailName = legItem.operatorTailName || '';
    singleLeg.operatingCompanyName = legItem.operatingCompanyName || '';
    singleLeg.flightLegExtIdentifier = legItem.flightLegExtIdentifier || '';
    singleLeg.isEditable = Helpers.isEditableLeg(singleLeg);
    singleLeg.isPast = isPastLeg(singleLeg);
    singleLeg.isCancelled = legItem.isCancelled || false;
    singleLeg.within24Hours = Helpers.satisfy24HoursCheck(singleLeg) && !isPastLeg(singleLeg);
    const aircraftCode =
      singleLeg.aircraftTypeName &&
        singleLeg.aircraftTypeName !== '' &&
        (singleLeg.within24Hours ||
          !singleLeg.requestedAircraftTypeName ||
          singleLeg.requestedAircraftTypeName === 'N/A')
        ? singleLeg.aircraftTypeName
        : singleLeg.requestedAircraftTypeName;
    singleLeg.aircraft = getAircraftByAllFields(aircraftCode);
    if (!!singleLeg.aircraft && !singleLeg.aircraft.displayName) {
      singleLeg.aircraft.displayName = singleLeg.aircraftTypeName;
    }
    singleLeg.duration =
      singleLeg.defaultDurationMinutes ||
      Helpers.getFlightDuration(
        singleLeg.departureAirport,
        singleLeg.arrivalAirport,
        singleLeg.aircraft,
      );
    singleLeg.uniqueIdentifier =
      singleLeg.legId || singleLeg.flightLegExtIdentifier;
    return singleLeg;
  });
  return newLegArray;
};
const normalizeItineraryListingData = itinerary => {
  return {
    legs: normalizeLegData(itinerary.legs, itinerary.accountId) || {},
    orderLegId: itinerary.orderLegId || '',
    orderExtIdentifier: itinerary.orderExtIdentifier || '',
    orderStatus: itinerary.orderStatus || '',
    orderType: itinerary.orderType || '',
    accountId: itinerary.accountId || ''
  };
};

export default normalizeItineraryListingData;
