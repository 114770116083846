import axios from 'axios';
import { parseXmlStringToJson } from "../../utils/parserUtils";
import { WRITE_USER_SETTINGS } from '../../configs/constants';
import { getToken } from '../../utils/userUtils';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';
import {
  HTTP_HEADERS_XML,
} from '../../constants/ApiConstants';
import { TIMEOUT_CODE } from '../../constants/ServerCodeConstants';

const options = {
  timeout: TIMEOUT_CODE.time,
  headers: HTTP_HEADERS_XML,
};
const status = {
  OK: 200,
};
const UserSettingsSource = {

  writeUserSettings(data) {
    return new Promise((resolve, reject) => {
      let payload = '<settings>' +
                        '{allsettings}' +
                    '</settings>';

      const basicXml = '<setting>' +
                          '<key>{key}</key>' +
                          '<value>{values}</value>' +
                      '</setting>';

      let allSettings = '';

      Object.keys(data).forEach((key) => {
        allSettings += basicXml.replace('{key}', key).replace('{values}', data[key].join(','));
      });

      payload = payload.replace('{allsettings}', allSettings);


      const url = WRITE_USER_SETTINGS.replace('{token}', getToken());

      axios
        .put(url, payload, options)
        .then((response) => {
          if (response.status !== status.OK) { // error occured
            reject(handleServerErrors(response.status));
          } else {
            const jsonData = parseXmlStringToJson(response.data);
            resolve({ searchPax: jsonData.passengerRestDtoes });
          }
        });
    });
  },
};

export default UserSettingsSource;
