import React from "react";
import Select from "react-select";
import { USER_ACCESS_TYPES } from '../../constants/UserAccessTypes';
import dirtystateSingleTon from "../../validators/validateDirty";
import SPAConstants from "../../constants/SPAConstants";
class ContractSelector extends React.Component {

  componentDidMount() {
    const options = this.renderAccountName() || [];
    let initialSelection;

    if (this.props.itineraryAccountId) {
      initialSelection = options.filter(account => account.value.id === this.props.itineraryAccountId);
      this.props.handleContractChange(initialSelection[0]);
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
    }

    if (!this.props.selectedContract && options && !this.props.isOfferFlow && options.length === 1) {
      initialSelection = options[0];
      this.props.handleContractChange(initialSelection);
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
    }
    else if (!this.props.selectedContract && options && this.props.isOfferFlow && !this.props.isOffer) {
      initialSelection = this.getPreferredAccountOption(options);
      this.props.handleContractChange(initialSelection);
    }
  }
  renderAccountName() {
    if (!this.props.user) return false;
    const { accounts } = this.props.user || {};
    let options = [];
    if (accounts && accounts.length >= 1) {
      accounts.forEach(account => {
        if (account.typeId == USER_ACCESS_TYPES.FSP) {
          options.push({ value: account, label: account.name });
        }
      });
    }
    return options;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps != this.props) {
      this.props = nextProps
    }

  }
  getPreferredAccountOption = accounts => {
    const preferredAccount = accounts.filter(
      account => account.value.typeId === USER_ACCESS_TYPES.FSP && account.value.isPreferred
    );
    return preferredAccount[0];
  };

  render() {
    const options = this.renderAccountName() || [];
    let initialSelection;
    return (
      <div className="vistajet_select_contract_column">
        {!this.props.itineraryAccountId ?
          <Select
            classNamePrefix="react-select"
            value={this.props.selectedContract || initialSelection || null}
            isSearchable={false}
            options={options}
            onChange={this.props.handleContractChange}
            placeholder={"Select Contract"}
          /> : (this.props.selectedContract && this.props.selectedContract.label)}
      </div>
    );
  }
}

export default ContractSelector;
