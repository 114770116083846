import { VJ_AIRCRAFTS } from '../mocks/aircraftDetails';

const OTHER_AIRCRAFTS = {
  'Learjet 60XR': {
    price: 5000000,
    crew: 241800,
    fixed: 169400,
    variable: 2406,
    cabinlength: 17.6,
    cabinwidth: 5.9,
    cabinheight: 5.7,
    baggage: 55,
    range: 2405,
    speed: 536,
    pax: '9 passengers',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_350,
  },
  'Citation Excel': {
    price: 12992205,
    crew: 241800,
    fixed: 169400,
    variable: 2406,
    cabinlength: 18.6,
    cabinwidth: 5.50,
    cabinheight: 5.66,
    baggage: 90,
    range: 2405,
    speed: 536,
    pax: '9 passengers',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_350,
  },
  'Citation X': {
    price: 10000000,
    crew: 427656,
    fixed: 293125,
    variable: 3881,
    cabinlength:  23.70,
    cabinwidth: 5.60,
    cabinheight: 5.70,
    baggage: 72,
    range: 3070,
    speed: 700,
    pax: '10 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_350,
  },
  'Hawker 750': {
    price: 4000000,
    crew: 241800,
    fixed: 181829,
    variable: 2804,
    comparablerate: 12265,
    cabinlength:  18.31,
    cabinwidth: 6.00,
    cabinheight: 5.74,
    baggage: 54,
    range: 2903,
    speed: 495,
    pax: '8 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_350,
  },
  'Hawker 900XP': {
    price: 10000000,
    crew: 241800,
    fixed: 188815,
    variable: 2798,
    cabinlength: 21.3,
    cabinwidth: 6.00,
    cabinheight: 5.75,
    baggage: 40,
    range: 3685,
    speed: 505,
    pax: '8 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_350,
  },
  'Hawker 4000': {
    price: 7500000,
    crew: 400400,
    fixed: 308555,
    variable: 3670,
    cabinlength: 25,
    cabinwidth: 6.5,
    cabinheight: 6,
    baggage: 108,
    range: 3208,
    speed: 555,
    pax: '10 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_350,
  },
  'Citation Sovereign': {
    price: 15000000,
    crew: 241800,
    fixed: 209666,
    variable: 2698,
    cabinlength: 24.2,
    cabinwidth: 5.6,
    cabinheight: 5.7,
    baggage: 100,
    range: 3243,
    speed: 495,
    pax: '10 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_350,
  },
  'Falcon 2000LX': {
    price: 31650000,
    crew: 388700,
    fixed: 306505,
    variable: 3077,
    comparablerate: 13730,
    cabinlength: 20.23,
    cabinwidth: 5.6,
    cabinheight: 5.7,
    baggage: 100,
    range: 3912,
    speed:  554,
    pax: '10 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_605,
  },
  'Gulfstream 450': {
    price: 33000000,
    crew: 406900,
    fixed: 416934,
    variable: 5139,
    cabinlength: 45.1,
    cabinwidth: 7.3,
    cabinheight: 6.2,
    baggage: 169,
    range: 4341,
    speed:  547,
    pax: '14 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_5000,
  },
  'Challenger 350': {
    price: 25000000,
    crew: 400400,
    fixed: 304218,
    variable: 3244,
    cabinlength: 28.6,
    cabinwidth: 7.2,
    cabinheight: 6.1,
    baggage: 106,
    range: 3200,
    speed: 541,
    pax: '9 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_350,
  },
  'Challenger 605': {
    price: 25000000,
    crew: 388700,
    fixed: 316570,
    variable: 3490,
    cabinlength: 28.3,
    cabinwidth: 8.2,
    cabinheight: 6.2,
    baggage: 114,
    range: 3995,
    speed: 541,
    pax: '12 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_605,
  },
  'Falcon 2000': {
    price: 12500000,
    crew: 388700,
    fixed: 310782,
    variable: 3765,
    cabinlength: 26.3,
    cabinwidth: 7.7,
    cabinheight: 6.2,
    baggage: 134,
    range: 3052,
    speed: 551.223,
    pax: '8 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_605,
  },
  'Falcon 2000EX': {
    price: 15000000,
    crew: 388700,
    fixed: 315222,
    variable: 3312,
    cabinlength: 26.3,
    cabinwidth: 7.7,
    cabinheight: 6.2,
    baggage: 131,
    range: 3425,
    speed: 562.731,
    pax: '8 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_605,
  },
  'Challenger 850': {
    price: 20000000,
    crew: 388700,
    fixed: 398560,
    variable: 3991,
    cabinlength: 48.42,
    cabinwidth: 8.17,
    cabinheight: 6.08,
    baggage: 202,
    range: 2807,
    speed: 528,
    pax: '14 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_850,
  },
  'Falcon 900EX': {
    price: 35000000,
    crew: 388700,
    fixed: 364982,
    variable: 3624,
    cabinlength: 32.9,
    cabinwidth: 7.7,
    cabinheight: 6.2,
    baggage: 127,
    range: 4057,
    speed: 545.469,
    pax: '12 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_5000,
  },
  'Falcon 900EX EASy': {
    price: 35000000,
    crew: 388700,
    fixed: 377882,
    variable: 3483,
    cabinlength: 32.9,
    cabinwidth: 7.7,
    cabinheight: 6.2,
    baggage: 127,
    range: 4469,
    speed: 545.469,
    pax: '12 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_5000,
  },
  'Global 5000': {
    price: 52000000,
    crew: 445900,
    fixed: 451680,
    variable: 5479,
    cabinlength: 45.7,
    cabinwidth: 8.17,
    cabinheight: 6.29,
    baggage: 195,
    range: 5200,
    speed: 590,
    pax: '13 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_5000,
  },
  'Global 6000': {
    price: 55000000,
    crew: 445900,
    fixed: 451680,
    variable: 5479,
    cabinlength: 48.4,
    cabinwidth: 8.17,
    cabinheight: 6.29,
    baggage: 195,
    range: 5998,
    speed: 590,
    pax: '15 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_6000,
  },
  'Global 7500': {
    price: 55000000,
    crew: 445900,
    fixed: 451680,
    variable: 5479,
    cabinlength: 48.4,
    cabinwidth: 8.17,
    cabinheight: 6.29,
    baggage: 195,
    range: 5998,
    speed: 590,
    pax: '15 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_7500,
  },  
  'Gulfstream 550': {
    price: 52500000,
    crew: 445900,
    fixed: 503705,
    variable: 4553,
    cabinlength: 42.6,
    cabinwidth: 7.3,
    cabinheight: 6.2,
    baggage: 226,
    range: 6708,
    speed: 561.58,
    pax: '16 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_6000,
  },
  'Falcon 7X': {
    price: 50000000,
    crew: 445900,
    fixed: 400195,
    variable: 3840,
    cabinlength: 48.4,
    cabinwidth: 8.17,
    cabinheight: 6.29,
    baggage: 195,
    range: 5850,
    speed: 561.58,
    pax: '15 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_6000,
  },
  'Legacy 600': {
    price: 20000000,
    crew: 401700,
    fixed: 374897,
    variable: 3493,
    cabinlength: 42.4,
    cabinwidth: 6.9,
    cabinheight: 6.0,
    baggage: 240,
    range: 3403,
    speed: 523.605,
    pax: '13 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_605,
  },
  'Legacy 650': {
    price: 25000000,
    crew: 401700,
    fixed: 369597,
    variable: 3477,
    cabinlength: 42.4,
    cabinwidth: 6.9,
    cabinheight: 6.0,
    baggage: 286,
    range: 3900,
    speed: 523.605,
    pax: '13 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.CHALLENGER_850,
  },
  'Gulfstream 650': {
    price: 72000000,
    crew: 482300,
    fixed: 506384,
    variable: 5364,
    comparablerate: 17230,
    cabinlength: 46.9,
    cabinwidth: 8.5,
    cabinheight: 6.35,
    baggage: 195,
    range: 7000,
    speed: 561.58,
    pax: '14 passengers',
    illustration: '',
    vjAirCraft: VJ_AIRCRAFTS.GLOBAL_6000,
  },
};

export default OTHER_AIRCRAFTS;
