import React, { Component } from 'react';
import SpinnerLoader from '../../shared/spinnerLoader';
import {
	getCateringStatus,
	shouldShowCountdownString,
	selectionCountdownString,
	isSelectionMade,
	isCateringEditable
} from '../../../utils/cateringUtils';
import { isPastLeg } from '../../../utils/itinerariesUtils';
import CateringPopup from './CateringPopup';
// import CateringDetail from '../CateringDetail';
import SupplierMenuList from './SupplierMenuList';
import PassengerQuantityList from './PassengerQuantityList';
import CateringSuppliers from './CateringSuppliers';
import {
	CATERING_SCREENS,
	CATERING_SHARED_ORDER_KEY,
	CATERING_SHARED_ORDER_LABEL,
	cateringStates,
} from '../../../constants/CateringConstants';
import { convertToTitleCase } from '../../../utils/stringUtils';
import ConfirmationPopup from '../../shared/ConfirmationPopUpNew';
import dirtystateSingleTon from "../../../validators/validateDirty";
import { CONFIRMATION_TITLE, LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT, CONFIRMATION_MESSAGE } from '../../../constants/DirtyPopupConstant';
import SPAConstants from '../../../constants/SPAConstants';
const FALLBACK_MSG = 'Menu will be available shortly';


class Catering extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHistory: [CATERING_SCREENS.SUPPLIERS_LIST],
			selectedSupplier: null,
			selectedMenuItem: null,
			selectedService: null,
			cateringProposal: [],
			isDirtyState: false,
			supplierQuantities: [],
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.cateringDetails && (nextProps.cateringDetails !== this.props.cateringDetails)) {
			this.setProposalQuantities(nextProps.cateringDetails.cateringProposal);
		}
		// if (nextProps.activeLegId !== this.props.activeLegId) {
		//     CateringActions.fetchCateringSelectionPDF.defer(nextProps.activeLegId);
		// }
		// const { activeLeg } = LegCardStore.getState();
		// if (activeLeg) this.setState({ isEditable: !isLegCancelled(activeLeg.legStatus) });
	}

	getTotalItemsCount = (catering) => {
		let totalItemCount = 0;
		catering.forEach((supplier) => {
			const { totalCount } = this.getSupplierWiseCounts(supplier);
			totalItemCount += totalCount;
		});
		return totalItemCount;
	}
	getSupplierWiseCounts = (supplier) => {
		const passengerCounts = {};
		let totalCount = 0;
		Object.keys(supplier.services).forEach((serviceName) => {
			Object.keys(supplier.services[serviceName]).forEach((category) => {
				supplier.services[serviceName][category].forEach((item) => {
					if (item.quantities) {
						Object.keys(item.quantities).forEach((passengerId) => {
							const quantity = item.quantities[passengerId];
							if (passengerCounts[passengerId]) {
								passengerCounts[passengerId].push({ ...quantity, ...item });
							} else {
								passengerCounts[passengerId] = [{ ...quantity, ...item }];
							}
							totalCount += quantity.count || 0;
						});
					}
				});
			});
		});
		return { passengerCounts, totalCount };
	}

	onSupplierSelected = (supplier) => {
		const { withIn24Hours, isPast } = this.props;
		if (withIn24Hours && !isPast) {
			this.setState({
				errorMessage: 'Menu selection cannot be modified within 24 hours.'
			}, () => {
				this.errorPopup.show();
			})
			return;
		}
		this.setState({
			selectedSupplier: supplier,
		});
	}

	onServiceSelected = (service) => {
		this.setState({
			selectedService: service,
		});
	}

	onServiceConfrimed = () => {
		this.setState({
			showTotalItems: true
		})
	}

	onMenuItemSelected = (item) => {
		this.setState({
			selectedMenuItem: item,
		});
	}

	onQunatitiesAdded = (passengerQuantities) => {
		const { selectedSupplier, selectedMenuItem } = this.state;
		let item = selectedSupplier.services[selectedMenuItem.service][selectedMenuItem.cateringType];
		item = item.filter(i => i.title === selectedMenuItem.title)[0];
		item.quantities = { ...item.quantities, ...passengerQuantities };
		this.setState({
			cateringProposal: this.state.cateringProposal, //sahi kar
			isDirtyState: true,
		});
	}

	onSelectionConfirmed = (confirmedSelection) => {
		this.props.changeCateringProposal(this.props.token, this.props.currentLeg.legId, confirmedSelection, this.props.currentLeg);
		this.handleExitPopUp();
	}

	setProposalQuantities = (cateringProposal) => {
		let passengers = [];
		passengers = ((this.props.passengers || []).passengers) || [];

		if (cateringProposal && cateringProposal.length) {
			const newProposal = [...cateringProposal];
			const supplierQuantities = [];
			newProposal.forEach((proposal) => {
				let totalSupplierCount = 0;
				Object.keys(proposal.services).forEach((service) => {
					const categories = proposal.services[service];
					Object.keys(categories).forEach((category) => {
						const items = categories[category];
						items.forEach((item) => {
							const newItem = item;
							newItem.selectionList.forEach((person) => {
								if (!newItem.quantities) newItem.quantities = {};
								const key = person.personId ? person.personId : CATERING_SHARED_ORDER_KEY;
								const name = person.personId !== CATERING_SHARED_ORDER_KEY ? convertToTitleCase(person.paxName) :
									CATERING_SHARED_ORDER_LABEL;
								newItem.quantities[key] = {
									count: person.quantity,
									passengerId: person.personId,
									passengerName: name,
									customerNotes: person.customerNotes,
								};
								totalSupplierCount += person.quantity;
							});
						});
					});
				});
				supplierQuantities.push({
					name: proposal.supplierDisplayName,
					count: totalSupplierCount,
				});
			});
			this.setState({
				cateringProposal: newProposal,
				supplierQuantities,
			});
		}
	}

	getSelectedSupplierProposal = (supplierId) => (
		this.state.cateringProposal.filter((proposal) => supplierId === proposal.serviceProviderId)[0]
	);

	getCurrentScreen = () => this.state.screenHistory[this.state.screenHistory.length - 1];

	handleConfirmCancel = () => {

	}
	handleClose = () => {
		if (this.state.isDirtyState) {
			this.setState({
				errorMessage: "If you leave now, you will lose your current menu selection. Do you wish to leave anyway?"
			}, () => {
				this.errorPopup.show();
			})
			return;
		} else {
			this.handleExitPopUp();
		}
	}

	handleScreenChange = (screen, shouldClearAll) => {
		const { screenHistory } = this.state;
		if (shouldClearAll && screen) {
			this.setState({
				screenHistory: [screen],
			});
		} else if (screen == null) {
			//  If no screen is given it pops up the current screen
			screenHistory.pop();
			this.setState({
				screenHistory: [...screenHistory],
			});
		} else {
			this.setState({
				screenHistory: [...screenHistory, screen],
			});
		}
	}

	handleExitPopUp = () => {
		this.cateringModal.hide();
		this.setState({
			selectedSupplier: null,
			selectedMenuItem: null,
			selectedService: null,
			screenHistory: [CATERING_SCREENS.SUPPLIERS_LIST],
			isDirtyState: false,
		});
		// this.errorPopup.show();
		this.handleConfirmCancel();
	}

	handleBackButton = () => {
		const isCateringDirty = dirtystateSingleTon.getDirty(SPAConstants.ItineraryCatering);
		if (this.state.screenHistory.length === 1) {
			if (isCateringDirty) {
				this.cateringConfirmPopup.show();
			} else {
				this.handleExitPopUp();
			}
		}
		else if (this.getCurrentScreen() === CATERING_SCREENS.PASSENGER_QUANTITY_LIST) {
			if (isCateringDirty) {
				this.cateringConfirmPopup.show();
			} else {
				this.handleScreenChange(null);
			}
		}
		else if (this.getCurrentScreen() === CATERING_SCREENS.SUPPLIER_LIST_MENU) {
			this.setState({
				selectedService: null,
			});
			this.handleScreenChange(null);
		}
	}

	onMoveAway = () => {
		dirtystateSingleTon.setDirty(SPAConstants.ItineraryCatering, false);
		this.cateringConfirmPopup.hide();
		if (this.state.screenHistory.length === 1) { this.handleExitPopUp() }
		else if (this.getCurrentScreen() === CATERING_SCREENS.PASSENGER_QUANTITY_LIST) {
			this.handleScreenChange(null)
		}
	}

	renderCurrentScreen = () => {
		const { isLoading, cateringDetails = {}, currentLeg, isPast, withIn24Hours } = this.props;
		const { cateringProposal } = cateringDetails;
		const departureTime = cateringDetails.departureAirportTimeUTC;
		const cateringDuration = selectionCountdownString(departureTime);
		const isCateringLoading = cateringDetails.isCateringLoading || isLoading;
		const { selectedSupplier } = this.state;
		let supplierProposal = {};
		if (selectedSupplier) {
			supplierProposal = this.getSelectedSupplierProposal(selectedSupplier.serviceProviderId);
		}
		let passengers = [];
		passengers = ((this.props.passengers || []).passengers) || [];
		const isEditable = isCateringEditable(currentLeg, withIn24Hours) &&
			(currentLeg && currentLeg.legStatus !== "Cancellation Pending") &&
			(currentLeg && currentLeg.legStatus !== "Cancelled");
		const currentScreen = this.getCurrentScreen();
		switch (currentScreen) {
			case CATERING_SCREENS.PASSENGER_QUANTITY_LIST:
				return (
					<PassengerQuantityList
						handleScreenChange={this.handleScreenChange}
						menuItem={this.state.selectedMenuItem}
						passengers={passengers}
						onQunatitiesAdded={this.onQunatitiesAdded}
						selectedSupplier={this.state.selectedSupplier.supplierDisplayName}
					/>);
			case CATERING_SCREENS.SUPPLIER_LIST_MENU:
				return (<SupplierMenuList
					proposal={supplierProposal}
					handleScreenChange={this.handleScreenChange}
					onMenuItemSelected={this.onMenuItemSelected}
					selectedService={this.state.selectedService}
					onServiceSelected={this.onServiceSelected}
					selectedSupplier={this.state.selectedSupplier.supplierDisplayName}
					onSelectionConfirmed={this.onServiceConfrimed}
				/>);
			case CATERING_SCREENS.SUPPLIERS_LIST:
				return (<CateringSuppliers
					ref={(supplierScreen) => { this.supplierScreen = supplierScreen; }}
					isSummary={false}
					legCountDown={cateringDuration}
					handleScreenChange={this.handleScreenChange}
					activeLeg={currentLeg}
					onSupplierSelected={this.onSupplierSelected}
					loading={isCateringLoading}
					cateringProposal={cateringProposal}
					onSelectionConfirmed={this.onSelectionConfirmed}
					isDirtyState={this.state.isDirtyState}
					catering={cateringDetails}
					isPast={isPast}
					withIn24Hours={this.props.withIn24Hours}
					isEditable={isEditable}
					isFlightEditable={this.props.isEditable}
				/>);
			default:
				return null;
		}
	}

	showCateringModal = () => {
		const { cateringDetails } = this.props;

		if (!cateringDetails) {
			this.setState({
				errorMessage: FALLBACK_MSG
			}, () => {
				this.errorPopup.show();
			})
			return;
		}

		if (cateringDetails && cateringDetails.cateringProposal) {
			if ((cateringDetails.cateringProposal && cateringStates.NOT_STARTED.includes(cateringDetails.stateId)) ||
				cateringDetails.cateringProposal.length === 0
			) {
				this.setState({
					errorMessage: FALLBACK_MSG
				}, () => {
					this.errorPopup.show();
				})
				return;
			}
		}
		this.cateringModal.show()
	}

	renderSelectionCountdown = () => {
		const { cateringDetails = {}, isLoading } = this.props;
		if (!cateringDetails.isCateringLoading && !isLoading &&
			!shouldShowCountdownString(cateringDetails, this.props.withIn24Hours)) return null;
		const departureTime = cateringDetails.departureAirportTimeUTC;
		const cateringDuration = selectionCountdownString(departureTime);
		return <span>Selection Countdown: <strong>{cateringDuration}</strong></span>;
	}

	renderSupplierQuantities = () => {
		const quantities = [];
		this.state.supplierQuantities.forEach((supplier) => {
			if (supplier.count > 0) {
				quantities.push((
					<div className="vistajet_catering_data_wrapper vistajet_private_dining_wrapper">
						<h5 className="uk-margin-remove uk-width-expand">{supplier.name}</h5>
						<p className="uk-width-1-5@s uk-width-1-6@m uk-text-center@s vistajet_catering_bold_text">x{supplier.count}</p>
					</div>
				));
			}
		});
		return [quantities];
	}

	disableCatering = () => {
		const dontDisplayCateringStatus = [
			...cateringStates.NOT_REQUIRED,
		];

		const { currentLeg, cateringDetails, withIn24Hours } = this.props;
		if (cateringDetails && cateringDetails.stateId &&
			dontDisplayCateringStatus.includes(cateringDetails.stateId)) {
			return true
		};
		if (isCateringEditable(currentLeg, withIn24Hours)) return false;

		return false;
	}

	render() {
		const { isLoading, cateringDetails = {}, currentLeg, isPast, passengers, withIn24Hours } = this.props;
		const isPassengerLoading = passengers && passengers.isLoadingPassenger
		const isCateringLoading = (cateringDetails.isCateringLoading || isLoading ||
			cateringDetails.isCateringProposalLoading || isPassengerLoading
		);

		let disableCateringDialog = this.disableCatering();
		let popupHeading = (this.state.screenHistory.length > 1) ? this.state.selectedSupplier.supplierDisplayName : 'Private Dining'
		let totalItems = 0;
		if (cateringDetails && cateringDetails.cateringProposal) {
			totalItems = this.getTotalItemsCount(cateringDetails.cateringProposal)
		}
		let countDownMargin = ""
		if (!isSelectionMade(cateringDetails, isPast) && !isCateringLoading && currentLeg && this.renderSelectionCountdown()) {
			countDownMargin = "uk-margin-small-top";
		}
		return (
			<div>
				<CateringPopup
					isCenter={true}
					ref={(cateringModal) => { this.cateringModal = cateringModal; }}
					content={this.renderCurrentScreen()}
					onExited={this.handleExitPopUp}
					handleBackButton={this.handleBackButton}
					showBackButton={true}
					disableConfirmButton={!this.props.isDirtyState}
					onConfirmClicked={this.onSelectionConfirmed}
					showCloseIcon={false}
					heading={popupHeading}
					totalItems={totalItems}
					isSelectionMade={this.state.showTotalItems}
					closeOnOverlayClick={false}
				/>
				<ConfirmationPopup
					ref={(errorPopup) => { this.errorPopup = errorPopup; }}
					content={this.state.errorMessage}
					onClose={() => { }}
					rightButtonContent="OK"
					onRightButtonClick={() => this.errorPopup.hide()}
				/>
				<div className="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove">
					<div className="vistajet_itinerary_card_header">
						<h5 className="uk-width-expand">Private Dining</h5>
						{(!disableCateringDialog && !isCateringLoading) &&
							<a className="vistajet_button_default uk-margin-remove" onClick={this.showCateringModal}>View</a>
						}
					</div>
					<div className="vistajet_itinerary_card_body">
						{(!isSelectionMade(cateringDetails, isPast) && !isCateringLoading && currentLeg) &&
							<div>
								<div>
									<p class="uk-width-expand ">{getCateringStatus(cateringDetails, isPastLeg(currentLeg))}</p>
								</div>
								<div className="vistajet_catering_data_wrapper">
									<p className={`${countDownMargin} uk-width-expand`}>
										{this.renderSelectionCountdown()}
									</p>
								</div>
							</div>
						}
						{(isSelectionMade(cateringDetails, isPast) && !isCateringLoading && currentLeg) &&
							<div className="vistajet_catering_data_wrapper">
								<p className="uk-margin-remove uk-width-expand">
									{this.renderSelectionCountdown()}
								</p>
							</div>
						}
						{(isSelectionMade(cateringDetails, isPast) && !isCateringLoading && currentLeg) &&
							this.renderSupplierQuantities()
						}
						{(isSelectionMade(cateringDetails, isPast) && cateringDetails.fileUrl && !isCateringLoading && currentLeg) &&
							<div className="vistajet_catering_download_wrapper">
								<div className="uk-width-expand">
									<a href={cateringDetails.fileUrl} target="blank" className="vistajet_download_text">Download Selection</a>
								</div>
								<p className="vistajet_catering_bold_text">{getCateringStatus(cateringDetails, isPastLeg(currentLeg))}</p>
							</div>
						}
						{(isCateringLoading) &&
							< div className="vistajet_spinner">
								<SpinnerLoader />
							</div>
						}
					</div>
				</div>
				<ConfirmationPopup
					ref={cateringConfirmPopup => { this.cateringConfirmPopup = cateringConfirmPopup }}
					title={CONFIRMATION_TITLE}
					content={CONFIRMATION_MESSAGE}
					leftButtonContent={LEFT_BUTTON_TEXT}
					rightButtonContent={RIGHT_BUTTON_TEXT}
					onRightButtonClick={() => this.onMoveAway()}
					onLeftButtonClick={() => this.cateringConfirmPopup.hide()}
				/>
			</div>
		);
	}
}

export default Catering;
