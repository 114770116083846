/*
    This function is used for getting a particular parameter from current url
    Just pass the parameter you want to find,
    If found, it will return its value.
    If Not found, it will return empty string
*/

export const getParameterByName = (name) => {
    const url = window.location.href;
    const paramName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  