
import CryptoJS from 'crypto-js';
import Cookies from 'universal-cookie';

import { ENCRYPTION_SECRET } from '../configs/constants';
import { isValidJSON } from './objectUtils';

const cookies = new Cookies();

export const saveToLocal = (data, key, isJson = true, isEncrypted = false) => {
  let saveData = data;
  //console.log("Saving to local:");
  //console.log(JSON.stringify(data));
  if (data && isJson) {
    saveData = JSON.stringify(data);
  }
  if (saveData && isEncrypted) {
    saveData = CryptoJS.AES.encrypt(saveData, ENCRYPTION_SECRET).toString();
  }
  if (key === "token")
    cookies.set(key, saveData, { path: '/', sameSite: true });
  else
    global.localStorage.setItem(key, saveData);
};

export const getFromLocal = (key, isJson = true, isEncrypted = false) => {
  let data;
  if (key === "user")
  {
    data = cookies.get(key, { doNotParse: true });
    if (data === "set")
    {
      data = window.epiSettings.reactUser;
      global.localStorage.setItem(key, data);
      cookies.set(key, "authenticated", { path: '/', sameSite: true });
    } else if (data !== "authenticated") {
      data = null;
      global.localStorage.setItem(key, data);
      cookies.set(key, "", { path: '/', sameSite: true });
    } else {
      data = global.localStorage.getItem(key);
    }
  }
  else if (key === "token")
    data = cookies.get(key, { doNotParse: true });
  else
    data = global.localStorage.getItem(key);
  const isValidJson = isValidJSON(data);
  if (data && isEncrypted && isValidJson === false) {
    const bytes = CryptoJS.AES.decrypt(data.toString(), ENCRYPTION_SECRET);
    data = bytes.toString(CryptoJS.enc.Utf8);
  }
  if (data && isJson) {
    data = JSON.parse(data);
  }
  //console.log("Getting from local (" + key + "):");
  //console.log(JSON.stringify(data));
  return data;
};

export const removeFromLocal = (key) => {
  cookies.remove(key, { path: '/', sameSite: true });
};

export const existInLocal = key => cookies.get(key) != null;
