/**
 * luhn algorithm for Credit Card Validation
 * https://en.wikipedia.org/wiki/Luhn_algorithm
 */
const luhn = (cardNumber) => {
  const trimmed = String(cardNumber).replace(/[\s]/g, '');
  const length = trimmed.length; // eslint-disable-line
  let odd = false;
  let total = 0;
  let calc;
  let calc2;

  if (length === 0) {
    return true;
  }

  if (!/^[0-9]+$/.test(trimmed)) {
    return false;
  }

  for (let i = length; i > 0; i--) {
    calc = parseInt(trimmed.charAt(i - 1), 10);
    if (!odd) {
      total += calc;
    } else {
      const calcAux = calc * 2;

      switch (calcAux) {
        case 10: calc2 = 1; break;
        case 12: calc2 = 3; break;
        case 14: calc2 = 5; break;
        case 16: calc2 = 7; break;
        case 18: calc2 = 9; break;
        default: calc2 = calcAux;
      }
      total += calc2;
    }
    odd = !odd;
  }

  return ((total % 10) === 0);
};

export default luhn;
