import React, { Component } from "react";
import aircraftsList from "../../../utils/Aircrafts";
import AircraftDropList from "../AircraftDropList";

const cl350 = "/legacy/static/img/icons/CL350-icon.svg";
const cl605 = "/legacy/static/img/icons/CL605-icon.svg";
const cl850 = "/legacy/static/img/icons/CL850-icon.svg";
const gl5T = "/legacy/static/img/icons/GL5T-icon.svg";
const glex = "/legacy/static/img/icons/GLEX-icon.svg";

export const AIRCRAFT_ICONS = {
  CL350: cl350,
  CL605: cl605,
  CL850: cl850,
  GL5T: gl5T,
  GLEX: glex
};
const aircraftAlignment = {
  position: "relative",
  top: "3px"
};
class AircraftDropdown extends Component {
  constructor(props) {
    super(props);
    // this.aircrafts = aircraftsList;
    this.state = {
      selectedAircraft: null,
      openContractPopup: true,
      aircrafts: aircraftsList
    };
  }

  componentDidMount() {
    if (this.props.leg && this.props.leg.selectedAircraft) {
      this.setState({ selectedAircraft: this.props.leg.selectedAircraft });
      this.props.handleAircraftChange(this.props.leg.selectedAircraft);
    }
    if (this.props.aircraftsList.length > 0)
      this.setState({
        aircrafts: this.props.aircraftsList
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.aircraftsList.length > this.props.aircraftsList.length) {
      this.setState({
        aircrafts: nextProps.aircraftsList
      });
    }
    if (
      nextProps.leg &&
      (nextProps.leg.selectedAircraft !== this.state.selectedAircraft ||
        nextProps.leg.selectedAircraft !== this.props.leg.selectedAircraft)
      // && !nextProps.isWidgetFlow
    ) {
      this.setState({
        selectedAircraft: nextProps.leg.selectedAircraft,
        openContractPopup: false
      });
      // window.UIkit.dropdown('#airCraftdropDown').hide();
      this.props.handleAircraftChange(nextProps.leg.selectedAircraft);
    }
  }

  onAirCraftSelection = e => {
    const newAir = e.currentTarget.attributes["data-code"].nodeValue;
    const selectedAircraft = this.state.aircrafts.find(
      el => el.code === newAir
    );
    const { isEditFlow, passengers } = this.props;
    if (!(isEditFlow && selectedAircraft.maxNumberOfPassengers < passengers.length)) {
      // on aircraft change it will send call back to LegCard
      this.setState({
        selectedAircraft,
        openContractPopup: false
      });
    }
    // window.UIkit.dropdown('#airCraftdropDown').hide();
    this.props.handleAircraftChange(selectedAircraft);
  };

  getAircraftName = selectedAircraft => (
    <strong
      //className="font_standard--color font--caslon"
      style={aircraftAlignment}
    >
      {selectedAircraft ? selectedAircraft.displayName : "Select Aircraft"}
    </strong>
  );

  getAircraftImage = selectedAircraft =>
    selectedAircraft ? (
      <img
        alt={selectedAircraft.code}
        src={AIRCRAFT_ICONS[selectedAircraft.code]}
        className={`jet_selected jet_selected--${selectedAircraft.code}`}
      />
    ) : (
        <img
          alt="airplane-icon"
          src={glex}
          className={"jet_selected jet_selected--GLEX"}
        />
      );
  checkContractSelected = () => {
    // if (this.props.allowAircraftSelect || this.props.isWidgetFlow) {
    //   this.setState({
    //     openContractPopup: true
    //   });
    // } else {
    //   this.setState({
    //     openContractPopup: false
    //   });
    // }
    if (!this.props.isWidgetFlow) {
      this.props.validateContract();
    }
  };
  renderAircraftDropList = () => {
    const shouldOpenDropDown = (this.props.allowAircraftSelect || this.props.isWidgetFlow);
    if (shouldOpenDropDown) {
      return (
        <AircraftDropList
          aircrafts={this.state.aircrafts}
          selectedAircraftObj={
            this.state.selectedAircraft || this.props.leg.selectedAircraft
          }
          onAirCraftSelection={this.onAirCraftSelection}
          availableAircraftIds={this.props.availableAircraftIds || []}
          showAircraftInContract={this.props.showAircraftInContract}
          passengersCount={this.props.passengersCount}
        />
      );
      // window.UIkit.dropdown('#airCraftdropDown').show();
    }
  };

  render() {
    const { validation } = this.props;
    const { openContractPopup } = this.state;
    let aircraftClass = "aircraft_selector--dropdown putTabIndex";
    if (((validation || {}).errorOn || []).indexOf("aircraft") !== -1) {
      aircraftClass += " validation_error--field";
    }
    return (
      <div className="vistajet_aircraft_picker">
        <div id="aircraft-dropdown" onClick={this.checkContractSelected}>
          <div className="vistajet_aircraft_selector">
            {this.getAircraftImage(this.state.selectedAircraft)}
            {this.getAircraftName(this.state.selectedAircraft)}
          </div>
          {this.renderAircraftDropList()}
        </div>
      </div>
    );
  }
}

export default AircraftDropdown;
