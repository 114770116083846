/* eslint-disable no-undef */
import React from 'react';
import GoogleMap from './GoogleMap';
import MapMarker from './MapMarker';
import GeoLocation from '../utils/geolocation';
import { googleMapStyles } from 'components/MapStyle';

let INDEX = 0;
class OffersMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: {},
      routes: [],
      center: [37.2583353, -104.6531616],
      zoom: 1,
      map: '',
      mapHeight: this.mapSmallHeight,
      buttonText: 'keyboard_arrow_down',
    };
    this.getCenterLocation();
  }

  mapSmallHeight = 'auto';

  mapLargeHeight = 'auto';

  getCenterLocation = () => {
    GeoLocation().then((pos) => {
      const crd = pos.coords;
      this.setState({ center: [crd.latitude, crd.longitude] })
    }, () => { })
  }

  createMarkers(legs) {
    /**
     * Data Structure of marker will be
     * markers = {legIdentifier:
        {from: {lat: '', lng: '', airportId: ''},
        to: {lat: '', lng: '', airportId: }}
     */
    const markers = {};

    const bound = new google.maps.LatLngBounds();

    legs.forEach((leg) => {
      const legIdentifier = leg.id || leg.tempId || INDEX++;
      markers[legIdentifier] = {};

      if (leg.arrivalAirport) {
        // if arrival airport object is present in leg then set key in marker object
        markers[legIdentifier].to = {
          lat: leg.arrivalAirport.latitude,
          lng: leg.arrivalAirport.longitude,
          airportId: leg.arrivalAirport.id,
        };
        bound.extend(
          new google.maps.LatLng(
            leg.arrivalAirport.latitude,
            leg.arrivalAirport.longitude,
          ),
        );
      }

      if (leg.departureAirport) {
        // if departure airport object is present in leg then set key in marker object
        markers[legIdentifier].from = {
          lat: leg.departureAirport.latitude,
          lng: leg.departureAirport.longitude,
          airportId: leg.departureAirport.id,
        };
        bound.extend(
          new google.maps.LatLng(
            leg.departureAirport.latitude,
            leg.departureAirport.longitude,
          ),
        );
      }
    });

    const center = (bound && bound.getCenter && bound.getCenter()) || {};
    if (Object.keys(markers).length > 0 && this.map) {
      const worldCoordinateCenter = this.map.getProjection().fromLatLngToPoint(center);
      let mapWidth = document.getElementById("vistajet_offer_map_default");
      mapWidth = (mapWidth || {}).clientWidth;
      let offsetX = 270;
      if (mapWidth <= 510) {
        offsetX = 285;
      }
      let pixelOffset = new google.maps.Point(offsetX, 0);
      let worldCoordinateNewCenter = new google.maps.Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
      )
      let newCenter = this.map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
      const centerArray = [
        newCenter.lat && newCenter.lat(),
        newCenter.lng && newCenter.lng(),
      ];
      this.state.center = centerArray;
    }
    this.drawRoutes(markers);
    this.state.markers = markers;
    // this.setState({ markers });
  }

  drawRoutes(markers, map = this.map) {
    this.removeRoutes();
    const routes = {};

    Object.keys(markers).forEach((key) => {
      const marker = markers[key];

      if (marker.hasOwnProperty('from') && marker.hasOwnProperty('to')) {
        // only draw route when both locations are selected for this leg
        routes[key] = new google.maps.Polyline({
          strokeColor: '#c60c30',
          strokeOpacity: 0.8,
          strokeWeight: 2.5,
          icons: [
            {
              offset: '40%',
            },
          ],
          geodesic: true,
          map,
          path: [
            new google.maps.LatLng(marker.from.lat, marker.from.lng),
            new google.maps.LatLng(marker.to.lat, marker.to.lng),
          ],
        });
      }
    });

    // I need routes in state because whenever an airport is updated,
    // I create all the markers and their routes again
    // So previously created routes need to be removed from map
    this.state.routes = routes;
  }

  removeRoutes() {
    Object.keys(this.state.routes).forEach((key) => {
      this.state.routes[key].setMap(null);
    });
  }

  isReturnItinerary(marker, legId, type, legs) {
    const currentLeg = legs.find(leg => leg.id.toString() === legId) || {};
    return legs.some(
      leg => (((leg.departureAirport || {}).id || '')
        === ((currentLeg.arrivalAirport || {}).id || '')
        && type === 'to')
        || (((leg.arrivalAirport || {}).id || '')
          === ((currentLeg.departureAirport || {}).id || '')
          && type === 'from'),
    );
  }

  render() {
    const { isGoogleMapsApiLoaded, onGoogleMapsApiLoaded, legs } = this.props;
    const markersHtml = [];
    const self = this;
    if (isGoogleMapsApiLoaded) {
      this.createMarkers(legs);
      // Iterating over the markers and generating their html as required by the map plugin
      const markerKeys = Object.keys(this.state.markers);
      markerKeys.forEach((key) => {
        const marker = this.state.markers[key];

        if (marker.hasOwnProperty('from')) {
          const side = parseFloat((marker.from || {}).lng, 10)
            > parseFloat((marker.to || {}).lng, 10)
            ? 'left'
            : 'right';

          markersHtml.push(
            <MapMarker
              key={`${key} from`}
              lat={marker.from.lat}
              lng={marker.from.lng}
              type="from"
              side={side}
              isListing={this.props.isListing}
            />,
          );
        }

        if (marker.hasOwnProperty('to')) {
          const side = parseFloat((marker.from || {}).lng, 10)
            > parseFloat((marker.to || {}).lng, 10)
            ? 'left'
            : 'right';

          markersHtml.push(
            <MapMarker
              key={`${key} to`}
              lat={marker.to.lat}
              lng={marker.to.lng}
              side={side}
              type="to"
              isListing={this.props.isListing}
            />,
          );
        }
      });

      self.drawRoutes(this.state.markers);
    }

    function createMapOptions(maps) {
      return {
        zoomControlOptions: {
          position: maps.ControlPosition.RIGHT_BOTTOM,
          style: maps.ZoomControlStyle.SMALL,
        },
        mapTypeControlOptions: {
          position: maps.ControlPosition.TOP_RIGHT,
        },
        mapTypeControl: false,
        fullscreenControl: false,
        styles: googleMapStyles,
      };
    }
    return (
      <GoogleMap
        options={createMapOptions}
        // style={mapStyle}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          this.map = map;
          this.drawRoutes(this.state.markers);
          onGoogleMapsApiLoaded();
        }}
        center={this.state.center}
        zoom={this.state.zoom}
      >
        {markersHtml}
      </GoogleMap>
    );
  }
}

export default OffersMap;
