
import xml2js from "xml2js";
import CryptoJS from "crypto-js";
import { SALT } from "../configs/constants";

import { USER_ACCESS_TYPES } from "../constants/UserAccessTypes";
import { getPreferredAccount } from "utils/requestFlightUtils";
import { MEMBERSHIP_STATUS } from "../constants/MembershipConstants";
import {
  MEMBERSHIP_EXPIRED_CODE,
  USER_EXISTS,
  PHONE_NUMBER_EXISTS,
  LOGIN_FAILED_CODE,
  PAYMENT_NOT_DEFINED_CODE,
  ACCOUNT_NOT_FOUND_CODE,
  INVALID_ACCOUNT,
  UNAUTHORIZED,
  SERVER_UNRESOLVED,
  BAD_REQUEST,
  LEG_IN_PAST_CODE,
  UPDATE_FIRST_CODE,
  TIMEOUT_CODE,
  INTERNAL_SERVER_ERROR,
  PERMISSION_DENIED_CODE,
  GENERIC_EXCEPTION_CODE,
  INVALID_FEEDBACK_CODE,
  USER_UNAUTHENTICATED_CODE,
  EMAIL_EXISTS,
  FEEDBACK_EXIST,
  MULTI_LEG_OFFER_QOUTING_NA,
  ALREADY_LINKED_WITH_COMPANY,
  INVOICES_NOT_FOUND,
  RECORD_NOT_FOUND,
  PHONE_NUMBER_NOT_FOUND,
  USER_NOT_FOUND,
  DEVICE_ID_NULL,
  PHONE_NUMBER_NULL,
  USERNAME_NULL,
  RECORD_NOT_UPDATE,
  ACCOUNT_BLOCKED,
  INVALID_SIGNATURE,
  TIME_MISMATCH,
  MISSING_PARAMETER,
  USER_ALREADY_REGISTERED,
  INVALID_SOURCE,
  INVALID_TIME,
  CANNOT_ACCESS_STATEMENTS_CODE,
} from "../constants/ServerCodeConstants";
import {
  NETWORK_OFFLINE,
  ACCOUNT_NOT_FOUND,
  SESSION_EXPIRED,
  TIMEOUT,
  LEG_IN_PAST,
  PERMISSION_DENIED,
  UPDATE_FIRST,
  SOMETHING_UNEXPECTED,
  MEMBERSHIP_EXPIRED,
  PHONE_NUMBER_ALREADY_EXISTS,
  USER_ALREADY_EXISTS,
  GENERIC_EXCEPTION,
  INVALID_FEEDBACK,
  USER_NOT_AUTHENTICATED,
  LOGIN_FAILED,
  PAYMENT_NOT_DEFINED,
  EMAIL_ALREADY_EXISTS,
  CARD_TYPE_NOT_SUPPORTED,
  OFFER_QUOTING_NOT_AVAILABLE,
  ACCOUNT_EXPIRY_MESSAGE_RAF,
  INVOICES_NOT_FOUND_MESSAGE,
  RECORD_NOT_FOUND_MESSAGE,
  PHONE_NUMBER_NOT_FOUND_MESSAGE,
  USER_NOT_FOUND_MESSAGE,
  DEVICE_ID_NULL_MESSAGE,
  PHONE_NUMBER_NULL_MESSAGE,
  USERNAME_NULL_MESSAGE,
  RECORD_NOT_UPDATE_MESSAGE,
  ACCOUNT_BLOCKED_MESSAGE,
  INVALID_SIGNATURE_MESSAGE,
  TIME_MISMATCH_MESSAGE,
  MISSING_PARAMETER_MESSAGE,
  USER_ALREADY_REGISTERED_MESSAGE,
  INVALID_SOURCE_MESSAGE,
  INVALID_TIME_MESSAGE,
  CANNOT_ACCESS_STATEMENTS,
} from "../constants/MessageConstants";

export function parseBoolean(value) {
  return value === true || value === "true";
}

export function encryptData(token, plainTextParam) {
  const key = CryptoJS.MD5(token + SALT); // eslint-disable-line

  const plainText = CryptoJS.enc.Utf8.parse(plainTextParam);

  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    iv
  });
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export const handleServerErrors = statusCode => {
  if (!statusCode) {
    return NETWORK_OFFLINE;
  }
  if (statusCode == ACCOUNT_NOT_FOUND_CODE.code || statusCode == INVALID_ACCOUNT.code) {
    return ACCOUNT_NOT_FOUND;
  }
  if (statusCode == UNAUTHORIZED.code) {
    return SESSION_EXPIRED;
  }

  if (statusCode == TIMEOUT_CODE.code || statusCode == SERVER_UNRESOLVED.code) {
    return TIMEOUT;
  }

  if (statusCode == EMAIL_EXISTS.code) {
    return EMAIL_ALREADY_EXISTS;
  }

  if (statusCode >= BAD_REQUEST.code && statusCode <= INTERNAL_SERVER_ERROR.code) {
    return SOMETHING_UNEXPECTED;
  }

  if (statusCode == LEG_IN_PAST_CODE.code) {
    return LEG_IN_PAST;
  }

  if (statusCode == PERMISSION_DENIED_CODE.code) {
    return PERMISSION_DENIED;
  }

  if (statusCode == UPDATE_FIRST_CODE.code) {
    return UPDATE_FIRST;
  }

  if (statusCode >= INTERNAL_SERVER_ERROR.code) {
    return SOMETHING_UNEXPECTED;
  }

  if (statusCode == MEMBERSHIP_EXPIRED_CODE.code) {
    return MEMBERSHIP_EXPIRED;
  }

  if (statusCode == PHONE_NUMBER_EXISTS.code) {
    return PHONE_NUMBER_ALREADY_EXISTS;
  }

  if (statusCode == USER_EXISTS.code) {
    return USER_ALREADY_EXISTS;
  }

  if (statusCode == GENERIC_EXCEPTION_CODE.code) {
    return GENERIC_EXCEPTION;
  }

  if (statusCode == INVALID_FEEDBACK_CODE.code) {
    return INVALID_FEEDBACK;
  }
  if (statusCode == FEEDBACK_EXIST.code) {
    return FEEDBACK_EXIST;
  }

  if (statusCode == USER_UNAUTHENTICATED_CODE.code) {
    return USER_NOT_AUTHENTICATED;
  }

  if (statusCode == LOGIN_FAILED_CODE.code) {
    return LOGIN_FAILED;
  }

  if (statusCode == PAYMENT_NOT_DEFINED_CODE.code) {
    return PAYMENT_NOT_DEFINED;
  }

  // if (statusCode == INVALID_CARD_TYPE_CODE.code) {
  //   return CARD_TYPE_NOT_SUPPORTED;
  // }

  if (statusCode == ALREADY_LINKED_WITH_COMPANY.code) {
    return ACCOUNT_EXPIRY_MESSAGE_RAF;
  }

  if (statusCode == INVOICES_NOT_FOUND.code) {
    return INVOICES_NOT_FOUND_MESSAGE;
  }

  if (statusCode == RECORD_NOT_FOUND.code) {
    return RECORD_NOT_FOUND_MESSAGE;
  }

  if (statusCode == PHONE_NUMBER_NOT_FOUND.code) {
    return PHONE_NUMBER_NOT_FOUND_MESSAGE;
  }

  if (statusCode == USER_NOT_FOUND.code) {
    return USER_NOT_FOUND_MESSAGE;
  }

  if (statusCode == DEVICE_ID_NULL.code) {
    return DEVICE_ID_NULL_MESSAGE;
  }

  if (statusCode == PHONE_NUMBER_NULL.code) {
    return PHONE_NUMBER_NULL_MESSAGE;
  }

  if (statusCode == USERNAME_NULL.code) {
    return USERNAME_NULL_MESSAGE;
  }

  if (statusCode == RECORD_NOT_UPDATE.code) {
    return RECORD_NOT_UPDATE_MESSAGE;
  }

  if (statusCode == ACCOUNT_BLOCKED.code) {
    return ACCOUNT_BLOCKED_MESSAGE;
  }

  if (statusCode == INVALID_SIGNATURE.code) {
    return INVALID_SIGNATURE_MESSAGE;
  }

  if (statusCode == TIME_MISMATCH.code) {
    return TIME_MISMATCH_MESSAGE;
  }

  if (statusCode == MISSING_PARAMETER.code) {
    return MISSING_PARAMETER_MESSAGE;
  }

  if (statusCode == USER_ALREADY_REGISTERED.code) {
    return USER_ALREADY_REGISTERED_MESSAGE;
  }

  if (statusCode == INVALID_SOURCE.code) {
    return INVALID_SOURCE_MESSAGE;
  }

  if (statusCode == INVALID_TIME.code) {
    return INVALID_TIME_MESSAGE;
  }
  if (statusCode == MULTI_LEG_OFFER_QOUTING_NA.code) {
    return OFFER_QUOTING_NOT_AVAILABLE;
  }
  if (statusCode === CANNOT_ACCESS_STATEMENTS_CODE.code[0] ||
    statusCode === CANNOT_ACCESS_STATEMENTS_CODE.code[1]) {
    return CANNOT_ACCESS_STATEMENTS;
  }


  return SOMETHING_UNEXPECTED;
};

// function parseNumbers(str) {
//   if (typeof +str === "number") {
//     const number = str % 1 === 0 ? parseInt(str, 10) : parseFloat(str);
//     // garantee that left zeros or space remain as string
//     if (`${number}`.length === str.length) {
//       return number;
//     }
//   }
//   return str;
// }

const parser = new xml2js.Parser({
  explicitArray: false,
  trim: true,
  valueProcessors: [
    parseNumbers, // parse numbers must always be set before parse booleans
    xml2js.processors.parseBooleans
  ]
});

const parserPascalCase = new xml2js.Parser({
  explicitArray: false,
  trim: true,
  valueProcessors: [
    parseNumbers, // parse numbers must always be set before parse booleans
    xml2js.processors.parseBooleans
  ],
  tagNameProcessors: [xml2js.processors.firstCharLowerCase]
});

export function parseString(...args) {
  parser.parseString.apply(this, args);
}

// export function parsePascalCaseString(...args) {
//   parserPascalCase.parseString.apply(this, args);
// }

const parserIgnoreNumber = new xml2js.Parser({
  explicitArray: false,
  trim: true,
  valueProcessors: [xml2js.processors.parseBooleans],
  tagNameProcessors: [xml2js.processors.firstCharLowerCase]
});

export function parserPascalCaseIgnoreNumber(...args) {
  parserIgnoreNumber.parseString.apply(this, args);
}

const builder = new xml2js.Builder({
  xmldec: {
    version: "1.0",
    encoding: "UTF-8",
    standalone: undefined
  }
});

export function builderXmlRequest(obj) {
  return builder.buildObject(obj);
}

export function isArray(obj) {
  return obj.constructor === Array;
}

export function garanteeArray(obj) {
  if (obj) {
    return isArray(obj) ? obj : [obj];
  }
  return [];
}

export function encodeData(data) {
  return Object.keys(data).map((key) => (
    [key, data[key]].map(encodeURIComponent).join('=')
  )).join('&');
}
export function normalizePhoneNumber(phoneNumber) {
  return {
    id: (phoneNumber && phoneNumber.id) || "",
    phoneNumber: (phoneNumber && `${phoneNumber.phoneNumber}`) || ""
  };
}

export function accessType(accounts = []) {
  const ids = [];
  accounts.forEach((account) => {
    if ((account.typeId === USER_ACCESS_TYPES.MEMBER &&
      account.membershipStatus === MEMBERSHIP_STATUS.ACTIVE) ||
      (account.typeId === USER_ACCESS_TYPES.MEMBER &&
        account.membershipStatus === MEMBERSHIP_STATUS.ADHOC) ||
      account.typeId === USER_ACCESS_TYPES.FSP
    ) {
      ids.push(account.typeId);
    }
  });

  const preferredContract = getPreferredAccount(accounts);

  if (
    preferredContract &&
    preferredContract.typeId === USER_ACCESS_TYPES.MEMBER &&
    (preferredContract.membershipStatus === MEMBERSHIP_STATUS.ACTIVE ||
      preferredContract.membershipStatus === MEMBERSHIP_STATUS.ADHOC)
  ) {
    return USER_ACCESS_TYPES.MEMBER;
  }
  if (
    (ids.includes(USER_ACCESS_TYPES.FSP) &&
      ids.includes(USER_ACCESS_TYPES.MEMBER)) ||
    ids.includes(USER_ACCESS_TYPES.FSP)
  ) {
    return USER_ACCESS_TYPES.FSP;
  }
  if (ids.includes(USER_ACCESS_TYPES.MEMBER)) {
    return USER_ACCESS_TYPES.MEMBER;
  }
  return USER_ACCESS_TYPES.GUEST;
}

export function membershipType(accounts = []) {
  const membershipAccount =
    accounts.find(
      account =>
        account.typeId === USER_ACCESS_TYPES.MEMBER &&
        account.membershipStatus === MEMBERSHIP_STATUS.ACTIVE
    ) || {};
  return membershipAccount.membershipTypeId || "";
}

export function findMembershiptAccountByStatus(accounts = [], status = "") {
  return (
    accounts.find(
      account =>
        account.typeId === USER_ACCESS_TYPES.MEMBER &&
        account.membershipStatus === status
    ) || {}
  );
}

export function findActiveMembershipAccount(accounts) {
  return findMembershiptAccountByStatus(accounts, MEMBERSHIP_STATUS.ACTIVE);
}

export function findPendingMembershipAccount(accounts) {
  return findMembershiptAccountByStatus(accounts, MEMBERSHIP_STATUS.NEW);
}

export function findAdhocMembershipAccount(accounts) {
  return findMembershiptAccountByStatus(accounts, MEMBERSHIP_STATUS.ADHOC);
}

export function accountMembershipStatus(accounts = []) {
  return (
    findActiveMembershipAccount(accounts).membershipStatus ||
    findPendingMembershipAccount(accounts).membershipStatus ||
    findAdhocMembershipAccount(accounts).membershipStatus ||
    ""
  );
}

export function hasVjDirectAccount(accounts = []) {
  const ids = [];
  let vjAccount = {};
  accounts.forEach(account => {
    ids.push(account.typeId);
    if (
      account.typeId === USER_ACCESS_TYPES.MEMBER &&
      account.membershipStatus === MEMBERSHIP_STATUS.ACTIVE
    ) {
      vjAccount = account;
    }
  });
  if (vjAccount.isPreferred) {
    return false;
  }
  if (
    ids.includes(USER_ACCESS_TYPES.FSP) &&
    ids.includes(USER_ACCESS_TYPES.MEMBER) &&
    vjAccount.membershipStatus === MEMBERSHIP_STATUS.ACTIVE &&
    !vjAccount.isPreferred
  ) {
    return true;
  }
  return false;
}

export function accountsAircraftList(accounts = []) {
  const aircrafts = [];
  accounts.forEach(account => {
    (account.aircraftTypeDtos || []).forEach(aircraft => {
      aircrafts.push({
        name: aircraft.name,
        displayName: aircraft.displayName,
        code: aircraft.code,
        aircraftTypeId: aircraft.id,
        id: aircraft.id
      });
    });
  });
  return aircrafts;
}

function parseNumbers(str) {
  if (!isNaN(str)) {
    const number = str % 1 === 0 ? parseInt(str, 10) : parseFloat(str);
    // garantee that left zeros or space remain as string
    if (`${number}`.length === str.length) {
      return number;
    }
  }
  return str;
}

// const parserPascalCase = new xml2js.Parser({
//   explicitArray: false,
//   trim: true,
//   valueProcessors: [
//     parseNumbers, // parse numbers must always be set before parse booleans
//     xml2js.processors.parseBooleans,
//   ],
//   tagNameProcessors: [
//     xml2js.processors.firstCharLowerCase,
//   ],
// });

export function parsePascalCaseString(...args) {
  parserPascalCase.parseString.apply(this, args);
}

// export function filterAccounts(accounts = []) {
//   const filteredAccounts = accounts.filter(account =>
//     account.typeId === USER_ACCESS_TYPES.MEMBER
//     && account.membershipStatus === MEMBERSHIP_STATUS.ACTIVE
//     || account.typeId === USER_ACCESS_TYPES.FSP
//   );
//   return filteredAccounts || [];
// }
