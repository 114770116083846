import React, { Component } from 'react';
import moment from 'moment';
import { getFiles } from '../utils/fileUtils';
import { convertToTitleCase } from '../utils/stringUtils';
import { scrollFunctionForTable } from '../utils/Helpers';
import SpinnerLoader from './shared/spinnerLoader';
import SkeletonLoaderAirport from '../components/shared/SkeletonLoaderAirport';

export default class CurrentBalance extends Component {
  isLoading = () => {
    const {
      isOtherFlight = false,
      contract: {
        accountId,
        aircraftTypeId,
      } = {},
      otherAircrafts = [],
      loadingMoreInvoices = [],
    } = this.props;
    if (isOtherFlight) {
      return loadingMoreInvoices
        .some(invoice => !!otherAircrafts.find(({ accountId, aircraftTypeId }) =>
          invoice.accountId === accountId &&
          invoice.aircraftTypeId === aircraftTypeId
        ));
    }
    return !!loadingMoreInvoices
      .find(invoice => (
        invoice.accountId === accountId &&
        invoice.aircraftTypeId === aircraftTypeId
      ));
  }

  shouldShowLoadMore = () => {
    const {
      isOtherFlight = false,
      otherContracts = [],
      contract = {},
    } = this.props;

    if (isOtherFlight) {
      return !!(otherContracts.filter(({ noMoreInvoices }) => !noMoreInvoices).length);
    } return !contract.noMoreInvoices;

  }

  getOtherFlights = contracts => contracts && contracts.filter(contract => !contract.aircraftName);

  getOtherInvoices = flights => {
    return flights.reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.invoice,
      ]
    }, []);
  };

  fetchMoreInvoices = () => {
    const {
      token,
      contract: {
        accountId,
        aircraftTypeId,
        invoice,
      } = {},
      fetchMoreInvoices,
      isOtherFlight = false,
      otherContracts = [],
    } = this.props;
    if (isOtherFlight) {
      otherContracts.forEach((flight = {}) =>
        fetchMoreInvoices(token, (flight.invoice || []).length, flight.aircraftTypeId, flight.accountId))
    } else {
      fetchMoreInvoices(token, invoice.length, aircraftTypeId, accountId);
    }
  }

  getAirportName = airport => {
    const { visitedAirportsFinder } = this.props;
    if (!visitedAirportsFinder) return '';
    const currentAirport = visitedAirportsFinder[airport] || {};
    return `${convertToTitleCase(currentAirport.name || '')} (${currentAirport.icao || ''})`;
  }

  renderBalanceAsOf = ({ createdDate = '' }) => {
    return (
      createdDate ?
      <p class="vistajet_small_text uk-margin-remove">
        Balance as of: <span className="vistajet_bold_text">{createdDate}</span>
      </p>
      :
      <p class="vistajet_small_text uk-margin-remove">No Statements Found.</p>
    )
  }


renderInvoiceRow = ({ deptUtc, description, legHoursDeducted, fileKey }, otherFlightSelected = false) => (
  <tr>
    <td class="vistajet_light_text uk-padding-remove-left uk-table-shrink uk-text-nowrap">
      {moment(deptUtc).format('DD MMM YYYY')}
    </td>
    <td class="vistajet_light_text uk-table-expand uk-text-truncate vistajet_max_width_130" uk-tooltip={`title: ${description}`}>
      {description}
    </td>
    <td class="uk-text-right vistajet_dark_text uk-padding-remove-left uk-text-nowrap">{(+legHoursDeducted).toFixed(2)} Hrs</td>
    <td className={otherFlightSelected ? '' : 'uk-padding-remove-right'}>
      <ul class="uk-iconnav uk-flex-right uk-visible@m">
        <li><a
          className="vistajet_eye_icon"
          onClick={() => window.open(getFiles(fileKey, this.props.token || '', false), '_blank')}
        ></a></li>
        <li><a
          className="vistajet_pdf_icon"
          onClick={() => window.open(getFiles(fileKey, this.props.token || '', false, true), '_blank')}
        ></a></li>
      </ul>
      <ul class="uk-iconnav uk-flex-right uk-hidden@m">
        <li><button class="vistajet_more_icon" type="button"></button></li>
        <div uk-dropdown="mode: click; pos: bottom-right" class="uk-margin-remove vistajet_iconnav_dropdown">
          <ul class="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
            <li className="uk-text-truncate"><a
              className="vistajet_eye_icon"
              onClick={() => window.open(getFiles(fileKey, this.props.token || '', false), '_blank')}
            ><span>View</span></a></li>
            <li className="uk-text-truncate"><a
              className="vistajet_pdf_icon"
              onClick={() => window.open(getFiles(fileKey, this.props.token || '', false, true), '_blank')}
            ><span>Download</span></a></li>
          </ul>
        </div>
      </ul>
    </td>
  </tr>
)

render() {
  const {
    contract: {
      createdDate = '',
      contractStartDate,
      contractEndDate,
      hoursForYear = 0,
      hoursFlown = 0,
      remainingHours = 0,
      noMoreInvoices = true,
      invoice = [],
    } = {},
    lastStatement = {},
    loadingAccountStatements = false,
    isOtherFlight = false,
    otherContracts = [],
    otherFlightSelected = false,
  } = this.props;
  const invoices = isOtherFlight ? this.getOtherInvoices(otherContracts) : invoice;
  return (
    <div>
      {!isOtherFlight && (
        <div class="uk-grid-collapse vistajet_balance_detail" data-uk-grid>
          <div class="uk-width-1-2@s">
            <p class="vistajet_small_text uk-margin-remove">Contract Date: <span class="vistajet_bold_text">
              {moment(contractStartDate).format('DD MMM YYYY')} - {moment(contractEndDate).format('DD MMM YYYY')}
            </span></p>
            <p class="vistajet_small_text uk-margin-remove">Contracted Hours: <span class="vistajet_bold_text">
              {hoursForYear}
            </span></p>
            {
              loadingAccountStatements ?
                <SkeletonLoaderAirport /> :
                this.renderBalanceAsOf(lastStatement)
            }
          </div>
          <div class="uk-width-1-2@s vistajet_time_duration_container">
            <div class="uk-grid-collapse vistajet_time_duration_wrapper" data-uk-grid>
              <div>
                <h3 class="vistajet_hours vistajet_red_text uk-margin-remove">{hoursFlown}</h3>
                <p class="vistajet_dark_text uk-margin-remove">Hours Flown</p>
              </div>
              <div>
                <h3 class="vistajet_hours vistajet_green_text uk-margin-remove">{remainingHours}</h3>
                <p class="vistajet_dark_text uk-margin-remove">Hours Remaining</p>
              </div>
            </div>
            <progress id="js-progressbar" class="uk-progress vistajet_time_progress" value={hoursFlown}
              max={hoursForYear}></progress>
          </div>
        </div>
      )}

      <div>
        <p class="vistajet_dark_sub_heading uk-margin-small-top uk-margin-remove-bottom">Invoices</p>
        {
          !invoices.length ?
            <div>No Invoices Found</div> :
            <div class="uk-overflow-auto vistajet_table_wrapper">
            { scrollFunctionForTable && scrollFunctionForTable() }
              <table class="uk-table uk-table-small">
                <tbody>
                  {invoices.map(invoice => this.renderInvoiceRow(invoice, otherFlightSelected))}
                </tbody>
              </table>
            </div>
        }
        {this.shouldShowLoadMore() &&
          <div class="vistajet_bottom_button_wrapper" data-uk-grid>
            <div class="uk-width-1-2@s">
              <button
                disabled={this.isLoading()}
                className="uk-button vistajet_button_default"
                onClick={this.fetchMoreInvoices}
              >{
                  this.isLoading() ?
                    <SpinnerLoader /> : 'Load More'
                }</button>
            </div>
            <div class="uk-width-1-2@s uk-margin-remove uk-text-right@s"></div>
          </div>
        }
      </div>
    </div>
  );
}
}
