import {
  validateCVV,
  validateExpiryDate,
  validateCreditCardNumber,
} from './Validators';

export default function validateAddCreditCardDetailsForm(obj) {
  let cvvResponse = null; 
  let creditCardNumberResponse = null;
  if (obj.hasOwnProperty('creditCardNumber')) {
    creditCardNumberResponse = validateCreditCardNumber(obj.creditCardNumber);
    cvvResponse = validateCVV(obj.cvv, obj.creditCardNumber);
  } else {
    cvvResponse = validateCVV(obj.cvv, null, obj.brandId);
  }
  const expiryDateResponse = validateExpiryDate(obj.expiryDate);
  const res = {
    errors: {},
  };
  if (cvvResponse.isError) {
    res.isError = cvvResponse.isError;
    res.errors.cvv = cvvResponse.message;
  }
  if (expiryDateResponse.isError) {
    res.isError = expiryDateResponse.isError;
    res.errors.expiryDate = expiryDateResponse.message;
  }
  if (creditCardNumberResponse && creditCardNumberResponse.isError) {
    res.isError = creditCardNumberResponse.isError;
    res.errors.creditCardNumber = creditCardNumberResponse.message;
  }
  return res;
}
