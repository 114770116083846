import React, { Component } from 'react';
import AddBillingAddressModal from './AddBillingAddressModal';
import SpinnerLoader from '../shared/spinnerLoader';
import MessagePopUp from '../shared/MessagePopUpNew';
import countryData from '../../constants/CountryConstants';
import { capitalize } from '../../utils/stringUtils';

export default class BillingAddress extends Component {

  componentWillReceiveProps(nextProps) {
    const { isBillingAddressError } = nextProps;
    if (isBillingAddressError && isBillingAddressError !== this.props.isBillingAddressError) {
      this.messagePopup.show();
    }
  }

  render() {
    const { billingAddress, isBillingAddressLoading, isBillingAddressError, saveBillingAddress } = this.props;
    const { address, city, state, postalCode, countryCode } = billingAddress;
    let { country } = billingAddress;
    if (countryCode) {
      country = countryData.filter(country_ => country_.THREE_LETTER_CODE === countryCode)[0].COUNTRY_NAME;
      country = capitalize(country);
    }
    return (
      <div class="uk-width-1-1 uk-flex uk-grid-small uk-margin-small-top" uk-grid>
        <div class="vistajet_billing_address">
          <p className="vistajet_spm">Billing Address:</p>
          {isBillingAddressLoading ?
            (<div className="vistajet_spinner">
              <SpinnerLoader />
            </div>) : (
              <div>
                {
                  Object.keys(billingAddress).length ?
                    <span className="vistajet_billing_address_text">{address}, {city}, {state || ''}, {country}, {postalCode || ''}</span>
                    :
                    <div>
                      <span className="vistajet_billing_address_text">No billing address.</span>
                      <p className="vistajet_add_credit_card_wrapper">
                        <a
                          class="vistajet_add_credit_card"
                          onClick={() => this.addBillingAddress.show()}
                          uk-toggle
                        >
                          Add New
                        </a>
                      </p>
                    </div>
                }
              </div>)
          }
        </div>
        {!isBillingAddressLoading && <AddBillingAddressModal
          ref={(addBillingAddress) => { this.addBillingAddress = addBillingAddress; }}
          user={this.props.user}
          billingAddress={billingAddress}
          isBillingAddressLoading={isBillingAddressLoading}
          saveBillingAddress={saveBillingAddress}
        />}
        {!isBillingAddressLoading && <MessagePopUp
          ref={(messagePopup) => { this.messagePopup = messagePopup; }}
          content={isBillingAddressError}
        />}
      </div>
    )
  }

}
