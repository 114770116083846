import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStore from '../../components/withStore';
import ExpiredQuotes from '../../components/Quotes/ExpiredQuotes';

function mapStateToProps(state) {
  const { isGoogleMapsApiLoaded } = state.googleMap;
  const { isAirportByIdLoading, visitedAirportsFinder, error } = state.visitedAirports;
  const { isQuoteLoading, isQuoteLoadMoreLoading, expiredQuotes, quoteError } = state.quotes;
  const { user } = state.user;
  const { aircraftsList } = state.aircrafts;
  return {
    user,
    isAirportByIdLoading,
    visitedAirportsFinder,
    isGoogleMapsApiLoaded,
    isQuoteLoading,
    isQuoteLoadMoreLoading,
    quotes: expiredQuotes,
    error: quoteError,
    airportError: error,
    aircraftsList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAirportById: id => dispatch(actions.getAirportById(id)),
    onGoogleMapsApiLoaded: () => dispatch(actions.onGoogleMapsApiLoaded()),
    fetchQuotes: (quoteType, pageNum) => dispatch(actions.fetchQuotes(quoteType, pageNum)),
  };
}

const ExpiredQuotesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpiredQuotes);

export default withStore(ExpiredQuotesContainer);
