import React, { Component } from 'react';
import { ENTERTAINMENT_DETAILS_PAGE_URL } from '../../configs/constants';
import SpinnerLoader from '../shared/spinnerLoader';
class EntertainmentCard extends Component {
    render () {
        const {
            legKey,
            itineraryType,
            orderExtIdentifier,
            legId,
            tailNumber,
            isLoading
        } = this.props;
        const entertainmentUrl = ENTERTAINMENT_DETAILS_PAGE_URL.url.replace(
            '{key}',
            legKey,
          ).replace(
            '{orderExtendifier}',
            orderExtIdentifier,
          ).replace(
            '{flightLegExtendifier}',
            legId,
          ).replace(
            '{itineraryType}',
            itineraryType,
          ).replace(
            '{tailNumber}',
            tailNumber,
          );        
        return (
            <div class="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove">
                <div class="vistajet_itinerary_card_header">
                    <h5 class="uk-width-expand">Entertainment</h5>
                    {
                        !isLoading ? (
                            <a class="vistajet_button_default uk-margin-remove" href={entertainmentUrl} target="_blank">View Entertainment</a>

                        ) : null
                    }
                    </div>
                <div class="vistajet_itinerary_card_body">
                {
                    !isLoading ? (
                            <p class="uk-margin-remove">
                                Preview your in-flight entertainment selection here.
                            </p>
                    ) : (
                        <div className="vistajet_spinner">
                            <SpinnerLoader />
                        </div>
                    )
                }
                </div>
            </div>
        );
    } 
}

export default EntertainmentCard;