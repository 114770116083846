
const IsAuthenticated = (props) => {
  const { user, children } = props
  if (user && user.isLoggedIn) {
    return children;
  }
  if (user && !user.userHasLoggedOut) {
    const { pathname, search } = window.location;
    const route = pathname + search;
    window.location = `/?notAuthenticated=true&from=${route}`;
    return null;
  }
  return null;
}

export default IsAuthenticated;