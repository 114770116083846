import axios from 'axios';
import { FETCH_FBO_URL } from '../../configs/constants';
import {
    fetchFboInit,
    fetchFboSuccess,
    fetchFboFail,
} from '../actions';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';
import { getFromLocal } from '../../utils/cache';

const status = {
  OK: 200,
};

const fetchFboPromiseInprogress = {};

export const fetchFbo = (token, legId) => (dispatch) => {
  const uniqueKey = `${legId}`;
  if (fetchFboPromiseInprogress[uniqueKey]) {
    return fetchFboPromiseInprogress[uniqueKey];
  }
  const fetchFboPromise = new Promise((resolve, reject) => {
    const url = FETCH_FBO_URL.replace('{token}', token).replace('{flightLegId}', legId);
    dispatch(fetchFboInit(legId));
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          dispatch(fetchFboSuccess(response.data, legId));
          resolve(response.data);
        } else {
          const errors = handleServerErrors(response.status);
          dispatch(fetchFboFail(errors, legId));
          reject(errors);
        }
        delete fetchFboPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(fetchFboFail(errors, legId));
        reject(errors);
        delete fetchFboPromiseInprogress[uniqueKey];
      });
  });
  fetchFboPromiseInprogress[uniqueKey] = fetchFboPromise;
  return fetchFboPromise;
};

