import * as types from '../actionTypes';

const initialState = {
  isAirportsLoading: false,
  isAirportByIdLoading: false,
  visitedAirports: [],
  visitedAirportsFinder: {},
  error: '',
  isAirportsFetched: false,
};

export default function visitedAirportsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_VISITED_AIRPORTS_INIT: {
      return Object.assign({}, state, {
        isAirportsLoading: true,
        error: '',
      });
    }
    case types.FETCH_VISITED_AIRPORTS_SUCCESS: {
      const visitedAirportsFinder = { ...state.visitedAirportsFinder, ...action.data };
      const visitedAirports = Object.values(visitedAirportsFinder);
      return Object.assign({}, state, {
        isAirportsLoading: false,
        visitedAirports,
        visitedAirportsFinder,
        error: '',
        isAirportsFetched: true,
      });
    }
    case types.FETCH_VISITED_AIRPORTS_FAIL: {
      return Object.assign({}, state, {
        isAirportsLoading: false,
        visitedAirports: [],
        error: action.data,
        isAirportsFetched: false,
      });
    }
    case types.FETCH_AIRPORT_BY_ID_INIT: {
      return Object.assign({}, state, {
        isAirportByIdLoading: true,
        error: '',
      });
    }
    case types.FETCH_AIRPORT_BY_ID_SUCCESS: {
      const visitedAirportsFinder = { ...state.visitedAirportsFinder, ...action.data };
      const visitedAirports = Object.values(visitedAirportsFinder);
      return Object.assign({}, state, {
        isAirportByIdLoading: false,
        visitedAirports,
        visitedAirportsFinder,
        error: '',
      });
    }
    case types.FETCH_AIRPORT_BY_ID_FAIL: {
      return Object.assign({}, state, {
        isAirportByIdLoading: false,
        error: action.data,
      });
    }
    default:
      return state;
  }
}
