import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Helpers from '../../utils/Helpers';
import { LEG_ARRAY } from '../../constants/localStorageKeys';
import 'react-datepicker/dist/react-datepicker.css';
import Stepper from 'components/Stepper';
import AirportSelectorWrapper from 'components/PlanAFlight/Wrappers/AirportSelectorWrapper';
import { saveToLocal } from '../../utils/cache';
import { PAF_PAGE_URL } from '../../configs/constants';
import 'rc-slider/assets/index.css';
import ProgramBuilderRangeSlider from './ProgramBuilderRangeSlider';

class ProgramBuilderWidget extends Component {
  static propTypes = {
    fetchVisitedAirports: PropTypes.func,
    visitedAirports: PropTypes.instanceOf(Array),
  };

  static defaultProps = {
    fetchVisitedAirports: undefined,
    visitedAirports: [],
  };

  constructor(props) {
    super(props);
    this.orderExtIdentifier = Helpers.guid();
    this.initialLegStruct = {
      prevLeg: null,
      guid: Helpers.guid(),
      orderExtIdentifier: this.orderExtIdentifier,
      date: null,
      selectedAircraft: null,
      time: '',
      distance: null,
      duration: null,
      years: '',
      airportDistance: null,
      departureAirport: null,
      arrivalAirport: null,
      departTime: 'Invalid date',
      arrivalTime: 'Invalid date',
      minutes: '',
      hour: '',
      bookedFlightId: 0,
      passengersCount: 1,
      version: 0,
      isCanceled: false,
      isEditableLeg: true,
      isRequestFailed: false,
      isReturn: false,
      isWidgetFlow: false,
      validation: {
        isError: false,
        errorMsg: '',
        errorOn: {
          departure: null,
          arrival: null,
          departureArrival: null,
          departureArrivalSame: null,
          time: null,
          passenger: null,
          aircraft: null,
          date: null,
        },
      },
    };

    this.state = {
      legArray: [{ ...this.initialLegStruct }],
      disableReturn: true,
      popupErrorMessages: [],
    };
  }

  componentDidMount() {
    if (!this.props.isAirportsFetched) {
      this.props.fetchVisitedAirports();
    }
    this.props.fetchVisitedAirports();
  }

  handleAirportSelect = (value, source, index) => {
    let { disableReturn } = this.state;
    const { legArray } = this.state;
    if (source === 'departure') {
      legArray[index].departureAirport = value;
      this.handleDurationChange(index);
      this.updateAiportsTime(index);
      if (legArray[index].passengersCount)
        this.updateAirCraftDetails(index);
    } else {
      legArray[index].arrivalAirport = value;
      if (legArray[index].departureAirport !== null) {
        disableReturn = false;
      }
      this.handleDurationChange(index);
      this.updateAiportsTime(index);
      if (legArray[index].passengersCount)
        this.updateAirCraftDetails(index);
    }
    this.setState({ legArray, disableReturn });
  };

  handleStepperChange = (value, legIndex) => {
    const { legArray } = this.state;
    legArray[legIndex].passengersCount = value;
    this.setState({ legArray });
    this.updateAirCraftDetails(legIndex);
  };

  selectPrefferedAirCraft = (legArray) => {
    let aircraftsList = Helpers.copy(this.props.aircraftsList);

    let maxPassengerCount = Math.max(...legArray.map((leg) => {
      return leg.passengersCount;
    }));

    let maxFlightDistance = Math.max(...legArray.map((leg) => {
      return leg.distance;
    }));

    /*Sort AirCrafts on the Basis on Range*/
    aircraftsList = aircraftsList.sort(function (a, b) {
      return a.maxRangeKm - b.maxRangeKm;
    });

    /*Filter on the basis of Range and Passenger*/
    const filter = {
      maxNumberOfPassengers: maxPassengerCount,
      maxRangeKm: maxFlightDistance,
    };

    let selectedAirCraft = aircraftsList.filter(function (item) {
      return item.maxNumberOfPassengers >= filter.maxNumberOfPassengers &&
        item.maxRangeKm >= filter.maxRangeKm
    }).shift();

    if (!selectedAirCraft) {
      selectedAirCraft = aircraftsList.pop();
    }

    return selectedAirCraft;
  };

  updateAirCraftDetails = (index) => {
    const { legArray } = this.state;

    this.handleDistanceChange(index);
    const prefferedAirCraft = this.selectPrefferedAirCraft(legArray);

    /*Put Selected Aircraft for all legs*/
    legArray.map((leg) => {
      leg.selectedAircraft = prefferedAirCraft;
      return leg;
    });

    this.handleDurationChange(index);
    this.updateAiportsTime(index);
    this.setState({
      legArray,
    });
  };

  handleDurationChange(index) {
    const { legArray } = this.state;
    if (
      legArray[index].departureAirport !== null
      && legArray[index].arrivalAirport !== null
      && legArray[index].selectedAircraft !== null
    ) {
      legArray[index].duration = Helpers.getFlightDuration(
        legArray[index].departureAirport,
        legArray[index].arrivalAirport,
        legArray[index].selectedAircraft,
      );
      this.setState({
        legArray,
      });
    }
  }

  handleDistanceChange(index) {
    const { legArray } = this.state;
    if (
      legArray[index].departureAirport !== null
      && legArray[index].arrivalAirport !== null
    ) {
      legArray[index].distance = Helpers.getAirportDistance(
        legArray[index].departureAirport,
        legArray[index].arrivalAirport
      );
      this.setState({
        legArray,
      });
    }
  }

  updateAiportsTime(legIndex) {
    const { legArray } = this.state;
    const leg = legArray[legIndex];
    const tmpObj = {
      departTime: 'Invalid date',
      arrivalTime: 'Invalid date',
      legIndex,
    };
    let departTime = 'etd';
    let arrivalTime = 'eta';
    if (!!leg.time && !!leg.date) {
      const datetimeString = `${moment.utc(leg.date).format('DD/MM/YYYY')} ${leg.time}`;
      if (leg.departureAirport !== null) {
        departTime = moment(`${datetimeString} +0000`, 'DD/MM/YYYY HH:mm Z')
          .utc()
          .format();

        tmpObj.departTime = departTime;
      }
      if (
        leg.departureAirport
        && leg.arrivalAirport
        && departTime
        && leg.selectedAircraft
      ) {
        arrivalTime = Helpers.getArrivalTime(
          departTime,
          leg.duration,
          leg.departureAirport,
          leg.arrivalAirport,
        );
        tmpObj.arrivalTime = arrivalTime;
      }
      legArray[legIndex].departTime = tmpObj.departTime;
      legArray[legIndex].arrivalTime = tmpObj.arrivalTime;

      this.setState({ legArray });
    }
  }

  refreshFormFields = (isReturnAdded = false) => {
    const { legArray } = this.state;
    legArray.push({
      ...this.initialLegStruct,
      isReturn: isReturnAdded,
      prevLeg: legArray[legArray.length - 1],
      departureAirport: isReturnAdded,
      arrivalAirport: isReturnAdded
        ? legArray[legArray.length - 1].departureAirport
        : null,
    });
    this.setState({
      legArray,
      disableReturn: !isReturnAdded,
    });
  };

  handleAddFlight = () => {
    const { legArray } = this.state;
    let isAddFlight = [];
    isAddFlight = legArray.map(flight => this.formValidatiion(flight));
    if (
      isAddFlight.every((isAddFlight) => {
        return isAddFlight;
      })
    ) {
      this.refreshFormFields();
    }
  };

  fieldValidator = value => {
    if (value === '' || value === null || value === [] || value === 0) {
      return false;
    }
    return true;
  };

  formValidatiion = flightInfo => {
    if (
      this.fieldValidator(flightInfo.departureAirport)
      && this.fieldValidator(flightInfo.arrivalAirport)
      && this.fieldValidator(flightInfo.passengersCount)
      && this.fieldValidator(flightInfo.years)
    ) {
      return true;
    }
    return false;
  };

  submitLegArraytoProgramBuilder = () => {
    const { legArray } = this.state;
    const { onChange } = this.props;
    onChange({ legArray });
  };

  /* TODO */
  handlePlan = () => {
    const { legArray } = this.state;
    const { isLoggedIn } = this.props;
    const legs = legArray.map(leg => ({
      ...leg,
      isWidgetFlow: true,
    }));
    if (isLoggedIn) {
      saveToLocal(legs, LEG_ARRAY);
      window.open(PAF_PAGE_URL, '_self');
    } else {
      this.setState({
        legArray: legs,
      });
      window.UIkit.modal('#PAFModal').show();
    }
  };

  removeFlight = index => {
    const copyFlightArray = [...this.state.legArray];
    copyFlightArray.splice(index, 1);
    this.setState({ legArray: copyFlightArray });
  };

  renderButtons() {
    const { legArray } = this.state;
    let isFormSubmitable = false;
    let isButtonEnable = false;
    if (legArray.length > 0) {
      legArray.forEach(leg => {
        isFormSubmitable = this.formValidatiion(leg);
      });
    }
    const disableButtonClass = 'vistajet_button_disable';
    if (isFormSubmitable) {
      isButtonEnable = true;
      this.submitLegArraytoProgramBuilder();
    }
    return (
      <div className="vistajet_plan_a_flight_button">
        <button
          className={
            isButtonEnable
              ? 'uk-button vistajet_button_default'
              : `uk-button vistajet_button_default ${disableButtonClass}`
          }
          onClick={isButtonEnable && this.handleAddFlight}
          type="submit"
        >
          Add Flight
        </button>
      </div>
    );
  }

  getSliderValue = (index, value) => {
    const { legArray } = this.state;
    legArray[index].years = value;
    this.setState({ legArray });
  };

  renderAddFlights() {
    const { legArray } = this.state;
    if (legArray.length > 0) {
      const renderBookedFlightRows = legArray.map((item, index) => {
        return (
          <div className="vistajet_offer_form vistajet_plan_a_program">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 vistajet_padding_small">
                <div className="vistajet_input vistajet_form_icon">
                  <AirportSelectorWrapper
                    leg={item}
                    eTime="etd"
                    source="departure"
                    onAirportSelect={(value, source) => this.handleAirportSelect(value, source, index)
                    }
                    isAirportsFetched={this.props.isAirportsFetched}
                    visitedAirports={this.props.visitedAirports}
                    fetchVisitedAirports={this.props.fetchVisitedAirports}
                  />
                </div>
              </div>
              <hr className="col-xs-12 col-sm-12 col-md-12 col-lg-12 vistajet_padding_small" />
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 vistajet_padding_small">
                <div className="vistajet_input vistajet_to_icon">
                  <AirportSelectorWrapper
                    leg={item}
                    eTime="eta"
                    source="arrival"
                    onAirportSelect={(value, source) => this.handleAirportSelect(value, source, index)
                    }
                    isAirportsFetched={this.props.isAirportsFetched}
                    visitedAirports={this.props.visitedAirports}
                    fetchVisitedAirports={this.props.fetchVisitedAirports}
                  />
                </div>
              </div>
              <hr className="col-xs-12 col-sm-12 col-md-12 col-lg-12 vistajet_padding_small" />
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 vistajet_padding_small">
                <div className="vistajet_input">
                  <ProgramBuilderRangeSlider
                    onChange={this.getSliderValue}
                    data={index}
                  />
                </div>
              </div>
              <hr className="col-xs-12 col-sm-12 col-md-12 col-lg-12 vistajet_padding_small" />
              <div className="col-xs-12 col-sm-12 col-md-1 col-lg-1 vistajet_padding_small disable-dbl-tap-zoom">
                <div className="vistajet_input vistajet_stepper_icon">
                  <Stepper
                    value={item.passengersCount}
                    maxValue={
                      this.selectedAircraft
                        ? this.selectedAircraft.maxNumberOfPassengers
                        : 14
                    }
                    onChange={value => this.handleStepperChange(value, index)}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 vistajet_padding_small">
                {legArray.length > 1 && (
                  <button
                    style={{ cursor: 'pointer' }}
                    className="vistajet_removeflight"
                    onClick={item => this.removeFlight(index)}
                  >


                    X
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      });
      return renderBookedFlightRows;
    }
  }

  render() {
    const { legArray } = this.state;
    return (
      <div className="uk-width-1-1">
        {legArray.length > 0 && this.renderAddFlights()}
        {this.renderButtons()}
      </div>
    );
  }
}

export default ProgramBuilderWidget;
