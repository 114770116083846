import axios from 'axios';
import { FETCH_SINGLE_LEG_PAX } from '../../configs/constants';
import {
  HTTP_HEADERS_JSON,
} from '../../constants/ApiConstants';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';
import { clone } from '../../utils/Helpers';
import { getLegKey } from '../../utils/itinerariesUtils';
import { normalizePassengerUtil } from '../../normalizers/PassengerNormalizer';
import * as actions from '../actions';

const inprogressLegPaxPromise = {};
const isError = (obj) => (obj.isError);

export const fetchSingleLegPax = (token, data) => (dispatch) => {
  const { legId } = data.params;
  const uniqueKey = `${legId}-${token}`;

  if (inprogressLegPaxPromise[uniqueKey]) {
    return inprogressLegPaxPromise[uniqueKey];
  }

  const legKey = getLegKey(legId);
  const url = FETCH_SINGLE_LEG_PAX
    .replace('{token}', token)
    .replace('{flightLegKey}', legKey)
    .replace('{flightLegId}', legId);
  const legPaxPromise = new Promise((resolve, reject) => {
    dispatch(actions.fetchPassengerInit(legId));
    axios
      .get(url, HTTP_HEADERS_JSON)
      .then((res) => {
        try {
          const response = res.data;
          // on error
          if (isError(response.responseHeaderDto)) {
            reject(handleServerErrors(response.status));
          }
          const leg = clone(data.leg);
          // leg.departureAirport = getAirportById(leg.departureAirportId);
          const normalizedData = normalizePassengerUtil(response.passengersInfoResponseDtoList,
            { ...data.params }, leg);
          delete inprogressLegPaxPromise[uniqueKey];
          const responseData = {
            normalizedData,
            leg,
          };
          dispatch(actions.fetchPassengerSuccess(responseData, legId));
          resolve(responseData);
        } catch (error) {
          dispatch(actions.fetchPassengerFail(legId));
          reject(new Error('Something went wrong'));
          delete inprogressLegPaxPromise[uniqueKey];
        }
      }).catch(err => {
        if (err) {
          reject(handleServerErrors(err));
        }
        dispatch(actions.fetchPassengerFail(legId));
        delete inprogressLegPaxPromise[uniqueKey];
      });
  });
  inprogressLegPaxPromise[uniqueKey] = legPaxPromise;
  return legPaxPromise;
};

export default fetchSingleLegPax;
