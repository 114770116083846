import * as types from '../actionTypes';

export const initialState = {
  user: {},
  isUserRegistered: false,
  isLoading: false,
  isError: false,
  error: '',
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.REGISTRATION_INIT: {
      return Object.assign({}, state, {
        user: {},
        isUserRegistered: false,
        isLoading: true,
        isError: false,
        error: '',
      });
    }
    case types.REGISTRATION_SUCCESS: {
      return Object.assign({}, state, {
        user: action.data.user,
        isUserRegistered: action.data.isUserRegistered,
        isLoading: false,
        isError: false,
        error: '',
      });
    }
    case types.REGISTRATION_FAIL: {
      return Object.assign({}, state, {
        user: {},
        isUserRegistered: false,
        isLoading: false,
        isError: true,
        error: action.data,
      });
    }
    default:
      return state;
  }
}
