import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStore from '../withStore';
import * as actions from '../../store/actions';
import CostCalculatorForm from './CostCalculatorForm';
import CostComparisonCard from './CostComparisonCard';

class CostCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAircraft: null,
      hours: 0,
      payload: null,
    };
  }

  updateData = ({ aircraft, hours, payload }) => this.setState({ selectedAircraft: aircraft, hours, payload },
    () => {document.getElementById('comparision-card').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })}
    );

  render() {
    const { selectedAircraft = null, hours, payload } = this.state;
    return (
      <div>
        <CostCalculatorForm
          updateData={this.updateData}
          {...this.props}
        />
        <div id={"comparision-card"}>
          <CostComparisonCard
            aircraft={selectedAircraft}
            hours={hours}
            onContactUsForm={this.props.onContactUsForm}
            payload={payload}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoading, isSuccess } = state.contactus;
  return {
    isLoading,
    isSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClearReducer: () => dispatch(actions.clearReducer()),
    onContactUsForm: payload => dispatch(actions.postContactUs(payload)),
  };
}

const CostCalculatorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CostCalculator);

export default withStore(CostCalculatorContainer);
