import fetchMovementMessagesSource from '../sources/MovementMessagesSource';
import {
  FETCH_MOVEMENT_MESSAGES_INIT,
  FETCH_MOVEMENT_MESSAGES_SUCCESS,
  FETCH_MOVEMENT_MESSAGES_FAIL,
} from '../actionTypes';

export const fetchMovementMessagesInit = (flightLegId) => ({
  type: FETCH_MOVEMENT_MESSAGES_INIT,
  flightLegId,
});

export const fetchMovementMessagesSuccess = (data, flightLegId) => ({
  type: FETCH_MOVEMENT_MESSAGES_SUCCESS,
  data,
  flightLegId,
});

export const fetchMovementMessagesFail = (data, flightLegId) => ({
  type: FETCH_MOVEMENT_MESSAGES_FAIL,
  data,
  flightLegId,
});

export const fetchMovementMessages = (token, flightLegId) => fetchMovementMessagesSource(token, flightLegId);
