import moment from 'moment';
import { garanteeArray } from '../utils/sourceUtils';
import { getAircraftByField } from '../utils/aircraftUtils';
import { INVOICES_PER_PAGE } from '../configs/constants';

function getAirportData(id) {
  return '';
}

function normalizeInvoice(invoices) {
  return {
    currency: invoices.currency,
    depTimeLocal: invoices.depTimeLocal,
    deptUtc: moment.utc(invoices.deptUtc),
    description: invoices.description,
    fileKey: invoices.fileKey,
    invoiceId: invoices.invoiceId,
    invoicePk: invoices.invoicePk,
    invoicedLineType: invoices.invoicedLineType,
    legHoursDeducted: (+invoices.legHoursDeducted).toFixed(2),
    totalAmount: invoices.totalAmount,
    totalInvoiceHours: (+invoices.totalInvoiceHours).toFixed(2),
    departureAirportId: invoices.departureAirportId,
    arrivalAirportId: invoices.arrivalAirportId,
    departureAirport: getAirportData(invoices.departureAirportId),
    arrivalAirport: getAirportData(invoices.arrivalAirportId),
  };
}

const sortInvoices = (a, b) => (b.deptUtc - a.deptUtc);

function normalizeFleet(fleet) {
  const aircraft = getAircraftByField(fleet.aircraftTypeId);
  return {
    accountId: fleet.accountId,
    accountName: fleet.accountName,
    aircraftTypeId: fleet.aircraftTypeId,
    contractEndDate: moment.utc(fleet.contractEndDate),
    contractPeriodId: fleet.contractPeriodId,
    contractStartDate: moment.utc(fleet.contractStartDate),
    hoursFlown: (+fleet.hoursFlown).toFixed(2),
    hoursForYear: (+fleet.hoursForYear).toFixed(2),
    invoice: garanteeArray(fleet.invoice).map(normalizeInvoice).sort(sortInvoices),
    remainingHours: (+fleet.remainingHours).toFixed(2),
    aircraftName: aircraft.displayName,
    aircraftRank: aircraft.rank,
  };
}

function normalizefleets(fleets) {
  return fleets
    .map(fleet => normalizeFleet(fleet))
    .sort((a, b) => (
          a.aircraftRank -
          b.aircraftRank
      ));
}

export function pasNormalizer(invoiceHoursResult, fetchMore = false) {
  const billedFlights = normalizefleets(garanteeArray(invoiceHoursResult.invoiceHoursResponse.fleet));
  return billedFlights.map(fleet => ({
    ...fleet,
    noMoreInvoices: !fleet.invoice.length || fleet.invoice.length < INVOICES_PER_PAGE,
  }));

}

function normalizeUnbilledFlights(fleet) {
  return fleet.flights.map(flight => (
    {
      aircraftTypeId: flight.airTypeId || '',
      arrivalAirportId: flight.arrAirId,
      arrivalAirport: getAirportData(flight.arrAirId),
      arrivalAirportTimeLocal: flight.arrAirTimeLocal,
      arrivalAirportTimeUTC: flight.arrAirTimeLocal,
      departureAirportId: flight.depAirId,
      departureAirport: getAirportData(flight.depAirId),
      departureAirportTimeLocal: flight.depAirTimeLocal,
      departureAirportTimeUTC: flight.depAirTimeUTC,
      legId: flight.legId || '',
      orderId: flight.orderId || '',
    }
  ));
}

function normalizeUnbilledAircrafts(data) {
  const aircrafts = {};
  let flights = [];
  data.forEach(account => {
    account.contractAircrafts.forEach(fleet => {
      const aircraft = getAircraftByField(fleet.typeId);
      flights = normalizeUnbilledFlights(fleet);
      if (!!aircrafts[fleet.typeId]) {
        aircrafts[fleet.typeId].concat(flights);
        aircrafts[fleet.typeId].aircraftName = aircraft.displayName;
        aircrafts[fleet.typeId].aircraftRank = aircraft.aircraftRank;
      } else {
        aircrafts[fleet.typeId] = flights || [];
        aircrafts[fleet.typeId].aircraftName = aircraft.displayName;
        aircrafts[fleet.typeId].aircraftRank = aircraft.aircraftRank;
      }
    });
  });
  return aircrafts;
}

export function normalizeUnbilled(data) {
  return normalizeUnbilledAircrafts(data);
  // return {
  //   unbilled: normalizeUnbilledAircrafts(data),
  // };
}

  const normalizeAccountStatmentInfo = infoList => infoList.map(({
    contractPeriodStart = '',
    contractPeriodEnd = '',
    periodNumber = '',
  }) => ({
    contractPeriodStart: contractPeriodStart && moment(contractPeriodStart).format('DD MMM YYYY'),
    contractPeriodEnd: contractPeriodEnd && moment(contractPeriodEnd).format('DD MMM YYYY'),
    periodNumber,
  }));

  const normalizeAccountStatementsList = statementsList => statementsList.map(({
    id = '',
    createdDate = '',
    periodStart = '',
    periodEnd = '',
    totalBilledHours = '',
    accountStatementPDFFile = '',
    title = '',
    periodNumber = '',
  }) => ({
    createdDate: createdDate && moment(createdDate).format('DD MMM YYYY'),
    periodStart: periodStart && moment(periodStart).format('DD MMM YYYY'),
    periodEnd: periodEnd && moment(periodEnd).format('DD MMM YYYY'),
    id,
    totalBilledHours,
    accountStatementPDFFile,
    title,
    periodNumber,
  }));

  export const normalizeAccountStatements = ({
    accountStatementInfoDtoList = [],
    accountStatementDtoList = [],
  }) => ({
    accountStatementInfo: normalizeAccountStatmentInfo(accountStatementInfoDtoList),
    accountStatements: normalizeAccountStatementsList(accountStatementDtoList),
  });

  export const normalizeStatementInvoices = (invoices) => invoices.map(({
      invoiceId = '',
      invoiceDate = '',
      description = '',
      billedHours = '',
      fileKey = '',
    }) => ({
      invoiceDate: invoiceDate && moment(invoiceDate).format('DD MMM YYYY'),
      invoiceId, description, billedHours, fileKey,
    })
);
