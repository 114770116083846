import React from "react";
import Select from "react-select";
import {
  handleFormatAirport,
  SHOW_AIRPPORTS_TO,
  MAX_AIRPORT_SEARCH,
  debounceIt,
} from "../../utils/airportUtils";
class AirportSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: null,
      showLabel: true,
      displayText: "Departure Airport",
      searchedAirport: "",
      airports: [],
      filteredAirports: [],
      shouldChangeSelection: true
    };
  }
  componentDidMount() {
    if (this.props.isAirportsFetched) {
      this.setState({
        airports: this.airportsSortByCity(this.props.visitedAirports)
      });
    } else {
      this.props.fetchVisitedAirports();
    }
    if (this.props.prePopulatedData) {
      const label = handleFormatAirport(this.props.prePopulatedData);
      this.setState({
        selectValue: {
          value: this.props.preSelectedDeparture,
          label
        },
        displayText: label,
        showLabel: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visitedAirports !== this.props.visitedAirports) {
      const sortedAirport = this.airportsSortByCity(nextProps.visitedAirports);
      const { searchedAirport } = this.state;
      this.setState({
        airports: sortedAirport,
      });
      if (searchedAirport) {
        this.searchAirports(searchedAirport, sortedAirport);
      }
    }

    if (nextProps.prePopulatedData !== this.props.prePopulatedData) {
      const label = nextProps.prePopulatedData
        ? handleFormatAirport(nextProps.prePopulatedData)
        : "";
      this.setState({
        selectValue: {
          value: nextProps.preSelectedDeparture,
          label
        },
        displayText: label,
        shouldChangeSelection: false,
        showLabel: true
      });
    }
  }
  compareCity = (a, b) => {

    if (a.city && b.city) {
      const city1 = a.city.toLowerCase()
      const city2 = b.city.toLowerCase()
      if (city1 < city2) {
        return -1;
      }
      if (city1 > city2) {
        return 1;
      }
      return 0;
    }
  };

  airportsSortByCity = (airports) => {
    return airports.sort(this.compareCity)
  }
  searchAirportList = () => {
    const { searchedAirport, airports} = this.state;
    this.searchAirports(searchedAirport, airports);
  }
  handleInputChange = value => {
    this.setState({
      searchedAirport: value
    },() => debounceIt(this.searchAirportList, 200));
  };  
  handleSelectChange = value => {
    if (Array.isArray(value) === false) {
      value.label = handleFormatAirport(value.value)
      this.setState({
        selectValue: value,
        displayText: value.label,
        showLabel: true
      });
      this.props.onAirportSelect(value.value, this.props.source);
    }
  };
  hideLabel = () => {
    this.setState({
      showLabel: false
    });
  };
  searchAirports = (value, airports) => {
    const result = airports.filter(element => {
      const airportCity = element.city ? element.city.toLowerCase() : "";
      const airportCountry = element.country.toLowerCase();
      const airportName = element.name.toLowerCase();
      const airpotCode = element.icao.toLowerCase();
      return (
        airportCity.indexOf(value.toLowerCase()) !== -1 ||
        airportCountry.indexOf(value.toLowerCase()) !== -1 ||
        airportName.indexOf(value.toLowerCase()) !== -1 ||
        airpotCode.indexOf(value.toLowerCase()) !== -1
      );
    });
    const filtered = value.length > 0 && value.length < 3  ? result.slice(0, MAX_AIRPORT_SEARCH) : result
    this.setState({

      filteredAirports: filtered
    });
  };
  formatAirportData = airports => {
    const airportOptions = airports.map(airport => ({
      value: airport,
      label: handleFormatAirport(airport)
    }));
    return airportOptions;
  };

  render() {
    const { airports, searchedAirport, filteredAirports } = this.state;
    const { source } = this.props;
    const placeholder = source === "departure" ? "From" : "To";
    let options = [];
    if (searchedAirport.length >= 1 && filteredAirports.length > 0) {
      options = this.formatAirportData(filteredAirports);
    } 

    else if (airports && airports.length > 0 ) {
      options = this.formatAirportData(airports.slice(0, SHOW_AIRPPORTS_TO))
    }

    const AirportsList = (
      <div className="vistajet_departure_selector">
        <Select
          classNamePrefix="react-select"
          options={options}
          isSearchable={true}
          onInputChange={this.handleInputChange}
          value={this.state.selectValue}
          onChange={this.handleSelectChange}
          inputValue={searchedAirport}
          placeholder={placeholder}
          noOptionsMessage={() =>
            (searchedAirport.length > 0 && airports.length > 0)
              ? "Searched airport is not found"
              : "Loading Airports"
          }
        />
      </div>
    );
    return <div>{AirportsList}</div>;
  }
}
export default AirportSelector;
