import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import { CABIN_HOSTESS } from '../../constants/ItineraryConstants';

class Crew extends Component {

  renderCrew = crewMembers => (crewMembers || []).map(crewMember => (
    (
      <div className="uk-width-1-1 uk-width-1-3@s">
        <h6 className="uk-width-expand uk-margin-small-bottom vistajet_subheading">
          {crewMember.crewMemberRole && crewMember.crewMemberRole}
        </h6>
        <p>
          {crewMember.crewMemberName && crewMember.crewMemberName}
        </p>
        <p>
          {crewMember.crewMemberTitle !== CABIN_HOSTESS && crewMember.crewMemberPhoneNumber}
        </p>
      </div>
    )));

  render() {
    const { crewInfos, withIn24Hours, isLoading } = this.props;
    let sortedCrewMembers;
    if (crewInfos && crewInfos.crew) {
      sortedCrewMembers = crewInfos.crew.sort(function (obj1, obj2) {
        return obj1.crewMemberTitle - obj2.crewMemberTitle;
      });
    }

    return (
      <div className="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove">
        <div className="vistajet_itinerary_card_header">
          <h5>Crew</h5>
        </div>
        <div className="vistajet_itinerary_card_body">
          {
            (crewInfos || {}).isLoadingCrew || isLoading
              ? (
                <div className="vistajet_spinner">
                  <SpinnerLoader />
                </div>
              )
              : (
                !withIn24Hours || !(crewInfos && crewInfos.crew) ? (
                  <div className="uk-grid uk-grid-large" data-uk-grid>
                    <p className="uk-margin-remove">
                      Crew information will be made available 24 hours before the flight.
                  </p>
                  </div>
                ) : (
                    <div className="uk-grid uk-grid-large" data-uk-grid>
                      {this.renderCrew(sortedCrewMembers || [])}
                    </div>
                  )
              )}
        </div>
      </div>
    );
  }
}

export default Crew;
