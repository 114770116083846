import axios from 'axios';
import {
  fetchGroundServicesInit,
  fetchGroundServicesFail,
  fetchGroundServicesSuccess,
} from '../actions/GroundServicesActions';
import {
  servicesAvailedInit,
  servicesAvailedSuccess,
  servicesAvailedFail,

  addTransportServicesInit,
  addTransportServicesFail,
  addTransportServicesSuccess,

  addAdditionalServicesSuccess,
  addAdditionalServicesFail,
  addAdditionalServicesInit,

} from '../actions/AvailedServicesActions';

import { garanteeArray } from '../../utils/sourceUtils';
import { HTTP_HEADERS_JSON } from '../../constants/ApiConstants';
import { FETCH_GROUND_SERVICES_URL, ADD_GROUND_SERVICES_URL, SERVICES_AVAILED_URL } from '../../configs/constants';
import { SOMETHING_UNEXPECTED } from '../../constants/MessageConstants';
import { BAD_REQUEST, TIMEOUT_CODE, UNAUTHORIZED } from '../../constants/ServerCodeConstants';
import { handleServerErrors, } from '../../utils/sourceUtils';
import { GROUND_TRANSPORT_SERVICE } from '../../constants/ItineraryConstants';

//----fetch all services options
export const fetchGroundServices = (token, payload) => (dispatch) => {
  let url;
  url = FETCH_GROUND_SERVICES_URL.replace('{token}', token);

  const reqPayload = {
    airportIds: payload,
  };

  dispatch(fetchGroundServicesInit());
  axios
    .post(url, reqPayload, HTTP_HEADERS_JSON)
    .then((response) => {
      if (response && response.data) {
        const responseHeader = response.data.responseHeaderDto;
        if (responseHeader && responseHeader.isError) {
          const errors = handleServerErrors(responseHeader.statusCode);
          dispatch(fetchGroundServicesFail(errors));
        }
        else {
          const groundServices = garanteeArray(response.data.serviceTypes);
          dispatch(fetchGroundServicesSuccess(groundServices));
        }
      } else {
        dispatch(fetchGroundServicesFail(SOMETHING_UNEXPECTED));
      }
    })
    .catch((err) => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(fetchGroundServicesFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(fetchGroundServicesFail(handleServerErrors(UNAUTHORIZED)));
      }
      else dispatch(fetchGroundServicesFail(handleServerErrors(BAD_REQUEST)));
    });
};

export const addGroundServices = (token, key, uniqueIdentifier, payload) => (dispatch) => {

  const url = ADD_GROUND_SERVICES_URL.replace('{token}', token).replace('{key}', key).replace('{uniqueIdentifier}', uniqueIdentifier);

  let serviceXml = '<serviceList>' +
    '<service>' +
    '<serviceTypeId>{serviceTypeId}</serviceTypeId>' +
    '<serviceTypeName>{serviceTypeName}</serviceTypeName>' +
    '<onDeparture>{onDeparture}</onDeparture>' +
    '<instructions>{instructions}</instructions>' +
    '</service>' +
    '</serviceList>';

  serviceXml = serviceXml
    .replace('{serviceTypeId}', payload.transportServiceId)
    .replace('{onDeparture}', payload.onDeparture)
    .replace('{instructions}', payload.notes)
    .replace('{serviceTypeName}', payload.transportServiceTitle);

  const AdditionalServicesPayload = {
    notes: payload.notes,
    onDeparture: payload.onDeparture,
    serviceId: payload.transportServiceId,
    title: payload.transportServiceTitle,
  };

  const serviceType = payload.serviceType;
  const options = {
    headers: {
      "Accept": "application/xml",
      "Content-Type": "application/xml",
    }
  };

  dispatch(serviceType === GROUND_TRANSPORT_SERVICE ?
    addTransportServicesInit(uniqueIdentifier)
    : addAdditionalServicesInit(uniqueIdentifier));
  axios
    .post(url, serviceXml, options)
    .then((response) => {
      if (response && response.data) {
        const responseHeader = response.data.responseHeaderDto;
        if (responseHeader && responseHeader.isError) {
          const errors = handleServerErrors(responseHeader.statusCode);
          dispatch(serviceType === GROUND_TRANSPORT_SERVICE
            ? addTransportServicesFail(uniqueIdentifier, errors)
            : addAdditionalServicesFail);
        } else {
          dispatch(serviceType === GROUND_TRANSPORT_SERVICE
            ? addTransportServicesSuccess(uniqueIdentifier, payload, serviceType)
            : addAdditionalServicesSuccess(uniqueIdentifier, AdditionalServicesPayload, serviceType));
        }
      } else {
        dispatch(serviceType === GROUND_TRANSPORT_SERVICE
          ? addTransportServicesFail(uniqueIdentifier, SOMETHING_UNEXPECTED)
          : addAdditionalServicesFail);
      }
    })
    .catch((err) => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(serviceType === GROUND_TRANSPORT_SERVICE
          ? addTransportServicesFail(uniqueIdentifier, handleServerErrors(TIMEOUT_CODE.code))
          : addAdditionalServicesFail(uniqueIdentifier, handleServerErrors(TIMEOUT_CODE.code)));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(serviceType === GROUND_TRANSPORT_SERVICE
          ? addTransportServicesFail(uniqueIdentifier, handleServerErrors(UNAUTHORIZED))
          : addAdditionalServicesFail(uniqueIdentifier, handleServerErrors(UNAUTHORIZED)));
      }
      else dispatch(serviceType === GROUND_TRANSPORT_SERVICE
        ? addTransportServicesFail(uniqueIdentifier, handleServerErrors(BAD_REQUEST))
        : addAdditionalServicesFail(uniqueIdentifier, handleServerErrors(BAD_REQUEST)));
    });
};

//------fetch availed service ------
export const serviceAvailed = (token, key, flightLegId) => (dispatch) => {

  const url = SERVICES_AVAILED_URL
    .replace('{token}', token)
    .replace('{key}', key)
    .replace('{flightLegId}', flightLegId);

  dispatch(servicesAvailedInit(flightLegId));

  axios
    .get(url, HTTP_HEADERS_JSON)
    .then((response) => {
      if (response && response.data) {
        const responseHeader = response.data.responseHeaderDto;
        if (!responseHeader.isError) {
          const availedGTData = response.data.transportSummaryDtos;
          const availedASData = response.data.otherServiceDtos;
          dispatch(servicesAvailedSuccess(availedGTData, availedASData, flightLegId));
        } else {
          const errors = handleServerErrors(response.data.responseHeaderDto.statusCode);
          dispatch(servicesAvailedFail(flightLegId, errors));
        }
      } else {
        dispatch(servicesAvailedFail(flightLegId, SOMETHING_UNEXPECTED));
      }
    })
    .catch((err) => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(servicesAvailedFail(flightLegId, handleServerErrors(TIMEOUT_CODE.code)));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(servicesAvailedFail(flightLegId, handleServerErrors(UNAUTHORIZED)));
      }
      else dispatch(servicesAvailedFail(flightLegId, handleServerErrors(BAD_REQUEST)));
    });
};

