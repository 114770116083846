import { fetchItineraryListing } from '../sources/ItineraryListingSource';
import {
  FETCH_ITINERARIES_INIT,
  FETCH_ITINERARIES_SUCCESS,
  FETCH_ITINERARIES_FAIL,
  CLEAR_ERROR_FETCH_ITINERARY,
} from '../actionTypes';

export const fetchItinerariesInit = (itineraryType, isLoadMore) => ({
  type: FETCH_ITINERARIES_INIT,
  itineraryType,
  isLoadMore,
});

export const fetchItinerariesSuccess = (itineraries, itineraryType, isLoadMore) => ({
  type: FETCH_ITINERARIES_SUCCESS,
  data: itineraries,
  itineraryType,
  isLoadMore,
});

export const fetchItinerariesFail = (itineraryType, data, isLoadMore) => ({
  type: FETCH_ITINERARIES_FAIL,
  itineraryType,
  data,
  isLoadMore,
});

export const clearErrorFetchItinerary = () => ({
  type: CLEAR_ERROR_FETCH_ITINERARY,
})
export const fetchAllItineraryListing = (token, itineraryType, pageNum, isLoadMore) => fetchItineraryListing(token, itineraryType, pageNum, isLoadMore);
