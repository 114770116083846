import { AIRCRAFT_URLS } from "../configs/constants";
const AircraftsList = [
  {
    "id": 1,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Learjet 40XR",
    "code": "LJ40XR",
    "range": 3687,
    "speed": 860,
    "maxNumberOfPassengers": 0,
    "maxNumberOfPassengersSleeping": 0,
    "maxFlightTime": 0,
    "turnaroundTimeMinutes": 120,
    "speedFirst30minBlock": 1,
    "speedSecond30minBlock": 1,
    "taxiTimeMinutes": 1,
    "cruisingAltitude": 0,
    "maxAltitudeFt": 0,
    "maxAltitudeMetres": 0,
    "maxRangeKm": 3687,
    "maxRangeNm": 1,
    "rank": 1,
    "lastUpdated": 1723658946448,
    "availableOnCustomerApp": "false",
    "tourURL": "0"
  },
  {
    "id": 2,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Learjet 60XR",
    "code": "LJ60XR",
    "range": 4454,
    "speed": 833,
    "maxNumberOfPassengers": 0,
    "maxNumberOfPassengersSleeping": 0,
    "maxFlightTime": 0,
    "turnaroundTimeMinutes": 30,
    "speedFirst30minBlock": 667,
    "speedSecond30minBlock": 741,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": 0,
    "maxAltitudeFt": 0,
    "maxAltitudeMetres": "0",
    "maxRangeKm": 4454,
    "maxRangeNm": 0,
    "rank": 2,
    "lastUpdated": 1713514922091,
    "availableOnCustomerApp": "false",
    "tourURL": "0"
  },
  {
    "id": 3,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Challenger 300",
    "code": "CL300N",
    "range": 5185,
    "speed": 827,
    "maxNumberOfPassengers": 9,
    "maxNumberOfPassengersSleeping": 0,
    "maxFlightTime": 0,
    "turnaroundTimeMinutes": 120,
    "speedFirst30minBlock": 763,
    "speedSecond30minBlock": 797,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": 0,
    "maxAltitudeFt": 0,
    "maxAltitudeMetres": 0,
    "maxRangeKm": 5185,
    "maxRangeNm": 2800,
    "rank": 110,
    "lastUpdated": 1725003532345,
    "availableOnCustomerApp": "false",
    "tourURL": "https://www.vistajet.com/360-tour/challenger-350/"
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/8/baggage.png"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/9/sketch.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/101/Challenger-605-1-1.png"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/102/Challenger-605-2-1.png"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/103/Challenger-605-3-1.png"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/161/360-tour-icon.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/162/Challenger605-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/163/Challenger605-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/164/Challenger605-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/165/Challenger605-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/166/Challenger605-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/167/Challenger605-Carousel_6.jpg"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/168/challenger-605-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/169/challenger-605-floorplan-night.png"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/252/icon_605-new.jpg"
        ]
      }
    ],
    "id": 5,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Challenger 605",
    "code": "CL605",
    "range": 6852,
    "speed": 833,
    "maxNumberOfPassengers": 12,
    "maxNumberOfPassengersSleeping": 5,
    "maxFlightTime": 480,
    "turnaroundTimeMinutes": 30,
    "speedFirst30minBlock": 727,
    "speedSecond30minBlock": 827,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": 541,
    "maxAltitudeFt": 41000,
    "maxAltitudeMetres": 12497,
    "maxRangeKm": 6852,
    "maxRangeNm": 3700,
    "rank": 140,
    "lastUpdated": 1725787026512,
    "availableOnCustomerApp": "true",
    "tourURL": AIRCRAFT_URLS.LINK_TO_CHALLENGER_605
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/13/baggage.png"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/15/sketch.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/16/internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/17/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/18/internal_3.jpg"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/170/360-tour-icon.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/171/Challenger850-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/172/Challenger850-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/173/Challenger850-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/174/Challenger850-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/175/Challenger850-Carousel_5.jpg"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/176/challenger-850-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/177/challenger-850-floorplan-night.png"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/253/icon_850-new.jpg"
        ]
      }
    ],
    "id": 5,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Challenger 605",
    "code": "CL605",
    "range": 6852,
    "speed": 833,
    "maxNumberOfPassengers": 12,
    "maxNumberOfPassengersSleeping": 5,
    "maxFlightTime": 480,
    "turnaroundTimeMinutes": 30,
    "speedFirst30minBlock": 727,
    "speedSecond30minBlock": 827,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": 541,
    "maxAltitudeFt": 41000,
    "maxAltitudeMetres": 12497,
    "maxRangeKm": 6852,
    "maxRangeNm": 3700,
    "rank": 140,
    "lastUpdated": 1725787026512,
    "availableOnCustomerApp": "true",
    "tourURL": AIRCRAFT_URLS.LINK_TO_CHALLENGER_850
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/25/baggage.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/27/internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/28/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/29/internal_3.jpg"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/30/sketch.png"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/191/360-tour-icon.png"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/192/global-6000-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/193/global-6000-floorplan-night.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/194/GlobalXRS6000-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/195/GlobalXRS6000-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/196/GlobalXRS6000-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/197/GlobalXRS6000-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/198/GlobalXRS6000-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/199/GlobalXRS6000-Carousel_6.jpg"
        ]
      },
      {
        classification: ["14"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/200/GlobalXRS6000-Carousel_7.jpg"
        ]
      },
      {
        classification: ["15"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/201/GlobalXRS6000-Carousel_8.jpg"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/255/icon_6000-new.jpg"
        ]
      }
    ],
    "id": 7,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Global 6000",
    "code": "GLEX",
    "range": 10371,
    "speed": 838.96,
    "maxNumberOfPassengers": 14,
    "maxNumberOfPassengersSleeping": 7,
    "maxFlightTime": 780,
    "turnaroundTimeMinutes": 30,
    "speedFirst30minBlock": 668.576,
    "speedSecond30minBlock": 838.96,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": 590,
    "maxAltitudeFt": 51000,
    "maxAltitudeMetres": 15545,
    "maxRangeKm": 10371,
    "maxRangeNm": 5600,
    "rank": 260,
    "lastUpdated": 1721325549654,
    "availableOnCustomerApp": "true",
    "tourURL": AIRCRAFT_URLS.LINK_TO_GLOBAL_XRS_6000
  },
  {
    "id": 8,
    "manufacturer": "0",
    "displayName": "Dispo Aircraft Type",
    "code": "DISPO",
    "range": 0,
    "speed": 0,
    "maxNumberOfPassengers": "0",
    "maxNumberOfPassengersSleeping": "0",
    "maxFlightTime": "0",
    "turnaroundTimeMinutes": 0,
    "speedFirst30minBlock": 0,
    "speedSecond30minBlock": 0,
    "taxiTimeMinutes": 0,
    "cruisingAltitude": "0",
    "maxAltitudeFt": "0",
    "maxAltitudeMetres": "0",
    "maxRangeKm": 0,
    "maxRangeNm": "0",
    "rank": "0",
    "lastUpdated": 1713514049835,
    "availableOnCustomerApp": "false",
    "tourURL": "0"
  },  
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/2/baggage.png"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/3/sketch.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/4/Internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/5/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/6/internal_3.jpg"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/151/360-tour-icon.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/152/Challenger350-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/153/Challenger350-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/154/Challenger350-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/155/Challenger350-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/156/Challenger350-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/157/Challenger350-Carousel_6.jpg"
        ]
      },
      {
        classification: ["14"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/158/Challenger350-Carousel_7.jpg"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/159/challenger-350-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/160/challenger-350-floorplan-night.png"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/251/icon_350-new.jpg"
        ]
      }
    ],
    "id": 10,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Challenger 350",
    "code": "CL350",
    "range": 5185,
    "speed": 827,
    "maxNumberOfPassengers": 8,
    "maxNumberOfPassengersSleeping": 3,
    "maxFlightTime": 435,
    "turnaroundTimeMinutes": 120,
    "speedFirst30minBlock": 763,
    "speedSecond30minBlock": 797,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": 541,
    "maxAltitudeFt": 45000,
    "maxAltitudeMetres": 13716,
    "maxRangeKm": 5185,
    "maxRangeNm": 2800,
    "rank": 120,
    "lastUpdated": 1717659987871,
    "availableOnCustomerApp": "true",
    "tourURL": AIRCRAFT_URLS.LINK_TO_CHALLENGER_350
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/19/baggage.png"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/20/Global-5000-sketch.jpg"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/22/internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/23/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/24/internal_3.jpg"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/178/360-tour-icon.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/179/Global5000-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/180/Global5000-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/181/Global5000-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/182/Global5000-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/183/Global5000-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/184/Global5000-Carousel_6.jpg"
        ]
      },
      {
        classification: ["14"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/185/Global5000-Carousel_7.jpg"
        ]
      },
      {
        classification: ["15"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/186/Global5000-Carousel_8.jpg"
        ]
      },
      {
        classification: ["16"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/187/Global5000-Carousel_9.jpg"
        ]
      },
      {
        classification: ["17"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/188/Global5000-Carousel_10.jpg"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/189/global-5000-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/190/global-5000-floorplan-night.png"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/254/icon_5000-new.jpg"
        ]
      }
    ],
    "id": 16,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Global 5000",
    "code": "GL5T",
    "range": 9260,
    "speed": 838.96,
    "maxNumberOfPassengers": 13,
    "maxNumberOfPassengersSleeping": 7,
    "maxFlightTime": 660,
    "turnaroundTimeMinutes": 30,
    "speedFirst30minBlock": 668.576,
    "speedSecond30minBlock": 838.96,
    "taxiTimeMinutes": 12,
    "cruisingAltitude": 590,
    "maxAltitudeFt": 51000,
    "maxAltitudeMetres": 15545,
    "maxRangeKm": 9260,
    "maxRangeNm": 5000,
    "rank": 245,
    "lastUpdated": 1718378963899,
    "availableOnCustomerApp": "true",
    "tourURL": AIRCRAFT_URLS.LINK_TO_GLOBAL_5000
  },
  {
    "id": 36,
    "manufacturer": "Cessna",
    "displayName": "Citation XLS",
    "code": "C56X",
    "range": 3150,
    "speed": 695,
    "maxNumberOfPassengers": 7,
    "maxNumberOfPassengersSleeping": "0",
    "maxFlightTime": "0",
    "turnaroundTimeMinutes": 60,
    "speedFirst30minBlock": 695,
    "speedSecond30minBlock": 695,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": "0",
    "maxAltitudeFt": "0",
    "maxAltitudeMetres": "0",
    "maxRangeKm": 3150,
    "maxRangeNm": 0,
    "rank": 60,
    "lastUpdated": 1713514049835,
    "availableOnCustomerApp": "false",
    "tourURL": "0"
  },    
  {
    "id": 118,
    "manufacturer": "Agusta Westland",
    "displayName": "Agusta A109SP",
    "code": "A109SP",
    "range": 800,
    "speed": 222,
    "maxNumberOfPassengers": 0,
    "maxNumberOfPassengersSleeping": 0,
    "maxFlightTime": 0,
    "turnaroundTimeMinutes": 45,
    "speedFirst30minBlock": 222,
    "speedSecond30minBlock": 222,
    "taxiTimeMinutes": 6,
    "cruisingAltitude": 0,
    "maxAltitudeFt": 0,
    "maxAltitudeMetres": 0,
    "maxRangeKm": 800,
    "maxRangeNm": 432,
    "rank": 2,
    "lastUpdated": 1721483765187,
    "availableOnCustomerApp": "false",
    "tourURL": "https://www.vistajet.com/360-tour/global-6000/"
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/25/baggage.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/27/internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/28/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/29/internal_3.jpg"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/30/sketch.png"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/191/360-tour-icon.png"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/192/global-6000-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/193/global-6000-floorplan-night.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/194/GlobalXRS6000-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/195/GlobalXRS6000-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/196/GlobalXRS6000-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/197/GlobalXRS6000-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/198/GlobalXRS6000-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/199/GlobalXRS6000-Carousel_6.jpg"
        ]
      },
      {
        classification: ["14"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/200/GlobalXRS6000-Carousel_7.jpg"
        ]
      },
      {
        classification: ["15"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/201/GlobalXRS6000-Carousel_8.jpg"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/255/icon_6000-new.jpg"
        ]
      }
    ],
    "id": 1718,
    "manufacturer": "Bombardier Aerospace",
    "displayName": "Global 7500",
    "code": "GL7T",
    "range": 13890,
    "speed": 838.96,
    "maxNumberOfPassengers": 14,
    "maxNumberOfPassengersSleeping": 0,
    "maxFlightTime": 0,
    "turnaroundTimeMinutes": 60,
    "speedFirst30minBlock": 668.576,
    "speedSecond30minBlock": 838.96,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": 0,
    "maxAltitudeFt": 0,
    "maxAltitudeMetres": 0,
    "maxRangeKm": 13890,
    "maxRangeNm": 7500,
    "rank": 270,
    "lastUpdated": 1716017722235,
    "availableOnCustomerApp": "true",
    "tourURL": AIRCRAFT_URLS.LINK_TO_GLOBAL_XRS_7500
  },
  {
    "id": 4218,
    "manufacturer": "VistaJet Limited B2B",
    "displayName": "Hybrid CL350",
    "code": "H-CL350",
    "range": 5185,
    "speed": 827,
    "maxNumberOfPassengers": "0",
    "maxNumberOfPassengersSleeping": "0",
    "maxFlightTime": "0",
    "turnaroundTimeMinutes": 120,
    "speedFirst30minBlock": 763,
    "speedSecond30minBlock": 797,
    "taxiTimeMinutes": 10,
    "cruisingAltitude": "0",
    "maxAltitudeFt": "0",
    "maxAltitudeMetres": "0",
    "maxRangeKm": 5185,
    "maxRangeNm": 2799,
    "rank": 120,
    "lastUpdated": 1691567382096,
    "availableOnCustomerApp": "false",
    "tourURL": "0"
  }  
];
export default AircraftsList;
