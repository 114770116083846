import {
  validateEmail,
  validateNotEmpty,
} from './Validators';

export default function validateLoginForm(obj) {
  const emailResponse = validateEmail(obj.email);
  const passwordResponse = validateNotEmpty(obj.password);
  const res = {
    errors: {},
  };
  if (emailResponse.isError) {
    res.isError = emailResponse.isError;
    res.errors.email = emailResponse.message;
  }
  if (passwordResponse.isError) {
    res.isError = passwordResponse.isError;
    res.errors.password = passwordResponse.message;
  }
  return res;
}
 