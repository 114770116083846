import { connect } from 'react-redux';

import withStore from 'components/withStore';
import ProgramAccountStatement from '../components/ProgramAccountStatement';

import * as actions from '../store/actions';

const mapDispatchToProps = (dispatch) => ({
  fetchUnbilledFlights: token => dispatch(actions.fetchUnbilledFlightsAction(token)),
  fetchRecentInvoices: (token, currentCount, aircraftTypeId) =>
    dispatch(actions.fetchRecentInvoicesAction(token, currentCount, aircraftTypeId)),
  fetchVisitedAirports: () => dispatch(actions.fetchAllVisitedAirports()),
  fetchMoreInvoices: (token, currentCount, aircraftTypeId, accountId) =>
    dispatch(actions.fetchMoreInvoicesAction(token, currentCount, aircraftTypeId, accountId)),
  fetchAccountStatements: (token, aircraftTypeId, accountId) =>
    dispatch(actions.fetchAccountStatementsAction(token, aircraftTypeId, accountId)),
  fetchStatementInvoices: (token, accountStatementId, pageNumber, pageSize) =>
    dispatch(actions.fetchStatementInvoicesAction(token, accountStatementId, pageNumber, pageSize)),
  fetchZipUrl: (key, token ) => dispatch(actions.fetchZipUrlAction(key, token)),
  getAirportById: id => dispatch(actions.getAirportById(id)),
  clearInvoiceFilter: () => dispatch(actions.clearInvoiceFilter()),
  clearError: errorField => dispatch(actions.clearPasError(errorField)),
})

const mapStateToProps = ({ PASData, visitedAirports, token }) => ({
  unbilledFlights: PASData.unbilledFlights,
  loadingUnbilledFlights: PASData.loadingUnbilledFlights,
  unbilledFlightsError: PASData.unbilledFlightsError,
  billedFlights: PASData.billedFlights,
  loadingBilledFlights: PASData.loadingBilledFlights,
  billedFlightsError: PASData.billedFlightsError,
  loadingMoreInvoices: PASData.loadingMoreBilledFlights,
  moreInvoicesError: PASData.moreBilledFlightsError,
  visitedAirports: visitedAirports.visitedAirports,
  isAirportsLoading: visitedAirports.isAirportsLoading,
  visitedAirportsFinder: visitedAirports.visitedAirportsFinder,
  accountStatementInfo: PASData.accountStatementInfo,
  accountStatements: PASData.accountStatements,
  accountStatementsError: PASData.accountStatementsError,
  loadingAccountStatements: PASData.loadingAccountStatements,
  statementInvoices: PASData.statementInvoices,
  statementInvoicesLastPage: PASData.statementInvoicesLastPage,
  loadingStatementInvoices: PASData.loadingStatementInvoices,
  loadingMoreStatementInvoices: PASData.loadingMoreStatementInvoices,
  statementInvoicesError: PASData.statementInvoicesError,
  loadingZipUrl: PASData.loadingZipUrl,
  zipUrlError: PASData.zipUrlError,
  isAirportByIdLoading: visitedAirports.isAirportByIdLoading,
  userToken: (token || {}).token || '',
});

const PASContainer = connect(mapStateToProps, mapDispatchToProps)(ProgramAccountStatement);

export default withStore(PASContainer);
