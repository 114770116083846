import React from 'react';
import PropTypes from 'prop-types';
import CountriesList from '../../constants/CountryConstants';
import moment from 'moment';
import ConfirmationPopUpNew from '../shared/ConfirmationPopUpNew';
import "react-datepicker/dist/react-datepicker.css";
import Validate from '../../utils/Validator';
import { getPassengerId } from '../../utils/passengerUtils';
import MessagePopUpNew from '../shared/MessagePopUpNew';
import Select from 'react-select';
import Calendar from '../shared/ReactCalendar'
import { convertToTitleCase } from '../../utils/stringUtils';
import { NAME_REGEX } from '../../constants/SignupConstants';
import { getTruncatedCountryName } from 'utils/countryUtils';

class AddPassport extends React.Component {

  static propTypes = {
    addPassportToPassenger: PropTypes.func,
    updateFlightPassengers: PropTypes.func,
    setStateDirty: PropTypes.func,
    showToastMesg: PropTypes.func,
    updateHeading: PropTypes.func,
    handleAddPassportToPax: PropTypes.func,
    handleDirtyChange: PropTypes.func,
    newPassenger: PropTypes.object,
    addedPax: PropTypes.array,
    selectedPassengerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);
    this.state = {
      isLegPassport: false,
      isAdded: true,
      isActive: true,
      imageUrl: null,
      imageFile: null,
      passportNumber: '',
      displayName: '',
      isoPassportCountry: '',
      expiresOnDate: '',
      isoIssuedByCountry: '',
      validationErrors: {
        passportNumber: '',
        expiresOnDate: '',
        displayName: '',
        nationality: '',
        issuingCountry: '',
      },
      showLoader: false,
      openPassportExistsDialog: false,
    };
    this.styles = {
      selectBox: { borderRadius: 0 },
    };
    this.updatePaxFlag = false;
  }

  onHandleClose = () => {
    this.setState({ duplicatePassportErr: '' });
  }

  onPassportNumberChange = (e) => {
    this.props.handleDirtyChange(true);
    this.setState({
      passportNumber: e.target.value,
    });
  }

  onPassportNameChange = (e) => {
    this.props.handleDirtyChange(true);
    const input = e.target.value;
    if (input && NAME_REGEX.test(input)) {
      this.setState({
        displayName: input,
      });
    } else if (!input) {
      this.setState({
        displayName: input,
      });
    }
  }

  onPassportNationalityChange = (obj) => {
    let newValue = obj;
    newValue.label = getTruncatedCountryName(obj.value, obj.code);
    this.props.handleDirtyChange(true);
    this.setState({
      isoPassportCountry: newValue,
    });
  }

  onPassportIssueCountryChange = (obj) => {
    let newValue = obj;
    newValue.label = getTruncatedCountryName(obj.value, obj.code);
    this.props.handleDirtyChange(true);
    this.setState({
      isoIssuedByCountry: newValue,
    });
  }

  handleDateChange = (jsDate) => {
    let dateFormated = jsDate;
    if (jsDate) {
      // dateFormated  = formatDate(jsDate, dateFormats.DDMMMYYYY);
      this.props.handleDirtyChange(true);
      this.validatePassportExpiry(dateFormated);
    }

  }

  handlePassportOverride = () => {
    const data = this.createData({ isUpdate: true });
    this.props.handleDirtyChange(false);
    this.props.handleAddPassportToPax(data, this.props.selectedPassengerId);
    this.passportChangesOverride.show();
  }

  isDuplicatePassport = (targetPassportNumber) => {
    // checking for a duplicate passport.
    let isDuplicate = false;
    // iterate over all passengers
    this.props.addedPax.forEach((passenger) => {
      // iterate over all passports
      if (passenger.passports && passenger.passports.length > 0 && !isDuplicate) {
        isDuplicate = passenger.passports
          .some((passport) => (passport.passportNumber.toUpperCase() === targetPassportNumber.toUpperCase()));
      }
    });
    return isDuplicate;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // const that = this;
    let isFormValid = true;
    const validationErrors = {
      passportNumber: '',
      expiresOnDate: '',
      displayName: '',
      nationality: '',
      issuingCountry: '',
    };

    // form validations
    if (Validate.onlyAlphaNumeric(this.state.passportNumber)) {
      isFormValid = false;
      validationErrors.passportNumber = 'Passport Number should contain ' +
        'only alpha numeric characters';
    }

    // form validations
    if (Validate.validRequired(this.state.passportNumber)) {
      isFormValid = false;
      validationErrors.passportNumber = 'Passport Number is required';
    }

    if (!Validate.checkCharacterLimit(this.state.passportNumber, 10)) {
      isFormValid = false;
      validationErrors.passportNumber = 'Passport Number should not ' +
        'exceed 10 characters';
    }

    // passport name
    if (Validate.validRequired(this.state.displayName)) {
      isFormValid = false;
      validationErrors.displayName = 'Passport Name is required';
    }

    // passport country
    if (Validate.validRequired(this.state.isoPassportCountry)) {
      isFormValid = false;
      validationErrors.nationality = 'Please enter valid nationality';
    }

    // passport issue country
    if (Validate.validRequired(this.state.isoIssuedByCountry)) {
      isFormValid = false;
      validationErrors.issuingCountry = 'Please enter valid issuing country';
    }

    const formattedExpiration = this.state.expiresOnDate ?
      this.state.expiresOnDate.format() : '';

    if (Validate.validRequired(formattedExpiration)) {
      isFormValid = false;
      validationErrors.expiresOnDate = 'Expiry date is required';
    }

    if (Validate.dateInPast(formattedExpiration)) {
      isFormValid = false;
      validationErrors.expiresOnDate =
        'Date should be in future';
    }
    if (isFormValid) {
      if (this.isDuplicatePassport(this.state.passportNumber)) {
        this.setState({
          duplicatePassportErr: 'This passport is already saved',
        });
        this.duplicatePassportErrRef.show();
        return false;
      }
      if (this.isPassportExist(this.state.passportNumber, this.props.selectedPassengerId)) {
        this.passportChangesOverride.show();
      } else {
        const data = this.createData({ isUpdate: false, isNew: true });
        this.props.handleDirtyChange(false);
        this.props.handleAddPassportToPax(data, this.props.selectedPassengerId);
        return true;
      }
    } else {
      this.setState({
        validationErrors,
      });
    }
    return false;
  }
  createData = ({ isUpdate, isNew }) => {
    const expiry = this.state.expiresOnDate ?
      this.state.expiresOnDate.utc().format() : '';
    return {
      displayName: this.state.displayName,
      expiry,
      isActive: true,
      isAdded: true,
      isDefault: false,
      isInvalid: false,
      isOnHands: false,
      isUpdated: !!isUpdate,
      isDeleted: false,
      isNew: !!isNew,
      isoPassportCountry: this.state.isoPassportCountry.code,
      isoPassportIssuedByCountry: this.state.isoIssuedByCountry.code,
      issuedOnDate: '',
      passportCountry: this.state.isoPassportCountry.value || '',
      passportFiles: [],
      passportId: '',
      passportIssuedByCountry: this.state.isoIssuedByCountry.value || '',
      passportNumber: this.state.passportNumber,
      visa: '',
      isLegPassport: false,
    };
  }
  makePassportNumberCapital = () => {
    let { passportNumber } = this.state;
    if (passportNumber) {
      passportNumber = passportNumber.trim();
      passportNumber = passportNumber.toUpperCase();
      this.setState({
        passportNumber,
      });
    }
  }



  validatePassportExpiry = (value) => {
    if (Validate.isDateValid(value, this.state.expiresOnDate) && value) {
      const validationErrors = this.state.validationErrors;
      const expiresOnDate = moment(value) || '';
      if (Validate.validRequired(expiresOnDate)) {
        validationErrors.expiresOnDate = 'Expiry date is required';
      } else if (Validate.dateInPast(expiresOnDate)) {
        validationErrors.expiresOnDate = 'Date should be in future';
      } else {
        validationErrors.expiresOnDate = '';
      }

      this.setState({
        validationErrors,
        expiresOnDate,
      });
    }
  }
  isPassportExist = (passportNumber, paxId) => {
    let isExistPassport = false;
    const passenger = !!paxId ?
      this.props.addedPax.find(pax => {
        const id = getPassengerId(pax);
        return (id === paxId);
      }) :
      this.props.newPassenger;
    isExistPassport = ((passenger || {}).passports || []).some(passport =>
      passport.passportNumber.toUpperCase() === passportNumber.toUpperCase());
    return !!isExistPassport;
  }
  onCancel = () => {
    this.passportChangesOverride.hide();
  }
  generateCountries = () => {
    return CountriesList.map((country) => {
      const flagIcon = `flag-icon-style flag-icon-${country.TWO_LETTER_CODE.toLowerCase()}`;
      const countryLabel = convertToTitleCase(country.COUNTRY_NAME);
      return {
        value: country.COUNTRY_NAME,
        label: <div className="country-list-input"><span className={flagIcon} ></span><span className="country-name">{countryLabel}</span></div>, // eslint-disable-line
        code: country.THREE_LETTER_CODE,
      };
    });
  };
  disableButton = () => {
    const {
      passportNumber,
      displayName,
      isoPassportCountry,
      expiresOnDate,
      isoIssuedByCountry,
    } = this.state;
    if (passportNumber && displayName && isoPassportCountry && expiresOnDate && isoIssuedByCountry) {
      return false;
    }
    return true;
  }
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  render() {
    const countryOptions = this.generateCountries();
    const isDisableButton = this.disableButton();
    const buttonClass = isDisableButton ?
      `uk-button vistajet_disabled uk-width-1-1 uk-text-center` :
      `uk-button uk-button-default uk-width-1-1 uk-text-center`;
    const { validationErrors = {} } = this.state;
    const isPassportError = validationErrors && validationErrors.passportNumber;
    let classMargin = ''
    if (isPassportError) {
      classMargin = 'vistajet_passenger_passport_passportname';
    }
    return (
      <div class="vistajet_add_new_passanger_form vistajet_padding_bottom vistajet_remove_border_bottom">
        <ConfirmationPopUpNew
          ref={(passportChangesOverride) => { this.passportChangesOverride = passportChangesOverride; }}
          content="This passport is already saved, Do you want to overwrite it?"
          leftButtonContent="No"
          rightButtonContent="Yes"
          onLeftButtonClick={this.onCancel}
          onRightButtonClick={this.handlePassportOverride}
        />
        <h6 class="passenger_details_subheading_text uk-margin-remove">Please add passport details</h6>
        <div className="vistajet_profile_details_column vistajet_profile_edit" style={{ marginTop: '20px !important' }}>
          <div class="vistajet_add_new_passenger_form_details uk-grid">
            <div class="vistajet_primary_form uk-margin-remove uk-margin-small-bottom uk-width-1-2@s">
              <div class="uk-margin-remove ">
                <div class="vistajet_input_wrapper">
                  <input
                    id="passportNumber"
                    type="text"
                    data-key="passportNumber"
                    name="passport-number"
                    maxLength="10"
                    ref="passportNumber"
                    onBlur={this.makePassportNumberCapital}
                    onChange={this.onPassportNumberChange}
                    value={this.state.passportNumber}
                  />
                  <label
                    htmlFor="passportNumber"
                    ref="passportNumberError"
                  >
                    Passport Number
                  </label >
                  {
                    validationErrors &&
                      validationErrors.passportNumber ?
                      <p>{validationErrors.passportNumber}</p> :
                      null
                  }
                </div>
              </div>
            </div>
            <div className="vistajet_primary_form uk-width-1-2@s vistajet_passenger_passport_expiry_form">
              <div className="vistajet_input_wrapper">
                <div className="vistajet_date_picker_new">
                  <Calendar
                    dateInput={this.state.expiresOnDate}
                    placeholder={'Expiry Date (DD MMM YYYY)'}
                    readOnly={true}
                    minDate={moment().add(1, 'day')}
                    maxDate={moment().add(10, 'year')}
                    onDateChange={this.handleDateChange}
                    className="uk-width-1-1"
                  />
                  {
                    validationErrors &&
                      validationErrors.expiresOnDate ?
                      <p className="validation_error_passenger_details">{validationErrors.expiresOnDate}</p> :
                      null
                  }
                </div>
              </div>
            </div>
            <div class={`vistajet_primary_form uk-margin-remove uk-margin-small-bottom uk-width-1-1@s ${classMargin}`}>
              <div class="uk-margin-small-top">
                <div class="vistajet_input_wrapper">
                  <input
                    id="displayName"
                    data-key="displayName"
                    type="text"
                    maxLength="100"
                    ref="passportName"
                    name="passport-name"
                    autoComplete="name"
                    onChange={this.onPassportNameChange}
                    value={this.state.displayName}
                  />
                  <label
                    htmlFor="passportName"
                    ref="passportNameError"
                  >Passport Name
                  </label>
                  {
                    validationErrors &&
                      validationErrors.displayName ?
                      <p>{validationErrors.displayName}</p> :
                      null
                  }
                </div>
              </div>
            </div>
            <div class="vistajet_primary_form vistajet_displayname_container uk-margin-remove vistajet_remove_input_wrapper_transition uk-margin-small-bottom uk-width-1-2@s">
              <div class="uk-margin-small-top">
                <div className="vistajet_input_wrapper">
                  <Select
                    classNamePrefix="react-select"
                    id="nationality"
                    valueKey="value"
                    labelKey="label"
                    onChange={this.onPassportNationalityChange}
                    value={this.state.isoPassportCountry}
                    className="vistajet_select_input"
                    options={countryOptions}
                    placeholder={''}
                    isSearchable={true}
                  >
                  </Select>
                  <label>
                    Nationality
                  </label>
                  {
                    validationErrors &&
                      validationErrors.nationality ?
                      <p>{validationErrors.nationality}</p>
                      :
                      null
                  }
                </div>
              </div>
            </div>
            <div class="vistajet_primary_form vistajet_displayname_container uk-margin-remove vistajet_remove_input_wrapper_transition uk-margin-small-bottom uk-width-1-2@s">
              <div class="uk-margin-small-top">
                <div class="vistajet_input_wrapper">
                  <Select
                    classNamePrefix="react-select"
                    id="issuingCountry"
                    type="text"
                    ref="issuingCountry"
                    options={countryOptions}
                    onChange={this.onPassportIssueCountryChange}
                    value={this.state.isoIssuedByCountry}
                    className="vistajet_select_input"
                    placeholder={''}
                  >
                  </Select>
                  <label htmlFor="text">Issuing Country</label>
                  {
                    validationErrors &&
                      validationErrors.issuingCountry ?
                      <p>{validationErrors.issuingCountry}</p> :
                      null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vistajet_modal_footer uk-text-center fix">
          <button
            class={buttonClass}
            type="button"
            disabled={this.state.showLoader || isDisableButton}
            data-addpassporttoexistingpassenger="true"
            onClick={this.handleSubmit}
          >Add Passport</button>
        </div>
        <MessagePopUpNew
          ref={(duplicatePassportErrRef) => { this.duplicatePassportErrRef = duplicatePassportErrRef; }}
          onClose={this.onHandleClose}
          content={this.state.duplicatePassportErr}
        />
      </div>
    );
  }
}

export default AddPassport;
