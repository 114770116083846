import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SpinnerLoader from '../shared/spinnerLoader';
import ItineraryListItem from './ItineraryListItem';
import { ITINERARY_TYPES } from '../../constants/ItineraryConstants';
import MessagePopUp from '../../../src/components/shared/MessagePopUpNew';
const PAGE_NUMBER = 1;

class ItinerariesListing extends Component {
  static propTypes = {
    isLoadingMore: PropTypes.bool,
    emptyMessage: PropTypes.string,
    itineraryType: PropTypes.string,
    isLoadMoreDisable: PropTypes.bool,
    itineraryHeading: PropTypes.string,
    isLoadingItineraries: PropTypes.bool,
    isGoogleMapsApiLoaded: PropTypes.bool,
    itineraries: PropTypes.instanceOf(Array),
    getAirportById: PropTypes.func.isRequired,
    isLoadingUpcomingItineraries: PropTypes.bool,
    fetchVisitedAirports: PropTypes.func.isRequired,
    onGoogleMapsApiLoaded: PropTypes.func.isRequired,
    fetchItineraryListing: PropTypes.func.isRequired,
    visitedAirportsFinder: PropTypes.instanceOf(Object),
    errorsItineraries: PropTypes.string,
    clearErrorFetchItinerary: PropTypes.func,
  };

  static defaultProps = {
    itineraries: [],
    emptyMessage: '',
    itineraryType: '',
    itineraryHeading: '',
    isLoadingMore: false,
    isLoadMoreDisable: true,
    visitedAirportsFinder: {},
    isLoadingItineraries: false,
    isGoogleMapsApiLoaded: true,
    isLoadingUpcomingItineraries: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      pageNum: PAGE_NUMBER,
    };
  }

  componentDidMount() {
    const {
      token,
      itineraryType,
      fetchVisitedAirports,
      fetchItineraryListing,
    } = this.props;

    fetchItineraryListing(token, itineraryType, PAGE_NUMBER, false);
    if (itineraryType === ITINERARY_TYPES.PAST_ITINERARIES) {
      fetchItineraryListing(token, ITINERARY_TYPES.UPCOMING_ITINERARIES, PAGE_NUMBER, false);
    }
    fetchVisitedAirports();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorsItineraries) {
      this.validationPopUp.show();
    }
  }
  getMissingAirports() {
    const { itineraries, visitedAirportsFinder, getAirportById } = this.props;
    const undefinedIds = [];
    const airportIds = [];
    if (itineraries && itineraries.length) {
      for (let i = 0; i < itineraries.length; i++) {
        for (let j = 0; j < itineraries[i].legs.length; j++) {
          airportIds.push(itineraries[i].legs[j].arrivalAirportId);
          airportIds.push(itineraries[i].legs[j].departureAirportId);
        }
      }
    }

    airportIds.forEach((id) => {
      if (visitedAirportsFinder[id] === undefined) {
        undefinedIds.push(id);
      }
      if (undefinedIds && undefinedIds.length) {
        getAirportById([...new Set(undefinedIds)]);
      }
    });
  }

  loadMoreItineraries = () => {
    const { fetchItineraryListing, itineraryType, token } = this.props;
    let { pageNum } = this.state;
    pageNum = pageNum + 1;
    fetchItineraryListing(token, itineraryType, pageNum, true);
    if (itineraryType === ITINERARY_TYPES.PAST_ITINERARIES) {
      fetchItineraryListing(token, ITINERARY_TYPES.UPCOMING_ITINERARIES, pageNum, true);
    }
    this.setState({ pageNum });
  };

  renderButton = (text) => (
    <div className="uk-text-center">
      <button
        type="button"
        className="uk-button vistajet_button_default"
        onClick={() => this.loadMoreItineraries()}
      >
        {text}
      </button>
    </div>
  )

  renderHeading = (itineraryHeading) => (
    <h3 className="uk-margin-remove">
      {itineraryHeading}
    </h3>
  )

  clearErrorMessage = () => {
    const { clearErrorFetchItinerary } = this.props;
    clearErrorFetchItinerary();
  }
  render() {
    const {
      itineraries,
      emptyMessage,
      itineraryType,
      isLoadingMore,
      aircraftsList,
      itineraryHeading,
      isLoadMoreDisable,
      isLoadingItineraries,
      visitedAirportsFinder,
      onGoogleMapsApiLoaded,
      isGoogleMapsApiLoaded,
    } = this.props;

    this.getMissingAirports();
    return (
      <div className="uk-container uk-container-small uk-margin-medium-top" uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 25;">
        <MessagePopUp
          id={"validationMessage"}
          content={this.props.errorsItineraries}
          ref={(validationPopUp) => { this.validationPopUp = validationPopUp }}
          onExited={this.clearErrorMessage}
        />
        {this.renderHeading(itineraryHeading)}
        {!isLoadingItineraries ? (
          <div>
            {itineraries
              && itineraries.map((itinerary) => {
                return (
                  <ItineraryListItem
                    item={itinerary}
                    itineraryType={itineraryType}
                    aircraftsList={aircraftsList}
                    key={itinerary.orderIdentifier}
                    index={itineraries.indexOf(itinerary)}
                    visitedAirportsFinder={visitedAirportsFinder}
                    onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
                    isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
                  />);
              })}
            {!itineraries.length && !isLoadingItineraries && (
              <div className="uk-card uk-card-default uk-card-body vistajet_card uk-padding-remove uk-margin-medium-top">
              <div className="vistajet_statement_content">
                <p class="uk-margin-left vistajet_dark_text uk-text-center vistajet_msg_error_margin">{emptyMessage}</p>
              </div>
            </div>)}
            {isLoadMoreDisable && (!isLoadingMore ? this.renderButton('Load More') : (<div className="uk-text-center uk-margin"><SpinnerLoader /></div>))}            
          </div>
        ) :
          (
            <div className="vistajet_offer_spinner" className="vistajet_itineries_loader">
              <SpinnerLoader />
            </div>
          )}
      </div>
    );
  }
}

export default ItinerariesListing;
