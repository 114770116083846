import React, { Component } from 'react';
import Modal from "components/shared/Modal";
import FboModal from "components/ItineraryDetails/FboModal";
import SpinnerLoader from '../shared/spinnerLoader';

const noFboAirport = "The FBO address cannot be found at this time. Would you like directions to the airport instead?";

class Fbo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAirportId: null,
            selectedFbo: {},
        };
      }
    
  renderFbo = (fboDetails, arrivalAirportId, departureAirportId, visitedAirportsFinder) => {
    const arrivalAirport = visitedAirportsFinder[arrivalAirportId] || {};
    const departureAirport = visitedAirportsFinder[departureAirportId] || {};
    const arrivalAirportName = arrivalAirport.name || '';
    const departureAirportName = departureAirport.name || '';
    const arrivalAirportAddress = `https://www.google.com/maps/dir//${(arrivalAirport || {}).latitude},${(arrivalAirport || {}).longitude}`;
    const departureAirportAddress = `https://www.google.com/maps/dir//${(departureAirport || {}).latitude},${(departureAirport || {}).longitude}`;
    if(fboDetails && fboDetails.length) {
        const arrivalFbo = fboDetails.find(fbo => fbo.airportId === arrivalAirportId) || null;
        const departureFbo = fboDetails.find(fbo => fbo.airportId === departureAirportId) || null;
        return (
        <div className="uk-grid uk-grid-large" data-uk-grid> 
            <div className="uk-width-1-1 uk-width-1-2@s">
                <h5 className="vistajet_departure_heading">{departureAirportName}</h5>
                {
                    departureFbo ? (
                    <div>
                        <h4>{departureFbo.handlingAgentName}</h4>
                        <ul>
                            { departureFbo.handlingAgentPhoneNumber ?
                                <li><span className="icon- baseline_phone"></span>{departureFbo.handlingAgentPhoneNumber}</li> :
                                null
                            }
    
                            { departureFbo.fboEmailAddress ?
                                <li><span className="icon- baseline_email"></span>{departureFbo.fboEmailAddress}</li> :
                                null
                            }
    
                            { departureFbo.fboAddress ?
                                <li><span className="icon- baseline_location"></span>{departureFbo.fboAddress}</li> :
                                null
                            }
                            <li><a href="#modal-fbo" onClick={() => this.showFboDetails(departureFbo)}>View Details</a></li>
                        </ul>
                    </div>
                ) : (
                    <div>
                        {noFboAirport} <a href={departureAirportAddress} target="_blank" rel="noopener noreferrer" className="vistajet_color_red"> Click Here.</a>
                    </div>
                )
                }
            </div> 
            <div className="uk-width-1-1 uk-width-1-2@s">
              <h5 className="vistajet-arrival-heading">{arrivalAirportName}</h5>
              {
                    arrivalFbo ? (
                    <div>
                        <h4>{arrivalFbo.handlingAgentName}</h4>
                        <ul>
                            { arrivalFbo.handlingAgentPhoneNumber ?
                                <li><span className="icon- baseline_phone"></span>{arrivalFbo.handlingAgentPhoneNumber}</li> :
                                null
                            }
        
                            { arrivalFbo.fboEmailAddress ?
                                <li><span className="icon- baseline_email"></span>{arrivalFbo.fboEmailAddress}</li> :
                                null
                            }
        
                            { arrivalFbo.fboAddress ?
                                <li><span className="icon- baseline_location"></span>{arrivalFbo.fboAddress}</li> :
                                null
                            }
                            <li><a href="#modal-fbo" onClick={() => this.showFboDetails(arrivalFbo)}>View Details</a></li>
                        </ul>
                    </div>
                ) : (
                    <div>
                        {noFboAirport} <a href={arrivalAirportAddress} target="_blank" rel="noopener noreferrer" className="vistajet_color_red"> Click Here.</a>
                    </div>
                )
                } 
            </div>
        </div>
        )   
    } else {
        return (<div>
        {'No FBO information available at this time.'}
    </div>);
    }
}

showFboDetails = (fbo) => {
    this.setState({ 
        selectedAirportId: fbo.airportId,
        selectedFbo: fbo
    });
    this.openFBOModal();
}

openFBOModal = () => {
    this.fboPopup.show();
}

handleBack = () => {
    this.fboPopup.hide();
}

render() {
    const { fboDetails, 
            isLoading, 
            isLoadingFbo,
            visitedAirportsFinder,
            arrivalAirportId,
            departureAirportId,
            isGoogleMapsApiLoaded,
            onGoogleMapsApiLoaded,
            legId,
     } = this.props;
     const { selectedAirportId, selectedFbo } = this.state;
    
    return (
        <div class="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove vistajet_itinerary_fbo">
            <div className="vistajet_itinerary_card_header">
                <h5 className="uk-width-expand">FBO</h5>
            </div>

            <div className="vistajet_itinerary_card_body">
            {
                !isLoading && !isLoadingFbo ? this.renderFbo(fboDetails, arrivalAirportId, departureAirportId, visitedAirportsFinder) : 
                (
                    <div className="vistajet_spinner">
                    <SpinnerLoader />
                    </div>
                )
            }
                
            </div>
            <Modal
                ref={(fboPopup) => {
                    this.fboPopup = fboPopup;
                }}
                showCloseIcon={false}
                classNames={{
                    overlay:'vistajet_modal vistajet_modal_large',
                    modal:'vistajet_modal_body vistajet_react_modal uk-padding-remove',
                    closeButton:'vistajet_modal_close',
                    closeIcon:'<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line><line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line></svg>'
                    }}
            >
                <FboModal
                    selectedAirportId={selectedAirportId}
                    selectedFbo={selectedFbo}
                    legId={legId}
                    isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
                    onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
                    handleBack={this.handleBack}
                    visitedAirportsFinder={visitedAirportsFinder}
                />

            </Modal>
        </div>

        
    );
  }
}


export default Fbo;
