import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStore from '../withStore';
import * as actions from '../../store/actions';
import ProgramBuilderWidgetContainer from '../../containers/ProgramBuilderWidget';
import ProgramBuilderDetailForm from './ProgramBuilderDetailForm';
import ProgramBuilderDonutAndSuggestion from './ProgramBuilderDonutAndSuggestion';
import { AIRCRAFT_DETAILS } from '../../mocks/aircraftDetails';
import Helpers from "../../utils/Helpers";

class ProgramBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legArray: {},
      isLegArray: false,
      suggestionData: {},
      showSuggestions: false,
      personalInfo: {},
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps() {
  }

  updateLegArray = (legArray) => {
    this.setState({ legArray: this.copy(legArray), isLegArray: true }, () => this.prepareDonutData(false));
  };

  /* To deep copy an Object in tempArray */
  copy = (o) => {
    let output; let v; let
      key;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
      v = o[key];
      output[key] = (typeof v === 'object') ? this.copy(v) : v;
    }
    return output;
  };

  prepareDonutData = (showSuggestion) => {
    const { legArray } = this.state;

    const tempArray = this.copy(legArray.legArray);

    tempArray.map((leg) => {
      leg.duration = (parseFloat(leg.duration) / 60) * leg.years;
      return leg;
    });

    const routesHours = Helpers.roundUpTo(tempArray
      .map(v => v.duration)
      .reduce((sum, current) => sum + current, 0), 25);

    const extraHours = Helpers.roundUpTo(routesHours * 0.2, 5);

    if (showSuggestion) {
      this.setState({
        suggestionData: { yearlyHours: parseInt(routesHours + extraHours), airCraft: AIRCRAFT_DETAILS[tempArray[0].selectedAircraft.id] },
        showSuggestions: true,
      }, () => { document.getElementById('donut-suggestion').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) });
    } else {
      this.setState({
        suggestionData: { yearlyHours: parseInt(routesHours + extraHours), airCraft: AIRCRAFT_DETAILS[tempArray[0].selectedAircraft.id] },
        showSuggestions: false,
      });
    }
  };

  submitSeeMore = (data) => {
    this.setState({ personalInfo: data });
    this.prepareDonutData(true);
  };

  render() {
    const {
      isLegArray, suggestionData, showSuggestions, legArray,
    } = this.state;
    const programBuilder = <ProgramBuilderWidgetContainer onChange={this.updateLegArray} />;
    return (

      <div className="vistajet_program_builder_wrapper">

        <ProgramBuilderDetailForm
          isLegArray={isLegArray}
          submit={this.submitSeeMore}
          legArray={legArray}
          suggestionData={suggestionData}
          programBuilder={programBuilder}
          {...this.props}
        />
        {
          showSuggestions && (
            <div id="donut-suggestion">
              <ProgramBuilderDonutAndSuggestion
                data={suggestionData}
                {...this.props}
              />
            </div>
          )}
      </div>

    );
  }
}

function mapStateToProps(state) {
  const { isLoading, isSuccess } = state.contactus;
  return {
    isLoading,
    isSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClearReducer: () => dispatch(actions.clearReducer()),
    onContactUsForm: payload => dispatch(actions.postContactUs(payload)),
  };
}

const ProgramBuilderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramBuilder);

export default withStore(ProgramBuilderContainer);