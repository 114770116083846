import React from "react";
import PropTypes from "prop-types";
import TimeElement from "./TimeElement";
import moment from 'moment';



const FIRST_TWO = /^(.){2}/;
const LAST_TWO = /(.){2}$/;
const HOUR_VALID_REGEX = /^([01]\d|2[0-3])/;
const TIME_VALID_REGEX = /^([01]\d|2[0-3]):([0-5]\d$)/;

const HOUR_MINUTE_FORMAT = '__:__';
const buildArray = length => Array.apply(null, { length });

const fillArray = (multiplier = 1) => (v, i) => `${i * multiplier < 10 ? 0 : ''}${i * multiplier}`;

export const HOURS = buildArray(24).map(fillArray());

export const MINUTES = buildArray(60 / 5).map(fillArray(5));

export const validateTime = time => TIME_VALID_REGEX.test(time);

export const roundUpToMultipleOfFive = num => {
  const mod = num % 5;
  return mod > 0 ? num + (5 - num % 5) : num;
};

export const roundMinutesUpToMultipleOfFive = minute => {
  const min = parseInt(minute, 10);
  return `${min > 55 ? 0 : roundUpToMultipleOfFive(min)}`.padStart(2, '0');
};
class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTimePicker: false,
      selectHour: false,
      selectMin: false,
      val: props.time || ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.time !== this.state.val) {
      this.setState({
        val: nextProps.time,
      });
    }
  }
  buildArray = length => Array(...{ length });

  fillArray = (multiplier = 1) => (v, i) =>
    `${i * multiplier < 10 ? 0 : ""}${i * multiplier}`;

  closeTimePicker = (hour, minutes) => {
    if (hour && minutes) {
      this.setState({
        openTimePicker: false
      });
    }
  };

  
  handleHoursSelect = (hours, event) => {
    const time = this.state.val || HOUR_MINUTE_FORMAT;
    this.handleChange(time.replace(FIRST_TWO, hours), event);
  };

  handleMinutesSelect = (minutes, event) => {
    let time = this.state.val || HOUR_MINUTE_FORMAT;
    // whether hour is not valid
    if (!HOUR_VALID_REGEX.test(time)) {
      // set current hours
      time = time.replace(FIRST_TWO, moment().format('HH'));
    }
    time = time.replace(LAST_TWO, minutes);
    const list = document.getElementsByClassName('timePickerClass');
    Array.from(list).forEach(element => {
      element.classList.remove('uk-open');
      window.UIkit.dropdown(element).hide();
    });
    this.handleChange(time, event);
  };

  handleChange = (time, event) => {
    this.setState({ val: time });
    this.props.handleOnChange(time);
    // this.props.onChange(time, validateTime(time), event);
  };


  handleDepartureTimeHour = value => {
    const { handleHour } = this.props;
    if (handleHour) {
      handleHour(value);
    }
    this.setState({
      selectHour: !this.state.selectHour
    });
    // this.closeTimePicker(!this.state.selectHour, this.state.selectMin);
  };

  handleDepartureTimeMinutes = value => {
    const { handleMinutes } = this.props;
    if (handleMinutes) {
      handleMinutes(value);
    }
    this.setState({
      selectMin: !this.state.selectMin
    });
    // this.closeTimePicker(this.state.selectHour, !this.state.selectMin);
  };
  handleInputClick = () => {
    this.setState({
      openTimePicker: true
    });
  };

  render() {
    const { hintText} = this.props;
    let selectedFlightTime = this.state.val;
    let loc = this.state.val ? 'vistajet_timepicker vistajet_loc' : 'vistajet_timepicker';
    return (
      <div className={loc}> 
        <input
          type="text"
          value={selectedFlightTime}
          placeholder={hintText}
          readOnly
        />
        <div data-uk-dropdown="mode: click" pos = "bottom-center" data-animation = "uk-animation-slide-bottom-small" id="timePickerDropDown" className = "timePickerClass noPaddingBottom">
          <div className="uk-dropdown-grid-remove" data-uk-grid>
            <div>
              <small>HH</small>
              <div className="vistajet_select_timepickerlist">
                <ul
                  className="uk-nav uk-dropdown-nav t_line"
                  style={{ height: "160px", overflowY: "auto" }}
                >
                  <li>
                    {HOURS.map((hour, index) => (
                      <TimeElement
                        key={index}
                        onClick={this.handleHoursSelect}
                        value={hour}
                      />
                    ))}
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <small>MM</small>
              <div className="vistajet_select_timepickerlist">
                <ul
                  className="uk-nav uk-dropdown-nav"
                  style={{ height: "160px", overflowY: "auto" }}
                >
                  <li>
                    {MINUTES.map(minutes => (
                      <TimeElement
                        onClick={this.handleMinutesSelect}
                        value={minutes}
                      />
                    ))}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    );
  }
}

export default TimePicker;

TimePicker.propTypes = {
  hour: PropTypes.string,
  minutes: PropTypes.string,
  selectedTime: PropTypes.string,
  handleHour: PropTypes.string,
  handleMinutes: PropTypes.string
};

TimePicker.defaultProps = {
  hour: "",
  minutes: "",
  selectedTime: "",
  handleHour: "",
  handleMinutes: ""
};
