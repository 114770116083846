import React, { Component } from 'react';
import DateTime from 'react-datetime';
import moment from 'moment'
import { convertToZulu } from '../../utils/dateUtils';
import { isValid } from 'ipaddr.js';

class ReactCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    validDates = (current) => {
        if (current) {
            const currentIsAfter = current.isAfter(moment(this.props.minDate).subtract(1, 'day').toDate());
            const currentIsBefore = current.isBefore(moment(this.props.maxDate).toDate())
            return currentIsAfter && currentIsBefore;
        }
        return false;
    }
    handleOnChange = (selectedDate) => {
        const date = convertToZulu(moment(selectedDate, ['DD/MM/YYYY', 'DD MMM YYYY'], true));
        let dateInput = isValid && this.validDates(date) ? date.utc().format('DD MMM YYYY') : selectedDate;
        this.props.onDateChange(dateInput);
    }
    render() {
        const inputProps = {
            placeholder: this.props.placeholder,
            readOnly: this.props.readOnly,
            autoComplete: 'off',
        };
        return (
            <DateTime
                utc={this.props.utc || false}
                timeFormat={false}
                closeOnSelect={true}
                closeOnTab = {true}
                dateFormat="DD MMM YYYY"
                disableOnClickOutside={true}
                inputProps={inputProps}
                isValidDate={this.validDates}
                onChange={this.handleOnChange}
                value  = {this.props.dateInput}
                className = {this.props.className || ''}
            />
        )
    }
}

export default ReactCalendar;