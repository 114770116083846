import CateringConstants from '../constants/CateringConstants';
import { CATERING_NOT_FOUND } from '../constants/MessageConstants';
import { isPastLeg } from '../utils/itinerariesUtils';
import moment from 'moment';

function getCateringMap(stateCode) {
  let status = CateringConstants.CATERING_STATUS_PENDING_APPROVAL;
  Object.keys(CateringConstants.cateringStates).forEach((state) => {
    if (CateringConstants.cateringStates[state].indexOf(+stateCode) > -1) {
      status = CateringConstants[`CATERING_STATUS_${state}`];
    }
  });
  return status;
}

export const getCateringStatus = (cateringDetails, isPast) => {
  let statusToShow = CATERING_NOT_FOUND;
  let summaryAvailable = false;
  if (!!cateringDetails.stateId) {
    const backendState = +cateringDetails.stateId;
    summaryAvailable = cateringDialogEnabled(backendState);
    if (backendState !== 0 && backendState !== 1) {
      const status = getCateringMap(backendState);
      statusToShow = status;
    }
    if (isPast) {
      const status = getCateringMap(backendState);

      if (!summaryAvailable &&
        status === CateringConstants.CATERING_STATUS_PENDING_APPROVAL ||
        status === CateringConstants.CATERING_STATUS_PENDING_SELECTION ||
        status === CateringConstants.CATERING_STATUS_NOT_REQUIRED) {
        statusToShow = status;
      }
      if (summaryAvailable) {
        statusToShow = status;
      }
    }
  }
  return statusToShow;
}

export function cateringDialogEnabled(stateCode) {
  const status = getCateringMap(stateCode);
  return (status === CateringConstants.CATERING_STATUS_CONFIRMED ||
    status === CateringConstants.CATERING_STATUS_ON_REQUEST ||
    status === CateringConstants.CATERING_STATUS_PENDING_SELECTION);
}

export function isCateringEditable(leg, within24Hours) {
  return leg && !isPastLeg(leg) && !within24Hours;
}

export function selectionCountdownString(departureTime) {
  const currentTime = moment.utc();
  const toTime = moment.utc(departureTime);
  const selectionTime = toTime.subtract(1, 'days');
  const days = selectionTime.diff(currentTime, 'days');
  const hours = selectionTime
    .subtract(days, 'days')
    .diff(currentTime, 'hours');

  let string = '';
  if (days > 0 || hours > 0) {
    string = '';
    if (days > 0) {
      string += (days === 1)
        ? (` ${days} day`)
        : (` ${days} days`);
    }
    if (hours > 0) {
      string += (hours === 1)
        ? (` ${hours} hour`)
        : (` ${hours} hours`);
    }
    return string;
  }
  return string;
}

export function shouldShowCountdownString(catering, within24Hours) {
  const status = getCateringMap(+catering.stateId);
  return (catering && !within24Hours &&
    (status === CateringConstants.CATERING_STATUS_ON_REQUEST ||
      status === CateringConstants.CATERING_STATUS_PENDING_SELECTION ||
      status === CateringConstants.CATERING_STATUS_CONFIRMED));
}

export function isSelectionMade(catering, isPast) {
  const status = getCateringStatus(catering, isPast);
  return (status === CateringConstants.CATERING_STATUS_ON_REQUEST ||
    status === CateringConstants.CATERING_STATUS_CONFIRMED);
}

export function isPendingSelection(catering) {
  const status = getCateringStatus(catering);
  return status === CateringConstants.CATERING_STATUS_PENDING_SELECTION;
}

// if count is of single digit then it prepends 0 to it
export function zeroFill(count) {
  let countStr = count.toString();
  if (countStr.length === 1) {
    countStr = `0${countStr}`;
  }
  return countStr;
}
