import {
  guaranteeArray,
} from '../utils/arrayUtils';
import { normalizePaymentAdditionalCharges } from '../utils/creditCardUtils';
import { builderXmlRequest } from '../utils/parserUtils';

export function normalizeFees(fees) {
  return fees && fees.fee;
}

function normalizeMembership(membership) {
  return {
    additionalCharges: normalizePaymentAdditionalCharges(
      guaranteeArray(membership.paymentAdditionalCharges
        && membership.paymentAdditionalCharges.paymentAdditionalCharge),
    ),
    description: membership.description,
    id: membership.planId,
    name: membership.title,
    price: {
      amount: membership.price,
      currency: membership.currency,
      details: normalizeFees(membership.fees),
    },
    termsAndConditionsId: membership.termsAndConditionsId,
  };
}

export function normalizeMemberships(membershipList) {
  return membershipList && membershipList.map(normalizeMembership).reduce((prev, membership) => {
    const previous = prev;
    previous.ids.push(membership.id);
    previous.content[membership.id] = membership;
    return previous;
  }, {
    ids: [],
    content: {},
  });
}

export function normalizeBankDetails(btResponse) {
  return {
    to: btResponse.bankDetails.to,
    iban: btResponse.bankDetails.iban,
    referenceNumber: btResponse.bankDetails.referenceNumber,
    bankCode: btResponse.bankDetails.swiftCode || btResponse.bankDetails.bankCode,
    bankName: btResponse.bankDetails.bankName,
    accountId: btResponse.accountId,
    accessType: btResponse.accessTypeId,
    statusMembership: btResponse.statusMembership,
    membershipTypeId: btResponse.membershipTypeId,
  };
}

export function normalizeCreditCardDetails(ccResponse) {
  const ccDetails = ccResponse.creditCardDetails;
  return {
    referenceNumber: ccDetails && ccDetails.referenceNumber,
    phoneNumber: ccDetails && ccDetails.phoneNumber,
    statusMembership: ccResponse.statusMembership,
    membershipTypeId: ccResponse.membershipTypeId,
    accessType: ccResponse.accessTypeId,
    accountId: ccResponse.accountId,
  };
}
export function normalizeSignupWithoutCardResponse(SignUpDto) {
  return {
    statusMembership: SignUpDto.statusMembership,
    accessType: SignUpDto.accessTypeId,
    accountId: SignUpDto.accountId,
    membershipTypeId: SignUpDto.membershipTypeId,
  }
}
export function directSignupRequestWithoutPaymentNormalizer(SignUpDto) {
  return builderXmlRequest({
    SignUpDto,
  })
}
export function directSignupRequestNormalizer(
  billingAddress,
  membershipTypeId,
  paymentOptionId,
  creditCard,
) {
  const {
    firstName,
    lastName,
    country,
    city,
    state,
    postalCode,
    address,
    vat,
  } = billingAddress;
  const {
    alias,
    cvv,
  } = creditCard || {};

  return builderXmlRequest({
    SignUpDto: {
      addressableName: `${firstName} ${lastName}`,
      countryCode: country.code,
      city,
      state,
      postCode: postalCode,
      vatNumber: vat,
      billingAddress: address,
      paymentOptionId,
      memberShipTypeId: membershipTypeId,
      creditCardAlias: alias,
      cvvCode: cvv,
    },
  });
}
