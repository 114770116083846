import axios from 'axios';
import { FLIGHT_CANCEL_URL } from '../../configs/constants';
import {
  flightCancelInit,
  flightCancelSuccess,
  flightCancelFail,
} from '../actions';
import { flightPayload } from '../../utils/itinerariesUtils';
import { parseXmlStringToJson } from '../../utils/parserUtils';
import {
  isAdhoc,
  isFSP,
  isVJ2,
} from '../../utils/userUtils';

const status = {
  OK: 200,
};

export const flightCancelSource = (token, accountId, leg, user, orderId) => (dispatch) => {
  let url;
  url = FLIGHT_CANCEL_URL.replace('{token}', token);
  const userType = {
    isVJ2: isVJ2(user),
    isAdhoc: isAdhoc(user),
    isFSP: isFSP(user),
  };
  const reqPayload = flightPayload(userType, leg, orderId, accountId);
  const options = {
    headers: {
      "Accept": "application/xml",
      "Content-Type": "application/xml",
    }
  };
  dispatch(flightCancelInit());
  axios
    .post(url, reqPayload, options)
    .then((response) => {
      if (response && response.status === status.OK) {
        const jsonData = parseXmlStringToJson(response.data);
        const jsonResponse = jsonData.ResponseBody.responseHeader.message
        dispatch(flightCancelSuccess(jsonResponse));
      } else {
        dispatch(flightCancelFail());
      }
    })
    .catch(() => {
      dispatch(flightCancelFail());
    });
};

export default flightCancelSource;
