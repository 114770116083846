import axios from 'axios';
import { normalizeAirports } from '../../normalizers/airportNomalizer';

import {
  fetchVisitedAirportsInit,
  fetchVisitedAirportsSuccess,
  fetchVisitedAirportsFail,
  fetchAirportByIdInit,
  fetchAirportByIdSuccess,
  fetchAirportByIdFail,
} from '../actions';

import {
  FETCH_VISITED_AIRPORTS_URL,
  FETCH_AIRPORT_BY_ID,
} from '../../configs/constants';

const status = {
  OK: 200,
};

const visitedAirportsPromiseInprogress = {};
const airportByIdPromiseInprogress = {};
export const fetchVisitedAirports = () => (dispatch) => {
  const uniqueKey = 'visitedAiports';
  if (visitedAirportsPromiseInprogress[uniqueKey]) {
    return visitedAirportsPromiseInprogress[uniqueKey];
  }
  const visitedAirportsPromise = new Promise((resolve, reject) => {
    dispatch(fetchVisitedAirportsInit());
    axios
      .get(FETCH_VISITED_AIRPORTS_URL)
      .then((response) => {
        if (response.status === status.OK) {
          const { data } = response;
          if (data.responseHeaderDto.isError === 'true') {
            const error = data.responseHeaderDto.message;
            dispatch(fetchVisitedAirportsFail(error));
          } else {
            const visitedAirports = data.airports;
            const normalizedAirports = normalizeAirports(visitedAirports);
            dispatch(fetchVisitedAirportsSuccess(normalizedAirports));
            resolve(normalizedAirports);
          }
        } else {
          dispatch(fetchVisitedAirportsFail());
          reject();
        }
        delete visitedAirportsPromiseInprogress[uniqueKey];
      })
      .catch(() => {
        dispatch(fetchVisitedAirportsFail());
        reject();
        delete visitedAirportsPromiseInprogress[uniqueKey];
      });
  });
  visitedAirportsPromiseInprogress[uniqueKey] = visitedAirportsPromise;
  return visitedAirportsPromise;
};

export const fetchAirportById = airportIds => (dispatch) => {
  const uniqueKey = 'airportById';
  if (airportByIdPromiseInprogress[uniqueKey]) {
    return airportByIdPromiseInprogress[uniqueKey];
  }
  const airportByIdPromise = new Promise((resolve, reject) => {
    dispatch(fetchAirportByIdInit());
    const payLoad = {
      airportIdList: airportIds,
    };
    axios
      .post(FETCH_AIRPORT_BY_ID, payLoad)
      .then((response) => {
        if (response.status === status.OK) {
          const { data } = response;
          if (data.responseHeaderDto.isError === 'true') {
            const error = data.responseHeaderDto.message;
            dispatch(fetchAirportByIdFail(error));
          } else {
            const { airports } = data;
            const normalizedAirport = normalizeAirports(airports);
            dispatch(fetchAirportByIdSuccess(normalizedAirport));
            resolve(normalizedAirport);
          }
        } else {
          dispatch(fetchAirportByIdFail());
          reject();
        }
        delete airportByIdPromiseInprogress[uniqueKey];
      })
      .catch(() => {
        dispatch(fetchAirportByIdFail());
        reject();
        delete airportByIdPromiseInprogress[uniqueKey];
      });
  });
  airportByIdPromiseInprogress[uniqueKey] = airportByIdPromise;
  return airportByIdPromise;
};
