import axios from 'axios';
import {
  postContactUsInit,
  postContactUsSuccess,
  postContactUsFail,
} from '../actions';

const status = {
  OK: 200,
};
const contactUsPromiseInprogress = {};
export const postContactUsForm = payload => (dispatch) => {
  const param = payload;
  const uniqueKey = 'contactUs';
  if (contactUsPromiseInprogress[uniqueKey]) {
    return contactUsPromiseInprogress[uniqueKey];
  }
  const contactUsPromise = new Promise((resolve, reject) => {
    dispatch(postContactUsInit());
    const CONTACTUS_URL = param.url;
    delete param.url;
    const formData = new FormData();
    Object.keys(param).forEach(key => {
      if (typeof param[key] === 'object') {
        formData.append(key, JSON.stringify(param[key]));
      } else {
        formData.append(key, param[key]);
      }
    });
    let contactUsSuccess;
    axios
      .post(`${CONTACTUS_URL}`, formData)
      .then((response) => {
        if (response && response.status === status.OK) {
          contactUsSuccess = true;
          dispatch(postContactUsSuccess(contactUsSuccess));
          resolve(contactUsSuccess);
        } else {
          contactUsSuccess = false;
          dispatch(postContactUsFail());
          reject(contactUsSuccess);
        }
        delete contactUsPromiseInprogress[uniqueKey];
      })
      .catch(() => {
        contactUsSuccess = true;
        dispatch(postContactUsSuccess(contactUsSuccess));
        resolve(contactUsSuccess);
        // dispatch(postContactUsFail());
        // reject(contactUsSuccess);
        delete contactUsPromiseInprogress[uniqueKey];
      });
  });
  contactUsPromiseInprogress[uniqueKey] = contactUsPromise;
  return contactUsPromise;
};

export default postContactUsForm;
