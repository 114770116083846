import * as types from '../actionTypes';

export const initialState = {
  swift: '',
  bankName: '',
  currency: '',
  topUpAmount: '',
  to: '',
  iban: '',
  referenceCode: '',
  topUpId: 0,
  paymentMethod: '',
  topUpStatusDesc: '',
  openPopUpMessage: 0,  // 1 for success, 2 for cancel
  topUpReferenceLoader: false,
  topUpSubmittedLoader: false,
  topUpCancelLoader: false,
  disableAmountField: false,
  topUpError:'',
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOPUP_REFERENCE_INIT: {
      return Object.assign({}, state, {
        topUpReferenceLoader: true,
        disableAmountField: false,
        topUpError: '',
      });
    }
    case types.TOPUP_REFERENCE_SUCCESS: {
      return Object.assign({}, state, {
        swift: action.data.swift,
        bankName: action.data.bankName,
        currency: action.data.currency,
        topUpAmount: action.data.topUpAmount,
        to: action.data.to,
        iban: action.data.iban,
        referenceCode: action.data.referenceCode,
        topUpId: action.data.topUpId,
        paymentMethod: action.data.paymentMethod,
        topUpStatusDesc: action.data.topUpStatusDesc,
        topUpReferenceLoader: false,
        disableAmountField: true,
      });
    }
    case types.TOPUP_REFERENCE_FAIL: {
      return Object.assign({}, state, {
        topUpReferenceLoader: false,
        disableAmountField: true,
        topUpError: action.data,
      });
    }
    case types.TOPUP_INIT : {
      return Object.assign({}, state, {
        topUpSubmittedLoader: true,
        topUpError: '',
      });
    }
    case types.TOPUP_SUCCESS : {
      return Object.assign({}, state, {
        openPopUpMessage: action.data,
      });
    }
    case types.TOPUP_FAIL : {
      return Object.assign({}, state, {
        topUpSubmittedLoader: false,
        topUpError: action.data,
      });
    }

    case types.CANCEL_REFERENCE_CODE_INIT : {
      return Object.assign({}, state, {
        topUpCancelLoader: true,
        topUpError: '',
      });
    }
    case types.CANCEL_REFERENCE_CODE_SUCCESS : {
      return Object.assign({}, state, {
       openPopUpMessage: action.data,
      });
    }
    case types.CANCEL_REFERENCE_CODE_FAIL : {
      return Object.assign({}, state, {
        topUpCancelLoader: false,
        topUpError: action.data,
      });
    }
    case types.TOPUP_VIEW_ACTION : {
      return Object.assign({}, state, {
        openPopUpMessage: 0,
        topUpCancelLoader: false,
        topUpSubmittedLoader: false,
      });
    }
    case types.CLEAR_TOPUP_ERROR : {
      return Object.assign({}, state, {
        topUpError: '',
      });
    }
    default:
      return state;
  }
}
