import { format, isWithinInterval, parseISO } from 'date-fns';
import React, { Component } from 'react';
import { dateFnsFormats } from 'utils/dateUtils';

class CardWithButton extends Component {
  handleSendRequest = () => {
    this.props.handleSendRequest();
    this.props.isSendRequest(true);
  };

  routeToDirectRegistration = (leftRef) => {
    window.open(leftRef, '_self');
  };

  render() {
    const { offerDetails, aircraftDetails } = this.props;
    const {
      aircraftName,
      flyingHours,
      range,
      maximumSpeed,
      passengerCapacity,
      imgUrl,
      pageUrl,
    } = aircraftDetails;
    const { from, to } = offerDetails;
    const availability = isWithinInterval(new Date(), { start: parseISO(from), end: parseISO(to) })
      ? 'Now'
      : 'From';
    return (
      <div>
        <div
          className="uk-width-1-3@s uk-width-1-3@m uk-grid-medium uk-margin-small-left remove_margin_small_page"
          data-uk-grid
        >
          <div className="uk-clearfix remove_padding_small_device">
            <div className="uk-card uk-card-body vistajet_card vistajet_card_dropoff vistajet_card_offer_map_details">
              <div className="vistajet_card_offer_map_content">
                <div className="vistajet_location_guide uk-float-left">
                  {offerDetails.departureCity}
                  {' '}
                  <span>
                    {offerDetails.departureAirportName}
                  </span>
                </div>
                <div className="vistajet_location_guide uk-float-right">
                  {offerDetails.arrivalCity ? (
                    <div>
                      {offerDetails.arrivalCity}
                      <span>
                        {offerDetails.arrivalAirportName}
                      </span>
                    </div>
                  ) : (
                    <i>
                      Anywhere
                    </i>
                  )}
                </div>

                <div className="vistajet_available_craft">
                  <img src={imgUrl.default} alt="" />
                  <div className="vistajet_planshadows" />
                  <a href={pageUrl} target="_blank" rel="noopener noreferrer">
                    <small>
                      {aircraftName}
                    </small>
                  </a>
                </div>
                <div className="vistajet_location_detail">
                  <small>
                    <span>
                      <strong>
                        Flying hours:
                        {' '}
                      </strong>
                      {flyingHours}
                    </span>
                    <span>
                      <strong>
                        Range:
                        {' '}
                      </strong>
                      {range}
                    </span>
                    <span>
                      <strong>
                        Maximum speed:
                        {' '}
                      </strong>
                      {maximumSpeed}
                    </span>
                    <span>
                      <strong>
                        Passenger capacity:
                        {' '}
                      </strong>
                      {passengerCapacity}
                    </span>
                  </small>
                </div>
                <div className="vistajet_available_date uk-text-center">
                  Available
                  {' '}
                  {availability}
                  :
                  {' '}
                  <span>
                    {format(parseISO(from), dateFnsFormats.DDMMMYYYY)}
                    {' '}
                    -
                    {' '}
                    {format(parseISO(to), dateFnsFormats.DDMMMYYYY)}
                  </span>
                </div>
              </div>
              <a
                onClick={this.handleSendRequest}
                className="uk-button uk-button-default vistajet_button_primary"
              >
                View Offer
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardWithButton;
