import * as types from '../actionTypes';

export const initialState = {
  topUpIds: [],
  topUpContent: {},
  membershipAccount: {},
  topUpHistoryLoader: false,
  topUpHistoryError:'',
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOPUP_HISTORY_INIT : {
      return Object.assign({}, state, {
        topUpHistoryLoader: true,
        topUpHistoryError: '',
      });
    }
    case types.TOPUP_HISTORY_SUCCESS : {
      const { data } = action;
      const descSortArray = data.topUps.ids.sort(function(a, b){ return b-a });
      return Object.assign({}, state, {
        topUpIds: descSortArray,
        topUpContent: data.topUps.content,
        membershipAccount: data.membershipAccount,
        topUpHistoryLoader: false,
      });
    }
    case types.TOPUP_HISTORY_FAIL : {
      return Object.assign({}, state, {
        topUpHistoryLoader: false,
        topUpHistoryError: action.data,
      });
    }
    case types.CLEAR_TOPUP_ERROR : {
      return Object.assign({}, state, {
        topUpHistoryError: '',
      });
    }
    default:
      return state;
  }
}
