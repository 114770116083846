import axios from 'axios';

import {
  AUTHENTICATE_URL,
  APP_VERSION,
} from '../../configs/constants';
import decryptSingupKeys from '../../utils/decrypt';

import {
  authenticateTokenInit,
  authenticateTokenSuccess,
  authenticateTokenFail,
} from '../actions';

import {
  HTTP_HEADERS_JSON,
} from '../../constants/ApiConstants';

import {
  handleServerErrors,
  encryptData,
} from '../../utils/sourceUtils';
import normalizeAuthenticationResponse from '../../normalizers/authenticateTokenNormalizer';

const status = {
  OK: 200,
};

const authenticateTokenPromiseInprogress = {};
const {DECRYPTED_SIGNUP_CONTEXT, DECRYPTED_SIGNUP_CONTEXT_PASSWORD}  = decryptSingupKeys();
const authenticateTokenSource = token => (dispatch) => {
  const uniqueKey = token;
  if (authenticateTokenPromiseInprogress[uniqueKey]) {
    return authenticateTokenPromiseInprogress[uniqueKey];
  }
  const authenticateTokenPromise = new Promise((resolve, reject) => {
    const url = AUTHENTICATE_URL
      .replace('{context}', DECRYPTED_SIGNUP_CONTEXT)
      .replace('{username}', DECRYPTED_SIGNUP_CONTEXT);

    const data = `${DECRYPTED_SIGNUP_CONTEXT}\\+${DECRYPTED_SIGNUP_CONTEXT_PASSWORD}`;

    const key = encryptData(token, data);

    const reqPayload = {
      credentials: token + key,
      attributes: {
        deviceInfo: 'WEB',
        appBuildNr: APP_VERSION,
        phoneNumber: '',
        devicePushId: '',
      },
    };


    dispatch(authenticateTokenInit());

    axios
      .put(
        url,
        reqPayload,
        HTTP_HEADERS_JSON,
      )
      // .timeout(TIMEOUT)
      // .set(HTTP_HEADERS_JSON)
      .then((res) => {
        // Helpers.showLoader(false);
        if (res.status === status.OK) {
          if (res.data.responseHeader.isError) {
            const errors = handleServerErrors(res.data.responseHeader.statusCode);
            dispatch(authenticateTokenFail(errors));
            reject(errors);
          } else {
            const normalizedAuthenticateResponse = normalizeAuthenticationResponse(
              res.data.responseBody,
              token,
            );
            dispatch(authenticateTokenSuccess(normalizedAuthenticateResponse));
            resolve({ token, success: true });
          }
        } else {
          const errors = handleServerErrors(res.status);
          dispatch(authenticateTokenFail(errors));
          reject(errors);
        }
        delete authenticateTokenPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(authenticateTokenFail(errors));
        reject(errors);
        delete authenticateTokenPromiseInprogress[uniqueKey];
      });
  });
  authenticateTokenPromiseInprogress[uniqueKey] = authenticateTokenPromise;
  return authenticateTokenPromise;
};

export default authenticateTokenSource;
