import { dateFormats } from "utils/dateUtils";
import moment from 'moment';

export const normalizeLeg = (leg) => {
  return {
    legId: leg.legId || '',
    arrivalAirportId: leg.arrivalAirportId || '',
    departureAirportId: leg.departureAirportId || '',
    departureAirportTimeLocal: leg.departureAirportTimeLocal || '',
    departureAirportTimeUTC: leg.departureAirportTimeUTC || '',
    arrivalAirportTimeLocal: leg.arrivalAirportTimeLocal || '',
    arrivalAirportTimeUTC: leg.arrivalAirportTimeUTC || '',
    duration: leg.defaultDurationMinutes || moment.duration(moment(leg.arrivalAirportTimeUTC)
      .diff(moment(leg.departureAirportTimeUTC))).asMinutes(),
    aircraftTypeName: leg.aircraftTypeName || '',
    requestedAircraftTypeName: leg.requestedAircraftTypeName || '',
    aircraftTailNumber: leg.aircraftTailNumber || null,
    aircraftId: leg.aircraftTypeId || null,
    passengers: leg.numberOfPassenger || '',
    legStatus: leg.legStatus || '',
    version: leg.version || 0,
    currencyCode: leg.currencyCode || '',
    operatingCompanyName: leg.operatingCompanyName || '',
  };
}

const getExpiryDate = (expiryDate, legsList) => {
  const _b = expiryDate.split(" ");
  const newExpiryDate = moment(`${moment(_b[0], "DD-MM-YYYY").format('YYYY-MM-DD')}T${_b[1]}`);
  const expiryDateViaDepartureDate = moment(legsList.length &&
    legsList[0].departureAirportTimeUTC.split('T')[0]);
  if (newExpiryDate.isBefore(expiryDateViaDepartureDate)) {
    return newExpiryDate;
  }
  return expiryDateViaDepartureDate;
}

export const normalizeMyQuotes = (itineraries) => {
  const normalizedItineraries = [];

  itineraries.forEach((itinerary) => {
    const { legs } = itinerary;
    const legsList = [];

    if (legs && legs.length) {
      legs.forEach((leg) => {
        legsList.push(normalizeLeg(leg));
      });
    }
    const { orderLegId, orderStatus, currencyCode,
      price, requestedDate, expiryDate, quotedDate } = itinerary;
    normalizedItineraries.push({
      id: orderLegId || '',
      orderStatus: orderStatus || '',
      currency: currencyCode || '',
      amount: +price || 0,
      legs: legsList,
      requestedDate: requestedDate || quotedDate,
      expiryDate: expiryDate ?
        getExpiryDate(expiryDate, legsList) : '',
      quotedDate: quotedDate || '',
    });
  });
  return normalizedItineraries;
}
