import axios from 'axios';
import { TIMEOUT } from '../../constants/ApiConstants';

import phoneNumberUrl from '../../utils/libphonenumber';

const LoadLibPhoneNumber = {
  loadPhoneLib() {
    return new Promise((resolve, reject) => {
      if (window.intlTelInputUtils) {
        resolve();
      } else {
        axios
          .get(phoneNumberUrl)
          .timeout(TIMEOUT)
          .end((err, res) => {
            if (err) {
              reject();
            } else {
              if (res.text && !document.getElementById('intlTelInputUtils')) {
                const oBody = document.getElementsByTagName('body')[0];
                const oScript = document.createElement('script');
                oScript.id = 'intlTelInputUtils';
                oScript.text = res.text;
                oBody.appendChild(oScript);
              }
              resolve();
            }
          });
      }
    });
  },
};

export default LoadLibPhoneNumber;
