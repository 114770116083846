export const TIMEOUT_CODE = {
  status: 'ECONNABORTED',
  code: 408,
  time: 120000, // two minutes
};

export const SERVER_UNRESOLVED = {
  code: 1002,
};

export const EMAIL_EXISTS = {
  code: 1009,
};

export const INVALID_ACCOUNT = {
  code: 5,
};

export const ACCOUNT_NOT_FOUND_CODE = {
  code: 13,
};

export const UNAUTHORIZED = {
  code: 401,
};

export const BAD_REQUEST = {
  code: 400,
};

export const INTERNAL_SERVER_ERROR = {
  code: 500,
};

export const LEG_IN_PAST_CODE = {
  code: 11,
};

export const PERMISSION_DENIED_CODE = {
  code: 37,
};

export const UPDATE_FIRST_CODE = {
  code: 38,
};

export const MEMBERSHIP_EXPIRED_CODE = {
  code: 32,
};

export const PHONE_NUMBER_EXISTS = {
  code: 3,
};

export const USER_EXISTS = {
  code: 2,
};

export const GENERIC_EXCEPTION_CODE = {
  code: 39,
};

export const INVALID_FEEDBACK_CODE = {
  code: 40,
};

export const USER_UNAUTHENTICATED_CODE = {
  code: 17,
};

export const LOGIN_FAILED_CODE = {
  code: 7,
};

export const PAYMENT_NOT_DEFINED_CODE = {
  code: -1,
};

export const INVALID_DETAILS_CODE = {
  code: 34,
};

export const INVALID_CARD_TYPE_CODE = {
  code: 1,
};
export const FEEDBACK_EXIST = {
  code: 41,
};
export const MULTI_LEG_OFFER_QOUTING_NA = {
  code: 56,
}
export const ALREADY_LINKED_WITH_COMPANY = {
  code: 54,
};

export const INVOICES_NOT_FOUND = {
  code: 60,
}
export const RECORD_NOT_FOUND = {
  code: 61,
}
export const PHONE_NUMBER_NOT_FOUND = {
  code: 62,
}
export const USER_NOT_FOUND = {
  code: 63,
}
export const DEVICE_ID_NULL = {
  code: 64,
}
export const PHONE_NUMBER_NULL = {
  code: 65,
}
export const USERNAME_NULL = {
  code: 66,
}
export const RECORD_NOT_UPDATE = {
  code: 67,
}
export const ACCOUNT_BLOCKED = {
  code: 68,
}
export const INVALID_SIGNATURE = {
  code: 69,
}
export const TIME_MISMATCH = {
  code: 70,
}
export const MISSING_PARAMETER = {
  code: 71,
}
export const USER_ALREADY_REGISTERED = {
  code: 72,
}
export const INVALID_SOURCE = {
  code: 73,
}
export const  INVALID_TIME = {
  code: 74, 
}
export const CANNOT_ACCESS_STATEMENTS_CODE = {
  code: [57, 59],
};
