import * as types from '../actionTypes';
import { getFromLocal, saveToLocal, existInLocal } from '../../utils/cache';

export const initialState = {
  user: null,
  isLoggingIn: false,
  isLoggedIn: false,
  isLoggingOut: false,
  isForgotEmailSent: false,
  isError: false,
  changePasswordRequired: false,
  error: '',
  forgotPasswordErrors: '',
  isPasswordChangeLoading: false,
  isDirectFromProfile: false,
  userNotVerified: false,
  isAccountDataFetched: false,
  isAccountDataFetchedLoading: false,
  isAccountDataFail: false,
  isLoading: false,
  addressSaved: false,
  authenticatedUser: {},
  isUserVerified: false,
  isVerifyUserLoading: false,
  isVerifyUserError: false,
  verifyUserError: '',
  billingAddress: {},
  // Update Profile
  isProfileUpdateLoading: false,
  isProfileUpdated: false,
  isProfileUpdateError: false,
  profileUpdateErrorCode: null,
  profileUpdateError: '',
  passportUpdateMessage: null,
  disablePushNotificationCities: false,
  isAccountDataFetchedError: false,
  accountDataError: '',
  userHasLoggedOut: false,
  isLogOutFailed: false,
};

export default function launchReducer(state = initialState, action) {
  let stateObj = state;
  let isDefault = false;
  switch (action.type) {
    case types.LOGIN_INIT: {
      stateObj = Object.assign({}, state, {
        user: null,
        isLoggingIn: true,
        isLoggedIn: false,
        isError: false,
        isForgotEmailSent: false,
        userNotVerified: false,
        error: '',
      });
      break;
    }
    case types.LOGIN_SUCCESS: {
      stateObj = Object.assign({}, state, {
        user: { ...action.data },
        isLoggingIn: false,
        isLoggedIn: true,
        isError: false,
        userNotVerified: false,
        error: '',
      });
      break;
    }
    case types.LOGIN_NOT_VERIFIED_SUCCESS: {
      stateObj = Object.assign({}, state, {
        user: { ...action.data },
        isLoggingIn: false,
        isLoggedIn: false,
        isError: false,
        error: '',
        userNotVerified: true,
        email: action.data.user_email,
      });
      break;
    }
    case types.LOGIN_FAILURE: {
      stateObj = Object.assign({}, state, {
        isLoggingIn: false,
        isLoggedIn: false,
        isError: true,
        error: action.data.error,
      });
      break;
    }
    case types.GET_ACCOUNT_STATUS_INIT: {
      stateObj = Object.assign({}, state, {
        isAccountDataFetched: false,
        isAccountDataFetchedLoading: true,
        isAccountDataFetchedError: false,
        isAccountDataFail: false,
        accountDataError: '',
      });
      break;
    }
    case types.GET_ACCOUNT_STATUS_SUCCESS: {
      stateObj = Object.assign({}, state, {
        isAccountDataFetched: true,
        isAccountDataFetchedLoading: false,
        user: { ...state.user, ...action.data },
        isAccountDataFetchedError: false,
        isAccountDataFail: false,
        accountDataError: '',
      });
      break;
    }
    case types.GET_ACCOUNT_STATUS_FAILURE: {
      stateObj = Object.assign({}, state, {
        isAccountDataFetched: false,
        isAccountDataFetchedLoading: false,
        isAccountDataFetchedError: true,
        isAccountDataFail: true,
        accountDataError: action.data,
      });
      break;
    }
    case types.LOGOUT_INIT: {
      stateObj = Object.assign({}, state, {
        isLoggingOut: true,
        isLoggedIn: true,
        userHasLoggedOut: false,
        isLogOutFailed: false,
      });
      break;
    }
    case types.LOGOUT_SUCCESS: {
      stateObj = Object.assign({}, state, {
        user: null,
        isLoggingOut: false,
        isLoggedIn: false,
        userHasLoggedOut: true,
        isLogOutFailed: false,
      });
      break;
    }
    case types.CLEAR_NOT_VERIFIED_USER: {
      let { user } = state || {};
      user = user || {};
      user.userNotVerified = false;
      stateObj = Object.assign({}, state, {
        user,
      });
      if (existInLocal('user')) {
        let userObjectInStore = getFromLocal('user', true, true);
        userObjectInStore.user = user;
        saveToLocal(userObjectInStore, 'user', true, true);
      };
      break;
    }
    case types.LOGOUT_FAIL: {
      stateObj = Object.assign({}, state, {
        isLoggingOut: false,
        isLoggedIn: true,
        userHasLoggedOut: false,
        isLogOutFailed: true,
      });
      break;
    }
    case types.FORGOT_INIT: {
      stateObj = Object.assign({}, state, {
        isForgotEmailSent: false,
        isSendingForgotEmail: true,
      });
      break;
    }
    case types.FORGOT_SUCCESS: {
      stateObj = Object.assign({}, state, {
        user: { ...action.data },
        isForgotEmailSent: true,
        isSendingForgotEmail: false,
      });
      break;
    }
    case types.FORGOT_FAILURE: {
      stateObj = Object.assign({}, state, {
        isForgotEmailSent: false,
        isSendingForgotEmail: false,
        forgotPasswordErrors: action.data && action.data.error,
      });
      break;
    }
    case types.CLEAR_FORGOT_PASSWORD_RESENT_EMAIL_MESSAGE: {
      return Object.assign({}, state, {
        resentForgotPassword: false,
      });
    }
    case types.CHANGE_PASSWORD_INIT: {
      stateObj = Object.assign({}, state, {
        isPasswordChangeLoading: true,
        isDirectFromProfile: false,
        changePasswordErrors: false,
        changePasswordSuccess: false,
        changePasswordErrorText: '',
      });
      break;
    }
    case types.CHANGE_PASSWORD_SUCCESS: {
      stateObj = Object.assign({}, state, {
        changePasswordRequired: false,
        changePasswordErrors: false,
        changePasswordSuccess: true,
        isPasswordChangeLoading: false,
        isDirectFromProfile: action.data.isProfile,
        changePasswordErrorText: '',
      });
      break;
    }
    case types.CHANGE_PASSWORD_REQUIRED: {
      stateObj = Object.assign({}, state, {
        changePasswordRequired: true,
        isPasswordChangeLoading: false,
      });
      break;
    }
    case types.CHANGE_PASSWORD_FAILURE: {
      stateObj = Object.assign({}, state, {
        changePasswordErrors: true,
        changePasswordErrorText: action.data.error,
        isPasswordChangeLoading: false,
        isDirectFromProfile: action.data.isProfile,
      });
      break;
    }
    case types.CLEAR_FORGOT_ERROR_MESSAGE: {
      stateObj = Object.assign({}, state, {
        forgotPasswordErrors: null,
        isPasswordChangeLoading: false,
      });
      break;
    }
    case types.RESEND_FORGOT_EMAIL: {
      stateObj = Object.assign({}, state, {
        resentForgotPassword: true,
      });
      break;
    }
    case types.VERIFY_USER_INIT: {
      return Object.assign({}, state, {
        authenticatedUser: {},
        isUserVerified: false,
        isVerifyUserLoading: true,
        isVerifyUserError: false,
        verifyUserError: '',
      });
    }
    case types.VERIFY_USER_SUCCESS: {
      let { user } = state || {};
      user = user || {};
      user.userNotVerified = false;
      return Object.assign({}, state, {
        authenticatedUser: action.data.authenticatedUser,
        isUserVerified: true,
        isVerifyUserLoading: false,
        isVerifyUserError: false,
        user,
        verifyUserError: '',
      });
    }
    case types.VERIFY_USER_FAIL: {
      return Object.assign({}, state, {
        authenticatedUser: {},
        isUserVerified: false,
        isVerifyUserLoading: false,
        isVerifyUserError: true,
        verifyUserError: action.data.error,
      });
    }
    case types.UPDATE_USER_CREATE_COMPANY: {
      stateObj = Object.assign({}, state, {
        user: action.data,
      });
      break;
    }
    case types.UPDATE_USER_REDUCER: {
      stateObj = Object.assign({}, state, action.data);
      break;
    }
    case types.UPDATE_USER_PROFILE_INIT: {
      return Object.assign({}, state, {
        isProfileUpdateLoading: true,
        isProfileUpdated: false,
        isProfileUpdateError: false,
        profileUpdateErrorCode: null,
        profileUpdateError: '',
        passportUpdateMessage: null,
      });
    }
    case types.UPDATE_USER_PROFILE_FAILURE: {
      return Object.assign({}, state, {
        isProfileUpdateLoading: false,
        isProfileUpdated: false,
        isProfileUpdateError: true,
        profileUpdateErrorCode: action.data.errorCode || null,
        profileUpdateError: action.data.error,
        passportUpdateMessage: null,
      });
    }
    case types.UPDATE_USER_PROFILE_SUCCESS: {
      const passport = {
        passports: action.data.response.passports,
      };
      const userObject = Object.assign({}, state.user, action.data.response.user, passport, {
        personPhoneNumber: action.data.response.phoneNumbers,
        phoneNumber: action.data.response.phoneNumbers[0].phoneNumber,
      });
      return Object.assign({}, state, {
        user: userObject,
        isProfileUpdateLoading: false,
        isProfileUpdated: true,
        isProfileUpdateError: false,
        profileUpdateErrorCode: null,
        profileUpdateError: '',
        passportUpdateMessage: action.data.message,
      });
    }
    case types.SAVE_BILLING_ADDRESS_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        billingAddress: {},
        addressSaved: false,
        error: '',
      });
    }
    case types.SAVE_BILLING_ADDRESS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        billingAddress: action.data,
        addressSaved: true,
      });
    }
    case types.SAVE_BILLING_ADDRESS_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        addressSaved: false,
        error: action.data,
      });
    }
    case types.GET_BILLING_ADDRESS_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        error: '',
        billingAddress: {},
      });
    }
    case types.GET_BILLING_ADDRESS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        billingAddress: action.data,
      });
    }
    case types.GET_BILLING_ADDRESS_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        error: action.data,
      })
    }

    case types.UPDATE_PUSH_NOTIFICATION_DISABLE_CITY_CHECK: {
      return Object.assign({}, state, {
        disablePushNotificationCities: action.data,
      });
    }
    default:
      isDefault = true;
      stateObj = state;
  }
  if (!isDefault) {
    // localStorage.setItem("userReducer", JSON.stringify(stateObj));
  }
  return stateObj;
}
