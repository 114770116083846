import moment from 'moment';

// export const getLastStatementYear = (accountStatements = []) => (
//   accountStatements.length ?
//   moment(findLatest(accountStatements.map(statement =>
//     statement.createdDate))) : ''
// );

// export const mergeMoreInvoices = (data = {}) => {
//   const { res = {}, currentAccount = '' } = data;
//   const currentAccountId = currentAccount ||
//     (res.billed && res.billed[0] ? res.billed[0].accountId : '');
//   const currentFlight = this.billedFlights.find(({ aircraftTypeId, accountId }) =>
//     aircraftTypeId === data.aircraftTypeId &&
//     accountId === currentAccountId);
//   const invoices = res.billed && res.billed[0] ? res.billed[0].invoice : [];
//   currentFlight.noMoreInvoices =
//     !invoices.length || invoices.length < INVOICES_PER_PAGE;
//   const availableInvoices = currentFlight.invoice;
//   const newInvoices = availableInvoices.concat(invoices);
//   currentFlight.invoice = newInvoices;
// }

export const currentDate = moment().format('DD MMM YYYY');

export const mergeInvoices = (currentFlights, { accountId, aircraftTypeId, billedFlights }) => {
  const clonedFlights = [ ...currentFlights ];
  const currentFlight = clonedFlights.find(flight => (
    flight.accountId === accountId && flight.aircraftTypeId === aircraftTypeId
  ));
  if (!currentFlight) return currentFlights;
  currentFlight.invoice = [
    ...currentFlight.invoice,
    ...billedFlights[0].invoice,
  ];
  currentFlight.noMoreInvoices = billedFlights[0].noMoreInvoices;
  return currentFlights;
}

export const isActiveContract = year => {
  const { contractPeriodStart, contractPeriodEnd } = year;
  const periodStart = moment(contractPeriodStart);
  const periodEnd = moment(contractPeriodEnd);
  return moment(currentDate).isBetween(periodStart, periodEnd, 'date', '[]');
};