import React, { Component } from 'react';
import CateringListActionItem from './CateringListActionItem';
import { CATERING_SCREENS } from '../../../constants/CateringConstants';
import { zeroFill } from '../../../utils/cateringUtils';
import CateringFooter from './CateringFooter';

class SupplierMenuList extends Component {

  constructor(props) {
    super(props);
    const collapsibleStates = {};
    if (props.selectedService) {
      collapsibleStates[props.selectedService] = true;
    }
    this.state = {
      collapsibleStates,
    };
  }

  onConfirmSelection = () => {
    this.props.onSelectionConfirmed();
    this.props.handleScreenChange(null);
  }

  getQuantityForMenuItem = (item) => {
    let sum = 0;
    if (item.quantities) {
      Object.keys(item.quantities).forEach((passenger) => {
        const count = item.quantities[passenger].count;
        if (count) sum += count;
      });
    }
    return sum;
  }

  getQuantityForService = (serviceCategories) => {
    let sum = 0;
    Object.keys(serviceCategories).forEach((category) => {
      const items = serviceCategories[category];
      items.forEach((item) => {
        if (item.quantities) {
          Object.keys(item.quantities).forEach((passengerId) => {
            const count = item.quantities[passengerId].count;
            if (count) sum += count;
          });
        }
      });
    });
    return sum;
  }

  handleMenuClicked = (item) => {
    this.props.handleScreenChange(CATERING_SCREENS.PASSENGER_QUANTITY_LIST);
    if (this.props.onMenuItemSelected) this.props.onMenuItemSelected(item);
  }

  handleServiceToggle = (service) => {
    const { collapsibleStates } = this.state;
    collapsibleStates[service] = !collapsibleStates[service];
    this.props.onServiceSelected(service);
    this.setState({
      collapsibleStates,
    });
  }

  renderCategories = (categories) => Object.keys(categories).map((category) =>
    (

      <div className="vistajet_accordion_menu_detail">
        <h5 className="vistajet_accordion_menu_category">{category}</h5>
        {this.renderMenuItems(categories[category])}
      </div>

      //   <div>
      //   <div className="catering-category separator-bottom">{category}</div>
      //   <ul className="catering-menu">
      //     {this.renderMenuItems(categories[category])}
      //   </ul>
      // </div>
    ));

  renderMenuItems = (items) => items.map((item) => {
    let count = this.getQuantityForMenuItem(item);
    // if (count) actionLabel = <span>{zeroFill(count)}</span>;
    count = (count) ? zeroFill(count) : "";
    return (
      <CateringListActionItem
        key={item.id}
        data={item}
        label={item.title}
        count={count}
        actionLabel={"ADD"}
        onActionClicked={() => this.handleMenuClicked(item)}
        description={item.description}
      />
    );
  });

  renderServices = () => {
    const { proposal } = this.props;
    const { collapsibleStates } = this.state;
    return Object.keys(proposal.services).map((service) => {
      const isOpen = !!collapsibleStates[service];
      const serviceQuantity = this.getQuantityForService(proposal.services[service]);
      return (
        <li>
          <a className="uk-accordion-title vistajet_accordion_menu_title" href="#">
            {service}
            {(!!serviceQuantity && !isOpen) && <span className="vistajet_item_quantity">x{zeroFill(serviceQuantity)}</span>}
          </a>
          <div className="uk-accordion-content vistajet_accordion_menu_content">
            {this.renderCategories(proposal.services[service])}
          </div>
        </li>
        // <div>
        //   <div
        //     className="supplier-service-value"
        //     tabIndex={service}
        //     onClick={() => this.handleServiceToggle(service)}
        //   >
        //     {service}
        //     <div className="action-item">
        //       {(!!serviceQuantity && !isOpen) &&
        //         <span>{zeroFill(serviceQuantity)}</span>
        //       }
        //       {isOpen ? 'up' : 'down'}
        //     </div>
        //   </div>
        //   <div style={{ display }}>
        //     {this.renderCategories(proposal.services[service])}
        //   </div>
        // </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div className="vistajet_subheading_container">
          <p className="vistajet_modal_subheading_2 uk-text-center">Please select your desired items</p>
        </div>
        <div className="vistajet_catering_menu_content">
          <ul data-uk-accordion className="vistajet_accordion_menu">
            {this.renderServices()}
          </ul>
        </div>
        <CateringFooter onClick={this.onConfirmSelection}/>
      </div>
      // <div className="catering-content-container">
      //   <div className="catering-content-heading">
      //     {this.props.selectedSupplier}
      //   </div>
      //   <div className="catering-menu-prompt">
      //     Please select an item
      //   </div>
      //   {this.renderServices()}
      //   {/* <div className="catering-popup-footer">
      //     <a className="vistajet_button_default uk-margin-remove" onClick={this.onConfirmSelection} uk-toggle>Confirm Selection</a>
      //   </div> */}
      // </div>
    );
  }
}

export default SupplierMenuList;
