import * as type from '../../actionTypes';
import { getFlightId } from '../../../utils/itinerariesUtils';

const initialState = {
  isLoadingFbo: 0,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.ITINERARY_DETAILS_SUCCESS: {
      const {
        legs,
      } = action.data;
      const fboData = {};
      legs.forEach(leg => {
        fboData[getFlightId(leg)] = {};
        fboData[getFlightId(leg)].isLoadingFbo = false;
      })
      return Object.assign({}, state, {
        fbo: fboData,
      });
    }
    case type.FETCH_FBO_INIT: {
      state.fbo[action.flightLegId].isLoadingFbo = true;
      state.fbo[action.flightLegId].fboFetched = false;
      return Object.assign({}, state, {
        isLoadingFbo: state.isLoadingFbo + 1,
        fbo: state.fbo,
      });
    }

    case type.FETCH_FBO_SUCCESS: {
      state.fbo[action.flightLegId].fbo = action.data;
      state.fbo[action.flightLegId].fboFetched = true;
      state.fbo[action.flightLegId].isLoadingFbo = false;
      return Object.assign({}, state, {
        isLoadingFbo: state.isLoadingFbo - 1,
        fbo: state.fbo,
      });
    }

    case type.FETCH_FBO_FAIL: {
      state.fbo[action.flightLegId].isLoadingFbo = false;
      state.fbo[action.flightLegId].fboFetched = true;
      return Object.assign({}, state, {
        isLoadingFbo: state.isLoadingFbo - 1,
        fbo: state.fbo,
      });
    }
    default:
      return state;
  }
}
