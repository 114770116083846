import React, { Component } from 'react';

function isValid(value) {
  return Boolean(value || value === 0);
}

class TextField extends Component {

  static defaultProps = {
    className: '',
    fullWidth: true,
    underlineShow: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasValue: isValid(this.props.value) || isValid(this.props.defaultValue),
      isFocused: false,
      disabled: this.props.disabled || false,
    };
  }

  handleChange = (event, value) => {
    if (this.props.onChange) this.props.onChange(event, value);
  };

  handleFocus = (e) => {
    if (this.props.onFocus) this.props.onFocus(e);
  };

  handleBlur = (e) => {
    if (this.props.onBlur) this.props.onBlur(e);
  };

  render() {
    const {
      id,
      placeholder,
      type,
      className,
      value,
      disabled,
    } = this.props;

    return (
      <input
        id={id}
        placeholder={placeholder}
        type={type}
        value={value}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        className={className}
        disabled={disabled}
      />
    );
  }
}

export default TextField;
