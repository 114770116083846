import * as types from '../actionTypes';
import resendRegisterCode from '../sources/ResendRegisterCodeSource';

export const resendRegisterCodeInit = () => ({
  type: types.FETCH_REGISTER_CODE_INIT,
});

export const resendRegisterCodeSuccess = data => ({
  type: types.FETCH_REGISTER_CODE_SUCCESS,
  data,
});

export const resendRegisterCodeFail = data => ({
  type: types.FETCH_REGISTER_CODE_FAIL,
  data,
});

export const resendRegisterCodeAction = obj => resendRegisterCode(obj);
