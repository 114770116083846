export function normalizeAirportSearchTerm(city, country) {
  return `${city}, ${country}`;
}

export function normalizeAirports(airports = []) {
  const airportList = airports;
  const result = {};
  for (let i = 0; i < airportList.length; i += 1) {
    result[airportList[i].id] = airportList[i];
    result[airportList[i].id].cityAndCountry = normalizeAirportSearchTerm(
      airportList[i].city !== undefined
        ? airportList[i].city
        : airportList[i].country,
      airportList[i].country
    );
  }
  return result;
}

const sortByWeight = (a1, a2) => ((a2.weight || 0) - (a1.weight || 0))

export function normalizeFlyxoAirports(airportsData) {
  const { ap: airports, ma: metropolitanAreas } = airportsData

  const airportsByAreas = airports.sort(sortByWeight).reduce((result, airport) => {
    if (!result.rest) result.rest = []

    const parentMA = metropolitanAreas.find(({ metropolitan_area_id }) =>
      metropolitan_area_id === airport.metropolitan_area_id
    )
    if (parentMA) {
      if (!result[airport.metropolitan_area_id]) {
        result[airport.metropolitan_area_id] = [];
      }

      result[airport.metropolitan_area_id].push({
        ...airport,
        ma_name: parentMA.name,
        type: "airport-nested"
      });

      return result;
    }

    result.rest.push({ ...airport, type: "airport" });

    return result;
  }, {})

  const flattenDestionations = metropolitanAreas.reduce((acc, ma) => {
    if (airportsByAreas[ma.metropolitan_area_id]) {
      acc.push({ ...ma, type: "metropolitan-area-with-airports" });
      acc = [...acc, ...airportsByAreas[ma.metropolitan_area_id]];
    } else {
      acc.push({ ...ma, type: "metropolitan-area" });
    }

    return acc;
  }, []);



  return airportsByAreas.rest
    ? [...flattenDestionations, ...airportsByAreas.rest]
    : flattenDestionations;
}
