import flightCancelSource from '../sources/FlightCancelSource';
import {
  FLIGHT_CANCEL_INIT,
  FLIGHT_CANCEL_SUCCESS,
  FLIGHT_CANCEL_FAILURE,
  FLIGHT_CANCEL_AFTER_SUCCESS,
} from '../actionTypes';

export const flightCancelInit = () => ({
  type: FLIGHT_CANCEL_INIT,
});

export const flightCancelSuccess = (data) => ({
  type: FLIGHT_CANCEL_SUCCESS,
  data,
});

export const flightCancelFail = (data) => ({
  type: FLIGHT_CANCEL_FAILURE,
  data,
});

export const flightCancelAfterSuccess = () => ({
  type: FLIGHT_CANCEL_AFTER_SUCCESS,
});

export const flightCancel = (token, accountId, leg, user, orderId) => flightCancelSource(token, accountId, leg, user, orderId);
