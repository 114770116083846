import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import Modal from '../shared/Modal';
import PassengerPopupContainer from '../../containers/passengers/passengerPopupContainer';
import { getPassengerId, generateFrequentPaxList } from '../../utils/passengerUtils';
import Source from '../../store/sources/ItineraryPassengerSource';
import SettingsSource from '../../store/sources/UserSettingsSource';
import { clone } from '../../utils/Helpers';
import { getFromLocal, saveToLocal } from '../../utils/cache';
import { garanteeArray } from '../../utils/sourceUtils';
import { convertToTitleCase } from '../../utils/stringUtils';
import {
  mergePassportbyPax,
} from '../../utils/passportUtils';
import { isPastLeg, isLegCancelled } from "../../utils/itinerariesUtils";
import * as actions from '../../store/actions';
import store from '../../store/store';
import { getAircraftByField } from "../../utils/aircraftUtils";
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants';

class PassengerCard extends Component {
  constructor(props) {
    super(props);
    this.popupRefs = {};
    this.state = {
      dirtyChange: false,
      isError: false,
      disableConfirmBtn: false,
      isEditable: true,
    };
  }

  handleconfirmNavigation = () => {
    dirtystateSingleTon.setDirty(SPAConstants.ItineraryPassengers, false);
    this.setState({
      dirtyChange: false
    });
    this.paxPopup.hide();
  }
  getTotalPassengerCount = (passengers) => {
    let classNameTobe = '';
    if (passengers && passengers.length) {
      classNameTobe = 'uk-margin-bottom';
    }
    const announcedPax = this.props.announcedPax;
    let passengerCount = null;
    if (announcedPax && announcedPax.remainingPax > 0) {
      passengerCount = (
        <div>
          <h6 className={`vistajet_subheading ${classNameTobe} vistajet_passenger_card_heading`}>
            Please provide details for {String(announcedPax.remainingPax).padStart(1, 0)} {announcedPax.paxLabel}
        </h6>
        </div>
      );
    }
    return passengerCount;
  }
  handlePassengerConfirm = (addedPax, deletedPax, paxCount) => {
    const params = (this.props.activeLeg.flightLegExtIdentifier && !this.props.activeLeg.legId) ? {
      uniqueIdentifier: this.props.activeLeg.flightLegExtIdentifier,
      key: 'flightLegExtIdentifier',
    } : {
        uniqueIdentifier: this.props.activeLeg.legId,
        key: 'flightLegId',
      };
    const passengerId = [];
    deletedPax.forEach(deletedPassenger => {
      const identifier = getPassengerId(deletedPassenger);
      if (identifier) {
        passengerId.push(getPassengerId(deletedPassenger));
      }
    });
    if (!this.props.isLegCard) {
      Source.managePassengers(params, addedPax, passengerId)
        .then(() => {
          const settingsInfo = { frequentPax: generateFrequentPaxList(addedPax) };
          SettingsSource.writeUserSettings(settingsInfo).then(() => {
            // shallow copy of flightPassengers array
            const paxToCache = addedPax.map((passengerParam) => {
              const passenger = clone(passengerParam);
              passenger.leadPassenger = false;
              (passenger.passports || []).forEach((passport) => {
                passport.isLegPassport = false; // eslint-disable-line no-param-reassign
                passport.isUpdated = false; // eslint-disable-line no-param-reassign
              });
              return passenger;
            });
            let freqPax = getFromLocal('frequentPax', true);
            freqPax = garanteeArray(freqPax);
            const mergePax = mergePassportbyPax(freqPax, paxToCache);
            saveToLocal('frequentPax', mergePax, true);
            this.handleconfirmNavigation();
            const legId = this.props.activeLeg.legId || this.props.activeLeg.flightLegExtIdentifier;
            store.dispatch(actions.updatePassengers({
              legId,
              passengers: addedPax.map(pax => {
                delete pax.isSoftlyAdded; // eslint-disable-line no-param-reassign
                delete pax.isAdded; // eslint-disable-line no-param-reassign
                return pax;
              }),
            }));
          });
        })
        .catch(() => {
          this.setState({ isError: true });
        });
    } else {
      this.handleconfirmNavigation();
      const legId = this.props.activeLeg.legId || this.props.activeLeg.flightLegExtIdentifier;
      store.dispatch(actions.updatePassengers({
        legId,
        passengers: addedPax.map(pax => {
          delete pax.isSoftlyAdded; // eslint-disable-line no-param-reassign
          delete pax.isAdded; // eslint-disable-line no-param-reassign
          return pax;
        }),
      }));
      this.props.gatherPassengerInfo({ params, addedPax, passengerId });
      this.props.handleStepperChange(paxCount);
    }
  }

  renderPassenger = (passenger, index) => {
    const legPassport = (((passenger || {}).formattedPaxObj || {}).legPassport || {});
    const { isExpired, isPassExpiredBeforeDept } = legPassport;
    const expireLbl = (isExpired ? ' (Passport Expired)' : '');
    const passExpiredBeforeDeptLbl = (!isExpired && isPassExpiredBeforeDept) ?
      ' (Selected passport will be expired on departure date)' :
      expireLbl;
    const passengerName = convertToTitleCase(`${passenger.firstName} ${passenger.lastName}`);
    const status = passenger.isApproved ? 'Confirmed' : 'On Request';
    return (
      <div key={index} class="uk-width-1-1 uk-padding-remove vistajet_passenger_list_child_list">
        <span class="uk-align-left vistajet_passenger_list_name uk-margin-small">
          {passengerName}
          {passenger.leadPassenger &&
            <img className="uk-margin-small-left vistajet_leadPax_icon_resize"
              src="/legacy/static/img/icons/vistajet_icon_leadPax.svg" alt="" />}
          {passExpiredBeforeDeptLbl}
        </span>
        <span class="vistajet_passenger_status passenger_status_color">
          {status}
        </span>
      </div>
    );
  }

  handlePassengerClose = () => {
    dirtystateSingleTon.setDirty(SPAConstants.ItineraryPassengers, false);
    this.setState({
      dirtyChange: false,
    });
  }

  setStateDirty = () => {
    dirtystateSingleTon.setDirty(SPAConstants.ItineraryPassengers, true);
    this.setState({
      dirtyChange: true,
    });
  }

  openPassengerPopup = () => {
    if (this.paxPopup) {
      this.paxPopup.show();
    }
  }

  closeMainPopup = () => {
    this.paxPopup.hide();
  }

  getMaxPassengers = (activeLeg, aircraftsList) => {
    let value  = '';
    if (activeLeg.aircraftTypeName) {
      value =  getAircraftByField(activeLeg.aircraftTypeName, aircraftsList, 'code');
    }
    if (!Object.keys(value).length) {
      value =  getAircraftByField(activeLeg.aircraftTypeId, aircraftsList);
    }
    return value.maxNumberOfPassengers;
  }
  render() {
    const {
      isError,
      dirtyChange,
    } = this.state;

    const {
      passengers,
      isLoading,
      isLoadingPassenger,
      isEditable,
      showToastMesg,
      activeLeg,
      isLegCard,
      maxValue,
      aircraftsList,
    } = this.props;
    const disableDialog = activeLeg ? isPastLeg(activeLeg) : true;
    const isCancelLeg = activeLeg ? isLegCancelled(activeLeg.legStatus) : true;
    const numOfPax = (activeLeg && aircraftsList) ? this.getMaxPassengers(activeLeg, aircraftsList): 14;
    return (
      <div>
        {!isLegCard ?
          <div className="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove">
            <div className="vistajet_itinerary_card_header">
              <h5 className="uk-width-expand">Passengers</h5>
              {(!disableDialog && !isCancelLeg && isEditable) &&
                <a className="vistajet_button_default uk-margin-remove" onClick={this.openPassengerPopup}
                  disabled={isLoadingPassenger || isLoading} >View</a>}
            </div>
            <div className="vistajet_itinerary_card_body">
              {!isLoadingPassenger && !isLoading ? (
                <div>
                  {this.getTotalPassengerCount(passengers)}
                  {(passengers && passengers.length) ?
                    <div className="vistajet_passenger_list uk-margin-remove uk-grid">
                      {passengers.map(this.renderPassenger)}
                    </div> : null
                  }
                  <Modal
                    ref={(paxPopup) =>
                      (this.paxPopup = paxPopup)
                    }
                    showCloseIcon={false}
                    classNames={{
                      overlay: 'vistajet_modal vistajet_modal_large',
                      modal: 'vistajet_react_modal vistajet_modal_body',
                    }}
                  >
                    <PassengerPopupContainer
                      closePopup={this.handlePassengerClose}
                      dirtyChange={this.setStateDirty}
                      showToastMesg={showToastMesg}
                      passengers={passengers}
                      paxCount={(activeLeg || {}).numberOfPassenger}
                      maxNumberOfPassengers={numOfPax}
                      showCounter={false}
                      handlePassengerConfirm={this.handlePassengerConfirm}
                      isError={isError}
                      setError={this.setError}
                      disableConfirmBtn={!dirtyChange}
                      onItineraryDetail
                      paxPopup={this.closeMainPopup}
                      showConfirmationPopup={this.state.dirtyChange}
                    />
                  </Modal>
                </div>
              ) : (
                  <div className="vistajet_spinner">
                    <SpinnerLoader />
                  </div>
                )
              }
            </div>
          </div>
          :
          <Modal
            ref={(paxPopup) =>
              (this.paxPopup = paxPopup)
            }
            showCloseIcon={false}
            classNames={{
              overlay: 'vistajet_modal vistajet_modal_large',
              modal: 'vistajet_react_modal vistajet_modal_body',
            }}
          >
            <PassengerPopupContainer
              closePopup={this.handlePassengerClose}
              dirtyChange={this.setStateDirty}
              showToastMesg={showToastMesg}
              passengers={passengers}
              paxCount={this.props.paxNumber}
              maxNumberOfPassengers={maxValue}
              showCounter={false}
              handlePassengerConfirm={this.handlePassengerConfirm}
              isError={isError}
              setError={this.setError}
              disableConfirmBtn={!dirtyChange}
              onItineraryDetail
              paxPopup={this.closeMainPopup}
              showConfirmationPopup={this.state.dirtyChange}
              showStepper={true}
            />
          </Modal>
        }
      </div>
    );
  }
}

export default PassengerCard;
