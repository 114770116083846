import React from 'react';
import PropTypes from 'prop-types';

const getStyles = (props) => {

  const underline = Object.assign({
    borderStyle: 'none none solid',
    borderBottomWidth: '1px',
    bottom: '0px',
    boxSizing: 'content-box',
    margin: '0px',
    position: 'absolute',
    width: '100%',
  }, props.underlineStyle);

  const underlineFocus = Object.assign({}, underline, {
    borderBottomWidth: '2px',
    transform: props.isFocused ? 'scaleX(1)' : 'scaleX(0)',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  }, props.underlineFocusStyle);

  return {
    underline,
    underlineFocus,
  };
};

const UnderlineField = (props) => {
  const styles = getStyles(props);
  return (
    <div>
      <hr style={styles.underline} />
      <hr style={styles.underlineFocus} />
    </div>
  );
};

UnderlineField.propTypes = {

  /**
   * Bool used to verify if it's disabled.
   */
  disabled: PropTypes.bool.isRequired, // eslint-disable-line

  /**
   * Bool used to verify if it has value.
   */
  hasValue: PropTypes.bool.isRequired, // eslint-disable-line

  /**
   * Bool used to verify if it's selected.
   */
  isFocused: PropTypes.bool.isRequired, // eslint-disable-line

  /**
   * Override the inline-styles of the underline WITH focus element.
   */
  underlineFocusStyle: PropTypes.object, // eslint-disable-line

  /**
   * Override the inline-styles of the underline element.
   */
  underlineStyle: PropTypes.object, // eslint-disable-line
};

export default UnderlineField;
