import fetchQuotesSource from '../sources/QuoteSource';
import {
  FETCH_QUOTES_INIT,
  FETCH_QUOTES_SUCCESS,
  FETCH_QUOTES_FAIL,
} from '../actionTypes';

export const fetchQuotesInit = (data) => ({
  type: FETCH_QUOTES_INIT,
  data,
});

export const fetchQuotesSuccess = (data) => ({
  type: FETCH_QUOTES_SUCCESS,
  data,
});

export const fetchQuotesFail = (data) => ({
  type: FETCH_QUOTES_FAIL,
  data,
});

export const fetchQuotes = (quoteType, pageNum) => fetchQuotesSource(quoteType, pageNum);
