export const isEmpty = obj => Object.keys(obj).length === 0;

export const isValidJSON = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
