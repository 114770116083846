import React from 'react';
import GoogleMapReact from 'google-map-react';

import { googleMapStyles } from 'components/MapStyle';
import { GOOGLE_MAP_API_KEY } from 'configs/constants';

const GoogleMap = props => <GoogleMapReact {...props} />;

GoogleMap.defaultProps = {
  bootstrapURLKeys: { key: GOOGLE_MAP_API_KEY, libraries: 'places', language: 'en' },
  defaultCenter: { lat: 48.0686419, lng: 74.320299 },
  defaultZoom: 12,
  // resetBoundsOnResize:true,
  options: {
    fullscreenControl: false,
    mapTypeId: 'roadmap',
    styles: googleMapStyles,
  },

};

export default GoogleMap;
