import React, { Component } from "react";
import PropTypes from "prop-types";
import TopUpAccount from './TopUpAccount';
import moment from 'moment';
import { convertToTitleCase } from "../../utils/stringUtils";
import SpinnerLoader from '../../../src/components/shared/spinnerLoader';
import { removeFromLocal, getFromLocal, existInLocal } from "../../utils/cache";
import { TOP_UP_STATUS } from "../../constants/localStorageKeys";
import MessagePopUp from '../../../src/components/shared/MessagePopUpNew';
import Modal from '../../../src/components/shared/Modal';
import { numberWithCommas } from "../../utils/parserUtils";
import { scrollFunctionForTable } from '../../utils/Helpers';

class TopUpHistory extends Component {

  static propTypes = {
    token: PropTypes.string,
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    openPopUpMessage: PropTypes.number,
    topUpIds: PropTypes.array,
    topUpContent: PropTypes.object,
    membershipAccount: PropTypes.object,
    GetTopUpHistory: PropTypes.func,
    TopUpReference: PropTypes.func,
    TopUpAction: PropTypes.func,
    CancelTopUp: PropTypes.func,
    SetOpenPopupFalse: PropTypes.func,
    bankName: PropTypes.string,
    currency: PropTypes.string,
    iban: PropTypes.string,
    referenceCode: PropTypes.string,
    swift: PropTypes.string,
    to: PropTypes.string,
    topUpAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topUpId: PropTypes.number,
    topUpReferenceLoader: PropTypes.bool,
    user: PropTypes.object,
    topUpHistoryLoader: PropTypes.bool,
    topUpSubmittedLoader: PropTypes.bool,
    topUpCancelLoader: PropTypes.bool,
    disableAmountField: PropTypes.bool,
    topUpHistoryError: PropTypes.string,
    ClearTopUpError: PropTypes.func,
    topUpError: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      topUpIdState: 0,
      referenceCodeState:'',
      topUpAmountState: '',
      disableAmountState: false,
      modalWithData : {
        amount: '',
        referenceCode: '',
        iban: '',
        bankName: '',
        swift: '',
        to: '',
        topUpId: '',
        currency: '',
      }
    }
  }

  componentDidMount() {
    const { GetTopUpHistory, token, accountId, SetOpenPopupFalse } = this.props;
    GetTopUpHistory(token, accountId);
    if (existInLocal(TOP_UP_STATUS)) {
      SetOpenPopupFalse();
      window.UIkit.notification({message:`${getFromLocal(TOP_UP_STATUS)}`, timeout: 5000});
      removeFromLocal(TOP_UP_STATUS);
    }
  }
  componentWillReceiveProps(nextProps) {
    const { topUpIdState } = this.state;
    if ((this.props.topUpId !== nextProps.toUpId) && 
      (nextProps.topUpId !== topUpIdState)) {
      this.setState({
        topUpIdState: nextProps.topUpId,
        referenceCodeState: nextProps.referenceCode,
        topUpAmountState: nextProps.topUpAmount,
      })
    }
    if (nextProps.topUpHistoryError) {
      this.validationPopUpHistory.show(); 
    }
  }
  openTopUp = () => {
    this.setState({
      disableAmountState: false,
      topUpIdState: 0,
      referenceCodeState: '',
      topUpAmountState: '',
    }, () =>  this.TopUpModal.show())
  }

  formatDate = (date) => {
    return (moment(date).format('DD MMM YYYY'));
  }
  clearErrorMessage = () => {
    this.props.ClearTopUpError();
  }
  setStateWithModalData = (data) => {
    this.setState({
      disableAmountState: true,
      modalWithData : {
        amount: data.topUpAmount,
        referenceCode: data.referenceCode,
        iban: data.iban,
        bankName: data.bankName,
        swift: data.swift,
        to: data.to,
        topUpId: data.topUpId,
        currency: data.currency,
      }
    }, () => this.TopUpModal.show())
  }
  getTopUpHistoryList() {
    const {
      topUpIds,
      topUpContent,
    } = this.props;
    let data = [];
    data = topUpIds.map((id) => {
      const line = topUpContent[id];
      return (
        <tr key={id}>
          <td className="vistajet_light_text uk-padding-remove-left uk-table-shrink uk-text-nowrap uk-width-small">
            {this.formatDate(line.topUpDate)}
          </td>
          <td className="vistajet_light_text uk-table-shrink uk-text-nowrap uk-width-small">
            {line.paymentMethod}
          </td>
          <td className="vistajet_dark_text uk-table-shrink uk-text-nowrap uk-text-left">
            {line.currency} {numberWithCommas(line.topUpAmount)}
          </td>
          <td className="vistajet_light_text uk-text-left uk-text-truncate uk-table-expand vistajet_max_width_130">
            {convertToTitleCase(line.topUpStatusDesc)}
          </td>
          <td className="uk-padding-remove-left uk-table-shrink uk-text-nowrap">
            <ul class="uk-iconnav uk-flex-right">
              { (line.topUpStatusId === '' || line.topUpStatusId === 2 || line.topUpStatusId === 3) ? null :
              <li><button className="vistajet_edit_icon" onClick={() => this.setStateWithModalData(line)}>
                </button></li> }
            </ul>
          </td>
        </tr>
    );
  });
 return data;
}
  render() {
   const {
    TopUpReference,
    TopUpAction,
    CancelTopUp,
    token,
    accountId,
    openPopUpMessage,
    membershipAccount,//history
    bankName,
    currency,
    iban,
    swift,
    to,
    user,
    topUpReferenceLoader,
    topUpIds,
    topUpHistoryLoader,
    topUpSubmittedLoader,
    topUpCancelLoader,
    disableAmountField,
    topUpError,
    ClearTopUpError,
  } = this.props;
  const {
    topUpIdState,
    referenceCodeState,
    topUpAmountState,
    modalWithData = {},
    disableAmountState,
  } = this.state;

  return (
    <div>
      <Modal
        ref={(TopUpModal)=>{this.TopUpModal=TopUpModal;}}
        classNames={{
          modal: 'topUpModalDirect',
        }}
        styles=
          {{modal :{background:'transparent', boxShadow: 'none'},
          overlay: {background: 'rgba(0, 0, 0, 0.2)'}}}
        showCloseIcon={false}
        focusTrapped={false}
      >
        <TopUpAccount
          modalId='TopUpModal'
          confirmId="TopUpModalConfirm"
          TopUpReference={TopUpReference}
          TopUpAction={TopUpAction}
          CancelTopUp={CancelTopUp} 
          token={token}
          accountId={accountId}
          bankName={disableAmountState? modalWithData.bankName: bankName}
          currency={disableAmountState? modalWithData.currency :currency}
          iban={disableAmountState? modalWithData.iban: iban}
          referenceCode={disableAmountState? modalWithData.referenceCode :referenceCodeState}
          swift={disableAmountState? modalWithData.swift : swift}
          to={disableAmountState? modalWithData.to: to}
          topUpAmount={disableAmountState? modalWithData.amount: topUpAmountState}
          topUpId={disableAmountState? modalWithData.topUpId: topUpIdState}
          user={user}
          topUpReferenceLoader={topUpReferenceLoader}
          openPopUpMessage={openPopUpMessage}
          topUpSubmittedLoader={topUpSubmittedLoader}
          topUpCancelLoader={topUpCancelLoader}
          disableAmountField={disableAmountState ? disableAmountState: disableAmountField}
          redirect={disableAmountField}
          topUpError={topUpError}
          ClearTopUpError={ClearTopUpError}
          TopUpModal={this.TopUpModal}
        />
      </Modal>
      <div className="uk-container vistajet_screen_wrapper">
      <div className="uk-margin-medium-top uk-width-1-1 uk-width-4-5@s uk-width-2-3@m uk-margin-auto">
          <div className="vistajet_top_up_history vistajet_top_up">
            <div class="uk-card uk-card-default uk-card-body vistajet_card uk-padding-remove">
            <div className="vistajet_statement_content">
              <div className="vistajet_top_up_header">
                  <h4>Top Up History</h4>
              </div>
              <div className="uk-grid-collapse vistajet_top_up_section" data-grid>
                {
                  (topUpHistoryLoader) && 
                  <div className="vistajet_spinner">
                    <SpinnerLoader />
                   </div>
                }
                {(!topUpHistoryLoader) &&
                  <React.Fragment>
                  <div className="uk-width-expand vistajet_balance_wrapper">
                      <h5>Balance</h5>
                      <h4 className="uk-margin-remove">{membershipAccount.currency} {numberWithCommas(membershipAccount.currentBalance)}</h4>
                  </div>
                  <div className="uk-width-1-1 uk-width-auto@s uk-text-right">
                      <button className="uk-button vistajet_button_default vistajet_button_primary"
                        onClick={this.openTopUp}>Top Up</button>
                  </div>
                </React.Fragment>
                }
              </div>
              <div className="vistajet_scrollable_table">
                  <p className="vistajet_dark_text uk-margin-small-top uk-margin-remove-bottom">Topup Transactions</p>
                  {(!topUpHistoryLoader) &&
                  <div className="uk-overflow-auto vistajet_table_wrapper">
                    { scrollFunctionForTable && scrollFunctionForTable() }
                      {(topUpIds && topUpIds.length) ?
                        <table className="uk-table uk-table-small">
                          <tbody>
                            {this.getTopUpHistoryList()}
                          </tbody>
                        </table> :
                      'No Top Ups Available'
                      }
                  </div>}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
          <MessagePopUp
            id = {'validationPopUpHistory'}
            content={this.props.topUpHistoryError}
            ref={(validationPopUpHistory)=>{this.validationPopUpHistory=validationPopUpHistory;}}
            onClose={this.clearErrorMessage}
          />
      </div>
    );
  }
}

export default TopUpHistory;
