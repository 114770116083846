import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import MessagePopUp from '../../shared/MessagePopUp';
import SpinnerLoader from '../../shared/spinnerLoader';
import ValidateChangePasswordForm from '../../../utils/Validators/ChangePasswordValidator';
import { errorMessages } from "../../../constants/ErrorMessageConstants";
import dirtystateSingleTon from '../../../validators/validateDirty';
import SPAConstants from '../../../constants/SPAConstants';

export default class UserChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      oldPasswordType: 'password',
      newPassword: '',
      newPasswordType: 'password',
      retypeNewPassword: '',
      retypeNewPasswordType: 'password',
      errors: {},
      oldPasswordIconClass: 'vistajet_password_hide',
      newPasswordIconClass: 'vistajet_password_hide',
      retypePasswordIconClass: 'vistajet_password_hide',
      popupMessage: 'Your Request cannot be processed this time',
      isFieldsChanged: false,
    };
    const forState = {
      oldPassword: '',
      newPassword: '',
      retypeNewPassword: '',
    };
    this.oldState = Object.assign({}, forState);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.user.changePasswordRequired && nextProps.user.changePasswordSuccess
      && this.props.user.changePasswordSuccess !== nextProps.user.changePasswordSuccess) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
      this.props.goToTabSelection(0);
    }
    if (this.props.user.changePasswordErrors !== nextProps.user.changePasswordErrors
      && nextProps.user.changePasswordErrors) {
      this.setState({
        popupMessage: nextProps.user.changePasswordErrorText
          ? nextProps.user.changePasswordErrorText
          : 'Your Request cannot be processed this time',
      }, () => {
        window.UIkit.modal('#UserPasswordUpdateErrorPopUp').show();
      });
    }
  }

  handleOldPasswordChange = (e) => {
    this.setState({ oldPassword: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleNewPasswordChange = (e) => {
    this.setState({ newPassword: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleRetypePasswordChange = (e) => {
    this.setState({ retypeNewPassword: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleChangePassword = () => {
    const { oldPassword, newPassword, retypeNewPassword } = this.state;
    const { changePasswordAction } = this.props;
    const { username, userToken } = this.props.user.user;
    const res = ValidateChangePasswordForm({
      oldPassword,
      newPassword,
      retypeNewPassword,
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      const payload = {
        oldPassword,
        newPassword,
        retypeNewPassword,
        username,
        userToken,
        isProfile: true,
      };
      changePasswordAction(payload);
    }
  };

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  toggleOldPasswordType = () => {
    this.setState(
      {
        oldPasswordType: (this.state.oldPasswordType === 'password') ? 'text' : 'password',
        oldPasswordIconClass: (this.state.oldPasswordType === 'password') ? 'vistajet_password_show' : 'vistajet_password_hide',
      },
    );
  };

  toggleNewPasswordType = () => {
    this.setState(
      {
        newPasswordType: (this.state.newPasswordType === 'password') ? 'text' : 'password',
        newPasswordIconClass: (this.state.newPasswordType === 'password') ? 'vistajet_password_show' : 'vistajet_password_hide',
      },
    );
  };

  toggleRetypePasswordType = () => {
    this.setState(
      {
        retypeNewPasswordType: (this.state.retypeNewPasswordType === 'password') ? 'text' : 'password',
        retypePasswordIconClass: (this.state.retypeNewPasswordType === 'password') ? 'vistajet_password_show' : 'vistajet_password_hide',
      },
    );
  };

  compareOldAndNewValues = () => {
    const {
      oldPassword,
      newPassword,
      retypeNewPassword,
      isFieldsChanged,
    } = this.state;
    const newState = {
      oldPassword,
      newPassword,
      retypeNewPassword,
    };

    if (
      (newState.oldPassword.toLowerCase() !== this.oldState.oldPassword.toLowerCase()
        || newState.newPassword.toLowerCase() !== this.oldState.newPassword.toLowerCase()
        || newState.retypeNewPassword.toLowerCase() !== this.oldState.retypeNewPassword.toLowerCase()
      )
      && !isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, true);
      this.setState({
        isFieldsChanged: true,
      });
    } else if (
      (newState.oldPassword.toLowerCase() === this.oldState.oldPassword.toLowerCase()
        && newState.newPassword.toLowerCase() === this.oldState.newPassword.toLowerCase()
        && newState.retypeNewPassword.toLowerCase() === this.oldState.retypeNewPassword.toLowerCase()
      )
      && isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
      this.setState({
        isFieldsChanged: false,
      });
    }
  }

  redirectToHome = () => {
    window.location = '/';
  };

  closePopup = (id) => {
    window.UIkit.modal(`#${id}`).hide();
  }

  renderChangePasswordForm = () => {
    const {
      oldPassword,
      newPassword,
      retypeNewPassword,
      retypeNewPasswordType,
      oldPasswordType,
      oldPasswordIconClass,
      newPasswordType,
      newPasswordIconClass,
      retypePasswordIconClass,
      popupMessage,
      errors,
      isFieldsChanged,
    } = this.state;
    const {
      isPasswordChangeLoading,
    } = this.props.user;
    const buttonClass = isFieldsChanged
      ? 'uk-button change_password_button uk-width-1-1 vistajet_button_primary'
      : 'uk-button vistajet_disabled uk-width-1-1 change_password_button vistajet_button_primary';
    let mobileErrorTextMarginStyle = {};
    if (
      isMobile
      && errors.errors
      && errors.errors.newPassword
      && (errorMessages.PASS_MAX_LENGTH == errors.errors.newPassword
        || errors.errors.newPassword == errorMessages.INVALID_PASS)) {
      mobileErrorTextMarginStyle = { marginBottom: 40 };
    }
    return (
      <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card">
        {
          isPasswordChangeLoading && (
            <div className="vistajet_spinner">
              <SpinnerLoader />
            </div>
          )
        }
        <div class="vistajet_head_column">
          <div class="uk-clearfix">
            <div class="uk-float-left">
              <h3 class="vistajet_right_tab_heading">Change Password</h3>
            </div>
          </div>
        </div>

        <div className="vistajet_profile_details_column vistajet_primary_form">
          <div className="uk-flex uk-grid-small" data-uk-grid>
            <div className="uk-width-1-1">
              <div className="vistajet_input_wrapper uk-flex uk-flex-middle">
                <input
                  id="oldPassword"
                  type={oldPasswordType}
                  name="oldPassword"
                  onChange={this.handleOldPasswordChange}
                  onFocus={this.removeErrors}
                  value={oldPassword}
                  autocomplete="current-password"
                />
                <label htmlFor="oldPassword">
                  Old Password
                </label>
                {
                  errors.errors && errors.errors.oldPassword && (
                    <p>
                      {errors.errors.oldPassword}
                    </p>
                  )
                }
                <span
                  className={oldPasswordIconClass}
                  onMouseDown={this.toggleOldPasswordType}
                  onMouseUp={this.toggleOldPasswordType}
                  onTouchStart={this.toggleOldPasswordType}
                  onTouchEnd={this.toggleOldPasswordType}
                />
              </div>
              <div
                className="vistajet_input_wrapper uk-flex uk-flex-middle"
                style={mobileErrorTextMarginStyle}
              >
                <input
                  id="newPassword"
                  type={newPasswordType}
                  name="newPassword"
                  onChange={this.handleNewPasswordChange}
                  onFocus={this.removeErrors}
                  value={newPassword}
                  autocomplete="new-password"
                />
                <label htmlFor="password">
                  New Password
                  </label>
                {
                  errors.errors && errors.errors.newPassword && (
                    <p>
                      {errors.errors.newPassword}
                    </p>
                  )
                }
                <span
                  className={newPasswordIconClass}
                  onMouseDown={this.toggleNewPasswordType}
                  onMouseUp={this.toggleNewPasswordType}
                  onTouchStart={this.toggleNewPasswordType}
                  onTouchEnd={this.toggleNewPasswordType}
                />
              </div>
              <div
                className="vistajet_input_wrapper uk-flex uk-flex-middle"
                style={mobileErrorTextMarginStyle}
              >
                <input
                  id="retypeNewPassword"
                  type={retypeNewPasswordType}
                  name="retypeNewPassword"
                  onChange={this.handleRetypePasswordChange}
                  onFocus={this.removeErrors}
                  value={retypeNewPassword}
                  autocomplete="new-password"
                />
                <label htmlFor="retypeNewPassword">
                  Retype Password
                  </label>
                {
                  errors.errors && errors.errors.retypeNewPassword && (
                    <p>
                      {errors.errors.retypeNewPassword}
                    </p>
                  )
                }
                <span
                  className={retypePasswordIconClass}
                  onMouseDown={this.toggleRetypePasswordType}
                  onMouseUp={this.toggleRetypePasswordType}
                  onTouchStart={this.toggleRetypePasswordType}
                  onTouchEnd={this.toggleRetypePasswordType}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="vistajet_profile_footer">
          <button
            className={buttonClass}
            disabled={!isFieldsChanged}
            onClick={this.handleChangePassword}
          >
            Change Password
              </button>
          <MessagePopUp
            id="UserPasswordUpdateErrorPopUp"
            content={popupMessage}
          />
        </div>
      </div>
    );
  };

  render() {
    return this.renderChangePasswordForm();
  }
}
