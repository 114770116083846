import React, { Component } from 'react';

class CateringListActionItem extends Component {

  handleActionClick = () => {
    if (this.props.onActionClicked) this.props.onActionClicked(this.props.data);
  }
  render() {
    let { description, count } = this.props;
    description = description && description.trim();
    return (
      <div className="uk-width vistajet_catering_menu_list vistajet_menu_list_wrapper">
        <div className="uk-width-expand uk-flex uk-flex-center uk-flex-column">
          <p className="uk-text-left vistajet_accordion_menu_item">{this.props.label}</p>
          {(description) && <p className="uk-text-left vistajet_accordion_menu_description">{description}</p>}
        </div>
        <p className="vistajet_item_quantity">{(count) ? `x ${count}` : ''}</p>
        <button className="uk-button vistajet_accordion_menu_add" onClick={this.handleActionClick}>ADD</button>
      </div>
      // <li className="separator-bottom catering-submenu">
      //   <div className="row" onClick={this.handleActionClick}>
      //     <div className="col s8 label-name">
      //       {this.props.label}
      //     </div>
      //     <div className="col s4">
      //       <div className="action-button catering-submenu-actions">
      //         {(this.props.actionLabel) &&
      //           <div>
      //             {this.props.actionLabel}
      //           </div>
      //         }
      //         circle
      //       </div>
      //     </div>
      //   </div>
      //   {(description) && <div className="description">{description}</div>}
      // </li>
    );
  }
}

export default CateringListActionItem;
