import React, { Component } from 'react';
import Slider, { Handle } from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';

export default class ProgramBuilderRangeSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      min: props.min,
      max: props.max,
      step: props.step,
      returnCheckbox: false,
      sliderValue: 5,
      showYears: false,
    };
  }


  handleRangeSliderMax = value => {
    let { max } = this.state;
    const { returnCheckbox } = this.state;
    if (value === max) {
      max += 25;
      this.setState({
        max,
      });
    }
    this.setState({
      sliderValue: value,
      showYears: true,
    });
    if (returnCheckbox) value *= 2;
    const { onChange, data } = this.props;
    onChange(data, value);   
  };

  handleRangeSlider = props => {
    const {
      value, dragging, index, ...restProps
    } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  handleReturnCheck = () => {
    const { returnCheckbox } = this.state;
    const { onChange, data } = this.props;
    this.setState({
      returnCheckbox: !returnCheckbox,
    },() => {
      const { returnCheckbox, sliderValue } = this.state;
      const value = returnCheckbox ? sliderValue * 2 : sliderValue;
      onChange(data, value);
    });
  };

  handleDropDown = (index) => {
    // window.UIkit.dropdown(`#range-slider-${index}`).show();
  };


  render() {
    const { data } = this.props;
    const {
      min, max, step, returnCheckbox, sliderValue, showYears
    } = this.state;
    const marks = {};
    marks[1] = min;
    marks[max] = max;

    return (
      <div>
        <div className="uk-inline uk-width-1-1">
          <p className="uk-margin-remove-bottom vistajet_how_many_times" onClick={() => this.handleDropDown(data)}>
            {
              returnCheckbox ? `${sliderValue} return flights per year` : showYears ? `${sliderValue} flights per year` : `How many times per year?`
            }
          </p>
          <div id={`range-slider-${data}`} uk-dropdown="mode : click" className="vistajet_program_time_slider_dropdown">
            <Slider
              min={min}
              max={max}
              defaultValue={5}
              marks={marks}
              step={step}
              onAfterChange={this.handleRangeSliderMax}
              handle={this.handleRangeSlider}
              className="uk-margin-medium-top"
            />
            <div className="vistajet_return_flights_checkbox uk-margin-medium-top">
              <input
                className="uk-checkbox"
                type="checkbox"
                name="checkbox"
                checked={returnCheckbox}
                onClick={this.handleReturnCheck}
              />
              <label>
                Include return flights
              </label>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

ProgramBuilderRangeSlider.defaultProps = {
  min: 1,
  max: 25,
  step: 1,
  onChange: () => {
  },
};
