import React from 'react';
import FlightPathRow from '../shared/FlightPathRow';
import OfferMap from '../OfferMap';
import { populateLegsForMap, getItineraryLegStatus } from '../../utils/itinerariesUtils';
import Helpers from '../../utils/Helpers';

export const ItineraryCard = (props) => {
  const {
    legs,
    currentLeg,
    renderDots,
    itineraryType,
    itineraryDetails,
    visitedAirportsFinder,
    isGoogleMapsApiLoaded,
    onGoogleMapsApiLoaded,
    passengers,
  } = props;
  const dates = props.flightDates(currentLeg && currentLeg.departureAirportTimeLocal);
  const arrivalAirportDetail = visitedAirportsFinder[currentLeg && currentLeg.arrivalAirportId];
  const departureAirportDetail = visitedAirportsFinder[currentLeg && currentLeg.departureAirportId];
  const item = currentLeg && { legs: [currentLeg] };
  return (
    <ul className="uk-child-width-1-1 uk-margin-remove">
      <li>
        <div className="uk-text-center vistajet_slider_header">
          <div className="vistajet_airport_name_wrapper">
            <p className="uk-text-truncate">
              {departureAirportDetail && Helpers.capitalizeWord(departureAirportDetail.city) + '  ' + departureAirportDetail.icao}
            </p>

            <p>&nbsp;{'-'}&nbsp;</p>

            <p className="uk-text-truncate">
              {arrivalAirportDetail && Helpers.capitalizeWord(arrivalAirportDetail.city) + '  ' + arrivalAirportDetail.icao}
            </p>
          </div>
          <p className="vistajet_on_request_text">{currentLeg && getItineraryLegStatus(currentLeg, itineraryType)}</p>
          <div className="vistajet_slider_dot_wrapper">{renderDots(legs)}</div>
        </div>
        <div className="uk-flex uk-grid-collapse" data-uk-grid>
          <div className="uk-width-2-3@s">
            <div className="vistajet_bs_body uk-width-1-1">
              {currentLeg && (
                <FlightPathRow
                  dates={dates}
                  childLeg={false}
                  leg={currentLeg}
                  isItineraryDetail
                  lastLeg={currentLeg}
                  item={itineraryDetails.orderIdentifier}
                  visitedAirportsFinder={visitedAirportsFinder}
                  aircraftsList ={props.aircraftsList || []}
                  passengers={passengers}
                />
              )}
            </div>
          </div>
          <div className="uk-width-1-3 uk-visible@s">
            <OfferMap
              isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
              onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
              legs={populateLegsForMap(item, visitedAirportsFinder)}
              isListing
            />
          </div>
        </div>
      </li>
    </ul>
  );
}

export default ItineraryCard;
