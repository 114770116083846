import { 
    topUpReferenceSource,
    topUpSource,
    topUpHistorySource,
    topUpCancelReferenceCodeSource,
   } from '../sources/TopUpAccountSource';
import * as types from '../actionTypes';

export const TopUpReferenceInit = () => ({
  type: types.TOPUP_REFERENCE_INIT,
});

export const TopUpReferenceSuccess = data => ({
  type: types.TOPUP_REFERENCE_SUCCESS,
  data,
});

export const TopUpReferenceFail = data => ({
  type: types.TOPUP_REFERENCE_FAIL,
  data,
});
export const TopUpInit = () => ({
  type: types.TOPUP_INIT,
});

export const TopUpSuccess = data => ({
  type: types.TOPUP_SUCCESS,
  data,
});

export const TopUpFail = (data) => ({
  type: types.TOPUP_FAIL,
  data,
});

export const TopUpHistoryInit = () => ({
  type: types.TOPUP_HISTORY_INIT,
})

export const TopUpHistorySuccess = (data) => ({
  type: types.TOPUP_HISTORY_SUCCESS,
  data,
})

export const TopUpHistoryFail = (data) => ({
  type: types.TOPUP_HISTORY_FAIL,
  data,
})

export const cancelReferenceCodeInit = () => ({
  type: types.CANCEL_REFERENCE_CODE_INIT,
})

export const cancelReferenceCodeSuccess = (data) => ({
  type: types.CANCEL_REFERENCE_CODE_SUCCESS,
  data,
})

export const cancelReferenceCodeFail = (data) => ({
  type: types.CANCEL_REFERENCE_CODE_FAIL,
  data,
})

export const TopUpViewAction = () => ({
  type: types.TOPUP_VIEW_ACTION,
})

export const ClearTopUpError = () => ({
  type: types.CLEAR_TOPUP_ERROR,
})

export const TopUpAction = (token, topUpId) => 
  topUpSource(token, topUpId);

export const TopUpReferenceAction = (token, accountId, transferAmount, Currency) =>
  topUpReferenceSource(token, accountId, transferAmount, Currency);

export const TopUpHistory = (token, accountId) =>
  topUpHistorySource(token, accountId);

export const TopUpCancelReferenceCode = (token, topUpId) =>
  topUpCancelReferenceCodeSource(token, topUpId);

