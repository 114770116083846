import {
  validateSalutation,
  validateFirstName,
  validateLastName,
  validateEmailStartingFromUpperCase,
  validatePhone,
  validateCheckBox,
  validateStateCode,
  validateTypeOfFlying,
  validateCurrentFlyingSolution,
} from './Validators';

export default function validateCostCalculatorForm(obj) {
  const salutationResponse = validateSalutation(obj.salutation.value);
  const firstNameResponse = validateFirstName(obj.firstName);
  const lastNameResponse = validateLastName(obj.lastName);
  const emailResponse = validateEmailStartingFromUpperCase(obj.email);
  const stateCodeResponse = validateStateCode(obj.stateCode, obj.country);
  const typeOfFlyingResponse = validateTypeOfFlying(obj.typeOfFlying);
  const currentFlyingSolutionResponse = validateCurrentFlyingSolution(obj.currentFlyingSolution);
  let phoneNumberResponse;
  if (obj.phoneNumber != null) {
    phoneNumberResponse = validatePhone(
      obj.phoneNumber,
      obj.isPhoneNumberValid
    );
  }
  const privacyCheckboxResponse = validateCheckBox(obj.privacyCheckbox);
  const res = {
    errors: {},
  };
  if (salutationResponse.isError) {
    res.isError = salutationResponse.isError;
    res.errors.salutation = salutationResponse.message;
  }
  if (firstNameResponse.isError) {
    res.isError = firstNameResponse.isError;
    res.errors.firstName = firstNameResponse.message;
  }
  if (lastNameResponse.isError) {
    res.isError = lastNameResponse.isError;
    res.errors.lastName = lastNameResponse.message;
  }
  if (emailResponse.isError) {
    res.isError = emailResponse.isError;
    res.errors.email = emailResponse.message;
  }
  if (phoneNumberResponse && phoneNumberResponse.isError) {
    res.isError = phoneNumberResponse.isError;
    res.errors.phoneNumber = phoneNumberResponse.message;
  }
  if (privacyCheckboxResponse.isError) {
    res.isError = privacyCheckboxResponse.isError;
    res.errors.privacyCheckbox = privacyCheckboxResponse.message;
  }
  if (stateCodeResponse.isError) {
    res.isError = stateCodeResponse.isError;
    res.errors.stateCode = stateCodeResponse.message;
  }
  if (typeOfFlyingResponse.isError) {
    res.isError = typeOfFlyingResponse.isError;
    res.errors.typeOfFlying = typeOfFlyingResponse.message;
  }
  if (currentFlyingSolutionResponse.isError) {
    res.isError = currentFlyingSolutionResponse.isError;
    res.errors.currentFlyingSolution = currentFlyingSolutionResponse.message;
  }
  return res;
}
