export const CUSTOMER_FEEDBACK_SUBMITTED = 'Thank you for submitting your feedback.';
export const FEEDBACK_ALREADY_EXIST = 'Feedback already exist. Please refresh the page to know the rating.';
export const FEEDBACK_CONFIRMATION = 'Are you sure you would like to submit your feedback?' +
  ' Please note that feedback cannot be changed once submitted.';
export const UPCOMING_FEEDBACK_MSG =
  'You will be able to submit your flight feedback after arrival.';
export const SUBMIT_CUSTOMER_FEEDBACK = 'We are committed to improving your flight experience.' +
    '  How would you rate your flight?';
export const UPCOMING_FLIGHT_FEEDBACK = 'Sorry you cannot submit your feedback before your flight.';
export const RATING_LABEL = [null, 'Poor', 'Fair', 'Good', 'Very Good', 'Excellent'];

export const GLITCH_TYPE = {
  catering: 4,
  'cabin-comfort': 5,
  'on-board': 20,
  transportation: 21,
  overall: 22,
};

export const FEEDBACK_TYPE = {
  1: 'Bad',
  2: 'Unsatisfactory',
  3: 'Good',
  4: 'Very Good',
  5: 'Excellent',
};
