import axios from 'axios';
import { CREW_INFO_URL } from '../../configs/constants';
import {
  fetchCrewInit,
  fetchCrewSuccess,
  fetchCrewFail,
} from '../actions';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';
import { SOMETHING_UNEXPECTED } from '../../constants/MessageConstants';
import { BAD_REQUEST, TIMEOUT_CODE, UNAUTHORIZED } from '../../constants/ServerCodeConstants';

const crewPromiseInprogress = {};

const fetchCrewSource = (token, flightLegId) => (dispatch) => {
  const uniqueKey = flightLegId;
  if (crewPromiseInprogress[uniqueKey]) {
    return crewPromiseInprogress[uniqueKey];
  }
  const crewPromise = new Promise((resolve, reject) => {
    const url = CREW_INFO_URL.replace('{token}', token).replace('{flightLegId}', flightLegId);

    dispatch(fetchCrewInit(flightLegId));
    axios
      .get(url)
      .then((response) => {
        if (response && response.data) {
          if ((response.data.responseHeaderDto &&
            response.data.responseHeaderDto.isError && response.data.responseHeaderDto.status)) {
            const errors = handleServerErrors(response.data.responseHeaderDto.status);
            dispatch(fetchCrewFail(errors, flightLegId));
            reject(fetchCrewFail(errors, flightLegId));
          }
          else {
            dispatch(fetchCrewSuccess(response.data.crewInfos, flightLegId));
            resolve(response.data.crewInfos);
          }
        }
        else {
          dispatch(fetchCrewFail(SOMETHING_UNEXPECTED, flightLegId));
          reject(fetchCrewFail(SOMETHING_UNEXPECTED, flightLegId));
        }
        delete crewPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        if (err.code === TIMEOUT_CODE.status) {
          dispatch(fetchCrewFail(handleServerErrors(TIMEOUT_CODE.code), flightLegId));
        } else if (+err.status === UNAUTHORIZED) {
          dispatch(fetchCrewFail(handleServerErrors(UNAUTHORIZED), flightLegId));
        }
        else dispatch(fetchCrewFail(handleServerErrors(BAD_REQUEST), flightLegId));
        delete crewPromiseInprogress[uniqueKey];
      });
  });
  crewPromiseInprogress[uniqueKey] = crewPromise;
  return crewPromise;
};

export default fetchCrewSource;
