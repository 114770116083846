import React from "react";
import OfferMap from "components/OfferMap";
import OfferDetailsCard from "./CardWithButton";

const OfferDetails = props => {
  const {
    departureAirportDetails,
    arrivalAirportDetails,
    aircraftDetails,
    from,
    to,
    isGoogleMapsApiLoaded,
    onGoogleMapsApiLoaded,
    offerId,
    isLoggedIn
  } = props;

  const offerDetailsObject = {
    departureCity: departureAirportDetails.city,
    departureAirportName: departureAirportDetails.name,
    from,
    to
  };
  if (arrivalAirportDetails) {
    offerDetailsObject.arrivalCity = arrivalAirportDetails.city;
    offerDetailsObject.arrivalAirportName = arrivalAirportDetails.name;
  }
  return (
    <div className="uk-inline uk-margin-medium-top">
      <div id="vistajet_offer_map_primary" className={props.mapStyle}>
        <OfferMap
          legs={[
            {
              id: offerId.toString(),
              departureAirport: departureAirportDetails,
              arrivalAirport: arrivalAirportDetails
            }
          ]}
          isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
          onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
        />
        <div className="uk-position-center-left">
          <OfferDetailsCard
            handleSendRequest={props.handleSendRequest}
            isSendRequest={props.isSendRequest}
            offerDetails={offerDetailsObject}
            aircraftDetails={aircraftDetails}
            isLoggedIn={isLoggedIn}
          />
        </div>
      </div>
    </div>
  );
};
export default OfferDetails;
