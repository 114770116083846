import * as types from '../actionTypes';

export const updateFrequentPax = (data) => ({
  type: types.UPDATE_FREQUENT_PAX,
  data,
});

export const updateAddedPassengers = (data) => ({
  type: types.UPDATE_ADDED_PASSENGERS,
  data,
});

export const updatePassport = (data) => ({
  type: types.UPDATE_PASSPORT,
  data,
});

export const closePopup = (data) => ({
  type: types.CLOSE_POPUP,
  data,
});

export const setLegPassport = (data) => ({
  type: types.SET_LEG_PASSPORT,
  data,
});

export const setLeadPassenger = (data) => ({
  type: types.SET_LEAD_PASSENGER,
  data,
});

export const updateSelectedPassenger = (data) => ({
  type: types.UPDATE_SELECTED_PASSENGER,
  data,
});

export const clearSelectedPassenger = (data) => ({
  type: types.CLEAR_SELECTED_PASSENGER,
  data,
});

export const removePassport = (data) => ({
  type: types.REMOVE_PASSPORT,
  data,
});

export const removeUser = (data) => ({
  type: types.REMOVE_USER,
  data,
});

export const initializePopup = (data) => ({
  type: types.INITIALIZE_POPUP,
  data,
});
