import * as type from '../../actionTypes';
import { getFlightId } from '../../../utils/itinerariesUtils';

const initialState = {
	isLoadingAvailedServices: 0,
	isLoadingAdditionalServices: 0,
	isErrorAvailedServices: '',
	isErrorAdditionalServices: '',
};

export default function launchReducer(state = initialState, action) {
	switch (action.type) {
		case type.ITINERARY_DETAILS_SUCCESS: {
			const {
				legs,
			} = action.data;
			const availedServiceData = {};
			const availedAdditionalServicesData = {};
			legs.forEach(leg => {
				availedServiceData[getFlightId(leg)] = {};
				availedAdditionalServicesData[getFlightId(leg)] = {};

				availedAdditionalServicesData[getFlightId(leg)].isLoadingAdditionalServices = false;
				availedServiceData[getFlightId(leg)].isLoadingAvailedServices = false;
			})
			return Object.assign({}, state, {
				availedService: availedServiceData,
				availedASData: availedAdditionalServicesData
			});
		}

		//----------FETCH_AVAILED_SERVICES------

		case type.FETCH_AVAILED_SERVICES_INIT: {
			state.availedService[action.flightLegId].isLoadingAvailedServices = true;
			state.availedASData[action.flightLegId].isLoadingAdditionalServices = true;
			return Object.assign({}, state, {
				availedService: state.availedService,
				availedASData: state.availedASData,
				isLoadingAvailedServices: state.isLoadingAvailedServices + 1,
				isLoadingAdditionalServices: state.isLoadingAdditionalServices + 1,
				isErrorAvailedServices: '',
			});
		}

		case type.FETCH_AVAILED_SERVICES_SUCCESS: {
			state.availedService[action.flightLegId].availedService = action.availedGTData || [];
			state.availedASData[action.flightLegId].availedASData = action.availedASData || [];
			state.availedService[action.flightLegId].isLoadingAvailedServices = false
			state.availedASData[action.flightLegId].isLoadingAdditionalServices = false;

			return Object.assign({}, state, {
				isLoadingAvailedServices: state.isLoadingAvailedServices - 1,
				isLoadingAdditionalServices: state.isLoadingAdditionalServices - 1,
				availedService: state.availedService,
				availedASData: state.availedASData,
				isErrorAvailedServices: '',
			});
		}

		case type.FETCH_AVAILED_SERVICES_FAIL: {
			state.availedService[action.flightLegId].isLoadingAvailedServices = false;
			state.availedASData[action.flightLegId].isLoadingAdditionalServices = false;
			state.availedService[action.flightLegId].availedService = [];
			state.availedASData[action.flightLegId].availedASData = [];

			return Object.assign({}, state, {
				isLoadingAvailedServices: state.isLoadingAvailedServices - 1,
				isLoadingAdditionalServices: state.isLoadingAdditionalServices - 1,
				availedService: state.availedService,
				availedASData: state.availedASData,
				isErrorAvailedServices: action.error,
			});
		}

		//----------------ADD_TRANSPORT_SERVICES---------------

		case type.ADD_TRANSPORT_SERVICES_INIT: {
			state.availedService[action.flightLegId].isLoadingAvailedServices = true;
			return Object.assign({}, state, {
				availedService: state.availedService,
				isLoadingAvailedServices: state.isLoadingAvailedServices + 1,
				isErrorAvailedServices: '',
			});
		}

		case type.ADD_TRANSPORT_SERVICES_SUCCESS: {
			state.availedService[action.flightLegId].availedService.push(action.data)
			state.availedService[action.flightLegId].isLoadingAvailedServices = false
			return Object.assign({}, state, {
				isLoadingAvailedServices: state.isLoadingAvailedServices - 1,
				availedService: state.availedService,
				isErrorAvailedServices: ''
			});
		}

		case type.ADD_TRANSPORT_SERVICES_FAIL: {
			state.availedService[action.flightLegId].isLoadingAvailedServices = false;
			return Object.assign({}, state, {
				isLoadingAvailedServices: state.isLoadingAvailedServices - 1,
				availedService: state.availedService,
				isErrorAvailedServices: action.error,
			});
		}

		//---------------ADD_ADDITIONAL_SERVICES----------------

		case type.ADD_ADDITIONAL_SERVICES_INIT: {
			state.availedASData[action.flightLegId].isLoadingAdditionalServices = true;
			return Object.assign({}, state, {
				availedASData: state.availedASData,
				isLoadingAdditionalServices: state.isLoadingAdditionalServices + 1,
				isErrorAdditionalServices: '',
			});
		}

		case type.ADD_ADDITIONAL_SERVICES_SUCCESS: {
			state.availedASData[action.flightLegId].availedASData.push(action.data);
			state.availedASData[action.flightLegId].isLoadingAdditionalServices = false
			return Object.assign({}, state, {
				isLoadingAdditionalServices: state.isLoadingAdditionalServices - 1,
				availedASData: state.availedASData,
				isErrorAdditionalServices: ''
			});
		}

		case type.ADD_ADDITIONAL_SERVICES_FAIL: {
			state.availedASData[action.flightLegId].isLoadingAdditionalServices = false;
			return Object.assign({}, state, {
				isLoadingAdditionalServices: state.isLoadingAdditionalServices - 1,
				availedASData: state.availedASData,
				isErrorAdditionalServices: action.error,
			});
		}

		case type.CLEAR_ERROR_FETCH_ITINERARY: {
			return Object.assign({}, state, {
				isErrorAvailedServices: '',
			});
		}

		default:
			return state;
	}
}
