import axios from 'axios';
import { GET_TOKEN_URL } from '../../configs/constants';
import {
  fetchAdminTokenInit,
  fetchAdminTokenSuccess,
  fetchAdminTokenFail,
} from '../actions';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';
import normalizeParsedToken from '../../normalizers/tokenNormalizer';
import { parseXmlStringToJson } from '../../utils/parserUtils';
import { saveToLocal } from '../../utils/cache';
import decryptSingupKeys from '../../utils/decrypt';

const status = {
  OK: 200,
};

const adminTokenPromiseInprogress = {};

const { DECRYPTED_SIGNUP_CONTEXT } = decryptSingupKeys();

const adminTokenSource = (username = DECRYPTED_SIGNUP_CONTEXT, context = DECRYPTED_SIGNUP_CONTEXT) => (dispatch) => {
  const uniqueKey = username;
  if (adminTokenPromiseInprogress[uniqueKey]) {
    return adminTokenPromiseInprogress[uniqueKey];
  }
  const adminTokenPromise = new Promise((resolve, reject) => {
    const url = GET_TOKEN_URL.replace('{context}', context)
      .replace('{username}', username);
    dispatch(fetchAdminTokenInit());
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          const parsedTokenResponse = parseXmlStringToJson(response.data);
          const normalizedToken = normalizeParsedToken(parsedTokenResponse);
          dispatch(fetchAdminTokenSuccess(normalizedToken.token));
          saveToLocal({ adminToken: normalizedToken.token }, 'adminToken');
          resolve(normalizedToken);
        } else {
          const error = handleServerErrors(response.status);
          dispatch(fetchAdminTokenFail());
          saveToLocal({ adminToken: null }, 'adminToken');
          reject(error);
        }
        delete adminTokenPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(fetchAdminTokenFail(errors));
        saveToLocal({ adminToken: null }, 'adminToken');
        reject(errors);
        delete adminTokenPromiseInprogress[uniqueKey];
      });
  });
  adminTokenPromiseInprogress[uniqueKey] = adminTokenPromise;
  return adminTokenPromise;
};

export default adminTokenSource;
