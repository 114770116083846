import React, { Component } from "react";
//import moment from "moment";
import Helpers from "../../utils/Helpers";
import ConfirmationPopUp from '../shared/ConfirmationPopUpNew';
import { dateFnsFormats } from "../../utils/dateUtils";
import LegCard from "./LegCard";
import ContractSelector from "../shared/ContractSelector";
import DialogWrapper from "../DialogWrapper";
import {
  validateLegs,
  validateMultiLeg,
  calcDepartAndArrivalTime,
  calcFlightDuration,
  sortLegs
} from "../../utils/PAFUtils";
import Button from "../shared/Button";
import BulletPopup from "../shared/BulletPopup";
import {
  isFSP,
  isFspDirect,
  isLoggedIn,
  isGuest,
  isVJ2,
  getVJAccountId
} from "../../utils/userUtils";
import { getAvailableAircrafts } from "../../utils/aircraftUtils";
import aircraftsList from "../../utils/Aircrafts";
import SpinnerLoader from "../shared/spinnerLoader";
import { legObject, leg } from "../../utils/offerUtils";
import { isExtendedOneWayValid, notValidOfferMessage } from "../../utils/oneWayOfferUtils";
import {
  AIRCRAFT_NOT_IN_CONTRACT,
  FILL_CONTRACT_EXCEPTION,
  BACK_TO_OFFER,
  OFFER_NOT_APPLIED,
  PASSENGER_EXCEED_AIRCRAFT_CHANGE
} from "../../constants/MessageConstants";
import { USER_ACCESS_TYPES } from "../../constants/UserAccessTypes";
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants'
import { scrollToTop } from "../../utils/browserUtils";
import { format, isWithinInterval, parse } from "date-fns";

class LegListing extends Component {
  constructor(props) {
    super(props);
    this.orderExtIdentifier = Helpers.guid();
    this.initialLegStruct = {
      ...legObject,
      orderExtIdentifier: this.orderExtIdentifier
    };
    this.aircrafts = aircraftsList;
    this.state = {
      legArray:
        props.legArray.length > 0
          ? props.legArray
          : [{ ...this.initialLegStruct }],
      disableReturn: true,
      popupErrorMessages: [],
      availableAircraftIds: [],
      selectedContract: null,
      accountId: null,
      contractValidation: null,
      isOkPressed: false,
      isOffer: true,
      message: "",
      showAircraftInContract: false,
      passengerInfo: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.legArray !== this.props.legArray) {
      dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
      this.setState({
        legArray: nextProps.legArray
      });
      this.setEnabledAddReturn(nextProps.legArray);
    }
    if (
      nextProps.validateFlightMessages.length > 0 &&
      !this.state.isOkPressed
    ) {
      window.UIkit.modal("#validationPopUp").show();
    }
    const actualData =
      nextProps.legArray.length > 0 ? nextProps.legArray : this.state.legArray;
    this.setEnabledAddReturn(actualData);
  }
  handleAddFlight = isReturnAdded => {
    this.refreshFormFields(isReturnAdded);
  };

  setEnabledAddReturn = legArray => {
    this.setState({ disableReturn: true });
    const legs = legArray.filter(leg => !leg.isCanceled);
    const lastLeg = legs ? legs[legs.length - 1] : {};
    const firstLeg = legs ? legs[0] : {};
    if (
      firstLeg.departureAirport !== null &&
      lastLeg.arrivalAirport !== null &&
      firstLeg.departureAirport !== lastLeg.arrivalAirport
    ) {
      this.setState({ disableReturn: false });
    }
  };

  refreshFormFields = (isReturnAdded = false) => {
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
    const legArray = [...this.state.legArray];
    const lastLeg = legArray[legArray.length - 1];
    const type = legArray[0].isEmptyLeg ? "Empty Leg" : "One Way";
    let duration = null;
    if (isReturnAdded) {
      duration = Helpers.getFlightDuration(
        lastLeg.arrivalAirport,
        lastLeg.departureAirport,
        lastLeg.selectedAircraft
      );
    }
    const initialLeg = leg();
    initialLeg.orderExtIdentifier = this.orderExtIdentifier;
    legArray.push({
      ...initialLeg,
      isReturn: isReturnAdded,
      prevLeg: lastLeg,
      departureAirport: lastLeg.arrivalAirport,
      arrivalAirport: isReturnAdded ? legArray[0].departureAirport : null,
      selectedAircraft: lastLeg.selectedAircraft,
      passengersCount: lastLeg.passengersCount,
      duration
      //validation: legArray[legArray.length - 1].validation
    });
    if (this.props.isOfferFlow) {
      const { isValidOffer } = this.validateOffer(legArray);
      if (isValidOffer && this.state.isOffer === false) {
        this.setState({
          isOffer: true,
          message: BACK_TO_OFFER,
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else if (!isValidOffer && this.state.isOffer === true) {
        this.setState({
          isOffer: isValidOffer,
          message: OFFER_NOT_APPLIED.replace("{offerType}", type),
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else {
        this.setState({
          legArray
        });
      }
    } else {
      this.setState({
        legArray
      });
    }
    this.setEnabledAddReturn(legArray);
  };
  popUpContent = () => {
    const list = this.state.popupErrorMessages.map((error, index) => (
      <li key={index}>{error}</li>
    ));
    return <ul>{list}</ul>;
  };

  isOfferValid(legs) {
    return (
      this.props.isOfferFlow &&
      (legs[0].opportunityId || !!(legs.length === 1 && legs[0].emptyLegId))
    );
  }

  validateAircraftinContract(value) {
    let validated = true;
    if (
      (isLoggedIn(this.props.user) &&
        (this.props.isOfferFlow &&
          !this.state.isOffer &&
          isFspDirect(this.props.user))) ||
      (!this.props.isOfferFlow &&
        (isFSP(this.props.user) || isFspDirect(this.props.user)))
    ) {
      validated = this.state.availableAircraftIds.includes(value.id);
      this.setState({ showAircraftInContract: true });
    } else {
      this.setState({ showAircraftInContract: false });
    }

    return validated;
  }

  gatherPassengerInfo = (info, id) => {
    let editiedInfo = this.state.passengerInfo;
    editiedInfo[id] = info;
    this.setState({ passengerInfo: editiedInfo });
  }

  validateContract = () => {
    let value = false;
    if (
      isLoggedIn(this.props.user) &&
      !this.props.isOfferFlow &&
      (isFSP(this.props.user) || isFspDirect(this.props.user)) &&
      !this.state.accountId
    ) {
      value = true;
      this.setState({ contractValidation: FILL_CONTRACT_EXCEPTION });
    }
    return value;
  };

  getAllDates = () => {
    const { legArray } = this.state;
    const dates = [];
    legArray.forEach(leg => {
      if (leg.date) {
        dates.push(leg.date);
      }
    })
    return dates;
  }

  checkAllowAircraftSelect = () => {
    let value = false;
    if (
      !isLoggedIn(this.props.user) ||
      this.props.isOfferFlow ||
      !(isFSP(this.props.user) || isFspDirect(this.props.user)) ||
      this.state.selectedContract
    ) {
      value = true;
    }
    return value;
  };

  handleOnChangeTimePicker = (time, guid) => {
    const { offerObject } = this.props
    const isTimeValid = true; //moment(time, 'HH:mm', true).isValid();
    const timeVal = isTimeValid ? time : '';
    const legArray = [...this.state.legArray];
    let legToUpdate = legArray.find(leg => leg.guid === guid);
    // legToUpdate.hour = value;
    // const { minutes } = legToUpdate;
    // const newSelectedTime = value.concat(":", minutes);
    // legToUpdate.time = newSelectedTime;
    legToUpdate.time = timeVal
    legToUpdate.validation.errorOn.time = null;
    legToUpdate = calcDepartAndArrivalTime(legToUpdate);
    if (this.props.isOfferFlow) {
      const type = legArray[0].isEmptyLeg ? "Empty Leg" : "One Way";
      const { isValidOffer, reason, legIndex } = this.validateOffer(legArray);
      if (isValidOffer && this.state.isOffer === false) {
        this.setState({
          isOffer: true,
          message: BACK_TO_OFFER,
          legArray,
          selectedContract: false
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else if (!isValidOffer && this.state.isOffer === true) {
        let notAnOfferMessage = notValidOfferMessage(reason, legArray, legIndex, offerObject);
        notAnOfferMessage = notAnOfferMessage ? notAnOfferMessage : OFFER_NOT_APPLIED.replace("{offerType}", type);
        this.setState({
          isOffer: isValidOffer,
          message: notAnOfferMessage,
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else {
        this.setState({
          legArray
        });
      }
    } else {
      this.setState({ legArray });
    }
  };

  renderLeg = () => {
    let legArray = [...this.state.legArray];
    if (!this.props.isOfferFlow) {
      legArray = sortLegs(legArray);
    }
    legArray[0].prevLeg = null;
    const { offerObject, passengers } = this.props;

    const allowAircraftSelect = this.checkAllowAircraftSelect();
    // !isLoggedIn(this.props.user) ||
    // this.props.isOfferFlow ||
    // !(isFSP(this.props.user) || isFspDirect(this.props.user)) ||
    // this.state.selectedContract;
    return legArray.map((leg, index) => {
      const guid = leg.guid;
      const legId = leg.legId || leg.flightLegExtIdentifier
      return (
        <LegCard
          validateContract={this.validateContract}
          availableAircraftIds={
            !(this.state.isOffer && this.props.isOfferFlow) &&
              (isFSP(this.props.user) || isFspDirect(this.props.user))
              ? this.state.availableAircraftIds
              : []
          }
          identifier={index}
          shouldRemoveButton={
            leg.shouldRemoveButton === false
              ? false
              : this.state.legArray.length > 1
          }
          handleRemoveFlight={() => this.handleRemoveFlight(guid)}
          isContractSelected={this.state.accountId}
          selectedContract={this.state.selectedContract}
          allowAircraftSelect={allowAircraftSelect}
          key={guid}
          handleAirportSelect={(value, source) =>
            this.handleAirportSelect(value, source, guid)
          }
          isAirportsFetched={this.props.isAirportsFetched}
          visitedAirports={this.props.visitedAirports}
          fetchVisitedAirports={this.props.fetchVisitedAirports}
          aircraftsList={this.props.aircraftsList}
          leg={leg}
          handleDateChange={value => this.handleDateChange(value, guid)}
          handleDepartureHourChange={value =>
            this.handleDepartureHourChange(value, guid)
          }
          handleDepartureMinuteChange={value =>
            this.handleDepartureMinuteChange(value, guid)
          }
          handleOnChange={value =>
            this.handleOnChangeTimePicker(value, guid)
          }
          handleAircraftChange={value => this.handleAircraftChange(value, guid)}
          handleStepperChange={value => this.handleStepperChange(value, guid)}
          offerObject={index == 0 && offerObject ? offerObject : {}}
          isOfferFlow={this.props.isOfferFlow}
          isOffer={this.state.isOffer}
          showAircraftInContract={this.state.showAircraftInContract}
          allHighlightedDates={this.getAllDates()}
          isEditFlow={this.props.isEditFlow}
          passengers={passengers ? passengers[legId] : {}}
          gatherPassengerInfo={value => this.gatherPassengerInfo(value, legId)}
        />
      );
    });
  };
  isEmptyLeg = (legs, emptyLeg) => {
    let validation = {
      valid: true,
      reason: "",
      legIndex: null

    };

    if (legs.length != 1) {
      validation.valid = false;
      return validation;
    }
    if (legs[0].departureAirport.id !== emptyLeg.departureAirport.id) {
      validation.valid = false;
      return validation;
    }

    if (legs[0].arrivalAirport.id !== emptyLeg.arrivalAirport.id) {
      validation.valid = false;
      return validation;
    }

    if (legs[0].selectedAircraft.id !== emptyLeg.aircraftTypeId) {
      validation.valid = false;
      return validation;
    }

    const validFrom = parse(emptyLeg.validFrom, "yyy-MM-dd", new Date());
    const validTo = parse(emptyLeg.validTo, "yyy-MM-dd", new Date());
    const departTime = parse(legs[0].departTime, "yyy-MM-dd", new Date());
    if (legs[0].departTime !== "Invalid date" && !isWithinInterval(departTime, { start: validFrom, end: validTo })) {
      validation.valid = false;
      return validation;
    }
    return validation;
  };
  checkAircraftInContract = (legArray, availableAircraftIds) => {
    let legArrayTemp = legArray.map(leg => {
      if (
        leg.selectedAircraft &&
        availableAircraftIds.length > 0 &&
        !availableAircraftIds.includes(leg.selectedAircraft.id)
      ) {
        leg.validation.errorOn.aircraft = AIRCRAFT_NOT_IN_CONTRACT;
      } else if (
        leg.selectedAircraft &&
        availableAircraftIds.includes(leg.selectedAircraft.id)
      ) {
        leg.validation.errorOn.aircraft = "";
      }
      return leg;
    });
    return legArrayTemp;
  };

  validateOffer = legArray => {
    const { offerObject } = this.props;
    const { valid, reason, legIndex } =
      offerObject.type === "OneWay"
        ? isExtendedOneWayValid(true, legArray, offerObject)
        : this.isEmptyLeg(legArray, offerObject);

    return { isValidOffer: valid, reason, legIndex }
  };

  // All the onChange handlers which are setting state will place down till render func.

  handleContractChange = value => {
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
    let legArray = [...this.state.legArray];
    const contract = value;
    let availableAircraftIds = [];

    availableAircraftIds =
      contract && contract.value
        ? getAvailableAircrafts(contract.value.aircraftTypeDtos)
        : null;
    legArray = this.checkAircraftInContract(legArray, availableAircraftIds);

    this.setState({
      legArray,
      availableAircraftIds,
      selectedContract: value,
      accountId: value.value.id,
      isContractSelected: true,
      contractValidation: null,
      showAircraftInContract: true
    });
  };

  handleAirportSelect = (value, source, guid) => {
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
    const legArray = [...this.state.legArray];
    let legToUpdate = legArray.find(leg => leg.guid === guid);
    const type = legArray[0].isEmptyLeg ? "Empty Leg" : "One Way";
    if (source === "departure") {
      legToUpdate.departureAirport = { ...value };
      legToUpdate = calcFlightDuration(legToUpdate);
      legToUpdate = calcDepartAndArrivalTime(legToUpdate);
      this.setEnabledAddReturn(legArray);
      legToUpdate.validation.errorOn.departure = null;
    } else {
      legToUpdate.arrivalAirport = { ...value };
      legToUpdate = calcFlightDuration(legToUpdate);

      legToUpdate = calcDepartAndArrivalTime(legToUpdate);
      this.setEnabledAddReturn(legArray);
      legToUpdate.validation.errorOn.arrival = null;
    }
    legToUpdate.validation.errorOn.departureArrivalSame = null;
    if (this.props.isOfferFlow) {
      const { isValidOffer } = this.validateOffer(legArray);
      if (isValidOffer && this.state.isOffer === false) {
        this.setState({
          isOffer: true,
          message: BACK_TO_OFFER,
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else if (!isValidOffer && this.state.isOffer === true) {
        this.setState({
          isOffer: isValidOffer,
          message: OFFER_NOT_APPLIED.replace("{offerType}", type),
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else {
        this.setState({
          legArray
        });
      }
    } else {
      this.setState({ legArray });
    }
    this.setEnabledAddReturn(legArray);
  };

  handleDateChange = (date, guid) => {
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
    const { offerObject } = this.props
    const legArray = [...this.state.legArray];
    let legToUpdate = legArray.find(leg => leg.guid === guid);
    legToUpdate.date = format(date, dateFnsFormats.YYYY_MM_DD);
    legToUpdate.validation.errorOn.date = null;
    legToUpdate.validation.errorOn.minHoursException = null;
    legToUpdate = calcDepartAndArrivalTime(legToUpdate);
    if (this.props.isOfferFlow) {
      const type = legArray[0].isEmptyLeg ? "Empty Leg" : "One Way";
      const { isValidOffer, reason, legIndex } = this.validateOffer(legArray);
      if (isValidOffer && this.state.isOffer === false) {
        this.setState({
          isOffer: true,
          message: BACK_TO_OFFER,
          legArray,
          selectedContract: false
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else if (!isValidOffer && this.state.isOffer === true) {
        let notAnOfferMessage = notValidOfferMessage(reason, legArray, legIndex, offerObject);
        notAnOfferMessage = notAnOfferMessage ? notAnOfferMessage : OFFER_NOT_APPLIED.replace("{offerType}", type);

        this.setState({
          isOffer: isValidOffer,
          message: notAnOfferMessage,
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else {
        this.setState({
          legArray
        });
      }
    } else {
      this.setState({ legArray });
    }
  };

  handleDepartureHourChange = (value, guid) => {
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
    const legArray = [...this.state.legArray];
    let legToUpdate = legArray.find(leg => leg.guid === guid);
    legToUpdate.hour = value;
    const { minutes } = legToUpdate;
    const newSelectedTime = value.concat(":", minutes);
    legToUpdate.time = newSelectedTime;
    legToUpdate.validation.errorOn.time = null;
    legToUpdate = calcDepartAndArrivalTime(legToUpdate);
    if (this.props.isOfferFlow) {
      const type = legArray[0].isEmptyLeg ? "Empty Leg" : "One Way";
      const { isValidOffer } = this.validateOffer(legArray);
      if (isValidOffer && this.state.isOffer === false) {
        this.setState({
          isOffer: true,
          message: BACK_TO_OFFER,
          legArray,
          selectedContract: false
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else if (!isValidOffer && this.state.isOffer === true) {
        this.setState({
          isOffer: isValidOffer,
          message: OFFER_NOT_APPLIED.replace("{offerType}", type),
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else {
        this.setState({
          legArray
        });
      }
    } else {
      this.setState({ legArray });
    }
  };

  handleDepartureMinuteChange = (value, guid) => {
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
    const legArray = [...this.state.legArray];
    let legToUpdate = legArray.find(leg => leg.guid === guid);
    legToUpdate.minutes = value;
    const { hour } = legToUpdate;
    const newSelectedTime = hour.concat(":", value);
    legToUpdate.time = newSelectedTime;
    legToUpdate.validation.errorOn.time = null;
    legToUpdate = calcDepartAndArrivalTime(legToUpdate);
    if (this.props.isOfferFlow) {
      const type = legArray[0].isEmptyLeg ? "Empty Leg" : "One Way";
      const { isValidOffer } = this.validateOffer(legArray);
      if (isValidOffer && this.state.isOffer === false) {
        this.setState({
          isOffer: true,
          message: BACK_TO_OFFER,
          legArray,
          selectedContract: false
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else if (!isValidOffer && this.state.isOffer === true) {
        this.setState({
          isOffer: isValidOffer,
          message: OFFER_NOT_APPLIED.replace("{offerType}", type),
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else {
        this.setState({
          legArray
        });
      }
    } else {
      this.setState({ legArray });
    }
  };

  handleAircraftChange = (value, guid) => {
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
    const legArray = [...this.state.legArray];
    let legToUpdate = legArray.find(leg => leg.guid === guid);
    const type = legArray[0].isEmptyLeg ? "Empty Leg" : "One Way";

    const legId = legToUpdate.legId || legToUpdate.flightLegExtIdentifier

    if (this.props.isEditFlow) {
      const { passengers } = this.props;
      const passengerInfo = passengers[legId];
      if (passengerInfo && passengerInfo.passengers && value.maxNumberOfPassengers < passengerInfo.passengers.length) {
        this.setState({ message: PASSENGER_EXCEED_AIRCRAFT_CHANGE });
        window.UIkit.modal("#OutFromOffer").show();
        return;
      }
    }
    if (!this.validateAircraftinContract(value) && this.state.accountId) {
      legToUpdate.validation.errorOn.aircraft = AIRCRAFT_NOT_IN_CONTRACT;
    } else if (legToUpdate.validation.errorOn.aircraft) {
      legToUpdate.validation.errorOn.aircraft = "";
    }
    legToUpdate.selectedAircraft = value;
    if (value.maxNumberOfPassengers < legToUpdate.passengersCount) {
      legToUpdate.passengersCount = parseInt(value.maxNumberOfPassengers);
      legToUpdate.numberOfPassenger = parseInt(value.maxNumberOfPassengers);
    }
    legToUpdate = calcFlightDuration(legToUpdate);

    legToUpdate = calcDepartAndArrivalTime(legToUpdate);

    if (this.props.isOfferFlow) {
      const { isValidOffer } = this.validateOffer(legArray);
      if (isValidOffer && this.state.isOffer === false) {
        this.setState({
          isOffer: true,
          message: BACK_TO_OFFER,
          legArray,
          selectedContract: false
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else if (!isValidOffer && this.state.isOffer === true) {
        this.setState({
          isOffer: isValidOffer,
          message: OFFER_NOT_APPLIED.replace("{offerType}", type),
          legArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else {
        this.setState({
          legArray
        });
      }
    } else {
      this.setState({
        legArray
      });
    }
    this.setEnabledAddReturn(legArray);

  };
  handleStepperChange = (value, guid) => {
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, true);
    const legArray = [...this.state.legArray];
    let legToUpdate = legArray.find(leg => leg.guid === guid);
    legToUpdate.passengersCount = value;
    legToUpdate.validation.errorOn.passengersCount = null;
    this.setState({ legArray });
  };
  handleRemoveFlight = guid => {
    const tempArray = [...this.state.legArray];
    const type = tempArray[0].isEmptyLeg ? "Empty Leg" : "One Way";
    const index = tempArray
      .map(leg => {
        return leg.guid;
      })
      .indexOf(guid);
    tempArray.splice(index, 1);
    if (tempArray.length === 1) {
      tempArray[0].prevLeg = null;
    }
    if (this.props.isOfferFlow) {
      const { isValidOffer } = this.validateOffer(tempArray);
      if (isValidOffer && this.state.isOffer === false) {
        this.setState({
          isOffer: true,
          message: BACK_TO_OFFER,
          legArray: tempArray,
          selectedContract: false
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else if (!isValidOffer && this.state.isOffer === true) {
        this.setState({
          isOffer: isValidOffer,
          message: OFFER_NOT_APPLIED.replace("{offerType}", type),
          legArray: tempArray
        });
        window.UIkit.modal("#OutFromOffer").show();
      } else {
        this.setState({
          legArray: tempArray
        });
      }
    } else {
      this.setState({
        legArray: tempArray
      });
    }
    // window.UIkit.modal("#removeLegPopup").hide();
    this.setEnabledAddReturn(tempArray);
  };

  getPreferredAccount = userProps => {
    const preferredAccount = userProps.accounts.filter(
      account => account.typeId === USER_ACCESS_TYPES.FSP && account.isPreferred
    );
    return preferredAccount[0];
  };
  getErrorArray = (validMultiLegs, modalMessage) => {
    let messages = [];
    if (modalMessage.length >= 1) {
      messages = modalMessage;
    }
    if (validMultiLegs.length >= 1) {
      messages = messages.concat(validMultiLegs);
    }
    return messages;
  };
  handleSend = () => {
    let legArray = [...this.state.legArray];
    if (!this.props.isOfferFlow) {
      legArray = sortLegs(legArray);
    }
    const { isError, validLegs, modalMessage } = validateLegs(legArray, this.props.isEditFlow);
    if (!this.validateContract()) {
      if (isError) {
        this.setState({
          legArray: validLegs
        });
      } else {
        const validMultiLegs = validateMultiLeg(legArray);
        const errorMessages = this.getErrorArray(validMultiLegs, modalMessage);
        if (errorMessages.length >= 1) {
          this.setState(
            {
              popupErrorMessages: errorMessages
            },
            () => {
              window.UIkit.modal("#multiLegError").show();
            }
          );
        } else if (this.props.isOfferFlow) {
          this.sendFinalRequest();
        } else {
          this.quoteConfirmPopup.show();
        }
      }
    }
  };

  handleValidationModalClose = id => {
    window.UIkit.modal("#" + id).hide();
  };
  handleOpsRestrictionsClose = id => {
    window.UIkit.modal("#" + id).hide();
    this.props.resetFlightErrorMessages();
  };

  sendFinalRequest = () => {
    this.quoteConfirmPopup.hide();
    setTimeout(() => {
      // scrollToSection(500, 'top');
      scrollToTop();
    }, 1000)
    const userProps = this.props.user;
    let accountId = null;
    let legArray = [...this.state.legArray];
    if (!this.props.isOfferFlow) {
      legArray = sortLegs(legArray);
    }
    if (this.props.isOfferFlow) {
      if (this.state.isOffer && (isFspDirect(userProps) || isVJ2(userProps))) {
        accountId = getVJAccountId(userProps);
        this.props.validateAllFlights(this.state.legArray, accountId, true);
      } else if (!this.state.isOffer) {
        if (isFspDirect(userProps)) {
          accountId = this.getPreferredAccount(userProps).id;
          this.props.validateAllFlights(legArray, this.state.accountId, false);
        } else if (isVJ2(userProps)) {
          this.props.requestAllQuote(legArray, userProps);
        }
      }
    } else if (this.props.isEditFlow) {
      const { itineraryDetails } = this.props;
      const { passengerInfo } = this.state;
      if (isFSP(userProps) || isFspDirect(userProps)) {
        this.props.validateAllFlights(
          legArray,
          this.state.accountId,
          false,
          true,
          itineraryDetails,
          passengerInfo,
        );
      }
    } else if (isFSP(userProps) || isFspDirect(userProps)) {
      this.props.validateAllFlights(legArray, this.state.accountId);
    } else if (isGuest(userProps)) {
      this.props.createCompany(legArray);
    } else {
      this.props.requestAllQuote(legArray, userProps);
    }
  };

  render() {
    const { legArray } = this.state;
    const { offerObject, itineraryDetails, isEditFlow } = this.props;
    const { user } = this.props;
    let confirmationMessage = "";
    let BUTTON_TEXT = "";
    const classNames =
      legArray.length >= 1
        ? "uk-button vistajet_button_default"
        : `uk-button vistajet_button_default ${this.disableButtonClass}`;
    if (isFSP(user) || isFspDirect(user)) {
      BUTTON_TEXT = "Request Flight";
      confirmationMessage = "Are you happy to confirm your purchase?";
    } else {
      BUTTON_TEXT = "Request Quote";
      confirmationMessage =
        "Are you sure you would like to proceed with this request?";
    }
    if (this.props.isOfferFlow && this.state.isOffer) {
      BUTTON_TEXT = "Request Quote";
    } else if (this.props.isOfferFlow && !this.state.isOffer) {
      BUTTON_TEXT = isFspDirect(user) ? "Request Flight" : "Request Quote";
    } else if (isEditFlow) {
      BUTTON_TEXT = "Update Request";
    }
    return (
      <div>
        <div className="vistajet_bs_body">
          {this.props.isValidateFlightLoading ||
            this.props.isRequestFlightLoading ? (
              <div className="vistajet_spinner">
                <SpinnerLoader />
              </div>
            ) : null}
          {itineraryDetails && itineraryDetails.orderId && (
            <div> OrderId: {itineraryDetails.orderId} </div>
          )}
          {!(this.props.isOfferFlow && this.state.isOffer) &&
            (isFSP(this.props.user) || isFspDirect(this.props.user)) ? (
              <div className="uk-width-1-1">
                <ContractSelector
                  handleContractChange={this.handleContractChange}
                  updateContractSelected={this.props.updateContractSelected}
                  user={this.props.user}
                  selectedContract={this.state.selectedContract}
                  isOfferFlow={this.props.isOfferFlow}
                  isOffer={this.state.isOffer}
                  itineraryAccountId={this.props.itineraryAccountId}
                />
                <p className="vistajet_valid_error uk-text-center">
                  {this.state.contractValidation}
                </p>
              </div>
            ) : null}
          {this.props.isOfferFlow && this.state.isOffer ? (
            <div>
              <div className="vistajet_operated_by_column uk-margin-bottom">
                Operated by: <span>{offerObject.operatingCompanyName}</span>
              </div>
              <div class="vistajet_tooltip">
                <i class="vistajet_tooltip_icon">
                  <span class="vistajet_tooltip_box">
                    {this.props.offerToolTipInfo.map(element => (
                      <div class="uk-clearfix">
                        <div class="uk-float-left">
                          <span>
                            {element.name}: {element.value}
                          </span>
                        </div>
                      </div>
                    ))}
                  </span>
                </i>
              </div>
            </div>
          ) : null}

          {this.renderLeg()}
        </div>
        {!this.props.hideButtons && (
          <div>
            <div className="vistajet_button_column">
              <Button
                classNames={"uk-button vistajet_button_default"}
                disabled={this.state.disableReturn}
                onClickHandler={() => this.handleAddFlight(true)}
                label={"Add Return"}
              />
              <Button
                classNames={classNames}
                disabled={false}
                onClickHandler={() => this.handleAddFlight(false)}
                label={"Add Flight"}
              />
            </div>
            <div className="vistajet_bs_foot">
              <Button
                classNames={"uk-width-1-1 vistajet_button_primary"}
                onClickHandler={this.handleSend}
                disabled={false}
                label={BUTTON_TEXT}
              />

              <BulletPopup
                id={"multiLegError"}
                content={this.state.popupErrorMessages}
                handleClose={this.handleValidationModalClose}
              />

              <BulletPopup
                id={"validationPopUp"}
                messages={this.props.validateFlightMessages}
                legs={this.state.legArray}
                isProposalOrRestriction={this.props.isProposalOrRestriction}
                handleClose={this.handleOpsRestrictionsClose}
              />
            </div>
          </div>
        )}
        <ConfirmationPopUp
          ref={(quoteConfirmPopup) => { this.quoteConfirmPopup = quoteConfirmPopup; }}
          title="Confirmation"
          id="quoteConfirmationPopup"
          leftButtonContent="No"
          rightButtonContent="Yes"
          onLeftButtonClick={() =>
            this.quoteConfirmPopup.hide()
          }
          onRightButtonClick={this.sendFinalRequest}
          content={confirmationMessage}
        />
        <DialogWrapper id={"OutFromOffer"} content={this.state.message} />
      </div>
    );
  }
}
export default LegListing;
