import axios from "axios";
import { USER_REDUCER } from "../../constants/localStorageKeys";
import {
  requestFlightInit,
  requestFlightSuccess,
  requestFlightFail
} from "../../store/actions/PAFActions";
import { REQUEST_FLIGHT_2_0_URL } from "../../configs/constants";
import status from "../../configs/status";
import createGuid from "../../utils/guidUtils";
import { handleServerErrors } from "../../utils/sourceUtils";
import { parseString } from "../../utils/parserUtils";
import {
  BAD_REQUEST,
  TIMEOUT_CODE,
  UNAUTHORIZED,
} from "../../constants/ServerCodeConstants";
import {
  normalizeLegStatus,
  normalizeFlightLegIdentifiers,
} from "../../normalizers/PAFNormalizer";
import { isBroker, isSimulateUser } from "../../utils/userUtils";
import { fetchOrderIdByLeg, startedUpdatingPassenger, finishedUpdatingPassenger } from "../actions";
import Source from './ItineraryPassengerSource';
import SettingsSource from './UserSettingsSource';
import { getFromLocal, saveToLocal } from "../../utils/cache";
import { guaranteeArray } from "../../utils/arrayUtils";
import { mergePassportbyPax } from '../../utils/passportUtils';
import { clone } from '../../utils/Helpers';
import { generateFrequentPaxList } from '../../utils/passengerUtils';

const dispatchPassengerEdit = (passengerInfo) => dispatch => {
  dispatch(startedUpdatingPassenger());
  (Object.keys(passengerInfo) || {}).forEach(id => {
    const { params, addedPax, passengerId } = passengerInfo[id];
    Source.managePassengers(params, addedPax, passengerId)
      .then(() => {
        const settingsInfo = { frequentPax: generateFrequentPaxList(addedPax) };
        SettingsSource.writeUserSettings(settingsInfo).then(() => {
          const paxToCache = addedPax.map((passengerParam) => {
            const passenger = clone(passengerParam);
            passenger.leadPassenger = false;
            (passenger.passports || []).forEach((passport) => {
              passport.isLegPassport = false; // eslint-disable-line no-param-reassign
              passport.isUpdated = false; // eslint-disable-line no-param-reassign
            });
            return passenger;
          });
          let freqPax = getFromLocal('frequentPax', true);
          freqPax = guaranteeArray(freqPax);
          const mergePax = mergePassportbyPax(freqPax, paxToCache);
          saveToLocal('frequentPax', mergePax, true);
          dispatch(finishedUpdatingPassenger());
        });
      })
      .catch(() => { });
  });
}


const planFSPFlights = (legs, accountId, isEdit = false, order = {}, passengers = {}) => dispatch => {
  dispatch(requestFlightInit());

  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;
  const orderExtIdentifier = createGuid();
  const broker = isBroker(user) || isSimulateUser(user);
  let contactXml = '';

  if (broker) {
    const {
      firstName,
      lastName,
      username,
      personPhoneNumbers,
      phoneNumber
    } = user;
    const number = personPhoneNumbers
      ? personPhoneNumbers.phoneNumber
      : phoneNumber;
    contactXml = '<contact>' +
      `<userFirstName>${firstName}</userFirstName>` +
      `<userLastName>${lastName}</userLastName>` +
      `<userEmail>${username}</userEmail>` +
      `<userPhoneNumber>${number || ''}</userPhoneNumber>` +
      '</contact>';
  }

  const orderXml =
    "<flightRequestForCustomer>" +
    (isEdit ? ('<referenceOrderId>{orderId}</referenceOrderId>'
      + `<quoteId>{orderExtIdentifier}</quoteId>`)
      : `<quoteId>${orderExtIdentifier}</quoteId>`) +
    (broker ? contactXml : '') +
    `<account>${accountId}</account>` +
    "<flightLegs>" +
    "{flightLegs}" +
    "</flightLegs>" +
    "</flightRequestForCustomer>";

  const legsXml =
    "<flightLeg>" +
    "<isCancelled>{isCancelled}</isCancelled>" +
    (isEdit ? '<flightLegId>{legId}</flightLegId>' : '') +
    "<flightLegExtIdentifier>{flightLegExtIdentifier}" +
    "</flightLegExtIdentifier>" +
    "<departureAirportId>{departureAirportId}</departureAirportId>" +
    "<arrivalAirportId>{arrivalAirportId}</arrivalAirportId>" +
    "<numberOfPassenger>{numberOfPassenger}</numberOfPassenger>" +
    "<requestedAircraftTypeId>{requestedAircraftTypeId}" +
    "</requestedAircraftTypeId>" +
    "<aircraftType>{aircraftType}</aircraftType>" +
    "<requestedAircraftTypeName>{requestedAircraftTypeName}" +
    "</requestedAircraftTypeName>" +
    "<departureTime>{departureTime}</departureTime>" +
    "<version>{version}</version>" +
    "<arrivalTime>{arrivalTime}</arrivalTime>" +
    "</flightLeg>";

  const options = {
    headers: {
      "Content-Type": "application/xml"
    }
  };

  let totalLegs = "";
  let flightLeg = "";
  legs.forEach(leg => {
    const {
      guid,
      departureAirport,
      arrivalAirport,
      passengersCount,
      selectedAircraft,
      departTime,
      arrivalTime,
      legId,
      flightLegExtIdentifier,
      version
    } = leg;
    const { id, displayName, code } = selectedAircraft;
    flightLeg = legsXml
      .replace("{isCancelled}", "false")
      .replace("{departureAirportId}", departureAirport.id)
      .replace("{arrivalAirportId}", arrivalAirport.id)
      .replace("{numberOfPassenger}", passengersCount)
      .replace("{aircraftType}", code)
      .replace("{requestedAircraftTypeId}", id)
      .replace("{departureTime}", departTime)
      .replace("{arrivalTime}", arrivalTime);

    if (isEdit) {
      flightLeg = flightLeg
        .replace('{flightLegExtIdentifier}', flightLegExtIdentifier || guid)
        .replace("{requestedAircraftTypeName}", code)
        .replace('{legId}', legId || '')
        .replace('{version}', version);
    }
    else {
      flightLeg = flightLeg
        .replace("{requestedAircraftTypeName}", displayName)
        .replace("{flightLegExtIdentifier}", guid)
        .replace('{version}', '0'); //0 for insertion
    }
    totalLegs += flightLeg;
  });

  let payload = orderXml.replace("{flightLegs}", totalLegs);
  if (isEdit) {
    payload = payload
      .replace('{orderExtIdentifier}', order.orderExtIdentifier || '')
      .replace('{orderId}', order.orderId || '')
  }

  axios
    .post(REQUEST_FLIGHT_2_0_URL.replace("{token}", token), payload, options)
    .then(response => {
      if (response.status === status.OK) {
        parseString(response.data, (parseErr, result) => {
          if (parseErr) {
            dispatch(requestFlightFail(handleServerErrors(BAD_REQUEST)));
          } else if (result.ResponseBody.responseHeader.isError) {
            const error = result.ResponseBody.responseHeader;
            dispatch(requestFlightFail(handleServerErrors(error.statusCode)));
          } else {
            const flightDetails = normalizeFlightLegIdentifiers(
              result.ResponseBody.flightLegs
            );
            if (!isEdit) {
              dispatch(fetchOrderIdByLeg('flightLegExtIdentifier', flightDetails[0]));
            }
            if (Object.keys(passengers).length) {
              dispatch(dispatchPassengerEdit(passengers));
            }
            dispatch(
              requestFlightSuccess({
                flightDetails,
                legArray: legs,
                status: normalizeLegStatus(result.ResponseBody.flightLegs),
              })
            );
          }
        });
      } else {
        dispatch(requestFlightFail(handleServerErrors(BAD_REQUEST)));
      }
    })
    .catch(err => {
      if (err.code === TIMEOUT_CODE.status) {
        dispatch(requestFlightFail(handleServerErrors(TIMEOUT_CODE.code)));
      } else if (!err.status) {
        dispatch(requestFlightFail(handleServerErrors()));
      } else if (+err.status === UNAUTHORIZED) {
        dispatch(requestFlightFail(handleServerErrors(UNAUTHORIZED)));
      } else {
        parseString(err.response.text, (error, result) => {
          if (error) {
            dispatch(requestFlightFail(handleServerErrors(BAD_REQUEST)));
          }
          else if (result.error.code) {
            dispatch(requestFlightFail(handleServerErrors(result.error.code)));
          } else {
            dispatch(requestFlightFail(handleServerErrors(BAD_REQUEST)));
          }
        });
      }
    });
};

export default planFSPFlights;
