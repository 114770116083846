import * as types from '../actionTypes';

const initialState = {
  creditCards: {},
  isLoading: false,
  isDeleted: false,
  isAdded: false,
  isError: false,
  error: '',
  message: '',
};

export const appendCreditCard = (creditCards = {}, newCard) => {
  let allCards = Object.assign({}, creditCards);
  if (Object.keys(allCards).length === 0) {
    allCards = {
      content: {},
      ids: [],
      defaultId: newCard && newCard.alias,
    };
  } else if (allCards.ids && allCards.ids.length === 0) {
    allCards.defaultId = newCard && newCard.alias;
  }
  if (newCard.isDefault && allCards.ids.length > 0) {
    allCards.defaultId = newCard.alias;
    allCards.ids.map((cardId) => {
      allCards.content[cardId].isDefault = false;
    });
  }
  allCards.content[newCard.alias] = newCard;
  allCards.ids.push(newCard.alias);
  return allCards;
};

const updateCreditCard = (creditCards = {}, updatedCard) => {
  const allCards = Object.assign({}, creditCards);
  const updatedCardAlias = updatedCard.alias;
  Object.assign(allCards.content[updatedCardAlias], updatedCard);
  if (updatedCard.isDefault) {
    allCards.defaultId = updatedCardAlias;
  }
  return allCards;
};

const removeCreditCard = (creditCards = {}, deletedCardAlias) => {
  const allCards = Object.assign({}, creditCards);
  delete allCards.content[deletedCardAlias];
  const index = allCards.ids.indexOf(deletedCardAlias);
  if (index > -1) {
    allCards.ids.splice(index, 1);
  }
  return allCards;
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_CREDIT_CARD_INIT: {
      return Object.assign({}, state, {
        creditCards: state.creditCards,
        isLoading: true,
        isDeleted: false,
        isAdded: false,
        isError: false,
        error: '',
        message: '',
      });
    }
    case types.ADD_CREDIT_CARD_SUCCESS: {
      return Object.assign({}, state, {
        creditCards: appendCreditCard(state.creditCards, action.data.response),
        isLoading: false,
        isDeleted: false,
        isAdded: true,
        isError: false,
        error: '',
        message: action.data.message,
      });
    }
    case types.UPDATE_CREDIT_CARD_SUCCESS: {
      return Object.assign({}, state, {
        creditCards: updateCreditCard(state.creditCards, action.data.response),
        isLoading: false,
        isDeleted: false,
        isAdded: true,
        isError: false,
        error: '',
        message: action.data.message,
      });
    }
    case types.ADD_CREDIT_CARD_FAIL: {
      return Object.assign({}, state, {
        creditCards: state.creditCards,
        isLoading: false,
        isDeleted: false,
        isAdded: false,
        isError: true,
        error: action.data,
      });
    }
    case types.GET_CREDIT_CARD_LIST_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        isDeleted: false,
        isAdded: false,
        error: '',
      });
    }
    case types.GET_CREDIT_CARD_LIST_SUCCESS: {
      return Object.assign({}, state, {
        creditCards: action.data,
        isLoading: false,
      });
    }
    case types.GET_CREDIT_CARD_LIST_FAIL: {
      return Object.assign({}, state, {
        isError: true,
        error: action.data,
      });
    }
    case types.DELETE_CREDIT_CARD_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        isDeleted: false,
        isAdded: false,
        isError: false,
        error: '',
        message: '',
      });
    }
    case types.DELETE_CREDIT_CARD_SUCCESS: {
      return Object.assign({}, state, {
        creditCards: removeCreditCard(state.creditCards, action.data.response),
        isLoading: false,
        isDeleted: true,
        isError: false,
        error: '',
        message: action.data.message,
      });
    }
    case types.DELETE_CREDIT_CARD_FAILURE: {
      return Object.assign({}, state, {
        creditCards: state.creditCards,
        isLoading: false,
        isDeleted: false,
        isError: false,
        error: '',
        message: '',
      });
    }
    case types.CLEAR_CREDIT_CARD_STATE: {
      return Object.assign({}, state, initialState);
    }
    default:
      return state;
  }
}
