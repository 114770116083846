import * as types from '../actionTypes';

export const initialState = {
  token: null,
  isTokenLoading: false,
  isTokenError: false,
  tokenError: '',
  isRegistrationTokenLoading: false,
  adminToken: null,
  isadminTokenLoading: false,
  isadminTokenError: false,
  adminTokenError: '',
  isTokenValid: false,
  isPasswordChange: false,
  isTokenAuthLoading: false,
  isTokenAuthError: false,
  tokenAuthError: '',
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TOKEN_INIT: {
      return Object.assign({}, state, {
        token: null,
        isTokenLoading: true,
        isTokenError: false,
        tokenError: '',
      });
    }
    case types.FETCH_TOKEN_SUCCESS: {
      return Object.assign({}, state, {
        token: action.data,
        isTokenLoading: false,
        isTokenError: false,
        tokenError: '',
      });
    }
    case types.FETCH_TOKEN_FAIL: {
      return Object.assign({}, state, {
        token: null,
        isTokenLoading: false,
        isTokenError: true,
        tokenError: action.data,
      });
    }
    case types.FETCH_REGISTRATION_TOKEN_INIT: {
      return Object.assign({}, state, {
        token: null,
        isRegistrationTokenLoading: true,
        isTokenError: false,
        tokenError: '',
      });
    }
    case types.FETCH_REGISTRATION_TOKEN_SUCCESS: {
      return Object.assign({}, state, {
        token: action.data,
        isRegistrationTokenLoading: false,
        isTokenError: false,
        tokenError: '',
      });
    }
    case types.FETCH_REGISTRATION_TOKEN_FAIL: {
      return Object.assign({}, state, {
        token: null,
        isRegistrationTokenLoading: false,
        isTokenError: true,
        tokenError: action.data,
      });
    }
    case types.ADMIN_FETCH_TOKEN_INIT: {
      return Object.assign({}, state, {
        adminToken: null,
        isadminTokenLoading: true,
        isadminTokenError: false,
        adminTokenError: '',
      });
    }
    case types.ADMIN_FETCH_TOKEN_SUCCESS: {
      return Object.assign({}, state, {
        adminToken: action.data,
        isadminTokenLoading: false,
        isadminTokenError: false,
        adminTokenError: '',
      });
    }
    case types.ADMIN_FETCH_TOKEN_FAIL: {
      return Object.assign({}, state, {
        adminToken: null,
        isadminTokenLoading: false,
        isadminTokenError: true,
        adminTokenError: action.data,
      });
    }
    case types.AUTHENTICATE_TOKEN_INIT: {
      return Object.assign({}, state, {
        isTokenValid: false,
        isPasswordChange: false,
        isTokenAuthLoading: true,
        isTokenAuthError: false,
        tokenAuthError: '',
      });
    }
    case types.AUTHENTICATE_TOKEN_SUCCESS: {
      return Object.assign({}, state, {
        isTokenLoading: false,
        isadminTokenLoading: false,
        isTokenValid: action.data.isTokenValid,
        isPasswordChange: action.data.mustChangePassword,
        isTokenAuthLoading: false,
        isTokenAuthError: false,
        tokenAuthError: '',
      });
    }
    case types.AUTHENTICATE_TOKEN_FAIL: {
      return Object.assign({}, state, {
        isTokenValid: false,
        isPasswordChange: false,
        isTokenAuthLoading: false,
        isTokenAuthError: true,
        tokenAuthError: action.data,
      });
    }
    default:
      return state;
  }
}
