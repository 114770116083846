import React, { Component } from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import createFilterOptions from "react-select-fast-filter-options";
import "react-virtualized-select/styles.css";
import "./react-select.css";
import MessagePopUp from '../../shared/MessagePopUp';
import { convertToTitleCase } from '../../../utils/stringUtils';
import { CITY_ALREADY_ADDED } from '../../../constants/MessageConstants';
import SpinnerLoader from '../../shared/spinnerLoader';
import dirtystateSingleTon from '../../../validators/validateDirty';
import SPAConstants from '../../../constants/SPAConstants';
import cities from '../../../constants/CityConstants';

const options = cities.map((city) => {
  return {
    value: city.placeId,
    label: convertToTitleCase(`${city.cityName}, ${city.countryName}`)
  }
});
const filterOptions = createFilterOptions({
  options
});
export default class PushNotificationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: null,
      selectedCities: {},
      selectedCitiesDisplayNames: [],
      notifications: {},
      errors: {},
      disableToggle: false,
      popupMessage: 'Your Request cannot be processed this time',
      isFieldsChanged: false,
    };
    this.checkFormated = false;
    this.firstThreeCities = [];
    this.notificationOldState = {};
    this.notificationOldIdsState = {};
    this.citiesOldState = {};
    this.citiesOldIdsState = {};
    this.toggleOldState = null;
  }

  componentDidMount() {
    const { fetchPushNotification } = this.props;
    const { user } = this.props.user;
    fetchPushNotification(user.userToken).then(() => {
      this.populateValues();
    }, this.handleApiErrors);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.notifications !== nextProps.notifications
        || this.props.cities !== nextProps.cities)
      && nextProps.isPushNotificationUpdated
    ) {
      this.props.goToTabSelection(0);
    }
    if (this.props.pushNotificationError !== nextProps.pushNotificationError
      && nextProps.isPushNotificationError) {
      window.UIkit.modal('#PushNotificationErrorPopUp').show();
    }
  }

  populateValues = () => {
    const { notifications, cities } = this.props;
    const { disablePushNotificationCities } = this.props.user;
    this.notificationOldState = JSON.parse(JSON.stringify(notifications));
    this.citiesOldState = (cities && Object.keys(cities))
      ? JSON.parse(JSON.stringify(cities))
      : {
        content: {},
        ids: [],
      };
    this.toggleOldState = disablePushNotificationCities;
    this.setState({
      notifications: Object.assign({}, notifications),
      selectedCities: (cities && Object.keys(cities))
        ? Object.assign({}, cities)
        : {
          content: {},
          ids: [],
        },
      disableToggle: disablePushNotificationCities,
    });
  }

  handleApiErrors = (err) => {
    this.setState({
      popupMessage: err,
    });
  };

  getCityDisplayName = (id) => {
    let displayName = '';
    cities.some((city) => {
      if (city.placeId === id) {
        displayName = convertToTitleCase(`${city.cityName}, ${city.countryName}`);
        return true;
      }
      return false;
    });
    return displayName;
  }

  isCityAdded = id => {
    const { selectedCities } = this.state;
    let isAdded = false;
    isAdded = selectedCities.ids.some((cityId) => {
      return (cityId === id && selectedCities.content[cityId].enabled);
    });
    return isAdded;
  }

  handleCityChange = value => {
    this.setState({
      city: value,
    });
  }

  handleCitySelectChange = value => {
    const { selectedCities } = this.state;
    const cityObj = Object.assign({}, selectedCities);
    if (!this.isCityAdded(value.value)) {
      /* Preventing state mutation */
      const ids = [...cityObj.ids];
      if (!cityObj.ids.some((id) => id === value.value)) {
        ids.push(value.value);
        Object.assign(cityObj, {ids});
        const newCityObj = {
          [value.value]: {
            cityId: value.value,
            enabled: true,
          },
        };
        cityObj.content = Object.assign({}, cityObj.content, newCityObj);
      } else {
        cityObj.content[value.value].enabled = true;
      }
      /* Preventing state mutation */
      this.setState({
        selectedCities: Object.assign({}, cityObj),
      }, () => {
        this.compareOldAndNewValues();
      });
    } else {
      const res = {
        errors: {},
      };
      res.isError = true;
      res.errors.city = CITY_ALREADY_ADDED;
      this.setState({
        errors: res,
      });
    }
  }

  handleNotificationRadioCheck = id => {
    const { notifications } = this.state;
    notifications.content[id].enabled = !notifications.content[id].enabled;
    this.setState({
      notifications
    }, () => {
      this.compareOldAndNewValues();
    });
  }

  removeSelectedCity = (id, index) => {
    const { selectedCities } = this.state;
    selectedCities.content[id].enabled = false;
    this.setState({
      selectedCities: Object.assign({}, selectedCities),
    }, () => {
      this.compareOldAndNewValues();
    });
  }

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  removeCityError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors.city = null;
      remErrors.isError = false;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  }

  compareNotificationIds = (
    newNotificationState,
    oldNotificationState,
  ) => {
    let isChanged = false;
    if (newNotificationState.ids.length !== oldNotificationState.ids.length) {
      isChanged = true;
      return isChanged;
    } else {
      newNotificationState.ids.some((newId) => {
        if (newNotificationState.content[newId].enabled
            !== oldNotificationState.content[newId].enabled) {
          isChanged = true;
          return;
        }
      });
    }
    return isChanged;
  }

  compareCityids = (newCitiesState, oldCitiesState) => {
    let isChanged = false;
    if (newCitiesState.ids.length !== oldCitiesState.ids.length) {
      isChanged = true;
      return isChanged;
    } else {
      newCitiesState.ids.some((newId) => {
        if (newCitiesState.content[newId].enabled
            !== oldCitiesState.content[newId].enabled) {
          isChanged = true;
          return;
        }
      });
    }
    return isChanged;
  }

  compareOldAndNewValues = () => {
    const {
      selectedCities,
      notifications,
      disableToggle,
      isFieldsChanged,
    } = this.state;
    const newState = {
      selectedCities,
      notifications,
      disableToggle,
    };

    if (
      (this.compareNotificationIds(notifications, this.notificationOldState)
       || this.compareCityids(selectedCities, this.citiesOldState)
       || newState.disableToggle !== this.toggleOldState
      )
      && !isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, true);
      this.setState({
        isFieldsChanged: true,
      });
    } else if (
      (!this.compareNotificationIds(notifications, this.notificationOldState)
        && !this.compareCityids(selectedCities, this.citiesOldState)
        && newState.disableToggle === this.toggleOldState
      )
      && isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
      this.setState({
        isFieldsChanged: false,
      });
    }
  }

  redirectToHome = () => {
    window.location = '/';
  };

  closePopup = (id) => {
    window.UIkit.modal(`#${id}`).hide();
  }

  toggleDisablePreferredCities = () => {
    this.setState({
      disableToggle: !this.state.disableToggle,
    }, () => {
      this.compareOldAndNewValues();
    });
  }

  updateNotifications = () => {
    const { updateNotifications } = this.props;
    const { userToken } = this.props.user.user;
    const { notifications, selectedCities, disableToggle } = this.state;
    updateNotifications(userToken, notifications, selectedCities, disableToggle)
      .then(() => {dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false)}, this.handleApiErrors);
  }

  renderNotificationOptions = () => {
    const { notifications } = this.state;
    if (notifications &&
      Object.keys(notifications).length > 0 &&
      notifications.ids
    ) {
      return notifications.ids.map((id, index) => {
        if (id !== 4) {
          return (
            <div className="uk-width-1-2@s uk-margin-top vistajet_userprofile_notification_checkboxes"> 
              <div className="uk-grid" data-uk-grid> 
              <div className="uk-width-1-5">
              <label className="vistajet_radio">
                <input
                  name="radio"
                  type="checkbox"
                  checked={notifications.content[id].enabled}
                  onClick={() => this.handleNotificationRadioCheck(id)}
                />
                <span />                
              </label>
              </div>
              <div className="uk-width-4-5 uk-padding-remove-left vistajet_displayname_container">
              <span className="vistajet_displayName" style={{marginBottom: '15px'}}>
                  {notifications.content[id].displayName}
                </span>
            </div>
            </div>
            </div>
          )
        }
      });
    }
  }

  renderChips = () => {
    const { selectedCities } = this.state;
    if (selectedCities && Object.keys(selectedCities).length > 0 && selectedCities.ids && selectedCities.ids.length > 0) {
      return selectedCities.ids.map((cityChipId, index) => {
        return (
          selectedCities.content[cityChipId].enabled && (
            <div className="md-chip" key={index}>
                <span>{this.getCityDisplayName(cityChipId)}</span>
                <button
                  type="button"
                  className="md-chip-remove"
                  onClick={() => this.removeSelectedCity(cityChipId, index)}
                >
                </button>
            </div>
          )
        );
      })
    }
  }

  renderPushNotificationCard = () => {
    const {
      popupMessage,
      errors,
      city,
      disableToggle,
      isFieldsChanged,
    } = this.state;
    const { isPushNotificationLoading } = this.props;
    const buttonClass = isFieldsChanged
      ? 'uk-button change_password_button uk-width-1-1 uk-text-center vistajet_button_primary'
      : 'uk-button vistajet_disabled uk-width-1-1 change_password_button vistajet_button_primary';
    return (
      <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card">
        {
          isPushNotificationLoading && (
            <div className="vistajet_spinner">
              <SpinnerLoader />
            </div>
          )
        }      
        <div class="vistajet_head_column">
          <div class="uk-clearfix">
            <div class="uk-float-left">
              <h3 class="vistajet_right_tab_heading">Notifications</h3>
            </div>           
          </div>             
        </div> 

        <div className="vistajet_profile_details_column vistajet_notification_details_column uk-padding-remove-top vistajet_primary_form">
          <div className="uk-flex uk-grid-small" data-uk-grid>
            <div className="uk-width-1-1 vistajet_search_cities">
              <div className="vistajet_switch">
                <label className="switch">
                    <input
                      type="checkbox"
                      checked={!disableToggle}
                      onChange={this.toggleDisablePreferredCities}
                    />
                    <span className="slider round" />
                </label>
                <h4 className="uk-margin-remove vistajt_preferred_cities_label">
                  Preferred Cities
                </h4>
              </div>
            </div>
            <span className="search-cities-explain">Save your preferred cities for offers notifications</span>
          </div>
          <div className="uk-flex uk-grid-small uk-margin-remove-top" data-uk-grid>           
            <div className="uk-width-1-1 vistajet_remove_input_wrapper_transition">
            {
              !disableToggle && (
                <div className="vistajet_input_wrapper" onClick={this.removeCityError}>
                  <VirtualizedSelect
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable
                    onFocus={this.removeCityError}
                    options={options}
                    filterOptions={filterOptions}
                    onChange={this.handleCitySelectChange}
                    value={city}
                    menuIsOpen={false}
                    id="city"
                    className="vistajet_select_input vistajet_cities_select_dropdown"
                    placeholder="Search for cities"
                    inputProps={{autoComplete: 'off'}}
                  />
                  {
                    errors.errors && errors.errors.city && (
                      <p className="vistajet_error">
                        {errors.errors.city}
                      </p>
                    )
                  }
                </div>
              )
            }
            </div>
          </div>
          <div className="uk-flex uk-grid-small uk-margin-remove-top" data-uk-grid>
          {
            !disableToggle && (
              <div className="md-chips">
                {this.renderChips()}
              </div>
            )
          }
          </div>
          <div className="vistajet_notification_checkboxes_container uk-margin-remove-top" data-uk-grid> 
            <div className="uk-width-1-1">  
              <h4 className="notifacation_heading">Notifications</h4>  
            </div>
            <div className="uk-flex uk-grid-samll uk-margin-remove-top" data-uk-grid>        
              {this.renderNotificationOptions()}
            </div>
          </div>         
        </div>  
        <div class="vistajet_profile_footer">
            <button
              className={buttonClass}
              disabled={!isFieldsChanged}
              onClick={this.updateNotifications}
            >
            Save Settings
            </button>
            <MessagePopUp
              id="PushNotificationErrorPopUp"
              content={popupMessage}
            />
        </div>                   
      </div>
    );
  };

  render() {
    return this.renderPushNotificationCard();
  }
}
