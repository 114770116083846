import React, { Component } from 'react';
import PaymentMethods from './PaymentMethods';
import { TERMS_URL_PAGE, ACTIVE_QUOTES_PAGE_URL } from '../../configs/constants';
import OfferSummary from './OfferSummary';
import { PAYMENT_METHODS_FOR_BOOKING } from '../../constants/BookingFlightConstants';
import SpinnerLoader from '../shared/spinnerLoader';
import ConfirmationPopUp from '../shared/ConfirmationPopUpNew';
import { isAdhoc } from '../../utils/userUtils';
import BillingAddress from './BillingAddress';
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants';
import { scrollToSection, scrollToTop } from '../../utils/browserUtils';

class QuoteSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      checkedTermsAndConditions: false,
      readMore: false,
      creditCards: {},
      selectedCard: !isAdhoc(props.user) ? 'membership' : null,
      selectedCvv: null,
      errors: '',
      hideDivider: false,
    };
  }

  componentDidMount() {
    this.props.getCreditCardList();
    this.props.getBillingAddress();
    dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
  }

  componentWillReceiveProps(nextProps) {
    const { creditCards } = nextProps;
    if (creditCards && (creditCards !== this.props.creditCards)) {
      this.setState({ creditCards });
    }
  }

  handleSelectCard = (ailias) => {
    this.setState({ selectedCard: ailias, selectedCvv: null });
  }

  handleCVVChange = (e) => {
    this.setState({ selectedCvv: e.target.value });
  }

  hideDivider = () => {
    this.setState({ hideDivider: true });
  }

  showDivider = () => {
    this.setState({ hideDivider: false });
  }

  validateForm = () => {
    const { quoteContent, buyOfferQuote, buyQuote } = this.props;
    let { legArray } = this.props;
    const { selectedCard, selectedCvv } = this.state;
    let isQuote = false;
    let amount = '', refrenceId = '';
    let {
      refrenceIds,
      content,
      id,
    } = quoteContent;
    if (Object.keys(buyQuote).length) {
      refrenceId = '';
      id = buyQuote.id;
      amount = buyQuote.amount;
      legArray = buyQuote.legs;
      isQuote = true;
    } else {
      refrenceId = refrenceIds[0];
      amount = content[refrenceId].amount;
    }
    const quoteDetails = {
      quoteId: id,
      quoteOfferId: refrenceId,
      totalAmount: amount,
      mode: 'new',
    }

    let paymentOption = 0;
    let creditCard = {};

    if (selectedCard !== 'membership') {
      paymentOption = PAYMENT_METHODS_FOR_BOOKING.CREDIT_CARD;
      creditCard = {
        ccAlias: selectedCard,
        ccCode: selectedCvv,
      };
    }
    else {
      paymentOption = PAYMENT_METHODS_FOR_BOOKING.MEMBERSHIP;
    }
    buyOfferQuote(legArray, quoteDetails, paymentOption, creditCard, isQuote);
  }
  sendFinalRequest = () => {
    this.confirm.hide();
    setTimeout(() => {
      // scrollToSection(500, 'top');
      scrollToTop();
    }, 1000)
    this.validateForm();
  }

  allFieldsPresent = () => {
    const { checkedTermsAndConditions, selectedCard, selectedCvv } = this.state;
    const { billingAddress } = this.props;
    if (checkedTermsAndConditions && Object.keys(billingAddress).length) {
      if (selectedCard === 'membership') {
        return false;
      }
      else if (selectedCard && selectedCvv) {
        return false;
      }
    }
    return true;
  }

  handleTermsAndConditions = () => {
    const { checkedTermsAndConditions } = this.state;
    this.setState({ checkedTermsAndConditions: !checkedTermsAndConditions })
  }

  handleReadMore = () => {
    const { readMore } = this.state;
    this.setState({ readMore: !readMore })
  }

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  goBack = () => {
    const { legArray, buyQuote, backToOffer } = this.props;
    if (Object.keys(buyQuote).length) {
      window.open(ACTIVE_QUOTES_PAGE_URL, '_self');
    }
    else {
      backToOffer(legArray);
    }
  }

  renderReadMoreText = () => {
    const { readMore } = this.state;
    return (readMore
      ? 'VistaJet Direct is a mobile application service provided by' +
      ' VistaJet Online and Mobile Services Ltd. (C78074) of Luqa/Malta and depending on the' +
      ' selected service either arranges for flight services with select air carriers in the name' +
      ' and on behalf of its members or hosts as a booking platform for select air carriers on' +
      ' their behalf. VistaJet affiliate-owned and U.S. registered aircraft are operated by duly' +
      ' licensed U.S. air carriers. VistaJet Online and Mobile Services Ltd. and its U.S.' +
      ' affiliate, VistaJet US Inc., are not U.S. direct or indirect air carriers and do not' +
      ' operate aircraft.'
      : 'VistaJet Online and Mobile Services Ltd. and its U.S. affiliate, VistaJet US Inc.,' +
      ' are not U.S. direct or indirect air carriers and do not operate aircraft...')
  }

  render() {
    const {
      errors,
      checkedTermsAndConditions,
      readMore,
      selectedCard,
      selectedCvv,
      hideDivider
    } = this.state;
    const { legArray, quoteContent, buyQuote } = this.props;
    const readMoreButtonText = readMore ? 'Read Less' : 'Read More';
    const readMoreText = this.renderReadMoreText();
    const buttonText = Object.keys(buyQuote).length ? 'Buy Quote' : 'Request Flight';
    const showDivider = Object.keys(buyQuote).length ? (!hideDivider && buyQuote.legs.length === 1)
      : (!hideDivider && legArray.length === 1);

    return (
      <div class="uk-container">
        <div class="uk-flex uk-grid-small" data-uk-grid>
          <div class="uk-width-1-1">
            <div class="vistajet_bs_card vistajet_quote_card vistajet_quote_summery_wrapper">
              <div class="vistajet_head_column">
                <button class="vistajet_back" onClick={this.goBack}><span>Back</span></button>
                <h5 class="uk-text-center vistajet_color_red">Quote Summary</h5>
              </div>
              <div class="vistajet_bs_body">
                {this.props.isRequestFlightLoading ? (<div className="vistajet_spinner"><SpinnerLoader /></div>) : null}
                <OfferSummary
                  legArray={buyQuote.legs || legArray}
                  quoteContent={quoteContent}
                  buyQuote={buyQuote}
                  isGoogleMapsApiLoaded={this.props.isGoogleMapsApiLoaded}
                  onGoogleMapsApiLoaded={this.props.onGoogleMapsApiLoaded}
                  hideDivider={this.hideDivider}
                  showDivider={this.showDivider}
                />

                <div class="vistajet_primary_form vistajet_direct_form vistajet_select_payment_column">
                  {showDivider ? <div class="vistajet_divider"></div> : null}
                  <p class="vistajet_spm">Select payment method</p>

                  {this.props.isCreditCardLoading
                    ? (<div className="vistajet_spinner">
                      <SpinnerLoader />
                    </div>)
                    : (<PaymentMethods
                      getCreditCardList={this.props.getCreditCardList}
                      creditCards={this.state.creditCards}
                      isCreditCardLoading={this.props.isCreditCardLoading}
                      isCreditCardAdded={this.props.isCreditCardAdded}
                      creditCardError={this.state.creditCardError}
                      addCreditCardAction={this.props.addCreditCardAction}
                      handleCVVChange={this.handleCVVChange}
                      handleSelectCard={this.handleSelectCard}
                      selectedCard={selectedCard}
                      selectedCvv={selectedCvv}
                      errors={errors}
                      user={this.props.user}
                    />)}
                  <BillingAddress
                    billingAddress={this.props.billingAddress}
                    user={this.props.user}
                    isBillingAddressLoading={this.props.isBillingAddressLoading}
                    saveBillingAddress={this.props.saveBillingAddress}
                    isBillingAddressError={this.props.isBillingAddressError}
                  />
                  <div class="vistajet_divider"></div>
                  <div class="vistajet_agree_column">
                    <div className="uk-flex uk-grid-small" data-uk-grid>
                      <div className="vistajet_disclaimer_text">
                        <div class="uk-width-1-1">
                          <p>{readMoreText}<button onClick={this.handleReadMore}>{readMoreButtonText}</button></p>
                        </div>
                      </div>
                    </div>
                    <div class="uk-flex uk-flex-middle uk-grid-small uk-margin-remove-top" data-uk-grid>
                      <div class="uk-width-1-2@s uk-text-left vistajet_terms_condition">
                        <label for="acceptAgreement">
                          <input
                            type="checkbox"
                            id=""
                            class="uk-checkbox uk-margin-small-right"
                            value="on"
                            checked={checkedTermsAndConditions}
                            onClick={this.handleTermsAndConditions}
                          />
                          I agree with the <a href={TERMS_URL_PAGE} target="_blank"><strong>Terms &amp;
                                                  Conditions</strong></a>
                        </label>
                        {
                          errors.errors && errors.errors.acceptAgreement && (
                            <p className="vistajet_outer_error">
                              {errors.errors.acceptAgreement}
                            </p>
                          )
                        }
                      </div>
                      <div className="uk-width-1-2@s uk-text-right">
                        <input
                          type="button"
                          class="uk-input vistajet_button_default vistajet_button_primary"
                          value={buttonText}
                          disabled={this.allFieldsPresent()}
                          onClick={() => this.confirm.show()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationPopUp
          id="buyQuoteConfirmationPopup"
          ref={(confirm) => { this.confirm = confirm; }}
          leftButtonContent="No"
          rightButtonContent="Yes"
          onLeftButtonClick={() => this.confirm.hide()}
          onRightButtonClick={this.sendFinalRequest}
          content="Are you happy to confirm your purchase?"
        />
      </div>
    )
  }
}

export default QuoteSummary;
