import React from 'react';
import Proptypes from 'prop-types';
import { convertToTitleCase } from 'utils/stringUtils';

const ValidateFlightMessage = (props) => {
  const { messages, legs, isProposalOrRestriction } = props;
  return (
    <div>
      {
        isProposalOrRestriction ? messages.map((message) => {
          const {
            flightLegId, restrictions, introductionToSuggestion, proposals,
          } = message;
          const departureCity = flightLegId ? legs[flightLegId - 1].departureAirport.city : '';
          const arrivalCity = flightLegId ? legs[flightLegId - 1].arrivalAirport.city : '';
          return (
            <div key={message.length}>
              <div>
                {`The flight from ${convertToTitleCase(departureCity)}`
                  + ` to ${convertToTitleCase(arrivalCity)} has restrictions.`}
              </div>
              <br />
              {
                restrictions.map((restriction, index) => (
                  <div key={index}>
                    {restriction}
                  </div>
                ))
              }
              <br />
              {proposals.length > 0 && (
                <div>
                  <div>
                    {`${introductionToSuggestion}:`}
                  </div>
                  <ol type="a">
                    {
                      proposals.map(proposal => (
                        <li key={proposal.length}>
                          {proposal}
                        </li>
                      ))
                    }
                  </ol>
                </div>
              )}
            </div>);
        }) : messages
      }
    </div>
  );
};

export default ValidateFlightMessage;

ValidateFlightMessage.propTypes = {
  legs: Proptypes.instanceOf(Array),
  messages: Proptypes.instanceOf(Array),
  isProposalOrRestriction: Proptypes.bool,
};

ValidateFlightMessage.defaultProps = {
  legs: [],
  messages: [],
  isProposalOrRestriction: false,
};
