import { fetchVisitedAirports, fetchAirportById } from '../sources/VisitedAirportsSource';
import * as types from '../actionTypes';

export const fetchVisitedAirportsInit = () => ({
  type: types.FETCH_VISITED_AIRPORTS_INIT,
});

export const fetchVisitedAirportsSuccess = data => ({
  type: types.FETCH_VISITED_AIRPORTS_SUCCESS,
  data,
});

export const fetchVisitedAirportsFail = data => ({
  type: types.FETCH_VISITED_AIRPORTS_FAIL,
  data,
});

export const fetchAirportByIdInit = () => ({
  type: types.FETCH_AIRPORT_BY_ID_INIT,
});

export const fetchAirportByIdSuccess = data => ({
  type: types.FETCH_AIRPORT_BY_ID_SUCCESS,
  data,
});

export const fetchAirportByIdFail = data => ({
  type: types.FETCH_AIRPORT_BY_ID_FAIL,
  data,
});

export const fetchAllVisitedAirports = data => fetchVisitedAirports(data);
export const getAirportById = id => fetchAirportById(id);
