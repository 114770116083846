import React from 'react';
import OfferMap from "../OfferMap";

const FboModal = (props) => {
    const {
        selectedAirportId,
        selectedFbo,
        legId,
        isGoogleMapsApiLoaded,
        onGoogleMapsApiLoaded,
        handleBack,
        visitedAirportsFinder,
    } = props;
    const airport = visitedAirportsFinder && visitedAirportsFinder[selectedAirportId];
    const latitude = airport && (airport || {}).latitude;
    const longitude = airport && (airport || {}).longitude;
    const fboAddressUrl = `https://www.google.com/maps/dir//${latitude},${longitude}`;
    const airportName = (airport || {}).name || '';
    const fboName = selectedFbo && selectedFbo.handlingAgentName || '';
    const fboNumber = selectedFbo && selectedFbo.handlingAgentPhoneNumber || '';
    const fboEmail = selectedFbo && selectedFbo.fboEmailAddress || '';
    const fboAddress = selectedFbo && selectedFbo.fboAddress || '';

    return (
        <div id="modal-fbo">
            <div className="vistajet_modal_header_fix">
                <a href="#" className="uk-modal-close" onClick={() => handleBack()}><span>Back</span></a>
                <h4 className="uk-text-center vistajet_fbo_modal_heading">{fboName}</h4>
            </div>

            <div className="vistajet_modal_content uk-margin-medium">
                <div style={{width: '100%', height: '250px', borderTop: '0.5px solid #d8d8d8', marginTop: '20px' }}>
                    <OfferMap
                        legs={[
                            {
                                id: (legId || '').toString(),
                                departureAirport: airport,
                            }
                        ]}
                        isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
                        onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
                    />
                </div>

                <div className="vistajet_modal_fbo_content_details">
                    {airportName ? <p><strong>Airport:</strong> {airportName}</p> : null}
                    {fboAddress ? <p><strong>Address:</strong> {fboAddress}</p> : null}
                    {fboNumber ? <p><strong>Tel:</strong> {fboNumber}</p> : null}
                    {fboEmail ? <p><strong>Email:</strong> {fboEmail}</p> : null}
                    <a class="vistajet_button_default uk-margin-remove" href={fboAddressUrl} target="_blank">Get Directions</a>
                </div>
            </div>
        </div>
    );
};

export default FboModal;