import axios from 'axios';
import { SEARCH_PAX_URL, MANAGE_PAX_URL, POST_FILE_URL } from '../../configs/constants';
import {
  HTTP_HEADERS_XML,
} from '../../constants/ApiConstants';

import {
  handleServerErrors,
  garanteeArray,
} from '../../utils/sourceUtils';
import { parseString, parseXmlStringToJson } from "../../utils/parserUtils";
import { postFiles } from '../../utils/fileUtils';
import { TIMEOUT_CODE } from '../../constants/ServerCodeConstants';
import moment from 'moment';
import { getToken, getUser } from '../../utils/userUtils';

const options = {
  timeout: TIMEOUT_CODE.time,
  headers: HTTP_HEADERS_XML,
};
const status = {
  OK: 200,
};
const ItineraryPassengerSource = {

  transformPassport(passport) {
    const displayName = passport.displayName;
    const isoIssuedByCountry = passport.isoIssuedByCountry;
    const isoPassportIssuedByCountry = passport.isoPassportIssuedByCountry;
    const passportNumber = passport.passportNumber;
    const expiresOnDate = passport.expiresOnDate;
    const isoPassportCountry = passport.isoPassportCountry;
    const isLegPassport = passport.isLegPassport || false;

    return {
      displayName,
      isoIssuedByCountry,
      isoPassportIssuedByCountry,
      passportNumber,
      expiresOnDate,
      isoPassportCountry,
      isLegPassport,
    };
  },

  searchPax(term) {
    const token = getToken();
    return new Promise((resolve, reject) => {
      const url = SEARCH_PAX_URL
                  .replace('{token}', token)
                  .replace('{term}', term);

      axios
      .get(url, options)
      .then((response) => {
        if (response.status !== status.OK) {
          reject(handleServerErrors(response.status));
        } else {
          parseString(response.data, (parseErr, response) => {
            if (parseErr) {
              reject({ text: 'Something went wrong', duration: 2000 });
              return false;
            }
            const passengers =
              garanteeArray((((response || {}).passengerRestDtoes || {}).passenger || []));
            passengers.forEach((pax) => {
              pax.passports = // eslint-disable-line no-param-reassign
                garanteeArray(pax.passports);
            });
            resolve({ searchPax: passengers });
            return true;
          });
        }
      });
    });
  },

  managePassengers(params, flightPassengers, deleted = []) {
    // Making all the structures - start
    // deleted passengers structure
    const deletedXmlStructure = '<deletedPassengersList><id>{id}</id>' +
                              '<passengerExtIdentifier>{identifier}</passengerExtIdentifier>' +
                              '</deletedPassengersList>';

    // main xml structure
    const passengersXmlStructure = '<passengerManageRequest>' +
                                '<passengers>' +
                                '<deleted>{deleted}</deleted>' +
                                '<new>{new}</new>' +
                                '<existingUpdatePassengers>{existingUpdated}' +
                                  '</existingUpdatePassengers>' +
                                '</passengers>' +
                              '</passengerManageRequest>';

    // new passengers
    const newXmlStructure = '<newPassengersList>' +
                              '<passengerExtIdentifier>{identifier}</passengerExtIdentifier>' +
                              '<passengerFirstName>{firstName}</passengerFirstName>' +
                              '<passengerLastName>{lastName}</passengerLastName>' +
                              '<passengerImageUrl>{imageUrl}</passengerImageUrl>' +
                              '<passengerEmail>{email}</passengerEmail>' +
                              '<phoneNumber>{phoneNumber}</phoneNumber>' +
                              '<passengerDob>{dob}</passengerDob>' +
                              '<gender>{gender}</gender>' +
                              '<isLeadPassenger>{leadPassenger}</isLeadPassenger>' +
                              '<passports>{passports}</passports>' +
                            '</newPassengersList>';

    // new passengers
    const existingUpdateXmlStructure = '<existingUpdatePassengersList>' +
                                    '<passengerId>{id}</passengerId>' +
                                    '<passengerFirstName>{firstName}</passengerFirstName>' +
                                    '<passengerLastName>{lastName}</passengerLastName>' +
                                    '<passengerImageUrl>{imageUrl}</passengerImageUrl>' +
                                    '<passengerEmail>{email}</passengerEmail>' +
                                    '<phoneNumber>{phoneNumber}</phoneNumber>' +
                                    '<passengerDob>{dob}</passengerDob>' +
                                    '<gender>{gender}</gender>' +
                                    '<isLeadPassenger>{leadPassenger}</isLeadPassenger>' +
                                    '<isInformationUpdated>true</isInformationUpdated>' +
                                    '<isApproved>{isApproved}</isApproved>' +
                                    '<passports>{passports}</passports>' +
                                '</existingUpdatePassengersList>';

    const passportXmlStructure = '<passport>' +
                                  '<id>{id}</id>' +
                                  '<isDefault>{isDefault}</isDefault>' +
                                  '<isActive>{isActive}</isActive>' +
                                  '<passportNumber>{passportNumber}</passportNumber>' +
                                  '<expiresOnDate>{expiry}</expiresOnDate>' +
                                  '<isoPassportCountry>{isoPassportCountry}</isoPassportCountry>' +
                                  '<isoIssuedByCountry>{isoIssuingCountry}</isoIssuedByCountry>' +
                                  '<displayName>{passportName}</displayName>' +
                                  '<isAdded>{isAdded}</isAdded>' +
                                  '<isUpdated>{isUpdated}</isUpdated>' +
                                  '<isDeleted>{isDeleted}</isDeleted>' +
                                  '<isLegPassport>{isLegPassport}</isLegPassport>' +
                                  '<passportFileDtos>' +
                                      '<fileLocation>{passportImageUrl}</fileLocation>' +
                                  '</passportFileDtos>' +
                                '</passport>';


    // Making all the structures - end
    const token = getToken();
    const url = MANAGE_PAX_URL
                .replace('{token}', token)
                .replace('{key}', params.key)
                .replace('{uniqueIdentifier}', params.uniqueIdentifier);

    let deletedXml = '';
    let newXml = '';
    let existingUpdateXml = '';
    let passportsXml = '';
    let finalXml = '';

    // making deleted payload - start
    deleted.forEach((id) => {
      // check if id is a guid or an id generated from GV
      // Using regex to identify GUID
      const regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
      const match = regex.exec(id);
      if (!match) { // its a id generated from GV
        deletedXml += deletedXmlStructure
                        .replace('{id}', id)
                        // this second replace is a patch required to cater API flow,
                        // I totally disagree with this btw!
                        .replace('{identifier}', '');
      } else {
        // its a guid
        deletedXml += deletedXmlStructure
                       .replace('{identifier}', id)
                       // this second replace is a patch required to cater API flow,
                       // I totally disagree with this btw!
                       .replace('{id}', '0');
      }
    });
    // making deleted payload - end

    // iterating over flight passengers - start
    flightPassengers.forEach((passenger) => {
      passportsXml = '';
      const email =
        passenger.emailAddresses.length > 0 ? passenger.emailAddresses[0].email : '';
      const phoneNumber =
        passenger.phoneNumbers.length > 0 ? passenger.phoneNumbers[0].phoneNumber : '';

      if ((passenger && passenger.isNew) || !passenger.passengerId) {
        // new passenger
        if (passenger.passports) {
          passenger.passports.forEach((passport) => {
            const pas = passport;
            passportsXml += passportXmlStructure.replace('{isDefault}', passport.isDefault || false)
                            .replace('{isActive}', true)
                            .replace('{passportNumber}', pas.passportNumber)
                            .replace('{expiry}', (pas.expiry) || '')
                            .replace('{isoPassportCountry}', pas.isoPassportCountry)
                            .replace('{isoIssuingCountry}', pas.isoPassportIssuedByCountry)
                            .replace('{passportName}', pas.displayName)
                            .replace('{isAdded}', passport.isAdded)
                            .replace('{isUpdated}', passport.isUpdated)
                            .replace('{isDeleted}', passport.isDeleted)
                            .replace('{isLegPassport}', pas.isLegPassport || false)
                            .replace('{passportImageUrl}', passport.imageUrl || '')
                            .replace('<id>{id}</id>', '');
          });
        }

        newXml += newXmlStructure
                    .replace('{firstName}', passenger.firstName)
                    .replace('{lastName}', passenger.lastName)
                    .replace('{imageUrl}', passenger.profilePicture ? passenger.profilePicture : '')
                    .replace('{email}', email)
                    .replace('{phoneNumber}', phoneNumber)
                    .replace('{dob}', passenger.dateOfBirth ?
                      moment(passenger.dateOfBirth).utc().format() : '')
                    .replace('{gender}', passenger.genderId >= 0 ? passenger.genderId : '')
                    .replace('{identifier}', passenger.passengerExtIdentifier)
                    .replace('{leadPassenger}', passenger.leadPassenger || false)
                    .replace('{passports}', passportsXml);
      } else {
        // existing passenger
        if (passenger.passports) {
          passenger.passports.forEach((passport) => {
            let temp = '';
            const pas = passport;
            if (passport && passport.passportId) {
              // old passport already added
              temp = passportXmlStructure.replace('{id}', passport.passportId);
            } else {
              // new passport added to existing passenger
              temp = passportXmlStructure.replace('<id>{id}</id>', '');
            }

            passportsXml += temp.replace('{isDefault}', passport.isDefault || false)
                            .replace('{isActive}', passport.isActive)
                            .replace('{passportNumber}', pas.passportNumber)
                            .replace('{expiry}', (pas.expiry) || '')
                            .replace(
                              '{isoPassportCountry}',
                              (pas.isoPassportCountry) || ''
                            )
                            .replace('{isoIssuingCountry}', pas.isoPassportIssuedByCountry || '')
                            .replace('{passportName}', pas.displayName || '')
                            .replace('{passportImageUrl}', passport.imageUrl || '')
                            .replace('{isAdded}', passport.isAdded || false)
                            .replace('{isUpdated}', passport.isUpdated || false)
                            .replace('{isDeleted}', passport.isDeleted || false)
                            .replace('{isLegPassport}', pas.isLegPassport || false);
          });
        }

        existingUpdateXml += existingUpdateXmlStructure
                    .replace('{firstName}', passenger.firstName)
                    .replace('{id}', passenger.passengerId)
                    .replace('{lastName}', passenger.lastName)
                    .replace('{imageUrl}', passenger.profilePicture || '')
                    .replace('{email}', email || '')
                    .replace('{phoneNumber}', phoneNumber || '')
                    .replace('{dob}', passenger.dateOfBirth || '')
                    .replace('{gender}', passenger.genderId >= 0 ? passenger.genderId : '')
                    .replace('{leadPassenger}', passenger.leadPassenger || false)
                    .replace('{isApproved}', passenger.isApproved || false)
                    .replace('{passports}', passportsXml);
      }
    });

    // iterating over flight passengers - end


    // replacing all the partial xmls in main xml
    finalXml = passengersXmlStructure.replace('{deleted}', deletedXml)
            .replace('{new}', newXml)
            .replace('{existingUpdated}', existingUpdateXml);
    return new Promise((resolve, reject) => {
      axios
        .post(url, finalXml, options)
        .then((response) => {
          if (response.status !== status.OK) { // error occured
            reject(handleServerErrors(response.status));
          } else {
            const jsonData = parseXmlStringToJson(response.data);
            resolve({ success: jsonData.vjResponse });
          }
        });
    });
  },

  uploadImage(file) {
    const form = new FormData();
    form.append('Filedata', file);

    const ext = `.${file.name.split('.').pop()}`;
    const userId = getUser().id || '';
    const timestamp = new Date().getTime().toString();
    const fileName = `${userId}_profile_image_${timestamp}${ext}`;
    const key = `persons/${userId}/${fileName}`;
    const token = getToken();
    const url = POST_FILE_URL
      .replace('{token}', token)
      .replace('{key}', key);
    return new Promise((resolve) => {
      axios
        .post(url, form)
        .then(() => {
          resolve({
            imageUrl: postFiles(key, token),
            relativeUrl: key,
          });
        });
    });
  },
};

export default ItineraryPassengerSource;
