import * as types from '../actionTypes';

const initialState = {
};

const getCateringCounter = (currentCount = 1, increment) => {
  currentCount += increment;
  if (currentCount < 0) currentCount = 0;
  return currentCount;
}

export default function cateringReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CATERING_STATE_INIT: {
      const newState = {
        ...state,
      };
      newState[action.data] = {
        ...newState[action.data],
        isCateringLoading: getCateringCounter((newState[action.data] || {}).isCateringLoading, 1)
      }
      return newState;
    }
    case types.GET_CATERING_STATE_SUCCESS: {
      const newState = {
        ...state,
      };
      newState[action.data.legId] = {
        ...newState[action.data.legId],
        ...action.data,
        isCateringLoading: getCateringCounter((newState[action.data.legId] || {}).isCateringLoading, -1),
      }
      return newState;
    }
    case types.GET_CATERING_STATE_FAIL: {
      const newState = {
        ...state,
      };
      newState[action.data] = {
        ...newState[action.data],
        isCateringLoading: getCateringCounter((newState[action.data]||{}).isCateringLoading, -1),
      }
      return newState;
    }
    case types.GET_CATERING_PROPOSAL_INIT: {
      const newState = {
        ...state
      }
      newState[action.data] = {
        ...newState[action.data],
        isCateringLoading: getCateringCounter((newState[action.data] || {}).isCateringLoading, 1)
      }
      return newState;
    }
    case types.GET_CATERING_PROPOSAL_SUCCESS: {
      const newState = {
        ...state
      }
      newState[action.data.legId] = {
        ...newState[action.data.legId],
        cateringProposal: [
          ...action.data,
        ],
        isCateringLoading: getCateringCounter((newState[action.data.legId] || {}).isCateringLoading, -1)
      }
      return newState;
    }
    case types.GET_CATERING_PROPOSAL_FAIL: {
      const newState = {
        ...state
      }
      newState[action.data] = {
        ...newState[action.data],
        isCateringLoading: getCateringCounter((newState[action.data] || {}).isCateringLoading, -1)
      }
      return newState;
    }
    case types.CHANGE_CATERING_PROPOSAL_INIT: {
      const newState = {
        ...state
      }
      newState[action.data] = {
        ...newState[action.data],
        isCateringLoading: getCateringCounter((newState[action.data] || {}).isCateringLoading, 1)
      }
      return newState;
    }
    case types.CHANGE_CATERING_PROPOSAL_SUCCESS: {
      const newState = {
        ...state
      }
      newState[action.data] = {
        ...newState[action.data],
        isCateringLoading: getCateringCounter((newState[action.data] || {}).isCateringLoading, -1)
      }
      return newState;
    }
    case types.CHANGE_CATERING_PROPOSAL_FAIL: {
      const newState = {
        ...state
      }
      newState[action.data] = {
        ...newState[action.data],
        isCateringLoading: getCateringCounter((newState[action.data] || {}).isCateringLoading, -1)
      }
      return newState;
    }
    case types.GET_CATERING_PDF_INIT: {
      const newState = {
        ...state
      }
      newState[action.data] = {
        ...newState[action.data],
        isCateringLoading: getCateringCounter((newState[action.data] || {}).isCateringLoading, 1)
      }
      return newState;
    }
    case types.GET_CATERING_PDF_SUCCESS: {
      const newState = {
        ...state
      }
      newState[action.data.legId] = {
        ...newState[action.data.legId],
        ...action.data,
        isCateringLoading: getCateringCounter((newState[action.data.legId] || {}).isCateringLoading, -1)
      }
      return newState;
    }
    case types.GET_CATERING_PDF_FAIL: {
      const newState = {
        ...state
      }
      newState[action.data.legId] = {
        ...newState[action.data.legId],
        error: action.data,
        isCateringLoading: getCateringCounter((newState[action.data.legId] || {}).isCateringLoading, -1)
      }
      return newState;
    }
    default:
      return state;
  }
}
