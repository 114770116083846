import React from 'react';

const SkeletonLoaderCards = () =>
    <div class="vistajet_skeleton_airport">
        <div class="vistajet_location_guide uk-float-left">
            <span></span>
        </div>
    </div>;

export default SkeletonLoaderCards;
