
import { normalizePaymentAdditionalCharges } from 'utils/creditCardUtils.js';
import { guaranteeArray } from '../utils/arrayUtils';

const findVAT = (costsBreakdown) => (
  costsBreakdown.find(costBreakdown => (costBreakdown.description === 'VAT'))
);

const normalizeLegs = (legs) => (
  legs.map((leg, index) => ({
    aircraftId: leg.requestedAircraftTypeId,
    arrivalTime: leg.arrivalTime,
    arrivalAirportId: leg.arrivalAirportId,
    departureTime: leg.departureTime,
    departureAirportId: leg.departureAirportId,
    duration: leg.flightTimeMinutes,
    flightLegId: index,
    opportunityId: leg.opportunityId ? leg.opportunityId : '',
    emptyLegId: leg.emptyLegId ? leg.emptyLegId : '',
    passengers: leg.numberOfPassenger,
  }))
);

const normalizeQuote = (price) => ({
  id: price.quoteOfferId,
  amount: parseInt(price.amount, 0),
  currency: price.currency,
  costsBreakdown: guaranteeArray(price.costsBreakdown.costBreakdown),
  vatPrice: findVAT(guaranteeArray(price.costsBreakdown.costBreakdown)) || null,
  legs: normalizeLegs(guaranteeArray(price.flightLegs.flightLeg)),
  paymentAdditionalCharges: normalizePaymentAdditionalCharges(
    guaranteeArray(price.paymentAdditionalCharges.paymentAdditionalCharge)
  ),
});

export const normalizeQuoteResponse = (quotes, isSingleLeg) => {
  const priceList = guaranteeArray(quotes.prices.price);
  return priceList.reduce(
    (prev, price) => {
      const previous = prev;
      previous.refrenceIds.push(price.quoteOfferId);
      previous.content[price.quoteOfferId] = normalizeQuote(price);
      return previous;
    }, {
      refrenceIds: [],
      content: {},
      id: quotes.quoteId,
      isSingleLeg,
    }
  );
};