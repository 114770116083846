import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import store from 'store/store';

// import AppRoot from 'AppRoot';

import IsAuthenticatedContainer from "containers/IsAuthenticatedContainer";
import HeaderContainer from "containers/HeaderContainer";
import CoreContainer from "containers/CoreContainer";

import OffersContainer from "containers/OffersContainer";
import PAFContainer from "containers/PAFContainer";
import UpcomingItinerariesListingContainer from "./containers/Itinerary/UpcomingItinerariesListingContainer";
import PastItinerariesListingContainer from "./containers/Itinerary/PastItinerariesListingContainer";
import CancelledItinerariesListingContainer from "./containers/Itinerary/CancelledItinerariesListingContainer";
import ItineraryDetailsContainer from "./containers/Itinerary/ItineraryDetailsContainer";
import EntertainmentContainer from "./containers/Itinerary/EntertainmentContainer";

import SimpleRegistrationContainer from "./containers/SimpleRegistrationContainer";
import RegistrationConfirmContainer from "./containers/RegistrationConfirmContainer";
import ActiveQuotesContainer from "./containers/Quotes/ActiveQuotesContainer";
import { removeOffersForFSP } from "./utils/userUtils";
import PASContainer from "./containers/PASContainer";
import UserProfileContainer from './containers/UserProfileContainer';
import ExpiredQuotesContainer from "./containers/Quotes/ExpiredQuotesContainer";
import TopUpAccountContainer from "./containers/TopUpAccountContainer";
import DirectAccountBalanceContainer from "./containers/DirectAccountBalanceContainer";
import TopUpHistoryContainer from "./containers/TopUpHistoryContainer";

import ProgramBuilder from './components/ProgramBuilder/ProgramBuilder';
import CostCalculator from './components/CostCalculator/CostCalculator';

//import FlightRequest from "./features/flightRequest/flightRequest";
const FlightRequest = React.lazy(() => import(/* webpackChunkName: "flightRequest" */"./features/flightRequest/flightRequest"));

let spaContainerObj = {
  'root': {
    reactComp: ((root) => <div><HeaderContainer /></div>),
    isAuthenticated: false,
  },
  'spa-topup-account': {
    reactComp: ((root) => <TopUpAccountContainer />),
    isAuthenticated: true,
  },
  'spa-direct-account-balance': {
    reactComp: ((root) => <DirectAccountBalanceContainer />),
    isAuthenticated: true,
  },
  'spa-topup-history': {
    reactComp: ((root) => <TopUpHistoryContainer />),
    isAuthenticated: true,
  },
  'spa-paf-container': {
    reactComp: ((root) => <PAFContainer />),
    isAuthenticated: true,
  },
  'spa-user-profile-container': {
    reactComp: ((root) => <UserProfileContainer />),
    isAuthenticated: true,
  },
  'spa-offer-container': {
    reactComp: ((root) => <OffersContainer />),
    isAuthenticated: false,
  },
  'spa-entertainment-container': {
    reactComp: ((root) => <EntertainmentContainer />),
    isAuthenticated: true,
  },
  'spa-upcoming-itineraries-container': {
    reactComp: ((root) => <UpcomingItinerariesListingContainer />),
    isAuthenticated: true,
  },
  'spa-past-itineraries-container': {
    reactComp: ((root) => <PastItinerariesListingContainer />),
    isAuthenticated: true,
  },
  'spa-cancelled-itineraries-container': {
    reactComp: ((root) => <CancelledItinerariesListingContainer />),
    isAuthenticated: true,
  },
  'spa-itinerary-details-container': {
    reactComp: ((root) => <ItineraryDetailsContainer />),
    isAuthenticated: true,
  },
  'spa-simple-registration': {
    reactComp: ((root) => <SimpleRegistrationContainer />),
    isAuthenticated: false,
  },
  'spa-simple-registration-confirm': {
    reactComp: ((root) => <RegistrationConfirmContainer isDirectConfirmContainer={false} />),
    isAuthenticated: false,
  },
  'spa-program-account-statement': {
    reactComp: ((root) => <PASContainer isDirectRegistration />),
    isAuthenticated: true,
  },
  'spa-header-container': {
    reactComp: ((root) => <HeaderContainer isDirectConfirmContainer={false} />),
    isAuthenticated: false,
  },
  'spa-active-quotes-container': {
    reactComp: ((root) => <ActiveQuotesContainer />),
    isAuthenticated: true,
  },
  'spa-expired-quotes-container': {
    reactComp: ((root) => <ExpiredQuotesContainer />),
    isAuthenticated: true,
  },
  'spa-program-builder': {
    reactComp: ((root) => <ProgramBuilder />),
    isAuthenticated: false,
  },
  'spa-cost-calculator': {
    reactComp: ((root) => <CostCalculator />),
    isAuthenticated: false,
  },
  'spa-core-container': {
    reactComp: ((root) => <CoreContainer />),
    isAuthenticated: false,
  },  
}

let blocks = {
  'vjr-flightRequest': {
    reactComp: ((root) => <FlightRequest {...(root.dataset)} />),
    isAuthenticated: false,
  }
}

Object.keys(spaContainerObj).forEach(key => {
  const container = document.getElementById(key);
  if (container) {
    /*  
        console.log(key);
        console.log(spaContainerObj[key].reactComp(container));
        console.log(container); 
    */
    if (spaContainerObj[key].isAuthenticated === true) {
      ReactDOM.render(<IsAuthenticatedContainer>{spaContainerObj[key].reactComp(container)}</IsAuthenticatedContainer>, container);
    } else {
      ReactDOM.render(spaContainerObj[key].reactComp(container), container);
    }
  }
})

Object.keys(blocks).forEach(key => {
  let containers = document.getElementsByClassName(key);
  if (containers !== null && containers !== undefined) {
    for (let container of containers) {
      if (blocks[key].isAuthenticated === true) {
        ReactDOM.render(<IsAuthenticatedContainer><Provider store={store}><Suspense fallback={<div>Loading...</div>}>{blocks[key].reactComp(container)}</Suspense></Provider></IsAuthenticatedContainer>, container);
      } else {
        ReactDOM.render(<Provider store={store}><Suspense fallback={<div>Loading...</div>}>{blocks[key].reactComp(container)}</Suspense></Provider>, container);
      }
    }
  }
});

removeOffersForFSP();
