import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import dirtystateSingleTon from "../../validators/validateDirty";
import SPAConstants from "../../constants/SPAConstants";
import { CONFIRMATION_TITLE, LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT, CONFIRMATION_MESSAGE } from '../../constants/DirtyPopupConstant';

import MessagePopUp from '../shared/MessagePopUp';
import {
	SERVICE_AVAILED_MESSAGE,
	SERVICE_DETAIL_PLACEHOLDER,
	GT_QUESTION_HEADING,
	GROUND_TRANSPORT_SERVICE,
	AS_DETAIL_PLACEHOLDER_EMPTY,
} from '../../constants/ItineraryConstants';
import ConfirmationPopup from '../shared/ConfirmationPopUpNew';

const INITIAL_STATE = {
	selectedOption: '',
	airportIndex: null,
	alertMessage: '',
	serviceData: {
		arrivalAirport: { detail: '' },
		departureAirport: { detail: '' },
	}
}

class GroundTransportationModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			open: false,
			selectedOption: '',
			airportIndex: null,
			serviceData: {
				arrivalAirport: { detail: '' },
				departureAirport: { detail: '' },
			}
		};
	}

	addService() {
		const { addGroundTransportService, legKey, uniqueIdentifier, token } = this.props;
		const { serviceData } = this.state;
		const key = legKey === 'orderId' ? 'flightLegId' : 'flightLegExtIdentifier';
		serviceData && serviceData.arrivalAirport && serviceData.arrivalAirport.value &&
			addGroundTransportService(token, key, uniqueIdentifier, {
				"transportServiceId": serviceData.arrivalAirport.value,
				"transportServiceTitle": serviceData.arrivalAirport.label,
				"onDeparture": false,
				"notes": serviceData.arrivalAirport.detail,
				"serviceType": GROUND_TRANSPORT_SERVICE,
			});
		serviceData && serviceData.departureAirport && serviceData.departureAirport.value &&
			addGroundTransportService(token, key, uniqueIdentifier, {
				"transportServiceId": serviceData.departureAirport.value,
				"transportServiceTitle": serviceData.departureAirport.label,
				"onDeparture": true,
				"notes": serviceData.departureAirport.detail,
				"serviceType": GROUND_TRANSPORT_SERVICE,
			});
		this.closeGTModal();
	}

	static propTypes = {
	};

	static defaultProps = {
	}

	onGTModalClose = () => {
		const isGTModalDirty = dirtystateSingleTon.getDirty(SPAConstants.GroundTransportation);
		if (isGTModalDirty) {
			this.gtConfirmPopup.show();
		} else {
			this.closeGTModal();
		}
	}

	closeGTModal = () => {
		dirtystateSingleTon.setDirty(SPAConstants.GroundTransportation, false);
		this.gtConfirmPopup.hide();
		this.state.serviceData.arrivalAirport = { detail: '' };
		this.state.serviceData.departureAirport = { detail: '' };
		this.setState({
			serviceData: INITIAL_STATE.serviceData,
			selectedOption: '',
			airportIndex: null,
			open: false,
		})
	}

	handleServiceChange = (selectedOption) => {
		dirtystateSingleTon.setDirty(SPAConstants.GroundTransportation, true);
		const { serviceData, airportIndex } = this.state;
		const { availedService } = this.props;

		let a = [], b = [];
		if (availedService && availedService.availedService) {
			availedService.availedService.filter(
				function (e) {
					if (e.onDeparture === false) {
						a.push(e.transportServiceId);
						a.push(e.transportServiceTitle)
					}
				});
			availedService.availedService.filter(
				function (e) {
					if (e.onDeparture === true) {
						b.push(e.transportServiceId);
						b.push(e.transportServiceTitle)
					}
				});
		}

		if (airportIndex === 1) {
			if (!a.includes(selectedOption.value) && !a.includes(selectedOption.label)) {
				serviceData.arrivalAirport.airportId = selectedOption.airportId;
				serviceData.arrivalAirport.label = selectedOption.label;
				serviceData.arrivalAirport.value = selectedOption.value;
			} else {
				this.state.serviceData.arrivalAirport = { detail: '' };
				dirtystateSingleTon.setDirty(SPAConstants.GroundTransportation, false);
				this.onGTModalClose();
				this.openAvailedModal(SERVICE_AVAILED_MESSAGE);
			}
		}
		else {
			if (!b.includes(selectedOption.value) && !b.includes(selectedOption.label)) {
				serviceData.departureAirport.airportId = selectedOption.airportId;
				serviceData.departureAirport.label = selectedOption.label;
				serviceData.departureAirport.value = selectedOption.value;
			} else {
				this.state.serviceData.departureAirport = { detail: '' };
				dirtystateSingleTon.setDirty(SPAConstants.GroundTransportation, false);
				this.onGTModalClose();
				this.openAvailedModal(SERVICE_AVAILED_MESSAGE);
			}
		}
		this.setState({ serviceData });
	};

	handleInputChange = event => {
		const target = event.target;
		const value = target.value;
		const { serviceData, airportIndex } = this.state;
		if (airportIndex === 1)
			serviceData.arrivalAirport.detail = value;
		else
			serviceData.departureAirport.detail = value;
		this.setState({
			serviceData
		});
	};

	selectAirport = (airportIndex) => {
		this.setState({ airportIndex })
	}

	getMissingAirports() {
		const { airportIds, visitedAirportsFinder, getAirportById } = this.props;
		const undefinedIds = [];
		const missingAirportIds = [];

		if (airportIds && airportIds.length) {
			for (let i = 0; i < airportIds.length; i++) {
				missingAirportIds.push(airportIds[i]);
			}
		}
		missingAirportIds.forEach((id) => {
			if (visitedAirportsFinder[id] === undefined) {
				undefinedIds.push(id);
			}
			if (undefinedIds && undefinedIds.length) {
				getAirportById([...new Set(undefinedIds)]);
			}
		});
	}

	isTextareaDisabled = (airportIndex) => {
		const { serviceData } = this.state;
		let isDeptTextareaDisabled = true;
		let isArrivalTextareaDisabled = true;
		if (airportIndex === 1) {
			if (serviceData && serviceData.arrivalAirport && serviceData.arrivalAirport.value) {
				isArrivalTextareaDisabled = false;
				return isArrivalTextareaDisabled;
			}
		}
		else {
			if (serviceData && serviceData.departureAirport && serviceData.departureAirport.value) {
				isDeptTextareaDisabled = false;
				return isDeptTextareaDisabled;
			}
		}
	}

	getActiveClass = (index) => {
		if (index === this.state.airportIndex) {
			return " active";
		}
		return ' ';
	}

	getDetailValue = (airportIndex, serviceData) => (
		airportIndex === 1 ? serviceData.arrivalAirport.detail : serviceData.departureAirport.detail
	)

	getAirportValue = (index, AirportDetail) => (
		AirportDetail && AirportDetail[index] && AirportDetail[index].name + ' ' + AirportDetail[index].iso2
	)

	openAvailedModal = (message) => {
		this.setState({ alertMessage: message });
		window.UIkit.modal('#modal-ground-transportation-availed').show();
	}

	isConfirmButtonEnabled = (airportIndex) => {
		let isEnabledArrival = true;
		let isEnabledDeparture = true;
		const { serviceData } = this.state;
		if (airportIndex === 1) {
			isEnabledArrival = serviceData && serviceData.arrivalAirport && serviceData.arrivalAirport.value
				? false : true;
			return isEnabledArrival;
		} else {
			isEnabledDeparture = serviceData && serviceData.departureAirport && serviceData.departureAirport.value
				? false : true;
			return isEnabledDeparture;
		}
	}

	show = () => {
		this.setState({ open: true });
	};

	hide = () => {
		this.setState({ open: false });
	}

	render() {
		const {
			airportIds,
			groundServices,
			visitedAirportsFinder,
		} = this.props;

		const { airportIndex, serviceData, alertMessage, open } = this.state;
		const selectedAirport = airportIds && airportIds[airportIndex];
		this.getMissingAirports();
		const getSubset = (keys, obj) => keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {});

		const options =
			groundServices && groundServices.length ? groundServices
				.filter(element => selectedAirport === element.airportId)
				.filter(element => element.groundTransportation === true)
				.map(a => getSubset(['id', 'externalName', 'airportId', 'detail'], a))
				.map(b => ({ value: b.id, label: b.externalName, airportId: b.airportId }))
				:
				[];

		const selectValue = airportIndex !== null ? (airportIndex === 0
			? (serviceData.departureAirport ? serviceData.departureAirport : '')
			: (serviceData.arrivalAirport ? serviceData.arrivalAirport : '')) : '';
		const labelValue = (options === undefined || options.length == 0)
			? AS_DETAIL_PLACEHOLDER_EMPTY : 'Select Transport Option'
		const detailPlaceholder = (options === undefined || options.length == 0)
			? AS_DETAIL_PLACEHOLDER_EMPTY : SERVICE_DETAIL_PLACEHOLDER;

		const AirportDetail = airportIds && airportIds.length && airportIds.map(e => visitedAirportsFinder[e]);
		const isDisabled = airportIndex === null || this.isConfirmButtonEnabled(airportIndex);

		return (
			<Modal
				open={open}
				classNames={{
					overlay: 'vistajet_modal vistajet_modal_large vistajet_itinerary_modal',
					modal: 'vistajet_modal_body vistajet_react_modal',
				}}
				onClose={this.onGTModalClose}
				center
				showCloseIcon={false}
				closeOnOverlayClick={false}
			>
				<div className="vistajet_modal_height vistajet_ground_transportation_modal">
					<div className="vistajet_modal_header_fix">
						<a onClick={() => this.onGTModalClose()} className="uk-modal-close"><span>Back</span></a>
						<h4 className="uk-text-center">{'Add Ground Transportation'}</h4>
					</div>
					<div className="vistajet_modal_content uk-margin-remove-bottom uk-align-center uk-width-1-1@s vistajet_primary_form">
						<p className="vistajet_modal_subheading">{GT_QUESTION_HEADING}</p>
						<div className="uk-margin-small-top uk-grid uk-grid-small" uk-grid>
							<div className="uk-width-1-2@s uk-width-1-1">
								<button className={'uk-button vistajet_departure_button ' + this.getActiveClass(0)} onClick={() => this.selectAirport(0)}>
									<h6>Departure</h6>
									<p className="vistajet_truncate_one_line">{this.getAirportValue(0, AirportDetail)}</p>
								</button>
							</div>
							<div className="uk-width-1-2@s uk-width-1-1">
								<button className={'uk-button vistajet_arrival_button ' + this.getActiveClass(1)} onClick={() => this.selectAirport(1)} >
									<h6>Arrival</h6>
									<p className="vistajet_truncate_one_line">{this.getAirportValue(1, AirportDetail)}</p>
								</button>
							</div>
						</div>
						{airportIndex !== null && (
							<div>
								<hr className="gray_hr" />
								<p className="vistajet_modal_subheading">Please add more details</p>
								<div className="vistajet_input_wrapper uk-margin-top">
									<Select
										classNamePrefix="react-select"									
										isEditable={false}
										value={selectValue}
										isSearchable={false}
										className="vistajet_select_input"
										options={groundServices && options}
										onChange={this.handleServiceChange}
										isDisabled={(options === undefined || options.length == 0) ? true : false}
									/>
									<label className="uk-form-label">{labelValue}</label>
									<i uk-icon="icon: chevron-down; ratio: 1"></i>
								</div>
								<div className="vistajet_input_wrapper">
									<textarea
										rows="7"
										name="detail"
										onChange={this.handleInputChange}
										className="uk-textarea vistajet_textarea"
										value={this.getDetailValue(airportIndex, serviceData)}
										placeholder={detailPlaceholder}
										disabled={isDisabled}
									></textarea>
									<label htmlFor="additionalDetails">
										Additional Details
                        </label>
								</div>
							</div>)}
					</div>
					<div className="vistajet_modal_footer">
						<button
							type="button"
							disabled={isDisabled}
							onClick={() => airportIndex !== null && this.addService()}
							className="uk-button uk-button-default uk-width-1-1 uk-text-center "
						>
							Confirm Services
							</button>
					</div>
				</div>
				<ConfirmationPopup
					ref={gtConfirmPopup => { this.gtConfirmPopup = gtConfirmPopup }}
					title={CONFIRMATION_TITLE}
					content={CONFIRMATION_MESSAGE}
					leftButtonContent={LEFT_BUTTON_TEXT}
					rightButtonContent={RIGHT_BUTTON_TEXT}
					onRightButtonClick={() => this.closeGTModal()}
					onLeftButtonClick={() => this.gtConfirmPopup.hide()}
				/>
				<MessagePopUp
					id="modal-ground-transportation-availed"
					content={alertMessage}
				/>
			</Modal>
		);
	}
}

export default GroundTransportationModal;
