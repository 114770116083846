import moment from 'moment';
import Helpers, { clone } from 'utils/Helpers';
import { guaranteeArray } from './arrayUtils';
import { mergePassports, mergePassportbyPax, isPassportExpired, isPassportExpiredBeforeDept } from './passportUtils';
import { getDateWithoutTimeZone } from './timezoneUtils';
import {
  existInLocal,
  getFromLocal,
} from './cache';

export function isCurrentUser(passenger, user) {
  const currentUser = user.personId;
  return (+currentUser === +(Helpers.getValue(passenger.passengerId)));
}

export const getGender = (id) => {
  if (id > 0 && id < 5) {
    const genders = ['Male', 'Female', 'Unknown', 'Neither'];
    return genders[id - 1];
  }
  return '';
};

export function comparePassengers(pax1, pax2) {
  const p1 = {
    id: pax1.passengerId,
    extId: pax1.passengerExtIdentifier,
  };
  const p2 = {
    id: pax2.passengerId,
    extId: pax2.passengerExtIdentifier,
  };
  return (+p1.id && +p2.id && +p1.id === +p2.id) || (p1.extId && p2.extId && p1.extId === p2.extId);
}

export function getLeadPax(arr) {
  let lbl = 'No Lead Passenger';
  if (!arr || !arr.length) {
    return lbl;
  }
  const leadPax = arr.find(pax => pax.leadPassenger);

  if (!leadPax) {
    return lbl;
  }
  // handles first name key
  if (leadPax.passengerFirstName) {
    leadPax.firstName = leadPax.passengerFirstName; // eslint-disable-line no-param-reassign
  }
  // handles last name key
  if (leadPax.passengerLastName) {
    leadPax.lastName = leadPax.passengerLastName; // eslint-disable-line no-param-reassign
  }
  lbl = `${leadPax.firstName} ${leadPax.lastName}`;
  return lbl;
}

export function getAnnouncedPax(activeLeg, flightPax = null) {
  const flightPassengers = flightPax || activeLeg.passengers;
  const remainingPax = activeLeg.numberOfPassenger || activeLeg.passengerCount;
  const announcedPax = {
    remainingPax,
    paxLabel: (+remainingPax === 1 ? 'Passenger' : 'Passengers'),
  };

  if (flightPassengers && flightPassengers.length) {
    const paxCount = (+announcedPax.remainingPax) - (+flightPassengers.length);

    // count for additional pax
    // ei: Please provide details for 1 additional passenger
    if (paxCount < 1) {
      announcedPax.remainingPax = '';
      announcedPax.paxLabel = '';
    } else {
      announcedPax.remainingPax = paxCount;
      announcedPax.paxLabel = +announcedPax.remainingPax === 1 ? 'Passenger' : 'Passengers';
    }
  }
  return announcedPax;
}

export const getPaxToBeAnnounced = (count, addedPax) => {
  const remaining = count - (addedPax || []).length;
  const paxlabel = remaining === 1 ? 'Passenger' : 'Passengers';
  const announcedText = remaining > 0 ? `Please provide details for ${String(remaining).padStart(1,0)} ${paxlabel}` : '';
  return announcedText;
};

export function normalizePaxForCateringData(pax) {
  if (!!pax) {
    return {
      leadPassenger: pax.leadPassenger,
      isApproved: pax.isApproved,
      passengerId: pax.passengerId,
      passengerFirstName: pax.passengerFirstName,
      passengerLastName: pax.passengerLastName,
    };
  }
  return [];
}

// merge Passenger
export function mergePassenger(oldPaxObj, newPaxObj) {
  const pax = oldPaxObj || {};
  pax.firstName = newPaxObj.firstName || '';
  pax.lastName = newPaxObj.lastName || '';
  pax.profilePicture = newPaxObj.profilePicture || '';
  pax.genderId = newPaxObj.genderId || '';
  pax.gender = getGender(+newPaxObj.genderId || '');
  pax.emailAddresses = newPaxObj.emailAddresses || '';
  pax.dateOfBirth = newPaxObj.dateOfBirth || '';

  if (pax.hasOwnProperty('passports') && newPaxObj.hasOwnProperty('passports')) {
    pax.passports = mergePassports(
      guaranteeArray(pax.passports),
      guaranteeArray(newPaxObj.passports)
    );
  } else {
    pax.passports = guaranteeArray(pax.passports || newPaxObj.passports);
  }
  return pax;
}

// get passengerId
export function getPassengerId(passenger) {
  return passenger.passengerId || passenger.passengerExtIdentifier || '';
}

export function mergePassengersList(added, freq) {
  const newAdded = added.filter(pax => pax.isNewlyAdded);
  const Search = added.filter(pax => pax.isSearched);
  const cacheAdded = newAdded.concat(Search);
  const cacheMerged = mergePassportbyPax(added, freq);
  const finalMerge = cacheMerged.concat(cacheAdded);
  const temp = {};
  // removing repeated ids
  finalMerge.forEach(pax => {
    temp[getPassengerId(pax)] = pax;
  });
  const paxList = Object.keys(temp).map((id) => (temp[id]));
  return clone(paxList);
}

export function generateFrequentPaxList(addedPax) {
  const cachedfreqPax = existInLocal('frequentPax') ?
    guaranteeArray(getFromLocal('frequentPax', true)) : [];
  let frequentPaxList = [];
  cachedfreqPax.forEach((passenger) => {
    frequentPaxList.push(getPassengerId(passenger));
  });

  addedPax.forEach((passenger) => {
    frequentPaxList.push(getPassengerId(passenger));
  });

  // removing repeated ids
  frequentPaxList = frequentPaxList.filter((elem, pos) => (
    frequentPaxList.indexOf(elem) === pos)
  );
  return frequentPaxList;
}

export const formatDetailPax = (paxObj) => {
  const passengerObj = {
    dateOfBirth: '-',
    genderId: '-',
    email: '-',
    phoneNumber: '-',
    truncatedName: '-',
    fullName: '-',
  };
  passengerObj.leadPassenger = paxObj.leadPassenger;

  passengerObj.fullName =
  paxObj.firstName.concat(' ', paxObj.lastName).toLowerCase();

  // truncate fullname for summary row
  passengerObj.truncatedName =
    Helpers.truncateStr(passengerObj.fullName, 35).toLowerCase();

  if (paxObj.dateOfBirth) {
    passengerObj.dateOfBirth = moment(paxObj.dateOfBirth).format('DD-MMM-YYYY');
  }
  const genderMap = {
    1: 'Male',
    2: 'Female',
    3: 'Neither',
    4: 'Unknown',
  };
  // only show male/female
  if (+paxObj.genderId > 0 && +paxObj.genderId < 3) {
    passengerObj.gender = genderMap[paxObj.genderId];
  }
  if (paxObj.email) {
    passengerObj.email = paxObj.emailAddresses &&
    paxObj.emailAddresses[0] &&
    paxObj.emailAddresses[0].email || '-';
  }

  if (paxObj.phoneNumber) {
    passengerObj.phoneNumber = paxObj.phoneNumbers &&
    paxObj.phoneNumbers[0] &&
    paxObj.phoneNumbers[0].phoneNumber || '-';
  }

  passengerObj.emailAddresses = paxObj.emailAddresses;
  passengerObj.phoneNumbers = paxObj.phoneNumbers;
  return passengerObj;
};

export const setLegPassport = (passengers, activeLeg) => {
  if (!passengers || !passengers.length) { return []; }

  passengers.forEach((pax) => {
    // filter leg passport
    const passports = pax.passports || [];
    const legPassport = clone(passports.find(passport => passport.isLegPassport)) || null;
    const tempDetailPax = formatDetailPax(pax);

    if (legPassport) {
      if ((legPassport.isoPassportCountry || []).length === 3) {
        legPassport.isoPassportCountry = Helpers.getCountryFromCode(
          legPassport.isoPassportCountry
        ).name || '-';
      }

      if ((legPassport.isoIssuedByCountry || []).length === 3) {
        legPassport.isoIssuedByCountry = Helpers.getCountryFromCode(
          legPassport.isoIssuedByCountry
        ).name || '-';
      }


      legPassport.isExpired = isPassportExpired(legPassport);
      legPassport.isPassExpiredBeforeDept =
        isPassportExpiredBeforeDept(legPassport, moment(activeLeg.deptAirportTimeUTC).utc());
      legPassport.expiresOnDate =
        legPassport.expiresOnDate ?
          moment(getDateWithoutTimeZone(legPassport.expiresOnDate)).utc().format('DD-MMM-YY') :
          '-';
      const paxName = (legPassport.displayName ||
      (pax.formattedPaxObj || {}).truncatedName ||
      `${pax.firstName} ${pax.lastName}`);
      legPassport.displayName = paxName.toString().toLowerCase();

      tempDetailPax.legPassport = legPassport;
    }
    pax.formattedPaxObj = tempDetailPax; // eslint-disable-line no-param-reassign
  });
  return passengers;
};

export const sortPassengers = (passengers) => {
  let sortedPassengers = [];
  passengers.forEach((pax) => {
    if (pax.leadPassenger) {
      sortedPassengers.unshift(pax);
    } else {
      sortedPassengers.push(pax);
    }
  });
  return sortedPassengers;
};

export const setPaxLegPassport = (passports) => {
  const pass = passports;
  const filterPassports = pass.filter(passport => !passport.isDeleted && passport.isActive);
  if (filterPassports.length > 0) {
    let isLeg = filterPassports.find(passport => (
      passport.isLegPassport
    ));
    if (!isLeg) {
      isLeg = filterPassports.find(passport => (
        passport.isDefault
      ));
    }
    if (!isLeg) {
      filterPassports[0].isLegPassport = true;
    } else {
      isLeg.isLegPassport = true;
    }
  }
  return filterPassports;
};

export const setPaxDefaultPassport = (passports) => {
  const pass = passports;
  const filterPassports = pass.filter(passport => !passport.isDeleted && passport.isActive);
  if (filterPassports.length > 0) {
    const isLeg = filterPassports.find(passport => (
      passport.isDefault
    ));
    if (!isLeg) {
      filterPassports[0].isDefault = true;
    }
  }
  return filterPassports;
};

export const setLeadPax = (addedPax) => {
  if (addedPax.length > 0) {
    const leadPax = addedPax.find(pax => (pax.leadPassenger));
    if (!leadPax) {
      addedPax[0].leadPassenger = true;
    }
  }
}
