import { CUSTOMER_APP_ID } from "../configs/constants";
import { GLITCH_TYPE } from "../constants/feedbackConstants";
import { isEmpty } from "../utils/arrayUtils";

export const normalizeFeedback = params => {
  let feedbackObject = {
    legId: "",
    feedbackRating: "",
    categoryRatingTypeId: "",
    comments: ""
  };
  const feedbackResponse = (params && params.feedbacksResponse) || [];
  feedbackResponse.forEach(item => {
    feedbackObject.legId = item.flightLegId;
    if (item.glitchTypeId && item.glitchTypeId === 22) {
      feedbackObject.feedbackRating = item.feedbackTypeId;
      feedbackObject.categoryRatingTypeId = item.glitchTypeId;
      feedbackObject.comments = item.feedbackDescription || "";
    }
  });
  return feedbackObject;
};

export const noramlizeSaveFeedbackParams = ({
  servicesFeedbacks,
  legId,
  feedbackIdentifier,
  personId
}) => {
  const feedbacks = [];
  Object.keys(servicesFeedbacks).forEach(key => {
    if (!isEmpty(servicesFeedbacks[key])) {
      feedbacks.push({
        flightLegId: legId,
        feedbackTypeId: servicesFeedbacks[key].rating,
        glitchOriginId: CUSTOMER_APP_ID,
        glitchTypeId: GLITCH_TYPE[servicesFeedbacks[key].category],
        feedbackDescription: servicesFeedbacks[key].desc
      });
    }
  });
  const params = {
    feedbacks,
    flightLegId: legId,
    feedbackIdentifier,
    userId: personId,
    feedbackReporterId: personId,
    immediateAttention: 1
  };
  return params;
};

export const normalizeSaveFeedback = (params) => {
  const saveFeedbackResponse = params && params.feedbacksResponse || [];
  return saveFeedbackResponse.map((item) => ({
    feedbackId: item.feedbackId,
    feedbackRating: true,
  }));
}
