import {
  AddCreditCardSource,
  getCreditCardList,
  deleteCreditCardSource,
} from '../sources/CreditCardSource';
import * as types from '../actionTypes';

export const addCreditCardInit = () => ({
  type: types.ADD_CREDIT_CARD_INIT,
});

export const addCreditCardSuccess = data => ({
  type: types.ADD_CREDIT_CARD_SUCCESS,
  data,
});

export const updateCreditCardSuccess = data => ({
  type: types.UPDATE_CREDIT_CARD_SUCCESS,
  data,
});

export const addCreditCardFail = data => ({
  type: types.ADD_CREDIT_CARD_FAIL,
  data,
});

export const getCreditCardListInit = () => ({
  type: types.GET_CREDIT_CARD_LIST_INIT,
});

export const getCreditCardListSuccess = data => ({
  type: types.GET_CREDIT_CARD_LIST_SUCCESS,
  data,
});

export const getCreditCardListFail = data => ({
  type: types.GET_CREDIT_CARD_LIST_FAIL,
  data,
});

export const deleteCreditCardInit = () => ({
  type: types.DELETE_CREDIT_CARD_INIT,
});

export const deleteCreditCardSuccess = data => ({
  type: types.DELETE_CREDIT_CARD_SUCCESS,
  data,
});

export const deleteCreditCardFailure = data => ({
  type: types.DELETE_CREDIT_CARD_FAILURE,
  data,
});

export const clearCreditCardState = () => ({
  type: types.CLEAR_CREDIT_CARD_STATE,
});

export const addCreditCardAction = (token, payload) => AddCreditCardSource(token, payload);
export const getAllCreditCards = () => getCreditCardList();
export const deleteCreditCardAction = (token, payload) => deleteCreditCardSource(token, payload);
