import Helpers from "./Helpers";
import moment from "moment";

import { calcFlightDuration } from "./PAFUtils";

export const legObject = {
  prevLeg: null,
  guid: Helpers.guid(),
  orderExtIdentifier: null,
  date: null,
  selectedAircraft: null,
  time: "",
  duration: null,
  departureAirport: null,
  arrivalAirport: null,
  departTime: "Invalid date",
  arrivalTime: "Invalid date",
  minutes: "00",
  hour: "00",
  bookedFlightId: 0,
  passengersCount: 1,
  version: 0,
  isCanceled: false,
  isEditableLeg: true,
  isRequestFailed: false,
  isReturn: false,
  isWidgetFlow: false,
  validation: {
    isError: false,
    errorMsg: "",
    errorOn: {
      departure: null,
      arrival: null,
      departureArrival: null,
      departureArrivalSame: null,
      time: null,
      passenger: null,
      aircraft: null,
      date: null,
      minHoursException: null,
      fuelStop: null
    }
  }
};

export const leg = () => {
  return {
    prevLeg: null,
    guid: Helpers.guid(),
    orderExtIdentifier: null,
    date: null,
    selectedAircraft: null,
    time: "",
    duration: null,
    departureAirport: null,
    arrivalAirport: null,
    departTime: "Invalid date",
    arrivalTime: "Invalid date",
    minutes: "00",
    hour: "00",
    bookedFlightId: 0,
    passengersCount: 1,
    version: 0,
    isCanceled: false,
    isEditableLeg: true,
    isRequestFailed: false,
    isReturn: false,
    isWidgetFlow: false,
    validation: {
      isError: false,
      errorMsg: "",
      errorOn: {
        departure: null,
        arrival: null,
        departureArrival: null,
        departureArrivalSame: null,
        time: null,
        passenger: null,
        aircraft: null,
        date: null,
        minHoursException: null,
        fuelStop: null
      }
    }
  };
};

export const calcDepartTime = (date, time) => {
  const datetimeString =
    `${moment.utc(date).format("DD/MM/YYYY")} ${time}`;
  const departTime = moment(`${datetimeString} +0000`, "DD/MM/YYYY HH:mm Z")
    .utc()
    .format();
  return departTime;
};

export const formatDate = date => {
  const splitDate = date.split("T");
  const day = splitDate[0];
  const splitTime = splitDate[1].split(":");
  const hour = splitTime[0];
  const minutes = Helpers.roundUp5(parseInt(splitTime[1]));

  const time = `${hour}:${minutes}`;
  return {
    day,
    hour,
    minutes,
    time
  };
};


export const formatOfferObject = objectFromOffer => {
  let dateObject;
  if (objectFromOffer.isOneWay) {
    if ((moment(objectFromOffer.segmentStart)).isBefore(moment())) {
      dateObject = Helpers.getLatestTimeOfAirport(objectFromOffer.departureAirport).format('YYYY-MM-DDTHH:mm:SSS')
    }
    else {
      dateObject = objectFromOffer.segmentStart;
    }
    const { day, hour, minutes, time } = formatDate(
      dateObject)
    const departTime = calcDepartTime(day, time);
    objectFromOffer.date = day;
    objectFromOffer.hour = hour;
    objectFromOffer.minutes = minutes;
    objectFromOffer.time = time;
    objectFromOffer.departTime = departTime;
  } else if (objectFromOffer.isEmptyLeg) {
    if ((moment(objectFromOffer.minDepartureTime)).isBefore(moment())) {
      dateObject = Helpers.getLatestTimeOfAirport(objectFromOffer.departureAirport).format('YYYY-MM-DDTHH:mm:SSS')
    }
    else {
      dateObject = objectFromOffer.minDepartureTime;
    }
    const { day, hour, minutes, time } = formatDate(
      dateObject
    );
    const departTime = calcDepartTime(day, time);
    const { duration } = calcFlightDuration(objectFromOffer, false);
    const arrivalTime = Helpers.getArrivalTime(
      departTime,
      duration,
      objectFromOffer.departureAirport,
      objectFromOffer.arrivalAirport
    );
    objectFromOffer.duration = duration;
    objectFromOffer.date = day;
    objectFromOffer.hour = hour;
    objectFromOffer.minutes = minutes;
    objectFromOffer.time = time;
    objectFromOffer.departTime = departTime;
    objectFromOffer.arrivalTime = arrivalTime;
  }
  return { ...legObject, ...objectFromOffer };
};


