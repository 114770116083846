import moment from "moment";
import { GROUNDTIME_VALIDATION_MESSAGE } from "../constants/MessageConstants"

export const GROUND_TIME = "groundTime";

export const sortOneWays = oneWayContent => (previous, next) => {
  if (oneWayContent[previous] && oneWayContent[next]) {
    const availableInPrevious = moment(
      oneWayContent[previous].availableIn
    ).utc();
    const availableInNext = moment(oneWayContent[next].availableIn).utc();
    if (availableInPrevious === availableInNext) {
      return 0;
    }
    if (availableInPrevious > availableInNext) {
      return 1;
    }
    return -1;
  }
  return undefined;
};

export const maxArrivalDateTime = (arrivalTime, groundTime) =>{
  return moment(arrivalTime).utc().add(groundTime, 'hours');}

export const notValidOfferMessage = (reason, legArray, legIndex, offerObject) =>{
  let message = "";
  if (reason === GROUND_TIME) {
    if(legArray[legIndex].arrivalTime){
      message = GROUNDTIME_VALIDATION_MESSAGE
      .replace("{time}", maxArrivalDateTime(legArray[legIndex].arrivalTime, offerObject.maxGroundTimeInHours).format('Do MMM, YYYY HH:mm'))
    }
  }
  return message
};
export const getAvailableTime = availableIn => {
  const availableInHours = Math.floor(moment.duration(availableIn).asHours());
  let ending = "Now";
  let timeDiff = 0;
  if (availableInHours < 1) {
    return {
      value: timeDiff,
      unit: ending
    };
  }

  if (availableInHours <= 24) {
    ending = "hours";
    timeDiff = availableInHours;

    if (timeDiff === 1) {
      ending = "hour";
    }
    return {
      value: timeDiff,
      unit: ending
    };
  }

  ending = "days";
  timeDiff = Math.floor(moment.duration(availableIn).asDays());

  if (timeDiff === 1) {
    ending = "day";
  }

  return {
    value: timeDiff,
    unit: ending
  };
};

export function isLegInOfferRange(currentLeg, offerObject) {
  const departTime = moment(currentLeg.departTime).utc()
  const validFrom = moment(offerObject.segmentStart).utc()
  const validTo = moment(offerObject.segmentEnd).utc()

  if (
    currentLeg.departTime !== "Invalid date" && !(departTime.isSameOrAfter(validFrom) && departTime.isSameOrBefore(validTo))
  ) {
    return false;
  }
  return true;
}

export function areLegsConnected(currentLeg, prevLeg) {
  if (!prevLeg) {
    return true;
  }

  if (currentLeg.departureAirport === null || prevLeg.arrivalAirport === null) {
    return true;
  }

  return currentLeg.departureAirport.id === prevLeg.arrivalAirport.id;
}

export function sortLegsByDepartTime(legs) {
  return legs.sort((a, b) =>
    moment(a.departTime)
      .utc()
      .isAfter(moment(b.departTime).utc())
  );
}

export function areLegsInGroundTime(groundTime, currentLeg, prevLeg) {
  if (
    !prevLeg ||
    !moment(currentLeg.departTime)
      .utc()
      .isValid()
  ) {
    return true;
  }

  const { arrivalTime } = prevLeg;
  const { departTime } = currentLeg;

  const legsInterval = moment(departTime)
    .utc()
    .diff(moment(arrivalTime).utc(), "minutes");

  return !(legsInterval > 60 * groundTime);
}

export function isAircraftEqualToOffer(currentLeg, oneWay) {
  return currentLeg.selectedAircraft.id === oneWay.aircraftTypeId;
}

export function isExtendedOneWayValid(isValid, legs, oneWay = {}) {
  let validation = {
    valid: true,
    reason: "",
    legIndex: null
  };

  const groundTime = oneWay.maxGroundTimeInHours;
  if (legs.length === 0) {
    validation.valid = false;
    return validation;
  }

  if (legs.length > oneWay.maxLegs) {
    validation.valid = false;
    return validation;
  }

  if (!isLegInOfferRange(legs[0], oneWay)) {
    validation.valid = false;
    return validation;

  }
  if (legs[0].departureAirport.id !== oneWay.departureAirport.id) {
    validation.valid = false;
    return validation;
  }

  if (legs[0].selectedAircraft.id !== oneWay.aircraftTypeId) {
    validation.valid = false;
    return validation;
  }

  legs.forEach((leg, index) => {
    if (index > 0) {
      const prevLeg = legs[index - 1];
      const connected = areLegsConnected(leg, prevLeg);
      const isAircraftValid = isAircraftEqualToOffer(leg, oneWay);
      let isInRage = true;
      let isInGroundTime = true;

      if (leg.departTime) {
        isInRage = isLegInOfferRange(leg, oneWay);
        isInGroundTime = areLegsInGroundTime(groundTime, leg, prevLeg);
      }

      if (!connected && validation.valid) {
        validation.valid = false;
      }

      if (!isInRage && validation.valid) {
        validation.valid = false;
      }

      if (!isInGroundTime && validation.valid) {
        validation = {
          valid: false,
          reason: GROUND_TIME,
          legIndex: index - 1
        };
      }

      if (!isAircraftValid && validation.valid) {
        validation.valid = false;
      }
    }
  });
  return validation;
}
