import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PassengersAddContainer from '../../containers/passengers/PassengersAddContainer';
import PassengersListContainer from '../../containers/passengers/PassengersListContainer';
import PassengersDetailContainer from '../../containers/passengers/PassengersDetailContainer';
import AddPassportContainer from '../../containers/passengers/AddPassportContainer';
import AddNewPassenger from './AddNewPassenger';
import Counter from '../../components/Stepper';
import ConfirmationPopUp from '../shared/ConfirmationPopUpNew';
import SpinnerLoader from '../shared/spinnerLoader';
import MessagePopUpNew from '../shared/MessagePopUpNew';

import {
  existInLocal,
  getFromLocal,
  saveToLocal,
} from '../../utils/cache';
import { clone } from '../../utils/Helpers';
import { mergePassengersList } from '../../utils/passengerUtils';
import {
  PassengerList,
  PassengerSearch,
  PassengerForm,
  PassportForm,
  PassengerDetails,
} from '../../constants/passengerConstants';
import { garanteeArray } from '../../utils/sourceUtils';
import { PASSENGER_CANT_BE_REDUCED } from '../../constants/MessageConstants';
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants';
const headings = {
  ADD_PAX: 'Passengers',
  ADD_DETAIL: 'Select Passenger',
  ADD_NEW: 'Add New Passenger',
  ADD_PASSPORT: 'Add New Passport',
  PAX_DETAIL: 'Passenger Details',
};

class PassengerPopup extends Component {
  static propTypes = {
    closePopup: PropTypes.func,
    paxCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxNumberOfPassengers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showCounter: PropTypes.bool,
    disableConfirmBtn: PropTypes.bool,
    isError: PropTypes.bool,
    passengers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    deletedPax: PropTypes.array,
    handlePassengerConfirm: PropTypes.func,
    setError: PropTypes.func,
    dirtyChange: PropTypes.func,
    onItineraryDetail: PropTypes.bool,
    paxPopup: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      welcome: false,
      backBtnFlag: false,
      componentsToRender: PassengerList,
      referenceScreen: 0,
      passengerDirtyChange: false,
      passportDirtyChange: false,
      showLoaderPassenger: false,
      isSearch: false,
      newPassenger: {},
      paxCount: props.paxCount,
      heading: headings.ADD_PAX,
    };
    this.props.initializePopup(
      clone(props.passengers || []),
      clone(props.deletedPax || []),
    );
    this.referrerManager = {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isError) {
      this.setState({ showLoader: false });
      this.props.setError(false);
    }
  }
  // add/update state when user navigate to next state
  setReferrer(nextCmp, ref) {
    this.referrerManager[nextCmp] = ref;
  }

  // get the parent state of current component
  getReferrer(currentCmp) {
    return this.referrerManager[currentCmp] || null;
  }

  isShowBackBtn = (cmps) => (
    !!this.referrerManager[cmps]
  );

  //paxPopup
  handleBackButton = () => {
    const {
      passportDirtyChange,
      passengerDirtyChange,
      backBtnFlag,
      componentsToRender,
    } = this.state;

    const {
      showConfirmationPopup
    } = this.props;

    if (passportDirtyChange || passengerDirtyChange ||
      (showConfirmationPopup && componentsToRender === PassengerList )) {
      this.changesLostPopup.show();
    } 
    else {
      if (!backBtnFlag) {
        this.closeMainPopup();
      } else {
        const referrerCmps = this.getReferrer(this.state.componentsToRender);
        this.setState({
          backBtnFlag: this.isShowBackBtn(referrerCmps),
          referenceScreen: referrerCmps,
        });
        this.handleRenderingChange(referrerCmps);
      }
    }
  }

  handleRemovePassport = (passportNumber, passengerId) => {
    this.handleDirtyChange(true);
    this.props.removePassport(passportNumber, passengerId);
  }

  handleRenderingChange = (to, ref) => {
    let heading = headings.ADD_PAX;
    if (!!ref) {
      this.setReferrer(to, ref);
    }
    switch (to) {
      case PassengerForm: {
        heading = headings.ADD_NEW;
        this.props.clearSelectedPassenger();
        break;
      }
      case PassportForm: {
        heading = headings.ADD_PASSPORT;
        break;
      }
      case PassengerSearch: {
        heading = headings.ADD_DETAIL;
        break;
      }
      case PassengerDetails: {
        heading = headings.PAX_DETAIL;
        break;
      }
      default: {
        heading = headings.ADD_PAX;
      }
    }
    this.setState({
      componentsToRender: to,
      referenceScreen: ref,
      backBtnFlag: this.isShowBackBtn(to),
      heading,
      isSearch: false,
    });
  }

  handlePaxCount = (count) => {
    this.handleDirtyChange(true);
    this.setState({
      paxCount: count,
    });
  }

  handleAddPassportFromPax = (data) => {
    this.handleDirtyChange(true);
    this.setState({
      newPassenger: data,
    });
  }

  // add new passport click to existing passenger
  handleAddNewPassport = (from, currentPaxSelectedId) => {
    this.handleDirtyChange(true);
    this.props.updateSelectedPassenger(currentPaxSelectedId);
    this.handleRenderingChange(PassportForm, from);
  }

  // add new passport to passenger
  handleAddPassportToPax = (data, passengerId = null) => {
    this.handleDirtyChange(true);
    this.handlePassportDirtyChange(false);
    this.state.passportDirtyChange = false;
    if (passengerId) {
      this.props.updatePassport(data, passengerId);
      this.handleBackButton();
      return;
    }
    const newPassenger = this.state.newPassenger;
    this.handlePassengerDirtyChange(true);
    if (!newPassenger.passports) {
      newPassenger.passports = [];
    }
    if (data.isUpdated) {
      newPassenger.passports.forEach((passport) => {
        const pass = passport;
        if (passport.passportNumber === data.passportNumber) {
          pass.displayName = data.displayName;
          pass.expiry = data.expiry;
          pass.isUpdated = data.isUpdated;
          pass.isoPassportCountry = data.isoPassportCountry;
          pass.isoPassportIssuedByCountry = data.isoPassportIssuedByCountry;
          pass.passportCountry = data.passportCountry;
          pass.passportIssuedByCountry = data.passportIssuedByCountry;
        }
      });
    } else {
      newPassenger.passports.push(data);
    }
    this.setState({
      newPassenger,
    });
    this.handleBackButton();
  }

  handleConfirm = (addedPax, deletedPax) => {
    const { paxCount } = this.state;
    if (this.props.handlePassengerConfirm) {
      this.props.handlePassengerConfirm(addedPax, deletedPax, paxCount);
    }
    const frequentPax = existInLocal('frequentPax') ?
    garanteeArray(getFromLocal('frequentPax', true)) : [];
    const cacheFrequent = mergePassengersList(addedPax, frequentPax);
    cacheFrequent.forEach(pax => {
      const tempPax = pax;
      tempPax.isUpdated = false;
      tempPax.isAdded = false;
      tempPax.isApproved = false;
      tempPax.leadPassenger = false;
      tempPax.passports.forEach(pass => {
        const tempPass = pass;
        tempPass.isLegPassport = false;
      });
    });
    saveToLocal(cacheFrequent, 'frequentPax', true);
  }
  handleConfirmClick = () => {
    this.handleConfirm(this.props.addedPax, this.props.deletedPax);
    this.setState({ showLoaderPassenger: true });
  }
  // added new passenger from form
  handleSubmitNewPassenger = (data) => {
    this.handleDirtyChange(true);
    this.setState({
      newPassenger: {},
    });
    this.handlePassengerDirtyChange(false);
    this.props.updateAddedPassengers(data);
    this.handleRenderingChange(PassengerList);
  }

  handleViewDetail = (paxId) => {
    this.props.updateSelectedPassenger(paxId);
    this.handleRenderingChange(PassengerDetails, PassengerList);
  }

  handleClosePopup = () => {
    this.props.closePopup();
  }

  handlePassportDirtyChange = (bool) => {
    dirtystateSingleTon.setDirty(SPAConstants.ItineraryPassengersPassport, bool);
    this.setState({
      passportDirtyChange: bool,
    });
  }

  handlePassengerDirtyChange = (bool) => {
    dirtystateSingleTon.setDirty(SPAConstants.ItineraryNewPassengers, bool);
    this.setState({
      passengerDirtyChange: bool,
    });
  }

  handleDirtyChange = (bool) => {
    this.props.dirtyChange(bool);
  }

  isSearch = (bool) => {
    this.setState({ isSearch: bool });
  }

  handleCounterChange = (count) => {
    const {
      paxCount,
      addedPax
    } = this.props;
    if (addedPax && (count < addedPax.length)) {
      // const removePaxId = addedPax[addedPax.length-1].passengerId;
      // this.props.removeUser(removePaxId);
      this.setState({
        passengerRemoveLimitError: PASSENGER_CANT_BE_REDUCED,
      });
      this.removePaxFromCounter.show();
    } else {
      this.handlePaxCount(count);
    }
  }

  handleconfirmNavigation = () => {
    this.changesLostPopup.hide();
    const currentComp = this.state.componentsToRender;
    if (currentComp === PassengerForm) {
      dirtystateSingleTon.setDirty(SPAConstants.ItineraryNewPassengers, false);
      this.setState({
        newPassenger: {},
        passengerDirtyChange: false,
      });
    }
    if (currentComp === PassportForm) {
      this.handlePassportDirtyChange(false);
    }
    const ref = this.getReferrer(currentComp);
    this.handleRenderingChange(ref);
  }
  closeChangesLostPopup = () => {
    this.changesLostPopup.hide();
  }
  closeMainPopup = () => {
    this.props.paxPopup();
  }
  handleAddPassenger = () => {
    this.handleRenderingChange(PassengerSearch, PassengerList);
  }
  onHandleCloseMessage = () => {
    this.setState({
      passengerRemoveLimitError: ''
    })
  }
  onMoveAway = () => {
    const {
      passportDirtyChange,
      passengerDirtyChange,
      componentsToRender,
    } = this.state;
    const {
      showConfirmationPopup,
    } = this.props;

    if (showConfirmationPopup && componentsToRender === PassengerList) {
      this.handleClosePopup();
      this.changesLostPopup.hide();
      this.props.paxPopup();
    } else if (passportDirtyChange || passengerDirtyChange) {
      this.handleconfirmNavigation()
    }
  }
  disableAddPassengerButton = () => {
    const { addedPax, maxNumberOfPassengers } = this.props
    if (addedPax.length < maxNumberOfPassengers) {
      return false;
    }
    return true;
  }
  render() {
    const {
      componentsToRender,
      backBtnFlag,
      paxCount,
      newPassenger,
      isSearch,
      heading,
      showLoaderPassenger,
    } = this.state;
    const {
      maxNumberOfPassengers,
      showCounter,
      onItineraryDetail,
      disableConfirmBtn,
      isError,
      setError,
      showStepper,
    } = this.props;
    const currentScreen = [];
    switch (componentsToRender) {
      case PassengerList: {
        currentScreen.push(<PassengersListContainer
          key={PassengerList}
          handleRenderingChange={this.handleRenderingChange}
          paxCount={paxCount}
          maxNumberOfPassengers={maxNumberOfPassengers}
          handlePaxCount={this.handlePaxCount}
          showCounter={showCounter}
          handleViewDetail={this.handleViewDetail}
          handleAddNewPassport={this.handleAddNewPassport}
          handleRemovePassport={this.handleRemovePassport}
          handleConfirm={this.handleConfirm}
          disableConfirmBtn={disableConfirmBtn}
          isError={isError}
          setError={setError}
          handleDirtyChange={this.handleDirtyChange}
          onItineraryDetail={!onItineraryDetail}
          showLoaderPassenger={showLoaderPassenger}
        />);
        break;
      }
      case PassengerSearch: {
        currentScreen.push(<PassengersAddContainer
          key={PassengerSearch}
          handleRenderingChange={this.handleRenderingChange}
          handleDirtyChange={this.handleDirtyChange}
          isSearch={this.isSearch}
        />);
        break;
      }
      case PassengerForm: {
        currentScreen.push(<AddNewPassenger
          key={PassengerForm}
          handleRenderingChange={this.handleRenderingChange}
          handleAddPassportFromPax={this.handleAddPassportFromPax}
          newPassenger={newPassenger}
          submitNewPassenger={this.handleSubmitNewPassenger}
          handleDirtyChange={this.handlePassengerDirtyChange}
          handleRemovePassport={this.handleRemovePassport}
          setLegPassport = {this.props.setLegPassport}
        />);
        break;
      }
      case PassportForm: {
        currentScreen.push(<AddPassportContainer
          key={PassportForm}
          handleRenderingChange={this.handleRenderingChange}
          handleAddPassportToPax={this.handleAddPassportToPax}
          newPassenger={newPassenger}
          handleDirtyChange={this.handlePassportDirtyChange}
        />);
        break;
      }
      case PassengerDetails: {
        currentScreen.push(<PassengersDetailContainer
          key={PassengerDetails}
          handleRenderingChange={this.handleRenderingChange}
          handleAddNewPassport={this.handleAddNewPassport}
          handleRemovePassport={this.handleRemovePassport}
          handleDirtyChange={this.handleDirtyChange}
        />);
        break;
      }
      default: {
        currentScreen.push(<PassengersListContainer
          key={PassengerList}
          handleRenderingChange={this.handleRenderingChange}
          paxCount={paxCount}
          maxNumberOfPassengers={maxNumberOfPassengers}
          handlePaxCount={this.handlePaxCount}
          showCounter={showCounter}
          handleViewDetail={this.handleViewDetail}
          handleAddNewPassport={this.handleAddNewPassport}
          handleRemovePassport={this.handleRemovePassport}
          handleConfirm={this.handleConfirm}
          disableConfirmBtn={disableConfirmBtn}
          isError={isError}
          setError={setError}
          handleDirtyChange={this.handleDirtyChange}
        />);
      }
    }
    let heading1 = '';
    if (isSearch) {
      heading1 = headings.ADD_PAX;
    }
    const zeroPax = paxCount === 0;
    const topClass = (componentsToRender !== PassportForm) ?
    `vistajet_modal_height`: ``;
    const buttonClass = (showLoaderPassenger || disableConfirmBtn || zeroPax) ?
    `vistajet_passenger_modal_button uk-button vistajet_disabled uk-width-1-1 uk-text-center`:
    `vistajet_passenger_modal_button uk-button uk-button-default uk-width-1-1 uk-text-center`;

    return (
        <div id="modal-passenger" className={`${topClass} vistajet_passenger_modal_parent`}>
            <div className="">
                <div className="vistajet_modal_header_fix">
                    <a href="#" onClick={this.handleBackButton}><span>Back</span></a>
                    <h4 className="uk-text-center vistajet_passenger_modal_heading">{heading1 || heading}</h4>
                     {(componentsToRender === PassengerList && !showStepper) ?
                      <button className="vistajet_button_default uk-margin-remove add_passenger_button_passenger" onClick={this.handleAddPassenger}
                       disabled = {this.disableAddPassengerButton()}>
                        Add
                      </button> 
                      : '' }
                      {(componentsToRender === PassengerList && showStepper) ? 
                      <div className="vistajet_input vistajet_stepper_icon vistajet_modal_header_counter pax_popup_counter">
                        <Counter
                          value={paxCount}
                          maxValue={maxNumberOfPassengers}
                          onChange={this.handleCounterChange}
                          // minValue={1}
                          showPassengerLabel={false}
                        />
                      </div> : ''}
                </div>
                <div className="vistajet_modal_content uk-margin-remove-bottom uk-align-center uk-width-1-1@s vistajet_primary_form vistajet_passenger_search_form">
                  {showLoaderPassenger  &&
                    <div className="vistajet_spinner">
                      <SpinnerLoader/>
                    </div>}
                 {currentScreen}
                </div>
                {componentsToRender === PassengerList &&
                  <div className="vistajet_modal_footer" style={{ position: 'absolute'}}>
                    <button className={buttonClass}
                      disabled={showLoaderPassenger || disableConfirmBtn || zeroPax}
                      onClick={this.handleConfirmClick}>Confirm</button>
                </div> }
            </div>
            <ConfirmationPopUp
              title="Confirmation"
              ref={(changesLostPopup) => { this.changesLostPopup = changesLostPopup; }}
              id="changesLost"
              leftButtonContent="Stay on this page"
              rightButtonContent="Move away"
              onLeftButtonClick={this.closeChangesLostPopup}
              onRightButtonClick={this.onMoveAway}
              content={`Your changes will be lost if you move away from this page.
              Do you wish to move away from this page?`}
            />
            <MessagePopUpNew
              ref={(removePaxFromCounter) => { this.removePaxFromCounter = removePaxFromCounter; }}
              onClose={this.onHandleCloseMessage}
              content={this.state.passengerRemoveLimitError}
            />
        </div>
    );
  }
}
PassengerPopup.defaultProps = {
  paxCount: 0,
  maxNumberOfPassengers: 14,
};
export default PassengerPopup;
