import DirectSignupSource from '../sources/DirectSignupSource'; // eslint-disable-line
import * as types from '../actionTypes';

export const directSignupInit = () => ({
  type: types.DIRECT_SIGNUP_INIT,
});

export const directSignupSuccess = data => ({
  type: types.DIRECT_SIGNUP_SUCCESS,
  data,
});

export const directSignupFail = data => ({
  type: types.DIRECT_SIGNUP_FAIL,
  data,
});

export const directSignupSourceAction = (token, payload) => DirectSignupSource(token, payload); // eslint-disable-line
