import React from 'react';
import PropTypes from 'prop-types';

class Stepper extends React.Component {

  OPERATOR = {
    SUB: 'sub',
    ADD: 'add'
  }
  static propTypes = {
    onClick: PropTypes.func,
    index: PropTypes.number,
    value: PropTypes.number,
  };

  static defaultProps = {
    onClick: undefined,
    index: 0,
    value: 1,
    minValue: 1,
    showPassengerLabel: true,
  };

  onClick = (operator) => {
    let { value, maxValue, minValue } = this.props;
    if (operator === this.OPERATOR.ADD && value < maxValue) {
      // value += 1;
      this.props.onChange(value + 1);
    }
    else if (operator === this.OPERATOR.SUB && value > minValue) {
      // value -= 1;
      this.props.onChange(value - 1);
    }
    // this.props.onChange(value);
  };

  render() {
    const { value, showPassengerLabel, maxValue, minValue } = this.props;
    const isDisableAdd = !(value < maxValue);
    const isDisableSub = !(value > minValue);
    return (
      <div className="vistajet_passengers_picker disable-dbl-tap-zoom">
        {/* <img src={vistaJetPangersIcon} /> */}
        {showPassengerLabel && <span className="vistajet_passengers_lable">Passengers</span>}
        <div className="vistajet_count_passengers disable-dbl-tap-zoom">
          <button className={`vistajet_minus_button ${isDisableSub ? "disable_stepper_button" : ""} vistajet_mobile_icons`} onClick={() => this.onClick("sub")}><img src="/legacy/static/img/icons/vistajet_minus_icon.svg" alt="subtract" /></button>
          <span className="inlineDiv vistajet_mobile_stepper disable-dbl-tap-zoom">{value}</span>
          <button className={`vistajet_plus_button ${isDisableAdd ? "disable_stepper_button" : ""} vistajet_mobile_icons`} onClick={() => this.onClick("add")}><img src="/legacy/static/img/icons/vistajet_plus_two_icon.svg" alt="add" /></button>
        </div>
        {/* <button
          className="vistajet_plus_button"
          onClick={() => this.onClick('add')}
        >
          {'+'}
        </button> */}
      </div>
    );
  }
}

export default Stepper;
