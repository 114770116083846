/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { parseXmlStringToJson } from "../../utils/parserUtils";

import {
  fetchOffersInit,
  fetchOffersSuccess,
  fetchOffersFail
} from "../actions";

import { FETCH_OFFERS_URL } from "../../configs/constants";
import { normalizeOffers } from "../../normalizers/offerNormalizer";
import status from "../../configs/status";
import { handleServerErrors } from "utils/sourceUtils";
import { BAD_REQUEST, TIMEOUT_CODE } from "constants/ServerCodeConstants";

const fetchOffersPromiseInprogress = {};

export const fetchOffers = (pageNum, pageSize, regionId) => dispatch => {
  const uniqueKey = `${pageNum}-${pageSize}-${regionId}`;
  if (fetchOffersPromiseInprogress[uniqueKey]) {
    return fetchOffersPromiseInprogress[uniqueKey];
  }
  const fetchOffersPromise = new Promise((resolve, reject) => {
    dispatch(fetchOffersInit());

    const offersPayload = regionId
      ? `<offerRequestDto>
    <pageNumber>${pageNum}</pageNumber>
    <pageSize>${pageSize}</pageSize>
    <regionId>${regionId}</regionId>
  </offerRequestDto>`
      : `<offerRequestDto>
      <pageNumber>${pageNum}</pageNumber>
      <pageSize>${pageSize}</pageSize>
    </offerRequestDto>`;

    const options = {
      headers: {
        "Content-Type": "application/xml"
      }
    };

    axios
      .post(FETCH_OFFERS_URL, offersPayload, options)
      .then(response => {
        if (response.status === status.OK) {
          const parsedData = parseXmlStringToJson(response.data);
          if (parsedData.responseDto.responseHeader.isError === true) {
            const error = parsedData.responseDto.responseHeader.statusCode;
            dispatch(fetchOffersFail(handleServerErrors(error)));
            reject();
          } else if (!parsedData.responseDto.responseBody) {
            const normalizedOffers = {
              offers: [],
              totalOffers: 0,
              isFetched: true,
            };
            dispatch(fetchOffersSuccess(normalizedOffers));
            resolve(normalizedOffers);
          } else {
            const data = parsedData.responseDto.responseBody;
            const normalizedOffers = regionId ? normalizeOffers(data,regionId) : normalizeOffers(data);
            dispatch(fetchOffersSuccess(normalizedOffers));
            resolve(normalizedOffers);
          }
        } else {
          dispatch(fetchOffersFail(handleServerErrors(BAD_REQUEST)));
          reject();
        }
        delete fetchOffersPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        if (err.code === TIMEOUT_CODE.status) {
          dispatch(fetchOffersFail(handleServerErrors(TIMEOUT_CODE.code)));
        } else if (!err.code) {
          dispatch(fetchOffersFail(handleServerErrors()));
        } else { 
          dispatch(fetchOffersFail(handleServerErrors(BAD_REQUEST)))
        };
        reject();
        delete fetchOffersPromiseInprogress[uniqueKey];
      });
  });
  fetchOffersPromiseInprogress[uniqueKey] = fetchOffersPromise;
  return fetchOffersPromise;
};

export default fetchOffers;
