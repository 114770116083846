import { fetchOffers } from '../sources/OffersSource';
import * as types from '../actionTypes';

export const fetchOffersInit = () => ({
  type: types.FETCH_OFFERS_INIT,
});

export const fetchOffersSuccess = data => ({
  type: types.FETCH_OFFERS_SUCCESS,
  data,
});

export const fetchOffersFail = data => ({
  type: types.FETCH_OFFERS_FAIL,
  data,
});

export const fetchAllOffers = (pageNum, pageSize, regionId) => fetchOffers(
  pageNum,
  pageSize,
  regionId,
);
