import requestFSPFlights from "../sources/PAFSource";
import {
  CHECK_CONTRACT_SELECTED,
  UPDATE_CONTRACT_SELECTED,
  UPDATE_DEPARTURE_AIRPORT,
  UPDATE_ARRIVAL_AIRPORT,
  UPDATE_DATE,
  UPDATE_AIRCRAFT,
  UPDATE_PASSENGERS_COUNT,
  UPDATE_DURATION,
  UPDATE_TIME,
  ADD_LEG,
  UPDATE_ESTIMATE_TIME,
  REQUEST_FLIGHT_INIT,
  REQUEST_FLIGHT_SUCCESS,
  REQUEST_FLIGHT_FAIL
} from "../actionTypes";

export const requestFlightInit = () => ({
  type: REQUEST_FLIGHT_INIT
});

export const requestFlightSuccess = data => ({
  type: REQUEST_FLIGHT_SUCCESS,
  data
});

export const requestFlightFail = data => ({
  type: REQUEST_FLIGHT_FAIL,
  data
});

export const requestAllFSPFlights = (flightLegs, accountId, isEdit, order,passengers) =>
  requestFSPFlights(flightLegs, accountId, isEdit, order, passengers);

export const checkContractSelected = data => ({
  type: CHECK_CONTRACT_SELECTED,
  data
});

export const updateContractSelected = accountId => ({
  type: UPDATE_CONTRACT_SELECTED,
  data: accountId
});

export const updateDepartureAirport = departure => ({
  type: UPDATE_DEPARTURE_AIRPORT,
  data: departure
});

export const updateArrivalAirport = arrival => ({
  type: UPDATE_ARRIVAL_AIRPORT,
  data: arrival
});

export const updateDate = dateDetails => {
  return { type: UPDATE_DATE, data: dateDetails };
};

export const updateSelectedAircraft = selectedAircraftDetails => {
  return { type: UPDATE_AIRCRAFT, data: selectedAircraftDetails };
};

export const updatePassengersCounter = updatePassengersCount => {
  return { type: UPDATE_PASSENGERS_COUNT, data: updatePassengersCount };
};

export const updateDuration = legIndex => {
  return {
    type: UPDATE_DURATION,
    data: legIndex
  };
};

export const updateTime = updateTime => {
  return {
    type: UPDATE_TIME,
    data: updateTime
  };
};
export const updateEstimateTime = timeObject => {
  return {
    type: UPDATE_ESTIMATE_TIME,
    data: timeObject
  };
};
export const addLeg = () => {
  return {
    type: ADD_LEG
  };
};
