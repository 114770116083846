import {
  passportNumberValidator,
  expiresOnDatevalidator,
  displayNameValidator,
  isoPassportIssueAndIssuingCountryValidator,
} from './Validators';

export default function validateAddEditPassportForm(obj) {
  const passportNumberResponse = passportNumberValidator(obj.paxNumber);
  const expiryDateResponse = expiresOnDatevalidator(obj.expiryDate);
  const displayNameResponse = displayNameValidator(obj.paxName);
  const nationalityResponse = isoPassportIssueAndIssuingCountryValidator(
    obj.nationality.code,
  );
  const issuingCountryResponse = isoPassportIssueAndIssuingCountryValidator(
    obj.issuingCountry.code, true,
  );
  const res = {
    errors: {},
  };
  if (passportNumberResponse.isError) {
    res.isError = passportNumberResponse.isError;
    res.errors.paxNumber = passportNumberResponse.message;
  }
  if (expiryDateResponse.isError) {
    res.isError = expiryDateResponse.isError;
    res.errors.expiryDate = expiryDateResponse.message;
  }
  if (displayNameResponse.isError) {
    res.isError = displayNameResponse.isError;
    res.errors.paxName = displayNameResponse.message;
  }
  if (nationalityResponse.isError) {
    res.isError = nationalityResponse.isError;
    res.errors.nationality = nationalityResponse.message;
  }
  if (issuingCountryResponse.isError) {
    res.isError = issuingCountryResponse.isError;
    res.errors.issuingCountry = issuingCountryResponse.message;
  }
  return res;
}
