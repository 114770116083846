import { connect } from 'react-redux';
import withStore from '../components/withStore';
import UserProfile from '../components/UserProfile/UserProfile';
import * as actions from '../store/actions';

function mapStateToProps(state) {
  const {
    isPasswordChangeLoading,
  } = state.user;
  const { user } = state;
  const { creditCards, isAdded, isDeleted } = state.creditCardReducer;
  const { notifications, cities } = state.pushNotificationReduer;
  const {
    membershipAccountLoading,
    isDirectAccountBalanceError,
    isMemebershipAccountFetched,
    directAccoutBalanceError,
    accountData,
  } = state.directAccountBalance;
  return {
    user,
    isTokenError: state.token.isTokenError,
    tokenError: state.token.tokenError,
    isAuthError: state.token.isTokenAuthError,
    authError: state.token.tokenAuthError,
    isPasswordChangeLoading,
    creditCards,
    isAddedCreditCard: isAdded,
    isDeletedCreditCard: isDeleted,
    isAddCreditCardLoading: state.creditCardReducer.isLoading,
    isAddCreditCardError: state.creditCardReducer.isError,
    addCreditCardLoadingError: state.creditCardReducer.error,
    creditCardMessage: state.creditCardReducer.message,
    notifications,
    cities,
    pushNotificationFetched: state.pushNotificationReduer.isFetched,
    isPushNotificationLoading: state.pushNotificationReduer.isLoading,
    isPushNotificationError: state.pushNotificationReduer.isError,
    pushNotificationError: state.pushNotificationReduer.error,
    isPushNotificationUpdated: state.pushNotificationReduer.isUpdated,
    accountData,
    membershipAccountLoading,
    isDirectAccountBalanceError,
    isMemebershipAccountFetched,
    directAccoutBalanceError,
    isAccountDataFetched: state.user.isAccountDataFetched,
    isAccountDataFetchedError: state.user.isAccountDataFetchedError,
    isAccountDataFail: state.user.isAccountDataFail,
    accountDataError: state.user.accountDataError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAdminToken: () => dispatch(actions.fetchAdminToken()),
    getToken: () => dispatch(actions.fetchToken()),
    authenticateTokenAction: token => dispatch(actions.authenticateTokenAction(token)),
    logout: token => dispatch(actions.logoutAction(token)),
    changePasswordAction: payload => dispatch(actions.changePasswordAction(payload)),
    getCreditCardList: () => dispatch(actions.getAllCreditCards()),
    addCreditCardAction: (token, payload) => dispatch(actions.addCreditCardAction(token, payload)),
    deleteCreditCardAction: (token, payload) => dispatch(actions.deleteCreditCardAction(
      token,
      payload,
    )),
    updateUserProfileAction: (
      payload,
      user,
      passports,
      flightLegId,
      paxOperationId,
    ) => dispatch(actions.updateUserProfileAction(
      payload,
      user,
      passports,
      flightLegId,
      paxOperationId,
    )),
    fetchPushNotification: (token) => dispatch(actions.fetchPushNotificationAction(token)),
    updateNotifications: (
      token,
      notifications,
      cities,
      disableToggle,
    ) => dispatch(actions.updatePushNotificationAction(
      token,
      notifications,
      cities,
      disableToggle,
    )),
    getDirectAccountStatement: (token, currentLength, isLoadMore) => dispatch(
      actions.MembershipAccountStatement(
        token,
        currentLength,
        isLoadMore,
      ),
    ),
    getAccountStatusAction: token => dispatch(actions.getAccountStatusAction(token)),
  };
}

const UserProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfile);

export default withStore(UserProfileContainer);
