import axios from 'axios';
import { FETCH_MOVEMENT_MESSAGES } from '../../configs/constants';
import { parseXmlStringToJson } from "../../utils/parserUtils";
import {
  fetchMovementMessagesInit,
  fetchMovementMessagesSuccess,
  fetchMovementMessagesFail,
} from '../actions';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';

const status = {
  OK: 200,
};

const movementMessagesPromiseInprogress = {};

const fetchMovementMessagesSource = (token, flightLegId) => (dispatch) => {
  const uniqueKey = flightLegId;
  if (movementMessagesPromiseInprogress[uniqueKey]) {
    return movementMessagesPromiseInprogress[uniqueKey];
  }
  const movementMessagesPromise = new Promise((resolve, reject) => {
    const url = FETCH_MOVEMENT_MESSAGES.replace('{token}', token).replace('{flightId}', flightLegId);
    dispatch(fetchMovementMessagesInit(flightLegId));
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          const parsedData = parseXmlStringToJson(response.data);
          dispatch(fetchMovementMessagesSuccess(parsedData, flightLegId));
          resolve(parsedData);
        } else {
          const parsedData = parseXmlStringToJson(response.data);
          const errors = handleServerErrors(parsedData.status);
          dispatch(fetchMovementMessagesFail(errors, flightLegId));
          reject(errors);
        }
        delete movementMessagesPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(fetchMovementMessagesFail(errors, flightLegId));
        reject(errors);
        delete movementMessagesPromiseInprogress[uniqueKey];
      });
  });
  movementMessagesPromiseInprogress[uniqueKey] = movementMessagesPromise;
  return movementMessagesPromise;
};

export default fetchMovementMessagesSource;
