// Admin Token
export const ADMIN_FETCH_TOKEN_INIT = 'ADMIN_FETCH_TOKEN_INIT';
export const ADMIN_FETCH_TOKEN_SUCCESS = 'ADMIN_FETCH_TOKEN_SUCCESS';
export const ADMIN_FETCH_TOKEN_FAIL = 'ADMIN_FETCH_TOKEN_FAIL';

// Authentication Token
export const AUTHENTICATE_TOKEN_INIT = 'AUTHENTICATE_TOKEN_INIT';
export const AUTHENTICATE_TOKEN_SUCCESS = 'AUTHENTICATE_TOKEN_SUCCESS';
export const AUTHENTICATE_TOKEN_FAIL = 'AUTHENTICATE_TOKEN_FAIL';

// Contact Us
export const POST_CONTACTUS_INIT = 'POST_CONTACTUS_INIT';
export const POST_CONTACTUS_SUCCESS = 'POST_CONTACTUS_SUCCESS';
export const POST_CONTACTUS_FAIL = 'POST_CONTACTUS_FAIL';
export const POST_CONTACTUS_REFRESH = 'POST_CONTACTUS_REFRESH';

// GeoCode
export const FETCH_GEOCODE_INIT = 'FETCH_GEOCODE_INIT';
export const FETCH_GEOCODE_SUCCESS = 'FETCH_GEOCODE_SUCCESS';
export const FETCH_GEOCODE_FAIL = 'FETCH_GEOCODE_FAIL';

// Google Map API
export const GOOGLE_MAPS_API_LOADED = 'GOOGLE_MAPS_API_LOADED';
export const PLACES_AUTOCOMPLETE_BINDED = 'PLACES_AUTOCOMPLETE_BINDED';

// Offers
export const FETCH_OFFERS_INIT = 'FETCH_OFFERS_INIT';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAIL = 'FETCH_OFFERS_FAIL';

// Registation
export const REGISTRATION_INIT = 'REGISTRATION_INIT';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';

// Register
export const REGISTER_INIT = 'REGISTER_INIT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// Verify
export const VERIFY_USER_INIT = 'VERIFY_USER_INIT';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAIL = 'VERIFY_USER_FAIL';

// Resend Register Code
export const FETCH_REGISTER_CODE_INIT = 'FETCH_REGISTER_CODE_INIT';
export const FETCH_REGISTER_CODE_SUCCESS = 'FETCH_REGISTER_CODE_SUCCESS';
export const FETCH_REGISTER_CODE_FAIL = 'FETCH_REGISTER_CODE_FAIL';

// Term
export const FETCH_TERMS_INIT = 'FETCH_TERMS_INIT';
export const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS';
export const FETCH_TERMS_FAILURE = 'FETCH_TERMS_FAIL';

export const FETCH_POLICY_INIT = 'FETCH_POLICY_INIT';
export const FETCH_POLICY_SUCCESS = 'FETCH_POLICY_SUCCESS';
export const FETCH_POLICY_FAILURE = 'FETCH_POLICY_FAIL';

// Token
export const FETCH_TOKEN_INIT = 'FETCH_TOKEN_INIT';
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS';
export const FETCH_TOKEN_FAIL = 'FETCH_TOKEN_FAIL';

// Token Registration Flow
export const FETCH_REGISTRATION_TOKEN_INIT = 'FETCH_REGISTRATION_TOKEN_INIT';
export const FETCH_REGISTRATION_TOKEN_SUCCESS = 'FETCH_REGISTRATION_TOKEN_SUCCESS';
export const FETCH_REGISTRATION_TOKEN_FAIL = 'FETCH_REGISTRATION_TOKEN_FAIL';

// Login
export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_NOT_VERIFIED_SUCCESS = 'LOGIN_NOT_VERIFIED_SUCCESS';

// Forgot Password
export const FORGOT_INIT = 'FORGOT_INIT';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAILURE = 'FORGOT_FAILURE';
export const RESEND_FORGOT_EMAIL = 'RESEND_FORGOT_EMAIL';
export const CLEAR_FORGOT_ERROR_MESSAGE = 'CLEAR_FORGOT_ERROR_MESSAGE';
export const CLEAR_FORGOT_PASSWORD_RESENT_EMAIL_MESSAGE = 'CLEAR_FORGOT_PASSWORD_RESENT_EMAIL_MESSAGE';
export const CLEAR_NOT_VERIFIED_USER = 'CLEAR_NOT_VERIFIED_USER';

// Logout
export const LOGOUT = 'LOGOUT';
export const LOGOUT_INIT = 'LOGOUT_INIT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

// Change Password
export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_REQUIRED = 'CHANGE_PASSWORD_REQUIRED';

// Visited Airports
export const FETCH_VISITED_AIRPORTS_INIT = 'FETCH_VISITED_AIRPORTS_INIT';
export const FETCH_VISITED_AIRPORTS_SUCCESS = 'FETCH_VISITED_AIRPORTS_SUCCESS';
export const FETCH_VISITED_AIRPORTS_FAIL = 'FETCH_VISITED_AIRPORTS_FAIL';

// Airport By Id
export const FETCH_AIRPORT_BY_ID_INIT = 'FETCH_AIRPORT_BY_ID_INIT';
export const FETCH_AIRPORT_BY_ID_SUCCESS = 'FETCH_AIRPORT_BY_ID_SUCCESS';
export const FETCH_AIRPORT_BY_ID_FAIL = 'FETCH_AIRPORT_BY_ID_FAIL';

// Direct Sign up
export const DIRECT_SIGNUP_INIT = 'DIRECT_SIGNUP_INIT';
export const DIRECT_SIGNUP_SUCCESS = 'DIRECT_SIGNUP_SUCCESS';
export const DIRECT_SIGNUP_FAIL = 'DIRECT_SIGNUP_FAIL';

// fetch membership
export const FETCH_MEMBERSHIP_INIT = 'FETCH_MEMBERSHIP_INIT';
export const FETCH_MEMBERSHIP_SUCCESS = 'FETCH_MEMBERSHIP_SUCCESS';
export const FETCH_MEMBERSHIP_FAIL = 'FETCH_MEMBERSHIP_FAIL';

// Credit Card Constants
export const ADD_CREDIT_CARD_INIT = 'ADD_CREDIT_CARD_INIT';
export const ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS';
export const UPDATE_CREDIT_CARD_SUCCESS = 'UPDATE_CREDIT_CARD_SUCCESS';
export const ADD_CREDIT_CARD_FAIL = 'ADD_CREDIT_CARD_FAIL';
export const CLEAR_CREDIT_CARD_STATE = 'CLEAR_CREDIT_CARD_STATE';
export const GET_CREDIT_CARD_LIST_INIT = 'GET_CREDIT_CARD_LIST_INIT';
export const GET_CREDIT_CARD_LIST_SUCCESS = 'GET_CREDIT_CARD_LIST_SUCCESS';
export const GET_CREDIT_CARD_LIST_FAIL = 'GET_CREDIT_CARD_LIST_FAIL';
export const DELETE_CREDIT_CARD_INIT = 'DELETE_CREDIT_CARD_INIT';
export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
export const DELETE_CREDIT_CARD_FAILURE = 'DELETE_CREDIT_CARD_FAILURE';

// Validate Flight
export const VALIDATE_FLIGHT_INIT = 'VALIDATE_FLIGHT_INIT';
export const VALIDATE_FLIGHT_SUCCESS = 'VALIDATE_FLIGHT_SUCCESS';
export const VALIDATE_FLIGHT_FAIL = 'VALIDATE_FLIGHT_FAIL';
export const RESET_FLIGHT_ERROR_MESSAGES = 'RESET_FLIGHT_ERROR_MESSAGES';

//PAF Constants
export const PAF_INIT = 'PAF_INIT';
export const PAF_SUCCESS = 'PAF_SUCCESS';
export const PAF_FAILURE = 'PAF_FAILURE';
export const REQUEST_FLIGHT_INIT = 'REQUEST_FLIGHT_INIT';
export const REQUEST_FLIGHT_SUCCESS = 'REQUEST_FLIGHT_SUCCESS';
export const REQUEST_FLIGHT_FAIL = 'REQUEST_FLIGHT_FAIL';
export const CHECK_CONTRACT_SELECTED = "CONTRACT_SELECTED";
export const UPDATE_CONTRACT_SELECTED = "UPDATE_CONTRACT_SELECTED";
export const UPDATE_DEPARTURE_AIRPORT = "UPDATE_DEPARTURE_AIRPORT";
export const UPDATE_ARRIVAL_AIRPORT = "UPDATE_ARRIVAL_AIRPORT";
export const UPDATE_DATE = "UPDATE_DATE";
export const UPDATE_AIRCRAFT = "UPDATE_AIRCRAFT";
export const UPDATE_DURATION = "UPDATE_DURATION";
export const UPDATE_PASSENGERS_COUNT = "UPDATE_PASSENGERS_COUNT";
export const UPDATE_TIME = "UPDATE_TIME";
export const UPDATE_ESTIMATE_TIME = "UPDATE_ESTIMATE_TIME";
export const ADD_LEG = "ADD_LEG";

// Itinerary Listing

export const FETCH_ITINERARIES_INIT = "FETCH_ITINERARIES_INIT";
export const FETCH_ITINERARIES_SUCCESS = "FETCH_ITINERARIES_SUCCESS";
export const FETCH_ITINERARIES_FAIL = "FETCH_ITINERARIES_FAIL";

// Itinerary Details

export const ITINERARY_DETAILS_INIT = 'ITINERARY_DETAILS_INIT';
export const ITINERARY_DETAILS_SUCCESS = 'ITINERARY_DETAILS_SUCCESS';
export const ITINERARY_DETAILS_FAIL = 'ITINERARY_DETAILS_FAIL';

// Itinerary PDF

export const ITINERARY_PDF_INIT = 'ITINERARY_PDF_INIT';
export const ITINERARY_PDF_SUCCESS = 'ITINERARY_PDF_SUCCESS';
export const ITINERARY_PDF_FAIL = 'ITINERARY_PDF_FAIL';

// Memebership constants
export const GET_ACCOUNT_STATUS_INIT = 'GET_ACCOUNT_STATUS_INIT';
export const GET_ACCOUNT_STATUS_SUCCESS = 'GET_ACCOUNT_STATUS_SUCCESS';
export const GET_ACCOUNT_STATUS_FAILURE = 'GET_ACCOUNT_STATUS_FAILURE';
// Avail Offer
export const AVAIL_OFFER_INIT = 'AVAIL_OFFER_INIT';
export const AVAIL_OFFER_SUCCESS = 'AVAIL_OFFER_SUCCESS';
export const AVAIL_OFFER_FAIL = 'AVAIL_OFFER_FAIL';
export const AVAIL_OFFER_QUOTING_NOT_AVAILABLE = 'AVAIL_OFFER_QUOTING_NOT_AVAILABLE';
export const UPDATE_QUOTE_CONSTANT = 'UPDATE_QUOTE_CONSTANT';

//Buy Offer/Quote
export const BUY_OFFER_INIT = 'BUY_OFFER_INIT';
export const BUY_OFFER_SUCCESS = 'BUY_OFFER_SUCCESS';
export const BUY_OFFER_FAIL = 'BUY_OFFER_FAIL';

//request quote
export const REQUEST_QUOTE_INIT = "REQUEST_QUOTE_INIT";
export const REQUEST_QUOTE_SUCCESS = "REQUEST_QUOTE_SUCCESS";
export const REQUEST_QUOTE_FAIL = "REQUEST_QUOTE_FAIL";

//create company
export const CREATE_COMPANY_INIT = 'CREATE_COMPANY_INIT';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';
export const UPDATE_USER_CREATE_COMPANY = 'UPDATE_USER_CREATE_COMPANY';

//entertainment
export const FETCH_ENTERTAINMENT_ISSUE_INIT = 'FETCH_ENTERTAINMENT_ISSUE_INIT';
export const FETCH_ENTERTAINMENT_ISSUE_SUCCESS = 'FETCH_ENTERTAINMENT_ISSUE_SUCCESS';
export const FETCH_ENTERTAINMENT_ISSUE_FAIL = 'FETCH_ENTERTAINMENT_ISSUE_FAIL';
export const FETCH_ENTERTAINMENT_INIT = 'FETCH_ENTERTAINMENT_INIT';
export const FETCH_ENTERTAINMENT_SUCCESS = 'FETCH_ENTERTAINMENT_SUCCESS';
export const FETCH_ENTERTAINMENT_FAIL = 'FETCH_ENTERTAINMENT_FAIL';

//fbo
export const FETCH_FBO_INIT = 'FETCH_FBO_INIT';
export const FETCH_FBO_SUCCESS = 'FETCH_FBO_SUCCESS';
export const FETCH_FBO_FAIL = 'FETCH_FBO_FAIL';

export const UPDATE_USER_REDUCER = 'UPDATE_USER_REDUCER';

//fetch unbilled flights
export const FETCH_UNBILLED_INIT = 'FETCH_UNBILLED_INIT';
export const FETCH_UNBILLED_SUCCESS = 'FETCH_UNBILLED_SUCCESS';
export const FETCH_UNBILLED_FAIL = 'FETCH_UNBILLED_FAIL';

//fetch recent invoices
export const FETCH_RECENT_INVOICES_INIT = 'FETCH_RECENT_INVOICES_INIT';
export const FETCH_RECENT_INVOICES_SUCCESS = 'FETCH_RECENT_INVOICES_SUCCESS';
export const FETCH_RECENT_INVOICES_FAIL = 'FETCH_RECENT_INVOICES_FAIL';

//fetch more invoices
export const FETCH_MORE_INVOICES_INIT = 'FETCH_MORE_INVOICES_INIT';
export const FETCH_MORE_INVOICES_SUCCESS = 'FETCH_MORE_INVOICES_SUCCESS';
export const FETCH_MORE_INVOICES_FAIL = 'FETCH_MORE_INVOICES_FAIL';

//fetch account statements
export const FETCH_ACCOUNT_STATEMENTS_INIT = 'FETCH_ACCOUNT_STATEMENTS_INIT';
export const FETCH_ACCOUNT_STATEMENTS_SUCCESS = 'FETCH_ACCOUNT_STATEMENTS_SUCCESS';
export const FETCH_ACCOUNT_STATEMENTS_FAIL = 'FETCH_ACCOUNT_STATEMENTS_FAIL';

//fetch statement invoices
export const FETCH_STATEMENT_INVOICES_INIT = 'FETCH_STATEMENT_INVOICES_INIT';
export const FETCH_STATEMENT_INVOICES_SUCCESS = 'FETCH_STATEMENT_INVOICES_SUCCESS';
export const FETCH_STATEMENT_INVOICES_FAIL = 'FETCH_STATEMENT_INVOICES_FAIL';
export const CLEAR_INVOICE_FILTER = 'CLEAR_INVOICE_FILTER';

//fetch account statement zip url
export const FETCH_ZIP_URL_INIT = 'FETCH_ZIP_URL_INIT';
export const FETCH_ZIP_URL_SUCCESS = 'FETCH_ZIP_URL_SUCCESS';
export const FETCH_ZIP_URL_FAIL = 'FETCH_ZIP_URL_FAIL';

//fetch account statement pdf
export const FETCH_ACCOUNT_STATEMENT_PDF_INIT = 'FETCH_ACCOUNT_STATEMENT_PDF_INIT';
export const FETCH_ACCOUNT_STATEMENT_PDF_SUCCESS = 'FETCH_ACCOUNT_STATEMENT_PDF_SUCCESS';
export const FETCH_ACCOUNT_STATEMENT_PDF_FAIL = 'FETCH_ACCOUNT_STATEMENT_PDF_FAIL';

export const CLEAR_PAS_ERROR = 'CLEAR_PAS_ERROR';
//TopUpReference
export const TOPUP_REFERENCE_INIT = 'TOPUP_REFERENCE_INIT';
export const TOPUP_REFERENCE_SUCCESS = 'TOPUP_REFERENCE_SUCCESS';
export const TOPUP_REFERENCE_FAIL = 'TOPUP_REFERENCE_FAIL';

//TopUp
export const TOPUP_INIT = 'TOPUP_INIT';
export const TOPUP_SUCCESS = 'TOPUP_SUCCESS';
export const TOPUP_FAIL = 'TOPUP_FAIL';

//TopUpHistory
export const TOPUP_HISTORY_INIT = 'TOPUP_HISTORY_INIT';
export const TOPUP_HISTORY_SUCCESS = 'TOPUP_HISTORY_SUCCESS';
export const TOPUP_HISTORY_FAIL = 'TOPUP_HISTORY_FAIL';

//cancelReferenceCode
export const CANCEL_REFERENCE_CODE_INIT = 'CANCEL_REFERENCE_CODE_INIT';
export const CANCEL_REFERENCE_CODE_SUCCESS = 'CANCEL_REFERENCE_CODE_SUCCESS';
export const CANCEL_REFERENCE_CODE_FAIL = 'CANCEL_REFERENCE_CODE_FAIL';

export const TOPUP_VIEW_ACTION = 'TOPUP_VIEW_ACTION';
export const CLEAR_TOPUP_ERROR = 'CLEAR_TOPUP_ERROR';
// Update User Profile constants
export const UPDATE_USER_PROFILE_INIT = 'UPDATE_USER_PROFILE_INIT';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
//Weather
export const FETCH_WEATHER_INIT = 'FETCH_WEATHER_INIT';
export const FETCH_WEATHER_SUCCESS = 'FETCH_WEATHER_SUCCESS';
export const FETCH_WEATHER_FAIL = 'FETCH_WEATHER_FAIL';

// Crew
export const FETCH_CREW_INIT = 'FETCH_CREW_INIT';
export const FETCH_CREW_SUCCESS = 'FETCH_CREW_SUCCESS';
export const FETCH_CREW_FAIL = 'FETCH_CREW_FAIL';

//ground services
export const FETCH_GROUND_SERVICES_INIT = 'FETCH_GROUND_SERVICES_INIT';
export const FETCH_GROUND_SERVICES_FAIL = 'FETCH_GROUND_SERVICES_FAIL';
export const FETCH_GROUND_SERVICES_SUCCESS = 'FETCH_GROUND_SERVICES_SUCCESS';

//availed services
export const FETCH_AVAILED_SERVICES_INIT = 'FETCH_AVAILED_SERVICES_INIT';
export const FETCH_AVAILED_SERVICES_FAIL = 'FETCH_AVAILED_SERVICES_FAIL';
export const FETCH_AVAILED_SERVICES_SUCCESS = 'FETCH_AVAILED_SERVICES_SUCCESS';

//add transport services
export const ADD_TRANSPORT_SERVICES_INIT = 'ADD_TRANSPORT_SERVICES_INIT';
export const ADD_TRANSPORT_SERVICES_SUCCESS = 'ADD_TRANSPORT_SERVICES_SUCCESS';
export const ADD_TRANSPORT_SERVICES_FAIL = 'ADD_TRANSPORT_SERVICES_FAIL';

//add additional services
export const ADD_ADDITIONAL_SERVICES_INIT = 'ADD_ADDITIONAL_SERVICES_INIT';
export const ADD_ADDITIONAL_SERVICES_SUCCESS = 'ADD_ADDITIONAL_SERVICES_SUCCESS';
export const ADD_ADDITIONAL_SERVICES_FAIL = 'ADD_ADDITIONAL_SERVICES_FAIL';


//Billing Address Constants
export const SAVE_BILLING_ADDRESS_INIT = 'SAVE_BILLING_ADDRESS_INIT';
export const SAVE_BILLING_ADDRESS_SUCCESS = 'SAVE_BILLING_ADDRESS_SUCCESS';
export const SAVE_BILLING_ADDRESS_FAILURE = 'SAVE_BILLING_ADDRESS_FAILURE';
export const GET_BILLING_ADDRESS_INIT = 'GET_BILLING_ADDRESS_INIT';
export const GET_BILLING_ADDRESS_SUCCESS = 'GET_BILLING_ADDRESS_SUCCESS';
export const GET_BILLING_ADDRESS_FAILURE = 'GET_BILLING_ADDRESS_FAILURE';


//catering constants
export const GET_CATERING_STATE_INIT = 'GET_CATERING_STATE_INIT';
export const GET_CATERING_STATE_SUCCESS = 'GET_CATERING_STATE_SUCCESS';
export const GET_CATERING_STATE_FAIL = 'GET_CATERING_STATE_FAIL';
export const GET_CATERING_PROPOSAL_INIT = 'GET_CATERING_PROPOSAL_INIT';
export const GET_CATERING_PROPOSAL_SUCCESS = 'GET_CATERING_PROPOSAL_SUCCESS';
export const GET_CATERING_PROPOSAL_FAIL = 'GET_CATERING_PROPOSAL_FAIL';
export const CHANGE_CATERING_PROPOSAL_INIT = 'CHANGE_CATERING_PROPOSAL_INIT';
export const CHANGE_CATERING_PROPOSAL_SUCCESS = 'CHANGE_CATERING_PROPOSAL_SUCCESS';
export const CHANGE_CATERING_PROPOSAL_FAIL = 'CHANGE_CATERING_PROPOSAL_FAIL';
export const GET_CATERING_PDF_INIT = 'GET_CATERING_PDF_INIT';
export const GET_CATERING_PDF_SUCCESS = 'GET_CATERING_PDF_SUCCESS';
export const GET_CATERING_PDF_FAIL = 'GET_CATERING_PDF_FAIL';

// Passenger
export const UPDATE_PASSENGER = 'UPDATE_PASSENGER';
export const FETCH_PASSENGER_INIT = 'FETCH_PASSENGER_INIT';
export const FETCH_PASSENGER_SUCCESS = 'FETCH_PASSENGER_SUCCESS';
export const FETCH_PASSENGER_FAIL = 'FETCH_PASSENGER_FAIL';
export const STARTED_UPDATING_PASSENGER = 'STARTED_UPDATING_PASSENGER';
export const FINISHED_UPDATING_PASSENGER = 'FINISHED_UPDATING_PASSENGER';

// Push Notification Constants
export const GET_PUSH_NOTIFICATION_INIT = 'GET_PUSH_NOTIFICATION_INIT';
export const GET_PUSH_NOTIFICATION_SUCCESS = 'GET_PUSH_NOTIFICATION_SUCCESS';
export const GET_PUSH_NOTIFICATION_FAILURE = 'GET_PUSH_NOTIFICATION_FAILURE';
export const UPDATE_PUSH_NOTIFICATION_INIT = 'UPDATE_PUSH_NOTIFICATION_INIT';
export const UPDATE_PUSH_NOTIFICATION_SUCCESS = 'UPDATE_PUSH_NOTIFICATION_SUCCESS';
export const UPDATE_PUSH_NOTIFICATION_FAILURE = 'UPDATE_PUSH_NOTIFICATION_FAILURE';
export const UPDATE_PUSH_NOTIFICATION_DISABLE_CITY_CHECK = 'UPDATE_PUSH_NOTIFICATION_DISABLE_CITY_CHECK';

export const MEMBERSHIP_ACCOUNT_STATEMENT_INIT = 'MEMBERSHIP_ACCOUNT_STATEMENT_INIT';
export const MEMBERSHIP_ACCOUNT_STATEMENT_SUCCESS = 'MEMBERSHIP_ACCOUNT_STATEMENT_SUCCESS';
export const MEMBERSHIP_ACCOUNT_STATEMENT_FAIL = 'MEMBERSHIP_ACCOUNT_STATEMENT_FAIL';

// Flight Cancel
export const FLIGHT_CANCEL_INIT = 'FLIGHT_CANCEL_INIT';
export const FLIGHT_CANCEL_SUCCESS = 'FLIGHT_CANCEL_SUCCESS';
export const FLIGHT_CANCEL_FAILURE = 'FLIGHT_CANCEL_FAILURE';
export const FLIGHT_CANCEL_AFTER_SUCCESS = 'FLIGHT_CANCEL_AFTER_SUCCESS';

// Aircrfts List
export const GET_AIRCRAFTS_INIT = 'GET_AIRCRAFTS_INIT';
export const GET_AIRCRAFTS_SUCCESS = 'GET_AIRCRAFTS_SUCCESS';
export const GET_AIRCRAFTS_FAILURE = 'GET_AIRCRAFTS_FAILURE';


//Movement Messages
export const FETCH_MOVEMENT_MESSAGES_INIT = 'FETCH_MOVEMENT_MESSAGES_INIT';
export const FETCH_MOVEMENT_MESSAGES_SUCCESS = 'FETCH_MOVEMENT_MESSAGES_SUCCESS';
export const FETCH_MOVEMENT_MESSAGES_FAIL = 'FETCH_MOVEMENT_MESSAGES_FAIL';

export const CLEAR_ERROR_FETCH_ITINERARY = 'CLEAR_ERROR_FETCH_ITINERARY';

//Fetch order id by leg identifier
export const FETCH_ORDER_ID_BY_LEG_INIT = "FETCH_ORDER_ID_BY_LEG_INIT";
export const FETCH_ORDER_ID_BY_LEG_SUCCESS = "FETCH_ORDER_ID_BY_LEG_SUCCESS";
export const FETCH_ORDER_ID_BY_LEG_FAIL = "FETCH_ORDER_ID_BY_LEG_FAIL";

//Feedback Actions
export const FETCH_LEG_FEEDBACK_INIT = "FETCH_LEG_FEEDBACK_INIT";
export const FETCH_LEG_FEEDBACK_SUCCESS = "FETCH_LEG_FEEDBACK_SUCCESS";
export const FETCH_LEG_FEEDBACK_FAIL = "FETCH_LEG_FEEDBACK_FAIL";
export const SAVE_LEG_FEEDBACK_INIT = "SAVE_LEG_FEEDBACK_INIT";
export const SAVE_LEG_FEEDBACK_SUCCESS = "SAVE_LEG_FEEDBACK_SUCCESS";
export const SAVE_LEG_FEEDBACK_FAIL = "SAVE_LEG_FEEDBACK_FAIL";
export const CLEAR_FEEDBACK = "CLEAR_FEEDBACK";

//fetch quotes
export const FETCH_QUOTES_INIT = 'FETCH_QUOTES_INIT';
export const FETCH_QUOTES_SUCCESS = 'FETCH_QUOTES_SUCCESS';
export const FETCH_QUOTES_FAIL = 'FETCH_QUOTES_FAIL';

// Passport popup
export const UPDATE_FREQUENT_PAX = 'UPDATE_FREQUENT_PAX';
export const UPDATE_ADDED_PASSENGERS = 'UPDATE_ADDED_PASSENGERS';
export const UPDATE_PASSPORT = 'UPDATE_PASSPORT';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SET_LEG_PASSPORT = 'SET_LEG_PASSPORT';
export const SET_LEAD_PASSENGER = 'SET_LEAD_PASSENGER';
export const UPDATE_SELECTED_PASSENGER = 'UPDATE_SELECTED_PASSENGER';
export const CLEAR_SELECTED_PASSENGER = 'CLEAR_SELECTED_PASSENGER';
export const REMOVE_PASSPORT = 'REMOVE_PASSPORT';
export const REMOVE_USER = 'REMOVE_USER';
export const INITIALIZE_POPUP = 'INITIALIZE_POPUP';
