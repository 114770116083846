import { connect } from 'react-redux';
import withStore from '../components/withStore';
import Login from '../components/Login/Login';
import * as actions from '../store/actions';

function mapStateToProps(state) {
  const {
    isLoggedIn,
    isLoggingOut,
    userHasLoggedOut,
    isForgotEmailSent,
    isSendingForgotEmail,
    isUserVerified,
    changePasswordRequired,
    isPasswordChangeLoading,
  } = state.user;
  const { user } = state;
  return {
    isForgotEmailSent,
    isSendingForgotEmail,
    user,
    isLoggedIn,
    isLoggingOut,
    isUserVerified,
    userHasLoggedOut,
    isTokenError: state.token.isTokenError,
    tokenError: state.token.tokenError,
    isAuthError: state.token.isTokenAuthError,
    authError: state.token.tokenAuthError,
    changePasswordRequired,
    isPasswordChangeLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginAction: payload => dispatch(actions.loginAction(payload)),
    forgotAction: payload => dispatch(actions.forgotAction(payload)),
    changePasswordAction: payload =>
      dispatch(actions.changePasswordAction(payload)),
    clearForgotErrorMessage: () => dispatch(actions.clearForgotErrorMessage()),
    clearForgotPasswordResentMessageModal: () => dispatch(actions.clearForgotPasswordResentMessageModal()),
    clearNotVerifiedFlag: () => dispatch(actions.clearNotVerifiedUser()),
  };
}

const UserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default withStore(UserContainer);
