import React, { Component } from "react";
import MessagePopup from "../components/shared/MessagePopUp";

class DialogWrapper extends Component {
  render() {
    const { id, content } = this.props;
    return <MessagePopup id={id} content={content} />;
  }
}
export default DialogWrapper;
