import React, { Component } from "react";
import DrawerContainer from "../containers/DrawerContainer";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    }
  }

  render() {
    return (
      <React.Fragment>
        <ul className="uk-nav" uk-nav="animation: false">
          {!this.props.user.isLoggedIn && (
            <li className="loggedOutState">
              <a href={window.epiSettings.loginUrl}>Log In</a> {/* / <a href={window.epiSettings.registerUrl}>Register</a> */}
            </li>
          )}
          {this.props.user.isLoggedIn && (
            <DrawerContainer />
          )}
        </ul>
        {this.props.user.isLoggedIn && (
          <div class="uk-nav-center loggedInState">
            Logged&nbsp;in&nbsp;as: <strong>{this.props.user.user.firstName}&nbsp;{this.props.user.user.lastName}</strong>
          </div>
        )}
      </React.Fragment>
    );
  }
}
