import * as types from "../actionTypes";

const initialState = {
  isValidateFlightLoading: false,
  validateFlightMessages: [],
  isProposalOrRestriction: false
};

export default function validateFlightReducer(state = initialState, action) {
  switch (action.type) {
    case types.VALIDATE_FLIGHT_INIT: {
      return Object.assign({}, state, {
        isValidateFlightLoading: true,
        validateFlightMessages: [],
        isProposalOrRestriction: false
      });
    }
    case types.VALIDATE_FLIGHT_SUCCESS: {
      return Object.assign({}, state, {
        isValidateFlightLoading: false,
        validateFlightMessages: [],
        isProposalOrRestriction: false
      });
    }
    case types.VALIDATE_FLIGHT_FAIL: {
      return Object.assign({}, state, {
        isValidateFlightLoading: false,
        validateFlightMessages: action.data,
        isProposalOrRestriction: !!Array.isArray(action.data)
      });
    }
    case types.RESET_FLIGHT_ERROR_MESSAGES: {
      return Object.assign({}, state, {
        validateFlightMessages: [],
        isProposalOrRestriction: false
      });
    }
    default:
      return state;
  }
}
