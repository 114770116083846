import fetchWeather from '../sources/WeatherSource';
import {
  FETCH_WEATHER_INIT,
  FETCH_WEATHER_SUCCESS,
  FETCH_WEATHER_FAIL,
} from '../actionTypes';

export const fetchWeatherInit = (flightLegId, key) => ({
  type: FETCH_WEATHER_INIT,
  flightLegId,
  key
});

export const fetchWeatherSuccess = (data, key, flightLegId) => ({
  type: FETCH_WEATHER_SUCCESS,
  data,
  flightLegId,
  key
});

export const fetchWeatherFail = (data, key, flightLegId) => ({
  type: FETCH_WEATHER_FAIL,
  data,
  flightLegId,
  key
});

export const fetchWeatherDetails = (lat, long, time, key, flightLegId) => fetchWeather(lat, long, time, key, flightLegId);