import moment from "moment";
import Validator from "./Validators/PAFValidators";
import { isLoggedIn, isFSP, isFspDirect } from "./userUtils";
import Helpers from "./Helpers";
import {
  DEPARTURE_AIRPORT_EXCEPTION,
  ARRIVAL_AIRPORT_EXCEPTION,
  DEPARTURE_ARRIVAL_AIRPORT_EXCEPTION,
  SAME_ARRIVAL_DEPARTURE_EXCEPTION,
  MIN_HOUR_EXPECPTION,
  OUTSIDE_TIMEZONE_EXCEPTION,
  FILL_FIELDS_EXCEPTION,
  TIME_CLASH_EXCEPTION,
  FILL_AIRCRAFT_EXCEPTION,
  FILL_TIME_EXCEPTION,
  FILL_DATE_EXCEPTION,
  FUEL_STOP_WARNING
} from "../constants/MessageConstants";
import { dateFormats } from "../utils/dateUtils";
import {
  getUtcOffsetMinutes,
  removeOffsetMinutes
} from "./timezoneUtils";
import { legStatus } from "../constants/PAFConstants";
export const validateLegs = (rawLegs, isEditFlow=false) => {
  const { MMDDYYYY, MM_DD_YYYY_HHmm, YYYY_MM_DD_HHmm } = dateFormats;
  const legs = rawLegs;
  let isError = false;
  let isModalError = false;
  let modalMessage = [];

  const validLegs = legs.map((leg, index) => {

    if (isEditFlow && (leg.isCanceled || !leg.isEditableLeg)) {
      return;
    }

    const fuelStop = leg.validation.errorOn.fuelStop;
    leg.validation = {};
    leg.validation.errorOn = {
      departure: null,
      arrival: null,
      departureArrival: null,
      departureArrivalSame: null,
      time: null,
      passenger: null,
      aircraft: null,
      date: null,
      contract: null,
      minHoursException: null,
      fuelStop
    };
    leg.validation.isError = false;

    if (leg.departureAirport == null) {
      leg.validation.isError = true;
      leg.validation.errorOn.departure = DEPARTURE_AIRPORT_EXCEPTION;
      isError = true;
    }
    if (leg.arrivalAirport == null) {
      leg.validation.isError = true;
      leg.validation.errorOn.arrival = ARRIVAL_AIRPORT_EXCEPTION;
      isError = true;
    }
    if (leg.arrivalAirport == null && leg.departureAirport == null) {
      leg.validation.isError = true;
      leg.validation.errorOn.departureArrival = DEPARTURE_ARRIVAL_AIRPORT_EXCEPTION;
      isError = true;
    }
    if (
      !!leg.departureAirport &&
      !!leg.arrivalAirport &&
      leg.arrivalAirport.id === leg.departureAirport.id
    ) {
      leg.validation.isError = true;
      leg.validation.errorOn.departureArrivalSame = SAME_ARRIVAL_DEPARTURE_EXCEPTION;
      isError = true;
    }
    if (
      !Validator.isInvalidDate(leg.date) &&
      !Validator.validRequired(leg.time) &&
      !!leg.departureAirport
    ) {
      const datetime = `${moment(leg.date).format(MMDDYYYY)} ${leg.time}`;

      // local time input by user
      const userDepartTime = moment(datetime, MM_DD_YYYY_HHmm).utc();
      const offset = Helpers.findOffset(userDepartTime, leg.departureAirport);

      const actualCurrentLocalTimeString = moment()
        .utc()
        .utcOffset(offset)
        .format(YYYY_MM_DD_HHmm);

      // local time at the moment actually
      const actualCurrentLocalTime = moment(
        actualCurrentLocalTimeString,
        YYYY_MM_DD_HHmm
      ).utc();

      const afterFourHoursTime  = moment(actualCurrentLocalTime).add(4, 'hours');

      if (userDepartTime.isBefore(afterFourHoursTime)) {
        leg.validation.isError = true;
        leg.validation.errorMsg = MIN_HOUR_EXPECPTION;
        leg.validation.errorOn.minHoursException = MIN_HOUR_EXPECPTION;
        isError = true;
      }
    }
    if (
      Validator.validTimeZone(leg.date, leg.departureAirport) ||
      Validator.validTimeZone(leg.date, leg.arrivalAirport)
    ) {
      leg.validation.isError = true;
      leg.validation.errorOn.date = OUTSIDE_TIMEZONE_EXCEPTION;
      isError = true;
    }
    if (Validator.isInvalidDate(leg.date)) {
      leg.date = undefined;
      leg.validation.isError = true;
      leg.validation.errorOn.date = FILL_DATE_EXCEPTION;
      isError = true;
    }
    if (Validator.validRequired(leg.time)) {
      leg.time = "";
      leg.validation.isError = true;
      leg.validation.errorOn.time = FILL_TIME_EXCEPTION;
      leg.isTimeValid = true;
      isError = true;
    }
    if (Validator.validPax(leg.passengersCount)) {
      leg.validation.isError = true;
      leg.validation.errorOn.passenger = FILL_FIELDS_EXCEPTION;
      isError = true;
    }
    if (Validator.validAircraft(leg.selectedAircraft)) {
      leg.validation.isError = true;
      leg.validation.errorOn.aircraft = FILL_AIRCRAFT_EXCEPTION;
      isError = true;
    }
    if (index > 0) {
      const prevLeg = legs[index - 1];
      const curDepartDate = moment(leg.departTime, 'YYYY-MM-DDTHH:mm:ss');
      const prevArrivalDate = moment(prevLeg.arrivalTime, 'YYYY-MM-DDTHH:mm:ss');
      const timeDiff = Math.abs(moment(curDepartDate).utc().diff(moment(prevArrivalDate).utc(), "hour"));
      if (curDepartDate && prevArrivalDate && (timeDiff < 4) && leg.time) {
        modalMessage.push(`Please allow for a minimum of at least 4 hours between the arrival at ${prevLeg.arrivalAirport.name}, ${prevLeg.arrivalAirport.city} and the departure at ${leg.departureAirport.name}, ${leg.departureAirport.city}`);
        isModalError = true;
      }
    }
    return leg;
  });
  return { isError, validLegs, isModalError, modalMessage };
};

export const validateMultiLeg = multiLegs => {
  const legs = multiLegs.filter(leg => !leg.isCanceled);
  const popupErrors = [];
  if (legs.length > 1) {
    const firstleg = legs[0];
    const lastLeg = legs[legs.length - 1];

    if (
      !firstleg.departureAirport ||
      !lastLeg.arrivalAirport ||
      firstleg.departureAirport.id !== lastLeg.arrivalAirport.id
    ) {
      return false;
    }
    let arrivalDatetimeFirstLeg = moment(firstleg.arrivalTime).utc();
    let departureDatetimeLastLeg = moment(lastLeg.departTime).utc();

    const offsetMinutesArrival = getUtcOffsetMinutes(
      firstleg.arrivalAirport.timezones,
      arrivalDatetimeFirstLeg
    );
    const offsetMinutesDeparture = getUtcOffsetMinutes(
      lastLeg.departureAirport.timezones,
      departureDatetimeLastLeg
    );

    arrivalDatetimeFirstLeg = removeOffsetMinutes(
      offsetMinutesArrival,
      arrivalDatetimeFirstLeg
    );
    departureDatetimeLastLeg = removeOffsetMinutes(
      offsetMinutesDeparture,
      departureDatetimeLastLeg
    );

    if (arrivalDatetimeFirstLeg.isSameOrAfter(departureDatetimeLastLeg)) {
      lastLeg.validation.isError = true;
      lastLeg.validation.errorMsg =
        "The selected date or time clashes with another of your flight requests";
      popupErrors.push(TIME_CLASH_EXCEPTION);
    }
  }
  return popupErrors;
};

export function mapLegStatus(status) {
  if (status === legStatus.REQUESTED || status === legStatus.UNCONFIRMED)
    return "On Request";
  return status;
}

export const calcDepartAndArrivalTime = leg => {
  const legToUpdate = leg;
  const tmpObj = {
    departTime: "Invalid date",
    arrivalTime: "Invalid date"
  };
  let departTime = "etd";
  let arrivalTime = "eta";
  if (!!legToUpdate.time && !!legToUpdate.date) {
    const datetimeString =
      `${moment.utc(legToUpdate.date).format("DD/MM/YYYY")} ` +
      `${legToUpdate.time}`;
    if (legToUpdate.departureAirport !== null) {
      departTime = moment(`${datetimeString} +0000`, "DD/MM/YYYY HH:mm Z")
        .utc()
        .format();

      tmpObj.departTime = departTime;
    }

    if (
      legToUpdate.departureAirport &&
      legToUpdate.arrivalAirport &&
      departTime &&
      legToUpdate.selectedAircraft
    ) {
      arrivalTime = Helpers.getArrivalTime(
        departTime,
        legToUpdate.duration,
        legToUpdate.departureAirport,
        legToUpdate.arrivalAirport
      );
      tmpObj.arrivalTime = arrivalTime;
      // legArray;
    }
    legToUpdate.departTime = tmpObj.departTime;
    legToUpdate.arrivalTime = tmpObj.arrivalTime;
    legToUpdate.validation.errorOn.minHoursException = null;
  }
  return legToUpdate;
};

export const calcFlightDuration = (leg, isNotComingFromOffer = true) => {
  const legToUpdate = leg;
  if (
    legToUpdate.departureAirport !== null &&
    legToUpdate.arrivalAirport !== null &&
    legToUpdate.selectedAircraft !== null
  ) {
    legToUpdate.duration = Helpers.getFlightDuration(
      legToUpdate.departureAirport,
      legToUpdate.arrivalAirport,
      legToUpdate.selectedAircraft
    );
    if (isNotComingFromOffer) {
      const fuelStopErr = validateDuration(
        legToUpdate.duration,
        Helpers.getValue(legToUpdate.selectedAircraft.maxFlightTime)
      );
      if (fuelStopErr) {
        legToUpdate.validation.errorOn.fuelStop = FUEL_STOP_WARNING;
      } else {
        legToUpdate.validation.errorOn.fuelStop = null;
      }
    }
  }
  return legToUpdate;
};

export const validateAircraftinContract = (user, availableAircraftIds, id) => {
  if (isLoggedIn(user) && (isFSP(user) || isFspDirect(user))) {
    return availableAircraftIds.includes(id);
  }
  return true;
};

export const validateDuration = (duration, aircraftTime) => {
  return !isNaN(+duration) && +duration > +aircraftTime;
};

export const sortLegs = legs =>
  legs.sort((leg1, leg2) => {
    if (moment(leg1.departTime).isBefore(moment(leg2.departTime))) return -1;
    else if (moment(leg1.departTime).isAfter(moment(leg2.departTime))) return 1;
    return 0;
  });