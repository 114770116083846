import axios from 'axios';
import {
  getPushNotificationInit,
  getPushNotificationSuccess,
  getPushNotificationFailure,
  updatePushNotificationInit,
  updatePushNotificationSuccess,
  updatePushNotificationFailure,
  updateDisableCitiesCheck,
} from '../actions';
import {
  GET_NOTIFICATION_OPTIONS, UPDATE_NOTIFICATION_OPTIONS,
} from '../../configs/constants';
// import { CREDIT_CARD_MESSAGE } from '../../constants/MessageConstants';
import { HTTP_HEADERS_XML } from '../../constants/ApiConstants';
import { handleServerErrors, parsePascalCaseString } from '../../utils/sourceUtils';
import { existInLocal, getFromLocal, saveToLocal } from '../../utils/cache';
import { normalizePushNotifications, normalizeRequestUpdateNotifications } from '../../normalizers/pushNotificationNormalizer';
import status from '../../configs/status';

export const updatePushNotifications = (
  token,
  notifications,
  cities,
  isCitiesDisable = false,
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(updatePushNotificationInit());
    const requestPayload = normalizeRequestUpdateNotifications(notifications, cities);
    const url = UPDATE_NOTIFICATION_OPTIONS.replace('{token}', token);

    axios
      .post(
        url,
        requestPayload,
        {
          headers: HTTP_HEADERS_XML,
        },
      )
      .then((res) => {
        let user = null;
        if (existInLocal('user')) {
          user = getFromLocal('user', true, true);
        }
        if (res.status === status.OK) {
          parsePascalCaseString(res.data, (parseErr, result) => {
            if (parseErr) {
              const error = handleServerErrors(null);
              dispatch(getPushNotificationFailure(error));
              reject(error);
            } else {
              if (result.response.responseHeader.isError) {
                const error = handleServerErrors(result.response.responseHeader.statusCode);
                dispatch(updatePushNotificationFailure(error));
                reject(error);
              } else {
                dispatch(updatePushNotificationSuccess({
                  notifications,
                  cities,
                }));
                dispatch(updateDisableCitiesCheck(isCitiesDisable));
                const userObject = Object.assign({}, user, {
                  disablePushNotificationCities: isCitiesDisable,
                });
                saveToLocal(userObject, 'user', true, true);
                resolve();
              }
            }
          });
        } else {
          const errors = handleServerErrors(null);
          dispatch(getPushNotificationFailure(errors));
          reject(errors);
        }
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(updatePushNotificationFailure(errors));
        reject(errors);
      });
  });
};

export const fetchPushNotification = (token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(getPushNotificationInit());
    const url = GET_NOTIFICATION_OPTIONS.replace('{token}', token);
    axios
      .get(
        url,
        {
          headers: HTTP_HEADERS_XML,
        },
      )
      .then((res) => {
        if (res.status === status.OK) {
          parsePascalCaseString(res.data, (parseErr, result) => {
            if (parseErr) {
              const error = handleServerErrors(null);
              dispatch(getPushNotificationFailure(error));
              reject(error);
            } else {
              if (result.response.responseHeader.isError) {
                const error = handleServerErrors(result.response.responseHeader.statusCode);
                dispatch(getPushNotificationFailure(error));
                reject(error);
              } else {
                const normalizedPushNotification = normalizePushNotifications(
                  result,
                );
                dispatch(getPushNotificationSuccess(normalizedPushNotification));
                resolve(normalizedPushNotification);
              }
            }
          });
        } else {
          const errors = handleServerErrors(null);
          dispatch(getPushNotificationFailure(errors));
          reject(errors);
        }
      })
      .catch(err => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(getPushNotificationFailure(errors));
        reject(errors);
      });
  });
};

// export const deleteCreditCardSource = (token, creditCardPayload) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     dispatch(deleteCreditCardInit());
//     const url = DELETE_CREDIT_CARD_URL.replace('{token}', token);
//     const {
//       alias,
//     } = creditCardPayload;
//     const payload = builderXmlRequest({
//       creditCardRequestDto: {
//         alias,
//       },
//     });

//     axios
//       .post(
//         url,
//         payload,
//         {
//           headers: HTTP_HEADERS_XML,
//         },
//       )
//       .then((res) => {
//         parserPascalCaseIgnoreNumber(res.data, (error, result) => {
//           if (error) {
//             const errors = handleServerErrors(null);
//             dispatch(deleteCreditCardFailure(errors));
//             reject(errors);
//           } else {
//             const {
//               responseHeaderDto,
//             } = result.creditCardResponseDto;

//             if (responseHeaderDto && responseHeaderDto.isError === true) {
//               const {
//                 statusCode,
//               } = responseHeaderDto;
//               const errors = handleServerErrors(statusCode);
//               dispatch(deleteCreditCardFailure(errors));
//               reject(errors);
//             } else {
//               let message = CREDIT_CARD_MESSAGE[creditCardPayload.cardBrandId];
//               message = `${message}${creditCardPayload.cardNumberLastFour} deleted`;
//               dispatch(deleteCreditCardSuccess({
//                 response: creditCardPayload.alias,
//                 message,
//               }));
//               resolve(creditCardPayload.alias);
//             }
//           }
//         });
//       })
//       .catch(err => {
//         const errors = handleServerErrors(
//           err.response
//             ? err.response.status
//             : err.response,
//         );
//         dispatch(addCreditCardFail(errors));
//         reject(errors);
//       });
//   });
// };
