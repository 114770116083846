export const Salutation = [
  { label: 'Mr.', value: 0 },
  { label: 'Ms.', value: 1 },
  { label: 'Mrs.', value: 2 },
  { label: 'Other', value: 3 }
];
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/;
// eslint-disable-next-line
export const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
export const NAME_REGEX = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u; //eslint-disable-line
export const INVALID_NAME = 'Name cannot have special characters';
export const PASSPORT_NAME_REGEX = /^[a-zA-Z ']+$/;
export const PASSPORT_REGEX = /^[a-zA-Z\d]+$/;
