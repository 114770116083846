import { types as CardType } from 'credit-card-type';

export const CREDIT_CARD_TYPE = {
  PERSONAL: 'Personal',
  BUSINESS: 'Business',
};

export const NEW_CARD_TYPES = [
  {
    label: 'Personal', value: 'Personal',
  },
  {
    label: 'Business', value: 'Business',
  },
];

export const CREDIT_CARD_TYPES = [
  CREDIT_CARD_TYPE.PERSONAL,
  CREDIT_CARD_TYPE.BUSINESS,
];

export const CREDIT_CARD_BRANDS = [
  CardType.AMERICAN_EXPRESS,
//  CardType.DINERS_CLUB,
//  CardType.DISCOVER,
  CardType.MASTERCARD,
  CardType.VISA,
];

export const CREDIT_CARD_BRAND_IDS = {};
CREDIT_CARD_BRAND_IDS[CardType.AMERICAN_EXPRESS] = 1;
// CREDIT_CARD_BRAND_IDS[CardType.DINERS_CLUB] = 2;
// CREDIT_CARD_BRAND_IDS[CardType.DISCOVER] = 3;
CREDIT_CARD_BRAND_IDS[CardType.MASTERCARD] = 4;
CREDIT_CARD_BRAND_IDS[CardType.VISA] = 5;

export const CREDIT_CARD_BRAND_NAMES = {
  1: 'AMERICAN EXPRESS',
  2: 'DINERS CLUB',
  3: 'DISCOVER',
  4: 'MASTER',
  5: 'VISA',
};

