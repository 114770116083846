import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import MessagePopUp from '../shared/MessagePopUp';
import UserDetailSection from './UserDetailsSection/UserDetailSection';
import PassportListingSection from './PassportSection/PassportListingSection';
import CreditCardListingSection from './UserCreditCardSection/CreditCardListingSection';
import UserChangePasswordForm from './ChangePassowordSection/ChangeUserPasswordForm';
import PushNotificationCard from './PushNotificationSection/PushNotificationCard';
import {
  USER_PROFILE_SUCCESSFULLY_UPDATED,
  PASSWORD_UPDATE_SUCCESS,
  UPDATE_SAVED,
} from '../../constants/MessageConstants';
import { concatenateStrings } from '../../utils/stringUtils';
import {
  isGuest,
  isFspDirect,
  isFSP,
  isAdhoc,
  isVJ2,
} from '../../utils/userUtils';
import ConfirmationPopup from '../shared/ConfirmationPopUpNew';
import { CONFIRMATION_TITLE, LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT, CONFIRMATION_MESSAGE } from '../../constants/DirtyPopupConstant';
import dirtystateSingleTon from '../../validators/validateDirty';
import SPAConstants from '../../constants/SPAConstants';

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: '0',
      popupMessage: '',
      tempIndex: '0'
    };
    this.shouldNotificationShow = false;
    this.userType = {
      isGuestUser: false,
      isAdhoc: false,
      isFspDirect: false,
      isFsp: false,
      isVjdirect: false,
    };
  }

  componentDidMount() {
    this.getUserType();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.user.user !== nextProps.user.user
      && nextProps.user.isProfileUpdated
      && this.props.user.isProfileUpdated !== nextProps.user.isProfileUpdated
      && !this.shouldNotificationShow
    ) {
      if (nextProps.user.passportUpdateMessage) {
        this.showNotification(nextProps.user.passportUpdateMessage);
      } else {
        this.showNotification(USER_PROFILE_SUCCESSFULLY_UPDATED);
      }
    }
    if (!nextProps.user.changePasswordRequired && nextProps.user.changePasswordSuccess
      && this.props.user.changePasswordSuccess !== nextProps.user.changePasswordSuccess
    ) {
      this.showNotification(PASSWORD_UPDATE_SUCCESS);
    }
    if (
      nextProps.isAddedCreditCard
      && this.props.isAddedCreditCard !== nextProps.isAddedCreditCard
      && !nextProps.isAddCreditCardLoading
      && this.props.creditCardMessage !== nextProps.creditCardMessage
    ) {
      this.showNotification(nextProps.creditCardMessage);
    }
    if (
      nextProps.isDeletedCreditCard
      && this.props.isDeletedCreditCard !== nextProps.isDeletedCreditCard
      && !nextProps.isAddCreditCardLoading
      && this.props.creditCardMessage !== nextProps.creditCardMessage
    ) {
      this.showNotification(nextProps.creditCardMessage);
    }
    if (
      (this.props.notifications !== nextProps.notifications
        || this.props.cities !== nextProps.cities)
      && nextProps.isPushNotificationUpdated
      && this.props.isPushNotificationUpdated !== nextProps.isPushNotificationUpdated
    ) {
      this.showNotification(UPDATE_SAVED);
    }
    // if (this.props.accountDataError !== nextProps.accountDataError
    //     && nextProps.isAccountDataFetchedError
    // ) {
    //   this.setState({
    //     popupMessage: nextProps.accountDataError,
    //   }, () => {
    //     window.UIkit.modal('#AccountStatusErrorPopUp').show();
    //   });
    // }
  }

  getUserType = () => {
    const { user } = this.props;
    if (user && user.user) {
      this.userType.isGuestUser = isGuest(user.user);
      this.userType.isAdhoc = isAdhoc(user.user);
      this.userType.isFsp = isFSP(user.user);
      this.userType.isFspDirect = isFspDirect(user.user);
      this.userType.isVjdirect = isVJ2(user.user);
    }
    this.setState({
      reRender: true,
    });
  }

  showNotification = (message) => {
    window.UIkit.notification({
      message,
      status: 'danger',
      pos: 'top-center',
      timeout: 5000,
    });
  }

  toggleShowNotification = () => {
    this.shouldNotificationShow = !this.shouldNotificationShow;
  }

  handleTabChange = (e) => {
    const index = e.target.id;
    const isUserProfileDirty = dirtystateSingleTon.getDirty(SPAConstants.UserProfile);
    if (isUserProfileDirty) {
      this.setState({
        tempIndex: index
      }, () => {
        this.userProfilePopup.show();
      });

    } else {
      this.handleConfirmTabChange(index);
    }
  }

  handleConfirmTabChange = (tabIndex) => {
    dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
    this.userProfilePopup.hide();
    this.setState({
      tabIndex,
      tempEvent: null
    });
  }

  goToTabSelection = (index) => {
    this.setState({
      tabIndex: index.toString(),
    });
  }

  renderSelectedTab = (index) => {
    const { user } = this.props;
    switch (Number(index)) {
      case 0: return (
        <UserDetailSection
          user={user}
          userType={this.userType}
          toggleShowNotification={this.toggleShowNotification}
          {...this.props}
        />
      );
      case 1: return (
        <PassportListingSection
          user={user}
          toggleShowNotification={this.toggleShowNotification}
          {...this.props}
        />
      );
      case 2: return (
        <UserChangePasswordForm
          goToTabSelection={this.goToTabSelection}
          {...this.props}
        />
      );
      case 3: return (
        <PushNotificationCard
          user={user}
          goToTabSelection={this.goToTabSelection}
          {...this.props}
        />
      );
      case 4: return (
        <CreditCardListingSection
          user={user}
          toggleShowNotification={this.toggleShowNotification}
          {...this.props}
        />
      );
      default: return (
        <div />
      );
    }
  }

  renderUserProfileScreen = () => {
    const { tabIndex, popupMessage } = this.state;
    const { user } = this.props.user;
    const { isAccountDataFetched, isAccountDataFetchedError, isAccountDataFail } = this.props;
    const isLoading = (!isAccountDataFetched && !isAccountDataFetchedError);

    const menuOptionText = {
      0: 'My Account',
      1: 'Manage Passport',
      2: 'Change Password',
      3: 'Notifications',
      4: 'Payment Methods',
    };
    return (
      <div className="vistajet_user_profile_container">
        <div className={`uk-container uk-container-small vistajet_screen_wrapper`}>
          {
            (isLoading) && (
              <div className="vistajet_UserProfile_spinner">
                <SpinnerLoader />
              </div>
            )
          }
          {(isAccountDataFetched || isAccountDataFail) &&
            <div className="uk-flex uk-flex-center uk-grid-medium uk-margin-medium-top" data-uk-grid>
              <div className="uk-width-1-3@s uk-width-1-1">
                <div className="uk-inline uk-width-1-1 uk-hidden@s">
                  <button className="vistajet_account_button vistajet_button_default vistajet_responsive_dropdown uk-width" type="button">
                    <span className="uk-align-left uk-margin-remove">
                      {tabIndex && menuOptionText[tabIndex]}
                    </span>
                    <span className="uk-margin-remove vistajet_chevron_down uk-align-right">
                      <img src="/legacy/static/img/icons/vistajet_chevron_right_red.svg" alt="" />
                    </span>
                  </button>
                  <div uk-dropdown="mode: click; pos: bottom-justify ">
                    <ul className="uk-nav uk-nav-default uk-text-left vistajet_profile_reponsive_dropdown_list">
                      <li className={tabIndex === '0' ? 'uk-active' : ''}>
                        <button className="text-button" id="0" onClick={this.handleTabChange}>
                          My Account
                        </button>
                      </li>
                      <li className={tabIndex === '1' ? 'uk-active' : ''}>
                        <button className="text-button" id="1" onClick={this.handleTabChange}>
                          Manage Passports
                        </button>
                      </li>
                      <li className={tabIndex === '2' ? 'uk-active' : ''}>
                        <button className="text-button" id="2" onClick={this.handleTabChange}>
                          Change Password
                        </button>
                      </li>
                      {
                        (!this.userType.isFsp || this.userType.isFspDirect) && (
                          <li className={tabIndex === '3' ? 'uk-active' : ''}>
                            <button className="text-button" id="3" onClick={this.handleTabChange}>
                              Notifications
                            </button>
                          </li>
                        )
                      }
                      {
                        ((!this.userType.isFsp
                          || this.userType.isFspDirect)
                          && !this.userType.isGuestUser) && (
                          <li className={tabIndex === '4' ? 'uk-active' : ''}>
                            <button className="text-button" id="4" onClick={this.handleTabChange}>
                              Payment Methods
                            </button>
                          </li>
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div className="uk-visible@s uk-card vistajet_user_profile_left_card uk-padding-remove-bottom uk-card-default uk-card-body">
                  <h3 className="vistajet_left_tab_heading">
                    {concatenateStrings(
                      user && user.firstName,
                      user && user.lastName,
                    )}
                  </h3>
                  <ul className="uk-nav uk-nav-default vistajet_side_tab_list uk-text-left">
                    <li className={tabIndex === '0' ? 'uk-active' : ''}>
                      <button className="text-button" id="0" onClick={this.handleTabChange}>
                        My Account
                      </button>
                    </li>
                    <li className={tabIndex === '1' ? 'uk-active' : ''}>
                      <button className="text-button" id="1" onClick={this.handleTabChange}>
                        Manage Passports
                      </button>
                    </li>
                    <li className={tabIndex === '2' ? 'uk-active' : ''}>
                      <button className="text-button" id="2" onClick={this.handleTabChange}>
                        Change Password
                      </button>
                    </li>
                    {
                      (!this.userType.isFsp || this.userType.isFspDirect) && (
                        <li className={tabIndex === '3' ? 'uk-active' : ''}>
                          <button className="text-button" id="3" onClick={this.handleTabChange}>
                            Notifications
                          </button>
                        </li>
                      )
                    }
                    {
                      ((!this.userType.isFsp
                        || this.userType.isFspDirect)
                        && !this.userType.isGuestUser) && (
                        <li className={tabIndex === '4' ? 'uk-active' : ''}>
                          <button className="text-button" id="4" onClick={this.handleTabChange}>
                            Payment Methods
                          </button>
                        </li>
                      )
                    }
                  </ul>
                </div>
              </div>
              <div className="uk-width-2-3@s">
                {this.renderSelectedTab(tabIndex)}
              </div>
            </div>
          }
        </div>
        <ConfirmationPopup
          ref={(userProfilePopup) => { this.userProfilePopup = userProfilePopup; }}
          title={CONFIRMATION_TITLE}
          leftButtonContent={LEFT_BUTTON_TEXT}
          rightButtonContent={RIGHT_BUTTON_TEXT}
          onLeftButtonClick={() => this.userProfilePopup.hide()} // close form
          onRightButtonClick={() => this.handleConfirmTabChange(this.state.tempIndex)}
          content={CONFIRMATION_MESSAGE}
        />
        <MessagePopUp
          id="AccountStatusErrorPopUp"
          content={popupMessage}
        />
      </div>
    );
  };

  render() {
    return this.renderUserProfileScreen();
  }
}
