import * as type from '../../actionTypes';
import { getFlightId } from '../../../utils/itinerariesUtils';

const initialState = {
  isLoadingWeather: 0,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.ITINERARY_DETAILS_SUCCESS: {
      const {
        legs,
      } = action.data;
      const weatherData = {};
      legs.forEach(leg => {
        weatherData[getFlightId(leg)] = {};
        weatherData[getFlightId(leg)].isLoadingWeather = false;
      })
      return Object.assign({}, state, {
        weather: weatherData,
      });
    }
    case type.FETCH_WEATHER_INIT: {
      action.key === 'arrival' ?
      state.weather[action.flightLegId].isLoadingArrivalWeather = true :
      state.weather[action.flightLegId].isLoadingDepartureWeather = true;

      action.key === 'arrival' ?
      state.weather[action.flightLegId].arrivalWeather = '' :
      state.weather[action.flightLegId].departureWeather = '';

      action.key === 'arrival' ?
      state.weather[action.flightLegId].arrivalWeatherFetched = false :
      state.weather[action.flightLegId].departureWeatherFetched = false;

      return Object.assign({}, state, {
        isLoadingWeather: state.isLoadingWeather + 1,
        weather: state.weather,
      });
    }

    case type.FETCH_WEATHER_SUCCESS: {
      action.key === 'arrival' ?
      state.weather[action.flightLegId].arrivalWeather = action.data || '' : 
      state.weather[action.flightLegId].departureWeather = action.data || '';

      action.key === 'arrival' ?
      state.weather[action.flightLegId].isLoadingArrivalWeather = false : 
      state.weather[action.flightLegId].isLoadingDepartureWeather = false;

      action.key === 'arrival' ?
      state.weather[action.flightLegId].arrivalWeatherFetched = true :
      state.weather[action.flightLegId].departureWeatherFetched = true;

      return Object.assign({}, state, {
        isLoadingWeather: state.isLoadingWeather - 1,
        weather: state.weather,
      });
    }

    case type.FETCH_WEATHER_FAIL: {
      action.key === 'arrival' ?
      state.weather[action.flightLegId].isLoadingArrivalWeather = false : 
      state.weather[action.flightLegId].isLoadingDepartureWeather = false;

      action.key === 'arrival' ?
      state.weather[action.flightLegId].arrivalWeather = '' : 
      state.weather[action.flightLegId].departureWeather = '';

      action.key === 'arrival' ?
      state.weather[action.flightLegId].arrivalWeatherFetched = true :
      state.weather[action.flightLegId].departureWeatherFetched = true;
      
      return Object.assign({}, state, {
        isLoadingWeather: state.isLoadingWeather - 1,
        weather: state.weather,
      });
    }
    default:
      return state;
  }
}
