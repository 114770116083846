import tokenSource from '../sources/TokenSource';
import * as types from '../actionTypes';

export const fetchTokenInit = () => ({
  type: types.FETCH_TOKEN_INIT,
});

export const fetchTokenSuccess = data => ({
  type: types.FETCH_TOKEN_SUCCESS,
  data,
});

export const fetchTokenFail = data => ({
  type: types.FETCH_TOKEN_FAIL,
  data,
});

export const fetchRegistrationTokenInit = () => ({
  type: types.FETCH_REGISTRATION_TOKEN_INIT,
});

export const fetchRegistrationTokenSuccess = data => ({
  type: types.FETCH_REGISTRATION_TOKEN_SUCCESS,
  data,
});

export const fetchRegistrationTokenFail = data => ({
  type: types.FETCH_REGISTRATION_TOKEN_FAIL,
  data,
});

export const fetchToken = (firstValue, secondValue, isRegistrationFlow) => tokenSource(firstValue, secondValue, isRegistrationFlow);
