import axios from 'axios';
import { FETCH_ITINERARY_DETAILS_URL, CONTEXT, FETCH_ORDER_ID_BY_LEG } from '../../configs/constants';
import {
  itineraryDetailsInit,
  itineraryDetailsSuccess,
  itineraryDetailsFail,
  fetchOrderIdByLegInit,
  fetchOrderIdByLegSuccess,
  fetchOrderIdByLegFail,
} from '../actions';
import {
  handleServerErrors,
} from '../../utils/sourceUtils';
import { seprateDetailPastUpcomingLegs } from '../../utils/itinerariesUtils';
import { BAD_REQUEST } from '../../constants/ServerCodeConstants';
import { USER_REDUCER } from '../../constants/localStorageKeys';
import { getFromLocal } from '../../utils/cache';
import { SOMETHING_UNEXPECTED, NO_FLIGHT_LEG_ORDER_FOUND, NO_LEG_MESSAGE } from '../../constants/MessageConstants';

const status = {
  OK: 200,
};

const itinerarydetailsPromiseInprogress = {};

export const fetchItineraryDetailsSource = (token, key, params, type) => (dispatch) => {
  const uniqueKey = 'itineraryDetails';
  if (itinerarydetailsPromiseInprogress[uniqueKey]) {
    return itinerarydetailsPromiseInprogress[uniqueKey];
  }
  const itineraryDetailsPromise = new Promise((resolve, reject) => {
    const url = FETCH_ITINERARY_DETAILS_URL.replace('{context}', CONTEXT).replace('{token}', token)
      .replace('{key}', key).replace('{uniqueIdentifier}', params);
    dispatch(itineraryDetailsInit());
    axios
      .get(url)
      .then((response) => {
        if (response && response.data) {
          const responseHeader = response.data.responseHeaderDto;
          if (responseHeader && responseHeader.isError) {
            if (responseHeader.statusCode === 1 &&
              responseHeader.message === NO_LEG_MESSAGE) {
              dispatch(itineraryDetailsFail(NO_FLIGHT_LEG_ORDER_FOUND));
              reject(NO_FLIGHT_LEG_ORDER_FOUND);
            } else {
              const errors = handleServerErrors(responseHeader.statusCode);
              dispatch(itineraryDetailsFail(errors));
              reject(errors);
            }
          } else {
            const responseData = response.data.itineraryDtos[0];
            const sepratedData = seprateDetailPastUpcomingLegs(responseData.legs, type)
            responseData.legs = sepratedData;
            dispatch(itineraryDetailsSuccess(responseData));
            resolve(responseData);
          }
        }
        else {
          dispatch(itineraryDetailsFail(SOMETHING_UNEXPECTED));
        }

        delete itinerarydetailsPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        const errors = handleServerErrors(
          err.response
            ? err.response.status
            : err.response,
        );
        dispatch(itineraryDetailsFail(errors));
        reject(errors);
        delete itinerarydetailsPromiseInprogress[uniqueKey];
      });
  });
  itinerarydetailsPromiseInprogress[uniqueKey] = itineraryDetailsPromise;
  return itineraryDetailsPromise;
};

const fetchOrderIdByLegPromiseInprogress = {};
export const fetchOrderIdByLegDetails = (key, identifier) => (dispatch) => {
  const uniqueKey = 'fetchOrderIdByLeg';
  if (fetchOrderIdByLegPromiseInprogress[uniqueKey]) {
    return fetchOrderIdByLegPromiseInprogress[uniqueKey];
  }
  const { user } = getFromLocal(USER_REDUCER, true, true);
  const token = user.userToken;

  const fechOrderIdByLegPromise = new Promise((resolve, reject) => {
    const url = FETCH_ORDER_ID_BY_LEG.replace('{token}', token)
      .replace('{key}', key).replace('{legId}', identifier);
    dispatch(fetchOrderIdByLegInit());
    axios
      .get(url)
      .then((response) => {
        if (response.status === status.OK) {
          const responseHeader = response.data.responseHeaderDto;
          if (responseHeader.isError === true) {
            dispatch(fetchOrderIdByLegFail(handleServerErrors(responseHeader.statusCode)));
          } else {
            const responseData = response.data.itineraryDtos[0];
            dispatch(fetchOrderIdByLegSuccess(responseData.orderExtIdentifier));
            resolve(responseData.orderExtIdentifier);
          }
        } else {
          dispatch(fetchOrderIdByLegFail(handleServerErrors(BAD_REQUEST)));
          reject(handleServerErrors(BAD_REQUEST));
        }
        delete fetchOrderIdByLegPromiseInprogress[uniqueKey];
      })
      .catch((err) => {
        if (!err.status) {
          dispatch(fetchOrderIdByLegFail(handleServerErrors()));
          reject(handleServerErrors());
        } else {
          dispatch(fetchOrderIdByLegFail(handleServerErrors(BAD_REQUEST)));
          reject(handleServerErrors(BAD_REQUEST));
        }
        delete fetchOrderIdByLegPromiseInprogress[uniqueKey];
      });
  });
  fetchOrderIdByLegPromiseInprogress[uniqueKey] = fechOrderIdByLegPromise;
  return fechOrderIdByLegPromise;
};
