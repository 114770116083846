import moment from 'moment';

export const getTimeString = (eventDate, localDate) => {
  const event = moment(eventDate).utc().startOf('day');
  const local = moment(localDate).utc().startOf('day');
  const diff = event.diff(local, 'days');
  let diffStr = '';
  if (diff > 0) {
    diffStr = ` (+${diff})`;
  } else if (diff < 0) {
    diffStr = ` (${diff})`;
  }
  return `${moment(eventDate).utc().format('HH:mm')} loc${diffStr}`;
};
