import * as type from '../actionTypes';
import {
  sortPastItineraries,
  sepratePastUpcomingLegs,
  sortUpcomingItinerariesLegs,
} from '../../utils/itinerariesUtils';
import { ITINERARY_TYPES } from '../../constants/ItineraryConstants';


const initialState = {
  errorsItineraries: '',

  pastItineraries: [],
  cancelItineraries: [],
  upcomingItineraries: [],

  isLoadMorePast: false,
  isLoadMoreUpcoming: false,
  isLoadMoreCancelled: false,

  isLoadingMorePastItineraries: false,
  isLoadingMoreUpcomingItineraries: false,
  isLoadingMoreCancelledItineraries: false,

  isLoadingPastItineraries: false,
  isLoadingUpcomingItineraries: false,
  isLoadingCancelledItineraries: false,

};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.FETCH_ITINERARIES_INIT: {
      let {
        isLoadingPastItineraries,
        isLoadingUpcomingItineraries,
        isLoadingCancelledItineraries,
        isLoadingMorePastItineraries,
        isLoadingMoreUpcomingItineraries,
        isLoadingMoreCancelledItineraries,
      } = state;
      if (action.itineraryType === ITINERARY_TYPES.PAST_ITINERARIES) {
        action.isLoadMore ? isLoadingMorePastItineraries = true : isLoadingPastItineraries = true;
      } else if (action.itineraryType === ITINERARY_TYPES.UPCOMING_ITINERARIES) {
        action.isLoadMore ? isLoadingMoreUpcomingItineraries = true : isLoadingUpcomingItineraries = true;
      } else if (action.itineraryType === ITINERARY_TYPES.CANCELLED_ITINERARIES) {
        action.isLoadMore ? isLoadingMoreCancelledItineraries = true : isLoadingCancelledItineraries = true;
      }
      return Object.assign({}, state, {
        isLoadingPastItineraries,
        isLoadingUpcomingItineraries,
        isLoadingCancelledItineraries,
        isLoadingMorePastItineraries,
        isLoadingMoreUpcomingItineraries,
        isLoadingMoreCancelledItineraries,
        errorsItineraries: '',
      });
    }

    case type.FETCH_ITINERARIES_SUCCESS: {
      let { upcomingItineraries, pastItineraries, cancelItineraries } = state;
      const {
        upcomingOrders,
        pastOrders,
        cancelledOrders,
      } = sepratePastUpcomingLegs(
        action.data,
        upcomingItineraries,
        pastItineraries,
        cancelItineraries,
      );
      upcomingItineraries = upcomingItineraries.concat(upcomingOrders);
      pastItineraries = pastItineraries.concat(pastOrders);
      cancelItineraries = cancelItineraries.concat(cancelledOrders);

      upcomingItineraries.sort(sortUpcomingItinerariesLegs);
      pastItineraries.sort(sortPastItineraries);

      const LoadingKeys = {
        isLoadingPastItineraries: state.isLoadingPastItineraries,
        isLoadMorePast: state.isLoadMorePast,
        isLoadingUpcomingItineraries: state.isLoadingUpcomingItineraries,
        isLoadMoreUpcoming: state.isLoadMoreUpcoming,
        isLoadingCancelledItineraries: state.isLoadingCancelledItineraries,
        isLoadMoreCancelled: state.isLoadMoreCancelled,
        isLoadingMorePastItineraries: state.isLoadingMorePastItineraries,
        isLoadingMoreUpcomingItineraries: state.isLoadingMoreUpcomingItineraries,
        isLoadingMoreCancelledItineraries: state.isLoadingMoreCancelledItineraries,
      };
      if (action.itineraryType === ITINERARY_TYPES.PAST_ITINERARIES) {
        action.isLoadMore ? LoadingKeys.isLoadingMorePastItineraries = false : LoadingKeys.isLoadingPastItineraries = false;
        LoadingKeys.isLoadMorePast = action.data.length ? true : false;
      } else if (action.itineraryType === ITINERARY_TYPES.UPCOMING_ITINERARIES) {
        action.isLoadMore ? LoadingKeys.isLoadingMoreUpcomingItineraries = false : LoadingKeys.isLoadingUpcomingItineraries = false;
        LoadingKeys.isLoadMoreUpcoming = action.data.length ? true : false;
      } else if (action.itineraryType === ITINERARY_TYPES.CANCELLED_ITINERARIES) {
        action.isLoadMore ? LoadingKeys.isLoadingMoreCancelledItineraries = false : LoadingKeys.isLoadingCancelledItineraries = false;
        LoadingKeys.isLoadMoreCancelled = action.data.length ? true : false;
      }

      return Object.assign({}, state, {
        ...LoadingKeys,
        upcomingItineraries,
        pastItineraries,
        cancelItineraries,
        errorsItineraries: '',
      });
    }

    case type.FETCH_ITINERARIES_FAIL: {
      const LoadingKeys = {
        isLoadingPastItineraries: false,
        isLoadingUpcomingItineraries: false,
        isLoadingCancelledItineraries: false,
      };
      if (action.itineraryType === ITINERARY_TYPES.PAST_ITINERARIES) {
        action.isLoadMore ? LoadingKeys.isLoadingMorePastItineraries = false : LoadingKeys.isLoadingPastItineraries = false;
      } else if (action.itineraryType === ITINERARY_TYPES.UPCOMING_ITINERARIES) {
        action.isLoadMore ? LoadingKeys.isLoadingMoreUpcomingItineraries = false : LoadingKeys.isLoadingUpcomingItineraries = false;
      } else if (action.itineraryType === ITINERARY_TYPES.CANCELLED_ITINERARIES) {
        action.isLoadMore ? LoadingKeys.isLoadingMoreCancelledItineraries = false : LoadingKeys.isLoadingCancelledItineraries = false;
      }
      return Object.assign({}, state, {
        ...LoadingKeys,
        upcomingItineraries: [],
        pastItineraries: [],
        cancelItineraries: [],
        errorsItineraries: action.data,
      });
    }
    
    case type.CLEAR_ERROR_FETCH_ITINERARY: {
      return Object.assign({}, state, {
        errorsItineraries: '',
      });
    }
    default:
      return state;
  }
}
