import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCountry } from '../../utils/countryUtils';
import moment from 'moment';
import { getPassengerId } from 'utils/passengerUtils';
import { convertToTitleCase } from 'utils/stringUtils';
import {
  PassengerList,
} from 'constants/passengerConstants';
import ConfirmationPopUp from '../shared/ConfirmationPopUpNew';

class PassengerRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassport: false,
      showIcons: false,
      changePassportNumber: 0,
      removeBorder: true,
    };
  }

  setLegPassport = (passportNumber) => {
    this.props.handleDirtyChange(true);
    this.setState({
      changePassportNumber: passportNumber,
    });
    const paxId = getPassengerId(this.props.passenger);
    this.props.setLegPassport(paxId, passportNumber);
  }

  setToLeadPassenger = () => {
    this.props.handleDirtyChange(true);
    const paxId = getPassengerId(this.props.passenger);
    this.props.setLeadPassenger(paxId);
  }

  handleIconsHover = () => {
    this.setState({
      showIcons: true,
    });
  }

  handleIconsOut = () => {
    this.setState({
      showIcons: false,
    });
  }

  viewPassengerDetail = () => {
    const paxId = getPassengerId(this.props.passenger);
    this.props.handleViewDetail(paxId);
  }

  handlePassportShow = (dropdownId) => {
    if (dropdownId && window.UIkit.dropdown(`#${dropdownId}`)) {
      window.UIkit.dropdown(`#${dropdownId}`).hide();
    }
    this.setState((PrevState) => ({
      showPassport: !PrevState.showPassport,
      removeBorder: !this.state.removeBorder,
    }));
  }

  handleRemoveUser = (dropdownId) => {
    if (dropdownId && window.UIkit.dropdown(`#${dropdownId}`)) {
      window.UIkit.dropdown(`#${dropdownId}`).hide();
    }
    this.deletePopup.show();
  }
  removeUser = () => {
    this.props.handleDirtyChange(true);
    const paxId = getPassengerId(this.props.passenger);
    this.props.removeUser(paxId);
    this.closeDeletePopup()
  }
  closeDeletePopup = () => {
    this.deletePopup.hide();
  }
  getDefaultPassportChecked = (isDefault, passportNumber) => {
    const { changePassportNumber } = this.state;
    if (changePassportNumber === passportNumber) {
      return true;
    } else if (!changePassportNumber && isDefault) {
      return true;
    }
    return false;
  }
  renderPassport = (passports) => (
    !passports.length ? (
      <div className="uk-width-1-1 uk-margin-small">
        No passport added
        </div>
    )
      :
      passports.map((passport, index) => {
        if (passport.isDeleted || (!passport.isActive && !passport.isLegPassport)) {
          return null;
        }
        const passportCountry = getCountry('THREE_LETTER_CODE', passport.isoPassportCountry) || {};
        const countryFlagCode = passportCountry && passportCountry.TWO_LETTER_CODE;
        const dateToExpire = passport.expiry ?
          `Expires ${moment(passport.expiry).format('DD MMM YYYY')}` : '';
        return (
          <div className="vistajet_user_profile_container vistajet_passenger_passport_container" key={index}>
            <div className="vistajet_profile_details_column">
              <div className="vistajet_passport_details">
                <div className="uk-flex uk-grid-small uk-grid uk-grid-stack">
                  <div className="uk-width-1-1">
                    <div className="vistajet_passpport_list_content">
                      <div className="vistajet_details_hover vistajet_passport_buttons_list">

                        <label className="vistajet_radio vistajet_passenger_passport_radio_buttons" >
                          <input name="checkbox" type="checkbox" id={passport.passportId}
                            checked={this.getDefaultPassportChecked(passport.isLegPassport, passport.passportNumber)}
                            onChange={() => { this.setLegPassport(passport.passportNumber); }}
                          />
                          <span>&nbsp;</span>
                        </label>
                        <span className={`vistatejet_passenger_passport_flag flag-icon-style flag-icon-${countryFlagCode && countryFlagCode.toLowerCase()}`}>
                        </span>
                        <span className="vistajet_text uk-text-middle vistajet_passport_number">{passport && passport.passportNumber.toUpperCase()}</span>
                        <span className="vistajet_text uk-text-middle vistajet_expires_number">
                          <small className="uk-margin-small-left">{dateToExpire}</small>
                        </span>
                        <span className="vistajet_passport_hover_action vistajet_passport_buttons">
                          <span>
                            <img src="/legacy/static/img/icons/vistajet_close_grey_icon.svg" alt=""
                              onClick={() => {
                                const paxId = getPassengerId(this.props.passenger);
                                this.props.handleRemovePassport(passport.passportNumber, paxId);
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
  );
  render() {
    const {
      passenger,
      passengerCount,
      uniqueId,
    } = this.props;
    const passengerName = convertToTitleCase(`${passenger.firstName} ${passenger.lastName}`);
    const {
      showPassport,
      removeBorder,
    } = this.state;
    const dropdownId = uniqueId;
    const paxStatus = passenger.isApproved ? 'Confirmed' : 'On Request';
    let removeBorderClass = 'vistajet_remove_border_bottom'
    if (!removeBorder) {
      removeBorderClass = '';
    }
    const activePassportClass = showPassport ? `passport_active_class` : ``;
    return (
      <div className={`vistajet_passenger_list uk-margin-remove uk-padding-bottom ${removeBorderClass}`} key={uniqueId}>
        <div className="uk-grid uk-grid-stack">
          <div className={`uk-width-1-1 uk-margin-small-bottom uk-padding-remove vistajet_passenger_list_child_list uk-first-column`}>
            <span className="uk-align-left vistajet_passenger_list_name uk-margin-small"
              onClick={() => {
                const id = getPassengerId(passenger);
                this.viewPassengerDetail(id);
              }}
            >
              {passengerName}
            </span>
            {passenger.leadPassenger && <span className="vistajet_passenger_list_icons vistajet_lead_pax_icon">
              <img src="/legacy/static/img/icons/vistajet_icon_leadPax.svg" className="vistajet_leadPax_icon_resize" alt="" />
            </span>}
            <span className="uk-visible@m uk-align-right vistajet_passenger_status">
              {paxStatus}
              <span className={`vistajet_passenger_list_icons ${activePassportClass}`} onClick={() => {
                const id = getPassengerId(passenger);
                this.viewPassengerDetail(id);
              }}>
                <img src="/legacy/static/img/icons/vistajet_password_show_icon.svg" alt="" />
              </span>
              {!passenger.leadPassenger &&
                <span className={`vistajet_passenger_list_icons ${activePassportClass}`} onClick={this.setToLeadPassenger}>
                  <img src="/legacy/static/img/icons/vistajet_icon_leadPax.svg" alt="" />
                </span>}
              {
                showPassport ?
                  <span className={`vistajet_passenger_list_icons passenger_active_passport ${activePassportClass}`} onClick={this.handlePassportShow}>
                    <img src="/legacy/static/img/icons/icon_passport_active.svg" alt="" />
                  </span> :
                  <span className="vistajet_passenger_list_icons" onClick={this.handlePassportShow}>
                    <img src="/legacy/static/img/icons/vistajet_icon_passport.svg" alt="" />
                  </span>
              }
              <span className={`vistajet_passenger_list_icons ${activePassportClass}`} onClick={this.handleRemoveUser}>
                <img src="/legacy/static/img/icons/vistajet_icon_passport_delete.svg" alt="" />
              </span>
            </span>
            <ul class="uk-iconnav uk-flex-right uk-hidden@m vistajet_passenger_iconnav_dropdown">
              <span className="vistajet_passenger_status_mobile">
                {paxStatus}
              </span>
              <li><button class="vistajet_more_icon" type="button"></button></li>
              <div id={dropdownId} uk-dropdown="mode: click; pos: bottom-right; delay-hide: 0" class="uk-margin-remove vistajet_iconnav_dropdown">
                <ul class="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
                  <li className="uk-text-truncate">
                    <a onClick={() => {
                      const id = getPassengerId(passenger);
                      this.viewPassengerDetail(id);
                    }}>
                      <img src="/legacy/static/img/icons/vistajet_password_show_icon.svg" alt="" />
                      <span>View</span>
                    </a>
                  </li>
                  {!passenger.leadPassenger &&
                    <li className="uk-text-truncate">
                      <a onClick={this.setToLeadPassenger}>
                        <img src="/legacy/static/img/icons/vistajet_icon_leadPax.svg" alt="" />
                        <span>Make Lead Passenger</span>
                      </a>
                    </li>}
                  <li className="uk-text-truncate">
                    <a onClick={() => this.handlePassportShow(dropdownId)}>
                      <img src="/legacy/static/img/icons/vistajet_icon_passport.svg" alt="" />
                      <span>Manage Passport</span>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.handleRemoveUser(dropdownId)}>
                      <img src="/legacy/static/img/icons/vistajet_icon_passport_delete.svg" alt="" />
                      <span>Remove</span>
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
          {showPassport && <React.Fragment>
            {this.renderPassport(passenger.passports)}
            <div className="vistajet_passenger_add_new_passport_button">
              <a className="vistajet_button_default uk-margin-remove" uk-toggle=""
                onClick={() => {
                  const id = getPassengerId(passenger);
                  this.props.handleAddNewPassport(PassengerList, id);
                }}>
                Add new passport
              </a>
            </div>
          </React.Fragment>}
        </div>
        <ConfirmationPopUp
          ref={(deletePopup) => { this.deletePopup = deletePopup; }}
          id="deletePopup"
          leftButtonContent="No"
          rightButtonContent="Yes"
          onLeftButtonClick={this.closeDeletePopup}
          onRightButtonClick={this.removeUser}
          content={`Are you sure you want to remove this passenger?`}
          closeOnOverlayClick={false}
        />
      </div>
    );
  }
}
PassengerRow.propTypes = {
  passenger: PropTypes.object,
  passengerCount: PropTypes.number,
  handleViewDetail: PropTypes.func,
  handleAddNewPassport: PropTypes.func,
  handleRemovePassport: PropTypes.func,
  handleDirtyChange: PropTypes.func,
};
export default PassengerRow;
