import { connect } from 'react-redux';

import withStore from '../components/withStore';
import Offers from '../components/Offers';
import * as actions from '../store/actions';


function mapStateToProps(state) {
  const {
    isLoading, offers, error, totalOffers, cities, isOfferFetched
  } = state.offers;
  const { regionId } = state.geocode;
  const locationNotDisabled = !state.geocode.locationDisabled;
  const loadingGeolocation = state.geocode.isLoading;
  const { isLoggedIn, user } = state.user;
  const {
    visitedAirports,
    visitedAirportsFinder,
    isAirportsLoading,
    isAirportByIdLoading,
    isAirportsFetched
  } = state.visitedAirports;
  const {
    isGoogleMapsApiLoaded,
    isGooglePlacesAutocompleteBinded,
  } = state.googleMap;
  const { aircraftsList } = state.aircrafts;
  return {
    isLoggedIn,
    isLoading,
    offers,
    error,
    totalOffers,
    regionId,
    locationNotDisabled,
    loadingGeolocation,
    visitedAirports,
    visitedAirportsFinder,
    isGoogleMapsApiLoaded,
    isGooglePlacesAutocompleteBinded,
    isAirportsLoading,
    cities,
    isAirportByIdLoading,
    isAirportsFetched,
    user,
    isOfferFetched,
    aircraftsList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOffers: (pageNum, pageSize, regionId) => dispatch(
      actions.fetchAllOffers(pageNum, pageSize, regionId),
    ),
    onGoogleMapsApiLoaded: () => dispatch(actions.onGoogleMapsApiLoaded()),
    onPlacesAutocompleteBinded: () => dispatch(actions.onPlacesAutocompleteBinded()),
    fetchVisitedAirports: () => dispatch(actions.fetchAllVisitedAirports()),
    fetchGeocode: () => dispatch(actions.fetchCountryGeocode()),
    getAirportById: id => dispatch(actions.getAirportById(id)),
  };
}

const OffersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Offers);

export default withStore(OffersContainer);
