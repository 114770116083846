import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validateDateOfBirth,
} from './Validators';

export default function validateUserProfileForm(obj) {
  const firstNameResponse = validateFirstName(obj.firstName);
  const lastNameResponse = validateLastName(obj.lastName);
  const emailResponse = validateEmail(obj.email);
  const phoneNumberResponse = validatePhone(obj.phoneNumber, obj.isPhoneNumberValid);
  const dobResponse = validateDateOfBirth(obj.dateOfBirth);
  const res = {
    errors: {},
  };
  if (firstNameResponse.isError) {
    res.isError = firstNameResponse.isError;
    res.errors.firstName = firstNameResponse.message;
  }
  if (lastNameResponse.isError) {
    res.isError = lastNameResponse.isError;
    res.errors.lastName = lastNameResponse.message;
  }
  if (emailResponse.isError) {
    res.isError = emailResponse.isError;
    res.errors.email = emailResponse.message;
  }
  if (phoneNumberResponse.isError) {
    res.isError = phoneNumberResponse.isError;
    res.errors.phoneNumber = phoneNumberResponse.message;
  }
  if (dobResponse.isError) {
    res.isError = dobResponse.isError;
    res.errors.dateOfBirth = dobResponse.message;
  }
  return res;
}
