import {
  GET_PUBLIC_FILE_URL,
  GET_PRIVATE_FILE_URL,
  DOWNLOAD_PRIVATE_FILE_URL,
  POST_FILE_URL,
} from '../configs/constants';
import { getToken } from './userUtils';
export function getFiles(fileName, userToken = '', isPublic = true, shouldDownload = false) {
  if (!fileName) {
    return '';
  }
  let fileURL;
  if (isPublic) {
    fileURL = GET_PUBLIC_FILE_URL.replace('{key}', fileName);
  } else {
    if (shouldDownload) {
      fileURL = DOWNLOAD_PRIVATE_FILE_URL
    .replace('{token}', userToken)
    .replace('{key}', fileName);
    } else {
      fileURL = GET_PRIVATE_FILE_URL
      .replace('{token}', userToken)
      .replace('{key}', fileName);
    }
  }
  return fileURL;
}
export function fileKeyFromURL(url, userToken, isPublic) {
  const fileName = url.split('fileKey=');
  return getFiles(fileName[fileName.length - 1], userToken, isPublic);
}

function isOldURL(url) {
  const val = url.match(/1.1|getfile/g);
  return (val[0] === '1.1' && val[1] === 'getfile');
}

export function getfileURL(currentURL, userToken, isPublic) {
  const fileName = currentURL.split('=');
  if (isOldURL(currentURL)) {
    return getFiles(fileName[fileName.length - 1], userToken, isPublic);
  }
  return currentURL;
}

export function postFiles(fileName, userToken = '') {
  const token = userToken || getToken();
  const fileURL = POST_FILE_URL
    .replace('{token}', token)
    .replace('{key}', fileName);
  return fileURL;
}

