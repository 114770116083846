import * as cateringSources from '../sources/CateringSource';
import * as types from "../actionTypes";

export const getCateringState = data => ({
  type: types.GET_CATERING_STATE_INIT,
  data
});

export const getCateringStateFail = data => ({
  type: types.GET_CATERING_STATE_FAIL,
  data
});

export const getCateringStateSuccess = data => ({
  type: types.GET_CATERING_STATE_SUCCESS,
  data
});

export const getCateringProposalInit = (data) => ({
  type: types.GET_CATERING_PROPOSAL_INIT,
  data
})

export const getCateringProposalFail = data => ({
  type: types.GET_CATERING_PROPOSAL_FAIL,
  data
})

export const getCateringProposalSuccess = data => ({
  type: types.GET_CATERING_PROPOSAL_SUCCESS,
  data
})

export const changeCateringPropsalInit = (data) => ({
  type: types.CHANGE_CATERING_PROPOSAL_INIT,
  data
})

export const changeCateringPropsalFail = data => ({
  type: types.CHANGE_CATERING_PROPOSAL_FAIL,
  data
})

export const changeCateringPropsalSuccess = data => ({
  type: types.CHANGE_CATERING_PROPOSAL_SUCCESS,
  data
})

export const getCateringPDFInit = (data) => ({
  type: types.GET_CATERING_PDF_FAIL,
  data
})

export const getCateringPDFFail = data => ({
  type: types.GET_CATERING_PDF_FAIL,
  data
})

export const getCateringPDFSuccess = data => ({
  type: types.GET_CATERING_PDF_SUCCESS,
  data
})


export const getCateringStateAction = (token, leg) => cateringSources.getCateringState(token, leg);
export const getCateringProposalAction = (token, flightId) => cateringSources.getCateringProposal(token, flightId);
export const changeCateringProposalAction = (token, flightId, cateringSelection, leg) => cateringSources.changeCateringSelection(token, flightId, cateringSelection, leg);
export const getCateringPDFAction = (token, flightId) => cateringSources.fetchCateringSelectionPDF(token, flightId);