import { fetchFbo } from '../sources/FboSource';
import * as types from '../actionTypes';

export const fetchFboInit = (flightLegId) => ({
  type: types.FETCH_FBO_INIT,
  flightLegId,
});

export const fetchFboSuccess = (data, flightLegId) => ({
  type: types.FETCH_FBO_SUCCESS,
  data,
  flightLegId,
});

export const fetchFboFail = (data, flightLegId) => ({
  type: types.FETCH_FBO_FAIL,
  data,
  flightLegId,
});

export const fetchFboDetails = (token, legId) => fetchFbo(token, legId);
