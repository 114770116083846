import React, { PropTypes } from "react";
import Helpers from "../../utils/Helpers";

const getDurationString = duration => {
  const hr = Math.floor(duration / 60);
  const min = Math.floor(duration % 60);
  const hrString = hr > 1 ? `${hr}h` : `${hr}h`;
  return `${hrString} ${Helpers.roundTo5Minutes(min)}m`;
};

const FlightTime = props => {

  const duration = !!props.duration ? (
    <div>
      {/* <i className="material-icons icon--timer">timer</i> */}
      {getDurationString(props.duration)}
    </div>
  ) : (
    <div className="flightime--before">
      {/* <i className="material-icons icon--timer">timer</i>Flight Time */}
    </div>
  );
  let iconDivStyle = { display: "flex" };
  iconDivStyle = Object.assign(iconDivStyle, { justifyContent: "center" });
  return (
    <div className="vistajet_departure_center">
      <span>{duration}</span>
    </div>
  );
};


export default FlightTime;
