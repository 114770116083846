import React from 'react';
import PropTypes from 'prop-types';
import { convertToTitleCase } from '../../utils/stringUtils';

const ExistingPax = (props) => {
  const {
    heading,
    frequentPax,
    search,
    paxList,
    handleAddPaxClick,
    submitting,
    searchTerm,
    searchClicked,
  } = props;

  const paxRows = (searchClicked ? paxList : frequentPax) || [];
  if (searchClicked && ((searchTerm && searchTerm.length < 3) || !searchTerm)) {
    return (
      <div className="uk-margin uk-padding-bottom vistajet_passenger_search_results">
        <span style={{ color: '#c60c30' }}>
          Search should be at least 3 characters long.
        </span>
      </div>
    );
  }
  if (!paxRows.length) {
    if (search && !submitting) {
      return (
        <div className="uk-margin uk-padding-bottom vistajet_passenger_search_results">
          <span> No results found. </span>
        </div>
      );
    }
    if (!search) {
      return null;
    }
  }

  const paxNameRows = paxRows.map((pax, index) => (
      <li onClick={() => { handleAddPaxClick(pax); }} key={pax.passengerId+index}>
      {convertToTitleCase(`${pax.firstName} ${pax.lastName}`)}
      <span className="vistajet_add_passenger_chevron">
        <img src="/legacy/static/img/icons/vistajet_chevron_right_gray.svg" alt="add passenger" />
      </span>
      </li>)
  );

  return (
    <div className="uk-margin uk-padding-bottom vistajet_passenger_search_results">
      <h6 className="vistajet_subheading">{heading}</h6>
        <ul className="vistajet_search_results_list uk-padding-remove">
          {paxNameRows}
        </ul>
    </div>
  );
};

ExistingPax.propTypes = {
  heading: PropTypes.string,
  marginClass: PropTypes.string,
  frequentPax: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  search: PropTypes.bool,
  paxList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleAddPaxClick: PropTypes.func,
  submitting: PropTypes.bool,
  searchTerm: PropTypes.string,
  searchClicked: PropTypes.bool,
};

export default ExistingPax;
