import { connect } from 'react-redux';
import withStore from '../components/withStore';

import DirectAccountBalance from '../components/AccountStatement/DirectAccountBalance';
import { MembershipAccountStatement } from '../store/actions/DirectAccountBalanceActions';
import { 
  TopUpReferenceAction,
  TopUpAction,
  TopUpCancelReferenceCode,
  TopUpViewAction,
  ClearTopUpError
} from '../store/actions/TopUpAccountActions';
import { getAccountId } from '../../src/utils/userUtils';

function mapStateToProps(state) {
  const { user } = state.user;
  const accountId = user ? getAccountId(user) : null;
  const token = user ? user.userToken : null;
  const {  
    membershipAccountLoading,
    accountData,
    moreTransactions,
    loadingMoreTransactions,
    directAccoutBalanceError,
  } = state.directAccountBalance;
  const {
    swift,
    bankName,
    currency,
    topUpAmount,
    to,
    iban,
    referenceCode,
    topUpId,
    openPopUpMessage,
    topUpReferenceLoader,
    topUpSubmittedLoader,
    topUpCancelLoader,
    disableAmountField,
    topUpError,
  } = state.topUp;

  return {
    token,
    membershipAccountLoading,
    accountData,
    moreTransactions,
    loadingMoreTransactions,
    directAccoutBalanceError,
    //topup
    user,
    accountId,
    swift,
    bankName,
    currency,
    topUpAmount,
    to,
    iban,
    referenceCode,
    topUpId,
    openPopUpMessage,
    topUpReferenceLoader,
    topUpSubmittedLoader,
    topUpCancelLoader,
    disableAmountField,
    topUpError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetMembershipAccountStatement: (token, currentLength, isLoadMore) =>
      dispatch(MembershipAccountStatement(token, currentLength, isLoadMore)),
      
    TopUpReference: (token, accountId, transferAmount, Currency) =>
      dispatch(TopUpReferenceAction(token, accountId, transferAmount, Currency)),

    TopUpAction: (token, topUpId) =>
      dispatch(TopUpAction(token, topUpId)),

    CancelTopUp: (token, topUpId) => 
      dispatch(TopUpCancelReferenceCode(token, topUpId)),

    SetOpenPopupFalse : () =>
      dispatch(TopUpViewAction()),
    
    ClearTopUpError : () =>
      dispatch(ClearTopUpError()),
  };
}

const DirectAccountBalanceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DirectAccountBalance);

export default withStore(DirectAccountBalanceContainer);
