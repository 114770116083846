import React from 'react';

import visa from 'payment-icons/svg/single/visa.svg';
import american from 'payment-icons/svg/single/amex.svg';
import diners from 'payment-icons/svg/single/diners.svg';
import discover from 'payment-icons/svg/single/discover.svg';
import master from 'payment-icons/svg/single/mastercard.svg';

import CreditCardFlagImage from './CreditCardFlagImage';

const CREDIT_CARD_BRAND_IMAGE = {
  1: american,
  2: diners,
  3: discover,
  4: master,
  5: visa,
};

const CreditCardFlag = (props) => {
  const {
    brandId,
    ...other
  } = props;

  if (!brandId) {
    return <noscript />;
  }

  const backgroundImage = CREDIT_CARD_BRAND_IMAGE[brandId];

  return <CreditCardFlagImage backgroundImage={backgroundImage} {...other} />;
};

export default CreditCardFlag;
