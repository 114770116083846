import React from "react";
import Helpers from "../../utils/Helpers";
import moment from "moment";
import { dateFormats, formatDate } from "../../utils/dateUtils";
import { capitalize } from "../../utils/stringUtils";
import { ITINERARY_DETAILS_PAGE_URL } from "../../configs/constants";
import dirtystateSingleTon from "../../validators/validateDirty";
import SPAConstants from "../../constants/SPAConstants";

const glex = "/legacy/static/img/icons/GLEX-icon.svg";

export const formatDepartTime = departTime => {
  const depart = departTime.split("T")[1].split(":");
  const formattedDepartTime = `${depart[0]}:${depart[1]} loc`;
  return formattedDepartTime;
};

export const formatArrivalTime = (departTime, arrivalTime) => {
  const arrival = arrivalTime.split("T")[1].split(":");
  const daysDiff = Helpers.getDaysDiff(departTime, arrivalTime);
  const formattedArrivalTime =
    `${arrival[0]}:${Helpers.roundTo5Minutes(arrival[1])} loc ${daysDiff}`;
  return formattedArrivalTime;
};
export const makeData = legs => {
  let legCardObject = {
    date: null,
    departureAirport: {
      city: null,
      name: null
    },
    arrivalAirport: {
      city: null,
      name: null
    },
    time: null,
    arrivalTime: null,
    departTime: null
  };
  if (legs.length === 1) {
    const leg = legs[0];
    legCardObject = {
      date: leg.date,
      departureAirport: {
        city: leg.departureAirport.city,
        name: leg.departureAirport.name
      },
      arrivalAirport: {
        city: leg.arrivalAirport.city,
        name: leg.arrivalAirport.name
      },
      time: Number(leg.duration),
      aircraftName: leg.selectedAircraft.displayName,
      arrivalTime: formatArrivalTime(leg.departTime, leg.arrivalTime),
      departTime: formatDepartTime(leg.departTime),
      arrivalDate: leg.arrivalTime,
      departDate: leg.departTime,
      legsLength: legs.length
    };
  } else {
    const firstLeg = legs[0];
    const lastLeg = legs[legs.length - 1];
    legCardObject = {
      date: firstLeg.date,
      departureAirport: {
        city: firstLeg.departureAirport.city,
        name: firstLeg.departureAirport.name
      },
      arrivalAirport: {
        city: lastLeg.arrivalAirport.city,
        name: lastLeg.arrivalAirport.name
      },
      time: legs.reduce((sum, element) => sum + Number(element.duration), 0),
      arrivalTime: formatArrivalTime(lastLeg.departTime, lastLeg.arrivalTime),
      departTime: formatDepartTime(firstLeg.departTime),
      isReturn: firstLeg.departureAirport.id === lastLeg.arrivalAirport.id,
      arrivalDate: lastLeg.arrivalTime,
      departDate: firstLeg.departTime,
      legsLength: legs.length
    };
  }
  return legCardObject;
};

const redirectToItinerary = props => {
  const { orderIdentifiers, itineraryDetails } = props;
  const key = Object.keys(itineraryDetails)[0];
  const Url = ITINERARY_DETAILS_PAGE_URL.url
    .replace("{key}", key)
    .replace("{orderExtendifier}", itineraryDetails[key])
    .replace("{flightLegExtendifier}", orderIdentifiers[0])
    .replace("{itineraryType}", "upcomingItineraries");
  window.open(Url, "_self");
};

const shouldViewButton = (requestFlightSuccess, requestQuoteSuccess, buyOfferSuccess, isBuyQuote) => {
  let isShowViewItinerary = false;
  if (requestFlightSuccess === true || buyOfferSuccess === true || isBuyQuote) {
    isShowViewItinerary = true;
  }
  return isShowViewItinerary;
}

const ThankYou = props => {
  dirtystateSingleTon.setDirty(SPAConstants.PAFContainer, false);
  dirtystateSingleTon.setDirty(SPAConstants.MyQuotes, false);
  const { requestFlightSuccess, requestQuoteSuccess, buyOfferSuccess, isBuyQuote } = props;
  const structuredData = makeData(props.legArray);
  const shouldButtonAppear = shouldViewButton(requestFlightSuccess, requestQuoteSuccess, buyOfferSuccess, isBuyQuote);
  const {
    departureAirport,
    arrivalAirport,
    time,
    aircraftName,
    arrivalTime,
    departTime,
    isReturn,
    arrivalDate,
    departDate,
    legsLength
  } = structuredData;
  let iconClass = "vistajet_departure_center";
  if (isReturn) {
    iconClass = "vistajet_departure_center vistajet_return_icon";
  } else if (legsLength > 1) {
    iconClass = "vistajet_departure_center vistajet_multicities_icon";
  }
  const formattedDepartDate = formatDate(
    moment(departDate).utc(),
    dateFormats.DDMMMYYYY
  );
  const formattedArrivalDate = formatDate(
    moment(arrivalDate),
    dateFormats.DDMMMYYYY
  );
  const formattedTime = `${Math.floor(time / 60)}h ${Helpers.roundTo5Minutes(
    Math.floor(time % 60)
  )}m`;
  const departureAirportCity = capitalize(departureAirport.city);
  const departureAirportName = capitalize(departureAirport.name);
  const arrivalAirportCity = capitalize(arrivalAirport.city);
  const arrivalAirportName = capitalize(arrivalAirport.name);
  return (
    <div className="vistajet_bs_thankyou_card">
      <div className="vistajet_bs_head">
        <h3 className="uk-text-center vistajet_color_red">Thank you</h3>
        <p className="uk-text-center">{props.message}</p>
      </div>
      <div className="vistajet_bs_body">
        <div className="uk-width-1-1">
          <div class="vistajet_order_status_column">
            <div class="uk-clearfix">
              <div class="uk-float-right vistajet_red_text">
                {props.orderStatus}
              </div>
              {props.orderId ? (<div class="uk-float-left">
                Order # {props.orderId}
              </div>) : null}
            </div>
          </div>
          <div className="vistajet_date_column">
            <div class="uk-clearfix">
              <div class="uk-float-right">
                <span className="vistajet_date_icon uk-position-relative">
                  {formattedArrivalDate}
                </span>
              </div>
              <div class="uk-float-left">
                <span className="vistajet_date_icon">
                  {formattedDepartDate}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1">
          <div className="vistajet_departure_column">
            <div className="uk-flex">
              <div className="uk-width-2-5">
                <div className="vistajet_departure_left">
                  {departureAirportCity}
                  <small>{departureAirportName}</small>
                </div>
              </div>
              <div className="uk-width-1-5 uk-text-center">
                <div className={iconClass}>
                  <span>{formattedTime}</span>
                </div>
              </div>
              <div className="uk-width-2-5 uk-text-right">
                <div className="vistajet_departure_right">
                  {arrivalAirportCity}
                  <small>{arrivalAirportName}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="vistajet_eta_column">
            <div className="uk-clearfix">
              <div className="uk-float-right">{arrivalTime}</div>
              <div className="uk-float-left">{departTime}</div>
            </div>
          </div>
          <div className="vistajet_cp_column">
            <div className="uk-clearfix">
              {aircraftName ? (
                <div className="uk-float-left vistajet_aircraft_column">
                  <img src={glex} width="90" height="90" alt="" />
                  <small className="uk-text-middle">{aircraftName}</small>
                </div>
              ) : null}
            </div>
          </div>
          <div className="vistajet_divider" />
        </div>
      </div>
      <div className="vistajet_back_home_column">
        <p className="uk-text-center">
          Request another flight?{" "}
          <a onClick={() => props.redirectToHome(true)}>Go to Home</a>
        </p>
      </div>
      <div className="vistajet_bs_foot">
        {shouldButtonAppear && (
          <button
            className="uk-width-1-1 vistajet_button_primary"
            onClick={() => redirectToItinerary(props)}
          >
            View this Itinerary
          </button>
        )}
      </div>
    </div>
  );
};

export default ThankYou;
