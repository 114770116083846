import * as type from '../../actionTypes';
import { getFlightId } from '../../../utils/itinerariesUtils';

const initialState = {
  isLoadingMovementMessages: 0,
};

export default function launchReducer(state = initialState, action) {
  switch (action.type) {
    case type.ITINERARY_DETAILS_SUCCESS: {
      const {
        legs,
      } = action.data;
      const movementMessagesData = {};
      legs.forEach(leg => {
        movementMessagesData[getFlightId(leg)] = {};
        movementMessagesData[getFlightId(leg)].isLoadingMovementMessages = false;
      })
      return Object.assign({}, state, {
        movementMessages: movementMessagesData,
      });
    }
    case type.FETCH_MOVEMENT_MESSAGES_INIT: {
      state.movementMessages[action.flightLegId].isLoadingMovementMessages = true;
      state.movementMessages[action.flightLegId].movementMessagesFetched = false;
      return Object.assign({}, state, {
        isLoadingMovementMessages: state.isLoadingMovementMessages + 1,
        movementMessages: state.movementMessages,
      });
    }

    case type.FETCH_MOVEMENT_MESSAGES_SUCCESS: {
      state.movementMessages[action.flightLegId].movementMessages = action.data;
      state.movementMessages[action.flightLegId].movementMessagesFetched = true;
      state.movementMessages[action.flightLegId].isLoadingMovementMessages = false;
      return Object.assign({}, state, {
        isLoadingMovementMessages: state.isLoadingMovementMessages - 1,
        movementMessages: state.movementMessages,
      });
    }

    case type.FETCH_MOVEMENT_MESSAGES_FAIL: {
      state.movementMessages[action.flightLegId].isLoadingMovementMessages = false;
      state.movementMessages[action.flightLegId].movementMessagesFetched = true;
      return Object.assign({}, state, {
        isLoadingMovementMessages: state.isLoadingMovementMessages - 1,
        movementMessages: state.movementMessages,
      });
    }
    default:
      return state;
  }
}
