import React from "react";
import SpinnerLoader from './spinnerLoader';

const ConfirmationPopUp = props => {
  const {
    id,
    leftButtonContent,
    rightButtonContent,
    content,
    title,
    onLeftButtonClick,
    onClosePopup,
    isSendingForgotEmail,
  } = props;
  let { leftRef, rightRef, rightTarget, leftTarget } = props;

  rightRef = rightRef || "#";
  rightTarget = rightTarget ? `target: #${rightTarget}` : "false";

  leftRef = leftRef || "#";
  leftTarget = leftTarget ? `target: #${leftTarget}` : "false";

  const onRightButtonClick = props.onRightButtonClick ? (
    <button
      className="uk-button"
      onClick={props.onRightButtonClick}
    >
      {rightButtonContent}
    </button>
  ) : (
    <a
      className="uk-button"
      href={rightRef}
      data-uk-toggle={rightTarget}
    >
      {rightButtonContent}
    </a>
  );

  const onClose = () => {
    if (onClosePopup) onClosePopup(false);
  };

  return (
    <div
      id={id}
      className="vistajet_modal"
      data-uk-modal
      bg-close="false"
      esc-close="false"
    >
      <div className="uk-modal-dialog uk-modal-body vistajet_modal_body uk-margin-auto-vertical">
      {isSendingForgotEmail ? (
          <div className="vistajet_spinner">
            <SpinnerLoader />
          </div>
        ) :
        (
          <button
            className="uk-modal-close-default vistajet_modal_close"
            type="button"
            data-uk-close
            onClick={onClose}
          />
        )}
        {title && (
          <div className="vistajet_modal_header">
            <h4 className="uk-margin-small-top uk-text-center">{title}</h4>
          </div>
        )}

        <div className="vistajet_modal_content uk-text-center">{content}</div>

        <div className="uk-flex uk-child-width-1-2@s vistajet_button_wrapper">
          {onLeftButtonClick ? (
            <button className="uk-button uk-button-secondary" onClick={onLeftButtonClick}>
              {leftButtonContent}
            </button>
          ) : leftTarget !== "false" ? (
            <a
              className="uk-button uk-button-secondary"
              href={leftRef}
              data-uk-toggle={leftTarget}
            >
              {leftButtonContent}
            </a>
          ) : (
            <a className="uk-button uk-button-secondary" href={leftRef}>
              {leftButtonContent}
            </a>
          )}
          {onRightButtonClick}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopUp;
