import authenticateTokenSource from '../sources/AuthenticateTokenSource';
import * as types from '../actionTypes';

export const authenticateTokenInit = () => ({
  type: types.AUTHENTICATE_TOKEN_INIT,
});

export const authenticateTokenSuccess = data => ({
  type: types.AUTHENTICATE_TOKEN_SUCCESS,
  data,
});

export const authenticateTokenFail = data => ({
  type: types.AUTHENTICATE_TOKEN_FAIL,
  data,
});

export const authenticateTokenAction = token => authenticateTokenSource(token);
