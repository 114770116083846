import moment from "moment";
import React, { Component } from "react";

import Crew from "./Crew";
import Helpers from "../../utils/Helpers";
import FlightCancel from "./FlightCancel";
import ItineraryCard from "./ItineraryCard";
import MessagePopUp from "../shared/MessagePopUp";
import SpinnerLoader from "../shared/spinnerLoader";
import PassengerCardContainer from "../../containers/passengers/passengerCardContainer";
import GroundServicesCard from "./GroundServicesCard";
import Fbo from "./Fbo";
import Weather from "./Weather";
import PassengerCard from "./PassengerCard";
import OperationalNotes from "./OperationalNotes";
import MovementMessages from "./MovementMessages";
import EntertainmentCard from "./EntertainmentCard";
import { isFSP, isFspDirect, isAdhoc, isVJ2 } from "../../utils/userUtils";
import { isPastLeg } from "../../utils/itinerariesUtils";
import { guaranteeArray } from "../../utils/arrayUtils";
import {
  PAF_PAGE_URL,
  RAF_PAGE_URL,
  MODIFY_ORDER_PAGE_URL
} from "../../configs/constants";
import MessagePopUpNew from "../../../src/components/shared/MessagePopUpNew";
import CustomerFeedbackContainer from "../../containers/Itinerary/CustomerFeedbackContainer";
import {
  GT_EMPTY_MESSAGE,
  AS_EMPTY_MESSAGE,
  GROUND_TRANSPORT_SERVICE,
  ADDITIONAL_SERVICE
} from "../../constants/ItineraryConstants";
import Catering from "./Catering/Catering";

import { NO_FLIGHT_LEG_ORDER_FOUND } from "../../constants/MessageConstants";

class ItineraryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      currentLegId: "",
      airportIds: "",
      orderkey: "",
      uniqueIdentifier: "",
      errorArray: [],
      removeCard: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      token,
      legs = {},
      fetchGroundServices,
      isErrorCrew,
      isErrorGroundTransport,
      isErrorAvailedServices,
      isLoadingAvailedServices,
      isLoadingGroundServices,
      isLoadingCrew,
      isLoading,
      itineraryDetailsError
    } = nextProps;
    const { index, currentLegId, errorArray } = this.state;
    if (this.props.availedService !== nextProps.availedService) {
      this.setState({});
    }
    const legsArray = Object.keys(legs || {}).map(key => legs[key]);
    const legsSelected = legsArray[index] || {};
    const currentLegIdSelected =
      currentLegId || legsSelected.legId || legsSelected.flightLegExtIdentifier;
    this.setState({
      legsArray,
      currentLegId: currentLegIdSelected
    });
    let allAirports = [];
    const a = legsArray.map(e => e.arrivalAirportId);
    const b = legsArray.map(e => e.departureAirportId);
    allAirports = a.concat(b);
    let airports = [...new Set(allAirports)];
    this.setState({ airportIds: airports });
    if (isErrorCrew) {
      this.addNewError(isErrorCrew);
    }
    if (isErrorGroundTransport) {
      this.addNewError(isErrorGroundTransport);
    }
    if (isErrorAvailedServices) {
      this.addNewError(isErrorAvailedServices);
    }
    if (itineraryDetailsError) {
      this.addNewError(itineraryDetailsError);
    }
    if (
      errorArray.length > 0 &&
      !isLoadingAvailedServices &&
      !isLoadingGroundServices &&
      !isLoadingCrew &&
      !isLoading
    ) {
      this.showError();
    }
    if (currentLegIdSelected && legsSelected && legsSelected.arrivalAirportId) {
      if (this.props.legs !== nextProps.legs) {
        fetchGroundServices(token, airports);
      }
    }
    this.fetchDataOnChange(nextProps, this.state);
  }

  componentDidMount() {
    const { fetchItineraryDetails, fetchVisitedAirports, token } = this.props;

    fetchVisitedAirports();
    this.onFetchItineraryDetails();
  }

  onFetchItineraryDetails = () => {
    const { fetchItineraryDetails, token, fetchVisitedAirports } = this.props;
    const params = window.location.search;
    const itineraryDetailsParam = params.split("/");
    const key = itineraryDetailsParam[itineraryDetailsParam.length - 4];
    const itineraryType =
      itineraryDetailsParam[itineraryDetailsParam.length - 1];
    const uniqueIdentifier =
      itineraryDetailsParam[itineraryDetailsParam.length - 3];

    // const key = 'orderId';
    // const uniqueIdentifier = '473911';
    // const itineraryType = 'upcomingItineraries';
    fetchItineraryDetails(token, key, uniqueIdentifier, itineraryType);
    this.setState({
      orderkey: key,
      uniqueIdentifier
    });
  };
  addNewError = error => {
    const { errorArray } = this.state;
    if (!errorArray.includes(error)) {
      this.setErrorArrayState(error);
    }
  };
  setErrorArrayState = error => {
    const { errorArray } = this.state;
    errorArray.push(error);
    this.setState({
      errorArray
    });
  };
  showError = () => {
    const { errorArray } = this.state;
    const errorMessage = errorArray.length && errorArray[errorArray.length - 1];
    if (NO_FLIGHT_LEG_ORDER_FOUND === errorMessage) {
      this.setState({
        removeCard: true
      });
    }
    this.errorValidationPopUp.show();
  };
  fetchDataOnChange = (props, state) => {
    const {
      legs,
      crew,
      token,
      fbo,
      fetchFbo,
      fetchCrew,
      weather,
      fetchWeather,
      itineraryPdf,
      availedService,
      getCateringState,
      getCateringProposal,
      fetchCateringPdf,
      fetchitineraryPdf,
      fetchAvailedServices,
      movementMessages,
      fetchMovementMessages,
      visitedAirportsFinder,
      fetchLegFeedback,
      feedback,
      isFeedbackLoading,
      cateringDetails,
      fetchPassengers,
      orderStatus,
      orderId,
      orderExtIdentifier,
      passengers
    } = props;
    const { currentLegId, orderkey } = state;
    const currentLeg = currentLegId && legs[currentLegId];
    const arrivalAirportTime = currentLeg && currentLeg.arrivalAirportTimeLocal;
    const departureAirportTime =
      currentLeg && currentLeg.departureAirportTimeLocal;
    const arrivalAirportDetail =
      visitedAirportsFinder[currentLeg && currentLeg.arrivalAirportId];
    const departureAirportDetail =
      visitedAirportsFinder[currentLeg && currentLeg.departureAirportId];
    const params = window.location.search;
    const itineraryDetailsParam = params.split("/");
    const key =
      currentLeg && (currentLeg.legId || '') !== "" ? "orderId" : "orderExtIdentifier";
    const availedServicesKey =
      currentLeg && (currentLeg.legId || '') !== ""
        ? "flightLegId"
        : "flightLegExtIdentifier";
    const isLegId = !isNaN(currentLegId);
    const hasLegId = currentLeg && (currentLeg || {}).legId ? true : false;

    if (
      currentLegId &&
      !(itineraryPdf[currentLegId] || {}).itineraryPdf &&
      !(itineraryPdf[currentLegId] || {}).itineraryPdfLoading &&
      isLegId
    ) {
      fetchitineraryPdf(token, currentLegId);
    }
    if (
      currentLegId &&
      !(crew[currentLegId] || {}).crew &&
      !(crew[currentLegId] || {}).crewSuccess &&
      !(crew[currentLegId] || {}).isLoadingCrew &&
      isLegId
    ) {
      fetchCrew(token, currentLegId);
    }
    if (
      currentLegId &&
      !(availedService[currentLegId] || {}).availedService &&
      !(availedService[currentLegId] || {}).isLoadingAvailedServices
    ) {
      fetchAvailedServices(token, availedServicesKey, currentLegId);
    }
    if (
      currentLegId &&
      isLegId &&
      legs[currentLegId] &&
      !cateringDetails[currentLegId] &&
      !(cateringDetails[currentLegId] || {}).isCateringLoading
    ) {
      getCateringState(token, legs[currentLegId]);
      getCateringProposal(token, currentLegId);
      fetchCateringPdf(token, currentLegId);
    }
    if (
      currentLegId &&
      !(passengers[currentLegId] || {}).passengers &&
      !(passengers[currentLegId] || {}).isLoadingPassenger
    ) {
      const currentLeg = currentLegId && legs[currentLegId];
      fetchPassengers(token, {
        params: {
          legId: currentLegId,
          status: orderStatus,
          uniqueIdentifier: orderId || orderExtIdentifier,
          key: orderkey
        },
        leg: currentLeg
      });
    }
    if (
      hasLegId &&
      currentLegId &&
      !(fbo[currentLegId] || {}).fbo &&
      !(fbo[currentLegId] || {}).fboFetched &&
      !(fbo[currentLegId] || {}).isLoadingFbo
    ) {
      fetchFbo(token, currentLegId);
    }
    if (
      hasLegId &&
      currentLegId &&
      !(movementMessages[currentLegId] || {}).movementMessages &&
      !(movementMessages[currentLegId] || {}).movementMessagesFetched &&
      !(movementMessages[currentLegId] || {}).isLoadingMovementMessages
    ) {
      fetchMovementMessages(token, currentLegId);
    }
    if (
      currentLegId &&
      currentLeg &&
      arrivalAirportDetail &&
      !(weather[currentLegId] || {}).arrivalWeather &&
      (weather[currentLegId] || {}).arrivalWeather !== "" &&
      !(weather[currentLegId] || {}).isLoadingArrivalWeather &&
      !(weather[currentLegId] || {}).arrivalWeatherFetched
    ) {
      fetchWeather(
        arrivalAirportDetail.latitude,
        arrivalAirportDetail.longitude,
        arrivalAirportTime,
        "arrival",
        currentLegId
      );
    }

    if (
      currentLegId &&
      currentLeg &&
      departureAirportDetail &&
      !(weather[currentLegId] || {}).departureWeather &&
      (weather[currentLegId] || {}).departureWeather !== "" &&
      !(weather[currentLegId] || {}).isLoadingDepartureWeather &&
      !(weather[currentLegId] || {}).departureWeatherFetched
    ) {
      fetchWeather(
        departureAirportDetail.latitude,
        departureAirportDetail.longitude,
        departureAirportTime,
        "departure",
        currentLegId
      );
    }
    if (
      currentLegId &&
      !(feedback[currentLegId] || {}).feedback &&
      !(feedback[currentLegId] || {}).feedbackSuccess &&
      !(feedback[currentLegId] || {}).isFeedbackLoading &&
      isLegId
    ) {
      const legData = {
        orderKey: key,
        legId: currentLegId,
        isPast: isPastLeg(currentLeg),
        arrivalAirportTimeUTC: currentLeg.arrivalAirportTimeUTC
      };
      fetchLegFeedback(legData);
    }
  };

  flightDates = departureTime => {
    const dateFormat = "DD MMM YYYY";
    const dates = `${moment.utc(departureTime).format(dateFormat)}`;
    return dates;
  };

  fetchPreviousLeg = () => {
    this.setLegIndex(this.state.index - 1);
  };

  fetchNextLeg = () => {
    this.setLegIndex(this.state.index + 1);
  };

  setLegIndex = index => {
    const { legsArray } = this.state;
    const state = {
      index: index,
      currentLegId:
        (legsArray[index] || {}).legId ||
        (legsArray[index] || {}).flightLegExtIdentifier
    };
    if (index >= (legsArray || []).length) {
      state.index = 0;
      state.currentLegId =
        legsArray[0].legId || legsArray[0].flightLegExtIdentifier;
    } else if (index < 0) {
      state.index = (legsArray || []).length - 1;
      state.currentLegId =
        legsArray[state.index].legId ||
        legsArray[state.index].flightLegExtIdentifier;
    }
    this.setState(state, () => {
      this.fetchDataOnChange(this.props, state);
    });
  };

  renderDots = legs => {
    const isMultileg = Object.keys(legs || {}).length > 1;
    const { legsArray } = this.state;
    if (isMultileg && legs) {
      return Object.keys(legs).map((leg, index) => {
        let flightIndex =
          (legsArray[index] || {}).legId ||
          (legsArray[index] || {}).flightLegExtIdentifier;
        return (
          <span
            onClick={() => this.setLegIndex(index)}
            className={`vistajet_slider_dot ${
              index === this.state.index ? "active" : ""
              }`}
          />
        );
      });
    }
    return null;
  };

  renderArrows = legs => {
    const isMultileg = Object.keys(legs || {}).length > 1;
    return isMultileg ? (
      <div className="vistajet_slide_arrow_row">
        <a className="uk-position-center-left" onClick={this.fetchPreviousLeg}>
          <img src="/legacy/static/img/icons/vistajet_arrow_aircraft.svg" className="vistajet_slide_arrow_left" />
        </a>
        <a className="uk-position-center-right" onClick={this.fetchNextLeg}>
          <img src="/legacy/static/img/icons/vistajet_arrow_aircraft.svg" className="vistajet_slide_arrow_right" />
        </a>
      </div>
    ) : null;
  };

  renderDownloadBtn = downloadItinerary => {
    const pdfUrl = downloadItinerary && downloadItinerary.itineraryPdf;
    return downloadItinerary && downloadItinerary.itineraryPdfLoading ? (
      <a
        className="vistajet_button_default uk-margin-remove vistajet_download_itinerary_button"
        target="_blank"
      >
        <span>Preparing Itinerary ...</span>
      </a>
    ) : (
        <a
          className="vistajet_button_default uk-margin-remove vistajet_download_itinerary_button"
          target="_blank"
          href={pdfUrl}
        >
          <span>Download Itinerary</span>
        </a>
      );
  };

  isRenderCancelButton = () => {
    this.onFetchItineraryDetails();
  };

  renderEditBtn = () => {
    const { legs } = this.props;
    const params = window.location.search;
    const itineraryDetailsParam = params.split("/");
    const key = itineraryDetailsParam[itineraryDetailsParam.length - 4];
    const uniqueIdentifier =
      itineraryDetailsParam[itineraryDetailsParam.length - 3];
    const flightLegExtIdentifier =
      itineraryDetailsParam[itineraryDetailsParam.length - 2];
    let isEditable = false;
    legs &&
      Object.keys(legs).forEach(leg => {
        if (moment(legs[leg].departureAirportTimeUTC).isAfter(moment.utc())) {
          isEditable = true;
        }
      });

    const redirectionURL =
      MODIFY_ORDER_PAGE_URL +
      `?${key}=${uniqueIdentifier}` +
      `&flightLegExtIdentifier=${flightLegExtIdentifier}`;
    return isEditable ? (
      <a
        className="vistajet_button_default uk-margin-remove vistajet_modify_order_button"
        onClick={() => this.renderEditContent(redirectionURL)}
      >
        <span>Modify Order</span>
      </a>
    ) : null;
  };

  renderEditContent = redirectionURL => {
    const { orderType, user } = this.props;
    if (isFSP(user) || isFspDirect(user)) {
      if (orderType === "" || orderType === "Program") {
        window.open(redirectionURL, "_self");
      } else {
        window.UIkit.modal("#cannotEditError").show();
      }
    } else {
      window.UIkit.modal("#cannotEditError").show();
    }
  };
  clearErrorMessage = () => {
    const { errorArray } = this.state;
    errorArray.pop();
    this.setState({
      errorArray
    });
    if (errorArray.length > 0) {
      this.showError();
    } else {
      this.props.clearErrorFetchItinerary();
    }
  };

  render() {
    const {
      fbo,
      legs,
      user,
      crew,
      token,
      orderId,
      isLoading,
      weather,
      isLoadingWeather,
      groundServices,
      getAirportById,
      accountId,
      orderStatus,
      flightCancel,
      itineraryPdf,
      availedASData,
      availedService,
      cateringDetails,
      movementMessages,
      updateFlightCancel,
      orderExtIdentifier,
      onGoogleMapsApiLoaded,
      isGoogleMapsApiLoaded,
      visitedAirportsFinder,
      addGroundTransportService,
      passengers,
      itineraryCancellationMessage,
      itineraryCancellationIsLoading,
      feedback,
      isFeedbackLoading,
      changeCateringProposal,
      isLoadingPassenger,
      aircraftsList
    } = this.props;
    const { currentLegId, errorArray, removeCard } = this.state;
    const fboDetails = currentLegId && fbo[currentLegId];
    const fboInfo = fboDetails && (fbo[currentLegId].fbo || {}).fboDetails;
    const isLoadingFbo = fboDetails && (fbo[currentLegId] || {}).isLoadingFbo;
    const crewInfos = currentLegId && crew[currentLegId];
    const passengersInfo = currentLegId && passengers[currentLegId];
    const announcedPax = currentLegId && passengers[currentLegId].announcedPax;
    const downloadItinerary = currentLegId && itineraryPdf[currentLegId];
    const weatherDetails = currentLegId && weather[currentLegId];
    const feedbackInfo = currentLegId && feedback[currentLegId];
    // const isFeedbackInProgress = feedback[currentLegId].isFeedbackLoading;
    const currentLeg = currentLegId && legs[currentLegId];
    const tailNumber =
      (currentLegId &&
        legs[currentLegId] &&
        legs[currentLegId].aircraftTailNumber) ||
      "_";
    const arrivalAirportId = currentLeg && currentLeg.arrivalAirportId;
    const departureAirportId = currentLeg && currentLeg.departureAirportId;
    const departureDate =
      currentLeg &&
      moment
        .utc(currentLeg.departureAirportTimeLocal)
        .format("dddd, D MMMM YYYY");
    const arrivalDate =
      currentLeg &&
      moment
        .utc(currentLeg.arrivalAirportTimeLocal)
        .format("dddd, D MMMM YYYY");
    const arrivalTime =
      (currentLeg && currentLeg.arrivalAirportTimeLocal) || "";
    const departureTime =
      (currentLeg && currentLeg.departureAirportTimeLocal) || "";
    const flightLegId =
      currentLeg && currentLeg.legId
        ? currentLeg.legId
        : currentLeg && currentLeg.flightLegExtIdentifier;
    let withIn24Hours = true;
    const availedServiceInfo = currentLegId && availedService[currentLegId];
    const availedASDataInfo = currentLegId && availedASData[currentLegId];
    const isLoadingMovementMessages =
      currentLegId &&
      currentLeg &&
      (movementMessages[currentLegId] || {}).isLoadingMovementMessages;
    const movementMessagesResponse =
      currentLegId &&
      currentLeg &&
      ((movementMessages[currentLegId] || {}).movementMessages || {})
        .movementMessageResultDTOes;
    const movementMessagesInfo = movementMessagesResponse && guaranteeArray(movementMessagesResponse.movementMessageResult);
    let legStatus = "";
    let isEditable = true;
    let isPast = true;
    const operationalNotes = (currentLeg && currentLeg.operationalNotes) || "";
    const canBook = isVJ2(user) || isAdhoc(user) || isFSP(user);
    if (currentLeg) {
      const departureAirportDetail =
        visitedAirportsFinder[currentLeg && currentLeg.departureAirportId];
      legStatus = currentLeg.legStatus;
      withIn24Hours = Helpers.satisfy24HoursCheck(
        currentLeg,
        departureAirportDetail
      );
      isEditable = Helpers.isEditableLeg(currentLeg, departureAirportDetail);
      isPast = isPastLeg(currentLeg);
    }

    let allAirports = [];
    if (currentLeg && currentLeg.arrivalAirportId) {
      allAirports.push(currentLeg.departureAirportId);
      allAirports.push(currentLeg.arrivalAirportId);
    }

    const params = window.location.search;
    const itineraryDetailsParam = params.split("/");
    const key = itineraryDetailsParam[itineraryDetailsParam.length - 4];
    const itineraryType =
      itineraryDetailsParam[itineraryDetailsParam.length - 1];
    const disableFeedback =
      itineraryType === "cancelledItineraries" ||
      ((itineraryType === "pastItineraries" ||
        itineraryType === "upcomingItineraries") &&
        (currentLeg && currentLeg.legStatus === "Cancellation Pending"));
    let legData = {};
    if (currentLeg) {
      legData = {
        orderKey: key,
        legId: currentLegId,
        isPast: isPastLeg(currentLeg),
        arrivalAirportTimeUTC: currentLeg.arrivalAirportTimeUTC
      };
    }

    return (
      <div
        className="uk-container uk-container-small uk-margin-medium-top vistajet_confirm_screen vistajet_itinerary_detail_screen"
        uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 25;"
      >
        <div className="uk-clearfix uk-flex uk-flex-middle uk-flex-between uk-margin-medium-bottom">
          <div className="uk-float-left">
            <h3 className="uk-margin-remove">Itinerary</h3>
          </div>
          <MessagePopUpNew
            id={"validationMessages"}
            content={errorArray[errorArray.length - 1]}
            ref={errorValidationPopUp => {
              this.errorValidationPopUp = errorValidationPopUp;
            }}
            onExited={this.clearErrorMessage}
          />
          <div className="uk-float-right">
            {itineraryType !== "cancelledItineraries" &&
              (currentLeg && currentLeg.legStatus === "Confirmed") &&
              this.renderDownloadBtn(downloadItinerary)}

            {itineraryType === "upcomingItineraries" &&
              isEditable &&
              (currentLeg && currentLeg.legStatus !== "Cancellation Pending") &&
              (currentLeg && currentLeg.legStatus !== "Cancelled") &&
              this.renderEditBtn(legs)}
          </div>
        </div>
        {!removeCard && (
          <div className="uk-card uk-card-default uk-card-body vistajet_card uk-padding-remove">
            <div className="vistajet_slider_content">
              {isLoading ? (
                <div className="vistajet_spinner">
                  <SpinnerLoader />
                </div>
              ) : (
                  <div>
                    <div className="uk-position-relative">
                      <div className="uk-slider-container">
                        {currentLeg && currentLeg !== undefined && (
                          <ItineraryCard
                            legs={legs}
                            currentLeg={currentLeg}
                            itineraryType={itineraryType}
                            flightDates={this.flightDates}
                            visitedAirportsFinder={visitedAirportsFinder}
                            isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
                            onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
                            renderDots={this.renderDots}
                            aircraftsList={aircraftsList}
                            itineraryDetails={{
                              orderStatus,
                              orderIdentifier: orderId || orderExtIdentifier
                            }}
                            passengers={(passengersInfo && passengersInfo.passengers) || []}
                          />
                        )}
                      </div>
                      {this.renderArrows(legs)}
                    </div>
                    <ul className="uk-slider-nav uk-dotnav uk-flex-center vistajet_slider_dot" />
                  </div>
                )}
            </div>
          </div>
        )}
        <OperationalNotes
          operationalNotes={operationalNotes}
          isLoading={isLoading}
        />
        <Crew
          crewInfos={crewInfos}
          isLoading={isLoading}
          withIn24Hours={withIn24Hours}
        />
        <PassengerCardContainer
          passengers={passengersInfo && passengersInfo.passengers}
          activeLeg={currentLeg}
          isLoadingPassenger={isLoadingPassenger}
          isLoading={isLoading}
          announcedPax={announcedPax}
          isEditable={isEditable}
          aircraftsList={aircraftsList}
        />
        <Fbo
          fboDetails={fboInfo}
          isLoading={isLoading}
          isLoadingFbo={isLoadingFbo}
          visitedAirportsFinder={visitedAirportsFinder}
          arrivalAirportId={arrivalAirportId}
          departureAirportId={departureAirportId}
          isGoogleMapsApiLoaded={isGoogleMapsApiLoaded}
          onGoogleMapsApiLoaded={onGoogleMapsApiLoaded}
          legId={flightLegId}
        />
        <Catering
          token={token}
          currentLeg={currentLeg}
          cateringDetails={cateringDetails[currentLegId]}
          isLoading={isLoading || isLoadingPassenger}
          getCateringState={this.props.getCateringState}
          withIn24Hours={withIn24Hours}
          isPast={isPast}
          isEditable={isEditable}
          passengers={passengersInfo}
          changeCateringProposal={changeCateringProposal}
        />
        <GroundServicesCard
          legKey={key}
          token={token}
          legStatus={legStatus}
          isLoading={isLoading}
          isEditable={isEditable}
          airportIds={allAirports}
          componentType={GROUND_TRANSPORT_SERVICE}
          itineraryType={itineraryType}
          getAirportById={getAirportById}
          groundServices={groundServices}
          uniqueIdentifier={currentLegId}
          emptyMessage={GT_EMPTY_MESSAGE}
          availedService={availedServiceInfo}
          cardHeading={"Ground Transportation"}
          visitedAirportsFinder={visitedAirportsFinder}
          addGroundTransportService={addGroundTransportService}
          isLoadingAvailedServices={
            availedServiceInfo && availedServiceInfo.isLoadingAvailedServices
          }
        />
        <GroundServicesCard
          legKey={key}
          token={token}
          legStatus={legStatus}
          isLoading={isLoading}
          isEditable={isEditable}
          airportIds={allAirports}
          componentType={ADDITIONAL_SERVICE}
          itineraryType={itineraryType}
          getAirportById={getAirportById}
          groundServices={groundServices}
          uniqueIdentifier={currentLegId}
          emptyMessage={AS_EMPTY_MESSAGE}
          availedService={availedASDataInfo}
          cardHeading={"Additional Services"}
          visitedAirportsFinder={visitedAirportsFinder}
          addGroundTransportService={addGroundTransportService}
          isLoadingAdditionalServices={
            availedASDataInfo && availedASDataInfo.isLoadingAdditionalServices
          }
        />
        <EntertainmentCard
          legKey={key}
          itineraryType={itineraryType}
          orderExtIdentifier={orderExtIdentifier}
          legId={flightLegId}
          tailNumber={tailNumber}
        />
        <MovementMessages
          movementMessages={movementMessagesInfo}
          isLoadingMovementMessages={isLoadingMovementMessages}
          isLoading={isLoading}
          arrivalTime={arrivalTime}
          departureTime={departureTime}
        />
        <Weather
          weatherDetails={weatherDetails}
          arrivalAirportId={arrivalAirportId}
          departureAirportId={departureAirportId}
          visitedAirportsFinder={visitedAirportsFinder}
          isLoading={isLoading}
          isLoadingWeather={isLoadingWeather}
          departureDate={departureDate}
          arrivalDate={arrivalDate}
        />
        {!disableFeedback && (
          <CustomerFeedbackContainer
            legData={legData}
            feedbackInfo={feedbackInfo}
            isLoading={isLoading}
            isDetailFlow={true}
            user={user}
          />
        )}
        {itineraryType === "upcomingItineraries" &&
          (currentLeg && currentLeg.legStatus !== "Cancellation Pending") &&
          canBook &&
          isEditable &&
          !isPast && (
            <FlightCancel
              user={user}
              token={token}
              leg={currentLeg}
              accountId={accountId}
              flightCancel={flightCancel}
              uniqueIdentifier={currentLegId}
              orderId={orderId || orderExtIdentifier}
              updateFlightCancel={updateFlightCancel}
              isRenderCancelButton={this.isRenderCancelButton}
              itineraryCancellationMessage={itineraryCancellationMessage}
              itineraryCancellationIsLoading={itineraryCancellationIsLoading}
            />
          )}
        <MessagePopUp
          id="cannotEditError"
          content={"Please call us to request changes to your booking."}
        />
      </div>
    );
  }
}

export default ItineraryDetails;
