import { connect } from 'react-redux';

import withStore from '../components/withStore';
import TopUpAccount from '../components/AccountStatement/TopUpAccount';
import { 
  TopUpReferenceAction,
  TopUpAction,
  TopUpCancelReferenceCode,
  ClearTopUpError 
} from '../store/actions/TopUpAccountActions';
import { getAccountId } from '../../src/utils/userUtils';

function mapStateToProps(state) {
  const { user } = state.user;
  const accountId = user ? getAccountId(user) : null;
  const token = user ? user.userToken : null;
  const {
    swift,
    bankName,
    currency,
    topUpAmount,
    to,
    iban,
    referenceCode,
    topUpId,
    openPopUpMessage,
    topUpReferenceLoader,
    topUpSubmittedLoader,
    topUpCancelLoader,
    disableAmountField,
    topUpError,
  } = state.topUp;

  return {
    token,
    accountId,
    user,
    swift,
    bankName,
    currency,
    topUpAmount,
    to,
    iban,
    referenceCode,
    topUpId,
    openPopUpMessage,
    topUpReferenceLoader,
    topUpSubmittedLoader,
    topUpCancelLoader,
    disableAmountField,
    topUpError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    TopUpReference: (token, accountId, transferAmount, Currency) =>
      dispatch(TopUpReferenceAction(token, accountId, transferAmount, Currency)),
    TopUpAction: (token, topUpId) =>
      dispatch(TopUpAction(token, topUpId)),
    CancelTopUp: (token, topUpId) => 
      dispatch(TopUpCancelReferenceCode(token, topUpId)),
    ClearTopUpError: () =>
      dispatch(ClearTopUpError()),
  };
}

const TopUpAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopUpAccount);

export default withStore(TopUpAccountContainer);
