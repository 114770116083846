import * as types from "../actionTypes";
import { getFlightId } from "../../utils/itinerariesUtils";

const initialState = {
  feedback: {},
  isFeedbackLoading: 0,
  feedbackError: "",
  isSavingFeedback : false,
};
export default function customerFeedbackReducer(state = initialState, action) {
  switch (action.type) {
    case types.ITINERARY_DETAILS_SUCCESS: {
      const { legs } = action.data;
      let feedbackData = {};
      legs.forEach(leg => {
        feedbackData[getFlightId(leg)] = {};
        feedbackData[getFlightId(leg)].isFeedbackLoading = false;
      });
      const result = Object.assign({}, state, {
        feedback: feedbackData
      });
      return result;
    }
    case types.FETCH_LEG_FEEDBACK_INIT: {
      state.feedback[action.flightLegId].isFeedbackLoading = true;
      state.feedback[action.flightLegId].feedbackSuccess = false;
      return Object.assign({}, state, {
        isFeedbackLoading: state.isFeedbackLoading + 1,
        feedback: state.feedback,
        feedbackError: ""
      });
    }
    case types.FETCH_LEG_FEEDBACK_SUCCESS: {
      state.feedback[action.flightLegId].feedback = action.data || "";
      state.feedback[action.flightLegId].feedbackSuccess = true;
      state.feedback[action.flightLegId].isFeedbackLoading = false;
      return Object.assign({}, state, {
        isFeedbackLoading: state.isFeedbackLoading - 1,
        feedback: state.feedback,
        feedbackError: ""
      });
    }
    case types.FETCH_LEG_FEEDBACK_FAIL: {
      state.feedback[action.flightLegId].isFeedbackLoading = false;
      state.feedback[action.flightLegId].feedbackSuccess = false;
      return Object.assign({}, state, {
        isFeedbackLoading: state.isFeedbackLoading - 1,
        feedback: state.feedback,
        feedbackError: action.data
      });
    }
    case types.SAVE_LEG_FEEDBACK_INIT: {
      state.feedback[action.flightLegId].isSavingFeedback = true;
      state.feedback[action.flightLegId].feedbackSuccess = false;
      return Object.assign({}, state, {
        isFeedbackLoading: state.isFeedbackLoading + 1,
        feedback: state.feedback,
        feedbackError: ""
      });
    }
    case types.SAVE_LEG_FEEDBACK_SUCCESS: {
      state.feedback[action.flightLegId].feedback = action.data || "";
      state.feedback[action.flightLegId].feedbackSuccess = true;
      state.feedback[action.flightLegId].isSavingFeedback = false;
      return Object.assign({}, state, {
        isFeedbackLoading: state.isFeedbackLoading - 1,
        feedback: state.feedback,
        feedbackError: ""
      });
    }
    case types.SAVE_LEG_FEEDBACK_FAIL: {
      state.feedback[action.flightLegId].isSavingFeedback = false;
      state.feedback[action.flightLegId].feedbackSuccess = false;
      state.feedback[action.flightLegId].feedbackError = 'The feedback identifier is expired.';
      return Object.assign({}, state, {
        isFeedbackLoading: state.isFeedbackLoading - 1,
        feedback: state.feedback,
      });
    }
    case types.CLEAR_FEEDBACK: {
      state.feedback[action.flightLegId].feedbackError = "";
      return Object.assign({}, state, {
        feedback: state.feedback,
        feedbackError: ""
      });
    }
    default:
      return state;
  }
}
