import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import ListPassengers from '../../components/passengersPopup/ListPassengers';
import withStore from '../../components/withStore';

function mapStateToProps(state, props) {
  return {
    ...state.passengerPopup,
    ...props,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFrequentPax: (frequentPax) => dispatch(actions.updateFrequentPax(frequentPax)),
    updateAddedPassengers: (passenger) => dispatch(actions.updateAddedPassengers({ passenger })),
    updatePassport: (data, passengerId) => dispatch(actions.updatePassport({ data, passengerId })),
    closePopup: () => dispatch(actions.closePopup()),
    setLegPassport: (passengerId, passportNumber) => dispatch(actions.setLegPassport({ passengerId, passportNumber })),
    setLeadPassenger: (paxId) => dispatch(actions.setLeadPassenger({ paxId })),
    updateSelectedPassenger: (paxId) => dispatch(actions.updateSelectedPassenger({ paxId })),
    clearSelectedPassenger: () => dispatch(actions.clearSelectedPassenger()),
    removePassport: (passportNumber, passengerId) => dispatch(actions.removePassport({ passportNumber, passengerId })),
    removeUser: (paxId) => dispatch(actions.removeUser({ paxId })),
    initializePopup: (passengers, deletedPassengers) => dispatch(actions.initializePopup({ passengers, deletedPassengers })),
  };
}

const PassengersListContainer = connect(mapStateToProps, mapDispatchToProps)(ListPassengers);

export default withStore(PassengersListContainer);
