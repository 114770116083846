import React, { Component } from 'react';
import SpinnerLoader from '../shared/spinnerLoader';
import { getTimeString } from 'utils/movementMessageUtils';

// const offBlocksIcon = '/legacy/Static/img/icons/offblocks.png';
// const departureIcon = '/legacy/Static/img/icons/takeoff.png';
// const landingIcon = '/legacy/Static/img/icons/landing.png';
// const onBlocksIcon = '/legacy/Static/img/icons/onblocks.png';

const offBlocksIcon = '/legacy/Static/img/icons/vistajet_take_off_enabled.svg';
const offBlocksDisabledIcon = '/legacy/Static/img/icons/vistajet_take_off_disabled.svg';
const departureIcon = '/legacy/Static/img/icons/vistajet_form_icon.svg';
const departureDisabledIcon = '/legacy/Static/img/icons/vistajet_form_icon-disabled.svg';
const landingIcon = '/legacy/Static/img/icons/vistajet_to_icon.svg';
const landingDisabledIcon = '/legacy/Static/img/icons/vistajet_to_icon_disabled.svg';
const onBlocksIcon = '/legacy/Static/img/icons/vistajet_flight_complete_enabled.svg';
const onBlocksDisabledIcon = '/legacy/Static/img/icons/vistajet_flight_complete_disabled.svg';

const movementTypeIds = [4, 5, 6, 7];

class MovementMessages extends Component {

  renderMovementMessages = (movementMessages, arrivalTime, departureTime) => {
    const offBlock = ((movementMessages || []).find(message => message.movementMessageTypeId === 4) || {}).mvtTimeLocalTime || '';
    const depart = ((movementMessages || []).find(message => message.movementMessageTypeId === 5) || {}).mvtTimeLocalTime || '';
    const landing = ((movementMessages || []).find(message => message.movementMessageTypeId === 6) || {}).mvtTimeLocalTime || '';
    const onBlock = ((movementMessages || []).find(message => message.movementMessageTypeId === 7) || {}).mvtTimeLocalTime || '';
    const offBlockTime = offBlock ? getTimeString(offBlock, departureTime) : '-';
    const departTime = depart ? getTimeString(depart, departureTime) : '-';
    const landingTime = landing ? getTimeString(landing, arrivalTime) : '-';
    const onBlockTime = onBlock ? getTimeString(onBlock, arrivalTime) : '-';
    return (
      <div>
        <div class="vistajet_movement_content_wrapper">
          <div class="vistajet_movement_left_content uk-flex uk-flex-middle uk-width-expand">
            <img src={offBlockTime !== '-' ? offBlocksIcon : offBlocksDisabledIcon} alt="icon" />
            <p className={offBlockTime === '-' ? 'disabled' : ''}>Waiting to take off</p>
          </div>
          <div class="vistajet_movement_right_content uk-width-auto">
            <p className="vistajet_movement_right_content_text">{offBlockTime}</p>
          </div>
        </div>

        <div class="vistajet_movement_content_wrapper">
          <div class="vistajet_movement_left_content uk-flex uk-flex-middle uk-width-expand">
            <img src={departTime !== '-' ? departureIcon : departureDisabledIcon} alt="icon" />
            <p className={departTime === '-' ? 'disabled' : ''}>Departed</p>
          </div>
          <div class="vistajet_movement_right_content uk-width-auto">
            <p className="vistajet_movement_right_content_text">{departTime}</p>
          </div>
        </div>

        <div class="vistajet_movement_content_wrapper">
          <div class="vistajet_movement_left_content uk-flex uk-flex-middle uk-width-expand">
            <img src={landingTime !== '-' ? landingIcon : landingDisabledIcon} alt="icon" />
            <p className={landingTime === '-' ? 'disabled' : ''}>Landed</p>
          </div>
          <div class="vistajet_movement_right_content uk-width-auto">
            <p className="vistajet_movement_right_content_text">{landingTime}</p>
          </div>
        </div>

        <div class="vistajet_movement_content_wrapper">
          <div class="vistajet_movement_left_content uk-flex uk-flex-middle uk-width-expand">
            <img src={onBlockTime !== '-' ? onBlocksIcon : onBlocksDisabledIcon} alt="icon" />
            <p className={onBlockTime === '-' ? 'disabled' : ''}>Flight complete</p>
          </div>
          <div class="vistajet_movement_right_content uk-width-auto">
            <p className="vistajet_movement_right_content_text">{onBlockTime}</p>
          </div>
        </div>
      </div>
    );
  }


  render() {
    const { movementMessages,
      isLoadingMovementMessages,
      isLoading,
      arrivalTime,
      departureTime
    } = this.props;
    const showMovementMessages = movementMessages &&
      movementMessages.some(message => movementTypeIds.includes(message.movementMessageTypeId));

    return (
      <div className="uk-card uk-card-default uk-card-body vistajet_card vistajet_itinerary_card uk-padding-remove">
        <div className="vistajet_itinerary_card_header">
          <h5>Movement Messages</h5>
        </div>
        <div className="vistajet_itinerary_card_body">
          {
            !isLoadingMovementMessages && !isLoading
              ? (!(movementMessages &&
                movementMessages.length) || !showMovementMessages ? (
                  <p className="uk-margin-remove">
                    Latest flight information will be available on departure.
                    </p>
                )
                : (
                  <div>
                    {this.renderMovementMessages(movementMessages || {}, arrivalTime, departureTime)}
                  </div>
                )
              ) : (
                <div className="vistajet_spinner">
                  <SpinnerLoader />
                </div>
              )
          }
        </div>
      </div>
    );
  }
}
export default MovementMessages;
