import React, { Component } from 'react';
import StepperPagination from './StepperPagination';
import AccountStatementDetails from './AccountStatementDetails';
import { isActiveContract } from '../utils/PASUtils';
import { getFiles } from '../utils/fileUtils';
import { scrollFunctionForTable } from '../utils/Helpers';
import SpinnerLoader from './shared/spinnerLoader';
import moment from 'moment';

export default class AccountStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: null,
      selectedStatement: null,
    }
  }

  onBackButtonClick = () => {
    const { selectedContractualYear } = this.props;
    this.setState({ selectedStatement: null }, () =>
      this.updateSelectedStatement(selectedContractualYear));
  }

  onStatementClick = selectedStatement =>
    this.setState({ selectedStatement });

  updateCurrentYear = currentYear => {
    this.setState({ currentYear });
    this.props.selectContractualYear(currentYear.periodNumber);
  }

  getCurrentStatements = statements =>
    (statements || []).filter(({ periodNumber }) =>
    periodNumber === (this.state.currentYear || {}).periodNumber);

  setCurrentTab = index => {
    const stepper  = document.getElementById('contract-stepper');
    if (stepper) {
      const activeSlideTab = Math.ceil(index/5) - 1;
      window.UIkit.slider(stepper).show(activeSlideTab);
    }
  }

  updateSelectedStatement = index => {
    const stepper  = document.getElementById('contract-stepper');
    if (stepper) {
      const activeSlideTab = Math.ceil(index/5) - 1;
      window.UIkit.slider(stepper).show(activeSlideTab);
    }
  }

  componentDidMount() {
    const {
      contract: {
        accountId,
        aircraftTypeId,
      },
      token,
      fetchAccountStatements,
    } = this.props;
    
    if (accountId && aircraftTypeId && token)
      fetchAccountStatements(token, accountId, aircraftTypeId);
  }

  componentWillReceiveProps(nextProps) {
    const { accountStatementInfo, contract, fetchAccountStatements, selectContractualYear } = this.props;
    if (nextProps.contract && nextProps.contract !== contract) {
      this.setState({ selectedStatement: null });
      fetchAccountStatements(
        nextProps.token,
        (nextProps.contract || {}).accountId,
        (nextProps.contract || {}).aircraftTypeId
      );
    }
    if (
      nextProps.accountStatementInfo !== accountStatementInfo && 
      (nextProps.accountStatementInfo || []).length
    ) {
      const currentYear = (nextProps.accountStatementInfo || []).find(isActiveContract);
      if (currentYear) {
        this.setState({ currentYear: currentYear || {} });
        selectContractualYear((currentYear || {}).periodNumber);
        
      } else {
        const firstYear = (nextProps.accountStatementInfo || [])[0] || {};
        this.setState({ currentYear: firstYear });
        selectContractualYear(1);
      }
    }
  }

  componentDidUpdate({ accountStatementInfo }) {
    if (this.props.accountStatementInfo !== accountStatementInfo) {
      const currentYear = (this.props.accountStatementInfo || []).find(isActiveContract);
      if (currentYear) {
        const currentYearIndex = (this.props.accountStatementInfo || []).indexOf(currentYear);
        this.setCurrentTab(currentYearIndex);
      }
    }
  }

  renderStatementRow = (statement = {}) => (
    <tr>
      <td class="vistajet_light_text uk-padding-remove-left uk-table-shrink uk-text-nowrap">
        {`${moment(statement.periodStart).format('DD MMM YYYY')} - ${moment(statement.periodEnd).format('DD MMM YYYY')}`}
      </td>
      <td class="vistajet_dark_text uk-text-center uk-text-right@s uk-padding-remove-right uk-padding-remove-left">{+(statement.totalBilledHours).toFixed(2)} Hrs</td>
      <td class="uk-padding-remove-right">
        <ul class="uk-iconnav uk-flex-right uk-visible@s">
          <li><a
            href={getFiles((statement || {}).accountStatementPDFFile, this.props.token || '', false)}
            target={'_blank'}
            rel="noreferrer"
            className="vistajet_eye_icon"
          ></a></li>
          <li><a
            href={getFiles((statement || {}).accountStatementPDFFile, this.props.token || '', false, true)}
            target={'_blank'}
            rel="noreferrer"
            className="vistajet_pdf_icon"
          ></a></li>
          <li><a
            href="#"
            class="vistajet_list_icon"
            onClick={() => this.onStatementClick(statement)}
          ></a></li>
        </ul>
        <ul class="uk-iconnav uk-flex-right uk-hidden@s">
          <li><button class="vistajet_more_icon" type="button"></button></li>
          <div uk-dropdown="mode: click; pos: bottom-right" class="uk-margin-remove vistajet_iconnav_dropdown">
            <ul class="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
              <li className="uk-text-truncate"><a
                href={getFiles((statement || {}).accountStatementPDFFile, this.props.token || '', false)}
                target={'_blank'}
                rel="noreferrer"
                className="vistajet_eye_icon"
              ><span>View</span></a></li>
              <li className="uk-text-truncate"><a
                href={getFiles((statement || {}).accountStatementPDFFile, this.props.token || '', false, true)}
                target={'_blank'}
                rel="noreferrer"
                className="vistajet_pdf_icon"
              ><span>Download</span></a></li>
              <li className="uk-text-truncate"><a
                href="#"
                class="vistajet_list_icon"
                onClick={() => this.onStatementClick(statement)}
              ><span>Breakdown</span></a></li>
            </ul>
          </div>
        </ul>
      </td>
    </tr>
  );

  renderStatementRows = statements => (
    statements
      .map(this.renderStatementRow)
  )

  render() {
    const {
      accountStatementInfo,
      accountStatements,
      loadingAccountStatements,
      fetchStatementInvoices,
      loadingStatementInvoices,
      loadingMoreStatementInvoices,
      statementInvoices,
      statementInvoicesError = '',
      statementInvoicesLastPage,
      token,
      fetchZipUrl,
      clearInvoiceFilter,
      loadingZipUrl = false,
      selectedContractualYear = 0,
    } = this.props;
    const {
      currentYear,
      selectedStatement,
    } = this.state;
    const currentStatements = this.getCurrentStatements(accountStatements);
    if (selectedStatement)
      return (
        <AccountStatementDetails
          onBackButtonClick={this.onBackButtonClick}
          selectedStatement={selectedStatement}
          fetchStatementInvoices={fetchStatementInvoices}
          token={token}
          loadingStatementInvoices={loadingStatementInvoices}
          loadingMoreStatementInvoices={loadingMoreStatementInvoices}
          statementInvoices={statementInvoices}
          statementInvoicesLastPage={statementInvoicesLastPage}
          fetchZipUrl={fetchZipUrl}
          loadingZipUrl={loadingZipUrl}
          clearInvoiceFilter={clearInvoiceFilter}
          statementInvoicesError={statementInvoicesError}
        />
      );
    return loadingAccountStatements ? (
      <div className="uk-flex uk-flex-center uk-flex-middle uk-height-small">
        <SpinnerLoader />
      </div>
    ) : (
      <div>
        {accountStatementInfo && (
          <div class="uk-grid-collapse vistajet_balance_detail" uk-grid>
            <div class="uk-width-1-1@s">
                <p class="vistajet_small_text uk-margin-remove">Period: <span class="vistajet_bold_text">
                  {`${(currentYear || {}).contractPeriodStart} - ${(currentYear || {}).contractPeriodEnd}`}
                </span></p>
                <StepperPagination
                  contractualYears={accountStatementInfo}
                  updateCurrentYear={this.updateCurrentYear}
                  currentYear={currentYear}
                  selectedContractualYear={selectedContractualYear}
                />
            </div>
          </div>
        )}
        <div>
            <p class="vistajet_dark_text uk-margin-small-top uk-margin-remove-bottom">Statements</p>
            {(currentStatements || []).length ? (
              <div>
                <div class="uk-overflow-auto vistajet_table_wrapper">
                { scrollFunctionForTable && scrollFunctionForTable() }
                  <table class="uk-table uk-table-small">
                      <tbody>
                          {this.renderStatementRows(currentStatements)}
                      </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div>No Statements Found.</div>
            )}
        </div>
      </div>
    );
  }
}