import React, { Component } from "react";
import 'react-responsive-modal/styles.css';
import ReactModal from "react-responsive-modal";
import CustomerFeedbackOptions from "./CustomerFeedbackOptions";
import Button from "../shared/Button";
import { getPersonId } from "../../utils/userUtils";
import ConfirmationPopup from "../shared/ConfirmationPopUpNew";
import SpinnerLoader from "../shared/spinnerLoader";
import dirtystateSingleTon from "../../validators/validateDirty";
import SPAConstants from "../../constants/SPAConstants";

const services = {
  OVERALL: "overall",
  CATERING: "catering",
  CABINCOMFORT: "cabin-comfort",
  ONBOARD: "on-board",
  TRANSPORT: "transportation"
};

const stateMapping = {};
stateMapping[services.CATERING] = "cateringFeedback";
stateMapping[services.CABINCOMFORT] = "cabinComfortFeedback";
stateMapping[services.TRANSPORT] = "transportFeedback";
stateMapping[services.ONBOARD] = "onBoardFeedback";

const initialState = {
  overallFeedback: {},
  cateringFeedback: {},
  cabinComfortFeedback: {},
  onBoardFeedback: {},
  transportFeedback: {},
  showServices: false,
  submitDisable: true,
  isDirty: false
};
class CustomerFeedbackPopup extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }
  onChangeOverallRating = service => {
    dirtystateSingleTon.setDirty(SPAConstants.CustomerFeedback, true);
    this.setState({
      showServices: service.rating !== 0,
      submitDisable: service.rating === 0,
      isDirty: true,
      overallFeedback: {
        rating: service.rating,
        desc: service.description,
        category: service.category
      }
    });
  };

  onServiceRatingChange = service => {
    const newState = { ...this.state };

    if (service && service.rating && service.rating !== 0) {
      newState[stateMapping[service.category]] = {
        rating: service.rating,
        desc: service.description,
        category: service.category
      };
      dirtystateSingleTon.setDirty(SPAConstants.CustomerFeedback, true);
      this.setState({ ...newState, isDirty: true });
    }
  };

  confirmFeedbackPopup = () => {
    this.feedbackConfirmPopup.show();
  };
  submitFeedback = () => {
    const {
      overallFeedback,
      cateringFeedback,
      cabinComfortFeedback,
      onBoardFeedback,
      transportFeedback
    } = this.state;

    const { userToken } = this.props.user;
    const personId = getPersonId(this.props.user);

    const servicesFeedbacks = {
      overallFeedback,
      cateringFeedback,
      cabinComfortFeedback,
      onBoardFeedback,
      transportFeedback
    };
    const params = {
      token: userToken,
      legId: this.props.legId,
      feedbackIdentifier: "",
      servicesFeedbacks,
      personId
    };
    this.feedbackConfirmPopup.hide();
    this.props.saveLegFeedback(params);
  };
  handleClosePopup = () => {
    dirtystateSingleTon.setDirty(SPAConstants.CustomerFeedback, false);
    this.dirtyStatePopup.hide();
    this.setState({
      ...initialState
    });
    this.props.closeRatingDialogue();
  };
  handleDirtyPopup = () => {
    if (this.state.isDirty) {
      this.dirtyStatePopup.show();
    } else {
      this.props.closeRatingDialogue();
    }
  };
  onExited = () => {
    dirtystateSingleTon.setDirty(SPAConstants.CustomerFeedback, false);
    this.setState({
      ...initialState
    });
  }
  render() {
    const { showServices, submitDisable } = this.state;
    const { isSavingFeedback } = this.props;
    return (
      <ReactModal
        open={this.props.open /*true this.props.open*/}
        classNames={{
          overlay: "vistajet_modal vistajet_modal_large",
          modal: "vistajet_modal_body vistajet_react_modal vistajet_modal_height uk-padding-remove"

        }}
        showCloseIcon={false}
        onExited={this.onExited}
        center
        closeOnOverlayClick={false}
      >
        <div>
          {isSavingFeedback ? (
            <div className="vistajet_spinner">
              <SpinnerLoader />
            </div>
          ) : null}
          <div className="vistajet_itinerary_card_header vistajet_modal_header_fix uk-text-center">
            <a
              href="#"
              className="uk-modal-close"
              onClick={this.handleDirtyPopup}
            >
              <span>Back</span>
            </a>
            <h4 className="uk-text-center">Feedback</h4>
          </div>
          <div className="vistajet_itinerary_card_body vistajet_feedback_container vistajet_passenger_search_form">
            <CustomerFeedbackOptions
              ratingFor="Overall Experience"
              onrating={this.onChangeOverallRating}
              category={services.OVERALL}
              isOptional={false}
            />
            {showServices && (
              <React.Fragment>
                <CustomerFeedbackOptions
                  isOptional={true}
                  ratingFor="Cabin Comfort"
                  category={services.CABINCOMFORT}
                  onrating={this.onServiceRatingChange}
                />
                <CustomerFeedbackOptions
                  isOptional={true}
                  ratingFor="Catering"
                  category={services.CATERING}
                  onrating={this.onServiceRatingChange}
                />
                <CustomerFeedbackOptions
                  isOptional={true}
                  ratingFor="On-board Services"
                  category={services.ONBOARD}
                  onrating={this.onServiceRatingChange}
                />
                <CustomerFeedbackOptions
                  isOptional={true}
                  ratingFor="Transport Services"
                  category={services.TRANSPORT}
                  onrating={this.onServiceRatingChange}
                />
              </React.Fragment>
            )}
          </div>
          <div className="vistajet_modal_footer">
            <Button
              classNames={
                "uk-button uk-width-1-1 uk-text-center"
              }
              disabled={submitDisable}
              onClickHandler={this.confirmFeedbackPopup}
              label={"Submit Feedback"}
            />
          </div>
        </div>
        <ConfirmationPopup
          title="Confirmation"
          ref={feedbackConfirmPopup => {
            this.feedbackConfirmPopup = feedbackConfirmPopup;
          }}
          leftButtonContent="Cancel"
          rightButtonContent="Ok"
          onLeftButtonClick={() => this.feedbackConfirmPopup.hide()}
          onRightButtonClick={() => this.submitFeedback()}
          content={
            "Are you sure you would like to submit your feedback? Please note that feedback cannot be changed once submitted."
          }
          closeOnOverlayClick={false}
        />
        <ConfirmationPopup
          title="Confirmation"
          ref={dirtyStatePopup => {
            this.dirtyStatePopup = dirtyStatePopup;
          }}
          leftButtonContent="Stay On This Page"
          rightButtonContent="Move Away"
          onLeftButtonClick={() => this.dirtyStatePopup.hide()}
          onRightButtonClick={() => this.handleClosePopup()}
          content={
            "Your changes will be lost if you move away from this page. Do you wish to move away from this page?"
          }
        />
      </ReactModal>
    );
  }
}

export default CustomerFeedbackPopup;
