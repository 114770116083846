import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'features/api/airportLookupAPI';

const initialState = {
    nearestAirports: null,
    popularAirports: null,
    status: 'idle'
};

export const getNearestAirports = createAsyncThunk(
    'flyxoAirportAPI/getNearestAirports',
    async () => await api.getNearestAirports()
);

export const getPopularAirports = createAsyncThunk(
    'flyxoAirportAPI/getPopularAirports',
    async () => await api.getPopularAirports()
);

export const searchAirports = api.searchAirports;

export const airportLookupSlice = createSlice({
    name: 'airportLookup',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getNearestAirports.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNearestAirports.fulfilled, (state, action) => {
                state.status = 'idle';
                state.nearestAirports = action.payload;
            })
            .addCase(getPopularAirports.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPopularAirports.fulfilled, (state, action) => {
                state.status = 'idle';
                state.popularAirports = action.payload;
            });
    },
});

export const selectNearestAirports = (state) => state.airportLookup.nearestAirports;
export const selectPopularAirports = (state) => state.airportLookup.popularAirports;

export default airportLookupSlice.reducer;