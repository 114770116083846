import { fetchGeocode } from '../sources/GeocoderSource';
import * as types from '../actionTypes';

export const fetchGeocodeInit = () => ({
  type: types.FETCH_GEOCODE_INIT,
});

export const fetchGeocodeSuccess = data => ({
  type: types.FETCH_GEOCODE_SUCCESS,
  data,
});

export const fetchGeocodeFail = data => ({
  type: types.FETCH_GEOCODE_FAIL,
  data,
});

export const fetchCountryGeocode = () => fetchGeocode();
