import { connect } from 'react-redux';

import withStore from '../components/withStore';
import RegistrationForm from '../components/Registration/RegistrationForm';

import * as actions from '../store/actions';

function mapStateToProps(state) {
  const { isTokenValid, isPasswordChange } = state.token;
  const { user, isUserRegistered } = state.registration;
  const { isLoggedIn, changePasswordRequired } = state.user;
  const { paymentDetails, isSuccess } = state.directSignupReducer;

  return {
    isWizard: false,
    // adminToken: state.token,
    isAdminTokenError: state.token.isadminTokenError,
    adminTokenError: state.token.adminTokenError,
    // token: state.token,
    isTokenError: state.token.isTokenError,
    tokenError: state.token.tokenError,
    isTokenValid,
    isPasswordChange,
    isAuthError: state.token.isTokenAuthError,
    authError: state.token.tokenAuthError,
    isAuthLoading: state.token.isTokenAuthLoading,
    user,
    isUserRegistered,
    isUserRegisterError: state.registration.isError,
    userRegistrationError: state.registration.error,
    adminTokenLoading: state.token.isadminTokenLoading,
    tokenLoading: state.token.isRegistrationTokenLoading,
    registerLoading: state.registration.isLoading,
    isLoggedIn,
    changePasswordRequired,
    //direct signup
    isDirectSignupLoading: state.directSignupReducer.isLoading,
    isDirectSignupError: state.directSignupReducer.isError,
    directSignupError: state.directSignupReducer.error,
    paymentDetails,
    isSuccess
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAdminToken: () => dispatch(actions.fetchAdminToken()),
    getToken: () => dispatch(actions.fetchToken(undefined, undefined, true)),
    authenticateTokenAction: token => dispatch(actions.authenticateTokenAction(token)),
    registerMemeber: (token, payload) => dispatch(actions.registrationAction(token, payload)),
    directSignupSourceAction:(token, payload) => dispatch(actions.directSignupSourceAction(
      token, payload)),
  };
}

const SimpleRegistrationContainer = connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);

export default withStore(SimpleRegistrationContainer);
