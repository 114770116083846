import { fetchEntertainment, fetchEntertainmentIssue } from '../sources/EntertainmentSource';
import * as types from '../actionTypes';

export const fetchEntertainmentIssueInit = (flightLegId) => ({
  type: types.FETCH_ENTERTAINMENT_ISSUE_INIT,
  flightLegId,
});

export const fetchEntertainmentIssueSuccess = (data, flightLegId) => ({
  type: types.FETCH_ENTERTAINMENT_ISSUE_SUCCESS,
  data,
  flightLegId,
});

export const fetchEntertainmentIssueFail = (data, flightLegId) => ({
  type: types.FETCH_ENTERTAINMENT_ISSUE_FAIL,
  data,
  flightLegId,
});

export const fetchEntertainmentInit = (flightLegId) => ({
  type: types.FETCH_ENTERTAINMENT_INIT,
  flightLegId,
});

export const fetchEntertainmentSuccess = (data, flightLegId) => ({
  type: types.FETCH_ENTERTAINMENT_SUCCESS,
  data,
  flightLegId,
});

export const fetchEntertainmentFail = (data, flightLegId) => ({
  type: types.FETCH_ENTERTAINMENT_FAIL,
  data,
  flightLegId,
});

export const fetchEntertainmentIssueNumber = (tailNumber, legId) => fetchEntertainmentIssue(tailNumber, legId);

export const fetchEntertainmentDetails = (issueNumber, tailNumber, legId) => fetchEntertainment(issueNumber, tailNumber, legId);