import { connect } from "react-redux";
import withStore from "../../components/withStore";
import CustomerFeedback from "../../components/ItineraryDetails/CustomerFeedback";
import * as actions from "../../store/actions";

function mapStateToProps(state, props) {
  if (props && props.isDetailFlow) {
    return {
      legData: props.legData,
      feedbackInfo: props.feedbackInfo,
      isLoading: props.isLoading,
      user: props.user
    };
  } else {
    const { feedback, isFeedbackLoading } = state.customerFeedback;
    return { feedback, isFeedbackLoading };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveLegFeedback: params => dispatch(actions.saveLegFeedback(params)),
    clearFeedbackError: flightLegId => dispatch(actions.clearFeedbackError(flightLegId))
  };
}

const CustomerFeedbackContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerFeedback);

export default withStore(CustomerFeedbackContainer);
