import React, { Component } from 'react';
import PassportListingCard from './PassportListingCard';
import PassportDetailForm from './AddEditPassportDetailsForm';

export default class PassportListingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPassportDetailAddEdit: false,
      isAdd: false,
      isEdit: false,
      editFieldId: null,
      isDefaultEdit: false,
    };
  }

  toggleIsPassportAddEditDetails = (isAdd = false, isEdit = false, editId = null, isDefaultEdit = false) => {
    this.setState({
      isPassportDetailAddEdit: !this.state.isPassportDetailAddEdit,
      isAdd,
      isEdit,
      editFieldId: editId,
      isDefaultEdit,
    });
  }

  renderCards = () => {
    const { isPassportDetailAddEdit, isAdd, isEdit, editFieldId, isDefaultEdit } = this.state;
    const { user, updateUserProfileAction } = this.props;
    if (isPassportDetailAddEdit) {
      return (
        <PassportDetailForm
          toggleIsPassportAddEditDetails={this.toggleIsPassportAddEditDetails}
          isAdd={isAdd}
          isEdit={isEdit}
          editFieldId={editFieldId}
          isDefaultEdit={isDefaultEdit}
          {...this.props}
        />
      );
    }
    return (
      <PassportListingCard
        toggleIsPassportAddEditDetails={this.toggleIsPassportAddEditDetails}
        user={user}
        updateUserProfileAction={updateUserProfileAction}
        {...this.props}
      />
    );
  }

  render() {
    return this.renderCards();
  }
}
