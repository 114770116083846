import { capitalize } from "utils/stringUtils";
export const SHOW_AIRPPORTS_TO = 500;
export const MAX_AIRPORT_SEARCH = 500;

const formatAirport = ({
  city = '', icao, iso2 = '', name,
} = {}) => {
  const capitalizeCity = capitalize(city);
  const country = iso2.substring(0, 2);
  const capitalizeName = capitalize(name);
  return `${capitalizeCity}, ${capitalizeName} (${icao}), ${country}`;
};

export const handleFormatAirport = ({ city = "", country, icao, iso2, name } = {}) => {
  const capitalizeCity = capitalize(city);
  const capitalizeCountry = capitalize(country);
  const capitalizeName = capitalize(name);
  return `${capitalizeCity}, ${capitalizeName} (${icao}), ${iso2} ${capitalizeCountry}`;
};

export const handleFormatFlyxoAirport = ({ city = "", country, icao, iso2, name } = {}) => {
  const capitalizeName = capitalize(name);
  return `${capitalizeName}`;
};

export const showOnLabel = ({ city = "", iso2, name } = {}) => {
  const capitalizeCity = capitalize(city);
  const capitalizeName = capitalize(name);
  return `${capitalizeCity}, ${capitalizeName}, ${iso2}`;
};

export const debounceOnChange = () => {
  let debounceTimer = null;
  return function(func, delay) {
      if (debounceTimer) clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => { func() }, delay);
  }
}
export const debounceIt = debounceOnChange();

export const formatName = (value) => {
  const label = handleFormatFlyxoAirport(value)

  if (value.type === 'metropolitan-area-with-airports' || value.has_multiple_airports) {
    const country = value.country_code
    return `${label}${country ? `, ${country}` : ''} (Any airport)`
  }

  if (value.type === 'metropolitan-area') {
    return label
  }

  if (value.icao) {
    return `${value.ma_name || value.city} ${label} (${value.icao})`
  }
}


export default formatAirport;
