import React from 'react';

import MaskedInput from 'react-text-mask';

const MaskedTextField = (props) => {
  const {
    mask,
    guide,
    placeholderChar,
    hintText,
    value,
    tabIndexing,
    inputClassName,
    onChange,
    onFocus,
    id,
    disabled = false,
    ...textField
  } = props;

  return (
    <MaskedInput
      id={id}
      value={value}
      mask={mask}
      guide={guide}
      placeholderChar={placeholderChar}
      placeholder={hintText}
      tabIndex={tabIndexing}
      className={inputClassName}
      onChange={onChange}
      onFocus={onFocus}
      disabled={disabled}
    />
  );
};

export default MaskedTextField;
