import { garanteeArray } from '../utils/sourceUtils';

function normalizeLeg(leg) {
  return {
    arrivalAirportIcao:
      leg.arrivalAirportIcao,
    arrivalAirportName:
      leg.arrivalAirportName,
    departureAirportIcao:
      leg.departureAirportIcao,
    departureAirportName:
      leg.departureAirportName,
  };
}

function normalizeFlightLegs(legs) {
  const newLegs = [];

  if (legs && legs.length > 0) {
    legs.forEach((leg) => {
      newLegs.push({ flightLeg: normalizeLeg(leg.flightLeg) });
    });
  }

  return newLegs;
}

function normalizeLineItems(lineItem) {
  const l = lineItem;
  l.amount = lineItem.amount;
  l.currency = lineItem.currency;
  l.description = lineItem.description;
  l.documentDate = lineItem.documentDate;
  l.documentNumber = lineItem.documentNumber;
  l.fileKey = lineItem.fileKey ? lineItem.fileKey : null;
  l.invoiceNumber = lineItem.invoiceNumber ? lineItem.invoiceNumber : null;
  l.flightLegs = normalizeFlightLegs(lineItem.flightLegs);
  // flightLegs
  return lineItem;
}

export const  normalizeAccount = (account) => {
  if (account && account.ResponseDto.ResponseBody) {
    const c = account.invoiceAndBalanceResponse ?
                account.invoiceAndBalanceResponse
                : account.ResponseDto.ResponseBody.membershipAccount;
    const newAccount = {};
    if (c.lineItem) {
      newAccount.lineItems = c.lineItem;
      newAccount.lineItems = garanteeArray(newAccount.lineItems);
      newAccount.lineItems = newAccount.lineItems.map(normalizeLineItems);
    } else {
      newAccount.lineItems = [];
    }

    newAccount.accountTypeId = c.accountTypeId;
    if (c.accountDisplayName) {
      newAccount.accountDisplayName = c.accountDisplayName;
    } else {
      newAccount.accountDisplayName = 'VistaJet Direct (Non Member)';
    }

    if (c.currency) {
      newAccount.currency = c.currency;
    } else {
      newAccount.currency = '';
    }

    if (c.currentBalance) {
      newAccount.currentBalance = c.currentBalance;
    } else {
      newAccount.currentBalance = 0;
    }
    return newAccount;
  }

  return { lineItems: [] };
}
