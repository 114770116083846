export const TOP_UP_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
  EXPIRED: 3,
  WAITING_APPROVAL: 5,
};

export const TOP_UP_STATUS_TYPE = {
  1: 'PENDING',
  2: 'COMPLETE',
  3: 'EXPIRED',
  5: 'WAITING FOR APPROVAL',
};

export const MEMBERSHIP_TYPE = {
  1: 'VistaJet Direct',
  2: 'Global',
  3: 'Regional',
};
export const TOP_UP_PAYMENT_METHOD = 'Bank Transfer';
