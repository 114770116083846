import getAccountStatusSource from '../sources/AccountStatusSource'; // eslint-disable-line
import * as types from '../actionTypes';

export const getAccountStatusInit = () => ({
  type: types.GET_ACCOUNT_STATUS_INIT,
});

export const getAccountStatusSuccess = data => ({
  type: types.GET_ACCOUNT_STATUS_SUCCESS,
  data,
});

export const getAccountStatusFail = data => ({
  type: types.GET_ACCOUNT_STATUS_FAILURE,
  data,
});

export const getAccountStatusAction = token => getAccountStatusSource(token);
