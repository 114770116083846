import { registrationSource, verifyUserSource } from '../sources/RegistrationSource'; // eslint-disable-line
import * as types from '../actionTypes';

export const registrationInit = () => ({
  type: types.REGISTRATION_INIT,
});

export const registrationSuccess = data => ({
  type: types.REGISTRATION_SUCCESS,
  data,
});

export const registrationFail = data => ({
  type: types.REGISTRATION_FAIL,
  data,
});

export const registrationAction = (token, payload) => registrationSource(token, payload);


export const verifyUserInit = () => ({
  type: types.VERIFY_USER_INIT,
});

export const verifyUserSuccess = data => ({
  type: types.VERIFY_USER_SUCCESS,
  data,
});

export const verifyUserFail = data => ({
  type: types.VERIFY_USER_FAIL,
  data,
});


export const verifyUserAction = (verificationCode) => verifyUserSource(verificationCode);
