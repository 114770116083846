import { addGroundServices, serviceAvailed } from '../sources/GroundServicesSource';
import {
	ADD_TRANSPORT_SERVICES_INIT,
	ADD_TRANSPORT_SERVICES_SUCCESS,
	ADD_TRANSPORT_SERVICES_FAIL,

	ADD_ADDITIONAL_SERVICES_INIT,
	ADD_ADDITIONAL_SERVICES_SUCCESS,
	ADD_ADDITIONAL_SERVICES_FAIL,

	FETCH_AVAILED_SERVICES_INIT,
	FETCH_AVAILED_SERVICES_SUCCESS,
	FETCH_AVAILED_SERVICES_FAIL,
} from '../actionTypes';

//---------------Add--Transport Services-----------------

export const addTransportServicesInit = (uniqueIdentifier) => ({
	type: ADD_TRANSPORT_SERVICES_INIT,
	flightLegId: uniqueIdentifier,
});

export const addTransportServicesSuccess = (uniqueIdentifier, payload, serviceType) => ({
	type: ADD_TRANSPORT_SERVICES_SUCCESS,
	data: payload,
	serviceType: serviceType,
	flightLegId: uniqueIdentifier,
});

export const addTransportServicesFail = (uniqueIdentifier, error) => ({
	type: ADD_TRANSPORT_SERVICES_FAIL,
	flightLegId: uniqueIdentifier,
	error,
});

//----------------Add-Additional Services-----------------

export const addAdditionalServicesInit = (uniqueIdentifier) => ({
	type: ADD_ADDITIONAL_SERVICES_INIT,
	flightLegId: uniqueIdentifier,
});

export const addAdditionalServicesSuccess = (uniqueIdentifier, payload, serviceType) => ({
	type: ADD_ADDITIONAL_SERVICES_SUCCESS,
	data: payload,
	serviceType: serviceType,
	flightLegId: uniqueIdentifier,
});

export const addAdditionalServicesFail = (uniqueIdentifier, error) => ({
	type: ADD_ADDITIONAL_SERVICES_FAIL,
	flightLegId: uniqueIdentifier,
	error,
});

//-------------------------Fetch Availed Services------------

export const servicesAvailedInit = (flightLegId) => ({
	type: FETCH_AVAILED_SERVICES_INIT,
	flightLegId,
});

export const servicesAvailedSuccess = (availedGTData, availedASData, flightLegId) => ({
	type: FETCH_AVAILED_SERVICES_SUCCESS,
	availedGTData: availedGTData,
	availedASData: availedASData,
	flightLegId,
});

export const servicesAvailedFail = (flightLegId, error) => ({
	type: FETCH_AVAILED_SERVICES_FAIL,
	flightLegId,
	error,
});

export const fetchAvailedServices = (token, key, flightLegId) => serviceAvailed(token, key, flightLegId);

export const addGroundTransportService = (token, key, uniqueIdentifier, payload) => addGroundServices(token, key, uniqueIdentifier, payload);
