import axios from 'axios'

const IPINFO_API_KEY = '255a3f1017fbb6'
const SESSION_STORAGE_KEY = 'geolocation'

// var promise1 = new Promise(function(resolve, reject) {
//     navigator.geolocation.getCurrentPosition(function(pos){
//         lat = pos.coords.latitude
//         lon = pos.coords.longitude
//         resolve({lat,lon});
//     })
// })

const getCurrentPosition = async () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        if (position.coords) {
          resolve(position)
        }
        reject()
      },
      error => { reject(error) },
      { timeout: 10000 }
    )
  })


const transformResponseToBrowserLikeFormat = data => {
  if (!data || !data.loc) return null
  const [latitude, longitude] = data.loc.split(',')
  if (!latitude || !longitude) return null
  return {
    coords: {
      latitude: Number(latitude),
      longitude: Number(longitude),
    },
    ...data,
  }
}

export default async function GeoLocation() {
  const cache = window.sessionStorage.getItem(SESSION_STORAGE_KEY)
  if (cache) { 
    return JSON.parse(cache) 
  }

  // Step 1 - use external geoip api
  try {
    const response = await axios.get(`https://ipinfo.io?token=${IPINFO_API_KEY}`)
    if (!response) {
      throw new Error('didnt find user coordinates')
    }
    const location = transformResponseToBrowserLikeFormat(response.data)
    if (!location) {
      throw new Error('didnt find lat/long')
    }
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(location))
    return location
  } catch (e) {
    console.error(e)
  }

  // Step 2, fallback - use native navigator.geolocation
  try {
    const location = await getCurrentPosition()
    return location
  }
  catch (e) {
    console.error(e)
  }

  return null
}
