import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SkeletonLoaderAirport from './SkeletonLoaderAirport';
import { AIRCRAFT_IDS, AIRCRAFT_DETAILS } from '../../mocks/aircraftDetails';
import { getLegLocalTime, getLegDuration, getItineraryLegStatus } from '../../utils/itinerariesUtils';
import {
  MULTI_LEG,
  RETURN_LEG,
} from '../../constants/ItineraryConstants';
import Helpers from '../../utils/Helpers';

import { isPastLeg } from "../../utils/itinerariesUtils";
import { AddEventCalendarLink } from "../shared/AddEventCalendarLink";
import { getAircraftByField } from "../../utils/aircraftUtils";
class FlightPathRow extends Component {
  static propTypes = {
    dates: PropTypes.string,
    childLeg: PropTypes.bool,
    lastLeg: PropTypes.string,
    flightIcon: PropTypes.string,
    isItineraryDetail: PropTypes.bool,
    leg: PropTypes.instanceOf(Object),
    item: PropTypes.instanceOf(Object),
    visitedAirportsFinder: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    leg: {},
    item: {},
    dates: '',
    lastLeg: {},
    flightIcon: '',
    childLeg: false,
    isItineraryDetail: false,
    visitedAirportsFinder: {},
  }

  getSelectedAircraft = (leg) => {
    let selectedAircraft = '';
    if (leg.aircraftTypeName) {
      selectedAircraft = Object.keys(AIRCRAFT_IDS)
        .map(aircraft => AIRCRAFT_DETAILS[AIRCRAFT_IDS[aircraft]])
        .find(aircraft => aircraft.aircraftTypeName === leg.aircraftTypeName);
    }
    if (!selectedAircraft) {
      selectedAircraft = Object.keys(AIRCRAFT_IDS)
        .map(aircraft => AIRCRAFT_DETAILS[AIRCRAFT_IDS[aircraft]])
        .find(aircraft => aircraft.id === leg.aircraftTypeId);
    }

    return selectedAircraft;
  }

  getLegOrderId = (item, leg, isItineraryDetail) => {
    let orderId = '';
    if (isItineraryDetail) {
      orderId = item && !isNaN(item) && 'Order # ' + item;
    }
    else {
      orderId = !isItineraryDetail ? (item && !isNaN(item.orderIdentifier) ? 'Order # ' + item.orderIdentifier : '  ') : (leg && leg.legId && 'Order # ' + leg.legId);
    }
    return orderId;
  }

  getAircraftImage = (selectedAircraft) => {
    return selectedAircraft ? (
      <img
        alt="airplane-icon"
        style={{ withd: 30, height: 30 }}
        src={selectedAircraft.icon}
        className="jet_selected "
      />
    ) :
      (
        <img
          alt="airplane-icon"
          src={'/legacy/Static/img/icons/GLEX-icon.svg'}
          className="jet_selected jet_selected--GLEX"
        />
      );
  }

  getFlightPathIcon = (flightIconType) => {
    if (flightIconType === RETURN_LEG) {
      return 'vistajet_departure_center vistajet_return_icon';
    }
    else if (flightIconType === MULTI_LEG) {
      return 'vistajet_departure_center vistajet_multicities_icon';
    }
    return 'vistajet_departure_center';
  }
  renderAddToCalender = (arrivalAirportDetail, departureAirportDetail) => {
    const { leg, isItineraryDetail } = this.props;
    const isLegPast = isPastLeg(leg);
    if (leg.legStatus === "Confirmed" && isItineraryDetail && !isLegPast) {
      return (
        <AddEventCalendarLink
          departureAirport={departureAirportDetail}
          departureAirportId={leg.departureAirportId}
          departureTime={leg.departureAirportTimeUTC}
          arrivalAirport={arrivalAirportDetail}
          arrivalAirportId={leg.arrivalAirportId}
          arrivalTime={leg.arrivalAirportTimeUTC}
        />
      );
    }
    return null;
  }

  getDuration = (leg, departureAirportDetail, arrivalAirportDetail) => {
    let selectedAircraft;
    const aircraftInfo = this.props.aircraftsList;
    if (leg.aircraftTypeId) {
      selectedAircraft = getAircraftByField(leg.aircraftTypeId, aircraftInfo)
    }
    else {
      selectedAircraft = getAircraftByField(leg.aircraftTypeName, aircraftInfo, 'code')
    }
    const legDuration = Helpers.getFlightDuration(departureAirportDetail, arrivalAirportDetail, selectedAircraft)
    return legDuration
  }
  render() {
    let legDuration;
    const {
      leg, lastLeg, childLeg, flightIcon, item, dates, isItineraryDetail, visitedAirportsFinder, itineraryType,
      upcomingFirstMultiLeg, index, passengers
    } = this.props;

    const arrivalAirportDetail = visitedAirportsFinder[lastLeg && lastLeg.arrivalAirportId];
    const departureAirportDetail = visitedAirportsFinder[leg && leg.departureAirportId];
    const selectedAircraft = this.getSelectedAircraft(leg);
    if (departureAirportDetail && arrivalAirportDetail && selectedAircraft) {
      legDuration = this.getDuration(leg, departureAirportDetail, arrivalAirportDetail);
    }
    const daysDiff = Helpers.getDaysDiff(leg.departureAirportTimeLocal, leg.arrivalAirportTimeLocal);
    const upcomingFirstLeg = index === 0 && itineraryType === 'upcomingItineraries' && true;
    const pax = passengers ? passengers.length : 0;
    return (
      <div className="uk-width-1-1">
        <div className="uk-clearfix">
          <div className="uk-float-right uk-text-right">
            <span className="vistajet_itinery_ordernumber">
              {!childLeg && this.getLegOrderId(item, leg, isItineraryDetail)}
            </span>
            {!isItineraryDetail && (
                <span className="vistajet_color_red uk-display-block">
                  {leg && getItineraryLegStatus(leg, itineraryType)}
                </span>
              )}
          </div>
          <div className="uk-float-left">
            <div className="vistajet_date_column">
              <span className="vistajet_date_icon">
                {
                  dates.includes('|') ? (
                    <React.Fragment>
                      {dates.split('|')[0]} <span className="vistajet_multidate_pipe">|</span> <span className="vistajet_multi_dates">{dates.split(' | ')[1]}</span>
                    </React.Fragment>
                  ) : (dates)
                }
              </span>
              <div>
                {
                  this.renderAddToCalender(arrivalAirportDetail, departureAirportDetail)
                }
              </div>
            </div>
          </div>
        </div>
        <div className="vistajet_departure_column">
          <div className="uk-flex uk-grid-medium" data-uk-grid>
            <div className="uk-width-2-5">
              <div className="vistajet_departure_left">
                {departureAirportDetail ? Helpers.capitalizeWord(departureAirportDetail.city) : (<SkeletonLoaderAirport />)}
                <small>
                  {departureAirportDetail && Helpers.capitalizeWord(departureAirportDetail.name) + ' ' + departureAirportDetail.iso2}
                </small>
              </div>
            </div>
            <div className="uk-width-1-5 uk-text-center">
              <div className={this.getFlightPathIcon(flightIcon)}>
                <span>
                  {(upcomingFirstMultiLeg || upcomingFirstLeg || isItineraryDetail) && legDuration && getLegDuration(legDuration)}
                </span>
              </div>
            </div>
            <div className="uk-width-2-5 uk-text-right">
              <div className="vistajet_departure_right">
                {arrivalAirportDetail ? Helpers.capitalizeWord(arrivalAirportDetail.city) : (<div className="uk-float-right uk-align-right"><SkeletonLoaderAirport /></div>)}
                <small>
                  {arrivalAirportDetail && Helpers.capitalizeWord(arrivalAirportDetail.name) + ' ' + arrivalAirportDetail.iso2}
                </small>
              </div>
            </div>
          </div>
        </div>
        {(upcomingFirstMultiLeg || upcomingFirstLeg || isItineraryDetail || childLeg) && (
          <div className="vistajet_eta_column">
            <div className="uk-clearfix">
              <div className="uk-float-right">
                {getLegLocalTime(leg.arrivalAirportTimeLocal) + ` loc ${daysDiff}`}
              </div>
              <div className="uk-float-left">
                {getLegLocalTime(leg.departureAirportTimeLocal) + ' loc'}
              </div>
            </div>
          </div>
        )}
        <div className="vistajet_cp_column">
          <div className="uk-clearfix">
            {!childLeg && (upcomingFirstMultiLeg || upcomingFirstLeg || isItineraryDetail) && (
              <div className="uk-float-right vistajet_passengers_column">
                <span>
                  {(pax < leg.numberOfPassenger)
                    ? leg.numberOfPassenger : pax}
                </span>
              </div>
            )}
            <div className="uk-float-left vistajet_aircraft_column">
              {this.getAircraftImage(selectedAircraft)}
              <span className="uk-text-bottom">
                {selectedAircraft && selectedAircraft.aircraftName}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FlightPathRow;
