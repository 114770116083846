import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExistingPax from './ExistingPax';
import Source from '../../store/sources/ItineraryPassengerSource';
import { searchPaxNormalizer } from '../../normalizers/PassengerNormalizer';
import {
  PassengerList,
  PassengerForm,
  PassengerSearch,
} from 'constants/passengerConstants';
import { getPassengerId } from 'utils/passengerUtils';
import SpinnerLoader from '../../components/shared/spinnerLoader';

class AddPassengers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      heading: 'Recent Passengers',
      marginClass: 'my-pax-section',
      searchTerm: '',
      search: false,
      submitting: false,
      paxList: null,
      searchClicked: false,
    };
    this.props.isSearch(false);
  }

  handleSearchInput = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  }

  searchPax = () => {
    this.setState({ searchClicked: true });
    if (this.state.searchTerm && this.state.searchTerm.length >= 3) {
      this.setState({
        heading: '',
        paxList: [],
        marginClass: '',
        search: true,
        submitting: true,
      });
      this.props.isSearch(true);
      if (!this.refs.searchInput.checkValidity()) {
        this.setState({
          submitting: false,
          search: false,
        });
      }
    }
    const term = this.state.searchTerm.trim();
    if (!this.state.submitting && term.length >= 3) {
      // prevent multiple submit
      Source.searchPax(term)
        .then((data) => {
          let paxList = searchPaxNormalizer(data.searchPax);
          const addedPaxIds = [];
          (this.props.addedPax || []).forEach(pax => {
            addedPaxIds.push(getPassengerId(pax));
          });
          paxList = paxList.filter(pax => (!addedPaxIds.includes(getPassengerId(pax))));
          this.setState({
            heading: 'Search Results',
            submitting: false,
            paxList,
          });
        })
        .catch(() => {
          this.handleReset();
        });
    }
  }

  handleReset = () => {
    this.setState({
      heading: 'Recent Passengers',
      marginClass: 'my-pax-section',
      search: false,
      searchTerm: '',
      paxList: null,
      submitting: false,
      searchClicked: false,
    });
    this.props.isSearch(false);
  }
  handleAddNewPaxClick = () => {
    this.props.handleRenderingChange(PassengerForm, PassengerSearch);
  };

  handleAddPaxClick = (passenger) => {
    this.props.handleDirtyChange(true);
    this.props.updateAddedPassengers(passenger);
    this.props.handleRenderingChange(PassengerList);
  };

  formSubmit = (e) => {
    e.preventDefault();
  }

  render() {
    const {
      searchTerm,
      search,
      submitting,
      searchClicked,
    } = this.state;

    return (
      <div>
        {
          !(submitting || searchClicked) &&
          <p className="vistajet_add_new_passenger">Select a passenger from the team OR <a href="#" onClick={this.handleAddNewPaxClick}>Add a new
            passenger
          </a>
          </p>
        }
        <div className="vistajet_primary_form vistajet_passenger_select_form">
          <div className="vistajet_input_with_icon uk-margin-remove">
            <div className="vistajet_input_wrapper" style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}>
              <input
                // type="search"
                id="searchText"
                ref="searchInput"
                onChange={this.handleSearchInput}
                value={this.state.searchTerm}
                autoComplete="off"
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    this.searchPax();
                  }
                }}
                className="vistajet_passenger_search_input"
              />
              <label htmlFor="text">Search Passengers in my team</label>
              {(searchTerm.length >= 3 && search === true) &&
                <span className="vistajet_search_close_icon" onClick={this.handleReset}>
                  <img src="/legacy/static/img/icons/ic_cancel_grey.svg" alt="" />
                </span>}
            </div>
            <button className="vistajet_passenger_modal_button uk-button uk-margin-remove vistajet_button_primary vistajet_passenger_search_button" onClick={this.searchPax}>
              <span>
                Search
              </span>
            </button>
          </div>
        </div>
        {!submitting ?
          <ExistingPax
            heading={this.state.heading}
            marginClass={this.state.marginClass}
            frequentPax={this.props.frequentPax}
            search={this.state.search}
            paxList={this.state.paxList}
            handleAddPaxClick={this.handleAddPaxClick}
            submitting={this.state.submitting}
            searchTerm={this.state.searchTerm}
            searchClicked={this.state.searchClicked}
          /> :
          (<div className="uk-text-center uk-margin">
            <SpinnerLoader />
          </div>
          )
        }
      </div>
    );
  }
}

AddPassengers.propTypes = {
  frequentPax: PropTypes.array,
  addedPax: PropTypes.array,
  handleRenderingChange: PropTypes.func,
  handleDirtyChange: PropTypes.func,
  isSearch: PropTypes.func,
};

export default AddPassengers;
