import * as types from '../actionTypes';
import countryData from 'constants/CountryConstants';

const initialState = {
  country: '',
  regionId: null,
  error: '',
  isLoading: false,
  locationDisabled: false,
};

export default function geolocationReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_GEOCODE_INIT: {
      return Object.assign({}, state, {
        country: '',
        regionId: null,
        error: '',
        isLoading: true,
        locationDisabled: false,
      });
    }
    case types.FETCH_GEOCODE_SUCCESS: {
      const countryName = action.data.country.toUpperCase();
      const region = countryData.find(country => country.COUNTRY_NAME === countryName);
      const regionId = region ? +region.REGION_ID : null;
      return Object.assign({}, state, {
        country: countryName,
        regionId,
        error: '',
        isLoading: false,
        locationDisabled: false,
      });
    }
    case types.FETCH_GEOCODE_FAIL: {
      return Object.assign({}, state, {
        country: '',
        regionId: null,
        error: 'Geocode not available',
        isLoading: false,
        locationDisabled: true,
      });
    }
    default:
      return state;
  }
}
