import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListPassengerRows from './ListPassengerRows';
import { getPaxToBeAnnounced } from '../../utils/passengerUtils';

class ListPassengers extends Component {
  static propTypes = {
    handleRenderingChange: PropTypes.func,
    handlePaxCount: PropTypes.func,
    paxCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showCounter: PropTypes.bool,
    disableConfirmBtn: PropTypes.bool,
    disableAddPassengerBtn: PropTypes.bool,
    isError: PropTypes.bool,
    maxNumberOfPassengers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addedPax: PropTypes.array,
    deletedPax: PropTypes.array,
    handleViewDetail: PropTypes.func,
    handleRemovePassport: PropTypes.func,
    handleAddNewPassport: PropTypes.func,
    removeUser: PropTypes.func,
    handleConfirm: PropTypes.func,
    setError: PropTypes.func,
    handleDirtyChange: PropTypes.func,
    onItineraryDetail: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  render() {
    const {
      paxCount,
      addedPax,
      handleRenderingChange,
      handleViewDetail,
      handleRemovePassport,
      handleAddNewPassport,
      handleDirtyChange,
      removeUser,
      setLeadPassenger,
      setLegPassport,
      showLoaderPassenger,
    } = this.props;
    const paxToBeAnnounced = getPaxToBeAnnounced(paxCount, addedPax);
    return (
      <div className="vistajet_passenger_list vistajet_passenger_to_be_announced uk-margin-remove vistajet_remove_border_bottom">
        <h6 className="vistajet_subheading">{paxToBeAnnounced}</h6>
          <ListPassengerRows
            count={paxCount}
            addedPax={addedPax}
            handleRenderingChange={handleRenderingChange}
            handleViewDetail={handleViewDetail}
            handleRemovePassport={handleRemovePassport}
            handleAddNewPassport={handleAddNewPassport}
            handleDirtyChange={handleDirtyChange}
            setLeadPassenger={setLeadPassenger}
            removeUser={removeUser}
            setLegPassport={setLegPassport}
            showLoaderPassenger={showLoaderPassenger}
          />
      </div>
    );
  }
}
export default ListPassengers;
