const cities = [
  { placeId: 4297, cityName: 'BAGRAM', countryName: 'AFGHANISTAN' },
  { placeId: 4702, cityName: 'BASTION', countryName: 'AFGHANISTAN' },
  { placeId: 10124, cityName: 'CHAKHCHARAN', countryName: 'AFGHANISTAN' },
  { placeId: 10682, cityName: 'FARAH', countryName: 'AFGHANISTAN' },
  { placeId: 10706, cityName: 'FEYZABAD', countryName: 'AFGHANISTAN' },
  { placeId: 5830, cityName: 'HERAT', countryName: 'AFGHANISTAN' },
  { placeId: 11305, cityName: 'JALALABAD', countryName: 'AFGHANISTAN' },
  { placeId: 2340, cityName: 'KABUL', countryName: 'AFGHANISTAN' },
  { placeId: 2341, cityName: 'KANDAHAR', countryName: 'AFGHANISTAN' },
  { placeId: 11448, cityName: 'KHOST/CHAPMAN', countryName: 'AFGHANISTAN' },
  { placeId: 6314, cityName: 'KONDUZ', countryName: 'AFGHANISTAN' },
  { placeId: 11801, cityName: 'MAIMANA', countryName: 'AFGHANISTAN' },
  { placeId: 6891, cityName: 'MAZAR-E SHARIF', countryName: 'AFGHANISTAN' },
  { placeId: 12684, cityName: 'QALA-I-NAW', countryName: 'AFGHANISTAN' },
  { placeId: 12896, cityName: 'SALERNO', countryName: 'AFGHANISTAN' },
  { placeId: 13034, cityName: 'SHANK', countryName: 'AFGHANISTAN' },
  { placeId: 13035, cityName: 'SHARANA', countryName: 'AFGHANISTAN' },
  { placeId: 13062, cityName: 'SHINDAND', countryName: 'AFGHANISTAN' },
  { placeId: 8620, cityName: 'TEREEN', countryName: 'AFGHANISTAN' },
  { placeId: 1392, cityName: 'TIRANA', countryName: 'ALBANIA' },
  { placeId: 4016, cityName: 'ADRAR', countryName: 'ALGERIA' },
  { placeId: 4075, cityName: 'ALGIERS', countryName: 'ALGERIA' },
  { placeId: 4155, cityName: 'ANNABA', countryName: 'ALGERIA' },
  { placeId: 4377, cityName: 'BATNA', countryName: 'ALGERIA' },
  { placeId: 9776, cityName: 'BECHAR', countryName: 'ALGERIA' },
  { placeId: 4404, cityName: 'BEJAIA', countryName: 'ALGERIA' },
  { placeId: 9133, cityName: 'BISKRA', countryName: 'ALGERIA' },
  { placeId: 4546, cityName: 'BORDJ MOKHTAR', countryName: 'ALGERIA' },
  { placeId: 4561, cityName: 'BOU SAADA', countryName: 'ALGERIA' },
  { placeId: 4848, cityName: 'CHLEF', countryName: 'ALGERIA' },
  { placeId: 4946, cityName: 'CONSTANTINE', countryName: 'ALGERIA' },
  { placeId: 5184, cityName: 'DJANET', countryName: 'ALGERIA' },
  { placeId: 9177, cityName: 'EL BAYADH', countryName: 'ALGERIA' },
  { placeId: 5286, cityName: 'EL GOLEA', countryName: 'ALGERIA' },
  { placeId: 5293, cityName: 'EL OUED', countryName: 'ALGERIA' },
  { placeId: 5554, cityName: 'GHARDAIA', countryName: 'ALGERIA' },
  { placeId: 5558, cityName: 'GHRISS', countryName: 'ALGERIA' },
  { placeId: 2859, cityName: 'HASSI MESSAOUD', countryName: 'ALGERIA' },
  { placeId: 5949, cityName: 'ILLIZI', countryName: 'ALGERIA' },
  { placeId: 5958, cityName: 'IN GUEZZAM', countryName: 'ALGERIA' },
  { placeId: 5959, cityName: 'IN SALAH', countryName: 'ALGERIA' },
  { placeId: 6062, cityName: 'JIJEL', countryName: 'ALGERIA' },
  { placeId: 7400, cityName: 'ORAN', countryName: 'ALGERIA' },
  { placeId: 7440, cityName: 'OUARGLA', countryName: 'ALGERIA' },
  { placeId: 8195, cityName: 'SETIF', countryName: 'ALGERIA' },
  { placeId: 8565, cityName: 'TAMANRASSET', countryName: 'ALGERIA' },
  { placeId: 8602, cityName: 'TEBESSA', countryName: 'ALGERIA' },
  { placeId: 8654, cityName: 'TIARET', countryName: 'ALGERIA' },
  { placeId: 8668, cityName: 'TIMIMOUN', countryName: 'ALGERIA' },
  { placeId: 13440, cityName: 'TINDOUF', countryName: 'ALGERIA' },
  { placeId: 8676, cityName: 'TLEMCEN', countryName: 'ALGERIA' },
  { placeId: 8689, cityName: 'TOUGGOURT', countryName: 'ALGERIA' },
  { placeId: 9111, cityName: 'ZARZAITINE', countryName: 'ALGERIA' },
  { placeId: 12408, cityName: 'PAGO PAGO', countryName: 'AMERICAN SAMOA' },
  { placeId: 4116, cityName: 'AMBRIZ', countryName: 'ANGOLA' },
  { placeId: 4422, cityName: 'BENGUELA', countryName: 'ANGOLA' },
  { placeId: 4664, cityName: 'CABINDA', countryName: 'ANGOLA' },
  { placeId: 5897, cityName: 'HUAMBO', countryName: 'ANGOLA' },
  { placeId: 6339, cityName: 'KUITO', countryName: 'ANGOLA' },
  { placeId: 6592, cityName: 'LOBITO', countryName: 'ANGOLA' },
  { placeId: 1144, cityName: 'LUANDA', countryName: 'ANGOLA' },
  { placeId: 6641, cityName: 'LUBANGO', countryName: 'ANGOLA' },
  { placeId: 6656, cityName: 'LUENA', countryName: 'ANGOLA' },
  { placeId: 6685, cityName: 'M\'BANZA CONGO', countryName: 'ANGOLA' },
  { placeId: 6734, cityName: 'MALANGE', countryName: 'ANGOLA' },
  { placeId: 6791, cityName: 'MAQUELA', countryName: 'ANGOLA' },
  { placeId: 6938, cityName: 'MENONGUE', countryName: 'ANGOLA' },
  { placeId: 6495, cityName: 'N\'ZETO', countryName: 'ANGOLA' },
  { placeId: 7175, cityName: 'NAMIBE', countryName: 'ANGOLA' },
  { placeId: 7211, cityName: 'NEGAGE', countryName: 'ANGOLA' },
  { placeId: 7164, cityName: 'ONDJIVA', countryName: 'ANGOLA' },
  { placeId: 7714, cityName: 'PORTO AMBOIM', countryName: 'ANGOLA' },
  { placeId: 8131, cityName: 'SAURIMO', countryName: 'ANGOLA' },
  { placeId: 8376, cityName: 'SOYO', countryName: 'ANGOLA' },
  { placeId: 8511, cityName: 'SUMBE', countryName: 'ANGOLA' },
  { placeId: 8777, cityName: 'UIGE', countryName: 'ANGOLA' },
  { placeId: 8904, cityName: 'WAKO KUNGO', countryName: 'ANGOLA' },
  { placeId: 9057, cityName: 'XANGONGO', countryName: 'ANGOLA' },
  { placeId: 8636, cityName: 'THE VALLEY', countryName: 'ANGUILLA' },
  { placeId: 6914, cityName: 'MCMURDO STATION', countryName: 'ANTARCTICA (AUS, NZ)' },
  { placeId: 6913, cityName: 'MCMURDO', countryName: 'ANTARCTICA (AUS, NZ)' },
  { placeId: 8372, cityName: 'SOUTH POLE STATION', countryName: 'ANTARCTICA (AUS, NZ)' },
  { placeId: 8622, cityName: 'TERRA NOVA BAY', countryName: 'ANTARCTICA (AUS, NZ)' },
  { placeId: 9722, cityName: 'BARBUDA', countryName: 'ANTIGUA' },
  { placeId: 2537, cityName: 'ST JOHNS', countryName: 'ANTIGUA' },
  { placeId: 3215, cityName: 'ALTO RIO SENGUERR', countryName: 'ARGENTINA' },
  { placeId: 3228, cityName: 'AZUL', countryName: 'ARGENTINA' },
  { placeId: 3229, cityName: 'BAHIA BLANCA', countryName: 'ARGENTINA' },
  { placeId: 3235, cityName: 'BOLIVAR', countryName: 'ARGENTINA' },
  { placeId: 2451, cityName: 'BUENOS AIRES', countryName: 'ARGENTINA' },
  { placeId: 3185, cityName: 'CATAMARCA', countryName: 'ARGENTINA' },
  { placeId: 2457, cityName: 'CATARATAS DEL IGUAZU', countryName: 'ARGENTINA' },
  { placeId: 3191, cityName: 'CERES', countryName: 'ARGENTINA' },
  { placeId: 3189, cityName: 'CHILECITO', countryName: 'ARGENTINA' },
  { placeId: 10277, cityName: 'CLORINDA', countryName: 'ARGENTINA' },
  { placeId: 2461, cityName: 'COMODORO RIVADAVIA', countryName: 'ARGENTINA' },
  { placeId: 10324, cityName: 'CONCORDIA', countryName: 'ARGENTINA' },
  { placeId: 2452, cityName: 'CORDOBA', countryName: 'ARGENTINA' },
  { placeId: 3179, cityName: 'CORONEL OLMEDO', countryName: 'ARGENTINA' },
  { placeId: 3230, cityName: 'CORONEL SUAREZ', countryName: 'ARGENTINA' },
  { placeId: 10348, cityName: 'CORRIENTES', countryName: 'ARGENTINA' },
  { placeId: 3209, cityName: 'CURUZU CUATIA', countryName: 'ARGENTINA' },
  { placeId: 3244, cityName: 'CUTRAL-CO', countryName: 'ARGENTINA' },
  { placeId: 3210, cityName: 'EL BOLSON', countryName: 'ARGENTINA' },
  { placeId: 3221, cityName: 'EL CALAFATE', countryName: 'ARGENTINA' },
  { placeId: 3211, cityName: 'EL MAITEN', countryName: 'ARGENTINA' },
  { placeId: 3226, cityName: 'EL TURBIO', countryName: 'ARGENTINA' },
  { placeId: 3204, cityName: 'ELDORADO', countryName: 'ARGENTINA' },
  { placeId: 3212, cityName: 'ESQUEL', countryName: 'ARGENTINA' },
  { placeId: 2456, cityName: 'FORMOSA', countryName: 'ARGENTINA' },
  { placeId: 3181, cityName: 'GENERAL ALVEAR', countryName: 'ARGENTINA' },
  { placeId: 3233, cityName: 'GENERAL PICO', countryName: 'ARGENTINA' },
  { placeId: 3180, cityName: 'GENERAL ROCA', countryName: 'ARGENTINA' },
  { placeId: 3907, cityName: 'GOBERNADOR GORDILLO', countryName: 'ARGENTINA' },
  { placeId: 3224, cityName: 'GOBERNADOR GREGORES', countryName: 'ARGENTINA' },
  { placeId: 3205, cityName: 'GOYA', countryName: 'ARGENTINA' },
  { placeId: 3174, cityName: 'GUALEGUAYCHU', countryName: 'ARGENTINA' },
  { placeId: 3225, cityName: 'JOSE DE SAN MARTIN', countryName: 'ARGENTINA' },
  { placeId: 3236, cityName: 'JUAREZ', countryName: 'ARGENTINA' },
  { placeId: 2460, cityName: 'JUJUY', countryName: 'ARGENTINA' },
  { placeId: 3175, cityName: 'JUNIN', countryName: 'ARGENTINA' },
  { placeId: 3178, cityName: 'LA CUMBRE', countryName: 'ARGENTINA' },
  { placeId: 3183, cityName: 'LA PAZ', countryName: 'ARGENTINA' },
  { placeId: 11565, cityName: 'LA PLATA', countryName: 'ARGENTINA' },
  { placeId: 3188, cityName: 'LA RIOJA', countryName: 'ARGENTINA' },
  { placeId: 3194, cityName: 'LABOULAYE', countryName: 'ARGENTINA' },
  { placeId: 3220, cityName: 'LAGO ARGENTINO', countryName: 'ARGENTINA' },
  { placeId: 3182, cityName: 'MALARGUE', countryName: 'ARGENTINA' },
  { placeId: 3214, cityName: 'MAQUINCHAO', countryName: 'ARGENTINA' },
  { placeId: 3237, cityName: 'MAR DEL PLATA', countryName: 'ARGENTINA' },
  { placeId: 3195, cityName: 'MARCOS JUAREZ', countryName: 'ARGENTINA' },
  { placeId: 3908, cityName: 'MARIANO MORENO', countryName: 'ARGENTINA' },
  { placeId: 11900, cityName: 'MATANZA', countryName: 'ARGENTINA' },
  { placeId: 2453, cityName: 'MENDOZA', countryName: 'ARGENTINA' },
  { placeId: 3206, cityName: 'MERCEDES', countryName: 'ARGENTINA' },
  { placeId: 3200, cityName: 'MONTE CASEROS', countryName: 'ARGENTINA' },
  { placeId: 3909, cityName: 'MORON', countryName: 'ARGENTINA' },
  { placeId: 3238, cityName: 'NECOCHEA', countryName: 'ARGENTINA' },
  { placeId: 2464, cityName: 'NEUQUEN', countryName: 'ARGENTINA' },
  { placeId: 3245, cityName: 'NUEVE DE JULIO', countryName: 'ARGENTINA' },
  { placeId: 3207, cityName: 'OBERA', countryName: 'ARGENTINA' },
  { placeId: 3232, cityName: 'OLAVARRIA', countryName: 'ARGENTINA' },
  { placeId: 3202, cityName: 'ORAN', countryName: 'ARGENTINA' },
  { placeId: 3176, cityName: 'PARANA', countryName: 'ARGENTINA' },
  { placeId: 3199, cityName: 'PASO DE LOS LIBRES', countryName: 'ARGENTINA' },
  { placeId: 3239, cityName: 'PEHUAJO', countryName: 'ARGENTINA' },
  { placeId: 3223, cityName: 'PERITO MORENO', countryName: 'ARGENTINA' },
  { placeId: 3231, cityName: 'PIGUE', countryName: 'ARGENTINA' },
  { placeId: 2458, cityName: 'POSADAS', countryName: 'ARGENTINA' },
  { placeId: 3201, cityName: 'PRESIDENCIA ROQUE S. PENA', countryName: 'ARGENTINA' },
  { placeId: 12668, cityName: 'PUERTO DESEADO', countryName: 'ARGENTINA' },
  { placeId: 3219, cityName: 'PUERTO MADRYN', countryName: 'ARGENTINA' },
  { placeId: 12679, cityName: 'PUNTA INDIO', countryName: 'ARGENTINA' },
  { placeId: 12702, cityName: 'QUILMES', countryName: 'ARGENTINA' },
  { placeId: 3208, cityName: 'RECONQUISTA', countryName: 'ARGENTINA' },
  { placeId: 2455, cityName: 'RESISTENCIA', countryName: 'ARGENTINA' },
  { placeId: 3240, cityName: 'RIO COLORADO', countryName: 'ARGENTINA' },
  { placeId: 3192, cityName: 'RIO CUARTO', countryName: 'ARGENTINA' },
  { placeId: 2462, cityName: 'RIO GALLEGOS', countryName: 'ARGENTINA' },
  { placeId: 12798, cityName: 'RIO GRANDE', countryName: 'ARGENTINA' },
  { placeId: 3222, cityName: 'RIO MAYO', countryName: 'ARGENTINA' },
  { placeId: 2450, cityName: 'ROSARIO', countryName: 'ARGENTINA' },
  { placeId: 2465, cityName: 'S C DE BARILOCHE', countryName: 'ARGENTINA' },
  { placeId: 2459, cityName: 'SALTA', countryName: 'ARGENTINA' },
  { placeId: 3213, cityName: 'SAN ANTONIO OESTE', countryName: 'ARGENTINA' },
  { placeId: 12921, cityName: 'SAN FERNANDO', countryName: 'ARGENTINA' },
  { placeId: 3190, cityName: 'SAN JUAN', countryName: 'ARGENTINA' },
  { placeId: 12925, cityName: 'SAN JULIAN', countryName: 'ARGENTINA' },
  { placeId: 3198, cityName: 'SAN LUIS', countryName: 'ARGENTINA' },
  { placeId: 3246, cityName: 'SAN MARTIN DE LOS ANDES', countryName: 'ARGENTINA' },
  { placeId: 3184, cityName: 'SAN RAFAEL', countryName: 'ARGENTINA' },
  { placeId: 3227, cityName: 'SANTA CRUZ', countryName: 'ARGENTINA' },
  { placeId: 3177, cityName: 'SANTA FE', countryName: 'ARGENTINA' },
  { placeId: 3241, cityName: 'SANTA ROSA', countryName: 'ARGENTINA' },
  { placeId: 12954, cityName: 'SANTA TERESITA', countryName: 'ARGENTINA' },
  { placeId: 3186, cityName: 'SANTIAGO DEL ESTERO', countryName: 'ARGENTINA' },
  { placeId: 3216, cityName: 'SIERRA GRANDE', countryName: 'ARGENTINA' },
  { placeId: 3242, cityName: 'TANDIL', countryName: 'ARGENTINA' },
  { placeId: 3203, cityName: 'TARTAGAL', countryName: 'ARGENTINA' },
  { placeId: 3187, cityName: 'TINOGASTA', countryName: 'ARGENTINA' },
  { placeId: 3217, cityName: 'TRELEW', countryName: 'ARGENTINA' },
  { placeId: 3234, cityName: 'TRES ARROYOS', countryName: 'ARGENTINA' },
  { placeId: 2454, cityName: 'TUCUMAN', countryName: 'ARGENTINA' },
  { placeId: 2463, cityName: 'USHUAIA', countryName: 'ARGENTINA' },
  { placeId: 3197, cityName: 'VALLE DEL CONLARA', countryName: 'ARGENTINA' },
  { placeId: 3218, cityName: 'VIEDMA', countryName: 'ARGENTINA' },
  { placeId: 3193, cityName: 'VILLA DOLORES', countryName: 'ARGENTINA' },
  { placeId: 3243, cityName: 'VILLA GESELL', countryName: 'ARGENTINA' },
  { placeId: 3196, cityName: 'VILLA REYNOLDS', countryName: 'ARGENTINA' },
  { placeId: 5757, cityName: 'GYUMRI', countryName: 'ARMENIA' },
  { placeId: 2571, cityName: 'YEREVAN', countryName: 'ARMENIA' },
  { placeId: 2548, cityName: 'ARUBA ISLAND', countryName: 'ARUBA' },
  { placeId: 9626, cityName: 'ASCENSION I.', countryName: 'ASCENSION' },
  // { placeId: 9411, cityName: '', countryName: 'AUSTRALIA' },
  { placeId: 2769, cityName: 'ADELAIDE', countryName: 'AUSTRALIA' },
  { placeId: 3849, cityName: 'AEROPELICAN', countryName: 'AUSTRALIA' },
  { placeId: 3745, cityName: 'ALBANY', countryName: 'AUSTRALIA' },
  { placeId: 3822, cityName: 'ALBURY', countryName: 'AUSTRALIA' },
  { placeId: 2758, cityName: 'ALICE SPRINGS', countryName: 'AUSTRALIA' },
  { placeId: 9553, cityName: 'ALPHA', countryName: 'AUSTRALIA' },
  { placeId: 9565, cityName: 'AMBERLEY', countryName: 'AUSTRALIA' },
  { placeId: 9575, cityName: 'ANDAMOOKA', countryName: 'AUSTRALIA' },
  { placeId: 3747, cityName: 'ARARAT', countryName: 'AUSTRALIA' },
  { placeId: 3748, cityName: 'ARGYLE', countryName: 'AUSTRALIA' },
  { placeId: 3749, cityName: 'ARMIDALE', countryName: 'AUSTRALIA' },
  { placeId: 9645, cityName: 'ATHERTON', countryName: 'AUSTRALIA' },
  { placeId: 9674, cityName: 'AURUKUN', countryName: 'AUSTRALIA' },
  { placeId: 2765, cityName: 'AVALON', countryName: 'AUSTRALIA' },
  { placeId: 9683, cityName: 'AYERS ROCK', countryName: 'AUSTRALIA' },
  { placeId: 9688, cityName: 'BADU ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3758, cityName: 'BAIRNSDALE', countryName: 'AUSTRALIA' },
  { placeId: 3751, cityName: 'BALGO HILL', countryName: 'AUSTRALIA' },
  { placeId: 3756, cityName: 'BALLARAT', countryName: 'AUSTRALIA' },
  { placeId: 9702, cityName: 'BALLERA', countryName: 'AUSTRALIA' },
  { placeId: 3757, cityName: 'BALLINA', countryName: 'AUSTRALIA' },
  { placeId: 3759, cityName: 'BALRANALD', countryName: 'AUSTRALIA' },
  { placeId: 9706, cityName: 'BAMYILI', countryName: 'AUSTRALIA' },
  { placeId: 9723, cityName: 'BARCALDINE', countryName: 'AUSTRALIA' },
  { placeId: 3761, cityName: 'BARIMUNYA', countryName: 'AUSTRALIA' },
  { placeId: 9732, cityName: 'BARROW I', countryName: 'AUSTRALIA' },
  { placeId: 9738, cityName: 'BARYULGIL', countryName: 'AUSTRALIA' },
  { placeId: 9750, cityName: 'BATHURST I', countryName: 'AUSTRALIA' },
  { placeId: 3762, cityName: 'BATHURST', countryName: 'AUSTRALIA' },
  { placeId: 9783, cityName: 'BEDOURIE', countryName: 'AUSTRALIA' },
  { placeId: 9800, cityName: 'BELLEVUE', countryName: 'AUSTRALIA' },
  { placeId: 3755, cityName: 'BENALLA', countryName: 'AUSTRALIA' },
  { placeId: 3750, cityName: 'BENDIGO', countryName: 'AUSTRALIA' },
  { placeId: 13937, cityName: 'Beverley Springs', countryName: 'AUSTRALIA' },
  { placeId: 3753, cityName: 'BIRCHIP', countryName: 'AUSTRALIA' },
  { placeId: 9844, cityName: 'BIRDSVILLE', countryName: 'AUSTRALIA' },
  { placeId: 9854, cityName: 'BLACKALL', countryName: 'AUSTRALIA' },
  { placeId: 9858, cityName: 'BLACKWATER', countryName: 'AUSTRALIA' },
  { placeId: 9879, cityName: 'BOIGU', countryName: 'AUSTRALIA' },
  { placeId: 9890, cityName: 'BORROLOOLA', countryName: 'AUSTRALIA' },
  { placeId: 9900, cityName: 'BOULIA', countryName: 'AUSTRALIA' },
  { placeId: 3754, cityName: 'BOURKE', countryName: 'AUSTRALIA' },
  { placeId: 9904, cityName: 'BOWEN', countryName: 'AUSTRALIA' },
  { placeId: 3760, cityName: 'BREWARRINA', countryName: 'AUSTRALIA' },
  { placeId: 2759, cityName: 'BRISBANE', countryName: 'AUSTRALIA' },
  { placeId: 3752, cityName: 'BROKEN HILL', countryName: 'AUSTRALIA' },
  { placeId: 3956, cityName: 'BRONZEWING', countryName: 'AUSTRALIA' },
  { placeId: 9951, cityName: 'BROOME', countryName: 'AUSTRALIA' },
  { placeId: 9976, cityName: 'BUNBURY', countryName: 'AUSTRALIA' },
  { placeId: 9977, cityName: 'BUNDABERG', countryName: 'AUSTRALIA' },
  { placeId: 9980, cityName: 'BURKETOWN', countryName: 'AUSTRALIA' },
  { placeId: 9997, cityName: 'BUSSELTON', countryName: 'AUSTRALIA' },
  { placeId: 2761, cityName: 'CAIRNS', countryName: 'AUSTRALIA' },
  { placeId: 3861, cityName: 'CAMDEN', countryName: 'AUSTRALIA' },
  { placeId: 10034, cityName: 'CAMOOWEAL', countryName: 'AUSTRALIA' },
  { placeId: 2775, cityName: 'CANBERRA', countryName: 'AUSTRALIA' },
  { placeId: 10061, cityName: 'CAPE LEVEQUE', countryName: 'AUSTRALIA' },
  { placeId: 10080, cityName: 'CARNARVON', countryName: 'AUSTRALIA' },
  { placeId: 3763, cityName: 'CASINO', countryName: 'AUSTRALIA' },
  { placeId: 10108, cityName: 'CEDUNA', countryName: 'AUSTRALIA' },
  { placeId: 10119, cityName: 'CENTURY MINE', countryName: 'AUSTRALIA' },
  { placeId: 10144, cityName: 'CHARLEVILLE', countryName: 'AUSTRALIA' },
  { placeId: 10195, cityName: 'CHILLAGOE', countryName: 'AUSTRALIA' },
  { placeId: 10199, cityName: 'CHINCHILLA', countryName: 'AUSTRALIA' },
  { placeId: 13924, cityName: 'Christmas Island', countryName: 'AUSTRALIA' },
  { placeId: 10263, cityName: 'CLERMONT', countryName: 'AUSTRALIA' },
  { placeId: 10264, cityName: 'CLEVE', countryName: 'AUSTRALIA' },
  { placeId: 10275, cityName: 'CLONCURRY', countryName: 'AUSTRALIA' },
  { placeId: 3764, cityName: 'COBAR', countryName: 'AUSTRALIA' },
  { placeId: 10285, cityName: 'COCONUT ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 10286, cityName: 'COCOS (KEELING) I', countryName: 'AUSTRALIA' },
  { placeId: 10288, cityName: 'COEN', countryName: 'AUSTRALIA' },
  { placeId: 3768, cityName: 'COFFS HARBOUR', countryName: 'AUSTRALIA' },
  { placeId: 3766, cityName: 'COLLARENEBRI', countryName: 'AUSTRALIA' },
  { placeId: 3767, cityName: 'CONDOBOLIN', countryName: 'AUSTRALIA' },
  { placeId: 10331, cityName: 'COOBER PEDY', countryName: 'AUSTRALIA' },
  { placeId: 10334, cityName: 'COOKTOWN', countryName: 'AUSTRALIA' },
  { placeId: 3957, cityName: 'COOLAH', countryName: 'AUSTRALIA' },
  { placeId: 3771, cityName: 'COOMA-SNOWY MOUNTAINS', countryName: 'AUSTRALIA' },
  { placeId: 10335, cityName: 'COOMINYA', countryName: 'AUSTRALIA' },
  { placeId: 3765, cityName: 'COONABARABRAN', countryName: 'AUSTRALIA' },
  { placeId: 3770, cityName: 'COONAMBLE', countryName: 'AUSTRALIA' },
  { placeId: 3777, cityName: 'COONDEWANNA', countryName: 'AUSTRALIA' },
  { placeId: 2861, cityName: 'COOTAMUNDRA', countryName: 'AUSTRALIA' },
  { placeId: 3772, cityName: 'COROWA', countryName: 'AUSTRALIA' },
  { placeId: 3773, cityName: 'CORRYONG', countryName: 'AUSTRALIA' },
  { placeId: 10359, cityName: 'COWELL', countryName: 'AUSTRALIA' },
  { placeId: 3778, cityName: 'COWRA', countryName: 'AUSTRALIA' },
  { placeId: 10376, cityName: 'CROKER I', countryName: 'AUSTRALIA' },
  { placeId: 3774, cityName: 'CUDAL', countryName: 'AUSTRALIA' },
  { placeId: 3775, cityName: 'CUE', countryName: 'AUSTRALIA' },
  { placeId: 3776, cityName: 'CUNDERDIN', countryName: 'AUSTRALIA' },
  { placeId: 10395, cityName: 'CUNNAMULLA', countryName: 'AUSTRALIA' },
  { placeId: 10397, cityName: 'CURTIN', countryName: 'AUSTRALIA' },
  { placeId: 3779, cityName: 'DARLOT', countryName: 'AUSTRALIA' },
  { placeId: 10424, cityName: 'DARNLEY ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 2770, cityName: 'DARWIN', countryName: 'AUSTRALIA' },
  { placeId: 10460, cityName: 'DELAMERE', countryName: 'AUSTRALIA' },
  { placeId: 10464, cityName: 'DELISSAVILLE', countryName: 'AUSTRALIA' },
  { placeId: 3780, cityName: 'DENILIQUIN', countryName: 'AUSTRALIA' },
  { placeId: 10476, cityName: 'DERBY', countryName: 'AUSTRALIA' },
  { placeId: 10484, cityName: 'DEVONPORT', countryName: 'AUSTRALIA' },
  { placeId: 3781, cityName: 'DOCHRA', countryName: 'AUSTRALIA' },
  { placeId: 3782, cityName: 'DONALD', countryName: 'AUSTRALIA' },
  { placeId: 10504, cityName: 'DOOMADGEE', countryName: 'AUSTRALIA' },
  { placeId: 3864, cityName: 'DUBBO', countryName: 'AUSTRALIA' },
  { placeId: 10532, cityName: 'DUNK ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 10543, cityName: 'DYSART', countryName: 'AUSTRALIA' },
  { placeId: 3828, cityName: 'EAST SALE', countryName: 'AUSTRALIA' },
  { placeId: 3783, cityName: 'ECHUCA', countryName: 'AUSTRALIA' },
  { placeId: 10572, cityName: 'EDINBURGH', countryName: 'AUSTRALIA' },
  { placeId: 10590, cityName: 'ELCHO I', countryName: 'AUSTRALIA' },
  { placeId: 10615, cityName: 'EMERALD', countryName: 'AUSTRALIA' },
  { placeId: 10629, cityName: 'ENOGGERA', countryName: 'AUSTRALIA' },
  { placeId: 13935, cityName: 'Erldunda', countryName: 'AUSTRALIA' },
  { placeId: 3784, cityName: 'ESPERANCE', countryName: 'AUSTRALIA' },
  { placeId: 3787, cityName: 'FITZROY CROSSING', countryName: 'AUSTRALIA' },
  { placeId: 10718, cityName: 'FLINDERS I', countryName: 'AUSTRALIA' },
  { placeId: 3785, cityName: 'FORBES', countryName: 'AUSTRALIA' },
  { placeId: 2764, cityName: 'FORREST', countryName: 'AUSTRALIA' },
  { placeId: 3786, cityName: 'FORRESTANIA', countryName: 'AUSTRALIA' },
  { placeId: 10746, cityName: 'FORTESCUE DAVE FORREST', countryName: 'AUSTRALIA' },
  { placeId: 10866, cityName: 'GARDEN I', countryName: 'AUSTRALIA' },
  { placeId: 10867, cityName: 'GARDEN POINT', countryName: 'AUSTRALIA' },
  { placeId: 10876, cityName: 'GAYNDAH', countryName: 'AUSTRALIA' },
  { placeId: 10878, cityName: 'GEORGETOWN', countryName: 'AUSTRALIA' },
  { placeId: 10883, cityName: 'GERALDTON', countryName: 'AUSTRALIA' },
  { placeId: 3792, cityName: 'GIBB RIVER', countryName: 'AUSTRALIA' },
  { placeId: 3793, cityName: 'GINGIN', countryName: 'AUSTRALIA' },
  { placeId: 10900, cityName: 'GLADSTONE', countryName: 'AUSTRALIA' },
  { placeId: 3795, cityName: 'GLEN INNES', countryName: 'AUSTRALIA' },
  { placeId: 2760, cityName: 'GOLD COAST', countryName: 'AUSTRALIA' },
  { placeId: 3791, cityName: 'GOLDEN GROVE', countryName: 'AUSTRALIA' },
  { placeId: 3788, cityName: 'GOODOOGA', countryName: 'AUSTRALIA' },
  { placeId: 10927, cityName: 'GOOLWA', countryName: 'AUSTRALIA' },
  { placeId: 10928, cityName: 'GOONDIWINDI', countryName: 'AUSTRALIA' },
  { placeId: 3794, cityName: 'GOULBURN', countryName: 'AUSTRALIA' },
  { placeId: 10941, cityName: 'GOVE', countryName: 'AUSTRALIA' },
  { placeId: 3790, cityName: 'GRAFTON', countryName: 'AUSTRALIA' },
  { placeId: 3796, cityName: 'GREAT LAKES', countryName: 'AUSTRALIA' },
  { placeId: 3798, cityName: 'GRIFFITH', countryName: 'AUSTRALIA' },
  { placeId: 11007, cityName: 'GROOTE EYLANDT', countryName: 'AUSTRALIA' },
  { placeId: 3789, cityName: 'GUNNEDAH', countryName: 'AUSTRALIA' },
  { placeId: 3800, cityName: 'HALLS CREEK', countryName: 'AUSTRALIA' },
  { placeId: 2762, cityName: 'HAMILTON I', countryName: 'AUSTRALIA' },
  { placeId: 3801, cityName: 'HAMILTON', countryName: 'AUSTRALIA' },
  { placeId: 3799, cityName: 'HAY', countryName: 'AUSTRALIA' },
  { placeId: 11132, cityName: 'HERVEY BAY', countryName: 'AUSTRALIA' },
  { placeId: 2766, cityName: 'HOBART', countryName: 'AUSTRALIA' },
  { placeId: 3867, cityName: 'HOLSWORTHY', countryName: 'AUSTRALIA' },
  { placeId: 11172, cityName: 'HOOKER CREEK', countryName: 'AUSTRALIA' },
  { placeId: 3804, cityName: 'HOPETOUN', countryName: 'AUSTRALIA' },
  { placeId: 11183, cityName: 'HORN ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3805, cityName: 'HORSHAM', countryName: 'AUSTRALIA' },
  { placeId: 3803, cityName: 'HOXTON PARK', countryName: 'AUSTRALIA' },
  { placeId: 11207, cityName: 'HUGHENDEN', countryName: 'AUSTRALIA' },
  { placeId: 11259, cityName: 'INNISFAIL', countryName: 'AUSTRALIA' },
  { placeId: 3806, cityName: 'INVERELL', countryName: 'AUSTRALIA' },
  { placeId: 11287, cityName: 'JABIRU', countryName: 'AUSTRALIA' },
  { placeId: 11288, cityName: 'JACINTH AMBROSIA', countryName: 'AUSTRALIA' },
  { placeId: 11292, cityName: 'JACKSON', countryName: 'AUSTRALIA' },
  { placeId: 3807, cityName: 'JERVIS BAY', countryName: 'AUSTRALIA' },
  { placeId: 11352, cityName: 'JULIA CREEK', countryName: 'AUSTRALIA' },
  { placeId: 3808, cityName: 'JUNDEE', countryName: 'AUSTRALIA' },
  { placeId: 3810, cityName: 'KALBARRI', countryName: 'AUSTRALIA' },
  { placeId: 2772, cityName: 'KALGOORLIE-BOULDER', countryName: 'AUSTRALIA' },
  { placeId: 11369, cityName: 'KALKGURUNG', countryName: 'AUSTRALIA' },
  { placeId: 3809, cityName: 'KALUMBURU', countryName: 'AUSTRALIA' },
  { placeId: 11372, cityName: 'KAMBALDA', countryName: 'AUSTRALIA' },
  { placeId: 11390, cityName: 'KARARA', countryName: 'AUSTRALIA' },
  { placeId: 11395, cityName: 'KARRATHA', countryName: 'AUSTRALIA' },
  { placeId: 11397, cityName: 'KARUMBA', countryName: 'AUSTRALIA' },
  { placeId: 3813, cityName: 'KATANNING', countryName: 'AUSTRALIA' },
  { placeId: 3812, cityName: 'KEMPSEY', countryName: 'AUSTRALIA' },
  { placeId: 3811, cityName: 'KERANG', countryName: 'AUSTRALIA' },
  { placeId: 11456, cityName: 'KIDSTON', countryName: 'AUSTRALIA' },
  { placeId: 11467, cityName: 'KIMBA', countryName: 'AUSTRALIA' },
  { placeId: 11476, cityName: 'KING I', countryName: 'AUSTRALIA' },
  { placeId: 11479, cityName: 'KINGAROY', countryName: 'AUSTRALIA' },
  { placeId: 11482, cityName: 'KINGSCOTE', countryName: 'AUSTRALIA' },
  { placeId: 11529, cityName: 'KOWANYAMA', countryName: 'AUSTRALIA' },
  { placeId: 11536, cityName: 'KUBIN', countryName: 'AUSTRALIA' },
  { placeId: 3854, cityName: 'KUNUNURRA', countryName: 'AUSTRALIA' },
  { placeId: 3815, cityName: 'LAKE CARGELLIGO', countryName: 'AUSTRALIA' },
  { placeId: 11594, cityName: 'LAKE EVELLA', countryName: 'AUSTRALIA' },
  { placeId: 3821, cityName: 'LATROBE VALLEY', countryName: 'AUSTRALIA' },
  { placeId: 2767, cityName: 'LAUNCESTON', countryName: 'AUSTRALIA' },
  { placeId: 11639, cityName: 'LAVARACK', countryName: 'AUSTRALIA' },
  { placeId: 3797, cityName: 'LAVERTON', countryName: 'AUSTRALIA' },
  { placeId: 2773, cityName: 'LEARMONTH', countryName: 'AUSTRALIA' },
  { placeId: 11660, cityName: 'LEIGH CREEK', countryName: 'AUSTRALIA' },
  { placeId: 3814, cityName: 'LEINSTER', countryName: 'AUSTRALIA' },
  { placeId: 11668, cityName: 'LEONGATHA', countryName: 'AUSTRALIA' },
  { placeId: 3816, cityName: 'LEONORA', countryName: 'AUSTRALIA' },
  { placeId: 3820, cityName: 'LIGHTNING RIDGE', countryName: 'AUSTRALIA' },
  { placeId: 3817, cityName: 'LILYDALE', countryName: 'AUSTRALIA' },
  { placeId: 3818, cityName: 'LISMORE', countryName: 'AUSTRALIA' },
  { placeId: 11719, cityName: 'LOCKHART RIVER', countryName: 'AUSTRALIA' },
  { placeId: 11731, cityName: 'LONGREACH', countryName: 'AUSTRALIA' },
  { placeId: 11737, cityName: 'LORD HOWE ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 11755, cityName: 'LOXTON', countryName: 'AUSTRALIA' },
  { placeId: 11774, cityName: 'MABUIAG ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 11779, cityName: 'MACKAY', countryName: 'AUSTRALIA' },
  { placeId: 3833, cityName: 'MAITLAND', countryName: 'AUSTRALIA' },
  { placeId: 3824, cityName: 'MALLACOOTA', countryName: 'AUSTRALIA' },
  { placeId: 3835, cityName: 'MANGALORE', countryName: 'AUSTRALIA' },
  { placeId: 11819, cityName: 'MANINGRIDA', countryName: 'AUSTRALIA' },
  { placeId: 3831, cityName: 'MANJIMUP', countryName: 'AUSTRALIA' },
  { placeId: 11850, cityName: 'MAREEBA', countryName: 'AUSTRALIA' },
  { placeId: 3829, cityName: 'MARGARET RIVER', countryName: 'AUSTRALIA' },
  { placeId: 3823, cityName: 'MARYBOROUGH', countryName: 'AUSTRALIA' },
  { placeId: 11924, cityName: 'MCARTHUR RIVER MINE', countryName: 'AUSTRALIA' },
  { placeId: 3826, cityName: 'MEEKATHARRA', countryName: 'AUSTRALIA' },
  { placeId: 2768, cityName: 'MELBOURNE', countryName: 'AUSTRALIA' },
  { placeId: 3827, cityName: 'MERIMBULA', countryName: 'AUSTRALIA' },
  { placeId: 3830, cityName: 'MILDURA', countryName: 'AUSTRALIA' },
  { placeId: 11983, cityName: 'MILINGIMBI', countryName: 'AUSTRALIA' },
  { placeId: 11987, cityName: 'MILLICENT', countryName: 'AUSTRALIA' },
  { placeId: 12001, cityName: 'MINJILANG', countryName: 'AUSTRALIA' },
  { placeId: 12004, cityName: 'MINNIPA', countryName: 'AUSTRALIA' },
  { placeId: 12012, cityName: 'MITTA MITTA', countryName: 'AUSTRALIA' },
  { placeId: 12056, cityName: 'MOOMBA', countryName: 'AUSTRALIA' },
  { placeId: 12067, cityName: 'MORANBAH', countryName: 'AUSTRALIA' },
  { placeId: 3839, cityName: 'MORAWA', countryName: 'AUSTRALIA' },
  { placeId: 3837, cityName: 'MOREE', countryName: 'AUSTRALIA' },
  { placeId: 12073, cityName: 'MORNINGTON I', countryName: 'AUSTRALIA' },
  { placeId: 3840, cityName: 'MORUYA', countryName: 'AUSTRALIA' },
  { placeId: 12091, cityName: 'MOUNT GAMBIER', countryName: 'AUSTRALIA' },
  { placeId: 3836, cityName: 'MOUNT MAGNET', countryName: 'AUSTRALIA' },
  { placeId: 3802, cityName: 'MT HOTHAM', countryName: 'AUSTRALIA' },
  { placeId: 12106, cityName: 'MT ISA', countryName: 'AUSTRALIA' },
  { placeId: 3834, cityName: 'MT. KEITH', countryName: 'AUSTRALIA' },
  { placeId: 3825, cityName: 'MUDGEE', countryName: 'AUSTRALIA' },
  { placeId: 12126, cityName: 'MURRAY FIELD', countryName: 'AUSTRALIA' },
  { placeId: 12127, cityName: 'MURRAY/MER ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3832, cityName: 'MURRIN MURRIN', countryName: 'AUSTRALIA' },
  { placeId: 12165, cityName: 'NARACOORTE', countryName: 'AUSTRALIA' },
  { placeId: 3959, cityName: 'NARRABRI', countryName: 'AUSTRALIA' },
  { placeId: 3958, cityName: 'NARRANDERA', countryName: 'AUSTRALIA' },
  { placeId: 3961, cityName: 'NARROMINE', countryName: 'AUSTRALIA' },
  { placeId: 3843, cityName: 'NEWMAN', countryName: 'AUSTRALIA' },
  { placeId: 12222, cityName: 'NGUKURR', countryName: 'AUSTRALIA' },
  { placeId: 3960, cityName: 'NHILL', countryName: 'AUSTRALIA' },
  { placeId: 3769, cityName: 'NIFTY', countryName: 'AUSTRALIA' },
  { placeId: 12243, cityName: 'NORFOLK I', countryName: 'AUSTRALIA' },
  { placeId: 12246, cityName: 'NORMANTON', countryName: 'AUSTRALIA' },
  { placeId: 3842, cityName: 'NORSEMAN', countryName: 'AUSTRALIA' },
  { placeId: 12263, cityName: 'NORTHERN PENINSULA', countryName: 'AUSTRALIA' },
  { placeId: 3869, cityName: 'NOWRA', countryName: 'AUSTRALIA' },
  { placeId: 12283, cityName: 'NUMBULWAR', countryName: 'AUSTRALIA' },
  { placeId: 3844, cityName: 'NYNGAN', countryName: 'AUSTRALIA' },
  { placeId: 12291, cityName: 'OAKEY', countryName: 'AUSTRALIA' },
  { placeId: 12307, cityName: 'OENPELLI', countryName: 'AUSTRALIA' },
  { placeId: 12333, cityName: 'OLYMPIC DAM', countryName: 'AUSTRALIA' },
  { placeId: 12341, cityName: 'ONSLOW', countryName: 'AUSTRALIA' },
  { placeId: 12346, cityName: 'OODNADATTA', countryName: 'AUSTRALIA' },
  { placeId: 3846, cityName: 'ORANGE', countryName: 'AUSTRALIA' },
  { placeId: 3845, cityName: 'ORBOST', countryName: 'AUSTRALIA' },
  { placeId: 12366, cityName: 'OSBORNE MINE', countryName: 'AUSTRALIA' },
  { placeId: 12416, cityName: 'PALM ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3847, cityName: 'PARABURDOO', countryName: 'AUSTRALIA' },
  { placeId: 3853, cityName: 'PARKES', countryName: 'AUSTRALIA' },
  { placeId: 2771, cityName: 'PEARCE', countryName: 'AUSTRALIA' },
  { placeId: 2774, cityName: 'PERTH', countryName: 'AUSTRALIA' },
  { placeId: 3851, cityName: 'PHILLIP ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3855, cityName: 'PLUTONIC', countryName: 'AUSTRALIA' },
  { placeId: 3838, cityName: 'POINT COOK', countryName: 'AUSTRALIA' },
  { placeId: 3850, cityName: 'POLO FLAT', countryName: 'AUSTRALIA' },
  { placeId: 3848, cityName: 'POONCARIE', countryName: 'AUSTRALIA' },
  { placeId: 12586, cityName: 'PORMPURAAW', countryName: 'AUSTRALIA' },
  { placeId: 12591, cityName: 'PORT AUGUSTA', countryName: 'AUSTRALIA' },
  { placeId: 12597, cityName: 'PORT HEDLAND', countryName: 'AUSTRALIA' },
  { placeId: 12603, cityName: 'PORT KEATS', countryName: 'AUSTRALIA' },
  { placeId: 12605, cityName: 'PORT LINCOLN', countryName: 'AUSTRALIA' },
  { placeId: 3856, cityName: 'PORT MACQUARIE', countryName: 'AUSTRALIA' },
  { placeId: 12609, cityName: 'PORT PIRIE', countryName: 'AUSTRALIA' },
  { placeId: 3857, cityName: 'PORTLAND', countryName: 'AUSTRALIA' },
  { placeId: 12656, cityName: 'PROMINENT HILL', countryName: 'AUSTRALIA' },
  { placeId: 12657, cityName: 'PROSERPINE', countryName: 'AUSTRALIA' },
  { placeId: 3852, cityName: 'PUCKAPUNYAL', countryName: 'AUSTRALIA' },
  { placeId: 3859, cityName: 'QUEENSTOWN', countryName: 'AUSTRALIA' },
  { placeId: 12703, cityName: 'QUILPIE', countryName: 'AUSTRALIA' },
  { placeId: 3858, cityName: 'QUIRINDI', countryName: 'AUSTRALIA' },
  { placeId: 12722, cityName: 'RAMINGINING', countryName: 'AUSTRALIA' },
  { placeId: 3841, cityName: 'RAVENSTHORPE', countryName: 'AUSTRALIA' },
  { placeId: 12755, cityName: 'REDCLIFFE', countryName: 'AUSTRALIA' },
  { placeId: 12769, cityName: 'RENMARK', countryName: 'AUSTRALIA' },
  { placeId: 2776, cityName: 'RICHMOND', countryName: 'AUSTRALIA' },
  { placeId: 3860, cityName: 'ROBINVALE', countryName: 'AUSTRALIA' },
  { placeId: 12827, cityName: 'ROCKHAMPTON', countryName: 'AUSTRALIA' },
  { placeId: 12845, cityName: 'ROMA', countryName: 'AUSTRALIA' },
  { placeId: 12857, cityName: 'ROTTNEST I', countryName: 'AUSTRALIA' },
  { placeId: 12889, cityName: 'SAIBAI ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3871, cityName: 'SAINT ARNAUD', countryName: 'AUSTRALIA' },
  { placeId: 13936, cityName: 'Saint Paul\'s Mission', countryName: 'AUSTRALIA' },
  { placeId: 12983, cityName: 'SCHERGER', countryName: 'AUSTRALIA' },
  { placeId: 3862, cityName: 'SCONE', countryName: 'AUSTRALIA' },
  { placeId: 3868, cityName: 'SEA LAKE', countryName: 'AUSTRALIA' },
  { placeId: 13037, cityName: 'SHARK BAY', countryName: 'AUSTRALIA' },
  { placeId: 3865, cityName: 'SHAY GAP', countryName: 'AUSTRALIA' },
  { placeId: 3866, cityName: 'SHEPPARTON', countryName: 'AUSTRALIA' },
  { placeId: 13115, cityName: 'SMITHTON', countryName: 'AUSTRALIA' },
  { placeId: 13117, cityName: 'SNAKE BAY', countryName: 'AUSTRALIA' },
  { placeId: 13139, cityName: 'SOUTH GOULBURN I', countryName: 'AUSTRALIA' },
  { placeId: 3863, cityName: 'SOUTHERN CROSS', countryName: 'AUSTRALIA' },
  { placeId: 13148, cityName: 'SOUTHPORT', countryName: 'AUSTRALIA' },
  { placeId: 13184, cityName: 'ST GEORGE', countryName: 'AUSTRALIA' },
  { placeId: 3872, cityName: 'ST HELENS', countryName: 'AUSTRALIA' },
  { placeId: 13240, cityName: 'STANTHORPE', countryName: 'AUSTRALIA' },
  { placeId: 3876, cityName: 'STAWELL', countryName: 'AUSTRALIA' },
  { placeId: 13276, cityName: 'STRAHAN', countryName: 'AUSTRALIA' },
  { placeId: 13282, cityName: 'STREAKY BAY', countryName: 'AUSTRALIA' },
  { placeId: 3870, cityName: 'SUNRISE DAM', countryName: 'AUSTRALIA' },
  { placeId: 13311, cityName: 'SUNSHINE COAST', countryName: 'AUSTRALIA' },
  { placeId: 3875, cityName: 'SWAN HILL', countryName: 'AUSTRALIA' },
  { placeId: 2777, cityName: 'SYDNEY', countryName: 'AUSTRALIA' },
  { placeId: 3873, cityName: 'TAMWORTH', countryName: 'AUSTRALIA' },
  { placeId: 3968, cityName: 'TAREE', countryName: 'AUSTRALIA' },
  { placeId: 13368, cityName: 'TAROOM', countryName: 'AUSTRALIA' },
  { placeId: 3962, cityName: 'TELFER', countryName: 'AUSTRALIA' },
  { placeId: 3963, cityName: 'TEMORA', countryName: 'AUSTRALIA' },
  { placeId: 13391, cityName: 'TENNANT CREEK', countryName: 'AUSTRALIA' },
  { placeId: 13406, cityName: 'THANGOOL', countryName: 'AUSTRALIA' },
  { placeId: 13407, cityName: 'THARGOMINDAH', countryName: 'AUSTRALIA' },
  { placeId: 13409, cityName: 'THE GRANITES', countryName: 'AUSTRALIA' },
  { placeId: 13410, cityName: 'THE MONUMENT', countryName: 'AUSTRALIA' },
  { placeId: 3964, cityName: 'TIBOOBURRA', countryName: 'AUSTRALIA' },
  { placeId: 13439, cityName: 'TINDAL', countryName: 'AUSTRALIA' },
  { placeId: 3966, cityName: 'TOCUMWAL', countryName: 'AUSTRALIA' },
  { placeId: 13461, cityName: 'TOOWOOMBA', countryName: 'AUSTRALIA' },
  { placeId: 3967, cityName: 'TOTTENHAM', countryName: 'AUSTRALIA' },
  { placeId: 2763, cityName: 'TOWNSVILLE', countryName: 'AUSTRALIA' },
  { placeId: 13484, cityName: 'TREPELL', countryName: 'AUSTRALIA' },
  { placeId: 13489, cityName: 'TROUGHTON I', countryName: 'AUSTRALIA' },
  { placeId: 13495, cityName: 'TRUSCOTT-MUNGALALU', countryName: 'AUSTRALIA' },
  { placeId: 13511, cityName: 'TUMBY BAY', countryName: 'AUSTRALIA' },
  { placeId: 3965, cityName: 'TUMUT', countryName: 'AUSTRALIA' },
  { placeId: 13606, cityName: 'VICTORIA RIVER DOWNS', countryName: 'AUSTRALIA' },
  { placeId: 3874, cityName: 'WAGGA WAGGA', countryName: 'AUSTRALIA' },
  { placeId: 13641, cityName: 'WAIKERIE', countryName: 'AUSTRALIA' },
  { placeId: 3882, cityName: 'WALGETT', countryName: 'AUSTRALIA' },
  { placeId: 3880, cityName: 'WANGARATTA', countryName: 'AUSTRALIA' },
  { placeId: 3878, cityName: 'WARBURTON', countryName: 'AUSTRALIA' },
  { placeId: 13661, cityName: 'WARRABER ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3881, cityName: 'WARRACKNABEAL', countryName: 'AUSTRALIA' },
  { placeId: 3885, cityName: 'WARREN', countryName: 'AUSTRALIA' },
  { placeId: 3877, cityName: 'WARRNAMBOOL', countryName: 'AUSTRALIA' },
  { placeId: 13669, cityName: 'WARWICK', countryName: 'AUSTRALIA' },
  { placeId: 13697, cityName: 'WAVE HILL', countryName: 'AUSTRALIA' },
  { placeId: 13710, cityName: 'WEIPA', countryName: 'AUSTRALIA' },
  { placeId: 3746, cityName: 'WEST ANGELAS', countryName: 'AUSTRALIA' },
  { placeId: 3819, cityName: 'WEST PERTH', countryName: 'AUSTRALIA' },
  { placeId: 3886, cityName: 'WEST SALE', countryName: 'AUSTRALIA' },
  { placeId: 3887, cityName: 'WEST WYALONG', countryName: 'AUSTRALIA' },
  { placeId: 13756, cityName: 'WHYALLA', countryName: 'AUSTRALIA' },
  { placeId: 13764, cityName: 'WILLIAM CREEK', countryName: 'AUSTRALIA' },
  { placeId: 13770, cityName: 'WILLIAMSON', countryName: 'AUSTRALIA' },
  { placeId: 2778, cityName: 'WILLIAMTOWN', countryName: 'AUSTRALIA' },
  { placeId: 3883, cityName: 'WILUNA', countryName: 'AUSTRALIA' },
  { placeId: 3879, cityName: 'WINDARLING', countryName: 'AUSTRALIA' },
  { placeId: 13789, cityName: 'WINDORAH', countryName: 'AUSTRALIA' },
  { placeId: 13806, cityName: 'WINTON', countryName: 'AUSTRALIA' },
  { placeId: 3884, cityName: 'WOLLONGONG', countryName: 'AUSTRALIA' },
  { placeId: 13816, cityName: 'WOODIE WOODIE', countryName: 'AUSTRALIA' },
  { placeId: 13821, cityName: 'WOOMERA', countryName: 'AUSTRALIA' },
  { placeId: 13829, cityName: 'WUDINNA', countryName: 'AUSTRALIA' },
  { placeId: 3888, cityName: 'WYCHEPROOF', countryName: 'AUSTRALIA' },
  { placeId: 3889, cityName: 'WYNDHAM', countryName: 'AUSTRALIA' },
  { placeId: 13833, cityName: 'WYNYARD', countryName: 'AUSTRALIA' },
  { placeId: 13844, cityName: 'YAM ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3891, cityName: 'YARRAM', countryName: 'AUSTRALIA' },
  { placeId: 3892, cityName: 'YARRAWONGA', countryName: 'AUSTRALIA' },
  { placeId: 13867, cityName: 'YORKE ISLAND', countryName: 'AUSTRALIA' },
  { placeId: 3890, cityName: 'YOUNG', countryName: 'AUSTRALIA' },
  { placeId: 4092, cityName: 'ALTLICHTENWARTH', countryName: 'AUSTRIA' },
  { placeId: 2069, cityName: 'DOBERSBERG', countryName: 'AUSTRIA' },
  { placeId: 2098, cityName: 'EFERDING', countryName: 'AUSTRIA' },
  { placeId: 2090, cityName: 'FELDKIRCHEN', countryName: 'AUSTRIA' },
  { placeId: 2091, cityName: 'FERLACH', countryName: 'AUSTRIA' },
  { placeId: 2099, cityName: 'FREISTADT', countryName: 'AUSTRIA' },
  { placeId: 2092, cityName: 'FRIESACH', countryName: 'AUSTRIA' },
  { placeId: 2076, cityName: 'FURSTENFELD', countryName: 'AUSTRIA' },
  { placeId: 2107, cityName: 'GMUNDEN', countryName: 'AUSTRIA' },
  { placeId: 2110, cityName: 'GRAZ', countryName: 'AUSTRIA' },
  { placeId: 2101, cityName: 'HB HOFKIRCHEN', countryName: 'AUSTRIA' },
  { placeId: 2086, cityName: 'HOHENEMS', countryName: 'AUSTRIA' },
  { placeId: 2111, cityName: 'INNSBRUCK', countryName: 'AUSTRIA' },
  { placeId: 2079, cityName: 'KAPFENBERG', countryName: 'AUSTRIA' },
  { placeId: 2112, cityName: 'KLAGENFURT', countryName: 'AUSTRIA' },
  { placeId: 2071, cityName: 'KREMS', countryName: 'AUSTRIA' },
  { placeId: 2088, cityName: 'KUFSTEIN', countryName: 'AUSTRIA' },
  { placeId: 2080, cityName: 'LANZEN', countryName: 'AUSTRIA' },
  { placeId: 2093, cityName: 'LIENZ', countryName: 'AUSTRIA' },
  { placeId: 2104, cityName: 'LINZ OST', countryName: 'AUSTRIA' },
  { placeId: 2113, cityName: 'LINZ', countryName: 'AUSTRIA' },
  { placeId: 2081, cityName: 'MARIAZELL', countryName: 'AUSTRIA' },
  { placeId: 2109, cityName: 'MAUTERNDORF', countryName: 'AUSTRIA' },
  { placeId: 2094, cityName: 'MAYERHOFEN', countryName: 'AUSTRIA' },
  { placeId: 2103, cityName: 'MICHELDORF', countryName: 'AUSTRIA' },
  { placeId: 2082, cityName: 'NIEDEROBLARN', countryName: 'AUSTRIA' },
  { placeId: 2095, cityName: 'NOTSCH IM GAILTAL', countryName: 'AUSTRIA' },
  { placeId: 2068, cityName: 'OTTENSCHLAG', countryName: 'AUSTRIA' },
  { placeId: 2083, cityName: 'PINKAFELD', countryName: 'AUSTRIA' },
  { placeId: 2077, cityName: 'PUNITZ-GUSSING', countryName: 'AUSTRIA' },
  { placeId: 2089, cityName: 'REUTTE', countryName: 'AUSTRIA' },
  { placeId: 2102, cityName: 'RIED', countryName: 'AUSTRIA' },
  { placeId: 2114, cityName: 'SALZBURG', countryName: 'AUSTRIA' },
  { placeId: 2105, cityName: 'SCHARDING-SUBEN', countryName: 'AUSTRIA' },
  { placeId: 2097, cityName: 'SCHARNSTEIN', countryName: 'AUSTRIA' },
  { placeId: 2106, cityName: 'SEITENSTETTEN', countryName: 'AUSTRIA' },
  { placeId: 2073, cityName: 'SPITZERBERG', countryName: 'AUSTRIA' },
  { placeId: 2100, cityName: 'ST GEORGEN/YBBSFELD', countryName: 'AUSTRIA' },
  { placeId: 2087, cityName: 'ST JOHANN', countryName: 'AUSTRIA' },
  { placeId: 2074, cityName: 'STOCKERAU', countryName: 'AUSTRIA' },
  { placeId: 2084, cityName: 'TIMMERSDORF', countryName: 'AUSTRIA' },
  { placeId: 2078, cityName: 'TRIEBEN', countryName: 'AUSTRIA' },
  { placeId: 2115, cityName: 'VIENNA', countryName: 'AUSTRIA' },
  { placeId: 2070, cityName: 'VOLTENDORF', countryName: 'AUSTRIA' },
  { placeId: 2075, cityName: 'VOSLAU', countryName: 'AUSTRIA' },
  { placeId: 2085, cityName: 'WEIZ', countryName: 'AUSTRIA' },
  { placeId: 2108, cityName: 'WELS', countryName: 'AUSTRIA' },
  { placeId: 2072, cityName: 'WIENER NEUSTADT/OST', countryName: 'AUSTRIA' },
  { placeId: 2096, cityName: 'WOLFSBERG', countryName: 'AUSTRIA' },
  { placeId: 2116, cityName: 'ZELL AM SEE', countryName: 'AUSTRIA' },
  { placeId: 2117, cityName: 'ZELTWEG', countryName: 'AUSTRIA' },
  { placeId: 2568, cityName: 'BAKU', countryName: 'AZERBAIJAN' },
  { placeId: 10838, cityName: 'GABALA', countryName: 'AZERBAIJAN' },
  { placeId: 2569, cityName: 'GANJA', countryName: 'AZERBAIJAN' },
  { placeId: 11664, cityName: 'LENKORAN', countryName: 'AZERBAIJAN' },
  { placeId: 2570, cityName: 'NAKHCHIVAN', countryName: 'AZERBAIJAN' },
  { placeId: 13875, cityName: 'ZAGATALA', countryName: 'AZERBAIJAN' },
  { placeId: 4971, cityName: 'CORVO', countryName: 'AZORES' },
  { placeId: 2128, cityName: 'FLORES', countryName: 'AZORES' },
  { placeId: 2130, cityName: 'GRACIOSA', countryName: 'AZORES' },
  { placeId: 2131, cityName: 'HORTA', countryName: 'AZORES' },
  { placeId: 2134, cityName: 'LAJES', countryName: 'AZORES' },
  { placeId: 2144, cityName: 'PICO', countryName: 'AZORES' },
  { placeId: 2143, cityName: 'PONTA DELGADA', countryName: 'AZORES' },
  { placeId: 2119, cityName: 'SANTA MARIA', countryName: 'AZORES' },
  { placeId: 2152, cityName: 'SAO JORGE', countryName: 'AZORES' },
  { placeId: 9138, cityName: 'ABACO I', countryName: 'BAHAMAS' },
  { placeId: 4012, cityName: 'ACKLINS I', countryName: 'BAHAMAS' },
  { placeId: 4137, cityName: 'ANDROS I', countryName: 'BAHAMAS' },
  { placeId: 4443, cityName: 'BERRY I', countryName: 'BAHAMAS' },
  { placeId: 4475, cityName: 'BIMINI I', countryName: 'BAHAMAS' },
  { placeId: 4757, cityName: 'CAT ISLAND', countryName: 'BAHAMAS' },
  { placeId: 4858, cityName: 'CHUB CAY', countryName: 'BAHAMAS' },
  { placeId: 4994, cityName: 'CROOKED I', countryName: 'BAHAMAS' },
  { placeId: 5302, cityName: 'ELEUTHERA I', countryName: 'BAHAMAS' },
  { placeId: 5301, cityName: 'ELEUTHERA', countryName: 'BAHAMAS' },
  { placeId: 5346, cityName: 'EXUMA', countryName: 'BAHAMAS' },
  { placeId: 2836, cityName: 'FREEPORT', countryName: 'BAHAMAS' },
  { placeId: 5696, cityName: 'GREAT INAGUA I', countryName: 'BAHAMAS' },
  { placeId: 6610, cityName: 'LONG I', countryName: 'BAHAMAS' },
  { placeId: 6885, cityName: 'MAYAGUANA I', countryName: 'BAHAMAS' },
  { placeId: 2325, cityName: 'NASSAU', countryName: 'BAHAMAS' },
  { placeId: 7666, cityName: 'PITTSTOWN POINT', countryName: 'BAHAMAS' },
  { placeId: 12715, cityName: 'RAGGED ISLAND', countryName: 'BAHAMAS' },
  { placeId: 7982, cityName: 'RUM CAY', countryName: 'BAHAMAS' },
  { placeId: 8065, cityName: 'SAN SALVADOR', countryName: 'BAHAMAS' },
  { placeId: 8455, cityName: 'STANIEL CAY', countryName: 'BAHAMAS' },
  { placeId: 2342, cityName: 'BAHRAIN', countryName: 'BAHRAIN' },
  { placeId: 4349, cityName: 'BARISAL', countryName: 'BANGLADESH' },
  { placeId: 2680, cityName: 'CHITTAGONG', countryName: 'BANGLADESH' },
  { placeId: 4006, cityName: 'COMILLA', countryName: 'BANGLADESH' },
  { placeId: 4982, cityName: 'COX\'S BAZAR', countryName: 'BANGLADESH' },
  { placeId: 2681, cityName: 'DHAKA', countryName: 'BANGLADESH' },
  { placeId: 5995, cityName: 'ISHURDI', countryName: 'BANGLADESH' },
  { placeId: 6058, cityName: 'JESSORE', countryName: 'BANGLADESH' },
  { placeId: 7842, cityName: 'RAJSHAHI', countryName: 'BANGLADESH' },
  { placeId: 8003, cityName: 'SAIDPUR', countryName: 'BANGLADESH' },
  { placeId: 8211, cityName: 'SHAMSHERNAGAR', countryName: 'BANGLADESH' },
  { placeId: 8339, cityName: 'SYLHET', countryName: 'BANGLADESH' },
  { placeId: 2538, cityName: 'BARBADOS', countryName: 'BARBADOS' },
  { placeId: 2610, cityName: 'BREST', countryName: 'BELARUS' },
  { placeId: 2614, cityName: 'GRODNO', countryName: 'BELARUS' },
  { placeId: 2611, cityName: 'HOMIEL', countryName: 'BELARUS' },
  { placeId: 2616, cityName: 'MAHILIOU', countryName: 'BELARUS' },
  { placeId: 2615, cityName: 'MINSK', countryName: 'BELARUS' },
  { placeId: 2612, cityName: 'VICIEBSK', countryName: 'BELARUS' },
  { placeId: 159, cityName: 'AMOUGIES', countryName: 'BELGIUM' },
  { placeId: 160, cityName: 'ANTWERP', countryName: 'BELGIUM' },
  { placeId: 172, cityName: 'BALEN-KEIHEUVEL', countryName: 'BELGIUM' },
  { placeId: 161, cityName: 'BEAUVECHAIN', countryName: 'BELGIUM' },
  { placeId: 165, cityName: 'BERTRIX', countryName: 'BELGIUM' },
  { placeId: 164, cityName: 'BRASSCHAAT', countryName: 'BELGIUM' },
  { placeId: 163, cityName: 'BRUSSELS', countryName: 'BELGIUM' },
  { placeId: 4778, cityName: 'CERFONTAINE', countryName: 'BELGIUM' },
  { placeId: 166, cityName: 'CHARLEROI', countryName: 'BELGIUM' },
  { placeId: 167, cityName: 'CHIEVRES', countryName: 'BELGIUM' },
  { placeId: 169, cityName: 'FLORENNES', countryName: 'BELGIUM' },
  { placeId: 189, cityName: 'GENK', countryName: 'BELGIUM' },
  { placeId: 5551, cityName: 'GERAARDSBERGEN', countryName: 'BELGIUM' },
  { placeId: 182, cityName: 'GOETSENHOVEN', countryName: 'BELGIUM' },
  { placeId: 170, cityName: 'GRIMBERGEN', countryName: 'BELGIUM' },
  { placeId: 187, cityName: 'HASSELT', countryName: 'BELGIUM' },
  { placeId: 171, cityName: 'HOEVENEN', countryName: 'BELGIUM' },
  { placeId: 162, cityName: 'KLEINE-BROGEL', countryName: 'BELGIUM' },
  { placeId: 168, cityName: 'KOKSIJDE', countryName: 'BELGIUM' },
  { placeId: 173, cityName: 'KORTRIJK-WEVELGEM', countryName: 'BELGIUM' },
  { placeId: 6529, cityName: 'LEOPOLDSBURG', countryName: 'BELGIUM' },
  { placeId: 174, cityName: 'LIEGE', countryName: 'BELGIUM' },
  { placeId: 175, cityName: 'MOORSELE', countryName: 'BELGIUM' },
  { placeId: 176, cityName: 'NAMUR', countryName: 'BELGIUM' },
  { placeId: 177, cityName: 'OSTEND-BRUGGE', countryName: 'BELGIUM' },
  { placeId: 7515, cityName: 'SAINT-GHISLAIN', countryName: 'BELGIUM' },
  { placeId: 180, cityName: 'SPA', countryName: 'BELGIUM' },
  { placeId: 178, cityName: 'ST HUBERT', countryName: 'BELGIUM' },
  { placeId: 181, cityName: 'ST TRUIDEN', countryName: 'BELGIUM' },
  { placeId: 184, cityName: 'TOURNAI', countryName: 'BELGIUM' },
  { placeId: 185, cityName: 'URSEL', countryName: 'BELGIUM' },
  { placeId: 183, cityName: 'VERVIERS', countryName: 'BELGIUM' },
  { placeId: 186, cityName: 'WEELDE', countryName: 'BELGIUM' },
  { placeId: 188, cityName: 'ZOERSEL', countryName: 'BELGIUM' },
  { placeId: 179, cityName: 'ZUTENDAAL', countryName: 'BELGIUM' },
  { placeId: 2326, cityName: 'BELIZE CITY', countryName: 'BELIZE' },
  { placeId: 8061, cityName: 'SAN PEDRO, BELIZE', countryName: 'BELIZE' },
  { placeId: 140, cityName: 'COTONOU', countryName: 'BENIN' },
  { placeId: 5641, cityName: 'KANDI', countryName: 'BENIN' },
  { placeId: 7197, cityName: 'NATITINGOU', countryName: 'BENIN' },
  { placeId: 7554, cityName: 'PARAKOU', countryName: 'BENIN' },
  { placeId: 7695, cityName: 'PORGA', countryName: 'BENIN' },
  { placeId: 8135, cityName: 'SAVE', countryName: 'BENIN' },
  { placeId: 2552, cityName: 'BERMUDA IS', countryName: 'BERMUDA' },
  { placeId: 2833, cityName: 'PARO INTL', countryName: 'BHUTAN' },
  { placeId: 7563, cityName: 'PARO', countryName: 'BHUTAN' },
  { placeId: 4169, cityName: 'APOLO', countryName: 'BOLIVIA' },
  { placeId: 4234, cityName: 'ASCENCION DE GUARAYOS', countryName: 'BOLIVIA' },
  { placeId: 4441, cityName: 'BERMEJO', countryName: 'BOLIVIA' },
  { placeId: 4700, cityName: 'CAMIRI', countryName: 'BOLIVIA' },
  { placeId: 4788, cityName: 'CHARANA', countryName: 'BOLIVIA' },
  { placeId: 4909, cityName: 'COBIJA', countryName: 'BOLIVIA' },
  { placeId: 2519, cityName: 'COCHABAMBA', countryName: 'BOLIVIA' },
  { placeId: 4934, cityName: 'CONCEPCION', countryName: 'BOLIVIA' },
  { placeId: 4954, cityName: 'COPACABANA', countryName: 'BOLIVIA' },
  { placeId: 5732, cityName: 'GUAYARAMERIN', countryName: 'BOLIVIA' },
  { placeId: 2520, cityName: 'LA PAZ', countryName: 'BOLIVIA' },
  { placeId: 6408, cityName: 'LAJA', countryName: 'BOLIVIA' },
  { placeId: 6707, cityName: 'MAGDALENA', countryName: 'BOLIVIA' },
  { placeId: 7042, cityName: 'MONTEAGUDO', countryName: 'BOLIVIA' },
  { placeId: 7428, cityName: 'ORURO', countryName: 'BOLIVIA' },
  { placeId: 7746, cityName: 'POTOSI', countryName: 'BOLIVIA' },
  { placeId: 7793, cityName: 'PUERTO SUAREZ', countryName: 'BOLIVIA' },
  { placeId: 7904, cityName: 'REYES', countryName: 'BOLIVIA' },
  { placeId: 7907, cityName: 'RIBERALTA', countryName: 'BOLIVIA' },
  { placeId: 7930, cityName: 'ROBORE', countryName: 'BOLIVIA' },
  { placeId: 7986, cityName: 'RURRENABAQUE', countryName: 'BOLIVIA' },
  { placeId: 8032, cityName: 'SAN BORJA', countryName: 'BOLIVIA' },
  { placeId: 8043, cityName: 'SAN IGNACIO DE MOXOS', countryName: 'BOLIVIA' },
  { placeId: 8044, cityName: 'SAN IGNACIO DE VELASCO', countryName: 'BOLIVIA' },
  { placeId: 8046, cityName: 'SAN JAVIER', countryName: 'BOLIVIA' },
  { placeId: 8047, cityName: 'SAN JOAQUIN', countryName: 'BOLIVIA' },
  { placeId: 8050, cityName: 'SAN JOSE DE CHIQUITOS', countryName: 'BOLIVIA' },
  { placeId: 8056, cityName: 'SAN MATIAS', countryName: 'BOLIVIA' },
  { placeId: 8064, cityName: 'SAN RAMON', countryName: 'BOLIVIA' },
  { placeId: 8084, cityName: 'SANTA ANA DEL YACUMA', countryName: 'BOLIVIA' },
  { placeId: 2522, cityName: 'SANTA CRUZ', countryName: 'BOLIVIA' },
  { placeId: 8104, cityName: 'SANTA ROSA DEL YACUMA', countryName: 'BOLIVIA' },
  { placeId: 8163, cityName: 'SUCRE', countryName: 'BOLIVIA' },
  { placeId: 8587, cityName: 'TARIJA', countryName: 'BOLIVIA' },
  { placeId: 2521, cityName: 'TRINIDAD', countryName: 'BOLIVIA' },
  { placeId: 8171, cityName: 'VALLEGRANDE', countryName: 'BOLIVIA' },
  { placeId: 8859, cityName: 'VILLAMONTES', countryName: 'BOLIVIA' },
  { placeId: 9062, cityName: 'YACUIBA', countryName: 'BOLIVIA' },
  { placeId: 2157, cityName: 'BANJA LUKA', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 4470, cityName: 'BIHAC', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 6588, cityName: 'LIVNO', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 2158, cityName: 'MOSTAR', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 7764, cityName: 'PRIJEDOR', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 2159, cityName: 'SARAJEVO', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 2160, cityName: 'TUZLA', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 13590, cityName: 'VELIKA KLADUSA', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 8869, cityName: 'VISOKO', countryName: 'BOSNIA AND HERZEGOVINA' },
  { placeId: 1118, cityName: 'FRANCISTOWN', countryName: 'BOTSWANA' },
  { placeId: 1122, cityName: 'GABORONE', countryName: 'BOTSWANA' },
  { placeId: 1119, cityName: 'GHANZI', countryName: 'BOTSWANA' },
  { placeId: 5743, cityName: 'GUMARE', countryName: 'BOTSWANA' },
  { placeId: 6088, cityName: 'JWANENG', countryName: 'BOTSWANA' },
  { placeId: 5643, cityName: 'KANG', countryName: 'BOTSWANA' },
  { placeId: 6132, cityName: 'KANYE', countryName: 'BOTSWANA' },
  { placeId: 1120, cityName: 'KASANE', countryName: 'BOTSWANA' },
  { placeId: 6214, cityName: 'KHWAI RIVER', countryName: 'BOTSWANA' },
  { placeId: 6562, cityName: 'LIMPOPO VALLEY', countryName: 'BOTSWANA' },
  { placeId: 6722, cityName: 'MAKALAMABEDI', countryName: 'BOTSWANA' },
  { placeId: 1121, cityName: 'MAUN', countryName: 'BOTSWANA' },
  { placeId: 7194, cityName: 'NATA', countryName: 'BOTSWANA' },
  { placeId: 7274, cityName: 'NOKANENG', countryName: 'BOTSWANA' },
  { placeId: 7408, cityName: 'ORAPA', countryName: 'BOTSWANA' },
  { placeId: 6512, cityName: 'PALAPYE', countryName: 'BOTSWANA' },
  { placeId: 7844, cityName: 'RAKOPS', countryName: 'BOTSWANA' },
  { placeId: 1123, cityName: 'SELEBI-PHIKWE', countryName: 'BOTSWANA' },
  { placeId: 8193, cityName: 'SEROWE', countryName: 'BOTSWANA' },
  { placeId: 8209, cityName: 'SHAKAWE', countryName: 'BOTSWANA' },
  { placeId: 8501, cityName: 'SUA', countryName: 'BOTSWANA' },
  { placeId: 8720, cityName: 'TSHABONG', countryName: 'BOTSWANA' },
  { placeId: 8721, cityName: 'TSHANE', countryName: 'BOTSWANA' },
  { placeId: 9061, cityName: 'XUGANA', countryName: 'BOTSWANA' },
  // { placeId: 9319, cityName: '', countryName: 'BRAZIL' },
  { placeId: 3349, cityName: 'ABARE', countryName: 'BRAZIL' },
  { placeId: 3529, cityName: 'ACU', countryName: 'BRAZIL' },
  { placeId: 3326, cityName: 'ADAMANTINA', countryName: 'BRAZIL' },
  { placeId: 3389, cityName: 'ADUSTINA', countryName: 'BRAZIL' },
  { placeId: 3557, cityName: 'ALEGRETE', countryName: 'BRAZIL' },
  { placeId: 3428, cityName: 'ALFENAS', countryName: 'BRAZIL' },
  { placeId: 3300, cityName: 'ALMEIRIM', countryName: 'BRAZIL' },
  { placeId: 3394, cityName: 'ALMENARA', countryName: 'BRAZIL' },
  { placeId: 3252, cityName: 'ALTA FLORESTA', countryName: 'BRAZIL' },
  { placeId: 3282, cityName: 'ALTAMIRA', countryName: 'BRAZIL' },
  { placeId: 3678, cityName: 'ALTO GARCAS', countryName: 'BRAZIL' },
  { placeId: 3378, cityName: 'ALTO PARAISO', countryName: 'BRAZIL' },
  { placeId: 3390, cityName: 'ALTO PARNAIBA', countryName: 'BRAZIL' },
  { placeId: 3558, cityName: 'AMAMBAI', countryName: 'BRAZIL' },
  { placeId: 9562, cityName: 'AMAPA', countryName: 'BRAZIL' },
  { placeId: 3328, cityName: 'AMERICANA', countryName: 'BRAZIL' },
  { placeId: 3248, cityName: 'ANAPOLIS', countryName: 'BRAZIL' },
  { placeId: 3559, cityName: 'ANDIRA', countryName: 'BRAZIL' },
  { placeId: 3335, cityName: 'ANDRADINA', countryName: 'BRAZIL' },
  { placeId: 3327, cityName: 'ANGRA DOS REIS', countryName: 'BRAZIL' },
  { placeId: 3560, cityName: 'APUCARANA', countryName: 'BRAZIL' },
  { placeId: 3721, cityName: 'APUI', countryName: 'BRAZIL' },
  { placeId: 3250, cityName: 'ARACAJU', countryName: 'BRAZIL' },
  { placeId: 9600, cityName: 'ARACATI', countryName: 'BRAZIL' },
  { placeId: 3253, cityName: 'ARACATUBA', countryName: 'BRAZIL' },
  { placeId: 3532, cityName: 'ARACUAI', countryName: 'BRAZIL' },
  { placeId: 3653, cityName: 'ARAGARCAS', countryName: 'BRAZIL' },
  { placeId: 3675, cityName: 'ARAGUACEMA', countryName: 'BRAZIL' },
  { placeId: 3655, cityName: 'ARAGUAIANA', countryName: 'BRAZIL' },
  { placeId: 3677, cityName: 'ARAGUAINA', countryName: 'BRAZIL' },
  { placeId: 3388, cityName: 'ARAGUARI', countryName: 'BRAZIL' },
  { placeId: 3654, cityName: 'ARAGUATINS', countryName: 'BRAZIL' },
  { placeId: 3391, cityName: 'ARAPIRACA', countryName: 'BRAZIL' },
  { placeId: 3612, cityName: 'ARAPONGAS', countryName: 'BRAZIL' },
  { placeId: 3649, cityName: 'ARAPOTI', countryName: 'BRAZIL' },
  { placeId: 3249, cityName: 'ARARAQUARA', countryName: 'BRAZIL' },
  { placeId: 3325, cityName: 'ARARAS', countryName: 'BRAZIL' },
  { placeId: 3255, cityName: 'ARAXA', countryName: 'BRAZIL' },
  { placeId: 3387, cityName: 'ARCOVERDE', countryName: 'BRAZIL' },
  { placeId: 3944, cityName: 'ARIQUEMES', countryName: 'BRAZIL' },
  { placeId: 3699, cityName: 'ARRAIAS', countryName: 'BRAZIL' },
  { placeId: 3556, cityName: 'ARROIO GRANDE', countryName: 'BRAZIL' },
  { placeId: 3943, cityName: 'ARUANA', countryName: 'BRAZIL' },
  { placeId: 3251, cityName: 'ASSIS', countryName: 'BRAZIL' },
  { placeId: 3690, cityName: 'ATALAIA DO NORTE', countryName: 'BRAZIL' },
  { placeId: 3368, cityName: 'AVARE', countryName: 'BRAZIL' },
  { placeId: 3399, cityName: 'BACABAL', countryName: 'BRAZIL' },
  { placeId: 2467, cityName: 'BAGE', countryName: 'BRAZIL' },
  { placeId: 3406, cityName: 'BAIAO', countryName: 'BRAZIL' },
  { placeId: 3398, cityName: 'BAIXO GUANDU', countryName: 'BRAZIL' },
  { placeId: 3404, cityName: 'BALSAS', countryName: 'BRAZIL' },
  { placeId: 3564, cityName: 'BANDEIRANTES', countryName: 'BRAZIL' },
  { placeId: 3256, cityName: 'BARBACENA', countryName: 'BRAZIL' },
  { placeId: 3657, cityName: 'BARCELOS', countryName: 'BRAZIL' },
  { placeId: 3332, cityName: 'BARIRI', countryName: 'BRAZIL' },
  { placeId: 3396, cityName: 'BARRA DO CORDA', countryName: 'BRAZIL' },
  { placeId: 3259, cityName: 'BARRA DO GARCAS', countryName: 'BRAZIL' },
  { placeId: 3407, cityName: 'BARRA', countryName: 'BRAZIL' },
  { placeId: 3403, cityName: 'BARREIRAS', countryName: 'BRAZIL' },
  { placeId: 3658, cityName: 'BARREIRINHA', countryName: 'BRAZIL' },
  { placeId: 3257, cityName: 'BARRETOS', countryName: 'BRAZIL' },
  { placeId: 3258, cityName: 'BAURU', countryName: 'BRAZIL' },
  { placeId: 3329, cityName: 'BEBEDOURO', countryName: 'BRAZIL' },
  { placeId: 3565, cityName: 'BELA VISTA', countryName: 'BRAZIL' },
  { placeId: 3431, cityName: 'BELEM DE SAO FRANCISCO', countryName: 'BRAZIL' },
  { placeId: 2466, cityName: 'BELEM', countryName: 'BRAZIL' },
  { placeId: 9804, cityName: 'BELMONTE', countryName: 'BRAZIL' },
  { placeId: 2470, cityName: 'BELO HORIZONTE', countryName: 'BRAZIL' },
  { placeId: 3400, cityName: 'BELO JARDIM', countryName: 'BRAZIL' },
  { placeId: 3424, cityName: 'BELTERRA', countryName: 'BRAZIL' },
  { placeId: 3405, cityName: 'BENEDITO LEITE', countryName: 'BRAZIL' },
  { placeId: 3563, cityName: 'BLUMENAU', countryName: 'BRAZIL' },
  { placeId: 2469, cityName: 'BOA VISTA', countryName: 'BRAZIL' },
  { placeId: 3945, cityName: 'BOCA DO ACRE', countryName: 'BRAZIL' },
  { placeId: 3401, cityName: 'BOCAIUVA', countryName: 'BRAZIL' },
  { placeId: 3296, cityName: 'BOM JESUS DA LAPA', countryName: 'BRAZIL' },
  { placeId: 3436, cityName: 'BOM JESUS DO GURGUEIA', countryName: 'BRAZIL' },
  { placeId: 3562, cityName: 'BOM JESUS', countryName: 'BRAZIL' },
  { placeId: 3385, cityName: 'BONITO', countryName: 'BRAZIL' },
  { placeId: 3402, cityName: 'BOQUIRA', countryName: 'BRAZIL' },
  { placeId: 3660, cityName: 'BORBA', countryName: 'BRAZIL' },
  { placeId: 3330, cityName: 'BOTUCATU', countryName: 'BRAZIL' },
  { placeId: 3331, cityName: 'BRAGANCA PAULISTA', countryName: 'BRAZIL' },
  { placeId: 3661, cityName: 'BRASILEIA', countryName: 'BRAZIL' },
  { placeId: 2468, cityName: 'BRASILIA', countryName: 'BRAZIL' },
  { placeId: 3506, cityName: 'BREJO', countryName: 'BRAZIL' },
  { placeId: 3540, cityName: 'BREVES', countryName: 'BRAZIL' },
  { placeId: 3458, cityName: 'BROTAS DE MACAUBAS', countryName: 'BRAZIL' },
  { placeId: 3656, cityName: 'BURITI ALEGRE', countryName: 'BRAZIL' },
  { placeId: 3525, cityName: 'BURITIRAMA', countryName: 'BRAZIL' },
  { placeId: 3261, cityName: 'CABO FRIO', countryName: 'BRAZIL' },
  { placeId: 3263, cityName: 'CACADOR', countryName: 'BRAZIL' },
  { placeId: 3647, cityName: 'CACAPAVA DO SUL', countryName: 'BRAZIL' },
  { placeId: 3569, cityName: 'CACEQUI', countryName: 'BRAZIL' },
  { placeId: 3669, cityName: 'CACERES', countryName: 'BRAZIL' },
  { placeId: 3595, cityName: 'CACHOEIRA DO SUL', countryName: 'BRAZIL' },
  { placeId: 3454, cityName: 'CACHOEIRO DO ITAPEMIRIM', countryName: 'BRAZIL' },
  { placeId: 3692, cityName: 'CACOAL', countryName: 'BRAZIL' },
  { placeId: 3929, cityName: 'CAETITE', countryName: 'BRAZIL' },
  { placeId: 3455, cityName: 'CAICO', countryName: 'BRAZIL' },
  { placeId: 10011, cityName: 'CALCOENE', countryName: 'BRAZIL' },
  { placeId: 3268, cityName: 'CALDAS NOVAS', countryName: 'BRAZIL' },
  { placeId: 10033, cityName: 'CAMOCIM', countryName: 'BRAZIL' },
  { placeId: 3291, cityName: 'CAMPINA GRANDE', countryName: 'BRAZIL' },
  { placeId: 3413, cityName: 'CAMPINA VERDE', countryName: 'BRAZIL' },
  { placeId: 2481, cityName: 'CAMPINAS', countryName: 'BRAZIL' },
  { placeId: 3627, cityName: 'CAMPO ALEGRE DE LOURDES', countryName: 'BRAZIL' },
  { placeId: 3409, cityName: 'CAMPO DO MEIO', countryName: 'BRAZIL' },
  { placeId: 2471, cityName: 'CAMPO GRANDE', countryName: 'BRAZIL' },
  { placeId: 3593, cityName: 'CAMPO MOURAO', countryName: 'BRAZIL' },
  { placeId: 3594, cityName: 'CAMPO NOVO', countryName: 'BRAZIL' },
  { placeId: 3663, cityName: 'CAMPOS BELOS', countryName: 'BRAZIL' },
  { placeId: 3269, cityName: 'CAMPOS DOS GOYTACAZES', countryName: 'BRAZIL' },
  { placeId: 3412, cityName: 'CAMPOS SALES', countryName: 'BRAZIL' },
  { placeId: 3671, cityName: 'CANARANA', countryName: 'BRAZIL' },
  { placeId: 10047, cityName: 'CANAVIEIRAS', countryName: 'BRAZIL' },
  { placeId: 10049, cityName: 'CANDIOTA', countryName: 'BRAZIL' },
  { placeId: 3567, cityName: 'CANELA', countryName: 'BRAZIL' },
  { placeId: 3333, cityName: 'CAPAO BONITO', countryName: 'BRAZIL' },
  { placeId: 3592, cityName: 'CAPAO DA CANOA', countryName: 'BRAZIL' },
  { placeId: 3459, cityName: 'CAPELINHA', countryName: 'BRAZIL' },
  { placeId: 3698, cityName: 'CARACARAI', countryName: 'BRAZIL' },
  { placeId: 3266, cityName: 'CARAJAS', countryName: 'BRAZIL' },
  { placeId: 3662, cityName: 'CARAUARI', countryName: 'BRAZIL' },
  { placeId: 10067, cityName: 'CARAVELAS', countryName: 'BRAZIL' },
  { placeId: 3597, cityName: 'CARAZINHO', countryName: 'BRAZIL' },
  { placeId: 3475, cityName: 'CARINHANHA', countryName: 'BRAZIL' },
  { placeId: 3265, cityName: 'CAROLINA', countryName: 'BRAZIL' },
  { placeId: 3511, cityName: 'CARUARU', countryName: 'BRAZIL' },
  { placeId: 3410, cityName: 'CARUTAPERA', countryName: 'BRAZIL' },
  { placeId: 3339, cityName: 'CASA NOVA', countryName: 'BRAZIL' },
  { placeId: 3260, cityName: 'CASCAVEL', countryName: 'BRAZIL' },
  { placeId: 3626, cityName: 'CASTRO ALVES', countryName: 'BRAZIL' },
  { placeId: 3623, cityName: 'CASTRO', countryName: 'BRAZIL' },
  { placeId: 3694, cityName: 'CATALAO, BRAZIL', countryName: 'BRAZIL' },
  { placeId: 10101, cityName: 'CATANDUVA', countryName: 'BRAZIL' },
  { placeId: 3382, cityName: 'CATOLE DO ROCHA', countryName: 'BRAZIL' },
  { placeId: 3665, cityName: 'CAVALCANTE', countryName: 'BRAZIL' },
  { placeId: 3544, cityName: 'CAXAMBU', countryName: 'BRAZIL' },
  { placeId: 3270, cityName: 'CAXIAS DO SUL', countryName: 'BRAZIL' },
  { placeId: 3546, cityName: 'CAXIAS', countryName: 'BRAZIL' },
  { placeId: 3651, cityName: 'CENTENARIO DO SOL', countryName: 'BRAZIL' },
  { placeId: 3666, cityName: 'CERES', countryName: 'BRAZIL' },
  { placeId: 3953, cityName: 'CHAPADA DOS GUIMARAES', countryName: 'BRAZIL' },
  { placeId: 3264, cityName: 'CHAPECO', countryName: 'BRAZIL' },
  { placeId: 3547, cityName: 'CHAVES', countryName: 'BRAZIL' },
  { placeId: 3571, cityName: 'CIANORTE', countryName: 'BRAZIL' },
  { placeId: 3932, cityName: 'CIPO', countryName: 'BRAZIL' },
  { placeId: 3693, cityName: 'COARI', countryName: 'BRAZIL' },
  { placeId: 3449, cityName: 'COCOS', countryName: 'BRAZIL' },
  { placeId: 3468, cityName: 'CODO', countryName: 'BRAZIL' },
  { placeId: 3503, cityName: 'COELHO NETO', countryName: 'BRAZIL' },
  { placeId: 3414, cityName: 'COLATINA', countryName: 'BRAZIL' },
  { placeId: 3456, cityName: 'COLINAS', countryName: 'BRAZIL' },
  { placeId: 3537, cityName: 'CONCEICAO DAS ALAGOAS', countryName: 'BRAZIL' },
  { placeId: 3247, cityName: 'CONCEICAO DO ARAGUAIA', countryName: 'BRAZIL' },
  { placeId: 3450, cityName: 'CONCEICAO DO MATO DENTRO', countryName: 'BRAZIL' },
  { placeId: 3383, cityName: 'CONCEICAO', countryName: 'BRAZIL' },
  { placeId: 3566, cityName: 'CONCORDIA', countryName: 'BRAZIL' },
  { placeId: 3452, cityName: 'CONSELHEIRO LAFAIETE', countryName: 'BRAZIL' },
  { placeId: 3568, cityName: 'CORNELIO PROCOPIO', countryName: 'BRAZIL' },
  { placeId: 3478, cityName: 'COROATA', countryName: 'BRAZIL' },
  { placeId: 3460, cityName: 'CORRENTE', countryName: 'BRAZIL' },
  { placeId: 2472, cityName: 'CORUMBA', countryName: 'BRAZIL' },
  { placeId: 3664, cityName: 'COSTA MARQUES', countryName: 'BRAZIL' },
  { placeId: 3379, cityName: 'COSTA RICA', countryName: 'BRAZIL' },
  { placeId: 3924, cityName: 'COXIM', countryName: 'BRAZIL' },
  { placeId: 3542, cityName: 'CRATEUS', countryName: 'BRAZIL' },
  { placeId: 3453, cityName: 'CREPUTIA', countryName: 'BRAZIL' },
  { placeId: 3267, cityName: 'CRICIUMA', countryName: 'BRAZIL' },
  { placeId: 3684, cityName: 'CRISTALANDIA', countryName: 'BRAZIL' },
  { placeId: 3377, cityName: 'CRISTALINA', countryName: 'BRAZIL' },
  { placeId: 3271, cityName: 'CRUZEIRO DO SUL', countryName: 'BRAZIL' },
  { placeId: 2474, cityName: 'CUIABA', countryName: 'BRAZIL' },
  { placeId: 2473, cityName: 'CURITIBA', countryName: 'BRAZIL' },
  { placeId: 3596, cityName: 'CURITIBANOS', countryName: 'BRAZIL' },
  { placeId: 3457, cityName: 'CURRAIS NOVOS', countryName: 'BRAZIL' },
  { placeId: 3504, cityName: 'CURVELO', countryName: 'BRAZIL' },
  { placeId: 3420, cityName: 'DIAMANTINA', countryName: 'BRAZIL' },
  { placeId: 3667, cityName: 'DIAMANTINO', countryName: 'BRAZIL' },
  { placeId: 3668, cityName: 'DIANOPOLIS', countryName: 'BRAZIL' },
  { placeId: 3572, cityName: 'DIONISIO CERQUEIRA', countryName: 'BRAZIL' },
  { placeId: 3421, cityName: 'DIVINOPOLIS', countryName: 'BRAZIL' },
  { placeId: 3574, cityName: 'DOM PEDRITO', countryName: 'BRAZIL' },
  { placeId: 3423, cityName: 'DORES DO INDAIA', countryName: 'BRAZIL' },
  { placeId: 3573, cityName: 'DOURADOS', countryName: 'BRAZIL' },
  { placeId: 3343, cityName: 'DR. JOSE AUGUSTO DE ARRUDA BOT', countryName: 'BRAZIL' },
  { placeId: 3336, cityName: 'DRACENA', countryName: 'BRAZIL' },
  { placeId: 3670, cityName: 'EIRUNEPE', countryName: 'BRAZIL' },
  { placeId: 10591, cityName: 'ELDORADO DO SUL', countryName: 'BRAZIL' },
  { placeId: 3422, cityName: 'ENCRUZILHADA', countryName: 'BRAZIL' },
  { placeId: 3576, cityName: 'ERECHIM', countryName: 'BRAZIL' },
  { placeId: 3427, cityName: 'ESPLANADA', countryName: 'BRAZIL' },
  { placeId: 3411, cityName: 'EUCLIDES DA CUNHA', countryName: 'BRAZIL' },
  { placeId: 3426, cityName: 'EUNAPOLIS', countryName: 'BRAZIL' },
  { placeId: 3672, cityName: 'FEIJO', countryName: 'BRAZIL' },
  { placeId: 10703, cityName: 'FERNANDO DE NORONHA', countryName: 'BRAZIL' },
  { placeId: 3338, cityName: 'FERNANDOPOLIS', countryName: 'BRAZIL' },
  { placeId: 3501, cityName: 'FLORIANO', countryName: 'BRAZIL' },
  { placeId: 2477, cityName: 'FLORIANOPOLIS', countryName: 'BRAZIL' },
  { placeId: 3948, cityName: 'FONTE BOA', countryName: 'BRAZIL' },
  { placeId: 3430, cityName: 'FORMIGA', countryName: 'BRAZIL' },
  { placeId: 3673, cityName: 'FORMOSA', countryName: 'BRAZIL' },
  { placeId: 2478, cityName: 'FORTALEZA', countryName: 'BRAZIL' },
  { placeId: 2476, cityName: 'FOZ DO IGUACU', countryName: 'BRAZIL' },
  { placeId: 3384, cityName: 'FRANCA', countryName: 'BRAZIL' },
  { placeId: 3577, cityName: 'FRANCISCO BELTRAO', countryName: 'BRAZIL' },
  { placeId: 3429, cityName: 'FRANCISCO SA', countryName: 'BRAZIL' },
  { placeId: 3275, cityName: 'FRONTEIRA', countryName: 'BRAZIL' },
  { placeId: 3432, cityName: 'FRUTAL', countryName: 'BRAZIL' },
  { placeId: 3919, cityName: 'GARANHUNS', countryName: 'BRAZIL' },
  { placeId: 3340, cityName: 'GARCA', countryName: 'BRAZIL' },
  { placeId: 3578, cityName: 'GARIBALDI', countryName: 'BRAZIL' },
  { placeId: 3278, cityName: 'GAVIAO PEIXOTO', countryName: 'BRAZIL' },
  { placeId: 3920, cityName: 'GENTIO DE OURO', countryName: 'BRAZIL' },
  { placeId: 3580, cityName: 'GETULIO VARGAS', countryName: 'BRAZIL' },
  { placeId: 3434, cityName: 'GILBUES', countryName: 'BRAZIL' },
  { placeId: 3277, cityName: 'GOIANIA', countryName: 'BRAZIL' },
  { placeId: 3581, cityName: 'GOIO-ERE', countryName: 'BRAZIL' },
  { placeId: 3280, cityName: 'GOVERNADOR VALADARES', countryName: 'BRAZIL' },
  { placeId: 3439, cityName: 'GRAJAU', countryName: 'BRAZIL' },
  { placeId: 3435, cityName: 'GUADALUPE', countryName: 'BRAZIL' },
  { placeId: 3582, cityName: 'GUAIRA', countryName: 'BRAZIL' },
  { placeId: 3276, cityName: 'GUAJARA MIRIM', countryName: 'BRAZIL' },
  { placeId: 3438, cityName: 'GUANAMBI', countryName: 'BRAZIL' },
  { placeId: 3437, cityName: 'GUANHAES', countryName: 'BRAZIL' },
  { placeId: 3579, cityName: 'GUAPORE', countryName: 'BRAZIL' },
  { placeId: 11014, cityName: 'GUARAPARI', countryName: 'BRAZIL' },
  { placeId: 3279, cityName: 'GUARAPUAVA', countryName: 'BRAZIL' },
  { placeId: 3281, cityName: 'GUARATINGUETA', countryName: 'BRAZIL' },
  { placeId: 11015, cityName: 'GUARATUBA', countryName: 'BRAZIL' },
  { placeId: 3910, cityName: 'GUARUJA', countryName: 'BRAZIL' },
  { placeId: 3922, cityName: 'GUAXUPE', countryName: 'BRAZIL' },
  { placeId: 11021, cityName: 'GUIMARAES', countryName: 'BRAZIL' },
  { placeId: 3921, cityName: 'GURUPA', countryName: 'BRAZIL' },
  { placeId: 3676, cityName: 'GURUPI', countryName: 'BRAZIL' },
  { placeId: 11182, cityName: 'HORIZONTINA', countryName: 'BRAZIL' },
  { placeId: 3680, cityName: 'HUMAITA', countryName: 'BRAZIL' },
  { placeId: 3681, cityName: 'IASSIARA', countryName: 'BRAZIL' },
  { placeId: 3555, cityName: 'IBAITI', countryName: 'BRAZIL' },
  { placeId: 3935, cityName: 'IBIMIRIM', countryName: 'BRAZIL' },
  { placeId: 3341, cityName: 'IBITINGA', countryName: 'BRAZIL' },
  { placeId: 3934, cityName: 'IBOTIRAMA', countryName: 'BRAZIL' },
  { placeId: 3926, cityName: 'IGARAPE-ACU', countryName: 'BRAZIL' },
  { placeId: 11234, cityName: 'IGUARACU', countryName: 'BRAZIL' },
  { placeId: 3561, cityName: 'IGUATEMI', countryName: 'BRAZIL' },
  { placeId: 3930, cityName: 'IGUATU', countryName: 'BRAZIL' },
  { placeId: 3583, cityName: 'IJUI', countryName: 'BRAZIL' },
  { placeId: 11241, cityName: 'ILHEUS', countryName: 'BRAZIL' },
  { placeId: 11244, cityName: 'IMBITUBA', countryName: 'BRAZIL' },
  { placeId: 3287, cityName: 'IMPERATRIZ', countryName: 'BRAZIL' },
  { placeId: 3683, cityName: 'IPAMERI', countryName: 'BRAZIL' },
  { placeId: 3285, cityName: 'IPATINGA', countryName: 'BRAZIL' },
  { placeId: 11270, cityName: 'IPIAU', countryName: 'BRAZIL' },
  { placeId: 3646, cityName: 'IRAI', countryName: 'BRAZIL' },
  { placeId: 3586, cityName: 'IRATI', countryName: 'BRAZIL' },
  { placeId: 3928, cityName: 'IRECE', countryName: 'BRAZIL' },
  { placeId: 3927, cityName: 'ITABERABA', countryName: 'BRAZIL' },
  { placeId: 3923, cityName: 'ITABUNA', countryName: 'BRAZIL' },
  { placeId: 3283, cityName: 'ITACOATIARA', countryName: 'BRAZIL' },
  { placeId: 3284, cityName: 'ITAITUBA', countryName: 'BRAZIL' },
  { placeId: 3375, cityName: 'ITAPERUNA', countryName: 'BRAZIL' },
  { placeId: 3933, cityName: 'ITAPETINGA', countryName: 'BRAZIL' },
  { placeId: 3380, cityName: 'ITAPEVA', countryName: 'BRAZIL' },
  { placeId: 11282, cityName: 'ITAPOLIS', countryName: 'BRAZIL' },
  { placeId: 3584, cityName: 'ITAQUI', countryName: 'BRAZIL' },
  { placeId: 3548, cityName: 'ITUIUTABA', countryName: 'BRAZIL' },
  { placeId: 3286, cityName: 'ITUMBIARA', countryName: 'BRAZIL' },
  { placeId: 3342, cityName: 'ITUVERAVA', countryName: 'BRAZIL' },
  { placeId: 3344, cityName: 'JABOTICABAL', countryName: 'BRAZIL' },
  { placeId: 3273, cityName: 'JACAREACANGA', countryName: 'BRAZIL' },
  { placeId: 3588, cityName: 'JACAREZINHO', countryName: 'BRAZIL' },
  { placeId: 3685, cityName: 'JACIARA', countryName: 'BRAZIL' },
  { placeId: 3440, cityName: 'JACOBINA', countryName: 'BRAZIL' },
  { placeId: 3591, cityName: 'JAGUARAO', countryName: 'BRAZIL' },
  { placeId: 3469, cityName: 'JAGUARARI', countryName: 'BRAZIL' },
  { placeId: 3345, cityName: 'JALES', countryName: 'BRAZIL' },
  { placeId: 3393, cityName: 'JANAUBA', countryName: 'BRAZIL' },
  { placeId: 3443, cityName: 'JANUARIA', countryName: 'BRAZIL' },
  { placeId: 3688, cityName: 'JAPURA', countryName: 'BRAZIL' },
  { placeId: 3936, cityName: 'JARDIM DE ANGICOS', countryName: 'BRAZIL' },
  { placeId: 3447, cityName: 'JARDIM DO SERIDO', countryName: 'BRAZIL' },
  { placeId: 3589, cityName: 'JARDIM', countryName: 'BRAZIL' },
  { placeId: 3691, cityName: 'JATAI', countryName: 'BRAZIL' },
  { placeId: 3442, cityName: 'JEQUIE', countryName: 'BRAZIL' },
  { placeId: 3445, cityName: 'JEQUITINHONHA', countryName: 'BRAZIL' },
  { placeId: 3686, cityName: 'JI-PARANA', countryName: 'BRAZIL' },
  { placeId: 3587, cityName: 'JOACABA', countryName: 'BRAZIL' },
  { placeId: 3441, cityName: 'JOAO DURVAL CARNEIRO', countryName: 'BRAZIL' },
  { placeId: 11338, cityName: 'JOAO PESSOA', countryName: 'BRAZIL' },
  { placeId: 3444, cityName: 'JOAO PINHEIRO', countryName: 'BRAZIL' },
  { placeId: 11344, cityName: 'JOINVILLE', countryName: 'BRAZIL' },
  { placeId: 3290, cityName: 'JUAZEIRO DO NORTE', countryName: 'BRAZIL' },
  { placeId: 3687, cityName: 'JUINA', countryName: 'BRAZIL' },
  { placeId: 3289, cityName: 'JUIZ DE FORA', countryName: 'BRAZIL' },
  { placeId: 3590, cityName: 'JULIO DE CASTILOS', countryName: 'BRAZIL' },
  { placeId: 3288, cityName: 'JUNDIAI', countryName: 'BRAZIL' },
  { placeId: 3689, cityName: 'JURUENA', countryName: 'BRAZIL' },
  { placeId: 3937, cityName: 'LABREA', countryName: 'BRAZIL' },
  { placeId: 3293, cityName: 'LAGES', countryName: 'BRAZIL' },
  { placeId: 3297, cityName: 'LAGOA SANTA', countryName: 'BRAZIL' },
  { placeId: 11590, cityName: 'LAGUNA', countryName: 'BRAZIL' },
  { placeId: 3350, cityName: 'LEME', countryName: 'BRAZIL' },
  { placeId: 3351, cityName: 'LENCOIS PAULISTA', countryName: 'BRAZIL' },
  { placeId: 3292, cityName: 'LENCOIS', countryName: 'BRAZIL' },
  { placeId: 3419, cityName: 'LEOPOLDINA', countryName: 'BRAZIL' },
  { placeId: 3462, cityName: 'LINHARES', countryName: 'BRAZIL' },
  { placeId: 3294, cityName: 'LINS', countryName: 'BRAZIL' },
  { placeId: 3461, cityName: 'LIVRAMENTO DO BRUMADO', countryName: 'BRAZIL' },
  { placeId: 3600, cityName: 'LOANDA', countryName: 'BRAZIL' },
  { placeId: 3295, cityName: 'LONDRINA', countryName: 'BRAZIL' },
  { placeId: 3599, cityName: 'LONTRAS', countryName: 'BRAZIL' },
  { placeId: 3348, cityName: 'LUCELIA', countryName: 'BRAZIL' },
  { placeId: 3710, cityName: 'LUCIARA', countryName: 'BRAZIL' },
  { placeId: 3713, cityName: 'LUZIANIA', countryName: 'BRAZIL' },
  { placeId: 11775, cityName: 'MACAE', countryName: 'BRAZIL' },
  { placeId: 2483, cityName: 'MACAPA', countryName: 'BRAZIL' },
  { placeId: 3467, cityName: 'MACAUBAS', countryName: 'BRAZIL' },
  { placeId: 2482, cityName: 'MACEIO', countryName: 'BRAZIL' },
  { placeId: 3543, cityName: 'MACHADO', countryName: 'BRAZIL' },
  { placeId: 3602, cityName: 'MAFRA', countryName: 'BRAZIL' },
  { placeId: 2475, cityName: 'MANAUS', countryName: 'BRAZIL' },
  { placeId: 3306, cityName: 'MANICORE', countryName: 'BRAZIL' },
  { placeId: 3304, cityName: 'MANOEL RIBAS', countryName: 'BRAZIL' },
  { placeId: 3298, cityName: 'MARABA', countryName: 'BRAZIL' },
  { placeId: 3603, cityName: 'MARACAJU', countryName: 'BRAZIL' },
  { placeId: 3470, cityName: 'MARACAS', countryName: 'BRAZIL' },
  { placeId: 3570, cityName: 'MARECHAL CANDIDO ROND*', countryName: 'BRAZIL' },
  { placeId: 3352, cityName: 'MARICA', countryName: 'BRAZIL' },
  { placeId: 3303, cityName: 'MARILIA', countryName: 'BRAZIL' },
  { placeId: 3301, cityName: 'MARINGA', countryName: 'BRAZIL' },
  { placeId: 3355, cityName: 'MATAO', countryName: 'BRAZIL' },
  { placeId: 3940, cityName: 'MAUES', countryName: 'BRAZIL' },
  { placeId: 3601, cityName: 'MEDIANEIRA', countryName: 'BRAZIL' },
  { placeId: 3624, cityName: 'MIMOSO DO OESTE', countryName: 'BRAZIL' },
  { placeId: 3299, cityName: 'MINACU', countryName: 'BRAZIL' },
  { placeId: 3939, cityName: 'MINEIROS', countryName: 'BRAZIL' },
  { placeId: 3353, cityName: 'MIRASSOL', countryName: 'BRAZIL' },
  { placeId: 3347, cityName: 'MOCOCA', countryName: 'BRAZIL' },
  { placeId: 3354, cityName: 'MOGI MIRIM', countryName: 'BRAZIL' },
  { placeId: 3466, cityName: 'MOMBACA', countryName: 'BRAZIL' },
  { placeId: 3474, cityName: 'MONTALVANIA', countryName: 'BRAZIL' },
  { placeId: 3465, cityName: 'MONTE ALEGRE', countryName: 'BRAZIL' },
  { placeId: 3521, cityName: 'MONTE CARMELO', countryName: 'BRAZIL' },
  { placeId: 3473, cityName: 'MONTE SANTO DE MINAS', countryName: 'BRAZIL' },
  { placeId: 3622, cityName: 'MONTE SANTO', countryName: 'BRAZIL' },
  { placeId: 3605, cityName: 'MONTENEGRO', countryName: 'BRAZIL' },
  { placeId: 3302, cityName: 'MONTES CLAROS', countryName: 'BRAZIL' },
  { placeId: 3471, cityName: 'MORADA NOVA DE MINAS', countryName: 'BRAZIL' },
  { placeId: 3472, cityName: 'MORADA NOVA', countryName: 'BRAZIL' },
  { placeId: 3941, cityName: 'MORRINHOS', countryName: 'BRAZIL' },
  { placeId: 3480, cityName: 'MORRO DO CHAPEU', countryName: 'BRAZIL' },
  { placeId: 3305, cityName: 'MOSSORO', countryName: 'BRAZIL' },
  { placeId: 12087, cityName: 'MOSTARDAS', countryName: 'BRAZIL' },
  { placeId: 3949, cityName: 'MOURA', countryName: 'BRAZIL' },
  { placeId: 3361, cityName: 'MOZARLANDIA', countryName: 'BRAZIL' },
  { placeId: 3621, cityName: 'MUNDO NOVO', countryName: 'BRAZIL' },
  { placeId: 3477, cityName: 'NANUQUE', countryName: 'BRAZIL' },
  { placeId: 2484, cityName: 'NATAL', countryName: 'BRAZIL' },
  { placeId: 12173, cityName: 'NAVEGANTES', countryName: 'BRAZIL' },
  { placeId: 3425, cityName: 'NEROPOLIS', countryName: 'BRAZIL' },
  { placeId: 3476, cityName: 'NILO PECANHA', countryName: 'BRAZIL' },
  { placeId: 3610, cityName: 'NIOAQUE', countryName: 'BRAZIL' },
  { placeId: 3947, cityName: 'NIQUELANDIA', countryName: 'BRAZIL' },
  { placeId: 3608, cityName: 'NONOAI', countryName: 'BRAZIL' },
  { placeId: 3358, cityName: 'NOVA IGUACU', countryName: 'BRAZIL' },
  { placeId: 3946, cityName: 'NOVA OLINDA DO NORTE', countryName: 'BRAZIL' },
  { placeId: 3609, cityName: 'NOVA PRATA', countryName: 'BRAZIL' },
  { placeId: 3720, cityName: 'NOVA XAVANTINA', countryName: 'BRAZIL' },
  { placeId: 3942, cityName: 'NOVO ARIPUANA', countryName: 'BRAZIL' },
  { placeId: 3606, cityName: 'NOVO HAMBURGO', countryName: 'BRAZIL' },
  { placeId: 3356, cityName: 'NOVO HORIZONTE', countryName: 'BRAZIL' },
  { placeId: 3262, cityName: 'NOVO PROGRESSO', countryName: 'BRAZIL' },
  { placeId: 3520, cityName: 'OBIDOS', countryName: 'BRAZIL' },
  { placeId: 3481, cityName: 'OEIRAS', countryName: 'BRAZIL' },
  { placeId: 3309, cityName: 'OIAPOQUE', countryName: 'BRAZIL' },
  { placeId: 3359, cityName: 'OLIMPIA', countryName: 'BRAZIL' },
  { placeId: 3485, cityName: 'ORIXIMINA', countryName: 'BRAZIL' },
  { placeId: 3362, cityName: 'OURILANDIA DO NORTE', countryName: 'BRAZIL' },
  { placeId: 3360, cityName: 'OURINHOS', countryName: 'BRAZIL' },
  { placeId: 3482, cityName: 'OURO FINO', countryName: 'BRAZIL' },
  { placeId: 3312, cityName: 'PALMAS', countryName: 'BRAZIL' },
  { placeId: 3616, cityName: 'PALMEIRA DAS MISSOES', countryName: 'BRAZIL' },
  { placeId: 3493, cityName: 'PALMEIRAS', countryName: 'BRAZIL' },
  { placeId: 3618, cityName: 'PALOTINA', countryName: 'BRAZIL' },
  { placeId: 3486, cityName: 'PARA DE MINAS', countryName: 'BRAZIL' },
  { placeId: 3554, cityName: 'PARACATU', countryName: 'BRAZIL' },
  { placeId: 3371, cityName: 'PARAGUACU PAULISTA', countryName: 'BRAZIL' },
  { placeId: 3496, cityName: 'PARAGUACU', countryName: 'BRAZIL' },
  { placeId: 3706, cityName: 'PARAISO DO TOCANTINS', countryName: 'BRAZIL' },
  { placeId: 3408, cityName: 'PARAMIRIM', countryName: 'BRAZIL' },
  { placeId: 3955, cityName: 'PARANA', countryName: 'BRAZIL' },
  { placeId: 3614, cityName: 'PARANAGUA', countryName: 'BRAZIL' },
  { placeId: 3617, cityName: 'PARANAIBA', countryName: 'BRAZIL' },
  { placeId: 3615, cityName: 'PARANAVAI', countryName: 'BRAZIL' },
  { placeId: 12438, cityName: 'PARATI', countryName: 'BRAZIL' },
  { placeId: 12439, cityName: 'PARDINHO', countryName: 'BRAZIL' },
  { placeId: 2867, cityName: 'PARINTINS', countryName: 'BRAZIL' },
  { placeId: 12448, cityName: 'PARNAIBA', countryName: 'BRAZIL' },
  { placeId: 3311, cityName: 'PASSO FUNDO', countryName: 'BRAZIL' },
  { placeId: 3484, cityName: 'PASSOS', countryName: 'BRAZIL' },
  { placeId: 3487, cityName: 'PASTOS BONS', countryName: 'BRAZIL' },
  { placeId: 3613, cityName: 'PATO BRANCO', countryName: 'BRAZIL' },
  { placeId: 3489, cityName: 'PATOS DE MINAS', countryName: 'BRAZIL' },
  { placeId: 3527, cityName: 'PATOS', countryName: 'BRAZIL' },
  { placeId: 3551, cityName: 'PATROCINIO', countryName: 'BRAZIL' },
  { placeId: 3711, cityName: 'PAUINI', countryName: 'BRAZIL' },
  { placeId: 3464, cityName: 'PAULISTANA', countryName: 'BRAZIL' },
  { placeId: 3918, cityName: 'PAULO AFONSO', countryName: 'BRAZIL' },
  { placeId: 3499, cityName: 'PEDRA AZUL', countryName: 'BRAZIL' },
  { placeId: 3950, cityName: 'PEDRO AFONSO', countryName: 'BRAZIL' },
  { placeId: 2486, cityName: 'PELOTAS', countryName: 'BRAZIL' },
  { placeId: 3363, cityName: 'PENAPOLIS', countryName: 'BRAZIL' },
  { placeId: 3490, cityName: 'PENEDO', countryName: 'BRAZIL' },
  { placeId: 3495, cityName: 'PESQUEIRA BRAZIL', countryName: 'BRAZIL' },
  { placeId: 3313, cityName: 'PETROLINA', countryName: 'BRAZIL' },
  { placeId: 3492, cityName: 'PIATA', countryName: 'BRAZIL' },
  { placeId: 3497, cityName: 'PIAUS', countryName: 'BRAZIL' },
  { placeId: 3488, cityName: 'PICOS', countryName: 'BRAZIL' },
  { placeId: 3549, cityName: 'PILAO ARCADO', countryName: 'BRAZIL' },
  { placeId: 3954, cityName: 'PIMENTA BUENO', countryName: 'BRAZIL' },
  { placeId: 3550, cityName: 'PINHEIRO', countryName: 'BRAZIL' },
  { placeId: 3365, cityName: 'PIRACICABA', countryName: 'BRAZIL' },
  { placeId: 3585, cityName: 'PIRAI DO SUL', countryName: 'BRAZIL' },
  { placeId: 3505, cityName: 'PIRAPORA', countryName: 'BRAZIL' },
  { placeId: 3324, cityName: 'PIRASSUNUNGA', countryName: 'BRAZIL' },
  { placeId: 3696, cityName: 'PIRES DO RIO', countryName: 'BRAZIL' },
  { placeId: 3526, cityName: 'PIRITIBA, BRAZIL', countryName: 'BRAZIL' },
  { placeId: 3531, cityName: 'PIUMHI', countryName: 'BRAZIL' },
  { placeId: 3553, cityName: 'POCOES', countryName: 'BRAZIL' },
  { placeId: 3952, cityName: 'POCONE', countryName: 'BRAZIL' },
  { placeId: 3310, cityName: 'POCOS DE CALDAS', countryName: 'BRAZIL' },
  { placeId: 3494, cityName: 'POMPEU', countryName: 'BRAZIL' },
  { placeId: 3652, cityName: 'PONTA GROSSA', countryName: 'BRAZIL' },
  { placeId: 2487, cityName: 'PONTA PORA', countryName: 'BRAZIL' },
  { placeId: 3415, cityName: 'PONTE NOVA', countryName: 'BRAZIL' },
  { placeId: 3716, cityName: 'PORANGATU', countryName: 'BRAZIL' },
  { placeId: 2485, cityName: 'PORTO ALEGRE', countryName: 'BRAZIL' },
  { placeId: 3307, cityName: 'PORTO DE MOZ', countryName: 'BRAZIL' },
  { placeId: 3951, cityName: 'PORTO DOS GAUCHOS', countryName: 'BRAZIL' },
  { placeId: 3491, cityName: 'PORTO GRANDE', countryName: 'BRAZIL' },
  { placeId: 3314, cityName: 'PORTO NACIONAL', countryName: 'BRAZIL' },
  { placeId: 3315, cityName: 'PORTO SEGURO', countryName: 'BRAZIL' },
  { placeId: 2488, cityName: 'PORTO VELHO', countryName: 'BRAZIL' },
  { placeId: 3697, cityName: 'POSSE', countryName: 'BRAZIL' },
  { placeId: 3552, cityName: 'POUSO ALEGRE', countryName: 'BRAZIL' },
  { placeId: 12634, cityName: 'PRADO', countryName: 'BRAZIL' },
  { placeId: 3931, cityName: 'PRAINHA', countryName: 'BRAZIL' },
  { placeId: 3510, cityName: 'PRATA', countryName: 'BRAZIL' },
  { placeId: 3337, cityName: 'PRESIDENTE EPITACIO', countryName: 'BRAZIL' },
  { placeId: 3272, cityName: 'PRESIDENTE PRUDENTE', countryName: 'BRAZIL' },
  { placeId: 3364, cityName: 'PRESIDENTE VENCESLAU', countryName: 'BRAZIL' },
  { placeId: 3483, cityName: 'PROPRIA', countryName: 'BRAZIL' },
  { placeId: 3366, cityName: 'QUATA', countryName: 'BRAZIL' },
  { placeId: 3502, cityName: 'QUEIMADAS', countryName: 'BRAZIL' },
  { placeId: 12727, cityName: 'RANCHARIA', countryName: 'BRAZIL' },
  { placeId: 3625, cityName: 'REALEZA', countryName: 'BRAZIL' },
  { placeId: 2490, cityName: 'RECIFE', countryName: 'BRAZIL' },
  { placeId: 3417, cityName: 'REDENCAO', countryName: 'BRAZIL' },
  { placeId: 3381, cityName: 'REGIONAL DA ZONA DA MATA', countryName: 'BRAZIL' },
  { placeId: 3369, cityName: 'RESENDE', countryName: 'BRAZIL' },
  { placeId: 3512, cityName: 'RIACHAO', countryName: 'BRAZIL' },
  { placeId: 3317, cityName: 'RIBEIRAO PRETO', countryName: 'BRAZIL' },
  { placeId: 2489, cityName: 'RIO BRANCO', countryName: 'BRAZIL' },
  { placeId: 3367, cityName: 'RIO CLARO', countryName: 'BRAZIL' },
  { placeId: 2479, cityName: 'RIO DE JANEIRO', countryName: 'BRAZIL' },
  { placeId: 3386, cityName: 'RIO GRANDE', countryName: 'BRAZIL' },
  { placeId: 3508, cityName: 'RIO PARANAIBA', countryName: 'BRAZIL' },
  { placeId: 12799, cityName: 'RIO QUENTE', countryName: 'BRAZIL' },
  { placeId: 3507, cityName: 'RIO TINTO', countryName: 'BRAZIL' },
  { placeId: 3938, cityName: 'RIO VERDE', countryName: 'BRAZIL' },
  { placeId: 3700, cityName: 'RONDONOPOLIS', countryName: 'BRAZIL' },
  { placeId: 3254, cityName: 'ROSANA', countryName: 'BRAZIL' },
  { placeId: 3629, cityName: 'ROSARIO DO SUL', countryName: 'BRAZIL' },
  { placeId: 3416, cityName: 'RUROPOLIS', countryName: 'BRAZIL' },
  { placeId: 3509, cityName: 'RUSSAS', countryName: 'BRAZIL' },
  { placeId: 3448, cityName: 'SACRAMENTO', countryName: 'BRAZIL' },
  { placeId: 3513, cityName: 'SALGUEIRO', countryName: 'BRAZIL' },
  { placeId: 3517, cityName: 'SALINAS', countryName: 'BRAZIL' },
  { placeId: 3607, cityName: 'SALTO DO JACUI', countryName: 'BRAZIL' },
  { placeId: 2493, cityName: 'SALVADOR', countryName: 'BRAZIL' },
  { placeId: 3631, cityName: 'SANTA CRUZ DO SUL', countryName: 'BRAZIL' },
  { placeId: 3679, cityName: 'SANTA HELENA DE GOIAS', countryName: 'BRAZIL' },
  { placeId: 3707, cityName: 'SANTA ISABEL DO RIO NEGRO', countryName: 'BRAZIL' },
  { placeId: 3536, cityName: 'SANTA MARIA DA VITORIA', countryName: 'BRAZIL' },
  { placeId: 3318, cityName: 'SANTA MARIA', countryName: 'BRAZIL' },
  { placeId: 3514, cityName: 'SANTA RITA DO SAPUCAI', countryName: 'BRAZIL' },
  { placeId: 3650, cityName: 'SANTA ROSA', countryName: 'BRAZIL' },
  { placeId: 3704, cityName: 'SANTA TEREZINHA', countryName: 'BRAZIL' },
  { placeId: 3451, cityName: 'SANTANA DO ARAGUAIA', countryName: 'BRAZIL' },
  { placeId: 2492, cityName: 'SANTAREM', countryName: 'BRAZIL' },
  { placeId: 3635, cityName: 'SANTIAGO', countryName: 'BRAZIL' },
  { placeId: 3308, cityName: 'SANTO ANGELO', countryName: 'BRAZIL' },
  { placeId: 3392, cityName: 'SANTO ANTONIO DO AMPARO', countryName: 'BRAZIL' },
  { placeId: 3682, cityName: 'SANTO ANTONIO DO ICA', countryName: 'BRAZIL' },
  { placeId: 3630, cityName: 'SAO BORJA', countryName: 'BRAZIL' },
  { placeId: 3370, cityName: 'SAO CARLOS', countryName: 'BRAZIL' },
  { placeId: 3418, cityName: 'SAO DOMINGOS DO MARANHAO', countryName: 'BRAZIL' },
  { placeId: 3674, cityName: 'SAO FELIX DO ARAGUAIA', countryName: 'BRAZIL' },
  { placeId: 3433, cityName: 'SAO FELIX DO XINGU', countryName: 'BRAZIL' },
  { placeId: 3695, cityName: 'SAO GABRIEL DA CACHOEIRA', countryName: 'BRAZIL' },
  { placeId: 3633, cityName: 'SAO GABRIEL', countryName: 'BRAZIL' },
  { placeId: 3446, cityName: 'SAO JOAO DEL REI', countryName: 'BRAZIL' },
  { placeId: 3634, cityName: 'SAO JOAQUIM', countryName: 'BRAZIL' },
  { placeId: 3346, cityName: 'SAO JOAQUIN DA BARRA', countryName: 'BRAZIL' },
  { placeId: 3319, cityName: 'SAO JOSE DO RIO PRETO', countryName: 'BRAZIL' },
  { placeId: 2491, cityName: 'SAO JOSE DOS CAMPOS', countryName: 'BRAZIL' },
  { placeId: 3628, cityName: 'SAO LOURENCO DO SUL', countryName: 'BRAZIL' },
  { placeId: 3463, cityName: 'SAO LOURENCO', countryName: 'BRAZIL' },
  { placeId: 3598, cityName: 'SAO LUIS GONZAGA', countryName: 'BRAZIL' },
  { placeId: 12959, cityName: 'SAO LUIS', countryName: 'BRAZIL' },
  { placeId: 3357, cityName: 'SAO MANUEL', countryName: 'BRAZIL' },
  { placeId: 12960, cityName: 'SAO MATEUS', countryName: 'BRAZIL' },
  { placeId: 3709, cityName: 'SAO MIGUEL DO ARAGUAIA, BRAZIL', countryName: 'BRAZIL' },
  { placeId: 3604, cityName: 'SAO MIGUEL DO IGUACU', countryName: 'BRAZIL' },
  { placeId: 3611, cityName: 'SAO MIGUEL DO OESTE', countryName: 'BRAZIL' },
  { placeId: 12961, cityName: 'SAO PAULO DE OLIVENCA', countryName: 'BRAZIL' },
  { placeId: 2480, cityName: 'SAO PAULO', countryName: 'BRAZIL' },
  { placeId: 3274, cityName: 'SAO PEDRO DA ALDEIA', countryName: 'BRAZIL' },
  { placeId: 3515, cityName: 'SAO RAIMUNDO NONATO', countryName: 'BRAZIL' },
  { placeId: 3498, cityName: 'SAO SEBASTIAO DO PARAISO', countryName: 'BRAZIL' },
  { placeId: 3575, cityName: 'SAO SEPE', countryName: 'BRAZIL' },
  { placeId: 3648, cityName: 'SARANDI', countryName: 'BRAZIL' },
  { placeId: 3397, cityName: 'SENHOR DO BONFIM', countryName: 'BRAZIL' },
  { placeId: 3925, cityName: 'SERRA TALHADA', countryName: 'BRAZIL' },
  { placeId: 13016, cityName: 'SERRANOPOLIS', countryName: 'BRAZIL' },
  { placeId: 3516, cityName: 'SERRO', countryName: 'BRAZIL' },
  { placeId: 3703, cityName: 'SERTANIA', countryName: 'BRAZIL' },
  { placeId: 3636, cityName: 'SERTANOPOLIS', countryName: 'BRAZIL' },
  { placeId: 3702, cityName: 'SINOP', countryName: 'BRAZIL' },
  { placeId: 3619, cityName: 'SIQUEIRA CAMPOS', countryName: 'BRAZIL' },
  { placeId: 3479, cityName: 'SOBRAL', countryName: 'BRAZIL' },
  { placeId: 3632, cityName: 'SOLEDADE', countryName: 'BRAZIL' },
  { placeId: 3334, cityName: 'SOROCABA', countryName: 'BRAZIL' },
  { placeId: 13134, cityName: 'SOURE', countryName: 'BRAZIL' },
  { placeId: 3518, cityName: 'SOUTO SOARES, BRAZIL', countryName: 'BRAZIL' },
  { placeId: 3500, cityName: 'SOUZA', countryName: 'BRAZIL' },
  { placeId: 2494, cityName: 'TABATINGA', countryName: 'BRAZIL' },
  { placeId: 3708, cityName: 'TAGUATINGA', countryName: 'BRAZIL' },
  { placeId: 3522, cityName: 'TAMBORIL', countryName: 'BRAZIL' },
  { placeId: 3620, cityName: 'TANQUE NOVO', countryName: 'BRAZIL' },
  { placeId: 3915, cityName: 'TARAUACA', countryName: 'BRAZIL' },
  { placeId: 3372, cityName: 'TATUI', countryName: 'BRAZIL' },
  { placeId: 3911, cityName: 'TAUBATE', countryName: 'BRAZIL' },
  { placeId: 3914, cityName: 'TEFE', countryName: 'BRAZIL' },
  { placeId: 3519, cityName: 'TEIXEIRA DE FREITAS', countryName: 'BRAZIL' },
  { placeId: 3916, cityName: 'TELEMACO BORBA', countryName: 'BRAZIL' },
  { placeId: 3524, cityName: 'TEOFILO OTONI', countryName: 'BRAZIL' },
  { placeId: 3913, cityName: 'TERESINA', countryName: 'BRAZIL' },
  { placeId: 13397, cityName: 'TERRAVISTA', countryName: 'BRAZIL' },
  { placeId: 3701, cityName: 'TESOURO', countryName: 'BRAZIL' },
  { placeId: 13437, cityName: 'TIMON', countryName: 'BRAZIL' },
  { placeId: 3705, cityName: 'TOCANTINIA', countryName: 'BRAZIL' },
  { placeId: 3912, cityName: 'TOLEDO', countryName: 'BRAZIL' },
  { placeId: 13466, cityName: 'TORRES', countryName: 'BRAZIL' },
  { placeId: 3637, cityName: 'TRES BARRAS', countryName: 'BRAZIL' },
  { placeId: 3538, cityName: 'TRES CORACOES', countryName: 'BRAZIL' },
  { placeId: 3638, cityName: 'TRES LAGOAS', countryName: 'BRAZIL' },
  { placeId: 3395, cityName: 'TRES MARIAS', countryName: 'BRAZIL' },
  { placeId: 3639, cityName: 'TRES PASSOS', countryName: 'BRAZIL' },
  { placeId: 3917, cityName: 'TUCURUI', countryName: 'BRAZIL' },
  { placeId: 3373, cityName: 'TUPA', countryName: 'BRAZIL' },
  { placeId: 3523, cityName: 'TURMALINA', countryName: 'BRAZIL' },
  { placeId: 3535, cityName: 'UAUA', countryName: 'BRAZIL' },
  { placeId: 3528, cityName: 'UBA', countryName: 'BRAZIL' },
  { placeId: 3374, cityName: 'UBATUBA', countryName: 'BRAZIL' },
  { placeId: 3321, cityName: 'UBERABA', countryName: 'BRAZIL' },
  { placeId: 3320, cityName: 'UBERLANDIA', countryName: 'BRAZIL' },
  { placeId: 3712, cityName: 'UIRAPURU', countryName: 'BRAZIL' },
  { placeId: 3640, cityName: 'UMUARAMA', countryName: 'BRAZIL' },
  { placeId: 13540, cityName: 'UNA', countryName: 'BRAZIL' },
  { placeId: 3533, cityName: 'UNAI', countryName: 'BRAZIL' },
  { placeId: 3641, cityName: 'UNIAO DA VITORIA', countryName: 'BRAZIL' },
  { placeId: 3530, cityName: 'URBANO SANTOS', countryName: 'BRAZIL' },
  { placeId: 3718, cityName: 'URUACU', countryName: 'BRAZIL' },
  { placeId: 3717, cityName: 'URUCARA', countryName: 'BRAZIL' },
  { placeId: 2495, cityName: 'URUGUAIANA', countryName: 'BRAZIL' },
  { placeId: 3534, cityName: 'UTINGA, BRAZIL', countryName: 'BRAZIL' },
  { placeId: 3642, cityName: 'VACARIA', countryName: 'BRAZIL' },
  { placeId: 13571, cityName: 'VALENCA', countryName: 'BRAZIL' },
  { placeId: 3322, cityName: 'VARGINHA', countryName: 'BRAZIL' },
  { placeId: 3541, cityName: 'VARZEA DA PALMA', countryName: 'BRAZIL' },
  { placeId: 3645, cityName: 'VENANCIO AIRES', countryName: 'BRAZIL' },
  { placeId: 3644, cityName: 'VERANOPOLIS', countryName: 'BRAZIL' },
  { placeId: 3643, cityName: 'VIDEIRA', countryName: 'BRAZIL' },
  { placeId: 3659, cityName: 'VILA BELA DA S S TRINDADE BRA*', countryName: 'BRAZIL' },
  { placeId: 3714, cityName: 'VILA BELA', countryName: 'BRAZIL' },
  { placeId: 3715, cityName: 'VILA RICA', countryName: 'BRAZIL' },
  { placeId: 3323, cityName: 'VILHENA', countryName: 'BRAZIL' },
  { placeId: 3539, cityName: 'VIRGEM DA LAPA', countryName: 'BRAZIL' },
  { placeId: 3316, cityName: 'VITORIA DA CONQUISTA', countryName: 'BRAZIL' },
  { placeId: 13620, cityName: 'VITORIA', countryName: 'BRAZIL' },
  { placeId: 3376, cityName: 'VOTUPORANGA', countryName: 'BRAZIL' },
  { placeId: 3719, cityName: 'XAPURI', countryName: 'BRAZIL' },
  { placeId: 3545, cityName: 'XIQUE-XIQUE', countryName: 'BRAZIL' },
  { placeId: 4139, cityName: 'ANEGADA', countryName: 'BRITISH VIRGIN ISLANDS' },
  { placeId: 7732, cityName: 'ROADTOWN', countryName: 'BRITISH VIRGIN ISLANDS' },
  { placeId: 8377, cityName: 'SPANISHTOWN', countryName: 'BRITISH VIRGIN ISLANDS' },
  { placeId: 4138, cityName: 'ANDUKI/SERIA', countryName: 'BRUNEI' },
  { placeId: 2739, cityName: 'BANDAR SERI BEGAWAN', countryName: 'BRUNEI' },
  { placeId: 1393, cityName: 'BURGAS', countryName: 'BULGARIA' },
  { placeId: 1394, cityName: 'GORNA ORYAHOVITSA', countryName: 'BULGARIA' },
  { placeId: 11671, cityName: 'LESNOVO', countryName: 'BULGARIA' },
  { placeId: 1395, cityName: 'PLOVDIV', countryName: 'BULGARIA' },
  { placeId: 12648, cityName: 'PRIMORSKO', countryName: 'BULGARIA' },
  { placeId: 1396, cityName: 'SOFIA', countryName: 'BULGARIA' },
  { placeId: 1397, cityName: 'VARNA', countryName: 'BULGARIA' },
  { placeId: 4120, cityName: 'AMILCAR CABRAL DE GAOUA', countryName: 'BURKINA FASO' },
  { placeId: 4214, cityName: 'ARIBINDA', countryName: 'BURKINA FASO' },
  { placeId: 4218, cityName: 'ARLY', countryName: 'BURKINA FASO' },
  { placeId: 4329, cityName: 'BANFORA', countryName: 'BURKINA FASO' },
  { placeId: 142, cityName: 'BOBO-DIOULASSO', countryName: 'BURKINA FASO' },
  { placeId: 4518, cityName: 'BOGANDE', countryName: 'BURKINA FASO' },
  { placeId: 5091, cityName: 'DEDOUGOU', countryName: 'BURKINA FASO' },
  { placeId: 5168, cityName: 'DIEBOUGOU', countryName: 'BURKINA FASO' },
  { placeId: 5185, cityName: 'DJIBO', countryName: 'BURKINA FASO' },
  { placeId: 5200, cityName: 'DORI', countryName: 'BURKINA FASO' },
  { placeId: 5349, cityName: 'FADA N\'GOURMA', countryName: 'BURKINA FASO' },
  { placeId: 5143, cityName: 'GOROM-GOROM', countryName: 'BURKINA FASO' },
  { placeId: 5889, cityName: 'HOUNDE', countryName: 'BURKINA FASO' },
  { placeId: 6327, cityName: 'KOUDOUGOU', countryName: 'BURKINA FASO' },
  { placeId: 6632, cityName: 'LOUMANA', countryName: 'BURKINA FASO' },
  { placeId: 7318, cityName: 'NOUNA', countryName: 'BURKINA FASO' },
  { placeId: 7423, cityName: 'ORODARA', countryName: 'BURKINA FASO' },
  { placeId: 141, cityName: 'OUAGADOUGOU', countryName: 'BURKINA FASO' },
  { placeId: 7301, cityName: 'OUAHIGOUYA', countryName: 'BURKINA FASO' },
  { placeId: 7541, cityName: 'PAMA', countryName: 'BURKINA FASO' },
  { placeId: 7999, cityName: 'SAFANE', countryName: 'BURKINA FASO' },
  { placeId: 7525, cityName: 'SEBBA', countryName: 'BURKINA FASO' },
  { placeId: 8619, cityName: 'TENKODOGO', countryName: 'BURKINA FASO' },
  { placeId: 8688, cityName: 'TOUGAN', countryName: 'BURKINA FASO' },
  { placeId: 1203, cityName: 'BUJUMBURA', countryName: 'BURUNDI' },
  { placeId: 5570, cityName: 'GITEGA', countryName: 'BURUNDI' },
  { placeId: 6261, cityName: 'KIRUNDO', countryName: 'BURUNDI' },
  { placeId: 9564, cityName: 'AMBERGRIS CAY', countryName: 'CAICOS IS' },
  { placeId: 6966, cityName: 'MIDDLE CAICOS', countryName: 'CAICOS IS' },
  { placeId: 7282, cityName: 'NORTH CAICOS I.', countryName: 'CAICOS IS' },
  { placeId: 7651, cityName: 'PINE CAY I.', countryName: 'CAICOS IS' },
  { placeId: 7772, cityName: 'PROVIDENCIALES I.', countryName: 'CAICOS IS' },
  { placeId: 8326, cityName: 'SOUTH CAICOS I.', countryName: 'CAICOS IS' },
  { placeId: 4176, cityName: 'BATTAMBANG', countryName: 'CAMBODIA' },
  { placeId: 6295, cityName: 'KOH KONG', countryName: 'CAMBODIA' },
  { placeId: 6333, cityName: 'KRATIE', countryName: 'CAMBODIA' },
  { placeId: 7023, cityName: 'MONDULKIRI', countryName: 'CAMBODIA' },
  { placeId: 2838, cityName: 'PHNOM PENH', countryName: 'CAMBODIA' },
  { placeId: 7862, cityName: 'RATANAKIRI', countryName: 'CAMBODIA' },
  { placeId: 2837, cityName: 'SIEM REAP', countryName: 'CAMBODIA' },
  { placeId: 8255, cityName: 'SIHANOUK VILLE', countryName: 'CAMBODIA' },
  { placeId: 8498, cityName: 'STUNG TRENG', countryName: 'CAMBODIA' },
  { placeId: 9145, cityName: 'ABONG-M\'BANG', countryName: 'CAMEROON' },
  { placeId: 4289, cityName: 'BAFIA', countryName: 'CAMEROON' },
  { placeId: 4291, cityName: 'BAFOUSSAM', countryName: 'CAMEROON' },
  { placeId: 4314, cityName: 'BALI', countryName: 'CAMEROON' },
  { placeId: 4320, cityName: 'BAMENDA', countryName: 'CAMEROON' },
  { placeId: 4337, cityName: 'BANYO', countryName: 'CAMEROON' },
  { placeId: 4378, cityName: 'BATOURI', countryName: 'CAMEROON' },
  { placeId: 4444, cityName: 'BERTOUA', countryName: 'CAMEROON' },
  { placeId: 4447, cityName: 'BETARE-OYA', countryName: 'CAMEROON' },
  { placeId: 1131, cityName: 'DOUALA', countryName: 'CAMEROON' },
  { placeId: 5212, cityName: 'DSCHANG', countryName: 'CAMEROON' },
  { placeId: 5260, cityName: 'EBOLOWA', countryName: 'CAMEROON' },
  { placeId: 5030, cityName: 'ESEKA', countryName: 'CAMEROON' },
  { placeId: 5441, cityName: 'FOUMBAN', countryName: 'CAMEROON' },
  { placeId: 5535, cityName: 'GAROUA', countryName: 'CAMEROON' },
  { placeId: 6334, cityName: 'KRIBI', countryName: 'CAMEROON' },
  { placeId: 6743, cityName: 'MAMFE', countryName: 'CAMEROON' },
  { placeId: 6829, cityName: 'MAROUA', countryName: 'CAMEROON' },
  { placeId: 7163, cityName: 'N\'GAOUNDERE', countryName: 'CAMEROON' },
  { placeId: 7166, cityName: 'N\'KONGSAMBA', countryName: 'CAMEROON' },
  { placeId: 8655, cityName: 'TIBATI', countryName: 'CAMEROON' },
  { placeId: 8662, cityName: 'TIKO', countryName: 'CAMEROON' },
  { placeId: 1132, cityName: 'YAOUNDE', countryName: 'CAMEROON' },
  { placeId: 9075, cityName: 'YAOUNDE/VILLE', countryName: 'CAMEROON' },
  { placeId: 9134, cityName: '108 MILE RANCH', countryName: 'CANADA' },
  {
    placeId: 118, cityName: 'ABBOTSFORD', state: 'BC', countryName: 'CANADA',
  },
  { placeId: 4013, cityName: 'ACME', countryName: 'CANADA' },
  { placeId: 4029, cityName: 'AIRDRIE', countryName: 'CANADA' },
  { placeId: 4037, cityName: 'AKLAVIK', countryName: 'CANADA' },
  { placeId: 9517, cityName: 'AKULIVIK', countryName: 'CANADA' },
  { placeId: 4066, cityName: 'ALERT BAY', countryName: 'CANADA' },
  { placeId: 2918, cityName: 'ALERT', countryName: 'CANADA' },
  { placeId: 4071, cityName: 'ALEXANDRIA', countryName: 'CANADA' },
  { placeId: 4074, cityName: 'ALEXIS LAKE', countryName: 'CANADA' },
  { placeId: 4084, cityName: 'ALLISTON', countryName: 'CANADA' },
  { placeId: 4086, cityName: 'ALMA', countryName: 'CANADA' },
  { placeId: 4095, cityName: 'ALTONA', countryName: 'CANADA' },
  { placeId: 4121, cityName: 'AMOS', countryName: 'CANADA' },
  { placeId: 4125, cityName: 'ANAHIM LAKE', countryName: 'CANADA' },
  { placeId: 4134, cityName: 'ANDREW', countryName: 'CANADA' },
  { placeId: 9596, cityName: 'APPLE RIVER', countryName: 'CANADA' },
  { placeId: 4204, cityName: 'ARBORFIELD', countryName: 'CANADA' },
  { placeId: 4205, cityName: 'ARBORG', countryName: 'CANADA' },
  { placeId: 4208, cityName: 'ARCOLA', countryName: 'CANADA' },
  { placeId: 9608, cityName: 'ARCTIC BAY', countryName: 'CANADA' },
  { placeId: 4221, cityName: 'ARMSTRONG', countryName: 'CANADA' },
  { placeId: 9616, cityName: 'ARNES', countryName: 'CANADA' },
  { placeId: 4222, cityName: 'ARNPRIOR', countryName: 'CANADA' },
  { placeId: 4223, cityName: 'ARNSTEIN', countryName: 'CANADA' },
  { placeId: 4227, cityName: 'ARTHUR', countryName: 'CANADA' },
  { placeId: 9624, cityName: 'ARVIAT', countryName: 'CANADA' },
  { placeId: 4238, cityName: 'ASHERN', countryName: 'CANADA' },
  { placeId: 4248, cityName: 'ASSINIBOIA', countryName: 'CANADA' },
  { placeId: 4257, cityName: 'ATHABASCA', countryName: 'CANADA' },
  { placeId: 4263, cityName: 'ATIKOKAN', countryName: 'CANADA' },
  { placeId: 4267, cityName: 'ATLIN', countryName: 'CANADA' },
  { placeId: 4272, cityName: 'ATTAWAPISKAT', countryName: 'CANADA' },
  { placeId: 4274, cityName: 'ATWOOD', countryName: 'CANADA' },
  { placeId: 3972, cityName: 'AUPALUK', countryName: 'CANADA' },
  { placeId: 9682, cityName: 'AXE LAKE', countryName: 'CANADA' },
  { placeId: 4288, cityName: 'BADDECK', countryName: 'CANADA' },
  {
    placeId: 28, cityName: 'BAGOTVILLE', state: 'QC', countryName: 'CANADA',
  },
  { placeId: 9692, cityName: 'BAIE-COMEAU', countryName: 'CANADA' },
  { placeId: 4302, cityName: 'BAIE-COMEAU', countryName: 'CANADA' },
  { placeId: 29, cityName: 'BAKER LAKE', countryName: 'CANADA' },
  { placeId: 4312, cityName: 'BALDWIN', countryName: 'CANADA' },
  { placeId: 4323, cityName: 'BANCROFT', countryName: 'CANADA' },
  { placeId: 4328, cityName: 'BANFF', countryName: 'CANADA' },
  { placeId: 4338, cityName: 'BAR RIVER', countryName: 'CANADA' },
  { placeId: 4350, cityName: 'BARKERVILLE', countryName: 'CANADA' },
  { placeId: 4356, cityName: 'BARRHEAD', countryName: 'CANADA' },
  { placeId: 9730, cityName: 'BARRIE-ORILLIA', countryName: 'CANADA' },
  { placeId: 4357, cityName: 'BARRIE-ORILLIA', countryName: 'CANADA' },
  { placeId: 4358, cityName: 'BARRY\'S BAY', countryName: 'CANADA' },
  { placeId: 4366, cityName: 'BASHAW', countryName: 'CANADA' },
  { placeId: 4368, cityName: 'BASSANO', countryName: 'CANADA' },
  { placeId: 138, cityName: 'BATHURST', countryName: 'CANADA' },
  { placeId: 4380, cityName: 'BAWLF', countryName: 'CANADA' },
  { placeId: 2900, cityName: 'BEARSKIN LAKE', countryName: 'CANADA' },
  { placeId: 4387, cityName: 'BEAUVAL', countryName: 'CANADA' },
  { placeId: 4389, cityName: 'BEAVER CREEK', countryName: 'CANADA' },
  { placeId: 4391, cityName: 'BEAVERLEY', countryName: 'CANADA' },
  { placeId: 4392, cityName: 'BEAVERLODGE', countryName: 'CANADA' },
  { placeId: 4398, cityName: 'BEECHY', countryName: 'CANADA' },
  { placeId: 4403, cityName: 'BEISEKER', countryName: 'CANADA' },
  { placeId: 9789, cityName: 'BELL ISLAND', countryName: 'CANADA' },
  { placeId: 26, cityName: 'BELLA BELLA', countryName: 'CANADA' },
  { placeId: 4409, cityName: 'BELLA COOLA', countryName: 'CANADA' },
  { placeId: 4413, cityName: 'BELLEVILLE', countryName: 'CANADA' },
  { placeId: 4439, cityName: 'BERENS RIVER', countryName: 'CANADA' },
  { placeId: 4466, cityName: 'BIG SAND LAKE', countryName: 'CANADA' },
  { placeId: 95, cityName: 'BIG TROUT LAKE', countryName: 'CANADA' },
  { placeId: 4469, cityName: 'BIGGAR', countryName: 'CANADA' },
  { placeId: 4482, cityName: 'BIRCH HILLS', countryName: 'CANADA' },
  { placeId: 4483, cityName: 'BIRCH MOUNTAIN', countryName: 'CANADA' },
  { placeId: 4486, cityName: 'BIRD RIVER', countryName: 'CANADA' },
  { placeId: 4496, cityName: 'BITUMOUNT', countryName: 'CANADA' },
  { placeId: 4498, cityName: 'BJORGUM FARM', countryName: 'CANADA' },
  { placeId: 4499, cityName: 'BLACK DIAMOND', countryName: 'CANADA' },
  { placeId: 9853, cityName: 'BLACK TICKLE', countryName: 'CANADA' },
  { placeId: 4501, cityName: 'BLACKIE', countryName: 'CANADA' },
  { placeId: 9865, cityName: 'BLEIBLER RANCH', countryName: 'CANADA' },
  { placeId: 4508, cityName: 'BLOODVEIN RIVER', countryName: 'CANADA' },
  { placeId: 4510, cityName: 'BLUE RIVER', countryName: 'CANADA' },
  { placeId: 4513, cityName: 'BOB QUINN LAKE', countryName: 'CANADA' },
  { placeId: 4521, cityName: 'BOISSEVAIN', countryName: 'CANADA' },
  { placeId: 4534, cityName: 'BONAVENTURE', countryName: 'CANADA' },
  { placeId: 4540, cityName: 'BONNYVILLE', countryName: 'CANADA' },
  { placeId: 4558, cityName: 'BOSTON BROOK', countryName: 'CANADA' },
  { placeId: 9901, cityName: 'BOUNDARY BAY', countryName: 'CANADA' },
  { placeId: 4575, cityName: 'BOW ISLAND', countryName: 'CANADA' },
  { placeId: 4576, cityName: 'BOYLE', countryName: 'CANADA' },
  { placeId: 4580, cityName: 'BRADFORD', countryName: 'CANADA' },
  { placeId: 4583, cityName: 'BRAEBURN', countryName: 'CANADA' },
  { placeId: 4586, cityName: 'BRAMPTON', countryName: 'CANADA' },
  { placeId: 31, cityName: 'BRANDON', countryName: 'CANADA' },
  { placeId: 4588, cityName: 'BRANT', countryName: 'CANADA' },
  { placeId: 4589, cityName: 'BRANTFORD', countryName: 'CANADA' },
  { placeId: 4598, cityName: 'BRIERCREST SOUTH', countryName: 'CANADA' },
  { placeId: 4600, cityName: 'BRISTOL', countryName: 'CANADA' },
  { placeId: 4603, cityName: 'BROCHET', countryName: 'CANADA' },
  { placeId: 9943, cityName: 'BROCKVILLE', countryName: 'CANADA' },
  { placeId: 4604, cityName: 'BROCKWAY', countryName: 'CANADA' },
  { placeId: 4606, cityName: 'BROMONT', countryName: 'CANADA' },
  { placeId: 4609, cityName: 'BROOKS', countryName: 'CANADA' },
  { placeId: 4613, cityName: 'BRUSSELS', countryName: 'CANADA' },
  { placeId: 106, cityName: 'BUFFALO NARROWS', countryName: 'CANADA' },
  { placeId: 4643, cityName: 'BURLINGTON', countryName: 'CANADA' },
  { placeId: 4649, cityName: 'BURNS LAKE', countryName: 'CANADA' },
  { placeId: 35, cityName: 'BURWASH', countryName: 'CANADA' },
  { placeId: 10002, cityName: 'CABLE HEAD AIRPARK', countryName: 'CANADA' },
  { placeId: 4667, cityName: 'CABRI', countryName: 'CANADA' },
  { placeId: 4668, cityName: 'CACHE CREEK', countryName: 'CANADA' },
  { placeId: 4670, cityName: 'CADOTTE', countryName: 'CANADA' },
  {
    placeId: 121, cityName: 'CALGARY', state: 'AB', countryName: 'CANADA',
  },
  { placeId: 4687, cityName: 'CALLING LAKE', countryName: 'CANADA' },
  { placeId: 10028, cityName: 'CAMBRIDGE BAY', countryName: 'CANADA' },
  { placeId: 4695, cityName: 'CAMBRIDGE', countryName: 'CANADA' },
  { placeId: 30, cityName: 'CAMPBELL RIVER', countryName: 'CANADA' },
  { placeId: 4708, cityName: 'CAMROSE', countryName: 'CANADA' },
  { placeId: 4709, cityName: 'CAMSELL PORTAGE', countryName: 'CANADA' },
  { placeId: 4715, cityName: 'CANDLE LAKE AIRPARK', countryName: 'CANADA' },
  { placeId: 10052, cityName: 'CANIAPISCAU', countryName: 'CANADA' },
  { placeId: 4716, cityName: 'CANORA', countryName: 'CANADA' },
  { placeId: 10059, cityName: 'CAPE DORSET', countryName: 'CANADA' },
  { placeId: 4729, cityName: 'CARCROSS', countryName: 'CANADA' },
  { placeId: 4730, cityName: 'CARDSTON', countryName: 'CANADA' },
  { placeId: 4732, cityName: 'CAREY LAKE', countryName: 'CANADA' },
  { placeId: 4734, cityName: 'CARLETON PLACE', countryName: 'CANADA' },
  { placeId: 4736, cityName: 'CARLYLE', countryName: 'CANADA' },
  { placeId: 4737, cityName: 'CARMACKS', countryName: 'CANADA' },
  { placeId: 4738, cityName: 'CARMAN', countryName: 'CANADA' },
  { placeId: 4746, cityName: 'CARP', countryName: 'CANADA' },
  { placeId: 4194, cityName: 'CARSTAIRS', countryName: 'CANADA' },
  { placeId: 32, cityName: 'CARTWRIGHT', countryName: 'CANADA' },
  { placeId: 4754, cityName: 'CASTLEGAR', countryName: 'CANADA' },
  { placeId: 4755, cityName: 'CASTOR', countryName: 'CANADA' },
  { placeId: 2909, cityName: 'CAT LAKE', countryName: 'CANADA' },
  { placeId: 4763, cityName: 'CAUSAPSCAL', countryName: 'CANADA' },
  {
    placeId: 4765, cityName: 'CAYLEY', state: 'AB', countryName: 'CANADA',
  },
  { placeId: 4775, cityName: 'CENTRAL BUTTE', countryName: 'CANADA' },
  { placeId: 4777, cityName: 'CENTRALIA', countryName: 'CANADA' },
  { placeId: 4786, cityName: 'CHAPLEAU', countryName: 'CANADA' },
  { placeId: 4787, cityName: 'CHAPMAN LAKE', countryName: 'CANADA' },
  {
    placeId: 2919, cityName: 'CHARLEVOIX', state: 'QC', countryName: 'CANADA',
  },
  { placeId: 4792, cityName: 'CHARLO', countryName: 'CANADA' },
  { placeId: 2882, cityName: 'CHARLOTTETOWN', countryName: 'CANADA' },
  { placeId: 10152, cityName: 'CHARLOTTETOWN', countryName: 'CANADA' },
  { placeId: 4795, cityName: 'CHATHAM', countryName: 'CANADA' },
  { placeId: 4799, cityName: 'CHEADLE', countryName: 'CANADA' },
  { placeId: 10174, cityName: 'CHESTERFIELD INLET', countryName: 'CANADA' },
  { placeId: 4809, cityName: 'CHESTERMERE', countryName: 'CANADA' },
  { placeId: 2912, cityName: 'CHETWYND', countryName: 'CANADA' },
  { placeId: 10179, cityName: 'CHEVERY', countryName: 'CANADA' },
  { placeId: 4813, cityName: 'CHIBOUGAMAU', countryName: 'CANADA' },
  { placeId: 4824, cityName: 'CHILKO LAKE', countryName: 'CANADA' },
  { placeId: 4828, cityName: 'CHILLIWACK', countryName: 'CANADA' },
  { placeId: 4835, cityName: 'CHINCHAGA', countryName: 'CANADA' },
  { placeId: 4841, cityName: 'CHIPEWYAN LAKE', countryName: 'CANADA' },
  { placeId: 4842, cityName: 'CHIPMAN', countryName: 'CANADA' },
  { placeId: 4843, cityName: 'CHISASIBI', countryName: 'CANADA' },
  { placeId: 4857, cityName: 'CHRISTINA BASIN', countryName: 'CANADA' },
  { placeId: 10216, cityName: 'CHRISTINA LAKE', countryName: 'CANADA' },
  { placeId: 2897, cityName: 'CHURCHBRIDGE', countryName: 'CANADA' },
  { placeId: 139, cityName: 'CHURCHILL FALLS', countryName: 'CANADA' },
  { placeId: 128, cityName: 'CHURCHILL', countryName: 'CANADA' },
  { placeId: 4863, cityName: 'CIGAR LAKE', countryName: 'CANADA' },
  { placeId: 10240, cityName: 'CLARENVILLE', countryName: 'CANADA' },
  { placeId: 4875, cityName: 'CLARESHOLM', countryName: 'CANADA' },
  { placeId: 4891, cityName: 'CLEARWATER RIVER', countryName: 'CANADA' },
  { placeId: 4889, cityName: 'CLEARWATER', countryName: 'CANADA' },
  { placeId: 4893, cityName: 'CLEMENTSPORT MAPLECOVE', countryName: 'CANADA' },
  { placeId: 4906, cityName: 'CLUFF LAKE', countryName: 'CANADA' },
  { placeId: 10280, cityName: 'CLYDE RIVER', countryName: 'CANADA' },
  { placeId: 4908, cityName: 'COBDEN ONTARIO', countryName: 'CANADA' },
  { placeId: 4911, cityName: 'COCHRANE', countryName: 'CANADA' },
  { placeId: 68, cityName: 'COLD LAKE', countryName: 'CANADA' },
  { placeId: 4925, cityName: 'COLLINGWOOD', countryName: 'CANADA' },
  { placeId: 4926, cityName: 'COLLINS BAY', countryName: 'CANADA' },
  { placeId: 4004, cityName: 'COLVILLE LAKE', countryName: 'CANADA' },
  { placeId: 10318, cityName: 'COMOX', countryName: 'CANADA' },
  { placeId: 4942, cityName: 'CONKLIN', countryName: 'CANADA' },
  { placeId: 4945, cityName: 'CONSORT', countryName: 'CANADA' },
  { placeId: 4953, cityName: 'COOKSTOWN', countryName: 'CANADA' },
  { placeId: 10336, cityName: 'CORAL HARBOUR', countryName: 'CANADA' },
  { placeId: 2911, cityName: 'CORNWALL', countryName: 'CANADA' },
  { placeId: 4966, cityName: 'CORONACH', countryName: 'CANADA' },
  { placeId: 4967, cityName: 'CORONATION', countryName: 'CANADA' },
  { placeId: 4969, cityName: 'CORTES ISLAND', countryName: 'CANADA' },
  { placeId: 4974, cityName: 'COTTAM', countryName: 'CANADA' },
  { placeId: 10357, cityName: 'COURTENAY', countryName: 'CANADA' },
  { placeId: 4978, cityName: 'COURTENAY', countryName: 'CANADA' },
  { placeId: 4979, cityName: 'COUTTS', countryName: 'CANADA' },
  { placeId: 4981, cityName: 'COWLEY', countryName: 'CANADA' },
  { placeId: 4986, cityName: 'CRAIK', countryName: 'CANADA' },
  { placeId: 110, cityName: 'CRANBROOK', countryName: 'CANADA' },
  { placeId: 2875, cityName: 'CRAWFORD BAY', countryName: 'CANADA' },
  { placeId: 10368, cityName: 'CREE LAKE', countryName: 'CANADA' },
  { placeId: 2874, cityName: 'CRESTON', countryName: 'CANADA' },
  { placeId: 4997, cityName: 'CROSS LAKE', countryName: 'CANADA' },
  { placeId: 5000, cityName: 'CRYSTAL CITY-PILOT MOUND', countryName: 'CANADA' },
  { placeId: 5004, cityName: 'CUDWORTH', countryName: 'CANADA' },
  { placeId: 5010, cityName: 'CUMBERLAND HOUSE', countryName: 'CANADA' },
  { placeId: 5015, cityName: 'CUT KNIFE', countryName: 'CANADA' },
  { placeId: 37, cityName: 'DAUPHIN', countryName: 'CANADA' },
  { placeId: 5071, cityName: 'DAVIDSON', countryName: 'CANADA' },
  { placeId: 5072, cityName: 'DAVIN LAKE', countryName: 'CANADA' },
  { placeId: 2914, cityName: 'DAWSON CREEK', countryName: 'CANADA' },
  { placeId: 34, cityName: 'DAWSON', countryName: 'CANADA' },
  { placeId: 5080, cityName: 'DE WINTON', countryName: 'CANADA' },
  { placeId: 5082, cityName: 'DEASE LAKE', countryName: 'CANADA' },
  { placeId: 5083, cityName: 'DEBDEN', countryName: 'CANADA' },
  { placeId: 5084, cityName: 'DEBERT', countryName: 'CANADA' },
  { placeId: 5086, cityName: 'DEBOLT', countryName: 'CANADA' },
  { placeId: 2904, cityName: 'DEEP RIVER', countryName: 'CANADA' },
  { placeId: 36, cityName: 'DEER LAKE', countryName: 'CANADA' },
  { placeId: 2923, cityName: 'DEER LAKE', countryName: 'CANADA' },
  { placeId: 10458, cityName: 'DEL BONITA', countryName: 'CANADA' },
  { placeId: 5102, cityName: 'DELINE', countryName: 'CANADA' },
  { placeId: 5103, cityName: 'DELORAINE', countryName: 'CANADA' },
  { placeId: 5149, cityName: 'DELTA AIR PARK', countryName: 'CANADA' },
  { placeId: 5165, cityName: 'DIAVIK', countryName: 'CANADA' },
  { placeId: 10489, cityName: 'DIDSBURY', countryName: 'CANADA' },
  { placeId: 5172, cityName: 'DIGBY', countryName: 'CANADA' },
  { placeId: 5181, cityName: 'DINSMORE', countryName: 'CANADA' },
  { placeId: 5191, cityName: 'DOIG', countryName: 'CANADA' },
  { placeId: 5194, cityName: 'DOLBEAU-ST-FELICIEN', countryName: 'CANADA' },
  { placeId: 5198, cityName: 'DONNELLY', countryName: 'CANADA' },
  { placeId: 5199, cityName: 'DORE LAKE', countryName: 'CANADA' },
  { placeId: 10506, cityName: 'DORIS LAKE', countryName: 'CANADA' },
  { placeId: 5208, cityName: 'DOUGLAS LAKE', countryName: 'CANADA' },
  { placeId: 5020, cityName: 'DRAYTON VALLEY', countryName: 'CANADA' },
  { placeId: 5022, cityName: 'DRUMHELLER', countryName: 'CANADA' },
  { placeId: 48, cityName: 'DRYDEN', countryName: 'CANADA' },
  { placeId: 5219, cityName: 'DUNCAN', countryName: 'CANADA' },
  { placeId: 10534, cityName: 'DUNNVILLE', countryName: 'CANADA' },
  { placeId: 5227, cityName: 'DURHAM', countryName: 'CANADA' },
  { placeId: 10540, cityName: 'DWIGHT', countryName: 'CANADA' },
  { placeId: 2898, cityName: 'EAGLE RIVER', countryName: 'CANADA' },
  { placeId: 10550, cityName: 'EAGLESHAM', countryName: 'CANADA' },
  { placeId: 2920, cityName: 'EAR FALLS', countryName: 'CANADA' },
  { placeId: 2925, cityName: 'EARLTON', countryName: 'CANADA' },
  { placeId: 5247, cityName: 'EASTEND', countryName: 'CANADA' },
  { placeId: 5248, cityName: 'EASTERVILLE', countryName: 'CANADA' },
  { placeId: 5250, cityName: 'EASTMAIN RIVER', countryName: 'CANADA' },
  { placeId: 5256, cityName: 'EATONIA', countryName: 'CANADA' },
  { placeId: 5261, cityName: 'ECHO VALLEY', countryName: 'CANADA' },
  { placeId: 5262, cityName: 'EDAM', countryName: 'CANADA' },
  { placeId: 5265, cityName: 'EDDONTENAJON', countryName: 'CANADA' },
  { placeId: 5266, cityName: 'EDENVALE', countryName: 'CANADA' },
  {
    placeId: 38, cityName: 'EDMONTON', state: 'AB', countryName: 'CANADA',
  },
  { placeId: 10573, cityName: 'EDMONTON', countryName: 'CANADA' },
  { placeId: 10574, cityName: 'EDMUNDSTON', countryName: 'CANADA' },
  { placeId: 5268, cityName: 'EDRA', countryName: 'CANADA' },
  { placeId: 5269, cityName: 'EDSON', countryName: 'CANADA' },
  { placeId: 5274, cityName: 'EKATI', countryName: 'CANADA' },
  { placeId: 10592, cityName: 'ELEONORE', countryName: 'CANADA' },
  { placeId: 5108, cityName: 'ELK ISLAND', countryName: 'CANADA' },
  { placeId: 5109, cityName: 'ELK LAKE', countryName: 'CANADA' },
  { placeId: 5110, cityName: 'ELK POINT', countryName: 'CANADA' },
  { placeId: 5114, cityName: 'ELKIN CREEK', countryName: 'CANADA' },
  { placeId: 2876, cityName: 'ELKO', countryName: 'CANADA' },
  { placeId: 5303, cityName: 'ELLIOT LAKE', countryName: 'CANADA' },
  { placeId: 5305, cityName: 'ELMIRA', countryName: 'CANADA' },
  { placeId: 5311, cityName: 'EMBARRAS', countryName: 'CANADA' },
  { placeId: 5312, cityName: 'EMBRUN', countryName: 'CANADA' },
  { placeId: 2915, cityName: 'EMPRESS', countryName: 'CANADA' },
  { placeId: 5319, cityName: 'EMSDALE', countryName: 'CANADA' },
  { placeId: 5023, cityName: 'ERICKSON', countryName: 'CANADA' },
  { placeId: 5331, cityName: 'ESSEX', countryName: 'CANADA' },
  { placeId: 5334, cityName: 'ESTERHAZY', countryName: 'CANADA' },
  { placeId: 5335, cityName: 'ESTEVAN', countryName: 'CANADA' },
  { placeId: 5337, cityName: 'ESTON', countryName: 'CANADA' },
  { placeId: 5338, cityName: 'ETHEL', countryName: 'CANADA' },
  { placeId: 10654, cityName: 'EUREKA', countryName: 'CANADA' },
  { placeId: 5345, cityName: 'EXPLOITS VALLEY', countryName: 'CANADA' },
  { placeId: 2913, cityName: 'FAIRMONT HOT SPRINGS', countryName: 'CANADA' },
  { placeId: 5358, cityName: 'FAIRVIEW', countryName: 'CANADA' },
  { placeId: 5375, cityName: 'FARNHAM', countryName: 'CANADA' },
  { placeId: 5376, cityName: 'FARO', countryName: 'CANADA' },
  { placeId: 5387, cityName: 'FERGUS', countryName: 'CANADA' },
  { placeId: 5390, cityName: 'FERLAND', countryName: 'CANADA' },
  { placeId: 5395, cityName: 'FILLMORE', countryName: 'CANADA' },
  { placeId: 5399, cityName: 'FINLAY', countryName: 'CANADA' },
  { placeId: 5402, cityName: 'FISHER BRANCH', countryName: 'CANADA' },
  { placeId: 5409, cityName: 'FLIN FLON', countryName: 'CANADA' },
  { placeId: 5413, cityName: 'FLORENCEVILLE', countryName: 'CANADA' },
  { placeId: 5418, cityName: 'FOGO', countryName: 'CANADA' },
  { placeId: 5421, cityName: 'FOND-DU-LAC', countryName: 'CANADA' },
  { placeId: 5422, cityName: 'FONTANGES', countryName: 'CANADA' },
  { placeId: 5423, cityName: 'FONTAS', countryName: 'CANADA' },
  { placeId: 5424, cityName: 'FORD BAY', countryName: 'CANADA' },
  { placeId: 5425, cityName: 'FORDWICH', countryName: 'CANADA' },
  { placeId: 5426, cityName: 'FOREMOST', countryName: 'CANADA' },
  { placeId: 5428, cityName: 'FORESTBURG', countryName: 'CANADA' },
  { placeId: 10735, cityName: 'FORESTVILLE', countryName: 'CANADA' },
  { placeId: 10738, cityName: 'FORT ERIE', countryName: 'CANADA' },
  { placeId: 5430, cityName: 'FORT GRAHAM', countryName: 'CANADA' },
  { placeId: 5431, cityName: 'FORT LANGLEY', countryName: 'CANADA' },
  { placeId: 5432, cityName: 'FORT MACKAY', countryName: 'CANADA' },
  { placeId: 5433, cityName: 'FORT MACLEOD', countryName: 'CANADA' },
  { placeId: 5436, cityName: 'FORT NELSON', countryName: 'CANADA' },
  { placeId: 5443, cityName: 'FOX CREEK', countryName: 'CANADA' },
  { placeId: 5444, cityName: 'FOX LAKE', countryName: 'CANADA' },
  { placeId: 5451, cityName: 'FRASER LAKE', countryName: 'CANADA' },
  { placeId: 41, cityName: 'FREDERICTON', countryName: 'CANADA' },
  { placeId: 5126, cityName: 'FRONTIER', countryName: 'CANADA' },
  { placeId: 5129, cityName: 'FT ALBANY', countryName: 'CANADA' },
  { placeId: 5458, cityName: 'FT CHIPEWYAN', countryName: 'CANADA' },
  { placeId: 10789, cityName: 'FT FRANCES', countryName: 'CANADA' },
  { placeId: 5462, cityName: 'FT GOOD HOPE', countryName: 'CANADA' },
  { placeId: 5464, cityName: 'FT HOPE', countryName: 'CANADA' },
  { placeId: 5470, cityName: 'FT LIARD', countryName: 'CANADA' },
  { placeId: 5471, cityName: 'FT MACLEOD', countryName: 'CANADA' },
  { placeId: 5472, cityName: 'FT MC PHERSON', countryName: 'CANADA' },
  {
    placeId: 65, cityName: 'FT MCMURRAY', state: 'AB', countryName: 'CANADA',
  },
  { placeId: 122, cityName: 'FT NELSON', countryName: 'CANADA' },
  { placeId: 5476, cityName: 'FT PROVIDENCE', countryName: 'CANADA' },
  { placeId: 5477, cityName: 'FT RESOLUTION', countryName: 'CANADA' },
  { placeId: 5480, cityName: 'FT SEVERN', countryName: 'CANADA' },
  { placeId: 5482, cityName: 'FT SIMPSON ISLAND', countryName: 'CANADA' },
  { placeId: 42, cityName: 'FT SIMPSON', countryName: 'CANADA' },
  { placeId: 93, cityName: 'FT SMITH', countryName: 'CANADA' },
  { placeId: 5484, cityName: 'FT ST JAMES', countryName: 'CANADA' },
  { placeId: 113, cityName: 'FT ST JOHN', countryName: 'CANADA' },
  { placeId: 5488, cityName: 'FT VERMILION', countryName: 'CANADA' },
  { placeId: 5489, cityName: 'FT WARE', countryName: 'CANADA' },
  { placeId: 2899, cityName: 'GAINSBOROUGH', countryName: 'CANADA' },
  { placeId: 5521, cityName: 'GAMETI', countryName: 'CANADA' },
  { placeId: 10860, cityName: 'GANANOQUE', countryName: 'CANADA' },
  {
    placeId: 87, cityName: 'GANDER', state: 'NF', countryName: 'CANADA',
  },
  { placeId: 5523, cityName: 'GANG RANCH', countryName: 'CANADA' },
  { placeId: 5530, cityName: 'GARDEN RIVER', countryName: 'CANADA' },
  { placeId: 10871, cityName: 'GASPE', countryName: 'CANADA' },
  { placeId: 2921, cityName: 'GATINEAU', countryName: 'CANADA' },
  { placeId: 44, cityName: 'GERALDTON', countryName: 'CANADA' },
  { placeId: 5562, cityName: 'GILBERT PLAINS', countryName: 'CANADA' },
  { placeId: 47, cityName: 'GILLAM', countryName: 'CANADA' },
  { placeId: 5566, cityName: 'GIMLI', countryName: 'CANADA' },
  { placeId: 49, cityName: 'GJOA HAVEN', countryName: 'CANADA' },
  { placeId: 5573, cityName: 'GLADSTONE', countryName: 'CANADA' },
  { placeId: 5575, cityName: 'GLASLYN', countryName: 'CANADA' },
  { placeId: 5576, cityName: 'GLENBORO', countryName: 'CANADA' },
  { placeId: 5580, cityName: 'GLENDON', countryName: 'CANADA' },
  { placeId: 5592, cityName: 'GODERICH', countryName: 'CANADA' },
  { placeId: 5594, cityName: 'GODS LAKE NARROWS', countryName: 'CANADA' },
  { placeId: 5593, cityName: 'GODS LAKE', countryName: 'CANADA' },
  { placeId: 5595, cityName: 'GODS RIVER', countryName: 'CANADA' },
  { placeId: 2916, cityName: 'GOLDEN', countryName: 'CANADA' },
  { placeId: 5133, cityName: 'GOODSOIL', countryName: 'CANADA' },
  {
    placeId: 10929,
    cityName: 'GOOSE BAY',
    state: 'NEWFOUNDLAND AND LABRADOR',
    countryName: 'CANADA',
  },
  { placeId: 5136, cityName: 'GORDON LAKE', countryName: 'CANADA' },
  { placeId: 5139, cityName: 'GORE BAY', countryName: 'CANADA' },
  { placeId: 5666, cityName: 'GRAND BEND', countryName: 'CANADA' },
  { placeId: 5670, cityName: 'GRAND FALLS', countryName: 'CANADA' },
  { placeId: 5671, cityName: 'GRAND FORKS', countryName: 'CANADA' },
  { placeId: 10954, cityName: 'GRAND MANAN', countryName: 'CANADA' },
  { placeId: 5676, cityName: 'GRAND RAPIDS', countryName: 'CANADA' },
  { placeId: 5678, cityName: 'GRAND RIVER', countryName: 'CANADA' },
  { placeId: 5683, cityName: 'GRANDE CACHE', countryName: 'CANADA' },
  { placeId: 84, cityName: 'GRANDE PRAIRIE', countryName: 'CANADA' },
  { placeId: 10963, cityName: 'GRANDE-RIVIERE', countryName: 'CANADA' },
  { placeId: 5681, cityName: 'GRANDE', countryName: 'CANADA' },
  { placeId: 10964, cityName: 'GRANDES-BERGERONNES', countryName: 'CANADA' },
  { placeId: 5685, cityName: 'GRANT', countryName: 'CANADA' },
  { placeId: 5689, cityName: 'GRAVELBOURG', countryName: 'CANADA' },
  { placeId: 5694, cityName: 'GREAT BEAR LAKE', countryName: 'CANADA' },
  { placeId: 5697, cityName: 'GREAT SLAVE LAKE LODGE', countryName: 'CANADA' },
  { placeId: 5034, cityName: 'GREEN LAKE', countryName: 'CANADA' },
  { placeId: 5698, cityName: 'GREENBANK', countryName: 'CANADA' },
  { placeId: 136, cityName: 'GREENWOOD', countryName: 'CANADA' },
  { placeId: 5711, cityName: 'GRENFELL', countryName: 'CANADA' },
  { placeId: 5715, cityName: 'GRIMSBY', countryName: 'CANADA' },
  { placeId: 5717, cityName: 'GRIMSHAW', countryName: 'CANADA' },
  { placeId: 11005, cityName: 'GRISE FIORD', countryName: 'CANADA' },
  { placeId: 5719, cityName: 'GRIST LAKE', countryName: 'CANADA' },
  { placeId: 5735, cityName: 'GUELPH', countryName: 'CANADA' },
  { placeId: 5740, cityName: 'GULL LAKE', countryName: 'CANADA' },
  { placeId: 5766, cityName: 'HAFFORD', countryName: 'CANADA' },
  { placeId: 5772, cityName: 'HAINES JUNCTION', countryName: 'CANADA' },
  { placeId: 5776, cityName: 'HALIBURTON', countryName: 'CANADA' },
  { placeId: 53, cityName: 'HALIFAX', countryName: 'CANADA' },
  { placeId: 11046, cityName: 'HALL BEACH', countryName: 'CANADA' },
  {
    placeId: 50, cityName: 'HAMILTON', state: 'ON', countryName: 'CANADA',
  },
  { placeId: 5792, cityName: 'HANLEY', countryName: 'CANADA' },
  { placeId: 5794, cityName: 'HANNA', countryName: 'CANADA' },
  { placeId: 5796, cityName: 'HANOVER', countryName: 'CANADA' },
  { placeId: 11071, cityName: 'HANOVER', countryName: 'CANADA' },
  { placeId: 5799, cityName: 'HARBOUR GRACE', countryName: 'CANADA' },
  { placeId: 5801, cityName: 'HARDISTY', countryName: 'CANADA' },
  { placeId: 5810, cityName: 'HARROW', countryName: 'CANADA' },
  { placeId: 5616, cityName: 'HATCHET LAKE LODGE', countryName: 'CANADA' },
  { placeId: 5622, cityName: 'HAVELOCK', countryName: 'CANADA' },
  { placeId: 45, cityName: 'HAVRE ST-PIERRE', countryName: 'CANADA' },
  { placeId: 2903, cityName: 'HAWKESBURY', countryName: 'CANADA' },
  { placeId: 52, cityName: 'HAY RIVER', countryName: 'CANADA' },
  { placeId: 5821, cityName: 'HEARST', countryName: 'CANADA' },
  { placeId: 5826, cityName: 'HELMET', countryName: 'CANADA' },
  { placeId: 5836, cityName: 'HESPERO', countryName: 'CANADA' },
  { placeId: 69, cityName: 'HIGH LEVEL', countryName: 'CANADA' },
  { placeId: 5840, cityName: 'HIGH PRAIRIE', countryName: 'CANADA' },
  { placeId: 5841, cityName: 'HIGH RIVER', countryName: 'CANADA' },
  { placeId: 5843, cityName: 'HIGHGATE', countryName: 'CANADA' },
  { placeId: 5852, cityName: 'HINTON', countryName: 'CANADA' },
  { placeId: 5864, cityName: 'HOLLAND LANDING AIRPARK', countryName: 'CANADA' },
  { placeId: 5873, cityName: 'HOMEWOOD', countryName: 'CANADA' },
  { placeId: 11176, cityName: 'HOPE BAY', countryName: 'CANADA' },
  { placeId: 5879, cityName: 'HOPE', countryName: 'CANADA' },
  { placeId: 11177, cityName: 'HOPEDALE', countryName: 'CANADA' },
  { placeId: 5880, cityName: 'HOPEWELL', countryName: 'CANADA' },
  { placeId: 5881, cityName: 'HORNEPAYNE', countryName: 'CANADA' },
  { placeId: 5890, cityName: 'HOUSTON', countryName: 'CANADA' },
  { placeId: 5901, cityName: 'HUDSON BAY', countryName: 'CANADA' },
  { placeId: 2922, cityName: 'HUDSON\'S HOPE', countryName: 'CANADA' },
  { placeId: 5905, cityName: 'HUMBOLDT', countryName: 'CANADA' },
  { placeId: 5908, cityName: 'HUNTSVILLE', countryName: 'CANADA' },
  { placeId: 5918, cityName: 'HYLAND', countryName: 'CANADA' },
  { placeId: 11232, cityName: 'IGLOOLIK', countryName: 'CANADA' },
  { placeId: 2930, cityName: 'IGNACE', countryName: 'CANADA' },
  { placeId: 5942, cityName: 'ILE AUX COUDRES', countryName: 'CANADA' },
  { placeId: 5943, cityName: 'ILE-A-LA-CROSSE', countryName: 'CANADA' },
  { placeId: 5944, cityName: 'ILE-AUX-GRUES', countryName: 'CANADA' },
  { placeId: 11239, cityName: 'ILES-DE-LA-MADELEINE', countryName: 'CANADA' },
  { placeId: 5946, cityName: 'ILFORD', countryName: 'CANADA' },
  { placeId: 5955, cityName: 'IMPERIAL', countryName: 'CANADA' },
  { placeId: 5967, cityName: 'INDUS', countryName: 'CANADA' },
  { placeId: 5970, cityName: 'INGENIKA', countryName: 'CANADA' },
  { placeId: 5974, cityName: 'INNISFAIL', countryName: 'CANADA' },
  { placeId: 11262, cityName: 'INUKJUAK', countryName: 'CANADA' },
  { placeId: 39, cityName: 'INUVIK', countryName: 'CANADA' },
  { placeId: 2873, cityName: 'INVERMERE', countryName: 'CANADA' },
  {
    placeId: 40, cityName: 'IQALUIT', state: 'NU', countryName: 'CANADA',
  },
  { placeId: 5989, cityName: 'IRMA', countryName: 'CANADA' },
  { placeId: 5990, cityName: 'IROQUOIS FALLS', countryName: 'CANADA' },
  { placeId: 11278, cityName: 'IROQUOIS', countryName: 'CANADA' },
  { placeId: 55, cityName: 'ISLAND LAKE', countryName: 'CANADA' },
  { placeId: 6014, cityName: 'ITUNA', countryName: 'CANADA' },
  { placeId: 6016, cityName: 'IVUJIVIK', countryName: 'CANADA' },
  { placeId: 2893, cityName: 'JAN LAKE', countryName: 'CANADA' },
  { placeId: 6043, cityName: 'JANVIER', countryName: 'CANADA' },
  { placeId: 6045, cityName: 'JASPER', countryName: 'CANADA' },
  { placeId: 6050, cityName: 'JEAN MARIE RIVER', countryName: 'CANADA' },
  { placeId: 6055, cityName: 'JENPEG', countryName: 'CANADA' },
  { placeId: 6068, cityName: 'JOHN D\'OR PRAIRIE', countryName: 'CANADA' },
  { placeId: 6069, cityName: 'JOHNSON LAKE', countryName: 'CANADA' },
  { placeId: 6072, cityName: 'JOLIETTE', countryName: 'CANADA' },
  { placeId: 6087, cityName: 'JUNIPER', countryName: 'CANADA' },
  { placeId: 11358, cityName: 'KAHNTAH', countryName: 'CANADA' },
  { placeId: 6111, cityName: 'KAKABEKA FALLS', countryName: 'CANADA' },
  { placeId: 57, cityName: 'KAMLOOPS', countryName: 'CANADA' },
  {
    placeId: 2888, cityName: 'KAMSACK', state: 'LA', countryName: 'CANADA',
  },
  { placeId: 5640, cityName: 'KANAWATA', countryName: 'CANADA' },
  { placeId: 5644, cityName: 'KANGIQSUALUJJUAQ', countryName: 'CANADA' },
  { placeId: 11382, cityName: 'KANGIQSUJUAQ', countryName: 'CANADA' },
  { placeId: 5035, cityName: 'KANGIRSUK', countryName: 'CANADA' },
  { placeId: 6129, cityName: 'KANTAH', countryName: 'CANADA' },
  { placeId: 131, cityName: 'KAPUSKASING', countryName: 'CANADA' },
  { placeId: 6142, cityName: 'KARS', countryName: 'CANADA' },
  { placeId: 6145, cityName: 'KASABONIKA', countryName: 'CANADA' },
  { placeId: 6148, cityName: 'KASBA LAKE', countryName: 'CANADA' },
  { placeId: 6152, cityName: 'KASHECHEWAN', countryName: 'CANADA' },
  { placeId: 2878, cityName: 'KASLO', countryName: 'CANADA' },
  { placeId: 6167, cityName: 'KATTINIQ', countryName: 'CANADA' },
  { placeId: 6182, cityName: 'KEENE', countryName: 'CANADA' },
  { placeId: 2906, cityName: 'KEEWAYWIN', countryName: 'CANADA' },
  { placeId: 11418, cityName: 'KEGASKA', countryName: 'CANADA' },
  {
    placeId: 62, cityName: 'KELOWNA', state: 'BC', countryName: 'CANADA',
  },
  { placeId: 6185, cityName: 'KELSEY', countryName: 'CANADA' },
  { placeId: 6187, cityName: 'KELVINGTON', countryName: 'CANADA' },
  { placeId: 6188, cityName: 'KEMESS CREEK', countryName: 'CANADA' },
  { placeId: 79, cityName: 'KENORA', countryName: 'CANADA' },
  { placeId: 5044, cityName: 'KERROBERT', countryName: 'CANADA' },
  { placeId: 6207, cityName: 'KEY LAKE', countryName: 'CANADA' },
  { placeId: 6230, cityName: 'KILLAM', countryName: 'CANADA' },
  { placeId: 6231, cityName: 'KILLARNEY', countryName: 'CANADA' },
  { placeId: 6232, cityName: 'KILLARNEY', countryName: 'CANADA' },
  { placeId: 11470, cityName: 'KIMMIRUT', countryName: 'CANADA' },
  { placeId: 6239, cityName: 'KINCARDINE', countryName: 'CANADA' },
  { placeId: 6241, cityName: 'KINDERSLEY', countryName: 'CANADA' },
  { placeId: 2902, cityName: 'KINGFISHER LAKE', countryName: 'CANADA' },
  { placeId: 2917, cityName: 'KINGSTON', countryName: 'CANADA' },
  { placeId: 6252, cityName: 'KIPLING', countryName: 'CANADA' },
  { placeId: 6255, cityName: 'KIRBY LAKE', countryName: 'CANADA' },
  { placeId: 6257, cityName: 'KIRKLAND LAKE', countryName: 'CANADA' },
  { placeId: 58, cityName: 'KITCHENER', countryName: 'CANADA' },
  { placeId: 6274, cityName: 'KITIMAT', countryName: 'CANADA' },
  { placeId: 6280, cityName: 'KLEENA KLEENE', countryName: 'CANADA' },
  { placeId: 11506, cityName: 'KNEE LAKE', countryName: 'CANADA' },
  { placeId: 2910, cityName: 'KUGAARUK', countryName: 'CANADA' },
  { placeId: 33, cityName: 'KUGLUKTUK', countryName: 'CANADA' },
  { placeId: 103, cityName: 'KUUJJUAQ', countryName: 'CANADA' },
  { placeId: 46, cityName: 'KUUJJUARAPIK', countryName: 'CANADA' },
  { placeId: 6354, cityName: 'KYLE', countryName: 'CANADA' },
  { placeId: 6359, cityName: 'LA BICHE RIVER', countryName: 'CANADA' },
  { placeId: 6363, cityName: 'LA CRETE', countryName: 'CANADA' },
  { placeId: 43, cityName: 'LA GRANDE RIVIERE', countryName: 'CANADA' },
  { placeId: 6371, cityName: 'LA GRANDE-4', countryName: 'CANADA' },
  { placeId: 6373, cityName: 'LA LOCHE', countryName: 'CANADA' },
  { placeId: 11568, cityName: 'LA ROMAINE', countryName: 'CANADA' },
  { placeId: 100, cityName: 'LA RONGE', countryName: 'CANADA' },
  { placeId: 6380, cityName: 'LA SARRE', countryName: 'CANADA' },
  { placeId: 6384, cityName: 'LA TUQUE', countryName: 'CANADA' },
  { placeId: 6389, cityName: 'LAC A LA PERCHAUDE', countryName: 'CANADA' },
  { placeId: 6390, cityName: 'LAC AGILE', countryName: 'CANADA' },
  { placeId: 6391, cityName: 'LAC BROCHET', countryName: 'CANADA' },
  { placeId: 6392, cityName: 'LAC DU BONNET', countryName: 'CANADA' },
  { placeId: 6393, cityName: 'LAC ETCHEMIN', countryName: 'CANADA' },
  { placeId: 6394, cityName: 'LAC LA BICHE', countryName: 'CANADA' },
  { placeId: 6395, cityName: 'LAC LA MARTRE', countryName: 'CANADA' },
  { placeId: 6396, cityName: 'LAC-A-LA-TORTUE', countryName: 'CANADA' },
  { placeId: 6397, cityName: 'LACHUTE', countryName: 'CANADA' },
  { placeId: 6398, cityName: 'LACOMBE', countryName: 'CANADA' },
  { placeId: 6423, cityName: 'LAMPMAN', countryName: 'CANADA' },
  { placeId: 6434, cityName: 'LANGLEY', countryName: 'CANADA' },
  { placeId: 6435, cityName: 'LANIGAN', countryName: 'CANADA' },
  { placeId: 6436, cityName: 'LANSDOWNE HOUSE', countryName: 'CANADA' },
  { placeId: 6454, cityName: 'LAURIE RIVER', countryName: 'CANADA' },
  { placeId: 6462, cityName: 'LEADER', countryName: 'CANADA' },
  { placeId: 6464, cityName: 'LEAF RAPIDS', countryName: 'CANADA' },
  { placeId: 6465, cityName: 'LEAMINGTON', countryName: 'CANADA' },
  { placeId: 6466, cityName: 'LEASK', countryName: 'CANADA' },
  { placeId: 6469, cityName: 'LEBEL-SUR-QUEVILLON', countryName: 'CANADA' },
  { placeId: 6520, cityName: 'LEFROY', countryName: 'CANADA' },
  { placeId: 6296, cityName: 'LEMBERG', countryName: 'CANADA' },
  { placeId: 6525, cityName: 'LENNOXVILLE', countryName: 'CANADA' },
  { placeId: 6530, cityName: 'LEOVILLE', countryName: 'CANADA' },
  { placeId: 80, cityName: 'LETHBRIDGE', countryName: 'CANADA' },
  { placeId: 6539, cityName: 'LEWVAN', countryName: 'CANADA' },
  { placeId: 6547, cityName: 'LIEGE', countryName: 'CANADA' },
  { placeId: 6552, cityName: 'LIKELY', countryName: 'CANADA' },
  { placeId: 6555, cityName: 'LILLOOET', countryName: 'CANADA' },
  { placeId: 6572, cityName: 'LINDSAY', countryName: 'CANADA' },
  { placeId: 6577, cityName: 'LISTOWEL', countryName: 'CANADA' },
  { placeId: 6579, cityName: 'LITTLE BEAR LAKE', countryName: 'CANADA' },
  { placeId: 6581, cityName: 'LITTLE GRAND RAPIDS', countryName: 'CANADA' },
  { placeId: 11713, cityName: 'LIVERPOOL', countryName: 'CANADA' },
  { placeId: 61, cityName: 'LLOYDMINSTER', countryName: 'CANADA' },
  {
    placeId: 117, cityName: 'LONDON', state: 'ON', countryName: 'CANADA',
  },
  { placeId: 6615, cityName: 'LOON LAKE', countryName: 'CANADA' },
  { placeId: 6616, cityName: 'LOON RIVER', countryName: 'CANADA' },
  { placeId: 6630, cityName: 'LOUISEVILLE', countryName: 'CANADA' },
  { placeId: 11751, cityName: 'LOURDES-DE-BLANC-SABLON', countryName: 'CANADA' },
  { placeId: 6633, cityName: 'LOURDES-DE-JOLIETTE', countryName: 'CANADA' },
  { placeId: 6635, cityName: 'LOWER EAST PUBNICO', countryName: 'CANADA' },
  { placeId: 6649, cityName: 'LUCAN', countryName: 'CANADA' },
  { placeId: 6651, cityName: 'LUCKY LAKE', countryName: 'CANADA' },
  { placeId: 5650, cityName: 'LUNDAR', countryName: 'CANADA' },
  { placeId: 6675, cityName: 'LUSELAND', countryName: 'CANADA' },
  { placeId: 6678, cityName: 'LUTSELK\'E', countryName: 'CANADA' },
  { placeId: 126, cityName: 'LYNN LAKE', countryName: 'CANADA' },
  { placeId: 6688, cityName: 'MABEL LAKE', countryName: 'CANADA' },
  { placeId: 6474, cityName: 'MACDONALD', countryName: 'CANADA' },
  { placeId: 6476, cityName: 'MACGREGOR', countryName: 'CANADA' },
  { placeId: 137, cityName: 'MACKENZIE', countryName: 'CANADA' },
  { placeId: 6689, cityName: 'MACKLIN', countryName: 'CANADA' },
  { placeId: 6690, cityName: 'MACMILLAN PASS', countryName: 'CANADA' },
  { placeId: 2887, cityName: 'MAIDSTONE', countryName: 'CANADA' },
  { placeId: 6725, cityName: 'MAKKOVIK', countryName: 'CANADA' },
  { placeId: 6762, cityName: 'MANIC-5', countryName: 'CANADA' },
  { placeId: 6766, cityName: 'MANITOU', countryName: 'CANADA' },
  { placeId: 6767, cityName: 'MANITOUWADGE', countryName: 'CANADA' },
  { placeId: 6768, cityName: 'MANITOWANING', countryName: 'CANADA' },
  { placeId: 6769, cityName: 'MANIWAKI', countryName: 'CANADA' },
  { placeId: 6775, cityName: 'MANNING', countryName: 'CANADA' },
  { placeId: 6781, cityName: 'MANSFIELD', countryName: 'CANADA' },
  { placeId: 6783, cityName: 'MANSONVILLE', countryName: 'CANADA' },
  { placeId: 6788, cityName: 'MAPLE CREEK', countryName: 'CANADA' },
  { placeId: 6799, cityName: 'MARATHON', countryName: 'CANADA' },
  { placeId: 6803, cityName: 'MAREK FARMS', countryName: 'CANADA' },
  { placeId: 6806, cityName: 'MARGAREE', countryName: 'CANADA' },
  { placeId: 6807, cityName: 'MARGARET LAKE', countryName: 'CANADA' },
  { placeId: 11882, cityName: 'MARY RIVER', countryName: 'CANADA' },
  { placeId: 11883, cityName: 'MARYS HARBOUR', countryName: 'CANADA' },
  { placeId: 6858, cityName: 'MASSET', countryName: 'CANADA' },
  { placeId: 6863, cityName: 'MATAGAMI', countryName: 'CANADA' },
  { placeId: 6866, cityName: 'MATANE', countryName: 'CANADA' },
  { placeId: 6869, cityName: 'MATHESON ISLAND', countryName: 'CANADA' },
  { placeId: 6887, cityName: 'MAYERTHORPE', countryName: 'CANADA' },
  { placeId: 63, cityName: 'MAYO', countryName: 'CANADA' },
  { placeId: 6905, cityName: 'MC QUESTEN', countryName: 'CANADA' },
  { placeId: 6907, cityName: 'MCARTHUR RIVER', countryName: 'CANADA' },
  { placeId: 6908, cityName: 'MCBRIDE', countryName: 'CANADA' },
  { placeId: 6909, cityName: 'MCCREARY', countryName: 'CANADA' },
  { placeId: 6916, cityName: 'MEADOW LAKE', countryName: 'CANADA' },
  { placeId: 11931, cityName: 'MEADOWBANK', countryName: 'CANADA' },
  { placeId: 112, cityName: 'MEDICINE HAT', countryName: 'CANADA' },
  { placeId: 6926, cityName: 'MELBOURNE', countryName: 'CANADA' },
  { placeId: 6927, cityName: 'MELFORT', countryName: 'CANADA' },
  { placeId: 6928, cityName: 'MELITA', countryName: 'CANADA' },
  { placeId: 6932, cityName: 'MELVILLE', countryName: 'CANADA' },
  { placeId: 6949, cityName: 'MERRITT', countryName: 'CANADA' },
  { placeId: 6968, cityName: 'MIDLAND', countryName: 'CANADA' },
  { placeId: 6970, cityName: 'MIDWAY', countryName: 'CANADA' },
  { placeId: 6971, cityName: 'MILDRED LAKE', countryName: 'CANADA' },
  { placeId: 6975, cityName: 'MILK RIVER', countryName: 'CANADA' },
  { placeId: 6981, cityName: 'MIMINISKA', countryName: 'CANADA' },
  { placeId: 6990, cityName: 'MINNEDOSA', countryName: 'CANADA' },
  { placeId: 12007, cityName: 'MINTO', countryName: 'CANADA' },
  { placeId: 6995, cityName: 'MIRAMICHI', countryName: 'CANADA' },
  { placeId: 6491, cityName: 'MOBIL BISTCHO', countryName: 'CANADA' },
  { placeId: 7016, cityName: 'MOLSON LAKE', countryName: 'CANADA' },
  { placeId: 81, cityName: 'MONCTON', countryName: 'CANADA' },
  { placeId: 132, cityName: 'MONT-JOLI', countryName: 'CANADA' },
  { placeId: 5659, cityName: 'MONT-LAURIER', countryName: 'CANADA' },
  { placeId: 7054, cityName: 'MONTMAGNY', countryName: 'CANADA' },
  { placeId: 12054, cityName: 'MONTREAL', countryName: 'CANADA' },
  {
    placeId: 66, cityName: 'MONTREAL', state: 'QC', countryName: 'CANADA',
  },
  { placeId: 64, cityName: 'MOOSE JAW', countryName: 'CANADA' },
  { placeId: 7061, cityName: 'MOOSE LAKE', countryName: 'CANADA' },
  { placeId: 7063, cityName: 'MOOSOMIN', countryName: 'CANADA' },
  { placeId: 7064, cityName: 'MOOSONEE', countryName: 'CANADA' },
  { placeId: 7068, cityName: 'MORDEN', countryName: 'CANADA' },
  { placeId: 12078, cityName: 'MORRISBURG', countryName: 'CANADA' },
  { placeId: 7095, cityName: 'MOUNTAIN VIEW', countryName: 'CANADA' },
  { placeId: 7126, cityName: 'MULE CREEK', countryName: 'CANADA' },
  { placeId: 7140, cityName: 'MUSKEG TOWER', countryName: 'CANADA' },
  { placeId: 7143, cityName: 'MUSKOKA', countryName: 'CANADA' },
  { placeId: 2926, cityName: 'MUSKRAT DAM', countryName: 'CANADA' },
  { placeId: 6501, cityName: 'NAHANNI BUTTE', countryName: 'CANADA' },
  { placeId: 6502, cityName: 'NAICAM', countryName: 'CANADA' },
  { placeId: 12148, cityName: 'NAIN', countryName: 'CANADA' },
  { placeId: 7172, cityName: 'NAKINA', countryName: 'CANADA' },
  { placeId: 7174, cityName: 'NAKUSP', countryName: 'CANADA' },
  { placeId: 7177, cityName: 'NAMUR LAKE', countryName: 'CANADA' },
  { placeId: 12153, cityName: 'NANAIMO', countryName: 'CANADA' },
  { placeId: 3896, cityName: 'NANISIVIK', countryName: 'CANADA' },
  { placeId: 12169, cityName: 'NATASHQUAN', countryName: 'CANADA' },
  { placeId: 2901, cityName: 'NATUASHISH', countryName: 'CANADA' },
  { placeId: 7210, cityName: 'NEEPAWA', countryName: 'CANADA' },
  { placeId: 7213, cityName: 'NEILBURG', countryName: 'CANADA' },
  { placeId: 7217, cityName: 'NEKWEAGA BAY', countryName: 'CANADA' },
  { placeId: 7218, cityName: 'NELSON', countryName: 'CANADA' },
  { placeId: 7223, cityName: 'NEMISCAU', countryName: 'CANADA' },
  { placeId: 2885, cityName: 'NESTOR FALLS', countryName: 'CANADA' },
  { placeId: 2907, cityName: 'NEW LISKEARD', countryName: 'CANADA' },
  { placeId: 12223, cityName: 'NIAGARA', countryName: 'CANADA' },
  { placeId: 7264, cityName: 'NIPAWIN', countryName: 'CANADA' },
  { placeId: 7268, cityName: 'NIXON', countryName: 'CANADA' },
  { placeId: 104, cityName: 'NORMAN WELLS', countryName: 'CANADA' },
  { placeId: 86, cityName: 'NORTH BATTLEFORD', countryName: 'CANADA' },
  { placeId: 120, cityName: 'NORTH BAY', countryName: 'CANADA' },
  { placeId: 7286, cityName: 'NORTH SEAL RIVER', countryName: 'CANADA' },
  { placeId: 2895, cityName: 'NORTH SPIRIT LAKE', countryName: 'CANADA' },
  { placeId: 67, cityName: 'NORWAY HOUSE', countryName: 'CANADA' },
  { placeId: 7312, cityName: 'NORWOOD', countryName: 'CANADA' },
  { placeId: 6503, cityName: 'NOVA SCOTIA', countryName: 'CANADA' },
  { placeId: 12275, cityName: 'NUELTIN LAKE', countryName: 'CANADA' },
  { placeId: 7334, cityName: 'OAK HAMMOCK AIR PARK', countryName: 'CANADA' },
  { placeId: 7351, cityName: 'OBRE LAKE/NORTH OF SIXTY', countryName: 'CANADA' },
  { placeId: 7365, cityName: 'OGILVIE RIVER', countryName: 'CANADA' },
  { placeId: 7366, cityName: 'OGOKI POST', countryName: 'CANADA' },
  { placeId: 7375, cityName: 'OLD CROW', countryName: 'CANADA' },
  { placeId: 7378, cityName: 'OLDS-DIDSBURY', countryName: 'CANADA' },
  { placeId: 7377, cityName: 'OLDS', countryName: 'CANADA' },
  {
    placeId: 7381, cityName: 'OLIVER', state: 'BC', countryName: 'CANADA',
  },
  { placeId: 7391, cityName: 'ONE HUNDRED MILE HOUSE', countryName: 'CANADA' },
  { placeId: 2894, cityName: 'OPAPIMISKAN LAKE', countryName: 'CANADA' },
  { placeId: 7396, cityName: 'OPINACA', countryName: 'CANADA' },
  { placeId: 7406, cityName: 'ORANGEVILLE', countryName: 'CANADA' },
  { placeId: 7414, cityName: 'ORILLIA', countryName: 'CANADA' },
  { placeId: 7426, cityName: 'ORTON', countryName: 'CANADA' },
  { placeId: 12369, cityName: 'OSHAWA', countryName: 'CANADA' },
  { placeId: 7436, cityName: 'OSOYOOS', countryName: 'CANADA' },
  { placeId: 7437, cityName: 'OSPIKA', countryName: 'CANADA' },
  {
    placeId: 70, cityName: 'OTTAWA', state: 'ON', countryName: 'CANADA',
  },
  { placeId: 12378, cityName: 'OTTAWA', countryName: 'CANADA' },
  { placeId: 7298, cityName: 'OTTER LAKE', countryName: 'CANADA' },
  { placeId: 7444, cityName: 'OUTLOOK', countryName: 'CANADA' },
  { placeId: 7451, cityName: 'OWEN SOUND', countryName: 'CANADA' },
  { placeId: 7454, cityName: 'OXBOW', countryName: 'CANADA' },
  { placeId: 7459, cityName: 'OXFORD HOUSE', countryName: 'CANADA' },
  { placeId: 7463, cityName: 'OYEN', countryName: 'CANADA' },
  { placeId: 7482, cityName: 'PALMERSTON', countryName: 'CANADA' },
  { placeId: 7547, cityName: 'PANGMAN', countryName: 'CANADA' },
  { placeId: 2924, cityName: 'PANGNIRTUNG', countryName: 'CANADA' },
  { placeId: 2886, cityName: 'PARADISE HILL', countryName: 'CANADA' },
  { placeId: 7303, cityName: 'PARAMOUNT BISTCHO', countryName: 'CANADA' },
  { placeId: 7557, cityName: 'PARENT', countryName: 'CANADA' },
  { placeId: 7564, cityName: 'PARRY SOUND', countryName: 'CANADA' },
  { placeId: 7579, cityName: 'PATUANAK', countryName: 'CANADA' },
  { placeId: 7581, cityName: 'PAULATUK', countryName: 'CANADA' },
  { placeId: 72, cityName: 'PEACE RIVER', countryName: 'CANADA' },
  { placeId: 7590, cityName: 'PEAWANUCK', countryName: 'CANADA' },
  { placeId: 7595, cityName: 'PEGGO DEVON CANADA', countryName: 'CANADA' },
  { placeId: 7596, cityName: 'PEGGO GAS PLANT', countryName: 'CANADA' },
  { placeId: 12469, cityName: 'PELEE ISLAND', countryName: 'CANADA' },
  { placeId: 7598, cityName: 'PELICAN NARROWS', countryName: 'CANADA' },
  { placeId: 7597, cityName: 'PELICAN', countryName: 'CANADA' },
  { placeId: 7602, cityName: 'PELLY CROSSING', countryName: 'CANADA' },
  { placeId: 7604, cityName: 'PEMBERTON', countryName: 'CANADA' },
  { placeId: 7606, cityName: 'PEMBROKE', countryName: 'CANADA' },
  { placeId: 7607, cityName: 'PENDLETON', countryName: 'CANADA' },
  { placeId: 123, cityName: 'PENTICTON', countryName: 'CANADA' },
  { placeId: 7617, cityName: 'PETAWAWA', countryName: 'CANADA' },
  {
    placeId: 7618, cityName: 'PETERBOROUGH', state: 'ON', countryName: 'CANADA',
  },
  { placeId: 7620, cityName: 'PETITOT', countryName: 'CANADA' },
  { placeId: 7634, cityName: 'PICKLE LAKE', countryName: 'CANADA' },
  { placeId: 12516, cityName: 'PICTON', countryName: 'CANADA' },
  { placeId: 3893, cityName: 'PIKANGIKUM', countryName: 'CANADA' },
  { placeId: 7642, cityName: 'PIKWITONEI', countryName: 'CANADA' },
  { placeId: 7647, cityName: 'PINCHER CREEK', countryName: 'CANADA' },
  { placeId: 7652, cityName: 'PINE DOCK', countryName: 'CANADA' },
  { placeId: 7653, cityName: 'PINE LAKE', countryName: 'CANADA' },
  { placeId: 7657, cityName: 'PINEHOUSE LAKE', countryName: 'CANADA' },
  { placeId: 7663, cityName: 'PITT MEADOWS', countryName: 'CANADA' },
  { placeId: 7310, cityName: 'PLEVNA', countryName: 'CANADA' },
  { placeId: 7488, cityName: 'POINTED MOUNTAIN', countryName: 'CANADA' },
  { placeId: 7489, cityName: 'POINTS NORTH LANDING', countryName: 'CANADA' },
  { placeId: 12572, cityName: 'POKEMOUCHE', countryName: 'CANADA' },
  { placeId: 54, cityName: 'POND INLET', countryName: 'CANADA' },
  { placeId: 7495, cityName: 'PONOKA', countryName: 'CANADA' },
  { placeId: 12580, cityName: 'PONTIAC', countryName: 'CANADA' },
  { placeId: 2905, cityName: 'POPLAR HILL,', countryName: 'CANADA' },
  { placeId: 7690, cityName: 'POPLAR RIVER', countryName: 'CANADA' },
  { placeId: 7694, cityName: 'PORCUPINE PLAIN', countryName: 'CANADA' },
  { placeId: 7696, cityName: 'PORT ALBERNI', countryName: 'CANADA' },
  { placeId: 7699, cityName: 'PORT AU CHOIX', countryName: 'CANADA' },
  { placeId: 7704, cityName: 'PORT ELGIN', countryName: 'CANADA' },
  { placeId: 135, cityName: 'PORT HARDY', countryName: 'CANADA' },
  { placeId: 12596, cityName: 'PORT HAWKESBURY', countryName: 'CANADA' },
  { placeId: 2883, cityName: 'PORT HOPE SIMPSON', countryName: 'CANADA' },
  { placeId: 12599, cityName: 'PORT HOPE', countryName: 'CANADA' },
  { placeId: 7706, cityName: 'PORT MC NEILL', countryName: 'CANADA' },
  { placeId: 12607, cityName: 'PORT MENIER', countryName: 'CANADA' },
  { placeId: 7710, cityName: 'PORTAGE LA PRAIRIE', countryName: 'CANADA' },
  { placeId: 12613, cityName: 'PORTERS LAKE', countryName: 'CANADA' },
  { placeId: 7743, cityName: 'POSTE MONTAGNAIS', countryName: 'CANADA' },
  { placeId: 2881, cityName: 'POSTVILLE', countryName: 'CANADA' },
  { placeId: 12630, cityName: 'POURVOIRIE MIRAGE', countryName: 'CANADA' },
  { placeId: 7751, cityName: 'POWELL RIVER', countryName: 'CANADA' },
  { placeId: 7754, cityName: 'PRAIRIE CREEK', countryName: 'CANADA' },
  { placeId: 7759, cityName: 'PREECEVILLE', countryName: 'CANADA' },
  { placeId: 71, cityName: 'PRINCE ALBERT', countryName: 'CANADA' },
  {
    placeId: 115, cityName: 'PRINCE GEORGE', state: 'BC', countryName: 'CANADA',
  },
  { placeId: 73, cityName: 'PRINCE RUPERT', countryName: 'CANADA' },
  { placeId: 7765, cityName: 'PRINCETON', countryName: 'CANADA' },
  { placeId: 7774, cityName: 'PROVOST', countryName: 'CANADA' },
  { placeId: 7798, cityName: 'PUKATAWAGAN', countryName: 'CANADA' },
  { placeId: 7806, cityName: 'PUNTZI MOUNTAIN', countryName: 'CANADA' },
  { placeId: 7808, cityName: 'PUVIRNITUQ', countryName: 'CANADA' },
  { placeId: 101, cityName: 'QIKIQTARJUAQ', countryName: 'CANADA' },
  { placeId: 7822, cityName: 'QUALICUM BEACH', countryName: 'CANADA' },
  { placeId: 12694, cityName: 'QUAQTAQ', countryName: 'CANADA' },
  {
    placeId: 74, cityName: 'QUEBEC', state: 'QC', countryName: 'CANADA',
  },
  { placeId: 7827, cityName: 'QUESNEL LAKE', countryName: 'CANADA' },
  { placeId: 89, cityName: 'QUESNEL', countryName: 'CANADA' },
  { placeId: 7830, cityName: 'QUILCHENA', countryName: 'CANADA' },
  { placeId: 7831, cityName: 'QUILL LAKE', countryName: 'CANADA' },
  { placeId: 7841, cityName: 'RADISSON', countryName: 'CANADA' },
  { placeId: 2877, cityName: 'RADIUM HOT SPRINGS', countryName: 'CANADA' },
  { placeId: 7500, cityName: 'RADVILLE', countryName: 'CANADA' },
  { placeId: 7501, cityName: 'RAE', countryName: 'CANADA' },
  { placeId: 7508, cityName: 'RAINBOW LAKE', countryName: 'CANADA' },
  { placeId: 12731, cityName: 'RANKIN INLET', countryName: 'CANADA' },
  { placeId: 7723, cityName: 'RED DEER FORESTRY', countryName: 'CANADA' },
  { placeId: 76, cityName: 'RED DEER', countryName: 'CANADA' },
  { placeId: 7874, cityName: 'RED EARTH CREEK', countryName: 'CANADA' },
  { placeId: 3894, cityName: 'RED LAKE', countryName: 'CANADA' },
  { placeId: 7877, cityName: 'RED SUCKER LAKE', countryName: 'CANADA' },
  { placeId: 7887, cityName: 'REGINA BEACH', countryName: 'CANADA' },
  {
    placeId: 82, cityName: 'REGINA', state: 'SK', countryName: 'CANADA',
  },
  { placeId: 12774, cityName: 'REPULSE BAY', countryName: 'CANADA' },
  { placeId: 12775, cityName: 'RESOLUTE BAY', countryName: 'CANADA' },
  { placeId: 7900, cityName: 'RESTON', countryName: 'CANADA' },
  {
    placeId: 7902, cityName: 'REVELSTOKE', state: 'BC', countryName: 'CANADA',
  },
  { placeId: 7914, cityName: 'RIDING MOUNTAIN', countryName: 'CANADA' },
  { placeId: 2884, cityName: 'RIGOLET', countryName: 'CANADA' },
  { placeId: 7916, cityName: 'RIMBEY', countryName: 'CANADA' },
  { placeId: 7917, cityName: 'RIMOUSKI', countryName: 'CANADA' },
  { placeId: 7727, cityName: 'RIVERTON', countryName: 'CANADA' },
  { placeId: 7729, cityName: 'RIVIERE AUX SAUMONS', countryName: 'CANADA' },
  { placeId: 12805, cityName: 'RIVIERE BELL', countryName: 'CANADA' },
  { placeId: 7730, cityName: 'RIVIERE ROUGE', countryName: 'CANADA' },
  { placeId: 7731, cityName: 'RIVIERE-DU-LOUP', countryName: 'CANADA' },
  { placeId: 90, cityName: 'ROBERVAL', countryName: 'CANADA' },
  { placeId: 7929, cityName: 'ROBLIN', countryName: 'CANADA' },
  { placeId: 2891, cityName: 'ROCANVILLE', countryName: 'CANADA' },
  { placeId: 7940, cityName: 'ROCKGLEN', countryName: 'CANADA' },
  { placeId: 7942, cityName: 'ROCKTON', countryName: 'CANADA' },
  { placeId: 7944, cityName: 'ROCKY MOUNTAIN HOUSE', countryName: 'CANADA' },
  { placeId: 7945, cityName: 'RODNEY', countryName: 'CANADA' },
  { placeId: 7949, cityName: 'ROGERSVILLE', countryName: 'CANADA' },
  { placeId: 7953, cityName: 'ROLAND', countryName: 'CANADA' },
  { placeId: 7962, cityName: 'ROSENORT', countryName: 'CANADA' },
  { placeId: 7963, cityName: 'ROSETOWN', countryName: 'CANADA' },
  { placeId: 7965, cityName: 'ROSS RIVER', countryName: 'CANADA' },
  { placeId: 7969, cityName: 'ROUGEMONT', countryName: 'CANADA' },
  { placeId: 2928, cityName: 'ROUND LAKE', countryName: 'CANADA' },
  { placeId: 99, cityName: 'ROUYN', countryName: 'CANADA' },
  { placeId: 7987, cityName: 'RUSSELL', countryName: 'CANADA' },
  { placeId: 12879, cityName: 'SABLE ISLAND', countryName: 'CANADA' },
  { placeId: 2927, cityName: 'SACHIGO LAKE', countryName: 'CANADA' },
  { placeId: 12884, cityName: 'SACHS HARBOUR', countryName: 'CANADA' },
  { placeId: 12890, cityName: 'SAINT JOHN', countryName: 'CANADA' },
  { placeId: 7517, cityName: 'SAKAMI', countryName: 'CANADA' },
  { placeId: 8015, cityName: 'SALLUIT', countryName: 'CANADA' },
  { placeId: 8017, cityName: 'SALMON ARM', countryName: 'CANADA' },
  { placeId: 12936, cityName: 'SANDSPIT', countryName: 'CANADA' },
  { placeId: 8078, cityName: 'SANDY BAY', countryName: 'CANADA' },
  { placeId: 2929, cityName: 'SANDY LAKE', countryName: 'CANADA' },
  { placeId: 3895, cityName: 'SANIKILUAQ', countryName: 'CANADA' },
  { placeId: 8122, cityName: 'SARNIA', countryName: 'CANADA' },
  {
    placeId: 111, cityName: 'SASKATOON', state: 'SK', countryName: 'CANADA',
  },
  { placeId: 12970, cityName: 'SASKATOON', countryName: 'CANADA' },
  {
    placeId: 27, cityName: 'SAULT STE MARIE', state: 'ON', countryName: 'CANADA',
  },
  { placeId: 59, cityName: 'SCHEFFERVILLE', countryName: 'CANADA' },
  { placeId: 8147, cityName: 'SCOTTSFIELD AIRPARK', countryName: 'CANADA' },
  { placeId: 7522, cityName: 'SCUM LAKE', countryName: 'CANADA' },
  { placeId: 12995, cityName: 'SECHELT-GIBSONS', countryName: 'CANADA' },
  { placeId: 8153, cityName: 'SELKIRK', countryName: 'CANADA' },
  { placeId: 13003, cityName: 'SELKIRK', countryName: 'CANADA' },
  { placeId: 8188, cityName: 'SENNETERRE', countryName: 'CANADA' },
  { placeId: 13014, cityName: 'SEPT-ILES', countryName: 'CANADA' },
  { placeId: 8197, cityName: 'SEVOGLE', countryName: 'CANADA' },
  { placeId: 8201, cityName: 'SEXSMITH', countryName: 'CANADA' },
  { placeId: 8210, cityName: 'SHAMATTAWA', countryName: 'CANADA' },
  { placeId: 8215, cityName: 'SHAUNAVON', countryName: 'CANADA' },
  { placeId: 8218, cityName: 'SHEDIAC BRIDGE', countryName: 'CANADA' },
  { placeId: 8220, cityName: 'SHELBURNE', countryName: 'CANADA' },
  { placeId: 8225, cityName: 'SHELL', countryName: 'CANADA' },
  { placeId: 8228, cityName: 'SHELLBROOK', countryName: 'CANADA' },
  { placeId: 8230, cityName: 'SHERBROOKE', countryName: 'CANADA' },
  { placeId: 8239, cityName: 'SHOAL LAKE', countryName: 'CANADA' },
  { placeId: 13072, cityName: 'SHUSHWAP', countryName: 'CANADA' },
  { placeId: 8262, cityName: 'SILVER CITY', countryName: 'CANADA' },
  { placeId: 8263, cityName: 'SILVER FALLS', countryName: 'CANADA' },
  { placeId: 8266, cityName: 'SIMCOE', countryName: 'CANADA' },
  { placeId: 114, cityName: 'SIOUX LOOKOUT', countryName: 'CANADA' },
  { placeId: 2892, cityName: 'SIOUX NARROWS', countryName: 'CANADA' },
  { placeId: 2890, cityName: 'SLATE FALLS', countryName: 'CANADA' },
  { placeId: 134, cityName: 'SLAVE LAKE', countryName: 'CANADA' },
  { placeId: 8294, cityName: 'SMITHERS', countryName: 'CANADA' },
  { placeId: 13114, cityName: 'SMITHS FALLS', countryName: 'CANADA' },
  { placeId: 8297, cityName: 'SNAP LAKE', countryName: 'CANADA' },
  { placeId: 8298, cityName: 'SNARE RIVER', countryName: 'CANADA' },
  { placeId: 8299, cityName: 'SNOW LAKE', countryName: 'CANADA' },
  { placeId: 8312, cityName: 'SOMERSET', countryName: 'CANADA' },
  { placeId: 8319, cityName: 'SOREL', countryName: 'CANADA' },
  { placeId: 8324, cityName: 'SOURIS GLENWOOD INDUSTRIAL AIR', countryName: 'CANADA' },
  { placeId: 8328, cityName: 'SOUTH INDIAN LAKE', countryName: 'CANADA' },
  { placeId: 8374, cityName: 'SOUTHAMPTON', countryName: 'CANADA' },
  { placeId: 8375, cityName: 'SOUTHEND', countryName: 'CANADA' },
  { placeId: 3897, cityName: 'SPARWOOD', countryName: 'CANADA' },
  { placeId: 8384, cityName: 'SPIRIT RIVER', countryName: 'CANADA' },
  { placeId: 8385, cityName: 'SPIRITWOOD', countryName: 'CANADA' },
  { placeId: 8387, cityName: 'SPRING VALLEY', countryName: 'CANADA' },
  { placeId: 8389, cityName: 'SPRINGDALE', countryName: 'CANADA' },
  { placeId: 8395, cityName: 'SPRINGHOUSE', countryName: 'CANADA' },
  { placeId: 8397, cityName: 'SPRINGWATER', countryName: 'CANADA' },
  { placeId: 8399, cityName: 'SQUAMISH', countryName: 'CANADA' },
  { placeId: 8400, cityName: 'SQUAW RAPIDS', countryName: 'CANADA' },
  { placeId: 13174, cityName: 'ST ANDREWS', countryName: 'CANADA' },
  { placeId: 8403, cityName: 'ST ANTHONY', countryName: 'CANADA' },
  { placeId: 8406, cityName: 'ST BRUNO DE GUIGUES', countryName: 'CANADA' },
  { placeId: 8407, cityName: 'ST CATHARINES', countryName: 'CANADA' },
  { placeId: 8411, cityName: 'ST DONAT', countryName: 'CANADA' },
  { placeId: 8413, cityName: 'ST FREDERIC', countryName: 'CANADA' },
  { placeId: 8416, cityName: 'ST GEORGES', countryName: 'CANADA' },
  {
    placeId: 130, cityName: 'ST JOHN\'S', state: 'NF', countryName: 'CANADA',
  },
  { placeId: 13194, cityName: 'ST LEWIS', countryName: 'CANADA' },
  { placeId: 8424, cityName: 'ST PAUL', countryName: 'CANADA' },
  { placeId: 13204, cityName: 'ST STEPHEN', countryName: 'CANADA' },
  { placeId: 8161, cityName: 'ST THERESA POINT', countryName: 'CANADA' },
  { placeId: 8162, cityName: 'ST THOMAS', countryName: 'CANADA' },
  { placeId: 8427, cityName: 'ST-ANSELME', countryName: 'CANADA' },
  { placeId: 13208, cityName: 'ST-AUGUSTIN', countryName: 'CANADA' },
  { placeId: 8428, cityName: 'ST-BASILE', countryName: 'CANADA' },
  { placeId: 8429, cityName: 'ST-DOMINIQUE', countryName: 'CANADA' },
  { placeId: 13211, cityName: 'ST-ESPRIT', countryName: 'CANADA' },
  { placeId: 8430, cityName: 'ST-FERDINAND', countryName: 'CANADA' },
  { placeId: 8431, cityName: 'ST-HONORE', countryName: 'CANADA' },
  { placeId: 51, cityName: 'ST-HUBERT', countryName: 'CANADA' },
  { placeId: 8432, cityName: 'ST-HYACINTHE QUEBEC', countryName: 'CANADA' },
  { placeId: 8434, cityName: 'ST-JEAN CHRYSOSTOME', countryName: 'CANADA' },
  { placeId: 8433, cityName: 'ST-JEAN', countryName: 'CANADA' },
  { placeId: 13218, cityName: 'ST-JEROME', countryName: 'CANADA' },
  { placeId: 8435, cityName: 'ST-JOVITE', countryName: 'CANADA' },
  { placeId: 8436, cityName: 'ST-LAMBERT-DE-LEVIS', countryName: 'CANADA' },
  { placeId: 8438, cityName: 'ST-LEONARD', countryName: 'CANADA' },
  { placeId: 8439, cityName: 'ST-LOUIS-DE-FRANCE', countryName: 'CANADA' },
  { placeId: 8440, cityName: 'ST-MATHIAS', countryName: 'CANADA' },
  { placeId: 8441, cityName: 'ST-MATHIEU-DE-BELOEIL', countryName: 'CANADA' },
  { placeId: 8442, cityName: 'ST-MICHEL-DES-SAINTS', countryName: 'CANADA' },
  { placeId: 8443, cityName: 'ST-NICEPHORE', countryName: 'CANADA' },
  { placeId: 8444, cityName: 'ST-QUENTIN', countryName: 'CANADA' },
  { placeId: 8445, cityName: 'ST-RAYMOND', countryName: 'CANADA' },
  { placeId: 8446, cityName: 'ST-SIMON-DE-BAGOT', countryName: 'CANADA' },
  { placeId: 8447, cityName: 'ST-VICTOR-DE-BEAUCE', countryName: 'CANADA' },
  { placeId: 8448, cityName: 'ST. BRIEUX', countryName: 'CANADA' },
  { placeId: 8449, cityName: 'ST. FRANCIS', countryName: 'CANADA' },
  { placeId: 8450, cityName: 'ST. JOSEPH ISLAND', countryName: 'CANADA' },
  { placeId: 13236, cityName: 'STANHOPE', countryName: 'CANADA' },
  { placeId: 8456, cityName: 'STANLEY', countryName: 'CANADA' },
  { placeId: 8457, cityName: 'STANSTEAD', countryName: 'CANADA' },
  { placeId: 8460, cityName: 'STARBUCK', countryName: 'CANADA' },
  { placeId: 8467, cityName: 'STE-AGNES-DE-DUNDEE', countryName: 'CANADA' },
  { placeId: 8468, cityName: 'STE-ANNE-DES-MONTS', countryName: 'CANADA' },
  { placeId: 8469, cityName: 'STE-JULIENNE', countryName: 'CANADA' },
  { placeId: 13253, cityName: 'STE-LUCIE-DE-BEAUREGARD', countryName: 'CANADA' },
  { placeId: 8470, cityName: 'STE. ROSE DU LAC', countryName: 'CANADA' },
  { placeId: 8473, cityName: 'STEEN RIVER', countryName: 'CANADA' },
  { placeId: 8474, cityName: 'STEEN TOWER', countryName: 'CANADA' },
  { placeId: 8475, cityName: 'STEINBACH', countryName: 'CANADA' },
  { placeId: 56, cityName: 'STEPHENVILLE', countryName: 'CANADA' },
  { placeId: 8480, cityName: 'STETTLER', countryName: 'CANADA' },
  { placeId: 8482, cityName: 'STEWART', countryName: 'CANADA' },
  { placeId: 8485, cityName: 'STONEY CREEK', countryName: 'CANADA' },
  { placeId: 8486, cityName: 'STONEY POINT', countryName: 'CANADA' },
  { placeId: 8487, cityName: 'STONY PLAIN', countryName: 'CANADA' },
  { placeId: 92, cityName: 'STONY RAPIDS', countryName: 'CANADA' },
  { placeId: 8490, cityName: 'STRAFFORDVILLE', countryName: 'CANADA' },
  { placeId: 8493, cityName: 'STRATFORD', countryName: 'CANADA' },
  { placeId: 8494, cityName: 'STRATHCLAIR', countryName: 'CANADA' },
  { placeId: 13280, cityName: 'STRATHMORE', countryName: 'CANADA' },
  { placeId: 8495, cityName: 'STRATHROY', countryName: 'CANADA' },
  { placeId: 91, cityName: 'SUDBURY', countryName: 'CANADA' },
  { placeId: 8512, cityName: 'SUMMER BEAVER', countryName: 'CANADA' },
  { placeId: 8513, cityName: 'SUMMERSIDE', countryName: 'CANADA' },
  { placeId: 8517, cityName: 'SUNDRE', countryName: 'CANADA' },
  { placeId: 8519, cityName: 'SUNRIDGE', countryName: 'CANADA' },
  { placeId: 13315, cityName: 'SUSSEX', countryName: 'CANADA' },
  { placeId: 8334, cityName: 'SWAN HILLS', countryName: 'CANADA' },
  { placeId: 8335, cityName: 'SWAN RIVER', countryName: 'CANADA' },
  { placeId: 127, cityName: 'SWIFT CURRENT', countryName: 'CANADA' },
  { placeId: 88, cityName: 'SYDNEY', countryName: 'CANADA' },
  { placeId: 8529, cityName: 'TABER', countryName: 'CANADA' },
  { placeId: 8543, cityName: 'TADOULE LAKE', countryName: 'CANADA' },
  { placeId: 124, cityName: 'TALOYOAK', countryName: 'CANADA' },
  { placeId: 8589, cityName: 'TASIUJAQ', countryName: 'CANADA' },
  { placeId: 8604, cityName: 'TEEPEE', countryName: 'CANADA' },
  { placeId: 8605, cityName: 'TEESWATER', countryName: 'CANADA' },
  { placeId: 8610, cityName: 'TELEGRAPH CREEK', countryName: 'CANADA' },
  { placeId: 116, cityName: 'TERRACE', countryName: 'CANADA' },
  { placeId: 8626, cityName: 'TESLIN', countryName: 'CANADA' },
  { placeId: 13403, cityName: 'TEXADA', countryName: 'CANADA' },
  { placeId: 75, cityName: 'THE PAS', countryName: 'CANADA' },
  { placeId: 8639, cityName: 'THESSALON', countryName: 'CANADA' },
  { placeId: 8640, cityName: 'THETFORD MINES', countryName: 'CANADA' },
  { placeId: 8641, cityName: 'THICKET PORTAGE', countryName: 'CANADA' },
  { placeId: 94, cityName: 'THOMPSON', countryName: 'CANADA' },
  { placeId: 8649, cityName: 'THORBURN', countryName: 'CANADA' },
  { placeId: 8652, cityName: 'THREE HILLS', countryName: 'CANADA' },
  { placeId: 83, cityName: 'THUNDER BAY', countryName: 'CANADA' },
  { placeId: 8665, cityName: 'TILLSONBURG', countryName: 'CANADA' },
  { placeId: 97, cityName: 'TIMMINS', countryName: 'CANADA' },
  { placeId: 8671, cityName: 'TIPELLA', countryName: 'CANADA' },
  { placeId: 8675, cityName: 'TISDALE', countryName: 'CANADA' },
  { placeId: 8677, cityName: 'TOAD RIVER', countryName: 'CANADA' },
  { placeId: 8678, cityName: 'TOBERMORY', countryName: 'CANADA' },
  { placeId: 8682, cityName: 'TOFIELD', countryName: 'CANADA' },
  { placeId: 8345, cityName: 'TOFINO', countryName: 'CANADA' },
  {
    placeId: 60, cityName: 'TORONTO', state: 'ON', countryName: 'CANADA',
  },
  { placeId: 8685, cityName: 'TOTTENHAM', countryName: 'CANADA' },
  { placeId: 8694, cityName: 'TRAIL', countryName: 'CANADA' },
  { placeId: 8696, cityName: 'TREHERNE', countryName: 'CANADA' },
  { placeId: 13478, cityName: 'TREHERNE', countryName: 'CANADA' },
  { placeId: 96, cityName: 'TRENTON', countryName: 'CANADA' },
  { placeId: 8700, cityName: 'TRENTON', countryName: 'CANADA' },
  { placeId: 8707, cityName: 'TROIS-RIVIERES', countryName: 'CANADA' },
  { placeId: 8708, cityName: 'TROUT LAKE', countryName: 'CANADA' },
  { placeId: 8709, cityName: 'TROUT LAKE', countryName: 'CANADA' },
  { placeId: 8716, cityName: 'TSACHA LAKE', countryName: 'CANADA' },
  { placeId: 8718, cityName: 'TSAY KEH', countryName: 'CANADA' },
  { placeId: 8719, cityName: 'TSETZI LAKE', countryName: 'CANADA' },
  { placeId: 8730, cityName: 'TSUNIAH LAKE', countryName: 'CANADA' },
  { placeId: 98, cityName: 'TUKTOYAKTUK', countryName: 'CANADA' },
  { placeId: 8739, cityName: 'TULITA', countryName: 'CANADA' },
  { placeId: 2880, cityName: 'TUMBLER RIDGE', countryName: 'CANADA' },
  { placeId: 8746, cityName: 'TUNDRA MINE/SALMITA MINE', countryName: 'CANADA' },
  { placeId: 2879, cityName: 'TUNGSTEN', countryName: 'CANADA' },
  { placeId: 8754, cityName: 'TURNER VALLEY BAR N RANCH', countryName: 'CANADA' },
  { placeId: 8761, cityName: 'TWIN CREEKS', countryName: 'CANADA' },
  { placeId: 8764, cityName: 'TWO HILLS', countryName: 'CANADA' },
  { placeId: 8765, cityName: 'TYENDINAGA', countryName: 'CANADA' },
  { placeId: 13536, cityName: 'ULUKHAKTOK', countryName: 'CANADA' },
  { placeId: 8786, cityName: 'UMIUJAQ', countryName: 'CANADA' },
  { placeId: 8793, cityName: 'UNITY', countryName: 'CANADA' },
  { placeId: 8800, cityName: 'URANIUM CITY', countryName: 'CANADA' },
  { placeId: 102, cityName: 'VAL-D\'OR', countryName: 'CANADA' },
  { placeId: 8814, cityName: 'VALCOURT', countryName: 'CANADA' },
  { placeId: 8816, cityName: 'VALEMOUNT', countryName: 'CANADA' },
  { placeId: 8173, cityName: 'VALLEY', countryName: 'CANADA' },
  { placeId: 8819, cityName: 'VALLEYFIELD', countryName: 'CANADA' },
  { placeId: 8820, cityName: 'VALLEYVIEW', countryName: 'CANADA' },
  {
    placeId: 105, cityName: 'VANCOUVER', state: 'BC', countryName: 'CANADA',
  },
  { placeId: 8825, cityName: 'VANDERHOOF', countryName: 'CANADA' },
  { placeId: 8830, cityName: 'VAUXHALL', countryName: 'CANADA' },
  { placeId: 8833, cityName: 'VEGREVILLE', countryName: 'CANADA' },
  { placeId: 8836, cityName: 'VERMILION', countryName: 'CANADA' },
  { placeId: 2896, cityName: 'VERMILLION BAY', countryName: 'CANADA' },
  { placeId: 8838, cityName: 'VERNON', countryName: 'CANADA' },
  { placeId: 8843, cityName: 'VICTOR MINE', countryName: 'CANADA' },
  {
    placeId: 125, cityName: 'VICTORIA', state: 'CA', countryName: 'CANADA',
  },
  { placeId: 8847, cityName: 'VICTORIAVILLE', countryName: 'CANADA' },
  { placeId: 8853, cityName: 'VIKING', countryName: 'CANADA' },
  { placeId: 8866, cityName: 'VIRDEN', countryName: 'CANADA' },
  { placeId: 2908, cityName: 'VOISEY\'S BAY', countryName: 'CANADA' },
  { placeId: 8879, cityName: 'VULCAN', countryName: 'CANADA' },
  { placeId: 13625, cityName: 'VULCAN', countryName: 'CANADA' },
  { placeId: 8881, cityName: 'WABASCA', countryName: 'CANADA' },
  { placeId: 108, cityName: 'WABUSH', countryName: 'CANADA' },
  { placeId: 8884, cityName: 'WADENA', countryName: 'CANADA' },
  { placeId: 8894, cityName: 'WAINWRIGHT', countryName: 'CANADA' },
  { placeId: 8900, cityName: 'WAKAW', countryName: 'CANADA' },
  { placeId: 2889, cityName: 'WAPEKEKA', countryName: 'CANADA' },
  { placeId: 8915, cityName: 'WARBURG', countryName: 'CANADA' },
  { placeId: 8917, cityName: 'WARNER', countryName: 'CANADA' },
  { placeId: 8919, cityName: 'WARREN', countryName: 'CANADA' },
  { placeId: 8928, cityName: 'WASKAGANISH', countryName: 'CANADA' },
  { placeId: 8936, cityName: 'WATERVILLE', countryName: 'CANADA' },
  { placeId: 8940, cityName: 'WATROUS', countryName: 'CANADA' },
  { placeId: 78, cityName: 'WATSON LAKE', countryName: 'CANADA' },
  { placeId: 8951, cityName: 'WAWA', countryName: 'CANADA' },
  { placeId: 8952, cityName: 'WAWOTA', countryName: 'CANADA' },
  { placeId: 8958, cityName: 'WEBEQUIE', countryName: 'CANADA' },
  { placeId: 8962, cityName: 'WEKWETI', countryName: 'CANADA' },
  { placeId: 13713, cityName: 'WELLAND', countryName: 'CANADA' },
  { placeId: 8970, cityName: 'WEMINDJI', countryName: 'CANADA' },
  { placeId: 8983, cityName: 'WEST POPLAR', countryName: 'CANADA' },
  { placeId: 8989, cityName: 'WESTLOCK', countryName: 'CANADA' },
  { placeId: 8992, cityName: 'WESTPORT', countryName: 'CANADA' },
  { placeId: 8179, cityName: 'WETASKIWIN', countryName: 'CANADA' },
  { placeId: 8181, cityName: 'WEYBURN', countryName: 'CANADA' },
  { placeId: 8182, cityName: 'WEYMAN', countryName: 'CANADA' },
  { placeId: 8183, cityName: 'WEYMONTACHIE', countryName: 'CANADA' },
  { placeId: 13743, cityName: 'WHALE COVE', countryName: 'CANADA' },
  { placeId: 8998, cityName: 'WHITECOURT', countryName: 'CANADA' },
  {
    placeId: 119, cityName: 'WHITEHORSE', state: '', countryName: 'CANADA',
  },
  { placeId: 9001, cityName: 'WHITEWOOD', countryName: 'CANADA' },
  { placeId: 9004, cityName: 'WIARTON', countryName: 'CANADA' },
  { placeId: 13761, cityName: 'WILDWOOD', countryName: 'CANADA' },
  { placeId: 9006, cityName: 'WILEY', countryName: 'CANADA' },
  { placeId: 13766, cityName: 'WILLIAMS HARBOUR', countryName: 'CANADA' },
  { placeId: 109, cityName: 'WILLIAMS LAKE', countryName: 'CANADA' },
  { placeId: 9017, cityName: 'WILLOW BUNCH', countryName: 'CANADA' },
  { placeId: 9022, cityName: 'WINCHESTER', countryName: 'CANADA' },
  { placeId: 9026, cityName: 'WINDERMERE', countryName: 'CANADA' },
  { placeId: 77, cityName: 'WINDSOR', countryName: 'CANADA' },
  { placeId: 9029, cityName: 'WINGHAM', countryName: 'CANADA' },
  { placeId: 9031, cityName: 'WINKLER', countryName: 'CANADA' },
  {
    placeId: 107, cityName: 'WINNIPEG', state: 'MB', countryName: 'CANADA',
  },
  { placeId: 9037, cityName: 'WINTERLAND', countryName: 'CANADA' },
  { placeId: 9044, cityName: 'WOLLASTON LAKE', countryName: 'CANADA' },
  { placeId: 9045, cityName: 'WOLVERINE', countryName: 'CANADA' },
  { placeId: 9047, cityName: 'WOODCOCK', countryName: 'CANADA' },
  { placeId: 8187, cityName: 'WOODSTOCK ONTARIO', countryName: 'CANADA' },
  { placeId: 8186, cityName: 'WOODSTOCK', countryName: 'CANADA' },
  { placeId: 9053, cityName: 'WRIGLEY', countryName: 'CANADA' },
  { placeId: 9054, cityName: 'WUNNUMIN LAKE', countryName: 'CANADA' },
  { placeId: 9055, cityName: 'WYEVALE', countryName: 'CANADA' },
  { placeId: 9056, cityName: 'WYNYARD', countryName: 'CANADA' },
  { placeId: 13853, cityName: 'YARMOUTH', countryName: 'CANADA' },
  { placeId: 133, cityName: 'YELLOWKNIFE', countryName: 'CANADA' },
  { placeId: 9094, cityName: 'YORK LANDING', countryName: 'CANADA' },
  { placeId: 9092, cityName: 'YORK', countryName: 'CANADA' },
  { placeId: 85, cityName: 'YORKTON', countryName: 'CANADA' },
  { placeId: 9104, cityName: 'ZAMA LAKE', countryName: 'CANADA' },
  { placeId: 9103, cityName: 'ZAMA', countryName: 'CANADA' },
  { placeId: 9122, cityName: 'ZHODA', countryName: 'CANADA' },
  { placeId: 2933, cityName: 'EL BERRIEL', countryName: 'CANARY IS' },
  { placeId: 1173, cityName: 'FUERTEVENTURA', countryName: 'CANARY IS' },
  { placeId: 1175, cityName: 'GRAN CANARIA', countryName: 'CANARY IS' },
  { placeId: 2932, cityName: 'HIERRO', countryName: 'CANARY IS' },
  { placeId: 2931, cityName: 'LA GOMERA', countryName: 'CANARY IS' },
  { placeId: 1174, cityName: 'LA PALMA', countryName: 'CANARY IS' },
  { placeId: 1176, cityName: 'LANZAROTE', countryName: 'CANARY IS' },
  { placeId: 1178, cityName: 'TENERIFE-NORTH', countryName: 'CANARY IS' },
  { placeId: 1177, cityName: 'TENERIFE-SOUTH', countryName: 'CANARY IS' },
  { placeId: 6719, cityName: 'MAIO', countryName: 'CAPE VERDE ISLANDS' },
  { placeId: 7085, cityName: 'MOSTEIROS', countryName: 'CAPE VERDE ISLANDS' },
  { placeId: 1199, cityName: 'PRAIA', countryName: 'CAPE VERDE ISLANDS' },
  { placeId: 7760, cityName: 'PREGUICA', countryName: 'CAPE VERDE ISLANDS' },
  { placeId: 7839, cityName: 'RABIL', countryName: 'CAPE VERDE ISLANDS' },
  { placeId: 1198, cityName: 'SAL', countryName: 'CAPE VERDE ISLANDS' },
  { placeId: 8111, cityName: 'SAO FILIPE', countryName: 'CAPE VERDE ISLANDS' },
  { placeId: 1200, cityName: 'SAO VICENTE', countryName: 'CAPE VERDE ISLANDS' },
  { placeId: 2323, cityName: 'CAYMAN BRAC', countryName: 'CAYMAN IS' },
  { placeId: 2324, cityName: 'GRAND CAYMAN', countryName: 'CAYMAN IS' },
  { placeId: 4080, cityName: 'ALINDAO', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 4318, cityName: 'BAMBARI', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 4332, cityName: 'BANGASSOU', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 1127, cityName: 'BANGUI', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 4437, cityName: 'BERBERATI', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 4480, cityName: 'BIRAO', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 4556, cityName: 'BOSSANGOA', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 4563, cityName: 'BOUAR', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 4579, cityName: 'BOZOUM', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 4595, cityName: 'BRIA', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 6930, cityName: 'MELLE', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 7162, cityName: 'N\'DELE', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 7348, cityName: 'OBO', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 7300, cityName: 'OUADDA', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 7438, cityName: 'OUANDA-DJALLE', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 7502, cityName: 'RAFAI', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 9066, cityName: 'YALINGA', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 9118, cityName: 'ZEMIO', countryName: 'CENTRAL AFRICAN REP' },
  { placeId: 9141, cityName: 'ABECHE', countryName: 'CHAD' },
  { placeId: 4103, cityName: 'AM-TIMAN', countryName: 'CHAD' },
  { placeId: 4261, cityName: 'ATI', countryName: 'CHAD' },
  { placeId: 4344, cityName: 'BARDAI', countryName: 'CHAD' },
  { placeId: 4474, cityName: 'BILTINE', countryName: 'CHAD' },
  { placeId: 4526, cityName: 'BOKORO', countryName: 'CHAD' },
  { placeId: 4529, cityName: 'BOL', countryName: 'CHAD' },
  { placeId: 4538, cityName: 'BONGOR', countryName: 'CHAD' },
  { placeId: 4573, cityName: 'BOUSSO', countryName: 'CHAD' },
  { placeId: 5348, cityName: 'FADA', countryName: 'CHAD' },
  { placeId: 5380, cityName: 'FAYA-LARGEAU', countryName: 'CHAD' },
  { placeId: 5607, cityName: 'GOZ-BEIDA', countryName: 'CHAD' },
  { placeId: 6407, cityName: 'LAI', countryName: 'CHAD' },
  { placeId: 6787, cityName: 'MAO', countryName: 'CHAD' },
  { placeId: 7026, cityName: 'MONGO', countryName: 'CHAD' },
  { placeId: 7091, cityName: 'MOUNDOU', countryName: 'CHAD' },
  { placeId: 1156, cityName: 'N\'DJAMENA', countryName: 'CHAD' },
  { placeId: 7477, cityName: 'PALA', countryName: 'CHAD' },
  { placeId: 8119, cityName: 'SARH', countryName: 'CHAD' },
  { placeId: 9126, cityName: 'ZOUAR', countryName: 'CHAD' },
  { placeId: 10490, cityName: 'DIEGO GARCIA', countryName: 'CHAGOS ARCHIPELAGO' },
  { placeId: 4093, cityName: 'ALTO PALENA', countryName: 'CHILE' },
  { placeId: 4128, cityName: 'ANCUD', countryName: 'CHILE' },
  { placeId: 4163, cityName: 'ANTOFAGASTA', countryName: 'CHILE' },
  { placeId: 4215, cityName: 'ARICA', countryName: 'CHILE' },
  { placeId: 2496, cityName: 'BALMACEDA', countryName: 'CHILE' },
  { placeId: 4678, cityName: 'CALAMA', countryName: 'CHILE' },
  { placeId: 4682, cityName: 'CALDERA', countryName: 'CHILE' },
  { placeId: 4756, cityName: 'CASTRO, CHILE', countryName: 'CHILE' },
  { placeId: 4779, cityName: 'CERRO SOMBRERO', countryName: 'CHILE' },
  { placeId: 4785, cityName: 'CHAITEN', countryName: 'CHILE' },
  { placeId: 3988, cityName: 'CHANARAL', countryName: 'CHILE' },
  { placeId: 4823, cityName: 'CHILE CHICO', countryName: 'CHILE' },
  { placeId: 4825, cityName: 'CHILLAN', countryName: 'CHILE' },
  { placeId: 4912, cityName: 'COCHRANE', countryName: 'CHILE' },
  { placeId: 2499, cityName: 'CONCEPCION', countryName: 'CHILE' },
  { placeId: 4948, cityName: 'CONSTITUCION', countryName: 'CHILE' },
  { placeId: 4955, cityName: 'COPIAPO', countryName: 'CHILE' },
  { placeId: 4983, cityName: 'COYHAIQUE', countryName: 'CHILE' },
  { placeId: 5012, cityName: 'CURACAVI', countryName: 'CHILE' },
  { placeId: 5296, cityName: 'EL SALVADOR', countryName: 'CHILE' },
  { placeId: 5500, cityName: 'FUNDO LOMA LARGA', countryName: 'CHILE' },
  { placeId: 5503, cityName: 'FUTALEUFU', countryName: 'CHILE' },
  { placeId: 5924, cityName: 'ICALMA', countryName: 'CHILE' },
  { placeId: 5986, cityName: 'IQUIQUE', countryName: 'CHILE' },
  { placeId: 5999, cityName: 'ISLA DAWSON', countryName: 'CHILE' },
  { placeId: 6005, cityName: 'ISLA MOCHA', countryName: 'CHILE' },
  { placeId: 2503, cityName: 'LA SERENA', countryName: 'CHILE' },
  { placeId: 6386, cityName: 'LA VINA', countryName: 'CHILE' },
  { placeId: 6563, cityName: 'LINARES', countryName: 'CHILE' },
  { placeId: 6590, cityName: 'LLANADA GRANDE', countryName: 'CHILE' },
  { placeId: 6621, cityName: 'LOS ANDES', countryName: 'CHILE' },
  { placeId: 6622, cityName: 'LOS ANGELES', countryName: 'CHILE' },
  { placeId: 6815, cityName: 'MARINA DE RAPEL', countryName: 'CHILE' },
  { placeId: 11943, cityName: 'MELINKA', countryName: 'CHILE' },
  { placeId: 2501, cityName: 'OSORNO', countryName: 'CHILE' },
  { placeId: 12385, cityName: 'OVALLE', countryName: 'CHILE' },
  { placeId: 7485, cityName: 'PALO ALTO', countryName: 'CHILE' },
  { placeId: 7661, cityName: 'PIRQUE', countryName: 'CHILE' },
  { placeId: 7742, cityName: 'PORVENIR', countryName: 'CHILE' },
  { placeId: 7777, cityName: 'PUCON', countryName: 'CHILE' },
  { placeId: 7780, cityName: 'PUERTO AYSEN', countryName: 'CHILE' },
  { placeId: 2505, cityName: 'PUERTO MONTT', countryName: 'CHILE' },
  { placeId: 2502, cityName: 'PUERTO NATALES', countryName: 'CHILE' },
  { placeId: 7794, cityName: 'PUERTO VARAS', countryName: 'CHILE' },
  { placeId: 7795, cityName: 'PUERTO WILLIAMS', countryName: 'CHILE' },
  { placeId: 2497, cityName: 'PUNTA ARENAS', countryName: 'CHILE' },
  { placeId: 7810, cityName: 'PUYUHUAPI', countryName: 'CHILE' },
  { placeId: 7826, cityName: 'QUELLON', countryName: 'CHILE' },
  { placeId: 7836, cityName: 'QUINTERO', countryName: 'CHILE' },
  { placeId: 7848, cityName: 'RANCAGUA', countryName: 'CHILE' },
  { placeId: 7854, cityName: 'RAPEL', countryName: 'CHILE' },
  { placeId: 7928, cityName: 'ROBINSON CRUSOE IS', countryName: 'CHILE' },
  { placeId: 7734, cityName: 'RUSSFIN', countryName: 'CHILE' },
  { placeId: 7519, cityName: 'SALAR DE ATACAMA', countryName: 'CHILE' },
  { placeId: 8039, cityName: 'SAN FELIPE', countryName: 'CHILE' },
  { placeId: 8040, cityName: 'SAN FERNANDO', countryName: 'CHILE' },
  { placeId: 8058, cityName: 'SAN PEDRO DE ATACAMA', countryName: 'CHILE' },
  { placeId: 8066, cityName: 'SAN SEBASTIAN', countryName: 'CHILE' },
  { placeId: 2498, cityName: 'SANTIAGO', countryName: 'CHILE' },
  { placeId: 12956, cityName: 'SANTO DOMINGO', countryName: 'CHILE' },
  { placeId: 8558, cityName: 'TALCA', countryName: 'CHILE' },
  { placeId: 8563, cityName: 'TALTAL', countryName: 'CHILE' },
  { placeId: 2504, cityName: 'TEMUCO', countryName: 'CHILE' },
  { placeId: 8657, cityName: 'TIC TOC', countryName: 'CHILE' },
  { placeId: 8681, cityName: 'TOCOPILLA', countryName: 'CHILE' },
  { placeId: 8815, cityName: 'VALDIVIA', countryName: 'CHILE' },
  { placeId: 8172, cityName: 'VALLENAR', countryName: 'CHILE' },
  { placeId: 8844, cityName: 'VICTORIA', countryName: 'CHILE' },
  { placeId: 8857, cityName: 'VILLA O\'HIGGINS', countryName: 'CHILE' },
  { placeId: 8860, cityName: 'VINA DEL MAR', countryName: 'CHILE' },
  // { placeId: 9461, cityName: '', countryName: 'CHINA PR OF' },
  { placeId: 13921, cityName: 'Yining', countryName: 'CHINA PR OF' },
  { placeId: 9236, cityName: 'ZHEJIANG', countryName: 'CHINA PR OF' },
  // { placeId: 9436, cityName: '', countryName: 'CHINA, PR OF' },
  { placeId: 14152, cityName: 'BAISHAN', countryName: 'CHINA, PR OF' },
  { placeId: 2779, cityName: 'BEIJING', countryName: 'CHINA, PR OF' },
  { placeId: 2808, cityName: 'CHANGCHUN', countryName: 'CHINA, PR OF' },
  { placeId: 2785, cityName: 'CHANGSHA', countryName: 'CHINA, PR OF' },
  { placeId: 2805, cityName: 'CHENGDU', countryName: 'CHINA, PR OF' },
  { placeId: 2804, cityName: 'CHONGQING', countryName: 'CHINA, PR OF' },
  { placeId: 2810, cityName: 'DALIAN', countryName: 'CHINA, PR OF' },
  { placeId: 14249, cityName: 'DEHONG', countryName: 'CHINA, PR OF' },
  { placeId: 2798, cityName: 'FUZHOU', countryName: 'CHINA, PR OF' },
  { placeId: 2784, cityName: 'GUANGZHOU', countryName: 'CHINA, PR OF' },
  { placeId: 2786, cityName: 'GUILIN', countryName: 'CHINA, PR OF' },
  { placeId: 2791, cityName: 'HAIKOU', countryName: 'CHINA, PR OF' },
  { placeId: 5769, cityName: 'HAILAR', countryName: 'CHINA, PR OF' },
  { placeId: 2799, cityName: 'HANGZHOU', countryName: 'CHINA, PR OF' },
  { placeId: 2809, cityName: 'HARBIN', countryName: 'CHINA, PR OF' },
  { placeId: 2801, cityName: 'HEFEI', countryName: 'CHINA, PR OF' },
  { placeId: 2780, cityName: 'HOHHOT', countryName: 'CHINA, PR OF' },
  { placeId: 5885, cityName: 'HOTAN', countryName: 'CHINA, PR OF' },
  { placeId: 6061, cityName: 'JIAMUSI', countryName: 'CHINA, PR OF' },
  { placeId: 6064, cityName: 'JINAN', countryName: 'CHINA, PR OF' },
  { placeId: 2806, cityName: 'KASHI', countryName: 'CHINA, PR OF' },
  { placeId: 2796, cityName: 'KUNMING', countryName: 'CHINA, PR OF' },
  { placeId: 2793, cityName: 'LANZHOU', countryName: 'CHINA, PR OF' },
  {
    placeId: 14151, cityName: 'LIBO', state: 'Guizhou Province<', countryName: 'CHINA, PR OF',
  },
  { placeId: 14250, cityName: 'LINCANG', countryName: 'CHINA, PR OF' },
  { placeId: 14199, cityName: 'LUOYANG', countryName: 'CHINA, PR OF' },
  { placeId: 7118, cityName: 'MUDANJIANG', countryName: 'CHINA, PR OF' },
  { placeId: 7180, cityName: 'NANCHANG', countryName: 'CHINA, PR OF' },
  { placeId: 2800, cityName: 'NANJING', countryName: 'CHINA, PR OF' },
  { placeId: 2787, cityName: 'NANNING', countryName: 'CHINA, PR OF' },
  { placeId: 7261, cityName: 'NINGBO', countryName: 'CHINA, PR OF' },
  { placeId: 2803, cityName: 'QINGDAO', countryName: 'CHINA, PR OF' },
  { placeId: 7819, cityName: 'QIQIHAR', countryName: 'CHINA, PR OF' },
  { placeId: 2792, cityName: 'SANYA', countryName: 'CHINA, PR OF' },
  { placeId: 2802, cityName: 'SHANGHAI', countryName: 'CHINA, PR OF' },
  { placeId: 2811, cityName: 'SHENYANG', countryName: 'CHINA, PR OF' },
  { placeId: 2788, cityName: 'SHENZHEN', countryName: 'CHINA, PR OF' },
  { placeId: 2781, cityName: 'SHIJIAZHUANG', countryName: 'CHINA, PR OF' },
  { placeId: 2783, cityName: 'TAIYUAN', countryName: 'CHINA, PR OF' },
  { placeId: 2782, cityName: 'TIANJIN', countryName: 'CHINA, PR OF' },
  {
    placeId: 9480, cityName: 'TONGLIAO', state: 'INNER MONGOLIA', countryName: 'CHINA, PR OF',
  },
  { placeId: 2807, cityName: 'URUMQI', countryName: 'CHINA, PR OF' },
  { placeId: 13910, cityName: 'WENZHOU', countryName: 'CHINA, PR OF' },
  { placeId: 2790, cityName: 'WUHAN', countryName: 'CHINA, PR OF' },
  {
    placeId: 13905, cityName: 'Wuxi', state: 'JIANGSU', countryName: 'CHINA, PR OF',
  },
  { placeId: 2794, cityName: 'XI\'AN', countryName: 'CHINA, PR OF' },
  { placeId: 2797, cityName: 'XIAMEN', countryName: 'CHINA, PR OF' },
  { placeId: 9058, cityName: 'XICHANG', countryName: 'CHINA, PR OF' },
  { placeId: 9060, cityName: 'XISHUANGBANNA', countryName: 'CHINA, PR OF' },
  { placeId: 9072, cityName: 'YANJI', countryName: 'CHINA, PR OF' },
  { placeId: 9073, cityName: 'YANTAI', countryName: 'CHINA, PR OF' },
  { placeId: 2789, cityName: 'ZHENGZHOU', countryName: 'CHINA, PR OF' },
  {
    placeId: 14150, cityName: 'Zhongwei', state: 'NINGXIA', countryName: 'CHINA, PR OF',
  },
  // { placeId: 9441, cityName: '', countryName: 'CHINA' },
  { placeId: 13901, cityName: 'Dandong', countryName: 'CHINA' },
  { placeId: 13932, cityName: 'Datong', countryName: 'CHINA' },
  {
    placeId: 13934, cityName: 'Dunhuang', state: 'GANSU', countryName: 'CHINA',
  },
  { placeId: 11022, cityName: 'GUIYANG', countryName: 'CHINA' },
  { placeId: 11204, cityName: 'HUANGSHAN', countryName: 'CHINA' },
  { placeId: 11211, cityName: 'HULUNBEIER', countryName: 'CHINA' },
  { placeId: 13931, cityName: 'Jiayuguan', countryName: 'CHINA' },
  { placeId: 11333, cityName: 'JIEYANG', countryName: 'CHINA' },
  { placeId: 11685, cityName: 'LHASA', countryName: 'CHINA' },
  { placeId: 13923, cityName: 'Lianyungang', countryName: 'CHINA' },
  {
    placeId: 13903, cityName: 'Lijiang', state: 'YUNNAN', countryName: 'CHINA',
  },
  { placeId: 9476, cityName: 'LONGYAN', countryName: 'CHINA' },
  { placeId: 11839, cityName: 'MANZHOULI', countryName: 'CHINA' },
  { placeId: 9156, cityName: 'NANTONG', countryName: 'CHINA' },
  { placeId: 12693, cityName: 'QUANZHOU', countryName: 'CHINA' },
  { placeId: 13709, cityName: 'WEIHAI', countryName: 'CHINA' },
  { placeId: 13838, cityName: 'XINING', countryName: 'CHINA' },
  { placeId: 13846, cityName: 'YANCHENG', countryName: 'CHINA' },
  { placeId: 13933, cityName: 'Yichang', countryName: 'CHINA' },
  { placeId: 13862, cityName: 'YINCHUAN', countryName: 'CHINA' },
  { placeId: 13891, cityName: 'ZHANGJIAJIE', countryName: 'CHINA' },
  { placeId: 4010, cityName: 'ACANDI', countryName: 'COLOMBIA' },
  { placeId: 4023, cityName: 'AGUACHICA', countryName: 'COLOMBIA' },
  { placeId: 4201, cityName: 'ARARACUARA', countryName: 'COLOMBIA' },
  { placeId: 4202, cityName: 'ARAUCA', countryName: 'COLOMBIA' },
  { placeId: 4220, cityName: 'ARMENIA', countryName: 'COLOMBIA' },
  { placeId: 4300, cityName: 'BAHIA SOLANO', countryName: 'COLOMBIA' },
  { placeId: 4354, cityName: 'BARRANCABERMEJA', countryName: 'COLOMBIA' },
  { placeId: 2513, cityName: 'BARRANQUILLA', countryName: 'COLOMBIA' },
  { placeId: 2512, cityName: 'BOGOTA', countryName: 'COLOMBIA' },
  { placeId: 4617, cityName: 'BUCARAMANGA', countryName: 'COLOMBIA' },
  { placeId: 4619, cityName: 'BUENAVENTURA', countryName: 'COLOMBIA' },
  { placeId: 2515, cityName: 'CALI', countryName: 'COLOMBIA' },
  { placeId: 4731, cityName: 'CAREPA', countryName: 'COLOMBIA' },
  { placeId: 4740, cityName: 'CARMEN DE BOLIVAR', countryName: 'COLOMBIA' },
  { placeId: 2514, cityName: 'CARTAGENA', countryName: 'COLOMBIA' },
  { placeId: 4195, cityName: 'CARTAGO', countryName: 'COLOMBIA' },
  { placeId: 4750, cityName: 'CARURU', countryName: 'COLOMBIA' },
  { placeId: 4762, cityName: 'CAUCASIA', countryName: 'COLOMBIA' },
  { placeId: 3996, cityName: 'CHAPARRAL', countryName: 'COLOMBIA' },
  { placeId: 4821, cityName: 'CHIGORODO', countryName: 'COLOMBIA' },
  { placeId: 4864, cityName: 'CIMITARRA', countryName: 'COLOMBIA' },
  { placeId: 4941, cityName: 'CONDOTO', countryName: 'COLOMBIA' },
  { placeId: 4197, cityName: 'COROZAL', countryName: 'COLOMBIA' },
  { placeId: 4980, cityName: 'COVENAS', countryName: 'COLOMBIA' },
  { placeId: 4987, cityName: 'CRAVO NORTE', countryName: 'COLOMBIA' },
  { placeId: 2848, cityName: 'CUCUTA', countryName: 'COLOMBIA' },
  { placeId: 5277, cityName: 'EL BAGRE', countryName: 'COLOMBIA' },
  { placeId: 5278, cityName: 'EL BANCO', countryName: 'COLOMBIA' },
  { placeId: 5299, cityName: 'EL YOPAL', countryName: 'COLOMBIA' },
  { placeId: 5414, cityName: 'FLORENCIA', countryName: 'COLOMBIA' },
  { placeId: 5536, cityName: 'GARZON', countryName: 'COLOMBIA' },
  { placeId: 5407, cityName: 'GIRARDOT', countryName: 'COLOMBIA' },
  { placeId: 5727, cityName: 'GUAPI', countryName: 'COLOMBIA' },
  { placeId: 5733, cityName: 'GUAYMARAL', countryName: 'COLOMBIA' },
  { placeId: 5618, cityName: 'HATO COROZAL', countryName: 'COLOMBIA' },
  { placeId: 5921, cityName: 'IBAGUE', countryName: 'COLOMBIA' },
  { placeId: 5984, cityName: 'IPIALES', countryName: 'COLOMBIA' },
  { placeId: 6006, cityName: 'ISLA PROVIDENCIA', countryName: 'COLOMBIA' },
  { placeId: 6378, cityName: 'LA PEDRERA', countryName: 'COLOMBIA' },
  { placeId: 2516, cityName: 'LETICIA', countryName: 'COLOMBIA' },
  { placeId: 6699, cityName: 'MADRID', countryName: 'COLOMBIA' },
  { placeId: 6706, cityName: 'MAGANGUE', countryName: 'COLOMBIA' },
  { placeId: 6715, cityName: 'MAICAO', countryName: 'COLOMBIA' },
  { placeId: 6730, cityName: 'MALAGA', countryName: 'COLOMBIA' },
  { placeId: 6761, cityName: 'MANI', countryName: 'COLOMBIA' },
  { placeId: 6770, cityName: 'MANIZALES', countryName: 'COLOMBIA' },
  { placeId: 6822, cityName: 'MARIQUITA', countryName: 'COLOMBIA' },
  { placeId: 6918, cityName: 'MEDELLIN', countryName: 'COLOMBIA' },
  { placeId: 6994, cityName: 'MIRAFLORES', countryName: 'COLOMBIA' },
  { placeId: 6999, cityName: 'MITU', countryName: 'COLOMBIA' },
  { placeId: 7021, cityName: 'MOMPOS', countryName: 'COLOMBIA' },
  { placeId: 7043, cityName: 'MONTELIBANO', countryName: 'COLOMBIA' },
  { placeId: 7046, cityName: 'MONTERIA', countryName: 'COLOMBIA' },
  { placeId: 7208, cityName: 'NECOCLI', countryName: 'COLOMBIA' },
  { placeId: 7215, cityName: 'NEIVA', countryName: 'COLOMBIA' },
  { placeId: 7322, cityName: 'NUQUI', countryName: 'COLOMBIA' },
  { placeId: 7354, cityName: 'OCANA', countryName: 'COLOMBIA' },
  { placeId: 7417, cityName: 'ORITO', countryName: 'COLOMBIA' },
  { placeId: 7422, cityName: 'OROCUE', countryName: 'COLOMBIA' },
  { placeId: 7473, cityName: 'PAIPA', countryName: 'COLOMBIA' },
  { placeId: 6511, cityName: 'PALANQUERO', countryName: 'COLOMBIA' },
  { placeId: 7572, cityName: 'PASTO', countryName: 'COLOMBIA' },
  { placeId: 7589, cityName: 'PAZ DE ARIPORO', countryName: 'COLOMBIA' },
  { placeId: 7611, cityName: 'PEREIRA', countryName: 'COLOMBIA' },
  { placeId: 7662, cityName: 'PITALITO', countryName: 'COLOMBIA' },
  { placeId: 7688, cityName: 'POPAYAN', countryName: 'COLOMBIA' },
  { placeId: 7713, cityName: 'PORTETE', countryName: 'COLOMBIA' },
  { placeId: 7778, cityName: 'PUERTO ASIS', countryName: 'COLOMBIA' },
  { placeId: 7781, cityName: 'PUERTO BERRIO', countryName: 'COLOMBIA' },
  { placeId: 7785, cityName: 'PUERTO CARRENO', countryName: 'COLOMBIA' },
  { placeId: 7787, cityName: 'PUERTO INIRIDA', countryName: 'COLOMBIA' },
  { placeId: 7788, cityName: 'PUERTO LEGUIZAMO', countryName: 'COLOMBIA' },
  { placeId: 7829, cityName: 'QUIBDO', countryName: 'COLOMBIA' },
  { placeId: 7837, cityName: 'QUIPAMA', countryName: 'COLOMBIA' },
  { placeId: 7893, cityName: 'REMEDIOS', countryName: 'COLOMBIA' },
  { placeId: 7921, cityName: 'RIOHACHA', countryName: 'COLOMBIA' },
  { placeId: 2517, cityName: 'RIONEGRO', countryName: 'COLOMBIA' },
  { placeId: 2518, cityName: 'SAN ANDRES', countryName: 'COLOMBIA' },
  { placeId: 8051, cityName: 'SAN JOSE DEL GUAVIARE', countryName: 'COLOMBIA' },
  { placeId: 8069, cityName: 'SAN VICENTE DEL CAGUAN', countryName: 'COLOMBIA' },
  { placeId: 8099, cityName: 'SANTA MARTA', countryName: 'COLOMBIA' },
  { placeId: 8118, cityName: 'SARAVENA', countryName: 'COLOMBIA' },
  { placeId: 8304, cityName: 'SOGAMOSO', countryName: 'COLOMBIA' },
  { placeId: 8452, cityName: 'STA FE DE ANTIOQUIA', countryName: 'COLOMBIA' },
  { placeId: 8570, cityName: 'TAME', countryName: 'COLOMBIA' },
  { placeId: 8656, cityName: 'TIBU', countryName: 'COLOMBIA' },
  { placeId: 8353, cityName: 'TOLU', countryName: 'COLOMBIA' },
  { placeId: 8706, cityName: 'TRINIDAD', countryName: 'COLOMBIA' },
  { placeId: 8741, cityName: 'TULUA', countryName: 'COLOMBIA' },
  { placeId: 8742, cityName: 'TUMACO', countryName: 'COLOMBIA' },
  { placeId: 8753, cityName: 'TURBO', countryName: 'COLOMBIA' },
  { placeId: 8802, cityName: 'URRAO', countryName: 'COLOMBIA' },
  { placeId: 8170, cityName: 'VALLEDUPAR', countryName: 'COLOMBIA' },
  { placeId: 8858, cityName: 'VILLAGARZON', countryName: 'COLOMBIA' },
  { placeId: 2857, cityName: 'VILLAVICENCIO', countryName: 'COLOMBIA' },
  { placeId: 4150, cityName: 'ANJOUAN', countryName: 'COMOROS' },
  { placeId: 7008, cityName: 'MOHELI', countryName: 'COMOROS' },
  { placeId: 1134, cityName: 'MORONI', countryName: 'COMOROS' },
  { placeId: 9137, cityName: 'ABA', countryName: 'CONGO, DR OF' },
  { placeId: 4008, cityName: 'ABUMUMBAZI', countryName: 'CONGO, DR OF' },
  { placeId: 4034, cityName: 'AKETI', countryName: 'CONGO, DR OF' },
  { placeId: 4319, cityName: 'BAMBILI-DINGILA', countryName: 'CONGO, DR OF' },
  { placeId: 4327, cityName: 'BANDUNDU', countryName: 'CONGO, DR OF' },
  { placeId: 4330, cityName: 'BANGA', countryName: 'CONGO, DR OF' },
  { placeId: 4364, cityName: 'BASANKUSU', countryName: 'CONGO, DR OF' },
  { placeId: 4367, cityName: 'BASONGO', countryName: 'CONGO, DR OF' },
  { placeId: 4180, cityName: 'BAU', countryName: 'CONGO, DR OF' },
  { placeId: 4425, cityName: 'BENI-DIBELE', countryName: 'CONGO, DR OF' },
  { placeId: 4423, cityName: 'BENI', countryName: 'CONGO, DR OF' },
  { placeId: 4428, cityName: 'BENO', countryName: 'CONGO, DR OF' },
  { placeId: 4434, cityName: 'BEONGO', countryName: 'CONGO, DR OF' },
  { placeId: 4460, cityName: 'BIBANGA', countryName: 'CONGO, DR OF' },
  { placeId: 4476, cityName: 'BINDJA', countryName: 'CONGO, DR OF' },
  { placeId: 4477, cityName: 'BINGA', countryName: 'CONGO, DR OF' },
  { placeId: 4517, cityName: 'BOENDE', countryName: 'CONGO, DR OF' },
  { placeId: 4523, cityName: 'BOKADA', countryName: 'CONGO, DR OF' },
  { placeId: 4525, cityName: 'BOKELA', countryName: 'CONGO, DR OF' },
  { placeId: 4527, cityName: 'BOKOTE', countryName: 'CONGO, DR OF' },
  { placeId: 4528, cityName: 'BOKUNGU', countryName: 'CONGO, DR OF' },
  { placeId: 4530, cityName: 'BOLILA', countryName: 'CONGO, DR OF' },
  { placeId: 4531, cityName: 'BOLONGONKELE', countryName: 'CONGO, DR OF' },
  { placeId: 4532, cityName: 'BOMA', countryName: 'CONGO, DR OF' },
  { placeId: 4537, cityName: 'BONGIMBA', countryName: 'CONGO, DR OF' },
  { placeId: 4539, cityName: 'BONKITA', countryName: 'CONGO, DR OF' },
  { placeId: 4550, cityName: 'BOSANGO', countryName: 'CONGO, DR OF' },
  { placeId: 4554, cityName: 'BOSOBE', countryName: 'CONGO, DR OF' },
  { placeId: 4555, cityName: 'BOSONDJO', countryName: 'CONGO, DR OF' },
  { placeId: 4559, cityName: 'BOTEKA', countryName: 'CONGO, DR OF' },
  { placeId: 4626, cityName: 'BUKAVU', countryName: 'CONGO, DR OF' },
  { placeId: 4627, cityName: 'BUKENA', countryName: 'CONGO, DR OF' },
  { placeId: 4631, cityName: 'BUMBA', countryName: 'CONGO, DR OF' },
  { placeId: 4634, cityName: 'BUNIA', countryName: 'CONGO, DR OF' },
  { placeId: 4651, cityName: 'BUSALA', countryName: 'CONGO, DR OF' },
  { placeId: 4656, cityName: 'BUTA-ZEGA', countryName: 'CONGO, DR OF' },
  { placeId: 4773, cityName: 'CELO-ZONGO', countryName: 'CONGO, DR OF' },
  { placeId: 5097, cityName: 'DEKESE', countryName: 'CONGO, DR OF' },
  { placeId: 5166, cityName: 'DIBOKO', countryName: 'CONGO, DR OF' },
  { placeId: 5174, cityName: 'DIKUNGU', countryName: 'CONGO, DR OF' },
  { placeId: 5177, cityName: 'DILOLO', countryName: 'CONGO, DR OF' },
  { placeId: 5180, cityName: 'DINGELE', countryName: 'CONGO, DR OF' },
  { placeId: 5186, cityName: 'DJOKELE', countryName: 'CONGO, DR OF' },
  { placeId: 5193, cityName: 'DOKO', countryName: 'CONGO, DR OF' },
  { placeId: 5201, cityName: 'DORUMA', countryName: 'CONGO, DR OF' },
  { placeId: 5222, cityName: 'DUNGU-UYE', countryName: 'CONGO, DR OF' },
  { placeId: 5324, cityName: 'ENGENGELE', countryName: 'CONGO, DR OF' },
  { placeId: 5366, cityName: 'FARADJE', countryName: 'CONGO, DR OF' },
  { placeId: 5501, cityName: 'FUNGURUME', countryName: 'CONGO, DR OF' },
  { placeId: 5522, cityName: 'GANDAJIKA', countryName: 'CONGO, DR OF' },
  { placeId: 5543, cityName: 'GBADO', countryName: 'CONGO, DR OF' },
  { placeId: 5544, cityName: 'GBADOLITE', countryName: 'CONGO, DR OF' },
  { placeId: 5546, cityName: 'GEMENA', countryName: 'CONGO, DR OF' },
  { placeId: 5599, cityName: 'GOMA', countryName: 'CONGO, DR OF' },
  { placeId: 5606, cityName: 'GOYONGO', countryName: 'CONGO, DR OF' },
  { placeId: 5753, cityName: 'GWAKA', countryName: 'CONGO, DR OF' },
  { placeId: 5927, cityName: 'IDIOFA', countryName: 'CONGO, DR OF' },
  { placeId: 5928, cityName: 'IDUMBE', countryName: 'CONGO, DR OF' },
  { placeId: 5937, cityName: 'IKELA', countryName: 'CONGO, DR OF' },
  { placeId: 5945, cityName: 'ILEBO', countryName: 'CONGO, DR OF' },
  { placeId: 5953, cityName: 'IMESSE', countryName: 'CONGO, DR OF' },
  { placeId: 5968, cityName: 'INGA', countryName: 'CONGO, DR OF' },
  { placeId: 5969, cityName: 'INGENDE', countryName: 'CONGO, DR OF' },
  { placeId: 5973, cityName: 'INKISI', countryName: 'CONGO, DR OF' },
  { placeId: 5975, cityName: 'INONGO', countryName: 'CONGO, DR OF' },
  { placeId: 5983, cityName: 'IPEKA', countryName: 'CONGO, DR OF' },
  { placeId: 5993, cityName: 'ISHASHA', countryName: 'CONGO, DR OF' },
  { placeId: 5997, cityName: 'ISIRO-MATARI', countryName: 'CONGO, DR OF' },
  { placeId: 5998, cityName: 'ISIRO-VILLE', countryName: 'CONGO, DR OF' },
  { placeId: 6010, cityName: 'ISONGO', countryName: 'CONGO, DR OF' },
  { placeId: 6013, cityName: 'ITO', countryName: 'CONGO, DR OF' },
  { placeId: 6091, cityName: 'KABALO', countryName: 'CONGO, DR OF' },
  { placeId: 6092, cityName: 'KABINDA', countryName: 'CONGO, DR OF' },
  { placeId: 6093, cityName: 'KABOMBO', countryName: 'CONGO, DR OF' },
  { placeId: 6102, cityName: 'KAHEMBA', countryName: 'CONGO, DR OF' },
  { placeId: 6106, cityName: 'KAILO', countryName: 'CONGO, DR OF' },
  { placeId: 6110, cityName: 'KAJIJI', countryName: 'CONGO, DR OF' },
  { placeId: 6115, cityName: 'KALA', countryName: 'CONGO, DR OF' },
  { placeId: 6120, cityName: 'KALEMIE', countryName: 'CONGO, DR OF' },
  { placeId: 6122, cityName: 'KALIMA', countryName: 'CONGO, DR OF' },
  { placeId: 6125, cityName: 'KALONDA', countryName: 'CONGO, DR OF' },
  { placeId: 5631, cityName: 'KAMINA', countryName: 'CONGO, DR OF' },
  { placeId: 5633, cityName: 'KAMITUGA', countryName: 'CONGO, DR OF' },
  { placeId: 5634, cityName: 'KAMPENE', countryName: 'CONGO, DR OF' },
  { placeId: 5639, cityName: 'KANANGA', countryName: 'CONGO, DR OF' },
  { placeId: 5037, cityName: 'KANIA', countryName: 'CONGO, DR OF' },
  { placeId: 5038, cityName: 'KANIAMA', countryName: 'CONGO, DR OF' },
  { placeId: 6128, cityName: 'KANSIMBA', countryName: 'CONGO, DR OF' },
  { placeId: 6135, cityName: 'KAPANGA', countryName: 'CONGO, DR OF' },
  { placeId: 6139, cityName: 'KARAWA', countryName: 'CONGO, DR OF' },
  { placeId: 6146, cityName: 'KASAJI', countryName: 'CONGO, DR OF' },
  { placeId: 6149, cityName: 'KASENGA', countryName: 'CONGO, DR OF' },
  { placeId: 6150, cityName: 'KASESE', countryName: 'CONGO, DR OF' },
  { placeId: 6153, cityName: 'KASHIA', countryName: 'CONGO, DR OF' },
  { placeId: 6155, cityName: 'KASONGA', countryName: 'CONGO, DR OF' },
  { placeId: 6156, cityName: 'KASONGO', countryName: 'CONGO, DR OF' },
  { placeId: 6160, cityName: 'KATAKO\'KOMBE', countryName: 'CONGO, DR OF' },
  { placeId: 6161, cityName: 'KATALE', countryName: 'CONGO, DR OF' },
  { placeId: 6168, cityName: 'KATUBWE', countryName: 'CONGO, DR OF' },
  { placeId: 6171, cityName: 'KATWE', countryName: 'CONGO, DR OF' },
  { placeId: 6190, cityName: 'KEMPA', countryName: 'CONGO, DR OF' },
  { placeId: 6191, cityName: 'KEMPILE', countryName: 'CONGO, DR OF' },
  { placeId: 6195, cityName: 'KENGE', countryName: 'CONGO, DR OF' },
  { placeId: 6203, cityName: 'KEREKERE', countryName: 'CONGO, DR OF' },
  { placeId: 6215, cityName: 'KIAPUPE', countryName: 'CONGO, DR OF' },
  { placeId: 6224, cityName: 'KIKONGO', countryName: 'CONGO, DR OF' },
  { placeId: 6226, cityName: 'KIKWIT', countryName: 'CONGO, DR OF' },
  { placeId: 6234, cityName: 'KILOMINES', countryName: 'CONGO, DR OF' },
  { placeId: 6238, cityName: 'KIMANO II', countryName: 'CONGO, DR OF' },
  { placeId: 6242, cityName: 'KINDU', countryName: 'CONGO, DR OF' },
  { placeId: 1170, cityName: 'KINSHASA', countryName: 'CONGO, DR OF' },
  { placeId: 6251, cityName: 'KIPATA-KATIKA', countryName: 'CONGO, DR OF' },
  { placeId: 6253, cityName: 'KIPUSHIA', countryName: 'CONGO, DR OF' },
  { placeId: 6262, cityName: 'KISANGANI', countryName: 'CONGO, DR OF' },
  { placeId: 6264, cityName: 'KISENGE', countryName: 'CONGO, DR OF' },
  { placeId: 6265, cityName: 'KISENGWA', countryName: 'CONGO, DR OF' },
  { placeId: 6275, cityName: 'KITONA', countryName: 'CONGO, DR OF' },
  { placeId: 6286, cityName: 'KM 95 CFL', countryName: 'CONGO, DR OF' },
  { placeId: 6293, cityName: 'KODORO', countryName: 'CONGO, DR OF' },
  { placeId: 6307, cityName: 'KOLE SUR LUKENIE', countryName: 'CONGO, DR OF' },
  { placeId: 6311, cityName: 'KOLWEZI', countryName: 'CONGO, DR OF' },
  { placeId: 6312, cityName: 'KONDE', countryName: 'CONGO, DR OF' },
  { placeId: 6316, cityName: 'KONGOLO', countryName: 'CONGO, DR OF' },
  { placeId: 6325, cityName: 'KOTAKOLI', countryName: 'CONGO, DR OF' },
  { placeId: 6350, cityName: 'KUTUSONGO', countryName: 'CONGO, DR OF' },
  { placeId: 6351, cityName: 'KWILU-NGONGO', countryName: 'CONGO, DR OF' },
  { placeId: 6544, cityName: 'LIBENGE', countryName: 'CONGO, DR OF' },
  { placeId: 6551, cityName: 'LIKASI', countryName: 'CONGO, DR OF' },
  { placeId: 6576, cityName: 'LISALA', countryName: 'CONGO, DR OF' },
  { placeId: 6594, cityName: 'LODJA', countryName: 'CONGO, DR OF' },
  { placeId: 6602, cityName: 'LOKUTU', countryName: 'CONGO, DR OF' },
  { placeId: 6604, cityName: 'LOMBO', countryName: 'CONGO, DR OF' },
  { placeId: 6605, cityName: 'LOMELA', countryName: 'CONGO, DR OF' },
  { placeId: 6642, cityName: 'LUBAO', countryName: 'CONGO, DR OF' },
  { placeId: 6643, cityName: 'LUBERO', countryName: 'CONGO, DR OF' },
  { placeId: 6646, cityName: 'LUBONDAIE', countryName: 'CONGO, DR OF' },
  { placeId: 6647, cityName: 'LUBUDI', countryName: 'CONGO, DR OF' },
  { placeId: 6648, cityName: 'LUBUMBASHI', countryName: 'CONGO, DR OF' },
  { placeId: 6655, cityName: 'LUEBO', countryName: 'CONGO, DR OF' },
  { placeId: 6657, cityName: 'LUENA', countryName: 'CONGO, DR OF' },
  { placeId: 6661, cityName: 'LUGUSHWA', countryName: 'CONGO, DR OF' },
  { placeId: 6662, cityName: 'LUISHI', countryName: 'CONGO, DR OF' },
  { placeId: 6663, cityName: 'LUIZA', countryName: 'CONGO, DR OF' },
  { placeId: 6664, cityName: 'LUKALA', countryName: 'CONGO, DR OF' },
  { placeId: 6666, cityName: 'LUKOMBE', countryName: 'CONGO, DR OF' },
  { placeId: 6669, cityName: 'LULINGA', countryName: 'CONGO, DR OF' },
  { placeId: 5654, cityName: 'LUNIEMU', countryName: 'CONGO, DR OF' },
  { placeId: 5655, cityName: 'LUNKUNI', countryName: 'CONGO, DR OF' },
  { placeId: 5656, cityName: 'LUOZI', countryName: 'CONGO, DR OF' },
  { placeId: 6673, cityName: 'LUSAMBO', countryName: 'CONGO, DR OF' },
  { placeId: 6674, cityName: 'LUSANGA', countryName: 'CONGO, DR OF' },
  { placeId: 6676, cityName: 'LUSINGA', countryName: 'CONGO, DR OF' },
  { placeId: 6679, cityName: 'LUVUA', countryName: 'CONGO, DR OF' },
  { placeId: 6711, cityName: 'MAHAGI', countryName: 'CONGO, DR OF' },
  { placeId: 6733, cityName: 'MALANGA', countryName: 'CONGO, DR OF' },
  { placeId: 6740, cityName: 'MALEBO', countryName: 'CONGO, DR OF' },
  { placeId: 6756, cityName: 'MANGAI II', countryName: 'CONGO, DR OF' },
  { placeId: 6778, cityName: 'MANONO', countryName: 'CONGO, DR OF' },
  { placeId: 6785, cityName: 'MANZALELE', countryName: 'CONGO, DR OF' },
  { placeId: 6850, cityName: 'MASI-MANIMBA', countryName: 'CONGO, DR OF' },
  { placeId: 6862, cityName: 'MATADI-TSHIMPI', countryName: 'CONGO, DR OF' },
  { placeId: 6892, cityName: 'MBANDAKA', countryName: 'CONGO, DR OF' },
  { placeId: 6896, cityName: 'MBOIE', countryName: 'CONGO, DR OF' },
  { placeId: 6897, cityName: 'MBUJI-MAYI', countryName: 'CONGO, DR OF' },
  { placeId: 6939, cityName: 'MENTOLE', countryName: 'CONGO, DR OF' },
  { placeId: 7000, cityName: 'MITWABA', countryName: 'CONGO, DR OF' },
  { placeId: 6488, cityName: 'MOANZA', countryName: 'CONGO, DR OF' },
  { placeId: 6489, cityName: 'MOBA', countryName: 'CONGO, DR OF' },
  { placeId: 7003, cityName: 'MOGA', countryName: 'CONGO, DR OF' },
  { placeId: 7010, cityName: 'MOKARIA-YAMOLEKA', countryName: 'CONGO, DR OF' },
  { placeId: 7017, cityName: 'MOMA', countryName: 'CONGO, DR OF' },
  { placeId: 7019, cityName: 'MOMBONGO', countryName: 'CONGO, DR OF' },
  { placeId: 7027, cityName: 'MONGOWAKENDA', countryName: 'CONGO, DR OF' },
  { placeId: 7030, cityName: 'MONKOTO', countryName: 'CONGO, DR OF' },
  { placeId: 7101, cityName: 'MPAKA', countryName: 'CONGO, DR OF' },
  { placeId: 7114, cityName: 'MUAMBI', countryName: 'CONGO, DR OF' },
  { placeId: 7116, cityName: 'MUANDA', countryName: 'CONGO, DR OF' },
  { placeId: 7123, cityName: 'MUKEDI', countryName: 'CONGO, DR OF' },
  { placeId: 7125, cityName: 'MUKOY', countryName: 'CONGO, DR OF' },
  { placeId: 7131, cityName: 'MULUNGU', countryName: 'CONGO, DR OF' },
  { placeId: 7134, cityName: 'MUNKAMBA', countryName: 'CONGO, DR OF' },
  { placeId: 7138, cityName: 'MUSESE', countryName: 'CONGO, DR OF' },
  { placeId: 7139, cityName: 'MUSHIE', countryName: 'CONGO, DR OF' },
  { placeId: 7146, cityName: 'MUTENA', countryName: 'CONGO, DR OF' },
  { placeId: 7147, cityName: 'MUTOTO', countryName: 'CONGO, DR OF' },
  { placeId: 7148, cityName: 'MUTSHATSHA', countryName: 'CONGO, DR OF' },
  { placeId: 7150, cityName: 'MVULA NSANDA', countryName: 'CONGO, DR OF' },
  { placeId: 7152, cityName: 'MWADINGUSHA', countryName: 'CONGO, DR OF' },
  { placeId: 7155, cityName: 'MWEKA', countryName: 'CONGO, DR OF' },
  { placeId: 7156, cityName: 'MWENE-DITU', countryName: 'CONGO, DR OF' },
  { placeId: 7251, cityName: 'NGI', countryName: 'CONGO, DR OF' },
  { placeId: 7253, cityName: 'NGUMU', countryName: 'CONGO, DR OF' },
  { placeId: 7262, cityName: 'NIOKI', countryName: 'CONGO, DR OF' },
  { placeId: 7271, cityName: 'NKOLO FUMA', countryName: 'CONGO, DR OF' },
  { placeId: 7327, cityName: 'NYUNZU', countryName: 'CONGO, DR OF' },
  { placeId: 7329, cityName: 'NZAMBA', countryName: 'CONGO, DR OF' },
  { placeId: 7331, cityName: 'NZOVU', countryName: 'CONGO, DR OF' },
  { placeId: 7341, cityName: 'OBAYE', countryName: 'CONGO, DR OF' },
  { placeId: 7350, cityName: 'OBOKOTE', countryName: 'CONGO, DR OF' },
  { placeId: 7361, cityName: 'ODUKU', countryName: 'CONGO, DR OF' },
  { placeId: 7434, cityName: 'OSHWE', countryName: 'CONGO, DR OF' },
  { placeId: 7610, cityName: 'PEPA', countryName: 'CONGO, DR OF' },
  { placeId: 7623, cityName: 'PHIBRAKI', countryName: 'CONGO, DR OF' },
  { placeId: 7691, cityName: 'POPOKABAKA', countryName: 'CONGO, DR OF' },
  { placeId: 7716, cityName: 'PUNIA-BASENGE', countryName: 'CONGO, DR OF' },
  { placeId: 7715, cityName: 'PUNIA', countryName: 'CONGO, DR OF' },
  { placeId: 7811, cityName: 'PWETO', countryName: 'CONGO, DR OF' },
  { placeId: 7989, cityName: 'RUTSHURU', countryName: 'CONGO, DR OF' },
  { placeId: 7990, cityName: 'RWINDI', countryName: 'CONGO, DR OF' },
  { placeId: 8075, cityName: 'SANDOA', countryName: 'CONGO, DR OF' },
  { placeId: 8129, cityName: 'SAULIA', countryName: 'CONGO, DR OF' },
  { placeId: 8156, cityName: 'SEMENDUA', countryName: 'CONGO, DR OF' },
  { placeId: 8204, cityName: 'SHABUNDA', countryName: 'CONGO, DR OF' },
  { placeId: 8242, cityName: 'SHONGAMBA', countryName: 'CONGO, DR OF' },
  { placeId: 8576, cityName: 'TANDALA', countryName: 'CONGO, DR OF' },
  { placeId: 8669, cityName: 'TINGI-TINGI', countryName: 'CONGO, DR OF' },
  { placeId: 8359, cityName: 'TONO', countryName: 'CONGO, DR OF' },
  { placeId: 8723, cityName: 'TSHELA', countryName: 'CONGO, DR OF' },
  { placeId: 8724, cityName: 'TSHIBALA', countryName: 'CONGO, DR OF' },
  { placeId: 8725, cityName: 'TSHIKAJI', countryName: 'CONGO, DR OF' },
  { placeId: 8726, cityName: 'TSHIKAPA', countryName: 'CONGO, DR OF' },
  { placeId: 8727, cityName: 'TSHUMBE', countryName: 'CONGO, DR OF' },
  { placeId: 8826, cityName: 'VANGA', countryName: 'CONGO, DR OF' },
  { placeId: 8930, cityName: 'WASOLO', countryName: 'CONGO, DR OF' },
  { placeId: 8941, cityName: 'WATSHA', countryName: 'CONGO, DR OF' },
  { placeId: 8969, cityName: 'WEMBO', countryName: 'CONGO, DR OF' },
  { placeId: 9067, cityName: 'YALINGIMBA', countryName: 'CONGO, DR OF' },
  { placeId: 9071, cityName: 'YANGAMBI', countryName: 'CONGO, DR OF' },
  { placeId: 9076, cityName: 'YASA', countryName: 'CONGO, DR OF' },
  { placeId: 9082, cityName: 'YEDI', countryName: 'CONGO, DR OF' },
  { placeId: 9084, cityName: 'YEMBE', countryName: 'CONGO, DR OF' },
  { placeId: 9085, cityName: 'YEMO', countryName: 'CONGO, DR OF' },
  { placeId: 9097, cityName: 'YUKI', countryName: 'CONGO, DR OF' },
  { placeId: 4331, cityName: 'BANGAMBA', countryName: 'CONGO, REP OF' },
  { placeId: 4570, cityName: 'BOUNDJI', countryName: 'CONGO, REP OF' },
  { placeId: 1124, cityName: 'BRAZZAVILLE', countryName: 'CONGO, REP OF' },
  { placeId: 5195, cityName: 'DOLISIE', countryName: 'CONGO, REP OF' },
  { placeId: 5344, cityName: 'EWO', countryName: 'CONGO, REP OF' },
  { placeId: 5956, cityName: 'IMPFONDO', countryName: 'CONGO, REP OF' },
  { placeId: 6184, cityName: 'KELLE', countryName: 'CONGO, REP OF' },
  { placeId: 6216, cityName: 'KIBANGOU', countryName: 'CONGO, REP OF' },
  { placeId: 6240, cityName: 'KINDAMBA', countryName: 'CONGO, REP OF' },
  { placeId: 6721, cityName: 'MAKABANA', countryName: 'CONGO, REP OF' },
  { placeId: 6727, cityName: 'MAKOUA', countryName: 'CONGO, REP OF' },
  { placeId: 6882, cityName: 'MAVINZA', countryName: 'CONGO, REP OF' },
  { placeId: 7084, cityName: 'MOSSENDJO', countryName: 'CONGO, REP OF' },
  { placeId: 7099, cityName: 'MOUYONDZI', countryName: 'CONGO, REP OF' },
  { placeId: 7165, cityName: 'N\'KAYI', countryName: 'CONGO, REP OF' },
  { placeId: 7317, cityName: 'NOUMBI', countryName: 'CONGO, REP OF' },
  { placeId: 13908, cityName: 'OLLOMBO', countryName: 'CONGO, REP OF' },
  { placeId: 7443, cityName: 'OUESSO', countryName: 'CONGO, REP OF' },
  { placeId: 7449, cityName: 'OWANDO', countryName: 'CONGO, REP OF' },
  { placeId: 1125, cityName: 'POINTE NOIRE', countryName: 'CONGO, REP OF' },
  { placeId: 8247, cityName: 'SIBITI', countryName: 'CONGO, REP OF' },
  { placeId: 8323, cityName: 'SOUANKE', countryName: 'CONGO, REP OF' },
  { placeId: 8875, cityName: 'VOUKA', countryName: 'CONGO, REP OF' },
  { placeId: 8876, cityName: 'VOUNDA', countryName: 'CONGO, REP OF' },
  { placeId: 9106, cityName: 'ZANAGA', countryName: 'CONGO, REP OF' },
  { placeId: 4032, cityName: 'AITUTAKI', countryName: 'COOK IS' },
  { placeId: 4264, cityName: 'ATIU, COOK IS', countryName: 'COOK IS' },
  { placeId: 6757, cityName: 'MANGAIA/AUAU, COOK IS', countryName: 'COOK IS' },
  { placeId: 6763, cityName: 'MANIHIKI', countryName: 'COOK IS' },
  { placeId: 6879, cityName: 'MAUKE', countryName: 'COOK IS' },
  { placeId: 7265, cityName: 'NITIARO/NUKUROA, COOK IS', countryName: 'COOK IS' },
  { placeId: 7609, cityName: 'PENRHYN ISLAND', countryName: 'COOK IS' },
  { placeId: 7797, cityName: 'PUKAPUKA', countryName: 'COOK IS' },
  { placeId: 2327, cityName: 'RAROTONGA I', countryName: 'COOK IS' },
  { placeId: 4213, cityName: 'ARENAL', countryName: 'COSTA RICA' },
  { placeId: 4620, cityName: 'BUENOS AIRES', countryName: 'COSTA RICA' },
  { placeId: 4188, cityName: 'CARRILLO', countryName: 'COSTA RICA' },
  { placeId: 4782, cityName: 'CHACARITA', countryName: 'COSTA RICA' },
  { placeId: 4973, cityName: 'COTO 47', countryName: 'COSTA RICA' },
  { placeId: 5169, cityName: 'DIECIOCHO', countryName: 'COSTA RICA' },
  { placeId: 5281, cityName: 'EL CARMEN', countryName: 'COSTA RICA' },
  { placeId: 5397, cityName: 'FINCA 63', countryName: 'COSTA RICA' },
  { placeId: 5415, cityName: 'FLORENCIA DE SAN CARLOS', countryName: 'COSTA RICA' },
  { placeId: 5598, cityName: 'GOLFITO', countryName: 'COSTA RICA' },
  { placeId: 5728, cityName: 'GUAPILES', countryName: 'COSTA RICA' },
  { placeId: 6367, cityName: 'LA FLOR', countryName: 'COSTA RICA' },
  { placeId: 6374, cityName: 'LA MANAGUA', countryName: 'COSTA RICA' },
  { placeId: 2314, cityName: 'LIBERIA', countryName: 'COSTA RICA' },
  { placeId: 6625, cityName: 'LOS CHILES', countryName: 'COSTA RICA' },
  { placeId: 7181, cityName: 'NANDAYURE', countryName: 'COSTA RICA' },
  { placeId: 7257, cityName: 'NICOYA', countryName: 'COSTA RICA' },
  { placeId: 7314, cityName: 'NOSARA', countryName: 'COSTA RICA' },
  { placeId: 6515, cityName: 'PALMAR SUR', countryName: 'COSTA RICA' },
  { placeId: 7538, cityName: 'PALO ARCO', countryName: 'COSTA RICA' },
  { placeId: 12489, cityName: 'PEREZ ZELEDON', countryName: 'COSTA RICA' },
  { placeId: 4353, cityName: 'POCOCI', countryName: 'COSTA RICA' },
  { placeId: 2315, cityName: 'PUERTO LIMON', countryName: 'COSTA RICA' },
  { placeId: 6453, cityName: 'PUNTARENAS', countryName: 'COSTA RICA' },
  { placeId: 8026, cityName: 'SAN ALBERTO', countryName: 'COSTA RICA' },
  { placeId: 12923, cityName: 'SAN ISIDRO', countryName: 'COSTA RICA' },
  { placeId: 2316, cityName: 'SAN JOSE', countryName: 'COSTA RICA' },
  { placeId: 8070, cityName: 'SAN VITO DE JABA', countryName: 'COSTA RICA' },
  { placeId: 8091, cityName: 'SANTA CLARA DE GUAPILES', countryName: 'COSTA RICA' },
  { placeId: 8092, cityName: 'SANTA CRUZ', countryName: 'COSTA RICA' },
  { placeId: 8098, cityName: 'SANTA MARIA DE GUACIMO', countryName: 'COSTA RICA' },
  { placeId: 8283, cityName: 'SIXAOLA', countryName: 'COSTA RICA' },
  { placeId: 8796, cityName: 'UPALA', countryName: 'COSTA RICA' },
  { placeId: 144, cityName: 'ABIDJAN', countryName: 'COTE D IVOIRE' },
  { placeId: 9142, cityName: 'ABENGOUROU', countryName: 'COTE D\'IVOIRE' },
  { placeId: 9144, cityName: 'ABOISSO', countryName: 'COTE D\'IVOIRE' },
  { placeId: 4514, cityName: 'BOCANDA', countryName: 'COTE D\'IVOIRE' },
  { placeId: 4535, cityName: 'BONDOUKOU', countryName: 'COTE D\'IVOIRE' },
  { placeId: 4562, cityName: 'BOUAKE', countryName: 'COTE D\'IVOIRE' },
  { placeId: 4568, cityName: 'BOUNA', countryName: 'COTE D\'IVOIRE' },
  { placeId: 4569, cityName: 'BOUNDIALI', countryName: 'COTE D\'IVOIRE' },
  { placeId: 5018, cityName: 'DABOU', countryName: 'COTE D\'IVOIRE' },
  { placeId: 5054, cityName: 'DALOA', countryName: 'COTE D\'IVOIRE' },
  { placeId: 5179, cityName: 'DIMBOKRO', countryName: 'COTE D\'IVOIRE' },
  { placeId: 5389, cityName: 'FERKESSEDOUGOU', countryName: 'COTE D\'IVOIRE' },
  { placeId: 5667, cityName: 'GRAND BEREBY', countryName: 'COTE D\'IVOIRE' },
  { placeId: 5736, cityName: 'GUIGLO', countryName: 'COTE D\'IVOIRE' },
  { placeId: 6320, cityName: 'KORHOGO', countryName: 'COTE D\'IVOIRE' },
  { placeId: 6745, cityName: 'MAN', countryName: 'COTE D\'IVOIRE' },
  { placeId: 7360, cityName: 'ODIENNE', countryName: 'COTE D\'IVOIRE' },
  { placeId: 7439, cityName: 'OUANGO FITINI', countryName: 'COTE D\'IVOIRE' },
  { placeId: 8057, cityName: 'SAN PEDRO', countryName: 'COTE D\'IVOIRE' },
  { placeId: 8125, cityName: 'SASSANDRA', countryName: 'COTE D\'IVOIRE' },
  { placeId: 7535, cityName: 'SEGUELA', countryName: 'COTE D\'IVOIRE' },
  { placeId: 8532, cityName: 'TABOU', countryName: 'COTE D\'IVOIRE' },
  { placeId: 8687, cityName: 'TOUBA', countryName: 'COTE D\'IVOIRE' },
  { placeId: 9069, cityName: 'YAMOUSSOUKRO', countryName: 'COTE D\'IVOIRE' },
  { placeId: 4497, cityName: 'BJELOVAR', countryName: 'CROATIA' },
  { placeId: 1412, cityName: 'BRAC', countryName: 'CROATIA' },
  { placeId: 4652, cityName: 'BUSEVEC', countryName: 'CROATIA' },
  { placeId: 1417, cityName: 'CAKOVEC', countryName: 'CROATIA' },
  { placeId: 4769, cityName: 'CAZMA', countryName: 'CROATIA' },
  { placeId: 5069, cityName: 'DARUVAR', countryName: 'CROATIA' },
  { placeId: 1401, cityName: 'DUBROVNIK', countryName: 'CROATIA' },
  { placeId: 1409, cityName: 'GROBNIK', countryName: 'CROATIA' },
  { placeId: 1413, cityName: 'HVAR', countryName: 'CROATIA' },
  { placeId: 6319, cityName: 'KOPRIVNICA', countryName: 'CROATIA' },
  { placeId: 1410, cityName: 'KRK ISLAND', countryName: 'CROATIA' },
  { placeId: 1402, cityName: 'LOSINJ ISLAND', countryName: 'CROATIA' },
  { placeId: 1407, cityName: 'MEDULIN', countryName: 'CROATIA' },
  { placeId: 7319, cityName: 'NOVA KAPELA', countryName: 'CROATIA' },
  { placeId: 1404, cityName: 'OSIJEK', countryName: 'CROATIA' },
  { placeId: 1411, cityName: 'OTOCAC', countryName: 'CROATIA' },
  { placeId: 1406, cityName: 'PULA', countryName: 'CROATIA' },
  { placeId: 1415, cityName: 'SINJ', countryName: 'CROATIA' },
  { placeId: 13098, cityName: 'SISAK', countryName: 'CROATIA' },
  { placeId: 1405, cityName: 'SLAVONSKI BROD', countryName: 'CROATIA' },
  { placeId: 1414, cityName: 'SPLIT', countryName: 'CROATIA' },
  { placeId: 1420, cityName: 'UDBINA', countryName: 'CROATIA' },
  { placeId: 8790, cityName: 'UNIJE', countryName: 'CROATIA' },
  { placeId: 1416, cityName: 'VARAZDIN', countryName: 'CROATIA' },
  { placeId: 8863, cityName: 'VINKOVCI', countryName: 'CROATIA' },
  { placeId: 1408, cityName: 'VRSAR', countryName: 'CROATIA' },
  { placeId: 1403, cityName: 'VUKOVAR', countryName: 'CROATIA' },
  { placeId: 1419, cityName: 'ZADAR', countryName: 'CROATIA' },
  { placeId: 1418, cityName: 'ZAGREB', countryName: 'CROATIA' },
  { placeId: 9131, cityName: 'ZVEKOVAC', countryName: 'CROATIA' },
  { placeId: 4340, cityName: 'BARACOA', countryName: 'CUBA' },
  { placeId: 4382, cityName: 'BAYAMO', countryName: 'CUBA' },
  { placeId: 2319, cityName: 'CAMAGUEY', countryName: 'CUBA' },
  { placeId: 2318, cityName: 'CAYO COCO', countryName: 'CUBA' },
  { placeId: 4766, cityName: 'CAYO LARGO DEL SUR', countryName: 'CUBA' },
  { placeId: 4767, cityName: 'CAYO LAS BRUJAS', countryName: 'CUBA' },
  { placeId: 4861, cityName: 'CIEGO DE AVILA', countryName: 'CUBA' },
  { placeId: 4862, cityName: 'CIENFUEGOS', countryName: 'CUBA' },
  { placeId: 5726, cityName: 'GUANTANAMO BAY', countryName: 'CUBA' },
  { placeId: 5725, cityName: 'GUANTANAMO', countryName: 'CUBA' },
  { placeId: 5729, cityName: 'GUARDALAVACA', countryName: 'CUBA' },
  { placeId: 2321, cityName: 'HAVANA', countryName: 'CUBA' },
  { placeId: 2322, cityName: 'HOLGUIN', countryName: 'CUBA' },
  { placeId: 6002, cityName: 'ISLA DE LA JUVENTUD', countryName: 'CUBA' },
  { placeId: 6444, cityName: 'LAS TUNAS', countryName: 'CUBA' },
  { placeId: 6786, cityName: 'MANZANILLO', countryName: 'CUBA' },
  { placeId: 6484, cityName: 'MOA', countryName: 'CUBA' },
  { placeId: 6508, cityName: 'NUEVA GERONA', countryName: 'CUBA' },
  { placeId: 7646, cityName: 'PINAR DEL RIO', countryName: 'CUBA' },
  { placeId: 9220, cityName: 'SANCTI SPIRITUS', countryName: 'CUBA' },
  { placeId: 8090, cityName: 'SANTA CLARA', countryName: 'CUBA' },
  { placeId: 8096, cityName: 'SANTA LUCIA', countryName: 'CUBA' },
  { placeId: 2320, cityName: 'SANTIAGO DE CUBA', countryName: 'CUBA' },
  { placeId: 8704, cityName: 'TRINIDAD', countryName: 'CUBA' },
  { placeId: 2823, cityName: 'VARADERO', countryName: 'CUBA' },
  { placeId: 1400, cityName: 'AKROTIRI', countryName: 'CYPRUS' },
  { placeId: 1398, cityName: 'LARNACA', countryName: 'CYPRUS' },
  { placeId: 13900, cityName: 'Nicosia', countryName: 'CYPRUS' },
  { placeId: 1399, cityName: 'PAFOS', countryName: 'CYPRUS' },
  { placeId: 1982, cityName: 'BENESOV', countryName: 'CZECH' },
  { placeId: 4519, cityName: 'BOHUNOVICE', countryName: 'CZECH' },
  { placeId: 1981, cityName: 'BRECLAV', countryName: 'CZECH' },
  { placeId: 2049, cityName: 'BRNO', countryName: 'CZECH' },
  { placeId: 1983, cityName: 'BROUMOV', countryName: 'CZECH' },
  { placeId: 1984, cityName: 'BUBOVICE', countryName: 'CZECH' },
  { placeId: 1985, cityName: 'CESKA LIPA', countryName: 'CZECH' },
  { placeId: 1989, cityName: 'CESKE BUDEJOVICE', countryName: 'CZECH' },
  { placeId: 10158, cityName: 'CHEB', countryName: 'CZECH' },
  { placeId: 1986, cityName: 'CHOMUTOV', countryName: 'CZECH' },
  { placeId: 1990, cityName: 'CHOTEBOR', countryName: 'CZECH' },
  { placeId: 1988, cityName: 'CHRUDIM', countryName: 'CZECH' },
  { placeId: 1991, cityName: 'DVUR KRALOVE', countryName: 'CZECH' },
  { placeId: 5026, cityName: 'ERPUZICE', countryName: 'CZECH' },
  { placeId: 1992, cityName: 'FRYDLANT', countryName: 'CZECH' },
  { placeId: 1993, cityName: 'HAVLICKUV BROD', countryName: 'CZECH' },
  { placeId: 1995, cityName: 'HODKOVICE', countryName: 'CZECH' },
  { placeId: 1998, cityName: 'HOLESOV', countryName: 'CZECH' },
  { placeId: 1994, cityName: 'HORICE', countryName: 'CZECH' },
  { placeId: 2000, cityName: 'HOROVICE', countryName: 'CZECH' },
  { placeId: 1999, cityName: 'HOSIN', countryName: 'CZECH' },
  { placeId: 1996, cityName: 'HRADEC KRALOVE', countryName: 'CZECH' },
  { placeId: 1997, cityName: 'HRANICE', countryName: 'CZECH' },
  { placeId: 2001, cityName: 'JAROMER', countryName: 'CZECH' },
  { placeId: 2002, cityName: 'JICIN', countryName: 'CZECH' },
  { placeId: 2004, cityName: 'JIHLAVA', countryName: 'CZECH' },
  { placeId: 2003, cityName: 'JINDRICHUV HRADEC', countryName: 'CZECH' },
  { placeId: 2012, cityName: 'KARLOVY VARY', countryName: 'CZECH' },
  { placeId: 11412, cityName: 'KBELY', countryName: 'CZECH' },
  { placeId: 2006, cityName: 'KLADNO', countryName: 'CZECH' },
  { placeId: 2010, cityName: 'KLATOVY', countryName: 'CZECH' },
  { placeId: 2008, cityName: 'KOLIN', countryName: 'CZECH' },
  { placeId: 2005, cityName: 'KRIZANOV', countryName: 'CZECH' },
  { placeId: 6335, cityName: 'KRIZENEC', countryName: 'CZECH' },
  { placeId: 2009, cityName: 'KRNOV', countryName: 'CZECH' },
  { placeId: 2007, cityName: 'KROMERIZ', countryName: 'CZECH' },
  { placeId: 2011, cityName: 'KUNOVICE', countryName: 'CZECH' },
  { placeId: 2013, cityName: 'KYJOV', countryName: 'CZECH' },
  { placeId: 2031, cityName: 'LETKOV', countryName: 'CZECH' },
  { placeId: 2016, cityName: 'LETNANY', countryName: 'CZECH' },
  { placeId: 2014, cityName: 'LIBEREC', countryName: 'CZECH' },
  { placeId: 2022, cityName: 'MARIANSKE LAZNE', countryName: 'CZECH' },
  { placeId: 1987, cityName: 'MEDLANKY', countryName: 'CZECH' },
  { placeId: 2019, cityName: 'MIKULOVICE', countryName: 'CZECH' },
  { placeId: 2017, cityName: 'MLADA BOLESLAV', countryName: 'CZECH' },
  { placeId: 2018, cityName: 'MNICHOVO HRADISTE', countryName: 'CZECH' },
  { placeId: 2020, cityName: 'MORAVSKA TREBOVA', countryName: 'CZECH' },
  { placeId: 2021, cityName: 'MOST', countryName: 'CZECH' },
  { placeId: 2024, cityName: 'NOVE MESTO', countryName: 'CZECH' },
  { placeId: 2025, cityName: 'OLOMOUC', countryName: 'CZECH' },
  { placeId: 2023, cityName: 'OSTRAVA', countryName: 'CZECH' },
  { placeId: 2026, cityName: 'OTROKOVICE', countryName: 'CZECH' },
  { placeId: 7545, cityName: 'PANENSKY TYNEC', countryName: 'CZECH' },
  { placeId: 2028, cityName: 'PARDUBICE', countryName: 'CZECH' },
  { placeId: 2036, cityName: 'PLASY', countryName: 'CZECH' },
  { placeId: 2015, cityName: 'PLZEN', countryName: 'CZECH' },
  { placeId: 2033, cityName: 'PODHORANY', countryName: 'CZECH' },
  { placeId: 2027, cityName: 'POLICKA', countryName: 'CZECH' },
  { placeId: 2035, cityName: 'PRAGUE', countryName: 'CZECH' },
  { placeId: 2034, cityName: 'PREROV', countryName: 'CZECH' },
  { placeId: 2032, cityName: 'PRIBRAM', countryName: 'CZECH' },
  { placeId: 2029, cityName: 'PRIBYSLAV', countryName: 'CZECH' },
  { placeId: 2030, cityName: 'PROSTEJOV', countryName: 'CZECH' },
  { placeId: 2038, cityName: 'RAKOVNIK', countryName: 'CZECH' },
  { placeId: 2037, cityName: 'RANA', countryName: 'CZECH' },
  { placeId: 7952, cityName: 'ROKYCANY', countryName: 'CZECH' },
  { placeId: 2039, cityName: 'ROUDNICE', countryName: 'CZECH' },
  { placeId: 2047, cityName: 'SAZENA', countryName: 'CZECH' },
  { placeId: 2041, cityName: 'SKUTEC', countryName: 'CZECH' },
  { placeId: 2042, cityName: 'SLANY', countryName: 'CZECH' },
  { placeId: 2043, cityName: 'SOBESLAV', countryName: 'CZECH' },
  { placeId: 2040, cityName: 'STANKOV', countryName: 'CZECH' },
  { placeId: 8483, cityName: 'STICHOVICE', countryName: 'CZECH' },
  { placeId: 2045, cityName: 'STRAKONICE', countryName: 'CZECH' },
  { placeId: 2044, cityName: 'STRUNKOVICE', countryName: 'CZECH' },
  { placeId: 2046, cityName: 'SUMPERK', countryName: 'CZECH' },
  { placeId: 2048, cityName: 'TABOR', countryName: 'CZECH' },
  { placeId: 8538, cityName: 'TACHOV', countryName: 'CZECH' },
  { placeId: 2050, cityName: 'TOCNA', countryName: 'CZECH' },
  { placeId: 2051, cityName: 'TOUZIM', countryName: 'CZECH' },
  { placeId: 8803, cityName: 'USTI NAD LABEM', countryName: 'CZECH' },
  { placeId: 2052, cityName: 'USTI NAD ORLICI', countryName: 'CZECH' },
  { placeId: 2056, cityName: 'VELKE PORICI', countryName: 'CZECH' },
  { placeId: 2053, cityName: 'VLASIM', countryName: 'CZECH' },
  { placeId: 2055, cityName: 'VODOCHODY', countryName: 'CZECH' },
  { placeId: 2057, cityName: 'VRCHLABI', countryName: 'CZECH' },
  { placeId: 2058, cityName: 'VYSKOV', countryName: 'CZECH' },
  { placeId: 2054, cityName: 'VYSOKE MYTO', countryName: 'CZECH' },
  { placeId: 2059, cityName: 'ZABREH', countryName: 'CZECH' },
  { placeId: 2061, cityName: 'ZAMBERK', countryName: 'CZECH' },
  { placeId: 9112, cityName: 'ZATEC', countryName: 'CZECH' },
  { placeId: 2060, cityName: 'ZBRASLAVICE', countryName: 'CZECH' },
  { placeId: 9125, cityName: 'ZNOJMO', countryName: 'CZECH' },
  { placeId: 2852, cityName: 'DAKHLA', countryName: 'DAKHLA AND LAAYOUNE' },
  { placeId: 1186, cityName: 'LAAYOUNE', countryName: 'DAKHLA AND LAAYOUNE' },
  { placeId: 857, cityName: 'AALBORG', countryName: 'DENMARK' },
  { placeId: 823, cityName: 'AARHUS', countryName: 'DENMARK' },
  { placeId: 822, cityName: 'AERO', countryName: 'DENMARK' },
  { placeId: 824, cityName: 'ANHOLT', countryName: 'DENMARK' },
  { placeId: 825, cityName: 'BILLUND', countryName: 'DENMARK' },
  { placeId: 840, cityName: 'BORNHOLM', countryName: 'DENMARK' },
  { placeId: 9915, cityName: 'BRAEDSTRUP', countryName: 'DENMARK' },
  { placeId: 826, cityName: 'COPENHAGEN', countryName: 'DENMARK' },
  { placeId: 5322, cityName: 'ENDELAVE', countryName: 'DENMARK' },
  { placeId: 827, cityName: 'ESBJERG', countryName: 'DENMARK' },
  { placeId: 5502, cityName: 'FUR', countryName: 'DENMARK' },
  { placeId: 10935, cityName: 'GORLEV', countryName: 'DENMARK' },
  { placeId: 10936, cityName: 'GORLOSE', countryName: 'DENMARK' },
  { placeId: 5709, cityName: 'GRENA', countryName: 'DENMARK' },
  { placeId: 828, cityName: 'GRONHOLT', countryName: 'DENMARK' },
  { placeId: 5765, cityName: 'HADERSLEV', countryName: 'DENMARK' },
  { placeId: 830, cityName: 'HADSUND', countryName: 'DENMARK' },
  { placeId: 829, cityName: 'HERNING', countryName: 'DENMARK' },
  { placeId: 5861, cityName: 'HOLBAEK', countryName: 'DENMARK' },
  { placeId: 832, cityName: 'KALUNDBORG', countryName: 'DENMARK' },
  { placeId: 831, cityName: 'KARUP', countryName: 'DENMARK' },
  { placeId: 853, cityName: 'KOLDING', countryName: 'DENMARK' },
  { placeId: 6321, cityName: 'KORSOR', countryName: 'DENMARK' },
  { placeId: 838, cityName: 'KRUSA-PADBORG', countryName: 'DENMARK' },
  { placeId: 833, cityName: 'LAESO', countryName: 'DENMARK' },
  { placeId: 834, cityName: 'LEMVIG', countryName: 'DENMARK' },
  { placeId: 835, cityName: 'LOLLAND FALSTER', countryName: 'DENMARK' },
  { placeId: 836, cityName: 'MORSO', countryName: 'DENMARK' },
  { placeId: 837, cityName: 'ODENSE', countryName: 'DENMARK' },
  { placeId: 839, cityName: 'RANDERS', countryName: 'DENMARK' },
  { placeId: 7856, cityName: 'RARUP', countryName: 'DENMARK' },
  { placeId: 841, cityName: 'RINGSTED', countryName: 'DENMARK' },
  { placeId: 7998, cityName: 'SAEBY', countryName: 'DENMARK' },
  { placeId: 846, cityName: 'SAMSO', countryName: 'DENMARK' },
  { placeId: 844, cityName: 'SINDAL', countryName: 'DENMARK' },
  { placeId: 848, cityName: 'SKIVE', countryName: 'DENMARK' },
  { placeId: 13104, cityName: 'SLAGLILLE', countryName: 'DENMARK' },
  { placeId: 842, cityName: 'SONDERBORG', countryName: 'DENMARK' },
  { placeId: 843, cityName: 'SPJALD', countryName: 'DENMARK' },
  { placeId: 856, cityName: 'STAUNING', countryName: 'DENMARK' },
  { placeId: 847, cityName: 'SYDFYN', countryName: 'DENMARK' },
  { placeId: 850, cityName: 'THISTED', countryName: 'DENMARK' },
  { placeId: 849, cityName: 'TONDER', countryName: 'DENMARK' },
  { placeId: 855, cityName: 'VESTHIMMERLAND', countryName: 'DENMARK' },
  { placeId: 852, cityName: 'VIBORG', countryName: 'DENMARK' },
  { placeId: 845, cityName: 'VOJENS/SKRYDSTRUP', countryName: 'DENMARK' },
  { placeId: 4246, cityName: 'ASSA-GUEYLA', countryName: 'DJIBOUTI' },
  { placeId: 5173, cityName: 'DIKHIL', countryName: 'DJIBOUTI' },
  { placeId: 1204, cityName: 'DJIBOUTI', countryName: 'DJIBOUTI' },
  { placeId: 5834, cityName: 'HERKALE', countryName: 'DJIBOUTI' },
  { placeId: 7349, cityName: 'OBOCK', countryName: 'DJIBOUTI' },
  { placeId: 8542, cityName: 'TADJOURAH', countryName: 'DJIBOUTI' },
  { placeId: 7960, cityName: 'ROSEAU', countryName: 'DOMINICA' },
  { placeId: 4142, cityName: 'ANGELINA', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 4224, cityName: 'ARROYO BARRIL', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 4341, cityName: 'BARAHONA', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 4665, cityName: 'CABO ROJO', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 4947, cityName: 'CONSTANZA', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 10388, cityName: 'CUEVA DE LAS MARAVILLAS', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 5049, cityName: 'DAJABON', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 5294, cityName: 'EL PORTILLO', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 5844, cityName: 'HIGUERO', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 2294, cityName: 'HIGUEY', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 2293, cityName: 'LA ROMANA', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 7040, cityName: 'MONTE CRISTI', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 2295, cityName: 'PUERTO PLATA', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 8021, cityName: 'SAMANA', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 8045, cityName: 'SAN ISIDRO', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 8059, cityName: 'SAN PEDRO DE MACORIS', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 2297, cityName: 'SANTIAGO', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 2296, cityName: 'SANTO DOMINGO', countryName: 'DOMINICAN REPUBLIC' },
  { placeId: 9504, cityName: 'AHUANO', countryName: 'ECUADOR' },
  { placeId: 4110, cityName: 'AMBATO', countryName: 'ECUADOR' },
  { placeId: 4316, cityName: 'BALTRA I GALAPAGOS IS', countryName: 'ECUADOR' },
  { placeId: 10100, cityName: 'CATAMAYO', countryName: 'ECUADOR' },
  { placeId: 4910, cityName: 'COCA', countryName: 'ECUADOR' },
  { placeId: 5005, cityName: 'CUENCA', countryName: 'ECUADOR' },
  { placeId: 5723, cityName: 'GUALAQUIZA', countryName: 'ECUADOR' },
  { placeId: 2506, cityName: 'GUAYAQUIL', countryName: 'ECUADOR' },
  { placeId: 5922, cityName: 'IBARRA', countryName: 'ECUADOR' },
  { placeId: 6004, cityName: 'ISLA ISABELA', countryName: 'ECUADOR' },
  { placeId: 6382, cityName: 'LA TOMA', countryName: 'ECUADOR' },
  { placeId: 2507, cityName: 'LATACUNGA', countryName: 'ECUADOR' },
  { placeId: 6472, cityName: 'MACARA', countryName: 'ECUADOR' },
  { placeId: 6473, cityName: 'MACAS', countryName: 'ECUADOR' },
  { placeId: 6477, cityName: 'MACHALA', countryName: 'ECUADOR' },
  { placeId: 2508, cityName: 'MANTA', countryName: 'ECUADOR' },
  { placeId: 7038, cityName: 'MONTALVO', countryName: 'ECUADOR' },
  { placeId: 6509, cityName: 'NUEVA LOJA', countryName: 'ECUADOR' },
  { placeId: 7740, cityName: 'PORTOVIEJO', countryName: 'ECUADOR' },
  { placeId: 2509, cityName: 'QUITO', countryName: 'ECUADOR' },
  { placeId: 7920, cityName: 'RIOBAMBA', countryName: 'ECUADOR' },
  { placeId: 8010, cityName: 'SALINAS', countryName: 'ECUADOR' },
  { placeId: 8037, cityName: 'SAN CRISTOBAL', countryName: 'ECUADOR' },
  { placeId: 8068, cityName: 'SAN VICENTE', countryName: 'ECUADOR' },
  { placeId: 8102, cityName: 'SANTA ROSA', countryName: 'ECUADOR' },
  { placeId: 8109, cityName: 'SANTO DOMINGO DE LOS COLORADOS', countryName: 'ECUADOR' },
  { placeId: 8226, cityName: 'SHELL', countryName: 'ECUADOR' },
  { placeId: 8164, cityName: 'SUCUA', countryName: 'ECUADOR' },
  { placeId: 8537, cityName: 'TACHINA', countryName: 'ECUADOR' },
  { placeId: 8552, cityName: 'TAISHA', countryName: 'ECUADOR' },
  { placeId: 8583, cityName: 'TARAPOA', countryName: 'ECUADOR' },
  { placeId: 8618, cityName: 'TENA', countryName: 'ECUADOR' },
  { placeId: 8672, cityName: 'TIPUTINI', countryName: 'ECUADOR' },
  { placeId: 8738, cityName: 'TULCAN', countryName: 'ECUADOR' },
  { placeId: 9135, cityName: '6TH OF OCTOBER', countryName: 'EGYPT' },
  { placeId: 2842, cityName: 'ABU SIMBEL', countryName: 'EGYPT' },
  { placeId: 4043, cityName: 'AL ALAMAIN', countryName: 'EGYPT' },
  { placeId: 1205, cityName: 'ALEXANDRIA', countryName: 'EGYPT' },
  { placeId: 4089, cityName: 'ALMAZA', countryName: 'EGYPT' },
  { placeId: 2813, cityName: 'ASWAN', countryName: 'EGYPT' },
  { placeId: 4251, cityName: 'ASYUT', countryName: 'EGYPT' },
  { placeId: 1206, cityName: 'CAIRO', countryName: 'EGYPT' },
  { placeId: 5050, cityName: 'DAKHLA', countryName: 'EGYPT' },
  { placeId: 5276, cityName: 'EL ARISH', countryName: 'EGYPT' },
  { placeId: 5287, cityName: 'EL GORA', countryName: 'EGYPT' },
  { placeId: 5288, cityName: 'EL GOUNA', countryName: 'EGYPT' },
  { placeId: 5290, cityName: 'EL KHARGA', countryName: 'EGYPT' },
  { placeId: 5297, cityName: 'EL TOR', countryName: 'EGYPT' },
  { placeId: 1207, cityName: 'HURGHADA', countryName: 'EGYPT' },
  { placeId: 1208, cityName: 'LUXOR', countryName: 'EGYPT' },
  { placeId: 1209, cityName: 'MARSA ALAM', countryName: 'EGYPT' },
  { placeId: 6951, cityName: 'MERSA MATRUH', countryName: 'EGYPT' },
  { placeId: 7708, cityName: 'PORT SAID', countryName: 'EGYPT' },
  { placeId: 8213, cityName: 'SHARK EL OWEINAT', countryName: 'EGYPT' },
  { placeId: 1210, cityName: 'SHARM EL SHEIKH', countryName: 'EGYPT' },
  { placeId: 13938, cityName: 'Siwa Oasis North', countryName: 'EGYPT' },
  { placeId: 8408, cityName: 'ST CATHERINE', countryName: 'EGYPT' },
  { placeId: 13295, cityName: 'SUHAG', countryName: 'EGYPT' },
  { placeId: 1211, cityName: 'TABA', countryName: 'EGYPT' },
  { placeId: 10587, cityName: 'EL PAPALON', countryName: 'EL SALVADOR' },
  { placeId: 11555, cityName: 'LA CARRERA', countryName: 'EL SALVADOR' },
  { placeId: 2317, cityName: 'SAN SALVADOR', countryName: 'EL SALVADOR' },
  { placeId: 4372, cityName: 'BATA', countryName: 'EQUATORIAL GUINEA' },
  { placeId: 1128, cityName: 'MALABO', countryName: 'EQUATORIAL GUINEA' },
  { placeId: 14154, cityName: 'MENGOMEYEN', countryName: 'EQUATORIAL GUINEA' },
  { placeId: 4244, cityName: 'ASMARA', countryName: 'ERITREA' },
  { placeId: 4247, cityName: 'ASSAB', countryName: 'ERITREA' },
  { placeId: 6857, cityName: 'MASSAWA', countryName: 'ERITREA' },
  { placeId: 8629, cityName: 'TESSENAI', countryName: 'ERITREA' },
  { placeId: 11343, cityName: 'JOHVI', countryName: 'ESTONIA' },
  { placeId: 550, cityName: 'KARDLA', countryName: 'ESTONIA' },
  { placeId: 11392, cityName: 'KARKSI', countryName: 'ESTONIA' },
  { placeId: 6222, cityName: 'KIHNU', countryName: 'ESTONIA' },
  { placeId: 551, cityName: 'KURESSAARE', countryName: 'ESTONIA' },
  { placeId: 11768, cityName: 'LYCKHOLM', countryName: 'ESTONIA' },
  { placeId: 7192, cityName: 'NARVA', countryName: 'ESTONIA' },
  { placeId: 552, cityName: 'PARNU', countryName: 'ESTONIA' },
  { placeId: 12720, cityName: 'RAKVERE', countryName: 'ESTONIA' },
  { placeId: 7855, cityName: 'RAPLA', countryName: 'ESTONIA' },
  { placeId: 7913, cityName: 'RIDALI', countryName: 'ESTONIA' },
  { placeId: 12793, cityName: 'RIIDAJA', countryName: 'ESTONIA' },
  { placeId: 7980, cityName: 'RUHNU', countryName: 'ESTONIA' },
  { placeId: 553, cityName: 'TALLINN', countryName: 'ESTONIA' },
  { placeId: 554, cityName: 'TARTU', countryName: 'ESTONIA' },
  { placeId: 13587, cityName: 'VARSTU', countryName: 'ESTONIA' },
  { placeId: 8855, cityName: 'VILJANDI', countryName: 'ESTONIA' },
  { placeId: 1201, cityName: 'ADDIS ABABA', countryName: 'ETHIOPIA' },
  { placeId: 4203, cityName: 'ARBA MINCH', countryName: 'ETHIOPIA' },
  { placeId: 4245, cityName: 'ASOSA', countryName: 'ETHIOPIA' },
  { placeId: 3981, cityName: 'AXUM', countryName: 'ETHIOPIA' },
  { placeId: 4301, cityName: 'BAHIR DAR', countryName: 'ETHIOPIA' },
  { placeId: 1202, cityName: 'DIRE DAWA', countryName: 'ETHIOPIA' },
  { placeId: 5520, cityName: 'GAMBELLA', countryName: 'ETHIOPIA' },
  { placeId: 5587, cityName: 'GOBA', countryName: 'ETHIOPIA' },
  { placeId: 5591, cityName: 'GODE', countryName: 'ETHIOPIA' },
  { placeId: 5600, cityName: 'GONDER', countryName: 'ETHIOPIA' },
  { placeId: 6063, cityName: 'JIMMA', countryName: 'ETHIOPIA' },
  { placeId: 6415, cityName: 'LALIBELLA', countryName: 'ETHIOPIA' },
  { placeId: 6924, cityName: 'MEKELE', countryName: 'ETHIOPIA' },
  { placeId: 13939, cityName: 'Neghelli', countryName: 'ETHIOPIA' },
  { placeId: 776, cityName: 'MOUNT PLEASANT', countryName: 'FALKLAND IS' },
  { placeId: 10676, cityName: 'FALKLAND ISLAND', countryName: 'FALKLAND ISLANDS (MALVINAS)' },
  { placeId: 854, cityName: 'VAGAR', countryName: 'FAROE ISLANDS' },
  { placeId: 9223, cityName: 'BA', countryName: 'FIJI ISLANDS' },
  { placeId: 9179, cityName: 'CICIA', countryName: 'FIJI ISLANDS' },
  { placeId: 6096, cityName: 'KADAVU, FIJI IS', countryName: 'FIJI ISLANDS' },
  { placeId: 6387, cityName: 'LABASA', countryName: 'FIJI ISLANDS' },
  { placeId: 9180, cityName: 'LAUCALA', countryName: 'FIJI ISLANDS' },
  { placeId: 6868, cityName: 'MATEI', countryName: 'FIJI ISLANDS' },
  { placeId: 2328, cityName: 'NADI', countryName: 'FIJI ISLANDS' },
  { placeId: 2329, cityName: 'NAUSORI', countryName: 'FIJI ISLANDS' },
  { placeId: 7968, cityName: 'ROTUMA', countryName: 'FIJI ISLANDS' },
  { placeId: 555, cityName: 'AAVAHELUKKA', countryName: 'FINLAND' },
  { placeId: 556, cityName: 'AHMOSUO', countryName: 'FINLAND' },
  { placeId: 557, cityName: 'ALAVUS', countryName: 'FINLAND' },
  { placeId: 558, cityName: 'ENONTEKIO', countryName: 'FINLAND' },
  { placeId: 559, cityName: 'EURA', countryName: 'FINLAND' },
  { placeId: 560, cityName: 'FORSSA', countryName: 'FINLAND' },
  { placeId: 5760, cityName: 'HAAPAJARVI', countryName: 'FINLAND' },
  { placeId: 566, cityName: 'HAAPAVESI', countryName: 'FINLAND' },
  { placeId: 563, cityName: 'HAILUOTO', countryName: 'FINLAND' },
  { placeId: 561, cityName: 'HALLI', countryName: 'FINLAND' },
  { placeId: 564, cityName: 'HAMEENKYRO', countryName: 'FINLAND' },
  { placeId: 565, cityName: 'HANKO', countryName: 'FINLAND' },
  { placeId: 562, cityName: 'HELSINKI', countryName: 'FINLAND' },
  { placeId: 567, cityName: 'HYVINKAA', countryName: 'FINLAND' },
  { placeId: 568, cityName: 'IISALMI', countryName: 'FINLAND' },
  { placeId: 570, cityName: 'IMMOLA', countryName: 'FINLAND' },
  { placeId: 572, cityName: 'IVALO', countryName: 'FINLAND' },
  { placeId: 573, cityName: 'JAMIJARVI', countryName: 'FINLAND' },
  { placeId: 574, cityName: 'JOENSUU', countryName: 'FINLAND' },
  { placeId: 575, cityName: 'JYVASKYLA', countryName: 'FINLAND' },
  { placeId: 580, cityName: 'KAJAANI', countryName: 'FINLAND' },
  { placeId: 6117, cityName: 'KALAJOKI', countryName: 'FINLAND' },
  { placeId: 5646, cityName: 'KANNUS', countryName: 'FINLAND' },
  { placeId: 584, cityName: 'KARSAMAKI', countryName: 'FINLAND' },
  { placeId: 581, cityName: 'KAUHAJOKI', countryName: 'FINLAND' },
  { placeId: 576, cityName: 'KAUHAVA', countryName: 'FINLAND' },
  { placeId: 577, cityName: 'KEMI', countryName: 'FINLAND' },
  { placeId: 583, cityName: 'KEMIJARVI', countryName: 'FINLAND' },
  { placeId: 569, cityName: 'KIIKALA', countryName: 'FINLAND' },
  { placeId: 571, cityName: 'KITEE', countryName: 'FINLAND' },
  { placeId: 586, cityName: 'KITTILA', countryName: 'FINLAND' },
  { placeId: 607, cityName: 'KIURUVESI', countryName: 'FINLAND' },
  { placeId: 588, cityName: 'KIVIJARVI', countryName: 'FINLAND' },
  { placeId: 582, cityName: 'KOKKOLA-PIETARSAARI', countryName: 'FINLAND' },
  { placeId: 579, cityName: 'KUHMO', countryName: 'FINLAND' },
  { placeId: 578, cityName: 'KUMLINGE', countryName: 'FINLAND' },
  { placeId: 587, cityName: 'KUOPIO', countryName: 'FINLAND' },
  { placeId: 585, cityName: 'KUUSAMO', countryName: 'FINLAND' },
  { placeId: 589, cityName: 'KYMI', countryName: 'FINLAND' },
  { placeId: 590, cityName: 'LAHTI', countryName: 'FINLAND' },
  { placeId: 591, cityName: 'LAPPEENRANTA', countryName: 'FINLAND' },
  { placeId: 6548, cityName: 'LIEKSA-NURMES', countryName: 'FINLAND' },
  { placeId: 592, cityName: 'MARIEHAMN', countryName: 'FINLAND' },
  { placeId: 593, cityName: 'MENKIJARVI', countryName: 'FINLAND' },
  { placeId: 594, cityName: 'MIKKELI', countryName: 'FINLAND' },
  { placeId: 595, cityName: 'NUMMELA', countryName: 'FINLAND' },
  { placeId: 596, cityName: 'ORIPAA', countryName: 'FINLAND' },
  { placeId: 597, cityName: 'OULU', countryName: 'FINLAND' },
  { placeId: 599, cityName: 'PIEKSAMAKI', countryName: 'FINLAND' },
  { placeId: 598, cityName: 'PIIKAJARVI', countryName: 'FINLAND' },
  { placeId: 7491, cityName: 'POKKA', countryName: 'FINLAND' },
  { placeId: 600, cityName: 'PORI', countryName: 'FINLAND' },
  { placeId: 601, cityName: 'PUDASJARVI', countryName: 'FINLAND' },
  { placeId: 7717, cityName: 'PUNKAHARJU', countryName: 'FINLAND' },
  { placeId: 602, cityName: 'PYHASALMI', countryName: 'FINLAND' },
  { placeId: 604, cityName: 'RAAHE', countryName: 'FINLAND' },
  { placeId: 605, cityName: 'RANTASALMI', countryName: 'FINLAND' },
  { placeId: 7853, cityName: 'RANUA', countryName: 'FINLAND' },
  { placeId: 603, cityName: 'RAUTAVAARA', countryName: 'FINLAND' },
  { placeId: 608, cityName: 'RAYSKALA', countryName: 'FINLAND' },
  { placeId: 606, cityName: 'ROVANIEMI', countryName: 'FINLAND' },
  { placeId: 8136, cityName: 'SAVIKKO', countryName: 'FINLAND' },
  { placeId: 609, cityName: 'SAVONLINNA', countryName: 'FINLAND' },
  { placeId: 611, cityName: 'SEINAJOKI', countryName: 'FINLAND' },
  { placeId: 610, cityName: 'SELANPAA', countryName: 'FINLAND' },
  { placeId: 612, cityName: 'SODANKYLA', countryName: 'FINLAND' },
  { placeId: 8505, cityName: 'SULKAHARJU', countryName: 'FINLAND' },
  { placeId: 613, cityName: 'SUOMUSSALMI', countryName: 'FINLAND' },
  { placeId: 614, cityName: 'TAMPERE', countryName: 'FINLAND' },
  { placeId: 615, cityName: 'TEISKO', countryName: 'FINLAND' },
  { placeId: 616, cityName: 'TURKU', countryName: 'FINLAND' },
  { placeId: 617, cityName: 'UTTI', countryName: 'FINLAND' },
  { placeId: 619, cityName: 'VAALA', countryName: 'FINLAND' },
  { placeId: 618, cityName: 'VAASA', countryName: 'FINLAND' },
  { placeId: 8821, cityName: 'VAMPULA', countryName: 'FINLAND' },
  { placeId: 620, cityName: 'VARKAUS', countryName: 'FINLAND' },
  { placeId: 8851, cityName: 'VIITASAARI', countryName: 'FINLAND' },
  { placeId: 621, cityName: 'WREDEBY', countryName: 'FINLAND' },
  { placeId: 622, cityName: 'YLIVIESKA', countryName: 'FINLAND' },
  { placeId: 1738, cityName: 'ABBEVILLE', countryName: 'FRANCE' },
  { placeId: 1482, cityName: 'AGEN', countryName: 'FRANCE' },
  { placeId: 1528, cityName: 'AIRE-SUR-L\'ADOUR', countryName: 'FRANCE' },
  { placeId: 1688, cityName: 'AIX', countryName: 'FRANCE' },
  { placeId: 1660, cityName: 'AJACCIO', countryName: 'FRANCE' },
  { placeId: 1473, cityName: 'ALBERT', countryName: 'FRANCE' },
  { placeId: 1653, cityName: 'ALBERTVILLE', countryName: 'FRANCE' },
  { placeId: 1512, cityName: 'ALBI', countryName: 'FRANCE' },
  { placeId: 1735, cityName: 'ALENCON', countryName: 'FRANCE' },
  { placeId: 1703, cityName: 'ALES', countryName: 'FRANCE' },
  { placeId: 1836, cityName: 'AMBERIEU', countryName: 'FRANCE' },
  { placeId: 1633, cityName: 'AMBERT', countryName: 'FRANCE' },
  { placeId: 1553, cityName: 'AMBOISE', countryName: 'FRANCE' },
  { placeId: 1481, cityName: 'AMIENS', countryName: 'FRANCE' },
  { placeId: 1578, cityName: 'ANCENIS', countryName: 'FRANCE' },
  { placeId: 1507, cityName: 'ANDERNOS-LES-BAINS', countryName: 'FRANCE' },
  { placeId: 4143, cityName: 'ANGERS', countryName: 'FRANCE' },
  { placeId: 1499, cityName: 'ANGOULEME', countryName: 'FRANCE' },
  { placeId: 1680, cityName: 'ANNECY', countryName: 'FRANCE' },
  { placeId: 1674, cityName: 'ANNEMASSE', countryName: 'FRANCE' },
  { placeId: 1595, cityName: 'ARBOIS', countryName: 'FRANCE' },
  { placeId: 1511, cityName: 'ARCACHON', countryName: 'FRANCE' },
  { placeId: 1467, cityName: 'ARGENTAN', countryName: 'FRANCE' },
  { placeId: 1554, cityName: 'ARGENTON SUR CREUSE', countryName: 'FRANCE' },
  { placeId: 1774, cityName: 'ARRAS', countryName: 'FRANCE' },
  { placeId: 1718, cityName: 'ASPRES SUR BUECH', countryName: 'FRANCE' },
  { placeId: 1628, cityName: 'AUBENAS', countryName: 'FRANCE' },
  { placeId: 4276, cityName: 'AUBENASSON', countryName: 'FRANCE' },
  { placeId: 1555, cityName: 'AUBIGNY-SUR-NERE', countryName: 'FRANCE' },
  { placeId: 1534, cityName: 'AUCH', countryName: 'FRANCE' },
  { placeId: 1686, cityName: 'AURILLAC', countryName: 'FRANCE' },
  { placeId: 1776, cityName: 'AUTUN', countryName: 'FRANCE' },
  { placeId: 1668, cityName: 'AUXERRE', countryName: 'FRANCE' },
  { placeId: 1596, cityName: 'AVALLON', countryName: 'FRANCE' },
  { placeId: 1706, cityName: 'AVIGNON', countryName: 'FRANCE' },
  { placeId: 1730, cityName: 'AVORD', countryName: 'FRANCE' },
  { placeId: 1815, cityName: 'AVRANCHES', countryName: 'FRANCE' },
  { placeId: 1505, cityName: 'BAGNERES DE LUCHON', countryName: 'FRANCE' },
  { placeId: 1471, cityName: 'BAGNOLES DE L\'ORNE', countryName: 'FRANCE' },
  { placeId: 1581, cityName: 'BAILLEAU ARMENONVILLE', countryName: 'FRANCE' },
  { placeId: 1567, cityName: 'BAR-LE-DUC', countryName: 'FRANCE' },
  { placeId: 1585, cityName: 'BAR-SUR-SEINE', countryName: 'FRANCE' },
  { placeId: 1702, cityName: 'BARCELONNETTE', countryName: 'FRANCE' },
  { placeId: 1817, cityName: 'BASLE-MULHOUSE', countryName: 'FRANCE' },
  { placeId: 1654, cityName: 'BASTIA', countryName: 'FRANCE' },
  { placeId: 1597, cityName: 'BEAUNE', countryName: 'FRANCE' },
  { placeId: 1731, cityName: 'BEAUVAIS', countryName: 'FRANCE' },
  { placeId: 1728, cityName: 'BEDARIEUX', countryName: 'FRANCE' },
  { placeId: 4407, cityName: 'BELFORT', countryName: 'FRANCE' },
  { placeId: 1549, cityName: 'BELLE ILE', countryName: 'FRANCE' },
  { placeId: 1627, cityName: 'BELLEGARDE', countryName: 'FRANCE' },
  { placeId: 1636, cityName: 'BELLEVILLE', countryName: 'FRANCE' },
  { placeId: 1666, cityName: 'BELLEY', countryName: 'FRANCE' },
  { placeId: 1640, cityName: 'BELVES', countryName: 'FRANCE' },
  { placeId: 1470, cityName: 'BERCK SUR MER', countryName: 'FRANCE' },
  { placeId: 1485, cityName: 'BERGERAC', countryName: 'FRANCE' },
  { placeId: 1755, cityName: 'BERNAY', countryName: 'FRANCE' },
  { placeId: 1723, cityName: 'BERRE', countryName: 'FRANCE' },
  { placeId: 1782, cityName: 'BESANCON', countryName: 'FRANCE' },
  { placeId: 1757, cityName: 'BEYNES', countryName: 'FRANCE' },
  { placeId: 1705, cityName: 'BEZIERS', countryName: 'FRANCE' },
  { placeId: 1503, cityName: 'BIARRITZ', countryName: 'FRANCE' },
  { placeId: 1497, cityName: 'BISCARROSSE', countryName: 'FRANCE' },
  { placeId: 4506, cityName: 'BLOIS', countryName: 'FRANCE' },
  { placeId: 1484, cityName: 'BORDEAUX', countryName: 'FRANCE' },
  { placeId: 1632, cityName: 'BOURG', countryName: 'FRANCE' },
  { placeId: 1671, cityName: 'BOURGES', countryName: 'FRANCE' },
  { placeId: 1795, cityName: 'BREST', countryName: 'FRANCE' },
  { placeId: 1769, cityName: 'BRETIGNY-SUR-ORGE', countryName: 'FRANCE' },
  { placeId: 1556, cityName: 'BRIARE', countryName: 'FRANCE' },
  { placeId: 1582, cityName: 'BRIENNE', countryName: 'FRANCE' },
  { placeId: 1631, cityName: 'BRIOUDE', countryName: 'FRANCE' },
  { placeId: 1500, cityName: 'BRIVE', countryName: 'FRANCE' },
  { placeId: 1572, cityName: 'BUNO BONNEVAUX', countryName: 'FRANCE' },
  { placeId: 1804, cityName: 'CAEN', countryName: 'FRANCE' },
  { placeId: 1506, cityName: 'CAHORS', countryName: 'FRANCE' },
  { placeId: 1461, cityName: 'CALAIS-DUNKERQUE', countryName: 'FRANCE' },
  { placeId: 1655, cityName: 'CALVI', countryName: 'FRANCE' },
  { placeId: 1779, cityName: 'CAMBRAI', countryName: 'FRANCE' },
  { placeId: 1690, cityName: 'CANNES', countryName: 'FRANCE' },
  { placeId: 1696, cityName: 'CARCASSONNE', countryName: 'FRANCE' },
  { placeId: 1717, cityName: 'CARPENTRAS', countryName: 'FRANCE' },
  { placeId: 1642, cityName: 'CASSAGNES-BEGONHES', countryName: 'FRANCE' },
  { placeId: 1542, cityName: 'CASTELNAU-MAGNOAC', countryName: 'FRANCE' },
  { placeId: 1707, cityName: 'CASTELNAUDARY', countryName: 'FRANCE' },
  { placeId: 1525, cityName: 'CASTELSARRASIN', countryName: 'FRANCE' },
  { placeId: 1514, cityName: 'CASTRES-MAZAMET', countryName: 'FRANCE' },
  { placeId: 1483, cityName: 'CAZAUX', countryName: 'FRANCE' },
  { placeId: 4768, cityName: 'CAZERES', countryName: 'FRANCE' },
  { placeId: 3984, cityName: 'CHALAIS', countryName: 'FRANCE' },
  { placeId: 1673, cityName: 'CHALON', countryName: 'FRANCE' },
  { placeId: 1740, cityName: 'CHALONS', countryName: 'FRANCE' },
  { placeId: 1669, cityName: 'CHAMBERY', countryName: 'FRANCE' },
  { placeId: 10127, cityName: 'CHAMBLEY', countryName: 'FRANCE' },
  { placeId: 1790, cityName: 'CHARLEVILLE-MEZIERES', countryName: 'FRANCE' },
  { placeId: 1746, cityName: 'CHARTRES', countryName: 'FRANCE' },
  { placeId: 1708, cityName: 'CHATEAU-ARNOUX', countryName: 'FRANCE' },
  { placeId: 1577, cityName: 'CHATEAU-THIERRY', countryName: 'FRANCE' },
  { placeId: 1833, cityName: 'CHATEAUBRIANT', countryName: 'FRANCE' },
  { placeId: 1732, cityName: 'CHATEAUDUN', countryName: 'FRANCE' },
  { placeId: 1587, cityName: 'CHATEAUNEUF SUR CHER', countryName: 'FRANCE' },
  { placeId: 1557, cityName: 'CHATEAUROUX', countryName: 'FRANCE' },
  { placeId: 1504, cityName: 'CHATELLERAULT', countryName: 'FRANCE' },
  { placeId: 1778, cityName: 'CHATILLON-SUR-SEINE', countryName: 'FRANCE' },
  { placeId: 4797, cityName: 'CHAUMONT', countryName: 'FRANCE' },
  { placeId: 1547, cityName: 'CHAUVIGNY', countryName: 'FRANCE' },
  { placeId: 1768, cityName: 'CHAVENAY', countryName: 'FRANCE' },
  { placeId: 1758, cityName: 'CHELLES', countryName: 'FRANCE' },
  { placeId: 1796, cityName: 'CHERBOURG', countryName: 'FRANCE' },
  { placeId: 1748, cityName: 'CHOLET', countryName: 'FRANCE' },
  { placeId: 4871, cityName: 'CLAMECY', countryName: 'FRANCE' },
  { placeId: 1670, cityName: 'CLERMONT-FERRAND/AUVERGNE', countryName: 'FRANCE' },
  { placeId: 4917, cityName: 'COETQUIDAN', countryName: 'FRANCE' },
  { placeId: 1487, cityName: 'COGNAC', countryName: 'FRANCE' },
  { placeId: 1593, cityName: 'COLMAR', countryName: 'FRANCE' },
  { placeId: 1462, cityName: 'COMPIEGNE', countryName: 'FRANCE' },
  { placeId: 4940, cityName: 'CONDOM', countryName: 'FRANCE' },
  { placeId: 4959, cityName: 'CORLIER', countryName: 'FRANCE' },
  { placeId: 1664, cityName: 'CORTE', countryName: 'FRANCE' },
  { placeId: 1598, cityName: 'COSNE SUR LOIRE', countryName: 'FRANCE' },
  { placeId: 1546, cityName: 'COUHE', countryName: 'FRANCE' },
  { placeId: 1759, cityName: 'COULOMMIERS', countryName: 'FRANCE' },
  { placeId: 1675, cityName: 'COURCHEVEL', countryName: 'FRANCE' },
  { placeId: 1754, cityName: 'CREIL', countryName: 'FRANCE' },
  { placeId: 1830, cityName: 'CUERS', countryName: 'FRANCE' },
  { placeId: 1840, cityName: 'DAMBLAIN', countryName: 'FRANCE' },
  { placeId: 1502, cityName: 'DAX', countryName: 'FRANCE' },
  { placeId: 1800, cityName: 'DEAUVILLE', countryName: 'FRANCE' },
  { placeId: 1460, cityName: 'DIEPPE', countryName: 'FRANCE' },
  { placeId: 1794, cityName: 'DIEUZE', countryName: 'FRANCE' },
  { placeId: 1599, cityName: 'DIJON', countryName: 'FRANCE' },
  { placeId: 1550, cityName: 'DINAN', countryName: 'FRANCE' },
  { placeId: 1797, cityName: 'DINARD', countryName: 'FRANCE' },
  { placeId: 1600, cityName: 'DOLE', countryName: 'FRANCE' },
  { placeId: 1608, cityName: 'DONCOURT LES CONFLANS', countryName: 'FRANCE' },
  { placeId: 1743, cityName: 'DREUX', countryName: 'FRANCE' },
  { placeId: 1468, cityName: 'DUNKERQUE', countryName: 'FRANCE' },
  { placeId: 1531, cityName: 'EGLETONS', countryName: 'FRANCE' },
  { placeId: 1575, cityName: 'ENGHIEN', countryName: 'FRANCE' },
  { placeId: 1828, cityName: 'EPERNAY', countryName: 'FRANCE' },
  { placeId: 1818, cityName: 'EPINAL', countryName: 'FRANCE' },
  { placeId: 1775, cityName: 'ETAIN', countryName: 'FRANCE' },
  { placeId: 1751, cityName: 'ETAMPES', countryName: 'FRANCE' },
  { placeId: 1591, cityName: 'ETREPAGNY', countryName: 'FRANCE' },
  { placeId: 1463, cityName: 'EU', countryName: 'FRANCE' },
  { placeId: 1734, cityName: 'EVREUX', countryName: 'FRANCE' },
  { placeId: 1475, cityName: 'FALAISE', countryName: 'FRANCE' },
  { placeId: 1692, cityName: 'FAYENCE', countryName: 'FRANCE' },
  { placeId: 1687, cityName: 'FEURS', countryName: 'FRANCE' },
  { placeId: 1658, cityName: 'FIGARI/SUD CORSE', countryName: 'FRANCE' },
  { placeId: 1509, cityName: 'FIGEAC', countryName: 'FRANCE' },
  { placeId: 1736, cityName: 'FLERS', countryName: 'FRANCE' },
  { placeId: 1720, cityName: 'FLORAC', countryName: 'FRANCE' },
  { placeId: 1580, cityName: 'FONTENAY-LE COMTE', countryName: 'FRANCE' },
  { placeId: 1764, cityName: 'FONTENAY-TRESIGNY', countryName: 'FRANCE' },
  { placeId: 1548, cityName: 'FUMEL', countryName: 'FRANCE' },
  { placeId: 1533, cityName: 'GAILLAC', countryName: 'FRANCE' },
  { placeId: 1711, cityName: 'GAP', countryName: 'FRANCE' },
  { placeId: 1659, cityName: 'GHISONACCIA', countryName: 'FRANCE' },
  { placeId: 1799, cityName: 'GRANVILLE', countryName: 'FRANCE' },
  { placeId: 1519, cityName: 'GRAULHET', countryName: 'FRANCE' },
  { placeId: 1568, cityName: 'GRAY', countryName: 'FRANCE' },
  { placeId: 1672, cityName: 'GRENOBLE', countryName: 'FRANCE' },
  { placeId: 1508, cityName: 'GUERET', countryName: 'FRANCE' },
  { placeId: 1565, cityName: 'GUISCRIFF', countryName: 'FRANCE' },
  { placeId: 1820, cityName: 'HAGUENAU', countryName: 'FRANCE' },
  { placeId: 1831, cityName: 'HYERES', countryName: 'FRANCE' },
  { placeId: 1571, cityName: 'ILE D\'YEU', countryName: 'FRANCE' },
  { placeId: 1616, cityName: 'ISSOIRE', countryName: 'FRANCE' },
  { placeId: 1558, cityName: 'ISSOUDUN', countryName: 'FRANCE' },
  { placeId: 1695, cityName: 'ISTRES', countryName: 'FRANCE' },
  { placeId: 1649, cityName: 'ITXASSOU', countryName: 'FRANCE' },
  { placeId: 1601, cityName: 'JOIGNY', countryName: 'FRANCE' },
  { placeId: 1579, cityName: 'JOINVILLE', countryName: 'FRANCE' },
  { placeId: 1513, cityName: 'JONZAC', countryName: 'FRANCE' },
  { placeId: 1792, cityName: 'JUVANCOURT', countryName: 'FRANCE' },
  { placeId: 1741, cityName: 'L\'AIGLE', countryName: 'FRANCE' },
  { placeId: 1634, cityName: 'L\'ALPE D\'HUEZ', countryName: 'FRANCE' },
  { placeId: 1798, cityName: 'LA BAULE-ESCOUBLAC', countryName: 'FRANCE' },
  { placeId: 1584, cityName: 'LA FERTE-ALAIS', countryName: 'FRANCE' },
  { placeId: 1576, cityName: 'LA FERTE-GAUCHER', countryName: 'FRANCE' },
  { placeId: 1469, cityName: 'LA FLECHE', countryName: 'FRANCE' },
  { placeId: 6370, cityName: 'LA GRAND\'COMBE', countryName: 'FRANCE' },
  { placeId: 6375, cityName: 'LA MOLE', countryName: 'FRANCE' },
  { placeId: 1693, cityName: 'LA MONTAGNE NOIRE', countryName: 'FRANCE' },
  { placeId: 6376, cityName: 'LA MOTTE', countryName: 'FRANCE' },
  { placeId: 1543, cityName: 'LA REOLE', countryName: 'FRANCE' },
  { placeId: 1802, cityName: 'LA ROCHE-SUR-YON', countryName: 'FRANCE' },
  { placeId: 1488, cityName: 'LA ROCHELLE', countryName: 'FRANCE' },
  { placeId: 1803, cityName: 'LANDIVISIAU', countryName: 'FRANCE' },
  { placeId: 1625, cityName: 'LANGOGNE', countryName: 'FRANCE' },
  { placeId: 1826, cityName: 'LANGRES-ROLAMPONT', countryName: 'FRANCE' },
  { placeId: 1808, cityName: 'LANNION', countryName: 'FRANCE' },
  { placeId: 1805, cityName: 'LANVEOC', countryName: 'FRANCE' },
  { placeId: 1464, cityName: 'LAON', countryName: 'FRANCE' },
  { placeId: 1637, cityName: 'LAPALISSE', countryName: 'FRANCE' },
  { placeId: 11632, cityName: 'LASCLAVERIES', countryName: 'FRANCE' },
  { placeId: 1749, cityName: 'LAVAL', countryName: 'FRANCE' },
  { placeId: 1559, cityName: 'LE BLANC', countryName: 'FRANCE' },
  { placeId: 1701, cityName: 'LE CASTELLET', countryName: 'FRANCE' },
  { placeId: 1737, cityName: 'LE HAVRE', countryName: 'FRANCE' },
  { placeId: 1689, cityName: 'LE LUC', countryName: 'FRANCE' },
  { placeId: 1806, cityName: 'LE MANS', countryName: 'FRANCE' },
  { placeId: 1729, cityName: 'LE MAZET DE ROMANIN', countryName: 'FRANCE' },
  { placeId: 1763, cityName: 'LE PLESSIS-BELLEVILLE', countryName: 'FRANCE' },
  { placeId: 1629, cityName: 'LE PUY', countryName: 'FRANCE' },
  { placeId: 1476, cityName: 'LE TOUQUET', countryName: 'FRANCE' },
  { placeId: 1781, cityName: 'LENS', countryName: 'FRANCE' },
  { placeId: 1839, cityName: 'LES MUREAUX', countryName: 'FRANCE' },
  { placeId: 1744, cityName: 'LES SABLES D\'OLONNE', countryName: 'FRANCE' },
  { placeId: 1545, cityName: 'LESPARRE', countryName: 'FRANCE' },
  { placeId: 1742, cityName: 'LESSAY', countryName: 'FRANCE' },
  { placeId: 1710, cityName: 'LEZIGNAN-CORBIERES', countryName: 'FRANCE' },
  { placeId: 1535, cityName: 'LIBOURNE', countryName: 'FRANCE' },
  { placeId: 1784, cityName: 'LILLE', countryName: 'FRANCE' },
  { placeId: 1492, cityName: 'LIMOGES', countryName: 'FRANCE' },
  { placeId: 1760, cityName: 'LOGNES', countryName: 'FRANCE' },
  { placeId: 1609, cityName: 'LONGUYON', countryName: 'FRANCE' },
  { placeId: 1602, cityName: 'LONS-LE-SAUNIER', countryName: 'FRANCE' },
  { placeId: 1801, cityName: 'LORIENT', countryName: 'FRANCE' },
  { placeId: 1538, cityName: 'LOUDUN', countryName: 'FRANCE' },
  { placeId: 1773, cityName: 'LUNEVILLE', countryName: 'FRANCE' },
  { placeId: 6672, cityName: 'LURCY', countryName: 'FRANCE' },
  { placeId: 1829, cityName: 'LUXEUIL', countryName: 'FRANCE' },
  { placeId: 1624, cityName: 'LYON', countryName: 'FRANCE' },
  { placeId: 1677, cityName: 'MACON', countryName: 'FRANCE' },
  { placeId: 1573, cityName: 'MANTES', countryName: 'FRANCE' },
  { placeId: 6804, cityName: 'MARENNES', countryName: 'FRANCE' },
  { placeId: 1539, cityName: 'MARMANDE', countryName: 'FRANCE' },
  { placeId: 1697, cityName: 'MARSEILLE/PROVENCE', countryName: 'FRANCE' },
  { placeId: 1780, cityName: 'MAUBEUGE', countryName: 'FRANCE' },
  { placeId: 6880, cityName: 'MAULEON', countryName: 'FRANCE' },
  { placeId: 1756, cityName: 'MEAUX', countryName: 'FRANCE' },
  { placeId: 1626, cityName: 'MEGEVE', countryName: 'FRANCE' },
  { placeId: 1761, cityName: 'MELUN', countryName: 'FRANCE' },
  { placeId: 1712, cityName: 'MENDE', countryName: 'FRANCE' },
  { placeId: 1665, cityName: 'MERIBEL', countryName: 'FRANCE' },
  { placeId: 1788, cityName: 'MERVILLE', countryName: 'FRANCE' },
  { placeId: 1651, cityName: 'METZ-NANCY/LORRAINE', countryName: 'FRANCE' },
  { placeId: 1819, cityName: 'METZ', countryName: 'FRANCE' },
  { placeId: 1515, cityName: 'MILLAU', countryName: 'FRANCE' },
  { placeId: 1527, cityName: 'MIMIZAN', countryName: 'FRANCE' },
  { placeId: 1713, cityName: 'MONT DAUPHIN', countryName: 'FRANCE' },
  { placeId: 1493, cityName: 'MONT-DE-MARSAN', countryName: 'FRANCE' },
  { placeId: 1722, cityName: 'MONT-LOUIS', countryName: 'FRANCE' },
  { placeId: 1589, cityName: 'MONTAIGU', countryName: 'FRANCE' },
  { placeId: 1560, cityName: 'MONTARGIS', countryName: 'FRANCE' },
  { placeId: 1529, cityName: 'MONTAUBAN', countryName: 'FRANCE' },
  { placeId: 1824, cityName: 'MONTBELIARD', countryName: 'FRANCE' },
  { placeId: 1603, cityName: 'MONTCEAU LES MINES', countryName: 'FRANCE' },
  { placeId: 1474, cityName: 'MONTDIDIER', countryName: 'FRANCE' },
  { placeId: 1681, cityName: 'MONTELIMAR', countryName: 'FRANCE' },
  { placeId: 1530, cityName: 'MONTENDRE', countryName: 'FRANCE' },
  { placeId: 1491, cityName: 'MONTLUCON-GUERET', countryName: 'FRANCE' },
  { placeId: 1683, cityName: 'MONTLUCON', countryName: 'FRANCE' },
  { placeId: 1716, cityName: 'MONTPELLIER', countryName: 'FRANCE' },
  { placeId: 1704, cityName: 'MONTPELLIER/MEDITERRANEE', countryName: 'FRANCE' },
  { placeId: 1623, cityName: 'MORESTEL', countryName: 'FRANCE' },
  { placeId: 1766, cityName: 'MORET', countryName: 'FRANCE' },
  { placeId: 1813, cityName: 'MORLAIX', countryName: 'FRANCE' },
  { placeId: 1480, cityName: 'MORTAGNE AU PERCHE', countryName: 'FRANCE' },
  { placeId: 1638, cityName: 'MOULINS', countryName: 'FRANCE' },
  { placeId: 7127, cityName: 'MULHOUSE', countryName: 'FRANCE' },
  { placeId: 1496, cityName: 'MURET', countryName: 'FRANCE' },
  { placeId: 1570, cityName: 'NANCY', countryName: 'FRANCE' },
  { placeId: 1466, cityName: 'NANGIS', countryName: 'FRANCE' },
  { placeId: 1811, cityName: 'NANTES/ATLANTIQUE', countryName: 'FRANCE' },
  { placeId: 12166, cityName: 'NARBONNE', countryName: 'FRANCE' },
  { placeId: 1586, cityName: 'NEUFCHATEAU', countryName: 'FRANCE' },
  { placeId: 1777, cityName: 'NEVERS', countryName: 'FRANCE' },
  { placeId: 1698, cityName: 'NICE/COTE D\'AZUR', countryName: 'FRANCE' },
  { placeId: 1691, cityName: 'NIMES', countryName: 'FRANCE' },
  { placeId: 1494, cityName: 'NIORT', countryName: 'FRANCE' },
  { placeId: 1516, cityName: 'NOGARO', countryName: 'FRANCE' },
  { placeId: 1615, cityName: 'NUITS-ST GEORGES', countryName: 'FRANCE' },
  { placeId: 1517, cityName: 'OLORON', countryName: 'FRANCE' },
  { placeId: 1699, cityName: 'ORANGE', countryName: 'FRANCE' },
  { placeId: 1739, cityName: 'ORLEANS', countryName: 'FRANCE' },
  { placeId: 1551, cityName: 'OUESSANT', countryName: 'FRANCE' },
  { placeId: 1676, cityName: 'OYONNAX', countryName: 'FRANCE' },
  { placeId: 1536, cityName: 'PAMIERS', countryName: 'FRANCE' },
  { placeId: 1604, cityName: 'PARAY LE MONIAL', countryName: 'FRANCE' },
  { placeId: 1753, cityName: 'PARIS', countryName: 'FRANCE' },
  { placeId: 1495, cityName: 'PAU/PYRENEES', countryName: 'FRANCE' },
  { placeId: 1501, cityName: 'PERIGUEUX', countryName: 'FRANCE' },
  { placeId: 1465, cityName: 'PERONNE-ST QUENTIN', countryName: 'FRANCE' },
  { placeId: 1617, cityName: 'PEROUGES', countryName: 'FRANCE' },
  { placeId: 1700, cityName: 'PERPIGNAN', countryName: 'FRANCE' },
  { placeId: 1752, cityName: 'PERSAN-BEAUMONT', countryName: 'FRANCE' },
  { placeId: 1646, cityName: 'PEYRESOURDE', countryName: 'FRANCE' },
  { placeId: 1721, cityName: 'PEZENAS', countryName: 'FRANCE' },
  { placeId: 1785, cityName: 'PHALSBOURG', countryName: 'FRANCE' },
  { placeId: 1618, cityName: 'PIERRELATTE', countryName: 'FRANCE' },
  { placeId: 1583, cityName: 'PITHIVIERS', countryName: 'FRANCE' },
  { placeId: 1809, cityName: 'PLOERMEL', countryName: 'FRANCE' },
  { placeId: 1489, cityName: 'POITIERS', countryName: 'FRANCE' },
  { placeId: 1518, cityName: 'PONS', countryName: 'FRANCE' },
  { placeId: 1827, cityName: 'PONT ST VINCENT', countryName: 'FRANCE' },
  { placeId: 1714, cityName: 'PONT-ST ESPRIT', countryName: 'FRANCE' },
  { placeId: 1605, cityName: 'PONT-SUR-YONNE', countryName: 'FRANCE' },
  { placeId: 1825, cityName: 'PONTARLIER', countryName: 'FRANCE' },
  { placeId: 1552, cityName: 'PONTIVY', countryName: 'FRANCE' },
  { placeId: 1765, cityName: 'PONTOISE', countryName: 'FRANCE' },
  { placeId: 1562, cityName: 'POUILLY', countryName: 'FRANCE' },
  { placeId: 1662, cityName: 'PROPRIANO', countryName: 'FRANCE' },
  { placeId: 1832, cityName: 'PUIMOISSON', countryName: 'FRANCE' },
  { placeId: 1727, cityName: 'PUIVERT', countryName: 'FRANCE' },
  { placeId: 1563, cityName: 'QUIBERON', countryName: 'FRANCE' },
  { placeId: 1810, cityName: 'QUIMPER', countryName: 'FRANCE' },
  { placeId: 1564, cityName: 'REDON', countryName: 'FRANCE' },
  { placeId: 1771, cityName: 'REIMS', countryName: 'FRANCE' },
  { placeId: 1807, cityName: 'RENNES', countryName: 'FRANCE' },
  { placeId: 1472, cityName: 'RETHEL', countryName: 'FRANCE' },
  { placeId: 1647, cityName: 'REVEL', countryName: 'FRANCE' },
  { placeId: 1643, cityName: 'RIBERAC', countryName: 'FRANCE' },
  { placeId: 1644, cityName: 'RION DES LANDES', countryName: 'FRANCE' },
  { placeId: 1679, cityName: 'ROANNE', countryName: 'FRANCE' },
  { placeId: 1540, cityName: 'ROCHEFORT', countryName: 'FRANCE' },
  { placeId: 1520, cityName: 'RODEZ', countryName: 'FRANCE' },
  { placeId: 1619, cityName: 'ROMANS', countryName: 'FRANCE' },
  { placeId: 1786, cityName: 'ROMILLY-SUR-SEINE', countryName: 'FRANCE' },
  { placeId: 7957, cityName: 'ROMORANTIN', countryName: 'FRANCE' },
  { placeId: 1745, cityName: 'ROUEN/VALLEE DE SEINE', countryName: 'FRANCE' },
  { placeId: 1526, cityName: 'ROYAN', countryName: 'FRANCE' },
  { placeId: 1620, cityName: 'RUOMS', countryName: 'FRANCE' },
  { placeId: 7514, cityName: 'SAINT REMY DE MAURIENNE', countryName: 'FRANCE' },
  { placeId: 12891, cityName: 'SAINT SULPICE DES LANDES', countryName: 'FRANCE' },
  { placeId: 1532, cityName: 'SAINTE FOY LA GRANDE', countryName: 'FRANCE' },
  { placeId: 1841, cityName: 'SAINTE LEOCADIE', countryName: 'FRANCE' },
  { placeId: 1837, cityName: 'SAINTES', countryName: 'FRANCE' },
  { placeId: 1639, cityName: 'SALLANCHES', countryName: 'FRANCE' },
  { placeId: 1709, cityName: 'SALON', countryName: 'FRANCE' },
  { placeId: 1544, cityName: 'SARLAT', countryName: 'FRANCE' },
  { placeId: 1789, cityName: 'SARRE-UNION', countryName: 'FRANCE' },
  { placeId: 1610, cityName: 'SARREBOURG', countryName: 'FRANCE' },
  { placeId: 1611, cityName: 'SARREGUEMINES', countryName: 'FRANCE' },
  { placeId: 1569, cityName: 'SAULIEU', countryName: 'FRANCE' },
  { placeId: 1733, cityName: 'SAUMUR', countryName: 'FRANCE' },
  { placeId: 1793, cityName: 'SAVERNE', countryName: 'FRANCE' },
  { placeId: 1822, cityName: 'SEDAN', countryName: 'FRANCE' },
  { placeId: 1607, cityName: 'SEMUR EN AUXOIS', countryName: 'FRANCE' },
  { placeId: 8194, cityName: 'SERRES-LA-BATIE', countryName: 'FRANCE' },
  { placeId: 1592, cityName: 'SEZANNE', countryName: 'FRANCE' },
  { placeId: 1724, cityName: 'SISTERON', countryName: 'FRANCE' },
  { placeId: 1652, cityName: 'SOISSONS', countryName: 'FRANCE' },
  { placeId: 1663, cityName: 'SOLENZARA', countryName: 'FRANCE' },
  { placeId: 1656, cityName: 'SOLLIERES SARDIERES', countryName: 'FRANCE' },
  { placeId: 1537, cityName: 'SOULAC SUR MER', countryName: 'FRANCE' },
  { placeId: 1641, cityName: 'ST AFRIQUE', countryName: 'FRANCE' },
  { placeId: 1574, cityName: 'ST ANDRE DE L\'EURE', countryName: 'FRANCE' },
  { placeId: 1812, cityName: 'ST BRIEUC', countryName: 'FRANCE' },
  { placeId: 1621, cityName: 'ST CHAMOND', countryName: 'FRANCE' },
  { placeId: 1838, cityName: 'ST CHRISTOL', countryName: 'FRANCE' },
  { placeId: 1667, cityName: 'ST CLAUDE', countryName: 'FRANCE' },
  { placeId: 1770, cityName: 'ST CYR-L\'ECOLE', countryName: 'FRANCE' },
  { placeId: 1614, cityName: 'ST DIE', countryName: 'FRANCE' },
  { placeId: 1821, cityName: 'ST DIZIER', countryName: 'FRANCE' },
  { placeId: 1694, cityName: 'ST ETIENNE', countryName: 'FRANCE' },
  { placeId: 1606, cityName: 'ST FLORENTIN', countryName: 'FRANCE' },
  { placeId: 1630, cityName: 'ST FLOUR', countryName: 'FRANCE' },
  { placeId: 1661, cityName: 'ST GALMIER', countryName: 'FRANCE' },
  { placeId: 1645, cityName: 'ST GAUDENS', countryName: 'FRANCE' },
  { placeId: 1510, cityName: 'ST GIRONS', countryName: 'FRANCE' },
  { placeId: 13188, cityName: 'ST INGLEVERT', countryName: 'FRANCE' },
  { placeId: 1650, cityName: 'ST JEAN D\'ANGELY', countryName: 'FRANCE' },
  { placeId: 1657, cityName: 'ST JEAN EN ROYANS', countryName: 'FRANCE' },
  { placeId: 1490, cityName: 'ST JUNIEN', countryName: 'FRANCE' },
  { placeId: 1719, cityName: 'ST MARTIN DE LONDRES', countryName: 'FRANCE' },
  { placeId: 1816, cityName: 'ST NAZAIRE', countryName: 'FRANCE' },
  { placeId: 1783, cityName: 'ST OMER', countryName: 'FRANCE' },
  { placeId: 1541, cityName: 'ST PIERRE D\'OLERON', countryName: 'FRANCE' },
  { placeId: 1750, cityName: 'ST QUENTIN', countryName: 'FRANCE' },
  { placeId: 1682, cityName: 'ST RAMBERT D\'ALBON', countryName: 'FRANCE' },
  { placeId: 1747, cityName: 'ST VALERY', countryName: 'FRANCE' },
  { placeId: 1678, cityName: 'ST YAN', countryName: 'FRANCE' },
  { placeId: 1594, cityName: 'STRASBOURG', countryName: 'FRANCE' },
  { placeId: 1498, cityName: 'TARBES', countryName: 'FRANCE' },
  { placeId: 1612, cityName: 'THIONVILLE', countryName: 'FRANCE' },
  { placeId: 1521, cityName: 'THOUARS', countryName: 'FRANCE' },
  { placeId: 1566, cityName: 'TIL-CHATEL', countryName: 'FRANCE' },
  { placeId: 1486, cityName: 'TOULOUSE', countryName: 'FRANCE' },
  { placeId: 1590, cityName: 'TOURNUS', countryName: 'FRANCE' },
  { placeId: 1561, cityName: 'TOURS', countryName: 'FRANCE' },
  { placeId: 1762, cityName: 'TOUSSUS-LE-NOBLE', countryName: 'FRANCE' },
  { placeId: 1772, cityName: 'TROYES', countryName: 'FRANCE' },
  { placeId: 1522, cityName: 'USSEL', countryName: 'FRANCE' },
  { placeId: 1725, cityName: 'UZES', countryName: 'FRANCE' },
  { placeId: 1684, cityName: 'VALENCE', countryName: 'FRANCE' },
  { placeId: 1478, cityName: 'VALENCIENNES', countryName: 'FRANCE' },
  { placeId: 1726, cityName: 'VALREAS', countryName: 'FRANCE' },
  { placeId: 1814, cityName: 'VANNES', countryName: 'FRANCE' },
  { placeId: 1477, cityName: 'VAUVILLE', countryName: 'FRANCE' },
  { placeId: 1648, cityName: 'VENDAYS MONTALIVET', countryName: 'FRANCE' },
  { placeId: 1613, cityName: 'VERDUN', countryName: 'FRANCE' },
  { placeId: 1791, cityName: 'VESOUL', countryName: 'FRANCE' },
  { placeId: 1685, cityName: 'VICHY', countryName: 'FRANCE' },
  { placeId: 1622, cityName: 'VIENNE', countryName: 'FRANCE' },
  { placeId: 1588, cityName: 'VIERZON', countryName: 'FRANCE' },
  { placeId: 1767, cityName: 'VILLACOUBLAY', countryName: 'FRANCE' },
  { placeId: 1523, cityName: 'VILLEFRANCHE-DE-ROUERGUE', countryName: 'FRANCE' },
  { placeId: 1635, cityName: 'VILLEFRANCHE', countryName: 'FRANCE' },
  { placeId: 1524, cityName: 'VILLENEUVE-SUR-LOT', countryName: 'FRANCE' },
  { placeId: 1479, cityName: 'VILLERUPT', countryName: 'FRANCE' },
  { placeId: 1715, cityName: 'VINON', countryName: 'FRANCE' },
  { placeId: 1787, cityName: 'VITRY EN ARTOIS', countryName: 'FRANCE' },
  { placeId: 1823, cityName: 'VITRY LE FRANCOIS', countryName: 'FRANCE' },
  { placeId: 2524, cityName: 'CAYENNE', countryName: 'FRENCH GUIANA' },
  { placeId: 8128, cityName: 'SAUL', countryName: 'FRENCH GUIANA' },
  { placeId: 8417, cityName: 'ST GEORGES DE L\'OYAPOCK', countryName: 'FRENCH GUIANA' },
  { placeId: 8437, cityName: 'ST-LAURENT-DU-MARONI', countryName: 'FRENCH GUIANA' },
  { placeId: 9571, cityName: 'ANAA I', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 9594, cityName: 'APATAKI', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 9602, cityName: 'ARATIKA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 9622, cityName: 'ARUTUA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 3906, cityName: 'BORA BORA I', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 10662, cityName: 'FAAITE', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 10673, cityName: 'FAKAHINA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 10674, cityName: 'FAKARAVA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 10681, cityName: 'FANGATAU I.', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11072, cityName: 'HAO I', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11143, cityName: 'HIKUERU', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11152, cityName: 'HIVA OA I', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11202, cityName: 'HUAHINE I', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11406, cityName: 'KATIU', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11408, cityName: 'KAUEHI', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11409, cityName: 'KAUKURA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11802, cityName: 'MAKEMO ISLAND', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11815, cityName: 'MANIHI', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11868, cityName: 'MARQUESAS IS', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11897, cityName: 'MATAIVA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11901, cityName: 'MATAURA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 11905, cityName: 'MAUPITI', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12057, cityName: 'MOOREA ISLAND', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12164, cityName: 'NAPUKA ISLAND', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12225, cityName: 'NIAU', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12279, cityName: 'NUKU-HIVA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12281, cityName: 'NUKUTAVAKE', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12569, cityName: 'POINTE PAKAKURU', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12671, cityName: 'PUKA PUKA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12673, cityName: 'PUKARUA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12716, cityName: 'RAIATEA I', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12730, cityName: 'RANGIROA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12734, cityName: 'RAROIA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12744, cityName: 'REAO I.', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12795, cityName: 'RIMATARA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12868, cityName: 'RURUTU AUSTRAL IS', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 2333, cityName: 'TAHITI I', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13343, cityName: 'TAKAPOTO', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13344, cityName: 'TAKAROA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13345, cityName: 'TAKUME', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13370, cityName: 'TATAKOTO', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13402, cityName: 'TETIAROA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13432, cityName: 'TIKEHAU', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13469, cityName: 'TOTEGEGIE I', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13517, cityName: 'TUREIA', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13529, cityName: 'UA POU', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 13565, cityName: 'VAHITAHI', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' },
  { placeId: 12718, cityName: 'RAIVAVAE, AUSTRAL IS', countryName: 'FRENCH PACIFIC OVERSEAS TERR.' }, // eslint-disable-line max-len
  { placeId: 4494, cityName: 'BITAM', countryName: 'GABON' },
  { placeId: 4544, cityName: 'BOOUE', countryName: 'GABON' },
  { placeId: 4914, cityName: 'COCOBEACH', countryName: 'GABON' },
  { placeId: 5440, cityName: 'FOUGAMOU', countryName: 'GABON' },
  { placeId: 5446, cityName: 'FRANCEVILLE', countryName: 'GABON' },
  { placeId: 6418, cityName: 'LAMBARENE', countryName: 'GABON' },
  { placeId: 6449, cityName: 'LASTOURVILLE', countryName: 'GABON' },
  { placeId: 1146, cityName: 'LIBREVILLE', countryName: 'GABON' },
  { placeId: 6726, cityName: 'MAKOKOU', countryName: 'GABON' },
  { placeId: 6889, cityName: 'MAYUMBA', countryName: 'GABON' },
  { placeId: 6895, cityName: 'MBIGOU', countryName: 'GABON' },
  { placeId: 6923, cityName: 'MEKAMBO', countryName: 'GABON' },
  { placeId: 6993, cityName: 'MINVOUL', countryName: 'GABON' },
  { placeId: 7001, cityName: 'MITZIC', countryName: 'GABON' },
  { placeId: 6486, cityName: 'MOABI', countryName: 'GABON' },
  { placeId: 6487, cityName: 'MOANDA', countryName: 'GABON' },
  { placeId: 7089, cityName: 'MOUILA', countryName: 'GABON' },
  { placeId: 7204, cityName: 'NDENDE', countryName: 'GABON' },
  { placeId: 7205, cityName: 'NDJOLE', countryName: 'GABON' },
  { placeId: 7372, cityName: 'OKONDJA', countryName: 'GABON' },
  { placeId: 7387, cityName: 'OMBOUE', countryName: 'GABON' },
  { placeId: 7462, cityName: 'OYEM', countryName: 'GABON' },
  { placeId: 1145, cityName: 'PORT GENTIL', countryName: 'GABON' },
  { placeId: 8196, cityName: 'SETTE-CAMA', countryName: 'GABON' },
  { placeId: 8598, cityName: 'TCHIBANGA', countryName: 'GABON' },
  { placeId: 1172, cityName: 'BANJUL', countryName: 'GAMBIA' },
  { placeId: 949, cityName: 'GAMBA', countryName: 'GAMBIA' },
  { placeId: 13930, cityName: 'ABKHAZIA', countryName: 'GEORGIA' },
  { placeId: 2576, cityName: 'BATUMI', countryName: 'GEORGIA' },
  { placeId: 2575, cityName: 'KUTAISI', countryName: 'GEORGIA' },
  { placeId: 2577, cityName: 'TBILISI', countryName: 'GEORGIA' },
  { placeId: 315, cityName: 'AACHEN', countryName: 'GERMANY' },
  { placeId: 413, cityName: 'AALEN-HEIDENHEIM', countryName: 'GERMANY' },
  { placeId: 529, cityName: 'ACHMER', countryName: 'GERMANY' },
  { placeId: 311, cityName: 'AHRENLOHE', countryName: 'GERMANY' },
  { placeId: 287, cityName: 'AILERTCHEN', countryName: 'GERMANY' },
  { placeId: 463, cityName: 'ALBSTADT', countryName: 'GERMANY' },
  { placeId: 277, cityName: 'ALLENDORF/EDER', countryName: 'GERMANY' },
  { placeId: 4085, cityName: 'ALLSTEDT', countryName: 'GERMANY' },
  { placeId: 469, cityName: 'ALTDORF-WALLBURG', countryName: 'GERMANY' },
  { placeId: 317, cityName: 'ALTENA', countryName: 'GERMANY' },
  { placeId: 1063, cityName: 'ALTENSTADT', countryName: 'GERMANY' },
  { placeId: 374, cityName: 'AMPFING', countryName: 'GERMANY' },
  { placeId: 228, cityName: 'ANKLAM', countryName: 'GERMANY' },
  { placeId: 431, cityName: 'ANSBACH', countryName: 'GERMANY' },
  { placeId: 262, cityName: 'ANSPACH/TAUNUS', countryName: 'GERMANY' },
  { placeId: 375, cityName: 'ARNBRUCK', countryName: 'GERMANY' },
  { placeId: 331, cityName: 'ARNSBERG', countryName: 'GERMANY' },
  { placeId: 211, cityName: 'ARNSTADT', countryName: 'GERMANY' },
  { placeId: 264, cityName: 'ASCHAFFENBURG', countryName: 'GERMANY' },
  { placeId: 236, cityName: 'ASCHERSLEBEN', countryName: 'GERMANY' },
  { placeId: 327, cityName: 'ATTENDORN', countryName: 'GERMANY' },
  { placeId: 395, cityName: 'AUERBACH', countryName: 'GERMANY' },
  { placeId: 352, cityName: 'AUGSBURG', countryName: 'GERMANY' },
  { placeId: 4282, cityName: 'BABENHAUSEN', countryName: 'GERMANY' },
  { placeId: 465, cityName: 'BACKNANG', countryName: 'GERMANY' },
  { placeId: 396, cityName: 'BAD BERKA', countryName: 'GERMANY' },
  { placeId: 414, cityName: 'BAD DITZENBACH', countryName: 'GERMANY' },
  { placeId: 447, cityName: 'BAD DURKHEIM', countryName: 'GERMANY' },
  { placeId: 4286, cityName: 'BAD FRANKENHAUSEN', countryName: 'GERMANY' },
  { placeId: 489, cityName: 'BAD GANDERSHEIM', countryName: 'GERMANY' },
  { placeId: 9686, cityName: 'BAD HERSFELD', countryName: 'GERMANY' },
  { placeId: 271, cityName: 'BAD KISSINGEN', countryName: 'GERMANY' },
  { placeId: 4287, cityName: 'BAD LANGENSALZA', countryName: 'GERMANY' },
  { placeId: 445, cityName: 'BAD NEUENAHR-AHRWEILER', countryName: 'GERMANY' },
  { placeId: 265, cityName: 'BAD NEUSTADT/SAALE', countryName: 'GERMANY' },
  { placeId: 504, cityName: 'BAD PYRMONT', countryName: 'GERMANY' },
  { placeId: 458, cityName: 'BAD SOBERNHEIM', countryName: 'GERMANY' },
  { placeId: 427, cityName: 'BAD WINDSHEIM', countryName: 'GERMANY' },
  { placeId: 380, cityName: 'BAD WORISHOFEN', countryName: 'GERMANY' },
  { placeId: 471, cityName: 'BADEN', countryName: 'GERMANY' },
  { placeId: 229, cityName: 'BALLENSTEDT', countryName: 'GERMANY' },
  { placeId: 528, cityName: 'BALTRUM', countryName: 'GERMANY' },
  { placeId: 1060, cityName: 'BAMBERG', countryName: 'GERMANY' },
  { placeId: 538, cityName: 'BARSSEL', countryName: 'GERMANY' },
  { placeId: 217, cityName: 'BARTH', countryName: 'GERMANY' },
  { placeId: 4360, cityName: 'BARTHOLOMA', countryName: 'GERMANY' },
  { placeId: 1061, cityName: 'BAUMHOLDER', countryName: 'GERMANY' },
  { placeId: 190, cityName: 'BAUTZEN', countryName: 'GERMANY' },
  { placeId: 429, cityName: 'BAYREUTH', countryName: 'GERMANY' },
  { placeId: 376, cityName: 'BEILNGRIES', countryName: 'GERMANY' },
  { placeId: 4438, cityName: 'BERCHING', countryName: 'GERMANY' },
  { placeId: 318, cityName: 'BERGNEUSTADT', countryName: 'GERMANY' },
  { placeId: 243, cityName: 'BERLIN', countryName: 'GERMANY' },
  { placeId: 320, cityName: 'BETZDORF-KIRCHEN', countryName: 'GERMANY' },
  { placeId: 353, cityName: 'BIBERACH AN DER RISS', countryName: 'GERMANY' },
  { placeId: 338, cityName: 'BIELEFELD', countryName: 'GERMANY' },
  { placeId: 401, cityName: 'BIENENFARM', countryName: 'GERMANY' },
  { placeId: 466, cityName: 'BINNINGEN', countryName: 'GERMANY' },
  { placeId: 4495, cityName: 'BITBURG', countryName: 'GERMANY' },
  { placeId: 354, cityName: 'BLAUBEUREN', countryName: 'GERMANY' },
  { placeId: 492, cityName: 'BLOMBERG', countryName: 'GERMANY' },
  { placeId: 468, cityName: 'BLUMBERG', countryName: 'GERMANY' },
  { placeId: 399, cityName: 'BOHLEN', countryName: 'GERMANY' },
  { placeId: 532, cityName: 'BOHMTE', countryName: 'GERMANY' },
  { placeId: 316, cityName: 'BONN', countryName: 'GERMANY' },
  { placeId: 387, cityName: 'BOPFINGEN', countryName: 'GERMANY' },
  { placeId: 506, cityName: 'BORDELUM', countryName: 'GERMANY' },
  { placeId: 350, cityName: 'BORKEN', countryName: 'GERMANY' },
  { placeId: 332, cityName: 'BORKENBERGE', countryName: 'GERMANY' },
  { placeId: 522, cityName: 'BORKUM', countryName: 'GERMANY' },
  { placeId: 299, cityName: 'BOTTENHORN', countryName: 'GERMANY' },
  { placeId: 4587, cityName: 'BRANDENBURG', countryName: 'GERMANY' },
  { placeId: 491, cityName: 'BRAUNSCHWEIG-WOLFSBURG', countryName: 'GERMANY' },
  { placeId: 288, cityName: 'BREITSCHEID', countryName: 'GERMANY' },
  { placeId: 257, cityName: 'BREMEN', countryName: 'GERMANY' },
  { placeId: 507, cityName: 'BREMERHAVEN', countryName: 'GERMANY' },
  { placeId: 4594, cityName: 'BREMGARTEN', countryName: 'GERMANY' },
  { placeId: 324, cityName: 'BRILON/HOCHSAUERLAND', countryName: 'GERMANY' },
  { placeId: 4607, cityName: 'BRONKOW', countryName: 'GERMANY' },
  { placeId: 472, cityName: 'BRUCHSAL', countryName: 'GERMANY' },
  { placeId: 1085, cityName: 'BUCHEL', countryName: 'GERMANY' },
  { placeId: 1064, cityName: 'BUCKEBURG', countryName: 'GERMANY' },
  { placeId: 430, cityName: 'BURG FEUERSTEIN', countryName: 'GERMANY' },
  { placeId: 216, cityName: 'BURG', countryName: 'GERMANY' },
  { placeId: 490, cityName: 'CELLE', countryName: 'GERMANY' },
  { placeId: 233, cityName: 'CHEMNITZ', countryName: 'GERMANY' },
  { placeId: 428, cityName: 'COBURG', countryName: 'GERMANY' },
  { placeId: 249, cityName: 'COLOGNE-BONN', countryName: 'GERMANY' },
  { placeId: 4975, cityName: 'COTTBUS-DREWITZ', countryName: 'GERMANY' },
  { placeId: 355, cityName: 'DACHAU', countryName: 'GERMANY' },
  { placeId: 328, cityName: 'DAHLEMER BINZ', countryName: 'GERMANY' },
  { placeId: 508, cityName: 'DAMME', countryName: 'GERMANY' },
  { placeId: 214, cityName: 'DEDELOW', countryName: 'GERMANY' },
  { placeId: 372, cityName: 'DEGGENDORF', countryName: 'GERMANY' },
  { placeId: 192, cityName: 'DESSAU', countryName: 'GERMANY' },
  { placeId: 5159, cityName: 'DETMOLD', countryName: 'GERMANY' },
  { placeId: 1073, cityName: 'DIEPHOLZ', countryName: 'GERMANY' },
  { placeId: 460, cityName: 'DIERDORF', countryName: 'GERMANY' },
  { placeId: 415, cityName: 'DINGOLFING', countryName: 'GERMANY' },
  { placeId: 377, cityName: 'DINKELSBUHL', countryName: 'GERMANY' },
  { placeId: 334, cityName: 'DINSLAKEN', countryName: 'GERMANY' },
  { placeId: 473, cityName: 'DONAUESCHINGEN', countryName: 'GERMANY' },
  { placeId: 368, cityName: 'DONAUWORTH', countryName: 'GERMANY' },
  { placeId: 422, cityName: 'DONZDORF', countryName: 'GERMANY' },
  { placeId: 348, cityName: 'DORTMUND', countryName: 'GERMANY' },
  { placeId: 244, cityName: 'DRESDEN', countryName: 'GERMANY' },
  { placeId: 250, cityName: 'DUSSELDORF', countryName: 'GERMANY' },
  { placeId: 440, cityName: 'EBERN', countryName: 'GERMANY' },
  { placeId: 5258, cityName: 'EBERSWALDE-FINOW', countryName: 'GERMANY' },
  { placeId: 356, cityName: 'EGGENFELDEN', countryName: 'GERMANY' },
  { placeId: 230, cityName: 'EGGERSDORF', countryName: 'GERMANY' },
  { placeId: 416, cityName: 'EICHSTATT', countryName: 'GERMANY' },
  { placeId: 289, cityName: 'EISENACH-KINDEL', countryName: 'GERMANY' },
  { placeId: 193, cityName: 'EISENHUTTENSTADT', countryName: 'GERMANY' },
  { placeId: 5304, cityName: 'ELLWANGEN', countryName: 'GERMANY' },
  { placeId: 285, cityName: 'ELZ', countryName: 'GERMANY' },
  { placeId: 509, cityName: 'EMDEN', countryName: 'GERMANY' },
  { placeId: 378, cityName: 'ERBACH', countryName: 'GERMANY' },
  { placeId: 1086, cityName: 'ERDING', countryName: 'GERMANY' },
  { placeId: 245, cityName: 'ERFURT', countryName: 'GERMANY' },
  { placeId: 335, cityName: 'ESSEN-MULHEIM', countryName: 'GERMANY' },
  { placeId: 5362, cityName: 'FALKENBERG', countryName: 'GERMANY' },
  { placeId: 1069, cityName: 'FASSBERG', countryName: 'GERMANY' },
  { placeId: 488, cityName: 'FINSTERWALDE', countryName: 'GERMANY' },
  { placeId: 534, cityName: 'FLENSBURG', countryName: 'GERMANY' },
  { placeId: 266, cityName: 'FRANKFURT-EGELSBACH', countryName: 'GERMANY' },
  { placeId: 268, cityName: 'FRANKFURT-HAHN', countryName: 'GERMANY' },
  { placeId: 246, cityName: 'FRANKFURT/MAIN', countryName: 'GERMANY' },
  { placeId: 474, cityName: 'FREIBURG I. BR.', countryName: 'GERMANY' },
  { placeId: 394, cityName: 'FRIEDRICHSHAFEN', countryName: 'GERMANY' },
  { placeId: 1065, cityName: 'FRITZLAR', countryName: 'GERMANY' },
  { placeId: 290, cityName: 'FULDA', countryName: 'GERMANY' },
  { placeId: 1087, cityName: 'FURSTENFELDBRUCK', countryName: 'GERMANY' },
  { placeId: 198, cityName: 'FURSTENWALDE', countryName: 'GERMANY' },
  { placeId: 357, cityName: 'FURSTENZELL', countryName: 'GERMANY' },
  { placeId: 521, cityName: 'GANDERKESEE', countryName: 'GERMANY' },
  { placeId: 397, cityName: 'GARDELEGEN', countryName: 'GERMANY' },
  { placeId: 1074, cityName: 'GEILENKIRCHEN', countryName: 'GERMANY' },
  { placeId: 267, cityName: 'GELNHAUSEN', countryName: 'GERMANY' },
  { placeId: 196, cityName: 'GERA', countryName: 'GERMANY' },
  { placeId: 426, cityName: 'GERSTETTEN', countryName: 'GERMANY' },
  { placeId: 10892, cityName: 'GIEBELSTADT', countryName: 'GERMANY' },
  { placeId: 379, cityName: 'GIENGEN/BRENZ', countryName: 'GERMANY' },
  { placeId: 272, cityName: 'GIESSEN', countryName: 'GERMANY' },
  { placeId: 5589, cityName: 'GOCH', countryName: 'GERMANY' },
  { placeId: 227, cityName: 'GORLITZ', countryName: 'GERMANY' },
  { placeId: 5146, cityName: 'GOTHA-OST', countryName: 'GERMANY' },
  { placeId: 5609, cityName: 'GRABENSTETTEN', countryName: 'GERMANY' },
  { placeId: 1070, cityName: 'GRAFENWOHR', countryName: 'GERMANY' },
  { placeId: 336, cityName: 'GREFRATH', countryName: 'GERMANY' },
  { placeId: 409, cityName: 'GREIZ', countryName: 'GERMANY' },
  { placeId: 418, cityName: 'GRIESAU', countryName: 'GERMANY' },
  { placeId: 197, cityName: 'GROSSENHAIN', countryName: 'GERMANY' },
  { placeId: 194, cityName: 'GROSSRUCKERSWALDE', countryName: 'GERMANY' },
  { placeId: 304, cityName: 'GRUBE', countryName: 'GERMANY' },
  { placeId: 5744, cityName: 'GUNDELFINGEN', countryName: 'GERMANY' },
  { placeId: 358, cityName: 'GUNZBURG', countryName: 'GERMANY' },
  { placeId: 359, cityName: 'GUNZENHAUSEN', countryName: 'GERMANY' },
  { placeId: 239, cityName: 'GUSTROW', countryName: 'GERMANY' },
  { placeId: 1091, cityName: 'GUTERSLOH', countryName: 'GERMANY' },
  { placeId: 203, cityName: 'HALLE', countryName: 'GERMANY' },
  { placeId: 248, cityName: 'HAMBURG', countryName: 'GERMANY' },
  { placeId: 337, cityName: 'HAMM', countryName: 'GERMANY' },
  { placeId: 256, cityName: 'HANNOVER', countryName: 'GERMANY' },
  { placeId: 542, cityName: 'HARLE', countryName: 'GERMANY' },
  { placeId: 309, cityName: 'HARTENHOLM', countryName: 'GERMANY' },
  { placeId: 441, cityName: 'HASSFURT', countryName: 'GERMANY' },
  { placeId: 530, cityName: 'HEIDE', countryName: 'GERMANY' },
  { placeId: 11118, cityName: 'HEILBAD-HEILIGENSTADT', countryName: 'GERMANY' },
  { placeId: 536, cityName: 'HELGOLAND', countryName: 'GERMANY' },
  { placeId: 5829, cityName: 'HEPPENHEIM', countryName: 'GERMANY' },
  { placeId: 195, cityName: 'HERINGSDORF', countryName: 'GERMANY' },
  { placeId: 258, cityName: 'HERRENTEICH', countryName: 'GERMANY' },
  { placeId: 482, cityName: 'HERTEN', countryName: 'GERMANY' },
  { placeId: 432, cityName: 'HERZOGENAURACH', countryName: 'GERMANY' },
  { placeId: 291, cityName: 'HETTSTADT', countryName: 'GERMANY' },
  { placeId: 443, cityName: 'HETZLESER BERG', countryName: 'GERMANY' },
  { placeId: 475, cityName: 'HEUBACH', countryName: 'GERMANY' },
  { placeId: 498, cityName: 'HILDESHEIM', countryName: 'GERMANY' },
  { placeId: 269, cityName: 'HIRZENHAIN', countryName: 'GERMANY' },
  { placeId: 284, cityName: 'HOCKENHEIM', countryName: 'GERMANY' },
  { placeId: 494, cityName: 'HODENHAGEN', countryName: 'GERMANY' },
  { placeId: 436, cityName: 'HOF-PLAUEN', countryName: 'GERMANY' },
  { placeId: 1071, cityName: 'HOHENFELS', countryName: 'GERMANY' },
  { placeId: 1075, cityName: 'HOHN', countryName: 'GERMANY' },
  { placeId: 497, cityName: 'HOLLEBERG', countryName: 'GERMANY' },
  { placeId: 1088, cityName: 'HOLZDORF', countryName: 'GERMANY' },
  { placeId: 449, cityName: 'HOPPSTADTEN-WEIERSBACH', countryName: 'GERMANY' },
  { placeId: 495, cityName: 'HOXTER-HOLZMINDEN', countryName: 'GERMANY' },
  { placeId: 319, cityName: 'HUNSBORN', countryName: 'GERMANY' },
  { placeId: 5913, cityName: 'HUSUM', countryName: 'GERMANY' },
  { placeId: 546, cityName: 'HUTTENBUSCH', countryName: 'GERMANY' },
  { placeId: 448, cityName: 'IDAR-OBERSTEIN', countryName: 'GERMANY' },
  { placeId: 360, cityName: 'ILLERTISSEN', countryName: 'GERMANY' },
  { placeId: 292, cityName: 'INGELFINGEN', countryName: 'GERMANY' },
  { placeId: 1089, cityName: 'INGOLSTADT', countryName: 'GERMANY' },
  { placeId: 6015, cityName: 'ITZEHOE', countryName: 'GERMANY' },
  { placeId: 219, cityName: 'JENA', countryName: 'GERMANY' },
  { placeId: 361, cityName: 'JESENWANG', countryName: 'GERMANY' },
  { placeId: 1076, cityName: 'JEVER', countryName: 'GERMANY' },
  { placeId: 514, cityName: 'JUIST', countryName: 'GERMANY' },
  { placeId: 235, cityName: 'KAMENZ', countryName: 'GERMANY' },
  { placeId: 333, cityName: 'KAMP-LINTFORT', countryName: 'GERMANY' },
  { placeId: 515, cityName: 'KARLSHOFEN', countryName: 'GERMANY' },
  { placeId: 476, cityName: 'KARLSRUHE', countryName: 'GERMANY' },
  { placeId: 464, cityName: 'KARLSRUHE/BADEN-BADEN', countryName: 'GERMANY' },
  { placeId: 496, cityName: 'KASSEL', countryName: 'GERMANY' },
  { placeId: 467, cityName: 'KEHL', countryName: 'GERMANY' },
  { placeId: 362, cityName: 'KEMPTEN', countryName: 'GERMANY' },
  { placeId: 307, cityName: 'KIEL', countryName: 'GERMANY' },
  { placeId: 382, cityName: 'KIRCHDORF/INN', countryName: 'GERMANY' },
  { placeId: 6281, cityName: 'KLEIN MUHLINGEN', countryName: 'GERMANY' },
  { placeId: 6285, cityName: 'KLIETZ', countryName: 'GERMANY' },
  { placeId: 232, cityName: 'KLIX', countryName: 'GERMANY' },
  { placeId: 452, cityName: 'KOBLENZ', countryName: 'GERMANY' },
  { placeId: 487, cityName: 'KONSTANZ', countryName: 'GERMANY' },
  { placeId: 294, cityName: 'KORBACH', countryName: 'GERMANY' },
  { placeId: 234, cityName: 'KOTHEN', countryName: 'GERMANY' },
  { placeId: 339, cityName: 'KREFELD', countryName: 'GERMANY' },
  { placeId: 549, cityName: 'KUHRSTEDT', countryName: 'GERMANY' },
  { placeId: 434, cityName: 'KULMBACH', countryName: 'GERMANY' },
  { placeId: 220, cityName: 'KYRITZ', countryName: 'GERMANY' },
  { placeId: 1077, cityName: 'LAAGE', countryName: 'GERMANY' },
  { placeId: 453, cityName: 'LACHEN-SPEYERDORF', countryName: 'GERMANY' },
  { placeId: 270, cityName: 'LAGER HAMMELBURG', countryName: 'GERMANY' },
  { placeId: 477, cityName: 'LAHR', countryName: 'GERMANY' },
  { placeId: 420, cityName: 'LAICHINGEN', countryName: 'GERMANY' },
  { placeId: 1084, cityName: 'LANDSBERG', countryName: 'GERMANY' },
  { placeId: 363, cityName: 'LANDSHUT', countryName: 'GERMANY' },
  { placeId: 259, cityName: 'LANGENLONSHEIM', countryName: 'GERMANY' },
  { placeId: 516, cityName: 'LANGEOOG', countryName: 'GERMANY' },
  { placeId: 6433, cityName: 'LANGHENNERSDORF', countryName: 'GERMANY' },
  { placeId: 221, cityName: 'LAUCHA', countryName: 'GERMANY' },
  { placeId: 313, cityName: 'LAUENBRUCK', countryName: 'GERMANY' },
  { placeId: 433, cityName: 'LAUF-LILLINGHOF', countryName: 'GERMANY' },
  { placeId: 1066, cityName: 'LAUPHEIM', countryName: 'GERMANY' },
  { placeId: 280, cityName: 'LAUTERBACH', countryName: 'GERMANY' },
  { placeId: 1090, cityName: 'LECHFELD', countryName: 'GERMANY' },
  { placeId: 6470, cityName: 'LECK', countryName: 'GERMANY' },
  { placeId: 510, cityName: 'LEER', countryName: 'GERMANY' },
  { placeId: 191, cityName: 'LEIPZIG-ALTENBURG', countryName: 'GERMANY' },
  { placeId: 253, cityName: 'LEIPZIG-HALLE', countryName: 'GERMANY' },
  { placeId: 383, cityName: 'LEUTKIRCH', countryName: 'GERMANY' },
  { placeId: 321, cityName: 'LEVERKUSEN', countryName: 'GERMANY' },
  { placeId: 435, cityName: 'LICHTENFELS', countryName: 'GERMANY' },
  { placeId: 450, cityName: 'LINKENHEIM', countryName: 'GERMANY' },
  { placeId: 308, cityName: 'LUBECK', countryName: 'GERMANY' },
  { placeId: 305, cityName: 'LUCHOW', countryName: 'GERMANY' },
  { placeId: 5653, cityName: 'LUNEBURG', countryName: 'GERMANY' },
  { placeId: 402, cityName: 'LUSSE', countryName: 'GERMANY' },
  { placeId: 222, cityName: 'MAGDEBURG', countryName: 'GERMANY' },
  { placeId: 213, cityName: 'MAGDEBURG/COCHSTEDT', countryName: 'GERMANY' },
  { placeId: 281, cityName: 'MAINBULLAU', countryName: 'GERMANY' },
  { placeId: 286, cityName: 'MAINZ', countryName: 'GERMANY' },
  { placeId: 273, cityName: 'MANNHEIM', countryName: 'GERMANY' },
  { placeId: 274, cityName: 'MARBURG-SCHONSTADT', countryName: 'GERMANY' },
  { placeId: 340, cityName: 'MARL', countryName: 'GERMANY' },
  { placeId: 330, cityName: 'MEINERZHAGEN', countryName: 'GERMANY' },
  { placeId: 535, cityName: 'MELLE-GRONEGAU', countryName: 'GERMANY' },
  { placeId: 2871, cityName: 'MEMMINGEN', countryName: 'GERMANY' },
  { placeId: 11949, cityName: 'MENDIG', countryName: 'GERMANY' },
  { placeId: 478, cityName: 'MENGEN', countryName: 'GERMANY' },
  { placeId: 493, cityName: 'MENGERINGHAUSEN', countryName: 'GERMANY' },
  { placeId: 6940, cityName: 'MEPPEN', countryName: 'GERMANY' },
  { placeId: 199, cityName: 'MERSEBURG', countryName: 'GERMANY' },
  { placeId: 322, cityName: 'MESCHEDE', countryName: 'GERMANY' },
  { placeId: 275, cityName: 'MICHELSTADT/ODW.', countryName: 'GERMANY' },
  { placeId: 365, cityName: 'MINDELHEIM', countryName: 'GERMANY' },
  { placeId: 341, cityName: 'MONCHENGLADBACH', countryName: 'GERMANY' },
  { placeId: 295, cityName: 'MOSBACH', countryName: 'GERMANY' },
  { placeId: 260, cityName: 'MOSENBERG', countryName: 'GERMANY' },
  { placeId: 373, cityName: 'MUHLDORF', countryName: 'GERMANY' },
  { placeId: 7121, cityName: 'MUHLHAUSEN', countryName: 'GERMANY' },
  { placeId: 251, cityName: 'MUNICH', countryName: 'GERMANY' },
  { placeId: 346, cityName: 'MUNSTER', countryName: 'GERMANY' },
  { placeId: 247, cityName: 'MUNSTER/OSNABRUCK', countryName: 'GERMANY' },
  { placeId: 479, cityName: 'NABERN/TECK', countryName: 'GERMANY' },
  { placeId: 455, cityName: 'NANNHAUSEN', countryName: 'GERMANY' },
  { placeId: 205, cityName: 'NARDT', countryName: 'GERMANY' },
  { placeId: 1080, cityName: 'NEUBRANDENBURG', countryName: 'GERMANY' },
  { placeId: 381, cityName: 'NEUBURG', countryName: 'GERMANY' },
  { placeId: 7226, cityName: 'NEUHARDENBERG', countryName: 'GERMANY' },
  { placeId: 7227, cityName: 'NEUHAUSEN OB ECK', countryName: 'GERMANY' },
  { placeId: 202, cityName: 'NEUHAUSEN', countryName: 'GERMANY' },
  { placeId: 446, cityName: 'NEUMAGEN-DHRON', countryName: 'GERMANY' },
  { placeId: 423, cityName: 'NEUMARKT/OPF.', countryName: 'GERMANY' },
  { placeId: 310, cityName: 'NEUMUNSTER', countryName: 'GERMANY' },
  { placeId: 200, cityName: 'NEUSTADT-GLEWE', countryName: 'GERMANY' },
  { placeId: 437, cityName: 'NEUSTADT/AISCH', countryName: 'GERMANY' },
  { placeId: 347, cityName: 'NIEDERRHEIN', countryName: 'GERMANY' },
  { placeId: 1067, cityName: 'NIEDERSTETTEN', countryName: 'GERMANY' },
  { placeId: 537, cityName: 'NIENBURG', countryName: 'GERMANY' },
  { placeId: 384, cityName: 'NITTENAU', countryName: 'GERMANY' },
  { placeId: 9146, cityName: 'NOERVENICH', countryName: 'GERMANY' },
  { placeId: 523, cityName: 'NORDEN-NORDDEICH', countryName: 'GERMANY' },
  { placeId: 527, cityName: 'NORDERNEY', countryName: 'GERMANY' },
  { placeId: 201, cityName: 'NORDHAUSEN', countryName: 'GERMANY' },
  { placeId: 540, cityName: 'NORDHOLZ-SPIEKA', countryName: 'GERMANY' },
  { placeId: 1072, cityName: 'NORDHOLZ', countryName: 'GERMANY' },
  { placeId: 518, cityName: 'NORDHORN', countryName: 'GERMANY' },
  { placeId: 385, cityName: 'NORDLINGEN', countryName: 'GERMANY' },
  { placeId: 499, cityName: 'NORTHEIM', countryName: 'GERMANY' },
  { placeId: 1078, cityName: 'NORVENICH', countryName: 'GERMANY' },
  { placeId: 252, cityName: 'NURNBERG', countryName: 'GERMANY' },
  { placeId: 276, cityName: 'OBER-MORLEN', countryName: 'GERMANY' },
  { placeId: 7344, cityName: 'OBERMEHLER/SCHLOTHEIM', countryName: 'GERMANY' },
  { placeId: 366, cityName: 'OBERPFAFFENHOFEN', countryName: 'GERMANY' },
  { placeId: 7345, cityName: 'OBERRISSDORF', countryName: 'GERMANY' },
  { placeId: 7346, cityName: 'OBERSCHLEISSHEIM', countryName: 'GERMANY' },
  { placeId: 293, cityName: 'OCHSENFURT', countryName: 'GERMANY' },
  { placeId: 223, cityName: 'OEHNA', countryName: 'GERMANY' },
  { placeId: 342, cityName: 'OERLINGHAUSEN', countryName: 'GERMANY' },
  { placeId: 480, cityName: 'OFFENBURG', countryName: 'GERMANY' },
  { placeId: 512, cityName: 'OLDENBURG', countryName: 'GERMANY' },
  { placeId: 296, cityName: 'OPPENHEIM', countryName: 'GERMANY' },
  { placeId: 406, cityName: 'OSCHATZ', countryName: 'GERMANY' },
  { placeId: 404, cityName: 'OSCHERSLEBEN', countryName: 'GERMANY' },
  { placeId: 519, cityName: 'OSNABRUCK', countryName: 'GERMANY' },
  { placeId: 438, cityName: 'OTTENGRUNER HEIDE', countryName: 'GERMANY' },
  { placeId: 344, cityName: 'PADERBORN', countryName: 'GERMANY' },
  { placeId: 343, cityName: 'PADERBORN/LIPPSTADT', countryName: 'GERMANY' },
  { placeId: 240, cityName: 'PASEWALK', countryName: 'GERMANY' },
  { placeId: 7578, cityName: 'PATTONVILLE', countryName: 'GERMANY' },
  { placeId: 7594, cityName: 'PEENEMUNDE', countryName: 'GERMANY' },
  { placeId: 444, cityName: 'PEGNITZ', countryName: 'GERMANY' },
  { placeId: 500, cityName: 'PEINE', countryName: 'GERMANY' },
  { placeId: 12475, cityName: 'PELLWORM', countryName: 'GERMANY' },
  { placeId: 408, cityName: 'PENNEWITZ', countryName: 'GERMANY' },
  { placeId: 386, cityName: 'PFARRKIRCHEN', countryName: 'GERMANY' },
  { placeId: 481, cityName: 'PFULLENDORF', countryName: 'GERMANY' },
  { placeId: 224, cityName: 'PINNOW', countryName: 'GERMANY' },
  { placeId: 457, cityName: 'PIRMASENS', countryName: 'GERMANY' },
  { placeId: 204, cityName: 'PIRNA', countryName: 'GERMANY' },
  { placeId: 325, cityName: 'PLETTENBERG', countryName: 'GERMANY' },
  { placeId: 505, cityName: 'PORTA WESTFALICA', countryName: 'GERMANY' },
  { placeId: 7769, cityName: 'PRITZWALK', countryName: 'GERMANY' },
  { placeId: 242, cityName: 'PURKSHOF', countryName: 'GERMANY' },
  { placeId: 12710, cityName: 'RADOLFZELL', countryName: 'GERMANY' },
  { placeId: 1059, cityName: 'RAMSTEIN', countryName: 'GERMANY' },
  { placeId: 208, cityName: 'RECHLIN-LARZ', countryName: 'GERMANY' },
  { placeId: 388, cityName: 'REGENSBURG', countryName: 'GERMANY' },
  { placeId: 263, cityName: 'REICHELSHEIM', countryName: 'GERMANY' },
  { placeId: 398, cityName: 'REINSDORF', countryName: 'GERMANY' },
  { placeId: 544, cityName: 'RENDSBURG', countryName: 'GERMANY' },
  { placeId: 7896, cityName: 'RENNERITZ', countryName: 'GERMANY' },
  { placeId: 237, cityName: 'RERIK-ZWEEDORF', countryName: 'GERMANY' },
  { placeId: 533, cityName: 'RHEINE', countryName: 'GERMANY' },
  { placeId: 206, cityName: 'RIESA', countryName: 'GERMANY' },
  { placeId: 501, cityName: 'RINTELN', countryName: 'GERMANY' },
  { placeId: 207, cityName: 'ROITZSCHJORA', countryName: 'GERMANY' },
  { placeId: 439, cityName: 'ROSENTHAL-FIELD', countryName: 'GERMANY' },
  { placeId: 543, cityName: 'ROTENBURG/WUMME', countryName: 'GERMANY' },
  { placeId: 1068, cityName: 'ROTH', countryName: 'GERMANY' },
  { placeId: 278, cityName: 'ROTHENBURG O.D. TAUBER', countryName: 'GERMANY' },
  { placeId: 225, cityName: 'ROTHENBURG/GORLITZ', countryName: 'GERMANY' },
  { placeId: 470, cityName: 'ROTTWEIL', countryName: 'GERMANY' },
  { placeId: 403, cityName: 'RUDOLSTADT', countryName: 'GERMANY' },
  { placeId: 231, cityName: 'RUGEN', countryName: 'GERMANY' },
  { placeId: 215, cityName: 'RUPPINER LAND', countryName: 'GERMANY' },
  { placeId: 254, cityName: 'SAARBRUCKEN', countryName: 'GERMANY' },
  { placeId: 451, cityName: 'SAARLOUIS', countryName: 'GERMANY' },
  { placeId: 238, cityName: 'SAARMUND', countryName: 'GERMANY' },
  { placeId: 502, cityName: 'SALZGITTER', countryName: 'GERMANY' },
  { placeId: 484, cityName: 'SAULGAU', countryName: 'GERMANY' },
  { placeId: 297, cityName: 'SCHAMEDER', countryName: 'GERMANY' },
  { placeId: 531, cityName: 'SCHLESWIG', countryName: 'GERMANY' },
  { placeId: 326, cityName: 'SCHMALLENBERG', countryName: 'GERMANY' },
  { placeId: 424, cityName: 'SCHMIDGADEN', countryName: 'GERMANY' },
  { placeId: 8142, cityName: 'SCHMOLDOW', countryName: 'GERMANY' },
  { placeId: 421, cityName: 'SCHONBERG', countryName: 'GERMANY' },
  { placeId: 412, cityName: 'SCHONEBECK', countryName: 'GERMANY' },
  { placeId: 210, cityName: 'SCHONHAGEN', countryName: 'GERMANY' },
  { placeId: 419, cityName: 'SCHWABACH-BUCHENBACH', countryName: 'GERMANY' },
  { placeId: 486, cityName: 'SCHWABISCH HALL', countryName: 'GERMANY' },
  { placeId: 389, cityName: 'SCHWABMUNCHEN', countryName: 'GERMANY' },
  { placeId: 417, cityName: 'SCHWANDORF', countryName: 'GERMANY' },
  { placeId: 8143, cityName: 'SCHWARZHEIDE/SCHIPKAU', countryName: 'GERMANY' },
  { placeId: 456, cityName: 'SCHWEIGHOFEN', countryName: 'GERMANY' },
  { placeId: 279, cityName: 'SCHWEINFURT', countryName: 'GERMANY' },
  { placeId: 483, cityName: 'SCHWENNINGEN AM NECKAR', countryName: 'GERMANY' },
  { placeId: 405, cityName: 'SCHWERIN-PARCHIM', countryName: 'GERMANY' },
  { placeId: 545, cityName: 'SEEDORF', countryName: 'GERMANY' },
  { placeId: 7533, cityName: 'SEGELETZ', countryName: 'GERMANY' },
  { placeId: 298, cityName: 'SIEGERLAND', countryName: 'GERMANY' },
  { placeId: 8250, cityName: 'SIERKSDORF', countryName: 'GERMANY' },
  { placeId: 351, cityName: 'SOEST', countryName: 'GERMANY' },
  { placeId: 226, cityName: 'SOMMERDA', countryName: 'GERMANY' },
  { placeId: 425, cityName: 'SONNEN', countryName: 'GERMANY' },
  { placeId: 1058, cityName: 'SPANGDAHLEM', countryName: 'GERMANY' },
  { placeId: 461, cityName: 'SPEYER', countryName: 'GERMANY' },
  { placeId: 8386, cityName: 'SPREMBERG', countryName: 'GERMANY' },
  { placeId: 8398, cityName: 'SPROSSEN', countryName: 'GERMANY' },
  { placeId: 539, cityName: 'ST MICHAELISDONN', countryName: 'GERMANY' },
  { placeId: 541, cityName: 'ST PETER-ORDING', countryName: 'GERMANY' },
  { placeId: 312, cityName: 'STADE', countryName: 'GERMANY' },
  { placeId: 345, cityName: 'STADTLOHN', countryName: 'GERMANY' },
  { placeId: 8472, cityName: 'STECHOW', countryName: 'GERMANY' },
  { placeId: 410, cityName: 'STENDAL-BORSTEL', countryName: 'GERMANY' },
  { placeId: 407, cityName: 'STOLLN/RHINOW', countryName: 'GERMANY' },
  { placeId: 8491, cityName: 'STRALSUND', countryName: 'GERMANY' },
  { placeId: 369, cityName: 'STRAUBING', countryName: 'GERMANY' },
  { placeId: 209, cityName: 'STRAUSBERG', countryName: 'GERMANY' },
  { placeId: 255, cityName: 'STUTTGART', countryName: 'GERMANY' },
  { placeId: 8165, cityName: 'SUHL', countryName: 'GERMANY' },
  { placeId: 547, cityName: 'SYLT', countryName: 'GERMANY' },
  { placeId: 370, cityName: 'TANNHEIM', countryName: 'GERMANY' },
  { placeId: 8590, cityName: 'TAUCHA', countryName: 'GERMANY' },
  { placeId: 8616, cityName: 'TEMPLIN', countryName: 'GERMANY' },
  { placeId: 8633, cityName: 'THALMASSING-WAIZENHOFEN', countryName: 'GERMANY' },
  { placeId: 391, cityName: 'THANNHAUSEN', countryName: 'GERMANY' },
  { placeId: 400, cityName: 'TORGAU', countryName: 'GERMANY' },
  { placeId: 454, cityName: 'TRABEN-TRARBACH', countryName: 'GERMANY' },
  { placeId: 390, cityName: 'TREUCHTLINGEN', countryName: 'GERMANY' },
  { placeId: 459, cityName: 'TRIER', countryName: 'GERMANY' },
  { placeId: 8756, cityName: 'TUTOW', countryName: 'GERMANY' },
  { placeId: 503, cityName: 'UELZEN', countryName: 'GERMANY' },
  { placeId: 306, cityName: 'UETERSEN', countryName: 'GERMANY' },
  { placeId: 300, cityName: 'UNTERSCHUPF', countryName: 'GERMANY' },
  { placeId: 524, cityName: 'VARRELBUSCH', countryName: 'GERMANY' },
  { placeId: 525, cityName: 'VERDEN', countryName: 'GERMANY' },
  { placeId: 367, cityName: 'VILSBIBURG', countryName: 'GERMANY' },
  { placeId: 1082, cityName: 'VILSECK', countryName: 'GERMANY' },
  { placeId: 371, cityName: 'VILSHOFEN', countryName: 'GERMANY' },
  { placeId: 392, cityName: 'VOGTAREUTH', countryName: 'GERMANY' },
  { placeId: 314, cityName: 'WAHLSTEDT', countryName: 'GERMANY' },
  { placeId: 302, cityName: 'WALLDORF', countryName: 'GERMANY' },
  { placeId: 261, cityName: 'WALLDURN', countryName: 'GERMANY' },
  { placeId: 511, cityName: 'WANGEROOGE', countryName: 'GERMANY' },
  { placeId: 411, cityName: 'WAREN', countryName: 'GERMANY' },
  { placeId: 8932, cityName: 'WASSERKUPPE', countryName: 'GERMANY' },
  { placeId: 442, cityName: 'WEIDEN/OPF.', countryName: 'GERMANY' },
  { placeId: 8960, cityName: 'WEIMAR', countryName: 'GERMANY' },
  { placeId: 303, cityName: 'WEINHEIM/BERGSTRASSE', countryName: 'GERMANY' },
  { placeId: 393, cityName: 'WEISSENHORN', countryName: 'GERMANY' },
  { placeId: 8968, cityName: 'WELZOW', countryName: 'GERMANY' },
  { placeId: 329, cityName: 'WERDOHL', countryName: 'GERMANY' },
  { placeId: 8973, cityName: 'WERNEUCHEN', countryName: 'GERMANY' },
  { placeId: 8974, cityName: 'WERSHOFEN/EIFEL', countryName: 'GERMANY' },
  { placeId: 349, cityName: 'WESEL', countryName: 'GERMANY' },
  { placeId: 517, cityName: 'WESER-WUMME', countryName: 'GERMANY' },
  { placeId: 526, cityName: 'WESTERSTEDE', countryName: 'GERMANY' },
  { placeId: 520, cityName: 'WIEFELSTEDE/CONNEFORDE', countryName: 'GERMANY' },
  { placeId: 1083, cityName: 'WIESBADEN', countryName: 'GERMANY' },
  { placeId: 513, cityName: 'WILHELMSHAVEN', countryName: 'GERMANY' },
  { placeId: 485, cityName: 'WINZELN', countryName: 'GERMANY' },
  { placeId: 323, cityName: 'WIPPERFURTH', countryName: 'GERMANY' },
  { placeId: 241, cityName: 'WISMAR', countryName: 'GERMANY' },
  { placeId: 1079, cityName: 'WITTMUNDHAFEN', countryName: 'GERMANY' },
  { placeId: 301, cityName: 'WOLFHAGEN', countryName: 'GERMANY' },
  { placeId: 282, cityName: 'WORMS', countryName: 'GERMANY' },
  { placeId: 1081, cityName: 'WUNSTORF', countryName: 'GERMANY' },
  { placeId: 283, cityName: 'WURZBURG', countryName: 'GERMANY' },
  { placeId: 548, cityName: 'WYK AUF FOHR', countryName: 'GERMANY' },
  { placeId: 9115, cityName: 'ZELL', countryName: 'GERMANY' },
  { placeId: 9120, cityName: 'ZERBST', countryName: 'GERMANY' },
  { placeId: 462, cityName: 'ZWEIBRUCKEN', countryName: 'GERMANY' },
  { placeId: 218, cityName: 'ZWICKAU', countryName: 'GERMANY' },
  { placeId: 143, cityName: 'ACCRA', countryName: 'GHANA' },
  { placeId: 6343, cityName: 'KUMASI', countryName: 'GHANA' },
  { placeId: 7200, cityName: 'NAVRONGO', countryName: 'GHANA' },
  { placeId: 8520, cityName: 'SUNYANI', countryName: 'GHANA' },
  { placeId: 8557, cityName: 'TAKORADI', countryName: 'GHANA' },
  { placeId: 8564, cityName: 'TAMALE', countryName: 'GHANA' },
  { placeId: 8880, cityName: 'WA', countryName: 'GHANA' },
  { placeId: 9086, cityName: 'YENDI', countryName: 'GHANA' },
  { placeId: 2271, cityName: 'GIBRALTAR', countryName: 'GIBRALTAR' },
  { placeId: 1843, cityName: 'AGRINION', countryName: 'GREECE' },
  { placeId: 1847, cityName: 'ALEXANDRIA', countryName: 'GREECE' },
  { placeId: 1844, cityName: 'ALEXANDROUPOLIS', countryName: 'GREECE' },
  { placeId: 1842, cityName: 'ANDRAVIDA', countryName: 'GREECE' },
  { placeId: 1877, cityName: 'ARAXOS', countryName: 'GREECE' },
  { placeId: 4250, cityName: 'ASTYPALAIA', countryName: 'GREECE' },
  { placeId: 1846, cityName: 'ATHENS', countryName: 'GREECE' },
  { placeId: 1878, cityName: 'CHANIA', countryName: 'GREECE' },
  { placeId: 1851, cityName: 'CHIOS', countryName: 'GREECE' },
  { placeId: 1892, cityName: 'DEKELIA', countryName: 'GREECE' },
  { placeId: 1849, cityName: 'ELEFSIS', countryName: 'GREECE' },
  { placeId: 1850, cityName: 'EPITALION', countryName: 'GREECE' },
  { placeId: 5936, cityName: 'IKARIA', countryName: 'GREECE' },
  { placeId: 1853, cityName: 'IOANNINA', countryName: 'GREECE' },
  { placeId: 1854, cityName: 'IRAKLION', countryName: 'GREECE' },
  { placeId: 1859, cityName: 'KALAMATA', countryName: 'GREECE' },
  { placeId: 5626, cityName: 'KALYMNOS', countryName: 'GREECE' },
  { placeId: 1863, cityName: 'KARPATHOS', countryName: 'GREECE' },
  { placeId: 1865, cityName: 'KASSOS', countryName: 'GREECE' },
  { placeId: 1889, cityName: 'KASTELI', countryName: 'GREECE' },
  { placeId: 1858, cityName: 'KASTELORIZO', countryName: 'GREECE' },
  { placeId: 1855, cityName: 'KASTORIA', countryName: 'GREECE' },
  { placeId: 1860, cityName: 'KAVALA', countryName: 'GREECE' },
  { placeId: 1857, cityName: 'KEFALLINIA', countryName: 'GREECE' },
  { placeId: 1864, cityName: 'KERKIRA', countryName: 'GREECE' },
  { placeId: 1856, cityName: 'KITHIRA', countryName: 'GREECE' },
  { placeId: 1862, cityName: 'KOS', countryName: 'GREECE' },
  { placeId: 1866, cityName: 'KOZANI', countryName: 'GREECE' },
  { placeId: 1869, cityName: 'LARISSA', countryName: 'GREECE' },
  { placeId: 1867, cityName: 'LEROS', countryName: 'GREECE' },
  { placeId: 1868, cityName: 'LIMNOS', countryName: 'GREECE' },
  { placeId: 1861, cityName: 'MARATHON', countryName: 'GREECE' },
  { placeId: 1870, cityName: 'MEGARA', countryName: 'GREECE' },
  { placeId: 1871, cityName: 'MIKONOS', countryName: 'GREECE' },
  { placeId: 1872, cityName: 'MILOS', countryName: 'GREECE' },
  { placeId: 1873, cityName: 'MITILINI', countryName: 'GREECE' },
  { placeId: 7201, cityName: 'NAXOS', countryName: 'GREECE' },
  { placeId: 1848, cityName: 'NEA ANCHIALOS', countryName: 'GREECE' },
  { placeId: 1874, cityName: 'PAROS', countryName: 'GREECE' },
  { placeId: 1852, cityName: 'PORTO CHELI', countryName: 'GREECE' },
  { placeId: 1875, cityName: 'PREVEZA', countryName: 'GREECE' },
  { placeId: 1876, cityName: 'RODOS', countryName: 'GREECE' },
  { placeId: 1881, cityName: 'SAMOS', countryName: 'GREECE' },
  { placeId: 1884, cityName: 'SANTORINI', countryName: 'GREECE' },
  { placeId: 1879, cityName: 'SEDES', countryName: 'GREECE' },
  { placeId: 1885, cityName: 'SITIA', countryName: 'GREECE' },
  { placeId: 1880, cityName: 'SKIATHOS', countryName: 'GREECE' },
  { placeId: 1887, cityName: 'SKIROS', countryName: 'GREECE' },
  { placeId: 1883, cityName: 'SPARTI', countryName: 'GREECE' },
  { placeId: 1886, cityName: 'STEFANOVIKION', countryName: 'GREECE' },
  { placeId: 1882, cityName: 'SYROS', countryName: 'GREECE' },
  { placeId: 1888, cityName: 'TANAGRA', countryName: 'GREECE' },
  { placeId: 1891, cityName: 'THESSALONIKI', countryName: 'GREECE' },
  { placeId: 1890, cityName: 'TRIPOLIS', countryName: 'GREECE' },
  { placeId: 1893, cityName: 'VOLOS', countryName: 'GREECE' },
  { placeId: 1894, cityName: 'ZAKINTHOS', countryName: 'GREECE' },
  { placeId: 9136, cityName: 'AASIAAT', countryName: 'GREENLAND' },
  { placeId: 11243, cityName: 'ILULISSAT', countryName: 'GREENLAND' },
  { placeId: 16, cityName: 'KANGERLUSSUAQ', countryName: 'GREENLAND' },
  { placeId: 11542, cityName: 'KULUSUK', countryName: 'GREENLAND' },
  { placeId: 11817, cityName: 'MANIITSOQ', countryName: 'GREENLAND' },
  { placeId: 13, cityName: 'NARSARSUAQ', countryName: 'GREENLAND' },
  { placeId: 14, cityName: 'NERLERIT INAAT', countryName: 'GREENLAND' },
  { placeId: 15, cityName: 'NUUK', countryName: 'GREENLAND' },
  { placeId: 12401, cityName: 'PAAMIUT', countryName: 'GREENLAND' },
  { placeId: 12683, cityName: 'QAANAAQ', countryName: 'GREENLAND' },
  { placeId: 13099, cityName: 'SISIMIUT', countryName: 'GREENLAND' },
  { placeId: 8465, cityName: 'STATION NORD', countryName: 'GREENLAND' },
  { placeId: 2872, cityName: 'THULE', countryName: 'GREENLAND' },
  { placeId: 13547, cityName: 'UPERNAVIK', countryName: 'GREENLAND' },
  { placeId: 8808, cityName: 'UUMMANNAQ', countryName: 'GREENLAND' },
  { placeId: 4748, cityName: 'CARRIACOU IS', countryName: 'GRENADA' },
  { placeId: 8415, cityName: 'ST GEORGES', countryName: 'GRENADA' },
  { placeId: 4369, cityName: 'BASSE-TERRE', countryName: 'GUADELOUPE' },
  { placeId: 5680, cityName: 'GRAND-BOURG', countryName: 'GUADELOUPE' },
  { placeId: 5682, cityName: 'GRANDE ANSE', countryName: 'GUADELOUPE' },
  { placeId: 2540, cityName: 'POINTE-A-PITRE', countryName: 'GUADELOUPE' },
  { placeId: 8405, cityName: 'ST BARTHELEMY I', countryName: 'GUADELOUPE' },
  { placeId: 8421, cityName: 'ST MARTIN I', countryName: 'GUADELOUPE' },
  { placeId: 8623, cityName: 'TERRE DE HAUT', countryName: 'GUADELOUPE' },
  { placeId: 11012, cityName: 'GUAM I', countryName: 'GUAM' },
  { placeId: 2404, cityName: 'GUAM, GQ', countryName: 'GUAM' },
  { placeId: 4907, cityName: 'COBAN', countryName: 'GUATEMALA' },
  { placeId: 5416, cityName: 'FLORES', countryName: 'GUATEMALA' },
  { placeId: 2298, cityName: 'GUATEMALA CITY', countryName: 'GUATEMALA' },
  { placeId: 7692, cityName: 'POPTUN', countryName: 'GUATEMALA' },
  { placeId: 2299, cityName: 'PUERTO BARRIOS', countryName: 'GUATEMALA' },
  { placeId: 2300, cityName: 'PUERTO SAN JOSE', countryName: 'GUATEMALA' },
  { placeId: 7901, cityName: 'RETALHULEU', countryName: 'GUATEMALA' },
  { placeId: 4322, cityName: 'BANANKORO', countryName: 'GUINEA REP' },
  { placeId: 4524, cityName: 'BOKE', countryName: 'GUINEA REP' },
  { placeId: 4933, cityName: 'CONAKRY', countryName: 'GUINEA REP' },
  { placeId: 5368, cityName: 'FARANAH', countryName: 'GUINEA REP' },
  { placeId: 5123, cityName: 'FRIA', countryName: 'GUINEA REP' },
  { placeId: 5635, cityName: 'KAMSAR', countryName: 'GUINEA REP' },
  { placeId: 5040, cityName: 'KANKAN', countryName: 'GUINEA REP' },
  { placeId: 6268, cityName: 'KISSIDOUGOU', countryName: 'GUINEA REP' },
  { placeId: 6329, cityName: 'KOUNDARA', countryName: 'GUINEA REP' },
  { placeId: 6388, cityName: 'LABE', countryName: 'GUINEA REP' },
  { placeId: 6475, cityName: 'MACENTA', countryName: 'GUINEA REP' },
  { placeId: 7330, cityName: 'NZEREKORE', countryName: 'GUINEA REP' },
  { placeId: 8081, cityName: 'SANGAREDI', countryName: 'GUINEA REP' },
  { placeId: 8254, cityName: 'SIGUIRI', countryName: 'GUINEA REP' },
  { placeId: 1180, cityName: 'BISSAU', countryName: 'GUINEA-BISSAU' },
  { placeId: 4030, cityName: 'AISHALTON', countryName: 'GUYANA' },
  { placeId: 4156, cityName: 'ANNAI', countryName: 'GUYANA' },
  { placeId: 4170, cityName: 'APOTERI', countryName: 'GUYANA' },
  { placeId: 3980, cityName: 'AWARUWANNAWA', countryName: 'GUYANA' },
  { placeId: 5259, cityName: 'EBINI', countryName: 'GUYANA' },
  { placeId: 2536, cityName: 'GEORGETOWN', countryName: 'GUYANA' },
  { placeId: 5952, cityName: 'IMBAIMADAI', countryName: 'GUYANA' },
  { placeId: 11360, cityName: 'KAIETEUR', countryName: 'GUYANA' },
  { placeId: 5628, cityName: 'KAMARANG', countryName: 'GUYANA' },
  { placeId: 6138, cityName: 'KARANAMBO', countryName: 'GUYANA' },
  { placeId: 6164, cityName: 'KATO', countryName: 'GUYANA' },
  { placeId: 6347, cityName: 'KURUKABARU GUYANA', countryName: 'GUYANA' },
  { placeId: 6534, cityName: 'LETHEM', countryName: 'GUYANA' },
  { placeId: 6569, cityName: 'LINDEN', countryName: 'GUYANA' },
  { placeId: 5649, cityName: 'LUMID PAU', countryName: 'GUYANA' },
  { placeId: 6713, cityName: 'MAHDIA', countryName: 'GUYANA' },
  { placeId: 7094, cityName: 'MOUNTAIN POINT GUYANA', countryName: 'GUYANA' },
  { placeId: 7415, cityName: 'ORINDUIK', countryName: 'GUYANA' },
  { placeId: 4721, cityName: 'CAP HAITIEN', countryName: 'HAITI' },
  { placeId: 4764, cityName: 'CAYES', countryName: 'HAITI' },
  { placeId: 6032, cityName: 'JACMEL', countryName: 'HAITI' },
  { placeId: 6056, cityName: 'JEREMIE', countryName: 'HAITI' },
  { placeId: 7703, cityName: 'PORT DE PAIX', countryName: 'HAITI' },
  { placeId: 7709, cityName: 'PORT-AU-PRINCE', countryName: 'HAITI' },
  { placeId: 4758, cityName: 'CATACAMAS', countryName: 'HONDURAS' },
  { placeId: 6008, cityName: 'ISLAS DEL CISNE I.', countryName: 'HONDURAS' },
  { placeId: 6361, cityName: 'LA CEIBA', countryName: 'HONDURAS' },
  { placeId: 6366, cityName: 'LA ESPERANZA', countryName: 'HONDURAS' },
  { placeId: 7789, cityName: 'PUERTO LEMPIRA', countryName: 'HONDURAS' },
  { placeId: 7926, cityName: 'ROATAN', countryName: 'HONDURAS' },
  { placeId: 8060, cityName: 'SAN PEDRO SULA', countryName: 'HONDURAS' },
  { placeId: 9224, cityName: 'SANTA ROSA DE COPAN', countryName: 'HONDURAS' },
  { placeId: 8606, cityName: 'TEGUCIGALPA', countryName: 'HONDURAS' },
  { placeId: 8609, cityName: 'TELA', countryName: 'HONDURAS' },
  { placeId: 2682, cityName: 'HONG KONG', countryName: 'HONG KONG, PR OF CHINA' },
  { placeId: 1895, cityName: 'BEKESCSABA', countryName: 'HUNGARY' },
  { placeId: 1897, cityName: 'BUDAORS', countryName: 'HUNGARY' },
  { placeId: 1896, cityName: 'BUDAPEST', countryName: 'HUNGARY' },
  { placeId: 1898, cityName: 'DEBRECEN', countryName: 'HUNGARY' },
  { placeId: 5391, cityName: 'FERTOSZENTMIKLOS', countryName: 'HUNGARY' },
  { placeId: 1901, cityName: 'GYOR-PER', countryName: 'HUNGARY' },
  { placeId: 6035, cityName: 'JAKABSZALLAS', countryName: 'HUNGARY' },
  { placeId: 11387, cityName: 'KAPOSVAR', countryName: 'HUNGARY' },
  { placeId: 11414, cityName: 'KECSKEMET', countryName: 'HUNGARY' },
  { placeId: 1899, cityName: 'NYIREGYHAZA', countryName: 'HUNGARY' },
  { placeId: 12304, cityName: 'OCSENY', countryName: 'HUNGARY' },
  { placeId: 12432, cityName: 'PAPA', countryName: 'HUNGARY' },
  { placeId: 1900, cityName: 'PECS', countryName: 'HUNGARY' },
  { placeId: 1902, cityName: 'SARMELLEK', countryName: 'HUNGARY' },
  { placeId: 13087, cityName: 'SIOFOK-KILITI', countryName: 'HUNGARY' },
  { placeId: 1903, cityName: 'SZEGED', countryName: 'HUNGARY' },
  { placeId: 13329, cityName: 'SZOLNOK', countryName: 'HUNGARY' },
  { placeId: 17, cityName: 'AKUREYRI', countryName: 'ICELAND' },
  { placeId: 9698, cityName: 'BAKKI', countryName: 'ICELAND' },
  { placeId: 4472, cityName: 'BILDUDALUR', countryName: 'ICELAND' },
  { placeId: 4507, cityName: 'BLONDUOS', countryName: 'ICELAND' },
  { placeId: 4592, cityName: 'BREIDDALSVIK', countryName: 'ICELAND' },
  { placeId: 18, cityName: 'EGILSSTADIR', countryName: 'ICELAND' },
  { placeId: 5572, cityName: 'GJOGUR', countryName: 'ICELAND' },
  { placeId: 5716, cityName: 'GRIMSEY', countryName: 'ICELAND' },
  { placeId: 19, cityName: 'HOFN', countryName: 'ICELAND' },
  { placeId: 5867, cityName: 'HOLMAVIK', countryName: 'ICELAND' },
  { placeId: 5911, cityName: 'HUSAVIK', countryName: 'ICELAND' },
  { placeId: 20, cityName: 'ISAFJORDUR', countryName: 'ICELAND' },
  { placeId: 21, cityName: 'KEFLAVIK', countryName: 'ICELAND' },
  { placeId: 6318, cityName: 'KOPASKER', countryName: 'ICELAND' },
  { placeId: 7275, cityName: 'NORDFJORDUR', countryName: 'ICELAND' },
  { placeId: 7575, cityName: 'PATREKSFJORDUR', countryName: 'ICELAND' },
  { placeId: 7867, cityName: 'RAUFARHOFN', countryName: 'ICELAND' },
  { placeId: 7905, cityName: 'REYKJAHLID', countryName: 'ICELAND' },
  { placeId: 23, cityName: 'REYKJAVIK', countryName: 'ICELAND' },
  { placeId: 7915, cityName: 'RIF', countryName: 'ICELAND' },
  { placeId: 22, cityName: 'SAUDARKROKUR', countryName: 'ICELAND' },
  { placeId: 8150, cityName: 'SELFOSS', countryName: 'ICELAND' },
  { placeId: 8253, cityName: 'SIGLUFJORDUR', countryName: 'ICELAND' },
  { placeId: 8500, cityName: 'STYKKISHOLMUR', countryName: 'ICELAND' },
  { placeId: 8644, cityName: 'THINGEYRI', countryName: 'ICELAND' },
  { placeId: 8651, cityName: 'THORSHOFN', countryName: 'ICELAND' },
  { placeId: 24, cityName: 'VESTMANNAEYJAR', countryName: 'ICELAND' },
  { placeId: 8874, cityName: 'VOPNAFJORDUR', countryName: 'ICELAND' },
  { placeId: 4018, cityName: 'AGARTALA', countryName: 'INDIA' },
  { placeId: 4021, cityName: 'AGRA', countryName: 'INDIA' },
  { placeId: 2674, cityName: 'AHMEDABAD', countryName: 'INDIA' },
  { placeId: 4039, cityName: 'AKOLA', countryName: 'INDIA' },
  { placeId: 4083, cityName: 'ALLAHABAD', countryName: 'INDIA' },
  { placeId: 2683, cityName: 'AMRITSAR', countryName: 'INDIA' },
  { placeId: 3973, cityName: 'AURANGABAD', countryName: 'INDIA' },
  { placeId: 4294, cityName: 'BAGDOGRA', countryName: 'INDIA' },
  { placeId: 2812, cityName: 'BANGALORE', countryName: 'INDIA' },
  { placeId: 4342, cityName: 'BARAPANI', countryName: 'INDIA' },
  { placeId: 4346, cityName: 'BAREILLY', countryName: 'INDIA' },
  { placeId: 4401, cityName: 'BEHALA', countryName: 'INDIA' },
  { placeId: 4408, cityName: 'BELGAUM', countryName: 'INDIA' },
  { placeId: 4454, cityName: 'BHAVNAGAR', countryName: 'INDIA' },
  { placeId: 4457, cityName: 'BHOPAL', countryName: 'INDIA' },
  { placeId: 4458, cityName: 'BHUBANESHWAR', countryName: 'INDIA' },
  { placeId: 4459, cityName: 'BHUJ', countryName: 'INDIA' },
  { placeId: 4471, cityName: 'BILASPUR', countryName: 'INDIA' },
  { placeId: 2692, cityName: 'CALICUT', countryName: 'INDIA' },
  { placeId: 4726, cityName: 'CAR NICOBAR', countryName: 'INDIA' },
  { placeId: 3983, cityName: 'CHAKULIA', countryName: 'INDIA' },
  { placeId: 3990, cityName: 'CHANDIGARH', countryName: 'INDIA' },
  { placeId: 2694, cityName: 'CHENNAI', countryName: 'INDIA' },
  { placeId: 2691, cityName: 'COCHIN', countryName: 'INDIA' },
  { placeId: 4919, cityName: 'COIMBATORE', countryName: 'INDIA' },
  { placeId: 4951, cityName: 'COOCH BEHAR', countryName: 'INDIA' },
  { placeId: 5093, cityName: 'DEESA', countryName: 'INDIA' },
  { placeId: 5095, cityName: 'DEHRADUN', countryName: 'INDIA' },
  { placeId: 2685, cityName: 'DELHI', countryName: 'INDIA' },
  { placeId: 5167, cityName: 'DIBRUGARH', countryName: 'INDIA' },
  { placeId: 5178, cityName: 'DIMAPUR', countryName: 'INDIA' },
  { placeId: 5540, cityName: 'GAYA', countryName: 'INDIA' },
  { placeId: 2834, cityName: 'GOA', countryName: 'INDIA' },
  { placeId: 5601, cityName: 'GONDIA', countryName: 'INDIA' },
  { placeId: 5135, cityName: 'GORAKHPUR', countryName: 'INDIA' },
  { placeId: 5750, cityName: 'GUWAHATI', countryName: 'INDIA' },
  { placeId: 5754, cityName: 'GWALIOR', countryName: 'INDIA' },
  { placeId: 5900, cityName: 'HUBLI', countryName: 'INDIA' },
  { placeId: 2693, cityName: 'HYDERABAD', countryName: 'INDIA' },
  { placeId: 5957, cityName: 'IMPHAL', countryName: 'INDIA' },
  { placeId: 5966, cityName: 'INDORE', countryName: 'INDIA' },
  { placeId: 6023, cityName: 'JABALPUR', countryName: 'INDIA' },
  { placeId: 2686, cityName: 'JAIPUR', countryName: 'INDIA' },
  { placeId: 6039, cityName: 'JAMMU', countryName: 'INDIA' },
  { placeId: 6040, cityName: 'JAMNAGAR', countryName: 'INDIA' },
  { placeId: 6041, cityName: 'JAMSHEDPUR', countryName: 'INDIA' },
  { placeId: 6067, cityName: 'JODHPUR', countryName: 'INDIA' },
  { placeId: 6080, cityName: 'JORHAT', countryName: 'INDIA' },
  { placeId: 5642, cityName: 'KANDLA', countryName: 'INDIA' },
  { placeId: 5036, cityName: 'KANGRA', countryName: 'INDIA' },
  { placeId: 6127, cityName: 'KANPUR', countryName: 'INDIA' },
  { placeId: 6205, cityName: 'KESHOD', countryName: 'INDIA' },
  { placeId: 6208, cityName: 'KHAJURAHO', countryName: 'INDIA' },
  { placeId: 6308, cityName: 'KOLHAPUR', countryName: 'INDIA' },
  { placeId: 2678, cityName: 'KOLKATA', countryName: 'INDIA' },
  { placeId: 6324, cityName: 'KOTA', countryName: 'INDIA' },
  { placeId: 6341, cityName: 'KULLU MANALI', countryName: 'INDIA' },
  { placeId: 13913, cityName: 'LEH', countryName: 'INDIA' },
  { placeId: 6524, cityName: 'LENGPUI', countryName: 'INDIA' },
  { placeId: 6554, cityName: 'LILABARI', countryName: 'INDIA' },
  { placeId: 2687, cityName: 'LUCKNOW', countryName: 'INDIA' },
  { placeId: 6653, cityName: 'LUDHIANA', countryName: 'INDIA' },
  { placeId: 6700, cityName: 'MADURAI', countryName: 'INDIA' },
  { placeId: 6758, cityName: 'MANGALORE', countryName: 'INDIA' },
  { placeId: 2675, cityName: 'MUMBAI', countryName: 'INDIA' },
  { placeId: 12141, cityName: 'MYSORE', countryName: 'INDIA' },
  { placeId: 2676, cityName: 'NAGPUR', countryName: 'INDIA' },
  { placeId: 7182, cityName: 'NANDED', countryName: 'INDIA' },
  { placeId: 7465, cityName: 'OZAR', countryName: 'INDIA' },
  { placeId: 7549, cityName: 'PANTNAGAR', countryName: 'INDIA' },
  { placeId: 7569, cityName: 'PASIGHAT', countryName: 'INDIA' },
  { placeId: 7573, cityName: 'PATHANKOT', countryName: 'INDIA' },
  { placeId: 2679, cityName: 'PATNA', countryName: 'INDIA' },
  { placeId: 7493, cityName: 'PONDICHERRY', countryName: 'INDIA' },
  { placeId: 7693, cityName: 'PORBANDAR', countryName: 'INDIA' },
  { placeId: 7701, cityName: 'PORT BLAIR', countryName: 'INDIA' },
  { placeId: 2845, cityName: 'PUNE', countryName: 'INDIA' },
  { placeId: 13940, cityName: 'Puttaparthi', countryName: 'INDIA' },
  { placeId: 7509, cityName: 'RAIPUR', countryName: 'INDIA' },
  { placeId: 7510, cityName: 'RAJAHMUNDRY', countryName: 'INDIA' },
  { placeId: 7512, cityName: 'RAJKOT', countryName: 'INDIA' },
  { placeId: 7849, cityName: 'RANCHI', countryName: 'INDIA' },
  { placeId: 7865, cityName: 'RATNAGIRI', countryName: 'INDIA' },
  { placeId: 7871, cityName: 'RAXAUL', countryName: 'INDIA' },
  { placeId: 7985, cityName: 'RUPSI', countryName: 'INDIA' },
  { placeId: 8004, cityName: 'SALEM', countryName: 'INDIA' },
  { placeId: 8127, cityName: 'SATNA', countryName: 'INDIA' },
  { placeId: 8234, cityName: 'SHIMLA', countryName: 'INDIA' },
  { placeId: 8240, cityName: 'SHOLAPUR', countryName: 'INDIA' },
  { placeId: 8401, cityName: 'SRI SATHYA SAI', countryName: 'INDIA' },
  { placeId: 8402, cityName: 'SRINAGAR', countryName: 'INDIA' },
  { placeId: 8523, cityName: 'SURAT', countryName: 'INDIA' },
  { placeId: 2696, cityName: 'THIRUVANANTHAPURAM', countryName: 'INDIA' },
  { placeId: 8645, cityName: 'THOISE', countryName: 'INDIA' },
  { placeId: 2695, cityName: 'TIRUCHIRAPPALLI', countryName: 'INDIA' },
  { placeId: 8674, cityName: 'TIRUPATI', countryName: 'INDIA' },
  { placeId: 8772, cityName: 'UDAIPUR', countryName: 'INDIA' },
  { placeId: 8812, cityName: 'VADODARA', countryName: 'INDIA' },
  { placeId: 2684, cityName: 'VARANASI', countryName: 'INDIA' },
  { placeId: 8852, cityName: 'VIJAYAWADA', countryName: 'INDIA' },
  { placeId: 8868, cityName: 'VISHAKHAPATNAM', countryName: 'INDIA' },
  { placeId: 14049, cityName: 'Yelahanka', countryName: 'INDIA' },
  // { placeId: 9435, cityName: '', countryName: 'INDONESIA' },
  { placeId: 3734, cityName: 'AMBON', countryName: 'INDONESIA' },
  { placeId: 13941, cityName: 'Atambua', countryName: 'INDONESIA' },
  { placeId: 2729, cityName: 'BALI', countryName: 'INDONESIA' },
  { placeId: 3729, cityName: 'BALIKPAPAN', countryName: 'INDONESIA' },
  { placeId: 3743, cityName: 'BANDA ACEH', countryName: 'INDONESIA' },
  { placeId: 9709, cityName: 'BANDAR LAMPUNG', countryName: 'INDONESIA' },
  { placeId: 3739, cityName: 'BANDUNG', countryName: 'INDONESIA' },
  { placeId: 9717, cityName: 'BANJARMASIN', countryName: 'INDONESIA' },
  { placeId: 2740, cityName: 'BATAM', countryName: 'INDONESIA' },
  { placeId: 9754, cityName: 'BAUCAU', countryName: 'INDONESIA' },
  { placeId: 9810, cityName: 'BENGKULU', countryName: 'INDONESIA' },
  { placeId: 9825, cityName: 'BIAK', countryName: 'INDONESIA' },
  { placeId: 9836, cityName: 'BIMA', countryName: 'INDONESIA' },
  { placeId: 10225, cityName: 'CILACAP', countryName: 'INDONESIA' },
  { placeId: 10229, cityName: 'CIREBON', countryName: 'INDONESIA' },
  { placeId: 2756, cityName: 'DILI', countryName: 'INDONESIA' },
  { placeId: 10528, cityName: 'DUMAI', countryName: 'INDONESIA' },
  { placeId: 10937, cityName: 'GORONTALO', countryName: 'INDONESIA' },
  { placeId: 2741, cityName: 'JAKARTA', countryName: 'INDONESIA' },
  { placeId: 3741, cityName: 'JAMBI', countryName: 'INDONESIA' },
  { placeId: 11320, cityName: 'JAYAPURA', countryName: 'INDONESIA' },
  { placeId: 851, cityName: 'KALIMANTAN', countryName: 'INDONESIA' },
  { placeId: 6193, cityName: 'KENDARI', countryName: 'INDONESIA' },
  { placeId: 11437, cityName: 'KETAPANG', countryName: 'INDONESIA' },
  { placeId: 11496, cityName: 'KISAR ISLAND', countryName: 'INDONESIA' },
  { placeId: 6345, cityName: 'KUPANG', countryName: 'INDONESIA' },
  { placeId: 9203, cityName: 'LABUAN BAJO', countryName: 'INDONESIA' },
  { placeId: 9228, cityName: 'LABUHA', countryName: 'INDONESIA' },
  {
    placeId: 13906, cityName: 'LHOK SEUMAWE', state: 'ACEH', countryName: 'INDONESIA',
  },
  { placeId: 11767, cityName: 'LUWUK', countryName: 'INDONESIA' },
  { placeId: 11793, cityName: 'MADIUN', countryName: 'INDONESIA' },
  { placeId: 3727, cityName: 'MAKASSAR', countryName: 'INDONESIA' },
  { placeId: 3735, cityName: 'MALANG', countryName: 'INDONESIA' },
  { placeId: 11808, cityName: 'MAMUJU', countryName: 'INDONESIA' },
  { placeId: 3731, cityName: 'MANADO', countryName: 'INDONESIA' },
  { placeId: 9225, cityName: 'MANGOLE', countryName: 'INDONESIA' },
  { placeId: 11833, cityName: 'MANOKWARI', countryName: 'INDONESIA' },
  { placeId: 3728, cityName: 'MATARAM', countryName: 'INDONESIA' },
  { placeId: 6881, cityName: 'MAUMERE', countryName: 'INDONESIA' },
  { placeId: 2742, cityName: 'MEDAN', countryName: 'INDONESIA' },
  { placeId: 11953, cityName: 'MERAUKE', countryName: 'INDONESIA' },
  { placeId: 12075, cityName: 'MOROTAI', countryName: 'INDONESIA' },
  { placeId: 12118, cityName: 'MUKOMUKO', countryName: 'INDONESIA' },
  { placeId: 12143, cityName: 'NABIRE', countryName: 'INDONESIA' },
  { placeId: 12317, cityName: 'OKSIBIL', countryName: 'INDONESIA' },
  { placeId: 3742, cityName: 'PADANG PARIAMAN', countryName: 'INDONESIA' },
  { placeId: 12404, cityName: 'PADANG', countryName: 'INDONESIA' },
  { placeId: 3733, cityName: 'PALANGKA RAYA', countryName: 'INDONESIA' },
  { placeId: 12412, cityName: 'PALEMBANG', countryName: 'INDONESIA' },
  { placeId: 12424, cityName: 'PALU', countryName: 'INDONESIA' },
  { placeId: 12429, cityName: 'PANGKAL PINANG', countryName: 'INDONESIA' },
  { placeId: 3732, cityName: 'PANGKALANBUN', countryName: 'INDONESIA' },
  { placeId: 3738, cityName: 'PEKANBARU', countryName: 'INDONESIA' },
  { placeId: 3740, cityName: 'PONTIANAK', countryName: 'INDONESIA' },
  { placeId: 12621, cityName: 'POSO, INDONESIA', countryName: 'INDONESIA' },
  { placeId: 12639, cityName: 'PRAYA', countryName: 'INDONESIA' },
  { placeId: 12726, cityName: 'RANAI', countryName: 'INDONESIA' },
  { placeId: 12768, cityName: 'RENGAT', countryName: 'INDONESIA' },
  { placeId: 12878, cityName: 'SABANG', countryName: 'INDONESIA' },
  { placeId: 12909, cityName: 'SAMARINDA', countryName: 'INDONESIA' },
  { placeId: 9181, cityName: 'SAMPIT', countryName: 'INDONESIA' },
  { placeId: 8126, cityName: 'SATARTACIK', countryName: 'INDONESIA' },
  { placeId: 13007, cityName: 'SEMARANG', countryName: 'INDONESIA' },
  { placeId: 13086, cityName: 'SINGKEP', countryName: 'INDONESIA' },
  { placeId: 3737, cityName: 'SOLO', countryName: 'INDONESIA' },
  { placeId: 8321, cityName: 'SORONG', countryName: 'INDONESIA' },
  { placeId: 13291, cityName: 'SUAI', countryName: 'INDONESIA' },
  { placeId: 13300, cityName: 'SUMBAWA', countryName: 'INDONESIA' },
  { placeId: 2730, cityName: 'SURABAYA', countryName: 'INDONESIA' },
  { placeId: 13357, cityName: 'TANGERANG', countryName: 'INDONESIA' },
  { placeId: 13359, cityName: 'TANJUNG PANDAN', countryName: 'INDONESIA' },
  { placeId: 13360, cityName: 'TANJUNG PINANG', countryName: 'INDONESIA' },
  { placeId: 3730, cityName: 'TARAKAN', countryName: 'INDONESIA' },
  { placeId: 13395, cityName: 'TERNATE', countryName: 'INDONESIA' },
  { placeId: 13435, cityName: 'TIMIKA', countryName: 'INDONESIA' },
  { placeId: 13904, cityName: 'WAIKABUBAK', countryName: 'INDONESIA' },
  { placeId: 13642, cityName: 'WAINGAPU', countryName: 'INDONESIA' },
  { placeId: 13654, cityName: 'WAMENA', countryName: 'INDONESIA' },
  { placeId: 13668, cityName: 'WARUKIN', countryName: 'INDONESIA' },
  { placeId: 3736, cityName: 'YOGYAKARTA', countryName: 'INDONESIA' },
  { placeId: 9139, cityName: 'ABADAN', countryName: 'IRAN' },
  { placeId: 4009, cityName: 'ABUMUSA ISLAND', countryName: 'IRAN' },
  { placeId: 4020, cityName: 'AGHAJARI', countryName: 'IRAN' },
  { placeId: 2354, cityName: 'AHWAZ', countryName: 'IRAN' },
  { placeId: 4198, cityName: 'ARAK', countryName: 'IRAN' },
  { placeId: 4210, cityName: 'ARDABIL', countryName: 'IRAN' },
  { placeId: 4233, cityName: 'ASALOYEH', countryName: 'IRAN' },
  { placeId: 9691, cityName: 'BAHREGAN', countryName: 'IRAN' },
  { placeId: 4317, cityName: 'BAM', countryName: 'IRAN' },
  { placeId: 2358, cityName: 'BANDAR ABBASS', countryName: 'IRAN' },
  { placeId: 4324, cityName: 'BANDAR LENGEH', countryName: 'IRAN' },
  { placeId: 4325, cityName: 'BANDAR MAHSHAHR', countryName: 'IRAN' },
  { placeId: 4487, cityName: 'BIRJAND', countryName: 'IRAN' },
  { placeId: 4490, cityName: 'BISHE KOLA', countryName: 'IRAN' },
  { placeId: 4522, cityName: 'BOJNORD', countryName: 'IRAN' },
  { placeId: 4653, cityName: 'BUSHEHR', countryName: 'IRAN' },
  { placeId: 4784, cityName: 'CHAH BAHAR', countryName: 'IRAN' },
  { placeId: 5163, cityName: 'DEZFUL', countryName: 'IRAN' },
  { placeId: 2356, cityName: 'ESFAHAN', countryName: 'IRAN' },
  { placeId: 5379, cityName: 'FASA', countryName: 'IRAN' },
  { placeId: 5506, cityName: 'GACHSARAN', countryName: 'IRAN' },
  { placeId: 5556, cityName: 'GHAZVIN', countryName: 'IRAN' },
  { placeId: 5557, cityName: 'GHESHM ISLAND', countryName: 'IRAN' },
  { placeId: 5140, cityName: 'GORGAN', countryName: 'IRAN' },
  { placeId: 5780, cityName: 'HAMADAN', countryName: 'IRAN' },
  { placeId: 5941, cityName: 'ILAM', countryName: 'IRAN' },
  { placeId: 5987, cityName: 'IRAN SHAHR', countryName: 'IRAN' },
  { placeId: 6034, cityName: 'JAHROM', countryName: 'IRAN' },
  { placeId: 6036, cityName: 'JAM', countryName: 'IRAN' },
  { placeId: 6065, cityName: 'JIROFT', countryName: 'IRAN' },
  { placeId: 6118, cityName: 'KALALEH', countryName: 'IRAN' },
  { placeId: 6136, cityName: 'KARAJ', countryName: 'IRAN' },
  { placeId: 11400, cityName: 'KASHAN', countryName: 'IRAN' },
  { placeId: 2359, cityName: 'KERMAN', countryName: 'IRAN' },
  { placeId: 5043, cityName: 'KERMANSHAH', countryName: 'IRAN' },
  { placeId: 6209, cityName: 'KHARK ISLAND', countryName: 'IRAN' },
  { placeId: 6211, cityName: 'KHORAM ABAD', countryName: 'IRAN' },
  { placeId: 6212, cityName: 'KHOY', countryName: 'IRAN' },
  { placeId: 2355, cityName: 'KISH ISLAND', countryName: 'IRAN' },
  { placeId: 6419, cityName: 'LAMERD', countryName: 'IRAN' },
  { placeId: 6440, cityName: 'LAR', countryName: 'IRAN' },
  { placeId: 6455, cityName: 'LAVAN ISLAND', countryName: 'IRAN' },
  { placeId: 6796, cityName: 'MARAGHEH', countryName: 'IRAN' },
  { placeId: 2360, cityName: 'MASHHAD', countryName: 'IRAN' },
  { placeId: 6853, cityName: 'MASJED SOLEIMAN', countryName: 'IRAN' },
  { placeId: 7315, cityName: 'NOSHAHR', countryName: 'IRAN' },
  { placeId: 7388, cityName: 'OMIDIYEH', countryName: 'IRAN' },
  { placeId: 7565, cityName: 'PARSABADE MOGHAN', countryName: 'IRAN' },
  { placeId: 7306, cityName: 'PERSIAN GULF', countryName: 'IRAN' },
  { placeId: 7504, cityName: 'RAFSANJAN', countryName: 'IRAN' },
  { placeId: 7847, cityName: 'RAMSAR', countryName: 'IRAN' },
  { placeId: 7861, cityName: 'RASHT', countryName: 'IRAN' },
  { placeId: 7993, cityName: 'SABZEVAR', countryName: 'IRAN' },
  { placeId: 8071, cityName: 'SANANDAJ', countryName: 'IRAN' },
  { placeId: 8113, cityName: 'SARAKHS', countryName: 'IRAN' },
  { placeId: 8116, cityName: 'SARAVAN', countryName: 'IRAN' },
  { placeId: 8120, cityName: 'SARI', countryName: 'IRAN' },
  { placeId: 8207, cityName: 'SHAHRE KORD', countryName: 'IRAN' },
  { placeId: 8208, cityName: 'SHAHROUD', countryName: 'IRAN' },
  { placeId: 2361, cityName: 'SHIRAZ', countryName: 'IRAN' },
  { placeId: 8277, cityName: 'SIRJAN', countryName: 'IRAN' },
  { placeId: 8278, cityName: 'SIRRI ISLAND', countryName: 'IRAN' },
  { placeId: 8344, cityName: 'TABAS', countryName: 'IRAN' },
  { placeId: 8533, cityName: 'TABRIZ', countryName: 'IRAN' },
  { placeId: 2357, cityName: 'TEHRAN', countryName: 'IRAN' },
  { placeId: 2362, cityName: 'UROMIYEH', countryName: 'IRAN' },
  { placeId: 9077, cityName: 'YASOUJ', countryName: 'IRAN' },
  { placeId: 9079, cityName: 'YAZD', countryName: 'IRAN' },
  { placeId: 9100, cityName: 'ZABOL', countryName: 'IRAN' },
  { placeId: 2363, cityName: 'ZAHEDAN', countryName: 'IRAN' },
  { placeId: 9108, cityName: 'ZANJAN', countryName: 'IRAN' },
  { placeId: 4044, cityName: 'AL ASAD', countryName: 'IRAQ' },
  { placeId: 9182, cityName: 'AL NAJAF', countryName: 'IRAQ' },
  { placeId: 4052, cityName: 'AL TAJI', countryName: 'IRAQ' },
  { placeId: 4053, cityName: 'AL TAQADDUM', countryName: 'IRAQ' },
  { placeId: 4295, cityName: 'BAGHDAD', countryName: 'IRAQ' },
  { placeId: 4309, cityName: 'BALAD', countryName: 'IRAQ' },
  { placeId: 2379, cityName: 'BASRAH', countryName: 'IRAQ' },
  { placeId: 2841, cityName: 'ERBIL', countryName: 'IRAQ' },
  { placeId: 6259, cityName: 'KIRKUK', countryName: 'IRAQ' },
  { placeId: 7086, cityName: 'MOSUL', countryName: 'IRAQ' },
  { placeId: 7818, cityName: 'QAYYARAH WEST', countryName: 'IRAQ' },
  { placeId: 8503, cityName: 'SULAYMANIYAH', countryName: 'IRAQ' },
  { placeId: 13348, cityName: 'TALL AFAR', countryName: 'IRAQ' },
  { placeId: 8562, cityName: 'TALLIL', countryName: 'IRAQ' },
  { placeId: 8663, cityName: 'TIKRIT', countryName: 'IRAQ' },
  { placeId: 799, cityName: 'ABBEYSHRULE', countryName: 'IRELAND' },
  { placeId: 9640, cityName: 'ATHBOY', countryName: 'IRELAND' },
  { placeId: 814, cityName: 'BALDONNEL', countryName: 'IRELAND' },
  { placeId: 800, cityName: 'BANTRY', countryName: 'IRELAND' },
  { placeId: 802, cityName: 'BELMULLET', countryName: 'IRELAND' },
  { placeId: 801, cityName: 'BIRR', countryName: 'IRELAND' },
  { placeId: 4854, cityName: 'CHONAMARA', countryName: 'IRELAND' },
  { placeId: 4902, cityName: 'CLONBULLOGUE', countryName: 'IRELAND' },
  { placeId: 805, cityName: 'COONAGH', countryName: 'IRELAND' },
  { placeId: 803, cityName: 'CORK', countryName: 'IRELAND' },
  { placeId: 806, cityName: 'DONEGAL', countryName: 'IRELAND' },
  { placeId: 807, cityName: 'DUBLIN', countryName: 'IRELAND' },
  { placeId: 10638, cityName: 'ERINAGH', countryName: 'IRELAND' },
  { placeId: 804, cityName: 'GALWAY', countryName: 'IRELAND' },
  { placeId: 808, cityName: 'GORMANSTON', countryName: 'IRELAND' },
  { placeId: 809, cityName: 'HACKETSTOWN', countryName: 'IRELAND' },
  { placeId: 811, cityName: 'INISHEER', countryName: 'IRELAND' },
  { placeId: 815, cityName: 'INISHMAAN', countryName: 'IRELAND' },
  { placeId: 810, cityName: 'INISHMORE', countryName: 'IRELAND' },
  { placeId: 812, cityName: 'IRELAND WEST', countryName: 'IRELAND' },
  { placeId: 813, cityName: 'KERRY', countryName: 'IRELAND' },
  { placeId: 6229, cityName: 'KILKENNY', countryName: 'IRELAND' },
  { placeId: 6236, cityName: 'KILRUSH', countryName: 'IRELAND' },
  { placeId: 7243, cityName: 'NEWCASTLE', countryName: 'IRELAND' },
  { placeId: 817, cityName: 'RATHCOOLE', countryName: 'IRELAND' },
  { placeId: 816, cityName: 'SHANNON', countryName: 'IRELAND' },
  { placeId: 818, cityName: 'SLIGO', countryName: 'IRELAND' },
  { placeId: 13485, cityName: 'TREVET', countryName: 'IRELAND' },
  { placeId: 819, cityName: 'TRIM', countryName: 'IRELAND' },
  { placeId: 820, cityName: 'WATERFORD', countryName: 'IRELAND' },
  { placeId: 821, cityName: 'WESTON', countryName: 'IRELAND' },
  { placeId: 2500, cityName: 'ISLA DE PASCUA', countryName: 'ISLA DE PASCUA' },
  { placeId: 2063, cityName: 'EILAT', countryName: 'ISRAEL' },
  { placeId: 2064, cityName: 'HAIFA', countryName: 'ISRAEL' },
  { placeId: 2065, cityName: 'JERUSALEM', countryName: 'ISRAEL' },
  { placeId: 2066, cityName: 'OVDA', countryName: 'ISRAEL' },
  { placeId: 2062, cityName: 'TEL AVIV', countryName: 'ISRAEL' },
  { placeId: 1928, cityName: 'ALBENGA', countryName: 'ITALY' },
  { placeId: 4068, cityName: 'ALESSANDRIA', countryName: 'ITALY' },
  { placeId: 1920, cityName: 'ALGHERO', countryName: 'ITALY' },
  { placeId: 4102, cityName: 'ALZATE BRIANZA', countryName: 'ITALY' },
  { placeId: 1904, cityName: 'AMENDOLA', countryName: 'ITALY' },
  { placeId: 2814, cityName: 'ANCONA', countryName: 'ITALY' },
  { placeId: 1934, cityName: 'AOSTA', countryName: 'ITALY' },
  { placeId: 4174, cityName: 'AQUINO', countryName: 'ITALY' },
  { placeId: 1954, cityName: 'AREZZO', countryName: 'ITALY' },
  { placeId: 4243, cityName: 'ASIAGO', countryName: 'ITALY' },
  { placeId: 1936, cityName: 'AVIANO', countryName: 'ITALY' },
  { placeId: 1906, cityName: 'BARI', countryName: 'ITALY' },
  { placeId: 4417, cityName: 'BELLUNO', countryName: 'ITALY' },
  { placeId: 1927, cityName: 'BERGAMO', countryName: 'ITALY' },
  { placeId: 4461, cityName: 'BIELLA', countryName: 'ITALY' },
  { placeId: 1940, cityName: 'BOLOGNA', countryName: 'ITALY' },
  { placeId: 1937, cityName: 'BOLZANO', countryName: 'ITALY' },
  { placeId: 1949, cityName: 'BRESCIA', countryName: 'ITALY' },
  { placeId: 1911, cityName: 'BRINDISI', countryName: 'ITALY' },
  { placeId: 1922, cityName: 'CAGLIARI', countryName: 'ITALY' },
  { placeId: 4681, cityName: 'CALCINATE DEL PESCE', countryName: 'ITALY' },
  { placeId: 1930, cityName: 'CAMERI', countryName: 'ITALY' },
  { placeId: 4725, cityName: 'CAPUA', countryName: 'ITALY' },
  { placeId: 4747, cityName: 'CARPI BUDRIONE', countryName: 'ITALY' },
  { placeId: 4752, cityName: 'CASALE MONFERRATO', countryName: 'ITALY' },
  { placeId: 1914, cityName: 'CATANIA', countryName: 'ITALY' },
  { placeId: 1938, cityName: 'CERVIA', countryName: 'ITALY' },
  { placeId: 4007, cityName: 'COMO', countryName: 'ITALY' },
  { placeId: 4989, cityName: 'CREMONA', countryName: 'ITALY' },
  { placeId: 1905, cityName: 'CROTONE', countryName: 'ITALY' },
  { placeId: 1935, cityName: 'CUNEO', countryName: 'ITALY' },
  { placeId: 1921, cityName: 'DECIMOMANNU', countryName: 'ITALY' },
  { placeId: 5365, cityName: 'FANO', countryName: 'ITALY' },
  { placeId: 1941, cityName: 'FERRARA', countryName: 'ITALY' },
  { placeId: 1968, cityName: 'FLORENCE', countryName: 'ITALY' },
  { placeId: 1907, cityName: 'FOGGIA', countryName: 'ITALY' },
  { placeId: 5419, cityName: 'FOLIGNO', countryName: 'ITALY' },
  { placeId: 1945, cityName: 'FORLI', countryName: 'ITALY' },
  { placeId: 1961, cityName: 'FROSINONE', countryName: 'ITALY' },
  { placeId: 1929, cityName: 'GENOA', countryName: 'ITALY' },
  { placeId: 1946, cityName: 'GHEDI', countryName: 'ITALY' },
  { placeId: 1912, cityName: 'GIOIA DEL COLLE', countryName: 'ITALY' },
  { placeId: 1942, cityName: 'GORIZIA', countryName: 'ITALY' },
  { placeId: 1965, cityName: 'GRAZZANISE', countryName: 'ITALY' },
  { placeId: 1969, cityName: 'GROSSETO', countryName: 'ITALY' },
  { placeId: 1960, cityName: 'GUIDONIA', countryName: 'ITALY' },
  { placeId: 6356, cityName: 'L\'AQUILA', countryName: 'ITALY' },
  { placeId: 1913, cityName: 'LAMEZIA TERME', countryName: 'ITALY' },
  { placeId: 1915, cityName: 'LAMPEDUSA', countryName: 'ITALY' },
  { placeId: 1964, cityName: 'LATINA', countryName: 'ITALY' },
  { placeId: 1909, cityName: 'LECCE', countryName: 'ITALY' },
  { placeId: 6522, cityName: 'LEGNAGO', countryName: 'ITALY' },
  { placeId: 1955, cityName: 'LUCCA', countryName: 'ITALY' },
  { placeId: 6660, cityName: 'LUGO DI ROMAGNA', countryName: 'ITALY' },
  { placeId: 1963, cityName: 'MARINA DI CAMPO', countryName: 'ITALY' },
  { placeId: 6856, cityName: 'MASSA', countryName: 'ITALY' },
  { placeId: 1926, cityName: 'MILAN', countryName: 'ITALY' },
  { placeId: 1947, cityName: 'MODENA', countryName: 'ITALY' },
  { placeId: 1966, cityName: 'NAPLES', countryName: 'ITALY' },
  { placeId: 1932, cityName: 'NOVI LIGURE', countryName: 'ITALY' },
  { placeId: 1923, cityName: 'OLBIA', countryName: 'ITALY' },
  { placeId: 7416, cityName: 'ORISTANO', countryName: 'ITALY' },
  { placeId: 1952, cityName: 'PADOVA', countryName: 'ITALY' },
  { placeId: 1917, cityName: 'PALERMO', countryName: 'ITALY' },
  { placeId: 1916, cityName: 'PANTELLERIA', countryName: 'ITALY' },
  { placeId: 1931, cityName: 'PARMA', countryName: 'ITALY' },
  { placeId: 7583, cityName: 'PAVULLO', countryName: 'ITALY' },
  { placeId: 1971, cityName: 'PERUGIA', countryName: 'ITALY' },
  { placeId: 1910, cityName: 'PESCARA', countryName: 'ITALY' },
  { placeId: 1933, cityName: 'PIACENZA', countryName: 'ITALY' },
  { placeId: 1967, cityName: 'PISA', countryName: 'ITALY' },
  { placeId: 7757, cityName: 'PRATI VECCHI DI AGUSCELLO', countryName: 'ITALY' },
  { placeId: 1959, cityName: 'PRATICA DI MARE', countryName: 'ITALY' },
  { placeId: 7868, cityName: 'RAVENNA', countryName: 'ITALY' },
  { placeId: 1918, cityName: 'REGGIO CALABRIA', countryName: 'ITALY' },
  { placeId: 7886, cityName: 'REGGIO EMILIA', countryName: 'ITALY' },
  { placeId: 1956, cityName: 'RIETI', countryName: 'ITALY' },
  { placeId: 1951, cityName: 'RIMINI', countryName: 'ITALY' },
  { placeId: 1944, cityName: 'RIVOLTO', countryName: 'ITALY' },
  { placeId: 1958, cityName: 'ROME', countryName: 'ITALY' },
  { placeId: 1962, cityName: 'SALERNO', countryName: 'ITALY' },
  { placeId: 8006, cityName: 'SALGAREDA', countryName: 'ITALY' },
  { placeId: 8123, cityName: 'SARZANA', countryName: 'ITALY' },
  { placeId: 1957, cityName: 'SIENA', countryName: 'ITALY' },
  { placeId: 8315, cityName: 'SONDRIO', countryName: 'ITALY' },
  { placeId: 1908, cityName: 'TARANTO', countryName: 'ITALY' },
  { placeId: 8643, cityName: 'THIENE', countryName: 'ITALY' },
  { placeId: 1925, cityName: 'TORINO', countryName: 'ITALY' },
  { placeId: 1924, cityName: 'TORTOLI', countryName: 'ITALY' },
  { placeId: 1919, cityName: 'TRAPANI', countryName: 'ITALY' },
  { placeId: 8698, cityName: 'TRENTO', countryName: 'ITALY' },
  { placeId: 1943, cityName: 'TREVISO', countryName: 'ITALY' },
  { placeId: 1950, cityName: 'TRIESTE', countryName: 'ITALY' },
  { placeId: 1939, cityName: 'UDINE', countryName: 'ITALY' },
  { placeId: 8813, cityName: 'VALBREMBO', countryName: 'ITALY' },
  { placeId: 8828, cityName: 'VARESE', countryName: 'ITALY' },
  { placeId: 1953, cityName: 'VENICE', countryName: 'ITALY' },
  { placeId: 8368, cityName: 'VERCELLI', countryName: 'ITALY' },
  { placeId: 8370, cityName: 'VERGIATE', countryName: 'ITALY' },
  { placeId: 1948, cityName: 'VERONA', countryName: 'ITALY' },
  { placeId: 1970, cityName: 'VITERBO', countryName: 'ITALY' },
  { placeId: 8870, cityName: 'VOGHERA', countryName: 'ITALY' },
  { placeId: 2301, cityName: 'KINGSTON', countryName: 'JAMAICA' },
  { placeId: 2302, cityName: 'MONTEGO BAY', countryName: 'JAMAICA' },
  { placeId: 7212, cityName: 'NEGRIL', countryName: 'JAMAICA' },
  { placeId: 7356, cityName: 'OCHO RIOS', countryName: 'JAMAICA' },
  { placeId: 7698, cityName: 'PORT ANTONIO, JAMAICA', countryName: 'JAMAICA' },
  { placeId: 4024, cityName: 'AGUNI', countryName: 'JAPAN' },
  { placeId: 4033, cityName: 'AKENO', countryName: 'JAPAN' },
  { placeId: 4035, cityName: 'AKITA', countryName: 'JAPAN' },
  { placeId: 4104, cityName: 'AMAKUSA', countryName: 'JAPAN' },
  { placeId: 4106, cityName: 'AMAMI', countryName: 'JAPAN' },
  { placeId: 2432, cityName: 'AOMORI', countryName: 'JAPAN' },
  { placeId: 4232, cityName: 'ASAHIKAWA', countryName: 'JAPAN' },
  { placeId: 4240, cityName: 'ASHIYA', countryName: 'JAPAN' },
  { placeId: 4271, cityName: 'ATSUGI', countryName: 'JAPAN' },
  { placeId: 9102, cityName: 'CAMP ZAMA', countryName: 'JAPAN' },
  { placeId: 4850, cityName: 'CHOFU', countryName: 'JAPAN' },
  { placeId: 5492, cityName: 'FUKUE', countryName: 'JAPAN' },
  { placeId: 5493, cityName: 'FUKUI', countryName: 'JAPAN' },
  { placeId: 2418, cityName: 'FUKUOKA', countryName: 'JAPAN' },
  { placeId: 5494, cityName: 'FUKUSHIMA', countryName: 'JAPAN' },
  { placeId: 5561, cityName: 'GIFU', countryName: 'JAPAN' },
  { placeId: 5761, cityName: 'HACHIJOJIMA', countryName: 'JAPAN' },
  { placeId: 5762, cityName: 'HACHINOHE', countryName: 'JAPAN' },
  { placeId: 2417, cityName: 'HAKODATE', countryName: 'JAPAN' },
  { placeId: 5781, cityName: 'HAMAMATSU', countryName: 'JAPAN' },
  { placeId: 5787, cityName: 'HANAMAKI', countryName: 'JAPAN' },
  { placeId: 5617, cityName: 'HATERUMA', countryName: 'JAPAN' },
  { placeId: 2427, cityName: 'HIROSHIMA', countryName: 'JAPAN' },
  { placeId: 5853, cityName: 'HIROSHIMANISHI', countryName: 'JAPAN' },
  { placeId: 5858, cityName: 'HOFU', countryName: 'JAPAN' },
  { placeId: 5916, cityName: 'HYAKURI', countryName: 'JAPAN' },
  { placeId: 5929, cityName: 'IE SHIMA I.', countryName: 'JAPAN' },
  { placeId: 5930, cityName: 'IEJIMA', countryName: 'JAPAN' },
  { placeId: 5938, cityName: 'IKISHIMA', countryName: 'JAPAN' },
  { placeId: 5991, cityName: 'IRUMA', countryName: 'JAPAN' },
  { placeId: 5994, cityName: 'ISHIGAKI I', countryName: 'JAPAN' },
  { placeId: 6017, cityName: 'IWAKUNI', countryName: 'JAPAN' },
  { placeId: 6018, cityName: 'IWAMI', countryName: 'JAPAN' },
  { placeId: 6020, cityName: 'IWOTO', countryName: 'JAPAN' },
  { placeId: 6022, cityName: 'IZUMO', countryName: 'JAPAN' },
  { placeId: 2419, cityName: 'KAGOSHIMA', countryName: 'JAPAN' },
  { placeId: 5630, cityName: 'KAMIGOTO', countryName: 'JAPAN' },
  { placeId: 5647, cityName: 'KANOYA', countryName: 'JAPAN' },
  { placeId: 6158, cityName: 'KASUMIGAURA', countryName: 'JAPAN' },
  { placeId: 6201, cityName: 'KERAMA', countryName: 'JAPAN' },
  { placeId: 6223, cityName: 'KIKAI', countryName: 'JAPAN' },
  { placeId: 6263, cityName: 'KISARAZU', countryName: 'JAPAN' },
  { placeId: 6271, cityName: 'KITADAITO I', countryName: 'JAPAN' },
  { placeId: 6272, cityName: 'KITAKYUSHU', countryName: 'JAPAN' },
  { placeId: 6290, cityName: 'KOBE', countryName: 'JAPAN' },
  { placeId: 2429, cityName: 'KOCHI', countryName: 'JAPAN' },
  { placeId: 2425, cityName: 'KOMATSU', countryName: 'JAPAN' },
  { placeId: 6331, cityName: 'KOZUSHIMA', countryName: 'JAPAN' },
  { placeId: 2422, cityName: 'KUMAMOTO', countryName: 'JAPAN' },
  { placeId: 6344, cityName: 'KUMEJIMA', countryName: 'JAPAN' },
  { placeId: 6349, cityName: 'KUSHIRO', countryName: 'JAPAN' },
  { placeId: 6872, cityName: 'MATSUDO', countryName: 'JAPAN' },
  { placeId: 6873, cityName: 'MATSUMOTO', countryName: 'JAPAN' },
  { placeId: 6874, cityName: 'MATSUSHIMA', countryName: 'JAPAN' },
  { placeId: 2430, cityName: 'MATSUYAMA', countryName: 'JAPAN' },
  { placeId: 6933, cityName: 'MEMANBETSU', countryName: 'JAPAN' },
  { placeId: 6956, cityName: 'METABARU', countryName: 'JAPAN' },
  { placeId: 6982, cityName: 'MINAMI TORISHIMA', countryName: 'JAPAN' },
  { placeId: 6983, cityName: 'MINAMIDAITO', countryName: 'JAPAN' },
  { placeId: 6997, cityName: 'MISAWA', countryName: 'JAPAN' },
  { placeId: 6480, cityName: 'MIYAKEJIMA', countryName: 'JAPAN' },
  { placeId: 6481, cityName: 'MIYAKOJIMA', countryName: 'JAPAN' },
  { placeId: 2420, cityName: 'MIYAZAKI', countryName: 'JAPAN' },
  { placeId: 7022, cityName: 'MONBETSU', countryName: 'JAPAN' },
  { placeId: 2423, cityName: 'NAGASAKI', countryName: 'JAPAN' },
  { placeId: 2424, cityName: 'NAGOYA', countryName: 'JAPAN' },
  { placeId: 2442, cityName: 'NAHA', countryName: 'JAPAN' },
  { placeId: 7168, cityName: 'NAKASHIBETSU', countryName: 'JAPAN' },
  { placeId: 7169, cityName: 'NAKATSU', countryName: 'JAPAN' },
  { placeId: 7183, cityName: 'NANKI-SHIRAHAMA', countryName: 'JAPAN' },
  { placeId: 2433, cityName: 'NIIGATA', countryName: 'JAPAN' },
  { placeId: 7258, cityName: 'NIIJIMA', countryName: 'JAPAN' },
  { placeId: 7316, cityName: 'NOTO', countryName: 'JAPAN' },
  { placeId: 7328, cityName: 'NYUTABARU', countryName: 'JAPAN' },
  { placeId: 7347, cityName: 'OBIHIRO', countryName: 'JAPAN' },
  { placeId: 7358, cityName: 'ODATE-NOSHIRO', countryName: 'JAPAN' },
  { placeId: 2421, cityName: 'OITA', countryName: 'JAPAN' },
  { placeId: 7368, cityName: 'OJIKA', countryName: 'JAPAN' },
  { placeId: 2428, cityName: 'OKAYAMA', countryName: 'JAPAN' },
  { placeId: 7370, cityName: 'OKI', countryName: 'JAPAN' },
  { placeId: 7371, cityName: 'OKIERABU I', countryName: 'JAPAN' },
  { placeId: 7292, cityName: 'OKINAWA I', countryName: 'JAPAN' },
  { placeId: 7374, cityName: 'OKUSHIRI', countryName: 'JAPAN' },
  { placeId: 7389, cityName: 'OMINATO', countryName: 'JAPAN' },
  { placeId: 12338, cityName: 'OMURA', countryName: 'JAPAN' },
  { placeId: 2415, cityName: 'OSAKA', countryName: 'JAPAN' },
  { placeId: 7432, cityName: 'OSHIMA', countryName: 'JAPAN' },
  { placeId: 7721, cityName: 'REBUN', countryName: 'JAPAN' },
  { placeId: 7923, cityName: 'RISHIRI', countryName: 'JAPAN' },
  { placeId: 7997, cityName: 'SADO', countryName: 'JAPAN' },
  { placeId: 8001, cityName: 'SAGA', countryName: 'JAPAN' },
  { placeId: 2416, cityName: 'SAPPORO', countryName: 'JAPAN' },
  { placeId: 2434, cityName: 'SENDAI', countryName: 'JAPAN' },
  { placeId: 8235, cityName: 'SHIMOJISHIMA', countryName: 'JAPAN' },
  { placeId: 8236, cityName: 'SHIMONOSEKI', countryName: 'JAPAN' },
  { placeId: 13067, cityName: 'SHIZUOKA', countryName: 'JAPAN' },
  { placeId: 8241, cityName: 'SHONAI', countryName: 'JAPAN' },
  { placeId: 8535, cityName: 'TACHIKAWA', countryName: 'JAPAN' },
  { placeId: 8554, cityName: 'TAJIMA', countryName: 'JAPAN' },
  { placeId: 2431, cityName: 'TAKAMATSU', countryName: 'JAPAN' },
  { placeId: 8577, cityName: 'TANEGASHIMA', countryName: 'JAPAN' },
  { placeId: 8582, cityName: 'TARAMA', countryName: 'JAPAN' },
  { placeId: 8347, cityName: 'TOKACHI', countryName: 'JAPAN' },
  { placeId: 8350, cityName: 'TOKUNOSHIMA', countryName: 'JAPAN' },
  { placeId: 8351, cityName: 'TOKUSHIMA', countryName: 'JAPAN' },
  { placeId: 2414, cityName: 'TOKYO', countryName: 'JAPAN' },
  { placeId: 8686, cityName: 'TOTTORI', countryName: 'JAPAN' },
  { placeId: 2426, cityName: 'TOYAMA', countryName: 'JAPAN' },
  { placeId: 8731, cityName: 'TSUSHIMA', countryName: 'JAPAN' },
  { placeId: 8770, cityName: 'UBE', countryName: 'JAPAN' },
  { placeId: 8807, cityName: 'UTSUNOMIYA', countryName: 'JAPAN' },
  { placeId: 8903, cityName: 'WAKKANAI', countryName: 'JAPAN' },
  { placeId: 9063, cityName: 'YAIZU', countryName: 'JAPAN' },
  { placeId: 9065, cityName: 'YAKUSHIMA', countryName: 'JAPAN' },
  { placeId: 9068, cityName: 'YAMAGATA', countryName: 'JAPAN' },
  { placeId: 9074, cityName: 'YAO', countryName: 'JAPAN' },
  { placeId: 9090, cityName: 'YONAGO', countryName: 'JAPAN' },
  { placeId: 9091, cityName: 'YONAGUNI I', countryName: 'JAPAN' },
  { placeId: 9095, cityName: 'YORON', countryName: 'JAPAN' },
  { placeId: 2364, cityName: 'AMMAN', countryName: 'JORDAN' },
  { placeId: 2365, cityName: 'AQABA', countryName: 'JORDAN' },
  { placeId: 13942, cityName: 'maan', countryName: 'JORDAN' },
  { placeId: 2564, cityName: 'AKTAU', countryName: 'KAZAKHSTAN' },
  { placeId: 2566, cityName: 'AKTOBE', countryName: 'KAZAKHSTAN' },
  { placeId: 2553, cityName: 'ALMATY', countryName: 'KAZAKHSTAN' },
  { placeId: 2554, cityName: 'ASTANA', countryName: 'KAZAKHSTAN' },
  { placeId: 2565, cityName: 'ATYRAU', countryName: 'KAZAKHSTAN' },
  { placeId: 9186, cityName: 'BALKHASH', countryName: 'KAZAKHSTAN' },
  { placeId: 10581, cityName: 'EKIBASTUS', countryName: 'KAZAKHSTAN' },
  { placeId: 2559, cityName: 'KARAGANDA', countryName: 'KAZAKHSTAN' },
  { placeId: 9170, cityName: 'KOKSHETAU', countryName: 'KAZAKHSTAN' },
  { placeId: 2567, cityName: 'KOSTANAY', countryName: 'KAZAKHSTAN' },
  { placeId: 2560, cityName: 'KYZYLORDA', countryName: 'KAZAKHSTAN' },
  { placeId: 2562, cityName: 'PAVLODAR', countryName: 'KAZAKHSTAN' },
  { placeId: 9184, cityName: 'PETROPAVLOVSK', countryName: 'KAZAKHSTAN' },
  { placeId: 2563, cityName: 'SEMEY', countryName: 'KAZAKHSTAN' },
  { placeId: 2558, cityName: 'SHYMKENT', countryName: 'KAZAKHSTAN' },
  { placeId: 13346, cityName: 'TALDYKORGAN', countryName: 'KAZAKHSTAN' },
  { placeId: 2555, cityName: 'TARAZ', countryName: 'KAZAKHSTAN' },
  { placeId: 2561, cityName: 'URALSK', countryName: 'KAZAKHSTAN' },
  { placeId: 2825, cityName: 'UST-KAMENOGORSK', countryName: 'KAZAKHSTAN' },
  { placeId: 9185, cityName: 'YUBILEYNY', countryName: 'KAZAKHSTAN' },
  { placeId: 9183, cityName: 'ZHEZKAZGAN', countryName: 'KAZAKHSTAN' },
  { placeId: 4114, cityName: 'AMBOSELI', countryName: 'KENYA' },
  { placeId: 4633, cityName: 'BUNGOMA', countryName: 'KENYA' },
  { placeId: 4638, cityName: 'BURA EAST', countryName: 'KENYA' },
  { placeId: 4654, cityName: 'BUSIA', countryName: 'KENYA' },
  { placeId: 1212, cityName: 'ELDORET', countryName: 'KENYA' },
  { placeId: 5104, cityName: 'ELIYE SPRINGS', countryName: 'KENYA' },
  { placeId: 5313, cityName: 'EMBU', countryName: 'KENYA' },
  { placeId: 5528, cityName: 'GARBA TULA', countryName: 'KENYA' },
  { placeId: 5534, cityName: 'GARISSA', countryName: 'KENYA' },
  { placeId: 5860, cityName: 'HOLA', countryName: 'KENYA' },
  { placeId: 5870, cityName: 'HOMA BAY', countryName: 'KENYA' },
  { placeId: 5996, cityName: 'ISIOLO', countryName: 'KENYA' },
  { placeId: 6112, cityName: 'KAKAMEGA', countryName: 'KENYA' },
  { placeId: 6123, cityName: 'KALOKOL', countryName: 'KENYA' },
  { placeId: 6181, cityName: 'KEEKOROK', countryName: 'KENYA' },
  { placeId: 5041, cityName: 'KERICHO', countryName: 'KENYA' },
  { placeId: 6227, cityName: 'KILAGUNI', countryName: 'KENYA' },
  { placeId: 6266, cityName: 'KISII', countryName: 'KENYA' },
  { placeId: 6269, cityName: 'KISUMU', countryName: 'KENYA' },
  { placeId: 6273, cityName: 'KITALE', countryName: 'KENYA' },
  { placeId: 6424, cityName: 'LAMU/MANDA', countryName: 'KENYA' },
  { placeId: 6595, cityName: 'LODWAR', countryName: 'KENYA' },
  { placeId: 6599, cityName: 'LOITOKITOK', countryName: 'KENYA' },
  { placeId: 6600, cityName: 'LOKICHOGIO', countryName: 'KENYA' },
  { placeId: 6601, cityName: 'LOKITAUNG', countryName: 'KENYA' },
  { placeId: 6636, cityName: 'LOYANGALANI', countryName: 'KENYA' },
  { placeId: 6479, cityName: 'MACKINNON ROAD', countryName: 'KENYA' },
  { placeId: 6705, cityName: 'MAGADI', countryName: 'KENYA' },
  { placeId: 6724, cityName: 'MAKINDU', countryName: 'KENYA' },
  { placeId: 2829, cityName: 'MALINDI', countryName: 'KENYA' },
  { placeId: 6754, cityName: 'MANDERA', countryName: 'KENYA' },
  { placeId: 6797, cityName: 'MARALAL', countryName: 'KENYA' },
  { placeId: 6832, cityName: 'MARSABIT', countryName: 'KENYA' },
  { placeId: 6952, cityName: 'MERU', countryName: 'KENYA' },
  { placeId: 1214, cityName: 'MOMBASA', countryName: 'KENYA' },
  { placeId: 7100, cityName: 'MOYALE LOWER', countryName: 'KENYA' },
  { placeId: 7112, cityName: 'MTITO ANDEI', countryName: 'KENYA' },
  { placeId: 1213, cityName: 'NAIROBI', countryName: 'KENYA' },
  { placeId: 7167, cityName: 'NAIVASHA', countryName: 'KENYA' },
  { placeId: 7173, cityName: 'NAKURU', countryName: 'KENYA' },
  { placeId: 7184, cityName: 'NANYUKI', countryName: 'KENYA' },
  { placeId: 7191, cityName: 'NAROK', countryName: 'KENYA' },
  { placeId: 7324, cityName: 'NYERI', countryName: 'KENYA' },
  { placeId: 8023, cityName: 'SAMBURU SOUTH', countryName: 'KENYA' },
  { placeId: 8872, cityName: 'VOI', countryName: 'KENYA' },
  { placeId: 8899, cityName: 'WAJIR', countryName: 'KENYA' },
  { placeId: 9998, cityName: 'BUTARITARI', countryName: 'KIRIBATI' },
  { placeId: 10057, cityName: 'CANTON I', countryName: 'KIRIBATI' },
  { placeId: 3172, cityName: 'CHRISTMAS I', countryName: 'KIRIBATI' },
  { placeId: 12422, cityName: 'PALMYRA IS', countryName: 'KIRIBATI' },
  { placeId: 13366, cityName: 'TARAWA I', countryName: 'KIRIBATI' },
  { placeId: 7814, cityName: 'PYONGYANG', countryName: 'KOREA, DPR OF' },
  { placeId: 2437, cityName: 'BUSAN', countryName: 'KOREA' },
  { placeId: 7813, cityName: 'CAMP HUMPHREYS', countryName: 'KOREA' },
  { placeId: 2441, cityName: 'CHEONGJU', countryName: 'KOREA' },
  { placeId: 4836, cityName: 'CHINHAE', countryName: 'KOREA' },
  { placeId: 4855, cityName: 'CHOONGWON', countryName: 'KOREA' },
  { placeId: 4859, cityName: 'CHUNCHON', countryName: 'KOREA' },
  { placeId: 2440, cityName: 'DAEGU', countryName: 'KOREA' },
  { placeId: 5524, cityName: 'GANGNEUNG', countryName: 'KOREA' },
  { placeId: 5746, cityName: 'GUNSAN', countryName: 'KOREA' },
  { placeId: 5755, cityName: 'GWANGJU', countryName: 'KOREA' },
  { placeId: 2436, cityName: 'JEJU', countryName: 'KOREA' },
  { placeId: 6060, cityName: 'JHUNJU', countryName: 'KOREA' },
  { placeId: 5638, cityName: 'KANAM-NI', countryName: 'KOREA' },
  { placeId: 7012, cityName: 'MOKPO', countryName: 'KOREA' },
  { placeId: 7115, cityName: 'MUAN', countryName: 'KOREA' },
  { placeId: 7430, cityName: 'OSAN', countryName: 'KOREA' },
  { placeId: 7682, cityName: 'POCHUN', countryName: 'KOREA' },
  { placeId: 7686, cityName: 'POHANG', countryName: 'KOREA' },
  { placeId: 7996, cityName: 'SACHEON', countryName: 'KOREA' },
  { placeId: 8189, cityName: 'SEOSAN', countryName: 'KOREA' },
  { placeId: 2439, cityName: 'SEOUL', countryName: 'KOREA' },
  { placeId: 2438, cityName: 'SEOUL/INCHEON', countryName: 'KOREA' },
  { placeId: 8526, cityName: 'SUSAEK', countryName: 'KOREA' },
  { placeId: 8330, cityName: 'SUWON', countryName: 'KOREA' },
  { placeId: 13534, cityName: 'ULJIN', countryName: 'KOREA' },
  { placeId: 8782, cityName: 'ULSAN', countryName: 'KOREA' },
  { placeId: 9046, cityName: 'WONJU', countryName: 'KOREA' },
  { placeId: 2435, cityName: 'YANGYANG', countryName: 'KOREA' },
  { placeId: 9081, cityName: 'YECHEON', countryName: 'KOREA' },
  { placeId: 9088, cityName: 'YEOSU', countryName: 'KOREA' },
  { placeId: 4077, cityName: 'ALI AL SALEM', countryName: 'KUWAIT' },
  { placeId: 2366, cityName: 'KUWAIT', countryName: 'KUWAIT' },
  { placeId: 8773, cityName: 'UDAIRI', countryName: 'KUWAIT' },
  { placeId: 2556, cityName: 'BISHKEK', countryName: 'KYRGYZSTAN' },
  { placeId: 2557, cityName: 'OSH', countryName: 'KYRGYZSTAN' },
  { placeId: 2843, cityName: 'TAMCHY', countryName: 'KYRGYZSTAN' },
  { placeId: 4273, cityName: 'ATTOPEU', countryName: 'LAOS' },
  { placeId: 4321, cityName: 'BAN HOUEISAY', countryName: 'LAOS' },
  { placeId: 3987, cityName: 'CHAMPASACK', countryName: 'LAOS' },
  { placeId: 6637, cityName: 'LUANG NAMTHA', countryName: 'LAOS' },
  { placeId: 6638, cityName: 'LUANG PHABANG', countryName: 'LAOS' },
  { placeId: 7441, cityName: 'OUDOMSAY', countryName: 'LAOS' },
  { placeId: 8020, cityName: 'SAM NEUA', countryName: 'LAOS' },
  { placeId: 8117, cityName: 'SARAVANE', countryName: 'LAOS' },
  { placeId: 8134, cityName: 'SAVANNAKHET', countryName: 'LAOS' },
  { placeId: 8138, cityName: 'SAYABOULY', countryName: 'LAOS' },
  { placeId: 8632, cityName: 'THAKHEK', countryName: 'LAOS' },
  { placeId: 2688, cityName: 'VIENTIANE', countryName: 'LAOS' },
  { placeId: 9059, cityName: 'XIENG KHOUANG', countryName: 'LAOS' },
  { placeId: 9495, cityName: 'ADAZI', countryName: 'LATVIA' },
  { placeId: 4780, cityName: 'CESIS', countryName: 'LATVIA' },
  { placeId: 10425, cityName: 'DAUGAVPILS', countryName: 'LATVIA' },
  { placeId: 5939, cityName: 'IKSHKILE', countryName: 'LATVIA' },
  { placeId: 1092, cityName: 'LIEPAJA', countryName: 'LATVIA' },
  { placeId: 11693, cityName: 'LIMBAZI', countryName: 'LATVIA' },
  { placeId: 1093, cityName: 'RIGA', countryName: 'LATVIA' },
  { placeId: 8736, cityName: 'TUKUMS', countryName: 'LATVIA' },
  { placeId: 8366, cityName: 'VENTSPILS', countryName: 'LATVIA' },
  { placeId: 2367, cityName: 'BEIRUT', countryName: 'LEBANON' },
  { placeId: 6284, cityName: 'KLEYATE', countryName: 'LEBANON' },
  { placeId: 7872, cityName: 'RAYAK', countryName: 'LEBANON' },
  { placeId: 6165, cityName: 'KATSE', countryName: 'LESOTHO' },
  { placeId: 6531, cityName: 'LERIBE', countryName: 'LESOTHO' },
  { placeId: 6535, cityName: 'LETSENG', countryName: 'LESOTHO' },
  { placeId: 6702, cityName: 'MAFETENG', countryName: 'LESOTHO' },
  { placeId: 6849, cityName: 'MASERU', countryName: 'LESOTHO' },
  { placeId: 7011, cityName: 'MOKHOTLONG', countryName: 'LESOTHO' },
  { placeId: 7815, cityName: 'QACHA\'S NEK', countryName: 'LESOTHO' },
  { placeId: 7536, cityName: 'SEHONGHONG', countryName: 'LESOTHO' },
  { placeId: 8159, cityName: 'SEMONKONG', countryName: 'LESOTHO' },
  { placeId: 4184, cityName: 'BUCHANAN', countryName: 'LIBERIA' },
  { placeId: 4185, cityName: 'BUCKANAN', countryName: 'LIBERIA' },
  { placeId: 5706, cityName: 'GREENVILLE', countryName: 'LIBERIA' },
  { placeId: 5805, cityName: 'HARPER', countryName: 'LIBERIA' },
  { placeId: 1181, cityName: 'MONROVIA', countryName: 'LIBERIA' },
  { placeId: 7260, cityName: 'NIMBA', countryName: 'LIBERIA' },
  { placeId: 8599, cityName: 'TCHIEN', countryName: 'LIBERIA' },
  { placeId: 4019, cityName: 'AGEDABIA', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 4105, cityName: 'AMAL V12', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 4394, cityName: 'BEDA M3', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 1215, cityName: 'BENGHAZI', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 4616, cityName: 'BU-ATTIFL A100', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5048, cityName: 'DAHRA WH32', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5264, cityName: 'EDDIB V7', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5279, cityName: 'EL BEIDA', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5332, cityName: 'ESSIDER OJ', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5553, cityName: 'GHADAMES', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5555, cityName: 'GHAT', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5559, cityName: 'GIALO WH59E', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5778, cityName: 'HAMADA NC5', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5779, cityName: 'HAMADA NC8', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 5876, cityName: 'HON', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 6338, cityName: 'KUFRA', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 6831, cityName: 'MARSA BREGA S21', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 6955, cityName: 'MESSLA 5ALV', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 6500, cityName: 'NAFOORA M4', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 7461, cityName: 'OXY 103A', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 7858, cityName: 'RASLANUF V40', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 8002, cityName: 'SAHIL', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 8121, cityName: 'SARIR C4/5ALZ', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 7526, cityName: 'SEBHA', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 8279, cityName: 'SIRTE', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 8603, cityName: 'TEBISTY V9', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 1216, cityName: 'TRIPOLI', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 8890, cityName: 'WAHA WH59A', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 9116, cityName: 'ZELLA Z74', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 9117, cityName: 'ZELTEN S22', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 9128, cityName: 'ZUEITINA OXYL', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 9132, cityName: 'ZWARA', countryName: 'LIBYA, SPA JAMAHIRIYA' },
  { placeId: 9979, cityName: 'BURDI', countryName: 'LIBYAN ARAB JAMAHIRIYA' },
  { placeId: 12010, cityName: 'MISURATA', countryName: 'LIBYAN ARAB JAMAHIRIYA' },
  { placeId: 13036, cityName: 'SHARARA', countryName: 'LIBYAN ARAB JAMAHIRIYA' },
  { placeId: 13530, cityName: 'UBARI', countryName: 'LIBYAN ARAB JAMAHIRIYA' },
  { placeId: 13635, cityName: 'WAFA', countryName: 'LIBYAN ARAB JAMAHIRIYA' },
  { placeId: 4038, cityName: 'AKMENE', countryName: 'LITHUANIA' },
  { placeId: 4101, cityName: 'ALYTUS', countryName: 'LITHUANIA' },
  { placeId: 4363, cityName: 'BARYSIAI', countryName: 'LITHUANIA' },
  { placeId: 4488, cityName: 'BIRZAI', countryName: 'LITHUANIA' },
  { placeId: 5211, cityName: 'DRUSKININKAI', countryName: 'LITHUANIA' },
  { placeId: 5934, cityName: 'IGNALINA', countryName: 'LITHUANIA' },
  { placeId: 6143, cityName: 'KARTENA', countryName: 'LITHUANIA' },
  { placeId: 6172, cityName: 'KAUNAS', countryName: 'LITHUANIA' },
  { placeId: 6179, cityName: 'KEDAINIAI', countryName: 'LITHUANIA' },
  { placeId: 6277, cityName: 'KLAIPEDA', countryName: 'LITHUANIA' },
  { placeId: 6355, cityName: 'KYVISKES', countryName: 'LITHUANIA' },
  { placeId: 7014, cityName: 'MOLETAI', countryName: 'LITHUANIA' },
  { placeId: 1094, cityName: 'PALANGA', countryName: 'LITHUANIA' },
  { placeId: 7540, cityName: 'PALUKNYS', countryName: 'LITHUANIA' },
  { placeId: 7546, cityName: 'PANEVEZYS', countryName: 'LITHUANIA' },
  { placeId: 7683, cityName: 'POCIUNAI', countryName: 'LITHUANIA' },
  { placeId: 7951, cityName: 'ROJUNAI', countryName: 'LITHUANIA' },
  { placeId: 7975, cityName: 'RUDISKES', countryName: 'LITHUANIA' },
  { placeId: 8124, cityName: 'SASNAVA', countryName: 'LITHUANIA' },
  { placeId: 7531, cityName: 'SEDUVA', countryName: 'LITHUANIA' },
  { placeId: 8246, cityName: 'SIAULIAI', countryName: 'LITHUANIA' },
  { placeId: 8259, cityName: 'SILUTE', countryName: 'LITHUANIA' },
  { placeId: 13374, cityName: 'TAURAGE', countryName: 'LITHUANIA' },
  { placeId: 8613, cityName: 'TELSIAI', countryName: 'LITHUANIA' },
  { placeId: 8673, cityName: 'TIRKSLIAI', countryName: 'LITHUANIA' },
  { placeId: 8804, cityName: 'UTENA', countryName: 'LITHUANIA' },
  { placeId: 1095, cityName: 'VILNIUS', countryName: 'LITHUANIA' },
  { placeId: 9109, cityName: 'ZARASAI', countryName: 'LITHUANIA' },
  { placeId: 858, cityName: 'LUXEMBOURG', countryName: 'LUXEMBOURG' },
  { placeId: 7273, cityName: 'NOERTRANGE', countryName: 'LUXEMBOURG' },
  { placeId: 2689, cityName: 'MACAO', countryName: 'MACAO, PR OF CHINA' },
  { placeId: 2269, cityName: 'OHRID', countryName: 'MACEDONIA, FORMER YUGOSLAV REP' },
  { placeId: 2270, cityName: 'SKOPJE', countryName: 'MACEDONIA, FORMER YUGOSLAV REP' },
  { placeId: 4108, cityName: 'AMBALAVAO', countryName: 'MADAGASCAR' },
  { placeId: 4109, cityName: 'AMBANJA', countryName: 'MADAGASCAR' },
  { placeId: 4111, cityName: 'AMBATONDRAZAKA', countryName: 'MADAGASCAR' },
  { placeId: 4112, cityName: 'AMBILOBE', countryName: 'MADAGASCAR' },
  { placeId: 4122, cityName: 'AMPANIHY', countryName: 'MADAGASCAR' },
  { placeId: 4127, cityName: 'ANALALAVA', countryName: 'MADAGASCAR' },
  { placeId: 4130, cityName: 'ANDAPA', countryName: 'MADAGASCAR' },
  { placeId: 4151, cityName: 'ANKAVANDRA', countryName: 'MADAGASCAR' },
  { placeId: 4152, cityName: 'ANKAZOBE', countryName: 'MADAGASCAR' },
  { placeId: 4160, cityName: 'ANTALAHA', countryName: 'MADAGASCAR' },
  { placeId: 1137, cityName: 'ANTANANARIVO', countryName: 'MADAGASCAR' },
  { placeId: 4164, cityName: 'ANTSALOVA', countryName: 'MADAGASCAR' },
  { placeId: 4165, cityName: 'ANTSIRABE', countryName: 'MADAGASCAR' },
  { placeId: 1139, cityName: 'ANTSIRANANA', countryName: 'MADAGASCAR' },
  { placeId: 4166, cityName: 'ANTSOHIHY', countryName: 'MADAGASCAR' },
  { placeId: 4400, cityName: 'BEFANDRIANA', countryName: 'MADAGASCAR' },
  { placeId: 4405, cityName: 'BEKILY', countryName: 'MADAGASCAR' },
  { placeId: 4419, cityName: 'BELO', countryName: 'MADAGASCAR' },
  { placeId: 4442, cityName: 'BEROROHA', countryName: 'MADAGASCAR' },
  { placeId: 4445, cityName: 'BESALAMPY', countryName: 'MADAGASCAR' },
  { placeId: 5367, cityName: 'FARAFANGANA', countryName: 'MADAGASCAR' },
  { placeId: 5392, cityName: 'FIANARANTSOA', countryName: 'MADAGASCAR' },
  { placeId: 5935, cityName: 'IHOSY', countryName: 'MADAGASCAR' },
  { placeId: 5940, cityName: 'ILAKA', countryName: 'MADAGASCAR' },
  { placeId: 1140, cityName: 'MAHAJANGA', countryName: 'MADAGASCAR' },
  { placeId: 6712, cityName: 'MAHANORO', countryName: 'MADAGASCAR' },
  { placeId: 6718, cityName: 'MAINTIRANO', countryName: 'MADAGASCAR' },
  { placeId: 6731, cityName: 'MALAIMBANDY', countryName: 'MADAGASCAR' },
  { placeId: 6746, cityName: 'MANAKARA', countryName: 'MADAGASCAR' },
  { placeId: 6747, cityName: 'MANANARA', countryName: 'MADAGASCAR' },
  { placeId: 6749, cityName: 'MANANJARY', countryName: 'MADAGASCAR' },
  { placeId: 6753, cityName: 'MANDABE', countryName: 'MADAGASCAR' },
  { placeId: 6755, cityName: 'MANDRITSARA', countryName: 'MADAGASCAR' },
  { placeId: 6771, cityName: 'MANJA', countryName: 'MADAGASCAR' },
  { placeId: 6828, cityName: 'MAROANTSETRA', countryName: 'MADAGASCAR' },
  { placeId: 6964, cityName: 'MIANDRIVAZO', countryName: 'MADAGASCAR' },
  { placeId: 7067, cityName: 'MORAFENOBE', countryName: 'MADAGASCAR' },
  { placeId: 12066, cityName: 'MORAMANGA', countryName: 'MADAGASCAR' },
  { placeId: 7074, cityName: 'MOROMBE', countryName: 'MADAGASCAR' },
  { placeId: 7075, cityName: 'MORONDAVA', countryName: 'MADAGASCAR' },
  { placeId: 1141, cityName: 'NOSY-BE', countryName: 'MADAGASCAR' },
  { placeId: 7700, cityName: 'PORT BERGE', countryName: 'MADAGASCAR' },
  { placeId: 7516, cityName: 'SAINTE-MARIE', countryName: 'MADAGASCAR' },
  { placeId: 8022, cityName: 'SAMBAVA', countryName: 'MADAGASCAR' },
  { placeId: 8301, cityName: 'SOALALA', countryName: 'MADAGASCAR' },
  { placeId: 8568, cityName: 'TAMBOHORANO', countryName: 'MADAGASCAR' },
  { placeId: 8573, cityName: 'TANANDAVA', countryName: 'MADAGASCAR' },
  { placeId: 1138, cityName: 'TOAMASINA', countryName: 'MADAGASCAR' },
  { placeId: 1142, cityName: 'TOLAGNARO', countryName: 'MADAGASCAR' },
  { placeId: 1143, cityName: 'TOLIARY', countryName: 'MADAGASCAR' },
  { placeId: 8717, cityName: 'TSARATANANA', countryName: 'MADAGASCAR' },
  { placeId: 8728, cityName: 'TSIROANOMANDIDY', countryName: 'MADAGASCAR' },
  { placeId: 8827, cityName: 'VANGAINDRANO', countryName: 'MADAGASCAR' },
  { placeId: 8829, cityName: 'VATOMANDRY', countryName: 'MADAGASCAR' },
  { placeId: 8871, cityName: 'VOHIMARINA', countryName: 'MADAGASCAR' },
  { placeId: 4333, cityName: 'BANGULA', countryName: 'MALAWI' },
  { placeId: 1164, cityName: 'BLANTYRE', countryName: 'MALAWI' },
  { placeId: 4803, cityName: 'CHELINDA', countryName: 'MALAWI' },
  { placeId: 4829, cityName: 'CHILUMBA', countryName: 'MALAWI' },
  { placeId: 4844, cityName: 'CHITIPA', countryName: 'MALAWI' },
  { placeId: 4905, cityName: 'CLUB MAKOKOLA', countryName: 'MALAWI' },
  { placeId: 5230, cityName: 'DWANGWA', countryName: 'MALAWI' },
  { placeId: 6141, cityName: 'KARONGA', countryName: 'MALAWI' },
  { placeId: 6159, cityName: 'KASUNGU', countryName: 'MALAWI' },
  { placeId: 6170, cityName: 'KATUMBI', countryName: 'MALAWI' },
  { placeId: 6553, cityName: 'LIKOMA', countryName: 'MALAWI' },
  { placeId: 1165, cityName: 'LILONGWE', countryName: 'MALAWI' },
  { placeId: 6759, cityName: 'MANGOCHI', countryName: 'MALAWI' },
  { placeId: 6911, cityName: 'MCHINJI', countryName: 'MALAWI' },
  { placeId: 7029, cityName: 'MONKEY BAY', countryName: 'MALAWI' },
  { placeId: 7160, cityName: 'MZIMBA', countryName: 'MALAWI' },
  { placeId: 7161, cityName: 'MZUZU', countryName: 'MALAWI' },
  { placeId: 7203, cityName: 'NCHALO', countryName: 'MALAWI' },
  { placeId: 7270, cityName: 'NKHOTAKOTA', countryName: 'MALAWI' },
  { placeId: 6505, cityName: 'NSANJE', countryName: 'MALAWI' },
  { placeId: 6506, cityName: 'NTCHISI', countryName: 'MALAWI' },
  { placeId: 8008, cityName: 'SALIMA', countryName: 'MALAWI' },
  { placeId: 2745, cityName: 'ALOR STAR', countryName: 'MALAYSIA' },
  { placeId: 4348, cityName: 'BARIO', countryName: 'MALAYSIA' },
  { placeId: 2731, cityName: 'BINTULU', countryName: 'MALAYSIA' },
  { placeId: 4660, cityName: 'BUTTERWORTH', countryName: 'MALAYSIA' },
  { placeId: 5602, cityName: 'GONG KEDAK', countryName: 'MALAYSIA' },
  { placeId: 2749, cityName: 'IPOH', countryName: 'MALAYSIA' },
  { placeId: 2750, cityName: 'JOHOR BAHRU', countryName: 'MALAYSIA' },
  { placeId: 6198, cityName: 'KENINGUA, MALAYSIA', countryName: 'MALAYSIA' },
  { placeId: 2748, cityName: 'KERTEH', countryName: 'MALAYSIA' },
  { placeId: 2743, cityName: 'KLUANG', countryName: 'MALAYSIA' },
  { placeId: 2746, cityName: 'KOTA BHARU', countryName: 'MALAYSIA' },
  { placeId: 2735, cityName: 'KOTA KINABALU', countryName: 'MALAYSIA' },
  { placeId: 2751, cityName: 'KUALA LUMPUR', countryName: 'MALAYSIA' },
  { placeId: 2754, cityName: 'KUALA TERENGGANU', countryName: 'MALAYSIA' },
  { placeId: 2747, cityName: 'KUANTAN', countryName: 'MALAYSIA' },
  { placeId: 2732, cityName: 'KUCHING', countryName: 'MALAYSIA' },
  { placeId: 2736, cityName: 'LABUAN I', countryName: 'MALAYSIA' },
  { placeId: 6406, cityName: 'LAHAD DATU', countryName: 'MALAYSIA' },
  { placeId: 2752, cityName: 'LANGKAWI I.', countryName: 'MALAYSIA' },
  { placeId: 6456, cityName: 'LAWAS', countryName: 'MALAYSIA' },
  { placeId: 6559, cityName: 'LIMBANG', countryName: 'MALAYSIA' },
  { placeId: 6609, cityName: 'LONG AKAH', countryName: 'MALAYSIA' },
  { placeId: 2753, cityName: 'MALACCA', countryName: 'MALAYSIA' },
  { placeId: 6842, cityName: 'MARUDI', countryName: 'MALAYSIA' },
  { placeId: 2733, cityName: 'MIRI', countryName: 'MALAYSIA' },
  { placeId: 7122, cityName: 'MUKAH', countryName: 'MALAYSIA' },
  { placeId: 7130, cityName: 'MULU', countryName: 'MALAYSIA' },
  { placeId: 2755, cityName: 'PENANG I', countryName: 'MALAYSIA' },
  { placeId: 7800, cityName: 'PULAU REDANG', countryName: 'MALAYSIA' },
  { placeId: 2744, cityName: 'PULAU TIOMAN', countryName: 'MALAYSIA' },
  { placeId: 2737, cityName: 'SANDAKAN', countryName: 'MALAYSIA' },
  { placeId: 2734, cityName: 'SIBU', countryName: 'MALAYSIA' },
  { placeId: 8551, cityName: 'TAIPING', countryName: 'MALAYSIA' },
  { placeId: 2738, cityName: 'TAWAU', countryName: 'MALAYSIA' },
  { placeId: 2697, cityName: 'GAN ISLAND', countryName: 'MALDIVES' },
  { placeId: 5790, cityName: 'HANIMAADHOO ISLAND', countryName: 'MALDIVES' },
  { placeId: 6089, cityName: 'KAADEDHDHOO ISLAND', countryName: 'MALDIVES' },
  { placeId: 6097, cityName: 'KADHDHOO ISLAND', countryName: 'MALDIVES' },
  { placeId: 13902, cityName: 'MAAMIGILI ISLAND', countryName: 'MALDIVES' },
  { placeId: 2698, cityName: 'MALE', countryName: 'MALDIVES' },
  { placeId: 4158, cityName: 'ANSONGO', countryName: 'MALI' },
  { placeId: 4290, cityName: 'BAFOULABE', countryName: 'MALI' },
  { placeId: 1171, cityName: 'BAMAKO', countryName: 'MALI' },
  { placeId: 4326, cityName: 'BANDIAGARA', countryName: 'MALI' },
  { placeId: 4565, cityName: 'BOUGOUNI', countryName: 'MALI' },
  { placeId: 4572, cityName: 'BOUREM', countryName: 'MALI' },
  { placeId: 5205, cityName: 'DOUENTZA', countryName: 'MALI' },
  { placeId: 5527, cityName: 'GAO', countryName: 'MALI' },
  { placeId: 5604, cityName: 'GOUNDAM', countryName: 'MALI' },
  { placeId: 6176, cityName: 'KAYES', countryName: 'MALI' },
  { placeId: 6197, cityName: 'KENIEBA', countryName: 'MALI' },
  { placeId: 6218, cityName: 'KIDAL', countryName: 'MALI' },
  { placeId: 6270, cityName: 'KITA', countryName: 'MALI' },
  { placeId: 6310, cityName: 'KOLOKANI', countryName: 'MALI' },
  { placeId: 6330, cityName: 'KOUTIALA', countryName: 'MALI' },
  { placeId: 6824, cityName: 'MARKALA', countryName: 'MALI' },
  { placeId: 6935, cityName: 'MENAKA', countryName: 'MALI' },
  { placeId: 2853, cityName: 'MOPTI', countryName: 'MALI' },
  { placeId: 7189, cityName: 'NARA', countryName: 'MALI' },
  { placeId: 7256, cityName: 'NIAFUNKE', countryName: 'MALI' },
  { placeId: 7263, cityName: 'NIORO', countryName: 'MALI' },
  { placeId: 8256, cityName: 'SIKASSO', countryName: 'MALI' },
  { placeId: 8627, cityName: 'TESSALIT', countryName: 'MALI' },
  { placeId: 8356, cityName: 'TOMBOUCTOU', countryName: 'MALI' },
  { placeId: 9083, cityName: 'YELIMANE', countryName: 'MALI' },
  { placeId: 2067, cityName: 'MALTA', countryName: 'MALTA' },
  { placeId: 10409, cityName: 'DALAP', countryName: 'MARSHALL ISLANDS' },
  { placeId: 10624, cityName: 'ENEWETAK ATOLL', countryName: 'MARSHALL ISLANDS' },
  { placeId: 11549, cityName: 'KWAJALEIN ATOLL', countryName: 'MARSHALL ISLANDS' },
  { placeId: 12841, cityName: 'ROI-NAMUR', countryName: 'MARSHALL ISLANDS' },
  { placeId: 2539, cityName: 'MARTINIQUE', countryName: 'MARTINIQUE' },
  { placeId: 4028, cityName: 'AIOUN-EL-ATROUSS', countryName: 'MAURITANIA' },
  { placeId: 4036, cityName: 'AKJOUJT', countryName: 'MAURITANIA' },
  { placeId: 4255, cityName: 'ATAR', countryName: 'MAURITANIA' },
  { placeId: 4479, cityName: 'BIR MOGHREIN', countryName: 'MAURITANIA' },
  { placeId: 2934, cityName: 'BOGUE', countryName: 'MAURITANIA' },
  { placeId: 4574, cityName: 'BOUTILIMIT', countryName: 'MAURITANIA' },
  { placeId: 5347, cityName: 'F\'DERIK', countryName: 'MAURITANIA' },
  { placeId: 6100, cityName: 'KAEDI', countryName: 'MAURITANIA' },
  { placeId: 6220, cityName: 'KIFFA', countryName: 'MAURITANIA' },
  { placeId: 7088, cityName: 'MOUDJERIA', countryName: 'MAURITANIA' },
  { placeId: 7221, cityName: 'NEMA', countryName: 'MAURITANIA' },
  { placeId: 1197, cityName: 'NOUADHIBOU', countryName: 'MAURITANIA' },
  { placeId: 1196, cityName: 'NOUAKCHOTT', countryName: 'MAURITANIA' },
  { placeId: 8151, cityName: 'SELIBABI', countryName: 'MAURITANIA' },
  { placeId: 8569, cityName: 'TAMCHAKETT', countryName: 'MAURITANIA' },
  { placeId: 8658, cityName: 'TICHIT', countryName: 'MAURITANIA' },
  { placeId: 8660, cityName: 'TIDJIKJA', countryName: 'MAURITANIA' },
  { placeId: 8667, cityName: 'TIMBEDRA', countryName: 'MAURITANIA' },
  { placeId: 9127, cityName: 'ZOUERATT', countryName: 'MAURITANIA' },
  { placeId: 1129, cityName: 'MAURITIUS', countryName: 'MAURITIUS' },
  { placeId: 1130, cityName: 'RODRIGUES', countryName: 'MAURITIUS' },
  { placeId: 1135, cityName: 'DZAOUDZI', countryName: 'MAYOTTE' },
  { placeId: 1179, cityName: 'MELILLA', countryName: 'MELILLA' },
  { placeId: 2303, cityName: 'ACAPULCO', countryName: 'MEXICO' },
  { placeId: 9500, cityName: 'AGUALEGUAS', countryName: 'MEXICO' },
  { placeId: 9501, cityName: 'AGUASCALIENTES', countryName: 'MEXICO' },
  { placeId: 9595, cityName: 'APATZINGAN', countryName: 'MEXICO' },
  { placeId: 9648, cityName: 'ATIZAPAN DE ZARAGOZA', countryName: 'MEXICO' },
  { placeId: 9690, cityName: 'BAHIAS DE HUATULCO', countryName: 'MEXICO' },
  { placeId: 10003, cityName: 'CABO SAN LUCAS', countryName: 'MEXICO' },
  { placeId: 10043, cityName: 'CAMPECHE', countryName: 'MEXICO' },
  { placeId: 2310, cityName: 'CANCUN', countryName: 'MEXICO' },
  { placeId: 10110, cityName: 'CELAYA', countryName: 'MEXICO' },
  { placeId: 10176, cityName: 'CHETUMAL', countryName: 'MEXICO' },
  { placeId: 10188, cityName: 'CHICHEN-ITZA', countryName: 'MEXICO' },
  { placeId: 10192, cityName: 'CHIHUAHUA', countryName: 'MEXICO' },
  { placeId: 10231, cityName: 'CIUDAD ACUNA', countryName: 'MEXICO' },
  { placeId: 10232, cityName: 'CIUDAD CONSTITUCION', countryName: 'MEXICO' },
  { placeId: 10233, cityName: 'CIUDAD DEL CARMEN', countryName: 'MEXICO' },
  { placeId: 10234, cityName: 'CIUDAD JUAREZ', countryName: 'MEXICO' },
  { placeId: 10235, cityName: 'CIUDAD OBREGON', countryName: 'MEXICO' },
  { placeId: 10236, cityName: 'CIUDAD VICTORIA', countryName: 'MEXICO' },
  { placeId: 10297, cityName: 'COLIMA', countryName: 'MEXICO' },
  { placeId: 10317, cityName: 'COMITAN', countryName: 'MEXICO' },
  { placeId: 10362, cityName: 'COZUMEL', countryName: 'MEXICO' },
  { placeId: 10387, cityName: 'CUERNAVACA', countryName: 'MEXICO' },
  { placeId: 10389, cityName: 'CULIACAN', countryName: 'MEXICO' },
  { placeId: 10536, cityName: 'DURANGO', countryName: 'MEXICO' },
  { placeId: 10630, cityName: 'ENSENADA', countryName: 'MEXICO' },
  { placeId: 11011, cityName: 'GUADALAJARA', countryName: 'MEXICO' },
  { placeId: 9475, cityName: 'GUANAJUATO', countryName: 'MEXICO' },
  { placeId: 11016, cityName: 'GUAYMAS', countryName: 'MEXICO' },
  { placeId: 11019, cityName: 'GUERRERO NEGRO', countryName: 'MEXICO' },
  { placeId: 11130, cityName: 'HERMOSILLO', countryName: 'MEXICO' },
  { placeId: 11280, cityName: 'ISLA MUJERES', countryName: 'MEXICO' },
  { placeId: 11286, cityName: 'IXTAPA-ZIHUATANEJO', countryName: 'MEXICO' },
  { placeId: 11306, cityName: 'JALAPA', countryName: 'MEXICO' },
  { placeId: 11564, cityName: 'LA PAZ', countryName: 'MEXICO' },
  { placeId: 11646, cityName: 'LAZARO CARDENAS', countryName: 'MEXICO' },
  { placeId: 11739, cityName: 'LORETO', countryName: 'MEXICO' },
  { placeId: 11744, cityName: 'LOS MOCHIS', countryName: 'MEXICO' },
  { placeId: 11838, cityName: 'MANZANILLO', countryName: 'MEXICO' },
  { placeId: 11898, cityName: 'MATAMOROS', countryName: 'MEXICO' },
  { placeId: 11910, cityName: 'MAZATLAN', countryName: 'MEXICO' },
  { placeId: 11955, cityName: 'MERIDA', countryName: 'MEXICO' },
  { placeId: 11966, cityName: 'MEXICALI', countryName: 'MEXICO' },
  { placeId: 2305, cityName: 'MEXICO CITY', countryName: 'MEXICO' },
  { placeId: 11994, cityName: 'MINATITLAN', countryName: 'MEXICO' },
  { placeId: 12029, cityName: 'MONCLOVA', countryName: 'MEXICO' },
  { placeId: 2304, cityName: 'MONTERREY', countryName: 'MEXICO' },
  { placeId: 12070, cityName: 'MORELIA', countryName: 'MEXICO' },
  { placeId: 12236, cityName: 'NOGALES', countryName: 'MEXICO' },
  { placeId: 12276, cityName: 'NUEVO CASAS GRANDES', countryName: 'MEXICO' },
  { placeId: 12277, cityName: 'NUEVO LAREDO', countryName: 'MEXICO' },
  { placeId: 12294, cityName: 'OAXACA', countryName: 'MEXICO' },
  { placeId: 12402, cityName: 'PACHUCA', countryName: 'MEXICO' },
  { placeId: 12413, cityName: 'PALENQUE', countryName: 'MEXICO' },
  { placeId: 12518, cityName: 'PIEDRAS NEGRAS', countryName: 'MEXICO' },
  { placeId: 12633, cityName: 'POZA RICA', countryName: 'MEXICO' },
  { placeId: 12665, cityName: 'PUEBLA', countryName: 'MEXICO' },
  { placeId: 12667, cityName: 'PUERTO CORTES', countryName: 'MEXICO' },
  { placeId: 12669, cityName: 'PUERTO ESCONDIDO', countryName: 'MEXICO' },
  { placeId: 12670, cityName: 'PUERTO PENASCO', countryName: 'MEXICO' },
  { placeId: 2306, cityName: 'PUERTO VALLARTA', countryName: 'MEXICO' },
  { placeId: 12677, cityName: 'PUNTA COLORADA', countryName: 'MEXICO' },
  { placeId: 12696, cityName: 'QUERETARO', countryName: 'MEXICO' },
  { placeId: 12779, cityName: 'REYNOSA', countryName: 'MEXICO' },
  { placeId: 3169, cityName: 'SALTILLO', countryName: 'MEXICO' },
  { placeId: 12916, cityName: 'SAN BLAS', countryName: 'MEXICO' },
  { placeId: 3170, cityName: 'SAN CRISTOBAL DE LAS CASAS', countryName: 'MEXICO' },
  { placeId: 12920, cityName: 'SAN FELIPE', countryName: 'MEXICO' },
  { placeId: 2307, cityName: 'SAN JOSE DEL CABO', countryName: 'MEXICO' },
  { placeId: 12928, cityName: 'SAN LUIS POTOSI', countryName: 'MEXICO' },
  { placeId: 2308, cityName: 'TAMPICO', countryName: 'MEXICO' },
  { placeId: 13354, cityName: 'TAMUIN', countryName: 'MEXICO' },
  { placeId: 13363, cityName: 'TAPACHULA', countryName: 'MEXICO' },
  { placeId: 3168, cityName: 'TEHUACAN', countryName: 'MEXICO' },
  { placeId: 13392, cityName: 'TEPIC', countryName: 'MEXICO' },
  { placeId: 13393, cityName: 'TERAN', countryName: 'MEXICO' },
  { placeId: 13431, cityName: 'TIJUANA', countryName: 'MEXICO' },
  { placeId: 2309, cityName: 'TOLUCA', countryName: 'MEXICO' },
  { placeId: 13465, cityName: 'TORREON', countryName: 'MEXICO' },
  { placeId: 13509, cityName: 'TULUM', countryName: 'MEXICO' },
  { placeId: 13520, cityName: 'TUXTLA GUTIERREZ', countryName: 'MEXICO' },
  { placeId: 13553, cityName: 'URUAPAN', countryName: 'MEXICO' },
  { placeId: 13594, cityName: 'VERACRUZ', countryName: 'MEXICO' },
  { placeId: 13613, cityName: 'VILLAHERMOSA', countryName: 'MEXICO' },
  { placeId: 13874, cityName: 'ZACATECAS', countryName: 'MEXICO' },
  { placeId: 13879, cityName: 'ZAMORA', countryName: 'MEXICO' },
  { placeId: 6323, cityName: 'KOSRAE I', countryName: 'MICRONESIA' },
  { placeId: 7687, cityName: 'POHNPEI I', countryName: 'MICRONESIA' },
  { placeId: 3724, cityName: 'ULITHI I', countryName: 'MICRONESIA' },
  { placeId: 2409, cityName: 'WENO I', countryName: 'MICRONESIA' },
  { placeId: 2411, cityName: 'YAP I', countryName: 'MICRONESIA' },
  { placeId: 11979, cityName: 'MIDWAY ATOLL', countryName: 'MIDWAY I' },
  { placeId: 1834, cityName: 'MIQUELON I', countryName: 'MIQUELON I' },
  { placeId: 2266, cityName: 'BALTI', countryName: 'MOLDOVA' },
  { placeId: 2267, cityName: 'CAHUL', countryName: 'MOLDOVA' },
  { placeId: 2268, cityName: 'CHISINAU', countryName: 'MOLDOVA' },
  { placeId: 11849, cityName: 'MARCULESTI', countryName: 'MOLDOVA' },
  { placeId: 9554, cityName: 'ALTAI', countryName: 'MONGOLIA' },
  { placeId: 9623, cityName: 'ARVAIKHEER', countryName: 'MONGOLIA' },
  { placeId: 9737, cityName: 'BARUUN-URT', countryName: 'MONGOLIA' },
  { placeId: 9760, cityName: 'BAYANKHONGOR', countryName: 'MONGOLIA' },
  { placeId: 9973, cityName: 'BULGAN SUM', countryName: 'MONGOLIA' },
  { placeId: 9972, cityName: 'BULGAN', countryName: 'MONGOLIA' },
  { placeId: 10207, cityName: 'CHOIBALSAN', countryName: 'MONGOLIA' },
  { placeId: 10404, cityName: 'DADAL', countryName: 'MONGOLIA' },
  { placeId: 10456, cityName: 'DEGLII TSAGAAN', countryName: 'MONGOLIA' },
  { placeId: 11029, cityName: 'GURVANSAIKHAN', countryName: 'MONGOLIA' },
  { placeId: 11444, cityName: 'KHARKHORIN', countryName: 'MONGOLIA' },
  { placeId: 11446, cityName: 'KHATGAL', countryName: 'MONGOLIA' },
  { placeId: 11449, cityName: 'KHOVD', countryName: 'MONGOLIA' },
  { placeId: 11811, cityName: 'MANDALGOBI', countryName: 'MONGOLIA' },
  { placeId: 12123, cityName: 'MUREN', countryName: 'MONGOLIA' },
  { placeId: 12399, cityName: 'OYUT', countryName: 'MONGOLIA' },
  { placeId: 13468, cityName: 'TOSONTSENGEL', countryName: 'MONGOLIA' },
  { placeId: 13499, cityName: 'TSETSERLEG', countryName: 'MONGOLIA' },
  { placeId: 2795, cityName: 'ULAANBAATAR', countryName: 'MONGOLIA' },
  { placeId: 13543, cityName: 'UNDURKHAAN', countryName: 'MONGOLIA' },
  { placeId: 2275, cityName: 'TIVAT', countryName: 'MONTENEGRO' },
  { placeId: 10882, cityName: 'GERALD\'S', countryName: 'MONTSERRAT I' },
  { placeId: 1182, cityName: 'AGADIR', countryName: 'MOROCCO' },
  { placeId: 1191, cityName: 'AL HOCEIMA', countryName: 'MOROCCO' },
  { placeId: 4424, cityName: 'BENI MELLAL', countryName: 'MOROCCO' },
  { placeId: 4429, cityName: 'BENSLIMANE', countryName: 'MOROCCO' },
  { placeId: 4564, cityName: 'BOUARFA', countryName: 'MOROCCO' },
  { placeId: 1187, cityName: 'CASABLANCA', countryName: 'MOROCCO' },
  { placeId: 5027, cityName: 'ERRACHIDIA', countryName: 'MOROCCO' },
  { placeId: 5330, cityName: 'ESSAOUIRA', countryName: 'MOROCCO' },
  { placeId: 1183, cityName: 'FES', countryName: 'MOROCCO' },
  { placeId: 5734, cityName: 'GUELMIME', countryName: 'MOROCCO' },
  { placeId: 5932, cityName: 'IFRANE', countryName: 'MOROCCO' },
  { placeId: 6199, cityName: 'KENITRA', countryName: 'MOROCCO' },
  { placeId: 1189, cityName: 'MARRAKECH', countryName: 'MOROCCO' },
  { placeId: 1188, cityName: 'NADOR', countryName: 'MOROCCO' },
  { placeId: 1190, cityName: 'OUARZAZATE', countryName: 'MOROCCO' },
  { placeId: 1184, cityName: 'OUJDA', countryName: 'MOROCCO' },
  { placeId: 1185, cityName: 'RABAT', countryName: 'MOROCCO' },
  { placeId: 8571, cityName: 'TAN TAN', countryName: 'MOROCCO' },
  { placeId: 1192, cityName: 'TANGER', countryName: 'MOROCCO' },
  { placeId: 8588, cityName: 'TAROUDANT', countryName: 'MOROCCO' },
  { placeId: 8597, cityName: 'TAZA', countryName: 'MOROCCO' },
  { placeId: 8630, cityName: 'TETOUAN', countryName: 'MOROCCO' },
  { placeId: 9101, cityName: 'ZAGORA', countryName: 'MOROCCO' },
  { placeId: 4145, cityName: 'ANGOCHE', countryName: 'MOZAMBIQUE' },
  { placeId: 1147, cityName: 'BEIRA', countryName: 'MOZAMBIQUE' },
  { placeId: 4473, cityName: 'BILENE', countryName: 'MOZAMBIQUE' },
  { placeId: 4832, cityName: 'CHIMOIO', countryName: 'MOZAMBIQUE' },
  { placeId: 5002, cityName: 'CUAMBA', countryName: 'MOZAMBIQUE' },
  { placeId: 5971, cityName: 'INHACA', countryName: 'MOZAMBIQUE' },
  { placeId: 5972, cityName: 'INHAMBANE', countryName: 'MOZAMBIQUE' },
  { placeId: 1148, cityName: 'LICHINGA', countryName: 'MOZAMBIQUE' },
  { placeId: 6671, cityName: 'LUMBO', countryName: 'MOZAMBIQUE' },
  { placeId: 1149, cityName: 'MAPUTO', countryName: 'MOZAMBIQUE' },
  { placeId: 6830, cityName: 'MARRUPA', countryName: 'MOZAMBIQUE' },
  { placeId: 6493, cityName: 'MOCIMBOA DA PRAIA', countryName: 'MOZAMBIQUE' },
  { placeId: 6497, cityName: 'NACALA', countryName: 'MOZAMBIQUE' },
  { placeId: 1150, cityName: 'NAMPULA', countryName: 'MOZAMBIQUE' },
  { placeId: 1151, cityName: 'PEMBA', countryName: 'MOZAMBIQUE' },
  { placeId: 7496, cityName: 'PONTA DE OURO', countryName: 'MOZAMBIQUE' },
  { placeId: 1152, cityName: 'QUELIMANE', countryName: 'MOZAMBIQUE' },
  { placeId: 8317, cityName: 'SONGO', countryName: 'MOZAMBIQUE' },
  { placeId: 1153, cityName: 'TETE', countryName: 'MOZAMBIQUE' },
  { placeId: 8781, cityName: 'ULONGWE', countryName: 'MOZAMBIQUE' },
  { placeId: 1154, cityName: 'VILANKULO', countryName: 'MOZAMBIQUE' },
  { placeId: 4149, cityName: 'ANISAKAN', countryName: 'MYANMAR' },
  { placeId: 9586, cityName: 'ANN', countryName: 'MYANMAR' },
  { placeId: 4293, cityName: 'BAGAN', countryName: 'MYANMAR' },
  { placeId: 4334, cityName: 'BANMAW', countryName: 'MYANMAR' },
  { placeId: 9883, cityName: 'BOKPYINN', countryName: 'MYANMAR' },
  { placeId: 4913, cityName: 'COCO I', countryName: 'MYANMAR' },
  { placeId: 5074, cityName: 'DAWEI', countryName: 'MYANMAR' },
  { placeId: 5526, cityName: 'GANTGAW', countryName: 'MYANMAR' },
  { placeId: 5751, cityName: 'GWA', countryName: 'MYANMAR' },
  { placeId: 5825, cityName: 'HEHO', countryName: 'MYANMAR' },
  { placeId: 5855, cityName: 'HMAWBY', countryName: 'MYANMAR' },
  { placeId: 5875, cityName: 'HOMMALINN', countryName: 'MYANMAR' },
  { placeId: 5893, cityName: 'HPA-AN', countryName: 'MYANMAR' },
  { placeId: 5894, cityName: 'HPA-PUN', countryName: 'MYANMAR' },
  { placeId: 6119, cityName: 'KALAY', countryName: 'MYANMAR' },
  { placeId: 6130, cityName: 'KANTI', countryName: 'MYANMAR' },
  { placeId: 6175, cityName: 'KAWTHOUNG', countryName: 'MYANMAR' },
  { placeId: 6196, cityName: 'KENGTUNG', countryName: 'MYANMAR' },
  { placeId: 6352, cityName: 'KYAUKPYU', countryName: 'MYANMAR' },
  { placeId: 6353, cityName: 'KYAUKTU', countryName: 'MYANMAR' },
  { placeId: 6439, cityName: 'LANYWA', countryName: 'MYANMAR' },
  { placeId: 6447, cityName: 'LASHIO', countryName: 'MYANMAR' },
  { placeId: 6598, cityName: 'LOIKAW', countryName: 'MYANMAR' },
  { placeId: 6710, cityName: 'MAGWAY', countryName: 'MYANMAR' },
  { placeId: 6752, cityName: 'MANAUNG', countryName: 'MYANMAR' },
  { placeId: 2727, cityName: 'MANDALAY', countryName: 'MYANMAR' },
  { placeId: 6883, cityName: 'MAWLAMYINE', countryName: 'MYANMAR' },
  { placeId: 6922, cityName: 'MEIKTILA', countryName: 'MYANMAR' },
  { placeId: 7020, cityName: 'MOMEIK', countryName: 'MYANMAR' },
  { placeId: 7024, cityName: 'MONG TONG', countryName: 'MYANMAR' },
  { placeId: 7025, cityName: 'MONG-HSAT', countryName: 'MYANMAR' },
  { placeId: 7056, cityName: 'MONYWAR', countryName: 'MYANMAR' },
  { placeId: 7158, cityName: 'MYEIK', countryName: 'MYANMAR' },
  { placeId: 7159, cityName: 'MYITKYINA', countryName: 'MYANMAR' },
  { placeId: 7176, cityName: 'NAMSANG', countryName: 'MYANMAR' },
  { placeId: 2858, cityName: 'NAYPYITAW', countryName: 'MYANMAR' },
  { placeId: 7475, cityName: 'PAKHOKKU', countryName: 'MYANMAR' },
  { placeId: 7574, cityName: 'PATHEIN', countryName: 'MYANMAR' },
  { placeId: 7807, cityName: 'PUTAO', countryName: 'MYANMAR' },
  { placeId: 7812, cityName: 'PYAY', countryName: 'MYANMAR' },
  { placeId: 8212, cityName: 'SHANTE', countryName: 'MYANMAR' },
  { placeId: 8281, cityName: 'SITTWE', countryName: 'MYANMAR' },
  { placeId: 8536, cityName: 'TACHILEK', countryName: 'MYANMAR' },
  { placeId: 8592, cityName: 'TAUNGOO', countryName: 'MYANMAR' },
  { placeId: 8635, cityName: 'THANDWE', countryName: 'MYANMAR' },
  { placeId: 2728, cityName: 'YANGON', countryName: 'MYANMAR' },
  { placeId: 9080, cityName: 'YE', countryName: 'MYANMAR' },
  { placeId: 4199, cityName: 'ARANDIS', countryName: 'NAMIBIA' },
  { placeId: 5588, cityName: 'GOBABIS', countryName: 'NAMIBIA' },
  { placeId: 5690, cityName: 'GRAVENSTEIN', countryName: 'NAMIBIA' },
  { placeId: 1166, cityName: 'GROOTFONTEIN', countryName: 'NAMIBIA' },
  { placeId: 6163, cityName: 'KATIMA MULILO', countryName: 'NAMIBIA' },
  { placeId: 1167, cityName: 'KEETMANSHOOP', countryName: 'NAMIBIA' },
  { placeId: 6543, cityName: 'LIANSHULU', countryName: 'NAMIBIA' },
  { placeId: 6652, cityName: 'LUDERITZ', countryName: 'NAMIBIA' },
  { placeId: 6812, cityName: 'MARIENTAL', countryName: 'NAMIBIA' },
  { placeId: 7013, cityName: 'MOKUTI LODGE', countryName: 'NAMIBIA' },
  { placeId: 7390, cityName: 'ONDANGWA', countryName: 'NAMIBIA' },
  { placeId: 7407, cityName: 'ORANJEMUND', countryName: 'NAMIBIA' },
  { placeId: 7505, cityName: 'RAG ROCK', countryName: 'NAMIBIA' },
  { placeId: 7984, cityName: 'RUNDU', countryName: 'NAMIBIA' },
  { placeId: 8286, cityName: 'SKORPION MINE', countryName: 'NAMIBIA' },
  { placeId: 8333, cityName: 'SWAKOPMUND', countryName: 'NAMIBIA' },
  { placeId: 8729, cityName: 'TSUMEB', countryName: 'NAMIBIA' },
  { placeId: 1168, cityName: 'WALVIS BAY', countryName: 'NAMIBIA' },
  { placeId: 1169, cityName: 'WINDHOEK', countryName: 'NAMIBIA' },
  { placeId: 2, cityName: 'NAURU I, NAURU', countryName: 'NAURU' },
  { placeId: 4296, cityName: 'BAGLUNG', countryName: 'NEPAL' },
  { placeId: 4303, cityName: 'BAITADI', countryName: 'NEPAL' },
  { placeId: 4304, cityName: 'BAJHANG', countryName: 'NEPAL' },
  { placeId: 4305, cityName: 'BAJURA', countryName: 'NEPAL' },
  { placeId: 4452, cityName: 'BHAIRAHAWA', countryName: 'NEPAL' },
  { placeId: 4453, cityName: 'BHARATPUR', countryName: 'NEPAL' },
  { placeId: 4456, cityName: 'BHOJPUR', countryName: 'NEPAL' },
  { placeId: 4481, cityName: 'BIRATNAGAR', countryName: 'NEPAL' },
  { placeId: 3993, cityName: 'CHANDRAGADHI', countryName: 'NEPAL' },
  { placeId: 4798, cityName: 'CHAURIJHARI', countryName: 'NEPAL' },
  { placeId: 5059, cityName: 'DANG', countryName: 'NEPAL' },
  { placeId: 5064, cityName: 'DARCHULA', countryName: 'NEPAL' },
  { placeId: 5164, cityName: 'DHANGADHI', countryName: 'NEPAL' },
  { placeId: 5196, cityName: 'DOLPA', countryName: 'NEPAL' },
  { placeId: 5204, cityName: 'DOTI', countryName: 'NEPAL' },
  { placeId: 5141, cityName: 'GORKHA', countryName: 'NEPAL' },
  { placeId: 6042, cityName: 'JANAKPUR', countryName: 'NEPAL' },
  { placeId: 6075, cityName: 'JOMSOM', countryName: 'NEPAL' },
  { placeId: 6084, cityName: 'JUMLA', countryName: 'NEPAL' },
  { placeId: 2690, cityName: 'KATHMANDU', countryName: 'NEPAL' },
  { placeId: 6420, cityName: 'LAMIDADA', countryName: 'NEPAL' },
  { placeId: 6665, cityName: 'LUKLA', countryName: 'NEPAL' },
  { placeId: 6714, cityName: 'MAHENDRANAGAR', countryName: 'NEPAL' },
  { placeId: 6748, cityName: 'MANANG', countryName: 'NEPAL' },
  { placeId: 6920, cityName: 'MEGHAULI', countryName: 'NEPAL' },
  { placeId: 7225, cityName: 'NEPALGUNJ', countryName: 'NEPAL' },
  { placeId: 7490, cityName: 'POKHARA', countryName: 'NEPAL' },
  { placeId: 7511, cityName: 'RAJBIRAJ', countryName: 'NEPAL' },
  { placeId: 7845, cityName: 'RAMECHHAP', countryName: 'NEPAL' },
  { placeId: 7983, cityName: 'RUMJATAR', countryName: 'NEPAL' },
  { placeId: 8079, cityName: 'SANFEBAGAR', countryName: 'NEPAL' },
  { placeId: 8265, cityName: 'SIMARA', countryName: 'NEPAL' },
  { placeId: 8268, cityName: 'SIMIKOT', countryName: 'NEPAL' },
  { placeId: 8525, cityName: 'SURKHET', countryName: 'NEPAL' },
  { placeId: 8745, cityName: 'TUMLINGTAR', countryName: 'NEPAL' },
  { placeId: 2549, cityName: 'BONAIRE I', countryName: 'NETHERLANDS ANTILLES' },
  { placeId: 2550, cityName: 'CURACAO I', countryName: 'NETHERLANDS ANTILLES' },
  { placeId: 8412, cityName: 'ST EUSTATIUS I', countryName: 'NETHERLANDS ANTILLES' },
  { placeId: 2551, cityName: 'ST MAARTEN I', countryName: 'NETHERLANDS ANTILLES' },
  { placeId: 777, cityName: 'AMELAND', countryName: 'NETHERLANDS' },
  { placeId: 778, cityName: 'AMSTERDAM', countryName: 'NETHERLANDS' },
  { placeId: 781, cityName: 'ARNHEM', countryName: 'NETHERLANDS' },
  { placeId: 788, cityName: 'DE KOOY', countryName: 'NETHERLANDS' },
  { placeId: 795, cityName: 'DEVENTER', countryName: 'NETHERLANDS' },
  { placeId: 782, cityName: 'DRACHTEN', countryName: 'NETHERLANDS' },
  { placeId: 783, cityName: 'EINDHOVEN', countryName: 'NETHERLANDS' },
  { placeId: 796, cityName: 'ENSCHEDE', countryName: 'NETHERLANDS' },
  { placeId: 785, cityName: 'GILZE-RIJEN', countryName: 'NETHERLANDS' },
  { placeId: 784, cityName: 'GRONINGEN', countryName: 'NETHERLANDS' },
  { placeId: 787, cityName: 'HILVERSUM', countryName: 'NETHERLANDS' },
  { placeId: 794, cityName: 'HOEVEN', countryName: 'NETHERLANDS' },
  { placeId: 786, cityName: 'HOOGEVEEN', countryName: 'NETHERLANDS' },
  { placeId: 790, cityName: 'LEEUWARDEN', countryName: 'NETHERLANDS' },
  { placeId: 789, cityName: 'LELYSTAD', countryName: 'NETHERLANDS' },
  { placeId: 780, cityName: 'MAASTRICHT', countryName: 'NETHERLANDS' },
  { placeId: 791, cityName: 'MIDDELBURG', countryName: 'NETHERLANDS' },
  { placeId: 792, cityName: 'ROTTERDAM', countryName: 'NETHERLANDS' },
  { placeId: 793, cityName: 'SOESTERBERG', countryName: 'NETHERLANDS' },
  { placeId: 797, cityName: 'TEXEL', countryName: 'NETHERLANDS' },
  { placeId: 8873, cityName: 'VOLKEL', countryName: 'NETHERLANDS' },
  { placeId: 779, cityName: 'WEERT', countryName: 'NETHERLANDS' },
  { placeId: 798, cityName: 'WOENSDRECHT', countryName: 'NETHERLANDS' },
  { placeId: 3723, cityName: 'NEVIS I.', countryName: 'NEVIS IS' },
  { placeId: 4225, cityName: 'ART I.', countryName: 'NEW CALEDONIA' },
  { placeId: 5157, cityName: 'DES PINS IS.', countryName: 'NEW CALEDONIA' },
  { placeId: 5886, cityName: 'HOUAILOU', countryName: 'NEW CALEDONIA' },
  { placeId: 6315, cityName: 'KONE', countryName: 'NEW CALEDONIA' },
  { placeId: 6328, cityName: 'KOUMAC', countryName: 'NEW CALEDONIA' },
  { placeId: 6549, cityName: 'LIFOU', countryName: 'NEW CALEDONIA' },
  { placeId: 6802, cityName: 'MARE', countryName: 'NEW CALEDONIA' },
  { placeId: 2336, cityName: 'NOUMEA', countryName: 'NEW CALEDONIA' },
  { placeId: 7445, cityName: 'OUVEA', countryName: 'NEW CALEDONIA' },
  { placeId: 13430, cityName: 'TIGA', countryName: 'NEW CALEDONIA' },
  { placeId: 8690, cityName: 'TOUHO', countryName: 'NEW CALEDONIA' },
  { placeId: 4070, cityName: 'ALEXANDRA', countryName: 'NEW ZEALAND' },
  { placeId: 4236, cityName: 'ASHBURTON', countryName: 'NEW ZEALAND' },
  { placeId: 2337, cityName: 'AUCKLAND', countryName: 'NEW ZEALAND' },
  { placeId: 4311, cityName: 'BALCLUTHA', countryName: 'NEW ZEALAND' },
  { placeId: 4505, cityName: 'BLENHEIM', countryName: 'NEW ZEALAND' },
  { placeId: 4796, cityName: 'CHATHAM IS', countryName: 'NEW ZEALAND' },
  { placeId: 2338, cityName: 'CHRISTCHURCH', countryName: 'NEW ZEALAND' },
  { placeId: 4964, cityName: 'COROMANDEL', countryName: 'NEW ZEALAND' },
  { placeId: 10377, cityName: 'CROMWELL', countryName: 'NEW ZEALAND' },
  { placeId: 5060, cityName: 'DANNEVIRKE', countryName: 'NEW ZEALAND' },
  { placeId: 5065, cityName: 'DARGAVILLE', countryName: 'NEW ZEALAND' },
  { placeId: 5221, cityName: 'DUNEDIN', countryName: 'NEW ZEALAND' },
  { placeId: 5385, cityName: 'FEILDING', countryName: 'NEW ZEALAND' },
  { placeId: 5445, cityName: 'FOXPINE', countryName: 'NEW ZEALAND' },
  { placeId: 5568, cityName: 'GISBORNE', countryName: 'NEW ZEALAND' },
  { placeId: 5581, cityName: 'GLENORCHY', countryName: 'NEW ZEALAND' },
  { placeId: 5583, cityName: 'GLENTANNER', countryName: 'NEW ZEALAND' },
  { placeId: 5138, cityName: 'GORE', countryName: 'NEW ZEALAND' },
  { placeId: 5692, cityName: 'GREAT BARRIER', countryName: 'NEW ZEALAND' },
  { placeId: 5712, cityName: 'GREYMOUTH', countryName: 'NEW ZEALAND' },
  { placeId: 5783, cityName: 'HAMILTON', countryName: 'NEW ZEALAND' },
  { placeId: 5793, cityName: 'HANMER SPRINGS', countryName: 'NEW ZEALAND' },
  { placeId: 5612, cityName: 'HASTINGS', countryName: 'NEW ZEALAND' },
  { placeId: 5623, cityName: 'HAWERA', countryName: 'NEW ZEALAND' },
  { placeId: 5859, cityName: 'HOKITIKA', countryName: 'NEW ZEALAND' },
  { placeId: 5978, cityName: 'INVERCARGILL', countryName: 'NEW ZEALAND' },
  { placeId: 6104, cityName: 'KAIKOHE', countryName: 'NEW ZEALAND' },
  { placeId: 6105, cityName: 'KAIKOURA', countryName: 'NEW ZEALAND' },
  { placeId: 6107, cityName: 'KAIPARA FLATS', countryName: 'NEW ZEALAND' },
  { placeId: 6109, cityName: 'KAITAIA', countryName: 'NEW ZEALAND' },
  { placeId: 6137, cityName: 'KARAMEA', countryName: 'NEW ZEALAND' },
  { placeId: 5042, cityName: 'KERIKERI', countryName: 'NEW ZEALAND' },
  { placeId: 6412, cityName: 'LAKE STATION/NELSON LAKES', countryName: 'NEW ZEALAND' },
  { placeId: 6750, cityName: 'MANAPOURI', countryName: 'NEW ZEALAND' },
  { placeId: 6859, cityName: 'MASTERTON', countryName: 'NEW ZEALAND' },
  { placeId: 6865, cityName: 'MATAMATA', countryName: 'NEW ZEALAND' },
  { placeId: 6867, cityName: 'MATARANGI', countryName: 'NEW ZEALAND' },
  { placeId: 6877, cityName: 'MAUI A', countryName: 'NEW ZEALAND' },
  { placeId: 6878, cityName: 'MAUI B', countryName: 'NEW ZEALAND' },
  { placeId: 6943, cityName: 'MERCER', countryName: 'NEW ZEALAND' },
  { placeId: 6974, cityName: 'MILFORD SOUND', countryName: 'NEW ZEALAND' },
  { placeId: 7087, cityName: 'MOTUEKA', countryName: 'NEW ZEALAND' },
  { placeId: 5661, cityName: 'MOUNT COOK', countryName: 'NEW ZEALAND' },
  { placeId: 7135, cityName: 'MURCHISON', countryName: 'NEW ZEALAND' },
  { placeId: 7187, cityName: 'NAPIER', countryName: 'NEW ZEALAND' },
  { placeId: 7219, cityName: 'NELSON', countryName: 'NEW ZEALAND' },
  { placeId: 6303, cityName: 'NEW PLYMOUTH', countryName: 'NEW ZEALAND' },
  { placeId: 7287, cityName: 'NORTH SHORE', countryName: 'NEW ZEALAND' },
  { placeId: 7338, cityName: 'OAMARU', countryName: 'NEW ZEALAND' },
  { placeId: 7339, cityName: 'OAONUI', countryName: 'NEW ZEALAND' },
  { placeId: 7367, cityName: 'OHAKEA', countryName: 'NEW ZEALAND' },
  { placeId: 7293, cityName: 'OKIWI', countryName: 'NEW ZEALAND' },
  { placeId: 7386, cityName: 'OMARAMA', countryName: 'NEW ZEALAND' },
  { placeId: 7398, cityName: 'OPOTIKI', countryName: 'NEW ZEALAND' },
  { placeId: 7483, cityName: 'PALMERSTON NORTH', countryName: 'NEW ZEALAND' },
  { placeId: 12435, cityName: 'PARAKAI', countryName: 'NEW ZEALAND' },
  { placeId: 7304, cityName: 'PARAPARAUMU', countryName: 'NEW ZEALAND' },
  { placeId: 7580, cityName: 'PAUANUI BEACH', countryName: 'NEW ZEALAND' },
  { placeId: 7796, cityName: 'PUKAKI', countryName: 'NEW ZEALAND' },
  { placeId: 2870, cityName: 'QUEENSTOWN', countryName: 'NEW ZEALAND' },
  { placeId: 7506, cityName: 'RAGLAN', countryName: 'NEW ZEALAND' },
  { placeId: 7851, cityName: 'RANGIORA', countryName: 'NEW ZEALAND' },
  { placeId: 7967, cityName: 'ROTORUA', countryName: 'NEW ZEALAND' },
  { placeId: 8393, cityName: 'SPRINGHILL', countryName: 'NEW ZEALAND' },
  { placeId: 8492, cityName: 'STRATFORD', countryName: 'NEW ZEALAND' },
  { placeId: 8549, cityName: 'TAIERI', countryName: 'NEW ZEALAND' },
  { placeId: 8556, cityName: 'TAKAKA', countryName: 'NEW ZEALAND' },
  { placeId: 8591, cityName: 'TAUMARUNUI', countryName: 'NEW ZEALAND' },
  { placeId: 8593, cityName: 'TAUPO', countryName: 'NEW ZEALAND' },
  { placeId: 8594, cityName: 'TAURANGA', countryName: 'NEW ZEALAND' },
  { placeId: 8600, cityName: 'TE ANAU', countryName: 'NEW ZEALAND' },
  { placeId: 8601, cityName: 'TE KUITI', countryName: 'NEW ZEALAND' },
  { placeId: 8634, cityName: 'THAMES', countryName: 'NEW ZEALAND' },
  { placeId: 8666, cityName: 'TIMARU', countryName: 'NEW ZEALAND' },
  { placeId: 8348, cityName: 'TOKOROA', countryName: 'NEW ZEALAND' },
  { placeId: 8751, cityName: 'TURANGI', countryName: 'NEW ZEALAND' },
  { placeId: 8892, cityName: 'WAIHI BEACH', countryName: 'NEW ZEALAND' },
  { placeId: 8893, cityName: 'WAIMATE', countryName: 'NEW ZEALAND' },
  { placeId: 8896, cityName: 'WAIOURU', countryName: 'NEW ZEALAND' },
  { placeId: 8897, cityName: 'WAIPUKURAU', countryName: 'NEW ZEALAND' },
  { placeId: 8898, cityName: 'WAIROA', countryName: 'NEW ZEALAND' },
  { placeId: 8909, cityName: 'WANAKA', countryName: 'NEW ZEALAND' },
  { placeId: 8911, cityName: 'WANGANUI', countryName: 'NEW ZEALAND' },
  { placeId: 2339, cityName: 'WELLINGTON', countryName: 'NEW ZEALAND' },
  { placeId: 8979, cityName: 'WEST MELTON', countryName: 'NEW ZEALAND' },
  { placeId: 8993, cityName: 'WESTPORT', countryName: 'NEW ZEALAND' },
  { placeId: 8184, cityName: 'WHAKATANE', countryName: 'NEW ZEALAND' },
  { placeId: 8994, cityName: 'WHANGAREI', countryName: 'NEW ZEALAND' },
  { placeId: 9002, cityName: 'WHITIANGA', countryName: 'NEW ZEALAND' },
  // { placeId: 9449, cityName: '', countryName: 'NICARAGUA' },
  { placeId: 4511, cityName: 'BLUEFIELDS', countryName: 'NICARAGUA' },
  { placeId: 4533, cityName: 'BONANZA', countryName: 'NICARAGUA' },
  { placeId: 4834, cityName: 'CHINANDEGA', countryName: 'NICARAGUA' },
  { placeId: 4960, cityName: 'CORN ISLAND', countryName: 'NICARAGUA' },
  { placeId: 6527, cityName: 'LEON', countryName: 'NICARAGUA' },
  { placeId: 6624, cityName: 'LOS BRASILES', countryName: 'NICARAGUA' },
  { placeId: 2311, cityName: 'MANAGUA', countryName: 'NICARAGUA' },
  { placeId: 7044, cityName: 'MONTELIMAR', countryName: 'NICARAGUA' },
  { placeId: 6496, cityName: 'N. GUINEA', countryName: 'NICARAGUA' },
  { placeId: 7544, cityName: 'PANCHITO', countryName: 'NICARAGUA' },
  { placeId: 7784, cityName: 'PUERTO CABEZAS', countryName: 'NICARAGUA' },
  { placeId: 8034, cityName: 'RIO SAN JUAN', countryName: 'NICARAGUA' },
  { placeId: 7964, cityName: 'ROSITA', countryName: 'NICARAGUA' },
  { placeId: 8282, cityName: 'SIUNA', countryName: 'NICARAGUA' },
  { placeId: 8931, cityName: 'WASPAN', countryName: 'NICARAGUA' },
  { placeId: 152, cityName: 'AGADES', countryName: 'NIGER' },
  { placeId: 5171, cityName: 'DIFFA', countryName: 'NIGER' },
  { placeId: 5183, cityName: 'DIRKOU', countryName: 'NIGER' },
  { placeId: 5190, cityName: 'DOGONDOUTCHI', countryName: 'NIGER' },
  { placeId: 5202, cityName: 'DOSSO', countryName: 'NIGER' },
  { placeId: 5541, cityName: 'GAYA', countryName: 'NIGER' },
  { placeId: 5605, cityName: 'GOURE', countryName: 'NIGER' },
  { placeId: 5931, cityName: 'IFEROUANE', countryName: 'NIGER' },
  { placeId: 6381, cityName: 'LA TAPOA', countryName: 'NIGER' },
  { placeId: 6717, cityName: 'MAINE-SOROA', countryName: 'NIGER' },
  { placeId: 6795, cityName: 'MARADI', countryName: 'NIGER' },
  { placeId: 151, cityName: 'NIAMEY', countryName: 'NIGER' },
  { placeId: 7302, cityName: 'OUALLAM', countryName: 'NIGER' },
  { placeId: 8547, cityName: 'TAHOUA', countryName: 'NIGER' },
  { placeId: 8628, cityName: 'TESSAOUA', countryName: 'NIGER' },
  { placeId: 8664, cityName: 'TILLABERY', countryName: 'NIGER' },
  { placeId: 153, cityName: 'ZINDER', countryName: 'NIGER' },
  { placeId: 145, cityName: 'ABUJA', countryName: 'NIGERIA' },
  { placeId: 4041, cityName: 'AKURE', countryName: 'NIGERIA' },
  {
    placeId: 13907, cityName: 'ASABA', state: 'DELTA STATE', countryName: 'NIGERIA',
  },
  { placeId: 4426, cityName: 'BENIN', countryName: 'NIGERIA' },
  { placeId: 14299, cityName: 'Birmin Kebbi', countryName: 'NIGERIA' },
  { placeId: 146, cityName: 'CALABAR', countryName: 'NIGERIA' },
  { placeId: 5328, cityName: 'ENUGU', countryName: 'NIGERIA' },
  { placeId: 10643, cityName: 'ESCRAVOS', countryName: 'NIGERIA' },
  { placeId: 14153, cityName: 'FINIMA', countryName: 'NIGERIA' },
  { placeId: 9187, cityName: 'GOMBE', countryName: 'NIGERIA' },
  { placeId: 5920, cityName: 'IBADAN', countryName: 'NIGERIA' },
  { placeId: 147, cityName: 'ILORIN', countryName: 'NIGERIA' },
  { placeId: 6081, cityName: 'JOS', countryName: 'NIGERIA' },
  { placeId: 6099, cityName: 'KADUNA', countryName: 'NIGERIA' },
  { placeId: 148, cityName: 'KANO', countryName: 'NIGERIA' },
  { placeId: 6166, cityName: 'KATSINA', countryName: 'NIGERIA' },
  { placeId: 149, cityName: 'LAGOS', countryName: 'NIGERIA' },
  { placeId: 2864, cityName: 'MAIDUGURI', countryName: 'NIGERIA' },
  { placeId: 9167, cityName: 'MARKUDI', countryName: 'NIGERIA' },
  { placeId: 6989, cityName: 'MINNA', countryName: 'NIGERIA' },
  { placeId: 9163, cityName: 'OSUBI', countryName: 'NIGERIA' },
  { placeId: 7452, cityName: 'OWERRI', countryName: 'NIGERIA' },
  { placeId: 9237, cityName: 'PORT HARCOURT MILITARY', countryName: 'NIGERIA' },
  { placeId: 150, cityName: 'PORT HARCOURT', countryName: 'NIGERIA' },
  { placeId: 8306, cityName: 'SOKOTO', countryName: 'NIGERIA' },
  { placeId: 9188, cityName: 'UYO', countryName: 'NIGERIA' },
  { placeId: 9089, cityName: 'YOLA', countryName: 'NIGERIA' },
  { placeId: 9110, cityName: 'ZARIA', countryName: 'NIGERIA' },
  { placeId: 2331, cityName: 'NIUE ISLAND', countryName: 'NIUE' },
  { placeId: 12856, cityName: 'ROTA I', countryName: 'NORTHERN MARIANA ISLANDS' },
  { placeId: 12892, cityName: 'SAIPAN I', countryName: 'NORTHERN MARIANA ISLANDS' },
  { placeId: 13441, cityName: 'TINIAN I', countryName: 'NORTHERN MARIANA ISLANDS' },
  { placeId: 859, cityName: 'ALESUND', countryName: 'NORWAY' },
  { placeId: 862, cityName: 'ALTA', countryName: 'NORWAY' },
  { placeId: 860, cityName: 'ANDENES', countryName: 'NORWAY' },
  { placeId: 872, cityName: 'BARDUFOSS', countryName: 'NORWAY' },
  { placeId: 868, cityName: 'BATSFJORD', countryName: 'NORWAY' },
  { placeId: 867, cityName: 'BERGEN', countryName: 'NORWAY' },
  { placeId: 869, cityName: 'BERLEVAG', countryName: 'NORWAY' },
  { placeId: 866, cityName: 'BODO', countryName: 'NORWAY' },
  { placeId: 864, cityName: 'BOMOEN', countryName: 'NORWAY' },
  { placeId: 865, cityName: 'BRONNOYSUND', countryName: 'NORWAY' },
  { placeId: 5192, cityName: 'DOKKA', countryName: 'NORWAY' },
  { placeId: 873, cityName: 'EGGEMOEN', countryName: 'NORWAY' },
  { placeId: 885, cityName: 'ELVERUM', countryName: 'NORWAY' },
  { placeId: 10626, cityName: 'ENGELOY', countryName: 'NORWAY' },
  { placeId: 876, cityName: 'FAGERNES', countryName: 'NORWAY' },
  { placeId: 892, cityName: 'FARSUND', countryName: 'NORWAY' },
  { placeId: 877, cityName: 'FLORO', countryName: 'NORWAY' },
  { placeId: 880, cityName: 'FOLLDAL', countryName: 'NORWAY' },
  { placeId: 863, cityName: 'FORDE', countryName: 'NORWAY' },
  { placeId: 875, cityName: 'FROYA', countryName: 'NORWAY' },
  { placeId: 5127, cityName: 'FRYA', countryName: 'NORWAY' },
  { placeId: 878, cityName: 'FYRESDAL', countryName: 'NORWAY' },
  { placeId: 871, cityName: 'GEILO', countryName: 'NORWAY' },
  { placeId: 5741, cityName: 'GULLKNAPP', countryName: 'NORWAY' },
  { placeId: 881, cityName: 'HAMAR', countryName: 'NORWAY' },
  { placeId: 883, cityName: 'HAMMERFEST', countryName: 'NORWAY' },
  { placeId: 874, cityName: 'HARSTAD-NARVIK', countryName: 'NORWAY' },
  { placeId: 884, cityName: 'HASVIK', countryName: 'NORWAY' },
  { placeId: 5619, cityName: 'HATTFJELLDAL', countryName: 'NORWAY' },
  { placeId: 882, cityName: 'HAUGESUND', countryName: 'NORWAY' },
  { placeId: 886, cityName: 'HOKKSUND', countryName: 'NORWAY' },
  { placeId: 887, cityName: 'HONNINGSVAG', countryName: 'NORWAY' },
  { placeId: 888, cityName: 'JARLSBERG', countryName: 'NORWAY' },
  { placeId: 891, cityName: 'KIRKENES', countryName: 'NORWAY' },
  { placeId: 890, cityName: 'KJELLER', countryName: 'NORWAY' },
  { placeId: 6278, cityName: 'KLANTEN', countryName: 'NORWAY' },
  { placeId: 870, cityName: 'KRISTIANSAND', countryName: 'NORWAY' },
  { placeId: 889, cityName: 'KRISTIANSUND', countryName: 'NORWAY' },
  { placeId: 896, cityName: 'LAKSELV', countryName: 'NORWAY' },
  { placeId: 893, cityName: 'LEKNES', countryName: 'NORWAY' },
  { placeId: 5652, cityName: 'LUNDE', countryName: 'NORWAY' },
  { placeId: 6921, cityName: 'MEHAMN', countryName: 'NORWAY' },
  { placeId: 902, cityName: 'MO I RANA', countryName: 'NORWAY' },
  { placeId: 894, cityName: 'MOLDE', countryName: 'NORWAY' },
  { placeId: 895, cityName: 'MOSJOEN', countryName: 'NORWAY' },
  { placeId: 906, cityName: 'MOSS', countryName: 'NORWAY' },
  { placeId: 898, cityName: 'NAMSOS', countryName: 'NORWAY' },
  { placeId: 897, cityName: 'NARVIK', countryName: 'NORWAY' },
  { placeId: 899, cityName: 'NOTODDEN', countryName: 'NORWAY' },
  { placeId: 861, cityName: 'NY ALESUND', countryName: 'NORWAY' },
  { placeId: 7399, cityName: 'OPPDAL', countryName: 'NORWAY' },
  { placeId: 900, cityName: 'ORLAND', countryName: 'NORWAY' },
  { placeId: 901, cityName: 'ORSTA-VOLDA', countryName: 'NORWAY' },
  { placeId: 7429, cityName: 'OS', countryName: 'NORWAY' },
  { placeId: 879, cityName: 'OSLO', countryName: 'NORWAY' },
  { placeId: 7843, cityName: 'RAKKESTAD', countryName: 'NORWAY' },
  { placeId: 7891, cityName: 'REINSVOLL', countryName: 'NORWAY' },
  { placeId: 7950, cityName: 'ROGNAN', countryName: 'NORWAY' },
  { placeId: 904, cityName: 'ROROS', countryName: 'NORWAY' },
  { placeId: 903, cityName: 'RORVIK', countryName: 'NORWAY' },
  { placeId: 905, cityName: 'ROST', countryName: 'NORWAY' },
  { placeId: 909, cityName: 'SANDANE', countryName: 'NORWAY' },
  { placeId: 919, cityName: 'SANDEFJORD', countryName: 'NORWAY' },
  { placeId: 917, cityName: 'SANDNESSJOEN', countryName: 'NORWAY' },
  { placeId: 913, cityName: 'SKIEN', countryName: 'NORWAY' },
  { placeId: 910, cityName: 'SOGNDAL', countryName: 'NORWAY' },
  { placeId: 915, cityName: 'SORKJOSEN', countryName: 'NORWAY' },
  { placeId: 922, cityName: 'STAVANGER', countryName: 'NORWAY' },
  { placeId: 912, cityName: 'STOKMARKNES', countryName: 'NORWAY' },
  { placeId: 914, cityName: 'STORD', countryName: 'NORWAY' },
  { placeId: 8518, cityName: 'SUNNDAL', countryName: 'NORWAY' },
  { placeId: 908, cityName: 'SVALBARD', countryName: 'NORWAY' },
  { placeId: 907, cityName: 'SVEA', countryName: 'NORWAY' },
  { placeId: 911, cityName: 'SVOLVAER', countryName: 'NORWAY' },
  { placeId: 918, cityName: 'TROMSO', countryName: 'NORWAY' },
  { placeId: 920, cityName: 'TRONDHEIM', countryName: 'NORWAY' },
  { placeId: 8715, cityName: 'TRYSIL', countryName: 'NORWAY' },
  { placeId: 8767, cityName: 'TYNSET', countryName: 'NORWAY' },
  { placeId: 921, cityName: 'VADSO', countryName: 'NORWAY' },
  { placeId: 8168, cityName: 'VALLE', countryName: 'NORWAY' },
  { placeId: 916, cityName: 'VARDO', countryName: 'NORWAY' },
  { placeId: 9494, cityName: 'ADAM', countryName: 'OMAN' },
  { placeId: 5351, cityName: 'FAHUD', countryName: 'OMAN' },
  { placeId: 5400, cityName: 'FIRQ', countryName: 'OMAN' },
  { placeId: 5771, cityName: 'HAIMA', countryName: 'OMAN' },
  { placeId: 6021, cityName: 'IZKI', countryName: 'OMAN' },
  { placeId: 6210, cityName: 'KHASAB', countryName: 'OMAN' },
  { placeId: 6827, cityName: 'MARMUL', countryName: 'OMAN' },
  { placeId: 6852, cityName: 'MASIRAH', countryName: 'OMAN' },
  { placeId: 12117, cityName: 'MUKHAIZNA', countryName: 'OMAN' },
  { placeId: 2373, cityName: 'MUSCAT', countryName: 'OMAN' },
  { placeId: 5552, cityName: 'QARN ALAM', countryName: 'OMAN' },
  { placeId: 2374, cityName: 'SALALAH', countryName: 'OMAN' },
  { placeId: 8653, cityName: 'THUMRAIT', countryName: 'OMAN' },
  { placeId: 4299, cityName: 'BAHAWALPUR', countryName: 'PAKISTAN' },
  { placeId: 4336, cityName: 'BANNU', countryName: 'PAKISTAN' },
  { placeId: 4845, cityName: 'CHITRAL', countryName: 'PAKISTAN' },
  { placeId: 5052, cityName: 'DALBANDIN', countryName: 'PAKISTAN' },
  { placeId: 5155, cityName: 'DERA GHAZI KHAN', countryName: 'PAKISTAN' },
  { placeId: 5156, cityName: 'DERA ISMAIL KHAN', countryName: 'PAKISTAN' },
  { placeId: 5359, cityName: 'FAISALABAD', countryName: 'PAKISTAN' },
  { placeId: 5563, cityName: 'GILGIT', countryName: 'PAKISTAN' },
  { placeId: 5752, cityName: 'GWADAR', countryName: 'PAKISTAN' },
  { placeId: 5917, cityName: 'HYDERABAD', countryName: 'PAKISTAN' },
  { placeId: 6007, cityName: 'ISLAMABAD', countryName: 'PAKISTAN' },
  { placeId: 6066, cityName: 'JIWANI', countryName: 'PAKISTAN' },
  { placeId: 2375, cityName: 'KARACHI', countryName: 'PAKISTAN' },
  { placeId: 6213, cityName: 'KHUZDAR', countryName: 'PAKISTAN' },
  { placeId: 2376, cityName: 'LAHORE', countryName: 'PAKISTAN' },
  { placeId: 7002, cityName: 'MOENJODARO', countryName: 'PAKISTAN' },
  { placeId: 7129, cityName: 'MULTAN', countryName: 'PAKISTAN' },
  { placeId: 7149, cityName: 'MUZAFFARABAD', countryName: 'PAKISTAN' },
  { placeId: 2377, cityName: 'NAWABSHAH', countryName: 'PAKISTAN' },
  { placeId: 7419, cityName: 'ORMARA', countryName: 'PAKISTAN' },
  { placeId: 7548, cityName: 'PANJGUR', countryName: 'PAKISTAN' },
  { placeId: 7551, cityName: 'PARACHINAR', countryName: 'PAKISTAN' },
  { placeId: 7570, cityName: 'PASNI', countryName: 'PAKISTAN' },
  { placeId: 2378, cityName: 'PESHAWAR', countryName: 'PAKISTAN' },
  { placeId: 7828, cityName: 'QUETTA', countryName: 'PAKISTAN' },
  { placeId: 7507, cityName: 'RAHIM YAR KHAN', countryName: 'PAKISTAN' },
  { placeId: 7869, cityName: 'RAWALAKOT', countryName: 'PAKISTAN' },
  { placeId: 7513, cityName: 'SAIDU SHARIF', countryName: 'PAKISTAN' },
  { placeId: 8245, cityName: 'SIALKOT', countryName: 'PAKISTAN' },
  { placeId: 8285, cityName: 'SKARDU', countryName: 'PAKISTAN' },
  { placeId: 8167, cityName: 'SUKKUR', countryName: 'PAKISTAN' },
  { placeId: 8752, cityName: 'TURBAT', countryName: 'PAKISTAN' },
  { placeId: 9121, cityName: 'ZHOB', countryName: 'PAKISTAN' },
  { placeId: 2410, cityName: 'BABELTHUAP I', countryName: 'PALAU' },
  { placeId: 4310, cityName: 'BALBOA', countryName: 'PANAMA' },
  { placeId: 4515, cityName: 'BOCAS DEL TORO', countryName: 'PANAMA' },
  { placeId: 3994, cityName: 'CHANGUINOLA', countryName: 'PANAMA' },
  { placeId: 4846, cityName: 'CHITRE', countryName: 'PANAMA' },
  { placeId: 4928, cityName: 'COLON', countryName: 'PANAMA' },
  { placeId: 2312, cityName: 'DAVID', countryName: 'PANAMA' },
  { placeId: 6044, cityName: 'JAQUE', countryName: 'PANAMA' },
  { placeId: 2313, cityName: 'PANAMA CITY', countryName: 'PANAMA' },
  { placeId: 7919, cityName: 'RIO HATO', countryName: 'PANAMA' },
  { placeId: 8107, cityName: 'SANTIAGO', countryName: 'PANAMA' },
  { placeId: 8912, cityName: 'WANNUKANDI', countryName: 'PANAMA' },
  { placeId: 9508, cityName: 'AIOME', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 4, cityName: 'ALOTAU, PNG', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 9700, cityName: 'BALIMO', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 4625, cityName: 'BUKA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 9975, cityName: 'BULOLO', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 8, cityName: 'BWAGAOIA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 4831, cityName: 'CHIMBU', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 3, cityName: 'DARU', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 10711, cityName: 'FINSCHHAFEN', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 10864, cityName: 'GARAINA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 5567, cityName: 'GIRUA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 10914, cityName: 'GOBE', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 5142, cityName: 'GOROKA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 10983, cityName: 'GREEN RIVER', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 11030, cityName: 'GUSAP', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 5882, cityName: 'HOSKINS', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 11380, cityName: 'KANDRIAN', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 11391, cityName: 'KARIMUI', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 6, cityName: 'KAVIENG', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 11411, cityName: 'KAWITO', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 6204, cityName: 'KEREMA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 6225, cityName: 'KIKORI', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 6256, cityName: 'KIRIWINA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 5, cityName: 'KIUNGA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 11515, cityName: 'KOKODA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 9, cityName: 'LAE', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 6550, cityName: 'LIHIR, PAPA NEW GUINEA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 6693, cityName: 'MADANG', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 6936, cityName: 'MENDI', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 11952, cityName: 'MENYAMYA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 7, cityName: 'MOMOTE', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 7072, cityName: 'MORO', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 6297, cityName: 'MOUNT HAGEN', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 10, cityName: 'PORT MORESBY', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 11, cityName: 'RABAUL', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 13083, cityName: 'SIMBERI', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 8504, cityName: 'SULE', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 8534, cityName: 'TABUBIL', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 13339, cityName: 'TADJI', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 13364, cityName: 'TAPINI', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 8586, cityName: 'TARI', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 13383, cityName: 'TELEFOMIN', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 12, cityName: 'VANIMO', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 13655, cityName: 'WANIGELA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 8914, cityName: 'WAPENAMANDA', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 8180, cityName: 'WEWAK', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 9041, cityName: 'WITU', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 13810, cityName: 'WOITAPE', countryName: 'PAPUA NEW GUINEA' },
  { placeId: 2510, cityName: 'ASUNCION', countryName: 'PARAGUAY' },
  { placeId: 9792, cityName: 'BELLA VISTA NORTE', countryName: 'PARAGUAY' },
  { placeId: 2511, cityName: 'CIUDAD DEL ESTE', countryName: 'PARAGUAY' },
  { placeId: 4935, cityName: 'CONCEPCION', countryName: 'PARAGUAY' },
  { placeId: 5320, cityName: 'ENCARNACION', countryName: 'PARAGUAY' },
  { placeId: 5394, cityName: 'FILADELFIA', countryName: 'PARAGUAY' },
  { placeId: 10832, cityName: 'FUERTE OLIMPO', countryName: 'PARAGUAY' },
  { placeId: 5835, cityName: 'HERNANDARIAS', countryName: 'PARAGUAY' },
  { placeId: 6823, cityName: 'MARISCAL ESTIGARRIBIA', countryName: 'PARAGUAY' },
  { placeId: 7593, cityName: 'PEDRO JUAN CABALLERO', countryName: 'PARAGUAY' },
  { placeId: 8105, cityName: 'SANTA TERESA', countryName: 'PARAGUAY' },
  { placeId: 8856, cityName: 'VILLA HAYES', countryName: 'PARAGUAY' },
  { placeId: 4067, cityName: 'ALERTA', countryName: 'PERU' },
  { placeId: 4129, cityName: 'ANDAHUAYLAS', countryName: 'PERU' },
  { placeId: 4133, cityName: 'ANDOAS', countryName: 'PERU' },
  { placeId: 4159, cityName: 'ANTA HUARAZ', countryName: 'PERU' },
  { placeId: 2529, cityName: 'AREQUIPA', countryName: 'PERU' },
  { placeId: 4253, cityName: 'ATALAYA', countryName: 'PERU' },
  { placeId: 4262, cityName: 'ATICO', countryName: 'PERU' },
  { placeId: 3982, cityName: 'AYACUCHO', countryName: 'PERU' },
  { placeId: 4662, cityName: 'CABALLOCOCHA', countryName: 'PERU' },
  { placeId: 4675, cityName: 'CAJABAMBA', countryName: 'PERU' },
  { placeId: 4676, cityName: 'CAJAMARCA', countryName: 'PERU' },
  { placeId: 4783, cityName: 'CHACHAPOYAS', countryName: 'PERU' },
  { placeId: 2525, cityName: 'CHICLAYO', countryName: 'PERU' },
  { placeId: 4830, cityName: 'CHIMBOTE', countryName: 'PERU' },
  { placeId: 4847, cityName: 'CHIVAY', countryName: 'PERU' },
  { placeId: 10230, cityName: 'CIRO ALEGRIA', countryName: 'PERU' },
  { placeId: 4927, cityName: 'COLLIQUE', countryName: 'PERU' },
  { placeId: 4930, cityName: 'COLONIA ANGAMOS', countryName: 'PERU' },
  { placeId: 2534, cityName: 'CUZCO', countryName: 'PERU' },
  { placeId: 10852, cityName: 'GALILEA', countryName: 'PERU' },
  { placeId: 11018, cityName: 'GUEPPI', countryName: 'PERU' },
  { placeId: 11203, cityName: 'HUAMACHUCO', countryName: 'PERU' },
  { placeId: 5898, cityName: 'HUANCABAMBA', countryName: 'PERU' },
  { placeId: 5899, cityName: 'HUANUCO', countryName: 'PERU' },
  { placeId: 5950, cityName: 'ILO', countryName: 'PERU' },
  { placeId: 5960, cityName: 'INAPARI', countryName: 'PERU' },
  { placeId: 5977, cityName: 'INTUTO, PERU', countryName: 'PERU' },
  { placeId: 2528, cityName: 'IQUITOS', countryName: 'PERU' },
  { placeId: 5992, cityName: 'ISCOZASIN', countryName: 'PERU' },
  { placeId: 6033, cityName: 'JAEN', countryName: 'PERU' },
  { placeId: 6049, cityName: 'JAUJA', countryName: 'PERU' },
  { placeId: 6082, cityName: 'JUANJUI', countryName: 'PERU' },
  { placeId: 2527, cityName: 'JULIACA', countryName: 'PERU' },
  { placeId: 11629, cityName: 'LAS MALVINAS', countryName: 'PERU' },
  { placeId: 6528, cityName: 'LEON VELARDE', countryName: 'PERU' },
  { placeId: 2526, cityName: 'LIMA-CALLAO', countryName: 'PERU' },
  { placeId: 6557, cityName: 'LIMA', countryName: 'PERU' },
  { placeId: 6784, cityName: 'MANU', countryName: 'PERU' },
  { placeId: 7015, cityName: 'MOLLENDO', countryName: 'PERU' },
  { placeId: 7065, cityName: 'MOQUEGUA', countryName: 'PERU' },
  { placeId: 7202, cityName: 'NAZCA', countryName: 'PERU' },
  { placeId: 6510, cityName: 'NUEVO MUNDO', countryName: 'PERU' },
  { placeId: 7409, cityName: 'ORCOPAMPA', countryName: 'PERU' },
  { placeId: 7411, cityName: 'ORELLANA', countryName: 'PERU' },
  { placeId: 7446, cityName: 'OVENTENI', countryName: 'PERU' },
  { placeId: 7467, cityName: 'PACASMAYO', countryName: 'PERU' },
  { placeId: 7480, cityName: 'PALMAS DEL ESPINO', countryName: 'PERU' },
  { placeId: 7543, cityName: 'PAMPA HERMOSA', countryName: 'PERU' },
  { placeId: 7556, cityName: 'PARAMONGA', countryName: 'PERU' },
  { placeId: 7576, cityName: 'PATRIA', countryName: 'PERU' },
  { placeId: 2531, cityName: 'PISCO', countryName: 'PERU' },
  { placeId: 7667, cityName: 'PIURA', countryName: 'PERU' },
  { placeId: 7776, cityName: 'PUCALLPA', countryName: 'PERU' },
  { placeId: 7782, cityName: 'PUERTO BREU', countryName: 'PERU' },
  { placeId: 7786, cityName: 'PUERTO ESPERANZA', countryName: 'PERU' },
  { placeId: 7790, cityName: 'PUERTO MALDONADO', countryName: 'PERU' },
  { placeId: 7791, cityName: 'PUERTO OCOPA', countryName: 'PERU' },
  { placeId: 7833, cityName: 'QUINCEMIL', countryName: 'PERU' },
  { placeId: 7899, cityName: 'REQUENA', countryName: 'PERU' },
  { placeId: 7922, cityName: 'RIOJA', countryName: 'PERU' },
  { placeId: 7946, cityName: 'RODRIGUEZ DE MENDOZA', countryName: 'PERU' },
  { placeId: 8053, cityName: 'SAN JUAN DE MARCONA', countryName: 'PERU' },
  { placeId: 8062, cityName: 'SAN RAFAEL', countryName: 'PERU' },
  { placeId: 8063, cityName: 'SAN RAMON', countryName: 'PERU' },
  { placeId: 8190, cityName: 'SEPAHUA', countryName: 'PERU' },
  { placeId: 2532, cityName: 'TACNA', countryName: 'PERU' },
  { placeId: 2533, cityName: 'TALARA', countryName: 'PERU' },
  { placeId: 8574, cityName: 'TANANTA', countryName: 'PERU' },
  { placeId: 8584, cityName: 'TARAPOTO', countryName: 'PERU' },
  { placeId: 13390, cityName: 'TENIENTE BERGERIE', countryName: 'PERU' },
  { placeId: 8621, cityName: 'TERESITA', countryName: 'PERU' },
  { placeId: 8670, cityName: 'TINGO MARIA', countryName: 'PERU' },
  { placeId: 8679, cityName: 'TOCACHE', countryName: 'PERU' },
  { placeId: 8691, cityName: 'TOURNAVISTA', countryName: 'PERU' },
  { placeId: 2530, cityName: 'TRUJILLO', countryName: 'PERU' },
  { placeId: 8743, cityName: 'TUMBES', countryName: 'PERU' },
  { placeId: 8771, cityName: 'UCHIZA', countryName: 'PERU' },
  { placeId: 8840, cityName: 'VICCO', countryName: 'PERU' },
  { placeId: 13612, cityName: 'VILCASHUAMAN', countryName: 'PERU' },
  { placeId: 9078, cityName: 'YAURI, PERU', countryName: 'PERU' },
  { placeId: 9099, cityName: 'YURIMAGUAS', countryName: 'PERU' },
  { placeId: 4060, cityName: 'ALABAT', countryName: 'PHILIPPINES' },
  { placeId: 2444, cityName: 'ANGELES CITY', countryName: 'PHILIPPINES' },
  { placeId: 4284, cityName: 'BACON', countryName: 'PHILIPPINES' },
  { placeId: 4292, cityName: 'BAGABAG', countryName: 'PHILIPPINES' },
  { placeId: 4298, cityName: 'BAGUIO', countryName: 'PHILIPPINES' },
  { placeId: 4313, cityName: 'BALER', countryName: 'PHILIPPINES' },
  { placeId: 4365, cityName: 'BASCO', countryName: 'PHILIPPINES' },
  { placeId: 4492, cityName: 'BISLIG', countryName: 'PHILIPPINES' },
  { placeId: 4536, cityName: 'BONGAO', countryName: 'PHILIPPINES' },
  { placeId: 4549, cityName: 'BORONGAN', countryName: 'PHILIPPINES' },
  { placeId: 4629, cityName: 'BULAN', countryName: 'PHILIPPINES' },
  { placeId: 4655, cityName: 'BUSUANGA', countryName: 'PHILIPPINES' },
  { placeId: 4661, cityName: 'BUTUAN', countryName: 'PHILIPPINES' },
  { placeId: 4672, cityName: 'CAGAYAN DE ORO', countryName: 'PHILIPPINES' },
  { placeId: 4673, cityName: 'CAGAYAN DE SULU', countryName: 'PHILIPPINES' },
  { placeId: 4679, cityName: 'CALAPAN', countryName: 'PHILIPPINES' },
  { placeId: 4680, cityName: 'CALBAYOG', countryName: 'PHILIPPINES' },
  { placeId: 4759, cityName: 'CATARMAN', countryName: 'PHILIPPINES' },
  { placeId: 4760, cityName: 'CATBALOGAN', countryName: 'PHILIPPINES' },
  { placeId: 4761, cityName: 'CAUAYAN', countryName: 'PHILIPPINES' },
  { placeId: 4972, cityName: 'COTABATO', countryName: 'PHILIPPINES' },
  { placeId: 5016, cityName: 'CUYO', countryName: 'PHILIPPINES' },
  { placeId: 5019, cityName: 'DAET', countryName: 'PHILIPPINES' },
  { placeId: 2447, cityName: 'DAVAO', countryName: 'PHILIPPINES' },
  { placeId: 5098, cityName: 'DEL CARMEN', countryName: 'PHILIPPINES' },
  { placeId: 5182, cityName: 'DIPOLOG', countryName: 'PHILIPPINES' },
  { placeId: 5217, cityName: 'DUMAGUETE', countryName: 'PHILIPPINES' },
  { placeId: 5417, cityName: 'FLORIDABLANCA', countryName: 'PHILIPPINES' },
  { placeId: 5434, cityName: 'FORT MAGSAYSAY APT', countryName: 'PHILIPPINES' },
  { placeId: 5537, cityName: 'GASAN', countryName: 'PHILIPPINES' },
  { placeId: 5547, cityName: 'GEN SANTOS', countryName: 'PHILIPPINES' },
  { placeId: 5549, cityName: 'GENERAL SANTOS CITY', countryName: 'PHILIPPINES' },
  { placeId: 5738, cityName: 'GUIUAN', countryName: 'PHILIPPINES' },
  { placeId: 5850, cityName: 'HILONGOS', countryName: 'PHILIPPINES' },
  { placeId: 5919, cityName: 'IBA', countryName: 'PHILIPPINES' },
  { placeId: 5948, cityName: 'ILIGAN CITY', countryName: 'PHILIPPINES' },
  { placeId: 5951, cityName: 'ILOILO', countryName: 'PHILIPPINES' },
  { placeId: 5985, cityName: 'IPIL', countryName: 'PHILIPPINES' },
  { placeId: 6011, cityName: 'ITBAYAT', countryName: 'PHILIPPINES' },
  { placeId: 6073, cityName: 'JOLO', countryName: 'PHILIPPINES' },
  { placeId: 6074, cityName: 'JOMALIG', countryName: 'PHILIPPINES' },
  { placeId: 6121, cityName: 'KALIBO', countryName: 'PHILIPPINES' },
  { placeId: 2445, cityName: 'LAOAG', countryName: 'PHILIPPINES' },
  { placeId: 2449, cityName: 'LAPU-LAPU', countryName: 'PHILIPPINES' },
  { placeId: 6521, cityName: 'LEGAZPI', countryName: 'PHILIPPINES' },
  { placeId: 6556, cityName: 'LILOY', countryName: 'PHILIPPINES' },
  { placeId: 6573, cityName: 'LINGAYEN', countryName: 'PHILIPPINES' },
  { placeId: 6574, cityName: 'LIPA', countryName: 'PHILIPPINES' },
  { placeId: 6640, cityName: 'LUBANG', countryName: 'PHILIPPINES' },
  { placeId: 6650, cityName: 'LUCENA', countryName: 'PHILIPPINES' },
  { placeId: 6687, cityName: 'MAASIN', countryName: 'PHILIPPINES' },
  { placeId: 6728, cityName: 'MALABANG', countryName: 'PHILIPPINES' },
  { placeId: 6735, cityName: 'MALAY', countryName: 'PHILIPPINES' },
  { placeId: 6736, cityName: 'MALAYBALAY', countryName: 'PHILIPPINES' },
  { placeId: 6741, cityName: 'MAMBAJAO', countryName: 'PHILIPPINES' },
  { placeId: 6742, cityName: 'MAMBURAO', countryName: 'PHILIPPINES' },
  { placeId: 2446, cityName: 'MANILA', countryName: 'PHILIPPINES' },
  { placeId: 6780, cityName: 'MANSALAY', countryName: 'PHILIPPINES' },
  { placeId: 6848, cityName: 'MASBATE', countryName: 'PHILIPPINES' },
  { placeId: 6870, cityName: 'MATI', countryName: 'PHILIPPINES' },
  { placeId: 7198, cityName: 'NAVAL', countryName: 'PHILIPPINES' },
  { placeId: 7420, cityName: 'ORMOC', countryName: 'PHILIPPINES' },
  { placeId: 7464, cityName: 'OZAMIS', countryName: 'PHILIPPINES' },
  { placeId: 7468, cityName: 'PAGADIAN', countryName: 'PHILIPPINES' },
  { placeId: 7478, cityName: 'PALANAN', countryName: 'PHILIPPINES' },
  { placeId: 7644, cityName: 'PILI', countryName: 'PHILIPPINES' },
  { placeId: 7672, cityName: 'PLARIDEL', countryName: 'PHILIPPINES' },
  { placeId: 7792, cityName: 'PUERTO PRINCESA', countryName: 'PHILIPPINES' },
  { placeId: 7959, cityName: 'ROSALES', countryName: 'PHILIPPINES' },
  { placeId: 7971, cityName: 'ROXAS', countryName: 'PHILIPPINES' },
  { placeId: 8041, cityName: 'SAN FERNANDO', countryName: 'PHILIPPINES' },
  { placeId: 8049, cityName: 'SAN JOSE DE BUENA VISTA', countryName: 'PHILIPPINES' },
  { placeId: 8048, cityName: 'SAN JOSE', countryName: 'PHILIPPINES' },
  { placeId: 8257, cityName: 'SILAY', countryName: 'PHILIPPINES' },
  { placeId: 8271, cityName: 'SIOCON', countryName: 'PHILIPPINES' },
  { placeId: 8275, cityName: 'SIQUIJOR', countryName: 'PHILIPPINES' },
  { placeId: 2443, cityName: 'SUBIC BAY', countryName: 'PHILIPPINES' },
  { placeId: 8522, cityName: 'SURALLAH', countryName: 'PHILIPPINES' },
  { placeId: 8524, cityName: 'SURIGAO', countryName: 'PHILIPPINES' },
  { placeId: 8530, cityName: 'TABLAS ISLAND', countryName: 'PHILIPPINES' },
  { placeId: 8539, cityName: 'TACLOBAN', countryName: 'PHILIPPINES' },
  { placeId: 8545, cityName: 'TAGBILARAN', countryName: 'PHILIPPINES' },
  { placeId: 8575, cityName: 'TANDAG', countryName: 'PHILIPPINES' },
  { placeId: 8735, cityName: 'TUGUEGARAO', countryName: 'PHILIPPINES' },
  { placeId: 8769, cityName: 'UBAY', countryName: 'PHILIPPINES' },
  { placeId: 8850, cityName: 'VIGAN', countryName: 'PHILIPPINES' },
  { placeId: 8865, cityName: 'VIRAC', countryName: 'PHILIPPINES' },
  { placeId: 2448, cityName: 'ZAMBOANGA', countryName: 'PHILIPPINES' },
  { placeId: 924, cityName: 'BIALYSTOK', countryName: 'POLAND' },
  { placeId: 4462, cityName: 'BIELSKO-BIALA', countryName: 'POLAND' },
  { placeId: 925, cityName: 'BYDGOSZCZ', countryName: 'POLAND' },
  { placeId: 4781, cityName: 'CEWICE', countryName: 'POLAND' },
  { placeId: 5067, cityName: 'DARLOWO', countryName: 'POLAND' },
  { placeId: 5085, cityName: 'DEBLIN', countryName: 'POLAND' },
  { placeId: 5300, cityName: 'ELBLAG', countryName: 'POLAND' },
  { placeId: 926, cityName: 'GDANSK', countryName: 'POLAND' },
  { placeId: 5586, cityName: 'GLIWICE', countryName: 'POLAND' },
  { placeId: 9190, cityName: 'GORASZKA', countryName: 'POLAND' },
  { placeId: 9201, cityName: 'GRADY', countryName: 'POLAND' },
  { placeId: 927, cityName: 'GRUDZIADZ', countryName: 'POLAND' },
  { placeId: 5976, cityName: 'INOWROCLAW', countryName: 'POLAND' },
  { placeId: 6019, cityName: 'IWONICZ', countryName: 'POLAND' },
  { placeId: 928, cityName: 'JELENIA GORA', countryName: 'POLAND' },
  { placeId: 9202, cityName: 'KANIOW', countryName: 'POLAND' },
  { placeId: 931, cityName: 'KATOWICE', countryName: 'POLAND' },
  { placeId: 9189, cityName: 'KETRZYN', countryName: 'POLAND' },
  { placeId: 929, cityName: 'KIELCE', countryName: 'POLAND' },
  { placeId: 11523, cityName: 'KORNE', countryName: 'POLAND' },
  { placeId: 930, cityName: 'KRAKOW', countryName: 'POLAND' },
  { placeId: 932, cityName: 'KROSNO', countryName: 'POLAND' },
  { placeId: 6448, cityName: 'LASK', countryName: 'POLAND' },
  { placeId: 6471, cityName: 'LECZYCA', countryName: 'POLAND' },
  { placeId: 6523, cityName: 'LEGNICA', countryName: 'POLAND' },
  { placeId: 934, cityName: 'LESZNO', countryName: 'POLAND' },
  { placeId: 933, cityName: 'LODZ', countryName: 'POLAND' },
  { placeId: 6644, cityName: 'LUBIN', countryName: 'POLAND' },
  { placeId: 6645, cityName: 'LUBLIN', countryName: 'POLAND' },
  { placeId: 6737, cityName: 'MALBORK', countryName: 'POLAND' },
  { placeId: 8342, cityName: 'MAZURY', countryName: 'POLAND' },
  { placeId: 935, cityName: 'MIELEC', countryName: 'POLAND' },
  { placeId: 6992, cityName: 'MINSK MAZOWIECKI', countryName: 'POLAND' },
  { placeId: 6996, cityName: 'MIROSLAWIEC', countryName: 'POLAND' },
  { placeId: 6504, cityName: 'NOWY SACZ', countryName: 'POLAND' },
  { placeId: 936, cityName: 'NOWY TARG', countryName: 'POLAND' },
  { placeId: 7373, cityName: 'OKSYWIE', countryName: 'POLAND' },
  { placeId: 937, cityName: 'OLSZTYN', countryName: 'POLAND' },
  { placeId: 7397, cityName: 'OPOLE', countryName: 'POLAND' },
  { placeId: 7295, cityName: 'OSTROW WIELKOPOLSKI', countryName: 'POLAND' },
  { placeId: 7649, cityName: 'PINCZOW', countryName: 'POLAND' },
  { placeId: 939, cityName: 'PIOTRKOW TRYBUNALSKI', countryName: 'POLAND' },
  { placeId: 7311, cityName: 'PLOCK', countryName: 'POLAND' },
  { placeId: 7679, cityName: 'POBIEDNIK WIELKI', countryName: 'POLAND' },
  { placeId: 7752, cityName: 'POWIDZ', countryName: 'POLAND' },
  { placeId: 938, cityName: 'POZNAN', countryName: 'POLAND' },
  { placeId: 7775, cityName: 'PRUSZCZ GDANSKI', countryName: 'POLAND' },
  { placeId: 7499, cityName: 'RADOM', countryName: 'POLAND' },
  { placeId: 7976, cityName: 'RUDNIKI', countryName: 'POLAND' },
  { placeId: 940, cityName: 'RYBNIK', countryName: 'POLAND' },
  { placeId: 941, cityName: 'RZESZOW', countryName: 'POLAND' },
  { placeId: 8292, cityName: 'SLUPSK', countryName: 'POLAND' },
  { placeId: 8454, cityName: 'STALOWA WOLA', countryName: 'POLAND' },
  { placeId: 8329, cityName: 'SUWALKI', countryName: 'POLAND' },
  { placeId: 944, cityName: 'SWIDNIK', countryName: 'POLAND' },
  { placeId: 943, cityName: 'SWIDWIN', countryName: 'POLAND' },
  { placeId: 942, cityName: 'SZCZECIN', countryName: 'POLAND' },
  { placeId: 8355, cityName: 'TOMASZOW MAZOWIECKI', countryName: 'POLAND' },
  { placeId: 8684, cityName: 'TORUN', countryName: 'POLAND' },
  { placeId: 923, cityName: 'WARSAW', countryName: 'POLAND' },
  { placeId: 8939, cityName: 'WATOROWO', countryName: 'POLAND' },
  { placeId: 9042, cityName: 'WLOCLAWEK', countryName: 'POLAND' },
  { placeId: 945, cityName: 'WROCLAW', countryName: 'POLAND' },
  { placeId: 9105, cityName: 'ZAMOSC', countryName: 'POLAND' },
  { placeId: 947, cityName: 'ZAR', countryName: 'POLAND' },
  { placeId: 946, cityName: 'ZIELONA GORA', countryName: 'POLAND' },
  { placeId: 2118, cityName: 'ALVERCA', countryName: 'PORTUGAL' },
  { placeId: 3977, cityName: 'AVEIRO', countryName: 'PORTUGAL' },
  { placeId: 2121, cityName: 'BEJA', countryName: 'PORTUGAL' },
  { placeId: 2122, cityName: 'BRAGA', countryName: 'PORTUGAL' },
  { placeId: 2120, cityName: 'BRAGANCA', countryName: 'PORTUGAL' },
  { placeId: 2125, cityName: 'CASCAIS', countryName: 'PORTUGAL' },
  { placeId: 2123, cityName: 'CHAVES', countryName: 'PORTUGAL' },
  { placeId: 2124, cityName: 'COIMBRA', countryName: 'PORTUGAL' },
  { placeId: 2126, cityName: 'COVILHA', countryName: 'PORTUGAL' },
  { placeId: 2132, cityName: 'ESPINHO', countryName: 'PORTUGAL' },
  { placeId: 2127, cityName: 'EVORA', countryName: 'PORTUGAL' },
  { placeId: 2129, cityName: 'FARO', countryName: 'PORTUGAL' },
  { placeId: 10704, cityName: 'FERREIRA DO ALENTEJO', countryName: 'PORTUGAL' },
  { placeId: 2133, cityName: 'LEIRIA', countryName: 'PORTUGAL' },
  { placeId: 2148, cityName: 'LISBON', countryName: 'PORTUGAL' },
  { placeId: 2135, cityName: 'LOUSA', countryName: 'PORTUGAL' },
  { placeId: 2136, cityName: 'MADEIRA', countryName: 'PORTUGAL' },
  { placeId: 2138, cityName: 'MIRANDELA', countryName: 'PORTUGAL' },
  { placeId: 7005, cityName: 'MOGADOURO', countryName: 'PORTUGAL' },
  { placeId: 2137, cityName: 'MONFORTINHO', countryName: 'PORTUGAL' },
  { placeId: 7039, cityName: 'MONTARGIL', countryName: 'PORTUGAL' },
  { placeId: 2139, cityName: 'MONTE REAL', countryName: 'PORTUGAL' },
  { placeId: 2140, cityName: 'MONTIJO', countryName: 'PORTUGAL' },
  { placeId: 2141, cityName: 'OVAR', countryName: 'PORTUGAL' },
  { placeId: 12578, cityName: 'PONTE DE SOR', countryName: 'PORTUGAL' },
  { placeId: 2145, cityName: 'PORTIMAO', countryName: 'PORTUGAL' },
  { placeId: 2147, cityName: 'PORTO SANTO', countryName: 'PORTUGAL' },
  { placeId: 2146, cityName: 'PORTO', countryName: 'PORTUGAL' },
  { placeId: 2149, cityName: 'PRAIA VERDE', countryName: 'PORTUGAL' },
  { placeId: 12655, cityName: 'PROENCA-A-NOVA', countryName: 'PORTUGAL' },
  { placeId: 2150, cityName: 'SANTA CRUZ', countryName: 'PORTUGAL' },
  { placeId: 8106, cityName: 'SANTAREM', countryName: 'PORTUGAL' },
  { placeId: 12999, cityName: 'SEIA', countryName: 'PORTUGAL' },
  { placeId: 2151, cityName: 'SINES', countryName: 'PORTUGAL' },
  { placeId: 2153, cityName: 'SINTRA', countryName: 'PORTUGAL' },
  { placeId: 2154, cityName: 'TANCOS', countryName: 'PORTUGAL' },
  { placeId: 2155, cityName: 'VILA REAL', countryName: 'PORTUGAL' },
  { placeId: 8854, cityName: 'VILAR DA LUZ', countryName: 'PORTUGAL' },
  { placeId: 2156, cityName: 'VISEU', countryName: 'PORTUGAL' },
  { placeId: 2543, cityName: 'AGUADILLA', countryName: 'PUERTO RICO' },
  { placeId: 9612, cityName: 'ARECIBO', countryName: 'PUERTO RICO' },
  { placeId: 10109, cityName: 'CEIBA', countryName: 'PUERTO RICO' },
  { placeId: 5360, cityName: 'FAJARDO', countryName: 'PUERTO RICO' },
  { placeId: 6001, cityName: 'ISLA DE CULEBRA', countryName: 'PUERTO RICO' },
  { placeId: 6003, cityName: 'ISLA DE VIEQUES', countryName: 'PUERTO RICO' },
  { placeId: 6886, cityName: 'MAYAGUEZ', countryName: 'PUERTO RICO' },
  { placeId: 2544, cityName: 'PONCE', countryName: 'PUERTO RICO' },
  { placeId: 2545, cityName: 'SAN JUAN', countryName: 'PUERTO RICO' },
  { placeId: 4050, cityName: 'AL KHOR', countryName: 'QATAR' },
  { placeId: 4058, cityName: 'AL-UDAID', countryName: 'QATAR' },
  { placeId: 2382, cityName: 'DOHA', countryName: 'QATAR' },
  { placeId: 1136, cityName: 'ST DENIS', countryName: 'REUNION' },
  { placeId: 13203, cityName: 'ST PIERRE', countryName: 'REUNION' },
  { placeId: 2161, cityName: 'ARAD', countryName: 'ROMANIA' },
  { placeId: 2162, cityName: 'BACAU', countryName: 'ROMANIA' },
  { placeId: 2163, cityName: 'BAIA MARE', countryName: 'ROMANIA' },
  { placeId: 2164, cityName: 'BUCHAREST', countryName: 'ROMANIA' },
  { placeId: 2167, cityName: 'CARANSEBES', countryName: 'ROMANIA' },
  { placeId: 2166, cityName: 'CLUJ-NAPOCA', countryName: 'ROMANIA' },
  { placeId: 2165, cityName: 'CONSTANTA', countryName: 'ROMANIA' },
  { placeId: 2168, cityName: 'CRAIOVA', countryName: 'ROMANIA' },
  { placeId: 2169, cityName: 'IASI', countryName: 'ROMANIA' },
  { placeId: 2170, cityName: 'ORADEA', countryName: 'ROMANIA' },
  { placeId: 2172, cityName: 'SATU MARE', countryName: 'ROMANIA' },
  { placeId: 2171, cityName: 'SIBIU', countryName: 'ROMANIA' },
  { placeId: 2173, cityName: 'SUCEAVA', countryName: 'ROMANIA' },
  { placeId: 2175, cityName: 'TARGU MURES', countryName: 'ROMANIA' },
  { placeId: 2176, cityName: 'TIMISOARA', countryName: 'ROMANIA' },
  { placeId: 2174, cityName: 'TULCEA', countryName: 'ROMANIA' },
  { placeId: 8759, cityName: 'TUZLA', countryName: 'ROMANIA' },
  { placeId: 2617, cityName: 'ABAKAN', countryName: 'RUSSIA' },
  { placeId: 9532, cityName: 'ALDAN', countryName: 'RUSSIA' },
  { placeId: 2580, cityName: 'ANADYR', countryName: 'RUSSIA' },
  { placeId: 2625, cityName: 'ANAPA', countryName: 'RUSSIA' },
  { placeId: 2605, cityName: 'ARKHANGELSK', countryName: 'RUSSIA' },
  { placeId: 2634, cityName: 'ASTRAKHAN', countryName: 'RUSSIA' },
  { placeId: 2618, cityName: 'BARNAUL', countryName: 'RUSSIA' },
  { placeId: 9743, cityName: 'BATAGAY', countryName: 'RUSSIA' },
  { placeId: 2666, cityName: 'BEGISHEVO', countryName: 'RUSSIA' },
  { placeId: 2821, cityName: 'BELGOROD', countryName: 'RUSSIA' },
  { placeId: 9216, cityName: 'BELOYARSKIY', countryName: 'RUSSIA' },
  { placeId: 2578, cityName: 'BLAGOVESHCHENSK', countryName: 'RUSSIA' },
  { placeId: 2586, cityName: 'BRATSK', countryName: 'RUSSIA' },
  { placeId: 2660, cityName: 'BRYANSK', countryName: 'RUSSIA' },
  { placeId: 9215, cityName: 'BUGULMA', countryName: 'RUSSIA' },
  { placeId: 3726, cityName: 'CHEBOKSARY', countryName: 'RUSSIA' },
  { placeId: 2637, cityName: 'CHELYABINSK', countryName: 'RUSSIA' },
  { placeId: 9214, cityName: 'CHEREMSHANKA', countryName: 'RUSSIA' },
  { placeId: 9165, cityName: 'CHEREPOVETS', countryName: 'RUSSIA' },
  { placeId: 10169, cityName: 'CHERSKY', countryName: 'RUSSIA' },
  { placeId: 2585, cityName: 'CHITA', countryName: 'RUSSIA' },
  { placeId: 10209, cityName: 'CHOKURDAKH', countryName: 'RUSSIA' },
  { placeId: 2635, cityName: 'ELISTA', countryName: 'RUSSIA' },
  { placeId: 9213, cityName: 'GELENDZHIK', countryName: 'RUSSIA' },
  {
    placeId: 9477, cityName: 'GORNO-ALTAYSK', state: 'ALTAI REPUBLIC', countryName: 'RUSSIA',
  },
  { placeId: 9166, cityName: 'GROZNY', countryName: 'RUSSIA' },
  { placeId: 11230, cityName: 'IGARKA', countryName: 'RUSSIA' },
  { placeId: 11274, cityName: 'IRKUTSK-2', countryName: 'RUSSIA' },
  { placeId: 2587, cityName: 'IRKUTSK', countryName: 'RUSSIA' },
  { placeId: 9173, cityName: 'IVANOVO', countryName: 'RUSSIA' },
  { placeId: 9148, cityName: 'IZHEVSK', countryName: 'RUSSIA' },
  { placeId: 2613, cityName: 'KALININGRAD', countryName: 'RUSSIA' },
  { placeId: 2665, cityName: 'KAZAN', countryName: 'RUSSIA' },
  { placeId: 2619, cityName: 'KEMEROVO', countryName: 'RUSSIA' },
  { placeId: 11432, cityName: 'KEPERVEYEM', countryName: 'RUSSIA' },
  { placeId: 2579, cityName: 'KHABAROVSK', countryName: 'RUSSIA' },
  { placeId: 2640, cityName: 'KHANTY-MANSIYSK', countryName: 'RUSSIA' },
  { placeId: 11445, cityName: 'KHATANGA', countryName: 'RUSSIA' },
  { placeId: 11471, cityName: 'KIMRY', countryName: 'RUSSIA' },
  { placeId: 11491, cityName: 'KIRENSK', countryName: 'RUSSIA' },
  { placeId: 9176, cityName: 'KIROV', countryName: 'RUSSIA' },
  { placeId: 9231, cityName: 'KIROVSK', countryName: 'RUSSIA' },
  { placeId: 2644, cityName: 'KOGALYM', countryName: 'RUSSIA' },
  { placeId: 11519, cityName: 'KOMSOMOLSK-NA-AMURE', countryName: 'RUSSIA' },
  { placeId: 9218, cityName: 'KOSTROMA', countryName: 'RUSSIA' },
  { placeId: 11526, cityName: 'KOTLAS', countryName: 'RUSSIA' },
  { placeId: 2626, cityName: 'KRASNODAR', countryName: 'RUSSIA' },
  { placeId: 2620, cityName: 'KRASNOYARSK', countryName: 'RUSSIA' },
  { placeId: 9217, cityName: 'KURGAN', countryName: 'RUSSIA' },
  { placeId: 14149, cityName: 'KURILSK', countryName: 'RUSSIA' },
  { placeId: 3725, cityName: 'KURSK', countryName: 'RUSSIA' },
  { placeId: 9154, cityName: 'KYZYL', countryName: 'RUSSIA' },
  { placeId: 11666, cityName: 'LENSK', countryName: 'RUSSIA' },
  { placeId: 9162, cityName: 'LIPETSK', countryName: 'RUSSIA' },
  { placeId: 2581, cityName: 'MAGADAN', countryName: 'RUSSIA' },
  { placeId: 11797, cityName: 'MAGAN', countryName: 'RUSSIA' },
  { placeId: 2638, cityName: 'MAGNITOGORSK', countryName: 'RUSSIA' },
  { placeId: 2628, cityName: 'MAKHACHKALA', countryName: 'RUSSIA' },
  { placeId: 11865, cityName: 'MARKOVO', countryName: 'RUSSIA' },
  { placeId: 2627, cityName: 'MAYKOP', countryName: 'RUSSIA' },
  { placeId: 2629, cityName: 'MINERALNYYE VODY', countryName: 'RUSSIA' },
  { placeId: 2574, cityName: 'MIRNY', countryName: 'RUSSIA' },
  { placeId: 2661, cityName: 'MOSCOW', countryName: 'RUSSIA' },
  { placeId: 2608, cityName: 'MURMANSK', countryName: 'RUSSIA' },
  { placeId: 12140, cityName: 'MYS KAMENYY', countryName: 'RUSSIA' },
  { placeId: 2641, cityName: 'NADYM', countryName: 'RUSSIA' },
  { placeId: 2630, cityName: 'NALCHIK', countryName: 'RUSSIA' },
  { placeId: 2606, cityName: 'NARYAN-MAR', countryName: 'RUSSIA' },
  { placeId: 9160, cityName: 'NERYUNGRI', countryName: 'RUSSIA' },
  { placeId: 12227, cityName: 'NIKOLAYEVSK NA AMURE', countryName: 'RUSSIA' },
  { placeId: 2642, cityName: 'NIZHNEVARTOVSK', countryName: 'RUSSIA' },
  { placeId: 2664, cityName: 'NIZHNY NOVGOROD', countryName: 'RUSSIA' },
  { placeId: 2624, cityName: 'NORILSK', countryName: 'RUSSIA' },
  { placeId: 2623, cityName: 'NOVOKUZNETSK', countryName: 'RUSSIA' },
  { placeId: 2621, cityName: 'NOVOSIBIRSK', countryName: 'RUSSIA' },
  { placeId: 2850, cityName: 'NOVY URENGOY', countryName: 'RUSSIA' },
  { placeId: 9159, cityName: 'NOYABRSK', countryName: 'RUSSIA' },
  { placeId: 9153, cityName: 'NYAGAN', countryName: 'RUSSIA' },
  { placeId: 12284, cityName: 'NYURBA', countryName: 'RUSSIA' },
  { placeId: 12314, cityName: 'OKHOTSK', countryName: 'RUSSIA' },
  { placeId: 12324, cityName: 'OLEKMINSK', countryName: 'RUSSIA' },
  { placeId: 12325, cityName: 'OLENYOK', countryName: 'RUSSIA' },
  { placeId: 12337, cityName: 'OMOLON', countryName: 'RUSSIA' },
  { placeId: 2622, cityName: 'OMSK', countryName: 'RUSSIA' },
  { placeId: 2668, cityName: 'ORENBURG', countryName: 'RUSSIA' },
  { placeId: 2669, cityName: 'ORSK', countryName: 'RUSSIA' },
  { placeId: 12466, cityName: 'PECHORA', countryName: 'RUSSIA' },
  { placeId: 2670, cityName: 'PENZA', countryName: 'RUSSIA' },
  { placeId: 2643, cityName: 'PERM', countryName: 'RUSSIA' },
  { placeId: 2582, cityName: 'PETROPAVLOVSK-KAMCHATSKY', countryName: 'RUSSIA' },
  { placeId: 2835, cityName: 'PETROZAVODSK', countryName: 'RUSSIA' },
  { placeId: 9207, cityName: 'PEVEK', countryName: 'RUSSIA' },
  { placeId: 12561, cityName: 'PODKAMENNAYA TUNGUSKA', countryName: 'RUSSIA' },
  { placeId: 2573, cityName: 'POLIARNY', countryName: 'RUSSIA' },
  { placeId: 12661, cityName: 'PROVIDENIYA BAY', countryName: 'RUSSIA' },
  { placeId: 2609, cityName: 'PSKOV', countryName: 'RUSSIA' },
  { placeId: 2632, cityName: 'ROSTOV-NA-DONU', countryName: 'RUSSIA' },
  { placeId: 9232, cityName: 'RYBINSK', countryName: 'RUSSIA' },
  { placeId: 2639, cityName: 'SALEKHARD', countryName: 'RUSSIA' },
  { placeId: 2673, cityName: 'SAMARA', countryName: 'RUSSIA' },
  { placeId: 9171, cityName: 'SARANSK', countryName: 'RUSSIA' },
  { placeId: 2671, cityName: 'SARATOV', countryName: 'RUSSIA' },
  { placeId: 13024, cityName: 'SEYMCHAN', countryName: 'RUSSIA' },
  { placeId: 13110, cityName: 'SLEPTSOVSKAYA', countryName: 'RUSSIA' },
  { placeId: 2633, cityName: 'SOCHI', countryName: 'RUSSIA' },
  { placeId: 13914, cityName: 'SOLTSY', countryName: 'RUSSIA' },
  { placeId: 13911, cityName: 'SOVETSKAYA GAVAN', countryName: 'RUSSIA' },
  { placeId: 9206, cityName: 'SOVETSKIY', countryName: 'RUSSIA' },
  { placeId: 2607, cityName: 'ST PETERSBURG', countryName: 'RUSSIA' },
  { placeId: 13243, cityName: 'STARAYA RUSSA', countryName: 'RUSSIA' },
  { placeId: 9205, cityName: 'STARY OSKOL', countryName: 'RUSSIA' },
  { placeId: 2631, cityName: 'STAVROPOL', countryName: 'RUSSIA' },
  { placeId: 13283, cityName: 'STREZHEVOY', countryName: 'RUSSIA' },
  { placeId: 2645, cityName: 'SURGUT', countryName: 'RUSSIA' },
  { placeId: 2663, cityName: 'SYKTYVKAR', countryName: 'RUSSIA' },
  { placeId: 9208, cityName: 'TAGANROG', countryName: 'RUSSIA' },
  { placeId: 9169, cityName: 'TAMBOV', countryName: 'RUSSIA' },
  { placeId: 9212, cityName: 'TIKSI', countryName: 'RUSSIA' },
  { placeId: 9149, cityName: 'TOMSK', countryName: 'RUSSIA' },
  { placeId: 9481, cityName: 'TYNDA', countryName: 'RUSSIA' },
  { placeId: 13528, cityName: 'TYOPLY KLYUCH', countryName: 'RUSSIA' },
  { placeId: 2647, cityName: 'TYUMEN', countryName: 'RUSSIA' },
  { placeId: 2672, cityName: 'UFA', countryName: 'RUSSIA' },
  { placeId: 9164, cityName: 'UKHTA', countryName: 'RUSSIA' },
  { placeId: 2588, cityName: 'ULAN-UDE', countryName: 'RUSSIA' },
  { placeId: 2667, cityName: 'ULYANOVSK', countryName: 'RUSSIA' },
  { placeId: 9478, cityName: 'URAY', countryName: 'RUSSIA' },
  { placeId: 9175, cityName: 'USINSK', countryName: 'RUSSIA' },
  { placeId: 9168, cityName: 'UST-KUT', countryName: 'RUSSIA' },
  { placeId: 13555, cityName: 'UST-MAYA', countryName: 'RUSSIA' },
  { placeId: 2851, cityName: 'VLADIKAVKAZ', countryName: 'RUSSIA' },
  { placeId: 2584, cityName: 'VLADIVOSTOK', countryName: 'RUSSIA' },
  { placeId: 2636, cityName: 'VOLGOGRAD', countryName: 'RUSSIA' },
  { placeId: 9211, cityName: 'VOLOGDA', countryName: 'RUSSIA' },
  { placeId: 9210, cityName: 'VORKUTA', countryName: 'RUSSIA' },
  { placeId: 2662, cityName: 'VORONEZH', countryName: 'RUSSIA' },
  { placeId: 2572, cityName: 'YAKUTSK', countryName: 'RUSSIA' },
  { placeId: 13845, cityName: 'YAMBURG', countryName: 'RUSSIA' },
  { placeId: 2832, cityName: 'YAROSLAVL', countryName: 'RUSSIA' },
  { placeId: 2646, cityName: 'YEKATERINBURG', countryName: 'RUSSIA' },
  { placeId: 13859, cityName: 'YENISEYSK', countryName: 'RUSSIA' },
  { placeId: 13861, cityName: 'YEYSK', countryName: 'RUSSIA' },
  { placeId: 9209, cityName: 'YOSHKAR-OLA', countryName: 'RUSSIA' },
  { placeId: 2583, cityName: 'YUZHNO-SAKHALINSK', countryName: 'RUSSIA' },
  { placeId: 13893, cityName: 'ZHIGANSK', countryName: 'RUSSIA' },
  { placeId: 13899, cityName: 'ZYRYANKA', countryName: 'RUSSIA' },
  { placeId: 4657, cityName: 'BUTARE', countryName: 'RWANDA' },
  { placeId: 5569, cityName: 'GISENYI', countryName: 'RWANDA' },
  { placeId: 5629, cityName: 'KAMEMBE', countryName: 'RWANDA' },
  { placeId: 1217, cityName: 'KIGALI', countryName: 'RWANDA' },
  { placeId: 7222, cityName: 'NEMBA', countryName: 'RWANDA' },
  { placeId: 7979, cityName: 'RUHENGERI', countryName: 'RWANDA' },
  { placeId: 2332, cityName: 'APIA', countryName: 'SAMOA' },
  { placeId: 5350, cityName: 'FAGALI', countryName: 'SAMOA' },
  { placeId: 7768, cityName: 'PRINCIPE', countryName: 'SAO TOME AND PRINCIPE' },
  { placeId: 8112, cityName: 'SAO TOME', countryName: 'SAO TOME AND PRINCIPE' },
  { placeId: 2343, cityName: 'ABHA', countryName: 'SAUDI ARABIA' },
  { placeId: 7824, cityName: 'ABQAIQ', countryName: 'SAUDI ARABIA' },
  { placeId: 4042, cityName: 'AL AHSA', countryName: 'SAUDI ARABIA' },
  { placeId: 4045, cityName: 'AL BAHA', countryName: 'SAUDI ARABIA' },
  { placeId: 4046, cityName: 'AL DAWADMI', countryName: 'SAUDI ARABIA' },
  { placeId: 4048, cityName: 'AL JOUF', countryName: 'SAUDI ARABIA' },
  { placeId: 4049, cityName: 'AL KHARJ', countryName: 'SAUDI ARABIA' },
  { placeId: 9520, cityName: 'AL ULA', countryName: 'SAUDI ARABIA' },
  { placeId: 4175, cityName: 'ARADAH', countryName: 'SAUDI ARABIA' },
  { placeId: 4200, cityName: 'ARAR', countryName: 'SAUDI ARABIA' },
  { placeId: 9748, cityName: 'BATHA', countryName: 'SAUDI ARABIA' },
  { placeId: 4489, cityName: 'BISHA', countryName: 'SAUDI ARABIA' },
  { placeId: 2344, cityName: 'DAMMAM', countryName: 'SAUDI ARABIA' },
  { placeId: 2345, cityName: 'DHAHRAN', countryName: 'SAUDI ARABIA' },
  { placeId: 5538, cityName: 'GASSIM', countryName: 'SAUDI ARABIA' },
  { placeId: 5747, cityName: 'GURIAT', countryName: 'SAUDI ARABIA' },
  { placeId: 5768, cityName: 'HAIL', countryName: 'SAUDI ARABIA' },
  { placeId: 5797, cityName: 'HARAD', countryName: 'SAUDI ARABIA' },
  { placeId: 5624, cityName: 'HAWTAH', countryName: 'SAUDI ARABIA' },
  { placeId: 2346, cityName: 'JAZAN', countryName: 'SAUDI ARABIA' },
  { placeId: 2347, cityName: 'JEDDAH', countryName: 'SAUDI ARABIA' },
  { placeId: 6083, cityName: 'JUBAIL', countryName: 'SAUDI ARABIA' },
  { placeId: 2348, cityName: 'KHAMIS MUSHAIT', countryName: 'SAUDI ARABIA' },
  { placeId: 11452, cityName: 'KHURAIS', countryName: 'SAUDI ARABIA' },
  { placeId: 6244, cityName: 'KING KHALID MILITARY CITY', countryName: 'SAUDI ARABIA' },
  { placeId: 2349, cityName: 'MADINAH', countryName: 'SAUDI ARABIA' },
  { placeId: 7190, cityName: 'NARIYA', countryName: 'SAUDI ARABIA' },
  { placeId: 7216, cityName: 'NEJRAN', countryName: 'SAUDI ARABIA' },
  { placeId: 7802, cityName: 'PUMP STATION 10', countryName: 'SAUDI ARABIA' },
  { placeId: 7803, cityName: 'PUMP STATION 3', countryName: 'SAUDI ARABIA' },
  { placeId: 7804, cityName: 'PUMP STATION 6', countryName: 'SAUDI ARABIA' },
  { placeId: 7805, cityName: 'PUMP STATION 9', countryName: 'SAUDI ARABIA' },
  { placeId: 7816, cityName: 'QAISUMAH', countryName: 'SAUDI ARABIA' },
  { placeId: 7838, cityName: 'RABIGH', countryName: 'SAUDI ARABIA' },
  { placeId: 7503, cityName: 'RAFHA', countryName: 'SAUDI ARABIA' },
  { placeId: 7857, cityName: 'RAS AL MISHAB', countryName: 'SAUDI ARABIA' },
  { placeId: 7859, cityName: 'RAS TANAJIB', countryName: 'SAUDI ARABIA' },
  { placeId: 7860, cityName: 'RAS TANURA', countryName: 'SAUDI ARABIA' },
  { placeId: 2350, cityName: 'RIYADH', countryName: 'SAUDI ARABIA' },
  { placeId: 13026, cityName: 'SHABITAH', countryName: 'SAUDI ARABIA' },
  { placeId: 8214, cityName: 'SHARURAH', countryName: 'SAUDI ARABIA' },
  { placeId: 8217, cityName: 'SHAYBAH', countryName: 'SAUDI ARABIA' },
  { placeId: 2351, cityName: 'TABUK', countryName: 'SAUDI ARABIA' },
  { placeId: 2352, cityName: 'TAIF', countryName: 'SAUDI ARABIA' },
  { placeId: 13405, cityName: 'THABLOTIN', countryName: 'SAUDI ARABIA' },
  { placeId: 8750, cityName: 'TURAIF', countryName: 'SAUDI ARABIA' },
  { placeId: 8775, cityName: 'UDHAILIYAH', countryName: 'SAUDI ARABIA' },
  { placeId: 8788, cityName: 'UMM LEJJ', countryName: 'SAUDI ARABIA' },
  { placeId: 8887, cityName: 'WADI AL DAWASIR', countryName: 'SAUDI ARABIA' },
  { placeId: 8961, cityName: 'WEJH', countryName: 'SAUDI ARABIA' },
  { placeId: 2353, cityName: 'YENBO', countryName: 'SAUDI ARABIA' },
  { placeId: 9129, cityName: 'ZULFI', countryName: 'SAUDI ARABIA' },
  { placeId: 4306, cityName: 'BAKEL', countryName: 'SENEGAL' },
  { placeId: 1193, cityName: 'CAP SKIRING', countryName: 'SENEGAL' },
  { placeId: 1194, cityName: 'DAKAR', countryName: 'SENEGAL' },
  { placeId: 6133, cityName: 'KAOLACK', countryName: 'SENEGAL' },
  { placeId: 6180, cityName: 'KEDOUGOU', countryName: 'SENEGAL' },
  { placeId: 6306, cityName: 'KOLDA', countryName: 'SENEGAL' },
  { placeId: 6864, cityName: 'MATAM', countryName: 'SENEGAL' },
  { placeId: 7684, cityName: 'PODOR', countryName: 'SENEGAL' },
  { placeId: 7909, cityName: 'RICHARD TOLL', countryName: 'SENEGAL' },
  { placeId: 8267, cityName: 'SIMENTI', countryName: 'SENEGAL' },
  { placeId: 1195, cityName: 'ST LOUIS', countryName: 'SENEGAL' },
  { placeId: 8566, cityName: 'TAMBACOUNDA', countryName: 'SENEGAL' },
  { placeId: 9123, cityName: 'ZIGUINCHOR', countryName: 'SENEGAL' },
  { placeId: 2272, cityName: 'BELGRADE', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 3159, cityName: 'BEOGRAD', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9817, cityName: 'BERANE', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9888, cityName: 'BOR', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 3160, cityName: 'DAKOVICA', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9196, cityName: 'KIKINDA', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 3162, cityName: 'KOSTOLAC', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 3161, cityName: 'KRALJEVO', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9195, cityName: 'KRUSEVAC', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 11670, cityName: 'LESKOVAC', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 12229, cityName: 'NIKSIC', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 2273, cityName: 'NIS', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 3163, cityName: 'NOVI SAD', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 12427, cityName: 'PANCEVO', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9192, cityName: 'PARACIN', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 2274, cityName: 'PODGORICA', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 25, cityName: 'PRISTINA', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9193, cityName: 'SMEDEREVO', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 3165, cityName: 'SMEDEREVSKA PALANKA', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 3164, cityName: 'SREMSKA MITROVICA', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 3166, cityName: 'SUBOTICA', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9191, cityName: 'TRSTENIK', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 13563, cityName: 'UZICE', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9194, cityName: 'VALJEVO', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 2276, cityName: 'VRSAC', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 9197, cityName: 'ZRENJANIN', countryName: 'SERBIA-MONTENEGRO' },
  { placeId: 4091, cityName: 'ALPHONSE', countryName: 'SEYCHELLES' },
  { placeId: 4249, cityName: 'ASSUMPTION ISLAND', countryName: 'SEYCHELLES' },
  { placeId: 4485, cityName: 'BIRD', countryName: 'SEYCHELLES' },
  { placeId: 4916, cityName: 'COETIVY', countryName: 'SEYCHELLES' },
  { placeId: 5068, cityName: 'DARROS ISLAND', countryName: 'SEYCHELLES' },
  { placeId: 5152, cityName: 'DENIS', countryName: 'SEYCHELLES' },
  { placeId: 5158, cityName: 'DESROCHES', countryName: 'SEYCHELLES' },
  { placeId: 5377, cityName: 'FARQUHAR', countryName: 'SEYCHELLES' },
  { placeId: 5119, cityName: 'FREGATE', countryName: 'SEYCHELLES' },
  { placeId: 6811, cityName: 'MARIE-LOUISE', countryName: 'SEYCHELLES' },
  { placeId: 7674, cityName: 'PLATTE', countryName: 'SEYCHELLES' },
  { placeId: 7756, cityName: 'PRASLIN', countryName: 'SEYCHELLES' },
  { placeId: 7894, cityName: 'REMIRE', countryName: 'SEYCHELLES' },
  { placeId: 1155, cityName: 'SEYCHELLES', countryName: 'SEYCHELLES' },
  { placeId: 4512, cityName: 'BO', countryName: 'SIERRA LEONE' },
  { placeId: 4541, cityName: 'BONTHE', countryName: 'SIERRA LEONE' },
  { placeId: 2868, cityName: 'FREETOWN', countryName: 'SIERRA LEONE' },
  { placeId: 5545, cityName: 'GBANGBATOKE', countryName: 'SIERRA LEONE' },
  { placeId: 5613, cityName: 'HASTINGS', countryName: 'SIERRA LEONE' },
  { placeId: 6090, cityName: 'KABALA', countryName: 'SIERRA LEONE' },
  { placeId: 6194, cityName: 'KENEMA', countryName: 'SIERRA LEONE' },
  { placeId: 9087, cityName: 'YENGEMA', countryName: 'SIERRA LEONE' },
  { placeId: 2757, cityName: 'SINGAPORE', countryName: 'SINGAPORE' },
  { placeId: 2278, cityName: 'BRATISLAVA', countryName: 'SLOVAKIA' },
  { placeId: 2277, cityName: 'DUBNICA', countryName: 'SLOVAKIA' },
  { placeId: 11314, cityName: 'JASNA', countryName: 'SLOVAKIA' },
  { placeId: 2279, cityName: 'KOSICE', countryName: 'SLOVAKIA' },
  { placeId: 6332, cityName: 'KRALOVA PRI SENCI', countryName: 'SLOVAKIA' },
  { placeId: 2281, cityName: 'MALACKY', countryName: 'SLOVAKIA' },
  { placeId: 2285, cityName: 'MALE BIELICE', countryName: 'SLOVAKIA' },
  { placeId: 2280, cityName: 'MARTIN', countryName: 'SLOVAKIA' },
  { placeId: 2282, cityName: 'NITRA', countryName: 'SLOVAKIA' },
  { placeId: 12272, cityName: 'NOVE ZAMKY', countryName: 'SLOVAKIA' },
  { placeId: 2284, cityName: 'PIESTANY', countryName: 'SLOVAKIA' },
  { placeId: 2290, cityName: 'POPRAD', countryName: 'SLOVAKIA' },
  { placeId: 2286, cityName: 'PRESOV', countryName: 'SLOVAKIA' },
  { placeId: 2283, cityName: 'PRIEVIDZA', countryName: 'SLOVAKIA' },
  { placeId: 2287, cityName: 'SLIAC', countryName: 'SLOVAKIA' },
  { placeId: 2288, cityName: 'SPISSKA NOVA VES', countryName: 'SLOVAKIA' },
  { placeId: 8331, cityName: 'SVIDNIK', countryName: 'SLOVAKIA' },
  { placeId: 2289, cityName: 'TRENCIN', countryName: 'SLOVAKIA' },
  { placeId: 2291, cityName: 'VAJNORY', countryName: 'SLOVAKIA' },
  { placeId: 2292, cityName: 'ZILINA', countryName: 'SLOVAKIA' },
  { placeId: 1972, cityName: 'AJDOVSCINA', countryName: 'SLOVENIA' },
  { placeId: 1974, cityName: 'BOVEC', countryName: 'SLOVENIA' },
  { placeId: 1975, cityName: 'CELJE', countryName: 'SLOVENIA' },
  { placeId: 10496, cityName: 'DIVACA', countryName: 'SLOVENIA' },
  { placeId: 1973, cityName: 'LESCE', countryName: 'SLOVENIA' },
  { placeId: 1976, cityName: 'LJUBLJANA', countryName: 'SLOVENIA' },
  { placeId: 1977, cityName: 'MARIBOR', countryName: 'SLOVENIA' },
  { placeId: 1978, cityName: 'MURSKA SOBOTA', countryName: 'SLOVENIA' },
  { placeId: 12273, cityName: 'NOVO MESTO', countryName: 'SLOVENIA' },
  { placeId: 1979, cityName: 'PORTOROZ', countryName: 'SLOVENIA' },
  { placeId: 12623, cityName: 'POSTOJNA', countryName: 'SLOVENIA' },
  { placeId: 1980, cityName: 'SLOVENJ GRADEC', countryName: 'SLOVENIA' },
  { placeId: 4268, cityName: 'ATOIFI', countryName: 'SOLOMON IS' },
  { placeId: 3971, cityName: 'AUKI', countryName: 'SOLOMON IS' },
  { placeId: 3979, cityName: 'AVU AVU', countryName: 'SOLOMON IS' },
  { placeId: 4281, cityName: 'BABANAKIRA', countryName: 'SOLOMON IS' },
  { placeId: 4315, cityName: 'BALLALAE', countryName: 'SOLOMON IS' },
  { placeId: 4416, cityName: 'BELLONA', countryName: 'SOLOMON IS' },
  { placeId: 4851, cityName: 'CHOISEUL', countryName: 'SOLOMON IS' },
  { placeId: 5386, cityName: 'FERA', countryName: 'SOLOMON IS' },
  { placeId: 5571, cityName: 'GIZO', countryName: 'SOLOMON IS' },
  { placeId: 1, cityName: 'HONIARA', countryName: 'SOLOMON IS' },
  { placeId: 6101, cityName: 'KAGHAU', countryName: 'SOLOMON IS' },
  { placeId: 6254, cityName: 'KIRAKIRA', countryName: 'SOLOMON IS' },
  { placeId: 7031, cityName: 'MONO', countryName: 'SOLOMON IS' },
  { placeId: 7133, cityName: 'MUNDA', countryName: 'SOLOMON IS' },
  { placeId: 7394, cityName: 'ONTONG JAVA', countryName: 'SOLOMON IS' },
  { placeId: 7305, cityName: 'PARASI', countryName: 'SOLOMON IS' },
  { placeId: 7895, cityName: 'RENNELL', countryName: 'SOLOMON IS' },
  { placeId: 8085, cityName: 'SANTA ANNA', countryName: 'SOLOMON IS' },
  { placeId: 8093, cityName: 'SANTA CRUZ, SOLOMON IS', countryName: 'SOLOMON IS' },
  { placeId: 7534, cityName: 'SEGHE', countryName: 'SOLOMON IS' },
  { placeId: 8502, cityName: 'SUAVANAO', countryName: 'SOLOMON IS' },
  { placeId: 9070, cityName: 'YANDINA', countryName: 'SOLOMON IS' },
  { placeId: 4099, cityName: 'ALULA', countryName: 'SOMALIA' },
  { placeId: 4406, cityName: 'BELET UEN', countryName: 'SOMALIA' },
  { placeId: 4436, cityName: 'BERBERA', countryName: 'SOMALIA' },
  { placeId: 4551, cityName: 'BOSASO', countryName: 'SOMALIA' },
  { placeId: 4639, cityName: 'BURAO', countryName: 'SOMALIA' },
  { placeId: 4713, cityName: 'CANDALA', countryName: 'SOMALIA' },
  { placeId: 5271, cityName: 'EGAL', countryName: 'SOMALIA' },
  { placeId: 5273, cityName: 'EIL', countryName: 'SOMALIA' },
  { placeId: 5025, cityName: 'ERIGAVO', countryName: 'SOMALIA' },
  { placeId: 5516, cityName: 'GALCAIO', countryName: 'SOMALIA' },
  { placeId: 5532, cityName: 'GARDO', countryName: 'SOMALIA' },
  { placeId: 6267, cityName: 'KISIMAYU', countryName: 'SOMALIA' },
  { placeId: 7004, cityName: 'MOGADISHU', countryName: 'SOMALIA' },
  { placeId: 7342, cityName: 'OBBIA', countryName: 'SOMALIA' },
  { placeId: 7523, cityName: 'SCUSCIUBAN', countryName: 'SOMALIA' },
  { placeId: 1096, cityName: 'ALEXANDER BAY', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4081, cityName: 'ALIWAL NORTH', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4082, cityName: 'ALKANTPAN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4385, cityName: 'BEAUFORT WEST', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4449, cityName: 'BETHLEHEM', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4455, cityName: 'BHISHO', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1097, cityName: 'BLOEMFONTEIN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4552, cityName: 'BOSCHKOP', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4560, cityName: 'BOTHAVILLE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4585, cityName: 'BRAKPAN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4601, cityName: 'BRITS', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4641, cityName: 'BURGERSDORP', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4688, cityName: 'CALVINIA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1098, cityName: 'CAPE TOWN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4742, cityName: 'CARNARVON', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 4744, cityName: 'CAROLINA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5075, cityName: 'DE AAR', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5100, cityName: 'DELAREYVILLE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5151, cityName: 'DENDRON', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1099, cityName: 'DURBAN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5229, cityName: 'DWAALBOOM', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1100, cityName: 'EAST LONDON', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5317, cityName: 'EMPANGENI', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5333, cityName: 'ESTCOURT', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5393, cityName: 'FICKSBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5401, cityName: 'FISANTEKRAAL', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5429, cityName: 'FORT BEAUFORT', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5118, cityName: 'FREEWAY', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5533, cityName: 'GARIEP DAM', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1101, cityName: 'GEORGE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5608, cityName: 'GRAAFF-REINET', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5663, cityName: 'GRAHAMSTOWN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5669, cityName: 'GRAND CENTRAL', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5713, cityName: 'GREYTOWN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5720, cityName: 'GROBLERSDAL', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5758, cityName: 'H.M.S.BASTARD MEMORIAL', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5775, cityName: 'HALFWEG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5800, cityName: 'HARDING', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5804, cityName: 'HARMONY', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5807, cityName: 'HARRISMITH', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5854, cityName: 'HLUHLUWE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1102, cityName: 'HOEDSPRUIT', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5892, cityName: 'HOWICK', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1103, cityName: 'JOHANNESBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1104, cityName: 'KIMBERLEY', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6282, cityName: 'KLEINSEE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6283, cityName: 'KLERKSDORP', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6336, cityName: 'KROONSTAD', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1105, cityName: 'KRUGER MPUMALANGA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6337, cityName: 'KRUGERSDORP', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6348, cityName: 'KURUMAN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6400, cityName: 'LADYSMITH', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1107, cityName: 'LANGEBAANWEG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1106, cityName: 'LANSERIA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6546, cityName: 'LICHTENBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6560, cityName: 'LIME ACRES', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6627, cityName: 'LOUIS TRICHARDT', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6681, cityName: 'LYDENBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1108, cityName: 'MAFIKENG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6720, cityName: 'MAJUBA POWER STATION', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6723, cityName: 'MAKHADO', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6732, cityName: 'MALAMALA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 5657, cityName: 'MALELANE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6800, cityName: 'MARBLE HALL', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6809, cityName: 'MARGATE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6965, cityName: 'MIDDELBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 6483, cityName: 'MKUZI', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7083, cityName: 'MOSSEL BAY', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1115, cityName: 'MTHATHA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7220, cityName: 'NELSPRUIT', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7238, cityName: 'NEW TEMPE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7244, cityName: 'NEWCASTLE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7326, cityName: 'NYLSTROOM', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7413, cityName: 'ORIENT', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7442, cityName: 'OUDTSHOORN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1109, cityName: 'OVERBERG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7567, cityName: 'PARYS', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7621, cityName: 'PHALABORWA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7637, cityName: 'PIET RETIEF', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7638, cityName: 'PIETERMARITZBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7639, cityName: 'PIETERSBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1111, cityName: 'PILANESBERG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7643, cityName: 'PILGRIMS REST', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7309, cityName: 'PLETTENBERG BAY', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1112, cityName: 'POLOKWANE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7494, cityName: 'PONGOLA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7697, cityName: 'PORT ALFRED', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1110, cityName: 'PORT ELIZABETH', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7744, cityName: 'POSTMASBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7745, cityName: 'POTCHEFSTROOM', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1116, cityName: 'PRETORIA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7763, cityName: 'PRIESKA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7770, cityName: 'PROGRESS', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7825, cityName: 'QUEENSTOWN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7892, cityName: 'REIVILO', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1113, cityName: 'RICHARDS BAY', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7927, cityName: 'ROBERTSON', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7736, cityName: 'RUSTENBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7995, cityName: 'SACE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7520, cityName: 'SALDANHA-VREDENBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8144, cityName: 'SCHWEIZER RENEKE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 7528, cityName: 'SECUNDA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8280, cityName: 'SISHEN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8287, cityName: 'SKUKUZA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8293, cityName: 'SLURRY', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8388, cityName: 'SPRINGBOK', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8396, cityName: 'SPRINGS', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8476, cityName: 'STELLENBOSCH', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8337, cityName: 'SWELLENDAM', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8358, cityName: 'TOMMY\'S FIELD', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8732, cityName: 'TSWALU GAME RESERVE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8757, cityName: 'TUTUKA POWER STATION', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8768, cityName: 'TZANEEN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8778, cityName: 'UITENHAGE', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8783, cityName: 'ULUNDI', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1114, cityName: 'UPINGTON', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8363, cityName: 'VENETIA', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8367, cityName: 'VERBORGENFONTEIN', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8369, cityName: 'VEREENIGING', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8846, cityName: 'VICTORIA WEST', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8877, cityName: 'VREDENDAL', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8878, cityName: 'VRYBURG', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8916, cityName: 'WARMBATHS', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1117, cityName: 'WATERKLOOF', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 8963, cityName: 'WELKOM', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 9013, cityName: 'WILLISTON', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 9040, cityName: 'WITBANK', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 9049, cityName: 'WORCESTER', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 9096, cityName: 'YSTERPLAAT', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 9113, cityName: 'ZEERUST', countryName: 'SOUTH AFRICAN REP' },
  { placeId: 1221, cityName: 'JUBA', countryName: 'SOUTH SUDAN' },
  { placeId: 1222, cityName: 'MALAKAL', countryName: 'SOUTH SUDAN' },
  { placeId: 8944, cityName: 'WAU', countryName: 'SOUTH SUDAN' },
  { placeId: 1430, cityName: 'A CORUNA', countryName: 'SPAIN' },
  { placeId: 1421, cityName: 'ALBACETE', countryName: 'SPAIN' },
  { placeId: 1422, cityName: 'ALICANTE', countryName: 'SPAIN' },
  { placeId: 1423, cityName: 'ALMERIA', countryName: 'SPAIN' },
  { placeId: 1453, cityName: 'ALT URGELL', countryName: 'SPAIN' },
  { placeId: 4123, cityName: 'AMPURIABRAVA', countryName: 'SPAIN' },
  { placeId: 1424, cityName: 'ASTURIAS', countryName: 'SPAIN' },
  { placeId: 1429, cityName: 'BADAJOZ', countryName: 'SPAIN' },
  { placeId: 1428, cityName: 'BARCELONA', countryName: 'SPAIN' },
  { placeId: 1426, cityName: 'BILBAO', countryName: 'SPAIN' },
  { placeId: 1427, cityName: 'BURGOS', countryName: 'SPAIN' },
  { placeId: 1448, cityName: 'CADIZ', countryName: 'SPAIN' },
  { placeId: 10093, cityName: 'CASARRUBIOS DEL MONTE', countryName: 'SPAIN' },
  { placeId: 4753, cityName: 'CASTELLON DE LA PLANA', countryName: 'SPAIN' },
  { placeId: 9157, cityName: 'CIUDAD REAL', countryName: 'SPAIN' },
  { placeId: 1425, cityName: 'CORDOBA', countryName: 'SPAIN' },
  { placeId: 1433, cityName: 'GIRONA', countryName: 'SPAIN' },
  { placeId: 1432, cityName: 'GRANADA', countryName: 'SPAIN' },
  { placeId: 1434, cityName: 'HUESCA', countryName: 'SPAIN' },
  { placeId: 1435, cityName: 'IBIZA', countryName: 'SPAIN' },
  { placeId: 1436, cityName: 'JEREZ', countryName: 'SPAIN' },
  { placeId: 6357, cityName: 'LA AXARQUIA', countryName: 'SPAIN' },
  { placeId: 6362, cityName: 'LA CERDANYA', countryName: 'SPAIN' },
  { placeId: 2830, cityName: 'LA PERDIZ', countryName: 'SPAIN' },
  { placeId: 1439, cityName: 'LEON', countryName: 'SPAIN' },
  { placeId: 9178, cityName: 'LLEIDA', countryName: 'SPAIN' },
  { placeId: 1440, cityName: 'LOGRONO', countryName: 'SPAIN' },
  { placeId: 1431, cityName: 'MADRID', countryName: 'SPAIN' },
  { placeId: 1441, cityName: 'MALAGA', countryName: 'SPAIN' },
  { placeId: 1450, cityName: 'MALLORCA', countryName: 'SPAIN' },
  { placeId: 1442, cityName: 'MENORCA', countryName: 'SPAIN' },
  { placeId: 7117, cityName: 'MUCHAMIEL', countryName: 'SPAIN' },
  { placeId: 1437, cityName: 'MURCIA', countryName: 'SPAIN' },
  { placeId: 1444, cityName: 'OCANA', countryName: 'SPAIN' },
  { placeId: 1445, cityName: 'PALMA DE MALLORCA', countryName: 'SPAIN' },
  { placeId: 1446, cityName: 'PAMPLONA', countryName: 'SPAIN' },
  { placeId: 1447, cityName: 'REUS', countryName: 'SPAIN' },
  { placeId: 12811, cityName: 'ROBLEDILLO DE MOHERNANDO', countryName: 'SPAIN' },
  { placeId: 7973, cityName: 'ROZAS', countryName: 'SPAIN' },
  { placeId: 1438, cityName: 'SABADELL', countryName: 'SPAIN' },
  { placeId: 1449, cityName: 'SALAMANCA', countryName: 'SPAIN' },
  { placeId: 12926, cityName: 'SAN LUIS', countryName: 'SPAIN' },
  { placeId: 1451, cityName: 'SAN SEBASTIAN', countryName: 'SPAIN' },
  { placeId: 8089, cityName: 'SANTA CILIA DE JACA', countryName: 'SPAIN' },
  { placeId: 1458, cityName: 'SANTANDER', countryName: 'SPAIN' },
  { placeId: 1452, cityName: 'SANTIAGO', countryName: 'SPAIN' },
  { placeId: 8110, cityName: 'SANTO TOME DEL PUERTO', countryName: 'SPAIN' },
  { placeId: 1443, cityName: 'SEVILLE', countryName: 'SPAIN' },
  { placeId: 13477, cityName: 'TREBUJENA', countryName: 'SPAIN' },
  { placeId: 1454, cityName: 'VALENCIA', countryName: 'SPAIN' },
  { placeId: 1455, cityName: 'VALLADOLID', countryName: 'SPAIN' },
  { placeId: 1457, cityName: 'VIGO', countryName: 'SPAIN' },
  { placeId: 13614, cityName: 'VILLAMARTIN', countryName: 'SPAIN' },
  { placeId: 1456, cityName: 'VITORIA', countryName: 'SPAIN' },
  { placeId: 1459, cityName: 'ZARAGOZA', countryName: 'SPAIN' },
  { placeId: 4167, cityName: 'ANURADHAPURA', countryName: 'SRI LANKA' },
  { placeId: 4177, cityName: 'BATTICALOA', countryName: 'SRI LANKA' },
  { placeId: 2677, cityName: 'COLOMBO', countryName: 'SRI LANKA' },
  { placeId: 5514, cityName: 'GAL OYA', countryName: 'SRI LANKA' },
  { placeId: 5851, cityName: 'HINGURAKGODA', countryName: 'SRI LANKA' },
  { placeId: 5645, cityName: 'KANKESANTURAI', countryName: 'SRI LANKA' },
  { placeId: 6169, cityName: 'KATUKURUNDA', countryName: 'SRI LANKA' },
  { placeId: 6294, cityName: 'KOGGALA', countryName: 'SRI LANKA' },
  { placeId: 13920, cityName: 'MATTALA', countryName: 'SRI LANKA' },
  { placeId: 7864, cityName: 'RATMALANA', countryName: 'SRI LANKA' },
  { placeId: 8252, cityName: 'SIGIRIYA', countryName: 'SRI LANKA' },
  { placeId: 8703, cityName: 'TRINCOMALEE', countryName: 'SRI LANKA' },
  { placeId: 8832, cityName: 'VAVUNIYA', countryName: 'SRI LANKA' },
  { placeId: 9038, cityName: 'WIRAWILA', countryName: 'SRI LANKA' },
  { placeId: 3722, cityName: 'ST KITTS I.', countryName: 'ST KITTS' },
  { placeId: 2546, cityName: 'CASTRIES', countryName: 'ST LUCIA' },
  { placeId: 2547, cityName: 'VIEUXFORT', countryName: 'ST LUCIA' },
  { placeId: 1835, cityName: 'ST PIERRE I', countryName: 'ST PIERRE I' },
  { placeId: 4435, cityName: 'BEQUIA', countryName: 'ST VINCENT' },
  { placeId: 4717, cityName: 'CANOUAN', countryName: 'ST VINCENT' },
  { placeId: 6246, cityName: 'KINGSTOWN', countryName: 'ST VINCENT' },
  { placeId: 7145, cityName: 'MUSTIQUE', countryName: 'ST VINCENT' },
  { placeId: 8792, cityName: 'UNION I.', countryName: 'ST VINCENT' },
  { placeId: 4256, cityName: 'ATBARA', countryName: 'SUDAN' },
  { placeId: 5056, cityName: 'DAMAZIN', countryName: 'SUDAN' },
  { placeId: 5197, cityName: 'DONGOLA', countryName: 'SUDAN' },
  { placeId: 5285, cityName: 'EL FASHIR', countryName: 'SUDAN' },
  { placeId: 1219, cityName: 'EL OBEID', countryName: 'SUDAN' },
  { placeId: 5548, cityName: 'GENEINA', countryName: 'SUDAN' },
  { placeId: 6098, cityName: 'KADUGLI', countryName: 'SUDAN' },
  { placeId: 1218, cityName: 'KASSALA', countryName: 'SUDAN' },
  { placeId: 1223, cityName: 'KHARTOUM', countryName: 'SUDAN' },
  { placeId: 6947, cityName: 'MEROWE', countryName: 'SUDAN' },
  { placeId: 7323, cityName: 'NYALA', countryName: 'SUDAN' },
  { placeId: 1220, cityName: 'PORT SUDAN', countryName: 'SUDAN' },
  { placeId: 12992, cityName: 'SEBERA GENEINA', countryName: 'SUDAN' },
  { placeId: 8888, cityName: 'WADI HALFA', countryName: 'SUDAN' },
  { placeId: 4915, cityName: 'COEROENIE', countryName: 'SURINAME' },
  { placeId: 6177, cityName: 'KAYSERBERG', countryName: 'SURINAME' },
  { placeId: 6300, cityName: 'NEW NICKERIE', countryName: 'SURINAME' },
  { placeId: 7539, cityName: 'PALOEMEU', countryName: 'SURINAME' },
  { placeId: 7555, cityName: 'PARAMARIBO', countryName: 'SURINAME' },
  { placeId: 8274, cityName: 'SIPALIWINI', countryName: 'SURINAME' },
  { placeId: 8544, cityName: 'TAFELBERG', countryName: 'SURINAME' },
  { placeId: 2523, cityName: 'ZANDERY', countryName: 'SURINAME' },
  { placeId: 4463, cityName: 'BIG BEND', countryName: 'SWAZILAND' },
  { placeId: 1126, cityName: 'MANZINI', countryName: 'SWAZILAND' },
  { placeId: 6962, cityName: 'MHLUME', countryName: 'SWAZILAND' },
  { placeId: 7255, cityName: 'NHLANGANO', countryName: 'SWAZILAND' },
  { placeId: 7640, cityName: 'PIGGS PEAK', countryName: 'SWAZILAND' },
  { placeId: 8269, cityName: 'SIMUNYE', countryName: 'SWAZILAND' },
  { placeId: 8567, cityName: 'TAMBANKULU', countryName: 'SWAZILAND' },
  { placeId: 8722, cityName: 'TSHANENI', countryName: 'SWAZILAND' },
  { placeId: 957, cityName: 'ALINGSAS', countryName: 'SWEDEN' },
  { placeId: 954, cityName: 'ALLEBERG', countryName: 'SWEDEN' },
  { placeId: 4090, cityName: 'ALMHULT', countryName: 'SWEDEN' },
  { placeId: 1049, cityName: 'ALVSBYN', countryName: 'SWEDEN' },
  { placeId: 991, cityName: 'ANDERSTORP', countryName: 'SWEDEN' },
  { placeId: 1043, cityName: 'ANGE', countryName: 'SWEDEN' },
  { placeId: 4141, cityName: 'ANGELHOLM', countryName: 'SWEDEN' },
  { placeId: 1023, cityName: 'ARBOGA', countryName: 'SWEDEN' },
  { placeId: 1038, cityName: 'ARBRA', countryName: 'SWEDEN' },
  { placeId: 4212, cityName: 'ARE OSTERSUND', countryName: 'SWEDEN' },
  { placeId: 1017, cityName: 'ARVIDSJAUR', countryName: 'SWEDEN' },
  { placeId: 978, cityName: 'ARVIKA', countryName: 'SWEDEN' },
  { placeId: 1047, cityName: 'ASELE', countryName: 'SWEDEN' },
  { placeId: 1051, cityName: 'AVESTA', countryName: 'SWEDEN' },
  { placeId: 953, cityName: 'BACKAMO', countryName: 'SWEDEN' },
  { placeId: 4545, cityName: 'BORAS', countryName: 'SWEDEN' },
  { placeId: 980, cityName: 'BORGLANDA', countryName: 'SWEDEN' },
  { placeId: 1025, cityName: 'BORLANGE', countryName: 'SWEDEN' },
  { placeId: 1031, cityName: 'BRATTFORSHEDEN', countryName: 'SWEDEN' },
  { placeId: 4632, cityName: 'BUNGE', countryName: 'SWEDEN' },
  { placeId: 970, cityName: 'DALA-JARNA', countryName: 'SWEDEN' },
  { placeId: 1050, cityName: 'EDSBYN', countryName: 'SWEDEN' },
  { placeId: 972, cityName: 'EKSHARAD', countryName: 'SWEDEN' },
  { placeId: 5275, cityName: 'EKSJO', countryName: 'SWEDEN' },
  { placeId: 979, cityName: 'EMMABODA', countryName: 'SWEDEN' },
  { placeId: 1024, cityName: 'ESKILSTUNA', countryName: 'SWEDEN' },
  { placeId: 981, cityName: 'ESLOV', countryName: 'SWEDEN' },
  { placeId: 982, cityName: 'FAGERHULT', countryName: 'SWEDEN' },
  { placeId: 5363, cityName: 'FALKENBERG', countryName: 'SWEDEN' },
  { placeId: 959, cityName: 'FALKOPING', countryName: 'SWEDEN' },
  { placeId: 5405, cityName: 'FJALLBACKA', countryName: 'SWEDEN' },
  { placeId: 1052, cityName: 'GAGNEF', countryName: 'SWEDEN' },
  { placeId: 1003, cityName: 'GALLIVARE', countryName: 'SWEDEN' },
  { placeId: 1041, cityName: 'GARGNAS', countryName: 'SWEDEN' },
  { placeId: 1030, cityName: 'GAVLE', countryName: 'SWEDEN' },
  { placeId: 955, cityName: 'GOTEBORG', countryName: 'SWEDEN' },
  { placeId: 5145, cityName: 'GOTENE', countryName: 'SWEDEN' },
  { placeId: 5721, cityName: 'GRONHOGEN', countryName: 'SWEDEN' },
  { placeId: 971, cityName: 'GRYTTJOM', countryName: 'SWEDEN' },
  { placeId: 1019, cityName: 'HAGFORS', countryName: 'SWEDEN' },
  { placeId: 995, cityName: 'HAGSHULT', countryName: 'SWEDEN' },
  { placeId: 1053, cityName: 'HALLEFORS', countryName: 'SWEDEN' },
  { placeId: 999, cityName: 'HALLVIKEN', countryName: 'SWEDEN' },
  { placeId: 994, cityName: 'HALMSTAD', countryName: 'SWEDEN' },
  { placeId: 1042, cityName: 'HARNOSAND', countryName: 'SWEDEN' },
  { placeId: 5611, cityName: 'HASSLEHOLM', countryName: 'SWEDEN' },
  { placeId: 1001, cityName: 'HEDE', countryName: 'SWEDEN' },
  { placeId: 1048, cityName: 'HEMAVAN TARNABY', countryName: 'SWEDEN' },
  { placeId: 956, cityName: 'HERRLJUNGA', countryName: 'SWEDEN' },
  { placeId: 984, cityName: 'HOGANAS', countryName: 'SWEDEN' },
  { placeId: 1004, cityName: 'HUDIKSVALL', countryName: 'SWEDEN' },
  { placeId: 1026, cityName: 'HULTSFRED-VIMMERBY', countryName: 'SWEDEN' },
  { placeId: 1040, cityName: 'IDRE', countryName: 'SWEDEN' },
  { placeId: 1005, cityName: 'JOKKMOKK', countryName: 'SWEDEN' },
  { placeId: 958, cityName: 'JONKOPING', countryName: 'SWEDEN' },
  { placeId: 986, cityName: 'KAGEROD', countryName: 'SWEDEN' },
  { placeId: 1044, cityName: 'KALIXFORS', countryName: 'SWEDEN' },
  { placeId: 992, cityName: 'KALMAR', countryName: 'SWEDEN' },
  { placeId: 966, cityName: 'KARLSBORG', countryName: 'SWEDEN' },
  { placeId: 973, cityName: 'KARLSKOGA', countryName: 'SWEDEN' },
  { placeId: 1020, cityName: 'KARLSTAD', countryName: 'SWEDEN' },
  { placeId: 1054, cityName: 'KATRINEHOLM', countryName: 'SWEDEN' },
  { placeId: 1012, cityName: 'KIRUNA', countryName: 'SWEDEN' },
  { placeId: 1056, cityName: 'KOPING', countryName: 'SWEDEN' },
  { placeId: 1006, cityName: 'KRAMFORS-SOLLEFTEA', countryName: 'SWEDEN' },
  { placeId: 987, cityName: 'KRISTIANSTAD', countryName: 'SWEDEN' },
  { placeId: 988, cityName: 'LANDSKRONA', countryName: 'SWEDEN' },
  { placeId: 1028, cityName: 'LAXA', countryName: 'SWEDEN' },
  { placeId: 960, cityName: 'LIDKOPING', countryName: 'SWEDEN' },
  { placeId: 948, cityName: 'LINKOPING', countryName: 'SWEDEN' },
  { placeId: 983, cityName: 'LJUNGBY', countryName: 'SWEDEN' },
  { placeId: 6589, cityName: 'LJUNGBYHED', countryName: 'SWEDEN' },
  { placeId: 1045, cityName: 'LJUSDAL', countryName: 'SWEDEN' },
  { placeId: 1027, cityName: 'LUDVIKA', countryName: 'SWEDEN' },
  { placeId: 1021, cityName: 'LULEA', countryName: 'SWEDEN' },
  { placeId: 989, cityName: 'LUND', countryName: 'SWEDEN' },
  { placeId: 1007, cityName: 'LYCKSELE', countryName: 'SWEDEN' },
  { placeId: 993, cityName: 'MALMO', countryName: 'SWEDEN' },
  { placeId: 1055, cityName: 'MALUNG', countryName: 'SWEDEN' },
  { placeId: 6929, cityName: 'MELLANSEL', countryName: 'SWEDEN' },
  { placeId: 7007, cityName: 'MOHED', countryName: 'SWEDEN' },
  { placeId: 974, cityName: 'MORA', countryName: 'SWEDEN' },
  { placeId: 975, cityName: 'MUNKFORS', countryName: 'SWEDEN' },
  { placeId: 1032, cityName: 'NORRKOPING', countryName: 'SWEDEN' },
  { placeId: 7279, cityName: 'NORRTALJE', countryName: 'SWEDEN' },
  { placeId: 998, cityName: 'OLANDA', countryName: 'SWEDEN' },
  { placeId: 1008, cityName: 'OPTAND', countryName: 'SWEDEN' },
  { placeId: 1018, cityName: 'OREBRO', countryName: 'SWEDEN' },
  { placeId: 7412, cityName: 'ORESTEN', countryName: 'SWEDEN' },
  { placeId: 1010, cityName: 'ORNSKOLDSVIK', countryName: 'SWEDEN' },
  { placeId: 1013, cityName: 'ORSA', countryName: 'SWEDEN' },
  { placeId: 990, cityName: 'OSKARSHAMN', countryName: 'SWEDEN' },
  { placeId: 7447, cityName: 'OVERKALIX', countryName: 'SWEDEN' },
  { placeId: 7448, cityName: 'OVIKEN', countryName: 'SWEDEN' },
  { placeId: 7474, cityName: 'PAJALA-YLLAS', countryName: 'SWEDEN' },
  { placeId: 1011, cityName: 'PITEA', countryName: 'SWEDEN' },
  { placeId: 952, cityName: 'RADA', countryName: 'SWEDEN' },
  { placeId: 1046, cityName: 'RAMSELE', countryName: 'SWEDEN' },
  { placeId: 951, cityName: 'RONNEBY', countryName: 'SWEDEN' },
  { placeId: 965, cityName: 'SAFFLE', countryName: 'SWEDEN' },
  { placeId: 8077, cityName: 'SANDVIK', countryName: 'SWEDEN' },
  { placeId: 967, cityName: 'SATENAS', countryName: 'SWEDEN' },
  { placeId: 1057, cityName: 'SILJANSNAS', countryName: 'SWEDEN' },
  { placeId: 985, cityName: 'SJOBO', countryName: 'SWEDEN' },
  { placeId: 1014, cityName: 'SKELLEFTEA', countryName: 'SWEDEN' },
  { placeId: 961, cityName: 'SKOVDE', countryName: 'SWEDEN' },
  { placeId: 997, cityName: 'SMALANDSSTENAR', countryName: 'SWEDEN' },
  { placeId: 8303, cityName: 'SODERHAMN', countryName: 'SWEDEN' },
  { placeId: 1000, cityName: 'SOLLEFTEA', countryName: 'SWEDEN' },
  { placeId: 13257, cityName: 'STEGEBORG', countryName: 'SWEDEN' },
  { placeId: 968, cityName: 'STOCKHOLM', countryName: 'SWEDEN' },
  { placeId: 1039, cityName: 'STORUMAN', countryName: 'SWEDEN' },
  { placeId: 8489, cityName: 'STORVIK', countryName: 'SWEDEN' },
  { placeId: 962, cityName: 'STROMSTAD', countryName: 'SWEDEN' },
  { placeId: 969, cityName: 'SUNDBRO', countryName: 'SWEDEN' },
  { placeId: 1009, cityName: 'SUNDSVALL-HARNOSAND', countryName: 'SWEDEN' },
  { placeId: 977, cityName: 'SUNNE', countryName: 'SWEDEN' },
  { placeId: 1002, cityName: 'SVEG', countryName: 'SWEDEN' },
  { placeId: 8659, cityName: 'TIDAHOLM', countryName: 'SWEDEN' },
  { placeId: 976, cityName: 'TIERP', countryName: 'SWEDEN' },
  { placeId: 8357, cityName: 'TOMELILLA', countryName: 'SWEDEN' },
  { placeId: 1033, cityName: 'TORSBY', countryName: 'SWEDEN' },
  { placeId: 963, cityName: 'TROLLHATTAN-VANERSBORG', countryName: 'SWEDEN' },
  { placeId: 8774, cityName: 'UDDEVALLA', countryName: 'SWEDEN' },
  { placeId: 1015, cityName: 'UMEA', countryName: 'SWEDEN' },
  { placeId: 950, cityName: 'UPPSALA', countryName: 'SWEDEN' },
  { placeId: 1037, cityName: 'VANGSO', countryName: 'SWEDEN' },
  { placeId: 964, cityName: 'VARBERG', countryName: 'SWEDEN' },
  { placeId: 13586, cityName: 'VARGARDA', countryName: 'SWEDEN' },
  { placeId: 1036, cityName: 'VASTERAS', countryName: 'SWEDEN' },
  { placeId: 1035, cityName: 'VASTERVIK', countryName: 'SWEDEN' },
  { placeId: 996, cityName: 'VAXJO', countryName: 'SWEDEN' },
  { placeId: 8834, cityName: 'VELLINGE', countryName: 'SWEDEN' },
  { placeId: 1022, cityName: 'VIDSEL', countryName: 'SWEDEN' },
  { placeId: 1016, cityName: 'VILHELMINA', countryName: 'SWEDEN' },
  { placeId: 1034, cityName: 'VISBY', countryName: 'SWEDEN' },
  { placeId: 1029, cityName: 'VISINGSO', countryName: 'SWEDEN' },
  { placeId: 4115, cityName: 'AMBRI', countryName: 'SWITZERLAND' },
  { placeId: 2191, cityName: 'AMLIKON', countryName: 'SWITZERLAND' },
  { placeId: 2200, cityName: 'BAD RAGAZ', countryName: 'SWITZERLAND' },
  { placeId: 2208, cityName: 'BASEL', countryName: 'SWITZERLAND' },
  { placeId: 2199, cityName: 'BERN', countryName: 'SWITZERLAND' },
  { placeId: 2177, cityName: 'BEX', countryName: 'SWITZERLAND' },
  { placeId: 2211, cityName: 'BIEL-KAPPELEN', countryName: 'SWITZERLAND' },
  { placeId: 2201, cityName: 'BIRRFELD', countryName: 'SWITZERLAND' },
  { placeId: 9928, cityName: 'BRESSAUCOURT', countryName: 'SWITZERLAND' },
  { placeId: 4636, cityName: 'BUOCHS', countryName: 'SWITZERLAND' },
  { placeId: 2215, cityName: 'BUTTWIL', countryName: 'SWITZERLAND' },
  { placeId: 2205, cityName: 'COURTELARY', countryName: 'SWITZERLAND' },
  { placeId: 2188, cityName: 'DUBENDORF', countryName: 'SWITZERLAND' },
  { placeId: 2179, cityName: 'ECUVILLENS', countryName: 'SWITZERLAND' },
  { placeId: 2189, cityName: 'EMMEN', countryName: 'SWITZERLAND' },
  { placeId: 2204, cityName: 'FRICKTAL-SCHUPFART', countryName: 'SWITZERLAND' },
  { placeId: 2180, cityName: 'GENEVA', countryName: 'SWITZERLAND' },
  { placeId: 2202, cityName: 'GRENCHEN', countryName: 'SWITZERLAND' },
  { placeId: 2186, cityName: 'GRUYERES', countryName: 'SWITZERLAND' },
  { placeId: 2209, cityName: 'HAUSEN AM ALBIS', countryName: 'SWITZERLAND' },
  { placeId: 2184, cityName: 'LA COTE', countryName: 'SWITZERLAND' },
  { placeId: 2193, cityName: 'LANGENTHAL', countryName: 'SWITZERLAND' },
  { placeId: 2182, cityName: 'LAUSANNE', countryName: 'SWITZERLAND' },
  { placeId: 2178, cityName: 'LES EPLATURES', countryName: 'SWITZERLAND' },
  { placeId: 2207, cityName: 'LOCARNO', countryName: 'SWITZERLAND' },
  { placeId: 2214, cityName: 'LOMMIS', countryName: 'SWITZERLAND' },
  { placeId: 2198, cityName: 'LUGANO', countryName: 'SWITZERLAND' },
  { placeId: 2210, cityName: 'LUZERN-BEROMUNSTER', countryName: 'SWITZERLAND' },
  { placeId: 2197, cityName: 'MONTRICHER', countryName: 'SWITZERLAND' },
  { placeId: 2196, cityName: 'MOTIERS', countryName: 'SWITZERLAND' },
  { placeId: 2183, cityName: 'NEUCHATEL', countryName: 'SWITZERLAND' },
  { placeId: 2190, cityName: 'PAYERNE', countryName: 'SWITZERLAND' },
  { placeId: 2218, cityName: 'PORRENTRUY', countryName: 'SWITZERLAND' },
  { placeId: 7888, cityName: 'REICHENBACH', countryName: 'SWITZERLAND' },
  { placeId: 2181, cityName: 'SAANEN', countryName: 'SWITZERLAND' },
  { placeId: 2213, cityName: 'SAMEDAN', countryName: 'SWITZERLAND' },
  { placeId: 2192, cityName: 'SCHAFFHAUSEN', countryName: 'SWITZERLAND' },
  { placeId: 2185, cityName: 'SION', countryName: 'SWITZERLAND' },
  { placeId: 2216, cityName: 'SITTERDORF', countryName: 'SWITZERLAND' },
  { placeId: 2206, cityName: 'SPECK-FEHRALTORF', countryName: 'SWITZERLAND' },
  { placeId: 2212, cityName: 'ST GALLEN', countryName: 'SWITZERLAND' },
  { placeId: 13234, cityName: 'ST. STEPHAN', countryName: 'SWITZERLAND' },
  { placeId: 2217, cityName: 'THUN', countryName: 'SWITZERLAND' },
  { placeId: 2194, cityName: 'TRIENGEN', countryName: 'SWITZERLAND' },
  { placeId: 2195, cityName: 'WANGEN-LACHEN', countryName: 'SWITZERLAND' },
  { placeId: 2187, cityName: 'YVERDON-LES-BAINS', countryName: 'SWITZERLAND' },
  { placeId: 2203, cityName: 'ZURICH', countryName: 'SWITZERLAND' },
  { placeId: 2380, cityName: 'ALEPPO', countryName: 'SYRIA' },
  { placeId: 2381, cityName: 'DAMASCUS', countryName: 'SYRIA' },
  { placeId: 5096, cityName: 'DEIR ZZOR', countryName: 'SYRIA' },
  { placeId: 5632, cityName: 'KAMISHLY', countryName: 'SYRIA' },
  { placeId: 6450, cityName: 'LATAKIA', countryName: 'SYRIA' },
  { placeId: 7484, cityName: 'PALMYRA', countryName: 'SYRIA' },
  { placeId: 4812, cityName: 'CHIAYI', countryName: 'TAIWAN' },
  { placeId: 5525, cityName: 'GANGSHAN', countryName: 'TAIWAN' },
  { placeId: 5828, cityName: 'HENGCHUN', countryName: 'TAIWAN' },
  { placeId: 5895, cityName: 'HSINCHU', countryName: 'TAIWAN' },
  { placeId: 5896, cityName: 'HUALIEN', countryName: 'TAIWAN' },
  { placeId: 2412, cityName: 'KAOHSIUNG', countryName: 'TAIWAN' },
  { placeId: 6250, cityName: 'KINMEN', countryName: 'TAIWAN' },
  { placeId: 6438, cityName: 'LANYU', countryName: 'TAIWAN' },
  { placeId: 6612, cityName: 'LONGTAN', countryName: 'TAIWAN' },
  { placeId: 6684, cityName: 'LUDAO', countryName: 'TAIWAN' },
  { placeId: 6709, cityName: 'MAGONG', countryName: 'TAIWAN' },
  { placeId: 6871, cityName: 'MATSU', countryName: 'TAIWAN' },
  { placeId: 7659, cityName: 'PINGTUNG', countryName: 'TAIWAN' },
  { placeId: 8548, cityName: 'TAICHUNG', countryName: 'TAIWAN' },
  { placeId: 8550, cityName: 'TAINAN', countryName: 'TAIWAN' },
  { placeId: 2413, cityName: 'TAIPEI', countryName: 'TAIWAN' },
  { placeId: 8553, cityName: 'TAITUNG', countryName: 'TAIWAN' },
  { placeId: 8580, cityName: 'TAOYUAN', countryName: 'TAIWAN' },
  { placeId: 8910, cityName: 'WANG-AN', countryName: 'TAIWAN' },
  { placeId: 2653, cityName: 'DUSHANBE', countryName: 'TAJIKISTAN' },
  { placeId: 2654, cityName: 'KHUDZHAND', countryName: 'TAJIKISTAN' },
  { placeId: 6342, cityName: 'KULYAB', countryName: 'TAJIKISTAN' },
  { placeId: 6346, cityName: 'KURGAN-TYUBE', countryName: 'TAJIKISTAN' },
  { placeId: 4230, cityName: 'ARUSHA', countryName: 'TANZANIA' },
  { placeId: 4628, cityName: 'BUKOBA', countryName: 'TANZANIA' },
  { placeId: 4860, cityName: 'CHUNYA', countryName: 'TANZANIA' },
  { placeId: 1224, cityName: 'DAR-ES-SALAAM', countryName: 'TANZANIA' },
  { placeId: 5189, cityName: 'DODOMA', countryName: 'TANZANIA' },
  { placeId: 5980, cityName: 'INYONGA', countryName: 'TANZANIA' },
  { placeId: 5988, cityName: 'IRINGA', countryName: 'TANZANIA' },
  { placeId: 6157, cityName: 'KASULU', countryName: 'TANZANIA' },
  { placeId: 6217, cityName: 'KIBONDO', countryName: 'TANZANIA' },
  { placeId: 6221, cityName: 'KIGOMA', countryName: 'TANZANIA' },
  { placeId: 1225, cityName: 'KILIMANJARO', countryName: 'TANZANIA' },
  { placeId: 6228, cityName: 'KILIMATINDE', countryName: 'TANZANIA' },
  { placeId: 6235, cityName: 'KILOSA', countryName: 'TANZANIA' },
  { placeId: 6237, cityName: 'KILWA', countryName: 'TANZANIA' },
  { placeId: 6260, cityName: 'KIRONDATAL', countryName: 'TANZANIA' },
  { placeId: 6313, cityName: 'KONDOA', countryName: 'TANZANIA' },
  { placeId: 6317, cityName: 'KONGWA', countryName: 'TANZANIA' },
  { placeId: 6410, cityName: 'LAKE MANYARA', countryName: 'TANZANIA' },
  { placeId: 6571, cityName: 'LINDI', countryName: 'TANZANIA' },
  { placeId: 6585, cityName: 'LIULI', countryName: 'TANZANIA' },
  { placeId: 6603, cityName: 'LOLIONDO', countryName: 'TANZANIA' },
  { placeId: 6703, cityName: 'MAFIA', countryName: 'TANZANIA' },
  { placeId: 6704, cityName: 'MAFINGA', countryName: 'TANZANIA' },
  { placeId: 6847, cityName: 'MASASI', countryName: 'TANZANIA' },
  { placeId: 6860, cityName: 'MASWA', countryName: 'TANZANIA' },
  { placeId: 6894, cityName: 'MBEYA', countryName: 'TANZANIA' },
  { placeId: 7018, cityName: 'MOMBO', countryName: 'TANZANIA' },
  { placeId: 7073, cityName: 'MOROGORO', countryName: 'TANZANIA' },
  { placeId: 7081, cityName: 'MOSHI', countryName: 'TANZANIA' },
  { placeId: 7102, cityName: 'MPANDA', countryName: 'TANZANIA' },
  { placeId: 7105, cityName: 'MPWAPWA', countryName: 'TANZANIA' },
  { placeId: 7113, cityName: 'MTWARA', countryName: 'TANZANIA' },
  { placeId: 7119, cityName: 'MUFINDI', countryName: 'TANZANIA' },
  { placeId: 7144, cityName: 'MUSOMA', countryName: 'TANZANIA' },
  { placeId: 7151, cityName: 'MVUMI', countryName: 'TANZANIA' },
  { placeId: 7153, cityName: 'MWADUI', countryName: 'TANZANIA' },
  { placeId: 7154, cityName: 'MWANZA', countryName: 'TANZANIA' },
  { placeId: 6498, cityName: 'NACHINGWEA', countryName: 'TANZANIA' },
  { placeId: 7250, cityName: 'NGARA', countryName: 'TANZANIA' },
  { placeId: 7269, cityName: 'NJOMBE', countryName: 'TANZANIA' },
  { placeId: 7603, cityName: 'PEMBA', countryName: 'TANZANIA' },
  { placeId: 8024, cityName: 'SAME', countryName: 'TANZANIA' },
  { placeId: 8192, cityName: 'SERONERA', countryName: 'TANZANIA' },
  { placeId: 8237, cityName: 'SHINYANGA', countryName: 'TANZANIA' },
  { placeId: 8270, cityName: 'SINGIDA', countryName: 'TANZANIA' },
  { placeId: 8316, cityName: 'SONGEA', countryName: 'TANZANIA' },
  { placeId: 8510, cityName: 'SUMBAWANGA', countryName: 'TANZANIA' },
  { placeId: 8531, cityName: 'TABORA', countryName: 'TANZANIA' },
  { placeId: 8578, cityName: 'TANGA', countryName: 'TANZANIA' },
  { placeId: 8747, cityName: 'TUNDURU', countryName: 'TANZANIA' },
  { placeId: 8779, cityName: 'UKEREWE', countryName: 'TANZANIA' },
  { placeId: 8799, cityName: 'URAMBO', countryName: 'TANZANIA' },
  { placeId: 8805, cityName: 'UTETE', countryName: 'TANZANIA' },
  { placeId: 8810, cityName: 'UVINZA', countryName: 'TANZANIA' },
  { placeId: 8978, cityName: 'WEST KILIMANJARO', countryName: 'TANZANIA' },
  { placeId: 1226, cityName: 'ZANZIBAR', countryName: 'TANZANIA' },
  { placeId: 2699, cityName: 'BANGKOK', countryName: 'THAILAND' },
  { placeId: 2719, cityName: 'BURI RAM', countryName: 'THAILAND' },
  { placeId: 2701, cityName: 'CHIANG MAI', countryName: 'THAILAND' },
  { placeId: 2702, cityName: 'CHIANG RAI', countryName: 'THAILAND' },
  { placeId: 4853, cityName: 'CHON BURI', countryName: 'THAILAND' },
  { placeId: 2706, cityName: 'CHUMPHON', countryName: 'THAILAND' },
  { placeId: 2717, cityName: 'KHON KAEN', countryName: 'THAILAND' },
  { placeId: 2708, cityName: 'KRABI', countryName: 'THAILAND' },
  { placeId: 6421, cityName: 'LAMPANG', countryName: 'THAILAND' },
  { placeId: 2718, cityName: 'LOEI', countryName: 'THAILAND' },
  { placeId: 6617, cityName: 'LOP BURI', countryName: 'THAILAND' },
  { placeId: 6701, cityName: 'MAE HONG SON', countryName: 'THAILAND' },
  { placeId: 11794, cityName: 'MAEHONGSON', countryName: 'THAILAND' },
  { placeId: 7170, cityName: 'NAKHON PATHOM', countryName: 'THAILAND' },
  { placeId: 2723, cityName: 'NAKHON PHANOM', countryName: 'THAILAND' },
  { placeId: 2720, cityName: 'NAKHON RATCHASIMA', countryName: 'THAILAND' },
  { placeId: 7171, cityName: 'NAKHON SAWAN', countryName: 'THAILAND' },
  { placeId: 2707, cityName: 'NAKHON SI THAMMARAT', countryName: 'THAILAND' },
  { placeId: 7179, cityName: 'NAN', countryName: 'THAILAND' },
  { placeId: 2705, cityName: 'NARATHIWAT', countryName: 'THAILAND' },
  { placeId: 2710, cityName: 'PATTANI', countryName: 'THAILAND' },
  { placeId: 7622, cityName: 'PHETCHABUN', countryName: 'THAILAND' },
  { placeId: 7628, cityName: 'PHITSANULOK', countryName: 'THAILAND' },
  { placeId: 7629, cityName: 'PHRAE', countryName: 'THAILAND' },
  { placeId: 2711, cityName: 'PHUKET', countryName: 'THAILAND' },
  { placeId: 7753, cityName: 'PRACHIN BURI', countryName: 'THAILAND' },
  { placeId: 2703, cityName: 'PRACHUAP KHIRI KHAN', countryName: 'THAILAND' },
  { placeId: 2712, cityName: 'RANONG', countryName: 'THAILAND' },
  { placeId: 7863, cityName: 'RATCHABURI', countryName: 'THAILAND' },
  { placeId: 2700, cityName: 'RAYONG', countryName: 'THAILAND' },
  { placeId: 2722, cityName: 'ROI ET', countryName: 'THAILAND' },
  { placeId: 2715, cityName: 'SAKON NAKHON', countryName: 'THAILAND' },
  { placeId: 2709, cityName: 'SONGKHLA', countryName: 'THAILAND' },
  { placeId: 8166, cityName: 'SUKHOTHAI', countryName: 'THAILAND' },
  { placeId: 2704, cityName: 'SURAT THANI', countryName: 'THAILAND' },
  { placeId: 2716, cityName: 'SURIN', countryName: 'THAILAND' },
  { placeId: 8555, cityName: 'TAK', countryName: 'THAILAND' },
  { placeId: 2713, cityName: 'TRANG', countryName: 'THAILAND' },
  { placeId: 13475, cityName: 'TRAT', countryName: 'THAILAND' },
  { placeId: 2721, cityName: 'UBON RATCHATHANI', countryName: 'THAILAND' },
  { placeId: 2714, cityName: 'UDON THANI', countryName: 'THAILAND' },
  { placeId: 8943, cityName: 'WATTHANA NAKHON', countryName: 'THAILAND' },
  { placeId: 4148, cityName: 'ANIE', countryName: 'TOGO' },
  { placeId: 4252, cityName: 'ATAKPAME', countryName: 'TOGO' },
  { placeId: 5063, cityName: 'DAPAONG', countryName: 'TOGO' },
  { placeId: 158, cityName: 'LOME', countryName: 'TOGO' },
  { placeId: 157, cityName: 'NIAMTOUGOU', countryName: 'TOGO' },
  { placeId: 8082, cityName: 'SANSANNE-MANGO', countryName: 'TOGO' },
  { placeId: 8305, cityName: 'SOKODE', countryName: 'TOGO' },
  { placeId: 5759, cityName: 'HA\'APAI-LIFUKA ISLAND', countryName: 'TONGA' },
  { placeId: 7266, cityName: 'NIUAFO\'OU', countryName: 'TONGA' },
  { placeId: 7267, cityName: 'NIUATOPUTAPU', countryName: 'TONGA' },
  { placeId: 2330, cityName: 'NUKU\'ALOFA', countryName: 'TONGA' },
  { placeId: 8831, cityName: 'VAVA\'U', countryName: 'TONGA' },
  { placeId: 7707, cityName: 'PORT OF SPAIN', countryName: 'TRINIDAD' },
  { placeId: 8140, cityName: 'SCARBOROUGH', countryName: 'TRINIDAD' },
  { placeId: 9502, cityName: 'AHE I', countryName: 'TUAMOTU IS' },
  { placeId: 4548, cityName: 'BORJ EL AMRI', countryName: 'TUNISIA' },
  { placeId: 156, cityName: 'DJERBA', countryName: 'TUNISIA' },
  { placeId: 5280, cityName: 'EL BORMA', countryName: 'TUNISIA' },
  { placeId: 10625, cityName: 'ENFIDHA', countryName: 'TUNISIA' },
  { placeId: 10840, cityName: 'GABES', countryName: 'TUNISIA' },
  { placeId: 5508, cityName: 'GAFSA', countryName: 'TUNISIA' },
  { placeId: 154, cityName: 'MONASTIR', countryName: 'TUNISIA' },
  { placeId: 8203, cityName: 'SFAX', countryName: 'TUNISIA' },
  { placeId: 8343, cityName: 'TABARKA', countryName: 'TUNISIA' },
  { placeId: 2847, cityName: 'TOZEUR', countryName: 'TUNISIA' },
  { placeId: 155, cityName: 'TUNIS', countryName: 'TUNISIA' },
  { placeId: 2220, cityName: 'ADANA', countryName: 'TURKEY' },
  { placeId: 4015, cityName: 'ADIYAMAN', countryName: 'TURKEY' },
  { placeId: 2221, cityName: 'AFYON', countryName: 'TURKEY' },
  { placeId: 4022, cityName: 'AGRI', countryName: 'TURKEY' },
  { placeId: 2227, cityName: 'AMASYA', countryName: 'TURKEY' },
  { placeId: 2219, cityName: 'ANKARA', countryName: 'TURKEY' },
  { placeId: 2222, cityName: 'ANTALYA', countryName: 'TURKEY' },
  { placeId: 2237, cityName: 'AYDIN', countryName: 'TURKEY' },
  { placeId: 2238, cityName: 'BALIKESIR', countryName: 'TURKEY' },
  { placeId: 2259, cityName: 'BATMAN', countryName: 'TURKEY' },
  { placeId: 2250, cityName: 'BODRUM', countryName: 'TURKEY' },
  { placeId: 2246, cityName: 'BURSA', countryName: 'TURKEY' },
  { placeId: 2239, cityName: 'CANAKKALE', countryName: 'TURKEY' },
  { placeId: 2234, cityName: 'DENIZLI', countryName: 'TURKEY' },
  { placeId: 2252, cityName: 'DIYARBAKIR', countryName: 'TURKEY' },
  { placeId: 2251, cityName: 'ELAZIG', countryName: 'TURKEY' },
  { placeId: 2233, cityName: 'EREGLI', countryName: 'TURKEY' },
  { placeId: 2253, cityName: 'ERZINCAN', countryName: 'TURKEY' },
  { placeId: 2254, cityName: 'ERZURUM', countryName: 'TURKEY' },
  { placeId: 2240, cityName: 'ESKISEHIR', countryName: 'TURKEY' },
  { placeId: 2223, cityName: 'GAZIANTEP', countryName: 'TURKEY' },
  { placeId: 5615, cityName: 'HATAY', countryName: 'TURKEY' },
  { placeId: 2224, cityName: 'ISKENDERUN', countryName: 'TURKEY' },
  { placeId: 2263, cityName: 'ISPARTA', countryName: 'TURKEY' },
  { placeId: 2236, cityName: 'ISTANBUL', countryName: 'TURKEY' },
  { placeId: 2241, cityName: 'IZMIR', countryName: 'TURKEY' },
  { placeId: 2245, cityName: 'IZMIT', countryName: 'TURKEY' },
  { placeId: 6103, cityName: 'KAHRAMANMARAS', countryName: 'TURKEY' },
  { placeId: 2255, cityName: 'KARS', countryName: 'TURKEY' },
  { placeId: 2225, cityName: 'KASTAMONU', countryName: 'TURKEY' },
  { placeId: 2231, cityName: 'KAYSERI', countryName: 'TURKEY' },
  { placeId: 2226, cityName: 'KONYA', countryName: 'TURKEY' },
  { placeId: 2242, cityName: 'KUTAHYA', countryName: 'TURKEY' },
  { placeId: 2230, cityName: 'MALATYA', countryName: 'TURKEY' },
  { placeId: 2248, cityName: 'MANISA', countryName: 'TURKEY' },
  { placeId: 6801, cityName: 'MARDIN', countryName: 'TURKEY' },
  { placeId: 2264, cityName: 'MILAS', countryName: 'TURKEY' },
  { placeId: 2247, cityName: 'MUGLA', countryName: 'TURKEY' },
  { placeId: 2260, cityName: 'MUS', countryName: 'TURKEY' },
  { placeId: 2235, cityName: 'NEVSEHIR', countryName: 'TURKEY' },
  { placeId: 2265, cityName: 'SAMSUN', countryName: 'TURKEY' },
  { placeId: 2257, cityName: 'SANLIURFA', countryName: 'TURKEY' },
  { placeId: 2261, cityName: 'SIIRT', countryName: 'TURKEY' },
  { placeId: 2262, cityName: 'SINOP', countryName: 'TURKEY' },
  { placeId: 2228, cityName: 'SIVAS', countryName: 'TURKEY' },
  { placeId: 2249, cityName: 'TEKIRDAG', countryName: 'TURKEY' },
  { placeId: 2232, cityName: 'TOKAT', countryName: 'TURKEY' },
  { placeId: 2256, cityName: 'TRABZON', countryName: 'TURKEY' },
  { placeId: 2243, cityName: 'USAK', countryName: 'TURKEY' },
  { placeId: 2258, cityName: 'VAN', countryName: 'TURKEY' },
  { placeId: 2244, cityName: 'YALOVA', countryName: 'TURKEY' },
  { placeId: 2229, cityName: 'ZONGULDAK', countryName: 'TURKEY' },
  { placeId: 2648, cityName: 'ASHGABAT', countryName: 'TURKMENISTAN' },
  { placeId: 2651, cityName: 'DASHOGUZ', countryName: 'TURKMENISTAN' },
  { placeId: 2650, cityName: 'MARY', countryName: 'TURKMENISTAN' },
  { placeId: 2652, cityName: 'TURKMENABAT', countryName: 'TURKMENISTAN' },
  { placeId: 2649, cityName: 'TURKMENBASHI', countryName: 'TURKMENISTAN' },
  { placeId: 5679, cityName: 'GRAND TURK I.', countryName: 'TURKS IS' },
  { placeId: 12906, cityName: 'SALT CAY', countryName: 'TURKS IS' },
  { placeId: 5499, cityName: 'FUNAFUTI', countryName: 'TUVALU' },
  { placeId: 4229, cityName: 'ARUA', countryName: 'UGANDA' },
  { placeId: 2860, cityName: 'ENTEBBE', countryName: 'UGANDA' },
  { placeId: 5742, cityName: 'GULU', countryName: 'UGANDA' },
  { placeId: 6114, cityName: 'KAKIRA', countryName: 'UGANDA' },
  { placeId: 6151, cityName: 'KASESE', countryName: 'UGANDA' },
  { placeId: 6326, cityName: 'KOTIDO', countryName: 'UGANDA' },
  { placeId: 6575, cityName: 'LIRA', countryName: 'UGANDA' },
  { placeId: 6851, cityName: 'MASINDI', countryName: 'UGANDA' },
  { placeId: 6893, cityName: 'MBARARA', countryName: 'UGANDA' },
  { placeId: 7076, cityName: 'MOROTO', countryName: 'UGANDA' },
  { placeId: 7476, cityName: 'PAKUBA', countryName: 'UGANDA' },
  { placeId: 8322, cityName: 'SOROTI', countryName: 'UGANDA' },
  { placeId: 4805, cityName: 'CHERKASY', countryName: 'UKRAINE' },
  { placeId: 2599, cityName: 'CHERNIVTSI', countryName: 'UKRAINE' },
  { placeId: 2592, cityName: 'DNIPROPETROVS\'K', countryName: 'UKRAINE' },
  { placeId: 2590, cityName: 'DONETS\'K', countryName: 'UKRAINE' },
  { placeId: 2597, cityName: 'IVANO-FRANKIVS\'K', countryName: 'UKRAINE' },
  { placeId: 6202, cityName: 'KERCH', countryName: 'UKRAINE' },
  { placeId: 2596, cityName: 'KHARKIV', countryName: 'UKRAINE' },
  { placeId: 13912, cityName: 'Kherson', countryName: 'UKRAINE' },
  { placeId: 9174, cityName: 'KHMEL\'NYTS\'KYI', countryName: 'UKRAINE' },
  { placeId: 11495, cityName: 'KIROVOHRAD', countryName: 'UKRAINE' },
  { placeId: 2594, cityName: 'KRYVYI RIH', countryName: 'UKRAINE' },
  { placeId: 2589, cityName: 'KYIV', countryName: 'UKRAINE' },
  { placeId: 2598, cityName: 'L\'VIV', countryName: 'UKRAINE' },
  { placeId: 2591, cityName: 'LUHANS\'K', countryName: 'UKRAINE' },
  { placeId: 2820, cityName: 'MARIUPOL\'', countryName: 'UKRAINE' },
  { placeId: 2602, cityName: 'MYKOLAIV', countryName: 'UKRAINE' },
  { placeId: 2603, cityName: 'ODESA', countryName: 'UKRAINE' },
  { placeId: 9233, cityName: 'POLTAVA', countryName: 'UKRAINE' },
  { placeId: 2600, cityName: 'RIVNE', countryName: 'UKRAINE' },
  { placeId: 13017, cityName: 'SEVASTOPOL\'', countryName: 'UKRAINE' },
  { placeId: 2595, cityName: 'SIMFEROPOL\'', countryName: 'UKRAINE' },
  { placeId: 9161, cityName: 'SUMY UKRAINE', countryName: 'UKRAINE' },
  { placeId: 2824, cityName: 'TERNOPIL\'', countryName: 'UKRAINE' },
  { placeId: 2601, cityName: 'UZHHOROD', countryName: 'UKRAINE' },
  { placeId: 2604, cityName: 'VINNYTSIA', countryName: 'UKRAINE' },
  { placeId: 2593, cityName: 'ZAPORIZHZHIA', countryName: 'UKRAINE' },
  { placeId: 9489, cityName: 'ABU ALABYAD', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 2368, cityName: 'ABU DHABI', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 2369, cityName: 'AL AIN', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 4047, cityName: 'AL DHAFRA', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 9518, cityName: 'AL HAMRA', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 9519, cityName: 'AL QAFFY', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 4051, cityName: 'AL SAQR FIELD', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 4231, cityName: 'ARZANAH', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 4624, cityName: 'BUHASA', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 5070, cityName: 'DAS ISLAND', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 10465, cityName: 'DELMA', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 2370, cityName: 'DUBAI', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 2855, cityName: 'FUJAIRAH', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 5504, cityName: 'FUTAYSI', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 6051, cityName: 'JEBEL DHANA', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 11715, cityName: 'LIWA', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 7817, cityName: 'QARNAYN', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 12687, cityName: 'QSEEWRAH', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 2371, cityName: 'RAS AL KHAIMAH', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 2372, cityName: 'SHARJAH', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 13093, cityName: 'SIR BANI YAS', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 8787, cityName: 'UMM AL QUWAIN', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 13854, cityName: 'YASET ALI', countryName: 'UNITED ARAB EMIRATES' },
  { placeId: 9124, cityName: 'ZIRKU', countryName: 'UNITED ARAB EMIRATES' },
  // { placeId: 9276, cityName: '', countryName: 'UNITED KINGDOM' },
  { placeId: 715, cityName: 'ABERDEEN', countryName: 'UNITED KINGDOM' },
  { placeId: 675, cityName: 'ALDERNEY', countryName: 'UNITED KINGDOM' },
  { placeId: 737, cityName: 'ANDREWSFIELD', countryName: 'UNITED KINGDOM' },
  { placeId: 9628, cityName: 'ASHCROFT', countryName: 'UNITED KINGDOM' },
  { placeId: 696, cityName: 'BAGBY', countryName: 'UNITED KINGDOM' },
  { placeId: 774, cityName: 'BARKSTON HEATH', countryName: 'UNITED KINGDOM' },
  { placeId: 727, cityName: 'BARRA', countryName: 'UNITED KINGDOM' },
  { placeId: 699, cityName: 'BARROW', countryName: 'UNITED KINGDOM' },
  { placeId: 738, cityName: 'BECCLES', countryName: 'UNITED KINGDOM' },
  { placeId: 9779, cityName: 'BEDFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 623, cityName: 'BELFAST', countryName: 'UNITED KINGDOM' },
  { placeId: 669, cityName: 'BEMBRIDGE', countryName: 'UNITED KINGDOM' },
  { placeId: 723, cityName: 'BENBECULA', countryName: 'UNITED KINGDOM' },
  { placeId: 750, cityName: 'BENSON', countryName: 'UNITED KINGDOM' },
  { placeId: 4451, cityName: 'BEVERLEY', countryName: 'UNITED KINGDOM' },
  { placeId: 627, cityName: 'BIRMINGHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 686, cityName: 'BLACKBUSHE', countryName: 'UNITED KINGDOM' },
  { placeId: 697, cityName: 'BLACKPOOL', countryName: 'UNITED KINGDOM' },
  { placeId: 681, cityName: 'BODMIN', countryName: 'UNITED KINGDOM' },
  { placeId: 649, cityName: 'BOSCOMBE DOWN', countryName: 'UNITED KINGDOM' },
  { placeId: 739, cityName: 'BOURN', countryName: 'UNITED KINGDOM' },
  { placeId: 667, cityName: 'BOURNEMOUTH', countryName: 'UNITED KINGDOM' },
  { placeId: 9937, cityName: 'BRIMPTON', countryName: 'UNITED KINGDOM' },
  { placeId: 658, cityName: 'BRISTOL', countryName: 'UNITED KINGDOM' },
  { placeId: 756, cityName: 'BRIZE NORTON', countryName: 'UNITED KINGDOM' },
  { placeId: 4671, cityName: 'CAERNARFON', countryName: 'UNITED KINGDOM' },
  { placeId: 733, cityName: 'CAMBRIDGE', countryName: 'UNITED KINGDOM' },
  { placeId: 654, cityName: 'CAMPBELTOWN', countryName: 'UNITED KINGDOM' },
  { placeId: 656, cityName: 'CARDIFF', countryName: 'UNITED KINGDOM' },
  { placeId: 693, cityName: 'CARLISLE', countryName: 'UNITED KINGDOM' },
  { placeId: 685, cityName: 'CHALGROVE', countryName: 'UNITED KINGDOM' },
  { placeId: 673, cityName: 'CHICHESTER', countryName: 'UNITED KINGDOM' },
  { placeId: 10206, cityName: 'CHIVENOR', countryName: 'UNITED KINGDOM' },
  { placeId: 766, cityName: 'CHURCH FENTON', countryName: 'UNITED KINGDOM' },
  { placeId: 4870, cityName: 'CLACTON', countryName: 'UNITED KINGDOM' },
  { placeId: 4922, cityName: 'COLERNE', countryName: 'UNITED KINGDOM' },
  { placeId: 10298, cityName: 'COLL', countryName: 'UNITED KINGDOM' },
  { placeId: 4931, cityName: 'COLONSAY', countryName: 'UNITED KINGDOM' },
  { placeId: 662, cityName: 'COMPTON-ABBAS', countryName: 'UNITED KINGDOM' },
  { placeId: 763, cityName: 'CONINGSBY', countryName: 'UNITED KINGDOM' },
  { placeId: 759, cityName: 'COSFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 767, cityName: 'COTTESMORE', countryName: 'UNITED KINGDOM' },
  { placeId: 629, cityName: 'COVENTRY', countryName: 'UNITED KINGDOM' },
  { placeId: 745, cityName: 'CRANFIELD', countryName: 'UNITED KINGDOM' },
  { placeId: 773, cityName: 'CRANWELL', countryName: 'UNITED KINGDOM' },
  { placeId: 4999, cityName: 'CROWFIELD', countryName: 'UNITED KINGDOM' },
  { placeId: 651, cityName: 'CULDROSE', countryName: 'UNITED KINGDOM' },
  { placeId: 718, cityName: 'CUMBERNAULD', countryName: 'UNITED KINGDOM' },
  { placeId: 5057, cityName: 'DAMYNS HALL', countryName: 'UNITED KINGDOM' },
  { placeId: 5081, cityName: 'DEANLAND', countryName: 'UNITED KINGDOM' },
  { placeId: 682, cityName: 'DENHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 628, cityName: 'DERBY', countryName: 'UNITED KINGDOM' },
  { placeId: 764, cityName: 'DISHFORTH', countryName: 'UNITED KINGDOM' },
  { placeId: 644, cityName: 'DONCASTER SHEFFIELD', countryName: 'UNITED KINGDOM' },
  { placeId: 725, cityName: 'DUNDEE', countryName: 'UNITED KINGDOM' },
  { placeId: 5223, cityName: 'DUNKESWELL', countryName: 'UNITED KINGDOM' },
  { placeId: 10535, cityName: 'DUNSFOLD', countryName: 'UNITED KINGDOM' },
  { placeId: 705, cityName: 'DURHAM TEES VALLEY', countryName: 'UNITED KINGDOM' },
  { placeId: 5228, cityName: 'DUXFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 5238, cityName: 'EAGLESCOTT', countryName: 'UNITED KINGDOM' },
  { placeId: 740, cityName: 'EARLS COLNE', countryName: 'UNITED KINGDOM' },
  { placeId: 707, cityName: 'EAST MIDLANDS', countryName: 'UNITED KINGDOM' },
  { placeId: 5263, cityName: 'EDAY', countryName: 'UNITED KINGDOM' },
  { placeId: 719, cityName: 'EDINBURGH', countryName: 'UNITED KINGDOM' },
  { placeId: 741, cityName: 'ELMSETT', countryName: 'UNITED KINGDOM' },
  { placeId: 749, cityName: 'ELSTREE', countryName: 'UNITED KINGDOM' },
  { placeId: 624, cityName: 'ENNISKILLEN', countryName: 'UNITED KINGDOM' },
  { placeId: 10631, cityName: 'ENSTONE', countryName: 'UNITED KINGDOM' },
  { placeId: 746, cityName: 'EXETER', countryName: 'UNITED KINGDOM' },
  { placeId: 5352, cityName: 'FAIR ISLE', countryName: 'UNITED KINGDOM' },
  { placeId: 755, cityName: 'FAIRFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 747, cityName: 'FAIROAKS', countryName: 'UNITED KINGDOM' },
  { placeId: 5378, cityName: 'FARTHING CORNER', countryName: 'UNITED KINGDOM' },
  { placeId: 643, cityName: 'FENLAND', countryName: 'UNITED KINGDOM' },
  { placeId: 721, cityName: 'FIFE', countryName: 'UNITED KINGDOM' },
  { placeId: 5442, cityName: 'FOWLMERE', countryName: 'UNITED KINGDOM' },
  { placeId: 5495, cityName: 'FULL SUTTON', countryName: 'UNITED KINGDOM' },
  { placeId: 717, cityName: 'GLASGOW', countryName: 'UNITED KINGDOM' },
  { placeId: 631, cityName: 'GLOUCESTERSHIRE', countryName: 'UNITED KINGDOM' },
  { placeId: 676, cityName: 'GUERNSEY', countryName: 'UNITED KINGDOM' },
  { placeId: 761, cityName: 'HALTON', countryName: 'UNITED KINGDOM' },
  { placeId: 655, cityName: 'HAVERFORDWEST', countryName: 'UNITED KINGDOM' },
  { placeId: 702, cityName: 'HAWARDEN', countryName: 'UNITED KINGDOM' },
  { placeId: 760, cityName: 'HENLOW', countryName: 'UNITED KINGDOM' },
  { placeId: 674, cityName: 'HENSTRIDGE', countryName: 'UNITED KINGDOM' },
  { placeId: 692, cityName: 'HUCKNALL', countryName: 'UNITED KINGDOM' },
  { placeId: 698, cityName: 'HUMBERSIDE', countryName: 'UNITED KINGDOM' },
  { placeId: 716, cityName: 'INVERNESS', countryName: 'UNITED KINGDOM' },
  { placeId: 720, cityName: 'ISLAY', countryName: 'UNITED KINGDOM' },
  { placeId: 703, cityName: 'ISLE OF MAN', countryName: 'UNITED KINGDOM' },
  { placeId: 671, cityName: 'ISLE OF WIGHT', countryName: 'UNITED KINGDOM' },
  { placeId: 677, cityName: 'JERSEY', countryName: 'UNITED KINGDOM' },
  { placeId: 636, cityName: 'KEMBLE', countryName: 'UNITED KINGDOM' },
  { placeId: 6249, cityName: 'KINLOSS', countryName: 'UNITED KINGDOM' },
  { placeId: 712, cityName: 'KIRKWALL', countryName: 'UNITED KINGDOM' },
  { placeId: 751, cityName: 'LAKENHEATH', countryName: 'UNITED KINGDOM' },
  { placeId: 663, cityName: 'LANDS END', countryName: 'UNITED KINGDOM' },
  { placeId: 670, cityName: 'LASHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 6446, cityName: 'LASHENDEN', countryName: 'UNITED KINGDOM' },
  { placeId: 6517, cityName: 'LEE-ON-SOLENT', countryName: 'UNITED KINGDOM' },
  { placeId: 700, cityName: 'LEEDS BRADFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 765, cityName: 'LEEMING', countryName: 'UNITED KINGDOM' },
  { placeId: 630, cityName: 'LEICESTER', countryName: 'UNITED KINGDOM' },
  { placeId: 6532, cityName: 'LERWICK', countryName: 'UNITED KINGDOM' },
  { placeId: 730, cityName: 'LEUCHARS', countryName: 'UNITED KINGDOM' },
  { placeId: 770, cityName: 'LINTON-ON-OUSE', countryName: 'UNITED KINGDOM' },
  { placeId: 6582, cityName: 'LITTLE GRANSDEN', countryName: 'UNITED KINGDOM' },
  { placeId: 660, cityName: 'LIVERPOOL', countryName: 'UNITED KINGDOM' },
  { placeId: 661, cityName: 'LONDON', countryName: 'UNITED KINGDOM' },
  { placeId: 626, cityName: 'LONDONDERRY', countryName: 'UNITED KINGDOM' },
  { placeId: 731, cityName: 'LOSSIEMOUTH', countryName: 'UNITED KINGDOM' },
  { placeId: 690, cityName: 'LYDD', countryName: 'UNITED KINGDOM' },
  { placeId: 648, cityName: 'LYNEHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 640, cityName: 'MANCHESTER', countryName: 'UNITED KINGDOM' },
  { placeId: 691, cityName: 'MANSTON', countryName: 'UNITED KINGDOM' },
  { placeId: 775, cityName: 'MARHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 6888, cityName: 'MAYPOLE', countryName: 'UNITED KINGDOM' },
  { placeId: 6950, cityName: 'MERRYFIELD', countryName: 'UNITED KINGDOM' },
  { placeId: 758, cityName: 'MIDDLE WALLOP', countryName: 'UNITED KINGDOM' },
  { placeId: 752, cityName: 'MILDENHALL', countryName: 'UNITED KINGDOM' },
  { placeId: 650, cityName: 'NETHERAVON', countryName: 'UNITED KINGDOM' },
  { placeId: 695, cityName: 'NETHERTHORPE', countryName: 'UNITED KINGDOM' },
  { placeId: 704, cityName: 'NEWCASTLE', countryName: 'UNITED KINGDOM' },
  { placeId: 646, cityName: 'NEWQUAY', countryName: 'UNITED KINGDOM' },
  { placeId: 625, cityName: 'NEWTOWNARDS', countryName: 'UNITED KINGDOM' },
  { placeId: 7285, cityName: 'NORTH RONALDSAY', countryName: 'UNITED KINGDOM' },
  { placeId: 743, cityName: 'NORTH WEALD', countryName: 'UNITED KINGDOM' },
  { placeId: 632, cityName: 'NORTHAMPTON', countryName: 'UNITED KINGDOM' },
  { placeId: 736, cityName: 'NORWICH', countryName: 'UNITED KINGDOM' },
  { placeId: 634, cityName: 'NOTTINGHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 7337, cityName: 'OAKSEY PARK', countryName: 'UNITED KINGDOM' },
  { placeId: 7340, cityName: 'OBAN', countryName: 'UNITED KINGDOM' },
  { placeId: 757, cityName: 'ODIHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 742, cityName: 'OLD BUCKENHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 688, cityName: 'OLD SARUM', countryName: 'UNITED KINGDOM' },
  { placeId: 748, cityName: 'OXFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 684, cityName: 'PANSHANGER', countryName: 'UNITED KINGDOM' },
  { placeId: 7550, cityName: 'PAPA WESTRAY', countryName: 'UNITED KINGDOM' },
  { placeId: 7605, cityName: 'PEMBREY', countryName: 'UNITED KINGDOM' },
  { placeId: 7612, cityName: 'PERRANPORTH', countryName: 'UNITED KINGDOM' },
  { placeId: 728, cityName: 'PERTH', countryName: 'UNITED KINGDOM' },
  { placeId: 734, cityName: 'PETERBOROUGH', countryName: 'UNITED KINGDOM' },
  { placeId: 664, cityName: 'PLYMOUTH', countryName: 'UNITED KINGDOM' },
  { placeId: 7689, cityName: 'POPHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 722, cityName: 'PRESTWICK', countryName: 'UNITED KINGDOM' },
  { placeId: 680, cityName: 'REDHILL', countryName: 'UNITED KINGDOM' },
  { placeId: 694, cityName: 'RETFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 7934, cityName: 'ROCHESTER', countryName: 'UNITED KINGDOM' },
  { placeId: 8073, cityName: 'SANDAY', countryName: 'UNITED KINGDOM' },
  { placeId: 641, cityName: 'SANDTOFT', countryName: 'UNITED KINGDOM' },
  { placeId: 768, cityName: 'SCAMPTON', countryName: 'UNITED KINGDOM' },
  { placeId: 724, cityName: 'SCATSTA', countryName: 'UNITED KINGDOM' },
  { placeId: 665, cityName: 'SCILLY ISLES', countryName: 'UNITED KINGDOM' },
  { placeId: 7532, cityName: 'SEETHING', countryName: 'UNITED KINGDOM' },
  { placeId: 709, cityName: 'SHAWBURY', countryName: 'UNITED KINGDOM' },
  { placeId: 642, cityName: 'SHERBURN-IN-ELMET', countryName: 'UNITED KINGDOM' },
  { placeId: 732, cityName: 'SHIPDHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 637, cityName: 'SHOBDON', countryName: 'UNITED KINGDOM' },
  { placeId: 678, cityName: 'SHOREHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 8290, cityName: 'SLEAP', countryName: 'UNITED KINGDOM' },
  { placeId: 668, cityName: 'SOUTHAMPTON', countryName: 'UNITED KINGDOM' },
  { placeId: 689, cityName: 'SOUTHEND', countryName: 'UNITED KINGDOM' },
  { placeId: 652, cityName: 'ST ATHAN', countryName: 'UNITED KINGDOM' },
  { placeId: 735, cityName: 'STAPLEFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 726, cityName: 'STORNOWAY', countryName: 'UNITED KINGDOM' },
  { placeId: 8496, cityName: 'STRONSAY', countryName: 'UNITED KINGDOM' },
  { placeId: 645, cityName: 'STURGATE', countryName: 'UNITED KINGDOM' },
  { placeId: 713, cityName: 'SUMBURGH', countryName: 'UNITED KINGDOM' },
  { placeId: 657, cityName: 'SWANSEA', countryName: 'UNITED KINGDOM' },
  { placeId: 633, cityName: 'TATENHILL', countryName: 'UNITED KINGDOM' },
  { placeId: 708, cityName: 'TERN HILL', countryName: 'UNITED KINGDOM' },
  { placeId: 672, cityName: 'THRUXTON', countryName: 'UNITED KINGDOM' },
  { placeId: 729, cityName: 'TIREE', countryName: 'UNITED KINGDOM' },
  { placeId: 772, cityName: 'TOPCLIFFE', countryName: 'UNITED KINGDOM' },
  { placeId: 8713, cityName: 'TRURO', countryName: 'UNITED KINGDOM' },
  { placeId: 638, cityName: 'TURWESTON', countryName: 'UNITED KINGDOM' },
  { placeId: 647, cityName: 'UPAVON', countryName: 'UNITED KINGDOM' },
  { placeId: 710, cityName: 'VALLEY', countryName: 'UNITED KINGDOM' },
  { placeId: 771, cityName: 'WADDINGTON', countryName: 'UNITED KINGDOM' },
  { placeId: 701, cityName: 'WARTON', countryName: 'UNITED KINGDOM' },
  { placeId: 753, cityName: 'WATTISHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 639, cityName: 'WELLESBOURNE MOUNTFORD', countryName: 'UNITED KINGDOM' },
  { placeId: 8967, cityName: 'WELSHPOOL', countryName: 'UNITED KINGDOM' },
  { placeId: 8988, cityName: 'WEST WALES', countryName: 'UNITED KINGDOM' },
  { placeId: 8178, cityName: 'WESTRAY', countryName: 'UNITED KINGDOM' },
  { placeId: 687, cityName: 'WHITE WALTHAM', countryName: 'UNITED KINGDOM' },
  { placeId: 714, cityName: 'WICK', countryName: 'UNITED KINGDOM' },
  { placeId: 706, cityName: 'WICKENBY', countryName: 'UNITED KINGDOM' },
  { placeId: 769, cityName: 'WITTERING', countryName: 'UNITED KINGDOM' },
  { placeId: 635, cityName: 'WOLVERHAMPTON', countryName: 'UNITED KINGDOM' },
  { placeId: 711, cityName: 'WOODVALE', countryName: 'UNITED KINGDOM' },
  { placeId: 744, cityName: 'WYCOMBE AIR PARK', countryName: 'UNITED KINGDOM' },
  { placeId: 754, cityName: 'WYTON', countryName: 'UNITED KINGDOM' },
  { placeId: 666, cityName: 'YEOVIL', countryName: 'UNITED KINGDOM' },
  { placeId: 653, cityName: 'YEOVILTON', countryName: 'UNITED KINGDOM' },
  // { placeId: 9371, cityName: '', countryName: 'UNITED STATES' },
  { placeId: 9483, cityName: 'ABBEVILLE', countryName: 'UNITED STATES' },
  { placeId: 4735, cityName: 'ABERDEEN PROVING GROUNDS', countryName: 'UNITED STATES' },
  { placeId: 6716, cityName: 'ABERDEEN', countryName: 'UNITED STATES' },
  { placeId: 9143, cityName: 'ABILENE', countryName: 'UNITED STATES' },
  { placeId: 3147, cityName: 'ABINGDON', countryName: 'UNITED STATES' },
  { placeId: 4014, cityName: 'ADA', countryName: 'UNITED STATES' },
  { placeId: 9493, cityName: 'ADAK I', countryName: 'UNITED STATES' },
  { placeId: 2937, cityName: 'ADRIAN', countryName: 'UNITED STATES' },
  { placeId: 4017, cityName: 'AFTON', countryName: 'UNITED STATES' },
  { placeId: 4025, cityName: 'AHOSKIE', countryName: 'UNITED STATES' },
  { placeId: 4026, cityName: 'AIKEN', countryName: 'UNITED STATES' },
  { placeId: 4027, cityName: 'AINSWORTH', countryName: 'UNITED STATES' },
  { placeId: 4031, cityName: 'AITKIN', countryName: 'UNITED STATES' },
  { placeId: 9512, cityName: 'AKHIOK', countryName: 'UNITED STATES' },
  { placeId: 9513, cityName: 'AKIAK', countryName: 'UNITED STATES' },
  { placeId: 1254, cityName: 'AKRON', countryName: 'UNITED STATES' },
  { placeId: 4040, cityName: 'AKRON', countryName: 'UNITED STATES' },
  { placeId: 4059, cityName: 'ALABASTER', countryName: 'UNITED STATES' },
  { placeId: 9523, cityName: 'ALAKANUK', countryName: 'UNITED STATES' },
  { placeId: 4061, cityName: 'ALAMOGORDO', countryName: 'UNITED STATES' },
  { placeId: 4062, cityName: 'ALAMOSA', countryName: 'UNITED STATES' },
  { placeId: 4063, cityName: 'ALBANY', countryName: 'UNITED STATES' },
  {
    placeId: 1230, cityName: 'ALBANY', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 4064, cityName: 'ALBEMARLE', countryName: 'UNITED STATES' },
  { placeId: 2938, cityName: 'ALBERT LEA', countryName: 'UNITED STATES' },
  { placeId: 4065, cityName: 'ALBION', countryName: 'UNITED STATES' },
  {
    placeId: 1228, cityName: 'ALBUQUERQUE', state: 'NM', countryName: 'UNITED STATES',
  },
  { placeId: 4069, cityName: 'ALEXANDER CITY', countryName: 'UNITED STATES' },
  { placeId: 4073, cityName: 'ALEXANDRIA', countryName: 'UNITED STATES' },
  { placeId: 4072, cityName: 'ALEXANDRIA', countryName: 'UNITED STATES' },
  { placeId: 4076, cityName: 'ALGONA', countryName: 'UNITED STATES' },
  { placeId: 4078, cityName: 'ALICE', countryName: 'UNITED STATES' },
  { placeId: 4079, cityName: 'ALICEVILLE', countryName: 'UNITED STATES' },
  { placeId: 9545, cityName: 'ALLAKAKET', countryName: 'UNITED STATES' },
  { placeId: 9546, cityName: 'ALLENTOWN', countryName: 'UNITED STATES' },
  {
    placeId: 1227, cityName: 'ALLENTOWN', state: 'PA', countryName: 'UNITED STATES',
  },
  { placeId: 2941, cityName: 'ALLIANCE', countryName: 'UNITED STATES' },
  { placeId: 4087, cityName: 'ALMA', countryName: 'UNITED STATES' },
  { placeId: 4088, cityName: 'ALMA', countryName: 'UNITED STATES' },
  { placeId: 1232, cityName: 'ALPENA', countryName: 'UNITED STATES' },
  { placeId: 4094, cityName: 'ALTON-ST LOUIS', countryName: 'UNITED STATES' },
  { placeId: 4096, cityName: 'ALTOONA', countryName: 'UNITED STATES' },
  { placeId: 4097, cityName: 'ALTURAS', countryName: 'UNITED STATES' },
  { placeId: 4098, cityName: 'ALTUS', countryName: 'UNITED STATES' },
  { placeId: 4100, cityName: 'ALVA', countryName: 'UNITED STATES' },
  { placeId: 1231, cityName: 'AMARILLO', countryName: 'UNITED STATES' },
  { placeId: 4113, cityName: 'AMBLER', countryName: 'UNITED STATES' },
  { placeId: 4117, cityName: 'AMERICUS', countryName: 'UNITED STATES' },
  { placeId: 4118, cityName: 'AMERY', countryName: 'UNITED STATES' },
  { placeId: 4119, cityName: 'AMES', countryName: 'UNITED STATES' },
  { placeId: 4126, cityName: 'ANAKTUVUK PASS', countryName: 'UNITED STATES' },
  {
    placeId: 2393, cityName: 'ANCHORAGE', state: 'AK', countryName: 'UNITED STATES',
  },
  { placeId: 4132, cityName: 'ANDERSON', countryName: 'UNITED STATES' },
  { placeId: 4131, cityName: 'ANDERSON', countryName: 'UNITED STATES' },
  { placeId: 4135, cityName: 'ANDREWS', countryName: 'UNITED STATES' },
  { placeId: 4136, cityName: 'ANDREWS', countryName: 'UNITED STATES' },
  { placeId: 4140, cityName: 'ANGEL FIRE', countryName: 'UNITED STATES' },
  { placeId: 4144, cityName: 'ANGLETON/LAKE JACKSON', countryName: 'UNITED STATES' },
  { placeId: 4146, cityName: 'ANGOLA', countryName: 'UNITED STATES' },
  { placeId: 4147, cityName: 'ANIAK', countryName: 'UNITED STATES' },
  { placeId: 4153, cityName: 'ANKENY', countryName: 'UNITED STATES' },
  { placeId: 4154, cityName: 'ANN ARBOR', countryName: 'UNITED STATES' },
  { placeId: 9588, cityName: 'ANNAPOLIS', countryName: 'UNITED STATES' },
  {
    placeId: 4157, cityName: 'ANNISTON', state: 'AL', countryName: 'UNITED STATES',
  },
  { placeId: 4161, cityName: 'ANTHONY', countryName: 'UNITED STATES' },
  { placeId: 4162, cityName: 'ANTIGO', countryName: 'UNITED STATES' },
  { placeId: 4168, cityName: 'ANVIK', countryName: 'UNITED STATES' },
  { placeId: 9593, cityName: 'APALACHICOLA', countryName: 'UNITED STATES' },
  { placeId: 4171, cityName: 'APPLE VALLEY', countryName: 'UNITED STATES' },
  { placeId: 4172, cityName: 'APPLETON', countryName: 'UNITED STATES' },
  { placeId: 4173, cityName: 'APPLETON', countryName: 'UNITED STATES' },
  { placeId: 4206, cityName: 'ARCATA-EUREKA', countryName: 'UNITED STATES' },
  { placeId: 4207, cityName: 'ARCO', countryName: 'UNITED STATES' },
  { placeId: 4209, cityName: 'ARCTIC VILLAGE', countryName: 'UNITED STATES' },
  { placeId: 4211, cityName: 'ARDMORE', countryName: 'UNITED STATES' },
  { placeId: 4216, cityName: 'ARLINGTON', countryName: 'UNITED STATES' },
  { placeId: 4217, cityName: 'ARLINGTON', countryName: 'UNITED STATES' },
  { placeId: 4226, cityName: 'ARTESIA', countryName: 'UNITED STATES' },
  { placeId: 4235, cityName: 'ASH FLAT', countryName: 'UNITED STATES' },
  { placeId: 4237, cityName: 'ASHEBORO', countryName: 'UNITED STATES' },
  { placeId: 4239, cityName: 'ASHEVILLE', countryName: 'UNITED STATES' },
  { placeId: 4241, cityName: 'ASHLAND', countryName: 'UNITED STATES' },
  { placeId: 2985, cityName: 'ASHLAND', countryName: 'UNITED STATES' },
  { placeId: 4242, cityName: 'ASHLEY', countryName: 'UNITED STATES' },
  { placeId: 3038, cityName: 'ASHTABULA', countryName: 'UNITED STATES' },
  {
    placeId: 2815, cityName: 'ASPEN', state: 'CO', countryName: 'UNITED STATES',
  },
  { placeId: 2948, cityName: 'ASTORIA', countryName: 'UNITED STATES' },
  { placeId: 4259, cityName: 'ATHENS', countryName: 'UNITED STATES' },
  { placeId: 3071, cityName: 'ATHENS', countryName: 'UNITED STATES' },
  {
    placeId: 4258, cityName: 'ATHENS', state: 'GA', countryName: 'UNITED STATES',
  },
  { placeId: 4260, cityName: 'ATHENS/ALBANY', countryName: 'UNITED STATES' },
  { placeId: 3171, cityName: 'ATKA', countryName: 'UNITED STATES' },
  {
    placeId: 1234, cityName: 'ATLANTA', state: 'GA', countryName: 'UNITED STATES',
  },
  { placeId: 4265, cityName: 'ATLANTA', countryName: 'UNITED STATES' },
  { placeId: 9650, cityName: 'ATLANTA', countryName: 'UNITED STATES' },
  { placeId: 9653, cityName: 'ATLANTIC CITY', countryName: 'UNITED STATES' },
  { placeId: 4266, cityName: 'ATLANTIC', countryName: 'UNITED STATES' },
  { placeId: 4269, cityName: 'ATOKA', countryName: 'UNITED STATES' },
  { placeId: 4270, cityName: 'ATQASUK', countryName: 'UNITED STATES' },
  { placeId: 4275, cityName: 'ATWOOD', countryName: 'UNITED STATES' },
  { placeId: 4280, cityName: 'AUBURN-LEWISTON', countryName: 'UNITED STATES' },
  { placeId: 4278, cityName: 'AUBURN', countryName: 'UNITED STATES' },
  { placeId: 4279, cityName: 'AUBURN', countryName: 'UNITED STATES' },
  { placeId: 4277, cityName: 'AUBURN', countryName: 'UNITED STATES' },
  { placeId: 3969, cityName: 'AUDUBON', countryName: 'UNITED STATES' },
  {
    placeId: 2849, cityName: 'AUGUSTA', state: 'GA', countryName: 'UNITED STATES',
  },
  { placeId: 3970, cityName: 'AUGUSTA', countryName: 'UNITED STATES' },
  { placeId: 3975, cityName: 'AURORA', countryName: 'UNITED STATES' },
  { placeId: 3144, cityName: 'AURORA', countryName: 'UNITED STATES' },
  { placeId: 3974, cityName: 'AURORA', countryName: 'UNITED STATES' },
  { placeId: 9676, cityName: 'AUSTIN', countryName: 'UNITED STATES' },
  {
    placeId: 1235, cityName: 'AUSTIN', state: 'TX', countryName: 'UNITED STATES',
  },
  { placeId: 9675, cityName: 'AUSTIN', countryName: 'UNITED STATES' },
  { placeId: 2949, cityName: 'AUSTIN', countryName: 'UNITED STATES' },
  { placeId: 3976, cityName: 'AVA', countryName: 'UNITED STATES' },
  { placeId: 9679, cityName: 'AVALON', countryName: 'UNITED STATES' },
  { placeId: 3978, cityName: 'AVON PARK', countryName: 'UNITED STATES' },
  { placeId: 9680, cityName: 'AVON PARK', countryName: 'UNITED STATES' },
  { placeId: 4285, cityName: 'BAD AXE', countryName: 'UNITED STATES' },
  { placeId: 2954, cityName: 'BAINBRIDGE', countryName: 'UNITED STATES' },
  { placeId: 2956, cityName: 'BAKER CITY', countryName: 'UNITED STATES' },
  { placeId: 4307, cityName: 'BAKER', countryName: 'UNITED STATES' },
  { placeId: 4308, cityName: 'BAKERSFIELD', countryName: 'UNITED STATES' },
  {
    placeId: 1252, cityName: 'BALTIMORE', state: 'MD', countryName: 'UNITED STATES',
  },
  { placeId: 9703, cityName: 'BALTIMORE', countryName: 'UNITED STATES' },
  {
    placeId: 1241, cityName: 'BANGOR', state: 'ME', countryName: 'UNITED STATES',
  },
  { placeId: 4335, cityName: 'BANNING', countryName: 'UNITED STATES' },
  { placeId: 9719, cityName: 'BAR HARBOR', countryName: 'UNITED STATES' },
  { placeId: 4339, cityName: 'BARABOO', countryName: 'UNITED STATES' },
  { placeId: 4345, cityName: 'BARDSTOWN', countryName: 'UNITED STATES' },
  { placeId: 4351, cityName: 'BARNWELL', countryName: 'UNITED STATES' },
  { placeId: 4355, cityName: 'BARRE-MONTPELIER', countryName: 'UNITED STATES' },
  { placeId: 2390, cityName: 'BARROW', countryName: 'UNITED STATES' },
  { placeId: 4359, cityName: 'BARTER I LRRS', countryName: 'UNITED STATES' },
  { placeId: 4361, cityName: 'BARTLESVILLE', countryName: 'UNITED STATES' },
  { placeId: 4362, cityName: 'BARTOW', countryName: 'UNITED STATES' },
  { placeId: 4370, cityName: 'BASSETT', countryName: 'UNITED STATES' },
  { placeId: 4371, cityName: 'BASTROP', countryName: 'UNITED STATES' },
  { placeId: 4373, cityName: 'BATAVIA', countryName: 'UNITED STATES' },
  { placeId: 4374, cityName: 'BATESVILLE', countryName: 'UNITED STATES' },
  { placeId: 4375, cityName: 'BATESVILLE', countryName: 'UNITED STATES' },
  { placeId: 4376, cityName: 'BATESVILLE', countryName: 'UNITED STATES' },
  { placeId: 1249, cityName: 'BATON ROUGE', countryName: 'UNITED STATES' },
  {
    placeId: 4178, cityName: 'BATTLE CREEK', state: 'MI', countryName: 'UNITED STATES',
  },
  { placeId: 4179, cityName: 'BATTLE MOUNTAIN', countryName: 'UNITED STATES' },
  { placeId: 4379, cityName: 'BAUDETTE', countryName: 'UNITED STATES' },
  { placeId: 4381, cityName: 'BAXLEY', countryName: 'UNITED STATES' },
  { placeId: 9758, cityName: 'BAY CITY', countryName: 'UNITED STATES' },
  { placeId: 9759, cityName: 'BAY ST LOUIS', countryName: 'UNITED STATES' },
  { placeId: 9761, cityName: 'BAYTOWN', countryName: 'UNITED STATES' },
  { placeId: 4383, cityName: 'BEATRICE', countryName: 'UNITED STATES' },
  { placeId: 4384, cityName: 'BEATTY', countryName: 'UNITED STATES' },
  { placeId: 9765, cityName: 'BEAUFORT', countryName: 'UNITED STATES' },
  { placeId: 9766, cityName: 'BEAUFORT', countryName: 'UNITED STATES' },
  { placeId: 4386, cityName: 'BEAUMONT', countryName: 'UNITED STATES' },
  { placeId: 9768, cityName: 'BEAUMONT/PORT ARTHUR', countryName: 'UNITED STATES' },
  { placeId: 4390, cityName: 'BEAVER FALLS', countryName: 'UNITED STATES' },
  { placeId: 9773, cityName: 'BEAVER I', countryName: 'UNITED STATES' },
  { placeId: 4388, cityName: 'BEAVER', countryName: 'UNITED STATES' },
  { placeId: 4393, cityName: 'BECKLEY', countryName: 'UNITED STATES' },
  { placeId: 4396, cityName: 'BEDFORD', countryName: 'UNITED STATES' },
  {
    placeId: 4397, cityName: 'BEDFORD', state: 'MA', countryName: 'UNITED STATES',
  },
  { placeId: 4395, cityName: 'BEDFORD', countryName: 'UNITED STATES' },
  { placeId: 4399, cityName: 'BEEVILLE', countryName: 'UNITED STATES' },
  { placeId: 9788, cityName: 'BELFAST', countryName: 'UNITED STATES' },
  { placeId: 4410, cityName: 'BELLAIRE', countryName: 'UNITED STATES' },
  { placeId: 2987, cityName: 'BELLE FOURCHE', countryName: 'UNITED STATES' },
  { placeId: 4411, cityName: 'BELLE PLAINE', countryName: 'UNITED STATES' },
  { placeId: 4412, cityName: 'BELLEFONTAINE', countryName: 'UNITED STATES' },
  { placeId: 4415, cityName: 'BELLEVILLE', countryName: 'UNITED STATES' },
  { placeId: 4414, cityName: 'BELLEVILLE', countryName: 'UNITED STATES' },
  { placeId: 1244, cityName: 'BELLINGHAM', countryName: 'UNITED STATES' },
  {
    placeId: 4418, cityName: 'BELMAR-FARMINGDALE', state: 'NJ', countryName: 'UNITED STATES',
  },
  { placeId: 4420, cityName: 'BELUGA', countryName: 'UNITED STATES' },
  { placeId: 4421, cityName: 'BEMIDJI', countryName: 'UNITED STATES' },
  { placeId: 2952, cityName: 'BEND', countryName: 'UNITED STATES' },
  { placeId: 4427, cityName: 'BENNETTSVILLE', countryName: 'UNITED STATES' },
  { placeId: 2978, cityName: 'BENNINGTON', countryName: 'UNITED STATES' },
  { placeId: 4430, cityName: 'BENSON', countryName: 'UNITED STATES' },
  { placeId: 4432, cityName: 'BENTON HARBOR', countryName: 'UNITED STATES' },
  { placeId: 4431, cityName: 'BENTON', countryName: 'UNITED STATES' },
  { placeId: 4433, cityName: 'BENTONVILLE', countryName: 'UNITED STATES' },
  { placeId: 4440, cityName: 'BERLIN', countryName: 'UNITED STATES' },
  { placeId: 4446, cityName: 'BESSEMER', countryName: 'UNITED STATES' },
  { placeId: 4448, cityName: 'BETHEL', countryName: 'UNITED STATES' },
  { placeId: 4450, cityName: 'BETTLES', countryName: 'UNITED STATES' },
  { placeId: 9824, cityName: 'BEVERLY', countryName: 'UNITED STATES' },
  { placeId: 9826, cityName: 'BIG LAKE', countryName: 'UNITED STATES' },
  { placeId: 4464, cityName: 'BIG PINEY', countryName: 'UNITED STATES' },
  { placeId: 4465, cityName: 'BIG RAPIDS', countryName: 'UNITED STATES' },
  { placeId: 4467, cityName: 'BIG SPRING', countryName: 'UNITED STATES' },
  { placeId: 4468, cityName: 'BIGFORK', countryName: 'UNITED STATES' },
  { placeId: 1243, cityName: 'BILLINGS', countryName: 'UNITED STATES' },
  { placeId: 9835, cityName: 'BILOXI', countryName: 'UNITED STATES' },
  {
    placeId: 4478, cityName: 'BINGHAMTON', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 4484, cityName: 'BIRCHWOOD', countryName: 'UNITED STATES' },
  {
    placeId: 1242, cityName: 'BIRMINGHAM', state: 'AL', countryName: 'UNITED STATES',
  },
  { placeId: 4491, cityName: 'BISHOP', countryName: 'UNITED STATES' },
  { placeId: 4493, cityName: 'BISMARCK', countryName: 'UNITED STATES' },
  { placeId: 4500, cityName: 'BLACK RIVER FALLS', countryName: 'UNITED STATES' },
  { placeId: 13949, cityName: 'Black Rock City', countryName: 'UNITED STATES' },
  { placeId: 4181, cityName: 'BLACKSBURG', countryName: 'UNITED STATES' },
  { placeId: 4182, cityName: 'BLACKSTONE', countryName: 'UNITED STATES' },
  { placeId: 9859, cityName: 'BLACKWELL', countryName: 'UNITED STATES' },
  { placeId: 4183, cityName: 'BLAIR', countryName: 'UNITED STATES' },
  { placeId: 4502, cityName: 'BLAIR', countryName: 'UNITED STATES' },
  { placeId: 9862, cityName: 'BLAIRSVILLE', countryName: 'UNITED STATES' },
  { placeId: 4503, cityName: 'BLAKELY', countryName: 'UNITED STATES' },
  { placeId: 4504, cityName: 'BLANDING', countryName: 'UNITED STATES' },
  { placeId: 9866, cityName: 'BLOCK ISLAND', countryName: 'UNITED STATES' },
  { placeId: 1245, cityName: 'BLOOMINGTON', countryName: 'UNITED STATES' },
  { placeId: 4509, cityName: 'BLOOMINGTON', countryName: 'UNITED STATES' },
  { placeId: 3124, cityName: 'BLUE EARTH', countryName: 'UNITED STATES' },
  { placeId: 2957, cityName: 'BLUEFIELD', countryName: 'UNITED STATES' },
  { placeId: 2958, cityName: 'BLYTHE', countryName: 'UNITED STATES' },
  { placeId: 2967, cityName: 'BLYTHEVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 9876, cityName: 'BOCA RATON', state: 'FLORIDA', countryName: 'UNITED STATES',
  },
  { placeId: 2966, cityName: 'BOGALUSA', countryName: 'UNITED STATES' },
  { placeId: 4520, cityName: 'BOISE', countryName: 'UNITED STATES' },
  { placeId: 4542, cityName: 'BOONE', countryName: 'UNITED STATES' },
  { placeId: 4543, cityName: 'BOONVILLE', countryName: 'UNITED STATES' },
  { placeId: 4547, cityName: 'BORGER', countryName: 'UNITED STATES' },
  { placeId: 4553, cityName: 'BOSCOBEL', countryName: 'UNITED STATES' },
  { placeId: 4557, cityName: 'BOSSIER CITY', countryName: 'UNITED STATES' },
  {
    placeId: 1247, cityName: 'BOSTON', state: 'MA', countryName: 'UNITED STATES',
  },
  { placeId: 9898, cityName: 'BOULDER CITY', countryName: 'UNITED STATES' },
  { placeId: 4567, cityName: 'BOULDER JUNCTION', countryName: 'UNITED STATES' },
  { placeId: 4566, cityName: 'BOULDER', countryName: 'UNITED STATES' },
  { placeId: 4571, cityName: 'BOUNTIFUL', countryName: 'UNITED STATES' },
  { placeId: 2964, cityName: 'BOWLING GREEN', countryName: 'UNITED STATES' },
  { placeId: 2961, cityName: 'BOWMAN', countryName: 'UNITED STATES' },
  { placeId: 4577, cityName: 'BOYNE FALLS', countryName: 'UNITED STATES' },
  {
    placeId: 4578, cityName: 'BOZEMAN', state: 'MT', countryName: 'UNITED STATES',
  },
  { placeId: 4581, cityName: 'BRADFORD', countryName: 'UNITED STATES' },
  { placeId: 4582, cityName: 'BRADY', countryName: 'UNITED STATES' },
  { placeId: 4584, cityName: 'BRAINERD', countryName: 'UNITED STATES' },
  { placeId: 9920, cityName: 'BRANSON WEST', countryName: 'UNITED STATES' },
  { placeId: 9919, cityName: 'BRANSON', countryName: 'UNITED STATES' },
  { placeId: 9923, cityName: 'BRAWLEY', countryName: 'UNITED STATES' },
  { placeId: 4590, cityName: 'BREAUX BRIDGE', countryName: 'UNITED STATES' },
  { placeId: 4591, cityName: 'BRECKENRIDGE', countryName: 'UNITED STATES' },
  { placeId: 4593, cityName: 'BREMERTON', countryName: 'UNITED STATES' },
  { placeId: 9929, cityName: 'BREVIG MISSION', countryName: 'UNITED STATES' },
  { placeId: 9932, cityName: 'BRIDGEPORT', countryName: 'UNITED STATES' },
  { placeId: 4596, cityName: 'BRIDGEPORT', countryName: 'UNITED STATES' },
  { placeId: 4597, cityName: 'BRIDGEWATER', countryName: 'UNITED STATES' },
  { placeId: 4599, cityName: 'BRIGHAM CITY', countryName: 'UNITED STATES' },
  { placeId: 3138, cityName: 'BRISTOL-JOHNSON-KINGSPORT', countryName: 'UNITED STATES' },
  { placeId: 4602, cityName: 'BRITTON', countryName: 'UNITED STATES' },
  { placeId: 4605, cityName: 'BROKEN BOW', countryName: 'UNITED STATES' },
  { placeId: 4608, cityName: 'BROOKINGS', countryName: 'UNITED STATES' },
  { placeId: 2960, cityName: 'BROOKINGS', countryName: 'UNITED STATES' },
  { placeId: 4610, cityName: 'BROOKSVILLE', countryName: 'UNITED STATES' },
  { placeId: 4611, cityName: 'BROWNFIELD', countryName: 'UNITED STATES' },
  {
    placeId: 9954, cityName: 'BROWNSVILLE', state: 'TEXAS', countryName: 'UNITED STATES',
  },
  { placeId: 4612, cityName: 'BROWNWOOD', countryName: 'UNITED STATES' },
  { placeId: 9957, cityName: 'BRUNSWICK', countryName: 'UNITED STATES' },
  {
    placeId: 1381, cityName: 'BRUNSWICK', state: 'GA', countryName: 'UNITED STATES',
  },
  { placeId: 9956, cityName: 'BRUNSWICK', countryName: 'UNITED STATES' },
  { placeId: 4614, cityName: 'BRYAN', countryName: 'UNITED STATES' },
  { placeId: 4615, cityName: 'BRYCE CANYON', countryName: 'UNITED STATES' },
  { placeId: 4186, cityName: 'BUCKEYE', countryName: 'UNITED STATES' },
  { placeId: 4187, cityName: 'BUCKLAND', countryName: 'UNITED STATES' },
  { placeId: 4618, cityName: 'BUENA VISTA', countryName: 'UNITED STATES' },
  { placeId: 4623, cityName: 'BUFFALO', countryName: 'UNITED STATES' },
  {
    placeId: 1251, cityName: 'BUFFALO', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 4621, cityName: 'BUFFALO', countryName: 'UNITED STATES' },
  { placeId: 4622, cityName: 'BUFFALO', countryName: 'UNITED STATES' },
  { placeId: 4630, cityName: 'BULLHEAD CITY', countryName: 'UNITED STATES' },
  {
    placeId: 4640, cityName: 'BURBANK', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 4642, cityName: 'BURLEY', countryName: 'UNITED STATES' },
  { placeId: 4644, cityName: 'BURLINGTON', countryName: 'UNITED STATES' },
  { placeId: 4646, cityName: 'BURLINGTON', countryName: 'UNITED STATES' },
  { placeId: 2962, cityName: 'BURLINGTON', countryName: 'UNITED STATES' },
  { placeId: 2963, cityName: 'BURLINGTON', countryName: 'UNITED STATES' },
  { placeId: 4645, cityName: 'BURLINGTON', countryName: 'UNITED STATES' },
  {
    placeId: 1250, cityName: 'BURLINGTON', state: 'VT', countryName: 'UNITED STATES',
  },
  { placeId: 4647, cityName: 'BURLINGTON/MT VERNON', countryName: 'UNITED STATES' },
  { placeId: 4648, cityName: 'BURNET', countryName: 'UNITED STATES' },
  { placeId: 2959, cityName: 'BURNS', countryName: 'UNITED STATES' },
  { placeId: 4650, cityName: 'BURWELL', countryName: 'UNITED STATES' },
  { placeId: 4658, cityName: 'BUTLER', countryName: 'UNITED STATES' },
  { placeId: 4659, cityName: 'BUTLER', countryName: 'UNITED STATES' },
  { placeId: 1248, cityName: 'BUTTE', countryName: 'UNITED STATES' },
  { placeId: 4666, cityName: 'CABOOL', countryName: 'UNITED STATES' },
  { placeId: 4669, cityName: 'CADILLAC', countryName: 'UNITED STATES' },
  {
    placeId: 2816, cityName: 'CAHOKIA/ST LOUIS', state: 'IL', countryName: 'UNITED STATES',
  },
  { placeId: 3903, cityName: 'CAIRO', countryName: 'UNITED STATES' },
  { placeId: 4683, cityName: 'CALDWELL', countryName: 'UNITED STATES' },
  { placeId: 10014, cityName: 'CALDWELL', countryName: 'UNITED STATES' },
  { placeId: 4684, cityName: 'CALDWELL', countryName: 'UNITED STATES' },
  { placeId: 3902, cityName: 'CALEDONIA', countryName: 'UNITED STATES' },
  { placeId: 10016, cityName: 'CALEXICO', countryName: 'UNITED STATES' },
  { placeId: 4685, cityName: 'CALHOUN', countryName: 'UNITED STATES' },
  { placeId: 4686, cityName: 'CALIPATRIA', countryName: 'UNITED STATES' },
  { placeId: 4689, cityName: 'CAMARILLO', countryName: 'UNITED STATES' },
  { placeId: 4691, cityName: 'CAMBRIDGE', countryName: 'UNITED STATES' },
  { placeId: 4694, cityName: 'CAMBRIDGE', countryName: 'UNITED STATES' },
  { placeId: 4693, cityName: 'CAMBRIDGE', countryName: 'UNITED STATES' },
  { placeId: 4692, cityName: 'CAMBRIDGE', countryName: 'UNITED STATES' },
  { placeId: 4696, cityName: 'CAMDEN', countryName: 'UNITED STATES' },
  { placeId: 4697, cityName: 'CAMDEN', countryName: 'UNITED STATES' },
  { placeId: 4698, cityName: 'CAMERON', countryName: 'UNITED STATES' },
  { placeId: 4699, cityName: 'CAMILLA', countryName: 'UNITED STATES' },
  { placeId: 4703, cityName: 'CAMP DOUGLAS', countryName: 'UNITED STATES' },
  { placeId: 4704, cityName: 'CAMP MACKALL', countryName: 'UNITED STATES' },
  { placeId: 4705, cityName: 'CAMP RIPLEY', countryName: 'UNITED STATES' },
  { placeId: 4706, cityName: 'CAMP ROBINSON', countryName: 'UNITED STATES' },
  { placeId: 4707, cityName: 'CAMP SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 2935, cityName: 'CAMPBELLSVILLE', countryName: 'UNITED STATES' },
  { placeId: 4710, cityName: 'CANADIAN', countryName: 'UNITED STATES' },
  { placeId: 4712, cityName: 'CANBY', countryName: 'UNITED STATES' },
  { placeId: 4714, cityName: 'CANDLE', countryName: 'UNITED STATES' },
  { placeId: 10055, cityName: 'CANTON', countryName: 'UNITED STATES' },
  { placeId: 4719, cityName: 'CANTON', countryName: 'UNITED STATES' },
  { placeId: 4720, cityName: 'CANTWELL', countryName: 'UNITED STATES' },
  { placeId: 3900, cityName: 'CAPE GIRARDEAU', countryName: 'UNITED STATES' },
  { placeId: 4722, cityName: 'CAPE LISBURNE', countryName: 'UNITED STATES' },
  { placeId: 10063, cityName: 'CAPE NEWENHAM', countryName: 'UNITED STATES' },
  { placeId: 10064, cityName: 'CAPE ROMANZOF', countryName: 'UNITED STATES' },
  { placeId: 4723, cityName: 'CAPE SARICHEF', countryName: 'UNITED STATES' },
  { placeId: 4724, cityName: 'CAPE SIMPSON', countryName: 'UNITED STATES' },
  { placeId: 4728, cityName: 'CARBONDALE-MURPHYSBORO', countryName: 'UNITED STATES' },
  { placeId: 4733, cityName: 'CARIBOU', countryName: 'UNITED STATES' },
  {
    placeId: 1265, cityName: 'CARLSBAD', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 2865, cityName: 'CARLSBAD', countryName: 'UNITED STATES' },
  { placeId: 4741, cityName: 'CARMI', countryName: 'UNITED STATES' },
  { placeId: 4743, cityName: 'CARO', countryName: 'UNITED STATES' },
  { placeId: 10084, cityName: 'CARRIZO SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 4189, cityName: 'CARRIZO SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 4190, cityName: 'CARROLL', countryName: 'UNITED STATES' },
  { placeId: 4191, cityName: 'CARROLLTON', countryName: 'UNITED STATES' },
  { placeId: 4192, cityName: 'CARROLLTON', countryName: 'UNITED STATES' },
  { placeId: 4193, cityName: 'CARSON CITY', countryName: 'UNITED STATES' },
  { placeId: 4196, cityName: 'CARTERSVILLE', countryName: 'UNITED STATES' },
  { placeId: 4751, cityName: 'CASA GRANDE', countryName: 'UNITED STATES' },
  { placeId: 2974, cityName: 'CASCADE LOCKS', countryName: 'UNITED STATES' },
  { placeId: 1263, cityName: 'CASPER', countryName: 'UNITED STATES' },
  { placeId: 10098, cityName: 'CASTROVILLE', countryName: 'UNITED STATES' },
  { placeId: 4770, cityName: 'CEDAR CITY', countryName: 'UNITED STATES' },
  { placeId: 10106, cityName: 'CEDAR KEY', countryName: 'UNITED STATES' },
  { placeId: 4771, cityName: 'CEDAR RAPIDS', countryName: 'UNITED STATES' },
  { placeId: 4772, cityName: 'CELINA', countryName: 'UNITED STATES' },
  { placeId: 4774, cityName: 'CENTERVILLE', countryName: 'UNITED STATES' },
  { placeId: 3140, cityName: 'CENTERVILLE', countryName: 'UNITED STATES' },
  { placeId: 10114, cityName: 'CENTRAL', countryName: 'UNITED STATES' },
  { placeId: 4776, cityName: 'CENTRALIA', countryName: 'UNITED STATES' },
  { placeId: 3107, cityName: 'CENTRE', countryName: 'UNITED STATES' },
  { placeId: 3898, cityName: 'CHADRON', countryName: 'UNITED STATES' },
  { placeId: 10125, cityName: 'CHALKYITSIK', countryName: 'UNITED STATES' },
  { placeId: 3985, cityName: 'CHALLIS', countryName: 'UNITED STATES' },
  {
    placeId: 3986, cityName: 'CHAMPAIGN-URBANA', state: 'IL', countryName: 'UNITED STATES',
  },
  { placeId: 3989, cityName: 'CHANDALAR LAKE', countryName: 'UNITED STATES' },
  { placeId: 3991, cityName: 'CHANDLER', countryName: 'UNITED STATES' },
  { placeId: 3992, cityName: 'CHANDLER', countryName: 'UNITED STATES' },
  { placeId: 3995, cityName: 'CHANUTE', countryName: 'UNITED STATES' },
  { placeId: 3997, cityName: 'CHAPEL HILL', countryName: 'UNITED STATES' },
  { placeId: 3904, cityName: 'CHAPPELL', countryName: 'UNITED STATES' },
  { placeId: 4789, cityName: 'CHARITON', countryName: 'UNITED STATES' },
  { placeId: 4790, cityName: 'CHARLES CITY', countryName: 'UNITED STATES' },
  { placeId: 1266, cityName: 'CHARLESTON', countryName: 'UNITED STATES' },
  { placeId: 3901, cityName: 'CHARLESTON', countryName: 'UNITED STATES' },
  { placeId: 10141, cityName: 'CHARLESTON', countryName: 'UNITED STATES' },
  {
    placeId: 1257, cityName: 'CHARLESTON', state: 'SC', countryName: 'UNITED STATES',
  },
  {
    placeId: 4791, cityName: 'CHARLEVOIX', state: 'MI', countryName: 'UNITED STATES',
  },
  { placeId: 1260, cityName: 'CHARLOTTE', countryName: 'UNITED STATES' },
  { placeId: 4793, cityName: 'CHARLOTTE', countryName: 'UNITED STATES' },
  { placeId: 4794, cityName: 'CHARLOTTESVILLE', countryName: 'UNITED STATES' },
  { placeId: 2973, cityName: 'CHASE CITY', countryName: 'UNITED STATES' },
  { placeId: 10154, cityName: 'CHATHAM', countryName: 'UNITED STATES' },
  { placeId: 1256, cityName: 'CHATTANOOGA', countryName: 'UNITED STATES' },
  { placeId: 4800, cityName: 'CHEBOYGAN', countryName: 'UNITED STATES' },
  { placeId: 4801, cityName: 'CHEFORNAK', countryName: 'UNITED STATES' },
  { placeId: 4802, cityName: 'CHEHALIS', countryName: 'UNITED STATES' },
  { placeId: 10162, cityName: 'CHENEGA', countryName: 'UNITED STATES' },
  { placeId: 4804, cityName: 'CHERAW', countryName: 'UNITED STATES' },
  { placeId: 4806, cityName: 'CHEROKEE', countryName: 'UNITED STATES' },
  { placeId: 4807, cityName: 'CHEROKEE', countryName: 'UNITED STATES' },
  { placeId: 10168, cityName: 'CHERRY POINT', countryName: 'UNITED STATES' },
  { placeId: 3036, cityName: 'CHESAPEAKE', countryName: 'UNITED STATES' },
  { placeId: 10172, cityName: 'CHESTER', countryName: 'UNITED STATES' },
  { placeId: 4808, cityName: 'CHESTER', countryName: 'UNITED STATES' },
  { placeId: 10171, cityName: 'CHESTER', countryName: 'UNITED STATES' },
  { placeId: 4810, cityName: 'CHEVAK', countryName: 'UNITED STATES' },
  { placeId: 4811, cityName: 'CHEYENNE', countryName: 'UNITED STATES' },
  {
    placeId: 1326, cityName: 'CHICAGO', state: 'IL', countryName: 'UNITED STATES',
  },
  { placeId: 4814, cityName: 'CHICAGO/AURORA', countryName: 'UNITED STATES' },
  {
    placeId: 4815,
    cityName: 'CHICAGO/PROSPECT HGTS/WHEELING',
    state: 'IL',
    countryName: 'UNITED STATES',
  },
  { placeId: 4816, cityName: 'CHICAGO/ROCKFORD', countryName: 'UNITED STATES' },
  { placeId: 4817, cityName: 'CHICAGO/ROMEOVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 4818, cityName: 'CHICAGO/WAUKEGAN', state: 'IL', countryName: 'UNITED STATES',
  },
  { placeId: 4819, cityName: 'CHICKASHA', countryName: 'UNITED STATES' },
  { placeId: 4820, cityName: 'CHICO', countryName: 'UNITED STATES' },
  { placeId: 10190, cityName: 'CHICO', countryName: 'UNITED STATES' },
  { placeId: 10191, cityName: 'CHIGNIK', countryName: 'UNITED STATES' },
  { placeId: 4822, cityName: 'CHILDRESS', countryName: 'UNITED STATES' },
  { placeId: 4827, cityName: 'CHILLICOTHE', countryName: 'UNITED STATES' },
  { placeId: 4826, cityName: 'CHILLICOTHE', countryName: 'UNITED STATES' },
  { placeId: 4838, cityName: 'CHINO', countryName: 'UNITED STATES' },
  { placeId: 10205, cityName: 'CHITTENANGO', countryName: 'UNITED STATES' },
  { placeId: 4856, cityName: 'CHOTEAU', countryName: 'UNITED STATES' },
  { placeId: 10218, cityName: 'CHUATHBALUK', countryName: 'UNITED STATES' },
  {
    placeId: 1268, cityName: 'CINCINNATI', state: 'OH', countryName: 'UNITED STATES',
  },
  { placeId: 4865, cityName: 'CIRCLE', countryName: 'UNITED STATES' },
  { placeId: 4866, cityName: 'CIRCLEVILLE', countryName: 'UNITED STATES' },
  { placeId: 4872, cityName: 'CLAREMONT', countryName: 'UNITED STATES' },
  { placeId: 4873, cityName: 'CLAREMORE', countryName: 'UNITED STATES' },
  { placeId: 4874, cityName: 'CLARENCE CENTER', countryName: 'UNITED STATES' },
  { placeId: 3040, cityName: 'CLARINDA', countryName: 'UNITED STATES' },
  { placeId: 4876, cityName: 'CLARION', countryName: 'UNITED STATES' },
  { placeId: 4877, cityName: 'CLARION', countryName: 'UNITED STATES' },
  { placeId: 10245, cityName: 'CLARKS POINT', countryName: 'UNITED STATES' },
  { placeId: 4878, cityName: 'CLARKSBURG', countryName: 'UNITED STATES' },
  { placeId: 4879, cityName: 'CLARKSBURG', countryName: 'UNITED STATES' },
  { placeId: 4880, cityName: 'CLARKSDALE', countryName: 'UNITED STATES' },
  { placeId: 4882, cityName: 'CLARKSVILLE', countryName: 'UNITED STATES' },
  { placeId: 4881, cityName: 'CLARKSVILLE', countryName: 'UNITED STATES' },
  { placeId: 4883, cityName: 'CLAXTON', countryName: 'UNITED STATES' },
  { placeId: 4884, cityName: 'CLAY CENTER', countryName: 'UNITED STATES' },
  { placeId: 4885, cityName: 'CLAYTON', countryName: 'UNITED STATES' },
  { placeId: 4886, cityName: 'CLAYTON', countryName: 'UNITED STATES' },
  { placeId: 4887, cityName: 'CLEAR', countryName: 'UNITED STATES' },
  { placeId: 4888, cityName: 'CLEARFIELD', countryName: 'UNITED STATES' },
  { placeId: 4890, cityName: 'CLEARWATER', countryName: 'UNITED STATES' },
  { placeId: 4892, cityName: 'CLEBURNE', countryName: 'UNITED STATES' },
  { placeId: 4894, cityName: 'CLEMSON', countryName: 'UNITED STATES' },
  { placeId: 4895, cityName: 'CLEVELAND', countryName: 'UNITED STATES' },
  {
    placeId: 1258, cityName: 'CLEVELAND', state: 'OH', countryName: 'UNITED STATES',
  },
  { placeId: 3025, cityName: 'CLEVELAND', countryName: 'UNITED STATES' },
  { placeId: 4896, cityName: 'CLIFTON-MORENCI', countryName: 'UNITED STATES' },
  { placeId: 4897, cityName: 'CLINTON', countryName: 'UNITED STATES' },
  { placeId: 4899, cityName: 'CLINTON', countryName: 'UNITED STATES' },
  { placeId: 4898, cityName: 'CLINTON', countryName: 'UNITED STATES' },
  { placeId: 4900, cityName: 'CLINTON', countryName: 'UNITED STATES' },
  { placeId: 2972, cityName: 'CLINTON', countryName: 'UNITED STATES' },
  { placeId: 4901, cityName: 'CLINTONVILLE', countryName: 'UNITED STATES' },
  { placeId: 4903, cityName: 'CLOQUET', countryName: 'UNITED STATES' },
  { placeId: 4904, cityName: 'CLOVIS', countryName: 'UNITED STATES' },
  { placeId: 3078, cityName: 'COATESVILLE', countryName: 'UNITED STATES' },
  { placeId: 10284, cityName: 'COCOA BEACH', countryName: 'UNITED STATES' },
  { placeId: 2840, cityName: 'CODY', countryName: 'UNITED STATES' },
  { placeId: 3905, cityName: 'COEUR D\'ALENE', countryName: 'UNITED STATES' },
  { placeId: 4918, cityName: 'COFFEYVILLE', countryName: 'UNITED STATES' },
  { placeId: 4920, cityName: 'COLBY', countryName: 'UNITED STATES' },
  { placeId: 2391, cityName: 'COLD BAY', countryName: 'UNITED STATES' },
  { placeId: 10294, cityName: 'COLDFOOT', countryName: 'UNITED STATES' },
  { placeId: 3082, cityName: 'COLDWATER', countryName: 'UNITED STATES' },
  { placeId: 4921, cityName: 'COLEMAN', countryName: 'UNITED STATES' },
  { placeId: 4923, cityName: 'COLLEGE PARK', countryName: 'UNITED STATES' },
  {
    placeId: 4924, cityName: 'COLLEGE STATION', state: 'TX', countryName: 'UNITED STATES',
  },
  { placeId: 10301, cityName: 'COLLEGEDALE', countryName: 'UNITED STATES' },
  { placeId: 4932, cityName: 'COLORADO CITY', countryName: 'UNITED STATES' },
  { placeId: 10305, cityName: 'COLORADO SPRINGS', countryName: 'UNITED STATES' },
  {
    placeId: 1262, cityName: 'COLORADO SPRINGS', state: 'CO', countryName: 'UNITED STATES',
  },
  { placeId: 3998, cityName: 'COLUMBIA', countryName: 'UNITED STATES' },
  {
    placeId: 1253, cityName: 'COLUMBIA', state: 'SC', countryName: 'UNITED STATES',
  },
  { placeId: 3999, cityName: 'COLUMBIA/MT PLEASANT', countryName: 'UNITED STATES' },
  { placeId: 4003, cityName: 'COLUMBUS-W POINT-STARKVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 1261, cityName: 'COLUMBUS', state: 'OH', countryName: 'UNITED STATES',
  },
  { placeId: 4002, cityName: 'COLUMBUS', countryName: 'UNITED STATES' },
  { placeId: 3145, cityName: 'COLUMBUS', countryName: 'UNITED STATES' },
  { placeId: 4001, cityName: 'COLUMBUS', countryName: 'UNITED STATES' },
  { placeId: 4000, cityName: 'COLUMBUS', countryName: 'UNITED STATES' },
  { placeId: 4005, cityName: 'COMANCHE', countryName: 'UNITED STATES' },
  { placeId: 10319, cityName: 'COMPTON', countryName: 'UNITED STATES' },
  { placeId: 4937, cityName: 'CONCORD', countryName: 'UNITED STATES' },
  {
    placeId: 4936, cityName: 'CONCORD', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 4938, cityName: 'CONCORD', countryName: 'UNITED STATES' },
  { placeId: 4939, cityName: 'CONCORDIA', countryName: 'UNITED STATES' },
  { placeId: 3153, cityName: 'CONNELLSVILLE', countryName: 'UNITED STATES' },
  { placeId: 4943, cityName: 'CONNERSVILLE', countryName: 'UNITED STATES' },
  { placeId: 4950, cityName: 'CONWAY', countryName: 'UNITED STATES' },
  { placeId: 4949, cityName: 'CONWAY', countryName: 'UNITED STATES' },
  { placeId: 4952, cityName: 'COOK', countryName: 'UNITED STATES' },
  { placeId: 4956, cityName: 'CORCORAN', countryName: 'UNITED STATES' },
  { placeId: 4957, cityName: 'CORDELE', countryName: 'UNITED STATES' },
  { placeId: 10339, cityName: 'CORDOVA', countryName: 'UNITED STATES' },
  { placeId: 4958, cityName: 'CORINTH', countryName: 'UNITED STATES' },
  { placeId: 4961, cityName: 'CORNELIA', countryName: 'UNITED STATES' },
  { placeId: 4962, cityName: 'CORNING', countryName: 'UNITED STATES' },
  { placeId: 4965, cityName: 'CORONA', countryName: 'UNITED STATES' },
  { placeId: 10347, cityName: 'CORPUS CHRISTI', countryName: 'UNITED STATES' },
  { placeId: 1264, cityName: 'CORPUS CHRISTI', countryName: 'UNITED STATES' },
  { placeId: 4968, cityName: 'CORSICANA', countryName: 'UNITED STATES' },
  { placeId: 4970, cityName: 'CORTEZ', countryName: 'UNITED STATES' },
  { placeId: 2971, cityName: 'CORVALLIS', countryName: 'UNITED STATES' },
  { placeId: 4976, cityName: 'COTULLA', countryName: 'UNITED STATES' },
  { placeId: 4977, cityName: 'COUNCIL BLUFFS', countryName: 'UNITED STATES' },
  { placeId: 10356, cityName: 'COUPEVILLE', countryName: 'UNITED STATES' },
  { placeId: 4984, cityName: 'COZAD', countryName: 'UNITED STATES' },
  { placeId: 4985, cityName: 'CRAIG', countryName: 'UNITED STATES' },
  { placeId: 4988, cityName: 'CRAWFORDSVILLE', countryName: 'UNITED STATES' },
  { placeId: 10369, cityName: 'CRESCENT CITY', countryName: 'UNITED STATES' },
  { placeId: 4990, cityName: 'CRESCO', countryName: 'UNITED STATES' },
  { placeId: 4991, cityName: 'CRESTON', countryName: 'UNITED STATES' },
  { placeId: 2989, cityName: 'CRESTVIEW', countryName: 'UNITED STATES' },
  { placeId: 4992, cityName: 'CRETE', countryName: 'UNITED STATES' },
  { placeId: 4993, cityName: 'CROCKETT', countryName: 'UNITED STATES' },
  { placeId: 4995, cityName: 'CROOKSTON', countryName: 'UNITED STATES' },
  { placeId: 4996, cityName: 'CROSS CITY', countryName: 'UNITED STATES' },
  { placeId: 4998, cityName: 'CROSSETT', countryName: 'UNITED STATES' },
  { placeId: 2969, cityName: 'CROSSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5001, cityName: 'CRYSTAL RIVER', countryName: 'UNITED STATES' },
  { placeId: 5003, cityName: 'CUBA', countryName: 'UNITED STATES' },
  { placeId: 5006, cityName: 'CULPEPER', countryName: 'UNITED STATES' },
  { placeId: 2968, cityName: 'CUMBERLAND', countryName: 'UNITED STATES' },
  { placeId: 5009, cityName: 'CUMBERLAND', countryName: 'UNITED STATES' },
  { placeId: 5011, cityName: 'CUMMING', countryName: 'UNITED STATES' },
  { placeId: 5013, cityName: 'CURRITUCK', countryName: 'UNITED STATES' },
  { placeId: 5014, cityName: 'CUSHING', countryName: 'UNITED STATES' },
  { placeId: 2970, cityName: 'CUSTER', countryName: 'UNITED STATES' },
  { placeId: 1267, cityName: 'CUT BANK', countryName: 'UNITED STATES' },
  { placeId: 5017, cityName: 'CYPRESS', countryName: 'UNITED STATES' },
  { placeId: 5046, cityName: 'DAGGETT', countryName: 'UNITED STATES' },
  { placeId: 5047, cityName: 'DAHLGREN', countryName: 'UNITED STATES' },
  { placeId: 5053, cityName: 'DALHART', countryName: 'UNITED STATES' },
  {
    placeId: 1272, cityName: 'DALLAS-FT WORTH', state: 'TX', countryName: 'UNITED STATES',
  },
  {
    placeId: 1269, cityName: 'DALLAS', state: 'TX', countryName: 'UNITED STATES',
  },
  { placeId: 5055, cityName: 'DALTON', countryName: 'UNITED STATES' },
  { placeId: 5058, cityName: 'DANBURY', countryName: 'UNITED STATES' },
  { placeId: 10417, cityName: 'DANIELSON', countryName: 'UNITED STATES' },
  { placeId: 5061, cityName: 'DANSVILLE', countryName: 'UNITED STATES' },
  { placeId: 2981, cityName: 'DANVILLE', countryName: 'UNITED STATES' },
  { placeId: 5062, cityName: 'DANVILLE', countryName: 'UNITED STATES' },
  { placeId: 2976, cityName: 'DANVILLE', countryName: 'UNITED STATES' },
  { placeId: 5066, cityName: 'DARLINGTON', countryName: 'UNITED STATES' },
  { placeId: 2984, cityName: 'DAVENPORT', countryName: 'UNITED STATES' },
  { placeId: 5073, cityName: 'DAVIS', countryName: 'UNITED STATES' },
  { placeId: 10431, cityName: 'DAVIS/WOODLAND/WINTERS', countryName: 'UNITED STATES' },
  { placeId: 1270, cityName: 'DAYTON', countryName: 'UNITED STATES' },
  {
    placeId: 2846, cityName: 'DAYTONA BEACH', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 5076, cityName: 'DE KALB', countryName: 'UNITED STATES' },
  { placeId: 5077, cityName: 'DE LAND', countryName: 'UNITED STATES' },
  { placeId: 5078, cityName: 'DE QUEEN', countryName: 'UNITED STATES' },
  { placeId: 5079, cityName: 'DE RIDDER', countryName: 'UNITED STATES' },
  { placeId: 2401, cityName: 'DEADHORSE', countryName: 'UNITED STATES' },
  { placeId: 10441, cityName: 'DEADHORSE', countryName: 'UNITED STATES' },
  {
    placeId: 5087, cityName: 'DECATUR', state: 'IL', countryName: 'UNITED STATES',
  },
  { placeId: 5088, cityName: 'DECATUR', countryName: 'UNITED STATES' },
  { placeId: 5089, cityName: 'DECATUR', countryName: 'UNITED STATES' },
  { placeId: 5090, cityName: 'DECORAH', countryName: 'UNITED STATES' },
  { placeId: 5092, cityName: 'DEER PARK', countryName: 'UNITED STATES' },
  { placeId: 10454, cityName: 'DEERING', countryName: 'UNITED STATES' },
  { placeId: 5094, cityName: 'DEFIANCE', countryName: 'UNITED STATES' },
  { placeId: 1274, cityName: 'DEL RIO', countryName: 'UNITED STATES' },
  { placeId: 10459, cityName: 'DEL RIO', countryName: 'UNITED STATES' },
  { placeId: 5099, cityName: 'DELANO', countryName: 'UNITED STATES' },
  { placeId: 5101, cityName: 'DELAWARE', countryName: 'UNITED STATES' },
  { placeId: 2389, cityName: 'DELTA JUNCTION/FT GREELY', countryName: 'UNITED STATES' },
  { placeId: 5147, cityName: 'DELTA', countryName: 'UNITED STATES' },
  { placeId: 5148, cityName: 'DELTA', countryName: 'UNITED STATES' },
  { placeId: 5150, cityName: 'DEMING', countryName: 'UNITED STATES' },
  { placeId: 10472, cityName: 'DEMOPOLIS', countryName: 'UNITED STATES' },
  { placeId: 5153, cityName: 'DENISON', countryName: 'UNITED STATES' },
  { placeId: 5154, cityName: 'DENTON', countryName: 'UNITED STATES' },
  {
    placeId: 1271, cityName: 'DENVER', state: 'CO', countryName: 'UNITED STATES',
  },
  {
    placeId: 1275, cityName: 'DES MOINES', state: 'IA', countryName: 'UNITED STATES',
  },
  { placeId: 10478, cityName: 'DESERT CENTER', countryName: 'UNITED STATES' },
  { placeId: 10479, cityName: 'DESTIN', countryName: 'UNITED STATES' },
  { placeId: 5160, cityName: 'DETROIT LAKES', countryName: 'UNITED STATES' },
  {
    placeId: 1276, cityName: 'DETROIT', state: 'MI', countryName: 'UNITED STATES',
  },
  { placeId: 10480, cityName: 'DETROIT', countryName: 'UNITED STATES' },
  { placeId: 10482, cityName: 'DETROIT/GROSSE ILE', countryName: 'UNITED STATES' },
  { placeId: 5161, cityName: 'DEVILS LAKE', countryName: 'UNITED STATES' },
  { placeId: 5162, cityName: 'DEXTER', countryName: 'UNITED STATES' },
  { placeId: 2979, cityName: 'DICKINSON', countryName: 'UNITED STATES' },
  { placeId: 2392, cityName: 'DILLINGHAM', countryName: 'UNITED STATES' },
  { placeId: 5175, cityName: 'DILLON', countryName: 'UNITED STATES' },
  { placeId: 5176, cityName: 'DILLON', countryName: 'UNITED STATES' },
  { placeId: 10497, cityName: 'DIXON', countryName: 'UNITED STATES' },
  { placeId: 5187, cityName: 'DODGE CENTER', countryName: 'UNITED STATES' },
  { placeId: 5188, cityName: 'DODGE CITY', countryName: 'UNITED STATES' },
  { placeId: 5203, cityName: 'DOTHAN', countryName: 'UNITED STATES' },
  { placeId: 10511, cityName: 'DOUGLAS BISBEE', countryName: 'UNITED STATES' },
  { placeId: 10510, cityName: 'DOUGLAS', countryName: 'UNITED STATES' },
  { placeId: 5206, cityName: 'DOUGLAS', countryName: 'UNITED STATES' },
  { placeId: 5207, cityName: 'DOUGLAS', countryName: 'UNITED STATES' },
  { placeId: 10513, cityName: 'DOVER', countryName: 'UNITED STATES' },
  { placeId: 5209, cityName: 'DOWNERS GROVE', countryName: 'UNITED STATES' },
  { placeId: 5210, cityName: 'DOYLESTOWN', countryName: 'UNITED STATES' },
  { placeId: 10517, cityName: 'DRESDEN', countryName: 'UNITED STATES' },
  { placeId: 5021, cityName: 'DRIGGS', countryName: 'UNITED STATES' },
  { placeId: 10520, cityName: 'DRUMMOND I', countryName: 'UNITED STATES' },
  { placeId: 5213, cityName: 'DUBLIN', countryName: 'UNITED STATES' },
  { placeId: 5214, cityName: 'DUBLIN', countryName: 'UNITED STATES' },
  { placeId: 5215, cityName: 'DUBOIS', countryName: 'UNITED STATES' },
  { placeId: 5216, cityName: 'DUBUQUE', countryName: 'UNITED STATES' },
  { placeId: 10526, cityName: 'DUGWAY PROVING GROUND', countryName: 'UNITED STATES' },
  {
    placeId: 1273, cityName: 'DULUTH', state: 'MN', countryName: 'UNITED STATES',
  },
  {
    placeId: 10527, cityName: 'DULUTH', state: 'MINNESOTA', countryName: 'UNITED STATES',
  },
  { placeId: 5218, cityName: 'DUMAS', countryName: 'UNITED STATES' },
  { placeId: 5220, cityName: 'DUNCAN', countryName: 'UNITED STATES' },
  { placeId: 5224, cityName: 'DUNKIRK', countryName: 'UNITED STATES' },
  { placeId: 5225, cityName: 'DURANGO', countryName: 'UNITED STATES' },
  { placeId: 2982, cityName: 'DURANT', countryName: 'UNITED STATES' },
  { placeId: 5231, cityName: 'DWIGHT', countryName: 'UNITED STATES' },
  { placeId: 5232, cityName: 'DYERSBURG', countryName: 'UNITED STATES' },
  { placeId: 5235, cityName: 'EAGLE GROVE', countryName: 'UNITED STATES' },
  { placeId: 5236, cityName: 'EAGLE LAKE', countryName: 'UNITED STATES' },
  { placeId: 5237, cityName: 'EAGLE RIVER', countryName: 'UNITED STATES' },
  {
    placeId: 5234, cityName: 'EAGLE', state: 'CO', countryName: 'UNITED STATES',
  },
  { placeId: 5233, cityName: 'EAGLE', countryName: 'UNITED STATES' },
  { placeId: 3035, cityName: 'EAST HAMPTON', countryName: 'UNITED STATES' },
  { placeId: 5249, cityName: 'EASTLAND', countryName: 'UNITED STATES' },
  { placeId: 5251, cityName: 'EASTMAN', countryName: 'UNITED STATES' },
  { placeId: 5253, cityName: 'EASTON', countryName: 'UNITED STATES' },
  { placeId: 5252, cityName: 'EASTON', countryName: 'UNITED STATES' },
  { placeId: 5254, cityName: 'EASTOVER', countryName: 'UNITED STATES' },
  { placeId: 10561, cityName: 'EASTPORT', countryName: 'UNITED STATES' },
  { placeId: 5255, cityName: 'EASTSOUND', countryName: 'UNITED STATES' },
  { placeId: 5257, cityName: 'EAU CLAIRE', countryName: 'UNITED STATES' },
  { placeId: 10569, cityName: 'EDENTON', countryName: 'UNITED STATES' },
  { placeId: 5267, cityName: 'EDINBURG', countryName: 'UNITED STATES' },
  { placeId: 5270, cityName: 'EDWARDS', countryName: 'UNITED STATES' },
  { placeId: 10578, cityName: 'EEK', countryName: 'UNITED STATES' },
  { placeId: 5272, cityName: 'EGEGIK', countryName: 'UNITED STATES' },
  { placeId: 10583, cityName: 'EL CENTRO', countryName: 'UNITED STATES' },
  { placeId: 5282, cityName: 'EL DORADO', countryName: 'UNITED STATES' },
  { placeId: 5284, cityName: 'EL DORADO', countryName: 'UNITED STATES' },
  { placeId: 5292, cityName: 'EL MONTE', countryName: 'UNITED STATES' },
  {
    placeId: 10588, cityName: 'EL PASO', state: 'TEXAS', countryName: 'UNITED STATES',
  },
  { placeId: 5295, cityName: 'EL RENO', countryName: 'UNITED STATES' },
  { placeId: 10593, cityName: 'ELIM', countryName: 'UNITED STATES' },
  { placeId: 5105, cityName: 'ELIZABETH CITY', countryName: 'UNITED STATES' },
  { placeId: 5106, cityName: 'ELIZABETHTOWN', countryName: 'UNITED STATES' },
  { placeId: 2993, cityName: 'ELIZABETHTOWN', countryName: 'UNITED STATES' },
  { placeId: 5107, cityName: 'ELK CITY', countryName: 'UNITED STATES' },
  { placeId: 5111, cityName: 'ELKHART', countryName: 'UNITED STATES' },
  { placeId: 5112, cityName: 'ELKHART', countryName: 'UNITED STATES' },
  { placeId: 5113, cityName: 'ELKIN', countryName: 'UNITED STATES' },
  { placeId: 5115, cityName: 'ELKINS', countryName: 'UNITED STATES' },
  { placeId: 5116, cityName: 'ELKO', countryName: 'UNITED STATES' },
  { placeId: 5117, cityName: 'ELLENSBURG', countryName: 'UNITED STATES' },
  { placeId: 5306, cityName: 'ELMIRA', countryName: 'UNITED STATES' },
  { placeId: 5309, cityName: 'ELY', countryName: 'UNITED STATES' },
  { placeId: 5308, cityName: 'ELY', countryName: 'UNITED STATES' },
  { placeId: 5314, cityName: 'EMIGRANT GAP', countryName: 'UNITED STATES' },
  { placeId: 5315, cityName: 'EMMETSBURG', countryName: 'UNITED STATES' },
  { placeId: 5316, cityName: 'EMMONAK', countryName: 'UNITED STATES' },
  { placeId: 2994, cityName: 'EMPORIA', countryName: 'UNITED STATES' },
  { placeId: 5318, cityName: 'EMPORIA', countryName: 'UNITED STATES' },
  { placeId: 5323, cityName: 'ENDICOTT', countryName: 'UNITED STATES' },
  { placeId: 5325, cityName: 'ENID', countryName: 'UNITED STATES' },
  { placeId: 5326, cityName: 'ENNIS', countryName: 'UNITED STATES' },
  { placeId: 5327, cityName: 'ENTERPRISE', countryName: 'UNITED STATES' },
  { placeId: 5329, cityName: 'EPHRATA', countryName: 'UNITED STATES' },
  { placeId: 5024, cityName: 'ERIE', countryName: 'UNITED STATES' },
  { placeId: 1277, cityName: 'ERIE', countryName: 'UNITED STATES' },
  { placeId: 5028, cityName: 'ERROL', countryName: 'UNITED STATES' },
  { placeId: 5029, cityName: 'ERWIN', countryName: 'UNITED STATES' },
  { placeId: 10642, cityName: 'ESCANABA', countryName: 'UNITED STATES' },
  { placeId: 5336, cityName: 'ESTHERVILLE', countryName: 'UNITED STATES' },
  { placeId: 5339, cityName: 'EUFAULA', countryName: 'UNITED STATES' },
  { placeId: 2998, cityName: 'EUGENE', countryName: 'UNITED STATES' },
  { placeId: 5340, cityName: 'EUREKA', countryName: 'UNITED STATES' },
  { placeId: 5341, cityName: 'EVANSTON', countryName: 'UNITED STATES' },
  { placeId: 2999, cityName: 'EVANSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5342, cityName: 'EVELETH', countryName: 'UNITED STATES' },
  { placeId: 1348, cityName: 'EVERETT', countryName: 'UNITED STATES' },
  { placeId: 5343, cityName: 'EVERGREEN', countryName: 'UNITED STATES' },
  {
    placeId: 2394, cityName: 'FAIRBANKS', state: 'AK', countryName: 'UNITED STATES',
  },
  { placeId: 5353, cityName: 'FAIRBURY', countryName: 'UNITED STATES' },
  { placeId: 5354, cityName: 'FAIRFIELD', countryName: 'UNITED STATES' },
  { placeId: 5356, cityName: 'FAIRFIELD', countryName: 'UNITED STATES' },
  { placeId: 5355, cityName: 'FAIRFIELD', countryName: 'UNITED STATES' },
  {
    placeId: 10668, cityName: 'FAIRHOPE', state: 'ALABAMA', countryName: 'UNITED STATES',
  },
  { placeId: 5357, cityName: 'FAIRMONT', countryName: 'UNITED STATES' },
  { placeId: 3007, cityName: 'FAIRMONT', countryName: 'UNITED STATES' },
  { placeId: 5361, cityName: 'FALFURRIAS', countryName: 'UNITED STATES' },
  { placeId: 5364, cityName: 'FALLON', countryName: 'UNITED STATES' },
  { placeId: 3005, cityName: 'FALLS CITY', countryName: 'UNITED STATES' },
  { placeId: 10679, cityName: 'FALMOUTH', countryName: 'UNITED STATES' },
  { placeId: 10680, cityName: 'FALSE PASS', countryName: 'UNITED STATES' },
  { placeId: 5370, cityName: 'FAREWELL LAKE', countryName: 'UNITED STATES' },
  { placeId: 5369, cityName: 'FAREWELL', countryName: 'UNITED STATES' },
  { placeId: 1279, cityName: 'FARGO', countryName: 'UNITED STATES' },
  { placeId: 5371, cityName: 'FARIBAULT', countryName: 'UNITED STATES' },
  {
    placeId: 3006, cityName: 'FARMINGDALE', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 5372, cityName: 'FARMINGTON', countryName: 'UNITED STATES' },
  { placeId: 5373, cityName: 'FARMINGTON', countryName: 'UNITED STATES' },
  { placeId: 5374, cityName: 'FARMVILLE', countryName: 'UNITED STATES' },
  { placeId: 5381, cityName: 'FAYETTEVILLE', countryName: 'UNITED STATES' },
  { placeId: 5382, cityName: 'FAYETTEVILLE', countryName: 'UNITED STATES' },
  { placeId: 5383, cityName: 'FAYETTEVILLE', countryName: 'UNITED STATES' },
  { placeId: 5384, cityName: 'FAYETTEVILLE/SPRINGDALE/ROGERS', countryName: 'UNITED STATES' },
  { placeId: 3081, cityName: 'FENTRESS', countryName: 'UNITED STATES' },
  { placeId: 5388, cityName: 'FERGUS FALLS', countryName: 'UNITED STATES' },
  { placeId: 10702, cityName: 'FERNANDINA BEACH', countryName: 'UNITED STATES' },
  { placeId: 3001, cityName: 'FESTUS', countryName: 'UNITED STATES' },
  { placeId: 5396, cityName: 'FILLMORE', countryName: 'UNITED STATES' },
  { placeId: 5398, cityName: 'FINDLAY', countryName: 'UNITED STATES' },
  { placeId: 5403, cityName: 'FITCHBURG', countryName: 'UNITED STATES' },
  { placeId: 5404, cityName: 'FITZGERALD', countryName: 'UNITED STATES' },
  {
    placeId: 5406, cityName: 'FLAGSTAFF', state: 'AZ', countryName: 'UNITED STATES',
  },
  { placeId: 5408, cityName: 'FLEMINGSBURG', countryName: 'UNITED STATES' },
  {
    placeId: 1282, cityName: 'FLINT', state: 'MI', countryName: 'UNITED STATES',
  },
  { placeId: 5410, cityName: 'FLIPPIN', countryName: 'UNITED STATES' },
  { placeId: 5411, cityName: 'FLORA', countryName: 'UNITED STATES' },
  { placeId: 5412, cityName: 'FLORENCE', countryName: 'UNITED STATES' },
  { placeId: 5420, cityName: 'FOND DU LAC', countryName: 'UNITED STATES' },
  { placeId: 5427, cityName: 'FOREST CITY', countryName: 'UNITED STATES' },
  { placeId: 10733, cityName: 'FOREST HILL', countryName: 'UNITED STATES' },
  { placeId: 3000, cityName: 'FORREST CITY', countryName: 'UNITED STATES' },
  { placeId: 10737, cityName: 'FORT A. P. HILL', countryName: 'UNITED STATES' },
  { placeId: 5435, cityName: 'FORT MEADE', countryName: 'UNITED STATES' },
  { placeId: 5437, cityName: 'FORTUNA', countryName: 'UNITED STATES' },
  { placeId: 5438, cityName: 'FOSSTON', countryName: 'UNITED STATES' },
  { placeId: 5439, cityName: 'FOSTORIA', countryName: 'UNITED STATES' },
  { placeId: 3158, cityName: 'FRANKFORT', countryName: 'UNITED STATES' },
  { placeId: 5449, cityName: 'FRANKFORT', countryName: 'UNITED STATES' },
  { placeId: 5447, cityName: 'FRANKFORT', countryName: 'UNITED STATES' },
  { placeId: 5448, cityName: 'FRANKFORT', countryName: 'UNITED STATES' },
  { placeId: 3003, cityName: 'FRANKLIN', countryName: 'UNITED STATES' },
  { placeId: 5450, cityName: 'FRANKLIN', countryName: 'UNITED STATES' },
  { placeId: 5453, cityName: 'FREDERICK', countryName: 'UNITED STATES' },
  { placeId: 5452, cityName: 'FREDERICK', countryName: 'UNITED STATES' },
  { placeId: 5454, cityName: 'FREDERICKSBURG', countryName: 'UNITED STATES' },
  { placeId: 5455, cityName: 'FREEPORT', countryName: 'UNITED STATES' },
  { placeId: 5120, cityName: 'FREMONT', countryName: 'UNITED STATES' },
  { placeId: 10766, cityName: 'FREMONT', countryName: 'UNITED STATES' },
  { placeId: 5121, cityName: 'FRENCH LICK', countryName: 'UNITED STATES' },
  { placeId: 5122, cityName: 'FRENCHVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 1280, cityName: 'FRESNO', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 10770, cityName: 'FRIDAY HARBOR', countryName: 'UNITED STATES' },
  { placeId: 5124, cityName: 'FRIENDLY', countryName: 'UNITED STATES' },
  { placeId: 5125, cityName: 'FRONT ROYAL', countryName: 'UNITED STATES' },
  { placeId: 5128, cityName: 'FRYEBURG', countryName: 'UNITED STATES' },
  { placeId: 2975, cityName: 'FT BELVOIR', countryName: 'UNITED STATES' },
  { placeId: 5130, cityName: 'FT BENNING', countryName: 'UNITED STATES' },
  { placeId: 2955, cityName: 'FT BLISS', countryName: 'UNITED STATES' },
  { placeId: 5131, cityName: 'FT BRAGG', countryName: 'UNITED STATES' },
  { placeId: 5456, cityName: 'FT BRIDGER', countryName: 'UNITED STATES' },
  { placeId: 3032, cityName: 'FT CAMPBELL/HOPKINSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5457, cityName: 'FT CARSON', countryName: 'UNITED STATES' },
  { placeId: 5459, cityName: 'FT COLLINS-LOVELAND', countryName: 'UNITED STATES' },
  { placeId: 1283, cityName: 'FT DODGE', countryName: 'UNITED STATES' },
  { placeId: 5460, cityName: 'FT DRUM', countryName: 'UNITED STATES' },
  { placeId: 5461, cityName: 'FT EUSTIS', countryName: 'UNITED STATES' },
  { placeId: 5463, cityName: 'FT HOOD', countryName: 'UNITED STATES' },
  { placeId: 5465, cityName: 'FT HUACHUCA', countryName: 'UNITED STATES' },
  { placeId: 5466, cityName: 'FT INDIANTOWN GAP', countryName: 'UNITED STATES' },
  { placeId: 5467, cityName: 'FT KNOX', countryName: 'UNITED STATES' },
  {
    placeId: 10796, cityName: 'FT LAUDERDALE', state: 'FLORIDA', countryName: 'UNITED STATES',
  },
  {
    placeId: 1281, cityName: 'FT LAUDERDALE', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 3004, cityName: 'FT LEAVENWORTH', countryName: 'UNITED STATES' },
  { placeId: 5468, cityName: 'FT LEONARD WOOD', countryName: 'UNITED STATES' },
  { placeId: 5469, cityName: 'FT LEWIS', countryName: 'UNITED STATES' },
  { placeId: 3010, cityName: 'FT MADISON', countryName: 'UNITED STATES' },
  { placeId: 5473, cityName: 'FT MORGAN', countryName: 'UNITED STATES' },
  {
    placeId: 1368, cityName: 'FT MYERS', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 5474, cityName: 'FT PIERCE', countryName: 'UNITED STATES' },
  { placeId: 5475, cityName: 'FT POLK', countryName: 'UNITED STATES' },
  { placeId: 5478, cityName: 'FT RILEY', countryName: 'UNITED STATES' },
  { placeId: 5479, cityName: 'FT RUCKER', countryName: 'UNITED STATES' },
  { placeId: 3009, cityName: 'FT SCOTT', countryName: 'UNITED STATES' },
  { placeId: 5481, cityName: 'FT SILL', countryName: 'UNITED STATES' },
  { placeId: 5483, cityName: 'FT SMITH', countryName: 'UNITED STATES' },
  { placeId: 5485, cityName: 'FT STEWART', countryName: 'UNITED STATES' },
  { placeId: 5486, cityName: 'FT STOCKTON', countryName: 'UNITED STATES' },
  { placeId: 5487, cityName: 'FT SUMNER', countryName: 'UNITED STATES' },
  { placeId: 2396, cityName: 'FT WAINWRIGHT', countryName: 'UNITED STATES' },
  { placeId: 5490, cityName: 'FT WAYNE', countryName: 'UNITED STATES' },
  {
    placeId: 1284, cityName: 'FT WORTH', state: 'TX', countryName: 'UNITED STATES',
  },
  { placeId: 5491, cityName: 'FT YUKON', countryName: 'UNITED STATES' },
  { placeId: 5496, cityName: 'FULLERTON', countryName: 'UNITED STATES' },
  { placeId: 5497, cityName: 'FULTON', countryName: 'UNITED STATES' },
  { placeId: 5498, cityName: 'FULTON', countryName: 'UNITED STATES' },
  { placeId: 5505, cityName: 'GABBS', countryName: 'UNITED STATES' },
  { placeId: 5507, cityName: 'GADSDEN', countryName: 'UNITED STATES' },
  { placeId: 5509, cityName: 'GAGE', countryName: 'UNITED STATES' },
  { placeId: 5511, cityName: 'GAINESVILLE', countryName: 'UNITED STATES' },
  { placeId: 5512, cityName: 'GAINESVILLE', countryName: 'UNITED STATES' },
  { placeId: 5510, cityName: 'GAINESVILLE', countryName: 'UNITED STATES' },
  { placeId: 5513, cityName: 'GAITHERSBURG', countryName: 'UNITED STATES' },
  { placeId: 3030, cityName: 'GALAX-HILLSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5515, cityName: 'GALBRAITH LAKE', countryName: 'UNITED STATES' },
  { placeId: 2397, cityName: 'GALENA', countryName: 'UNITED STATES' },
  { placeId: 5517, cityName: 'GALESBURG', countryName: 'UNITED STATES' },
  { placeId: 5518, cityName: 'GALION', countryName: 'UNITED STATES' },
  { placeId: 5031, cityName: 'GALLIANO', countryName: 'UNITED STATES' },
  { placeId: 3011, cityName: 'GALLIPOLIS', countryName: 'UNITED STATES' },
  { placeId: 5032, cityName: 'GALLUP', countryName: 'UNITED STATES' },
  { placeId: 10857, cityName: 'GALVESTON', countryName: 'UNITED STATES' },
  { placeId: 5519, cityName: 'GAMBELL', countryName: 'UNITED STATES' },
  { placeId: 5529, cityName: 'GARDEN CITY', countryName: 'UNITED STATES' },
  { placeId: 5531, cityName: 'GARDNER', countryName: 'UNITED STATES' },
  { placeId: 3023, cityName: 'GARY', countryName: 'UNITED STATES' },
  { placeId: 2943, cityName: 'GASTONIA', countryName: 'UNITED STATES' },
  { placeId: 5539, cityName: 'GATESVILLE', countryName: 'UNITED STATES' },
  { placeId: 5542, cityName: 'GAYLORD', countryName: 'UNITED STATES' },
  { placeId: 3015, cityName: 'GEORGETOWN', countryName: 'UNITED STATES' },
  { placeId: 3014, cityName: 'GEORGETOWN', countryName: 'UNITED STATES' },
  { placeId: 5550, cityName: 'GEORGETOWN', countryName: 'UNITED STATES' },
  { placeId: 10879, cityName: 'GEORGETOWN', countryName: 'UNITED STATES' },
  { placeId: 5560, cityName: 'GIDDINGS', countryName: 'UNITED STATES' },
  { placeId: 5564, cityName: 'GILLETTE', countryName: 'UNITED STATES' },
  { placeId: 5565, cityName: 'GILMER', countryName: 'UNITED STATES' },
  { placeId: 5574, cityName: 'GLADWIN', countryName: 'UNITED STATES' },
  { placeId: 1287, cityName: 'GLASGOW', countryName: 'UNITED STATES' },
  { placeId: 3019, cityName: 'GLASGOW', countryName: 'UNITED STATES' },
  { placeId: 5577, cityName: 'GLENCOE', countryName: 'UNITED STATES' },
  { placeId: 5578, cityName: 'GLENDALE', countryName: 'UNITED STATES' },
  { placeId: 5579, cityName: 'GLENDIVE', countryName: 'UNITED STATES' },
  { placeId: 5582, cityName: 'GLENS FALLS', countryName: 'UNITED STATES' },
  { placeId: 5585, cityName: 'GLENWOOD SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 5584, cityName: 'GLENWOOD', countryName: 'UNITED STATES' },
  { placeId: 5590, cityName: 'GODDARD', countryName: 'UNITED STATES' },
  { placeId: 5597, cityName: 'GOLDSBORO', countryName: 'UNITED STATES' },
  { placeId: 10922, cityName: 'GOLOVIN', countryName: 'UNITED STATES' },
  { placeId: 5132, cityName: 'GOODING', countryName: 'UNITED STATES' },
  { placeId: 3018, cityName: 'GOODLAND', countryName: 'UNITED STATES' },
  { placeId: 5134, cityName: 'GOODYEAR', countryName: 'UNITED STATES' },
  { placeId: 3021, cityName: 'GORDON', countryName: 'UNITED STATES' },
  { placeId: 5137, cityName: 'GORDONSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5144, cityName: 'GOSHEN', countryName: 'UNITED STATES' },
  { placeId: 5603, cityName: 'GOTHENBURG', countryName: 'UNITED STATES' },
  { placeId: 10940, cityName: 'GOUVERNEUR', countryName: 'UNITED STATES' },
  { placeId: 5610, cityName: 'GRAFTON', countryName: 'UNITED STATES' },
  { placeId: 5662, cityName: 'GRAHAM', countryName: 'UNITED STATES' },
  { placeId: 5664, cityName: 'GRANBURY', countryName: 'UNITED STATES' },
  { placeId: 5665, cityName: 'GRANBY', countryName: 'UNITED STATES' },
  { placeId: 5668, cityName: 'GRAND CANYON', countryName: 'UNITED STATES' },
  { placeId: 1286, cityName: 'GRAND FORKS', countryName: 'UNITED STATES' },
  { placeId: 5672, cityName: 'GRAND ISLAND', countryName: 'UNITED STATES' },
  { placeId: 5673, cityName: 'GRAND JUNCTION', countryName: 'UNITED STATES' },
  { placeId: 5674, cityName: 'GRAND MARAIS', countryName: 'UNITED STATES' },
  { placeId: 5675, cityName: 'GRAND PRAIRIE', countryName: 'UNITED STATES' },
  {
    placeId: 1290, cityName: 'GRAND RAPIDS', state: 'MI', countryName: 'UNITED STATES',
  },
  { placeId: 5677, cityName: 'GRAND RAPIDS', countryName: 'UNITED STATES' },
  { placeId: 10965, cityName: 'GRANGEVILLE', countryName: 'UNITED STATES' },
  { placeId: 5684, cityName: 'GRANITE FALLS', countryName: 'UNITED STATES' },
  { placeId: 3016, cityName: 'GRANT', countryName: 'UNITED STATES' },
  { placeId: 5686, cityName: 'GRANTS', countryName: 'UNITED STATES' },
  { placeId: 5687, cityName: 'GRANTSBURG', countryName: 'UNITED STATES' },
  { placeId: 5688, cityName: 'GRASS VALLEY', countryName: 'UNITED STATES' },
  { placeId: 5691, cityName: 'GRAYLING', countryName: 'UNITED STATES' },
  { placeId: 5693, cityName: 'GREAT BARRINGTON', countryName: 'UNITED STATES' },
  { placeId: 5695, cityName: 'GREAT BEND', countryName: 'UNITED STATES' },
  {
    placeId: 1293, cityName: 'GREAT FALLS', state: 'MT', countryName: 'UNITED STATES',
  },
  { placeId: 5033, cityName: 'GREELEY', countryName: 'UNITED STATES' },
  {
    placeId: 1289, cityName: 'GREEN BAY', state: 'WI', countryName: 'UNITED STATES',
  },
  { placeId: 10981, cityName: 'GREEN COVE SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 3013, cityName: 'GREENEVILLE', countryName: 'UNITED STATES' },
  { placeId: 5700, cityName: 'GREENFIELD', countryName: 'UNITED STATES' },
  { placeId: 5699, cityName: 'GREENFIELD', countryName: 'UNITED STATES' },
  {
    placeId: 1291, cityName: 'GREENSBORO', state: 'NC', countryName: 'UNITED STATES',
  },
  { placeId: 5701, cityName: 'GREENVILLE', countryName: 'UNITED STATES' },
  { placeId: 5705, cityName: 'GREENVILLE', countryName: 'UNITED STATES' },
  { placeId: 5702, cityName: 'GREENVILLE', countryName: 'UNITED STATES' },
  { placeId: 1353, cityName: 'GREENVILLE', countryName: 'UNITED STATES' },
  { placeId: 5703, cityName: 'GREENVILLE', countryName: 'UNITED STATES' },
  { placeId: 5704, cityName: 'GREENVILLE', countryName: 'UNITED STATES' },
  { placeId: 5707, cityName: 'GREENWOOD', countryName: 'UNITED STATES' },
  { placeId: 5708, cityName: 'GREENWOOD', countryName: 'UNITED STATES' },
  { placeId: 1292, cityName: 'GREER', countryName: 'UNITED STATES' },
  { placeId: 5710, cityName: 'GRENADA', countryName: 'UNITED STATES' },
  { placeId: 2839, cityName: 'GREYBULL', countryName: 'UNITED STATES' },
  { placeId: 5714, cityName: 'GRIFFIN', countryName: 'UNITED STATES' },
  { placeId: 5718, cityName: 'GRINNELL', countryName: 'UNITED STATES' },
  { placeId: 11008, cityName: 'GROTON/NEW LONDON', countryName: 'UNITED STATES' },
  { placeId: 3020, cityName: 'GROVE', countryName: 'UNITED STATES' },
  { placeId: 5722, cityName: 'GRUNDY', countryName: 'UNITED STATES' },
  { placeId: 11023, cityName: 'GULF SHORES', countryName: 'UNITED STATES' },
  { placeId: 11024, cityName: 'GULFPORT', countryName: 'UNITED STATES' },
  { placeId: 5739, cityName: 'GULKANA', countryName: 'UNITED STATES' },
  {
    placeId: 5745, cityName: 'GUNNISON', state: 'CO', countryName: 'UNITED STATES',
  },
  { placeId: 11031, cityName: 'GUSTAVUS', countryName: 'UNITED STATES' },
  { placeId: 5749, cityName: 'GUTHRIE CENTER', countryName: 'UNITED STATES' },
  { placeId: 5748, cityName: 'GUTHRIE', countryName: 'UNITED STATES' },
  { placeId: 3022, cityName: 'GUYMON', countryName: 'UNITED STATES' },
  { placeId: 5756, cityName: 'GWINNER', countryName: 'UNITED STATES' },
  { placeId: 5767, cityName: 'HAGERSTOWN', countryName: 'UNITED STATES' },
  { placeId: 5770, cityName: 'HAILEY', countryName: 'UNITED STATES' },
  { placeId: 11041, cityName: 'HAINES', countryName: 'UNITED STATES' },
  { placeId: 5774, cityName: 'HALF MOON BAY', countryName: 'UNITED STATES' },
  { placeId: 5777, cityName: 'HALLOCK', countryName: 'UNITED STATES' },
  { placeId: 5782, cityName: 'HAMILTON', countryName: 'UNITED STATES' },
  { placeId: 5785, cityName: 'HAMILTON', countryName: 'UNITED STATES' },
  { placeId: 5784, cityName: 'HAMILTON', countryName: 'UNITED STATES' },
  { placeId: 11054, cityName: 'HAMILTON', countryName: 'UNITED STATES' },
  { placeId: 11057, cityName: 'HAMMOND', countryName: 'UNITED STATES' },
  { placeId: 11059, cityName: 'HAMPTON', countryName: 'UNITED STATES' },
  { placeId: 5786, cityName: 'HAMPTON', countryName: 'UNITED STATES' },
  { placeId: 11060, cityName: 'HANA', countryName: 'UNITED STATES' },
  { placeId: 11061, cityName: 'HANALEI', countryName: 'UNITED STATES' },
  { placeId: 11062, cityName: 'HANAPEPE', countryName: 'UNITED STATES' },
  { placeId: 11063, cityName: 'HANCOCK', countryName: 'UNITED STATES' },
  { placeId: 5788, cityName: 'HANCOCK', countryName: 'UNITED STATES' },
  { placeId: 5789, cityName: 'HANFORD', countryName: 'UNITED STATES' },
  { placeId: 5791, cityName: 'HANKSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5795, cityName: 'HANNIBAL', countryName: 'UNITED STATES' },
  { placeId: 5798, cityName: 'HARBOR SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 5802, cityName: 'HARLAN', countryName: 'UNITED STATES' },
  { placeId: 1298, cityName: 'HARLINGEN', countryName: 'UNITED STATES' },
  { placeId: 5803, cityName: 'HARLOWTON', countryName: 'UNITED STATES' },
  { placeId: 1325, cityName: 'HARRISBURG', countryName: 'UNITED STATES' },
  { placeId: 5806, cityName: 'HARRISBURG', countryName: 'UNITED STATES' },
  { placeId: 5808, cityName: 'HARRISON', countryName: 'UNITED STATES' },
  { placeId: 5809, cityName: 'HARRISONVILLE', countryName: 'UNITED STATES' },
  { placeId: 11084, cityName: 'HARROLD', countryName: 'UNITED STATES' },
  { placeId: 2827, cityName: 'HARTFORD', countryName: 'UNITED STATES' },
  { placeId: 2826, cityName: 'HARTFORD', countryName: 'UNITED STATES' },
  { placeId: 5811, cityName: 'HARTSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5614, cityName: 'HASTINGS', countryName: 'UNITED STATES' },
  { placeId: 11091, cityName: 'HATTERAS', countryName: 'UNITED STATES' },
  { placeId: 5621, cityName: 'HATTIESBURG-LAUREL', countryName: 'UNITED STATES' },
  { placeId: 5620, cityName: 'HATTIESBURG', countryName: 'UNITED STATES' },
  { placeId: 1301, cityName: 'HAVRE', countryName: 'UNITED STATES' },
  { placeId: 11098, cityName: 'HAWI', countryName: 'UNITED STATES' },
  { placeId: 5812, cityName: 'HAWTHORNE', countryName: 'UNITED STATES' },
  { placeId: 11101, cityName: 'HAWTHORNE', countryName: 'UNITED STATES' },
  {
    placeId: 5813, cityName: 'HAYDEN', state: 'CO', countryName: 'UNITED STATES',
  },
  { placeId: 5814, cityName: 'HAYS', countryName: 'UNITED STATES' },
  { placeId: 5815, cityName: 'HAYWARD', countryName: 'UNITED STATES' },
  { placeId: 5816, cityName: 'HAYWARD', countryName: 'UNITED STATES' },
  { placeId: 11107, cityName: 'HAZARD', countryName: 'UNITED STATES' },
  { placeId: 5817, cityName: 'HAZEN', countryName: 'UNITED STATES' },
  { placeId: 5818, cityName: 'HAZLEHURST', countryName: 'UNITED STATES' },
  { placeId: 5819, cityName: 'HAZLETON', countryName: 'UNITED STATES' },
  { placeId: 11111, cityName: 'HEALDSBURG', countryName: 'UNITED STATES' },
  { placeId: 5820, cityName: 'HEARNE', countryName: 'UNITED STATES' },
  { placeId: 5822, cityName: 'HEBBRONVILLE', countryName: 'UNITED STATES' },
  { placeId: 5823, cityName: 'HEBER SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 5824, cityName: 'HEBRON', countryName: 'UNITED STATES' },
  { placeId: 3026, cityName: 'HELENA-WEST HELENA', countryName: 'UNITED STATES' },
  { placeId: 1294, cityName: 'HELENA', countryName: 'UNITED STATES' },
  { placeId: 5827, cityName: 'HEMET', countryName: 'UNITED STATES' },
  { placeId: 11124, cityName: 'HENDERSON', countryName: 'UNITED STATES' },
  { placeId: 2991, cityName: 'HENDERSON', countryName: 'UNITED STATES' },
  { placeId: 5831, cityName: 'HEREFORD', countryName: 'UNITED STATES' },
  {
    placeId: 5832, cityName: 'HERENDEEN BAY', state: 'AK', countryName: 'UNITED STATES',
  },
  { placeId: 5833, cityName: 'HERINGTON', countryName: 'UNITED STATES' },
  { placeId: 8251, cityName: 'HERLONG', countryName: 'UNITED STATES' },
  { placeId: 3033, cityName: 'HERMISTON', countryName: 'UNITED STATES' },
  { placeId: 11131, cityName: 'HERTFORD', countryName: 'UNITED STATES' },
  { placeId: 3027, cityName: 'HETTINGER', countryName: 'UNITED STATES' },
  { placeId: 5837, cityName: 'HIBBING', countryName: 'UNITED STATES' },
  { placeId: 5838, cityName: 'HICKORY', countryName: 'UNITED STATES' },
  { placeId: 5839, cityName: 'HIGGINSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5842, cityName: 'HIGHGATE', countryName: 'UNITED STATES' },
  { placeId: 5846, cityName: 'HILL CITY', countryName: 'UNITED STATES' },
  { placeId: 5848, cityName: 'HILLSBORO', countryName: 'UNITED STATES' },
  { placeId: 5847, cityName: 'HILLSBORO', countryName: 'UNITED STATES' },
  { placeId: 5849, cityName: 'HILLSBORO', countryName: 'UNITED STATES' },
  { placeId: 3053, cityName: 'HILLSDALE', countryName: 'UNITED STATES' },
  { placeId: 2408, cityName: 'HILO', countryName: 'UNITED STATES' },
  { placeId: 11150, cityName: 'HILTON HEAD I', countryName: 'UNITED STATES' },
  { placeId: 5856, cityName: 'HOBART', countryName: 'UNITED STATES' },
  { placeId: 5857, cityName: 'HOBBS', countryName: 'UNITED STATES' },
  { placeId: 5862, cityName: 'HOLDREGE', countryName: 'UNITED STATES' },
  { placeId: 5863, cityName: 'HOLLAND', countryName: 'UNITED STATES' },
  { placeId: 5865, cityName: 'HOLLISTER', countryName: 'UNITED STATES' },
  { placeId: 5866, cityName: 'HOLLYWOOD', countryName: 'UNITED STATES' },
  { placeId: 5868, cityName: 'HOLY CROSS', countryName: 'UNITED STATES' },
  { placeId: 5869, cityName: 'HOLYOKE', countryName: 'UNITED STATES' },
  { placeId: 5871, cityName: 'HOMER', countryName: 'UNITED STATES' },
  { placeId: 5872, cityName: 'HOMERVILLE', countryName: 'UNITED STATES' },
  { placeId: 11166, cityName: 'HOMESTEAD', countryName: 'UNITED STATES' },
  { placeId: 5874, cityName: 'HOMEY', countryName: 'UNITED STATES' },
  { placeId: 5877, cityName: 'HONDO', countryName: 'UNITED STATES' },
  {
    placeId: 2406, cityName: 'HONOLULU', state: 'HI', countryName: 'UNITED STATES',
  },
  { placeId: 5878, cityName: 'HOONAH', countryName: 'UNITED STATES' },
  { placeId: 11174, cityName: 'HOOPER BAY', countryName: 'UNITED STATES' },
  { placeId: 3037, cityName: 'HOPKINSVILLE', countryName: 'UNITED STATES' },
  { placeId: 1297, cityName: 'HOQUIAM', countryName: 'UNITED STATES' },
  { placeId: 11184, cityName: 'HORNELL', countryName: 'UNITED STATES' },
  { placeId: 11187, cityName: 'HORSESHOE BAY', countryName: 'UNITED STATES' },
  { placeId: 3034, cityName: 'HOT SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 5883, cityName: 'HOT SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 5884, cityName: 'HOT SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 5887, cityName: 'HOUGHTON LAKE', countryName: 'UNITED STATES' },
  { placeId: 11194, cityName: 'HOULTON', countryName: 'UNITED STATES' },
  { placeId: 5888, cityName: 'HOUMA', countryName: 'UNITED STATES' },
  {
    placeId: 1295, cityName: 'HOUSTON', state: 'TX', countryName: 'UNITED STATES',
  },
  { placeId: 5891, cityName: 'HOWELL', countryName: 'UNITED STATES' },
  { placeId: 11208, cityName: 'HUGHES', countryName: 'UNITED STATES' },
  { placeId: 5903, cityName: 'HUGO', countryName: 'UNITED STATES' },
  { placeId: 5904, cityName: 'HUGOTON', countryName: 'UNITED STATES' },
  { placeId: 3031, cityName: 'HUNTINGBURG', countryName: 'UNITED STATES' },
  { placeId: 5906, cityName: 'HUNTINGDON', countryName: 'UNITED STATES' },
  { placeId: 5907, cityName: 'HUNTINGTON', countryName: 'UNITED STATES' },
  { placeId: 1300, cityName: 'HUNTINGTON', countryName: 'UNITED STATES' },
  { placeId: 1299, cityName: 'HUNTSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5909, cityName: 'HUNTSVILLE', countryName: 'UNITED STATES' },
  { placeId: 5910, cityName: 'HURON', countryName: 'UNITED STATES' },
  { placeId: 11221, cityName: 'HURTSBORO', countryName: 'UNITED STATES' },
  { placeId: 5912, cityName: 'HUSLIA', countryName: 'UNITED STATES' },
  { placeId: 5914, cityName: 'HUTCHINSON', countryName: 'UNITED STATES' },
  { placeId: 5915, cityName: 'HUTCHINSON', countryName: 'UNITED STATES' },
  {
    placeId: 11225, cityName: 'HYANNIS', state: 'MASSACHUSETTS', countryName: 'UNITED STATES',
  },
  { placeId: 5925, cityName: 'IDA GROVE', countryName: 'UNITED STATES' },
  { placeId: 5926, cityName: 'IDAHO FALLS', countryName: 'UNITED STATES' },
  { placeId: 5933, cityName: 'IGIUGIG', countryName: 'UNITED STATES' },
  { placeId: 5947, cityName: 'ILIAMNA', countryName: 'UNITED STATES' },
  { placeId: 5954, cityName: 'IMMOKALEE', countryName: 'UNITED STATES' },
  { placeId: 11249, cityName: 'IMPERIAL BEACH', countryName: 'UNITED STATES' },
  { placeId: 3043, cityName: 'IMPERIAL', countryName: 'UNITED STATES' },
  { placeId: 11247, cityName: 'IMPERIAL', countryName: 'UNITED STATES' },
  { placeId: 5962, cityName: 'INDEPENDENCE', countryName: 'UNITED STATES' },
  { placeId: 5961, cityName: 'INDEPENDENCE', countryName: 'UNITED STATES' },
  { placeId: 5963, cityName: 'INDIAN SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 5964, cityName: 'INDIANA', countryName: 'UNITED STATES' },
  {
    placeId: 1307, cityName: 'INDIANAPOLIS', state: 'IN', countryName: 'UNITED STATES',
  },
  { placeId: 5965, cityName: 'INDIANOLA', countryName: 'UNITED STATES' },
  { placeId: 11258, cityName: 'INGLESIDE', countryName: 'UNITED STATES' },
  {
    placeId: 1308, cityName: 'INTERNATIONAL FALLS', state: 'MN', countryName: 'UNITED STATES',
  },
  { placeId: 11265, cityName: 'INVERNESS', countryName: 'UNITED STATES' },
  { placeId: 5979, cityName: 'INYOKERN', countryName: 'UNITED STATES' },
  { placeId: 5981, cityName: 'IOWA CITY', countryName: 'UNITED STATES' },
  { placeId: 5982, cityName: 'IOWA FALLS', countryName: 'UNITED STATES' },
  { placeId: 3045, cityName: 'IRON MT-KINGSFORD', countryName: 'UNITED STATES' },
  { placeId: 3048, cityName: 'IRONWOOD', countryName: 'UNITED STATES' },
  {
    placeId: 3047, cityName: 'ISLIP', state: 'NY', countryName: 'UNITED STATES',
  },
  {
    placeId: 6012, cityName: 'ITHACA', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 3050, cityName: 'JACKSBORO', countryName: 'UNITED STATES' },
  { placeId: 6024, cityName: 'JACKSON', countryName: 'UNITED STATES' },
  { placeId: 6028, cityName: 'JACKSON', countryName: 'UNITED STATES' },
  { placeId: 11297, cityName: 'JACKSON', countryName: 'UNITED STATES' },
  { placeId: 3070, cityName: 'JACKSON', countryName: 'UNITED STATES' },
  { placeId: 6026, cityName: 'JACKSON', countryName: 'UNITED STATES' },
  { placeId: 6025, cityName: 'JACKSON', countryName: 'UNITED STATES' },
  {
    placeId: 6027, cityName: 'JACKSON', state: 'WY', countryName: 'UNITED STATES',
  },
  { placeId: 11298, cityName: 'JACKSONVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 1312, cityName: 'JACKSONVILLE', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 6029, cityName: 'JACKSONVILLE', countryName: 'UNITED STATES' },
  { placeId: 6030, cityName: 'JACKSONVILLE', countryName: 'UNITED STATES' },
  { placeId: 6031, cityName: 'JACKSONVILLE', countryName: 'UNITED STATES' },
  { placeId: 11300, cityName: 'JACKSONVILLE', countryName: 'UNITED STATES' },
  { placeId: 2940, cityName: 'JAFFREY', countryName: 'UNITED STATES' },
  { placeId: 6037, cityName: 'JAMESTOWN', countryName: 'UNITED STATES' },
  { placeId: 6038, cityName: 'JAMESTOWN', countryName: 'UNITED STATES' },
  { placeId: 3051, cityName: 'JANESVILLE', countryName: 'UNITED STATES' },
  { placeId: 6046, cityName: 'JASPER', countryName: 'UNITED STATES' },
  { placeId: 6048, cityName: 'JASPER', countryName: 'UNITED STATES' },
  { placeId: 2945, cityName: 'JASPER', countryName: 'UNITED STATES' },
  { placeId: 6047, cityName: 'JASPER', countryName: 'UNITED STATES' },
  { placeId: 6054, cityName: 'JEFFERSON CITY', countryName: 'UNITED STATES' },
  { placeId: 6053, cityName: 'JEFFERSON', countryName: 'UNITED STATES' },
  { placeId: 6052, cityName: 'JEFFERSON', countryName: 'UNITED STATES' },
  {
    placeId: 3052, cityName: 'JEFFERSONVILLE', state: 'IN', countryName: 'UNITED STATES',
  },
  { placeId: 6057, cityName: 'JEROME', countryName: 'UNITED STATES' },
  { placeId: 6059, cityName: 'JESUP', countryName: 'UNITED STATES' },
  { placeId: 3012, cityName: 'JOHN DAY', countryName: 'UNITED STATES' },
  { placeId: 6070, cityName: 'JOHNSTOWN', countryName: 'UNITED STATES' },
  { placeId: 6071, cityName: 'JOLIET', countryName: 'UNITED STATES' },
  { placeId: 6076, cityName: 'JONESBORO', countryName: 'UNITED STATES' },
  { placeId: 6077, cityName: 'JOPLIN', countryName: 'UNITED STATES' },
  { placeId: 6078, cityName: 'JORDAN', countryName: 'UNITED STATES' },
  { placeId: 6079, cityName: 'JORDAN', countryName: 'UNITED STATES' },
  { placeId: 11351, cityName: 'JOSEPH', countryName: 'UNITED STATES' },
  { placeId: 6085, cityName: 'JUNCTION', countryName: 'UNITED STATES' },
  { placeId: 6086, cityName: 'JUNEAU', countryName: 'UNITED STATES' },
  { placeId: 11355, cityName: 'JUNEAU', countryName: 'UNITED STATES' },
  {
    placeId: 2407, cityName: 'KAHULUI', state: 'HI', countryName: 'UNITED STATES',
  },
  {
    placeId: 2405, cityName: 'KAILUA-KONA', state: 'HI', countryName: 'UNITED STATES',
  },
  { placeId: 6108, cityName: 'KAISER/LAKE OZARK', countryName: 'UNITED STATES' },
  { placeId: 6113, cityName: 'KAKE', countryName: 'UNITED STATES' },
  { placeId: 1237, cityName: 'KALAMAZOO', countryName: 'UNITED STATES' },
  { placeId: 11367, cityName: 'KALAUPAPA', countryName: 'UNITED STATES' },
  {
    placeId: 1288, cityName: 'KALISPELL', state: 'MT', countryName: 'UNITED STATES',
  },
  { placeId: 6126, cityName: 'KALSKAG', countryName: 'UNITED STATES' },
  { placeId: 5625, cityName: 'KALTAG', countryName: 'UNITED STATES' },
  { placeId: 5636, cityName: 'KAMUELA', countryName: 'UNITED STATES' },
  { placeId: 5637, cityName: 'KANAB', countryName: 'UNITED STATES' },
  { placeId: 3042, cityName: 'KANKAKEE', countryName: 'UNITED STATES' },
  {
    placeId: 1323, cityName: 'KANSAS CITY', state: 'MO', countryName: 'UNITED STATES',
  },
  { placeId: 11386, cityName: 'KAPOLEI', countryName: 'UNITED STATES' },
  { placeId: 6140, cityName: 'KARLUK', countryName: 'UNITED STATES' },
  { placeId: 11403, cityName: 'KASIGLUK', countryName: 'UNITED STATES' },
  { placeId: 11410, cityName: 'KAUNAKAKAI', countryName: 'UNITED STATES' },
  { placeId: 6178, cityName: 'KEARNEY', countryName: 'UNITED STATES' },
  { placeId: 2986, cityName: 'KEENE', countryName: 'UNITED STATES' },
  { placeId: 6183, cityName: 'KEKAHA', countryName: 'UNITED STATES' },
  { placeId: 6186, cityName: 'KELSO', countryName: 'UNITED STATES' },
  { placeId: 6189, cityName: 'KEMMERER', countryName: 'UNITED STATES' },
  { placeId: 2395, cityName: 'KENAI', countryName: 'UNITED STATES' },
  { placeId: 6192, cityName: 'KENANSVILLE', countryName: 'UNITED STATES' },
  { placeId: 6200, cityName: 'KENNETT', countryName: 'UNITED STATES' },
  { placeId: 2995, cityName: 'KENOSHA', countryName: 'UNITED STATES' },
  { placeId: 2996, cityName: 'KEOKUK', countryName: 'UNITED STATES' },
  { placeId: 5045, cityName: 'KERRVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 2399, cityName: 'KETCHIKAN', state: 'AK', countryName: 'UNITED STATES',
  },
  { placeId: 6206, cityName: 'KEWANEE', countryName: 'UNITED STATES' },
  {
    placeId: 11441, cityName: 'KEY WEST', state: 'FLORIDA', countryName: 'UNITED STATES',
  },
  { placeId: 11453, cityName: 'KIANA', countryName: 'UNITED STATES' },
  { placeId: 6219, cityName: 'KIDRON', countryName: 'UNITED STATES' },
  { placeId: 11460, cityName: 'KILL DEVIL HILLS', countryName: 'UNITED STATES' },
  { placeId: 6233, cityName: 'KILLEEN', countryName: 'UNITED STATES' },
  { placeId: 3039, cityName: 'KIMBALL', countryName: 'UNITED STATES' },
  { placeId: 11469, cityName: 'KIMBERLY', countryName: 'UNITED STATES' },
  { placeId: 6243, cityName: 'KING CITY', countryName: 'UNITED STATES' },
  { placeId: 11475, cityName: 'KING COVE', countryName: 'UNITED STATES' },
  { placeId: 2398, cityName: 'KING SALMON', countryName: 'UNITED STATES' },
  { placeId: 6245, cityName: 'KINGMAN', countryName: 'UNITED STATES' },
  { placeId: 6247, cityName: 'KINGSTREE', countryName: 'UNITED STATES' },
  { placeId: 6248, cityName: 'KINGSVILLE', countryName: 'UNITED STATES' },
  { placeId: 1311, cityName: 'KINSTON', countryName: 'UNITED STATES' },
  { placeId: 11488, cityName: 'KIPNUK', countryName: 'UNITED STATES' },
  { placeId: 6258, cityName: 'KIRKSVILLE', countryName: 'UNITED STATES' },
  { placeId: 11500, cityName: 'KIVALINA', countryName: 'UNITED STATES' },
  { placeId: 3057, cityName: 'KLAMATH FALLS', countryName: 'UNITED STATES' },
  { placeId: 6279, cityName: 'KLAWOCK', countryName: 'UNITED STATES' },
  { placeId: 6287, cityName: 'KNOB NOSTER', countryName: 'UNITED STATES' },
  { placeId: 6288, cityName: 'KNOX', countryName: 'UNITED STATES' },
  { placeId: 6289, cityName: 'KNOXVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 1390, cityName: 'KNOXVILLE', state: 'IN', countryName: 'UNITED STATES',
  },
  { placeId: 6291, cityName: 'KOBUK', countryName: 'UNITED STATES' },
  { placeId: 6292, cityName: 'KODIAK', countryName: 'UNITED STATES' },
  { placeId: 11514, cityName: 'KOKHANOK', countryName: 'UNITED STATES' },
  { placeId: 6305, cityName: 'KOKOMO', countryName: 'UNITED STATES' },
  { placeId: 6309, cityName: 'KOLIGANEK', countryName: 'UNITED STATES' },
  { placeId: 11521, cityName: 'KONGIGANAK', countryName: 'UNITED STATES' },
  { placeId: 6322, cityName: 'KOSCIUSKO', countryName: 'UNITED STATES' },
  { placeId: 11527, cityName: 'KOTLIK', countryName: 'UNITED STATES' },
  { placeId: 11528, cityName: 'KOTZEBUE', countryName: 'UNITED STATES' },
  { placeId: 11530, cityName: 'KOYUK', countryName: 'UNITED STATES' },
  { placeId: 11531, cityName: 'KOYUKUK', countryName: 'UNITED STATES' },
  { placeId: 6340, cityName: 'KULIK LAKE', countryName: 'UNITED STATES' },
  { placeId: 11550, cityName: 'KWETHLUK', countryName: 'UNITED STATES' },
  { placeId: 11551, cityName: 'KWIGILLINGOK', countryName: 'UNITED STATES' },
  {
    placeId: 6364, cityName: 'LA CROSSE', state: 'WI', countryName: 'UNITED STATES',
  },
  { placeId: 3055, cityName: 'LA GRANDE', countryName: 'UNITED STATES' },
  { placeId: 6372, cityName: 'LA JUNTA', countryName: 'UNITED STATES' },
  { placeId: 6379, cityName: 'LA PORTE', countryName: 'UNITED STATES' },
  { placeId: 6385, cityName: 'LA VERNE', countryName: 'UNITED STATES' },
  { placeId: 6399, cityName: 'LACONIA', countryName: 'UNITED STATES' },
  { placeId: 6401, cityName: 'LADYSMITH', countryName: 'UNITED STATES' },
  { placeId: 6402, cityName: 'LAFAYETTE', countryName: 'UNITED STATES' },
  { placeId: 6403, cityName: 'LAFAYETTE', countryName: 'UNITED STATES' },
  { placeId: 6404, cityName: 'LAGO VISTA', countryName: 'UNITED STATES' },
  { placeId: 6405, cityName: 'LAGRANGE', countryName: 'UNITED STATES' },
  { placeId: 11591, cityName: 'LAHAINA', countryName: 'UNITED STATES' },
  { placeId: 1317, cityName: 'LAKE CHARLES', countryName: 'UNITED STATES' },
  { placeId: 6409, cityName: 'LAKE CITY', countryName: 'UNITED STATES' },
  { placeId: 3028, cityName: 'LAKE HAVASU CITY', countryName: 'UNITED STATES' },
  { placeId: 6411, cityName: 'LAKE PLACID', countryName: 'UNITED STATES' },
  { placeId: 6413, cityName: 'LAKEHURST', countryName: 'UNITED STATES' },
  { placeId: 6414, cityName: 'LAKELAND', countryName: 'UNITED STATES' },
  { placeId: 3056, cityName: 'LAKEVIEW', countryName: 'UNITED STATES' },
  { placeId: 6417, cityName: 'LAMAR', countryName: 'UNITED STATES' },
  { placeId: 6416, cityName: 'LAMAR', countryName: 'UNITED STATES' },
  { placeId: 2983, cityName: 'LAMBERTVILLE', countryName: 'UNITED STATES' },
  { placeId: 3061, cityName: 'LAMONI', countryName: 'UNITED STATES' },
  { placeId: 6422, cityName: 'LAMPASAS', countryName: 'UNITED STATES' },
  { placeId: 11609, cityName: 'LANAI CITY', countryName: 'UNITED STATES' },
  { placeId: 6426, cityName: 'LANCASTER', countryName: 'UNITED STATES' },
  { placeId: 6430, cityName: 'LANCASTER', countryName: 'UNITED STATES' },
  { placeId: 6427, cityName: 'LANCASTER', countryName: 'UNITED STATES' },
  { placeId: 6429, cityName: 'LANCASTER', countryName: 'UNITED STATES' },
  { placeId: 6428, cityName: 'LANCASTER', countryName: 'UNITED STATES' },
  { placeId: 6425, cityName: 'LANCASTER', countryName: 'UNITED STATES' },
  { placeId: 6431, cityName: 'LAND O\'LAKES', countryName: 'UNITED STATES' },
  { placeId: 6432, cityName: 'LANDER', countryName: 'UNITED STATES' },
  { placeId: 6437, cityName: 'LANSING', countryName: 'UNITED STATES' },
  { placeId: 6441, cityName: 'LARAMIE', countryName: 'UNITED STATES' },
  {
    placeId: 1320, cityName: 'LAREDO', state: 'TX', countryName: 'UNITED STATES',
  },
  { placeId: 6442, cityName: 'LARNED', countryName: 'UNITED STATES' },
  { placeId: 11627, cityName: 'LARSEN BAY', countryName: 'UNITED STATES' },
  { placeId: 6443, cityName: 'LAS CRUCES', countryName: 'UNITED STATES' },
  {
    placeId: 1314, cityName: 'LAS VEGAS', state: 'NV', countryName: 'UNITED STATES',
  },
  { placeId: 6445, cityName: 'LAS VEGAS', countryName: 'UNITED STATES' },
  { placeId: 6451, cityName: 'LATROBE', countryName: 'UNITED STATES' },
  { placeId: 6452, cityName: 'LAUREL', countryName: 'UNITED STATES' },
  { placeId: 11636, cityName: 'LAURENS', countryName: 'UNITED STATES' },
  { placeId: 6457, cityName: 'LAWRENCE', countryName: 'UNITED STATES' },
  { placeId: 6458, cityName: 'LAWRENCE', countryName: 'UNITED STATES' },
  { placeId: 6459, cityName: 'LAWRENCEVILLE', countryName: 'UNITED STATES' },
  { placeId: 3059, cityName: 'LAWRENCEVILLE', countryName: 'UNITED STATES' },
  { placeId: 3063, cityName: 'LAWRENCEVILLE', countryName: 'UNITED STATES' },
  { placeId: 6460, cityName: 'LAWTON', countryName: 'UNITED STATES' },
  { placeId: 6461, cityName: 'LE MARS', countryName: 'UNITED STATES' },
  { placeId: 6463, cityName: 'LEADVILLE', countryName: 'UNITED STATES' },
  { placeId: 6467, cityName: 'LEBANON', countryName: 'UNITED STATES' },
  { placeId: 6468, cityName: 'LEBANON', countryName: 'UNITED STATES' },
  { placeId: 6516, cityName: 'LEE\'S SUMMIT', countryName: 'UNITED STATES' },
  { placeId: 6519, cityName: 'LEESBURG', countryName: 'UNITED STATES' },
  { placeId: 6518, cityName: 'LEESBURG', countryName: 'UNITED STATES' },
  { placeId: 3054, cityName: 'LEMMON', countryName: 'UNITED STATES' },
  { placeId: 5648, cityName: 'LEMOORE', countryName: 'UNITED STATES' },
  { placeId: 6526, cityName: 'LENORA', countryName: 'UNITED STATES' },
  { placeId: 6536, cityName: 'LEVELLAND', countryName: 'UNITED STATES' },
  { placeId: 6537, cityName: 'LEWISBURG', countryName: 'UNITED STATES' },
  { placeId: 3060, cityName: 'LEWISBURG', countryName: 'UNITED STATES' },
  { placeId: 3062, cityName: 'LEWISTON', countryName: 'UNITED STATES' },
  { placeId: 6538, cityName: 'LEWISTOWN', countryName: 'UNITED STATES' },
  { placeId: 6542, cityName: 'LEXINGTON-PARSONS', countryName: 'UNITED STATES' },
  {
    placeId: 1318, cityName: 'LEXINGTON', state: 'KY', countryName: 'UNITED STATES',
  },
  { placeId: 6540, cityName: 'LEXINGTON', countryName: 'UNITED STATES' },
  { placeId: 6541, cityName: 'LEXINGTON', countryName: 'UNITED STATES' },
  { placeId: 6545, cityName: 'LIBERAL', countryName: 'UNITED STATES' },
  { placeId: 11689, cityName: 'LIHUE', countryName: 'UNITED STATES' },
  { placeId: 6558, cityName: 'LIMA', countryName: 'UNITED STATES' },
  { placeId: 6561, cityName: 'LIMON', countryName: 'UNITED STATES' },
  { placeId: 6564, cityName: 'LINCOLN', countryName: 'UNITED STATES' },
  { placeId: 6565, cityName: 'LINCOLN', countryName: 'UNITED STATES' },
  { placeId: 6566, cityName: 'LINCOLN', countryName: 'UNITED STATES' },
  {
    placeId: 6567, cityName: 'LINCOLN', state: 'IL', countryName: 'UNITED STATES',
  },
  { placeId: 6568, cityName: 'LINCOLNTON', countryName: 'UNITED STATES' },
  { placeId: 6570, cityName: 'LINDEN', countryName: 'UNITED STATES' },
  { placeId: 6578, cityName: 'LITCHFIELD', countryName: 'UNITED STATES' },
  { placeId: 6580, cityName: 'LITTLE FALLS', countryName: 'UNITED STATES' },
  { placeId: 6583, cityName: 'LITTLE RIVER', countryName: 'UNITED STATES' },
  { placeId: 1319, cityName: 'LITTLE ROCK', countryName: 'UNITED STATES' },
  { placeId: 6584, cityName: 'LITTLEFIELD', countryName: 'UNITED STATES' },
  { placeId: 6586, cityName: 'LIVERMORE', countryName: 'UNITED STATES' },
  { placeId: 6587, cityName: 'LIVINGSTON', countryName: 'UNITED STATES' },
  { placeId: 6591, cityName: 'LLANO', countryName: 'UNITED STATES' },
  { placeId: 6593, cityName: 'LOCK HAVEN', countryName: 'UNITED STATES' },
  { placeId: 11720, cityName: 'LOCUST GROVE', countryName: 'UNITED STATES' },
  { placeId: 6596, cityName: 'LOGAN', countryName: 'UNITED STATES' },
  { placeId: 6597, cityName: 'LOGANSPORT', countryName: 'UNITED STATES' },
  { placeId: 11724, cityName: 'LOMPOC', countryName: 'UNITED STATES' },
  { placeId: 6607, cityName: 'LONDON', countryName: 'UNITED STATES' },
  { placeId: 6606, cityName: 'LONDON', countryName: 'UNITED STATES' },
  { placeId: 6608, cityName: 'LONE ROCK', countryName: 'UNITED STATES' },
  {
    placeId: 2831, cityName: 'LONG BEACH', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 6611, cityName: 'LONGMONT', countryName: 'UNITED STATES' },
  { placeId: 6613, cityName: 'LONGVIEW', countryName: 'UNITED STATES' },
  { placeId: 6614, cityName: 'LONGVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 6618, cityName: 'LORAIN/ELYRIA', state: 'OH', countryName: 'UNITED STATES',
  },
  { placeId: 6619, cityName: 'LORDSBURG', countryName: 'UNITED STATES' },
  { placeId: 11740, cityName: 'LOS ALAMITOS', countryName: 'UNITED STATES' },
  { placeId: 6620, cityName: 'LOS ALAMOS', countryName: 'UNITED STATES' },
  {
    placeId: 1315, cityName: 'LOS ANGELES', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 6623, cityName: 'LOS BANOS', countryName: 'UNITED STATES' },
  { placeId: 11745, cityName: 'LOST RIVER', countryName: 'UNITED STATES' },
  { placeId: 6628, cityName: 'LOUISA', countryName: 'UNITED STATES' },
  { placeId: 6629, cityName: 'LOUISBURG', countryName: 'UNITED STATES' },
  { placeId: 6631, cityName: 'LOUISVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 1375, cityName: 'LOUISVILLE', state: 'KY', countryName: 'UNITED STATES',
  },
  { placeId: 6634, cityName: 'LOVELOCK', countryName: 'UNITED STATES' },
  { placeId: 1316, cityName: 'LUBBOCK', countryName: 'UNITED STATES' },
  { placeId: 6654, cityName: 'LUDINGTON', countryName: 'UNITED STATES' },
  { placeId: 6659, cityName: 'LUFKIN', countryName: 'UNITED STATES' },
  { placeId: 6670, cityName: 'LUMBERTON', countryName: 'UNITED STATES' },
  { placeId: 6677, cityName: 'LUSK', countryName: 'UNITED STATES' },
  { placeId: 3064, cityName: 'LUVERNE', countryName: 'UNITED STATES' },
  {
    placeId: 6682, cityName: 'LYNCHBURG', state: 'VA', countryName: 'UNITED STATES',
  },
  { placeId: 11770, cityName: 'LYNDONVILLE', countryName: 'UNITED STATES' },
  { placeId: 6683, cityName: 'LYONS', countryName: 'UNITED STATES' },
  { placeId: 11778, cityName: 'MACHIAS', countryName: 'UNITED STATES' },
  { placeId: 6478, cityName: 'MACKINAC ISLAND', countryName: 'UNITED STATES' },
  { placeId: 6691, cityName: 'MACOMB', countryName: 'UNITED STATES' },
  { placeId: 11784, cityName: 'MACOMB', countryName: 'UNITED STATES' },
  { placeId: 6692, cityName: 'MACON', countryName: 'UNITED STATES' },
  { placeId: 6694, cityName: 'MADERA', countryName: 'UNITED STATES' },
  { placeId: 3044, cityName: 'MADISON', countryName: 'UNITED STATES' },
  { placeId: 6697, cityName: 'MADISON', countryName: 'UNITED STATES' },
  { placeId: 6698, cityName: 'MADISON', countryName: 'UNITED STATES' },
  { placeId: 6695, cityName: 'MADISON', countryName: 'UNITED STATES' },
  { placeId: 6696, cityName: 'MADISON', countryName: 'UNITED STATES' },
  { placeId: 3075, cityName: 'MADISONVILLE', countryName: 'UNITED STATES' },
  { placeId: 6708, cityName: 'MAGNOLIA', countryName: 'UNITED STATES' },
  { placeId: 6729, cityName: 'MALAD CITY', countryName: 'UNITED STATES' },
  { placeId: 6738, cityName: 'MALDEN', countryName: 'UNITED STATES' },
  { placeId: 5658, cityName: 'MALONE', countryName: 'UNITED STATES' },
  { placeId: 6744, cityName: 'MAMMOTH LAKES', countryName: 'UNITED STATES' },
  { placeId: 11809, cityName: 'MANASSAS', countryName: 'UNITED STATES' },
  { placeId: 6751, cityName: 'MANASSAS', countryName: 'UNITED STATES' },
  {
    placeId: 3069, cityName: 'MANCHESTER', state: 'NH', countryName: 'UNITED STATES',
  },
  { placeId: 6760, cityName: 'MANHATTAN', countryName: 'UNITED STATES' },
  { placeId: 6764, cityName: 'MANILA', countryName: 'UNITED STATES' },
  { placeId: 6765, cityName: 'MANISTEE', countryName: 'UNITED STATES' },
  { placeId: 11821, cityName: 'MANISTIQUE', countryName: 'UNITED STATES' },
  { placeId: 11825, cityName: 'MANITOWOC', countryName: 'UNITED STATES' },
  { placeId: 6772, cityName: 'MANKATO', countryName: 'UNITED STATES' },
  { placeId: 6773, cityName: 'MANKATO', countryName: 'UNITED STATES' },
  { placeId: 6774, cityName: 'MANLEY HOT SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 6776, cityName: 'MANNING', countryName: 'UNITED STATES' },
  { placeId: 6777, cityName: 'MANOKOTAK', countryName: 'UNITED STATES' },
  { placeId: 6782, cityName: 'MANSFIELD', countryName: 'UNITED STATES' },
  { placeId: 11837, cityName: 'MANTEO', countryName: 'UNITED STATES' },
  { placeId: 6789, cityName: 'MAPLE LAKE', countryName: 'UNITED STATES' },
  { placeId: 6790, cityName: 'MAPLETON', countryName: 'UNITED STATES' },
  { placeId: 6792, cityName: 'MAQUOKETA', countryName: 'UNITED STATES' },
  { placeId: 6798, cityName: 'MARANA', countryName: 'UNITED STATES' },
  { placeId: 11846, cityName: 'MARATHON', countryName: 'UNITED STATES' },
  {
    placeId: 11848, cityName: 'MARCO I', state: 'FLORIDA', countryName: 'UNITED STATES',
  },
  { placeId: 6805, cityName: 'MARFA', countryName: 'UNITED STATES' },
  { placeId: 3065, cityName: 'MARIANNA', countryName: 'UNITED STATES' },
  { placeId: 6813, cityName: 'MARIETTA', countryName: 'UNITED STATES' },
  { placeId: 6814, cityName: 'MARINA', countryName: 'UNITED STATES' },
  { placeId: 6820, cityName: 'MARION-WYTHEVILLE', countryName: 'UNITED STATES' },
  { placeId: 6819, cityName: 'MARION', countryName: 'UNITED STATES' },
  { placeId: 6817, cityName: 'MARION', countryName: 'UNITED STATES' },
  { placeId: 6816, cityName: 'MARION', countryName: 'UNITED STATES' },
  { placeId: 6818, cityName: 'MARION', countryName: 'UNITED STATES' },
  { placeId: 6821, cityName: 'MARIPOSA', countryName: 'UNITED STATES' },
  { placeId: 6825, cityName: 'MARKS', countryName: 'UNITED STATES' },
  { placeId: 6826, cityName: 'MARKSVILLE', countryName: 'UNITED STATES' },
  { placeId: 1373, cityName: 'MARQUETTE', countryName: 'UNITED STATES' },
  { placeId: 6834, cityName: 'MARSHALL', countryName: 'UNITED STATES' },
  { placeId: 6837, cityName: 'MARSHALL', countryName: 'UNITED STATES' },
  { placeId: 6835, cityName: 'MARSHALL', countryName: 'UNITED STATES' },
  { placeId: 6833, cityName: 'MARSHALL', countryName: 'UNITED STATES' },
  { placeId: 6836, cityName: 'MARSHALL', countryName: 'UNITED STATES' },
  { placeId: 6838, cityName: 'MARSHALLTOWN', countryName: 'UNITED STATES' },
  { placeId: 11878, cityName: 'MARSHFIELD', countryName: 'UNITED STATES' },
  { placeId: 6839, cityName: 'MARSHFIELD', countryName: 'UNITED STATES' },
  { placeId: 6840, cityName: 'MARTINSBURG', countryName: 'UNITED STATES' },
  { placeId: 6841, cityName: 'MARTINSVILLE', countryName: 'UNITED STATES' },
  { placeId: 11881, cityName: 'MARY ESTHER', countryName: 'UNITED STATES' },
  { placeId: 6844, cityName: 'MARYSVILLE', countryName: 'UNITED STATES' },
  { placeId: 6843, cityName: 'MARYSVILLE', countryName: 'UNITED STATES' },
  { placeId: 6845, cityName: 'MARYSVILLE', countryName: 'UNITED STATES' },
  { placeId: 6846, cityName: 'MARYVILLE', countryName: 'UNITED STATES' },
  { placeId: 6855, cityName: 'MASON CITY', countryName: 'UNITED STATES' },
  { placeId: 6854, cityName: 'MASON', countryName: 'UNITED STATES' },
  { placeId: 1337, cityName: 'MASSENA', countryName: 'UNITED STATES' },
  { placeId: 6875, cityName: 'MATTOON-CHARLESTON', countryName: 'UNITED STATES' },
  { placeId: 6884, cityName: 'MAXTON', countryName: 'UNITED STATES' },
  { placeId: 11909, cityName: 'MAYPORT', countryName: 'UNITED STATES' },
  { placeId: 6898, cityName: 'MC ALESTER', countryName: 'UNITED STATES' },
  { placeId: 1328, cityName: 'MC ALLEN', countryName: 'UNITED STATES' },
  { placeId: 6899, cityName: 'MC CALL', countryName: 'UNITED STATES' },
  { placeId: 6900, cityName: 'MC COMB', countryName: 'UNITED STATES' },
  { placeId: 6901, cityName: 'MC COOK', countryName: 'UNITED STATES' },
  { placeId: 6902, cityName: 'MC GREGOR', countryName: 'UNITED STATES' },
  { placeId: 6903, cityName: 'MC MINNVILLE', countryName: 'UNITED STATES' },
  { placeId: 3073, cityName: 'MC MINNVILLE', countryName: 'UNITED STATES' },
  { placeId: 6904, cityName: 'MC PHERSON', countryName: 'UNITED STATES' },
  { placeId: 6906, cityName: 'MC RAE', countryName: 'UNITED STATES' },
  { placeId: 6910, cityName: 'MCGRATH', countryName: 'UNITED STATES' },
  { placeId: 6912, cityName: 'MCKINLEY PARK', countryName: 'UNITED STATES' },
  { placeId: 6915, cityName: 'MEADE', countryName: 'UNITED STATES' },
  { placeId: 6917, cityName: 'MEADVILLE', countryName: 'UNITED STATES' },
  { placeId: 3066, cityName: 'MEDFORD', countryName: 'UNITED STATES' },
  { placeId: 11934, cityName: 'MEDFORD', countryName: 'UNITED STATES' },
  { placeId: 6919, cityName: 'MEEKER', countryName: 'UNITED STATES' },
  { placeId: 6925, cityName: 'MEKORYUK', countryName: 'UNITED STATES' },
  { placeId: 11940, cityName: 'MELBOURNE', countryName: 'UNITED STATES' },
  { placeId: 11941, cityName: 'MELFA', countryName: 'UNITED STATES' },
  {
    placeId: 1327, cityName: 'MEMPHIS', state: 'TN', countryName: 'UNITED STATES',
  },
  { placeId: 6934, cityName: 'MENA', countryName: 'UNITED STATES' },
  { placeId: 3074, cityName: 'MENOMINEE', countryName: 'UNITED STATES' },
  { placeId: 6937, cityName: 'MENOMONIE', countryName: 'UNITED STATES' },
  { placeId: 6941, cityName: 'MERCED', countryName: 'UNITED STATES' },
  { placeId: 6945, cityName: 'MERIDEN', countryName: 'UNITED STATES' },
  { placeId: 6946, cityName: 'MERIDIAN', countryName: 'UNITED STATES' },
  { placeId: 6948, cityName: 'MERRILL', countryName: 'UNITED STATES' },
  { placeId: 11960, cityName: 'MERRITT ISLAND', countryName: 'UNITED STATES' },
  { placeId: 6953, cityName: 'MESA', countryName: 'UNITED STATES' },
  { placeId: 6954, cityName: 'MESQUITE', countryName: 'UNITED STATES' },
  { placeId: 6957, cityName: 'METTER', countryName: 'UNITED STATES' },
  { placeId: 6958, cityName: 'MEXIA', countryName: 'UNITED STATES' },
  { placeId: 6959, cityName: 'MEXICO', countryName: 'UNITED STATES' },
  { placeId: 6963, cityName: 'MIAMI', countryName: 'UNITED STATES' },
  {
    placeId: 11968, cityName: 'MIAMI', state: 'FLORIDA', countryName: 'UNITED STATES',
  },
  {
    placeId: 1330, cityName: 'MIAMI', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 3067, cityName: 'MICHIGAN CITY', countryName: 'UNITED STATES' },
  { placeId: 11971, cityName: 'MIDDLETON I', countryName: 'UNITED STATES' },
  { placeId: 6967, cityName: 'MIDDLETOWN', countryName: 'UNITED STATES' },
  { placeId: 11973, cityName: 'MIDDLETOWN', countryName: 'UNITED STATES' },
  { placeId: 1321, cityName: 'MIDLAND', countryName: 'UNITED STATES' },
  { placeId: 11974, cityName: 'MIDLAND', countryName: 'UNITED STATES' },
  { placeId: 6969, cityName: 'MIDLOTHIAN/WAXAHACHIE', countryName: 'UNITED STATES' },
  { placeId: 6972, cityName: 'MILES CITY', countryName: 'UNITED STATES' },
  { placeId: 6973, cityName: 'MILFORD', countryName: 'UNITED STATES' },
  { placeId: 6976, cityName: 'MILLEDGEVILLE', countryName: 'UNITED STATES' },
  { placeId: 6977, cityName: 'MILLER', countryName: 'UNITED STATES' },
  { placeId: 6978, cityName: 'MILLINGTON', countryName: 'UNITED STATES' },
  { placeId: 6979, cityName: 'MILLINOCKET', countryName: 'UNITED STATES' },
  { placeId: 6980, cityName: 'MILLVILLE', countryName: 'UNITED STATES' },
  { placeId: 11991, cityName: 'MILTON', countryName: 'UNITED STATES' },
  { placeId: 3080, cityName: 'MILTON', countryName: 'UNITED STATES' },
  {
    placeId: 1331, cityName: 'MILWAUKEE', state: 'WI', countryName: 'UNITED STATES',
  },
  { placeId: 6984, cityName: 'MINCHUMINA', countryName: 'UNITED STATES' },
  { placeId: 6985, cityName: 'MINDEN', countryName: 'UNITED STATES' },
  { placeId: 11996, cityName: 'MINDEN', countryName: 'UNITED STATES' },
  { placeId: 6986, cityName: 'MINEOLA/QUITMAN', countryName: 'UNITED STATES' },
  { placeId: 6987, cityName: 'MINERAL POINT', countryName: 'UNITED STATES' },
  { placeId: 6988, cityName: 'MINERAL WELLS', countryName: 'UNITED STATES' },
  {
    placeId: 1336, cityName: 'MINNEAPOLIS', state: 'MN', countryName: 'UNITED STATES',
  },
  { placeId: 6991, cityName: 'MINOCQUA-WOODRUFF', countryName: 'UNITED STATES' },
  { placeId: 1334, cityName: 'MINOT', countryName: 'UNITED STATES' },
  { placeId: 1335, cityName: 'MISSOULA', countryName: 'UNITED STATES' },
  { placeId: 6998, cityName: 'MITCHELL', countryName: 'UNITED STATES' },
  { placeId: 6485, cityName: 'MOAB', countryName: 'UNITED STATES' },
  { placeId: 6490, cityName: 'MOBERLY', countryName: 'UNITED STATES' },
  { placeId: 1333, cityName: 'MOBILE', countryName: 'UNITED STATES' },
  { placeId: 12018, cityName: 'MOBILE', countryName: 'UNITED STATES' },
  { placeId: 6492, cityName: 'MOBRIDGE', countryName: 'UNITED STATES' },
  { placeId: 6494, cityName: 'MODESTO', countryName: 'UNITED STATES' },
  { placeId: 7006, cityName: 'MOHALL', countryName: 'UNITED STATES' },
  { placeId: 7009, cityName: 'MOJAVE', countryName: 'UNITED STATES' },
  { placeId: 12023, cityName: 'MOKAPU', countryName: 'UNITED STATES' },
  { placeId: 1332, cityName: 'MOLINE', countryName: 'UNITED STATES' },
  { placeId: 12028, cityName: 'MONCKS CORNER', countryName: 'UNITED STATES' },
  { placeId: 12031, cityName: 'MONETT', countryName: 'UNITED STATES' },
  { placeId: 7032, cityName: 'MONONGAHELA', countryName: 'UNITED STATES' },
  { placeId: 7033, cityName: 'MONROE', countryName: 'UNITED STATES' },
  { placeId: 7034, cityName: 'MONROE', countryName: 'UNITED STATES' },
  { placeId: 7035, cityName: 'MONROE', countryName: 'UNITED STATES' },
  { placeId: 2988, cityName: 'MONROE', countryName: 'UNITED STATES' },
  { placeId: 7036, cityName: 'MONROEVILLE', countryName: 'UNITED STATES' },
  { placeId: 7037, cityName: 'MONTAGUE', countryName: 'UNITED STATES' },
  { placeId: 12042, cityName: 'MONTAUK', countryName: 'UNITED STATES' },
  { placeId: 7041, cityName: 'MONTE VISTA', countryName: 'UNITED STATES' },
  {
    placeId: 7045, cityName: 'MONTEREY', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 7047, cityName: 'MONTEVIDEO', countryName: 'UNITED STATES' },
  { placeId: 1329, cityName: 'MONTGOMERY', countryName: 'UNITED STATES' },
  { placeId: 7048, cityName: 'MONTGOMERY', countryName: 'UNITED STATES' },
  { placeId: 7051, cityName: 'MONTICELLO', countryName: 'UNITED STATES' },
  { placeId: 7050, cityName: 'MONTICELLO', countryName: 'UNITED STATES' },
  { placeId: 7049, cityName: 'MONTICELLO', countryName: 'UNITED STATES' },
  { placeId: 7052, cityName: 'MONTICELLO', countryName: 'UNITED STATES' },
  { placeId: 7053, cityName: 'MONTICELLO', countryName: 'UNITED STATES' },
  { placeId: 7055, cityName: 'MONTROSE', countryName: 'UNITED STATES' },
  { placeId: 7058, cityName: 'MOORELAND', countryName: 'UNITED STATES' },
  { placeId: 7059, cityName: 'MOORHEAD', countryName: 'UNITED STATES' },
  { placeId: 7062, cityName: 'MOOSE LAKE', countryName: 'UNITED STATES' },
  { placeId: 7066, cityName: 'MORA', countryName: 'UNITED STATES' },
  { placeId: 7069, cityName: 'MORGANTON', countryName: 'UNITED STATES' },
  { placeId: 7070, cityName: 'MORGANTOWN', countryName: 'UNITED STATES' },
  { placeId: 7077, cityName: 'MORRILTON', countryName: 'UNITED STATES' },
  { placeId: 7078, cityName: 'MORRIS', countryName: 'UNITED STATES' },
  {
    placeId: 3072, cityName: 'MORRISTOWN', state: 'NJ', countryName: 'UNITED STATES',
  },
  { placeId: 3076, cityName: 'MORRISTOWN', countryName: 'UNITED STATES' },
  { placeId: 7079, cityName: 'MORRISVILLE', countryName: 'UNITED STATES' },
  { placeId: 7080, cityName: 'MOSBY', countryName: 'UNITED STATES' },
  { placeId: 1339, cityName: 'MOSES LAKE', countryName: 'UNITED STATES' },
  { placeId: 7082, cityName: 'MOSINEE', countryName: 'UNITED STATES' },
  { placeId: 7090, cityName: 'MOULTRIE', countryName: 'UNITED STATES' },
  { placeId: 5660, cityName: 'MOUNDSVILLE', countryName: 'UNITED STATES' },
  { placeId: 12090, cityName: 'MOUNDVILLE', countryName: 'UNITED STATES' },
  { placeId: 6298, cityName: 'MOUNT PLEASANT', countryName: 'UNITED STATES' },
  { placeId: 7093, cityName: 'MOUNTAIN HOME', countryName: 'UNITED STATES' },
  { placeId: 7092, cityName: 'MOUNTAIN HOME', countryName: 'UNITED STATES' },
  { placeId: 7097, cityName: 'MOUNTAIN VIEW', countryName: 'UNITED STATES' },
  { placeId: 7096, cityName: 'MOUNTAIN VIEW', countryName: 'UNITED STATES' },
  { placeId: 7098, cityName: 'MOUNTAIN VILLAGE', countryName: 'UNITED STATES' },
  { placeId: 7106, cityName: 'MT AIRY', countryName: 'UNITED STATES' },
  { placeId: 2942, cityName: 'MT CARMEL', countryName: 'UNITED STATES' },
  { placeId: 12104, cityName: 'MT CLEMENS', countryName: 'UNITED STATES' },
  { placeId: 7107, cityName: 'MT HOLLY', countryName: 'UNITED STATES' },
  { placeId: 12109, cityName: 'MT PLEASANT', countryName: 'UNITED STATES' },
  { placeId: 7108, cityName: 'MT PLEASANT', countryName: 'UNITED STATES' },
  { placeId: 3077, cityName: 'MT PLEASANT', countryName: 'UNITED STATES' },
  { placeId: 7109, cityName: 'MT POCONO', countryName: 'UNITED STATES' },
  { placeId: 7110, cityName: 'MT STERLING', countryName: 'UNITED STATES' },
  { placeId: 7111, cityName: 'MT VERNON', countryName: 'UNITED STATES' },
  { placeId: 3068, cityName: 'MULLEN', countryName: 'UNITED STATES' },
  { placeId: 7132, cityName: 'MUNCIE', countryName: 'UNITED STATES' },
  { placeId: 7136, cityName: 'MURFREESBORO', countryName: 'UNITED STATES' },
  { placeId: 3899, cityName: 'MURRAY', countryName: 'UNITED STATES' },
  { placeId: 3079, cityName: 'MUSCATINE', countryName: 'UNITED STATES' },
  { placeId: 7137, cityName: 'MUSCLE SHOALS', countryName: 'UNITED STATES' },
  { placeId: 7141, cityName: 'MUSKEGON', countryName: 'UNITED STATES' },
  { placeId: 7142, cityName: 'MUSKOGEE', countryName: 'UNITED STATES' },
  { placeId: 12139, cityName: 'MYRTLE BEACH', countryName: 'UNITED STATES' },
  { placeId: 6499, cityName: 'NACOGDOCHES', countryName: 'UNITED STATES' },
  { placeId: 12151, cityName: 'NAMPA', countryName: 'UNITED STATES' },
  {
    placeId: 2936, cityName: 'NANTUCKET', state: 'MA', countryName: 'UNITED STATES',
  },
  {
    placeId: 7185, cityName: 'NAPA', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 12160, cityName: 'NAPAKIAK', countryName: 'UNITED STATES' },
  { placeId: 12161, cityName: 'NAPASKIAK', countryName: 'UNITED STATES' },
  { placeId: 7186, cityName: 'NAPERVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 7188, cityName: 'NAPLES', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 2947, cityName: 'NASHUA', countryName: 'UNITED STATES' },
  {
    placeId: 1246, cityName: 'NASHVILLE', state: 'TN', countryName: 'UNITED STATES',
  },
  { placeId: 7195, cityName: 'NATCHEZ', countryName: 'UNITED STATES' },
  { placeId: 7196, cityName: 'NATCHITOCHES', countryName: 'UNITED STATES' },
  { placeId: 2939, cityName: 'NEBRASKA CITY', countryName: 'UNITED STATES' },
  { placeId: 7207, cityName: 'NECEDAH', countryName: 'UNITED STATES' },
  { placeId: 7209, cityName: 'NEEDLES', countryName: 'UNITED STATES' },
  { placeId: 7214, cityName: 'NEILLSVILLE', countryName: 'UNITED STATES' },
  { placeId: 12182, cityName: 'NELSON LAGOON', countryName: 'UNITED STATES' },
  { placeId: 7224, cityName: 'NENANA', countryName: 'UNITED STATES' },
  { placeId: 2997, cityName: 'NEOSHO', countryName: 'UNITED STATES' },
  { placeId: 7228, cityName: 'NEVADA', countryName: 'UNITED STATES' },
  {
    placeId: 7229, cityName: 'NEW BEDFORD', state: 'MA', countryName: 'UNITED STATES',
  },
  { placeId: 7230, cityName: 'NEW BERLIN', countryName: 'UNITED STATES' },
  { placeId: 7231, cityName: 'NEW BERN', countryName: 'UNITED STATES' },
  { placeId: 7232, cityName: 'NEW BRAUNFELS', countryName: 'UNITED STATES' },
  { placeId: 7233, cityName: 'NEW CASTLE', countryName: 'UNITED STATES' },
  { placeId: 7234, cityName: 'NEW CASTLE', countryName: 'UNITED STATES' },
  {
    placeId: 9479, cityName: 'NEW HAVEN', state: 'CT', countryName: 'UNITED STATES',
  },
  { placeId: 6299, cityName: 'NEW IBERIA', countryName: 'UNITED STATES' },
  { placeId: 2992, cityName: 'NEW MADRID', countryName: 'UNITED STATES' },
  { placeId: 12197, cityName: 'NEW ORLEANS', countryName: 'UNITED STATES' },
  {
    placeId: 1338, cityName: 'NEW ORLEANS', state: 'LA', countryName: 'UNITED STATES',
  },
  { placeId: 6301, cityName: 'NEW PALTZ', countryName: 'UNITED STATES' },
  { placeId: 6302, cityName: 'NEW PHILADELPHIA', countryName: 'UNITED STATES' },
  { placeId: 6304, cityName: 'NEW PORT RICHEY', countryName: 'UNITED STATES' },
  { placeId: 7235, cityName: 'NEW RICHMOND', countryName: 'UNITED STATES' },
  { placeId: 7236, cityName: 'NEW ROADS', countryName: 'UNITED STATES' },
  { placeId: 12203, cityName: 'NEW SMYRNA BEACH', countryName: 'UNITED STATES' },
  { placeId: 7237, cityName: 'NEW STUYAHOK', countryName: 'UNITED STATES' },
  { placeId: 7239, cityName: 'NEW ULM', countryName: 'UNITED STATES' },
  {
    placeId: 1313, cityName: 'NEW YORK', state: 'NY', countryName: 'UNITED STATES',
  },
  {
    placeId: 1278, cityName: 'NEWARK', state: 'NJ', countryName: 'UNITED STATES',
  },
  {
    placeId: 7240, cityName: 'NEWARK', state: 'OH', countryName: 'UNITED STATES',
  },
  { placeId: 7241, cityName: 'NEWBERRY', countryName: 'UNITED STATES' },
  { placeId: 12210, cityName: 'NEWBERRY', countryName: 'UNITED STATES' },
  {
    placeId: 1383, cityName: 'NEWBURGH', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 7242, cityName: 'NEWCASTLE', countryName: 'UNITED STATES' },
  { placeId: 7245, cityName: 'NEWELLTON', countryName: 'UNITED STATES' },
  { placeId: 1354, cityName: 'NEWPORT NEWS', countryName: 'UNITED STATES' },
  { placeId: 3086, cityName: 'NEWPORT', countryName: 'UNITED STATES' },
  { placeId: 12215, cityName: 'NEWPORT', countryName: 'UNITED STATES' },
  { placeId: 7246, cityName: 'NEWPORT', countryName: 'UNITED STATES' },
  { placeId: 7247, cityName: 'NEWTOK', countryName: 'UNITED STATES' },
  { placeId: 7249, cityName: 'NEWTON', countryName: 'UNITED STATES' },
  { placeId: 7248, cityName: 'NEWTON', countryName: 'UNITED STATES' },
  { placeId: 1303, cityName: 'NIAGARA FALLS', countryName: 'UNITED STATES' },
  { placeId: 7259, cityName: 'NIKOLAI', countryName: 'UNITED STATES' },
  { placeId: 12228, cityName: 'NIKOLSKI', countryName: 'UNITED STATES' },
  { placeId: 7272, cityName: 'NOATAK', countryName: 'UNITED STATES' },
  { placeId: 1343, cityName: 'NOGALES', countryName: 'UNITED STATES' },
  { placeId: 12238, cityName: 'NOME', countryName: 'UNITED STATES' },
  { placeId: 12239, cityName: 'NONDALTON', countryName: 'UNITED STATES' },
  { placeId: 12240, cityName: 'NOORVIK', countryName: 'UNITED STATES' },
  {
    placeId: 1346, cityName: 'NORFOLK', state: 'VA', countryName: 'UNITED STATES',
  },
  { placeId: 7276, cityName: 'NORFOLK', countryName: 'UNITED STATES' },
  { placeId: 7277, cityName: 'NORMAN', countryName: 'UNITED STATES' },
  { placeId: 7278, cityName: 'NORRIDGEWOCK', countryName: 'UNITED STATES' },
  { placeId: 7281, cityName: 'NORTH ADAMS', countryName: 'UNITED STATES' },
  { placeId: 3090, cityName: 'NORTH BEND', countryName: 'UNITED STATES' },
  { placeId: 3173, cityName: 'NORTH EAST', countryName: 'UNITED STATES' },
  { placeId: 12255, cityName: 'NORTH KINGSTOWN', countryName: 'UNITED STATES' },
  { placeId: 7283, cityName: 'NORTH LITTLE ROCK', countryName: 'UNITED STATES' },
  { placeId: 12257, cityName: 'NORTH MYRTLE BEACH', countryName: 'UNITED STATES' },
  { placeId: 7284, cityName: 'NORTH PLATTE', countryName: 'UNITED STATES' },
  { placeId: 7288, cityName: 'NORTH VERNON', countryName: 'UNITED STATES' },
  { placeId: 7289, cityName: 'NORTH WILKESBORO', countryName: 'UNITED STATES' },
  { placeId: 7280, cityName: 'NORTH', countryName: 'UNITED STATES' },
  {
    placeId: 13929, cityName: 'NORTHAMPTON', state: 'MASSACHUSETTS', countryName: 'UNITED STATES',
  },
  { placeId: 2400, cityName: 'NORTHWAY', countryName: 'UNITED STATES' },
  { placeId: 7290, cityName: 'NORTON', countryName: 'UNITED STATES' },
  { placeId: 7291, cityName: 'NORWICH', countryName: 'UNITED STATES' },
  { placeId: 7313, cityName: 'NORWOOD', countryName: 'UNITED STATES' },
  { placeId: 12270, cityName: 'NOVATO', countryName: 'UNITED STATES' },
  { placeId: 6507, cityName: 'NUCLA', countryName: 'UNITED STATES' },
  { placeId: 7320, cityName: 'NUIQSUT', countryName: 'UNITED STATES' },
  { placeId: 7321, cityName: 'NULATO', countryName: 'UNITED STATES' },
  { placeId: 7332, cityName: 'O\'NEILL', countryName: 'UNITED STATES' },
  { placeId: 7333, cityName: 'OAHU', countryName: 'UNITED STATES' },
  { placeId: 12288, cityName: 'OAK HARBOR', countryName: 'UNITED STATES' },
  { placeId: 12289, cityName: 'OAK ISLAND', countryName: 'UNITED STATES' },
  { placeId: 7335, cityName: 'OAKDALE', countryName: 'UNITED STATES' },
  {
    placeId: 1340, cityName: 'OAKLAND', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 7336, cityName: 'OAKLEY', countryName: 'UNITED STATES' },
  { placeId: 7343, cityName: 'OBERLIN', countryName: 'UNITED STATES' },
  { placeId: 7352, cityName: 'OCALA', countryName: 'UNITED STATES' },
  { placeId: 7353, cityName: 'OCALA/BELLEVIEW', countryName: 'UNITED STATES' },
  { placeId: 12300, cityName: 'OCEAN CITY', countryName: 'UNITED STATES' },
  { placeId: 12301, cityName: 'OCEANA', countryName: 'UNITED STATES' },
  { placeId: 7355, cityName: 'OCEANSIDE', countryName: 'UNITED STATES' },
  { placeId: 7357, cityName: 'OCONTO', countryName: 'UNITED STATES' },
  {
    placeId: 7359, cityName: 'ODESSA', state: 'TX', countryName: 'UNITED STATES',
  },
  { placeId: 7362, cityName: 'OELWEIN', countryName: 'UNITED STATES' },
  { placeId: 3083, cityName: 'OGALLALA', countryName: 'UNITED STATES' },
  { placeId: 7363, cityName: 'OGDEN', countryName: 'UNITED STATES' },
  { placeId: 7364, cityName: 'OGDENSBURG', countryName: 'UNITED STATES' },
  { placeId: 7369, cityName: 'OKEECHOBEE', countryName: 'UNITED STATES' },
  {
    placeId: 1341, cityName: 'OKLAHOMA CITY', state: 'OK', countryName: 'UNITED STATES',
  },
  { placeId: 12315, cityName: 'OKLAHOMA CITY', countryName: 'UNITED STATES' },
  { placeId: 7294, cityName: 'OKMULGEE', countryName: 'UNITED STATES' },
  { placeId: 3049, cityName: 'OLATHE', countryName: 'UNITED STATES' },
  { placeId: 7376, cityName: 'OLD TOWN', countryName: 'UNITED STATES' },
  { placeId: 7379, cityName: 'OLEAN', countryName: 'UNITED STATES' },
  { placeId: 7380, cityName: 'OLIVE BRANCH', countryName: 'UNITED STATES' },
  { placeId: 7382, cityName: 'OLIVIA', countryName: 'UNITED STATES' },
  { placeId: 7384, cityName: 'OLNEY-NOBLE', countryName: 'UNITED STATES' },
  { placeId: 7383, cityName: 'OLNEY', countryName: 'UNITED STATES' },
  { placeId: 1342, cityName: 'OLYMPIA', countryName: 'UNITED STATES' },
  {
    placeId: 1344, cityName: 'OMAHA', state: 'NE', countryName: 'UNITED STATES',
  },
  { placeId: 7385, cityName: 'OMAK', countryName: 'UNITED STATES' },
  { placeId: 3125, cityName: 'ONEIDA', countryName: 'UNITED STATES' },
  { placeId: 3167, cityName: 'ONSTED', countryName: 'UNITED STATES' },
  { placeId: 7392, cityName: 'ONTARIO', countryName: 'UNITED STATES' },
  { placeId: 1345, cityName: 'ONTARIO', countryName: 'UNITED STATES' },
  { placeId: 7393, cityName: 'ONTONAGON', countryName: 'UNITED STATES' },
  { placeId: 7395, cityName: 'OPELOUSAS', countryName: 'UNITED STATES' },
  { placeId: 7403, cityName: 'ORANGE CITY', countryName: 'UNITED STATES' },
  { placeId: 7404, cityName: 'ORANGE GROVE', countryName: 'UNITED STATES' },
  { placeId: 7402, cityName: 'ORANGE', countryName: 'UNITED STATES' },
  { placeId: 3088, cityName: 'ORANGE', countryName: 'UNITED STATES' },
  { placeId: 7401, cityName: 'ORANGE', countryName: 'UNITED STATES' },
  { placeId: 7405, cityName: 'ORANGEBURG', countryName: 'UNITED STATES' },
  { placeId: 7410, cityName: 'ORD', countryName: 'UNITED STATES' },
  {
    placeId: 1324, cityName: 'ORLANDO', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 7421, cityName: 'ORMOND BEACH', countryName: 'UNITED STATES' },
  { placeId: 7424, cityName: 'OROVILLE', countryName: 'UNITED STATES' },
  { placeId: 7425, cityName: 'ORR', countryName: 'UNITED STATES' },
  { placeId: 7427, cityName: 'ORTONVILLE', countryName: 'UNITED STATES' },
  { placeId: 7431, cityName: 'OSCEOLA', countryName: 'UNITED STATES' },
  { placeId: 12368, cityName: 'OSCODA', countryName: 'UNITED STATES' },
  { placeId: 3084, cityName: 'OSHKOSH', countryName: 'UNITED STATES' },
  { placeId: 7433, cityName: 'OSHKOSH', countryName: 'UNITED STATES' },
  { placeId: 7435, cityName: 'OSKALOOSA', countryName: 'UNITED STATES' },
  { placeId: 7296, cityName: 'OTTAWA', countryName: 'UNITED STATES' },
  { placeId: 7297, cityName: 'OTTAWA', countryName: 'UNITED STATES' },
  { placeId: 7299, cityName: 'OTTUMWA', countryName: 'UNITED STATES' },
  { placeId: 7450, cityName: 'OWATONNA', countryName: 'UNITED STATES' },
  { placeId: 3091, cityName: 'OWENSBORO', countryName: 'UNITED STATES' },
  { placeId: 7453, cityName: 'OWOSSO', countryName: 'UNITED STATES' },
  { placeId: 7455, cityName: 'OXFORD-HENDERSON', countryName: 'UNITED STATES' },
  { placeId: 7457, cityName: 'OXFORD', countryName: 'UNITED STATES' },
  {
    placeId: 7458, cityName: 'OXFORD', state: 'CT', countryName: 'UNITED STATES',
  },
  { placeId: 7456, cityName: 'OXFORD', countryName: 'UNITED STATES' },
  { placeId: 7460, cityName: 'OXNARD', countryName: 'UNITED STATES' },
  { placeId: 7466, cityName: 'OZONA', countryName: 'UNITED STATES' },
  { placeId: 3095, cityName: 'PACIFIC CITY', countryName: 'UNITED STATES' },
  { placeId: 3092, cityName: 'PADUCAH', countryName: 'UNITED STATES' },
  {
    placeId: 1352, cityName: 'PAGE', state: 'AZ', countryName: 'UNITED STATES',
  },
  { placeId: 7469, cityName: 'PAGELAND', countryName: 'UNITED STATES' },
  { placeId: 7470, cityName: 'PAGOSA SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 7471, cityName: 'PAHOKEE', countryName: 'UNITED STATES' },
  { placeId: 7472, cityName: 'PAINESVILLE', countryName: 'UNITED STATES' },
  { placeId: 12411, cityName: 'PALACIOS', countryName: 'UNITED STATES' },
  { placeId: 6513, cityName: 'PALESTINE', countryName: 'UNITED STATES' },
  { placeId: 4635, cityName: 'PALM COAST', countryName: 'UNITED STATES' },
  {
    placeId: 6514, cityName: 'PALM SPRINGS', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 1359, cityName: 'PALMDALE', countryName: 'UNITED STATES' },
  { placeId: 7481, cityName: 'PALMER', countryName: 'UNITED STATES' },
  { placeId: 7537, cityName: 'PALO ALTO', countryName: 'UNITED STATES' },
  { placeId: 7542, cityName: 'PAMPA', state: 'TX',countryName: 'UNITED STATES' },
  { placeId: 1351, cityName: 'PANAMA CITY', state: 'FL',countryName: 'UNITED STATES' },
  { placeId: 7552, cityName: 'PARADISE', countryName: 'UNITED STATES' },
  { placeId: 3096, cityName: 'PARAGOULD', countryName: 'UNITED STATES' },
  { placeId: 7558, cityName: 'PARIS', state: 'TX',countryName: 'UNITED STATES' },
  { placeId: 3104, cityName: 'PARIS', state: 'IL',countryName: 'UNITED STATES' },
  { placeId: 7559, cityName: 'PARIS', state: 'TN',countryName: 'UNITED STATES' },
  { placeId: 7560, cityName: 'PARK FALLS', countryName: 'UNITED STATES' },
  { placeId: 7561, cityName: 'PARK RAPIDS', countryName: 'UNITED STATES' },
  { placeId: 7562, cityName: 'PARKERSBURG', countryName: 'UNITED STATES' },
  { placeId: 7566, cityName: 'PARSONS', countryName: 'UNITED STATES' },
  { placeId: 12452, cityName: 'PASCAGOULA', countryName: 'UNITED STATES' },
  { placeId: 7568, cityName: 'PASCO', countryName: 'UNITED STATES' },
  { placeId: 7571, cityName: 'PASO ROBLES', countryName: 'UNITED STATES' },
  { placeId: 7577, cityName: 'PATTERSON', countryName: 'UNITED STATES' },
  { placeId: 12457, cityName: 'PATUXENT RIVER', countryName: 'UNITED STATES' },
  { placeId: 7582, cityName: 'PAULS VALLEY', countryName: 'UNITED STATES' },
  { placeId: 7584, cityName: 'PAWTUCKET', countryName: 'UNITED STATES' },
  { placeId: 7585, cityName: 'PAXSON', countryName: 'UNITED STATES' },
  { placeId: 7586, cityName: 'PAYNESVILLE', countryName: 'UNITED STATES' },
  { placeId: 7588, cityName: 'PAYSON', countryName: 'UNITED STATES' },
  { placeId: 7591, cityName: 'PECOS', countryName: 'UNITED STATES' },
  { placeId: 7599, cityName: 'PELL CITY', countryName: 'UNITED STATES' },
  { placeId: 7600, cityName: 'PELLA', countryName: 'UNITED STATES' },
  { placeId: 7601, cityName: 'PELLSTON', countryName: 'UNITED STATES' },
  { placeId: 3100, cityName: 'PEMBINA', countryName: 'UNITED STATES' },
  { placeId: 3744, cityName: 'PENCE SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 3094, cityName: 'PENDLETON', countryName: 'UNITED STATES' },
  { placeId: 7608, cityName: 'PENN YAN', countryName: 'UNITED STATES' },
  { placeId: 12486, cityName: 'PENSACOLA', countryName: 'UNITED STATES' },
  { placeId: 1357, cityName: 'PEORIA', countryName: 'UNITED STATES' },
  { placeId: 12490, cityName: 'PERKASIE', countryName: 'UNITED STATES' },
  { placeId: 7613, cityName: 'PERRY', countryName: 'UNITED STATES' },
  { placeId: 7614, cityName: 'PERRY', countryName: 'UNITED STATES' },
  { placeId: 7615, cityName: 'PERRYTON', countryName: 'UNITED STATES' },
  { placeId: 12494, cityName: 'PERRYVILLE', countryName: 'UNITED STATES' },
  { placeId: 7307, cityName: 'PERU', countryName: 'UNITED STATES' },
  { placeId: 7308, cityName: 'PERU', countryName: 'UNITED STATES' },
  { placeId: 7619, cityName: 'PETERSBURG', countryName: 'UNITED STATES' },
  { placeId: 12501, cityName: 'PETERSBURG', countryName: 'UNITED STATES' },
  {
    placeId: 1355, cityName: 'PHILADELPHIA', state: 'PA', countryName: 'UNITED STATES',
  },
  { placeId: 7624, cityName: 'PHILADELPHIA', countryName: 'UNITED STATES' },
  { placeId: 3097, cityName: 'PHILIP', countryName: 'UNITED STATES' },
  { placeId: 7625, cityName: 'PHILIPSBURG', countryName: 'UNITED STATES' },
  { placeId: 7626, cityName: 'PHILLIPS', countryName: 'UNITED STATES' },
  { placeId: 7627, cityName: 'PHILLIPSBURG', countryName: 'UNITED STATES' },
  {
    placeId: 1356, cityName: 'PHOENIX', state: 'AZ', countryName: 'UNITED STATES',
  },
  { placeId: 12512, cityName: 'PICABO', countryName: 'UNITED STATES' },
  { placeId: 7632, cityName: 'PICAYUNE', countryName: 'UNITED STATES' },
  { placeId: 7633, cityName: 'PICKENS', countryName: 'UNITED STATES' },
  { placeId: 7635, cityName: 'PIEDMONT', countryName: 'UNITED STATES' },
  { placeId: 7636, cityName: 'PIERRE', countryName: 'UNITED STATES' },
  { placeId: 7641, cityName: 'PIKEVILLE', countryName: 'UNITED STATES' },
  { placeId: 7645, cityName: 'PILOT POINT', countryName: 'UNITED STATES' },
  { placeId: 7648, cityName: 'PINCKNEYVILLE', countryName: 'UNITED STATES' },
  { placeId: 7650, cityName: 'PINE BLUFF', countryName: 'UNITED STATES' },
  { placeId: 7654, cityName: 'PINE MOUNTAIN', countryName: 'UNITED STATES' },
  { placeId: 3041, cityName: 'PINE RIDGE', countryName: 'UNITED STATES' },
  { placeId: 7655, cityName: 'PINE RIVER', countryName: 'UNITED STATES' },
  { placeId: 7656, cityName: 'PINEDALE', countryName: 'UNITED STATES' },
  { placeId: 7658, cityName: 'PINEHURST/SOUTHERN PINES', countryName: 'UNITED STATES' },
  { placeId: 7660, cityName: 'PIPESTONE', countryName: 'UNITED STATES' },
  {
    placeId: 3105, cityName: 'PITTSBURG', state: 'FL', countryName: 'UNITED STATES',
  },
  {
    placeId: 1229, cityName: 'PITTSBURGH', state: 'PA', countryName: 'UNITED STATES',
  },
  { placeId: 7664, cityName: 'PITTSFIELD', countryName: 'UNITED STATES' },
  { placeId: 7665, cityName: 'PITTSFIELD', countryName: 'UNITED STATES' },
  { placeId: 7668, cityName: 'PLACERVILLE', countryName: 'UNITED STATES' },
  { placeId: 7669, cityName: 'PLACIDA', countryName: 'UNITED STATES' },
  { placeId: 7670, cityName: 'PLAINVIEW', countryName: 'UNITED STATES' },
  { placeId: 7671, cityName: 'PLANT CITY', countryName: 'UNITED STATES' },
  { placeId: 7673, cityName: 'PLATINUM', countryName: 'UNITED STATES' },
  { placeId: 3106, cityName: 'PLATTEVILLE', countryName: 'UNITED STATES' },
  { placeId: 3093, cityName: 'PLATTSBURGH', countryName: 'UNITED STATES' },
  { placeId: 3101, cityName: 'PLATTSMOUTH', countryName: 'UNITED STATES' },
  { placeId: 9235, cityName: 'PLEASANT HARBOR', countryName: 'UNITED STATES' },
  { placeId: 7675, cityName: 'PLEASANTON', countryName: 'UNITED STATES' },
  { placeId: 7677, cityName: 'PLENTYWOOD', countryName: 'UNITED STATES' },
  { placeId: 12555, cityName: 'PLYMOUTH', countryName: 'UNITED STATES' },
  { placeId: 7678, cityName: 'PLYMOUTH', countryName: 'UNITED STATES' },
  { placeId: 7680, cityName: 'POCAHONTAS', countryName: 'UNITED STATES' },
  { placeId: 7681, cityName: 'POCATELLO', countryName: 'UNITED STATES' },
  { placeId: 7685, cityName: 'POHAKULOA', countryName: 'UNITED STATES' },
  { placeId: 12565, cityName: 'POINT HOPE', countryName: 'UNITED STATES' },
  { placeId: 7486, cityName: 'POINT LAY', countryName: 'UNITED STATES' },
  { placeId: 3099, cityName: 'POINT LOOKOUT', countryName: 'UNITED STATES' },
  { placeId: 7487, cityName: 'POINT MC INTYRE', countryName: 'UNITED STATES' },
  { placeId: 12568, cityName: 'POINT MUGU', countryName: 'UNITED STATES' },
  { placeId: 12574, cityName: 'POMPANO BEACH', countryName: 'UNITED STATES' },
  { placeId: 7492, cityName: 'PONCA CITY', countryName: 'UNITED STATES' },
  { placeId: 7498, cityName: 'PONTIAC', countryName: 'UNITED STATES' },
  {
    placeId: 7497, cityName: 'PONTIAC', state: 'MI', countryName: 'UNITED STATES',
  },
  { placeId: 3102, cityName: 'POPLAR BLUFF', countryName: 'UNITED STATES' },
  { placeId: 1259, cityName: 'PORT ANGELES', countryName: 'UNITED STATES' },
  { placeId: 12589, cityName: 'PORT ARANSAS', countryName: 'UNITED STATES' },
  { placeId: 12592, cityName: 'PORT CLARENCE', countryName: 'UNITED STATES' },
  { placeId: 7702, cityName: 'PORT CLINTON', countryName: 'UNITED STATES' },
  { placeId: 7705, cityName: 'PORT HEIDEN', countryName: 'UNITED STATES' },
  { placeId: 12601, cityName: 'PORT HURON', countryName: 'UNITED STATES' },
  { placeId: 12602, cityName: 'PORT ISABEL', countryName: 'UNITED STATES' },
  { placeId: 12604, cityName: 'PORT LAVACA', countryName: 'UNITED STATES' },
  { placeId: 12610, cityName: 'PORTAGE CREEK', countryName: 'UNITED STATES' },
  { placeId: 7711, cityName: 'PORTALES', countryName: 'UNITED STATES' },
  { placeId: 7712, cityName: 'PORTERVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 1350, cityName: 'PORTLAND', state: 'OR', countryName: 'UNITED STATES',
  },
  { placeId: 1363, cityName: 'PORTLAND', countryName: 'UNITED STATES' },
  { placeId: 3098, cityName: 'PORTLAND', countryName: 'UNITED STATES' },
  {
    placeId: 7741, cityName: 'PORTSMOUTH', state: 'OH', countryName: 'UNITED STATES',
  },
  { placeId: 12619, cityName: 'PORTSMOUTH', countryName: 'UNITED STATES' },
  { placeId: 12620, cityName: 'POSEYVILLE', countryName: 'UNITED STATES' },
  { placeId: 3117, cityName: 'POTEAU', countryName: 'UNITED STATES' },
  { placeId: 7747, cityName: 'POTSDAM', countryName: 'UNITED STATES' },
  { placeId: 7748, cityName: 'POTTSTOWN', countryName: 'UNITED STATES' },
  { placeId: 7749, cityName: 'POTTSVILLE', countryName: 'UNITED STATES' },
  { placeId: 3103, cityName: 'POUGHKEEPSIE', countryName: 'UNITED STATES' },
  { placeId: 7750, cityName: 'POWELL', countryName: 'UNITED STATES' },
  { placeId: 7755, cityName: 'PRAIRIE DU CHIEN', countryName: 'UNITED STATES' },
  { placeId: 7758, cityName: 'PRATT', countryName: 'UNITED STATES' },
  { placeId: 7761, cityName: 'PRESCOTT', countryName: 'UNITED STATES' },
  { placeId: 1360, cityName: 'PRESQUE ISLE', countryName: 'UNITED STATES' },
  { placeId: 3002, cityName: 'PRESTON', countryName: 'UNITED STATES' },
  { placeId: 7762, cityName: 'PRICE', countryName: 'UNITED STATES' },
  { placeId: 7766, cityName: 'PRINCETON', countryName: 'UNITED STATES' },
  { placeId: 7767, cityName: 'PRINCETON', countryName: 'UNITED STATES' },
  { placeId: 7771, cityName: 'PROSPECT CREEK', countryName: 'UNITED STATES' },
  {
    placeId: 1362, cityName: 'PROVIDENCE', state: 'RI', countryName: 'UNITED STATES',
  },
  { placeId: 12662, cityName: 'PROVINCETOWN', countryName: 'UNITED STATES' },
  {
    placeId: 7773, cityName: 'PROVO', state: 'UT', countryName: 'UNITED STATES',
  },
  { placeId: 1361, cityName: 'PUEBLO', countryName: 'UNITED STATES' },
  { placeId: 7799, cityName: 'PULASKI', countryName: 'UNITED STATES' },
  { placeId: 7801, cityName: 'PULLMAN/MOSCOW', countryName: 'UNITED STATES' },
  { placeId: 7719, cityName: 'PUNTA GORDA', countryName: 'UNITED STATES' },
  { placeId: 7809, cityName: 'PUYALLUP', countryName: 'UNITED STATES' },
  { placeId: 7821, cityName: 'QUAKERTOWN', countryName: 'UNITED STATES' },
  { placeId: 7823, cityName: 'QUANTICO', countryName: 'UNITED STATES' },
  { placeId: 7832, cityName: 'QUILLAYUTE', countryName: 'UNITED STATES' },
  { placeId: 7834, cityName: 'QUINCY', countryName: 'UNITED STATES' },
  { placeId: 7835, cityName: 'QUINHAGAK', countryName: 'UNITED STATES' },
  { placeId: 3108, cityName: 'RACINE', countryName: 'UNITED STATES' },
  {
    placeId: 1364, cityName: 'RALEIGH/DURHAM', state: 'NC', countryName: 'UNITED STATES',
  },
  { placeId: 7846, cityName: 'RAMONA', countryName: 'UNITED STATES' },
  { placeId: 7850, cityName: 'RANCHO MURIETA', countryName: 'UNITED STATES' },
  { placeId: 12729, cityName: 'RANGER', countryName: 'UNITED STATES' },
  { placeId: 7852, cityName: 'RANTOUL', countryName: 'UNITED STATES' },
  { placeId: 3109, cityName: 'RAPID CITY', countryName: 'UNITED STATES' },
  { placeId: 7866, cityName: 'RATON', countryName: 'UNITED STATES' },
  { placeId: 12740, cityName: 'RAVENNA', countryName: 'UNITED STATES' },
  { placeId: 7870, cityName: 'RAWLINS', countryName: 'UNITED STATES' },
  { placeId: 12742, cityName: 'RAYMOND', countryName: 'UNITED STATES' },
  {
    placeId: 7720, cityName: 'READING', state: 'PA', countryName: 'UNITED STATES',
  },
  { placeId: 7722, cityName: 'RED BLUFF', countryName: 'UNITED STATES' },
  { placeId: 7873, cityName: 'RED DOG', countryName: 'UNITED STATES' },
  { placeId: 7875, cityName: 'RED LODGE', countryName: 'UNITED STATES' },
  { placeId: 7876, cityName: 'RED OAK', countryName: 'UNITED STATES' },
  { placeId: 7878, cityName: 'RED WING', countryName: 'UNITED STATES' },
  { placeId: 7879, cityName: 'REDDING', countryName: 'UNITED STATES' },
  { placeId: 7880, cityName: 'REDLANDS', countryName: 'UNITED STATES' },
  { placeId: 3112, cityName: 'REDMOND', countryName: 'UNITED STATES' },
  { placeId: 7881, cityName: 'REDSTONE ARSENAL', countryName: 'UNITED STATES' },
  { placeId: 7882, cityName: 'REDWOOD FALLS', countryName: 'UNITED STATES' },
  { placeId: 7883, cityName: 'REED CITY', countryName: 'UNITED STATES' },
  { placeId: 7884, cityName: 'REEDSVILLE', countryName: 'UNITED STATES' },
  { placeId: 7885, cityName: 'REFUGIO', countryName: 'UNITED STATES' },
  { placeId: 7889, cityName: 'REIDSVILLE', countryName: 'UNITED STATES' },
  { placeId: 7890, cityName: 'REIDSVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 1366, cityName: 'RENO', state: 'NV', countryName: 'UNITED STATES',
  },
  { placeId: 12771, cityName: 'RENO', countryName: 'UNITED STATES' },
  { placeId: 7897, cityName: 'RENSSELAER', countryName: 'UNITED STATES' },
  { placeId: 7898, cityName: 'RENTON', countryName: 'UNITED STATES' },
  { placeId: 7903, cityName: 'REXBURG', countryName: 'UNITED STATES' },
  { placeId: 7906, cityName: 'RHINELANDER', countryName: 'UNITED STATES' },
  { placeId: 7908, cityName: 'RICE LAKE', countryName: 'UNITED STATES' },
  { placeId: 7910, cityName: 'RICHFIELD', countryName: 'UNITED STATES' },
  { placeId: 7911, cityName: 'RICHLAND', countryName: 'UNITED STATES' },
  { placeId: 12784, cityName: 'RICHLANDS', countryName: 'UNITED STATES' },
  { placeId: 3114, cityName: 'RICHMOND', countryName: 'UNITED STATES' },
  {
    placeId: 1365, cityName: 'RICHMOND', state: 'VA', countryName: 'UNITED STATES',
  },
  { placeId: 7912, cityName: 'RICHMOND/ASHLAND', countryName: 'UNITED STATES' },
  { placeId: 4833, cityName: 'RIDGECREST', countryName: 'UNITED STATES' },
  { placeId: 3115, cityName: 'RIDGELY', countryName: 'UNITED STATES' },
  {
    placeId: 2817, cityName: 'RIFLE', state: 'CO', countryName: 'UNITED STATES',
  },
  { placeId: 7725, cityName: 'RIVERSIDE', countryName: 'UNITED STATES' },
  { placeId: 7726, cityName: 'RIVERSIDE/RUBIDOUX', countryName: 'UNITED STATES' },
  { placeId: 7728, cityName: 'RIVERTON', countryName: 'UNITED STATES' },
  { placeId: 12808, cityName: 'ROANOKE RAPIDS', countryName: 'UNITED STATES' },
  { placeId: 7925, cityName: 'ROANOKE RAPIDS', countryName: 'UNITED STATES' },
  { placeId: 7924, cityName: 'ROANOKE', countryName: 'UNITED STATES' },
  { placeId: 3121, cityName: 'ROBINSON', countryName: 'UNITED STATES' },
  { placeId: 7931, cityName: 'ROBSTOWN', countryName: 'UNITED STATES' },
  { placeId: 12815, cityName: 'ROCHE HARBOR', countryName: 'UNITED STATES' },
  { placeId: 7932, cityName: 'ROCHELLE', countryName: 'UNITED STATES' },
  {
    placeId: 7935, cityName: 'ROCHESTER', state: 'MN', countryName: 'UNITED STATES',
  },
  { placeId: 12820, cityName: 'ROCHESTER', countryName: 'UNITED STATES' },
  { placeId: 7933, cityName: 'ROCHESTER', countryName: 'UNITED STATES' },
  {
    placeId: 1367, cityName: 'ROCHESTER', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 7936, cityName: 'ROCK HILL', countryName: 'UNITED STATES' },
  { placeId: 7937, cityName: 'ROCK RAPIDS', countryName: 'UNITED STATES' },
  { placeId: 7938, cityName: 'ROCK SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 7939, cityName: 'ROCKDALE', countryName: 'UNITED STATES' },
  { placeId: 3111, cityName: 'ROCKINGHAM', countryName: 'UNITED STATES' },
  {
    placeId: 3116, cityName: 'ROCKLAND', state: 'ME', countryName: 'UNITED STATES',
  },
  { placeId: 12830, cityName: 'ROCKPORT', countryName: 'UNITED STATES' },
  { placeId: 7941, cityName: 'ROCKSPRINGS', countryName: 'UNITED STATES' },
  { placeId: 3118, cityName: 'ROCKWOOD', countryName: 'UNITED STATES' },
  { placeId: 7943, cityName: 'ROCKY MOUNT', countryName: 'UNITED STATES' },
  { placeId: 7948, cityName: 'ROGERS CITY', countryName: 'UNITED STATES' },
  { placeId: 7947, cityName: 'ROGERS', countryName: 'UNITED STATES' },
  { placeId: 3122, cityName: 'ROGERSVILLE', countryName: 'UNITED STATES' },
  { placeId: 7954, cityName: 'ROLLA/VICHY', countryName: 'UNITED STATES' },
  { placeId: 7956, cityName: 'ROME', countryName: 'UNITED STATES' },
  { placeId: 7955, cityName: 'ROME', countryName: 'UNITED STATES' },
  { placeId: 3113, cityName: 'ROME', countryName: 'UNITED STATES' },
  { placeId: 7961, cityName: 'ROSEAU', countryName: 'UNITED STATES' },
  { placeId: 12850, cityName: 'ROSEBUD', countryName: 'UNITED STATES' },
  { placeId: 3110, cityName: 'ROSEBURG', countryName: 'UNITED STATES' },
  { placeId: 7966, cityName: 'ROSWELL', countryName: 'UNITED STATES' },
  { placeId: 12861, cityName: 'ROUND MOUNTAIN', countryName: 'UNITED STATES' },
  { placeId: 7970, cityName: 'ROUNDUP', countryName: 'UNITED STATES' },
  { placeId: 7972, cityName: 'ROXBORO', countryName: 'UNITED STATES' },
  { placeId: 7974, cityName: 'RUBY', countryName: 'UNITED STATES' },
  { placeId: 7978, cityName: 'RUGBY', countryName: 'UNITED STATES' },
  { placeId: 7981, cityName: 'RUIDOSO', countryName: 'UNITED STATES' },
  { placeId: 3119, cityName: 'RUSH CITY', countryName: 'UNITED STATES' },
  { placeId: 7988, cityName: 'RUSSELL', countryName: 'UNITED STATES' },
  { placeId: 7733, cityName: 'RUSSELLVILLE', countryName: 'UNITED STATES' },
  { placeId: 7735, cityName: 'RUSSIAN MISSION', countryName: 'UNITED STATES' },
  { placeId: 7737, cityName: 'RUSTON', countryName: 'UNITED STATES' },
  { placeId: 7738, cityName: 'RUTHERFORDTON', countryName: 'UNITED STATES' },
  {
    placeId: 7739, cityName: 'RUTLAND', state: 'VT', countryName: 'UNITED STATES',
  },
  { placeId: 7994, cityName: 'SAC CITY', countryName: 'UNITED STATES' },
  {
    placeId: 1369, cityName: 'SACRAMENTO', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 8000, cityName: 'SAFFORD', countryName: 'UNITED STATES' },
  {
    placeId: 1322, cityName: 'SAGINAW', state: 'MI', countryName: 'UNITED STATES',
  },
  { placeId: 3129, cityName: 'SALEM', countryName: 'UNITED STATES' },
  { placeId: 8005, cityName: 'SALEM', countryName: 'UNITED STATES' },
  { placeId: 8007, cityName: 'SALIDA', countryName: 'UNITED STATES' },
  { placeId: 8009, cityName: 'SALINA', countryName: 'UNITED STATES' },
  { placeId: 8011, cityName: 'SALINAS', countryName: 'UNITED STATES' },
  { placeId: 8013, cityName: 'SALISBURY', countryName: 'UNITED STATES' },
  { placeId: 8012, cityName: 'SALISBURY', countryName: 'UNITED STATES' },
  { placeId: 8014, cityName: 'SALLISAW', countryName: 'UNITED STATES' },
  { placeId: 8016, cityName: 'SALMON', countryName: 'UNITED STATES' },
  {
    placeId: 1380, cityName: 'SALT LAKE CITY', state: 'UT', countryName: 'UNITED STATES',
  },
  { placeId: 8019, cityName: 'SALTON CITY', countryName: 'UNITED STATES' },
  { placeId: 8027, cityName: 'SAN ANDREAS', countryName: 'UNITED STATES' },
  { placeId: 8028, cityName: 'SAN ANGELO', countryName: 'UNITED STATES' },
  {
    placeId: 1371, cityName: 'SAN ANTONIO', state: 'TX', countryName: 'UNITED STATES',
  },
  { placeId: 8030, cityName: 'SAN ARDO', countryName: 'UNITED STATES' },
  {
    placeId: 8031, cityName: 'SAN BERNARDINO', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 8035, cityName: 'SAN CARLOS', countryName: 'UNITED STATES' },
  { placeId: 12918, cityName: 'SAN CLEMENTE ISLAND', countryName: 'UNITED STATES' },
  {
    placeId: 12919, cityName: 'SAN DIEGO', state: 'CALIFORNIA', countryName: 'UNITED STATES',
  },
  {
    placeId: 1370, cityName: 'SAN DIEGO', state: 'CA', countryName: 'UNITED STATES',
  },
  {
    placeId: 1376, cityName: 'SAN FRANCISCO', state: 'CA', countryName: 'UNITED STATES',
  },
  {
    placeId: 1379, cityName: 'SAN JOSE', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 8054, cityName: 'SAN LUIS OBISPO', countryName: 'UNITED STATES' },
  { placeId: 8055, cityName: 'SAN MARCOS', countryName: 'UNITED STATES' },
  { placeId: 12930, cityName: 'SAN NICOLAS I', countryName: 'UNITED STATES' },
  { placeId: 12932, cityName: 'SAND POINT', countryName: 'UNITED STATES' },
  { placeId: 8072, cityName: 'SAND SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 8074, cityName: 'SANDERSVILLE', countryName: 'UNITED STATES' },
  { placeId: 3134, cityName: 'SANDPOINT', countryName: 'UNITED STATES' },
  { placeId: 8076, cityName: 'SANDUSKY', countryName: 'UNITED STATES' },
  { placeId: 12941, cityName: 'SANFORD', countryName: 'UNITED STATES' },
  { placeId: 8080, cityName: 'SANFORD', countryName: 'UNITED STATES' },
  {
    placeId: 8083, cityName: 'SANTA ANA', state: 'CA', countryName: 'UNITED STATES',
  },
  {
    placeId: 3123, cityName: 'SANTA BARBARA', state: 'CA', countryName: 'UNITED STATES',
  },
  {
    placeId: 8095, cityName: 'SANTA FE', state: 'NM', countryName: 'UNITED STATES',
  },
  { placeId: 8097, cityName: 'SANTA MARIA', countryName: 'UNITED STATES' },
  {
    placeId: 2818, cityName: 'SANTA MONICA', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 8100, cityName: 'SANTA PAULA', countryName: 'UNITED STATES' },
  { placeId: 8101, cityName: 'SANTA ROSA', countryName: 'UNITED STATES' },
  { placeId: 12953, cityName: 'SANTA ROSA', countryName: 'UNITED STATES' },
  { placeId: 12955, cityName: 'SANTA YNEZ', countryName: 'UNITED STATES' },
  { placeId: 8114, cityName: 'SARANAC LAKE', countryName: 'UNITED STATES' },
  {
    placeId: 2863, cityName: 'SARASOTA/BRADENTON', state: 'FL', countryName: 'UNITED STATES',
  },
  {
    placeId: 8115, cityName: 'SARATOGA', state: 'WY', countryName: 'UNITED STATES',
  },
  {
    placeId: 8130, cityName: 'SAULT STE MARIE', state: 'MI', countryName: 'UNITED STATES',
  },
  { placeId: 8132, cityName: 'SAVANNA', countryName: 'UNITED STATES' },
  {
    placeId: 1372, cityName: 'SAVANNAH', state: 'GA', countryName: 'UNITED STATES',
  },
  { placeId: 8133, cityName: 'SAVANNAH', countryName: 'UNITED STATES' },
  { placeId: 8137, cityName: 'SAVOONGA', countryName: 'UNITED STATES' },
  { placeId: 8139, cityName: 'SCAMMON BAY', countryName: 'UNITED STATES' },
  { placeId: 3131, cityName: 'SCAPPOOSE', countryName: 'UNITED STATES' },
  { placeId: 8141, cityName: 'SCHENECTADY', countryName: 'UNITED STATES' },
  { placeId: 8145, cityName: 'SCOTT CITY', countryName: 'UNITED STATES' },
  { placeId: 2953, cityName: 'SCOTTSBLUFF', countryName: 'UNITED STATES' },
  {
    placeId: 8146, cityName: 'SCOTTSDALE', state: 'AZ', countryName: 'UNITED STATES',
  },
  { placeId: 7521, cityName: 'SCRIBNER', countryName: 'UNITED STATES' },
  { placeId: 7524, cityName: 'SEARCY', countryName: 'UNITED STATES' },
  {
    placeId: 1240, cityName: 'SEATTLE', state: 'WA', countryName: 'UNITED STATES',
  },
  { placeId: 7527, cityName: 'SEBRING', countryName: 'UNITED STATES' },
  { placeId: 7529, cityName: 'SEDALIA', countryName: 'UNITED STATES' },
  { placeId: 7530, cityName: 'SEDONA', countryName: 'UNITED STATES' },
  { placeId: 8148, cityName: 'SELAWIK', countryName: 'UNITED STATES' },
  { placeId: 8149, cityName: 'SELDOVIA', countryName: 'UNITED STATES' },
  { placeId: 8152, cityName: 'SELINSGROVE', countryName: 'UNITED STATES' },
  { placeId: 8154, cityName: 'SELMA', countryName: 'UNITED STATES' },
  { placeId: 8155, cityName: 'SELMER', countryName: 'UNITED STATES' },
  { placeId: 8157, cityName: 'SEMINOLE', countryName: 'UNITED STATES' },
  { placeId: 8158, cityName: 'SEMINOLE', countryName: 'UNITED STATES' },
  { placeId: 3017, cityName: 'SEVIERVILLE', countryName: 'UNITED STATES' },
  { placeId: 8198, cityName: 'SEWANEE', countryName: 'UNITED STATES' },
  { placeId: 8200, cityName: 'SEWARD', countryName: 'UNITED STATES' },
  { placeId: 8199, cityName: 'SEWARD', countryName: 'UNITED STATES' },
  { placeId: 8202, cityName: 'SEYMOUR', countryName: 'UNITED STATES' },
  { placeId: 8205, cityName: 'SHAFTER', countryName: 'UNITED STATES' },
  { placeId: 8206, cityName: 'SHAGELUK', countryName: 'UNITED STATES' },
  { placeId: 13031, cityName: 'SHAKTOOLIK', countryName: 'UNITED STATES' },
  { placeId: 13039, cityName: 'SHAWANO', countryName: 'UNITED STATES' },
  { placeId: 8216, cityName: 'SHAWNEE', countryName: 'UNITED STATES' },
  { placeId: 13041, cityName: 'SHEBOYGAN', countryName: 'UNITED STATES' },
  { placeId: 8219, cityName: 'SHEEP MOUNTAIN', countryName: 'UNITED STATES' },
  { placeId: 2990, cityName: 'SHELBY', countryName: 'UNITED STATES' },
  { placeId: 8221, cityName: 'SHELBY', countryName: 'UNITED STATES' },
  { placeId: 8222, cityName: 'SHELBYVILLE', countryName: 'UNITED STATES' },
  { placeId: 8223, cityName: 'SHELBYVILLE', countryName: 'UNITED STATES' },
  { placeId: 8224, cityName: 'SHELDON', countryName: 'UNITED STATES' },
  { placeId: 8227, cityName: 'SHELL LAKE', countryName: 'UNITED STATES' },
  { placeId: 8229, cityName: 'SHELTON', countryName: 'UNITED STATES' },
  { placeId: 13053, cityName: 'SHEMYA', countryName: 'UNITED STATES' },
  { placeId: 3126, cityName: 'SHENANDOAH', countryName: 'UNITED STATES' },
  { placeId: 8231, cityName: 'SHERIDAN', countryName: 'UNITED STATES' },
  { placeId: 8233, cityName: 'SHERMAN-DENISON', countryName: 'UNITED STATES' },
  { placeId: 8232, cityName: 'SHERMAN', countryName: 'UNITED STATES' },
  { placeId: 13065, cityName: 'SHIRLEY', countryName: 'UNITED STATES' },
  { placeId: 13066, cityName: 'SHISHMAREF', countryName: 'UNITED STATES' },
  { placeId: 8243, cityName: 'SHOW LOW', countryName: 'UNITED STATES' },
  { placeId: 1378, cityName: 'SHREVEPORT', countryName: 'UNITED STATES' },
  { placeId: 8244, cityName: 'SHUNGNAK', countryName: 'UNITED STATES' },
  { placeId: 8248, cityName: 'SIBLEY', countryName: 'UNITED STATES' },
  { placeId: 8249, cityName: 'SIDNEY', countryName: 'UNITED STATES' },
  { placeId: 3130, cityName: 'SIDNEY', countryName: 'UNITED STATES' },
  { placeId: 3128, cityName: 'SIKESTON', countryName: 'UNITED STATES' },
  { placeId: 8258, cityName: 'SILOAM SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 8260, cityName: 'SILVER BAY', countryName: 'UNITED STATES' },
  { placeId: 8261, cityName: 'SILVER CITY', countryName: 'UNITED STATES' },
  { placeId: 8264, cityName: 'SILVER SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 8272, cityName: 'SIOUX CENTER', countryName: 'UNITED STATES' },
  { placeId: 8273, cityName: 'SIOUX CITY', countryName: 'UNITED STATES' },
  {
    placeId: 3008, cityName: 'SIOUX FALLS', state: 'SD', countryName: 'UNITED STATES',
  },
  { placeId: 8276, cityName: 'SIREN', countryName: 'UNITED STATES' },
  { placeId: 2402, cityName: 'SITKA', countryName: 'UNITED STATES' },
  { placeId: 8284, cityName: 'SKAGWAY', countryName: 'UNITED STATES' },
  { placeId: 8288, cityName: 'SKWENTNA', countryName: 'UNITED STATES' },
  { placeId: 8289, cityName: 'SLAYTON', countryName: 'UNITED STATES' },
  { placeId: 8291, cityName: 'SLEETMUTE', countryName: 'UNITED STATES' },
  { placeId: 2946, cityName: 'SLIDELL', countryName: 'UNITED STATES' },
  { placeId: 8295, cityName: 'SMITHFIELD', countryName: 'UNITED STATES' },
  {
    placeId: 8296, cityName: 'SMYRNA', state: 'TN', countryName: 'UNITED STATES',
  },
  { placeId: 8300, cityName: 'SNYDER', countryName: 'UNITED STATES' },
  { placeId: 8302, cityName: 'SOCORRO', countryName: 'UNITED STATES' },
  { placeId: 8307, cityName: 'SOLDIERS GROVE', countryName: 'UNITED STATES' },
  { placeId: 8308, cityName: 'SOLDOTNA', countryName: 'UNITED STATES' },
  { placeId: 8309, cityName: 'SOLON SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 8311, cityName: 'SOMERSET', countryName: 'UNITED STATES' },
  { placeId: 8313, cityName: 'SOMERVILLE', countryName: 'UNITED STATES' },
  { placeId: 8314, cityName: 'SOMERVILLE', countryName: 'UNITED STATES' },
  { placeId: 8318, cityName: 'SONORA', countryName: 'UNITED STATES' },
  {
    placeId: 8325, cityName: 'SOUTH BEND', state: 'IN', countryName: 'UNITED STATES',
  },
  { placeId: 8327, cityName: 'SOUTH HAVEN', countryName: 'UNITED STATES' },
  { placeId: 2950, cityName: 'SOUTH HILL', countryName: 'UNITED STATES' },
  { placeId: 8371, cityName: 'SOUTH LAKE TAHOE', countryName: 'UNITED STATES' },
  { placeId: 13144, cityName: 'SOUTH NAKNEK', countryName: 'UNITED STATES' },
  { placeId: 8373, cityName: 'SOUTH ST PAUL', countryName: 'UNITED STATES' },
  { placeId: 8378, cityName: 'SPARREVOHN', countryName: 'UNITED STATES' },
  { placeId: 8379, cityName: 'SPARTA', countryName: 'UNITED STATES' },
  { placeId: 8380, cityName: 'SPARTA', countryName: 'UNITED STATES' },
  { placeId: 8381, cityName: 'SPARTA', countryName: 'UNITED STATES' },
  { placeId: 8382, cityName: 'SPARTANBURG', countryName: 'UNITED STATES' },
  { placeId: 3132, cityName: 'SPEARFISH', countryName: 'UNITED STATES' },
  { placeId: 13156, cityName: 'SPENCER', countryName: 'UNITED STATES' },
  { placeId: 8383, cityName: 'SPENCER', countryName: 'UNITED STATES' },
  { placeId: 1285, cityName: 'SPOKANE', countryName: 'UNITED STATES' },
  { placeId: 8390, cityName: 'SPRINGDALE', countryName: 'UNITED STATES' },
  { placeId: 3151, cityName: 'SPRINGFIELD', countryName: 'UNITED STATES' },
  { placeId: 8392, cityName: 'SPRINGFIELD', countryName: 'UNITED STATES' },
  { placeId: 1377, cityName: 'SPRINGFIELD', countryName: 'UNITED STATES' },
  {
    placeId: 8391, cityName: 'SPRINGFIELD', state: 'IL', countryName: 'UNITED STATES',
  },
  { placeId: 1255, cityName: 'SPRINGFIELD/CHICOPEE', countryName: 'UNITED STATES' },
  { placeId: 8394, cityName: 'SPRINGHILL', countryName: 'UNITED STATES' },
  { placeId: 8404, cityName: 'ST AUGUSTINE', countryName: 'UNITED STATES' },
  { placeId: 8409, cityName: 'ST CHARLES', countryName: 'UNITED STATES' },
  { placeId: 8410, cityName: 'ST CLOUD', countryName: 'UNITED STATES' },
  { placeId: 3133, cityName: 'ST FRANCIS', countryName: 'UNITED STATES' },
  { placeId: 13185, cityName: 'ST GEORGE', countryName: 'UNITED STATES' },
  { placeId: 8414, cityName: 'ST GEORGE', countryName: 'UNITED STATES' },
  { placeId: 13189, cityName: 'ST INIGOES', countryName: 'UNITED STATES' },
  { placeId: 8418, cityName: 'ST JAMES', countryName: 'UNITED STATES' },
  { placeId: 8419, cityName: 'ST JOHNS', countryName: 'UNITED STATES' },
  { placeId: 8420, cityName: 'ST JOSEPH', countryName: 'UNITED STATES' },
  {
    placeId: 1382, cityName: 'ST LOUIS', state: 'MO', countryName: 'UNITED STATES',
  },
  { placeId: 8422, cityName: 'ST MARY\'S', countryName: 'UNITED STATES' },
  { placeId: 8423, cityName: 'ST MARYS', countryName: 'UNITED STATES' },
  { placeId: 13200, cityName: 'ST PAUL I', countryName: 'UNITED STATES' },
  {
    placeId: 8425, cityName: 'ST PAUL', state: 'MN', countryName: 'UNITED STATES',
  },
  {
    placeId: 1358,
    cityName: 'ST PETERSBURG-CLEARWATER',
    state: 'FL',
    countryName: 'UNITED STATES',
  },
  { placeId: 8426, cityName: 'ST PETERSBURG', countryName: 'UNITED STATES' },
  { placeId: 8451, cityName: 'ST. MICHAEL', countryName: 'UNITED STATES' },
  { placeId: 8453, cityName: 'STAFFORD', countryName: 'UNITED STATES' },
  { placeId: 8458, cityName: 'STANTON', countryName: 'UNITED STATES' },
  { placeId: 8459, cityName: 'STAPLES', countryName: 'UNITED STATES' },
  { placeId: 8461, cityName: 'STARKVILLE', countryName: 'UNITED STATES' },
  {
    placeId: 8462, cityName: 'STATE COLLEGE', state: 'PA', countryName: 'UNITED STATES',
  },
  { placeId: 8463, cityName: 'STATESBORO', countryName: 'UNITED STATES' },
  {
    placeId: 8464, cityName: 'STATESVILLE', state: 'NC', countryName: 'UNITED STATES',
  },
  { placeId: 8466, cityName: 'STAUNTON/WAYNESBORO/HARRISONBU', countryName: 'UNITED STATES' },
  { placeId: 8471, cityName: 'STEAMBOAT SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 8477, cityName: 'STEPHENVILLE', countryName: 'UNITED STATES' },
  { placeId: 8478, cityName: 'STERLING', countryName: 'UNITED STATES' },
  { placeId: 8479, cityName: 'STERLING/ROCKFALLS', countryName: 'UNITED STATES' },
  { placeId: 8481, cityName: 'STEVENS POINT', countryName: 'UNITED STATES' },
  { placeId: 13267, cityName: 'STIGLER', countryName: 'UNITED STATES' },
  { placeId: 8484, cityName: 'STILLWATER', countryName: 'UNITED STATES' },
  { placeId: 1374, cityName: 'STOCKTON', countryName: 'UNITED STATES' },
  { placeId: 8488, cityName: 'STORM LAKE', countryName: 'UNITED STATES' },
  { placeId: 8497, cityName: 'STROUD', countryName: 'UNITED STATES' },
  {
    placeId: 13286, cityName: 'STUART', state: 'FLORIDA', countryName: 'UNITED STATES',
  },
  { placeId: 13287, cityName: 'STURGEON BAY', countryName: 'UNITED STATES' },
  { placeId: 3142, cityName: 'STURGIS', countryName: 'UNITED STATES' },
  { placeId: 3046, cityName: 'STURGIS', countryName: 'UNITED STATES' },
  { placeId: 8499, cityName: 'STUTTGART', countryName: 'UNITED STATES' },
  { placeId: 3127, cityName: 'SUFFOLK', countryName: 'UNITED STATES' },
  { placeId: 8506, cityName: 'SULLIVAN', countryName: 'UNITED STATES' },
  { placeId: 8507, cityName: 'SULLIVAN', countryName: 'UNITED STATES' },
  { placeId: 8509, cityName: 'SULPHUR SPRINGS', countryName: 'UNITED STATES' },
  { placeId: 8508, cityName: 'SULPHUR', countryName: 'UNITED STATES' },
  { placeId: 8514, cityName: 'SUMMERSVILLE', countryName: 'UNITED STATES' },
  { placeId: 8515, cityName: 'SUMMERVILLE', countryName: 'UNITED STATES' },
  { placeId: 8516, cityName: 'SUMTER', countryName: 'UNITED STATES' },
  { placeId: 8521, cityName: 'SUPERIOR', countryName: 'UNITED STATES' },
  { placeId: 8527, cityName: 'SUSANVILLE', countryName: 'UNITED STATES' },
  { placeId: 8528, cityName: 'SUSSEX', countryName: 'UNITED STATES' },
  { placeId: 8332, cityName: 'SWAINSBORO', countryName: 'UNITED STATES' },
  { placeId: 13321, cityName: 'SWANSBORO', countryName: 'UNITED STATES' },
  { placeId: 8336, cityName: 'SWEETWATER', countryName: 'UNITED STATES' },
  { placeId: 8338, cityName: 'SYLACAUGA', countryName: 'UNITED STATES' },
  { placeId: 8340, cityName: 'SYLVANIA', countryName: 'UNITED STATES' },
  { placeId: 8341, cityName: 'SYLVESTER', countryName: 'UNITED STATES' },
  {
    placeId: 1384, cityName: 'SYRACUSE', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 8540, cityName: 'TACOMA', countryName: 'UNITED STATES' },
  { placeId: 8546, cityName: 'TAHLEQUAH', countryName: 'UNITED STATES' },
  { placeId: 8559, cityName: 'TALKEETNA', countryName: 'UNITED STATES' },
  { placeId: 8560, cityName: 'TALLADEGA', countryName: 'UNITED STATES' },
  {
    placeId: 8561, cityName: 'TALLAHASSEE', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 3141, cityName: 'TALLULAH/VICKSBURG', countryName: 'UNITED STATES' },
  {
    placeId: 1387, cityName: 'TAMPA', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 8572, cityName: 'TANANA', countryName: 'UNITED STATES' },
  { placeId: 13358, cityName: 'TANGIER', countryName: 'UNITED STATES' },
  { placeId: 2866, cityName: 'TAOS', countryName: 'UNITED STATES' },
  { placeId: 8581, cityName: 'TAPPAHANNOCK', countryName: 'UNITED STATES' },
  { placeId: 8585, cityName: 'TARBORO', countryName: 'UNITED STATES' },
  { placeId: 13371, cityName: 'TATITLEK', countryName: 'UNITED STATES' },
  { placeId: 13372, cityName: 'TATUM', countryName: 'UNITED STATES' },
  { placeId: 13373, cityName: 'TAUNTON', countryName: 'UNITED STATES' },
  { placeId: 8595, cityName: 'TAYLOR', countryName: 'UNITED STATES' },
  { placeId: 8596, cityName: 'TAYLORVILLE', countryName: 'UNITED STATES' },
  { placeId: 8607, cityName: 'TEHACHAPI', countryName: 'UNITED STATES' },
  { placeId: 8608, cityName: 'TEKAMAH', countryName: 'UNITED STATES' },
  { placeId: 3135, cityName: 'TELL CITY', countryName: 'UNITED STATES' },
  { placeId: 8611, cityName: 'TELLER', countryName: 'UNITED STATES' },
  {
    placeId: 8612, cityName: 'TELLURIDE', state: 'CO', countryName: 'UNITED STATES',
  },
  { placeId: 8615, cityName: 'TEMPLE', countryName: 'UNITED STATES' },
  { placeId: 8617, cityName: 'TEN SLEEP', countryName: 'UNITED STATES' },
  { placeId: 13394, cityName: 'TERN I.', countryName: 'UNITED STATES' },
  { placeId: 8624, cityName: 'TERRE HAUTE', countryName: 'UNITED STATES' },
  { placeId: 8625, cityName: 'TERRELL', countryName: 'UNITED STATES' },
  {
    placeId: 1385, cityName: 'TETERBORO', state: 'NJ', countryName: 'UNITED STATES',
  },
  { placeId: 8631, cityName: 'TEXARKANA', countryName: 'UNITED STATES' },
  { placeId: 2980, cityName: 'THE DALLES', countryName: 'UNITED STATES' },
  { placeId: 8637, cityName: 'THEDFORD', countryName: 'UNITED STATES' },
  { placeId: 8638, cityName: 'THERMOPOLIS', countryName: 'UNITED STATES' },
  { placeId: 8642, cityName: 'THIEF RIVER FALLS', countryName: 'UNITED STATES' },
  { placeId: 8646, cityName: 'THOMASTON', countryName: 'UNITED STATES' },
  { placeId: 3139, cityName: 'THOMASVILLE', countryName: 'UNITED STATES' },
  { placeId: 8647, cityName: 'THOMPSON FALLS', countryName: 'UNITED STATES' },
  { placeId: 8648, cityName: 'THOMSON', countryName: 'UNITED STATES' },
  { placeId: 8650, cityName: 'THORNWELL', countryName: 'UNITED STATES' },
  { placeId: 3024, cityName: 'THREE RIVERS', countryName: 'UNITED STATES' },
  { placeId: 8661, cityName: 'TIFTON', countryName: 'UNITED STATES' },
  { placeId: 3137, cityName: 'TILLAMOOK', countryName: 'UNITED STATES' },
  { placeId: 13438, cityName: 'TIN CITY', countryName: 'UNITED STATES' },
  { placeId: 13444, cityName: 'TITUSVILLE', countryName: 'UNITED STATES' },
  { placeId: 8680, cityName: 'TOCCOA', countryName: 'UNITED STATES' },
  { placeId: 8346, cityName: 'TOGIAK VILLAGE', countryName: 'UNITED STATES' },
  { placeId: 13452, cityName: 'TOK', countryName: 'UNITED STATES' },
  { placeId: 8349, cityName: 'TOKSOOK BAY', countryName: 'UNITED STATES' },
  { placeId: 8352, cityName: 'TOLEDO', countryName: 'UNITED STATES' },
  {
    placeId: 1386, cityName: 'TOLEDO', state: 'OH', countryName: 'UNITED STATES',
  },
  { placeId: 8354, cityName: 'TOMAHAWK', countryName: 'UNITED STATES' },
  { placeId: 3143, cityName: 'TOMPKINSVILLE', countryName: 'UNITED STATES' },
  { placeId: 13458, cityName: 'TOMS RIVER', countryName: 'UNITED STATES' },
  { placeId: 8360, cityName: 'TONOPAH', countryName: 'UNITED STATES' },
  { placeId: 8361, cityName: 'TOOELE', countryName: 'UNITED STATES' },
  {
    placeId: 8362, cityName: 'TOPEKA', state: 'KS', countryName: 'UNITED STATES',
  },
  { placeId: 13464, cityName: 'TORRANCE', countryName: 'UNITED STATES' },
  { placeId: 8683, cityName: 'TORRINGTON', countryName: 'UNITED STATES' },
  { placeId: 8693, cityName: 'TRACY', countryName: 'UNITED STATES' },
  { placeId: 8692, cityName: 'TRACY', countryName: 'UNITED STATES' },
  { placeId: 8695, cityName: 'TRAVERSE CITY', countryName: 'UNITED STATES' },
  {
    placeId: 8701, cityName: 'TRENTON', state: 'NJ', countryName: 'UNITED STATES',
  },
  { placeId: 8699, cityName: 'TRENTON', countryName: 'UNITED STATES' },
  { placeId: 8702, cityName: 'TRENTON', countryName: 'UNITED STATES' },
  { placeId: 8705, cityName: 'TRINIDAD', countryName: 'UNITED STATES' },
  { placeId: 8711, cityName: 'TROY', countryName: 'UNITED STATES' },
  { placeId: 8710, cityName: 'TROY', countryName: 'UNITED STATES' },
  { placeId: 8712, cityName: 'TRUCKEE', countryName: 'UNITED STATES' },
  { placeId: 8714, cityName: 'TRUTH OR CONSEQUENCES', countryName: 'UNITED STATES' },
  {
    placeId: 1389, cityName: 'TUCSON', state: 'AZ', countryName: 'UNITED STATES',
  },
  { placeId: 8733, cityName: 'TUCUMCARI', countryName: 'UNITED STATES' },
  { placeId: 8737, cityName: 'TULARE', countryName: 'UNITED STATES' },
  { placeId: 8740, cityName: 'TULLAHOMA', countryName: 'UNITED STATES' },
  { placeId: 1388, cityName: 'TULSA', countryName: 'UNITED STATES' },
  { placeId: 8748, cityName: 'TUNICA', countryName: 'UNITED STATES' },
  { placeId: 9234, cityName: 'TUNUNAK', countryName: 'UNITED STATES' },
  { placeId: 8749, cityName: 'TUPELO', countryName: 'UNITED STATES' },
  { placeId: 8755, cityName: 'TUSCALOOSA', countryName: 'UNITED STATES' },
  { placeId: 8760, cityName: 'TWENTYNINE PALMS', countryName: 'UNITED STATES' },
  {
    placeId: 8762, cityName: 'TWIN FALLS', state: 'ID', countryName: 'UNITED STATES',
  },
  { placeId: 8763, cityName: 'TWO HARBORS', countryName: 'UNITED STATES' },
  { placeId: 8766, cityName: 'TYLER', countryName: 'UNITED STATES' },
  { placeId: 8776, cityName: 'UGNU KUPARUK', countryName: 'UNITED STATES' },
  { placeId: 8780, cityName: 'UKIAH', countryName: 'UNITED STATES' },
  { placeId: 8784, cityName: 'ULYSSES', countryName: 'UNITED STATES' },
  { placeId: 8785, cityName: 'UMIAT', countryName: 'UNITED STATES' },
  { placeId: 8789, cityName: 'UNALAKLEET', countryName: 'UNITED STATES' },
  { placeId: 13542, cityName: 'UNALASKA', countryName: 'UNITED STATES' },
  { placeId: 8791, cityName: 'UNION CITY', countryName: 'UNITED STATES' },
  { placeId: 8794, cityName: 'UNIVERSAL CITY', countryName: 'UNITED STATES' },
  { placeId: 8798, cityName: 'UPLAND', countryName: 'UNITED STATES' },
  { placeId: 8806, cityName: 'UTOPIA CREEK', countryName: 'UNITED STATES' },
  { placeId: 8809, cityName: 'UVALDE', countryName: 'UNITED STATES' },
  { placeId: 8811, cityName: 'VACAVILLE', countryName: 'UNITED STATES' },
  { placeId: 2403, cityName: 'VALDEZ', countryName: 'UNITED STATES' },
  { placeId: 3146, cityName: 'VALDOSTA', countryName: 'UNITED STATES' },
  { placeId: 8817, cityName: 'VALENTINE', countryName: 'UNITED STATES' },
  { placeId: 13574, cityName: 'VALLEY CITY', countryName: 'UNITED STATES' },
  { placeId: 3149, cityName: 'VALPARAISO', countryName: 'UNITED STATES' },
  { placeId: 13577, cityName: 'VALPARAISO', countryName: 'UNITED STATES' },
  { placeId: 8822, cityName: 'VAN HORN', countryName: 'UNITED STATES' },
  {
    placeId: 2844, cityName: 'VAN NUYS', state: 'CA', countryName: 'UNITED STATES',
  },
  { placeId: 8823, cityName: 'VAN WERT', countryName: 'UNITED STATES' },
  { placeId: 3152, cityName: 'VANCOUVER', countryName: 'UNITED STATES' },
  { placeId: 8824, cityName: 'VANDALIA', countryName: 'UNITED STATES' },
  { placeId: 8364, cityName: 'VENETIE', countryName: 'UNITED STATES' },
  { placeId: 8365, cityName: 'VENICE', countryName: 'UNITED STATES' },
  { placeId: 3148, cityName: 'VERMILLION', countryName: 'UNITED STATES' },
  { placeId: 8837, cityName: 'VERNAL', countryName: 'UNITED STATES' },
  { placeId: 3150, cityName: 'VERO BEACH', countryName: 'UNITED STATES' },
  { placeId: 8839, cityName: 'VERSAILLES', countryName: 'UNITED STATES' },
  { placeId: 8842, cityName: 'VICKSBURG', countryName: 'UNITED STATES' },
  { placeId: 8845, cityName: 'VICTORIA', countryName: 'UNITED STATES' },
  { placeId: 8848, cityName: 'VICTORVILLE', countryName: 'UNITED STATES' },
  { placeId: 8849, cityName: 'VIDALIA', countryName: 'UNITED STATES' },
  { placeId: 13615, cityName: 'VINALHAVEN', countryName: 'UNITED STATES' },
  { placeId: 8861, cityName: 'VINCENNES', countryName: 'UNITED STATES' },
  { placeId: 8864, cityName: 'VINTON', countryName: 'UNITED STATES' },
  { placeId: 8867, cityName: 'VISALIA', countryName: 'UNITED STATES' },
  { placeId: 8882, cityName: 'WABASH', countryName: 'UNITED STATES' },
  { placeId: 8883, cityName: 'WACO', countryName: 'UNITED STATES' },
  { placeId: 8885, cityName: 'WADENA', countryName: 'UNITED STATES' },
  { placeId: 8886, cityName: 'WADESBORO', countryName: 'UNITED STATES' },
  { placeId: 8889, cityName: 'WAGNER', countryName: 'UNITED STATES' },
  { placeId: 13638, cityName: 'WAHIAWA', countryName: 'UNITED STATES' },
  { placeId: 8891, cityName: 'WAHOO', countryName: 'UNITED STATES' },
  { placeId: 2965, cityName: 'WAHPETON', countryName: 'UNITED STATES' },
  { placeId: 8895, cityName: 'WAINWRIGHT', countryName: 'UNITED STATES' },
  { placeId: 8902, cityName: 'WAKEFIELD', countryName: 'UNITED STATES' },
  { placeId: 13648, cityName: 'WALES', countryName: 'UNITED STATES' },
  { placeId: 8905, cityName: 'WALLA WALLA', countryName: 'UNITED STATES' },
  { placeId: 8906, cityName: 'WALLACE', countryName: 'UNITED STATES' },
  { placeId: 13651, cityName: 'WALLOPS I', countryName: 'UNITED STATES' },
  { placeId: 8907, cityName: 'WALNUT RIDGE', countryName: 'UNITED STATES' },
  {
    placeId: 8908, cityName: 'WALTERBORO', state: 'SC', countryName: 'UNITED STATES',
  },
  { placeId: 8913, cityName: 'WAPAKONETA', countryName: 'UNITED STATES' },
  { placeId: 8918, cityName: 'WARNER ROBINS', countryName: 'UNITED STATES' },
  { placeId: 8920, cityName: 'WARRENSBURG', countryName: 'UNITED STATES' },
  { placeId: 8921, cityName: 'WARRENTON', countryName: 'UNITED STATES' },
  { placeId: 8922, cityName: 'WARROAD', countryName: 'UNITED STATES' },
  { placeId: 8924, cityName: 'WARSAW', countryName: 'UNITED STATES' },
  { placeId: 8923, cityName: 'WARSAW', countryName: 'UNITED STATES' },
  { placeId: 8925, cityName: 'WASECA', countryName: 'UNITED STATES' },
  {
    placeId: 1302, cityName: 'WASHINGTON', state: 'VA', countryName: 'UNITED STATES',
  },
  { placeId: 8174, cityName: 'WASHINGTON', countryName: 'UNITED STATES' },
  { placeId: 8175, cityName: 'WASHINGTON', countryName: 'UNITED STATES' },
  { placeId: 13677, cityName: 'WASHINGTON', countryName: 'UNITED STATES' },
  { placeId: 8177, cityName: 'WASHINGTON', countryName: 'UNITED STATES' },
  { placeId: 8926, cityName: 'WASHINGTON', countryName: 'UNITED STATES' },
  { placeId: 2977, cityName: 'WASHINGTON', countryName: 'UNITED STATES' },
  { placeId: 8176, cityName: 'WASHINGTON', countryName: 'UNITED STATES' },
  { placeId: 8927, cityName: 'WASILLA', countryName: 'UNITED STATES' },
  { placeId: 8929, cityName: 'WASKISH', countryName: 'UNITED STATES' },
  { placeId: 8933, cityName: 'WATERLOO', countryName: 'UNITED STATES' },
  { placeId: 8934, cityName: 'WATERTOWN', countryName: 'UNITED STATES' },
  { placeId: 1233, cityName: 'WATERTOWN', countryName: 'UNITED STATES' },
  { placeId: 8935, cityName: 'WATERTOWN', countryName: 'UNITED STATES' },
  { placeId: 8937, cityName: 'WATERVILLE', countryName: 'UNITED STATES' },
  { placeId: 8938, cityName: 'WATONGA', countryName: 'UNITED STATES' },
  { placeId: 8942, cityName: 'WATSONVILLE', countryName: 'UNITED STATES' },
  { placeId: 8945, cityName: 'WAUCHULA', countryName: 'UNITED STATES' },
  {
    placeId: 8946, cityName: 'WAUKESHA', state: 'WI', countryName: 'UNITED STATES',
  },
  { placeId: 8947, cityName: 'WAUPACA', countryName: 'UNITED STATES' },
  { placeId: 8948, cityName: 'WAUSAU', countryName: 'UNITED STATES' },
  { placeId: 8949, cityName: 'WAUSEON', countryName: 'UNITED STATES' },
  { placeId: 8950, cityName: 'WAVERLY', countryName: 'UNITED STATES' },
  { placeId: 8953, cityName: 'WAYCROSS', countryName: 'UNITED STATES' },
  { placeId: 8954, cityName: 'WAYNE', countryName: 'UNITED STATES' },
  { placeId: 8955, cityName: 'WAYNESBORO', countryName: 'UNITED STATES' },
  { placeId: 3154, cityName: 'WAYNESBURG', countryName: 'UNITED STATES' },
  { placeId: 8956, cityName: 'WEATHERFORD', countryName: 'UNITED STATES' },
  { placeId: 8957, cityName: 'WEATHERFORD', countryName: 'UNITED STATES' },
  { placeId: 8959, cityName: 'WEBSTER CITY', countryName: 'UNITED STATES' },
  { placeId: 13711, cityName: 'WEIRWOOD', countryName: 'UNITED STATES' },
  { placeId: 8964, cityName: 'WELLINGTON', countryName: 'UNITED STATES' },
  { placeId: 8965, cityName: 'WELLS', countryName: 'UNITED STATES' },
  { placeId: 8966, cityName: 'WELLSVILLE', countryName: 'UNITED STATES' },
  { placeId: 8971, cityName: 'WENATCHEE', countryName: 'UNITED STATES' },
  { placeId: 8972, cityName: 'WENDOVER', countryName: 'UNITED STATES' },
  { placeId: 8975, cityName: 'WEST BEND', countryName: 'UNITED STATES' },
  { placeId: 3087, cityName: 'WEST CHESTER', countryName: 'UNITED STATES' },
  { placeId: 2951, cityName: 'WEST MEMPHIS', countryName: 'UNITED STATES' },
  { placeId: 13725, cityName: 'WEST OSSIPEE', countryName: 'UNITED STATES' },
  { placeId: 13726, cityName: 'WEST PALM BEACH', countryName: 'UNITED STATES' },
  {
    placeId: 1349, cityName: 'WEST PALM BEACH', state: 'FL', countryName: 'UNITED STATES',
  },
  { placeId: 8981, cityName: 'WEST PLAINS', countryName: 'UNITED STATES' },
  { placeId: 8982, cityName: 'WEST POINT', countryName: 'UNITED STATES' },
  { placeId: 2944, cityName: 'WEST UNION', countryName: 'UNITED STATES' },
  { placeId: 3155, cityName: 'WEST YELLOWSTONE', countryName: 'UNITED STATES' },
  { placeId: 13732, cityName: 'WESTERLY', countryName: 'UNITED STATES' },
  {
    placeId: 1238, cityName: 'WESTFIELD/SPRINGFIELD', state: 'MA', countryName: 'UNITED STATES',
  },
  {
    placeId: 2869, cityName: 'WESTHAMPTON BEACH', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 8990, cityName: 'WESTMINSTER', countryName: 'UNITED STATES' },
  { placeId: 8991, cityName: 'WESTON', countryName: 'UNITED STATES' },
  { placeId: 8995, cityName: 'WHARTON', countryName: 'UNITED STATES' },
  { placeId: 8996, cityName: 'WHEATLAND', countryName: 'UNITED STATES' },
  { placeId: 8997, cityName: 'WHEATON', countryName: 'UNITED STATES' },
  { placeId: 3029, cityName: 'WHEELING', countryName: 'UNITED STATES' },
  { placeId: 13748, cityName: 'WHITE MOUNTAIN', countryName: 'UNITED STATES' },
  {
    placeId: 1296, cityName: 'WHITE PLAINS', state: 'NY', countryName: 'UNITED STATES',
  },
  { placeId: 8999, cityName: 'WHITEFIELD', countryName: 'UNITED STATES' },
  { placeId: 9000, cityName: 'WHITEVILLE', countryName: 'UNITED STATES' },
  { placeId: 9003, cityName: 'WHITTIER', countryName: 'UNITED STATES' },
  { placeId: 9005, cityName: 'WICHITA FALLS', countryName: 'UNITED STATES' },
  {
    placeId: 1304, cityName: 'WICHITA', state: 'KS', countryName: 'UNITED STATES',
  },
  { placeId: 13760, cityName: 'WILDWOOD', countryName: 'UNITED STATES' },
  { placeId: 1236, cityName: 'WILKES-BARRE', countryName: 'UNITED STATES' },
  { placeId: 9007, cityName: 'WILLIAMS', countryName: 'UNITED STATES' },
  { placeId: 9008, cityName: 'WILLIAMSBURG', countryName: 'UNITED STATES' },
  { placeId: 13769, cityName: 'WILLIAMSON', countryName: 'UNITED STATES' },
  { placeId: 9009, cityName: 'WILLIAMSON/SODUS', countryName: 'UNITED STATES' },
  { placeId: 9010, cityName: 'WILLIAMSPORT', countryName: 'UNITED STATES' },
  { placeId: 9011, cityName: 'WILLIAMSTON', countryName: 'UNITED STATES' },
  { placeId: 9012, cityName: 'WILLIMANTIC', countryName: 'UNITED STATES' },
  { placeId: 1310, cityName: 'WILLISTON', countryName: 'UNITED STATES' },
  { placeId: 9014, cityName: 'WILLMAR', countryName: 'UNITED STATES' },
  { placeId: 9015, cityName: 'WILLOUGHBY', countryName: 'UNITED STATES' },
  { placeId: 9018, cityName: 'WILLOW GROVE', countryName: 'UNITED STATES' },
  { placeId: 9016, cityName: 'WILLOW', countryName: 'UNITED STATES' },
  { placeId: 9019, cityName: 'WILLOWS', countryName: 'UNITED STATES' },
  {
    placeId: 1306, cityName: 'WILMINGTON', state: 'NC', countryName: 'UNITED STATES',
  },
  { placeId: 9020, cityName: 'WILMINGTON', countryName: 'UNITED STATES' },
  { placeId: 1305, cityName: 'WILMINGTON', countryName: 'UNITED STATES' },
  { placeId: 9021, cityName: 'WINAMAC', countryName: 'UNITED STATES' },
  { placeId: 9023, cityName: 'WINCHESTER', countryName: 'UNITED STATES' },
  { placeId: 9024, cityName: 'WINCHESTER', countryName: 'UNITED STATES' },
  { placeId: 9025, cityName: 'WINDER', countryName: 'UNITED STATES' },
  { placeId: 9027, cityName: 'WINDOM', countryName: 'UNITED STATES' },
  { placeId: 3120, cityName: 'WINDOW ROCK', countryName: 'UNITED STATES' },
  {
    placeId: 1239, cityName: 'WINDSOR LOCKS', state: 'CT', countryName: 'UNITED STATES',
  },
  { placeId: 9028, cityName: 'WINFIELD-ARKANSAS CITY', countryName: 'UNITED STATES' },
  { placeId: 9030, cityName: 'WINK', countryName: 'UNITED STATES' },
  { placeId: 9032, cityName: 'WINNEMUCCA', countryName: 'UNITED STATES' },
  { placeId: 9033, cityName: 'WINNER', countryName: 'UNITED STATES' },
  { placeId: 9034, cityName: 'WINNSBORO', countryName: 'UNITED STATES' },
  { placeId: 3085, cityName: 'WINONA', countryName: 'UNITED STATES' },
  { placeId: 9035, cityName: 'WINSLOW', countryName: 'UNITED STATES' },
  { placeId: 1309, cityName: 'WINSTON-SALEM', countryName: 'UNITED STATES' },
  { placeId: 9036, cityName: 'WINTER HAVEN', countryName: 'UNITED STATES' },
  { placeId: 13807, cityName: 'WISCASSET', countryName: 'UNITED STATES' },
  { placeId: 9039, cityName: 'WISCONSIN RAPIDS', countryName: 'UNITED STATES' },
  { placeId: 3058, cityName: 'WISE', countryName: 'UNITED STATES' },
  { placeId: 9043, cityName: 'WOLF POINT', countryName: 'UNITED STATES' },
  { placeId: 13814, cityName: 'WOODBINE', countryName: 'UNITED STATES' },
  { placeId: 8185, cityName: 'WOODRUFF', countryName: 'UNITED STATES' },
  { placeId: 1391, cityName: 'WOODWARD', countryName: 'UNITED STATES' },
  { placeId: 9048, cityName: 'WOOSTER', countryName: 'UNITED STATES' },
  {
    placeId: 1347, cityName: 'WORCESTER', state: 'MA', countryName: 'UNITED STATES',
  },
  { placeId: 9050, cityName: 'WORLAND', countryName: 'UNITED STATES' },
  { placeId: 3089, cityName: 'WORTHINGTON', countryName: 'UNITED STATES' },
  { placeId: 9051, cityName: 'WRANGELL', countryName: 'UNITED STATES' },
  { placeId: 9052, cityName: 'WRIGHTSTOWN', countryName: 'UNITED STATES' },
  { placeId: 13840, cityName: 'YAKATAGA', countryName: 'UNITED STATES' },
  { placeId: 9064, cityName: 'YAKIMA', countryName: 'UNITED STATES' },
  { placeId: 13842, cityName: 'YAKUTAT', countryName: 'UNITED STATES' },
  { placeId: 3156, cityName: 'YANKTON', countryName: 'UNITED STATES' },
  { placeId: 3136, cityName: 'YORK', countryName: 'UNITED STATES' },
  { placeId: 9093, cityName: 'YORK', countryName: 'UNITED STATES' },
  { placeId: 3157, cityName: 'YOUNGSTOWN-WARREN', countryName: 'UNITED STATES' },
  { placeId: 9098, cityName: 'YUMA PROVING GROUND', countryName: 'UNITED STATES' },
  { placeId: 13870, cityName: 'YUMA', countryName: 'UNITED STATES' },
  { placeId: 9107, cityName: 'ZANESVILLE', countryName: 'UNITED STATES' },
  { placeId: 13883, cityName: 'ZAPATA', countryName: 'UNITED STATES' },
  { placeId: 9114, cityName: 'ZELIENOPLE', countryName: 'UNITED STATES' },
  { placeId: 9119, cityName: 'ZEPHYRHILLS', countryName: 'UNITED STATES' },
  { placeId: 9130, cityName: 'ZUNI PUEBLO', countryName: 'UNITED STATES' },
  { placeId: 4228, cityName: 'ARTIGAS', countryName: 'URUGUAY' },
  { placeId: 4739, cityName: 'CARMELO', countryName: 'URUGUAY' },
  { placeId: 4929, cityName: 'COLONIA', countryName: 'URUGUAY' },
  { placeId: 5226, cityName: 'DURAZNO', countryName: 'URUGUAY' },
  { placeId: 6739, cityName: 'MALDONADO', countryName: 'URUGUAY' },
  { placeId: 6931, cityName: 'MELO', countryName: 'URUGUAY' },
  { placeId: 6942, cityName: 'MERCEDES', countryName: 'URUGUAY' },
  { placeId: 2862, cityName: 'MONTEVIDEO', countryName: 'URUGUAY' },
  { placeId: 7587, cityName: 'PAYSANDU', countryName: 'URUGUAY' },
  { placeId: 7718, cityName: 'PUNTA DEL ESTE', countryName: 'URUGUAY' },
  { placeId: 7918, cityName: 'RIO BRANCO', countryName: 'URUGUAY' },
  { placeId: 7724, cityName: 'RIVERA', countryName: 'URUGUAY' },
  { placeId: 8018, cityName: 'SALTO', countryName: 'URUGUAY' },
  { placeId: 8320, cityName: 'SORIANO', countryName: 'URUGUAY' },
  { placeId: 8541, cityName: 'TACUAREMBO', countryName: 'URUGUAY' },
  { placeId: 8697, cityName: 'TREINTA Y TRES', countryName: 'URUGUAY' },
  { placeId: 8841, cityName: 'VICHADERO', countryName: 'URUGUAY' },
  { placeId: 9198, cityName: 'ANDIZHAN', countryName: 'UZBEKISTAN' },
  { placeId: 2656, cityName: 'BUKHARA', countryName: 'UZBEKISTAN' },
  { placeId: 9200, cityName: 'FERGANA', countryName: 'UZBEKISTAN' },
  { placeId: 9199, cityName: 'KARSHI', countryName: 'UZBEKISTAN' },
  { placeId: 9151, cityName: 'NAMANGAN', countryName: 'UZBEKISTAN' },
  { placeId: 7199, cityName: 'NAVOI', countryName: 'UZBEKISTAN' },
  { placeId: 12280, cityName: 'NUKUS', countryName: 'UZBEKISTAN' },
  { placeId: 2657, cityName: 'SAMARKAND', countryName: 'UZBEKISTAN' },
  { placeId: 2659, cityName: 'TASHKENT', countryName: 'UZBEKISTAN' },
  { placeId: 2658, cityName: 'TERMEZ', countryName: 'UZBEKISTAN' },
  { placeId: 2655, cityName: 'URGENCH', countryName: 'UZBEKISTAN' },
  { placeId: 5310, cityName: 'EMAE', countryName: 'VANUATU' },
  { placeId: 2335, cityName: 'PORT VILA', countryName: 'VANUATU' },
  { placeId: 2334, cityName: 'SANTO', countryName: 'VANUATU' },
  { placeId: 8579, cityName: 'TANNA', countryName: 'VANUATU' },
  { placeId: 4011, cityName: 'ACARIGUA', countryName: 'VENEZUELA' },
  { placeId: 4107, cityName: 'AMAZONAS', countryName: 'VENEZUELA' },
  { placeId: 4124, cityName: 'ANACO', countryName: 'VENEZUELA' },
  { placeId: 4219, cityName: 'ARMANDO SCHWARCK', countryName: 'VENEZUELA' },
  { placeId: 4283, cityName: 'BACHAQUERO', countryName: 'VENEZUELA' },
  { placeId: 4343, cityName: 'BARCELONA', countryName: 'VENEZUELA' },
  { placeId: 4347, cityName: 'BARINAS', countryName: 'VENEZUELA' },
  { placeId: 4352, cityName: 'BARQUISIMETO', countryName: 'VENEZUELA' },
  { placeId: 4516, cityName: 'BOCONO', countryName: 'VENEZUELA' },
  { placeId: 4663, cityName: 'CABIMAS', countryName: 'VENEZUELA' },
  { placeId: 4674, cityName: 'CAICARA', countryName: 'VENEZUELA' },
  { placeId: 4677, cityName: 'CALABOZO', countryName: 'VENEZUELA' },
  { placeId: 4711, cityName: 'CANAIMA', countryName: 'VENEZUELA' },
  { placeId: 4727, cityName: 'CARACAS', countryName: 'VENEZUELA' },
  { placeId: 4745, cityName: 'CARORA', countryName: 'VENEZUELA' },
  { placeId: 4749, cityName: 'CARUPANO', countryName: 'VENEZUELA' },
  { placeId: 4867, cityName: 'CIUDAD BOLIVAR', countryName: 'VENEZUELA' },
  { placeId: 4868, cityName: 'CIUDAD GUAYANA', countryName: 'VENEZUELA' },
  { placeId: 4869, cityName: 'CIUDAD PIAR', countryName: 'VENEZUELA' },
  { placeId: 4963, cityName: 'CORO', countryName: 'VENEZUELA' },
  { placeId: 5007, cityName: 'CUMANA', countryName: 'VENEZUELA' },
  { placeId: 5008, cityName: 'CUMAREBO', countryName: 'VENEZUELA' },
  { placeId: 5283, cityName: 'EL DORADO', countryName: 'VENEZUELA' },
  { placeId: 5289, cityName: 'EL GUAYABO', countryName: 'VENEZUELA' },
  { placeId: 5291, cityName: 'EL MANTECO', countryName: 'VENEZUELA' },
  { placeId: 5298, cityName: 'EL VIGIA', countryName: 'VENEZUELA' },
  { placeId: 5307, cityName: 'ELORZA', countryName: 'VENEZUELA' },
  { placeId: 5321, cityName: 'ENCONTRADOS', countryName: 'VENEZUELA' },
  { placeId: 5724, cityName: 'GUANARE', countryName: 'VENEZUELA' },
  { placeId: 5730, cityName: 'GUASDUALITO', countryName: 'VENEZUELA' },
  { placeId: 5731, cityName: 'GUASIPATI', countryName: 'VENEZUELA' },
  { placeId: 5737, cityName: 'GUIRIA', countryName: 'VENEZUELA' },
  { placeId: 5763, cityName: 'HACIENDA EL CALVARIO', countryName: 'VENEZUELA' },
  { placeId: 5764, cityName: 'HACIENDA RIO YAZA', countryName: 'VENEZUELA' },
  { placeId: 5845, cityName: 'HIGUEROTE', countryName: 'VENEZUELA' },
  { placeId: 5923, cityName: 'ICABARU', countryName: 'VENEZUELA' },
  { placeId: 6000, cityName: 'ISLA DE COCHE', countryName: 'VENEZUELA' },
  { placeId: 6173, cityName: 'KAVANAYEN', countryName: 'VENEZUELA' },
  { placeId: 6358, cityName: 'LA BANANERA', countryName: 'VENEZUELA' },
  { placeId: 6360, cityName: 'LA BLANQUILLA', countryName: 'VENEZUELA' },
  { placeId: 6365, cityName: 'LA DIVINA PASTORA', countryName: 'VENEZUELA' },
  { placeId: 6368, cityName: 'LA FRIA', countryName: 'VENEZUELA' },
  { placeId: 6369, cityName: 'LA GRAN CHINA', countryName: 'VENEZUELA' },
  { placeId: 6377, cityName: 'LA ORCHILA', countryName: 'VENEZUELA' },
  { placeId: 6383, cityName: 'LA TRINIDAD DE ARICHUNA', countryName: 'VENEZUELA' },
  { placeId: 6626, cityName: 'LOS SIETE SAMANES', countryName: 'VENEZUELA' },
  { placeId: 6658, cityName: 'LUEPA', countryName: 'VENEZUELA' },
  { placeId: 2822, cityName: 'MAIQUETIA', countryName: 'VENEZUELA' },
  { placeId: 6793, cityName: 'MARACAIBO', countryName: 'VENEZUELA' },
  { placeId: 6794, cityName: 'MARACAY', countryName: 'VENEZUELA' },
  { placeId: 6808, cityName: 'MARGARITA', countryName: 'VENEZUELA' },
  { placeId: 6861, cityName: 'MATA DE JUAJUA', countryName: 'VENEZUELA' },
  { placeId: 6876, cityName: 'MATURIN', countryName: 'VENEZUELA' },
  { placeId: 6944, cityName: 'MERIDA', countryName: 'VENEZUELA' },
  { placeId: 7071, cityName: 'MORICHAL', countryName: 'VENEZUELA' },
  { placeId: 7418, cityName: 'ORITUPANO', countryName: 'VENEZUELA' },
  { placeId: 7479, cityName: 'PALMARITO', countryName: 'VENEZUELA' },
  { placeId: 7553, cityName: 'PARAGUANA', countryName: 'VENEZUELA' },
  { placeId: 7592, cityName: 'PEDERNALES', countryName: 'VENEZUELA' },
  { placeId: 7779, cityName: 'PUERTO AYACUCHO', countryName: 'VENEZUELA' },
  { placeId: 7783, cityName: 'PUERTO CABELLO', countryName: 'VENEZUELA' },
  { placeId: 7992, cityName: 'SABANETA', countryName: 'VENEZUELA' },
  { placeId: 8029, cityName: 'SAN ANTONIO DEL TACHIRA', countryName: 'VENEZUELA' },
  { placeId: 8033, cityName: 'SAN CARLOS', countryName: 'VENEZUELA' },
  { placeId: 8036, cityName: 'SAN CRISTOBAL', countryName: 'VENEZUELA' },
  { placeId: 8038, cityName: 'SAN FELIPE', countryName: 'VENEZUELA' },
  { placeId: 8042, cityName: 'SAN FERNANDO DE APURE', countryName: 'VENEZUELA' },
  { placeId: 8052, cityName: 'SAN JUAN DE LOS MORROS', countryName: 'VENEZUELA' },
  { placeId: 8067, cityName: 'SAN TOME', countryName: 'VENEZUELA' },
  { placeId: 8086, cityName: 'SANTA BARBARA DE BARINSA', countryName: 'VENEZUELA' },
  { placeId: 8087, cityName: 'SANTA BARBARA DE MONAGAS', countryName: 'VENEZUELA' },
  { placeId: 8088, cityName: 'SANTA BARBARA DEL ZULIA', countryName: 'VENEZUELA' },
  { placeId: 8094, cityName: 'SANTA ELENA DE UAIREN', countryName: 'VENEZUELA' },
  { placeId: 8103, cityName: 'SANTA ROSA DE GUANARE', countryName: 'VENEZUELA' },
  { placeId: 8108, cityName: 'SANTO DOMINGO', countryName: 'VENEZUELA' },
  { placeId: 8614, cityName: 'TEMBLADOR', countryName: 'VENEZUELA' },
  { placeId: 8734, cityName: 'TUCUPITA', countryName: 'VENEZUELA' },
  { placeId: 8744, cityName: 'TUMEREMO', countryName: 'VENEZUELA' },
  { placeId: 8795, cityName: 'UON-QUEN', countryName: 'VENEZUELA' },
  { placeId: 8797, cityName: 'UPATA', countryName: 'VENEZUELA' },
  { placeId: 8801, cityName: 'URIMAN', countryName: 'VENEZUELA' },
  { placeId: 2535, cityName: 'VALENCIA', countryName: 'VENEZUELA' },
  { placeId: 8818, cityName: 'VALERA', countryName: 'VENEZUELA' },
  { placeId: 8169, cityName: 'VALLE DE LA PASCUA', countryName: 'VENEZUELA' },
  { placeId: 8835, cityName: 'VENELAC', countryName: 'VENEZUELA' },
  { placeId: 4637, cityName: 'BUON ME THUOT', countryName: 'VIETNAM' },
  {
    placeId: 13909, cityName: 'CAM RANH', state: 'Khanh Hoa', countryName: 'VIETNAM',
  },
  { placeId: 4690, cityName: 'CAMAU, VIETNAM', countryName: 'VIETNAM' },
  { placeId: 4701, cityName: 'CAMLY, VIETNAM', countryName: 'VIETNAM' },
  { placeId: 4718, cityName: 'CAN THO', countryName: 'VIETNAM' },
  { placeId: 9172, cityName: 'CHU LAI', countryName: 'VIETNAM' },
  { placeId: 4944, cityName: 'CONSON', countryName: 'VIETNAM' },
  { placeId: 2724, cityName: 'DA NANG', countryName: 'VIETNAM' },
  { placeId: 5051, cityName: 'DALAT', countryName: 'VIETNAM' },
  { placeId: 5170, cityName: 'DIENBIEN', countryName: 'VIETNAM' },
  { placeId: 5773, cityName: 'HAIPHONG', countryName: 'VIETNAM' },
  { placeId: 2725, cityName: 'HANOI', countryName: 'VIETNAM' },
  { placeId: 2726, cityName: 'HOCHIMINH', countryName: 'VIETNAM' },
  { placeId: 5902, cityName: 'HUE', countryName: 'VIETNAM' },
  { placeId: 7193, cityName: 'NASAN', countryName: 'VIETNAM' },
  { placeId: 7254, cityName: 'NHATRANG', countryName: 'VIETNAM' },
  { placeId: 7630, cityName: 'PHUCAT', countryName: 'VIETNAM' },
  { placeId: 7631, cityName: 'PHUQUOC', countryName: 'VIETNAM' },
  { placeId: 7676, cityName: 'PLEIKU', countryName: 'VIETNAM' },
  { placeId: 12690, cityName: 'QUANGBINH', countryName: 'VIETNAM' },
  { placeId: 7840, cityName: 'RACHGIA', countryName: 'VIETNAM' },
  { placeId: 8758, cityName: 'TUYHOA, VIETNAM', countryName: 'VIETNAM' },
  { placeId: 8862, cityName: 'VINH', countryName: 'VIETNAM' },
  { placeId: 2542, cityName: 'ST CROIX I', countryName: 'VIRGIN ISLANDS' },
  { placeId: 2541, cityName: 'ST THOMAS I/CHARLOTTE AMALIE', countryName: 'VIRGIN ISLANDS' },
  { placeId: 13646, cityName: 'WAKE I', countryName: 'WAKE I' },
  { placeId: 10836, cityName: 'FUTUNA I', countryName: 'WALLIS IS' },
  { placeId: 13561, cityName: 'UVEA I', countryName: 'WALLIS IS' },
  { placeId: 9140, cityName: 'ABBS', countryName: 'YEMEN' },
  { placeId: 2383, cityName: 'ADEN', countryName: 'YEMEN' },
  { placeId: 4054, cityName: 'AL-BAYDA', countryName: 'YEMEN' },
  { placeId: 4055, cityName: 'AL-BOUGH', countryName: 'YEMEN' },
  { placeId: 4056, cityName: 'AL-GHAIDAH', countryName: 'YEMEN' },
  { placeId: 4057, cityName: 'AL-HAZM', countryName: 'YEMEN' },
  { placeId: 4254, cityName: 'ATAQ', countryName: 'YEMEN' },
  { placeId: 4402, cityName: 'BEIHAN', countryName: 'YEMEN' },
  { placeId: 2384, cityName: 'HODEIDAH', countryName: 'YEMEN' },
  { placeId: 5627, cityName: 'KAMARAN', countryName: 'YEMEN' },
  { placeId: 6810, cityName: 'MARIB', countryName: 'YEMEN' },
  { placeId: 7060, cityName: 'MOORI', countryName: 'YEMEN' },
  { placeId: 2385, cityName: 'MUKALLA', countryName: 'YEMEN' },
  { placeId: 7124, cityName: 'MUKEIRAS', countryName: 'YEMEN' },
  { placeId: 7820, cityName: 'QISHN', countryName: 'YEMEN' },
  { placeId: 7991, cityName: 'SAADAH', countryName: 'YEMEN' },
  { placeId: 2386, cityName: 'SANAA', countryName: 'YEMEN' },
  { placeId: 2387, cityName: 'SAYUN', countryName: 'YEMEN' },
  { placeId: 2388, cityName: 'TAIZ', countryName: 'YEMEN' },
  { placeId: 4839, cityName: 'CHINSALI', countryName: 'ZAMBIA' },
  { placeId: 4840, cityName: 'CHIPATA', countryName: 'ZAMBIA' },
  { placeId: 4849, cityName: 'CHOCHA', countryName: 'ZAMBIA' },
  { placeId: 4852, cityName: 'CHOMA', countryName: 'ZAMBIA' },
  { placeId: 5239, cityName: 'EAST EIGHT', countryName: 'ZAMBIA' },
  { placeId: 5240, cityName: 'EAST FIVE', countryName: 'ZAMBIA' },
  { placeId: 5241, cityName: 'EAST FOUR', countryName: 'ZAMBIA' },
  { placeId: 5242, cityName: 'EAST ONE', countryName: 'ZAMBIA' },
  { placeId: 5243, cityName: 'EAST SEVEN', countryName: 'ZAMBIA' },
  { placeId: 5244, cityName: 'EAST SIX', countryName: 'ZAMBIA' },
  { placeId: 5245, cityName: 'EAST THREE', countryName: 'ZAMBIA' },
  { placeId: 5246, cityName: 'EAST TWO', countryName: 'ZAMBIA' },
  { placeId: 6009, cityName: 'ISOKA', countryName: 'ZAMBIA' },
  { placeId: 6094, cityName: 'KABOMPO', countryName: 'ZAMBIA' },
  { placeId: 6095, cityName: 'KABWE', countryName: 'ZAMBIA' },
  { placeId: 6116, cityName: 'KALABO', countryName: 'ZAMBIA' },
  { placeId: 6124, cityName: 'KALOMO', countryName: 'ZAMBIA' },
  { placeId: 5039, cityName: 'KANJA', countryName: 'ZAMBIA' },
  { placeId: 6131, cityName: 'KANYAU', countryName: 'ZAMBIA' },
  { placeId: 6134, cityName: 'KAOMA', countryName: 'ZAMBIA' },
  { placeId: 6144, cityName: 'KASABA BAY', countryName: 'ZAMBIA' },
  { placeId: 6147, cityName: 'KASAMA', countryName: 'ZAMBIA' },
  { placeId: 6154, cityName: 'KASOMPE', countryName: 'ZAMBIA' },
  { placeId: 6162, cityName: 'KATETE', countryName: 'ZAMBIA' },
  { placeId: 6174, cityName: 'KAWAMBWA', countryName: 'ZAMBIA' },
  { placeId: 6276, cityName: 'KITWE', countryName: 'ZAMBIA' },
  { placeId: 6533, cityName: 'LESA', countryName: 'ZAMBIA' },
  { placeId: 2854, cityName: 'LIVINGSTONE', countryName: 'ZAMBIA' },
  { placeId: 6639, cityName: 'LUANSHYA', countryName: 'ZAMBIA' },
  { placeId: 6667, cityName: 'LUKULU', countryName: 'ZAMBIA' },
  { placeId: 6668, cityName: 'LUKUZI', countryName: 'ZAMBIA' },
  { placeId: 5651, cityName: 'LUNDAZI', countryName: 'ZAMBIA' },
  { placeId: 1133, cityName: 'LUSAKA', countryName: 'ZAMBIA' },
  { placeId: 6680, cityName: 'LUWINGU', countryName: 'ZAMBIA' },
  { placeId: 6686, cityName: 'MAAMBA', countryName: 'ZAMBIA' },
  { placeId: 6779, cityName: 'MANSA', countryName: 'ZAMBIA' },
  { placeId: 6890, cityName: 'MAZABUKA', countryName: 'ZAMBIA' },
  { placeId: 6960, cityName: 'MFUWE', countryName: 'ZAMBIA' },
  { placeId: 6482, cityName: 'MKUSHI', countryName: 'ZAMBIA' },
  { placeId: 7028, cityName: 'MONGU', countryName: 'ZAMBIA' },
  { placeId: 7057, cityName: 'MONZE', countryName: 'ZAMBIA' },
  { placeId: 7103, cityName: 'MPIKA', countryName: 'ZAMBIA' },
  { placeId: 7104, cityName: 'MPOROKOSO', countryName: 'ZAMBIA' },
  { placeId: 7120, cityName: 'MUFULIRA', countryName: 'ZAMBIA' },
  { placeId: 7128, cityName: 'MULOBEZI', countryName: 'ZAMBIA' },
  { placeId: 7157, cityName: 'MWINILUNGA', countryName: 'ZAMBIA' },
  { placeId: 7178, cityName: 'NAMWALA', countryName: 'ZAMBIA' },
  { placeId: 7206, cityName: 'NDOLA', countryName: 'ZAMBIA' },
  { placeId: 7252, cityName: 'NGOMA', countryName: 'ZAMBIA' },
  { placeId: 7325, cityName: 'NYIMBA', countryName: 'ZAMBIA' },
  { placeId: 7616, cityName: 'PETAUKE', countryName: 'ZAMBIA' },
  { placeId: 7958, cityName: 'ROSA', countryName: 'ZAMBIA' },
  { placeId: 7977, cityName: 'RUFUNSA', countryName: 'ZAMBIA' },
  { placeId: 7518, cityName: 'SAKEJI', countryName: 'ZAMBIA' },
  { placeId: 8025, cityName: 'SAMFYA', countryName: 'ZAMBIA' },
  { placeId: 8160, cityName: 'SENANGA', countryName: 'ZAMBIA' },
  { placeId: 8191, cityName: 'SERENJE', countryName: 'ZAMBIA' },
  { placeId: 8238, cityName: 'SHIWA NGANDU', countryName: 'ZAMBIA' },
  { placeId: 8310, cityName: 'SOLWEZI', countryName: 'ZAMBIA' },
  { placeId: 8901, cityName: 'WAKAWAKA', countryName: 'ZAMBIA' },
  { placeId: 8976, cityName: 'WEST FIVE', countryName: 'ZAMBIA' },
  { placeId: 8977, cityName: 'WEST FOUR', countryName: 'ZAMBIA' },
  { placeId: 8980, cityName: 'WEST ONE', countryName: 'ZAMBIA' },
  { placeId: 8984, cityName: 'WEST SEVEN', countryName: 'ZAMBIA' },
  { placeId: 8985, cityName: 'WEST SIX', countryName: 'ZAMBIA' },
  { placeId: 8986, cityName: 'WEST THREE', countryName: 'ZAMBIA' },
  { placeId: 8987, cityName: 'WEST TWO', countryName: 'ZAMBIA' },
  { placeId: 1157, cityName: 'BULAWAYO', countryName: 'ZIMBABWE' },
  { placeId: 4837, cityName: 'CHINHOYI', countryName: 'ZIMBABWE' },
  { placeId: 1158, cityName: 'CHIREDZI', countryName: 'ZIMBABWE' },
  { placeId: 5596, cityName: 'GOKWE', countryName: 'ZIMBABWE' },
  { placeId: 1160, cityName: 'HARARE', countryName: 'ZIMBABWE' },
  { placeId: 1163, cityName: 'HWANGE', countryName: 'ZIMBABWE' },
  { placeId: 1161, cityName: 'KARIBA', countryName: 'ZIMBABWE' },
  { placeId: 1162, cityName: 'MASVINGO', countryName: 'ZIMBABWE' },
  { placeId: 6961, cityName: 'MHANGURA', countryName: 'ZIMBABWE' },
  { placeId: 1159, cityName: 'VICTORIA FALLS', countryName: 'ZIMBABWE' },
];

export default cities;
