import creditCardType from 'credit-card-type';

import { CREDIT_CARD_BRANDS, CREDIT_CARD_BRAND_IDS } from '../constants/CreditCardConstants';
import { replaceSpecialCharacterAndSpaces } from './stringUtils';

export const getCreditCardTypes = (creditCardValue) => {
  const creditCardNumber = replaceSpecialCharacterAndSpaces(creditCardValue || 'undefined');
  return creditCardType(creditCardNumber).filter((creditCard) => {
    return CREDIT_CARD_BRANDS.indexOf(creditCard.type) >= 0;
  });
};

export const getCreditCardType = (creditCardNumber) => {
  const types = getCreditCardTypes(creditCardNumber);
  return types.length === 1 ? types[0] : undefined;
};

export const getCreditCardBrandId = (creditCardNumber) => {
  const creditCard = getCreditCardType(creditCardNumber);
  return creditCard ? CREDIT_CARD_BRAND_IDS[creditCard.type] : undefined;
};

export const getPossibleBrandIds = (creditCardTypes = []) => creditCardTypes
  .map(creditCard => creditCard.type);

export const getDefaultCreditCard = (creditCard = {}) => {
  const {
    ids,
    defaultId,
  } = creditCard;
  if (ids && ids.size) {
    if (defaultId) {
      return defaultId;
    }
    return ids.first();
  }
  return null;
};

const normalizePaymentAdditionalCharge = (charge) => {
  const {
    amount,
    cardBrandID,
    description,
    percentage,
    totalAmount,
  } = charge;
  return {
    amount,
    cardBrandID,
    description,
    percentage,
    totalAmount,
  };
};

export const normalizePaymentAdditionalCharges = (charges = []) => charges
  .reduce((prev, charge) => {
    const previous = prev;
    const { cardBrandID } = charge;
    if (previous.ids.indexOf(cardBrandID) < 0) {
      previous.ids.push(cardBrandID);
      previous.content[cardBrandID] = normalizePaymentAdditionalCharge(charge);
    }
    return previous;
  }, { ids: [], content: {} });

export const findCreditCardByLastFourAndBrand = (
  creditCard = {}, lastFour, brandId,
) => creditCard.cardNumberLastFour === lastFour
    && creditCard.cardBrandId === `${brandId}`;
