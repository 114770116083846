export function normalizeCreditCard({
  alias,
  cardBrandId,
  cardNumberLastFour,
  isDefault,
  type,
  ...other
}) {
  return {
    alias,
    cardBrandId,
    cardNumberLastFour,
    isDefault: isDefault == '1',
    type,
    ...other,
  };
}

export function normalizeMultipleCreditCards(creditCards) {
  return creditCards.reduce((previous, current) => {
    const prev = previous;
    const creditCard = normalizeCreditCard(current);
    prev.ids.push(creditCard.alias);
    prev.content[creditCard.alias] = creditCard;
    if (!prev.defaultId && creditCard.isDefault) {
      prev.defaultId = creditCard.alias;
    }
    return prev;
  }, {
    ids: [],
    content: {},
    defaultId: undefined,
  });
}
