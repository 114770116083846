const DEFAULT_OPTIONS = {
  method: 'POST',
}

const HIDDEN_STYLES = {
  display: 'none',
  position: 'absolute',
  left: '-900px',
  top: '-900px',
}

const HIDDEN_STYLES_STRING = Object.entries(HIDDEN_STYLES).map(([prop, val]) => `${prop}: ${val}`).join(';')

/**
 * Transport for POST cross-domain requests without CORS
 * by using approach with form submitted to hidden iframe, without reloading the page
 * @param  {string} url
 * @param  {Object} data
 * @param  {Object} [options={}]
 * @return {}
 */
export default function PostTransport (url, data, options = {}) {
  if (!url) throw new Error('Missing param url')
  options = { ...DEFAULT_OPTIONS, ...options }

  let isFormSubmitted = false
  const id = String(Math.random()).replace('.', '')
  const frameId = `frame${id}`
  const formId = `form${id}`
  const container = document.createRange().createContextualFragment(`
    <div id="${id}" style="${HIDDEN_STYLES_STRING}">
      <iframe id="${frameId}" name="${frameId}"></iframe>
      <form id="${formId}" target="${frameId}" action="${url}" method="${options.method}" tabindex="-1">
        ${data ? Object.entries(data).map(([fieldName, value]) => `<input type="text" name="${fieldName}" value="" tabindex="-1" />`) : ''}
      </form>
    </div>
  `);
  document.body.appendChild(container);

  const containerEl = document.getElementById(id)
  const form = document.getElementById(formId)
  const frame = document.getElementById(frameId)

  for (const field in { ...data }) {
    form.elements[field].value = data[field];
  }

  frame.addEventListener('load', () => {
    if (isFormSubmitted) document.body.removeChild(containerEl)
  });
  form.submit()
  isFormSubmitted = true
  return true
}
