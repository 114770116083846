/**
 * @author: vinicius.silva@vistajet.com
 */
import React, { Component } from 'react';

import TextField from './TextField';
import MaskedTextField from './MaskedTextField';
import CreditCardFlag from './CreditCardFlag';
import { getPossibleBrandIds, getCreditCardTypes } from '../../utils/creditCardUtils';

import { CREDIT_CARD_BRANDS, CREDIT_CARD_BRAND_IDS } from '../../constants/CreditCardConstants';

const NOT_A_DIGIT = /\D/g;
const DIGIT = /\d/;

export const removeMaskPlaceholder = (value = '') => value.replace(NOT_A_DIGIT, '');

export const getOnlyPossibleCreditCardType = creditCardTypes => {
  if (creditCardTypes.length === 1) {
    return creditCardTypes[0];
  }
  return undefined;
};

export const generateMaskByCreditCardType = (creditCardType = {
  gaps: [4, 8, 12],
  lengths: [16],
}) => {
  const {
    gaps,
    lengths,
  } = creditCardType;

  const size = lengths[0];
  const mask = (new Array(size)).fill(DIGIT);
  gaps.forEach((gap, index) => mask.splice(gap + index, 0, ' '));
  return mask;
};

const getStyles = () => {
  const flag = {
    width: 32,
    height: 21,
    opacity: '1',
  };
  const flagContainer = {
    right: 0,
    top: 0,
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    alignItems: 'center',
  };
  const styles = {
    root: {
      width: '100%',
    },
    input: {},
    flag: Object.assign({}, flag, flagContainer),
    brands: Object.assign({ display: 'flex' }, flagContainer),
    brandFlag: Object.assign({ margin: '5px 5px 0 0' }, flag),
  };
  return styles;
};

class CreditCardInputNumber extends Component {

  static defaultProps = {
    className: '',
    inputClassName: '',
    onChange: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      anchorEl: undefined,
    };
  }

  handleFocus = (e) => {
    this.setState({ isFocused: true, anchorEl: e.currentTarget });
    if (this.props.onFocus) this.props.onFocus(e);
  };

  handleChange = (e, value) => {
    if (this.props.onChange) this.props.onChange(e, removeMaskPlaceholder(value));
  }

  render() {
    const {
      className,
      inputClassName,
      style,
      inputStyle,
      flagStyle,
      lastFour,
      brandId,
      disabled,
      value,
      tabIndexing,
      onChange, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;
    let ccBrandId;
    let possibleBrandIds;
    let creditCardTypes;
    let mask = generateMaskByCreditCardType();

    if (disabled) {
      ccBrandId = brandId;
    } else if (value) {
      creditCardTypes = getCreditCardTypes(value);
      possibleBrandIds = getPossibleBrandIds(creditCardTypes);
      const creditCardType = getOnlyPossibleCreditCardType(creditCardTypes);
      mask = generateMaskByCreditCardType(creditCardType);
    }

    const styles = getStyles(this.props, this.state);

    return (
      <div className={className} style={Object.assign(styles.root, style)}>
        {!disabled
          ? 
            <div>
              <MaskedTextField
                mask={mask}
                placeholderChar="_"
                style={Object.assign(styles.input, inputStyle)}
                // hintText="Credit card number*"
                className={inputClassName}
                onFocus={this.handleFocus}
                // onBlur={this.handleBlur}
                value={value}
                // tabIndexing={tabIndexing}
                onChange={this.handleChange}
                {...other}
              />
              <label>Credit Card Number</label>
            </div>
          
          :
          <div>
            <TextField
              className={inputClassName}
              value={lastFour && `···· ···· ···· ${lastFour}`}
              style={Object.assign(styles.input, inputStyle)}
              disabled={true}
              {...other}
            />
            <label>Credit card number</label>
          </div>
        }

        {ccBrandId
          ? (
            <CreditCardFlag
              brandId={ccBrandId}
              style={Object.assign(styles.flag, flagStyle, styles.brandFlag)}
            />
          )
          : (
            <div style={styles.brands}>
              {(possibleBrandIds || CREDIT_CARD_BRANDS).map((ccBrand) => {
                const flagId = CREDIT_CARD_BRAND_IDS[ccBrand];
                return (
                  <CreditCardFlag
                    key={flagId}
                    brandId={flagId}
                    style={styles.brandFlag}
                  />
                );
              })}
            </div>
          )
        }
      </div>
    );
  }
}

export default CreditCardInputNumber;
