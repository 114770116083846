import React, { Component } from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../../shared/ReactCalendar';
import ConfirmationPopUp from '../../shared/ConfirmationPopUpNew';
import IntlTelphoneInput from '../../shared/PhoneNumberSelector';
import MessagePopUp from '../../shared/MessagePopUp';
import SpinnerLoader from '../../shared/spinnerLoader';
import {
  getFirstChars,
} from '../../../utils/stringUtils';
import validateUserProfileForm from '../../../utils/Validators/AddEditUserProfileDetailValidator';
import { LEFT_BUTTON_TEXT, RIGHT_BUTTON_TEXT, CONFIRMATION_MESSAGE, CONFIRMATION_TITLE } from '../../../constants/DirtyPopupConstant';
import dirtystateSingleTon from '../../../validators/validateDirty';
import SPAConstants from '../../../constants/SPAConstants';

export default class UserDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      isPhoneNumberValid: false,
      email: '',
      errors: {},
      popupMessage: '',
      isFieldsChanged: false,
    };
    this.oldState = null;
    this.emailChanged = false;
  }

  componentDidMount() {
    this.populateValues();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user.user !== nextProps.user.user
      && nextProps.user.isProfileUpdated
      && !this.emailChanged
    ) {
      this.emailChanged = false;
      this.oldState = null;
      this.props.toggleIsUserEditDetails();
    }
    if (this.props.user.user !== nextProps.user.user
      && nextProps.user.isProfileUpdated
      && this.emailChanged
    ) {
      if (nextProps.user.user) {
        this.props.logout(nextProps.user.user.userToken).then(() => {
          this.redirectToHome();
        }, this.handleApiErrors);
      }
    }
    if (nextProps.user.isProfileUpdateError) {
      window.UIkit.modal('#UserProfileUpdateErrorPopUp').show();
    }
  }

  populateValues = () => {
    const { user } = this.props.user;
    const forState = {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.username || '',
      dateOfBirth: (user.dateOfBirth && user.dateOfBirth.length !== 0) && moment(user.dateOfBirth) || '',
    };
    this.setState(forState);
    // Preserving user original state
    this.oldState = forState;
    this.oldState.phoneNumber = user.phoneNumber || '';
    // call this handler to update state of phoneNumber
    this.handlePhoneNumberChange(true, user.phoneNumber);
  };

  validateForm = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      isPhoneNumberValid,
      email,
    } = this.state;

    const res = validateUserProfileForm({
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      isPhoneNumberValid,
      email,
    });
    if (res.isError) {
      this.setState({
        errors: res,
      });
    } else {
      if (this.emailChanged) {
        this.emailPopUp.show();
      } else {
        this.updateProfile();
      }
    }
  }

  updateProfile = () => {
    const {
      updateUserProfileAction,
    } = this.props;
    const {
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      isPhoneNumberValid,
      email,
    } = this.state;
    const {
      user,
    } = this.props.user;
    const payload = {
      firstName,
      lastName,
      dateOfBirth: moment(dateOfBirth),
      phoneNumber,
      isPhoneNumberValid,
      email,
    };
    updateUserProfileAction(payload, user).then(() => { dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false) }, this.handleApiErrors);
    if (this.emailChanged) {
      // This function stops the notification from showing when
      // user has changed his/her email
      // toggleShowNotification();
      this.closePopup(this.emailPopUp);
    }
  }

  compareOldAndNewValues = () => {
    const {
      firstName,
      lastName,
      email,
      dateOfBirth,
      phoneNumber,
      isFieldsChanged,
    } = this.state;
    const newState = {
      firstName,
      lastName,
      email,
      dateOfBirth,
      phoneNumber,
    };
    if (
      (newState.firstName.toLowerCase() !== this.oldState.firstName.toLowerCase()
        || newState.lastName.toLowerCase() !== this.oldState.lastName.toLowerCase()
        || newState.email.toLowerCase() !== this.oldState.email.toLowerCase()
        || newState.dateOfBirth !== this.oldState.dateOfBirth
        || newState.phoneNumber !== this.oldState.phoneNumber
      )
      && !isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, true);
      this.setState({
        isFieldsChanged: true,
      });
    } else if (
      (newState.firstName.toLowerCase() === this.oldState.firstName.toLowerCase()
        && newState.lastName.toLowerCase() === this.oldState.lastName.toLowerCase()
        && newState.email.toLowerCase() === this.oldState.email.toLowerCase()
        && newState.dateOfBirth === this.oldState.dateOfBirth
        && newState.phoneNumber === this.oldState.phoneNumber
      )
      && isFieldsChanged
    ) {
      dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
      this.setState({
        isFieldsChanged: false,
      });
    }
    // Check email change to log user out once profile updated
    if (newState.email !== this.oldState.email) {
      this.emailChanged = true;
    }
    if (newState.email === this.oldState.email) {
      this.emailChanged = false;
    }
  }

  handleApiErrors = (err) => {
    this.setState({
      popupMessage: err,
    });
  };

  redirectToHome = () => {
    window.location = '/';
  };

  handleFirstNameChange = (e) => {
    this.setState({ firstName: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleLastNameChange = (e) => {
    this.setState({ lastName: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleDobChange = (date) => {
    this.setState({ dateOfBirth: date }, () => {
      this.compareOldAndNewValues();
    });
  };

  handlePhoneNumberChange = (status = true, phoneNumber) => {
    this.setState({
      phoneNumber: getFirstChars(phoneNumber, 15, status),
      isPhoneNumberValid: status,
    }, () => {
      this.compareOldAndNewValues();
    });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value }, () => {
      this.compareOldAndNewValues();
    });
  };

  removeErrors = (e) => {
    e.preventDefault();
    if (this.state.errors && this.state.errors.isError) { // eslint-disable-line
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors[e.target.id] = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  };

  removePhoneError = () => {
    if (this.state.errors.isError) {
      const { errors } = this.state;
      const remErrors = errors;
      remErrors.errors.phoneNumber = null;
      const newState = {
        errors: remErrors.errors,
        isError: remErrors.isError,
      };
      this.setState({
        errors: newState,
      });
    }
  }

  handleGoBack = () => {
    const { isFieldsChanged } = this.state;
    if (isFieldsChanged) {
      this.confirmPopup.show();
    } else {
      this.goBackToAccountDetails();
    }
  }

  closePopup = (ref) => {
    ref.hide();
  }

  goBackToAccountDetails = () => {
    dirtystateSingleTon.setDirty(SPAConstants.UserProfile, false);
    this.closePopup(this.confirmPopup);
    const { toggleIsUserEditDetails } = this.props;
    toggleIsUserEditDetails();
  }

  renderUserDetailsForm = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      email,
      popupMessage,
      errors,
      isFieldsChanged,
    } = this.state;
    const { isProfileUpdateLoading, isLoggingOut } = this.props.user;
    const buttonClass = isFieldsChanged
      ? 'uk-button change_password_button uk-width-1-1 uk-text-center vistajet_button_primary'
      : 'uk-button vistajet_button_primary vistajet_disabled change_password_button uk-width-1-1 uk-text-center';
    return (
      <div className="uk-card uk-card-default uk-card-body uk-padding-remove vistajet_card vistajet_added_passport">
        {
          (isProfileUpdateLoading || isLoggingOut) && (
            <div className="vistajet_spinner">
              <SpinnerLoader />
            </div>
          )
        }
        <div class="vistajet_head_column">
          <div class="uk-clearfix">
            <div class="uk-float-left">
              <a href="#" onClick={this.handleGoBack}>
                <i data-uk-icon="icon: chevron-left; ratio: 1"></i>
                Back
            </a>
            </div>
            <div class="uk-text-center">
              <h3> Edit Profile</h3>
            </div>
          </div>
        </div>
        <div className="vistajet_profile_details_column vistajet_profile_edit">
          <div className="uk-flex uk-grid-small vistajet_primary_form" data-uk-grid>
            <div className="uk-width-1-2@s">
              <div
                className="vistajet_input_wrapper"
              >
                <input
                  id="firstName"
                  type="text"
                  name="firstName"
                  onChange={this.handleFirstNameChange}
                  onFocus={this.removeErrors}
                  value={firstName}
                  autocomplete="given-name"
                />
                <label htmlFor="firstName">
                  Name
                </label>
                {
                  errors.errors && errors.errors.firstName && (
                    <p>
                      {errors.errors.firstName}
                    </p>
                  )
                }
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div className="vistajet_input_wrapper">
                <input
                  id="lastName"
                  type="text"
                  name="lastName"
                  onChange={this.handleLastNameChange}
                  onFocus={this.removeErrors}
                  value={lastName}
                  autocomplete="family-name"
                />
                <label htmlFor="lastName">
                  Surname
                </label>
                {
                  errors.errors && errors.errors.lastName && (
                    <p>
                      {errors.errors.lastName}
                    </p>
                  )
                }
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div className="vistajet_input_wrapper uk-margin-remove">
                <div className="vistajet_date_picker_new">
                  <Calendar
                    dateInput={dateOfBirth}
                    placeholder={'Date of birth'}
                    readOnly={true}
                    minDate={moment().subtract(200, 'year')}
                    maxDate={moment()}
                    onDateChange={this.handleDobChange}
                    className="uk-width-1-1"
                  />
                  {
                    errors.errors && errors.errors.dateOfBirth && (
                      <p className="vistajet_outer_error">
                        {errors.errors.dateOfBirth}
                      </p>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div className="vistajet_input_wrapper vistajet_input_phone_number">
                <IntlTelphoneInput
                  id="phoneNumber"
                  value={phoneNumber}
                  errorText={errors.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                  onFocus={this.removePhoneError}
                  hintText=""
                  autoPlaceholder={false}
                />
                <label htmlFor="phoneNumber">
                  Phone Number
                </label>
                {
                  errors.errors && errors.errors.phoneNumber && (
                    <p>
                      {errors.errors.phoneNumber}
                    </p>
                  )
                }
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1">
              <div className="vistajet_input_wrapper" >
                <input
                  id="email"
                  type="text"
                  name="email"
                  onChange={this.handleEmailChange}
                  onFocus={this.removeErrors}
                  value={email}
                  autocomplete="email"
                />
                <label htmlFor="email">
                  Email
                </label>
                {
                  errors.errors && errors.errors.email && (
                    <p>
                      {errors.errors.email}
                    </p>
                  )
                }
              </div>
            </div>
          </div>
        </div>




        <div className="vistajet_profile_footer">
          <button
            className={buttonClass}
            // onClick={this.checkIfPaxNumberExistsAndValidateForm}
            disabled={!isFieldsChanged}
            onClick={this.validateForm}
          >
            Save Profile
          </button>
        </div>

        <ConfirmationPopUp
          ref={(confirmPopup) => { this.confirmPopup = confirmPopup; }}
          title={CONFIRMATION_TITLE}
          leftButtonContent={LEFT_BUTTON_TEXT}
          rightButtonContent={RIGHT_BUTTON_TEXT}
          onLeftButtonClick={() => this.closePopup(this.confirmPopup)} // close form
          onRightButtonClick={this.goBackToAccountDetails}
          content={CONFIRMATION_MESSAGE}
        />
        <ConfirmationPopUp
          ref={(emailPopUp) => { this.emailPopUp = emailPopUp; }}
          title="Attention"
          id="emailChangedWillLogout"
          leftButtonContent="Cancel"
          onLeftButtonClick={() => this.closePopup(this.emailPopUp)}
          rightButtonContent="Continue"
          onRightButtonClick={this.updateProfile}
          content="You are updating your email and you will need to login again. Do you wish to continue?"
        />
        <MessagePopUp
          id="UserProfileUpdateErrorPopUp"
          content={popupMessage}
        />
      </div>
    );
  };

  render() {
    return this.renderUserDetailsForm();
  }
}
