
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SpinnerLoader from '../../components/shared/spinnerLoader';
import { getFiles } from '../../utils/fileUtils';
import { formatDate, dateFormats } from '../../utils/dateUtils';
import TopUpAccount from '../../components/AccountStatement/TopUpAccount';
import moment from 'moment';
import Modal from '../shared/Modal';
import MessagePopUp from "../../components/shared/MessagePopUpNew";
import { isAdhoc } from '../../utils/userUtils';
import { numberWithCommas } from '../../utils/parserUtils';
import { scrollFunctionForTable } from '../../utils/Helpers';
import { isMobile } from 'react-device-detect';
import {
  TOPUP_ACCOUNT_PAGE_URL,
} from '../../configs/constants';
import { redirect } from '../Drawer';

class DirectAccountBalance extends Component {

  static propTypes = {
    token: PropTypes.string,
    membershipAccountLoading: PropTypes.bool,
    accountData: PropTypes.object,
    GetMembershipAccountStatement: PropTypes.func,
    moreTransactions: PropTypes.bool,
    loadingMoreTransactions: PropTypes.bool,
    //topup
    TopUpReference: PropTypes.func,
    TopUpAction: PropTypes.func,
    CancelTopUp: PropTypes.func,
    SetOpenPopupFalse: PropTypes.func,
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bankName: PropTypes.string,
    currency: PropTypes.string,
    openPopUpMessage: PropTypes.number,
    iban: PropTypes.string,
    referenceCode: PropTypes.string,
    swift: PropTypes.string,
    to: PropTypes.string,
    topUpAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topUpId: PropTypes.number,
    topUpReferenceLoader: PropTypes.bool,
    user: PropTypes.object,
    topUpSubmittedLoader: PropTypes.bool,
    topUpCancelLoader: PropTypes.bool,
    directAccoutBalanceError: PropTypes.string,
    ClearTopUpError: PropTypes.func,
    topUpError: PropTypes.string,
  };
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state = {
      topUpIdState: 0,
      referenceCodeState: '',
      topUpAmountState: '',
      disableAmountState: false,
    }
  }
  componentDidMount() {
    const { token, GetMembershipAccountStatement } = this.props;
    GetMembershipAccountStatement(token, 0, false)
  }
  componentWillReceiveProps(nextProps) {
    const { topUpIdState } = this.state;
    const { topUpId,topUpCancelLoader,topUpSubmittedLoader } = nextProps;
    if ((this.props.topUpId !== topUpId) &&
      (topUpId !== topUpIdState) &&
      !(topUpCancelLoader || topUpSubmittedLoader)) {
      this.setState({
        topUpIdState: nextProps.topUpId,
        referenceCodeState: nextProps.referenceCode,
        topUpAmountState: nextProps.topUpAmount,
        disableAmountState: true,
      })
    }
    if (nextProps.openPopUpMessage) {
      this.topUpAccountModal.hide();
      this.props.SetOpenPopupFalse();
      if (nextProps.openPopUpMessage === 1) {
        window.UIkit.notification({ message: `Top Up successful!`, timeout: 3000 });
      } else if(nextProps.openPopUpMessage === 2) {
        window.UIkit.notification({ message: `Top Up cancelled!`, timeout: 3000 });
      }
    }
    if (nextProps.directAccoutBalanceError) {
      this.validationPopUp.show();
    }
  }
  loadMoreTranscations = () => {
    const { token, GetMembershipAccountStatement, accountData } = this.props;
    GetMembershipAccountStatement(token, accountData.lineItems.length, true)
  }

  renderInvoices = () => {
    const { accountData, token } = this.props;
    let data = [];
    data = ((accountData || {}).lineItems || []).map((value) => {
      return (
        <tr key={value.description + value.documentDate + value.amount + value.fileKey}>
          <td className="vistajet_light_text uk-padding-remove-left uk-table-shrink uk-text-nowrap">
            {formatDate(moment(value.documentDate), dateFormats.DDMMMYYYY)}
          </td>
          <td className="vistajet_light_text uk-table-expand uk-text-truncate vistajet_max_width_130" uk-tooltip={`title: ${value.description}`}>
            {value.description}
          </td>
          <td className="vistajet_dark_text uk-table-shrink uk-text-nowrap">{value.currency} {numberWithCommas(value.amount)}</td>
          <td className="uk-padding-remove-right">
            {value.fileKey ?
              <ul className="uk-iconnav uk-flex-right uk-visible@s ">
                <li>
                  <a className="vistajet_eye_icon" href={getFiles(value.fileKey, token, false)} target={'_blank'}></a>
                </li>
                <li>
                  <a className="vistajet_pdf_icon" href={getFiles(value.fileKey, token, false, true)} target={'_blank'}></a>
                </li>
              </ul> : null}
              {value.fileKey ? <ul className="uk-iconnav uk-flex-right uk-hidden@s">
                    <li><button className="vistajet_more_icon" type="button"></button></li>
                    <div uk-dropdown="mode: click; pos: bottom-right" className="uk-margin-remove vistajet_iconnav_dropdown">
                        <ul className="uk-nav uk-dropdown-nav uk-iconnav uk-iconnav-vertical">
                            <li className="uk-text-truncate">
                              <a className="vistajet_eye_icon" href={getFiles(value.fileKey, token, false)} target={'_blank'}><span>View</span></a>
                            </li>
                            <li className="uk-text-truncate">
                              <a className="vistajet_pdf_icon" href={getFiles(value.fileKey, token, false, true)} target={'_blank'}><span>Download</span></a>
                            </li>
                        </ul>
                    </div>
                </ul> : null}
          </td>
        </tr>
      );
    });
    return data;
  }
  showTopup = () => {
    this.setState({
      disableAmountState: false,
      topUpIdState: 0,
      referenceCodeState: '',
      topUpAmountState: '',
    }, () => this.topUpAccountModal.show())
  }
  clearErrorMessage = () => {
    this.props.ClearTopUpError();
  }
  isComponentLoadingClass = () => {
    const { membershipAccountLoading } = this.props;
    if (membershipAccountLoading) {
      return 'vistajet_top_up';
    }
    return '';
  }
  render() {
    const {
      membershipAccountLoading,
      accountData,
      moreTransactions,
      loadingMoreTransactions,
      //topupData
      TopUpReference,
      TopUpAction,
      CancelTopUp,
      token,
      accountId,
      bankName,
      currency,
      iban,
      swift,
      to,
      user,
      topUpReferenceLoader,
      topUpSubmittedLoader,
      topUpCancelLoader,
      openPopUpMessage,
      topUpError,
      ClearTopUpError,
    } = this.props;
    const {
      topUpIdState,
      referenceCodeState,
      topUpAmountState,
      disableAmountState,
    } = this.state
    let adhocUser = '';
    if (user) {
      adhocUser = isAdhoc(user);
    }
    return (
      <div>
        <Modal
          ref={(topUpAccountModal)=>{this.topUpAccountModal=topUpAccountModal;}}
          classNames={{
            modal: 'topUpModalDirect',
          }}
          styles=
            {{modal :{background:'transparent', boxShadow: 'none'},
            overlay: {background: 'rgba(0, 0, 0, 0.2)'}}}
          showCloseIcon={false}
          focusTrapped={false}
        >
          <TopUpAccount
            modalId='TopUpModalDirect'
            confirmId='TopUpModalDirectConfirm'
            TopUpReference={TopUpReference}
            TopUpAction={TopUpAction}
            CancelTopUp={CancelTopUp}
            token={token}
            accountId={accountId}
            bankName={bankName}
            currency={currency}
            iban={iban}
            referenceCode={referenceCodeState}
            swift={swift}
            to={to}
            topUpAmount={topUpAmountState}
            topUpId={topUpIdState}
            user={user}
            topUpReferenceLoader={topUpReferenceLoader}
            topUpSubmittedLoader={topUpSubmittedLoader}
            topUpCancelLoader={topUpCancelLoader}
            disableAmountField={disableAmountState}
            openPopUpMessage={openPopUpMessage}
            fromDirectScreen={true}
            topUpAccountModal={this.topUpAccountModal}
            topUpError={topUpError}
            ClearTopUpError={ClearTopUpError}
          />
        </Modal>
          <div className="uk-container vistajet_screen_wrapper">
            <div className="uk-margin-medium-top uk-width-1-1@s uk-width-2-3@m uk-margin-auto vistajet_direct_account_balance">
              <h3 className="vistajet_main_outer_heading">Direct Account Balance</h3>
              <div className="uk-card uk-card-default uk-card-body vistajet_card uk-padding-remove">
              <div className={`${this.isComponentLoadingClass()}`}>
              <div className="vistajet_statement_content">
              {
                (membershipAccountLoading) && 
                <div className="vistajet_spinner">
                  <SpinnerLoader />
              </div>
              }
              {!(((accountData || {}).lineItems || []).length && !membershipAccountLoading)?
                    <p className={`${!isMobile ? "uk-margin-left" : "" } vistajet_dark_text`}>No transactions to show {!adhocUser ?
                      <button className="uk-button vistajet_button_default vistajet_button_primary top_up_direct_empty" onClick={() => redirect(TOPUP_ACCOUNT_PAGE_URL.url)}>
                      Add Top Up
                    </button> : ""}</p> :
                    <div>
                      <div className="uk-grid-collapse vistajet_top_up_section" data-uk-grid>
                        <div className="uk-width-expand vistajet_balance_wrapper">
                          {!adhocUser ? 
                          <h5>Balance</h5> :
                          <h4>{accountData.accountDisplayName}</h4>}
                          {!adhocUser &&
                          <h4>{accountData.currency} {numberWithCommas(accountData.currentBalance)}</h4>}
                        </div>
                        <div className="uk-width-1-1 uk-width-auto@s uk-text-right">
                          {!adhocUser &&
                          <button className="uk-button vistajet_button_default vistajet_button_primary" onClick={this.showTopup}>
                            Top Up
                          </button>}
                        </div>
                      </div>
                      <div className="vistajet_scrollable_table">
                        <p className="vistajet_dark_text uk-margin-small-top uk-margin-remove-bottom">Invoices</p>
                        <div className="uk-overflow-auto vistajet_table_wrapper">
                        { scrollFunctionForTable && scrollFunctionForTable() }
                          <table className="uk-table uk-table-small">
                            <tbody>
                              {this.renderInvoices()}
                            </tbody>
                          </table>
                        </div>
                        <div className="vistajet_bottom_button_wrapper">
                          {moreTransactions ?
                            <button className="uk-button vistajet_button_default"
                              onClick={this.loadMoreTranscations} disabled={loadingMoreTransactions}>
                              {loadingMoreTransactions ? <SpinnerLoader /> : `Load More`}
                            </button> : null}
                        </div>
                      </div>
                    </div>}
                </div>
              </div>
              </div>
            </div>
          </div>
        <MessagePopUp
          id = {'validationPopUp'}
          content={this.props.directAccoutBalanceError}
          ref={(validationPopUp)=>{this.validationPopUp=validationPopUp;}}
          onClose={this.clearErrorMessage}
        />
      </div>
    );

  }
}
export default DirectAccountBalance;
